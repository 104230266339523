import React, {useEffect, useState} from 'react'
import PropTypes from 'prop-types'
import 'react-phone-number-input/style.css'
import PhoneInput, { isPossiblePhoneNumber } from 'react-phone-number-input'
import styled from 'styled-components'
import { FormattedMessage, useIntl } from 'react-intl'
import { Input } from '../../../common/UiKit/Input'
import { TextButton } from '../../../common/UiKit/TextButton'
import { Colors } from '../../../../constants/colors'
import { useDispatch, useSelector } from 'react-redux'
import { sendCode, sendPhone } from '../../../../redux/modules/auth'
import {BUTTON_COLORS} from "../../../../constants/colors";
import {CircularProgress} from "@mui/material";

const SMS_TIMER_MAX = 15;

const InputComponent = React.forwardRef((props, ref) => <InputStyled {...props} inputRef={ref} />)

export const PhoneConfirmForm = ({ afterSubmit }) => {
  const [phone, onPhoneChange] = useState('')
  const [code, onCodeChange] = useState('')
  const [errors, setErrors] = useState({ phone: '', code: '' })
  const [showCodeInput, toggleCodeInput] = useState(false)
  const { formatMessage } = useIntl()
  const dispatch = useDispatch()
  const fetching = useSelector(state => state.loading.enterPhone)
  const codeFetching = useSelector(state => state.loading.enterCode)
  const isPhoneValid = phone && typeof phone === 'string' ? isPossiblePhoneNumber(phone) : false;
  const isCodeValid = code.length > 4
  const [canSendCode, setCanSendCode] = useState(true);
  const [sendingSmsTimer, setSendingSmsTimer] = useState(0)

  const onSendPhoneClick = () => {
    if (canSendCode) {
      let phoneToSend = phone;
      if (phoneToSend.length > 12) {
        phoneToSend = phoneToSend.replace(/^\+330/, '+33');
      }
      dispatch(sendPhone(phoneToSend))
        .then(() => {
          toggleCodeInput(true)
        })
        .catch(() => {
          setErrors({...errors, phone: 'auth.error.phone'})
        })

      //setting a timeout of 30 seconds before we can send another code to prevent spamming
      setCanSendCode(false);
      setSendingSmsTimer(SMS_TIMER_MAX)
      setTimeout(() => {
        setCanSendCode(true)
      }, SMS_TIMER_MAX * 1000);
    }
  }

  useEffect(() => {
    if (!canSendCode && sendingSmsTimer > 0) {
      setTimeout(() => {
        let newTime = sendingSmsTimer - 1
        setSendingSmsTimer(newTime)
      }, 1000)
    }
  }, [canSendCode, sendingSmsTimer])

  const onSendCodeClick = () => {
    dispatch(sendCode(code))
      .then(() => {
        if (afterSubmit) afterSubmit()
      })
      .catch(() => {
        setErrors({ ...errors, code: 'auth.error.code' })
      })
  }

  const onKeyDown = (e) => {
    if (e.keyCode === 13) {
      if (!showCodeInput && isPhoneValid) {
        onSendPhoneClick()
      }

      if (showCodeInput && isCodeValid) {
        onSendCodeClick()
      }
    }
  }

  const changePhone =() => {
    toggleCodeInput(false);
  }

  return (
    <Wrapper>
      <Label>
        <FormattedMessage id='auth.phone.enter' />
      </Label>
        <PhoneNumberInputStyled>
            <PhoneNumberInput
                international
                defaultCountry='FR'
                value={phone}
                onChange={onPhoneChange}
                disabled={showCodeInput || fetching}
                inputComponent={InputComponent}
                displayInitialValueAsLocalNumber
                numberInputProps={{
                    autoFocus: true,
                    color: 'primary',
                    disabled: showCodeInput || fetching,
                    inputProps: {
                        disabled: showCodeInput || fetching,
                    },
                    error: !!errors.phone,
                    helperText: errors.phone ? formatMessage({ id: errors.phone }) : '',
                    onKeyDown,
                }}
                limitMaxLength
            />
        </PhoneNumberInputStyled>
      {!showCodeInput && (
        <ButtonStyled
          color='primary'
          disabled={!isPhoneValid || !canSendCode}
          onClick={onSendPhoneClick}
          isFetching={fetching}
        >
          <FormattedMessage id='auth.send.sms' />
          {
            !canSendCode && (
              <CircularProgress
                variant='determinate'
                value={sendingSmsTimer / SMS_TIMER_MAX * 100}
                size={24}
                color='secondary'
                sx={{
                  display: 'inline-block',
                  verticalAlign: 'bottom',
                  marginLeft: '10px',
                }}
              />
            )
          }
        </ButtonStyled>
      )}
      {
        showCodeInput && (
          <>
            <ButtonStyled
              color='primary'
              onClick={changePhone}
            >
              <FormattedMessage id='auth.change.phone'/>
            </ButtonStyled>
            <Label>
              <FormattedMessage id='auth.enter.code' />
            </Label>
            <CodeInput
              value={code}
              onChange={(e) => {
                onCodeChange(e.target.value)
                setErrors({ ...errors, code: '' })
              }}
              autoFocus
              disabled={codeFetching}
              error={!!errors.code}
              helperText={errors.code ? formatMessage({ id: errors.code }) : ''}
              inputProps={{
                onKeyDown,
              }}
            />

            <ButtonStyled
              color='primary'
              disabled={!isCodeValid || codeFetching}
              onClick={onSendCodeClick}
              isFetching={codeFetching}
            >
              <FormattedMessage id='auth.confirm' />
            </ButtonStyled>
          </>
        )
      }
    </Wrapper>
  )
}

PhoneConfirmForm.defaultProps = {
  afterSubmit: undefined,
}

PhoneConfirmForm.propTypes = {
  afterSubmit: PropTypes.func,
}

//#region SC
const Wrapper = styled.div`
  padding: 24px;
  margin-left: auto;
  margin-right: auto;
  align-items: center;
  justify-content: center;
`

const Label = styled.div`
  font-weight: 700;
  color: ${Colors.dark80};
  display: block;
  margin: 16px auto 0;
  height: 42px;
  width: 245px;
  text-transform: none;
`

const PhoneNumberInputStyled = styled.div`
  margin: 0 auto 24px;
  height: 42px;
  font-size: 14px;
  width: 300px;
  text-transform: none;
  padding-bottom: 35px;
`

const PhoneNumberInput = styled(PhoneInput)`
  display: flex;
  margin-bottom: 24px;
  width: 300px;
`

const InputStyled = styled(Input)`
  && {
    margin-bottom: 0;
  }
`

const CodeInput = styled(Input)`
  && {
  }
`

const ButtonStyled = styled(TextButton)`
  && {
    display: block;
    margin: 16px auto 0;
    height: 42px;
    font-size: 14px;
    width: 180px;
    text-transform: none;
    vertical-align: middle;
  }
  &&:hover {
    background-color: ${BUTTON_COLORS.purpleHover};
    color: #fff;
  }
`
//#endregion

import React from 'react';
import PropTypes from 'prop-types'
import {
  compose,
  mapProps,
  withStateHandlers,
  withProps,
} from 'recompose'
import Button from '@mui/material/Button';
import styled from 'styled-components';
import { FormattedMessage } from 'react-intl'
import { Colors } from 'constants/colors'
import { Paper } from '../Paper';
import pdf from '../../../../images/doctors/signature-form.pdf';
import { Upload } from './Upload';
import { Preview } from './Preview';

const LinkPdf = styled.a`
  color: #fff;
`;

const Text = styled.p`
  margin-bottom: 12px;
  font-weight: 500;
  color: ${Colors.dark80};
  font-size: 14px;
`

const DownloadButton = styled(Button)`
  && {
    margin-left: 16px;
    margin-bottom: 12px;
  }
`

const SignatureComponent = ({
  onDrop,
  images = [],
  withFilenames,
}) => (
  <Paper>
    <div>
      <Text>
        1.{' '}
        <FormattedMessage id='signature.download' />
      </Text>
      <DownloadButton variant='contained' color='primary'>
        <LinkPdf target='_blank' download href={pdf}>
          <FormattedMessage id='signature.download.button' />
        </LinkPdf>
      </DownloadButton>
    </div>
    <div>
      <Text>
        2.{' '}
        <FormattedMessage id='signature.upload' />
      </Text>
      <Upload onDrop={onDrop} />
    </div>
    <div>
      <Text>
        3.{' '}
        <FormattedMessage id='signature.check' />
      </Text>
      {
        (!!images.length && !!images[0].image) && images.map(({ image, extension, name }) => (
          <Preview
            withFilenames={withFilenames}
            file={image}
            extension={extension}
            name={name}
            key={image}
          />
        ))
      }
    </div>
  </Paper>
);

const enhance = compose(
  mapProps(props => ({
    ...props,
    images: (props.signatures || []).map(item => ({
      image: item.url,
      extension: item.extension,
      name: item.name,
    })),
  })),
  withStateHandlers(
    {
      formatError: false,
      sizeError: false,
      droppedFile: null,
    },
    {
      onDrop: (state, { createSignature, withFilenames }) => (
        acceptedFiles,
        rejectedFiles,
      ) => {
        const files = acceptedFiles;
        const errors = {
          formatError: !!rejectedFiles.length,
          sizeError:
            !rejectedFiles.length && acceptedFiles.length !== files.length,
        };

        if (!withFilenames && !errors.formatError && !errors.sizeError) {
          createSignature({ file: files });
          return errors;
        }

        return { 
          ...errors,
          droppedFile:
            !errors.formatError && !errors.sizeError && !!files.length
              ? files[0]
              : null,
        };
      },
      onDialogClose: () => () => ({ formatError: false, sizeError: false }),
      onFilenameFormClose: () => () => ({ droppedFile: null }),
      onEditClick: () => file => ({ droppedFile: file }),
    },
  ),
  withProps(props => ({
    onFilenameFormSubmit: values => props.createSignature({
      ...values,
      file: [props.droppedFile],
    }).then(() => props.onFilenameFormClose()),
  })),
);

SignatureComponent.propTypes = {
  onDrop: PropTypes.func.isRequired,
  //acceptedExtensions: PropTypes.string,
  withFilenames: PropTypes.bool.isRequired,
};

export const Signature = enhance(SignatureComponent);

import { createGlobalStyle } from 'styled-components'
import { Colors } from 'constants/colors'
import { makeScrollbar } from '../helpers/makeScrollbar'

export const MEDIUMSCREEN_MAX_WIDTH = 1540 // px
export const SMALLSCREEN_MAX_WIDTH = 1190 // px
export const XSMALLSCREEN_MAX_WIDTH = 930 // px

export const GlobalStyle = createGlobalStyle`
  body {
    margin: 0;
    padding: 0;
    font-family: Roboto, sans-serif;
  }
  
  a {
    color: ${Colors.link};
    text-decoration: none;
    font-family: Roboto, sans-serif;
    font-size: 14px;
    line-height: 1.43;
    text-align: left;
    display: inline-block;
  }

  * {
    ${makeScrollbar()}
  }
  
  @keyframes fadeIn {
    from {
      opacity: 0;
    }
    
    to {
      opacity: 1;
    }
  }
  
  @keyframes ripple {
    0% {
      transform: scale(1);
      opacity: 1;
    }
    100% {
      transform: scale(1.3);
      opacity: 0;
    }
  }
  
  @keyframes blinking {
    0% {
      transform: none;
    }
    
    50% {
      transform: scale(1.05);
    }
    
    100% {
      transform: none;
    }
  }
  
  #menu-specialization {
    .MuiMenu-paper {
      box-shadow: none;
      background-color: white;
      color: #0000008A !important; 
      z-index: 97;
      font-size: 14px !important;
      height: 50%;
    }
  
    .MuiButtonBase-root {
      font-size: 14px !important;
    
      :hover {
        background-color: #E4DFF4 !important;
      }
    }
  }
`;

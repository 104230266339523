import React, {useEffect, useState} from 'react'
import PropTypes from 'prop-types'
import styled, {css} from 'styled-components'
import AccessTime from '@mui/icons-material/AccessTime'
import KeyboardArrowRight from '@mui/icons-material/KeyboardArrowRight'
import CloseIcon from '@mui/icons-material/Close';
import Button from '@mui/material/Button'
import {Paper} from 'common/UiKit/Paper'
import {Colors} from 'constants/colors'
import {compose, lifecycle} from 'recompose'
import idx from 'idx'
import {FormattedMessage} from 'react-intl'
import {useDispatch, useSelector} from 'react-redux';
import {SecuredBckgImage} from 'common/SecuredImage';
import concilium from '../../../../../../images/icons/concilium.svg'
import HandshakeIcon from '@mui/icons-material/Handshake';
import {ActionButton} from './ActionButton'
import defaultPhoto from '../../../../../../images/panel/default-avatar.svg'
import {FullStar} from './RateModal'
import {ConfirmWithReasonModal} from '../../../../../common/Modal/ConfirmWithReasonModal';
import {refuseConsultation, fetchConsultations} from '../../../../../../redux/modules/consultations';
import {DefaultAvatar} from '../../../../../common/DefaultAvatar';
import {CONSULTATION_TYPES} from "../../../../../../constants/consultation";
import MailIn from "../../../../../../images/panel/consultation/mailIn.png";
import MailOut from "../../../../../../images/panel/consultation/mailOut.png";
import EventIcon from "@mui/icons-material/Event";
import Steto from "../../../../../../images/panel/consultation/Steto.png";
import Tooltip from "@material-ui/core/Tooltip";
import get from "lodash/get";
import AccessTimeIcon from '@mui/icons-material/AccessTime';
import axios from "axios";
import moment from 'moment-timezone'
import {dateLabelOrDay} from "../../../../../../helpers/consultations";
import {fullName} from "../../../../../../helpers/user";

const WrapButtons = styled.div`
  font-size: 14px;
`

const Wrapper = styled.div`
  padding: 16px 26px;
  display: flex;
  border-radius: 8px;
  margin-bottom: 20px;
  width: 100%;
  min-width: 900px;
  align-items: center;
  height: 75px;
  width: 60vw;
  @media screen and (max-width: 4000px) {
      //margin-left: -15vw;
  }
  @media screen and (max-width: 1800px) {
      //margin-left: -9vw;
  }
  @media screen and (max-width: 1400px) {
      //margin-left: -23vw;
  }
  background-color: #FFFFFF;
  box-shadow: rgba(17, 17, 26, 0.05) 0px 1px 0px, rgba(17, 17, 26, 0.1) 0px 0px 8px;
    ${({type}) => {
  let colors = {background: 'white' }

  if (type === 'TCA' ) colors = {...colors, color: '#FFA500'}

  //is not TCA neither TER neither TEP (ie telexpertise)
  if ( !(type === CONSULTATION_TYPES.telexpertise || type === CONSULTATION_TYPES.TER || type === 'TCA') ) colors = {...colors, color: '#01A793'}

  return `
      border: 3px solid transparent;
      border-left: 6px solid ${colors.color};
      border-right: 6px solid ${colors.color};
      &:hover{
        border: 3px solid ${colors.color};
        padding: 16px 29px;
      }
      &:last-child:hover{
        color: ${colors.color};
      }
    `
}}
`

const Content = styled.div`
  display: flex;
  align-items: center;
  text-align: left;
`

const Photo = styled(SecuredBckgImage)`
  margin-top: 20px;
  border-radius: 50%;
  height: 62px;
  width: 62px;
  border: 3px solid ${Colors.white};
  box-shadow: 0 4px 8px 0 ${Colors.azure};
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center center;
`


const DefaultPhoto = styled.img`
  border-radius: 50%;
  height: 62px;
  width: 62px;
  border: 3px solid ${Colors.white};
  box-shadow: 0 4px 8px 0 ${Colors.azure};
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center center;
`

const Separator = styled.div`
  margin-right: 10px;
  margin-left: 10px;
  height: 100px;
  width: 2px;
  background-color: #DCDCDC;
`

const ItemGlobal = styled.div`
  border-radius: 8px;
  width: 100%;
  display: grid;
  grid-template-columns: 6% 26% 17% 23% 16% 5%; // photo / (doc correspondant + Patient) / reçu envoyé le / TER TEP Cons. dernier msg / icon delete 
  gap: 2%;
  cursor: pointer;
  align-items: stretch;
  & > img:first-child{
    margin-top: auto;
    margin-bottom: auto;
  }
`

const CancelButton = styled.div`
display: flex;
justify-content: flex-end;
align-items: flex-start;
`


const PhotoStyled = styled.div`
  margin-top: auto;
  margin-bottom: auto;
`

const AdditionalMember = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: start;
`

const DivTypeConcilium = styled.div`
    margin-top: 15px;
  display: grid;
  grid-template-columns: 35% 65%;
  grid-template-rows: 50% 12%;
  &:span:last-child{
    font-size: 16px;
  }
  & > * {
    margin-top: 10px;
    display: flex;
    align-items: center;
    justify-content: start;
  }
  && {
    color: ${props => props.color};
  }
`

const Italic = styled.span`
  grid-column: 2/2;
  grid-row: 2/2;  
  @-moz-document url-prefix() {
      & {
          margin-top: -15px;
      }
  }
  && {
    font-style: italic;
    color: ${props => props.color};
   ${props => (props.font && css`
     font-style:${props.font};
  `)
}
  ${props => (props.color === '#01A793' && css`
    margin-top: -2px;
    @-moz-document url-prefix() {
      margin-top: -25px;
  }
  `)
}
  }
`

const StyledCloseIcon = styled(CloseIcon)`
  position: relative;
  border-radius: 50px;
  margin-right: 8px;
  padding: 4px;
   ${props => (props.color && css`
  && {
    color: ${props.color};
    font-size: 20px;
  }
    &:hover {
    font-size: 23px;
    transition: 0.3s;
  }
  `)
}
`


const WrapIconMsg = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  & > * {
      margin-right: auto;
      margin-left: auto;
  }
`

const WrapMessages = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: start;
  font-size: 15px;
  & > * {
      margin: 0;
  }
`
const WrapConcilium = styled.div`
  display: flex;
  justify-content: space-between;
  align-items : center;
`

const Title = styled.h4`
margin: 0;
  font-size: 14px;
  margin-top: 4px;
  margin-left: 2px;
  color: ${Colors.dark70};
  font-weight: 400;
`


const Specialization = styled.p`
  margin: 0;
  font-size: 14px;
  margin-top: 4px;
  margin-left: 2px;
  color: ${Colors.dark70};
  font-weight: 400;
`


const CardComponent = (props) => {
  const {
    gender,
    firstName,
    lastName,
    photo,
    specialization,
    specialization_type,
    status,
    timeCreation,
    date,
    urgent,
    onClick,
    isCompleted,
    reviews,
    id,
    isConcilium,
    filters,
    type,
  } = props
  const dispatch = useDispatch();
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [refuseReason, setRefuseReason] = useState('');
  const [lastMessageTime, setLastMessageTime] = useState('');
  const refuseFalse = false;
  let ConsultationTimeDAYProposed = moment.utc(date).local().format('DD/MM')
  let ConsultationTimeHourProposed = moment.utc(date).local().format('HH:mm')
  let ConsultationTimeCreationDAY = moment.utc(timeCreation).local().format('DD/MM')
  let ConsultationTimeCreationHour = moment.utc(timeCreation).local().format('HH:mm')


  const getLastMessageTime = (id) => {
    axios.get(`/api/chat?relateId=${id}&relateType=consultation`)
      .then((response) => {
        const messages = get(response, 'data.data', []);
        let dateLastMess = messages.attributes.lastMessageTime
        const ConsultationTimeLastMessageDAY = dateLabelOrDay(dateLastMess)
        let ConsultationTimeLastMessageHour = moment.utc(dateLastMess).local().format('HH:mm')
        setLastMessageTime(ConsultationTimeLastMessageDAY + ' à ' + ConsultationTimeLastMessageHour)
      })
  }

  useEffect(() => {
    getLastMessageTime(id)
  }, [refuseReason])

  return (
    <Wrapper
      type={type}
      urgent={urgent}
      onClick={onClick}
    >
      <ItemGlobal>
        {photo ? (
          <Photo src={photo || defaultPhoto}/>
        ) : (
          <DefaultPhoto src={DefaultAvatar(firstName, lastName, specialization_type)}/>
        )
        }

        <WrapConcilium>
          <AdditionalMember>
            {fullName(gender, firstName || 'Name', lastName || 'Surname')}
            <Specialization>{ specialization }</Specialization>
          </AdditionalMember>
          <Separator/>
        </WrapConcilium>

        <WrapIconMsg>
          <img src={MailOut} width="48px" height="48px"/>
          <WrapMessages>
            <p>Envoyée le</p>
            <p>{ConsultationTimeCreationDAY + ' à ' + ConsultationTimeCreationHour} </p>
          </WrapMessages>
        </WrapIconMsg>

        <div>
          {type === 'TCA' ?
            <DivTypeConcilium color={'#FFA500'}>
              <HandshakeIcon fontSize='large'/>
              <span>TC ASSISTÉE</span>
              {isCompleted && <Italic color={'#FFA500'}>Terminée </Italic>}
              {status === 'cancelled' && <Italic color={'#FFA500'}>Annulée </Italic>}
              {!isCompleted && filters && filters.filter === 'upcoming'
                &&
                <Italic font={'normal'} color={'#FFA500'}>{'Le ' + ConsultationTimeDAYProposed + ' à ' + ConsultationTimeHourProposed}  </Italic>}
            </DivTypeConcilium>
            :
            <DivTypeConcilium color={'#01A793'}>
              <img src={Steto} width="54px"/>
              <span>CONSULTATION</span>
              {isCompleted && <Italic color={'#01A793'}>Terminée </Italic>}
              {!isConcilium && status === 'cancelled' && <Italic color={'#01A793'}>Annulée </Italic>}
              {!isConcilium && !isCompleted && filters && filters.filter === 'upcoming' && <Italic font={'normal'}
                                                                                                   color={'#01A793'}>{'Le ' + ConsultationTimeDAYProposed + ' à ' + ConsultationTimeHourProposed}  </Italic>}
            </DivTypeConcilium>}

        </div>


        <WrapIconMsg>
          <Separator/>
          <WrapMessages>
            <Title>Dernier message</Title>
            <p>{lastMessageTime}</p>
          </WrapMessages>
        </WrapIconMsg>


        {(!isConcilium || type ==='TCA') && !isCompleted && filters && filters.filter === 'upcoming' && (
          <CancelButton
            variant='outlined'
            onClick={(e) => {
              e.stopPropagation();
              return setShowDeleteModal(true)
            }}
          >
            <Tooltip title='Annuler'>
              <StyledCloseIcon color={type === 'TCA' ? '#FFA500'  : '#3CE797' }/>
            </Tooltip>
          </CancelButton>
        )}

      </ItemGlobal>
      {(!isConcilium || type === 'TCA') && !isCompleted && filters && filters.filter === 'upcoming' ? (
        <>
          <ConfirmWithReasonModal
            key='cancelModal'
            onClick={e => e.stopPropagation()}
            title={<FormattedMessage id='consultation.delteconfirm.consultation'/>}
            titleProps={{
              disableTypography: true,
              style: {maxWidth: '460px', fontSize: '16px', fontWeight: '400 !important'},
            }}
            maxWidth={false}
            open={showDeleteModal}
            confirmButtonText={<WrapButtons><FormattedMessage id='consultation.delteconfirmyes'/></WrapButtons>}
            cancelButtonText={<WrapButtons><FormattedMessage id='consultation.delteconfirmno'/></WrapButtons>}
            text={<FormattedMessage id='consultation.cancelreason.consultation'/>}
            setRefuseReason={setRefuseReason}
            refuseReason={refuseReason}
            handleSubmit={(e) => {
              e.stopPropagation();
              setShowDeleteModal(false);
              return dispatch(refuseConsultation(id, refuseReason, refuseFalse))
                .then(() => dispatch(fetchConsultations(filters)))
                .catch(e => console.warn(e))
            }}
            handleClose={(e) => {
              e.stopPropagation();
              return setShowDeleteModal(false)
            }}
          />
        </>
      ) : null}
    </Wrapper>
  )
}

CardComponent.defaultProps = {
  photo: '',
  firstName: '',
  lastName: '',
  specialization: '',
  hospital: '',
  date: '',
  language: [],
  reviews: undefined,
  reviewsFetching: false,
  onRepeatClick: () => {
  },
  onRateClick: () => {
  },
}

CardComponent.propTypes = {
  firstName: PropTypes.string,
  lastName: PropTypes.string,
  specialization: PropTypes.string,
  hospital: PropTypes.string,
  photo: PropTypes.string,
  date: PropTypes.string,
  language: PropTypes.arrayOf(PropTypes.string),
  urgent: PropTypes.bool.isRequired,
  onClick: PropTypes.func.isRequired,
  isCompleted: PropTypes.bool.isRequired,
  reviews: PropTypes.array,
  reviewsFetching: PropTypes.bool,
  onRepeatClick: PropTypes.func,
  onRateClick: PropTypes.func,
  id: PropTypes.string.isRequired,
}

const enhance = compose(
  lifecycle({
    componentDidMount() {
      if (this.props.isCompleted) {
        if (!this.props.reviews) {
          this.props.completedProps.fetchDoctorReviews()
        }
      }
    },
  }),
)

export const Card = enhance(CardComponent)

import React, {useEffect, useState} from 'react'
import { Field, Form } from 'react-final-form'
import styled from 'styled-components'
import { FormattedMessage, useIntl} from 'react-intl'
import { TextField } from '../../common/UiKit/TextField'
import { NotActivatedDialog } from './NotActivatedDialog'
import {auth, pscSubscribe} from "../../../redux/modules/auth";
import {useDispatch, useSelector} from "react-redux";
import {TextButton} from "../../common/UiKit/TextButton";
import {history} from "../../../utils/history";
import {Link, useParams } from "react-router-dom";
import Isemail from "isemail";
import imageLogoPSC from "../../../../src/images/auth/ProSanteConnect_sidentifier_COULEURS.svg"
import moment from "moment-timezone";
import {BUTTON_COLORS} from "../../../constants/colors";


const FieldStyled = styled(Field)`
  background-color : none;
  margin: 15px auto;
  display: flex;
  justify-content: start;
  align-item: center;
  max-width: 450px;
    & > & > *  {
      display: flex;
      justify-content: start;
      align-item: center;
      margin: auto;
      width: 40vw;
    }
`

const GreyStyle = styled.h4`
 && {
    font-weight: 500;
    font-family: Roboto, sans-serif;
    color: #A6A39E;
    font-size: 16px;
    margin-top: 15px;
    margin-bottom: 15px;
  }
`
const ButtonLogin = styled(TextButton)`
  && {
    height: 40px;
    width: 180px;
    margin-top: 10px;
    text-transform: none;
    font-size: 14px;
    font-weight: 500;
    background-color: ${BUTTON_COLORS.purple};
    color: #fff;
  }
  &&:hover {
    background-color: ${BUTTON_COLORS.purpleHover};
    color: #fff;
  }
  &&:hover {
    background: rgb(71, 35, 178);
  }`

const Header = styled.h5`
 && {
    font-family: Roboto, sans-serif;
    color: black;
    text-align: center;
    font-size: 19px;
    margin-top: 40px;
    margin-bottom: 5px;
  }
`

const StyledLinkPassword = styled(Link)`
  margin-left :200px;
  margin-bottom : 20px;
  margin-top : -10px;
`

const Styledform = styled.form`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`

const StyledError = styled.div`
  margin-top : 30px;
  margin-bottom : 15px;
  height : 35px;
  width : 355px;
  border: 1px solid #FF3333;
  border-radius : 5px;
  background-color: #FADCE6;
  color: #FF3333;
  display: flex;
  justify-content: center;
  align-items: center;
`

const ButtonSignUp = styled(ButtonLogin)`
  && {
    margin-top:0;
    margin-bottom: 10px;
    width: 350px;
    background-color: #21C5A9;
    color: #fff;
  }
  &&:hover {
    background-color: rgb(23, 137, 118);
    color: #fff;
  }
  &&:hover {
    background: rgb(23, 137, 118);
  }
`

export const MailPasswordLogin = () => {
  let { role } = useParams();
  localStorage.setItem('userRole', role === 'doctor' ? 'ROLE_DOCTOR' : 'ROLE_PATIENT')
  localStorage.setItem('userType', role)
  const [dialogOpen, setDialogOpen] = useState(false)
  const [errorMessage, setErrormessage] = useState(false)
  const isDoctor = role === 'doctor'
  const { formatMessage } = useIntl()
  const f = id => formatMessage({ id })
  const dispatch = useDispatch()


const validate = (values) => {
    const errors = {}

    if (!values.email) {
      errors.email = <FormattedMessage id='error.email.required' />
    } else if (!Isemail.validate(values.email)) {
      errors.email = <FormattedMessage id='error.email' />
    }

    if (!values.password) {
      errors.password = <FormattedMessage id='error.password.required' />
    }

    return errors
  }

  const onSubmit = (values) => {
    return dispatch(auth({...values, role}, role === "patient"))
      .then((res) => {
        if(res?.errorMessage === 'Email ou mot de passe incorrect.')
        {
          setErrormessage(res.errorMessage)
        }
        if(res?.status === 401)
        {
          setDialogOpen(true)
        }
      })
  }


  return (
    <>
      <Form
        onSubmit={onSubmit}
        validate={validate}
        render={({ handleSubmit }) => (
          <Styledform onSubmit={handleSubmit}>
            {
              errorMessage
              &&
              <StyledError>
                {errorMessage}
              </StyledError>
            }
            <FieldStyled
              component={TextField}
              name='email'
              label={<b>{f('auth.email')}</b>}
              type='email'
              required
            />
            <FieldStyled
              component={TextField}
              name='password'
              label={<b>{f( 'auth.password')}</b>}
              password
              required
            />
            <StyledLinkPassword to={`/auth/password-recovery?user=${role}`}>
              <b>
                <FormattedMessage id='auth.forgot.password' />
              </b>
            </StyledLinkPassword>

              <ButtonLogin
                color='primary'
                variant='extended'
                type='submit'
              >
                <FormattedMessage id='auth.login' />
              </ButtonLogin>
                {
                  isDoctor && (
                    <>
                      <GreyStyle>OU</GreyStyle>
                      <a href='#' onClick={(e) => {
                        e.preventDefault()
                        dispatch(pscSubscribe())
                      }}>
                        <img src={imageLogoPSC} width='240'
                             alt="Se connecter ou s'inscrire avec Pro Santé Connect" />
                      </a>
                      <GreyStyle>
                        <FormattedMessage id='auth.slogan.identification.eCPS' />
                      </GreyStyle>
                    </>
                  )
                }
                <Header>
                  <FormattedMessage id='auth.signUp'/>
                </Header>
                <ButtonSignUp
                  color='primary'
                  variant='extended'
                  type='button'
                  onClick={() => {
                    isDoctor ? history.push( 'doctor/registration-mode') : history.push('patient/registration-mode/classic-sign-in')
                  }}
                >
                  <FormattedMessage id='auth.register.free' />
                </ButtonSignUp>
          </Styledform>
        )}
      >
      </Form>
        <NotActivatedDialog dialogOpen={dialogOpen} onCloseAction={() => setDialogOpen(false)} />
  </>
  )
}

import React, { useRef, useImperativeHandle } from 'react'
import {compose, lifecycle, withProps} from 'recompose'
import styled from 'styled-components'
import { connect } from 'react-redux';
import {clearTeams, toggleFavoriteTeam} from '../../../redux/modules/teams'
import {Colors} from "../../../constants/colors";
import {EmptyScreen} from "../../Panel/Patient/Doctors/EmptyScreen";
import {TeamCard} from "../TeamCard";
import {fetchTeams} from "../../../redux/modules/teams";
import get from "lodash/get";

const Content = styled.div`
  margin: 0 auto;
  min-width: 100%;
  overflow-y: scroll;
`

const Wrapper = styled.div`
  & > * {
    border: ${props => (props.outlined ? `2px solid ${Colors.bluePurple}` : '')};
    margin-bottom: 24px;
  }
`

export const TeamListComponent = ({
                                         teams,
                                         favoriteToggle,
                                         toggleFavoriteTeam,
                                         match,
                                         idSelected,
                                         forwardedRef,
                                         isDoctor,
                                       }) => {

  const refs = useRef({})

  useImperativeHandle(forwardedRef, () => ({
    focusRef: (id) => {
      refs.current[id].scrollIntoView({
        behavior: 'smooth',
        block: 'start',
      });
    }
  }));

  const doctorAvailability = (data) => {
    let availabilityValues = []
    data.some(function(teamMember) {
      availabilityValues.push(teamMember.attributes.status_availability)
    })
    return availabilityValues.includes(true)
  }

  return (
    <>
      {
        teams.length ? (
            <Content>
              {teams.map(({ attributes, id, relationships }) => (
                <Wrapper ref={ref => (refs.current[id] = ref)} outlined={idSelected === id} >
                  <TeamCard
                    id={id}
                    name={attributes.name}
                    members={attributes.member_count}
                    type={attributes.type}
                    isFavorite={attributes.favorite}
                    isFavoriteFetching={favoriteToggle === id}
                    toggleFavorite={value => toggleFavoriteTeam(id, value)}
                    distance={attributes.distance}
                    status_availability={doctorAvailability(get(relationships, 'members.data', []))}
                    specializations={attributes.specializations}
                    isDoctor={isDoctor}
                  />
                </Wrapper>
              ))}
            </Content>
          ) :
          <EmptyScreen/>
      }
    </>
  )
}

const enhance = compose(
  connect(
    (state, props) => ({
      teams: state.teams.list,
      favoriteToggle: state.loading.favoriteToggle,
      isDoctor: state.auth.type === 'Doctor',
    }),
    {
      fetchTeams,
      clearTeams,
      toggleFavoriteTeam,
    },
    null,
    { forwardRef: true }
  ),
  withProps(props => ({
  })),
  lifecycle({
    componentWillMount() {
      this.props.clearTeams()
    },
  }),
)

export const TeamList = enhance(TeamListComponent)

export const TeamListWithRef = React.forwardRef(({...props}, ref) => <TeamList {...props} forwardedRef={ref} />)

import React, {
  useEffect, useMemo, useRef, useState, 
} from 'react';
import { Dialog, DialogContent, DialogTitle } from '@mui/material';
import { FormattedMessage, useIntl } from 'react-intl';
import { Field, Form } from 'react-final-form';
import styled from 'styled-components'
import { TextButton } from 'common/UiKit/TextButton';
import moment from 'moment-timezone'
import { useDispatch } from 'react-redux';
import get from 'lodash/get';
import Tooltip from '@mui/material/Tooltip';
import HelpIcon from '@mui/icons-material/Help';
import isEqual from 'lodash/isEqual';
import last from 'lodash/last';
import CloseIcon from '@mui/icons-material/Close';
import { TextField } from '../../../../common/FinalFormFields';
import {
  generateTimePeriods, transformTime,
} from '../../../../../helpers/calendar';
import {addRepeat, editEvent, editRepeat} from '../../../../../redux/modules/calendar/list';
import { CheckboxFieldV2 } from '../../../../common/CheckboxFieldV2';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { DesktopDatePicker } from '@mui/x-date-pickers/DesktopDatePicker';
import TextFieldMui from '@mui/material/TextField';
import {calendarColors, white} from "../../../../../constants/colors";

const FieldWrapper = styled.div`
  display: flex;
  align-items: center;
   margin-bottom: 20px;
  
    & > *:first-child {
      margin-right: 12px;
      margin-bottom: 0;
    }
    
    & > *:last-child {
      margin-left: 12px;
      margin-bottom: 0;
    }
`

const Button = styled(TextButton)`
  && {
    margin-top: 10px;
    width: 100%;
    font-size: 14px;
    font-weight: 500;
    border-radius: 24px;
    color: ${white};
    background-color: ${calendarColors.purpleConex};
    text-transform: none;
  }
  &&:hover {
    background-color: ${calendarColors.hoverCancel};
    color: ${white};
  }
`

const Title = styled.div`
  font-size: 20px;
  font-weight: 500;
  line-height: 1.55;
  color:${calendarColors.purpleConex};
  margin-right: 20px;
`

const Label = styled.div`
  opacity: 0.8;
  font-size: 12px;
  margin-bottom: 12px;
`

const Checkbox = styled(CheckboxFieldV2)`
`

const RepeatSettings = styled.div`
  width: 100%;
`

const StyledDateField = styled(Field)`
  width: 40% !important;
  margin-top: 5%;
  margin-right: 5%;
  margin-right: 5%;
  justify-content:center;
  align-items: center;
`

const RepeatDaysLabel = styled.div`
  opacity: 0.8;
  font-size: 12px;
  margin-bottom: 12px;
  align-items: center;
  display: flex;
`

const CloseDialog = styled(CloseIcon)`
    position: absolute;
    top: 10px;
    right: 10px;
    cursor: pointer;
`

const ErrorDay = styled.div`
    font-size: 0.8em;
    color: ${calendarColors.error};
    margin-left: 5%;
    margin-top: 1%;
`
const FlexDiv = styled.div`
  display:flex;
  align-items: center;
`


export const EventDialog = ({
  open, onClose, data, doctorId
}) => {
  const periods = useMemo(() => generateTimePeriods(), [])
  const resetForm = useRef(null)
  const dispatch = useDispatch()
  const [errorsState, setErrors] = useState({})
  const [initialChange, setInitialChange] = useState(false)
  const [dateFromValue, setDateFromValue] = useState(null);
  const [dateToValue, setDateToValue] = useState(null);
  const [dateDayValue, setDateDayValue] = useState(null);
  const [maxDate, setMaxDate] = useState(null);
  const [minDate, setMinDate] = useState(null);

  const onChangeFromDate = (newValue) => {
    setDateFromValue(newValue)
  }
  const onChangeToDate = (newValue) => {
    setDateToValue(newValue)
  }

  const onChangeDayDate = (newValue) => {
    setDateDayValue(newValue)
  }

  if(data){
    if(!initialChange){
      setInitialChange(true)
      if(data.repeatId){
        setDateFromValue(data.dateFrom)
        setDateToValue(data.dateTo)
        const currentMaxDate = new Date(data.dateTo);
        currentMaxDate.setFullYear(currentMaxDate.getFullYear()+1)
        setMaxDate(currentMaxDate)
        setMinDate(data.dateFrom)
      }
      else{
        if(data.day){
          setDateFromValue(data.day)
          setDateToValue(data.day)
          const currentMaxDate = new Date(data.day);
          currentMaxDate.setFullYear(currentMaxDate.getFullYear()+1)
          setMaxDate(currentMaxDate)
          setMinDate(data.day)
        }
        else{
          setMinDate(new Date());
        }
      }
    }
  }

  const { formatMessage } = useIntl()
  const f = id => formatMessage({ id })

  useEffect(() => {
    if (resetForm.current) {
      resetForm.current()
    }
  }, [data])

  const normalizeArray = (elms = []) => (
    elms.filter(el => !!el && !!el[0]).map(el => el[0])
  )

  const onSubmit = (values) => {
    const fromIndex = periods.findIndex(i => i.from === values.from)
    let toIndex = periods.findIndex(i => i.from === values.to)
    if (toIndex === -1) {
      toIndex = periods.length;
    }

    const dateDayFormatted = dateDayValue ? moment(dateDayValue, null).format('yyyy-MM-DD') : moment(new Date(),null).format('yyyy-MM-DD');
    const days = periods.slice(fromIndex, toIndex).map(i => `${data.day ?data.day :  dateDayFormatted} ${i.from.length < 5 ? `0${i.from}` : i.from}`)
    const selectedFrom = data.day ? data.day + ' ' + values.from : new Date().toISOString().split('T')[0]+ ' ' + values.from
    const selectedTo = data.day ? data.day  + ' ' + values.to : new Date().toISOString().split('T')[0]+ ' ' + values.to
    const fromUtc = moment(selectedFrom,null).tz(Intl.DateTimeFormat().resolvedOptions().timeZone).utc().format('HH:mm')
    const toUtc = moment(selectedTo ,null).tz(Intl.DateTimeFormat().resolvedOptions().timeZone).utc().format('HH:mm')

    if (values.repeat === true) {
      const dateRepeatFromFormatted = moment(dateFromValue, null).format('yyyy-MM-DD')
      const dateRepeatToFormatted =   moment(dateToValue, null).format('yyyy-MM-DD')
      if (data && data.repeatId) {
        return dispatch(editRepeat({
          repeatId: data.repeatId,
          te_available: values.te_available ? 1 : 0,
          tc_available: values.tc_available ? 1 : 0,
          tca_available: values.tca_available ? 1 : 0,
          type: values.type,
          from: values.from,
          to: values.to,
          dateRepeatFrom: dateRepeatFromFormatted,
          dateRepeatTo: dateRepeatToFormatted,
          days: [data.dayNumber],
          doctor_id : doctorId
        }))
          .then(() => {
            onClose("edit repeat")
          })
      }
      dispatch(addRepeat({
        te_available: values.te_available ? 1 : 0,
        tc_available: values.tc_available ? 1 : 0,
        tca_available: values.tca_available ? 1 : 0,
        type: values.type,
        from: fromUtc,
        to: toUtc,
        dateRepeatFrom: dateRepeatFromFormatted,
        dateRepeatTo: dateRepeatToFormatted,
        days: normalizeArray(values.days),
        doctorId : doctorId
      }))
        .then(() => {
          onClose("add repeat");
        })
    }
    else{
      return dispatch(
          editEvent(
              days ? days : days.push(dateDayValue),
              null,
              values.type,
              null,
              {
                te_available: values.te_available ? 1 : 0,
                tc_available: values.tc_available ? 1 : 0,
                tca_available: values.tca_available ? 1 : 0,
              },
              doctorId
          ),
      )
          .then(() => onClose("added"))
    }
  }

  const renderCheckBoxes = (day) => {
    const days = [1, 2, 3, 4, 5, 6, 7]

    return days.map((el, i) => {
      const textDay = moment().day(el).format('dddd');

      return (
      
        <Field
          {...(day === el ? { checked: true } : {})}
          disabled={!!day}
          key={textDay}
          name={`days[${el}]`}
          component={Checkbox}
          //checked={true}
          color='primary'
          type='checkbox'
          {...(day !== el ? { value: el } : {})}
          label={textDay}
        />
       
      ) 
    })
  }

  const validate = (values) => {
    const errors = {};
    const day = data.day ?data.day :  dateDayValue
    if(day && values.from){
      const dayFormatted = typeof day === "string" ? new Date(day + " " + values.from) : day
      const hoursMinutes = values.from.split(":")
      dayFormatted.setHours(hoursMinutes[0],hoursMinutes[1])
        if(dayFormatted < new Date()){
          errors.dateRequired = f('schedule.create.past')
        }
      }
    const repeatMode = get(values, 'repeat', false);
    if(!repeatMode && (!dateDayValue && (data !== null && !data.day))){
      errors.dateRequired = f('schedule.day.required')
    }
    if (
      !values.te_available && !values.tc_available && !values.tca_available
    ) {
      errors.tca_available = f('schedule.oneoftyperequired');
    }

    if (repeatMode) {
      const days = normalizeArray(values.days);
      if (!Array.isArray(days) || days.filter(el => !!el).length === 0) {
        if (data && !data.repeatId) {
          errors.daysError = f('schedule.onedayrequired');
        }
      }
    }

    if (repeatMode && values.dateRepeatTo < values.dateRepeatFrom) {
      errors.dateRepeatTo = f('schedule.error.tobeforefrom');
    }

    if (!isEqual(errors, errorsState)) {
      setErrors(errors);
    }

    return errors;
  }

  const getLastTime = periods => moment(last(periods).from, 'HH:mm').add(10, 'minutes').format('HH:mm')


  return (
    <Dialog open={open} maxWidth='xs'>
      <CloseDialog onClick={() => onClose()} />
      <DialogTitle>
        <Title>
          <FormattedMessage id='schedule.event.title' />
            <br/>
          {data !== null
            && data.day && (
            moment(data.day).local().format(' DD MMMM YYYY')
          )}
        </Title>
      </DialogTitle>
      <DialogContent>
        <Form
          onSubmit={onSubmit}
          validate={validate}
          initialValues={data ? {
            ...(data && data.repeatId ? { 
              dateRepeatTo: data && moment(data.day),
              dateRepeatFrom: data && moment(data.day),
              repeat: true,
            } : {}),
            te_available: data && data.te_available ? true : undefined,
            tc_available: data && data.tc_available ? true : undefined,
            tca_available: data && data.tca_available ? true : undefined,
            type: 'Available',
            from: data.from ? transformTime(data.from) : transformTime(data.periodFrom),
            to: data.to ? transformTime(data.to) : transformTime((periods[periods
              .findIndex(i => i.from === transformTime(data.periodFrom)) + 1]
              || { from: getLastTime(periods) }).from),
          } : { type: 'Available' }}
          destroyOnUnregister
          render={({
            handleSubmit, values, submitting, form,
          }) => {
            resetForm.current = form.reset
            const toOptions = periods.slice(values.from
              ? periods.findIndex(i => i.from === values.from) + 1
              : 0)
            const showRepeatSettings = get(values, 'repeat', false);
            const formState = form.getState();
            const showDayField = get(values, 'day', false);
            return (
              <form onSubmit={handleSubmit}>
                  {
                      data !== null && !data.day && !data.repeatId && (
                          <React.StrictMode>
                            <FlexDiv>
                              <StyledDateField
                                  name='dateRepeatTo'
                                  label={f('schedule.create.day.choose')}
                              >
                                {
                                  props => (
                                      <LocalizationProvider dateAdapter={AdapterDateFns}>
                                        <DesktopDatePicker
                                            {...props}
                                            inputFormat='dd/MM/yyyy'
                                            value={dateDayValue}
                                            onChange={onChangeDayDate}
                                            disableToolbar
                                            disablePast
                                            required
                                            renderInput={(params) => <TextFieldMui {...params}/>}
                                        />
                                      </LocalizationProvider>
                                  )
                                }
                              </StyledDateField>
                            </FlexDiv>
                          </React.StrictMode>
                      )
                  }
                { data !== null && !data.day && !data.repeatId && formState.submitFailed ? (
                    <ErrorDay>
                      {errorsState.dateRequired}
                    </ErrorDay>
                ) : ''}
                <Field
                  name='te_available'
                  component={Checkbox}
                  color='primary'
                  type='checkbox'
                  label={(<FormattedMessage id='schedule.time.off' />)}
                />
                <br />
                <Field
                  name='tc_available'
                  component={Checkbox}
                  color='primary'
                  type='checkbox'
                  label={(<FormattedMessage id='schedule.teleconsultation' />)}
                />
                <br />
                <Field
                  name='tca_available'
                  component={Checkbox}
                  color='primary'
                  type='checkbox'
                  label={(<FormattedMessage id='schedule.tca' />)}
                />
                <br />
                <Label>
                  <FormattedMessage id='schedule.event.time' />
                </Label>
                <FieldWrapper>
                  <Field
                    name='from'
                    component={TextField}
                    label={(<FormattedMessage id='schedule.create.hour.from' />)}
                    options={periods
                      .map(item => ({ label: item.from, value: item.from }))}
                  />
                  {' - '}
                  <Field
                    name='to'
                    component={TextField}
                    label={(<FormattedMessage id='schedule.create.hour.to' />)}
                    options={[...(toOptions && toOptions.length
                      ? toOptions : [{ from: getLastTime(periods) }])]
                      .map(item => ({ label: item.from, value: item.from }))}
                  />
                </FieldWrapper>

                <Field
                  name='repeat'
                  component={Checkbox}
                  color='primary'
                  type='checkbox'
                  disabled={data && data.repeatId}
                  label={(<FormattedMessage id='schedule.event.repeat' />)}
                />

                {
                  showRepeatSettings 
                    ? (
                      <RepeatSettings>
                        <RepeatDaysLabel>
                          <FormattedMessage id='schedule.editrepeats' />
                          <Tooltip title={f('schedule.repeatinfo')} placement='bottom-end'>
                            <HelpIcon size='small' />
                          </Tooltip>
                        </RepeatDaysLabel>
                        <div style={errorsState.daysError && formState.submitFailed ? { border: '1px solid red' } : {}}>
                          {renderCheckBoxes(data && data.dayNumber)}
                        </div>
                        {formState.submitFailed ? (
                          <div style={{ color: 'red' }}>
                            {errorsState.daysError}
                          </div>
                        ) : ''}
                        <React.StrictMode>
                          <StyledDateField
                            name='dateRepeatFrom'
                            label={f('schedule.daterepeatfrom')}
                          >
                            {
                          props => (
                            
                            <LocalizationProvider dateAdapter={AdapterDateFns}>
                              <DesktopDatePicker
                                  {...props}
                                  name={props.input.name}
                                  onChange={onChangeFromDate}
                                  inputFormat='dd/MM/yyyy'
                                  value={dateFromValue}
                                  minDate={minDate}
                                  maxDate={maxDate}
                                  disableToolbar
                                  renderInput={(params) => <TextFieldMui {...params} />}
                              />
                            </LocalizationProvider>
                          )
                        }
                          </StyledDateField>
                        </React.StrictMode>
                        <React.StrictMode>
                          <StyledDateField
                            name='dateRepeatTo'
                            label={f('schedule.daterepeatto')}
                          >
                            {
                          props => (
                            <LocalizationProvider dateAdapter={AdapterDateFns}>
                              <DesktopDatePicker
                                  {...props}
                                  name={props.input.name}
                                  onChange={onChangeToDate}
                                  inputFormat='dd/MM/yyyy'
                                  value={dateToValue}
                                  minDate={dateFromValue}
                                  maxDate={maxDate}
                                  disableToolbar
                                  renderInput={(params) => <TextFieldMui {...params} />}
                              />
                            </LocalizationProvider>
                          )
                        }
                          </StyledDateField>
                        </React.StrictMode>
                      </RepeatSettings>
                    )
                    : ''
                }

                <Button color='primary' type='submit' disabled={submitting} isFetching={submitting}>
                  <FormattedMessage id='schedule.event.add' />
                </Button>
              </form>
            )
          }}
        />
      </DialogContent>
    </Dialog>
  )
}

import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import { Colors } from 'constants/colors'
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline'
import WarningIcon from '@mui/icons-material/Warning'

const CompatibleText = styled.div`
  width: 130px;
  position: absolute;
  left: calc(100% - 135px);
  top: 0;
  font-size: 11px;

  svg {
    font-size: 19px;
  }
`

const Heading = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  color: ${Colors.blueGrey};
`

const MediumWarningIcon = styled(WarningIcon)`
  color: ${Colors.yellowOchre};
`

const HelperText = styled.div`
  line-heidht: 1.82;
  color: ${props => (props.hight ? Colors.red : Colors.yellowOchre)};
  font-weight: normal;
`

export const ProfileCompabilityField = ({ id, checkedDrugs }) => {
  if (!checkedDrugs[id]) return null

  const hightIncompability = checkedDrugs[id].find(d => d.attributes.type === 'HIGH')
  const mediumIncompaility = checkedDrugs[id].find(d => d.attributes.type === 'MEDIUM')

  if (hightIncompability) {
    return (
      <CompatibleText>
        <Heading>
          NOT COMPATIBLE&nbsp;
          <WarningIcon color='error' />
        </Heading>
        <HelperText hight>{hightIncompability.attributes.symptomName}</HelperText>
      </CompatibleText>
    )
  }

  if (mediumIncompaility) {
    return (
      <CompatibleText>
        <Heading>
          WITH LIMITATIONS&nbsp;
          <MediumWarningIcon />
        </Heading>
        <HelperText>{mediumIncompaility.attributes.symptomName}</HelperText>
      </CompatibleText>
    )
  }

  return (
    <CompatibleText>
      <Heading>
        FULLY COMPATIBLE&nbsp;
        <CheckCircleOutlineIcon color='primary' />
      </Heading>
    </CompatibleText>
  )
}

ProfileCompabilityField.propTypes = {
  id: PropTypes.string.isRequired,
  checkedDrugs: PropTypes.objectOf(PropTypes.array).isRequired,
}

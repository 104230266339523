import React from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import { FormattedMessage } from 'react-intl';
import TextField from '@mui/material/TextField';
import styled from 'styled-components'

const ReasonField = styled(TextField)`
  width: 100%;
  textarea {
    height: auto;
    padding: 8px;
  }

  p {
    text-align: right;
  }
`
const HeadingText = styled(DialogTitle)` 
    opacity: 0.9;
    font-size: 20px;
    font-weight: 500;
    line-height: 1.2;
    color: #21293e;
    padding-bottom: 25px !important;
`

const DescriptionText = styled.div`
  opacity: 0.6;
  font-size: 16px;
  line-height: 1.5;
  color: #21293e;
`

const StyledContent = styled(DialogContent)`
  max-width: 460px;
  padding-bottom: 0px !important;
`

const Buttons = styled(DialogActions)`
  padding-top: 0px !important;
`

export const ConfirmWithReasonModal = ({
  open, handleSubmit, handleClose, title = 'Confirm ?', text = '',
  confirmButtonText, cancelButtonText, titleProps = {}, setRefuseReason,
  refuseReason,
  ...rest
}) => (
  <Dialog
    open={open}
    onClose={handleClose}
    aria-labelledby='alert-dialog-title'
    aria-describedby='alert-dialog-description'
    {...rest}
  >
    <HeadingText {...titleProps} id='alert-dialog-title'>{title}</HeadingText>
    <StyledContent>
      <DialogContentText id='alert-dialog-description'>
        <DescriptionText>
          {text}
        </DescriptionText>
        <ReasonField
          variant='standard'
          onChange={e => setRefuseReason(e.currentTarget.value)}
          label={<FormattedMessage id='global.reason' />}
          name='Reason'
          inputProps={{ maxLength: 150 }}
          helperText={`${refuseReason.length}/150`}
          multiline
          maxRows={6}
        />
      </DialogContentText>
    </StyledContent>
    <Buttons>
      <Button onClick={handleClose} color='secondary'>
        {cancelButtonText || <FormattedMessage id='global.cancel' />}
      </Button>
      <Button onClick={handleSubmit} color='primary' autoFocus>
        {confirmButtonText || <FormattedMessage id='global.continue' />}
      </Button>
    </Buttons>
  </Dialog>
)

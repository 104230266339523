import React, {useEffect, useState} from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { EditPage } from 'common/UiKit/EditPage'
import { Paper } from 'common/UiKit/Paper'
import styled from 'styled-components'
import { TextField } from 'common/FinalFormFields/TextField'
import { TextButton } from 'common/UiKit/TextButton'
import { FormattedMessage } from 'react-intl'
import Isemail from 'isemail';
import DeleteIcon from '@mui/icons-material/Delete';
import { history } from '../../../../../utils/history'
import {editMSS} from "../../../../../redux/modules/auth";
import idx from "idx";
import { editEmails, deleteEmail } from '../../../../../redux/modules/auth';
import {BUTTON_COLORS} from "../../../../../constants/colors";

const Wrapper = styled(Paper)`
  margin-top: 24px;
  margin-bottom: 16px;
  border-radius: 4px;
  box-shadow: none;
  background-color: #ffffff;
  border-bottom: 0;
  padding: 0;
`

const Title = styled.div` 
  font-size: 16px;
  font-weight: 500;
  line-height: 1.13;
  color: #757f99;
  border-bottom: 1px solid rgba(6, 173, 255, 0.1);
  padding-bottom: 18px;
  padding-top: 21px;
  padding-left: 24px;
`

const MiniTitle = styled.div`
  font-size: 14px;
  font-weight: 500;
  color: rgba(33, 41, 62, 0.5);
  padding-bottom: 8px;
`

const EmailChangeContainer = styled.div`
  padding-left: 23px;
  padding-right: 23px;
  padding-top: 18px;
  padding-bottom: 105px;
`

const AddButton = styled(TextButton)`
    && {
      text-transform: none;
      font-weight: 500;
      width: 161px !important;
      height: 38px !important;
      background-color: ${BUTTON_COLORS.purple};
      border: 1px solid #fff;
      color: #fff;
      font-size: 14px;
    }
    &&:hover {
      background-color: ${BUTTON_COLORS.purpleHover};
      color: #fff;
    }
`

const SectionEmailFirst = styled.div`
    margin-bottom: 7px;
`

const SectionEmail = styled.div`
    margin-bottom: 18px;
`

const EmailLine = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 18px;
`

const StyledDeleteIcon = styled(DeleteIcon)`
  && {
    color: #b7bed2;
    cursor: pointer;
    margin-left: 10px;
  }
`

const StyledAdditionalEmailField = styled(TextField)`
  && {
    margin-bottom: 0;
  }
`

const validate = (check) => {
  if (typeof check === 'string' && check !== "") {
    return (!Isemail.validate(check)) ? 'Format incorrect' : false;
  }
  return false;
}

export const EmailManagement = () => {
  const user = useSelector(state => state.auth.attributes);
  const dispatch = useDispatch();
  const mssante = useSelector( state => idx(state, _ => _.auth.relationships.mss.data));
  const [newListMSS, setNewListMSS] = useState([]);
  const [mss, setMss] = useState({id: '', address: '', typeAddress: 'MSS', user_id: user.user_id});
  const [apicrypt, setApicrypt] = useState({id: '', address: '', typeAddress: 'APICRYPT', user_id: user.user_id})

  useEffect(() => {
    // If apicrypt and mss adresses already exist we set them with useState hooks
    if (mssante && Array.isArray(mssante)) {

      const elMSS = mssante.find(el => el.attributes.typeAddress === 'MSS')

      if (elMSS) {
        setMss(elMSS.attributes)
      }
    }
  }, [mssante])

  useEffect(() => {
    if (newListMSS && newListMSS.length > 0) {
      const indexOfError = newListMSS.findIndex(el => validate(el.address));
      const isAllValid = indexOfError === -1;
      if (isAllValid) {
        dispatch(editMSS(newListMSS))
            .then(() => {
              history.push('/panel/profile')
            })
            .catch((err) => {
              if (err.response.data
                  && err.response.data.data
                  && err.response.data.data.trace
                  && err.response.data.data.trace.length
                  && err.response.data.data.trace[0].message === 'user.email.exist'
              ) {
                setErrorExistsError(true)
                setTimeout(() => {
                  setErrorExistsError(false)
                }, 5000);
              }
            });
      }
    }
  }, [newListMSS])

  const [emailExistsError, setErrorExistsError] = useState(false);

  const addNewMSSAddresses = (values) => {
    setNewListMSS(newListMSS => [...newListMSS, ...values]);
  }

  return (
    <EditPage
      label='profile.email.management'
      buttonText='global.save'
      onBackIconClick={() => history.goBack()}
      background='grey'
      onButtonClick={() => {
        const array = [];
        // If the mail address is correct and the mail address or the id is defined we push the element in the array
        // because it means there is changes to process in the back
        ((mss.address || mss.id) && validate(mss.address) !== 'Email is wrong') && array.push(mss);
        addNewMSSAddresses(array);
      }}
    >
      <Wrapper>
        <Title>
          Email
        </Title>
        <EmailChangeContainer>
          <SectionEmailFirst>
            <MiniTitle>
              <FormattedMessage id='profile.email.type.mss' />
            </MiniTitle>
            <TextField 
              onChange={e => setMss({ ...mss, address: e.target.value})}
              defaultValue={mss?.address}
              value={mss?.address}
              meta={{ touched: true }}
              error={validate(mss?.address) || emailExistsError}
              errorMessage={validate(mss?.address) || (emailExistsError && 'This email is already used')}
            />
          </SectionEmailFirst>
        </EmailChangeContainer>
      </Wrapper>
    </EditPage>
  ) 
}

/* eslint-disable */
import React from 'react'
import PropTypes from 'prop-types'
import { compose, withStateHandlers } from 'recompose'
import styled from 'styled-components'
import { SecuredImage, SecuredPdf } from 'common/SecuredImage';
//import { Document, Page, pdfjs } from 'react-pdf'
//import { readFileType } from '../../utils/readFileType'

// Enable PDF.js worker for perfomance reason. Watch https://github.com/wojtekmaj/react-pdf#enable-pdfjs-worker
//pdfjs.GlobalWorkerOptions.workerSrc = 
//`//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`

const ImageWrapper = styled.div`
  box-sizing: border-box;
  height: 100%;
  width: calc(100% - 96px * 2);
  box-sizing: border-box;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 24px;
`

const Image = styled(SecuredImage)`
  max-width: 100%;
  max-height: 100%;
`

// Pdf file doesn't understand percents
// const Pdf = styled(Document)`
//   && :first-child {
//     max-width: calc(100vw - 192px - 24px - 48px);
//     max-height: calc(100vh - 56px - 48px);
//   }
// `

const PhotoComponent = ({
  file,
  fileType,
  //resetPdfSize,
  setWrapperRef,
}) => (
  <ImageWrapper ref={setWrapperRef}>
    {/.*\.pdf$/.test(file) ? 
    <SecuredPdf src={file} />
    : <Image alt='img' src={file} />}
    {
      //fileType === 'application/pdf'
        //? <>
          //<Pdf file={file}>
            //<Page
              //renderTextLayer={false}
              //pageNumber={1}
              //onLoadSuccess={resetPdfSize}
            ///>
          //</Pdf>
        //</>
        //: <Image alt='img' src={file} />
      //<Image alt='img' src={file} />
    }
  </ImageWrapper>
)

// Is needed to override width and heigth passed to Pdf through ReactElement.style
const resetNodeSize = (node) => {
  const pages = node.querySelectorAll('canvas')
  pages.forEach((page) => {
    page.style.height = 'auto'
    page.style.width = 'auto'
  })
}

const enhance = compose(
  withStateHandlers({
    wrapperRef: null,
  }, {
    setWrapperRef: () => wrapperRef => ({ wrapperRef }),
    resetPdfSize: ({ wrapperRef }) => () => resetNodeSize(wrapperRef),
  }),
  //lifecycle({
  //componentDidUpdate(prevProps) {
  //if (prevProps.file !== this.props.file) {
  //readFileType(this.props.file).then(fileType => this.setState({ fileType }))
  //}
  //},
  //}),
)

PhotoComponent.defaultProps = {
  fileType: 'jpg',
}

PhotoComponent.propTypes = {
  file: PropTypes.string.isRequired,
  fileType: PropTypes.string,
  resetPdfSize: PropTypes.func.isRequired,
  setWrapperRef: PropTypes.func.isRequired,
}

export const Photo = enhance(PhotoComponent)
/* eslint-disable */
//#region Actions
import {createAction, handleActions} from "redux-actions";
import axios from "axios";
import {combineReducers} from "redux";

export const fetchMedicalOfficeSofwaresListRequest = createAction('FETCH_MEDICAL_OFFICE_SOFTWARE_LIST_REQUEST')
export const fetchMedicalOfficeSofwaresListSuccess = createAction('FETCH_MEDICAL_OFFICE_SOFTWARE_LIST_SUCCESS')
export const fetchMedicalOfficeSofwaresListFailure = createAction('FETCH_MEDICAL_OFFICE_SOFTWARE_LIST_FAILURE')

export const fetchMedicalOfficeSoftwares = params => (dispatch) => {
  dispatch(fetchMedicalOfficeSofwaresListRequest(params))

  return axios.get(`/medical-office-software/softwares`)
    .then((res) => {
       dispatch(fetchMedicalOfficeSofwaresListSuccess(res.data.data))
    })
    .catch((err) => {
      dispatch(fetchMedicalOfficeSofwaresListFailure(err))
    })
}
//#endregion

//#region Reducers
const list = handleActions({
  [fetchMedicalOfficeSofwaresListSuccess]: (state, action) => action.payload
}, null)

export const medicalOfficeSoftwares = combineReducers({
  list
})
//#endregion

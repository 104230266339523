import React, {useState} from "react";
import {Paper} from "../../common/UiKit/Paper";
import {Colors} from "../../../constants/colors";
import {Avatar} from "../../common/Avatar";
import {Groups, KeyboardArrowDown, KeyboardArrowUp} from "@mui/icons-material";
import TagsList from "../../common/TagsList";
import styled from "styled-components";
import {SMALLSCREEN_MAX_WIDTH} from "../../../constants/globalStyles";
import {TeamLogo} from "./TeamLogo";
import {FormattedMessage} from "react-intl";

export const DoctorCardSimple = ({ doctor }) => {
  const [doctorDetails, setDoctorDetails] = useState(false)
  const name = doctor.first_name + ' ' + doctor.last_name
  const specialization = doctor.specialization
  const photo = doctor.photo || null
  const available = doctor.status_availability || false
  const doctorSkills = doctor?.skills.map(skill => skill.name)

  return (
    <>
      <Card target='_blank' href={'/panel/concilium/specializations/doctor/' + doctor.doctor_id}>
        <Paper papperRootProps={{style: { width: '100%' }}} style={{ padding: '10px 7px' }} hoverColor={Colors.link}>
          <Avatar
            image={photo}
            isDoctor={true}
            small={false}
            status={available}
          />
          <div style={{ float: 'right', color: 'black' }} onClick={(e) => {
            setDoctorDetails(!doctorDetails)
            e.stopPropagation()
            e.preventDefault()
          }}>
            { !!doctorSkills && doctorSkills.length > 0 ?
              doctorDetails ? <KeyboardArrowUp /> : <KeyboardArrowDown /> :
              ''
            }
          </div>
          <div style={{ paddingLeft: '75px' }}>
            <span style={{ fontWeight: 'bold', display: 'inline-block', padding: '5px' }}>{name}</span><br/>
            <span style={{ fontSize: '0.9em', display: 'inline-block', padding: '5px' }} >{specialization}</span>
          </div>
          <div style={{ clear: 'both', display: doctorDetails ? 'block' : 'none' }}>
            {
              !!doctorSkills && doctorSkills.length > 0 && (
                <>
                  <span style={{ fontWeight: 'bold', display: 'inline-block', padding: '5px' }}>
                    <FormattedMessage id='roso.team.skills.domains' />
                  </span><br/>
                  <div style={{ marginLeft: '10px' }}>
                    <TagsList tags={doctorSkills} wrapperStyle={{ margin: '0' }} />
                  </div>
                </>
              )
            }
          </div>
        </Paper>
      </Card>
    </>
  )
}

export const TeamCard = ({ team, onCardClick }) => (
  <Card onClick={() => onCardClick(team)}>
    <Paper
      papperRootProps={{style: { width: '100%' }}}
      contentWrapperStyle={{ height: '100%' }}
      style={{ padding: '10px 7px' }}
      hoverColor={Colors.link}
    >
      <div>
        <div>
          <TeamLogo teamType={team?.type} style={{ float: 'left' }} />
          <div style={{ float: 'right' }}>
            <Groups sx={{ verticalAlign: 'middle' }} /> { team.member_count }
          </div>
        </div>
        <div style={{ paddingLeft: '75px' }}>
          <span style={{ fontWeight: 'bold', display: 'inline-block', padding: '5px' }}>{team.name}</span><br/>
          {
            !!team.specializations && team.specializations.map((specialization, key) => (
              <span key={key} style={{ fontSize: '0.9em', display: 'inline-block', padding: '5px' }} >
            {specialization.name}
          </span>
            ))
          }
        </div>
        <div style={{clear: 'both'}}></div>
      </div>
    </Paper>
  </Card>
)

// --------------------------------- Styles CSS -------------------------------------

export const Card = styled.a`
  display: flex;
  min-width: 300px;
  flex: 30%;
  flex-grow: 1;
  padding: 5px;
  position: relative;

  @media (max-width: ${SMALLSCREEN_MAX_WIDTH}px) {
    min-width: 0;
    max-width: 100%;
  }
  
  & > :hover {
    cursor: pointer;
  }
`

import React, {
  useEffect, useMemo, useRef, useState, 
} from 'react';
import { Dialog, DialogContent, DialogTitle } from '@mui/material';
import { FormattedMessage, useIntl } from 'react-intl';
import { Field, Form } from 'react-final-form';
import styled from 'styled-components'
import { TextButton } from 'common/UiKit/TextButton';
import moment from 'moment-timezone'
import { useDispatch } from 'react-redux';
import isEqual from 'lodash/isEqual';
import last from 'lodash/last';
import { TextField } from '../../../../common/FinalFormFields';
import {generateTimePeriods, transformTime } from '../../../../../helpers/calendar';
import {addRepeat, deleteEvent, editEvent} from '../../../../../redux/modules/calendar/list';
import { ConfirmModal } from '../../../../common/Modal/ConfirmModal';
import {calendarColors, white} from "../../../../../constants/colors";

const FieldWrapper = styled.div`
  display: flex;
  align-items: center;
`

const Button = styled(TextButton)`
  && {
    width: 100%;
    font-size: 14px;
    font-weight: 500;
  }
`

const Title = styled.div`
  font-size: 20px;
  font-weight: 500;
  line-height: 1.55;
  color: ${calendarColors.purpleConex};
`

const RemoveButton = styled(Button)`
  && {
    background-color: red;
    margin-top: 15px;
    color: ${white};
    text-transform: none;
  }

  &&:hover {
    background-color: ${calendarColors.hoverDelete};
  }
`

const StyledDateField = styled(Field)`
  width: 100% !important;
  margin-top: 5%;
  margin-right: 5%;
  margin-left:5%;
  justify-content:center;
`

const DialogDelete = styled(Dialog)`
  backdrop-filter: blur(3px);
  & > * {
      margin : auto;
      text-align:center;
  }
`
const getLastTime = periods => moment(last(periods).from, 'HH:mm').add(30, 'minutes').format('HH:mm')

export const DeleteDialog = ({ open, onClose, data,doctorId }) => {
  const periods = useMemo(() => generateTimePeriods(), [])
  const resetForm = useRef(null)
  const dispatch = useDispatch()
  const [errorsState, setErrors] = useState({})
  const [showDeleteConfirmModal, setDeleteConfirmModal] = useState(false);

  if(parseInt(data.periodFrom.substring(0,2)) < 8){
    data.periodFrom = periods[0].from
  }
  if(parseInt(data.periodTo.substring(0,2)) >= 20){
    data.periodTo = periods[periods.length-1].to
  }

  const { formatMessage } = useIntl()
  const f = id => formatMessage({ id })

  useEffect(() => {
    if (resetForm.current) {
      resetForm.current()
    }
  }, [data])

  const normalizeDays = (days = []) => (
    days.filter(el => !!el).map(el => el[0])
  )

  const onSubmit = (values) => {
    const fromIndex = periods.findIndex(i => i.from === values.from)
    let toIndex = periods.findIndex(i => i.from === values.to)
    if (toIndex === -1) {
      toIndex = periods.length;
    }
    const days = periods.slice(fromIndex, toIndex).map(i => `${data.day} ${i.from.length < 5 ? `0${i.from}` : i.from}`)
    const selectedFrom = data.day + ' ' + values.from
    const selectedTo = data.day + ' ' + values.to

    const fromUtc = moment(selectedFrom, null).tz(Intl.DateTimeFormat().resolvedOptions().timeZone).utc().format('HH:mm')
    const toUtc = moment(selectedTo, null).tz(Intl.DateTimeFormat().resolvedOptions().timeZone).utc().format('HH:mm')
    if (values.repeat !== undefined) {
      addRepeat({
        type: values.type,
        from: fromUtc,
        to: toUtc,
        dateRepeatFrom: values.dateRepeatFrom,
        dateRepeatTo: values.dateRepeatTo,
        days: normalizeDays(values.days),
        doctorId:doctorId
      });
    }
      dispatch(deleteEvent(days,doctorId))
      .then(() => onClose("Delete"))
  }

  const validate = () => {
    const errors = {};

    if (!isEqual(errors, errorsState)) {
      setErrors(errors);
    }

    return errors;
  }

  return (
    <DialogDelete open={open} onClose={onClose}   PaperProps={{ sx: { width: "30%", height: "28%" } }}
    >
      <DialogTitle>
        <Title>
          <FormattedMessage id='schedule.delete' />
          {data !== null
            && (
              moment(data.day).local().format(' DD MMMM YYYY')
            )}
        </Title>
      </DialogTitle>
      <DialogContent>
        <Form
          onSubmit={onSubmit}
          validate={validate}
          initialValues={data ? {
            type: 'Available',
            from: transformTime(data.periodFrom),
            to:  transformTime(data.periodTo),
          } : { type: 'Available' }}
          destroyOnUnregister
          render={({
            handleSubmit, values, submitting, form,
          }) => {
            resetForm.current = form.reset

            const toOptions = periods.slice(values.from
              ? periods.findIndex(i => i.from === values.from)
              : 0)
            return (
              <form onSubmit={handleSubmit}>
                <FieldWrapper>
                  <StyledDateField
                    name='from'
                    component={TextField}
                    options={periods
                      .map(item => ({ label: item.from, value: item.from }))}
                  />
                  {' - '}
                  <StyledDateField
                    name='to'
                    component={TextField}
                    options={[...(toOptions && toOptions.length
                      ? toOptions : [{ from: getLastTime(periods) }])]
                      .map(item => ({ label: item.to, value: item.to }))}
                  />
                </FieldWrapper>
                <RemoveButton 
                  onClick={() => setDeleteConfirmModal(true)}
                  disabled={submitting}
                  isFetching={submitting}
                >
                  <FormattedMessage id='global.delete' />
                </RemoveButton>
                <ConfirmModal
                  title={f('schedule.delete.confirmmodal.title')} 
                  text={f('schedule.delete.confirmmodal.text')} 
                  confirmButtonText={f('schedule.delete.confirmmodal.confirmbutton')}
                  cancelButtonText={f('schedule.delete.confirmmodal.cancelbutton')}
                  open={showDeleteConfirmModal} 
                  handleClose={() => setDeleteConfirmModal(false)}
                  handleSubmit={() => {
                    setDeleteConfirmModal(false);
                    return onSubmit({ ...values, type: 'Empty' });
                  }}
                />
              </form>
            )
          }}
        />
      </DialogContent>
    </DialogDelete>
  )
}

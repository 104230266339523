import React from 'react'
import PropTypes from 'prop-types'
import { Switch, Route } from 'react-router-dom'
import { withProps } from 'recompose'
import { TypographyPage } from './TypographyPage'
import { history } from '../../../utils/history'
import { MainPage } from './MainPage'

const HelpPageComponent = ({
  faq,
  terms,
  match,
  onBackIconTypographyPageClick,
  onBackIconClick,
  onBackIconTermsClick,
}) => (
  <Switch>
    <Route
      exact
      path={`${match.path}`}
      render={({ ...props }) => (
        <MainPage
          onBackIconClick={onBackIconClick}
          faq={faq}
          {...props}
        />
      )}
    />
    <Route
      path={`${match.path}/terms`}
      render={() => (
        <TypographyPage
          label='auth.policy'
          text={terms}
          onBackIconClick={onBackIconTermsClick}
        />
      )}
    />
    {faq.map((item, id) => (
      <Route
        path={`${match.path}/faq${id}`}
        key={item.heading}
        render={({ ...props }) => (
          <TypographyPage
            label={item.heading}
            text={item.text}
            onBackIconClick={onBackIconTypographyPageClick}
            {...props}
          />
        )}
      />
    ))}
  </Switch>
)

HelpPageComponent.defaultProps = {
  onBackIconClick: false,
}

HelpPageComponent.propTypes = {
  faq: PropTypes.arrayOf(PropTypes.object).isRequired,
  terms: PropTypes.string.isRequired,
  match: PropTypes.object.isRequired,
  onBackIconTypographyPageClick: PropTypes.func.isRequired,
  onBackIconTermsClick: PropTypes.func.isRequired,
  onBackIconClick: PropTypes.oneOfType([PropTypes.bool, PropTypes.func]),
}

const enhance = withProps(props => ({
  onBackIconTypographyPageClick: () => history.push(`${props.match.path}`),
  onBackIconTermsClick: () => history.goBack(),
}))

export const HelpPage = enhance(HelpPageComponent)

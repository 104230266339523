/* eslint-disable */
import React, {useEffect, useRef, useState} from 'react'
import { Paper } from 'common/UiKit/Paper'
import { Colors } from 'constants/colors'
import Muipaper from '@mui/material/Paper'
import Avatar from '@mui/material/Avatar'
import List from '@mui/material/List'
import ListItem from '@mui/material/ListItem'
import ListItemAvatar from '@mui/material/ListItemAvatar'
import Button from '@mui/material/Button'
import ListItemText from '@mui/material/ListItemText'
import TextField from '@mui/material/TextField'
import { FormattedMessage, useIntl } from 'react-intl'
import Popper from '@mui/material/Popper'
import FormControlLabel from '@mui/material/FormControlLabel'
import Checkbox from '@mui/material/Checkbox'
import axios from 'axios'
import styled from 'styled-components'
import idx from 'idx'
import ClickAwayListener from '@mui/material/ClickAwayListener';
import moment from 'moment-timezone';
import get from 'lodash/get';
import defaultAvatar from '../../../../images/panel/default-avatar.svg'
import { CreatePatient, CheckboxWrapper } from './CreatePatient'
import { getVirtualPatients } from '../../../../helpers/consultations';
import { useSelector } from 'react-redux';
import {useRouteMatch} from "react-router-dom";
import {BUTTON_COLORS} from "../../../../constants/colors";

const PopperStyled = styled(Popper)`
  && {
    z-index: 20;
    max-height: 300px;
    overflow-y: auto;
    border: 1px solid ${Colors.divider};
  }
`
const AddPatient = styled(Button)`
  text-transform: none;
  font-family: Roboto;
  font-size: 14px;
  color: #fff;
  min-width: 235px;
  height: 40px;
  flex-grow: 0;
  margin-left: 0 !important;
`

const ValueWrapper = styled.div`
  display: flex;
  align-items: center;
  
  button {
    height: 38px;
    min-width: 150px;
  }
`

const ButtonWrapper = styled.div`
  margin-top: 12px;

  button {
    margin-left: 12px;
    box-shadow: none;
    height: 35px;
    background-color: ${BUTTON_COLORS.purple};
    color: #fff;
    flex: 0 0 auto;
  }
  button:hover {
    background-color: ${BUTTON_COLORS.purpleHover};
    color: #fff;
  }
`

const CheckboxContainer = styled(CheckboxWrapper)`
  text-align: left;
  padding-left: 24px;
`

const Disclaimer = styled.div`
  margin-top: 15px;
  margin-bottom: 15px;
  text-align: justify;
  font-style: italic;
  font-size: 14px;
  color: ${Colors.footerGrey};
`

const renderSuggestion = (suggestion, onClick) => {
  const rawDateBith = idx(suggestion, _ => _.attributes.date_of_birth);
  const formattedBirth = moment(rawDateBith);

  return (
    <ListItem button={!!onClick} onClick={onClick}>
      <ListItemAvatar>
        <Avatar src={idx(suggestion, _ => _.attributes.photo) || defaultAvatar} />
      </ListItemAvatar>
      {
      suggestion.type === 'HealthRecord'
        ? (
          <ListItemText>
            {idx(suggestion, _ => _.attributes.fullName) || (
              <>
                <FormattedMessage id='global.not.filled.name' />
                {' '}
                <FormattedMessage id='global.not.filled.lastName' />
              </>
            )}
          </ListItemText>
        )
        : (
          <ListItemText>
            {idx(suggestion, _ => _.attributes.first_name) || <FormattedMessage id='global.not.filled.name' />}
            {' '}
            {idx(suggestion, _ => _.attributes.last_name) || <FormattedMessage id='global.not.filled.lastName' />}
            {' '}
            ({rawDateBith ? formattedBirth.format('DD/MM/YYYY') : <FormattedMessage id='global.none' />})
          </ListItemText>
        )
    }
    </ListItem>
  ) 
}

export const PatientField = ({
  input, meta, hospitalized_patient, toggleHospitalizedPatient
  }) => {
  const draftCons = useSelector(state => state.consultations.draft.consultation)

  const [suggestions, setSuggestions] = useState([])
  const [inputValue, onInputChange] = useState('')
  const [isOpened, togglePopper] = useState(false)
  const [isPatientFormOpened, togglePatientForm] = useState(false)
  const { formatMessage } = useIntl()
  const inputRef = useRef(null)
  const idToEdit = get(input, 'value.id', false);
  const hasEditCurrent = get(input, 'value.attributes.hasEdit', false) 
  && get(input, 'value.attributes.virtual', false);
  const patentID = useSelector(store => store.auth.id);

  const onSearch = (controllerPatient, controllerVirtual) => {

    if (!isOpened) {
      togglePopper(true)
    }

     const delayDebounce = setTimeout(() => {
       let patentsWithoutHimself = []

        const response = axios('/doctor/patient/list', { params: { name: inputValue}, signal: controllerPatient.signal })
        const response2 = getVirtualPatients({ name: inputValue }, controllerVirtual.signal);

       Promise.all([response, response2]).then((resArray) => {
         patentsWithoutHimself = resArray[0].data.data.filter(patent => parseInt(patent.id) !== patentID);
         setSuggestions([...patentsWithoutHimself, ...resArray[1]])
       }).catch(err => {
         console.error(err)
       })
    }, 400)

    return () => clearTimeout(delayDebounce);

  }

  useEffect(() => {

    if (inputValue && inputValue.length > 0) {
      const controllerPatient = new AbortController();
      const controllerVirtual = new AbortController();
      onSearch(controllerPatient, controllerVirtual)
      return () => {
        controllerPatient.abort();
        controllerVirtual.abort();
      };
    } else {
      togglePopper(false)
    }

  }, [inputValue]);

  useEffect(() => {
    if (typeof draftCons !== 'undefined') {
      input.onChange(draftCons.relationships.patient.data)
    }
  }, [draftCons])

  const match = useRouteMatch();
  const isTCA = match.path.includes('teleconsultation');

  return (
    <Paper heading='concilium.select.patient'>
      {
        input.value
          ? (
            <>
              <ValueWrapper>
                {renderSuggestion(input.value)}
                <Button
                  color='primary'
                  size='small'
                  style={{minWidth: '160px'}}
                  onClick={() => {
                    onInputChange('')
                    input.onChange(null)
                    setSuggestions([])
                    setTimeout(() => {
                      if (inputRef.current) {
                        inputRef.current.focus()
                      }
                    }, 100)
                  }}
                >
                  <FormattedMessage id='concilium.change' />
                </Button>
                {
                  hasEditCurrent
                    ?
                  <Button
                    color='primary'
                    size='small'
                    onClick={() => {
                      togglePatientForm(true)
                    }}
                  >
                    <FormattedMessage id="global.edit" />
                  </Button>
                    :
                  ''
                }
              </ValueWrapper>
              <CheckboxContainer>
                <FormControlLabel
                  control={(
                    <Checkbox
                      color='primary'
                      checked={hospitalized_patient}
                      onChange={e => {
                        toggleHospitalizedPatient(e.target.checked)
                      }}
                      name='hospitalized_patient'
                    />
                  )}
                  label={<FormattedMessage id='concilium.patient.IsHospitalized' />}
                />
              </CheckboxContainer>
            </>
          )
          : (
            <>
              <TextField
                fullWidth
                variant='outlined'
                InputProps={{
                  inputRef,
                  onFocus: () => setTimeout(() => togglePopper(true), 300),
                  onClick: () => setTimeout(() => togglePopper(true), 300),
                  // onBlur: () => togglePopper(false),
                }}
                value={inputValue}
                onChange={e => onInputChange(e.target.value)}
                placeholder={formatMessage({ id: 'concilium.search' })}
                error={!!(meta.touched && meta.error)}
                helperText={(meta.touched && meta.error) ? meta.error : ''}
              />
              {isTCA && (
                  <Disclaimer>
                    <FormattedMessage id="concilium.patient.disclaimer" values={{'strong': (...chunks) => (<strong>{chunks}</strong>)}}/>
                  </Disclaimer>
              )}
              <ButtonWrapper>
                {/*<FormattedMessage id='concilium.or' />*/}
                               
                <AddPatient variant='contained' color='primary' onClick={() => togglePatientForm(true)}>
                  <FormattedMessage id='concilium.create.patient' />
                </AddPatient>

              </ButtonWrapper>
              <ClickAwayListener onClickAway={() => isOpened && togglePopper(false)}>
                <PopperStyled
                  open={!!(suggestions.length && isOpened)}
                  anchorEl={inputRef.current}
                  key={suggestions.length}
                >
                  <Muipaper
                    square
                  >
                    <List>
                      {
                        suggestions.map(item => renderSuggestion(item, () => input.onChange(item)))
                      }
                    </List>
                  </Muipaper>
                </PopperStyled>
              </ClickAwayListener>
            </>
          )
      }
      <CreatePatient
        editPatientId={hasEditCurrent ? idToEdit: false}
        open={isPatientFormOpened}
        onClose={() => togglePatientForm(false)}
        afterSubmit={(patient) => {
          input.onChange(patient)
        }}
      />
    </Paper>
  )
}
/* eslint-disable */
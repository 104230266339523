import {handleActions, createAction} from 'redux-actions'
import {combineReducers} from 'redux'
import axios from 'axios'
import pickBy from 'lodash/pickBy';
import queryString from 'query-string';
import {editProfileSuccess} from "./auth";
import {toggleFavoriteStructureSuccess} from "./structures";
import {toggleFavoriteTeamSuccess} from "./teams";

//#region Actions
export const fetchDoctorsListRequest = createAction('FETCH_DOCTORS_LIST_REQUEST')
export const fetchDoctorsListSuccess = createAction('FETCH_DOCTORS_LIST_SUCCESS')
export const fetchDoctorsListFailure = createAction('FETCH_DOCTORS_LIST_FAILURE')

export const fetchPaginatorListSuccess = createAction('FETCH_PAGINATOR_LIST_SUCCESS')

export const fetchDoctors = (specId, isAdditional, filter = {}, noLimit = false, fetching = null, abortController = {}) => (dispatch, getStore) => {
  dispatch(fetchDoctorsListRequest(specId))

  if (abortController.current) {
    abortController.current.abort()
  }
  abortController.current = new AbortController();

  const url = isAdditional ? '/doctor/consultation/addDoctor/search' : '/general/doctor'
  const spec = getStore().content.specializations.find(item => +item.id === specId)
  const {language} = getStore().auth.attributes
  const params = isAdditional
    ? {specialization: spec ? spec.attributes.key : undefined, ...filter} : {
      include: 'workPlace',
      language,
      limit: noLimit ? null : 10,
      specialization: specId,
      ...filter,
    }
  const location = getStore().geoloc.reverseGeocoding;
  if (location) {
    params.latitude = location.latitude;
    params.longitude = location.longitude;
  }
  return axios(url, {
    params,
    signal: abortController.current.signal
  })
    .then((res) => {
      dispatch(fetchDoctorsListSuccess(res.data.data))
      dispatch(fetchPaginatorListSuccess(res.data.paginator))
    })
    .catch(e => {
      if (!abortController.current.signal.aborted) {
        dispatch(fetchDoctorsListFailure(e))
      }
    })
}

export const clearDoctorsList = createAction('CLEAR_DOCTORS_LIST')

export const clearDoctors = () => (dispatch) => {
  dispatch(clearDoctorsList())
}

export const fetchDoctorRequest = createAction('FETCH_DOCTOR_REQUEST')
export const fetchDoctorSuccess = createAction('FETCH_DOCTOR_SUCCESS')
export const fetchDoctorFailure = createAction('FETCH_DOCTOR_FAILURE')

export const fetchDoctor = id => (dispatch) => {
  dispatch(fetchDoctorRequest(id))

  return axios(`/general/doctor/${id}?include=workPlace,skill,schedule,diploma`)
    .then(res => dispatch(fetchDoctorSuccess(res.data)))
    .catch(e => dispatch(fetchDoctorFailure(e)));
}

export const setDoctorAnnuaire = createAction('SET_DOCTOR_ANNUAIRE');

export const fetchDoctorFromAnnuaireRequest = createAction('FETCH_DOCTOR_FROM_ANNUAIRE_REQUEST');
export const fetchDoctorFromAnnuaireFailure = createAction('FETCH_DOCTOR_FROM_ANNUAIRE_FAILURE');
export const fetchDoctorFromAnnuaireSuccess = createAction('FETCH_DOCTOR_FROM_ANNUAIRE_SUCCESS');

export const fetchDoctorFromAnnuaire = (id) => (dispatch) => {
  dispatch(fetchDoctorFromAnnuaireRequest(id))
  axios.get(`/api/annuaire?id=${id}`)
    .then(response => {
      dispatch(fetchDoctorFromAnnuaireSuccess(response.data))
    })
    .catch(err => {
      dispatch(fetchDoctorFromAnnuaireFailure(err))
    })
}

export const toggleFavoriteRequest = createAction('TOGGLE_FAVORITE_REQUEST')
export const toggleFavoriteSuccess = createAction('TOGGLE_FAVORITE_SUCCESS')
export const toggleFavoriteFailure = createAction('TOGGLE_FAVORITE_FAILURE')

export const toggleFavorite = (id, value) => (dispatch) => {
  dispatch(toggleFavoriteRequest({id, value}))
  return axios(`/general/patient/favorite_doctor/${id}`, {method: value ? 'POST' : 'DELETE'})
    .then(response => {
        dispatch(toggleFavoriteSuccess(response.data))
      }
    )
    .catch((err) => {
      dispatch(toggleFavoriteFailure(err))
    })
}

export const fetchFavoriteRequest = createAction('FETCH_FAVORITE_REQUEST')
export const fetchFavoriteSuccess = createAction('FETCH_FAVORITE_SUCCESS')
export const fetchFavoriteFailure = createAction('FETCH_FAVORITE_FAILURE')

export const fetchFavoriteDoctors = (filter = {}) => (dispatch) => {
  dispatch(fetchFavoriteRequest())
  return axios(`/general/patient/favorite_doctor?${queryString.stringify(pickBy({...filter}))}`)
    .then((response) => {
      const rawResp = response.data;
      dispatch(fetchFavoriteSuccess(rawResp));
    })
    .catch((err) => {
      dispatch(fetchFavoriteFailure(err))
    })
}


export const fetchFavoriteStructuresRequest = createAction('FETCH_FAVORITE_STRUCTURES_REQUEST')
export const fetchFavoriteStructuresSuccess = createAction('FETCH_FAVORITE_STRUCTURES_SUCCESS')
export const fetchFavoriteStructuresFailure = createAction('FETCH_FAVORITE_STRUCTURES_FAILURE')

export const fetchFavoriteStructures = (filter = {}) => (dispatch) => {
  dispatch(fetchFavoriteStructuresRequest())
  return axios(`/general/patient/favorite_structure`, {method: 'GET'})
    .then((response) => {
      const rawResp = response.data;
      dispatch(fetchFavoriteStructuresSuccess(rawResp));
      dispatch(fetchPaginatorListSuccess(rawResp.paginator))
    })
    .catch((err) => {
      dispatch(fetchFavoriteStructuresFailure(err))
    })
}

export const fetchFavoriteTeamsRequest = createAction('FETCH_FAVORITE_TEAMS_REQUEST')
export const fetchFavoriteTeamsSuccess = createAction('FETCH_FAVORITE_TEAMS_SUCCESS')
export const fetchFavoriteTeamsFailure = createAction('FETCH_FAVORITE_TEAMS_FAILURE')

export const fetchFavoriteTeams = (filter = {}) => (dispatch) => {
  dispatch(fetchFavoriteTeamsRequest())
  return axios(`/general/patient/favorite_team`, {method: 'GET'})
    .then((response) => {
      const rawResp = response.data;
      dispatch(fetchFavoriteTeamsSuccess(rawResp));
      dispatch(fetchPaginatorListSuccess(rawResp.paginator))
    })
    .catch((err) => {
      dispatch(fetchFavoriteTeamsFailure(err))
    })
}

export const fetchDoctorSkillRequest = createAction('FETCH_DOCTOR_SKILL_REQUEST')
export const fetchDoctorSkillSuccess = createAction('FETCH_DOCTOR_SKILL_SUCCESS')
export const fetchDoctorSkillFailure = createAction('FETCH_DOCTOR_SKILL_FAILURE')

export const fetchDoctorSkill = search => (dispatch) => {
  dispatch(fetchDoctorSkillRequest())
  return axios(`/api/doctor/team/skill?search=${search}`, {
    method: 'GET',
  })
    .then(response => dispatch(fetchDoctorSkillSuccess(response.data)))
    .catch(err => dispatch(fetchDoctorSkillFailure(err)))
}

export const createDoctorSkillRequest = createAction('CREATE_DOCTOR_SKILL_REQUEST')
export const createDoctorSkillSuccess = createAction('CREATE_DOCTOR_SKILL_SUCCESS')
export const createDoctorSkillFailure = createAction('CREATE_DOCTOR_SKILL_FAILURE')

export const createDoctorSkill = nameSkill => (dispatch) => {
  dispatch(createDoctorSkillRequest())
  return axios(`/api/doctor/team/skill`, {
    method: 'POST',
    data: {
      name: nameSkill,
    },
  })
    .then(response => dispatch(createDoctorSkillSuccess(response.data)))
    .catch(err => dispatch(createDoctorSkillFailure(err)))
}

export const addDoctorSkillRequest = createAction('ADD_DOCTOR_SKILL_REQUEST')
export const addDoctorSkillSuccess = createAction('ADD_DOCTOR_SKILL_SUCCESS')
export const addDoctorSkillFailure = createAction('ADD_DOCTOR_SKILL_FAILURE')

export const addDoctorSkill = (skillId, id) => (dispatch) => {
  dispatch(addDoctorSkillRequest())
  return axios(`/api/doctor/skill`, {
    method: 'POST',
    data: {
      id_doctor: id,
      skill_ids: skillId,
    },
  })
    .then(() => axios('/doctor/profile?include=workPlace,skill,schedule,diploma'))
    .then(res => dispatch(editProfileSuccess(res.data)))
    .then(response => dispatch(addDoctorSkillSuccess(response.data)))
    .catch(err => dispatch(addDoctorSkillFailure(err)))
}

export const loadRequisInstructionsRequest = createAction('LOAD_REQUIS_INSTRUCTIONS_REQUEST')
export const loadRequisInstructionsSuccess = createAction('LOAD_REQUIS_INSTRUCTIONS_SUCCESS')
export const loadRequisInstructionsFailure = createAction('LOAD_REQUIS_INSTRUCTIONS_FAILURE')

export const loadRequisInstructions = () => (dispatch) => {
  dispatch(loadRequisInstructionsRequest())
  return axios.get('/doctor/instructions', {responseType: 'json'})
    .then((response) => {
      return dispatch(loadRequisInstructionsSuccess(response.data))
    })
    .catch(err => {
      console.error(err)
      return dispatch(loadRequisInstructionsFailure(err))
    });
}

export const updateRequisInstructionsRequest = createAction('UPDATE_REQUIS_INSTRUCTIONS_REQUEST')
export const updateRequisInstructionsSuccess = createAction('UPDATE_REQUIS_INSTRUCTIONS_SUCCESS')
export const updateRequisInstructionsFailure = createAction('UPDATE_REQUIS_INSTRUCTIONS_FAILURE')

export const updateRequisInstructions = (newInstructions) => (dispatch) => {
  dispatch(updateRequisInstructionsRequest())
  return axios.post('/doctor/instructions', { instructions: newInstructions })
    .then((response) => {
      return dispatch(updateRequisInstructionsSuccess(response.data))
    })
    .catch(err => {
      console.error(err)
      return dispatch(updateRequisInstructionsFailure(err))
    });
}

//#endregion

//#region Reducers
const paginator = handleActions({
  [fetchPaginatorListSuccess]: (state, action) => action.payload,
  [clearDoctorsList]: () => null,
}, [])
const list = handleActions({
  [fetchDoctorsListSuccess]: (state, action) => action.payload,
  [toggleFavoriteSuccess]: (state, action) => state.map((item) => {
    if (item.id === action.payload.data.id) {
      return {
        ...item,
        attributes: {...item.attributes, favorite: action.payload.data.attributes.favorite},
      }
    }

    return item
  }),
  [clearDoctorsList]: () => [],
}, [])

const favorite = handleActions({
  [fetchFavoriteSuccess]: (state, action) => action.payload.data,
  [toggleFavoriteSuccess]: (state, action) => {
    return action.payload.data.attributes.favorite ?
      [...state, action.payload.data]
      : state.filter((item) => {
        return item.id !== action.payload.data.id;
      });
  }
}, [])

const favoriteStructures = handleActions({
  [fetchFavoriteStructuresSuccess]: (state, action) => action.payload.data,
  [toggleFavoriteStructureSuccess]: (state, action) => {
    return action.payload.data.attributes.favorite ?
      [...state, action.payload.data]
      :
      state.filter((item) => {
        return item.id !== action.payload.data.id;
      })
  }
}, [])

const favoriteTeams = handleActions({
  [fetchFavoriteTeamsSuccess]: (state, action) => action.payload.data,
  [toggleFavoriteTeamSuccess]: (state, action) =>
    action.payload.data.attributes.favorite ?
      [...state, action.payload.data]
      :
      state.filter((item) => {
        return item.id !== action.payload.data.id;
      })
}, [])

const doctor = handleActions({
  [fetchDoctorSuccess]: (state, action) => action.payload.data,
  [toggleFavoriteSuccess]: (state, action) => {
    if (action.payload.data.id === state.id) {
      return {
        ...state,
        attributes: {...state.attributes, favorite: action.payload.data.attributes.favorite},
      }
    }

    return state
  },
}, {});

const skill = handleActions({
  [fetchDoctorSkillSuccess]: (state, action) => action.payload.data,
  [toggleFavoriteSuccess]: (state, action) => state.map((item) => {
    if (item.id === action.payload.data.id) {
      return {
        ...item,
        attributes: {...item.attributes, favorite: action.payload.data.attributes.favorite},
      }
    }

    return item
  }),
}, []);

const annuaireDoctor = handleActions({
  [setDoctorAnnuaire]: (state, action) => {
    return action.payload;
  },
  [fetchDoctorFromAnnuaireSuccess]: (state, action) => {
    return action.payload
  }
}, {})

const instructions = handleActions({
  [loadRequisInstructionsSuccess]: (state, action) => action.payload,
  [updateRequisInstructionsSuccess]: (state, action) => action.payload.instructions,
}, '')

export const doctors = combineReducers({
  paginator,
  list,
  doctor,
  annuaireDoctor,
  favorite,
  favoriteStructures,
  favoriteTeams,
  skill,
  instructions,
})
//#endregion

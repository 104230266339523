import React, { useRef, useImperativeHandle } from 'react'
import {compose, lifecycle, withProps} from 'recompose'
import styled from 'styled-components'
import {connect, useSelector} from 'react-redux';
import {toggleFavoriteTeam} from '../../../redux/modules/teams'
import {Colors} from "../../../constants/colors";
import {EmptyScreen} from "../../Panel/Patient/Doctors/EmptyScreen";
import {TeamCard} from "../TeamCard";
import get from "lodash/get";
import {DoctorCard} from "../DoctorCard";
import idx from "idx";
import {getDoctorPrice} from "../../../helpers/consultations";
import {history} from "../../../utils/history";
import {toggleFavorite} from "../../../redux/modules/doctors";
import {clearSkills, fetchSkillPossessors} from "../../../redux/modules/skills";

const Content = styled.div`
  margin: 0 auto;
  min-width: 100%;
  overflow-y: scroll;
`

const Wrapper = styled.div`
  & > * {
    border: ${props => (props.outlined ? `2px solid ${Colors.bluePurple}` : '')};
    margin-bottom: 24px;
  }
`

export const DoctorAndTeamListComponent = ({
                                    objects,
                                    favoriteToggle,
                                    toggleFavoriteTeam,
                                    match,
                                    idSelected,
                                    forwardedRef,
                                    isDoctor,
                                    onButtonClick,
                                    onButtonClickTER,
                                    onButtonClickTCA,
                                    toggleFavorite,
                                  }) => {
  const currentProfile = useSelector(state => state.auth);
  const isTCA = history.location.pathname.includes('teleconsultation')
  const currentDoctorStatus = get(currentProfile, 'attributes.status', '');
  const isUrlPathConcilium = match.path.includes('concilium');
  const allowToBookTE = currentProfile.type !== 'Doctor' || currentDoctorStatus === 'VERIFIED';

  const refs = useRef({})

  useImperativeHandle(forwardedRef, () => ({
    focusRef: (id) => {
      refs.current[id].scrollIntoView({
        behavior: 'smooth',
        block: 'start',
      });
    }
  }));

  const doctorAvailability = (data) => {
    let availabilityValues = []
    data.some(function(teamMember) {
      availabilityValues.push(teamMember.attributes.status_availability)
    })
    return availabilityValues.includes(true)
  }

  return (
    <>
      {
        objects.length ? (
            <Content>
              {objects.map(({ attributes, id, type, relationships }) => (
                <Wrapper key={id+type} ref={ref => (refs.current[id] = ref)} outlined={idSelected === id} >
                  {type == 'Doctor' &&
                    <DoctorCard
                      key={id + type}
                      id={id}
                      userId={attributes.user_id}
                      userStatus={attributes.userStatus}
                      isUrlPathConcilium={isUrlPathConcilium}
                      status_availability={attributes.status_availability}
                      onButtonClick={onButtonClick(id, false)}
                      onButtonClickTER={onButtonClickTER(id, true)}
                      onButtonClickTCA={onButtonClickTCA(id, false)}
                      disableButtonClick={!allowToBookTE}
                      firstName={attributes.first_name}
                      lastName={attributes.last_name}
                      experience={attributes.experience}
                      specialization={attributes.specialization}
                      specialization_type={attributes.specialization_type}
                      photo={attributes.photo}
                      language={attributes.language}
                      location={idx(relationships, _ => _.workPlace.data.slice(-1)[0].attributes)}
                      distance={attributes.distance}
                      available={idx(attributes, _ => _.calendar_nearest.TC)}
                      isFavorite={attributes.favorite}
                      isFavoriteFetching={favoriteToggle === id}
                      toggleFavorite={value => toggleFavorite(id, value)}
                      buttonText={isDoctor ? 'concilium.other.disponibilities' : 'consultation.make.appointment'}
                      isDoctor={isDoctor}
                      calendarNearestTE={ isDoctor ? get(attributes, `calendar_nearest.${isTCA ? 'TCA' : 'TE' }`, false) : get(attributes, 'calendar_nearest.TC', false) }
                      price={getDoctorPrice(attributes, 'daily_price')}
                      link={(isDoctor ? `/panel/concilium/doctor/` : `/panel/doctors/doctor/`) + id }
                      gender={attributes.gender}
                    />}
                  {type == 'DoctorTeam' &&
                    <TeamCard
                      key={id + type}
                      id={id}
                      name={attributes.name}
                      members={attributes.member_count}
                      type={attributes.type}
                      isFavorite={attributes.favorite}
                      isFavoriteFetching={favoriteToggle === id}
                      toggleFavorite={value => toggleFavoriteTeam(id, value)}
                      distance={attributes.distance}
                      status_availability={doctorAvailability(get(relationships, 'members.data', []))}
                      specializations={attributes.specializations}
                      isDoctor={isDoctor}
                    />}
                </Wrapper>
              ))}
            </Content>
          ) :
          <EmptyScreen/>
      }
    </>
  )
}

const enhance = compose(
  connect(
    (state, props) => ({
      objects: state.skills.list,
      favoriteToggle: state.loading.favoriteToggle,
      isDoctor: state.auth.type === 'Doctor',
    }),
    {
      fetchSkillPossessors,
      clearSkills,
      toggleFavorite,
      toggleFavoriteTeam,
    },
    null,
    { forwardRef: true }
  ),
  withProps(props => ({
    onButtonClick: (id, isUrgent) => (e, proposedDate) => {
      const url = !props.isDoctor
        ? `/panel/doctors/${props.match.params.consultationType}/create${proposedDate ? `?proposedDate=${proposedDate}` : ''}`
        : `/panel/concilium/create/${id}${isUrgent ? '?urgent=1' : ''}${proposedDate ? `${isUrgent ? '&' : '?'}proposedDate=${proposedDate}` : ''}`
      e.stopPropagation()

      if (props.isDoctor) {
        if (props.match.params.consultationId) {
          props.inviteDoctor(id, props.match.params.consultationId)
          return history.push('/panel/consultations/session')
        }

        return history.push(url)
      }

      return props.fetchDoctor(id)
        .then(() => history.push(url))
    },
    onButtonClickTCA: (id, isUrgent) => (e, proposedDate) => {
      const url = !props.isDoctor
        ? `/panel/doctors/appointment/create${proposedDate ? `?proposedDate=${proposedDate}` : ''}`
        : `/panel/teleconsultation/create/${id}${proposedDate ? `?proposedDate=${proposedDate}` : ''}`
      e.stopPropagation()
      if (props.isDoctor) {
        if (props.match.params.consultationId) {
          props.inviteDoctor(id, props.match.params.consultationId)
          return history.push('/panel/consultations/session')
        }
        return history.push(url)
      }

      return props.fetchDoctor(id)
        .then(() => history.push(url))
    },
    onButtonClickTER: (id, isUrgent) => (e, proposedDate) => {
      const url = !props.isDoctor
        ? `/panel/doctors/${props.match.params.consultationType}/create${proposedDate ? `?proposedDate=${proposedDate}` : ''}`
        : `/panel/concilium/create/${id}${isUrgent ? '?urgent=1' : ''}${proposedDate ? `${isUrgent ? '&' : '?'}proposedDate=${proposedDate}` : ''}`
      e.stopPropagation()

      if (props.isDoctor) {
        if (props.match.params.consultationId) {
          props.inviteDoctor(id, props.match.params.consultationId)
          return history.push('/panel/consultations/session')
        }

        return history.push(url)
      }

      return props.fetchDoctor(id)
        .then(() => history.push(url))
    },
    onToggleUrgent: (value) => {
      const isAdditional = !!props.match.params.consultationId
      const immediate = !props.isDoctor ? { tciimmediate: 1 } : { immediate: 1 };
      props.fetchDoctors(+props.match.params.doctorId,
        isAdditional, value ? immediate : {})
    },
  })),
  lifecycle({
    componentDidMount() {
      this.props.clearSkills()
    },
  }),
)

export const DoctorAndTeamListBySkill = enhance(DoctorAndTeamListComponent)

export const DoctorAndTeamBySkillListWithRef = React.forwardRef(({...props}, ref) => <DoctorAndTeamListBySkill {...props} forwardedRef={ref} />)

import React from "react";

export const getCorrectText = (events) => {
  events.map(element => {
    if(element.type == "Available"){
      if (element.data.actionAvailable.TE && element.data.actionAvailable.TC && element.data.actionAvailable.TCA) {
        element.title = "schedule.available.for.te.tc.tca"
      }
      else if (element.data.actionAvailable.TE && element.data.actionAvailable.TC) {
        element.title = "schedule.available.for.te.tc"
      }
      else if (element.data.actionAvailable.TE && element.data.actionAvailable.TCA) {
        element.title = "schedule.available.for.te.tca"
      }
      else if (element.data.actionAvailable.TC && element.data.actionAvailable.TCA) {
        element.title = "schedule.available.for.tc.tca"
      }
      else if (element.data.actionAvailable.TE) {
        element.title ='schedule.availabilities.TE'
      }
      else if (element.data.actionAvailable.TC) {
        element.title ='schedule.availabilities.TC'
      }
      else if (element.data.actionAvailable.TCI) {
        element.title ='schedule.teleconsultations.immediate'
      }
      else if (element.data.actionAvailable.TCA) {
        element.title ='schedule.availabilities.TCA'
      }
      else{
        element.title = ""
      }
    }
    else {
      switch (element.data.data.type){
        case 'telexpertise':
          element.title ='schedule.type.tep'
          element.type = 'schedule.type.tep'
          element.patient = element.data.data.doctorAttachment.name
          break;
        case 'TCA':
          element.title = element.data.data.patient.name
          element.type = 'schedule.type.tca'
          element.patient = element.data.data.patient.name
          break;
        case 'scheduled' :
        case 'urgent':
          element.title = element.data.data.patient.name
          element.type = 'schedule.type.consult'
          element.patient = element.data.data.patient.name
          break;
        case 'TER' :
          element.title ='schedule.type.ter'
          break;
        default:
          element.title ='schedule.type.consult'
      }
    }
  })
}
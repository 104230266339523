/* eslint-disable no-underscore-dangle */
import {
  combineReducers,
  createStore,
  applyMiddleware,
  compose,
} from 'redux'
import thunk from 'redux-thunk'

import {authReducer, authRppsAction} from './modules/auth'
import { loading } from './modules/loading'
import { consultations } from './modules/consultations'
import { passwordRecoveryReducer } from './modules/passwordRecovery'
import { notifications } from './modules/notifications'
import { healthRecords } from './modules/healthRecords'
import { doctors } from './modules/doctors'
import { content } from './modules/content'
import { settings } from './modules/settings'
import { medicines } from './modules/medicines'
import { reviewsReducer } from './modules/reviews'
import { localizationReducer } from './modules/localization'
import {chatsReducer, chatNotificationsReducer, chatFilesReducer} from './modules/chats'
import { calendarReducer } from './modules/calendar'
import { signatureReducer } from './modules/signature'
import { notificationReducer } from './modules/notification'
import { callReducer, visioReducer } from './modules/call'
import { statusReducer } from './modules/status'
import { geolocReducer } from './modules/geoloc'
import {profileaccess} from "../helpers/fillProfile";
import { structureInfo } from './modules/structureInfos'
import {structures} from "./modules/structures";
import {recentContacts} from "./modules/recentContact";
import {search} from "./modules/search"
import {teams} from "./modules/teams";
import {formsView} from "./modules/formsView"
import {discussions} from "./modules/discussions";
import {chatmembers} from "./modules/chatmembers";
import {contacts} from "./modules/contacts";
import {skills} from "./modules/skills";
import {mssanteReducer} from "./modules/mssante"
import {medicalOfficeSoftwares} from "./modules/medicalOfficeSoftwares";
import {invitationsReducer, proInviteReducer} from "./modules/proInvite";

export const rootReducer = combineReducers({
  auth: authReducer,
  calendar: calendarReducer,
  call: callReducer,
  visio: visioReducer,
  loading,
  passwordRecovery: passwordRecoveryReducer,
  consultations,
  notifications,
  healthRecords,
  doctors,
  structures,
  content,
  settings,
  medicines,
  reviews: reviewsReducer,
  localization: localizationReducer,
  chats: chatsReducer,
  chatsNotifications: chatNotificationsReducer,
  chatsFiles: chatFilesReducer,
  signature: signatureReducer,
  notification: notificationReducer,
  status: statusReducer,
  geoloc: geolocReducer,
  profileaccess: profileaccess,
  structureInfo,
  recentContacts: recentContacts,
  search: search,
  teams: teams,
  formsView: formsView,
  authRppsAction: authRppsAction,
  discussions: discussions,
  chatMembers: chatmembers,
  contacts: contacts,
  skills: skills,
  mssante: mssanteReducer,
  medicalOfficeSoftwares: medicalOfficeSoftwares,
  proInvite: proInviteReducer,
  invitations: invitationsReducer,
})

const composeEnhancers = (process.env.NODE_ENV !== 'production' && window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__)
  ? window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__
  : compose

export const store = createStore(rootReducer, composeEnhancers(applyMiddleware(thunk)))

import React from 'react'
import PropTypes from 'prop-types'
import { compose } from 'recompose'
import styled from 'styled-components'
import get from 'lodash/get'
import { connect, useDispatch, useSelector } from 'react-redux';
import StarIcon from '@mui/icons-material/Star'
import { EmptyScreen } from './EmptyScreen'
import {
    fetchDoctors,
    fetchDoctor,
    toggleFavorite,
} from '../../../../redux/modules/doctors'
import {clearDraftConsultation, inviteDoctor} from '../../../../redux/modules/consultations'
import { history } from '../../../../utils/history'
import {useIntl} from "react-intl";
import {FavoriteDoctorCard} from "../../../common/DoctorCard/FavoriteDoctorCard";
import CustomLoaderOverlay from "../../../common/CustomLoaderOverlay";

export const FavoritePSListComponent = ({
  favoriteToggle,
  toggleFavorite,
  emptyTitle,
  isFetching,
}) => {
  const dispatch = useDispatch()
  const { formatMessage } = useIntl()
  const f = id => formatMessage({ id })
  const currentProfile = useSelector(state => state.auth);

  const doctorFavorites = useSelector(state => state.doctors.favorite)
  const currentDoctorStatus = get(currentProfile, 'attributes.status', '');

  const allowToBookTE = currentProfile.type === 'Doctor';

  const onButtonClickTER = (id, isUrgent) => (e) => {
    const url = `/panel/concilium/create/${id}${isUrgent ? '?urgent=1' : ''}`
    e.stopPropagation()

    dispatch(clearDraftConsultation())
    return dispatch(fetchDoctor(id)).then(() => history.push(url))
  }

  return (
    <>
      <CustomLoaderOverlay isShow={isFetching}/>
      {
        doctorFavorites.length && !isFetching
          ?
            doctorFavorites.map(({ attributes, id }) => (
              <FavoriteDoctorCard
                key={id}
                id={id}
                status_availability={attributes.status_availability}
                onButtonClickTER={onButtonClickTER(id, true)}
                disableButtonClick={!allowToBookTE || attributes.status !== 'VERIFIED'}
                firstName={attributes.first_name}
                lastName={attributes.last_name}
                specialization={attributes.specialization}
                specialization_type={attributes.specialization_type}
                photo={attributes.photo}
                isFavorite={attributes.favorite}
                isFavoriteFetching={favoriteToggle === id}
                disableFavorite={id === currentProfile.id}
                toggleFavorite={value => toggleFavorite(id, value)}
                link={`concilium/doctor/` +id}
                gender={attributes.gender}
              />
            ))
          : <EmptyScreen Icon={StarIcon} title={emptyTitle} />
      }
    </>
  )
}

FavoritePSListComponent.defaultProps = {
    onIconClick: null,
    emptyTitle: '',
}

FavoritePSListComponent.propTypes = {
    doctors: PropTypes.arrayOf(PropTypes.object).isRequired,
    onBackIconClick: PropTypes.func.isRequired,
    onIconClick: PropTypes.func,
    favoriteToggle: PropTypes.oneOfType([PropTypes.bool, PropTypes.number]).isRequired,
    toggleFavorite: PropTypes.func.isRequired,
    emptyTitle: PropTypes.string,
    isFetching: PropTypes.bool.isRequired,
}

const enhance = compose(
  connect(
    (state, props) => ({
      paginator: state.doctors.paginator,
      doctors: props.withUrgentToggle
          ? state.doctors.list.filter(doctor => doctor.id !== state.auth.id)
          : state.doctors.list,
      favoriteToggle: state.loading.favoriteToggle,
      isFetching: state.loading.favoriteProfessionals,
      isDoctor: state.auth.type === 'Doctor',
    }),
    {
      fetchDoctors,
      fetchDoctor,
      toggleFavorite,
      inviteDoctor,
    },
  ),
)

export const FavoritePSList = enhance(FavoritePSListComponent)

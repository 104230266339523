export const getAge = (birthdate) => {
  let today = new Date();
  let todayYear = today.getFullYear();
  let todayMonth = today.getMonth();
  let todayDay = today.getDate()

  let birthdateYear = birthdate.getFullYear();
  let birthdateMonth = birthdate.getMonth();
  let birthdateDay = birthdate.getDate();

  let diff = todayYear - birthdateYear;

  if (diff !== 0) {
    if (birthdateMonth > todayMonth) {
      diff --;
    } else {
      if (birthdateMonth === todayMonth) {
        if (birthdateDay > todayDay) {
          diff --;
        }
      }
    }
    diff = diff + " ans";
  } else {
    if (birthdateMonth === todayMonth) {
      diff = (todayDay - birthdateDay) + " jours";
    } else {
      diff = (todayMonth - birthdateMonth) + " mois";
    }
  }

  return diff;
}

import * as React from 'react';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormControl from '@mui/material/FormControl';


export default function RadioButtonsGroup({setTcaActContextNurse}) {
  const handleChange = (event) => {
    setTcaActContextNurse(event.target.value);
  };
  return (
    <FormControl>
      <RadioGroup
        aria-labelledby="demo-radio-buttons-group-label"
        defaultValue="TLS"
        name="radio-buttons-group"
        onChange={handleChange}
      >
        <FormControlLabel value='TLS' control={<Radio />} label="À l’occasion d’un soin infirmier déjà prévu" />
        <FormControlLabel value='TLL' control={<Radio />} label="Dans un lieu dédié aux téléconsultations " />
        <FormControlLabel value='TLD' control={<Radio />} label="Réalisée à domicile sans soin associé" />
      </RadioGroup>
    </FormControl>
  );
}
import {useSelector} from "react-redux";
import {Dialog, DialogContent, DialogTitle, Skeleton} from "@mui/material";
import {Colors} from "../../../constants/colors";
import {Close} from "@mui/icons-material";
import {SkeletonTextRepeat} from "../../common/UiKit/SkeletonTextRepeat";
import React from "react";
import {TeamLogo} from "./TeamLogo";
import PropTypes from "prop-types";
import {DisplayGender} from "../../common/Gender";
import {Avatar} from "../../common/Avatar";
import styled from "styled-components";
import TagsList from "../../common/TagsList";
import {FormattedMessage, useIntl} from "react-intl";

export const TeamDetailsDialog = ({ open, onClose }) => {
  const { formatMessage } = useIntl()
  const team = useSelector(state => state.structureInfo?.team)
  const teamInfos = team?.attributes
  const teamSpecializations = team?.relationships?.specializations?.data
  const teamMembers = team?.attributes?.doctor_members
  const teamSkills = team?.attributes?.doctor_skills
  const isLoading = useSelector(state => state.loading.structureInfosTeam)

  return (
    <Dialog open={open} onClose={onClose} fullWidth={true}>
      <DialogTitle>
        <a
          style={{ color: Colors.steel, float: 'right', display: 'inline-block' }}
          onClick={(e) => {
            e.preventDefault()
            onClose()
          }}
          href='#'
        >
          <Close />
        </a>
        <div style={{ textAlign: 'center' }}>
          {
            isLoading && (
              <Skeleton width={250} sx={{ display: 'inline-block' }} />
            ) || (
              <>
                <TeamLogo teamType={teamInfos?.type} style={{ verticalAlign: 'middle' }} />
                <span style={{ display: 'inline-block', marginLeft: '15px' }}>{teamInfos?.name}</span>
              </>
            )
          }
        </div>
      </DialogTitle>
      <DialogContent>
        <TeamInfo title='structure.info.specialities' inline={true}>
          {
            isLoading && (
              <SkeletonTextRepeat repeat={3} width={70} />
            ) || !!teamSpecializations && teamSpecializations.map((specialization, key) => (
              <span key={key} style={{ fontSize: '0.9em', display: 'inline-block', padding: '5px' }} >
                {specialization.attributes?.name}{key < teamSpecializations.length - 1 ? ',' : ''}
              </span>
            ))
          }
        </TeamInfo>
        <TeamInfo title='structure.info.skills'>
          {
            isLoading && (
              <SkeletonTextRepeat repeat={3} width={50} />
            ) || (
              <DisplaySkills skills={teamSkills} />
            )
          }
        </TeamInfo>
        <TeamInfo title='structure.info.description'>
          {
            isLoading && (
              <SkeletonTextRepeat repeat={3} width={450} inline={false} />
            ) || (
              <>
                { teamInfos?.description }
              </>
            )
          }
        </TeamInfo>
        <TeamInfo title={ formatMessage({ id: 'structure.info.members' }, { count: teamInfos?.member_count }) } intl={false}>
          {
            isLoading && (
              <SkeletonTextRepeat repeat={3} width={150} inline={false} />
            ) || !!teamMembers && teamMembers.map((member, key) => (
                <TeamMember member={member} key={key} />
              )
            )
          }
        </TeamInfo>
      </DialogContent>
    </Dialog>
  )
}

TeamDetailsDialog.propTypes = {
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
}

const TeamInfo = ({ title = '', intl = true, inline = false, children }) => {
  return (
    <div style={{ marginBottom: '15px' }}>
      <span style={{ fontWeight: 'bold' }}>
        { intl && ( <FormattedMessage id={title} /> ) || title }
      </span>
      <div style={{
        display: inline ? 'inline-block' : 'block',
        marginTop: inline ? '0' : '10px',
        color: Colors.conexGrey,
      }}>
        { children }
      </div>
    </div>
  )
}

const TeamMember = ({ member }) => (
  <TeamMemberDetails target='_blank' href={'/panel/concilium/specializations/doctor/' + member.doctor_id}>
    <AvatarBlock
      image={member.photo}
      isDoctor={true}
      small={true}
      status={false}
    />
    <DisplayGender genderCode={member.gender} style={{ marginRight: '5px' }} />
    <span>{member.first_name} {member.last_name}</span>
    <span style={{ color: Colors.conexGrey }}> - {member.specialization}</span>
  </TeamMemberDetails>
)

const DisplaySkills = ({ skills }) => {
  const { formatMessage } = useIntl()
  const tags = skills.map(skill => ({ name: skill.name })).filter( tag => !!tag.name)

  return (
    <div>
      <TagsList tags={tags} emptyOption={ formatMessage({ id: 'structure.info.no-skills' }) } />
    </div>
  )
}

const TeamMemberDetails = styled.a`
  display: block;
  color: black;
  vertical-align: middle;
  border-bottom: 1px solid ${Colors.steel};
  padding: 5px 0;

  :hover {
    color: ${Colors.bluePurple};
  }
`

const AvatarBlock = styled(Avatar)`
  float: none;
  display: inline-block;
  vertical-align: middle;
  margin-right: 10px;
`
import React from 'react'
import MomentUtils from '@date-io/moment'
import { MuiPickersUtilsProvider, DatePicker, KeyboardTimePicker as InlineDatePicker } from '@material-ui/pickers'

export const DateProvider = ({ children, ...rest }) => (
  <MuiPickersUtilsProvider utils={MomentUtils} {...rest}>
    {children}
  </MuiPickersUtilsProvider>
)

export { DatePicker, InlineDatePicker }

import React, { useEffect, useRef, useState } from 'react'
import get from 'lodash/get'
import NotificationsIcon from '@mui/icons-material/Notifications'
import IconButton from '@mui/material/IconButton'
import styled from 'styled-components'
import { useSelector, useDispatch } from 'react-redux'
import Badge from '@mui/material/Badge'
import Paper from '@mui/material/Paper';
import Button from '@mui/material/Button';
import { FormattedMessage } from 'react-intl'
import { history } from '../../../utils/history'
import notification from '../../../assets/sounds/notification.mp3'
import {
  fetchNotification,
  readNotification,
  fetchGlobalNotification,
  readAllNotifications
} from '../../../redux/modules/notification'
import {Colors} from "../../../constants/colors";
import {NOTIFICATION_CATEGORY_QUESTION_YESNO} from "../../../constants/notification";
import {TextButton} from "../UiKit/TextButton";

const RingButton = styled(IconButton)`
  && {
    color: ${Colors.steel};
    
    span > span > span {
      background-color: #fa4464;
    }
  }
  
  &&:hover {
    color: ${Colors.bluePurple};
    background-color: white;
  }
`

const NotificationsContainer = styled(Paper)`
  width: 366px;
  height: 424px;
  position: absolute;
  right: 190px;
  z-index: 2000;
  display: flex;
  flex-direction: column;
`

const ViewAllButton = styled(Button)`
  && {
    text-transform: none;
    align-self: bottom;
    border-top: 1px solid #eee;
    font-size: 14px;
  }

  &&:hover {
    background-color: rgba(113, 77, 236, 0.07);
  }
`

const NotificationsHeader = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-left: 24px;
  padding-top: 21px;
  padding-bottom: 18px;
`

const NotificationsTitle = styled.div`
  font-size: 20px;
  font-weight: 500;
  letter-spacing: 0.25px;
  color: #21293e;
  display: flex;
  align-items: center;
`

const MarkAllReadButton = styled(Button)`
  && {
    font-size: 10px;
    font-weight: 500;
    line-height: 1.6;
    letter-spacing: 0.89px;
    text-align: center;
    color: #6633FF;
    line-height: 1;
    text-transform: none;
  }
  &&:hover {
    background-color: rgba(113, 77, 236, 0.07);
  }
`

const NotificationsList = styled.div`
  display: inline-block;
  overflow-y: scroll;
  height: 340px;

  &::-webkit-scrollbar {
    padding-left: 100px;
  }
`

const NotificationItem = styled.div`
  cursor: pointer;
  display: flex;
  padding-top: 10px;
  padding-bottom: 10px;
  padding-left: 21px;
  align-items: center;
  background-color: ${({ isNew }) => (isNew ? '#f5f7fb' : '#fff')};
  border-bottom: 2px solid #fff;
  position: relative;
`
const NotificationItemDescription = styled.div`
  display: flex;
  flex: 1;
  /*margin-left: 21px;*/
  flex-direction: column;
`

const DoctorName = styled.div`
  font-weight: 500;
  color: #21293e;
  font-size: 14px;
`

const Description = styled.div`
  font-size: 14px;
  line-height: 1.71;
  letter-spacing: 0.13px;
  color: #7a7f8b;
`

const CounterInContainer = styled.div`
  font-size: 14px;
  font-weight: 500;
  letter-spacing: -0.12px;
  color: #ffffff;
  border-radius: 50px;
  background-color: #ea3a42;
  width: 20px;
  height: 20px;
  text-align: center;
  line-height: 1.45;
`

const Title = styled.div`
  display: flex;
  padding-right: 6px;
`
const Bullet = styled.div`
  font-size: 40px;
  color: #21c5a9;
  position: absolute;
  top: 10px;
  right: 10px;
  line-height: 0;
`

const useOutsideAlerter = (ref, cb) => {
  useEffect(() => {
    function handleClickOutside(event) {
      if (ref.current && !ref.current.contains(event.target)) {
        cb();
      }
    }
    document.addEventListener('mouseup', handleClickOutside);
    return () => {
      document.removeEventListener('mouseup', handleClickOutside);
    };
  }, [ref]);
}


export const NotificationsButton = () => {
  const wrapperRef = useRef(null);
  const dispatch = useDispatch()
  const count = useSelector(state => state.notification.count)
  const notifications = useSelector(state => state.notification)
  const [toggleView, settoggleView] = useState(false)
  const audio = new Audio(notification)
  const prevCount = useRef()
  const list = get(notifications, 'list.data', []);
  const userStatus = useSelector(state => state.auth.attributes.status)
  useOutsideAlerter(wrapperRef, () => settoggleView(false));


  audio.addEventListener('ended', () => {
    audio.currentTime = 0
  })

  useEffect(() => {
    prevCount.current = count
  }, [])

  useEffect(() => {
    if(userStatus !== "PRE_REGISTRATED"){
      dispatch(fetchNotification())
      dispatch(fetchGlobalNotification())
    }
  }, [dispatch])

  useEffect(() => {
    if (count > prevCount.current) {
      audio.play()
          .catch(e => console.warn(e))
    }

    if (prevCount.current !== count) {
      prevCount.current = count
    }
  }, [count])

  const clickNotificationHandler = (entityId, id, isRead, links) => {
    if (links && links.front) {
      history.push(links.front)
    }
    if (!entityId) {
      return;
    }
    if (!isRead) {
      dispatch(readNotification([id]));
    }
    history.push(`/panel/consultations/${entityId}`)
  };

  useEffect(() => {
    const idsInCurrentList = list.map(el => el.id);
    if(toggleView){
      dispatch(readNotification(idsInCurrentList))
    }
  }, [toggleView])

  return (
      <div ref={wrapperRef}>
        <RingButton onClick={() => settoggleView(!toggleView)}>
          <Badge badgeContent={count} color='error' invisible={!count}>
            <NotificationsIcon />
          </Badge>
        </RingButton>
        {toggleView ? (
            <NotificationsContainer>
              <NotificationsHeader>
                <NotificationsTitle>
                  <Title>
                    <FormattedMessage id='concilium.notifications' />
                  </Title>
                  {count ? <CounterInContainer>{count}</CounterInContainer> : null}
                </NotificationsTitle>
                <MarkAllReadButton onClick={() => dispatch(readAllNotifications())} color='primary'>
                  <FormattedMessage id='global.markasread' />
                </MarkAllReadButton>
              </NotificationsHeader>
              <NotificationsList>
                {
                  list.map((el) => {
                    //const image = '/static/media/default-avatar.b887eb6a.svg';
                    const entityId = get(el, 'attributes.consultationId', false);
                    const isRead = get(el, 'attributes.read', false);
                    const category = get(el,'attributes.category',false)
                    const links = get(el, 'attributes.links', []);
                    return (
                        <NotificationItem
                            key={el.id}
                            isNew={!isRead}
                        >
                          {/*<Avatar photo={image} />*/}
                          <NotificationItemDescription
                          >
                            <DoctorName
                                onClick={() => clickNotificationHandler(entityId, el.id, isRead, links)}
                            >
                              {get(el, 'attributes.title', '')}
                            </DoctorName>
                            <Description
                                onClick={() => clickNotificationHandler(entityId, el.id, isRead, links)}
                            >
                              {get(el, 'attributes.message', '')}
                            </Description>
                          </NotificationItemDescription>
                          {isRead ? null : <Bullet>•</Bullet>}
                        </NotificationItem>
                    )
                  })
                }
              </NotificationsList>
              <ViewAllButton onClick={() => history.push('/panel/profile/notifications')} color='primary'>
                <FormattedMessage id='global.viewall' />
              </ViewAllButton>
            </NotificationsContainer>
        ) : null}
      </div>
  )
}

import {createAction, handleActions} from "redux-actions";
import axios from "axios";
import {logOut} from "../redux/modules/auth";


export const createProfileRequest = createAction('CREATE_PROFILE_REQUEST')
export const createProfileSuccess = createAction('CREATE_PROFILE_SUCCESS')
export const createProfileFailure = createAction('CREATE_PROFILE_FAILURE')

export const createProfile = (values, type_employment, isDoctor, doctor_id, user_id, setDialogOpen, setLoading,myUserStatus ) => (dispatch) => {
  dispatch(createProfileRequest(values))
  const data = new FormData()
  const stringIsDoctor = isDoctor ? 'isDoctor' : 'isPatient'
  data.append('user_id', user_id)
  data.append('first_name',  values.first_name)
  data.append('last_name',  values.last_name)
  data.append('gender',  values.gender)
  data.append('hospital_name', values.hospital_name)
  data.append('city', values.city)
  data.append('postcode', values.postcode)
  data.append('country', values.country)
  data.append('practice_workplace', values.practice_workplace)
  data.append('is_doctor_or_patient', stringIsDoctor)

    if(isDoctor) {
      data.append('specialization_id', values.specialization_id)
      data.append('practice_employment_type', type_employment)
      data.append('rpps', values.rpps)
      data.append('adeli', values.adeli)
      data.append('finess', values.finess)
      data.append('doctor_id', doctor_id)
    }
  else{
      data.append('medical_insurance',   values.medical_insurance)
    }

  setLoading(true)

  return axios('api/user/registration/profile', {
    method: 'POST',
    data,
  })
      .then((response) => {
        return new Promise((resolve, reject) => {
          axios('/api/doctor/set_status_limited', {
            method: 'POST',
          })
            .then(_ => resolve(response))
            .catch(e => reject(e))
        })
      })
      .then((response) => {
        dispatch(createProfileSuccess())
        if (myUserStatus !== "PRE_REGISTRATED") {
          return setDialogOpen(true)
        }
      })
      .then(() => {
        if (myUserStatus !== "PRE_REGISTRATED") {
          return setTimeout(() => dispatch(logOut()), 4000)
        } else {
          return dispatch(logOut())
        }
      })
      .then(() => {
        if (myUserStatus === "PRE_REGISTRATED") {
          window.location.replace(`${window.env.API_URL}/redirectstore`)
        }
      })
      .catch(err => {
        console.error(err)
        return dispatch(createProfileFailure(err?.response?.data?.data.error_key))
      })


}

const defaultState = {
  errorAdeli : false,
  errorRpps : false,
}

export const profileaccess = handleActions({
  [createProfileFailure]: (state, action) => ({
    errorAdeli: action.payload === "adeli.not.unique",
    errorRpps: action.payload === "rpps.not.unique"
  }),
  [createProfileRequest]: () => (defaultState),
}, defaultState)
import React from 'react'
import PropTypes from 'prop-types'
import { Route } from 'react-router-dom'
import { withProps, compose, lifecycle } from 'recompose'
import { connect } from 'react-redux'
import { Drawer } from 'common/UiKit/Drawer'
import styled from 'styled-components'
import idx from 'idx'
import queryString from 'query-string'

import { fetchPreviousConsultations } from '../../../../../redux/modules/consultations'
import { Chat } from './Chat'
import { Menu } from './Menu'
import { history } from '../../../../../utils/history'
import { Preloader } from '../../../../common/Preloader'
import { fetchHealthRecords } from '../../../../../redux/modules/healthRecords';

const Wrapper = styled.div`
  position: relative;
  height: 100%;
`

const MainComponent = ({
  onPatientAvatarClick,
  isFetching,
  isCalling,
  location,
}) => {
  if (isFetching) {
    return <Preloader />
  }

  return (
    <Wrapper>
      <Route
        path='/panel/consultations/session/menu'
        children={({ match }) => (
          <Drawer
            open={!!match}
            DrawerComponent={Menu}
          >
            <Chat
              onPatientAvatarClick={onPatientAvatarClick}
              isCalling={isCalling}
              location={location}
            />
          </Drawer>
        )}
      />
    </Wrapper>
  )
}

MainComponent.propTypes = {
  onPatientAvatarClick: PropTypes.func.isRequired,
  isFetching: PropTypes.bool.isRequired,
}

const enhance = compose(
  connect(
    state => ({
      patient: idx(state, _ => _.consultations.consultation.relationships.patient.data.id),
      isFetching: state.loading.consultation,
      consultationId: state.consultations.consultation.id,
    }),
    { fetchPreviousConsultations, fetchHealthRecords },
  ),
  withProps(props => ({
    onPatientAvatarClick: () => history.push('/panel/consultations/session/menu/profile'),
    isCalling: !!queryString.parse(props.location.search).call,
  })),
  lifecycle({
    componentDidMount() {
      this.props.fetchPreviousConsultations(this.props.patient)

      if (!this.props.patient) {
        this.props.fetchHealthRecords(this.props.consultationId)
      }

      if (this.props.isCalling) {
        setTimeout(() => history.push(this.props.match.url), 1000)
      }
    },
  }),
)

export const Main = enhance(MainComponent)

import React from 'react'
import styled from 'styled-components'
import {Colors} from "../../../../constants/colors";
import defaultAvatar from "../../../../images/panel/default-avatar.svg";
import moment from 'moment-timezone'
import {useSelector} from "react-redux";
import GroupsIcon from '@mui/icons-material/Groups';
import essLogo from "../../../Panel/Doctor/Community/ROSO/images/Logo_ESS.png";
import esspLogo from "../../../Panel/Doctor/Community/ROSO/images/Logo_ESSP.png";
import {useIntl} from 'react-intl'
import {
  DISCUSSION_TYPE__GROUP,
  DISCUSSION_TYPE__INDIVIDUAL,
  DISCUSSION_TYPE__TEAM
} from "../../../../constants/discussion";

const Name = styled.div`
  font-size: 16px;
  font-weight: 500;
  grid-column: 2;
  grid-row: 1;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
`

const Wrapper = styled.div`
  display: grid;
  width: 100%;
  grid-template-columns: 80px calc(100% - 80px - 40px) 40px;
  grid-template-rows: 1fr 1fr;
  padding: 10px 10px 10px 0;
  box-sizing: border-box;
  :hover {
    background-color: ${Colors.purpleGrey};  
    cursor: pointer;
  }
  background-color: ${({isSelected}) => isSelected ? Colors.purpleGrey : 'none'};  
`

const Avatar = styled.div`
  justify-self: center;
  min-width: 43px;
  width: 43px;
  height: 43px;
  box-sizing: border-box;
  border-radius: 100%;
  background: url(${props => props.photo || defaultAvatar}) ${Colors.lightGrey} center no-repeat; 
  cursor: ${props => (!props.onClick ? 'default' : 'pointer')};
  background-size: cover;
  grid-row: 1/3;
  grid-column: 1;
`

const Date = styled.div`
  grid-column: 3;
  grid-row: 1;
  color: ${Colors.footerGrey};
  justify-self: center;
  font-size: 14px;
`

const Unread = styled.div`
  color: white;
  background-color: ${Colors.bluePurple};
  width: 25px;
  height: 25px;
  font-size: 14px;
  border-radius: 100%;
  display: flex;
  align-items:center;
  justify-content:center;
  justify-self: center;
`

const Message = styled.div`
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  font-size: 14px;
  min-width: 100%;
  font-weight: ${({bold}) => bold ? '500' : 'none'};
`

const GroupIconStyled = styled(GroupsIcon)`
  justify-self: center;
  min-width: 43px;
  width: 43px;
  height: 43px;
  padding: 3px;
  box-sizing: border-box;
  border-radius: 100%;
  cursor: ${props => (!props.onClick ? 'default' : 'pointer')};
  border: 3px solid ${Colors.bluePurple};
  background-color: rgba(147, 132, 189, 0.5);
  color: ${Colors.bluePurple};
  grid-row: 1/3;
  grid-column: 1;
`

const TeamLogo = styled.img`
  justify-self: center;
  align-self: center;
  width: 55px;
  grid-row: 1/3;
  grid-column: 1;
`

const getDate = (date, translator) => {
  if(!date) {
    return ''
  }

  let a = moment(date).utc(true).local()
  let now = moment().millisecond(0).second(0).minute(0).hour(0)
  const daysSinceDate = moment.duration(now.diff(a.millisecond(0).second(0).minute(0).hour(0))).asDays()

  if(daysSinceDate < 1) {
    return moment(date).utc(true).local().format('HH:mm')
  } else if(daysSinceDate < 2) {
    return translator('global.yesterday')
  } else if(daysSinceDate < 7) {
    return moment(date).utc(true).format('dddd')
  } else {
    return moment(date).utc(true).format('DD/MM')
  }
}

const lastMessageByType = (message, messageType, senderId, senderName, type) => {
  const id = useSelector(state => state.auth.attributes.user_id);
  const { formatMessage } = useIntl()

  if (messageType == 'File') {
    if(!message) {
      return ''
    } else if (id == senderId) {
      return formatMessage({id: 'discussion.send.my.file'})
    } else {
      return formatMessage({id: 'discussion.send.file'}, {userName: senderName})
    }
  }

  if(!message) {
    return ''
  } else if (id == senderId) {
    return formatMessage({id: 'discussion.you'}) + " : " + message
  } else if (type === DISCUSSION_TYPE__INDIVIDUAL) {
    return message
  } else {
    return message && senderName + " : " + message
  }
}

export const photoByType = (type, photo, teamType) => {
  if (type === DISCUSSION_TYPE__INDIVIDUAL) {
    return <Avatar photo={photo}/>
  } else if (type === DISCUSSION_TYPE__GROUP) {
    return <GroupIconStyled/>
  } else if (type === DISCUSSION_TYPE__TEAM) {
    return <TeamLogo src={teamType === 'ESS' ? essLogo : esspLogo}/>
  }
}

export const Item = ({discussion, onClick, idSelected}) => {
  const { formatMessage } = useIntl()
  const f = id => formatMessage({ id: id })
  const id = discussion.id
  const name = discussion.attributes.name
  const lastMessageDate = discussion.relationships.chat.data.attributes.lastMessageTime
  const lastMessage = discussion.relationships.chat.data.attributes.lastMessage
  const lastMessageType = discussion.relationships.chat.data.attributes.lastMessageType
  const unread = discussion.relationships.chat.data.attributes.unread_messages_count
  const lastSender = discussion.relationships.chat.data.attributes.firstnameOfLastSender
  const type = discussion.attributes.type
  const idOfLastSender = discussion.relationships.chat.data.attributes.idOfLastSender
  const photo = discussion.attributes.photo
  const teamType = discussion.relationships.team?.data.attributes.type

  return (
    <Wrapper onClick={onClick} isSelected={id===idSelected}>
      {photoByType(type, photo, teamType)}
      <Name> {name} </Name>
      <Date> {getDate(lastMessageDate, f)} </Date>
      <Message bold={unread}>{lastMessageByType(lastMessage, lastMessageType, idOfLastSender, lastSender, type)}</Message>
      {unread ? <Unread>{unread <= 99 ? unread : "+99"}</Unread> : null}
    </Wrapper>
  )
}


import React, { useState } from 'react'
import styled from 'styled-components'
import { compose, withProps } from 'recompose'
import { EditPage } from '../../../common/UiKit/EditPage'
import { history } from '../../../../utils/history';
import { TextEditorComponent } from '../../../common/Settings/TextEditor';
import {useDispatch, useSelector} from "react-redux";
import {updateRequisInstructions} from "../../../../redux/modules/doctors";

const Wrapper = styled.div`
  margin-top: 24px;
`

const EditInstructionsComponent = ({ onBackIconClick }) => {
  const dispatch = useDispatch()
  const instructions = useSelector(state => state.doctors.instructions)
  const [texteState, setTexteState] = useState(instructions);
  const PostInstructions = async () => {
    await dispatch(updateRequisInstructions(texteState))
  }

  return (
    <EditPage
      label='profile.instructions'
      buttonText='global.save.language'
      onBackIconClick={onBackIconClick}
      onButtonClick={async () => {
        await PostInstructions();
        history.goBack();
      }}
      background
    >
      <Wrapper>
        <TextEditorComponent texteState={texteState} setTexteState={setTexteState} />
      </Wrapper>
    </EditPage>
  )
}

const enhance = compose(
  withProps(() => ({
    onBackIconClick: () => history.goBack(),
  })),
)
export const EditInstructions = enhance(EditInstructionsComponent)

import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import idx from 'idx'
import { compose, withProps, withState } from 'recompose'
import { connect, useSelector } from 'react-redux'
import { Header } from '../Header'
import { Card } from './Card'
import { EmptyScreen } from './EmptyScreen'
import { fetchConsultation, deleteSessionData } from '../../../../../../redux/modules/consultations'
import { fetchDoctorReviews, sendReview } from '../../../../../../redux/modules/reviews'
import { fetchDoctor } from '../../../../../../redux/modules/doctors'
import { history } from '../../../../../../utils/history'
import { Preloader } from '../../../../../common/Preloader';
import {matchByName} from "../../../../../../helpers/consultations";
import { CONSULTATION_TYPES } from '../../../../../../constants/consultation'
import {Skeleton} from "@mui/material";


const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: start;
  align-item: center;
  @media screen and (max-width: 4000px) {
    margin-left: calc( 12vw - 95px);
    width: 82%;
  }
  @media screen and (max-width: 1800px) {
      //margin-left: 1vw;
  }
  @media screen and (max-width: 1400px) {
    //margin-left: calc( 16vw - 30px);
    //width: 78%;
  }
`

export const ConsultationsListComponent = ({
  consultations,
  onConsultationClick,
  reviews,
  reviewsFetching,
  fetchDoctorReviews,
  onRepeatClick,
  sendReview,
  loading,
  filters,
}) => {

  const search = useSelector(state => state.settings.consultationSearch)
  const consultationFilteredForPatient = consultations.filter(item =>
      item.attributes.type === CONSULTATION_TYPES.urgent ||
      item.attributes.type === CONSULTATION_TYPES.scheduled
  )

  const filteredList = consultationFilteredForPatient.filter(matchByName(search))


return (
  <Wrapper>
    <Header/>
    {
      (!loading && filteredList.length)
        ? filteredList.map(item =>
            <div key={item.id}>
                <Card
                  gender={idx(item, _ => _.relationships.doctor.data.attributes.gender)}
                  type={idx(item, _ => _.attributes.type)}
                  doctorId={idx(item, _ => _.relationships.doctor.data.id)}
                  timeCreation={idx(item, _ => _.attributes.creation_time)}
                  status={idx(item, _ => _.attributes.status)}
                  firstName={idx(item, _ => _.relationships.doctor.data.attributes.first_name)}
                  lastName={idx(item, _ => _.relationships.doctor.data.attributes.last_name)}
                  photo={idx(item, _ => _.relationships.doctor.data.attributes.photo)}
                  specialization={idx(
                    item,
                    _ => _.relationships.doctor.data.attributes.specialization,
                  )}
                  specialization_type={idx(
                    item,
                    _ => _.relationships.doctor.data.attributes.specialization_type,
                  )}
                  language={idx(item, _ => _.relationships.doctor.data.attributes.language)}
                  date={idx(item, _ => _.attributes.proposed_date)}
                  urgent={idx(item, _ => _.attributes.type) === 'urgent'}
                  created={idx(item, _ => _.attributes.status) === 'created'}
                  isCompleted={(idx(item, _ => _.attributes.status) === 'completed')}
                  onClick={onConsultationClick(idx(item, _ => _.id))}
                  id={item.id}
                  isConcilium={item.attributes.consiliumStatus}
                  filters={filters}
                  completedProps={idx(item, _ => _.attributes.status) === 'completed' && {
                    reviews: reviews[idx(item, _ => _.relationships.doctor.data.id)],
                    reviewsFetching: reviewsFetching.includes(idx(item, _ => _.relationships.doctor.data.id)),
                    fetchDoctorReviews: () => fetchDoctorReviews(idx(item, _ => _.relationships.doctor.data.id)),
                    onRepeatClick: () => onRepeatClick(idx(item, _ => _.relationships.doctor.data.id)),
                  }}
                />
            </div>
        )
        : (
          <>
            {
              loading ?
              <>
                <Skeleton variant="circular" width={60} height={60} />
                <br/>
                <Skeleton variant="rectangular"  height={40} />
                <br/>
              </>
                :
              <EmptyScreen />
            }
          </>
        )
    }
  </Wrapper>)
}

ConsultationsListComponent.defaultProps = {
  consultations: [],
}

ConsultationsListComponent.propTypes = {
  consultations: PropTypes.arrayOf(PropTypes.object),
  onConsultationClick: PropTypes.func.isRequired,
  reviews: PropTypes.object.isRequired,
  reviewsFetching: PropTypes.array.isRequired,
  fetchDoctorReviews: PropTypes.func.isRequired,
  onRepeatClick: PropTypes.func.isRequired,
  sendReview: PropTypes.func.isRequired,
}

const enhance = compose(
  connect(
    state => ({
      reviews: state.reviews,
      reviewsFetching: state.loading.reviews,
      loading: state.loading.consultationsList,
    }),
    {
      fetchConsultation,
      deleteSessionData,
      fetchDoctor,
      fetchDoctorReviews,
      sendReview,
    },
  ),
  withProps(props => ({
    onConsultationClick: id => () => props.fetchConsultation(id)
      .then(() => props.afterConsultationClick()),
    onRepeatClick: id => props.fetchDoctor(id)
      .then(() => history.push('/panel/doctors/appointment/create')),
  })),
)

export const ConsultationsList = enhance(ConsultationsListComponent)

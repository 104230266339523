import React from 'react';
import {FormattedMessage} from 'react-intl'
import ClearIcon from '@mui/icons-material/Clear';
import Button from "../Button";
import {Colors} from "../../../../constants/colors";
import styled from "styled-components";
import {Dialog, DialogContent, DialogTitle} from "@material-ui/core";
import PropTypes from "prop-types";
import DialogActions from "@mui/material/DialogActions";

export const ConfirmDialog = ({open, onClose, onConfirm, title, confirmationMessage, cancelButton, confirmButton}) => (
  <DialogStyled
    open={open}
    onClose={onClose}
    maxWidth='lg'
    PaperProps={{
      style: {
        minWidth: '25%',
        border: `1px solid ${Colors.bluePurple}`,
        padding: '10px',
      }
    }}
    BackdropProps={{
      style: {
        opacity: 0,
      }
    }}
  >
    <>
      <StyledDialogTitle disableTypography>
        <StyledTitle>
          <FormattedMessage id={title} />
        </StyledTitle>
        <StyledClearIcon onClick={onClose}><ClearIcon/></StyledClearIcon>
      </StyledDialogTitle>
      <DialogContentStyled>
        <FormattedMessage id={confirmationMessage} />
      </DialogContentStyled>
      <DialogActionsStyled>
        <Button variant='outlined' onClick={onClose} large>
          <FormattedMessage id={cancelButton} />
        </Button>
        <Button variant='contained' onClick={onConfirm} large>
          <FormattedMessage id={confirmButton} />
        </Button>
      </DialogActionsStyled>
    </>
  </DialogStyled>
)

ConfirmDialog.propTypes = {
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  onConfirm: PropTypes.func.isRequired,
  title: PropTypes.string,
  confirmationMessage: PropTypes.string.isRequired,
  cancelButton: PropTypes.string,
  confirmButton: PropTypes.string,
}

ConfirmDialog.defaultProps = {
  title: 'global.confirmation',
  cancelButton: 'global.not',
  confirmButton: 'global.yes',
}

const DialogStyled = styled(Dialog)`
  backdrop-filter: blur(3px);
`

const StyledDialogTitle = styled(DialogTitle)`
  font-family: Roboto, sans-serif;
  display: flex;
  font-size: 20px;
  font-weight: 500;
  opacity: 0.9;
  color: ${Colors.dark80};
  border-bottom: 1px solid ${Colors.cloudyBlue};  
`

const StyledClearIcon = styled.div`
  cursor: pointer;
`
const StyledTitle = styled.div`
  margin: auto;
`

const DialogContentStyled = styled(DialogContent)`
  display: flex;
  justify-content: center;
  align-items: center;
`

const DialogActionsStyled = styled(DialogActions)`
  display: flex;
  justify-content: center;
  align-items: center;
`

import { ESTransporter } from 'searchkit'
import type {ElasticsearchResponseBody, SearchRequest} from "searchkit"

export class CustomTransporterES extends ESTransporter {

  constructor(applicationSearch) {
    super({},{});
    this.appSearch = applicationSearch ? window.env.APP_ENV+applicationSearch : window.env.APP_ENV+'_general_search_v1';
  }
  async performNetworkRequest(requests: SearchRequest[]) {
    const headers = connectionHeader();

    let url = `${window.env.ELASTICSEARCH_URL}/${this.appSearch}/_search`;
    let body = this.createBodyAppSearch(requests);

    if (!window.env.ELASTICSEARCH_AUTH_BASIC){
      url = `${window.env.ELASTICSEARCH_URL}/_msearch`;
      body = this.createElasticsearchQueryFromRequest(requests);
    }

    return fetch(url, {
      headers,
      body: body,
      method: 'POST'
    })
  }

  // Override for appSearchResult singleResult treatment
  async msearch(requests: SearchRequest[]): Promise<ElasticsearchResponseBody[]> {
    try {
      const response = await this.performNetworkRequest(requests)
      let responses = await response.json()

      if (this.settings.debug) {
        console.log('Elasticsearch response:')
        console.log(JSON.stringify(responses))
      }

      if (responses.status >= 500) {
        console.error(JSON.stringify(responses))
        throw new Error(
          'Elasticsearch Internal Error: Check your elasticsearch instance to make sure it can recieve requests.'
        )
      } else if (responses.status === 401) {
        console.error(JSON.stringify(responses))
        throw new Error(
          'Cannot connect to Elasticsearch. Check your connection host and auth details (username/password or API Key required). You can also provide a custom Elasticsearch transporter to the API Client. See https://www.searchkit.co/docs/guides/setup-elasticsearch#connecting-with-usernamepassword for more details.'
        )
      } else if (responses.responses?.[0]?.status === 403) {
        console.error(JSON.stringify(responses))
        throw new Error(
          'Auth Error: You do not have permission to access this index. Check you are calling the right index (specified in frontend) and your API Key permissions has access to the index.'
        )
      } else if (responses.status === 404 || responses.responses?.[0]?.status === 404) {
        console.error(JSON.stringify(responses))
        throw new Error(
          'Elasticsearch index not found. Check your index name and make sure it exists.'
        )
      } else if (responses.status === 400 || responses.responses?.[0]?.status === 400) {
        console.error(JSON.stringify(responses))
        throw new Error(
          `Elasticsearch Bad Request. 
          
          1. Check your query and make sure it is valid. 
          2. Check the field mapping. See documentation to make sure you are using text types for searching and keyword fields for faceting
          3. Turn on debug mode to see the Elasticsearch query and the error response.
          `
        )
      }

      // Surcouche avec appSearch
      let responseFinal = [];

      Array.isArray(responses?.responses) ? responseFinal = responses.responses : responseFinal.push(responses);

      return responseFinal
    } catch (error) {
      throw error
    }
  }

  createBodyAppSearch(requests: SearchRequest[]) {
     const query = recursiveResearchPropertyValue(requests[0], 'query', 'string');
     const from = recursiveResearchPropertyValue(requests[0], 'from', 'number');
     const location = recursiveResearchPropertyValue(requests[0], 'location', 'object');
     let pinned = recursiveResearchPropertyValue(requests[0], 'pinned', 'object');
     pinned ? pinned = pinned.ids[0] : pinned;
     let fields = recursiveResearchPropertyValue(requests[0], 'fields', 'object');
     if(fields?.length){
      fields = fields.map((field, key)=> {
        return {"value": field, "last": fields.length-1 === key}
      })
     }


     // treatment object no value for template elasticsearch
     let arr = [{query}, {from}, {location}, {pinned}, {fields}];
     arr = arr.filter((object) => !!object[Object.keys(object)]);
     let params= {};

     arr.forEach((field) => params = {...params, ...field});

    return JSON.stringify({params});
  }
}

function connectionHeader() {
  return {
    'Access-Control-Allow-Origin': '*',
    'Authorization': window.env.ELASTICSEARCH_AUTH_BASIC,
    'Content-Type': 'application/json'
  };
}

function recursiveResearchPropertyValue(JsonToSearch, propertyName: string, instanceOfSearched: string){
  let result = false;

  if (!JsonToSearch || typeof JsonToSearch !== 'object') {
    console.warn('json required on request eleastic.');
    return false;
  }

  const keys = Object.keys(JsonToSearch);

  keys.some((key) => {
    if (key === propertyName && typeof JsonToSearch[key] === instanceOfSearched) {
      result = JsonToSearch[key];
      return true;
    } else if (typeof JsonToSearch[key] === 'object') {
      const resultRecursive = recursiveResearchPropertyValue(JsonToSearch[key], propertyName, instanceOfSearched);
      if (resultRecursive !== false){
        result = resultRecursive;
        return true;
      }
    }
    return false;
  });
  return result;
}
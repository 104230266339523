import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import { connect } from 'react-redux'
import { LinkCard } from 'common/UiKit/LinkCard'
import moment from 'moment-timezone'

import stubImage from '../../../../../../../images/panel/doctors/consultations-empty-screen.svg'
import { EmptyStub } from '../EmptyStub'
import { fetchConsultation } from '../../../../../../../redux/modules/consultations'
import { CONSULTATION_TYPES } from '../../../../../../../constants/consultation';

const Wrapper = styled.div`
  margin-top: 24px;

  & > * {
    margin-bottom: 8px;
  }
`

const ConsultationsTabComponent = ({
  consultations,
  fetchConsultation,
}) => (
  <Wrapper>
    { consultations.length
      ? consultations.map((item) => {
        const date = moment(item.attributes.proposed_date).format('ddd, MMM DD')
        const timeFrom = moment(item.attributes.proposed_date).format('HH:mm')
        const consultationLength = window.env.CONSULTATION_LENGTH || 30
        const timeTo = moment(item.attributes.proposed_date).add(consultationLength, 'minutes').format('HH:mm A')

        return (
          <LinkCard
            label={date}
            text={`${timeFrom} - ${timeTo}`}
            onClick={() => fetchConsultation(item.id)}
            key={item.id}
          />
        )
      })
      : (
        <EmptyStub
          image={stubImage}
          heading='consultations.no.previous'
        />
      )}
  </Wrapper>
)

ConsultationsTabComponent.propTypes = {
  consultations: PropTypes.arrayOf(PropTypes.object).isRequired,
  fetchConsultation: PropTypes.func.isRequired,
}

const enhance = connect(
  state => ({
    consultations: state
      .consultations.consultation.attributes.type === CONSULTATION_TYPES.telexpertise
      ? [] : state.consultations.previous,
  }),
  { fetchConsultation },
)

export const ConsultationsTab = enhance(ConsultationsTabComponent)

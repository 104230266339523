import React, { useEffect, useState } from 'react';
import styled from 'styled-components'
import PropTypes from 'prop-types'
import { withProps } from 'recompose'
import { Form, Field } from 'react-final-form'
import MailOutlineIcon from '@mui/icons-material/MailOutline';
import { FormattedMessage } from 'react-intl'
import { useDispatch, useSelector } from 'react-redux';
import get from 'lodash/get';
import MessageIcon from '@mui/icons-material/Message';
import { Paper } from '../UiKit/Paper'
import { Colors } from '../../../constants/colors'
import { RadioInput } from '../FinalFormFields/RadioInput';
import { fetchSettings, updateSettings } from '../../../redux/modules/settings';
import { history } from '../../../utils/history';
import { SwitchField } from '../UiKit/SwitchField';

const PaperStyled = styled(Paper)`
  margin-bottom: 16px;
  padding: 12px 24px;
`

const Line = styled.div`
  margin-bottom: 24px;
  font-size: 16px;
  display: flex;
  align-items: center;
  display: flex;
  justify-content: space-between;
`

const CheckboxContainer = styled.div`
  font-size: 14px;
  color: ${Colors.blueGrey};
`

const Label = styled.span`
  opacity: 0.8;
  font-weight: 500;
  font-size: 16px;
  color: ${Colors.dark};
  line-height: 2;
`

const RadioWrapper = styled.div`
  margin-top: 8px;
`

const REMINDER_TIME = [
  { label: <FormattedMessage id='global.15.min' />, value: '15' },
  { label: <FormattedMessage id='global.30.min' />, value: '30' },
  { label: <FormattedMessage id='global.45.min' />, value: '45' },
  { label: <FormattedMessage id='global.1.h' />, value: '60' },
]

const PILL = [
  { label: <FormattedMessage id='global.none' />, value: 'none' },
  { label: '9:00', value: '9' },
  { label: '14:00', value: '14' },
  { label: '9:00, 18:00', value: '918' },
  { label: '9:00, 14:00, 18:00', value: '91418' },
]

const NotificationsComponent = ({
  pills,
}) => {
  const dispatch = useDispatch();

  const onSubmit = values => dispatch(updateSettings(values))
    .then(() => dispatch(fetchSettings()))
    .then(() => history.goBack())
  const initialValues = useSelector(state => state.settings.values)

  useEffect(() => {
    dispatch(fetchSettings())
  }, [dispatch])

  return (
    <Form
      onSubmit={onSubmit}
      initialValues={{
        ...initialValues,
        notifications_enabled: get(initialValues, 'notifications_enabled', 0) === '1',
        mail_opinion_te: get(initialValues, 'mail_opinion_te', 0) === '1',
        sms_opinion_te: get(initialValues, 'sms_opinion_te', 0) === '1',
        mail_message_received_te: get(initialValues, 'mail_message_received_te', 0) === '1',
        sms_message_received_te: get(initialValues, 'sms_message_received_te', 0) === '1',
        mail_cancelled_te: get(initialValues, 'mail_cancelled_te', 0) === '1',
        sms_cancelled_te: get(initialValues, 'sms_cancelled_te', 0) === '1',
        mail_accepted_ter: get(initialValues, 'mail_accepted_ter', 0) === '1',
        sms_accepted_ter: get(initialValues, 'sms_accepted_ter', 0) === '1',
        mail_refused_ter: get(initialValues, 'mail_refused_ter', 0) === '1',
        sms_refused_ter: get(initialValues, 'sms_refused_ter', 0) === '1',
        mail_completed_te: get(initialValues, 'mail_completed_te', 0) === '1',
        sms_completed_te: get(initialValues, 'sms_completed_te', 0) === '1',
        mail_not_finished_te: get(initialValues, 'mail_not_finished_te', 0) === '1',
        sms_not_finished_te: get(initialValues, 'sms_not_finished_te', 0) === '1',
        mail_request_tc: get(initialValues, 'mail_request_tc', 0) === '1',
        sms_request_tc: get(initialValues, 'sms_request_tc', 0) === '1',
        mail_cancelled_tc: get(initialValues, 'mail_cancelled_tc', 0) === '1',
        sms_cancelled_tc: get(initialValues, 'sms_cancelled_tc', 0) === '1',
        mail_ess_essp: get(initialValues, 'mail_ess_essp', 0) === '1',
        sms_ess_essp: get(initialValues, 'sms_ess_essp', 0) === '1',
      }}
      subscription={{}}
      render={({ handleSubmit }) => (
        <form onSubmit={handleSubmit} id='notifications'>
          {/* eslint-disable-next-line react/jsx-no-bind */}
          <PaperStyled heading='consultation.teleexpertise' >
            <Line>
              <div>
                <Label>
                  <FormattedMessage id='profile.reminder.time' />
                </Label>
                <RadioWrapper>
                  <Field
                    id='notifications_reminder_time'
                    name='notifications_reminder_time'
                    component={RadioInput}
                    options={REMINDER_TIME}
                  />
                </RadioWrapper>
              </div>
            </Line>
            <Line>
              <CheckboxContainer>
                <Label>
                  <FormattedMessage id='consultation.request.opinion.recieved' />
                </Label>
              </CheckboxContainer>
              <div>
                <Field
                  name='mail_opinion_te'
                  component={SwitchField}
                  color='primary'
                  type='checkbox'
                  checkedIcon={<MailOutlineIcon />}
                  icon={<MailOutlineIcon sx={{ color: '#353130' }} />}
                />
                <Field
                  name='sms_opinion_te'
                  component={SwitchField}
                  color='primary'
                  type='checkbox'
                  checkedIcon={<MessageIcon />}
                  icon={<MessageIcon sx={{ color: '#353130' }} />}
                />
              </div>
            </Line>
            {/*<Line>*/}
            {/*  <CheckboxContainer>*/}
            {/*    <Label>*/}
            {/*      <FormattedMessage id='consultation.message.recieved' />*/}
            {/*    </Label>*/}
            {/*  </CheckboxContainer>*/}
            {/*  <div>*/}
            {/*    <Field*/}
            {/*      name='mail_message_received_te'*/}
            {/*      component={SwitchField}*/}
            {/*      color='primary'*/}
            {/*      type='checkbox'*/}
            {/*      checkedIcon={<MailOutlineIcon />}*/}
            {/*      icon={<MailOutlineIcon sx={{ color: '#353130' }} />}*/}
            {/*    />*/}
            {/*    <Field*/}
            {/*      name='sms_message_received_te'*/}
            {/*      component={SwitchField}*/}
            {/*      color='primary'*/}
            {/*      type='checkbox'*/}
            {/*      checkedIcon={<MessageIcon />}*/}
            {/*      icon={<MessageIcon sx={{ color: '#353130' }} />}*/}
            {/*    />*/}
            {/*  </div>*/}
            {/*</Line>*/}
            <Line>
              <CheckboxContainer>
                <Label>
                  <FormattedMessage id='consultation.request.completed' />
                </Label>
              </CheckboxContainer>
              <div>
                <Field
                  name='mail_completed_te'
                  component={SwitchField}
                  color='primary'
                  type='checkbox'
                  checkedIcon={<MailOutlineIcon />}
                  icon={<MailOutlineIcon sx={{ color: '#353130' }} />}
                />
                <Field
                  name='sms_completed_te'
                  component={SwitchField}
                  color='primary'
                  type='checkbox'
                  checkedIcon={<MessageIcon />}
                  icon={<MessageIcon sx={{ color: '#353130' }} />}
                />
              </div>
            </Line>
            <Line>
              <CheckboxContainer>
                <Label>
                  <FormattedMessage id='consultation.request.cancelled' />
                </Label>
              </CheckboxContainer>
              <div>
                <Field
                  name='mail_cancelled_te'
                  component={SwitchField}
                  color='primary'
                  type='checkbox'
                  checkedIcon={<MailOutlineIcon />}
                  icon={<MailOutlineIcon sx={{ color: '#353130' }} />}
                />
                <Field
                  name='sms_cancelled_te'
                  component={SwitchField}
                  color='primary'
                  type='checkbox'
                  checkedIcon={<MessageIcon />}
                  icon={<MessageIcon sx={{ color: '#353130' }} />}
                />
              </div>
            </Line>
            <Line>
              <CheckboxContainer>
                <Label>
                  <FormattedMessage id='consultation.request.accepted' />
                </Label>
              </CheckboxContainer>
              <div>
                <Field
                    name='mail_accepted_ter'
                    component={SwitchField}
                    color='primary'
                    type='checkbox'
                    checkedIcon={<MailOutlineIcon />}
                    icon={<MailOutlineIcon sx={{ color: '#353130' }} />}
                />
                <Field
                    name='sms_accepted_ter'
                    component={SwitchField}
                    color='primary'
                    type='checkbox'
                    checkedIcon={<MessageIcon />}
                    icon={<MessageIcon sx={{ color: '#353130' }} />}
                />
              </div>
            </Line>
            <Line>
              <CheckboxContainer>
                <Label>
                  <FormattedMessage id='consultation.request.refused' />
                </Label>
              </CheckboxContainer>
              <div>
                <Field
                    name='mail_refused_ter'
                    component={SwitchField}
                    color='primary'
                    type='checkbox'
                    checkedIcon={<MailOutlineIcon />}
                    icon={<MailOutlineIcon sx={{ color: '#353130' }} />}
                />
                <Field
                    name='sms_refused_ter'
                    component={SwitchField}
                    color='primary'
                    type='checkbox'
                    checkedIcon={<MessageIcon />}
                    icon={<MessageIcon sx={{ color: '#353130' }} />}
                />
              </div>
            </Line>
            {/*<Line>*/}
            {/*  <CheckboxContainer>*/}
            {/*    <Label>*/}
            {/*      <FormattedMessage id='consultation.request.not.finished' />*/}
            {/*    </Label>*/}
            {/*  </CheckboxContainer>*/}
            {/*  <div>*/}
            {/*    <Field*/}
            {/*      name='mail_not_finished_te'*/}
            {/*      component={SwitchField}*/}
            {/*      color='primary'*/}
            {/*      type='checkbox'*/}
            {/*      checkedIcon={<MailOutlineIcon />}*/}
            {/*      icon={<MailOutlineIcon sx={{ color: '#353130' }} />}*/}
            {/*    />*/}
            {/*    <Field*/}
            {/*      name='sms_not_finished_te'*/}
            {/*      component={SwitchField}*/}
            {/*      color='primary'*/}
            {/*      type='checkbox'*/}
            {/*      checkedIcon={<MessageIcon />}*/}
            {/*      icon={<MessageIcon sx={{ color: '#353130' }} />}*/}
            {/*    />*/}
            {/*  </div>*/}
            {/*</Line>*/}

            {
              (pills && false) && (
                <Line>
                  <div>
                    <Label>
                      <FormattedMessage id='profile.take.pill' />
                    </Label>
                    <RadioWrapper>
                      <Field
                        id='pill'
                        name='pill'
                        component={RadioInput}
                        options={PILL}
                      />
                    </RadioWrapper>
                  </div>
                </Line>
              )
            }
          </PaperStyled>

          {/* eslint-disable-next-line react/jsx-no-bind */}
          <PaperStyled heading='consultation.teleconsultation'>
            <Line>
              <div>
                <Label>
                  <FormattedMessage id='profile.reminder.time' />
                </Label>
                <RadioWrapper>
                  <Field
                    id='notifications_reminder_time_tc'
                    name='notifications_reminder_time_tc'
                    component={RadioInput}
                    options={REMINDER_TIME}
                  />
                </RadioWrapper>
              </div>
            </Line>
            <Line>
              <CheckboxContainer>
                <Label>
                  <FormattedMessage id='consultation.request.tc.requested' />
                </Label>
              </CheckboxContainer>
              <div>
                <Field
                  name='mail_request_tc'
                  component={SwitchField}
                  color='primary'
                  type='checkbox'
                  checkedIcon={<MailOutlineIcon />}
                  icon={<MailOutlineIcon sx={{ color: '#353130' }} />}
                />
                <Field
                  name='sms_request_tc'
                  component={SwitchField}
                  color='primary'
                  type='checkbox'
                  checkedIcon={<MessageIcon />}
                  icon={<MessageIcon sx={{ color: '#353130' }} />}
                />
              </div>
            </Line>
            <Line>
              <CheckboxContainer>
                <Label>
                  <FormattedMessage id='consultation.tc.cancelled' />
                </Label>
              </CheckboxContainer>
              <div>
                <Field
                  name='mail_cancelled_tc'
                  component={SwitchField}
                  color='primary'
                  type='checkbox'
                  checkedIcon={<MailOutlineIcon />}
                  icon={<MailOutlineIcon sx={{ color: '#353130' }} />}
                />
                <Field
                  name='sms_cancelled_tc'
                  component={SwitchField}
                  color='primary'
                  type='checkbox'
                  checkedIcon={<MessageIcon />}
                  icon={<MessageIcon sx={{ color: '#353130' }} />}
                />
              </div>
            </Line>
          </PaperStyled>
        </form>
      )}
    />
  )
}

NotificationsComponent.defaultProps = {
  pills: false,
}

NotificationsComponent.propTypes = {
  pills: PropTypes.bool,
}

const enhance = withProps(props => ({
  onSubmit: v => props.onSubmit(v).then(() => props.afterSubmit()),
}))

export const Notifications = enhance(NotificationsComponent)

import React from 'react'
import styled from 'styled-components'
import { Colors } from 'constants/colors'
import { Paper } from 'common/UiKit/Paper'
import { FormattedMessage } from 'react-intl'
import {
  createTheme, ThemeProvider, StyledEngineProvider, 
} from '@mui/material/styles';
import appLogo from '../../../images/panel/logo1.png'
import {history} from "../../../utils/history";
import AddIcon from "@mui/icons-material/Add";
import {TextButton} from "../UiKit/TextButton";
import {BUTTON_COLORS} from "../../../constants/colors";

const theme = createTheme(({
  palette: {
    primary: {
      main: '#6633ff',
    },
  },
}));

const Wrapper = styled(Paper)`
  margin-top: 20px;
  padding: 26px 0 26px;
  margin-bottom: 20px;
  display: flex;
  align-items: center;
`

const Logo = styled.img`
  width: 320px;
`

const Content = styled.div`
  margin-left: 30px;
`

const Message = styled(FormattedMessage)`
  width: 600px;
`


const Copy = styled.p`
  display: flex;
  align-items: space-around;
  justify-content: center;
  margin: 16px 0 0;
  font-size: 16px;
  opacity: 0.8;
  color: ${Colors.blueGrey};
`
const Button = styled(TextButton)`
  box-shadow: none;
  && {
    width: 30%;
    margin-left: 200px;
    background-color: ${BUTTON_COLORS.purple};
    text-transform: none;
    font-size: 14px;
  }
  &&:hover {
    background-color: ${BUTTON_COLORS.purpleHover};
  }

`

export const AppInfo = () => (
  <Wrapper> 
    <Content>     
      <Logo src={appLogo} alt='logo' />
      <Copy>
        &copy; {new Date().getFullYear()}, Conex Sante.
        {' '}
        <Message id='global.copyright' />
        <Button
            variant='contained'
            color='primary'
            onClick={() => {
              location.href='mailto:support@conexsante.com'
            }}
        >
          <FormattedMessage id={'global.contact.us'}/>
        </Button>
      </Copy>

      {/*<StyledEngineProvider injectFirst>*/}
      {/*  <ThemeProvider theme={theme}>*/}
      {/*    <div>*/}
      {/*      <FormattedMessage id='global.contact.us.link' />*/}
      {/*      <a href='mailto:support@conexsante.com'>support@conexsante.com</a>*/}
      {/*    </div>*/}
      {/*  </ThemeProvider>*/}
      {/*</StyledEngineProvider>*/}
    </Content>
  </Wrapper>
)

import React from 'react'
import styled from 'styled-components'
import { Colors, calendarColors } from 'constants/colors'
import { FormattedMessage, injectIntl } from 'react-intl'

export const WeekDayAvailable = React.memo(injectIntl(() => (
  <Wrapper>
    <FormattedMessage id='schedule.available' />
  </Wrapper>
)))

const Wrapper = styled.div`
  width: 100%;
  height: 100%;
  border-left: 3px solid ${Colors.clearBlue};
  display: flex;
  align-items: center;
  box-sizing: border-box;
  padding-left: 12px;
  font-weight: 500;
  color: ${calendarColors.availableText};
  font-size: 14px;
`

import { createAction, handleActions } from 'redux-actions'
import { combineReducers } from 'redux'
import axios from 'axios'
import { handleMessageAction } from './chats';
import { sortSpecializations } from '../../helpers/doctors';

//history
export const fetchHistoryRequest = createAction('FETCH_HISTORY_REQUEST')
export const fetchHistorySuccess = createAction('FETCH_HISTORY_SUCCESS')
export const fetchHistoryFailure = createAction('FETCH_HISTORY_FAILURE')

export const fetchHistory = () => (dispatch) => {
  dispatch(fetchHistoryRequest())
  return axios.get('/api/gdpr/history')
    .then(res => dispatch(fetchHistorySuccess(res.data)))
    .catch((err) => {
      dispatch(fetchHistoryFailure(err))
    })
}

// restrict usage data
export const fetchRestrictRequest = createAction('FETCH_RESTRICT_REQUEST')
export const fetchRestrictSuccess = createAction('FETCH_RESTRICT_SUCCESS')
export const fetchRestrictFailure = createAction('FETCH_RESTRICT_FAILURE')

export const fetchRestrict = () => (dispatch) => {
  dispatch(fetchRestrictRequest())
  return axios.get('/api/gdpr/personal-data/restrictions')
    .then(res => dispatch(fetchRestrictSuccess(res.data)))
    .catch((err) => {
      dispatch(fetchRestrictFailure(err))
    })
}

// request data into csv
export const fetchRequestCSVRequest = createAction('FETCH_REQUEST_CSV')
export const fetchRequestCSVSuccess = createAction('FETCH_REQUEST_CSV_SUCCESS')
export const fetchRequestCSVFailure = createAction('FETCH_REQUEST_CSV_FAILURE')

export const fetchRequestCSV = () => (dispatch) => {
  dispatch(fetchRequestCSVRequest())
  return axios.get('/api/gdpr/personal-data/csv')
    .then(res => dispatch(fetchRequestCSVSuccess(res.data)))
    .catch((err) => {
      dispatch(fetchRequestCSVFailure(err))
    })
}

// request deletion
export const fetchRequestDeletionRequest = createAction('FETCH_REQUEST_DELETION')
export const fetchRequestDeletionSuccess = createAction('FETCH_REQUEST_DELETION_SUCCESS')
export const fetchRequestDeletionFailure = createAction('FETCH_REQUEST_DELETION_FAILURE')

export const fetchRequestDeletion = () => (dispatch) => {
  dispatch(fetchRequestDeletionRequest())
  return axios.get('/api/gdpr/personal-data/remove')
    .then(res => dispatch(fetchRequestDeletionSuccess(res.data)))
    .catch((err) => {
      dispatch(fetchRequestDeletionFailure(err))
    })
}

//#region Actions
export const fetchConsultationsQuestionsRequest = createAction('FETCH_CONSULTATION_QUESTIONS_REQUEST')
export const fetchConsultationsQuestionsSuccess = createAction('FETCH_CONSULTATION_QUESTIONS_SUCCESS')
export const fetchConsultationsQuestionsFailure = createAction('FETCH_CONSULTATION_QUESTIONS_FAILURE')

export const fetchConsultationsQuestions = () => (dispatch) => {
  dispatch(fetchConsultationsQuestionsRequest())

  return axios('/general/question')
    .then(res => dispatch(fetchConsultationsQuestionsSuccess(res.data)))
    .catch((err) => {
      dispatch(fetchConsultationsQuestionsFailure(err))
    })
}

export const fetchSpecializationsRequest = createAction('FETCH_SPECIALIZATIONS_REQUEST')
export const fetchSpecializationsSuccess = createAction('FETCH_SPECIALIZATIONS_SUCCESS')
export const fetchSpecializationsFailure = createAction('FETCH_SPECIALIZATIONS_FAILURE')

export const fetchSpecializations = () => (dispatch) => {
  dispatch(fetchSpecializationsRequest())

  return axios('/general/specialization')
    .then(res => dispatch(fetchSpecializationsSuccess(res.data)))
    .catch((err) => {
      dispatch(fetchSpecializationsFailure(err))
    })
}

export const fetchSpecializationsAvailableRequest = createAction('FETCH_SPECIALIZATIONS_REQUEST')
export const fetchSpecializationsAvailableSuccess = createAction('FETCH_SPECIALIZATIONS_SUCCESS')
export const fetchSpecializationsAvailableFailure = createAction('FETCH_SPECIALIZATIONS_FAILURE')

export const fetchSpecializationsAvailable = (withWorkplace = 0) => (dispatch) => {
  dispatch(fetchSpecializationsAvailableRequest())
  
  return axios(`/general/specialization?available=true&withWorkplace=${withWorkplace}`)
    .then(res => dispatch(fetchSpecializationsAvailableSuccess(res.data)))
    .catch((err) => {
      dispatch(fetchSpecializationsAvailableFailure(err))
    })
}

export const fetchStructureTypesRequest = createAction('FETCH_STRUCTURE_TYPES_REQUEST')
export const fetchStructureTypesSuccess = createAction('FETCH_STRUCTURE_TYPES_SUCCESS')
export const fetchStructureTypesFailure = createAction('FETCH_STRUCTURE_TYPES_FAILURE')

export const fetchStructureTypes = () => (dispatch) => {
  dispatch(fetchStructureTypesRequest())

  return axios('/api/doctor/structure/type/list')
    .then(res => dispatch(fetchStructureTypesSuccess(res.data)))
    .catch((err) => {
      dispatch(fetchStructureTypesFailure(err))
    })
}

export const fetchContraindicationsRequest = createAction('FETCH_CONTRAINDICATIONS_REQUEST')
export const fetchContraindicationsSuccess = createAction('FETCH_CONTRAINDICATIONS_SUCCESS')
export const fetchContraindicationsFailure = createAction('FETCH_CONTRAINDICATIONS_FAILURE')

export const fetchContraindications = () => (dispatch, getStore) => {
  dispatch(fetchContraindicationsRequest())
  const lang = getStore().localization

  return axios(`/drug/containdication/list?language_id=${lang || 'fr'}`)
    .then(res => dispatch(fetchContraindicationsSuccess(res.data)))
    .catch((err) => {
      dispatch(fetchContraindicationsFailure(err))
    })
}

export const toggleCallAnswerModal = createAction('TOGGLE_CALL_ANSWER_MODAL')
export const setCallAnswer = createAction('SET_CALL_ANSWER')
//#endregion

//#region Reducers
const history = handleActions({
  [fetchHistorySuccess]: (state, action) => action.payload.data,
}, [])

const consultationQuestions = handleActions({
  [fetchConsultationsQuestionsSuccess]: (state, action) => action.payload.data,
}, [])

const specializations = handleActions({
  [fetchSpecializationsSuccess]: (state, action) => sortSpecializations(action.payload.data),
  [fetchSpecializationsAvailableSuccess]: (state, action) => sortSpecializations(action.payload.data),
}, [])

const structureTypes = handleActions({
  [fetchStructureTypesSuccess]: (state, action) => action.payload.data,
}, [])

const contraindications = handleActions({
  [fetchContraindicationsSuccess]: (state, action) => action.payload.data,
}, [])

const restrictData = handleActions({
  [fetchRestrictSuccess]: (state, action) => action.payload.data,
}, [])

const requestDataCsv = handleActions({
  [fetchRequestCSVSuccess]: (state, action) => action.payload.data,
}, [])

const requestDataDeletion = handleActions({
  [fetchRequestDeletionSuccess]: (state, action) => action.payload.data,
}, [])

const isCallAnswerOpened = handleActions({
  [toggleCallAnswerModal]: (state, action) => action.payload,
}, false)

const callAnswer = handleActions({
  [setCallAnswer]: (state, action) => action.payload,
  [handleMessageAction]: (state, action) => {
    if (action.payload.action === 'cancel') {
      return action.payload.attributes.cancelMessage.trim()
        ? action.payload.attributes.cancelMessage
        : null
    }
    return state
  },
}, null)

export const content = combineReducers({
  consultationQuestions,
  specializations,
  contraindications,
  history,
  restrictData,
  requestDataCsv,
  requestDataDeletion,
  isCallAnswerOpened,
  callAnswer,
  structureTypes,
})
//#endregion

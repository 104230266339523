import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import get from 'lodash/get';
import axios from 'axios'
import {StructureCard} from "../../../../../common/StructureCard";
import CustomLoaderRectangleAndCircle from "../../../../../common/CustomLoaderRectangleAndCircle";

const Wrapper = styled.div`
    width: 721px;
    margin: 0px auto;
    margin-top: 24px;
`

export const ListOfStructures = ({ list = [], disableButtons = false }) => {
  const [myStructuresIds, setMyStructuresId] = useState([]);
  const [isLoading, setIsLoading] = useState(true);

  const getIds = async () => {
    try {
      const myStruct = await axios.get('/api/doctor/structure/list')
      const ids = get(myStruct, 'data.data', []).map(el => el.id)
      setMyStructuresId(ids);
    } catch (e) {
      console.warn(e);
    }
  }

  useEffect(() => {
    getIds();
  }, [myStructuresIds.length])

  return (
    <Wrapper>
      {list.map(el => (
        <StructureCard
            id={el.id}
            name={get(el, 'attributes.name', '-')}
            members={get(el, 'attributes.members', 0)}
            type={get(el, 'attributes.type', '-')}
            address={get(el, 'attributes.address', '-')}
            zipcode={get(el, 'attributes.zipcode', '-')}
            city={get(el, 'attributes.city', '-')}
            distance={get(el, 'attributes.distance')}
            disableButtons={disableButtons}
            photo={get(el, 'attributes.photo', null)}
            isDoctor={true}
        />
      ))}
    </Wrapper>
  ) 
}

import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import { Colors } from 'constants/colors'
import { FormattedMessage } from 'react-intl'
import BeenhereIcon from '@mui/icons-material/Beenhere'

import { Download } from '../../Download'

export const SuccessMessage = ({
  pdf,
  price,
}) => (
  <DownloadStyled
    heading='payments.success.title'
    seeButtonPath='/panel/consultations/session/menu/payment'
    downloadButtonPath={pdf}
  >
    <Icon />
    <Text>
      <FormattedMessage id='payments.success.text' />
    </Text>
    <Sum>
      <FormattedMessage id='consultation.total.sum' tagName='span' />
      {' '}
      —
      {' '}
      {price.toFixed(0)}€
    </Sum>
  </DownloadStyled>
)

SuccessMessage.defaultProps = {
  pdf: null,
}

SuccessMessage.propTypes = {
  pdf: PropTypes.object,
  price: PropTypes.number.isRequired,
}

const DownloadStyled = styled(Download)`
  width: 320px;
  margin-bottom: 16px;
  position: relative;
`

const Text = styled.div`
  color: ${Colors.blueGrey};
  font-size: 14px;
`

const Sum = styled.div`
  font-weight: 500;
  font-size: 16px;
  color: ${Colors.bluePurple};
  margin-top: 8px;
  
  span {
    color: ${Colors.blueGrey};
    text-transform: uppercase;
  }
`

const Icon = styled(BeenhereIcon)`
  && {
    fill: ${Colors.tealish};
    position: absolute;
    top: 8px;
    right: 8px;
    width: 20px;
    height: 20px;
  }
`

import React from 'react'
import PropTypes from 'prop-types'
import {connect, useSelector} from 'react-redux'
import styled from 'styled-components'
import { FormattedMessage } from 'react-intl';
import { LinkCard } from '../../../common/UiKit/LinkCard'
import { Page } from '../../../common/Page'
import { logOut } from '../../../../redux/modules/auth'
import {history} from "../../../../utils/history";
import SwitchRightIcon from '@mui/icons-material/SwitchRight';
import NotificationsIcon from '@mui/icons-material/Notifications';
import LanguageIcon from '@mui/icons-material/Language';
import {SvgIcon} from "@mui/material";
import AssignmentIcon from '@mui/icons-material/Assignment';
import ComputerIcon from '@mui/icons-material/Computer';

const LinkCardStyled = styled(LinkCard)`
  
  padding: 12px 16px 12px 24px;
  margin-bottom: 16px;
`
const LinkIconBig = styled(LinkCard)`
  height: 400px;
  width: 400px;
`

const Wrapper = styled.div`
  display: flex;
  justify-content: space-around;
  align-items: center;
`

export const ParametersApplicationComponent = ({
                                id,
                                logOut,
                              }) => {

  const role = useSelector(state => state.auth.type)
  const specialization_type = useSelector(state => state.auth.attributes.specialization_type)
  const specialization_key = useSelector(state => state.auth.attributes.specialization_key)

  return (
    <Page
      label='profile.application.parameters'
      onLogout={logOut}
      id={id}
      onBackIconClick={ () => history.goBack()}
    >
      <h2>
        <FormattedMessage id='profile.application.parameters' />
      </h2>
        {
          role !== 'Patient' && (specialization_type === 'generalist' || specialization_type === 'specialist' || specialization_key === 'Midwife') && (
            <LinkCardStyled
                label='profile.instructions'
                to='/panel/instructions'
            >
              <AssignmentIcon fontSize={"small"}/>
            </LinkCardStyled>
          )
        }
        {
          role !== 'Patient' && (
            <LinkCardStyled
              label='profile.notifications'
              to='/panel/settings/notifications'
            >
              <NotificationsIcon fontSize={"small"}/>
            </LinkCardStyled>
          )
        }
        <LinkCardStyled
          label='profile.language'
          to='/panel/settings/language'
        >
          <LanguageIcon fontSize={"small"}/>
        </LinkCardStyled>
        {
          role !== 'Patient' && (
            <LinkCardStyled
                label='profile.limitation.TE'
                to='/panel/settings/limitation/te'
            >
              <SvgIcon
                  version="1.0" xmlns="http://www.w3.org/2000/svg"
                  width="512.000000pt" height="512.000000pt" viewBox="0 0 512.000000 512.000000"
                  preserveAspectRatio="xMidYMid meet"
              >
                <g transform="translate(0.000000,512.000000) scale(0.100000,-0.100000)" stroke="none">
                  <path d="M1485 5095 l-25 -24 0 -1119 c0 -1112 0 -1118 20 -1140 12 -12 22
-23 23 -24 1 -2 305 -5 676 -8 l673 -5 188 -325 c153 -265 193 -327 218 -338
48 -23 82 -4 128 76 22 36 107 183 189 327 l151 260 673 5 673 5 24 28 24 28
0 1104 c0 788 -3 1111 -11 1128 -6 14 -22 30 -36 36 -17 8 -518 11 -1794 11
l-1770 0 -24 -25z m3261 -438 c31 -37 31 -81 -1 -112 l-24 -25 -621 0 -621 0
-24 25 c-41 40 -26 113 29 135 11 5 295 8 629 7 l609 -2 24 -28z m-2061 -24
c33 -8 98 -33 145 -55 70 -34 100 -57 175 -132 102 -103 150 -183 186 -311 28
-101 31 -258 5 -355 -60 -228 -234 -416 -455 -493 -112 -39 -269 -48 -385 -22
-145 32 -301 129 -391 243 -223 280 -204 682 45 933 182 184 426 254 675 192z
m2060 -408 c31 -30 32 -73 4 -109 l-20 -26 -629 0 -629 0 -20 26 c-28 36 -27
79 4 109 l24 25 621 0 621 0 24 -25z m-1 -434 c42 -39 34 -101 -15 -126 -24
-13 -121 -15 -629 -15 -617 0 -628 1 -657 39 -28 36 -5 102 41 121 11 5 294 8
628 7 l606 -2 26 -24z m1 -436 c16 -15 25 -36 25 -55 0 -19 -9 -40 -25 -55
l-24 -25 -621 0 -621 0 -24 25 c-16 15 -25 36 -25 55 0 19 9 40 25 55 l24 25
621 0 621 0 24 -25z"/>
                  <path d="M2386 4475 c-138 -35 -281 -145 -344 -263 -117 -221 -82 -464 92
-638 84 -84 164 -127 281 -151 197 -40 412 46 531 213 209 293 85 698 -252
821 -88 31 -222 39 -308 18z m521 -277 c16 -15 23 -33 23 -59 0 -35 -6 -43
-68 -96 -168 -144 -425 -349 -447 -357 -49 -19 -75 3 -177 143 -105 144 -114
168 -77 215 16 20 29 26 60 26 46 0 53 -7 128 -113 48 -66 57 -75 70 -63 36
30 300 245 349 284 62 48 103 54 139 20z"/>
                  <path d="M4455 1836 c-192 -73 -453 -175 -578 -227 -227 -93 -228 -94 -268
-146 -67 -87 -174 -181 -211 -187 -18 -3 -150 -12 -293 -21 -311 -19 -665 -50
-792 -70 -127 -20 -145 -19 -173 10 -54 57 -17 125 78 144 131 25 512 60 922
85 l225 14 48 53 c66 71 147 179 147 195 0 7 -8 18 -18 23 -39 21 -473 72
-1137 135 -713 68 -722 68 -816 23 -67 -32 -674 -382 -674 -388 0 -2 99 -176
219 -385 l220 -381 50 23 c61 28 139 31 236 8 36 -8 218 -59 405 -114 705
-205 779 -224 885 -224 115 0 200 27 323 101 44 26 258 182 476 347 411 309
738 549 1021 748 94 67 172 129 183 146 23 40 22 109 -3 150 -22 36 -75 72
-105 71 -11 0 -177 -60 -370 -133z"/>
                  <path d="M320 1415 c-173 -101 -314 -187 -313 -192 3 -14 685 -1197 697 -1209
8 -9 91 35 321 168 171 98 315 182 321 186 12 7 -683 1223 -701 1228 -6 1
-152 -80 -325 -181z"/>
                </g>
              </SvgIcon>
            </LinkCardStyled>
          )
        }
      {
        role !== 'Patient' && (
          <LinkCardStyled
            label='profile.settings.lgc'
            to='/panel/settings/lgc'
          >
            <ComputerIcon fontSize={"small"}/>
          </LinkCardStyled>
        )
      }
    </Page>
  )
}

ParametersApplicationComponent.propTypes = {
  id: PropTypes.string.isRequired,
  logOut: PropTypes.func.isRequired,
}

const enhance = connect(state => ({ id: state.auth.id }), { logOut })

export const ParametersApplication = enhance(ParametersApplicationComponent)

import React, { useState } from 'react';
import styled from 'styled-components'
import { Colors } from 'constants/colors'
import { FormattedMessage } from 'react-intl'
import { useSelector } from 'react-redux';
import { Tooltip } from '@mui/material'

// import facebook from '../../../../images/icons/facebook.png'
import gmail from '../../../../images/icons/gmail.svg'
import sms from '../../../../images/icons/sms.svg'
import { ShareModal } from './ShareModal'

export const StatusCodes = () => {
  const personal = useSelector(state => state.status.missions.referralCode)
  const [copied, toggleCopied] = useState(false)
  const [share, toggleShare] = useState(null)

  const onClick = (value, type) => {
    toggleCopied(type)
    navigator.clipboard.writeText(value)
    setTimeout(() => toggleCopied(false), 3000)
  }

  return (
    <Wrapper>
      <TitlePlate>
        <div>
          <FormattedMessage id='status.codes' />
        </div>
        <div>
          <Item>
            <FormattedMessage id='status.codes.personal' tagName='span' />
            <Tooltip title={<FormattedMessage id='status.code.copied' />} open={copied === 'personal'}>
              <Code onClick={() => onClick(personal, 'personal')}>{personal}</Code>
            </Tooltip>
          </Item>
          {/*<Item>*/}
          {/*  <FormattedMessage id='status.codes.team' tagName='span' />*/}
          {/*  <Tooltip*/}
          {/*    title={<FormattedMessage id='status.code.copied' />}*/}
          {/*    open={copied === 'team'}*/}
          {/*  >*/}
          {/*    <Code onClick={() => onClick(codes.team, 'team')}>{codes.team}</Code>*/}
          {/*  </Tooltip>*/}
          {/*</Item>*/}
          <Item>
            <FormattedMessage id='status.codes.share' tagName='span' />
            <ShareWrapper>
              {/*<Share>*/}
              {/*  <img src={facebook} alt='' />*/}
              {/*</Share>*/}
              <Share onClick={() => toggleShare('email')}>
                <img src={gmail} alt='' />
              </Share>
              <Share onClick={() => toggleShare('sms')}>
                <img src={sms} alt='' />
              </Share>
            </ShareWrapper>
          </Item>
        </div>
      </TitlePlate>
      <ShareModal open={!!share} type={share} onClose={() => toggleShare(null)} />
    </Wrapper>
  )
}

const Wrapper = styled.div`
  background-color: ${Colors.paleGrey};
  margin: -24px -24px -24px 0;
  padding: 40px 32px;
  box-sizing: border-box;
`

const Plate = styled.div`
  border-radius: 8px;
  box-shadow: 0 4px 8px 0 rgba(6, 173, 255, 0.2);
  background-color: #ffffff;
  margin: 12px 0;
  padding: 18px 24px;
  width: 100%;
  box-sizing: border-box;
`

export const TitlePlate = styled(Plate)`
  padding: 16px 24px;
  
  & > div:first-child {
    border-bottom: 1px solid rgba(197,203,219,0.2);
    margin: 0 -24px;
    padding: 0 24px 14px;
    font-weight: bold;
    font-size: 24px;
  }
  
  & > div:nth-child(2) {
    padding-top: 24px;
    font-size: 16px;
    
    & > div:not(:first-of-type) {
      margin-top: 16px;
    }
  }
`

const Item = styled.div`
  display: flex;
  align-items: center;
  
  & > span:first-of-type {
    margin-right: 16px;
    color: ${Colors.dark60};
  }
`

const Code = styled.div`
  flex-grow: 1;
  padding: 12px;
  border-radius: 4px;
  background-color: rgba(245, 247, 251, 0.6);
  text-transform: uppercase;
  font-size: 21px;
  text-align: center;
  color: #209dff;
  cursor: pointer;
`

const Share = styled.div`
  margin-right: 8px;

  img {
    width: 32px;
    height: 32px;
  }
`

const ShareWrapper = styled.div`
  display: flex;
  justify-content: space-around;
  flex-grow: 1;
  cursor: pointer;
  max-width: 100px;
`

import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import idx from 'idx'
import { Paper } from 'common/UiKit/Paper'
import { Colors } from 'constants/colors'
import { FormattedMessage } from 'react-intl'
import { SecuredBckgImage } from 'common/SecuredImage'

const Link = styled.a`
  width: 100%;
`

const Wrapper = styled(Paper)`
  padding: 16px;
  display: flex;
  margin-bottom: 8px;
  align-items: center;
`

const Image = styled(SecuredBckgImage)`
  width: 40px;
  height: 40px;
  margin-right: 16px;
  background: url(${props => props.src}) ${Colors.lightGrey} center no-repeat;
  background-size: cover;
  border-radius: 5px;
`

const Label = styled.div`
  margin: 0;
  color: ${Colors.dark};
  opacity: 0.9;
  width: calc(100% - 59px);
`

const Subtitle = styled.div`
  font-size: 12px;
  color: ${Colors.blueGrey};
`

const Title = styled.div`
  width: 100%;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
`

export const Item = ({ attachment }) => (
  <Link
    href={idx(attachment, _ => _.attributes.url)}
    target='_blank'
  >
    <Wrapper>
      <Image src={idx(attachment, _ => _.attributes.url)} />
      <Label>
        <Title title={attachment.attributes.custom_name}>
          {attachment.attributes.custom_name || attachment.attributes.name}
        </Title>
        <Subtitle>
          <FormattedMessage
            id={`profile.attachments.${idx(attachment, _ => _.attributes.type) || 'other'}`}
          />
        </Subtitle>
      </Label>
    </Wrapper>
  </Link>
)

Item.propTypes = {
  attachment: PropTypes.object.isRequired,
}

import { handleActions, combineActions } from 'redux-actions'
import { combineReducers } from 'redux'
import * as authActions from './auth'
import * as healthRecordsActions from './healthRecords'
import * as consultationsActions from './consultations'
import * as medicinesActions from './medicines'
import * as doctorsActions from './doctors'
import * as teamsActions from './teams'
import * as structuresActions from './structures'
import * as reviewsActions from './reviews'
import * as chatsActions from './chats'
import * as calendarActions from './calendar/list'
import * as geolocActions from './geoloc'
import * as structureInfosActions from './structureInfos'
import * as proInviteActions from './proInvite'

const createLoadingState = (requestActions, responseActions) => handleActions({
  [combineActions(...requestActions)]: () => true,
  [combineActions(...responseActions)]: () => false,
}, false)

const auth = createLoadingState(
  [authActions.authRequest],
  [authActions.authSuccess, authActions.authFailure],
)

const rppsAdeliSearch = createLoadingState(
  [authActions.rppsSearchRequest],
  [authActions.rppsSearchSuccess, authActions.rppsSearchFailure],
)

const editProfile = createLoadingState(
  [authActions.editProfileRequest],
  [authActions.editProfileSuccess, authActions.editProfileFailure],
)

const workPlace = createLoadingState(
  [authActions.createWorkPlaceRequest],
  [authActions.createWorkPlaceSuccess, authActions.createWorkPlaceFailure],
)

const schedule = createLoadingState(
  [authActions.createScheduleRequest],
  [authActions.createScheduleSuccess, authActions.createScheduleFailure],
)

const diplomas = createLoadingState(
  [authActions.createDiplomasRequest, authActions.deleteDiplomaRequest],
  [
    authActions.createDiplomasFailure,
    authActions.createDiplomasSuccess,
    authActions.deleteDiplomaFailure,
    authActions.deleteDiplomaSuccess,
  ],
)

const passwordRecovery = createLoadingState(
  [authActions.passwordRecoveryRequest],
  [authActions.passwordRecoverySuccess, authActions.passwordRecoveryFailure],
)

const setNewPassword = createLoadingState(
  [authActions.setNewPasswordRequest],
  [authActions.setNewPasswordSuccess, authActions.setNewPasswordFailure],
)

const setNewPasswordPreRegistrated = createLoadingState(
    [authActions.setNewPasswordPreRegistratedRequest],
    [authActions.setNewPasswordPreRegistratedSuccess, authActions.setNewPasswordPreRegistratedFailure],
)

const changePassword = createLoadingState(
  [authActions.changePasswordRequest],
  [authActions.changePasswordSuccess, authActions.changePasswordFailure],
)

const healthRecords = createLoadingState(
  [healthRecordsActions.editHealthRecordsRequest],
  [healthRecordsActions.editHealthRecordsSuccess, healthRecordsActions.editHealthRecordsFailure],
)

const healthRecordsAttachments = createLoadingState(
  [
    healthRecordsActions.createHealthRecordsAttachmentRequest,
    healthRecordsActions.deleteHealthRecordsAttachmentRequest,
  ],
  [
    healthRecordsActions.createHealthRecordsAttachmentSuccess,
    healthRecordsActions.deleteHealthRecordsAttachmentSuccess,
    healthRecordsActions.createHealthRecordsAttachmentFailure,
    healthRecordsActions.deleteHealthRecordsAttachmentFailure,
  ],
)
const createConsultation = createLoadingState(
  [
    consultationsActions.createUrgentConsultationRequest,
    consultationsActions.createAppointmentConsultationRequest,
  ],
  [
    consultationsActions.createUrgentConsultationSuccess,
    consultationsActions.createUrgentConsultationFailure,
    consultationsActions.createAppointmentConsultationSuccess,
    consultationsActions.createAppointmentConsultationFailure,
  ],
)

const editMedicalReport = createLoadingState(
  [
    consultationsActions.editMedicalReportRequest,
    consultationsActions.addPrescriptionRequest,
  ],
  [
    consultationsActions.editMedicalReportSuccess,
    consultationsActions.editMedicalReportFailure,
    consultationsActions.addPrescriptionSuccess,
    consultationsActions.addPrescriptionFailure,
  ],
)

const approveConsultation = createLoadingState(
  [consultationsActions.approveConsultationRequest, consultationsActions.refuseConsultationRequest],
  [
    consultationsActions.approveConsultationSuccess,
    consultationsActions.approveConsultationFailure,
    consultationsActions.refuseConsultationSuccess,
    consultationsActions.refuseConsultationFailure,
  ],
)

const medicinesCompability = createLoadingState(
  [
    medicinesActions.checkMedicineCompabilityWithProfileRequest,
    medicinesActions.checkMedicinesWithEachOtherRequest,
  ],
  [
    medicinesActions.checkMedicineCompabilityWithProfileSuccess,
    medicinesActions.checkMedicineCompabilityWithProfileFailure,
    medicinesActions.checkMedicinesWithEachOtherSuccess,
    medicinesActions.checkMedicinesWithEachOtherFailure,
  ],
)

const allergies = createLoadingState(
  [
    healthRecordsActions.addAllergyRequest,
    healthRecordsActions.deleteAllergyRequest,
  ],
  [
    healthRecordsActions.addAllergySuccess,
    healthRecordsActions.addAllergyFailure,
    healthRecordsActions.deleteAllergySuccess,
    healthRecordsActions.deleteAllergyFailure,
  ],
)

const favoriteProfessionals = createLoadingState(
  [
    doctorsActions.fetchFavoriteRequest,
  ],
  [
    doctorsActions.fetchFavoriteSuccess,
    doctorsActions.fetchFavoriteFailure,
  ],
)

const favoriteTeams = createLoadingState(
  [
    doctorsActions.fetchFavoriteTeamsRequest,
  ],
  [
    doctorsActions.fetchFavoriteTeamsSuccess,
    doctorsActions.fetchFavoriteTeamsFailure,
  ],
)

const favoriteStructures = createLoadingState(
  [
    doctorsActions.fetchFavoriteStructuresRequest,
  ],
  [
    doctorsActions.fetchFavoriteStructuresSuccess,
    doctorsActions.fetchFavoriteStructuresFailure,
  ],
)

const favoriteToggle = handleActions({
  [doctorsActions.toggleFavoriteRequest]: (state, action) => action.payload.id,
  [doctorsActions.toggleFavoriteSuccess]: () => false,
  [doctorsActions.toggleFavoriteFailure]: () => false,
  [structuresActions.toggleFavoriteStructureRequest]: (state, action) => action.payload.id,
  [structuresActions.toggleFavoriteStructureSuccess]: () => false,
  [structuresActions.toggleFavoriteStructureFailure]: () => false,
  [teamsActions.toggleFavoriteTeamRequest]: (state, action) => action.payload.id,
  [teamsActions.toggleFavoriteTeamSuccess]: () => false,
  [teamsActions.toggleFavoriteTeamFailure]: () => false,
}, false)

const reviews = handleActions({
  [reviewsActions.fetchDoctorReviewsRequest]: (state, action) => [...state, action.payload],
  [combineActions(
    reviewsActions.fetchDoctorReviewsSuccess,
    reviewsActions.fetchDoctorReviewsFailure,
  )]: (state, action) => state.filter(doctor => doctor !== action.payload.doctor),
}, [])

const doctors = createLoadingState(
  [doctorsActions.fetchDoctorsListRequest],
  [
    doctorsActions.fetchDoctorsListSuccess,
    doctorsActions.fetchDoctorsListFailure,
  ],
)

const authByToken = createLoadingState(
  [authActions.authByTokenRequest],
  [authActions.authByTokenSuccess, authActions.authByTokenFailure],
)

const previous = createLoadingState(
  [consultationsActions.fetchPreviousConsultationsRequest],
  [
    consultationsActions.fetchPreviousConsultationsSuccess,
    consultationsActions.fetchPreviousConsultationsFailure,
  ],
)

const consultation = createLoadingState(
  [consultationsActions.fetchConsultationRequest, consultationsActions.proposeNewSlotRequest],
  [
    consultationsActions.fetchConsultationFailure,
    consultationsActions.fetchConsultationSuccess,
    consultationsActions.proposeNewSlotFailure,
    consultationsActions.proposeNewSlotSuccess
  ],
)

const fileUploading = createLoadingState(
  [chatsActions.uploadFileRequest],
  [chatsActions.uploadFileSuccess, chatsActions.uploadFileFailure],
)

const enterPhone = createLoadingState(
  [authActions.sendPhoneRequest],
  [authActions.sendPhoneSuccess, authActions.sendPhoneFailure],
)

const enterCode = createLoadingState(
  [authActions.sendCodeRequest],
  [authActions.sendCodeSuccess, authActions.sendCodeFailure],
)

const payment = createLoadingState(
  [consultationsActions.paymentRequest],
  [consultationsActions.paymentSuccess, consultationsActions.paymentFailure],
)

const acceptConcilium = createLoadingState(
  [consultationsActions.toggleConciliumRequest],
  [consultationsActions.toggleConciliumSuccess, consultationsActions.toggleConciliumFailure],
)

const consultationsList = createLoadingState(
  [consultationsActions.fetchConsultationsListRequest],
  [
    consultationsActions.fetchConsultationsListSuccess,
    consultationsActions.fetchConsultationsListFailure,
  ],
)

const consultationTransfer = createLoadingState(
  [consultationsActions.transferConsultationRequest],
  [
    consultationsActions.transferConsultationFailure,
    consultationsActions.transferConsultationSuccess,
  ],
)

const geolocAroundMe = createLoadingState(
  [geolocActions.aroundMeRequest],
  [
    geolocActions.aroundMeSuccess,
    geolocActions.aroundMeFailure,
  ]
)

const structureInfosTeam = createLoadingState(
  [structureInfosActions.loadTeamDetailsRequest],
  [
    structureInfosActions.loadTeamDetailsSuccess,
    structureInfosActions.loadTeamDetailsFailure,
  ]
)

const proInviteSending = createLoadingState(
  [proInviteActions.inviteProfessionalRequest],
  [
    proInviteActions.inviteProfessionalSuccess,
    proInviteActions.inviteProfessionalFailure,
  ]
)

export const loading = combineReducers({
  auth,
  rppsAdeliSearch,
  passwordRecovery,
  setNewPassword,
  setNewPasswordPreRegistrated,
  changePassword,
  editProfile,
  workPlace,
  diplomas,
  schedule,
  healthRecords,
  healthRecordsAttachments,
  createConsultation,
  editMedicalReport,
  approveConsultation,
  medicinesCompability,
  allergies,
  favoriteToggle,
  reviews,
  doctors,
  authByToken,
  previous,
  consultation,
  fileUploading,
  enterPhone,
  enterCode,
  payment,
  acceptConcilium,
  consultationsList,
  consultationTransfer,
  geolocAroundMe,
  structureInfosTeam,
  proInviteSending,
  favoriteProfessionals,
  favoriteTeams,
  favoriteStructures
})

import React from 'react'
import { Form, Field } from 'react-final-form'
import styled from 'styled-components'
import { mapProps } from 'recompose'
import { FormattedMessage, useIntl } from 'react-intl'
import { Colors } from 'constants/colors'
import { Paper } from 'common/UiKit/Paper'
import Tooltip from '@mui/material/Tooltip';
import HelpIcon from '@mui/icons-material/Help';
import get from 'lodash/get';
import { TextField } from '../../../../../common/FinalFormFields';
import moment from "moment-timezone";
import InfoIcon from "@mui/icons-material/Info";

const Line = styled.div`
  display: flex;
  align-items: ${({ start }) => (start ? 'flex-start' : 'center')};

  .location {
    flex-grow: 1;
  }


`

const Information = styled.div`
  display: flex;
  justify-content : start;
  align-items :center;
  background-color: #E7E7E7;
  color: #585C65;
  border-radius: 5px;
  width: 100%;
  & {
    padding: 6px 10px 6px 2px;
    margin: 5px 0 20px 0;
    font-style: italic;
    line-height: 1.31;
  }
  & > * {
    padding: 5px 10px 5px 10px;
  }
`
const WrapperLocationsFields = styled.div`
  width : 100%;
  display: flex;
  justify-content: space-between;
  align-item: center;
`


const Label = styled.div`
  width: 210px;
  min-width: 200px;
  margin-bottom: 24px;
  margin-right: 6px;
  margin-top: 4px;
  opacity: 0.87;
  font-weight: bold;
  font-size: 16px;
  color: #585C65;
`

const LocationField = styled(Field)`
  && {
    width: 152px;
  }
`

const MethodWrapper = styled.div`
  margin-bottom: 12px;
  margin-top: -16px;
`

const StyledHelpIcon = styled(HelpIcon)`
  padding-bottom: 25px;
  padding-left: 15px;
`

const PricesBlock = styled.div`
  padding-left: 30px;
`

const COUNTRIES = [
  { label: 'France', value: 'France' },
]

const validate = (values) => {
  const errors = {}
  // eslint-disable-next-line no-restricted-globals
  if (!values.hospital_name) errors.hospital_name = <FormattedMessage id='error.hospital' />
  if (!values.street) errors.street = <FormattedMessage id='error.street' />
  if (!values.country) errors.country = <FormattedMessage id='error.country' />
  if (!values.city) errors.city = <FormattedMessage id='error.city' />
  if (!values.postcode) errors.postcode = <FormattedMessage id='error.postcode' />
  // eslint-disable-next-line no-restricted-globals
  if (Number.isNaN(values.postcode)) errors.postcode = <FormattedMessage id='error.number' />
  if (values.postcode && values.postcode.toString().length < 5) errors.postcode = <FormattedMessage id='error.postcode' />

  if(!values.credentials_adeli && !values.credentials_rpps  && !values.credentials_order_number ){
    errors.credentials_rpps = <FormattedMessage id='auth.register.one.of.tree.number' />
    errors.credentials_adeli = <FormattedMessage id='auth.register.one.of.tree.number' />
    errors.credentials_order_number = <FormattedMessage id='auth.register.one.of.tree.number' />
  }else{
   if (!!values.credentials_rpps && String(values.credentials_rpps).length !== 11) {
      errors.credentials_rpps = <FormattedMessage id='error.rpps_11' />
    }
   if (!!values.credentials_adeli && String(values.credentials_adeli).length !== 9) {
      errors.credentials_adeli = <FormattedMessage id='error.adeli_9' />
    }
   if (!!values.credentials_order_number && String(values.credentials_order_number).length !== 9) {
      errors.credentials_order_number = <FormattedMessage id='error.finess.order.number_9' />
    }
  }
  if (Number.isNaN(values.daily_price)) errors.daily_price = <FormattedMessage id='profile.error.daily.price' />
  if (Number.isNaN(values.weekend_price)) errors.weekend_price = <FormattedMessage id='profile.error.weekend.price' />
  if (Number.isNaN(values.urgent_price)) errors.urgent_price = <FormattedMessage id='profile.error.urgent.price' />

  return errors
}

const normalizeValues = values => ({
  work_place: {
    name: values.hospital_name || '',
    country: values.country || '',
    city: values.city || '',
    street: values.street || '',
    postcode: values.postcode || '',
  },
  credentials_rpps: values.credentials_rpps || '',
  credentials_adeli: values.credentials_adeli || '',
  credentials_order_number: values.credentials_order_number || '',
  daily_price: values.daily_price || '',
  weekend_price: values.weekend_price || '',
  urgent_price: values.urgent_price || '',
})

const normalizeInitialValues = values => ({
  hospital_name: values.work_place.name || '',
  country: values.work_place.country || 'France',
  city: values.work_place.city || '',
  street: values.work_place.street || '',
  postcode: values.work_place.postcode || '',
  credentials_rpps: values.credentials_rpps || '',
  credentials_adeli: values.credentials_adeli || '',
  credentials_order_number: values.credentials_order_number || '',
  daily_price: parseInt(values.daily_price, 10) || '',
  weekend_price: parseInt(values.weekend_price, 10) || '',
  urgent_price: parseInt(values.urgent_price, 10) || '',

})

const enhance = mapProps(props => ({
  ...props,
}))

export const AboutDoctorForm = enhance(({
  id,
  initialValues,
  disableShadow,
  methodField,
  onSubmit,
  afterSubmit,
}) => {
  const { formatMessage } = useIntl()
  const f = id => formatMessage({ id })
  const annuaireRpps = JSON.parse(localStorage.getItem("rpps_adeli"));
  const submit = async (values) => {
    try {
      await onSubmit({ ...normalizeValues(values) })
    } catch (err) {
      const error = get(err, 'response.data.data.error_description');
      if (error === 'field.validation.rpps.exist') {
        return { credentials_rpps: f('error.uniq') };
      }else if(error === 'field.validation.adeli.exist'){
        return { credentials_adeli: f('error.uniq') };
      }
      if (error === 'field.validation.rpps.length' || error === 'field.validation.rpps.format') {
        return { credentials_rpps: f('error.rpps_11') };
      }
    }
    return afterSubmit()
  }
  return (
    <Form
      onSubmit={submit}
      validate={validate}
      subscription={{}}
      keepDirtyOnReinitialize
      initialValues={annuaireRpps ? {
        ...initialValues && normalizeInitialValues(initialValues),
        credentials_rpps: annuaireRpps.typeIdentifiantPp === 8 ? annuaireRpps.identifiantPp : '',
        credentials_adeli: annuaireRpps.typeIdentifiantPp === 0 ? annuaireRpps.identifiantPp : '',
        hospital_name: annuaireRpps.raisonSocialeSite,
        street:  annuaireRpps.numeroVoieStructure + ' ' + annuaireRpps.libelleTypeDeVoieStructure + ' ' + annuaireRpps.libelleVoieStructure,
        postcode: annuaireRpps.codePostalStructure,
        city: annuaireRpps.libelleCommuneStructure,
      } : {
        ...initialValues && normalizeInitialValues(initialValues)
      }}
      render={({ handleSubmit }) => (
        <form onSubmit={handleSubmit} id={id}>
          <Paper disableShadow={disableShadow}>
            <Line>
              <Label>
                <FormattedMessage id='profile.rpps.optional' />
              </Label>
              <Field
                component={TextField}
                type='text'
                name='credentials_rpps'
                label={`${f('profile.rpps.label')}`}
                parse={value => value && (value[0] === '0' ? value : (+value || ''))}
              />
            </Line>
            <Line>
              <Label>
                <FormattedMessage id='profile.adeli.optional' />
              </Label>
              <Field
                component={TextField}
                type='text'
                name='credentials_adeli'
                label={`${f('profile.adeli.label')}`}
                parse={value => value && (value[0] === '0' ? value : (+value || ''))}
              />
            </Line>
            <Line>
              <Label>
                <FormattedMessage id='profile.number.optional' />
              </Label>
              <Field
                type='text'
                component={TextField}
                name='credentials_order_number'
                label={`${f('profile.number.label')}`}
                parse={value => value && (value[0] === '0' ? value : (+value || ''))}
              />
              <Tooltip title={f('profile.numberhint')} placement='top'>
                <StyledHelpIcon size='small' />
              </Tooltip>
            </Line>
            <Information>
              <InfoIcon/>
              <FormattedMessage id='auth.register.one.of.tree.number' />
            </Information>
            {
              methodField && (
              <Line>
                <Label>
                  <FormattedMessage id='profile.method' />
                </Label>
                <MethodWrapper>
                  {methodField}
                </MethodWrapper>
              </Line>
              )
            }
            <Line start={1}>
              <div className='location'>
                <Line>
                  <Label>
                    <FormattedMessage id='profile.hospital.name.main'/>
                  </Label>
                  <Field
                    component={TextField}
                    name='hospital_name'
                    label={`*${f('profile.hospital.name')}`}
                  />
                </Line>
                <Line>
                  <Label>
                    <FormattedMessage id='profile.workplace.main'/>
                  </Label>
                  <Field
                    component={TextField}
                    name='street'
                    label={`*${f('profile.workplace')}`}
                  />
                </Line>
              </div>
            </Line>
                <Line>
                  <Label></Label>
                  <WrapperLocationsFields>
                    <LocationField
                      component={TextField}
                      name='postcode'
                      label={`*${f('profile.postcode')}`}
                      parse={value => value && (value[0] === '0' ? value : (+value || ''))}
                    />
                    <LocationField
                      component={TextField}
                      name='city'
                      label={`*${f('profile.city')}`}
                    />
                    <LocationField
                      component={TextField}
                      name='country'
                      label={`*${f('profile.country')}`}
                      options={COUNTRIES}
                    />
                  </WrapperLocationsFields>
                </Line>
          </Paper>
        </form>
      )}
    />
  )
})

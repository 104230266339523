import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import { connect } from 'react-redux'
import {
  compose,
  withProps,
  withStateHandlers,
  lifecycle,
} from 'recompose'
import { Redirect } from 'react-router-dom'
import idx from 'idx'
import moment from 'moment-timezone'
import { FormattedMessage } from 'react-intl'
import Publish from '@mui/icons-material/Publish'
import IconButton from '@mui/material/IconButton'
import { Colors } from '../../../../../../constants/colors'
import { OpenSecuredInNewTab } from '../../../../../common/SecuredImage'
import { Paper } from '../../../../../common/UiKit/Paper'
import { Page } from '../../../../../common/Page'
import { history } from '../../../../../../utils/history'
import { Medicines } from './Medicines'
import { Buttons } from './Buttons'
import { clearCheckedMedicines } from '../../../../../../redux/modules/medicines'

const Content = styled(Paper)`
  padding: 20px 24px 1px;
  margin-bottom: 16px;
`

const Info = styled.div`
  margin-bottom: 40px;
`

const Line = styled.div`
  display: flex;
  margin-bottom: 20px;
  font-size: 16px;
  line-height: 1.43;
  color: ${Colors.dark80};

  & > :first-child {
    width: 150px;
    font-weight: bold;
  }
`

const Field = styled.div`
  margin-bottom: 20px;
  font-size: 14px;
  line-height: 1.43;
  color: ${Colors.blueGrey};

  & > :first-child {
    color: ${Colors.dark80};
    font-size: 16px;
    font-weight: 500;
    margin: 0 0 8px;
  }
`

const DownloadIcon = styled(Publish)`
  transform: rotate(180deg);
`

const StyledOpenSecuredInNewTab = styled(OpenSecuredInNewTab)`
  && {
    margin-left: -200px;
  }
`

const Filler = styled.div`height: 64px`

const ReportComponent = ({
  consultation,
  onBackIconClick,
  onCheckButtonClick,
  isCheck,
  checkWith,
  setCheckWith,
  loadingWithProfile,
  onDownloadIconClick,
  isPrescription,
}) => {
  if (!consultation.id) {
    return <Redirect to='/panel/consultations' />
  }

  return (
    <Page
      label={isPrescription ? 'consultations.prescription' : 'consultations.report'}
      onBackIconClick={onBackIconClick}
      Icon={DownloadIcon}
      onIconClick={onDownloadIconClick}
    >
      {
        !isPrescription && (idx(consultation, _ => _.relationships.medicalReport.data) || [])
          .map((item) => {
            const doctor = +item.attributes.doctor === +consultation.relationships.doctor.data.id
              ? consultation.relationships.doctor.data.attributes || {}
              : idx(consultation, _ => _
                .relationships.additionalMember.data[0].attributes.doctor) || {}
            return (
              <>
                <Content
                  heading={`Medical report #${idx(item, _ => _.id)}`}
                  onCheckCompabilityButtonClick={onCheckButtonClick}
                  additionalButton={(
                    <StyledOpenSecuredInNewTab action={idx(item, _ => _.attributes.pdf.report)}>
                      <IconButton color='primary' size='large'>
                        <DownloadIcon />
                      </IconButton>
                    </StyledOpenSecuredInNewTab>
                )}
                >
                  <Info>
                    <Line>
                      <span>
                        <FormattedMessage id='global.date' />
                        :
                      </span>
                      <span>{moment(idx(consultation, _ => _.attributes.creation_time)).format('MMMM DD [at] HH:mm')}</span>
                    </Line>
                    <Line>
                      <span>
                        <FormattedMessage id='auth.patient' />
                        :
                      </span>
                      <span>
                        {idx(consultation, _ => _.relationships.patient.data.attributes.first_name)}
                      &nbsp;
                        {idx(consultation, _ => _.relationships.patient.data.attributes.last_name)}
                      </span>
                    </Line>
                    <Line>
                      <span>
                        <FormattedMessage id='auth.doctor' />
                        :
                      </span>
                      <span>
                        {idx(consultation, _ => doctor.first_name || _.relationships
                          .doctor.data.attributes.first_name)}
                      &nbsp;
                        {idx(consultation, _ => doctor.last_name || _
                          .relationships.doctor.data.attributes.last_name)}
                        ,&nbsp;
                        {idx(consultation, _ => _.relationships
                          .doctor.data.attributes.specialization)}
                      </span>
                    </Line>
                  </Info>
                </Content>
                <Content heading='hr.main.info'>
                  <Field>
                    <p>
                      <FormattedMessage id='consultations.symptoms' />
                    </p>
                    {idx(consultation, _ => _.attributes.symptom_explanation)}
                  </Field>
                  <Field>
                    <p>
                      <FormattedMessage id='consultations.diagnosis' />
                    </p>
                    {idx(item, _ => _.attributes.diagnosis)}
                  </Field>
                  <Field>
                    <p>
                      <FormattedMessage id='consultations.referral' />
                    </p>
                    {idx(item, _ => _.attributes.referral)}
                  </Field>
                  <Field>
                    <p>
                      <FormattedMessage id='consultations.restrictions' />
                    </p>
                    {idx(item, _ => _.attributes.restrictions)}
                  </Field>
                </Content>
              </>
            );
          })
      }
      <Content
        heading='consultations.prescribed'
        onCheckCompabilityButtonClick={isPrescription && onCheckButtonClick}
      >
        <Medicines checkWith={checkWith} />
      </Content>
      <Filler />
      {isCheck && <Buttons loadingWithProfile={loadingWithProfile} setCheckWith={setCheckWith} />}
    </Page>
  );
}

ReportComponent.defaultProps = {
  isPrescription: false,
}

ReportComponent.propTypes = {
  consultation: PropTypes.object.isRequired,
  onBackIconClick: PropTypes.func.isRequired,
  onCheckButtonClick: PropTypes.func.isRequired,
  isCheck: PropTypes.bool.isRequired,
  checkWith: PropTypes.string.isRequired,
  setCheckWith: PropTypes.func.isRequired,
  loadingWithProfile: PropTypes.bool.isRequired,
  onDownloadIconClick: PropTypes.oneOfType([PropTypes.bool, PropTypes.func]).isRequired,
  isPrescription: PropTypes.bool,
}

const enhance = compose(
  connect(
    (state, ownProps) => ({
      consultation: idx(state, _ => _.consultations.consultation),
      loadingWithProfile: state.loading.medicinesCompability,
      downloadLink: ownProps.isPrescription
        ? idx(state, _ => _
          .consultations
          .consultation.relationships.medicalReport.data[0].attributes.pdf.prescription)
        : idx(state, _ => _
          .consultations.consultation.relationships.medicalReport.data[0].attributes.pdf.report),
    }),
    {
      clearCheckedMedicines,
    },
    null,
  ),
  lifecycle({
    componentDidMount() {
      if (!this.props.consultation.id) {
        history.push('/panel/consultations')
      }
    },
    componentWillUnmount() {
      this.props.clearCheckedMedicines()
    },
  }),
  withProps(props => ({
    onBackIconClick: () => history.goBack(),
    onDownloadIconClick: props.downloadLink ? () => window.open(props.downloadLink) : false,
  })),
  withStateHandlers({
    isCheck: false,
    checkWith: '',
  }, {
    onCheckButtonClick: ({ isCheck }) => () => ({ isCheck: !isCheck }),
    setCheckWith: () => v => ({ checkWith: v }),
  }),
)

export const Report = enhance(ReportComponent)

import React, { useEffect, useRef, useState } from 'react'
import PropTypes from 'prop-types'
import {Skeleton} from '@mui/material';
import styled, { css } from 'styled-components'
import { compose, withStateHandlers, withProps } from 'recompose'
import idx from 'idx'
import { connect, useSelector, useDispatch } from 'react-redux'
import moment from 'moment-timezone'
import Pagination from '@mui/material/Pagination';
import get from 'lodash/get';
import { fetchConsultation, fetchConsultations, deleteSessionData } from '../../../../../redux/modules/consultations'
import { Header } from './Header'
import { Item } from './Item'
import { history } from '../../../../../utils/history'
import { EmptyScreen } from './EmptyScreen'
import { CONSULTATION_TYPES } from '../../../../../constants/consultation'
import { fetchHealthRecords } from '../../../../../redux/modules/healthRecords'
import { matchByName } from '../../../../../helpers/consultations'
import { useRouteMatch} from 'react-router-dom';
import logoTE from '../../../../../images/panel/doctors/te-empty-screen.svg'
import logoTC from '../../../../../images/panel/doctors/tc-empty-screen.svg'
import {HelpWindow} from "./HelpWindow";
import {
  createFormsView,
  fetchHelpAroundMeView,
  fetchHelpWindowView,
  fetchNetworkView
} from "../../../../../redux/modules/formsView";
import {HELP_WINDOW} from "../../../../../constants/forms";
import {Survey} from "../../Survey/Survey";
import CustomLoaderRectangleAndCircle from "../../../../common/CustomLoaderRectangleAndCircle";

const StyledPagination = styled(Pagination)`
  & > ul {
    align-items: center;
    justify-content: center;
    margin-right: 10vw;
    margin-bottom: 2vh;
  }
`

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: start;
  min-height: 88.5vh;
  max-width: 1300px;
  margin: auto;
`

const List = styled.div`
  margin: 30px 0;
  margin-left: 5px;
`

const TopAnkor = styled.div`
  position: absolute;
  top: 0;
`

const ConsultationsListComponent = ({
  from,
  to,
  handleFromChange,
  handleToChange,
  consultations,
  onConsultationClick,
  nearestUrgent,
  myId,
  loading,
  refreshConsultations,
  filters,
  resetTimeFilter,
  auth,
  paginator,
}) => {
  const match = useRouteMatch();
  const dispatch = useDispatch();
  const search = useSelector(state => state.settings.consultationSearch)
  const notifications = useSelector(state => state.notification.list)
  const filteredList = consultations.filter(matchByName(search))
  const onlyPersonal = notifications && Array.isArray(notifications.data) ? notifications.data
    .filter(notification => idx(notification, _ => _.attributes.type) === 'personal' && !idx(notification, _ => _.attributes?.read)) : [];
  const [page, setPage] = useState(1)
  const ankor = useRef(null)
  const executeScroll = () => ankor.current.scrollIntoView(true)
  const currentPath = match.path;
  const isInConciliumMenu = currentPath === '/panel/consultations'
  const isTCA = currentPath.includes('teleconsultation')
  const actualFilter = filters.filter
  let emptyScreenTitle = 'te.'
  let conciliumLogo = logoTE
  if (!isInConciliumMenu) {
    emptyScreenTitle = 'tc.'
    conciliumLogo = logoTC
  }
  emptyScreenTitle += actualFilter === 'upcoming' ? 'empty' : 'completed.empty'

  const [open, setOpen] = useState(true);
  const doctorId = useSelector(state => state.auth.id);
  const isAlreadySeen = useSelector(state => state.formsView.isViewed.help_window);

  const fetchIsViewed = () => {
    dispatch(fetchHelpWindowView(doctorId))
    dispatch(fetchNetworkView(doctorId))
    dispatch(fetchHelpAroundMeView(doctorId))
  }

  useEffect(() => {
    fetchIsViewed();
  }, [])

  const handleClose = async () =>  {
    setOpen(false);
    dispatch(createFormsView(doctorId, HELP_WINDOW))
        .then((data) => {
          console.log(data)
        })
        .catch((errors) => {
          console.log(errors)
        })
  }

  useEffect(() => {
    filters.isconcilium = isInConciliumMenu
    dispatch(fetchConsultations({...filters, page}));
  }, [onlyPersonal.length, page])

  return (
    <Wrapper>
      <Header
        resetTimeFilter={resetTimeFilter}
        from={from}
        to={to}
        handleFromChange={handleFromChange}
        handleToChange={handleToChange}
        nearestUrgent={nearestUrgent}
        isInConciliumMenu={!isTCA}
        filteredList={filteredList}
      />
      { isAlreadySeen === false && open && (
        <HelpWindow handleClose={handleClose}/>
      )}
      <Survey/>
      <TopAnkor ref={ankor}/>
      {loading && ( <CustomLoaderRectangleAndCircle />)}
      {
        !loading && (
        isInConciliumMenu ?
          <List>
            {
              !!filteredList
              &&
              filteredList.map(item => (
                <div key={idx(item, _ => _.id)}>
                  {(item.attributes.consiliumStatus && idx(item, _ => _.attributes.type) !== 'TCA') &&
                    <Item
                      isTCA={isTCA}
                      item={item}
                      filters={filters}
                      lastmessagetime={idx(item, _ => _.attributes.lastmessagetime)}
                      consultations={consultations}
                      refreshConsultations={refreshConsultations}
                      id={idx(item, _ => _.id)}
                      patient_TCTE={idx(item, _ => _.relationships.patient.data.attributes)}
                      timeCreation={idx(item, _ => _.attributes.creation_time)}
                      additionalMemberIfTEOrTER={idx(item, _ => _.relationships[_.attributes.type === CONSULTATION_TYPES.telexpertise || _.attributes.type === CONSULTATION_TYPES.TER || _.attributes.type === CONSULTATION_TYPES.TER_ESS || _.attributes.type === CONSULTATION_TYPES.TCA ? 'doctor' : 'patient'].data.attributes)}
                      status={idx(item, _ => _.attributes.status)}
                      accessHealthRecords={idx(item, _ => _.attributes.access_health_record)}
                      onClick={onConsultationClick(item, match)}
                      urgent={!!idx(item, _ => _.attributes.type === 'urgent')}
                      isConcilium={item.attributes.consiliumStatus}
                      type={item.attributes.type}
                      additionalMember={idx(item, _ => _
                        .relationships.additionalMember.data[0].attributes.doctor)}
                      isMy={idx(
                        item,
                        _ => ((_.attributes.type === CONSULTATION_TYPES.telexpertise || _.attributes.type === CONSULTATION_TYPES.TER || _.attributes.type === CONSULTATION_TYPES.TER_ESS)
                          && _.relationships.doctor.data.id === myId),
                      )}

                      isMyEssTer={idx(
                          item,
                          _ => ((_.attributes.type === CONSULTATION_TYPES.TER_ESS)
                              && _.relationships.doctorCreator.data.id === myId),
                      )}

                      isMyTerTeam={idx(
                          item,
                          _ => ((_.attributes.type === CONSULTATION_TYPES.TER_ESS)
                              && _.relationships.additionalMember.data[0].attributes.doctorID.toString() === myId),
                      )}

                      team={idx(
                          item,
                          _ => (( _.attributes.type === CONSULTATION_TYPES.TER_ESS)
                              && _.relationships.team.data.attributes.name),
                      )}

                      specialization={idx(item,
                        _ => (_.relationships.doctor.data.id === myId ? _.relationships.additionalMember.data[0].attributes.doctor.specialization
                          : _.relationships.doctor.data.attributes.specialization))
                      }
                      specialization_type={idx(item,
                        _ => (_.relationships.doctor.data.id === myId ? _.relationships.additionalMember.data[0].attributes.doctor.specialization_type
                          : _.relationships.doctor.data.attributes.specialization_type))}
                    />
                  }
                </div>
              ))
            }
          </List>
          :
          <List>
            {
              !!filteredList && filteredList.map(item => (
                <div key={idx(item, _ => _.id)}>
                  {
                    ((idx(item, _ => _.attributes.type) === 'TCA') || (!item.attributes.consiliumStatus)) &&
                    <>
                      {
                        loading
                          ?
                          <>
                            <Skeleton variant="circular" width={60} height={60} />
                            <br/>
                            <Skeleton variant="rectangular" width="100%" height={40} />
                            <br/>
                          </>
                          :
                          <Item
                            isTCA={isTCA}
                            item={item}
                            filters={filters}
                            lastmessagetime={idx(item, _ => _.attributes.lastmessagetime)}
                            consultations={consultations}
                            refreshConsultations={refreshConsultations}
                            id={idx(item, _ => _.id)}
                            patient_TCTE={idx(item, _ => _.relationships.patient.data.attributes)}
                            timeCreation={idx(item, _ => _.attributes.creation_time)}
                            additionalMemberIfTEOrTER={idx(item, _ => _.relationships[_.attributes.type === CONSULTATION_TYPES.telexpertise || _.attributes.type === CONSULTATION_TYPES.TER || _.attributes.type === 'TCA' ? 'doctor' : 'patient'].data.attributes)}
                            status={idx(item, _ => _.attributes.status)}
                            accessHealthRecords={idx(item, _ => _.attributes.access_health_record)}
                            onClick={onConsultationClick(item, match)}
                            urgent={!!idx(item, _ => _.attributes.type === 'urgent')}
                            isConcilium={item.attributes.consiliumStatus}
                            type={item.attributes.type}
                            additionalMember={idx(item, _ => _
                              .relationships.additionalMember.data[0].attributes.doctor)}
                            isMy={idx(
                              item,
                              _ => ((_.attributes.type === CONSULTATION_TYPES.TCA)
                                && _.relationships.doctor.data.id === myId),
                            )}
                            sender={idx(item, _ => _.attributes.sender)}
                      specialization={idx(item,
                        _ => (_.relationships.doctor.data.id === myId ? _.relationships.additionalMember.data[0].attributes.doctor.specialization
                          : _.relationships.doctor.data.attributes.specialization))
                      }
                      specialization_type={idx(item,
                        _ => (_.relationships.doctor.data.id === myId ? _.relationships.additionalMember.data[0].attributes.doctor.specialization_type
                          : _.relationships.doctor.data.attributes.specialization_type))}
                    />}
                    </>
                  }
                </div>
              ))
            }
          </List>
        )
      }
      {
        (!loading && !filteredList.length)
        && (
          <EmptyScreen title={emptyScreenTitle} logo={conciliumLogo}/>
        )
      }

      {paginator
        ? (
          <StyledPagination
            onChange={(e, page) => {
              setPage(page)
              executeScroll()
            }}
            color='primary'
            page={get(paginator, 'currentPage', 1)}
            count={get(paginator, 'totalPage', 0)}
          />
        ) : ''}
    </Wrapper>
  )
}

ConsultationsListComponent.defaultProps = {
  nearestUrgent: undefined,
  consultations: [],
}

ConsultationsListComponent.propTypes = {
  from: PropTypes.object.isRequired,
  to: PropTypes.object.isRequired,
  handleFromChange: PropTypes.func.isRequired,
  handleToChange: PropTypes.func.isRequired,
  onConsultationClick: PropTypes.func.isRequired,
  consultations: PropTypes.arrayOf(PropTypes.object).isRequired,
  nearestUrgent: PropTypes.any,
}

const enhance = compose(
  connect(
    state => ({
      nearestUrgent: idx(state, _ => _.auth.attributes.calendar_nearest),
      myId: state.auth.id,
      auth: state.auth,
      loading: state.loading.consultationsList,
    }),
    { fetchConsultation, deleteSessionData, fetchHealthRecords },
  ),
  withStateHandlers({
    from: moment(),
    to: moment().add(7, 'days'),
  }, {
    resetTimeFilter: (state, props) => () => {
      props.fetchConsultations({
        ...props.filters,
        dateFrom: null,
        dateTo: null,
      })
    },
    handleFromChange: () => from => ({ from }),
    handleToChange: (state, props) => (to) => {
      if (props.fetchConsultations && props.filters) {
        props.fetchConsultations({
          ...props.filters,
          dateFrom: state.from.format('YYYY-MM-DD'),
          dateTo: to.format('YYYY-MM-DD'),
        })
      }

      return { to }
    },
    refreshConsultations: (state, props) => () => {
      if (props.fetchConsultations && props.filters) {
        props.fetchConsultations({
          ...props.filters,
          dateFrom: null,
        })
      }
    },
  }),
  withProps(() => ({
    onConsultationClick: ({ id }, match) => () => history.push(`${match.path}/${id}`),
  })),
)

export const ConsultationsList = enhance(ConsultationsListComponent)
import React from 'react'
import PropTypes from 'prop-types'
import {connect, useSelector} from 'react-redux'
import styled from 'styled-components'
import { FormattedMessage } from 'react-intl';
import { LinkCard } from '../../../common/UiKit/LinkCard'
import { Page } from '../../../common/Page'
import { logOut } from '../../../../redux/modules/auth'
import annotation from "../../../../images/panel/doctors/settings/undraw_annotation_re_h.svg";
import personal_information from "../../../../images/panel/doctors/settings/undraw_personal_information_re_vw.svg";
import personal_settings from "../../../../images/panel/doctors/settings/undraw_personal_settings_re.svg";
import secure_login from "../../../../images/panel/doctors/settings/undraw_secure_login_pdn4.svg";
import tuto_video from "../../../../images/panel/doctors/settings/undraw_tutorial_video_re_wepc.svg";
import { history } from '../../../../utils/history'

const LinkCardStyled = styled(LinkCard)`

  padding: 12px 16px 12px 24px;
  margin-bottom: 16px;
`

const TextStyle = styled.h4`

    font-family: Roboto, sans-serif;
    font-size: 16px;
    font-weight: 400;
    color: black;
    text-align: center;
`


const IconBig = styled.img`
  height: 200px;
`

const Card = styled.div`
    display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  font-family: Roboto, sans-serif;
  font-size: 16px;
  margin-right: 20px;
  margin-left: auto;
  padding:  24px;
  border-radius: 8px;
  box-shadow: rgba(0, 0, 0, 0.16) 0px 1px 4px;
  background-color: white;
  height: 180px;
  width: 180px;
  border: 1px solid transparent;
    &:hover {
      & > * {color: #6633FF;}
      box-shadow: rgba(102, 51, 255, 0.7) 0px 2px 4px;
      border: 1px solid #6633FF;
  }
`

const Wrapper = styled.div`
  display: flex;
  justify-content: space-around;
  align-items: center;
`

const LinkStyled = styled.div`
  cursor: pointer;
`

const Title = styled.h2`
  position: relative;
  right: 140px;
`



export const MainComponent = ({
  id,
  logOut,
}) => {

  const role = useSelector(state => state.auth.type)

  return (
      <Page
          label='global.settings'
          onLogout={logOut}
          id={id}
          onBackIconClick={() => history.goBack()}
      >
        <Title> <FormattedMessage id='global.settings' /> </Title>
        <Wrapper>
            <LinkStyled onClick={() => history.push("/panel/settings/parameters")}>
              <Card>
                <IconBig src={personal_settings} />
                <TextStyle><FormattedMessage id='profile.application.parameters' /></TextStyle>
              </Card>
            </LinkStyled>
            <LinkStyled onClick={() => history.push("/panel/settings/connexion-authentification")}>
              <Card>
                <IconBig src={secure_login} />
                <TextStyle><FormattedMessage id='profile.connexion.authentification' /></TextStyle>
              </Card>
            </LinkStyled>
            <LinkStyled onClick={() => history.push("/panel/personal/data")}>
              <Card>
                <IconBig src={personal_information} />
              <TextStyle><FormattedMessage id='global.personal.data' /></TextStyle>
              </Card>
            </LinkStyled>
            <LinkStyled onClick={() => history.push("/panel/settings/about-settings")}>
              <Card>
                <IconBig src={annotation} />
                <TextStyle><FormattedMessage id='profile.about.CDG.version.policy' /></TextStyle>
              </Card>
            </LinkStyled>
        </Wrapper>
      </Page>
  )
}

MainComponent.propTypes = {
  id: PropTypes.string.isRequired,
  logOut: PropTypes.func.isRequired,
}

const enhance = connect(state => ({ id: state.auth.id }), { logOut })

export const Main = enhance(MainComponent)

import React from 'react'
import styled from 'styled-components'
import { useIntl } from 'react-intl'
import { Field } from 'react-final-form'
import { Paper } from '../Paper'
import { MultilineTextField } from '../MultilineTextField'

const Wrapper = styled(Paper)`
  margin-bottom: 16px;
`

const MultilineField = styled(MultilineTextField)`
  & .root {
    height: 112px;
  }
`

export const Symptoms = () => {
  const { formatMessage } = useIntl()

  return (
    <Wrapper heading='consultations.describe'>
      <Field
        component={MultilineField}
        name='symptomExplanation'
        label={formatMessage({ id: 'consultations.explanation' })}
        maxLength={300}
        multiline
        maxRows={4}
      />
    </Wrapper>
  )
}

import { createAction, handleActions } from 'redux-actions'
import axios from 'axios'

//#region Actions
export const createSignatureRequest = createAction('CREATE_SIGNATURE_REQUEST')
export const createSignatureSuccess = createAction('CREATE_SIGNATURE_SUCCESS')
export const createSignatureFailure = createAction('CREATE_SIGNATURE_FAILURE')

export const fetchSignatureRequest = createAction('FETCH_SIGNATURE_REQUEST')
export const fetchSignatureSuccess = createAction('FETCH_SIGNATURE_SUCCESS')
export const fetchSignatureFailure = createAction('FETCH_SIGNATURE_FAILURE')

export const clearSignature = createAction('CLEAR_SIGNATURE')
//#endregion

//#region Thunks
export const createSignature = values => (dispatch) => {
  dispatch(createSignatureRequest(values))
  const formData = new FormData()

  values.file.forEach((item) => {
    formData.append('file', item)
  })

  return axios('doctor/signature/set', {
    method: 'POST',
    data: formData,
  })
    .then(res => dispatch(createSignatureSuccess(res.data.data.attributes)))
    .catch((err) => {
      dispatch(createSignatureFailure(err))
    })
}

export const fetchSignature = cb => (dispatch) => {
  dispatch(fetchSignatureRequest())

  return axios('/doctor/signature/get', {
    method: 'POST',
  })
    .then(res => dispatch(fetchSignatureSuccess(res.data.data.attributes)))
    .then(() => cb())
    .catch((err) => {
      dispatch(fetchSignatureFailure(err))
    })
}

export const createCertifiedSignatureRequest = createAction('CREATE_CERTIFIED_SIGNATURE_REQUEST')
export const createCertifiedSignatureSuccess = createAction('CREATE_CERTIFIED_SIGNATURE_SUCCESS')
export const createCertifiedSignatureFailure = createAction('CREATE_CERTIFIED_SIGNATURE_FAILURE')

export const createCertifiedSignature = values => (dispatch, getStore) => {
  dispatch(createCertifiedSignatureRequest(values))
  const { id } = getStore().auth

    return axios(`/doctor/signature/${id}/certified`, {
    method: 'POST',
    data: {
      signature: values,
    },
  })
      .then(res => dispatch(createCertifiedSignatureSuccess(res.data)))
      .catch((err) => {
        dispatch(createCertifiedSignatureFailure(err))
      })
}

export const getCertifiedSignatureRequest = createAction('GET_CERTIFIED_SIGNATURE_REQUEST')
export const getCertifiedSignatureSuccess = createAction('GET_CERTIFIED_SIGNATURE_SUCCESS')
export const getCertifiedSignatureFailure = createAction('GET_CERTIFIED_SIGNATURE_FAILURE')

export const getCertifiedSignature = values => (dispatch) => {
  dispatch(getCertifiedSignatureRequest(values))

  return axios(`/doctor/signature/${values}/certified`, {
    method: 'GET',
  })
      .then(res => dispatch(getCertifiedSignatureSuccess(res.data)))
      .catch((err) => {
        dispatch(getCertifiedSignatureFailure(err))
      })
}

export const signatureReducer = handleActions({
  [createSignatureSuccess]: (state, action) => action.payload,
  [fetchSignatureSuccess]: (state, action) => action.payload,
  [clearSignature]: () => ({}),
  [createCertifiedSignatureSuccess]: (state, action) => action.payload
}, {})


//#endregion

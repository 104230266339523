import React, {useEffect, useState} from 'react'
import styled from 'styled-components'
import { Paper } from 'common/UiKit/Paper'
import { FormattedMessage } from 'react-intl'
import Switch from "@mui/material/Switch";
import {useDispatch, useSelector} from "react-redux";
import idx from "idx";
import {fetchSettings, updateExternalPartners} from "../../../../../redux/modules/settings";

const PaperStyled = styled(Paper)`
  padding: 0;
`

const TypeLabel = styled.div`
  font-weight: bold;
  padding: 10px 24px;
`
const DivSpan = styled.span`
  padding-left:45px;
  display:flex;
  justify-content:space-between;
`

const SwitchStyled = styled(Switch)`
  justify-content:right;
`

export const ExternalPartners = ({verified}) => {
  const dispatch = useDispatch()
  const stateActivateOlaqin =   useSelector(state => idx(state, _ => _.settings.values.activate_olaqin))
  const [activateOlaqin, setActivateOlaqin] = useState(!!parseInt(stateActivateOlaqin));
  const [activating, setActivating] = useState(false)

  useEffect(() => {
    setActivateOlaqin(parseInt(stateActivateOlaqin))
  }, stateActivateOlaqin)

  const onOlaqinActivationChanged = (event) => {
    const activated = !activateOlaqin
    setActivating(true)
    dispatch(updateExternalPartners({
      activate_olaqin: activated ? 1 : 0,
    })).then(() => {
      setActivateOlaqin(activated)
      return dispatch(fetchSettings())
    }).finally(() => {
      setActivating(false)
    })
  }

  return (
    <PaperStyled
        heading='profile.externalpartner'
    >
      <TypeLabel>
        <FormattedMessage id='profile.externalpartner.olaqin' />
      </TypeLabel>
      <DivSpan>
        <FormattedMessage id='profile.externalpartner.olaqin.checkbox' />
        <SwitchStyled
            name='profile.externalpartner.olaqin.checkbox'
            color='success'
            checked={activateOlaqin}
            inputProps={{'aria-label': 'controlled'}}
            disabled={!verified || activating}
            onChange={onOlaqinActivationChanged}
        />
      </DivSpan>
    </PaperStyled>
  )
}

import React, {useEffect, useState} from "react";
import Box from "@mui/material/Box";
import {FormattedMessage, useIntl} from "react-intl";
import styled from "styled-components";
import {SearchBar} from "./SearchBar";
import axios from "axios";
import {history} from "../../../../utils/history";
import {TextButton} from "../../../common/UiKit/TextButton";
import {toggleFavorite} from "../../../../redux/modules/doctors";
import {useDispatch} from "react-redux";


const Wrapper = styled.div`
  justify-content: center;
`
const TitleStyled = styled.div`
  font-size: 18px;
  text-align: center;
  font-weight: 500;
  display: block;
  margin-top: 30px;
  margin-left: auto;
  margin-right: auto;
  padding-left: 50px;
  padding-right: 50px;
`

const SubtitleStyled = styled.div`
  font-size: 16px;
  font-weight: normal;
  text-align: center;
`
const ContinueButton = styled(TextButton)`
  box-shadow: none;
  align-self: center;
  z-index: 10;
  && {
    width: 20%;
    height: 35px;
    display: block;
    margin: 80px auto 30px auto;
    background-color: #6633FF;
    border: 1px solid #fff;
    color: #fff;
  }
  &:hover{
    background-color: rgb(71, 35, 178);
    border: 1px solid #fff;
    color: #fff;
  }
  text-transform : none;
  font-weight: 500;
  font-size: 14px;
`

export const StepDoctor = ({value, setOpen, isViewed}) => {
    const [id, setId] = useState(0);
    const type = 'doctor';
    const dispatch = useDispatch();
    return (
        <Wrapper>
            <Box display="flex" >
                <TitleStyled>
                    <FormattedMessage id='survey.who'/>
                    <br/>
                    <SubtitleStyled>
                        <FormattedMessage id='survey.subtitle'/>
                    </SubtitleStyled>
                </TitleStyled>
            </Box>
            <SearchBar type={type} id={id} setId={setId}/>
            <ContinueButton
                onClick={
                    async () => {
                        try {
                            await axios.post('forms/answer', {form: 'Formulaire première connexion', answer: value, doctor_id: id, structure_id: null, comments: null})
                        } catch (e) {
                            console.warn(e);
                        }
                        dispatch(toggleFavorite(id, 'POST'));
                        setOpen(false);
                        await isViewed();
                        history.push('/panel/proximity');
                    }
                }
                variant='contained'
                color='primary'
            >
                <FormattedMessage id='survey.continue'/>
            </ContinueButton>
        </Wrapper>
    )
}
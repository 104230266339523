import axios from 'axios';
import get from 'lodash/get';
import queryString from "query-string";

export const getStructures = async (filter) => {
  try {
    const base = '/api/doctor/structure/list';
    const resp = await axios.get(`${base}?${queryString.stringify({ ...filter, ...{ all: true } })}`);
    return resp.data;
  } catch (e) {
    console.warn(e);
  }
  return [];
}

export const getSearchResult = async (filter) => {
  try {
    const base = '/api/general/search'
    const resp = await axios.get(`${base}?${queryString.stringify({...filter, include: 'workPlace,structures'})}`)
    return resp.data;
  } catch (e) {
    console.warn(e);
  }
}

export const getAroundMeResult = async (filter, abortController = {}) => {
  try {
    if(abortController.current){
      abortController.current.abort()
    }
    abortController.current = new AbortController();

    let base = '/api/general/search/around'

    const resp = await axios.get(
      `${base}?${queryString.stringify({...filter, include: 'workPlace,structures', all: true})}`,
      {signal: abortController.signal})
    return resp.data;
  } catch (e) {
    console.warn(e);
  }
}
export const getStructure = async (structureId) => {
  try {
    const base = `/api/doctor/structure/${structureId}`;
    const resp = await axios.get(`${base}`);
    return resp.data;
  } catch (e) {
    console.warn(e);
  }
  return [];
}

export const getMembersOfStructure = async (structureId, filter = {}, reverseGeocoding = {}) => {
  try {
    const base = `/api/doctor/structure/${structureId}/members/v2`
    const filters = {...filter}
    if (reverseGeocoding.latitude && reverseGeocoding.longitude) {
      filters.latitude = reverseGeocoding.latitude
      filters.longitude = reverseGeocoding.longitude
    }
    const resp = await axios.get(`${base}?${queryString.stringify(filters)}`)
    return resp.data;
  } catch (e) {
    console.warn(e);
  }
  return [];
}

export const getTeamsOfStructure = async (structureId) => {
  try {
    const base = `/api/doctor/structure/${structureId}/teams`
    const resp = await axios.get(`${base}`)
    return resp.data;
  } catch (e) {
    console.warn(e);
  }
  return [];
}

export const JoinStructure = async (structureId) => {
  try {
    await axios.post(`/api/doctor/structure/${structureId}/join`)
  } catch (e) {
    console.warn(e);
  }
}

export const leaveStructure = async (structureId) => {
  try {
    await axios.delete(`/api/doctor/structure/${structureId}/leave`)
      .then(() => {
        window.location.reload();
      })
  } catch (e) {
    console.warn(e);
  }
}

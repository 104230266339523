import React from 'react'
import { Route, Switch } from 'react-router-dom'
import { List } from './List'
import { Report } from './Report'

export const MedicalReports = () => (
  <Switch>
    <Route path='/panel/health-records/medical-reports/report' component={Report} />
    <Route component={List} />
  </Switch>
)

import React, { useEffect, useState } from 'react';
import { FormattedMessage } from 'react-intl';
import Grid from '@mui/material/Grid';
import EditIcon from '@mui/icons-material/Edit';
import styled from 'styled-components';
import get from 'lodash/get';
import {useDispatch, useSelector} from 'react-redux';
import { MultipleInfinityAsyncSelect } from 'common/AsyncSelect/MultipleInfinity';
import difference from 'lodash/difference';
import Button from '@mui/material/Button';
import {
  getTeamById, getTeamMembersById, sendInvite
} from '../../../../../../helpers/Doctor/TeamHelper';
import { Page } from '../../../../../common/Page';
import { history } from '../../../../../../utils/history';
import { mdiHospitalBuilding } from '@mdi/js';
import PlaceIcon from '@mui/icons-material/Place';
import NotesIcon from '@mui/icons-material/Notes';
import GroupsIcon from '@mui/icons-material/Groups';
import CategoryIcon from '@mui/icons-material/Category';
import EsspLogo from '../images/Logo_ESSP.png';
import EssLogo from '../images/Logo_ESS.png';
import {toggleFavoriteTeam} from "../../../../../../redux/modules/teams";
import {Link} from "react-router-dom";
import {Colors, STATUS_COLORS} from "../../../../../../constants/colors";
import Icon from "@mdi/react";
import {DefaultAvatar} from "../../../../../common/DefaultAvatar";
import {SecuredBckgImage} from "../../../../../common/SecuredImage";
import {FavoriteButton} from "../../../../../common/DoctorCard/FavoriteButton";
import {TextButton} from "../../../../../common/UiKit/TextButton";
import {fullName} from "../../../../../../helpers/user"
import CustomLoaderCircular from "../../../../../common/CustomLoaderCircular";

export const ManageTeam = ({ match }) => {
  const dispatch = useDispatch()
  // eslint-disable-next-line
  const first_name = useSelector(state => state.auth.attributes.first_name);
  // eslint-disable-next-line
  const last_name = useSelector(state => state.auth.attributes.last_name);
  const authType = useSelector(state => state.auth.type)
  const isDoctor = authType === 'Doctor'
  // eslint-disable-next-line
  const onBackIconClick = () => history.goBack();
  const id = get(match, 'params.id', null);
  const [team, setTeam] = useState([]);
  const [teamMembers, setTeamMembers] = useState([]);
  const [listTeamMembers, setListTeamMembers] = useState([]);
  const [teamMembersAdd, setTeamMembersAdd] = useState([]);
  const teamInfo = get(team, 'attributes', {});
  const refreshTeam = () => {
    if (!!id) {
      getTeamById(id).then(result => setTeam(result));
    }
  }
  const refreshMembersTeam = () => getTeamMembersById(id).then(result => {
    setTeamMembers(result)
    setListTeamMembers(result.map((el) => get(el, 'attributes.doctor', '').toString()))
  });
  const structures = get(team, 'relationships.structures.data', []);
  const teamCreator = get(team, 'attributes.creator', '');
  // eslint-disable-next-line
  const specialization_id = useSelector(state => state.auth.attributes.specialization_id);
  // eslint-disable-next-line
  const specializationMemberFilter = teamInfo.type === 'ESS' ? `&specialization=${specialization_id}` : '';

  // For now we hide the edit button whatever
  const isMyTeam = teamCreator === `${first_name} ${last_name}`;
  const mySkills = get(team, 'relationships.skills.data', []);
  const favoriteToggle = useSelector(state => state.loading.favoriteToggle);

  const availabilityValues = []

  const doctorAvailability = () => {
    teamMembers.some(function(teamMember) {
      availabilityValues.push(teamMember.relationships.doctor.data.attributes.status_availability)
    })
    return availabilityValues.includes(true)
  }

  const onButtonClickTERTeam = (team_id, isUrgent, type) => (e) => {
    const url = `/panel/concilium/team/create/${team_id}/${type}${isUrgent ? '?urgent=1' : ''}`
    e.stopPropagation()

    return history.push(url)
  }


  const inviteMembers = () => {
    // nouveau tableau composé des id ajouté (teamMembersAdd) moins les id déjà existants (listTeamMembers) et filtré pour enlevés les chaines de caractère vides
    const newTeamMember = difference(teamMembersAdd, listTeamMembers).filter(el => el !== '');

    refreshTeam();
    refreshMembersTeam();
    if (newTeamMember && newTeamMember[0] && teamMembersAdd.filter(el => el.length < listTeamMembers.length)) {
      newTeamMember.forEach(el => sendInvite({ team_id: team.id, member_id: el }).then(() => setTeamMembersAdd([])));
    }
  }

  useEffect(() => {
    refreshTeam();
    refreshMembersTeam();
  }, [teamMembersAdd, favoriteToggle]);

  return (
      <Page
          label={teamInfo && teamInfo.name ? teamInfo.name : 'roso.community.label'}
          onBackIconClick={onBackIconClick}
          noHeaderCapitalize
          fullWidth
          style={{ backgroundColor: '#f5f7fb' }}
      >
        <Wrapper>
          <Container style={{padding: '0 15px 15px 15px'}}>
            <Grid columnSpacing={10} container>
              <Grid item xs={2}>
                <Img src={teamInfo.type === 'ESS' ? EssLogo : EsspLogo } alt='EsspLogo' />
              </Grid>
              <Grid item xs={10}>
                <NameWrapper>
                  <TeamName>
                    {teamInfo.name}
                  </TeamName>
                  <WrapperFavorite>
                    {isDoctor &&
                      <FavoriteButton checked={teamInfo.favorite}
                                      onClick={() => dispatch(toggleFavoriteTeam(id, !teamInfo.favorite))}
                                      isFetching={favoriteToggle === team.id}
                                      message={true}
                      />}
                  </WrapperFavorite>
                </NameWrapper>
                  {structures?.length > 0 ? (
                      <div>
                        <H5>
                          <IconStyled
                              path={mdiHospitalBuilding}
                              size={1}
                          />
                          <GridStructure>
                          {structures.map((el, i) => (
                                <CustomLink to={`/panel/concilium/main/structure/${el.attributes.id}`}>
                                  <StructureName key={el.attributes.id}>
                                    {el.attributes.name}{i + 1 < structures.length ? ',' : ''}&nbsp;
                                  </StructureName>
                                </CustomLink>
                          ))}
                          </GridStructure>
                        </H5>
                        <H5>
                          <IconMaterial>
                            <PlaceIcon style={{color: '#585C65', minWidth: '24px', minHeight: '24px'}}/>
                          </IconMaterial>
                          <GridStructure>
                          {structures.map((el, i) => (
                            <span key={el.attributes.id}>
                              {el.attributes.city}{i + 1 < structures.length ? ',' : ''}&nbsp;
                            </span>
                          ))}
                          </GridStructure>
                        </H5>
                      </div>
                  ) : ''}

                <H5>
                  <IconMaterial>
                      <NotesIcon style={{minWidth: '24px', minHeight: '24px'}}/>
                  </IconMaterial>
                  <FormattedMessage id='roso.team.description' />
                </H5>
                <TeamDescription>{teamInfo.description}</TeamDescription>
                {
                    teamInfo.type === 'ESS' && doctorAvailability() === true && (
                        <ButtonTER
                            variant='extended'
                            onClick={onButtonClickTERTeam(teamInfo.id, true, teamInfo.type)}
                        >
                          <FormattedMessage id='consultation.rosofollow.team' />
                        </ButtonTER>
                    )
                }
              </Grid>
            </Grid>

          </Container>
          {mySkills && mySkills.length ? (
          <Wrapper >
              <Container style={{padding: '0 15px 15px 15px'}}>
                <H5>
                  <IconMaterial >
                    <CategoryIcon style={{width: '24px', height: '24px'}}/>
                  </IconMaterial>
                  <SkillsTitle>
                    <FormattedMessage id='roso.team.skills.domains'/>
                  </SkillsTitle>
                </H5>
                <SkillsBlock>
                  {mySkills.map(el => (
                      <Skills
                          key={el.attributes.id}
                      >
                        {el.attributes.name}
                      </Skills>
                  ))}
                </SkillsBlock>
              </Container>
          </Wrapper>
          ) : ''}
          <Wrapper>
            <Container style={{padding: '0 15px 15px 15px'}}>
              <H5>
                <IconMaterial>
                  <GroupsIcon style={{width: '24px', height: '24px'}}/>
                </IconMaterial>
                <Members>
                  <FormattedMessage id='roso.members' /> ({teamMembers && teamMembers.length})
                </Members>
                <span style={{marginLeft: "10px"}}>
                  <CustomLoaderCircular isShow={teamMembers.length === 0} size={20} />
                </span>
              </H5>
              <Grid container>
              {
                teamMembers.map((el) => {
                  const isValid = get(el, 'relationships.doctor.data.attributes.status_availability', false);
                  const member = get(el, 'relationships.doctor.data.attributes', {});
                  const doctorId = get(el, 'attributes.doctor', '');
                  const specializationType = get(el, 'relationships.doctor.data.attributes.specialization_type', '');
                  const specializationKey = get(el, 'relationships.doctor.data.attributes.specialization', '');
                  const gender = get(el, 'relationships.doctor.data.attributes.gender', '');
                  const firstName = get(el, 'relationships.doctor.data.attributes.first_name', '');
                  const lastName = get(el, 'relationships.doctor.data.attributes.last_name', '');
                  const full_name = fullName(gender, firstName, lastName);

                  const photo = get(el, 'relationships.doctor.data.attributes.photo', '');

                  return (
                    <Grid style={{display: 'flex', justifyContent: 'center'}} item xs={6} key={member.user_id}>
                      <Link to={isDoctor ? `/panel/concilium/specializations/doctor/${doctorId}` : `/panel/doctors/doctor/${doctorId}`}>
                      <MemberContainer>
                        <AvatarWrapper>
                          {photo ? (
                                <DispoAvatar
                                    spinner={{width: '120px', height: '120px'}}
                                    src={photo.includes('/file/') ? `http://backend-application.owndoctor.eu${photo}` : photo}
                                    status={isValid}
                                >
                                </DispoAvatar>
                              ) : (
                                  <DispoDefault
                                      spinner={{width: '120px', height: '120px'}}
                                      style={{backgroundImage: `url(${DefaultAvatar(firstName, lastName, specializationType)})`}}
                                      status={isValid}
                                  />
                            )}
                        </AvatarWrapper>
                        <Info>
                          <Name>
                            {full_name}
                          </Name>
                          <Specialization>
                            {specializationKey}
                          </Specialization>
                        </Info>
                      </MemberContainer>
                      </Link>
                    </Grid>
                  )
                })
              }
              </Grid>
            </Container>
          </Wrapper>


          {isMyTeam ? (
              <ShowContainer>
                <H5>
                  <FormattedMessage id='roso.team.invite.new.member' />
                </H5>

                <MultipleInfinityAsyncSelect
                    listMembersAtCreation={listTeamMembers}
                    val={teamMembersAdd}
                    input={{
                      value: teamMembersAdd,
                      onChange: (value) => {
                        setTeamMembersAdd(value);
                      },
                    }}
                    createDefaultField
                    cacheOptions={false}
                    autoFocus
                    placeholder='roso.team.add.new.member'
                    name='members'
                    valuePath='id'
                    imagePath='attributes.photo'
                    noMarginError
                    labelPath={[
                      'attributes.first_name',
                      'attributes.last_name',
                      'relationships.workPlace.data[0].attributes.city',
                      'attributes.specialization_type',
                    ]}
                    requestUrl={`/general/doctor?include=workPlace,structures${specializationMemberFilter}&name=`}
                />
                <SaveButtonContainer>
                  <Button onClick={
                    inviteMembers
                  }
                          variant='contained'
                          color='primary'>
                    <FormattedMessage id='global.send' />
                  </Button>
                </SaveButtonContainer>
              </ShowContainer>
          ) : ''}
        </Wrapper>
      </Page>


  )
};

const SaveButtonContainer = styled.div`
  text-align: right;
`

const Members = styled.h3`
  display: flex;
  justify-content: center;
  font-family: Roboto;
  text-align: left;
  color: #585C65;
  word-break: break-word;
`

const Container = styled.div`
  background-color: #ffffff;
  padding: 15px;
  margin: 15px 0;
  border-radius: 5px;
  box-shadow: 0 4px 8px 0 rgb(6 173 255 / 10%);
`

const IconMaterial = styled.div`
  padding-right: 10px;
  color: #585C65;
`

const IconStyled = styled(Icon) `
  min-width: 24px;
  min-height: 24px;
  padding-right: 10px;
  color: #585C65;
`

const ShowContainer = styled.div`
background-color: #ffffff;
 margin-bottom: 15px;
 padding: 15px;
 .MuiFilledInput-underline:before {
  border-bottom: none;
}
`

const H5 = styled.div`
  font-family: Roboto;
  font-size: 16px;
  font-weight: 500;
  margin: 10px 0;
  word-break: break-word;
  display: flex;
  flex-wrap: wrap;
  align-items: center;

  span {
  font-family: Roboto;
  font-size: 16px;
  font-weight: 400;
  }
  p {
  margin: 5px 0;
  font-family: Roboto;
  font-size: 15px;
  font-weight: 400;
  }
`
const TeamName = styled.h1`
  font-size: 24px;
  text-align: left;
  color: #21293e;
  margin-top: 25px;
  margin-bottom: 24px;
  word-break: break-word;
`

const SkillsTitle = styled.h3`
  text-align: left;
  color: #585C65;
  word-break: break-word;
`
const Img = styled.img`
    width: 150px;
    margin-top: 10px;
`
const Wrapper = styled.div`
  max-width: 900px;
  min-width: 900px;
  margin: 24px auto;
.MuiGrid-grid-xs-1 {
    text-align: center;
    padding-top: 19px;
}
  .MuiButton-label {
  text-transform: initial;
`

const SkillsBlock = styled.div`
  margin-left: 40px;
  display: flex;
  max-width: 90%;
  flex-wrap: wrap;
`
const Skills = styled.div`
  background-color: ${Colors.purpleGrey};
  color: #5D5577;
  padding: 3px 16px;
  border-radius: 11px;
  margin: 7px;
  margin-left: 0;
  margin-top: 4px;
  margin-bottom: 4px;
  word-break: initial;
  display: inline-flex;
  justify-content: center;
  text-align: center;
`

const AvatarWrapper = styled.div`
  & > p {
    margin: 16px 0 0;
    color: ${Colors.clearBlue};
    font-size: 24px;
    font-weight: bold;
    text-align: center;
  }
`

const DispoAvatar = styled(SecuredBckgImage)`
    min-width: 70px;
    width: 70px;
    height: 70px;
    margin-bottom: 14px ;
    border-radius: 100%;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    &:after {
      position : relative;
      content: "oo";
      background-color: ${({ status }) => (status) ? STATUS_COLORS["available"] : STATUS_COLORS["notavailable"]};
      color: transparent;
      border-radius: 50%;
      border: 2px solid white;
      top: 50px;
      left:50px;
    }
`

const DispoDefault = styled.div`
    min-width: 70px;
    width: 70px;
    height: 70px;
    margin-bottom: 14px ;
    border-radius: 100%;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    &:after {
      position : relative;
      content: "oo";
      background-color: ${({ status }) => (status) ? STATUS_COLORS["available"] : STATUS_COLORS["notavailable"]};
      color: transparent;
      border-radius: 50%;
      border: 2px solid white;
      top: 50px;
      left:50px;
    }
`

const Name = styled.h3`
  margin: 0;
  font-weight: bold;
`

const Info = styled.div`
  flex-grow: 1;
  margin: 12px 32px 0;
`

const Specialization = styled.p`
  margin: 0;
  opacity: 0.8;
  padding-top: 10px;
  color: black !important;
`

const MemberContainer = styled.div`
  display: flex;
  margin: 15px;
  width: 350px;
  background-color: #ffffff;
  padding: 15px;
  border-radius: 5px;
  border: 2px solid transparent;
  box-shadow: rgb(0 0 0 / 2%) 0px 1px 3px 0px, rgb(27 31 35 / 15%) 0px 0px 0px 1px;  color: black;
  
  &:hover {
    border: 2px solid #6633FF;
  }
  
  &:hover *{
    color: #6633FF;
  }
`

const GridStructure =styled.div`
  display: contents;
`

const CustomLink = styled(Link)`
  color: black;
  &:hover {
    color: #6633FF;
  }
`

const StructureName = styled.div`
  font-size: 20px;
  font-weight: 500;
`

const TeamDescription = styled.p`
  color: #949eb8;
  padding-left: 45px;
  word-wrap: break-word;
  white-space: pre-wrap;
`

const NameWrapper = styled.div`
  justify-content: space-between;
  display: flex; 
`

const WrapperFavorite = styled.div`
  align-items: start;
  min-width: 182px;
  margin-top: 25px;
`

const ButtonTER = styled(TextButton)`
  && {
    height: 50px;
    margin-top: 18px;
    background-color: #6633FF;
    color: #fff;
    flex: 0 0 auto;
  }
`

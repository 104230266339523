import formatStringByPattern from 'format-string-by-pattern';
import get from 'lodash/get';
import set from 'lodash/set';

const formatOnlyNumbers = (anyString = '') => {
  const onlyNumbers = anyString.replace(/[^\d]/g, '');
  return formatStringByPattern('99 999 9999', onlyNumbers);
};

const mappingRawToFormPatient = {
  firstName: 'first_name',
  lastName: 'last_name',
  birthday: 'date_of_birth',
  gender: 'gender',
  insurance: 'medical_insurance',
  birthplace: 'birthplace',
  cpam: 'cpam',
  birth_lastname: 'birth_lastname',
  height: 'height',
  weight: 'weight',
  lastHospitalized: 'lastHospitalized.date',
  note: 'note',
  is_foreign: 'is_foreign',
}

export const transformRawPatientToForm = (rawPatient) => {
  const allTogether = { ...rawPatient, ...rawPatient.attributes };
  return Object.keys(mappingRawToFormPatient).reduce((accum, el) => {
    if (get(allTogether, mappingRawToFormPatient[el], false)) {
      const toReturn = { ...accum };
      let toSet = get(allTogether, mappingRawToFormPatient[el]);
      if (el === 'cpam' && toSet) {
        toSet = String(toSet);
      }
      set(toReturn, el, toSet);
      return toReturn;
    }
    return accum;
  }, {});
}

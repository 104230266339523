import React, { useEffect, useState } from 'react'
import styled from 'styled-components'
import { Paper } from '../../../../common/UiKit/Paper'
import {getDoctorStructures, getDoctorTeams} from "../../../../../helpers/Doctor/TeamHelper";
import {FormattedMessage} from "react-intl";
import {history} from "../../../../../utils/history";
import essLogo from "../../Community/ROSO/images/Logo_ESS.png";
import esspLogo from "../../Community/ROSO/images/Logo_ESSP.png";
import axios from "axios";
import get from "lodash/get";
import Tooltip from "@material-ui/core/Tooltip";
import {leaveStructure} from "../../../../../helpers/structures";
import CloseIcon from "@mui/icons-material/Close";
import defaultLogo from "../../../../../images/doctors/structures/logo_structure.png";
import { SecuredBckgImage } from '../../../../common/SecuredImage'
import Dialog from '@mui/material/Dialog'
import {StyledTextButton} from "../../../../common/UiKit/TextButton";
import PropTypes from "prop-types";

const PaperStyled = styled(Paper)`
  padding: 0;
`

const ItemWrapper = styled.div`
  box-sizing: border-box;
  display: grid;
  grid-template-columns: 2fr;
  grid-template-rows: ${props => (props.structure ? '0.5fr 3fr 6fr' : '3fr 6fr')};
  text-align: center;
  justify-content: center;
  align-items: center;
  height: 160px;
  width: 138px;
  border-radius: 10px;
  border: #e2e1e1 2px solid;
  padding: 2px;
  padding-bottom: 8px;
  padding-top: ${props => (props.structure ? '0' : '20px')};
  elevation: 4px;
  margin: auto;
`

const Grid = styled.div`
  display: grid;
  grid-template-columns: 2fr 2fr 2fr 2fr;
  row-gap: 20px;
  margin: auto;
`

const Content = styled.div`
  display: flex;
  margin-top: 16px;
  justify-content: right;
`

const CreateBlockTitle = styled.div`
  font-family: Roboto;
  font-size: 24px;
  font-weight: 500;
  color: #21293e;
  padding: 0 24px 10px;
  flex: 1;
`

const ButtonBlock = styled.div`
  display: flex;
  align-items: center;
  margin-right: 10px;
  justify-items: end;
`

const Name = styled.div`
  opacity: 0.8;
  margin: 8px 5px 0px;
  font-family: Roboto;
  font-size: 14px;
  color: #21293e;
  cursor: pointer;
  overflow: hidden;
  text-overflow: ellipsis;
  
  display: -webkit-box;
  -webkit-line-clamp: ${props => (props.structure ? '2' : '3')};
  -webkit-box-orient: vertical;  
  
  &:hover {
    color: #6633FF;
  }
`

const Img = styled.img`
  width: 100px;
  justify-self: center;
`

const Avatar = styled(SecuredBckgImage)`
  min-width: 70px;
  width: 70px;
  height: 70px;
  border: 2px solid white;
  border-radius: 100%;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  display: flex;
  align-items: center;
  justify-self: center;
  justify-content: center;
  box-shadow: 0 4px 8px 0 rgba(6, 173, 255, 0.1);
  position: relative;
`

const CancelButton = styled.div`
  display: flex;
  justify-self: end;
  cursor: pointer;
`

const StyledCloseIcon = styled(CloseIcon)`
  font-size: 20px;
  color: #e2e1e1;
  &:hover {
    color: #6633FF;
  }
`

const List = styled.div`
  padding: 24px;
  margin: auto;
`

const ContentDialog = styled.div`
  padding: 32px 48px;
  max-width: 272px;
  text-align: center;
`

const DialogButtons = styled.div`
  margin-top: 40px;
  display: flex;
  justify-content: space-around;
`

export const Roso = ({ locked }) => {
    const [teams, setTeams] = useState([]);
    const [structures, setStructures] = useState([]);
    const refreshMyTeams = () => getDoctorTeams().then(result => setTeams(result));
    const refreshMyStructures = () => getDoctorStructures().then(result => setStructures(result));
    const [structureId, setStructureId] = useState([]);
    const [open, toggle] = useState(false)
    const [quitStruct, setQuitStruct] = useState(null)

    const getStructureIds = async () => {
        try {
            const myStruct = await axios.get('/api/doctor/structure/list')
            const ids = get(myStruct, 'data.data', []).map(el => el.id)
            setStructureId(ids);
        } catch (e) {
            console.warn(e);
        }
    }

    useEffect(() => {
        refreshMyTeams();
        refreshMyStructures();
        getStructureIds();
    },[]);

    return(
      <>
        <PaperStyled
            heading='profile.roso.structure'
        >
          <Content>
            <CreateBlockTitle>
              <FormattedMessage id='roso.community.page.title2' />
            </CreateBlockTitle>
          </Content>
          <List>
            <Grid>
              {teams.map((team, index) => {
                const teamData = team.attributes;
                return (
                  <ItemWrapper key={index}>
                    <Img src={ teamData.type == "ESSP" ? esspLogo : essLogo } alt={ teamData.type == "ESSP" ? 'essp' : 'ess' } />
                      <Name
                        onClick={() => {
                          if (!locked) {
                            history.push(`/panel/community/roso/detail/${teamData.id}`)
                          }
                        }}
                      >
                        {teamData.name}
                      </Name>
                  </ItemWrapper>
                )
              })}
            </Grid>
          </List>
          <Content>
            <CreateBlockTitle>
              <FormattedMessage id='structures.mesStructures' />
            </CreateBlockTitle>
            <ButtonBlock>
              <StyledTextButton
                disabled={locked}
                onClick={() => history.push('/panel/community/joinstructures')}
                color='white'
              >
                <FormattedMessage id='structures.joinTitle' />
              </StyledTextButton>
            </ButtonBlock>
          </Content>
          <List>
            <Grid>
              {structures.map((el, index) => {
                const structureData = el.attributes;
                const photo=get(structureData, 'photo', null)
                return (
                  <ItemWrapper  structure key={index}>
                    {
                      locked ? (
                        <CancelButton />
                      ) : (
                        <CancelButton
                          variant='outlined'
                          onClick={async () => {
                            setQuitStruct(el.id)
                            toggle(true)}}
                        >
                          <Tooltip title='Annuler'>
                            <>
                              <StyledCloseIcon/>
                            </>
                          </Tooltip>
                        </CancelButton>
                      )
                    }

                    {photo ?
                      <Avatar
                        spinner={{width: '120px', height: '120px'}}
                        src={photo.includes('/file/') ? `http://backend-application.owndoctor.eu${photo}` : photo}
                      /> :
                      <Avatar
                        spinner={{width: '120px', height: '120px'}}
                        src={defaultLogo}
                      />}
                      <Name
                        onClick={() => {
                          if (!locked) {
                            history.push(`/panel/community/structures/${el.id}`)
                          }
                        }}
                        structure
                      >
                        {structureData.name}
                      </Name>
                  </ItemWrapper>
                )
              })}
            </Grid>
          </List>
        </PaperStyled>
        <Dialog open={open}>
          <ContentDialog>
            <FormattedMessage id='structures.quit' />
            <DialogButtons>
              <StyledTextButton color='white' onClick={async () => {
                await leaveStructure(quitStruct);
                setStructureId(structureId.filter(f => f !== quitStruct));
                toggle(false)}}
               >
                 <FormattedMessage id='structures.leaveTheStructure' />
              </StyledTextButton>
              <StyledTextButton color='white' onClick={() => toggle(false)}>
                <FormattedMessage id='global.cancel' />
              </StyledTextButton>
            </DialogButtons>
          </ContentDialog>
        </Dialog>
      </>
    )
}

Roso.propTypes = {
  locked: PropTypes.bool,
}

Roso.defaultProps = {
  locked: true,
}

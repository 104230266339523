import React from "react";
import styled from "styled-components";
import PropTypes from "prop-types";
import {Colors} from "../../../constants/colors";

export const TitledBlock = ({title, anchor, children}) => (
  <>

    <Title id={ anchor ? anchor : '' }>
      {title}
    </Title>
    <Content>{children}</Content>
  </>
)

TitledBlock.propTypes = {
  title: PropTypes.oneOfType([PropTypes.string, PropTypes.element]).isRequired,
}

// --------------------------------- Styles CSS -------------------------------------

const Title = styled.h3`
  color: ${Colors.conexGrey};
  & > svg {
    vertical-align: middle;
    margin: 0 15px 0 10px;
  }
`

const Content = styled.div`
  margin: 0 45px;
`

import React, { useEffect } from 'react';
import PropTypes from 'prop-types'
import styled from 'styled-components'
import { useSelector, useDispatch } from 'react-redux'
import idx from 'idx'

import { fetchConsultation } from '../../../../../redux/modules/consultations'

import { Download } from '../Download'
import { MESSAGE_TYPES } from '../../../../../constants/messageTypes'

const DownloadStyled = styled(Download)`
  min-width: 260px;
`

const List = styled.ol`
  counter-reset: myCounter;
  padding-left: 0;
  margin: 0;

  &>li {
    list-style: none;
    display: flex;
  }

  &>li:before {
    counter-increment: myCounter;
    content: counter(myCounter) ".";
    margin-right: 13px;
  }
`

export const PrescriptionMessage = ({
  medicines,
  chatId,
}) => {
  const downloadLink = useSelector((state) => {
    const messages = state.chats[chatId].lastMessages || []

    const report = messages.find(msg => msg.attributes.type === MESSAGE_TYPES.report)

    return idx(report, _ => _.attributes.message.medicalReport.pdf.prescription) || ''
  })
  const loaded = useSelector(state => idx(state, _ => _
    .consultations.consultation.relationships.drugs.data.length))
  const dispatch = useDispatch()

  useEffect(() => {
    if (!loaded) {
      dispatch(fetchConsultation(null, null, true))
    }
  }, [dispatch])

  return (
    <DownloadStyled
      heading='consultations.prescription'
      seeButtonPath='/panel/consultations/session/menu/prescription'
      downloadButtonPath={downloadLink}
    >
      <List>
        {medicines.map(item => <li key={item}>{item}</li>)}
      </List>
    </DownloadStyled>
  )
}

PrescriptionMessage.propTypes = {
  medicines: PropTypes.array.isRequired,
  chatId: PropTypes.string.isRequired,
}

import React, { useState, useRef, useEffect } from 'react';
import styled from 'styled-components';
import get from 'lodash/get';
import {useDispatch, useSelector} from 'react-redux';
import { FormattedMessage, useIntl } from 'react-intl'
import {
  getMembersOfStructure,
  getTeamsOfStructure, getStructure
} from '../../../../../helpers/structures';
import { Page } from '../../../../common/Page'
import { history } from '../../../../../utils/history'
import {StructureCard} from "../../../../common/StructureCard";
import idx from "idx";
import {DoctorCard} from "../../../../common/DoctorCard";
import {Search} from "../../../../common/Search";
import {toggleFavorite} from "../../../../../redux/modules/doctors";
import {toggleFavoriteStructure} from "../../../../../redux/modules/structures";
import Pagination from "@mui/material/Pagination";
import {Item} from "../../Concilium/DoctorTeams/ListItemsTeams";
import {GeoSearch} from "../../../../common/GeoSearch";
import CustomLoaderRectangleAndCircle from "../../../../common/CustomLoaderRectangleAndCircle";

const CardWrapper = styled.div`
 margin-top: 20px;
`

const DoctorsContainer = styled.div`
  width: 720px;
  margin: 0 auto;

  & > * {
    margin-bottom: 24px;
  }
`

const Title = styled.div`
  font-family: Roboto;
  font-size: 24px;
  font-weight: 500;
  color: #21293e;
  margin-bottom: 30px;
  margin-top: 60px;
  justify-content: left;
  flex: 1;
`

const SearchWrapper = styled.div`
    & > div {
        margin-right: 0;
    }
    & > * {
        max-width: 100%;
    }
    margin: 0px auto;
    margin-top: 24px;
`

const TopAnkor = styled.div`
  position: absolute;
  top: 0;
`

const StyledPagination = styled(Pagination)`
  & > ul {
    align-items: center;
    justify-content: center;
  }
`

const Wrapper = styled.div`
  max-width: 720px;
  min-width: 720px;
  margin: 24px auto;
`


export const StructurePage = ({ match }) => {
  const {formatMessage} = useIntl()
  const f = (id) => formatMessage({ id: id })
  const [structure, setStructure] = useState({});
  const [teams, setTeams] = useState([]);
  const [cardDoctors, setCardDoctors] = useState([]);
  const [isMember, setIsMember] = useState(null);
  const myProfileId = useSelector(state => state.auth.id);
  const [doctorFilter, setDoctorFilter] = useState({limit : 10, page : 1});
  const dispatch = useDispatch();
  const favoriteToggle = useSelector(state => state.loading.favoriteToggle);
  const [paginator, setPaginator] = useState(null)
  const ankor = useRef(null)
  const executeScroll = () => ankor.current.scrollIntoView(true)
  const doctorID = useSelector(store => store.auth.id);
  const reverseGeocoding = useSelector(state => state.geoloc.reverseGeocoding)
  const structureType = get(structure, 'attributes.type.type', '')
  const authType = useSelector(state => state.auth.type)
  const isDoctor = authType =='Doctor'

  const [isLoading, setIsLoading] = useState(false);

  const onBackIconClick = () => history.goBack();

  const onButtonClick = (id, isUrgent) => (e, proposedDate) => {
    const url = `/panel/concilium/create/${id}${isUrgent ? '?urgent=1' : ''}${proposedDate ? `${isUrgent ? '&' : '?'}proposedDate=${proposedDate}` : ''}`
    e.stopPropagation()

    return history.push(url)
  }

  const refreshTeams = () => getTeamsOfStructure(match.params.id).then( result => setTeams(result.data));

  const refreshStructureMembers = () => {
    setIsLoading(true);
   return getMembersOfStructure(match.params.id, doctorFilter, reverseGeocoding)
        .then(result => {
              setIsMember(result.data.some(el => get(el, 'id', 0) === myProfileId));
              setCardDoctors(result.data);
              setPaginator(result.paginator);
            }
        ).finally(() => setIsLoading(false))
  }

  useEffect(() => {
    getStructure(match.params.id).then( result => setStructure(get(result, 'data', [])))
    refreshStructureMembers()
    refreshTeams()
  }, [favoriteToggle])

  useEffect(() => {
    refreshStructureMembers()
  }, [isMember, doctorFilter.search, doctorFilter.page, reverseGeocoding])

  return (
    <Page
      label={get(structure, 'attributes.name', '-')}
      onBackIconClick={onBackIconClick}
      noHeaderCapitalize
      fullWidth
      style={{ backgroundColor: '#f5f7fb' }}
    >
      <Wrapper>
      <CardWrapper>
        <StructureCard
            id={structure.id}
            name={get(structure, 'attributes.name', '-')}
            members={get(structure, 'attributes.members', 0)}
            type={get(structure, 'attributes.type.type', '-')}
            address={get(structure, 'attributes.address', '-')}
            zipcode={get(structure, 'attributes.zipcode', '-')}
            city={get(structure, 'attributes.city', '-')}
            disableButtons={true}
            isNotClickable={true}
            photo={get(structure, 'attributes.photo', null)}
            isFavorite={get(structure, 'attributes.favorite', false)}
            isFavoriteFetching={favoriteToggle==structure.id}
            toggleFavorite={value => dispatch(toggleFavoriteStructure(structure.id, value))}
            showMessageFavorite={true}
            isStructurePage={true}
            isDoctor={isDoctor}
        />
      </CardWrapper>
        <Title>
          <FormattedMessage id='structures.teams' /> ({teams && teams.length ? teams.length : 0})
        </Title>

        {teams && teams.length ? (
            <div>
              {teams.map(team => (
                  <Item key={team.id} team={team} />
              ))}
            </div>
        ) : (
            <>
            </>
        ) }

      <Title>
        <FormattedMessage id='structures.participants' /> ({get(structure, 'attributes.members', 0)})
      </Title>
      <SearchWrapper>
        <Search
          label='Search'
          onSearch={search => setDoctorFilter({ ...doctorFilter, search })}
          placeholder='structure.member.search'
        />
        {
          (structureType === 'CPTS' || structureType === 'GHT') && (
            <GeoSearch placeholder={f('global.geo.where')} />
          )
        }
      </SearchWrapper>
      <TopAnkor ref={ankor} />
        <DoctorsContainer>
          {isLoading
              ? <CustomLoaderRectangleAndCircle />
              : cardDoctors.map(el => (
              <DoctorCard
                  key={el.id}
                  id={el.id}
                  status_availability={get(el, 'attributes.status_availability', '')}
                  onButtonClick={onButtonClick(el.id, false)}
                  onButtonClickTER={onButtonClick(el.id, true)}
                  disableButtonClick={(el.id == myProfileId)}
                  firstName={get(el, 'attributes.first_name', '')}
                  lastName={get(el, 'attributes.last_name', '')}
                  userStatus={get(el, 'attributes.status', '')}
                  experience={get(el, 'attributes.experience', '')}
                  specialization={get(el, 'attributes.specialization', '')}
                  specialization_type={get(el, 'attributes.specialization_type', '')}
                  photo={get(el, 'attributes.photo', null)}
                  language={get(el, 'attributes.language', '')}
                  location={idx(get(el, 'relationships', null), _ => _.workPlace.data.slice(-1)[0].attributes)}
                  distance={get(el, 'attributes.distance', null)}
                  available={idx(get(el, 'attributes', null), _ => _.calendar_nearest.TC)}
                  isFavorite={get(el, 'attributes.favorite', '')}
                  isFavoriteFetching={favoriteToggle === el.id}
                  toggleFavorite={value => dispatch(toggleFavorite(el.id, value))}
                  disableFavorite={el.id === doctorID}
                  buttonText={'concilium.select'}
                  isDoctor={isDoctor}
                  calendarNearestTE={get(el, 'attributes.calendar_nearest.TE', false)}
                  link={(isDoctor ? '/panel/concilium/specializations/doctor/' : '/panel/doctors/doctor/') + el.id}
                  gender={get(el, 'attributes.gender', null)}
              />
          ))}
        </DoctorsContainer>
      {paginator ?
        <StyledPagination
            onChange={(e, page) => {
              setDoctorFilter({...doctorFilter, page})
              executeScroll()
            }}
            color='primary'
            page={get(paginator, 'currentPage', 1)}
            count={get(paginator, 'totalPage', 0)}
        /> : ''}
      </Wrapper>
    </Page>
  ) 
}

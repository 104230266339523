import React from 'react'
import { DateTimePicker } from '@material-ui/pickers'
import { Input } from '../Input'

export const DateTimeField = ({
  input,
  meta,
  ...rest
}) => (
  <DateTimePicker
    {...input}
    {...rest}
    value={input.value || null}
    format='MMM DD, YYYY, hh:mm A'
    TextFieldComponent={Input}
    errorMessage={(meta.touched && meta.error) || ''}
  />
)

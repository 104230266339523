import { handleActions, createAction } from 'redux-actions'
import { combineReducers } from 'redux'
import axios from 'axios'
import pickBy from 'lodash/pickBy';
import queryString from 'query-string';
import {
  fetchFavoriteTeams
} from "./doctors";

//#region Actions
export const fetchTeamsListRequest = createAction('FETCH_TEAMS_LIST_REQUEST')
export const fetchTeamsListSuccess = createAction('FETCH_TEAMS_LIST_SUCCESS')
export const fetchTeamsListFailure = createAction('FETCH_TEAMS_LIST_FAILURE')

export const fetchPaginatorTeamsListSuccess = createAction('FETCH_PAGINATOR_TEAMS_LIST_SUCCESS')

let ajaxRequest = null;

export const fetchTeams = (filter = {}, abortController = {}) => (dispatch, getStore) => {
  dispatch(fetchTeamsListRequest())

  if(abortController.current){
    abortController.current.abort()
  }
  abortController.current = new AbortController();

  // abort previous request if not finished
  if(ajaxRequest) {
    ajaxRequest.cancel();
  }

  ajaxRequest = axios.CancelToken.source();

  const url = '/api/doctor/team'

  const params =
    {
      ...filter,
      cancelToken: ajaxRequest.token,
    }

  const location = getStore().geoloc.reverseGeocoding;
  if (location) {
    params.latitude = location.latitude;
    params.longitude = location.longitude;
  }

  return axios(url, {
    params,
    signal: abortController.current.signal
  })
    .then((res) => {
      dispatch(fetchTeamsListSuccess(res.data.data))
      dispatch(fetchPaginatorTeamsListSuccess(res.data.paginator))
    })
    .catch(e => {
      if (!abortController.current.signal.aborted) {
        dispatch(fetchTeamsListFailure(e))
      }
    })
}

export const clearTeamsList = createAction('CLEAR_STRUCTURES_LIST')

export const clearTeams = () => (dispatch) => {
  dispatch(clearTeamsList())
}

export const toggleFavoriteTeamRequest = createAction('TOGGLE_FAVORITE_TEAM_REQUEST')
export const toggleFavoriteTeamSuccess = createAction('TOGGLE_FAVORITE_TEAM_SUCCESS')
export const toggleFavoriteTeamFailure = createAction('TOGGLE_FAVORITE_TEAM_FAILURE')

export const toggleFavoriteTeam = (id, value) => (dispatch) => {
  dispatch(toggleFavoriteTeamRequest({ id, value }))
  return axios(`/general/patient/favorite_team/${id}`, { method: value ? 'POST' : 'DELETE' })
    .then(response => {
      dispatch(toggleFavoriteTeamSuccess(response.data))
    })
    .catch((err) => {
      dispatch(toggleFavoriteTeamFailure(err))
    })
}

//#endregion

//#region Reducers
const paginator = handleActions({
  [fetchPaginatorTeamsListSuccess]: (state, action) => action.payload,
  [clearTeamsList]: () => null,
}, [])

const list = handleActions({
  [fetchTeamsListSuccess]: (state, action) => action.payload,
  [toggleFavoriteTeamSuccess]: (state, action) => state.map((item) => {
    if (item.id === action.payload.data.id) {
      return {
        ...item,
        attributes: { ...item.attributes, favorite: action.payload.data.attributes.favorite },
      }
    }
    return item
  }),
  [clearTeamsList]: () => [],
}, [])

export const teams = combineReducers({
  paginator,
  list,
})
//#endregion

import moment from 'moment-timezone'
import { extendMoment } from 'moment-range'
import { random, sample } from 'lodash'
import get from 'lodash/get';
import axios from 'axios';
import {CALENDAR_END_DAY, CALENDAR_START_DAY} from "../constants/calendar";

export const getDiffDays = (calendarType) => {
  let addition = 0

  if (calendarType === 'week') {
    addition = 6
  }

  if (calendarType === 'month') {
    addition = 30
  }

  return addition
}

export const getToDate = (calendarType, fromDate) => {
  const addition = getDiffDays(calendarType)
  return moment(fromDate).add(addition, 'days').format('YYYY-MM-DD')
}

export const getBackDates = (calendarType, fromDate) => {
  const diff = getDiffDays(calendarType)
  const newToDate = moment(fromDate).subtract(1, 'days')
  const newFromDate = moment(newToDate).subtract(diff, 'days')
  return {
    from: newFromDate.format('YYYY-MM-DD'),
    to: newToDate.format('YYYY-MM-DD'),
  }
}

export const getForwardDates = (calendarType, toDate) => {
  const diff = getDiffDays(calendarType)
  const newFromDate = moment(toDate).add(1, 'days')
  const newToDate = moment(newFromDate).add(diff, 'days')
  return {
    from: newFromDate.format('YYYY-MM-DD'),
    to: newToDate.format('YYYY-MM-DD'),
  }
}

export const getNowPeriod = () => {
  let nowHour = moment().hour()
  const nowMinute = moment().minutes()

  let minutes = '00'

  if (nowMinute <= 20) {
    minutes = '40'
    nowHour -= 1
  } else if (nowMinute >= 20 && nowMinute < 40) minutes = '00'
  else if (nowMinute >= 40) minutes = '20'

  return `${nowHour}:${minutes}`
}

export const generateTimePeriods = (manualConsLength = false) => {
  const startTime = CALENDAR_START_DAY
  const endTime = CALENDAR_END_DAY
  const consultationLength = manualConsLength || +window.env.CONSULTATION_LENGTH
  const consultationsPerHour = 60 / consultationLength
  const getMinutes = j => (j === 1 || j > consultationsPerHour ? '00' : (j - 1) * consultationLength)
  const getFromMinutes = j => (j === 1 ? '00' : (j - 1) * consultationLength)
  let periods = []

  let i = startTime
  while (i < endTime) {
    let j = 1

    while (j <= consultationsPerHour) {
      const fromMinutes = getFromMinutes(j)
      const toMinutes = getMinutes(j + 1)

      periods = [...periods, {
        from: `${i}:${fromMinutes}`,
        to: `${toMinutes === '00' ? i + 1 : i}:${toMinutes}`,
      }]

      j += 1
    }

    i += 1
  }

  return periods
}

export const getDaysArray = (fromDate, toDate, length = 7) => {
  const Moment = extendMoment(moment)
  const range = Moment.range(Moment(fromDate), Moment(toDate))
  return Array.from(range.by('days')).slice(0, length)
}

export const getMonthDaysArray = (fromDate, toDate) => {
  const newFromDate = moment(fromDate).startOf('week')
  const newToDate = moment(toDate)
  const length = newToDate.diff(newFromDate, 'days')

  return getDaysArray(newFromDate, newToDate, length + 1)
}

const NAMES = [
  'Hezekiah Clark',
  'Jeremy Cooper',
  'Nickolas Wood',
  'Verney Brooks',
  'Forest Bell',
]

const PAST_STATUSES = [
  'cancelled',
  'completed',
  'refused',
]

const UPCOMING_STATUSES = [
  'created',
  'confirmed',
  'accepted',
]

const TYPES = [
  'urgent',
  'appointment',
]

export const mockConsultation = (
  upcoming = false,
  statusFilter = [...PAST_STATUSES, ...UPCOMING_STATUSES],
  required,
) => {
  const exists = !!random(1) || required

  if (!exists) {
    return undefined
  }

  const status = sample(upcoming ? UPCOMING_STATUSES : PAST_STATUSES)

  const consultation = {
    patient: {
      name: sample(NAMES),
    },
    status,
    reportLink: !upcoming && status !== 'cancelled' ? 'https://app.owndoctor.eu' : undefined,
    type: sample(TYPES),
    rate: random(1, 5),
  }

  if (statusFilter.includes(consultation.status)) {
    return consultation
  }

  return undefined
}

export const getPeriodDate = (day, period, includeAllDay) => {
  const time = moment(period.from, 'HH:mm')
  const date = day.set({ hours: time.get('hours'), minutes: time.get('minutes') })
  if (includeAllDay) {
  }

  return date
}

export const isInFuture = (period, day, includeAllDay) => {
  const date = getPeriodDate(day, period, includeAllDay)
  return date.isAfter()
}

export const getTotalHours = data => Math.ceil(Object.keys(data)
  .reduce((acc, cell) => (data[cell].type === 'Consultation' ? acc + 1 / 3 : acc), 0))

export const getMonthRange = (date) => {
  const Moment = extendMoment(moment)
  const start = Moment(date).startOf('month').startOf('week')
  const end = Moment(date).endOf('month').endOf('week')
  const range = Moment.range(start, end)

  return Array.from(range.by('days'))
}

export const isDayOff = data => Object.keys(data || {}).some(item => data[item].type === 'Holiday')

export const haveUrgent = data => Object.keys(data || {})
  .some(key => data[key].type === 'Consultation' && data[key].data.type === 'urgent')

export const groupCalendarData = (data) => {
  const list = {}

  Object.keys(data).forEach((key) => {
    const cells = Object.keys(data[key]).map(i => data[key][i])
    const grouped = {}

    let lastType = null
    let lastTime = null
    let lastActionAvailableTE = null
    let lastActionAvailableTC = null

    cells.forEach((cell) => {
      const actionAvailableTE = get(cell, 'data.actionAvailable.TE', null);
      const actionAvailableTC = get(cell, 'data.actionAvailable.TC', null);

      if (cell.type === lastType 
          && actionAvailableTE === lastActionAvailableTE
          && actionAvailableTC === lastActionAvailableTC) {
        grouped[lastTime] = [...(grouped[lastTime] || []), cell]
      } else {
        grouped[cell.date_time.split(' ')[1]] = [cell]
        lastType = cell.type
        // eslint-disable-next-line prefer-destructuring
        lastTime = cell.date_time.split(' ')[1]
        lastActionAvailableTE = actionAvailableTE
        lastActionAvailableTC = actionAvailableTC
      }
    })

    list[key] = grouped
  })

  return list
}

export const transformTime = time => (time && time[0] === '0' ? time.substr(1) : time)

import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import { FormattedMessage } from 'react-intl'
import { Paper } from 'common/UiKit/Paper'
import { SecuredBckgImage } from 'common/SecuredImage'
import {Colors, STATUS_COLORS} from '../../../constants/colors'
import {DefaultAvatar} from "../DefaultAvatar";
import {history} from "../../../utils/history";
import {StyledTextButton} from "../UiKit/TextButton";
import {fullName} from "../../../helpers/user";

const PaperStyled = styled(Paper)`
  padding: 10px 14px;
  position: relative;
`

const Wrapper = styled.div`
  display: grid;
  grid-template-rows: 1.5fr 1fr;  
  font-weight: normal;
  font-size: 14px;
  align-content: center;
  & > * {
    min-width: 0;
    min-height: 0;
  }
`

const Div = styled.div`
  display: flex;
  font-weight: normal;
  font-size: 14px;
`

const Info = styled.div`
  margin: 12px 20px 0;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap
`

const NameLinked = styled.h1`
  margin: 0;
  font-size: 18px;
  font-weight: bold;
  color: black;
  overflow: hidden;
  text-overflow: ellipsis;
  &:hover {
    cursor: pointer;
    color: #6633FF;
  }
`

const Name = styled.h1`
  margin: 0;
  font-size: 18px;
  font-weight: bold;
  color: black;
  overflow: hidden;
  text-overflow: ellipsis;
`

const Specialization = styled.p`
  margin: 0;
  opacity: 0.8;
  padding-top: 10px;
  color: ${Colors.blueGrey};
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap
`

const Avatar = styled(SecuredBckgImage)`
  min-width: 70px;
  width: 70px;
  height: 70px;
  margin-bottom: 14px ;
  border-radius: 100%;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  &:after {
    position : relative;
    content: "oo";
    border: 2px solid white;
    background-color: ${({ status }) => (status) ? STATUS_COLORS["available"] : STATUS_COLORS["notavailable"]};
    color: transparent;
    border-radius: 50%;
    top: 50px;
    left:50px;
  }
`

const Default = styled.div`
  min-width: 70px;
  width: 70px;
  height: 70px;
  margin-bottom: 14px ;
  border-radius: 100%;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  &:after {
    position : relative;
    content: "oo";
    border: 2px solid white;
    background-color: ${({ status }) => (status) ? STATUS_COLORS["available"] : STATUS_COLORS["notavailable"]};
    color: transparent;
    border-radius: 50%;
    top: 50px;
    left:50px;
  }
`

const ButtonContainer = styled.div`
    display: flex;
    overflow: hidden;
    align-items: center;
    justify-content: center;
`

export const FavoriteDoctorCard = ({
   id,
   doctorId,
   firstName,
   lastName,
   status_availability,
   specialization,
   specialization_type,
   photo,
   onButtonClickTER,
   isFavorite,
   disableButtonClick,
   link,
   gender,
   isInvited,
}) => {

    return (
        <PaperStyled>
            <Wrapper>
              <Div>
                    {
                            photo ? (
                                    <Avatar
                                        spinner={{width: '120px', height: '120px'}}
                                        src={photo.includes('/file/') ? `http://backend-application.owndoctor.eu${photo}` : photo}
                                        status={status_availability}
                                    />
                              ) :
                                (<Default
                                    spinner={{width: '120px', height: '120px'}}
                                    style={{backgroundImage: `url(${DefaultAvatar(firstName, lastName, specialization_type)})`}}
                                    status={status_availability}
                                />
                                )
                    }

                <Info>
                        {link ? (
                                <NameLinked onClick={()=>history.push(link)}>
                                  {fullName(gender, firstName || 'Name', lastName || 'Surname')}
                                </NameLinked>)
                            : (
                                <Name>
                                  {fullName(gender, firstName || 'Name', lastName || 'Surname')}
                                </Name>)
                        }
                        <Specialization>
                            {specialization || 'Therapist'}
                        </Specialization>
                </Info>
              </Div>
              <ButtonContainer>
                {
                  isInvited ? (
                    <span style={{ color: Colors.dark, fontStyle: 'italic' }}>Invitation envoyée</span>
                  ) : (
                    (status_availability === true && !disableButtonClick) ? (
                      <StyledTextButton
                        onClick={onButtonClickTER}
                        style={{height: '40px'}}
                      >
                        <FormattedMessage id='consultation.ask.teleexpertise'/>
                      </StyledTextButton>
                    ) : (
                      <StyledTextButton
                        style={{height: '40px'}}
                        disabled
                      >
                        <FormattedMessage id='consultation.ask.teleexpertise'/>
                      </StyledTextButton>
                    )
                  )
                }
              </ButtonContainer>
            </Wrapper>
        </PaperStyled>
    )
}

FavoriteDoctorCard.defaultProps = {
    location: {},
    onAvatarClick: () => null,
    photo: '',
    buttonText: null,
    rating: null,
    isInvited: false,
}

FavoriteDoctorCard.propTypes = {
    firstName: PropTypes.string.isRequired,
    lastName: PropTypes.string.isRequired,
    specialization: PropTypes.string.isRequired,
    photo: PropTypes.string,
    price: PropTypes.number,
    location: PropTypes.object,
    onAvatarClick: PropTypes.func,
    isFavorite: PropTypes.bool.isRequired,
    toggleFavorite: PropTypes.func.isRequired,
    isFavoriteFetching: PropTypes.bool.isRequired,
    disableFavorite: PropTypes.bool,
    rating: PropTypes.number,
    buttonText: PropTypes.string,
    isInvited: PropTypes.bool,
}

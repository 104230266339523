import React, {useState} from 'react'
import styled from "styled-components";
import Paper from "@mui/material/Paper";
import {DistanceDisplay} from "../../../../../common/DistanceDisplay";
import idx from "idx";
import {DefaultAvatar} from "../../../../../common/DefaultAvatar";
import {Colors, STATUS_COLORS} from "../../../../../../constants/colors";
import {SecuredBckgImage} from "../../../../../common/SecuredImage";
import {history} from "../../../../../../utils/history";
import {FavoriteButton} from "../../../../../common/DoctorCard/FavoriteButton";
import {useDispatch} from "react-redux";
import {useIntl} from "react-intl";
import {toggleFavorite} from "../../../../../../redux/modules/doctors";

export const DoctorItemComponent = (
  {item},
) => {

  const dispatch = useDispatch();
  const { formatMessage } = useIntl()
  const f = id => formatMessage({ id })

  const distance = idx(item, _ => _.attributes.distance);
  const photo = idx(item, _ => _.attributes.photo);
  const status_availability = idx(item, _ => _.attributes.status_availability);
  const first_name = idx(item, _ => _.attributes.first_name);
  const last_name = idx(item, _ => _.attributes.last_name);
  const specialization_type = idx(item, _ => _.attributes.specialization_type);
  const specialization = idx(item, _ => _.attributes.specialization);
  const city = idx(item, _ => _.attributes.workplace_city);
  const code_postal = idx(item, _ => _.attributes.workplace_code_postal);
  const gender = idx(item, _ => _.attributes?.gender) ? f('profile.genders.' + item.attributes?.gender) : '';
  let favorite = idx(item, _ => _.attributes.favorite);
  const doctorId = idx(item, _ => _.id);

  const [fav, setFav] = useState(favorite);

  const checkFavorite = (e) => {
    dispatch(toggleFavorite(doctorId, !favorite))
      .then(() => {
        setFav(!fav)
      })
    e.stopPropagation();
  }

  return (
    <PaperStyled
      onClick={() => history.push(`/panel/concilium/doctor/${doctorId}`)}
    >
      <Wrapper>
        <AvatarWrapper>
          {
            photo ? (
                <Avatar
                  spinner={{width: '63px', height: '63px'}}
                  src={photo.includes('/file/') ? `http://backend-application.owndoctor.eu${photo}` : photo}
                  status={status_availability}
                />
              ) :
              (<Default
                  spinner={{width: '63px', height: '63px'}}
                  style={{backgroundImage: `url(${DefaultAvatar(first_name, last_name, specialization_type)})`}}
                  status={status_availability}
                />
              )
          }
        </AvatarWrapper>
        <Info>
          <Name>
            {gender}&nbsp;{first_name}&nbsp;{last_name}
          </Name>
          <Specialization>
            {specialization}
          </Specialization>
          <City>
            {city}&nbsp;({code_postal})
          </City>
        </Info>
        <FavoriteWrapper>
          <FavoriteButton checked={fav}
                          onClick={checkFavorite}
                          isFetching={false}
                          message={false}
          />
        </FavoriteWrapper>
        <LanguageWrapper>
          {
            !!distance && ( <DistanceDisplay distance={distance} fontsize="small"/> )
          }
        </LanguageWrapper>
      </Wrapper>
    </PaperStyled>
  )
}

const PaperStyled = styled(Paper)`
  padding: 10px 24px;
  position: relative;
  display: flex;
  height: 70px;
  border: 1px solid  #DCDCDC;
  :hover {
    color: ${Colors.bluePurple};
    background-color: #E7E2F6;
    cursor: pointer;
  }
`

const Wrapper = styled.div`
  display: flex;
  font-weight: normal;
  font-size: 16px;
  align-items: center;
`

const LanguageWrapper = styled.div`
  position: absolute;
  bottom: 20px;
  right: 40px;
  display: flex;
  color: #b7bed2;
`

const FavoriteWrapper = styled.div`
  position: absolute;
  top: 8px;
  right: 8px;
  display: flex;

  color: #b7bed2;
`

const Avatar = styled(SecuredBckgImage)`
  min-width: 63px;
  width: 63px;
  height: 63px;
  border-radius: 100%;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  display: flex;
  align-items: center;
  justify-content: center;
  box-shadow: 0 4px 8px 0 rgba(6, 173, 255, 0.1);
  &:after {
    display: inline-block;
    position : relative;
    content: "oo";
    border: 2px solid white;
    background-color: ${({ status }) => (status) ? STATUS_COLORS["available"] : STATUS_COLORS["notavailable"]};
    color: transparent;
    border-radius: 50%;
    min-width: 15px;
    min-height: 15px;
    top: 20px;
    left: 20px;
  }
`

const Default = styled.div`
  min-width: 63px;
  width: 63px;
  height: 63px;
  border-radius: 100%;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  box-shadow: 0 4px 8px 0 rgba(6, 173, 255, 0.1);
  &:after {
    display: inline-block;
    position : relative;
    content: "oo";
    border: 2px solid white;
    background-color: ${({ status }) => (status) ? STATUS_COLORS["available"] : STATUS_COLORS["notavailable"]};
    color: transparent;
    border-radius: 50%;
    min-width: 15px;
    min-height: 15px;
    top: 40px;
    left: 40px;
  }
`

const AvatarWrapper = styled.div`
  & > p {
    margin: 16px 0 0;
    color: ${Colors.clearBlue};
    font-size: 24px;
    font-weight: bold;
    text-align: center;
  }
`

const Info = styled.div`
  flex-grow: 1;
  margin: 0 32px 0;
  max-width: 260px;
`

const Name = styled.div`
  font-weight: bold;
  word-break: break-word;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
`

const Specialization = styled.div`
  margin: 4px 0 0 0;
  font-size: 14px;
  color: ${Colors.dark70};
  font-weight: 400;
  word-break: break-word;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
`

const City = styled.div`
  margin: 4px 0 0 0;
  color: ${Colors.blueGrey};
  word-break: break-word;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
`
import React, {useState} from 'react'
import PropTypes from 'prop-types';
import styled from "styled-components";
import {Colors} from "../../../constants/colors";

export const TabsDefinition = ({ tabs, panels }) => {
  if (tabs.length <= 0 || panels.length <= 0 || tabs.length !== panels.length) {
    throw new Error("Wrong number of tabs and panels");
  } // else

  const [tabTitles, setTabTitles] = useState(tabs.map(title => ({
    title: title,
    selected: false,
  })))
  const [selectedPanel, setSelectedPanel] = useState(null)
  const onChangeTab = (index) => {
    const newTabs = [...tabTitles]
    newTabs.forEach(t => t.selected = false)
    if (index !== null) {
      newTabs[index].selected = true
    }
    setTabTitles(newTabs)
    setSelectedPanel(index)
  }

  return (
    <>
      <Tabs>
        {
          tabTitles.map((tab, key) => (
            <a
              key={key}
              href='#'
              className={tab.selected ? 'tab-selected' : ''}
              onClick={(e) => {
                e.preventDefault()
                onChangeTab(key)
              }}
            >
              {tab.title}
            </a>
          ))
        }
      </Tabs>
      {
        selectedPanel !== null && (
          <Definition>
            <div>{panels[selectedPanel]}</div>
            <a href='#' onClick={(e) => {
              e.preventDefault()
              onChangeTab(null)
            }}>
              Réduire
            </a>
          </Definition>
        )
      }
    </>
  )
}

TabsDefinition.propTypes = {
  tabs: PropTypes.array.isRequired,
  panels: PropTypes.array.isRequired,
}

// --------------------------------- Styles CSS -------------------------------------

const Tabs = styled.div`
  margin-top: 10px;

  a, a:visited {
    color: ${Colors.dark80};
    text-decoration: underline;
    display: inline-block;
    padding: 3px;
  }

  a:hover {
    text-decoration: none;
  }

  a.tab-selected, a.tab-selected:hover {
    text-decoration: none;
    cursor: default;
  }
`

const Definition = styled.div`
  background-color: ${Colors.purpleGrey};
  border-left: 2px solid ${Colors.bluePurple};
  padding: 5px;
  
  a {
    margin-top: 10px;
  }
`
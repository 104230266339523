import React from 'react'
import PropTypes from 'prop-types'
import Dropzone from 'react-dropzone'
import styled from 'styled-components'
import Add from '@mui/icons-material/Add'
import { Colors } from 'constants/colors'
import { FormattedMessage } from 'react-intl'

const Wrapper = styled.div`
  max-width: 100%;
  box-sizing: border-box;
  height: 100%;
  cursor: pointer;
  text-align: center;
  border: 2px solid ${Colors.clearBlue};
  border-radius: 4px;
  margin: 0 16px 16px;
`

const AddField = styled.div`
  height: 130px;
  font-size: 14px;
  font-weight: 500;
  color: ${Colors.clearBlue};
  text-transform: uppercase;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  svg {
    font-size: 31px;
  }
`

const HelperText = styled.p`
  margin: 0;
  padding: 0 10px;
  font-size: 12px;
  line-height: 1.5;
  font-weight: normal;
  color: ${Colors.blueGrey};
`

export const Upload = ({ onDrop }) => (
  <Dropzone onDrop={onDrop} accept='.jpg, .jpeg, .gif, .png, .heif, .heic, .doc, .docx, .xls, .xlsx, .csv, .pdf, .odt, .ods, .odp, .tex, .ppt, .pptx, .zip, .rar, .tar, .gz, .tar.gz, .7z, .txt, .md, .mov, .mpg, .mpeg, .mkv, .mp4'>
    {
      ({ getRootProps, getInputProps }) => (
        <Wrapper
          {...getRootProps()}
        >
          <AddField>
            <Add />
            <br />
            <HelperText>
              <FormattedMessage id='signature.select' />
            </HelperText>
          </AddField>
          <input {...getInputProps()} />
        </Wrapper>
      )
    }
  </Dropzone>
)

Upload.propTypes = {
  onDrop: PropTypes.func.isRequired,
}

import axios from 'axios';
import { connectedRouterRedirect } from 'redux-auth-wrapper/history4/redirect'
import locationHelperBuilder from 'redux-auth-wrapper/history4/locationHelper'

const locationHelper = locationHelperBuilder({})

export const doctorIsRecieved = connectedRouterRedirect({
  redirectPath: (state, ownProps) => locationHelper.getRedirectQueryParam(ownProps) || '/panel/doctors/main',
  authenticatedSelector: state => !!state.doctors.doctor.id,
  wrapperDisplayName: 'DoctorIsRecieved',
  allowRedirectBack: false,
})

export const sortSpecializations = (items = []) => {
  return [...items]
}

export const getTutorialList = async () => {
  const result = await axios.get('/api/public/admin/attachment/list');
  return result.data.data;
}

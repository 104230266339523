import React, {useState, useEffect, useRef} from 'react'
import styled from 'styled-components'
import {Page} from "../../../common/Page";
import {FormattedMessage, FormattedHTMLMessage, useIntl} from "react-intl";
import {Colors} from "../../../../constants/colors";
import SearchIcon from "@mui/icons-material/Search";
import {Item} from "./item";
import {ChatLayout} from "../../ChatLayout";
import {Chat as WebChat} from "../../Chat";
import {HeaderLeft} from "./headerLeft";
import {DocumentsTab} from "../../Chat/DocumentsTab";
import {useDispatch, useSelector} from "react-redux";
import DescriptionIcon from "@mui/icons-material/Description";
import MessageIcon from "@mui/icons-material/Message";
import MembersIcon from '@mui/icons-material/Groups';
import {fetchDiscussions, fetchDiscussion, hasUnreadMessage} from "../../../../redux/modules/discussions";
import {MembersTab} from "../../Chat/MembersTab";
import {fetchChatMembers} from "../../../../redux/modules/chatmembers";
import {fetchRecentContacts} from "../../../../redux/modules/recentContact";
import {DropdownMenu} from "../../../common/UiKit/DropdownMenu";
import {NewDiscussion} from "./New";
import {NewGroupDialog} from "./NewGroupDialog";
import {useHistory} from "react-router-dom";
import idx from "idx";
import {socket} from "../../../../services/socket";
import {DISCUSSION_TYPE__INDIVIDUAL} from "../../../../constants/discussion";
import {TextButton} from "../../../common/UiKit/TextButton";
import AddIcon from "@mui/icons-material/Add";
import emptyChatSvg from "../../../../assets/empty_chat_bubble.svg"
import emptySideChatSvg from "../../../../assets/side_empty_view.svg"


const PageContent = styled.div`
  display: flex;
`

const Wrapper = styled.div`
  width: 25%;
  min-width: 230px;
  height: calc(100vh - 56px);
  display: flex;
  background-color: white;
  border-right: 0.8px solid ${Colors.borderGrey};
  flex-direction: column;
`

const SearchBar = styled.div`
  display: flex;
  align-items: center;
  height: fit-content;
  width: 100%;
  justify-content: space-evenly;
  margin: 0;
`

const Input = styled.input`
  border: 1px solid ${Colors.textMuted};
  border-radius: 2.5px;
  background-color: white;
  outline: none;
  height: 20px;
  padding: 13px 0 13px 45px;
  box-sizing: border-box;
  width: 100%;
  font-size: 16px;
  font-weight: 500;
  color: ${Colors.dark};
  
  ::placeholder {
    color: ${Colors.blueGrey};
    font-size: 18px;
    font-weight: 500;
  }
`

const Search = styled.div`
  width: 90%;
  position: relative;
  & > svg {
    position: absolute;
    left: 10px;
    top: 2px;
    fill: ${Colors.bluePurple};
  }
`

const DiscussionsWrapper = styled.div`
  display: flex;
  overflow: auto;
  flex-direction: column;
  margin-top: 20px;
`

const ChatWrapper = styled.div`
  height: 100%;
`

const ChatLayoutWrapper = styled.div`
  display: block;
  width: 75%;
  min-width: 320px;
  max-height: calc(100vh - 56px);
`

const NewDiscussionButton = styled(TextButton)`
  box-shadow: none;
  align-self: center;
  padding-top: 4px;
  padding-bottom: 4px;
  margin: 16px 0;
  display: flex;
  align-items: center;
  height: fit-content;
  width: 90%;
  text-transform : none;
  font-weight: 500;
  font-size: 14px;
  && {
    background-color: ${Colors.bluePurple};
    border: 1px solid white;
    color: white;
  }
  &:hover{
    background-color: rgb(71, 35, 178);
    border: 1px solid white;
    color: white;
  }
`

const EmptyChatZone = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100%;
  & > img {
    margin-bottom: 30px;
  }
  
  & > div {
    width: 50%;
    text-align: center;
    & > span {
      font-weight: bold;
    }
  }
`

const EmptySideChatZone = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100%;

  & > img {
    margin: 30px 0;
  }
  
  & > div {
    margin: 10px 0;
    width: 90%;
    text-align: center;
    & > span {
      font-weight: bold;
    }
  }
`

const DiscussionsComponent = ({ match }) => {
  const history = useHistory()
  const dispatch = useDispatch()
  const { formatMessage } = useIntl()
  const f = id => formatMessage({ id })
  const discussions = useSelector(state => state.discussions.list.data)
  const discussionsIndex = useSelector(state => state.discussions.list.index)
  const discussionId = match?.params?.id
  const discussionSelected = useSelector(state => state.discussions.discussion)
  const discussionSelectedType = idx(discussionSelected, _ => _.attributes.type)
  const [search, setSearch] = useState('')
  const [modifyGroupName, setModifyGroupName] = useState(false)
  const [newGroup, setNewGroup] = useState(false);
  const [anchorEl, setAnchorEl] = useState(null)
  const resetRef = useRef();

  useEffect(() => {
    if (match.params?.id !== undefined && Number.isInteger(parseInt(match.params.id))) {
      dispatch(fetchDiscussion(match.params.id))
    } else {
      dispatch(fetchDiscussion(null))
    }
  }, [discussionId, dispatch])

  useEffect(() => {
    if (discussionSelected) {
      resetRef.current?.reset()
      getChatMembers(discussionSelected.id)
    }
  }, [discussionSelected])

  useEffect(() => {
    if (discussionId && discussionsIndex[discussionId] === undefined && discussionId !== 'new') {
      history.push('/panel/discussions')
    }
  }, [discussionsIndex, discussionId])

  const currentPath = window.location.pathname;
  const newDiscussion = currentPath.includes('discussions/new');

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const getDiscussions = (value) => {
    setSearch(value)
    dispatch(fetchDiscussions({include: 'chat,team', search: value}))
  }

  const updateDiscussion = (chatId) => {
    if (discussions && chatId) {
      const updatedDiscussion = discussions.find((discussion) => discussion.attributes.chatId === chatId);
      updatedDiscussion ? dispatch(fetchDiscussion(updatedDiscussion.id, true)) : getDiscussions();
    }
  }

  const getChatMembers = (value) => {
    dispatch(fetchChatMembers({discussionId: value}))
  }

  const fetchRecentContactsList = () => {
    dispatch(fetchRecentContacts({limit: 3, all: 1}));
  }

  const chatContent = newDiscussion ? (
    <NewDiscussion />
  ) : (
    <ChatWrapper>
      <WebChat isDiscussion/>
    </ChatWrapper>
  )

  const discussionTab = {
    tabName: 'Discussion',
    tabIcon: <MessageIcon />,
    tabContent: chatContent,
  }

  const documentsTab = {
    tabName: 'Documents',
    tabIcon: <DescriptionIcon />,
    tabContent: <DocumentsTab isDiscussion/>
  }

  const discussionTabs = [
    discussionTab,
    {
      tabName: 'Membres',
      tabIcon: <MembersIcon/>,
      tabContent: (
        <MembersTab/>
      )
    },
    documentsTab,
  ]

  const discussionIndivTabs = [
    discussionTab,
    documentsTab,
  ]

  const discussionNewTabs = [discussionTab]

  useEffect(() => {
    if (idx(socket.messageReceived, _=>_.attributes.chat_relate_type) === 'discussion') {
      // the socket action messageReceived and messageReaded don't have the same parameter structure, to correctly get the
      // chat_id we have to adapt to both structure
      if (
        socket.messageReceived.action === 'MessageReceived'
        && idx(socket.messageReceived, _ => _.attributes.chat_id)
      ) {
        const result = discussions.find(el => el.attributes.chatId === socket.messageReceived.attributes.chat_id)

        // If we don't find the discussion which received a new message it probably means that the discussions list is not up to date
        if (result) {
          updateDiscussion(socket.messageReceived.attributes.chat_id)
        }
      } else if (
        socket.messageReceived.action === 'MessageReaded'
        && idx(socket.messageReceived, _ => _.attributes[0].attributes.chat_id)
      ) {
        updateDiscussion(socket.messageReceived.attributes[0].attributes.chat_id)
      }
    }
    fetchRecentContactsList();
    getDiscussions();
  }, [socket.messageReceived])

  // Loading discussions list when loading component
  useEffect(() => {
    getDiscussions()
    dispatch(hasUnreadMessage())
  }, [match.path]);

  return (
    <Page
      label={f('roso.discussions')}
      noHeaderCapitalize
      fullWidth
      style={{ backgroundColor: '#f5f7fb' }}
      noWrapperPadding
    >
      <PageContent>
        <Wrapper>
          <NewDiscussionButton onClick={handleClick}>
            <AddIcon style={{marginRight: '8px'}}/>
            <FormattedMessage id='disc.create.1v1'/>
          </NewDiscussionButton>
          <SearchBar>
            <Search>
              <SearchIcon />
              <Input
                id='global-search'
                value={search}
                onFocus={e => e.target?.select()}
                onChange={e => getDiscussions(e.target.value)}
                placeholder={formatMessage({ id: 'global.search' })}
              />
            </Search>
            <DropdownMenu
              anchorEl={anchorEl}
              handleClose={handleClose}
              setNewGroup={setNewGroup}
              style={{zIndex: 10, position: "absolute"}}
              />
          </SearchBar>
          <NewGroupDialog open={newGroup} setOpen={setNewGroup} />
          <DiscussionsWrapper>
            { discussions &&
              discussions.map(discussion =>
                <Item
                  key={discussion.id}
                  discussion={discussion}
                  onClick={() => {
                    history.push(`/panel/discussions/${discussion.id}`)
                  }}
                  idSelected={discussionSelected?.id}
                /> )
            }
            {
              discussions.length === 0 && (
                    <EmptySideChatZone>
                      <img src={`${emptySideChatSvg}`} alt="empty_side_chat_svg"/>
                      <FormattedMessage id={"disc.empty.chat.side1"} />
                      <FormattedHTMLMessage tagName={"div"} id={"disc.empty.chat.side2"} />
                    </EmptySideChatZone>
                )
            }
          </DiscussionsWrapper>
        </Wrapper>

        <ChatLayoutWrapper>
          {discussionSelected && (
            <ChatLayout
              tabs={discussionSelectedType !== DISCUSSION_TYPE__INDIVIDUAL ? discussionTabs : discussionIndivTabs}
              footer={null}
              headerLeft={HeaderLeft(discussionSelected, modifyGroupName, setModifyGroupName)}
              headerRight={null}
              resetTabs={resetRef}
            />
          )}

          {(!discussionSelected && !newDiscussion) && (
              <EmptyChatZone>
                <img src={`${emptyChatSvg}`} alt="empty_chat_svg"/>
                <FormattedHTMLMessage tagName={"div"} id={'disc.empty.chat'}/>
              </EmptyChatZone>
            )}

          {(!discussionSelected && newDiscussion) && (
              <ChatLayout
                  tabs={discussionNewTabs}
                  footer={null}
                  headerLeft={HeaderLeft(discussionSelected, modifyGroupName, setModifyGroupName)}
                  headerRight={null}
                  resetTabs={resetRef}
              />
          )}

        </ChatLayoutWrapper>
      </PageContent>
    </Page>
  )
}

export const Discussions = React.memo(DiscussionsComponent)

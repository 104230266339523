import {Dialog} from "../../../common/UiKit/Dialog";
import {FormattedMessage} from "react-intl";
import {Colors} from "../../../../constants/colors";
import {Form} from "react-final-form";
import Grid from "@mui/material/Grid";
import FormLabel from "@mui/material/FormLabel";
import PropTypes from "prop-types";
import React from "react";
import {useSelector} from "react-redux";
import LoadingButton from "../../../common/UiKit/LoadingButton";
import {MuiTextField} from "../../../common/UiKit/MuiTextField";

export const InviteProfessionalDialog = ({
  open,
  onClose,
  onSendInvite,
  firstName,
  lastName,
  initialMessage
}) => {
  const inviteSending = useSelector(state => state.loading.proInviteSending)
  const inviteError = useSelector(state => state.proInvite.error)

  const onValidate = (values) => {
    let errors = {
      emailOrPhone: undefined,
      message: undefined,
    }
    if (!values.emailOrPhone) {
      errors.emailOrPhone = 'Un email ou un numéro doit être renseigné'
    } else if (
      !/^[\w.+-]+@[\w.-]+\.\w+$/.test(values.emailOrPhone)
      && !/^((\+\d{9,12})|(0\d{9}))$/.test(values.emailOrPhone)
    ) {
      errors.emailOrPhone = 'Email ou numéro invalide'
    }
    if (!values.message) {
      errors.message = 'Un message d\'invitation doit être renseigné'
    }
    return errors
  }

  return (
    <Dialog
      open={open}
      onClose={onClose}
      titleAlignment='center'
      title={
        <>
          <FormattedMessage id='invitation.invite' />&nbsp;
          <span style={{ color: Colors.bluePurple }}>{firstName} {lastName}</span>&nbsp;
          <FormattedMessage id='invitation.dialog.title' />
        </>
      }
    >
      <Form
        onSubmit={onSendInvite}
        validate={onValidate}
        initialValues={{
          emailOrPhone: '',
          message: initialMessage,
        }}
        render={({ handleSubmit, errors }) => (
          <form onSubmit={handleSubmit}>
            <Grid container spacing={1}>
              <Grid item xs={12}>
                <FormLabel htmlFor='emailOrPhone'><FormattedMessage id='invitation.label.emailOrPhone' /></FormLabel>
              </Grid>
              <Grid item xs={12}>
                <MuiTextField
                  fullWidth={true}
                  name='emailOrPhone'
                  disabled={inviteSending}
                  placeholder='Email ou Téléphone'
                  error={errors.emailOrPhone}
                />
              </Grid>
              <Grid item xs={12}>
                <FormLabel htmlFor='message'><FormattedMessage id='invitation.label.message' /></FormLabel>
              </Grid>
              <Grid item xs={12}>
                <MuiTextField
                  fullWidth={true}
                  name='message'
                  disabled={inviteSending}
                  placeholder={'Message d\'invitation'}
                  error={errors.message}
                  multiline={true}
                  minRows={10}
                />
              </Grid>
            </Grid>
            <div style={{ marginTop: '20px', textAlign: 'center', }}>
              {
                !!inviteError && (
                  <div style={{ color: Colors.red, marginBottom: '10px' }}>
                    {inviteError}
                  </div>
                )
              }
              <LoadingButton type='submit' loading={inviteSending}>
                <FormattedMessage id='invitation.button.invite' />
              </LoadingButton>
            </div>
          </form>
        )}
      >
      </Form>
    </Dialog>
  )
}

InviteProfessionalDialog.propTypes = {
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  onSendInvite: PropTypes.func.isRequired,
  firstName: PropTypes.string.isRequired,
  lastName: PropTypes.string.isRequired,
  initialMessage: PropTypes.string.isRequired,
}

InviteProfessionalDialog.defaults = {
  initialMessage: ''
}

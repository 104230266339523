import React from 'react';
import styled from 'styled-components';
import { Paper } from 'common/UiKit/Paper'
import { FormattedMessage } from 'react-intl'

const StyledGoalsTableContainer = styled(Paper)`
    margin-top: 20px;   
    padding: 0;
`

const TableHeading = styled.div`
    display: flex;
    font-size: 12px;
    font-weight: 500;
    line-height: 42px;
    color: #333c40;
    padding-left: 21px;
    padding-right: 21px;
`

const ListItem = styled.div`
    display: flex;
    border-top: 2px solid rgba(228,231,235,0.5);
    line-height: 38px;
    padding-left: 19px;
    padding-right: 19px;
}
`

const ListItemTitle = styled.div`
    flex: 1;
    font-size: 12px;
    color: #333c40;
`

export const GoalsManagementTable = ({ 
  ripsCount = 0,
  ripsSpecializationsCount = 0,
  ripsRosoCount = 0,
  averageScheduledWeek = 0,
  averageTelexpertiseWeek = 0,
  countOfLastMonthChat = 0,
}) => (
  <StyledGoalsTableContainer>
    <TableHeading>
      <div style={{ flex: '1' }}>
        Title
      </div>
      <div>
        Count
      </div>
    </TableHeading>
    <div>
      <ListItem>
        <ListItemTitle>
          <FormattedMessage id='comunity.statistic.ripscount' />
        </ListItemTitle>
        <div>
          {ripsCount || 0}
        </div>
      </ListItem>
      <ListItem>
        <ListItemTitle>
          <FormattedMessage id='comunity.statistic.ripsspecializationscount' />
        </ListItemTitle>
        <div>
          {ripsSpecializationsCount || 0}
        </div>
      </ListItem>
      <ListItem>
        <ListItemTitle>
          <FormattedMessage id='comunity.statistic.ripsrosocount' />
        </ListItemTitle>
        <div>
          {ripsRosoCount || 0}
        </div>
      </ListItem>
      <ListItem>
        <ListItemTitle>
          <FormattedMessage id='comunity.statistic.countoflastmonthchat' />
        </ListItemTitle>
        <div>
          {countOfLastMonthChat || 0}
        </div>
      </ListItem>
      <ListItem>
        <ListItemTitle>
          <FormattedMessage id='comunity.statistic.averagetelexpertiseweek' />
        </ListItemTitle>
        <div>
          {Math.ceil(averageTelexpertiseWeek || 0)}
        </div>
      </ListItem>
      <ListItem>
        <ListItemTitle>
          <FormattedMessage id='comunity.statistic.averagescheduledweek' />
        </ListItemTitle>
        <div>
          {Math.ceil(averageScheduledWeek || 0)}
        </div>
      </ListItem>
    </div>
  </StyledGoalsTableContainer>
)

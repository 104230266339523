import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import Paper from '@mui/material/Paper'
import { FormattedHTMLMessage, FormattedMessage } from 'react-intl'
import { Colors } from 'constants/colors'
import { isMacOS } from '../../../helpers/useragent'
import { POLICY_HEADERS } from '../../../constants/termsAndPolicy'

const PaperStyled = styled(Paper)`
  ${isMacOS() ? '' : 'padding: 3px 3px 3px 0;'}

  && {
    box-shadow: 0 4px 8px 0 ${Colors.azure};
  }
`
const Wrapper = styled.div`
  height: 588px;
  padding: 29px 24px;
  font-family: Roboto, sans-serif;
  overflow: auto;
  box-sizing: border-box;
`

const Heading = styled.h1`
  margin: 0;
  font-size: 24px;
  color: ${Colors.dark80};
`

const Text = styled.p`
  margin: 32px -7px 0 0;
  font-size: 16px;
  line-height: 1.63;
  color: ${Colors.dark60};
  white-space: pre-line;
  
  p {
    margin: 0;
  }
  
  h1, strong {
    color: rgb(75,172,198);
    margin-bottom: 16px;
  }
  
  strong {
    display: inline-block;
  }
  
  ul {
    margin: 0;
  }
`

export const TermsAndPolicy = ({ policy }) => (
  <PaperStyled>
    <Wrapper>
      <Heading>
        <FormattedMessage id={POLICY_HEADERS[policy] || 'auth.policy'} />
      </Heading>
      <Text>
        <a href="https://conexsante.com/cgu/" target="_blank" rel="noopener noreferrer">
          <FormattedHTMLMessage id={policy} />
        </a>
      </Text>
    </Wrapper>
  </PaperStyled>
)

TermsAndPolicy.propTypes = {
  policy: PropTypes.string.isRequired,
}

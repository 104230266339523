import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'

const Wrapper = styled.div`
  height: 100%;
  width: 100%;
  display: flex;
`

const Content = styled.div`
  height: 100%;
  flex-grow: 1;
`

const DrawerContent = styled.div`
  height: 100%;
  width: ${({ open, drawerWidth }) => (open ? `${drawerWidth}px` : '0px')};
  min-width: ${({ open, drawerWidth }) => (open ? `${drawerWidth}px` : '0px')};
  transition: all 0.5s;
  overflow: hidden;

  & > * {
    width: ${({ drawerWidth }) => `${drawerWidth}px`};
    box-sizing: border-box;
  }
`

export const Drawer = ({
  children,
  DrawerComponent,
  open,
  drawerWidth,
}) => (
  <Wrapper>
    <Content open={open}>{children}</Content>
    <DrawerContent open={open} drawerWidth={drawerWidth}>
      <DrawerComponent />
    </DrawerContent>
  </Wrapper>
)

Drawer.defaultProps = {
  open: false,
  drawerWidth: 360,
}

Drawer.propTypes = {
  open: PropTypes.bool,
  drawerWidth: PropTypes.number,
}

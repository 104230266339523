import { mapProps } from 'recompose'
import PropTypes from 'prop-types'
import moment from 'moment-timezone'
import { PropertiesList } from '../PropertiesList'

const enhance = mapProps(props => ({
  list: [
    { label: 'hr.height', value: props.height || 'hr.unknown' },
    { label: 'hr.weight', value: props.weight || 'hr.unknown' },
    { label: 'hr.hospitalized', value: props.lastHospitalized ? moment(props.lastHospitalized).format('MMMM DD, YYYY') : 'hr.unknown' },
  ],
}))

export const HealthDescription = enhance(PropertiesList)

HealthDescription.propTypes = {
  height: PropTypes.number,
  weight: PropTypes.number,
  lastHospitalized: PropTypes.string,
}

import React, {useEffect, useRef, useState} from 'react';
import CircularProgress from '@mui/material/CircularProgress';
import axios from 'axios';
import {Document} from 'react-pdf'
import {FormattedMessage} from "react-intl";
import {TextButton} from '../UiKit/TextButton';
import {useParams} from "react-router-dom";
import styled from "styled-components";
import {Skeleton} from "@mui/material";

export const SecuredRawImage = (src, cb = false, headingBase64 = '', thumbnail = false) => {
  const replacedImg = src || '/static/media/default-avatar.f79109f6.svg';
  if (replacedImg && replacedImg.includes && replacedImg.includes('/static/media/')) {
    return cb ? cb(replacedImg) : replacedImg;
  }
  const token = localStorage.getItem('authToken');
  const fd = new FormData();
  try {
    fd.append('token', token);
    return axios({
      url: `${replacedImg}?thumbnail=${thumbnail}`,
      method: 'GET',
      data: fd,
      headers: { 'Content-Type': 'multipart/form-data' },
      responseType: 'arraybuffer',
    })
      .then((result) => {
        if (result && result.data) {
          const based64 = Buffer.from(result.data).toString('base64');
          if (cb) {
            cb(`${headingBase64}${based64}`);
          }
          return `${headingBase64}${based64}`;
        }  
        return '/static/media/default-avatar.f79109f6.svg';
      })
      .catch(() => (cb ? cb('/static/media/default-avatar.f79109f6.svg') : '/static/media/default-avatar.f79109f6.svg'));
  } catch (e) {
    console.warn(e);
  }
  return false;
}

export const openInNewTab = (e, based64, type = 'image/png', name) => {
  e.preventDefault();
  fetch(based64)
    .then(res => res.blob())
    .then((blob) => {
      const link = document.createElement('a');
      const binaryData = [];
      binaryData.push(blob);
      const url = window.URL.createObjectURL(new Blob(binaryData, { type }));
      link.target = '_blank';
      if (name) {
        link.download = name;
      }
      link.href = url;
      link.click();
    })
}

const SecuredBackgImageContent = styled.div``
export const SecuredBckgImage = ({ src, spinner = {}, small = false, size =false, ...rest }) => {
  const [securedSrc, setSecuredSrc] = useState('');
  useEffect(() => {
    if (src && src.includes && src.includes('/static/media/')) {
      setSecuredSrc(src);
    } else {
      SecuredRawImage(src, setSecuredSrc, 'data:img/*;base64,');
    }
    return () => setSecuredSrc('');
  }, [src])

  if (!securedSrc) {
    return (
      <Skeleton variant="circular" width={small ? 35 : size ? size : 60} height={small ? 35 : size ? size : 60} />
    )
  }

  return (
    <SecuredBackgImageContent {...rest} style={{ backgroundImage: `url(${securedSrc})` }} />
  )
}

export const SecuredImage = ({ src, thumbnail = false, ...rest }) => {
  const [securedSrc, setSecuredSrc] = useState('');

  useEffect(() => {
    if (src && src.includes && src.includes('/static/media/')) {
      setSecuredSrc(src);
    } else {
      SecuredRawImage(src, setSecuredSrc, 'data:img/*;base64,', thumbnail);
    }
    return () => setSecuredSrc('');
  }, [src])

  if (!securedSrc) {
    return (
      <CircularProgress />
    )
  }

  return (
    <OpenSecuredInNewTab target='_blank' method='get' action={src}>
      <img alt='securedImage' src={securedSrc} {...rest} />
    </OpenSecuredInNewTab>
  )
}

export const SecuredPdf = ({ src, ...rest }) => {
  const [securedSrc, setSecuredSrc] = useState('');

  useEffect(() => {
    SecuredRawImage(src, setSecuredSrc, 'data:application/pdf;base64,');
    return () => setSecuredSrc('');
  }, [src])

  if (securedSrc) {
    return (
      <CircularProgress />
    )
  }

  return (
    <iframe alt='securedImage' title={Math.random()} style={{ width: '100%', height: '100%' }} id='iframepdf' {...rest} src={securedSrc} />
  )
}

export const SecuredPdfPreview = ({ src, ...rest }) => {
  const [securedSrc, setSecuredSrc] = useState('');

  useEffect(() => {
    SecuredRawImage(src, setSecuredSrc, 'data:application/pdf;base64,');
    return () => setSecuredSrc('');
  }, [src])

  if (!securedSrc) {
    return (
      <CircularProgress />
    )
  }

  return (
    <Document {...rest} file={securedSrc} />
  )
}

export const OpenSecuredInNewTab = ({ action, children, method='post' }) => {
  const refForm = useRef();
  const token = localStorage.getItem('authToken');
  return (
    <form target='_blank' action={action} ref={refForm} method={method}>
      <input type='hidden' name='token' value={token} />
      <input type='hidden' name='download' value={0} />
      <div onClick={() => refForm && refForm.current && refForm.current.submit && refForm.current.submit()}>{children}</div>
    </form>
  )
}

export const PopUpWeeklyActivitiesReport = ({ action }) => {
  const refForm = useRef();
  const token = localStorage.getItem('authToken');
  let { date } = useParams();
  const tableauDate = date.split('-');
  return (
    <Wrapper>
      <Panel>
        <Text > {'Accédez à votre relevé d’activités du ' + tableauDate[2] + '/' + tableauDate[1] + '/' + tableauDate[0]} </Text>
      <OpenSecuredInNewTab target='_blank' action={action+'/'+date} ref={refForm} method='post'>
        <TextButton
          style={{textTransform: 'none'}}
          variant='extended'
          color='primary'
        >
          <FormattedMessage  id='consultations.weekly.report.download' />
        </TextButton>
      </OpenSecuredInNewTab>
      </Panel>
    </Wrapper>
  )
}

export const SecuredAttachmentInNewTab = ({ src, children }) => {
  const type = src.replace(/^.*\.(.*)$/, '$1');
  const isPdf = type === 'pdf';
  const [securedSrc, setSecuredSrc] = useState('');

  useEffect(() => {
    SecuredRawImage(src, setSecuredSrc, `data:application/${type};base64,`);
    return () => setSecuredSrc('');
  }, [src])

  return (
    <div onClick={e => openInNewTab(e, securedSrc, isPdf ? `application/${type}` : 'octet/stream', isPdf ? false : `attachment.${type}`)}>
      {children}
    </div>
  )
}

const Wrapper = styled.div`
  width: 80vw;
  height : 95vh;
  display: flex;
  align-items: center;
  justify-content: center;
`



const Panel = styled.div`
  width: 600px ;
  height : 400px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  border: 1px solid #6633FF;
  border-radius: 15px;
  & > * {
    font-style: Roboto;
    font-size: 30px;
    padding: 80px 0 80px 0
  }
`

const Text = styled.div`
  font-style: Roboto;
  font-color: #6633FF;
  font-size: 21px;
  padding-bottom: 80px;
`
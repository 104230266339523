import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import Fab from '@mui/material/Fab'
import CircularProgress from '@mui/material/CircularProgress'
import { Colors, BUTTON_COLORS } from "../../../../constants/colors";

const ProgressStyled = styled(CircularProgress)`
  && {
    color: white;
  }
`

const FabStyled = styled(Fab)`
  &&{
    border-radius: 5px;
    box-shadow: inset 0 0 10px rgba(0,0,0,0.08);
  }
  &:active {
   -webkit-box-shadow: inset 0 0 10px rgba(0,0,0,0.7) !important;
   -moz-box-shadow:    inset 0 0 10px rgba(0,0,0,0.7) !important;
   box-shadow:         inset 0 0 10px rgba(0,0,0,0.7) !important;
  }
  &:hover {
   -webkit-box-shadow: inset 0 0 0 rgba(255,255,255,0) !important;
   -moz-box-shadow:    inset 0 0 0 rgba(255,255,255,0) !important;
   box-shadow:         inset 0 0 0 rgba(255,255,255,0) !important;
  }
`

export const TextButton = ({
  children,
  isFetching,
  disabled,
  ...rest
}) => {
  const otherArgs = {...rest}
  delete otherArgs.white
  delete otherArgs.VertPrintemps
  delete otherArgs.isButtonMenu
  return (
    <FabStyled
      {...otherArgs}
      disabled={isFetching || disabled}
      disableRipple
      variant='extended'
      size='large'
    >
      {isFetching ? <ProgressStyled size={20} /> : children}
    </FabStyled>
  )
}

TextButton.defaultProps = {
  isFetching: null,
}

TextButton.propTypes = {
  isFetching: PropTypes.bool,
}

export const StyledTextButton = styled(TextButton)`
  box-shadow: none;
  background-color: ${({ color }) => ( color === 'white' ? 'white' : BUTTON_COLORS.purple)};
  color: ${({ color }) => ( color === 'white' ? BUTTON_COLORS.purple : 'white')};
  border: ${({ color }) => ( color === 'white' ? '2px solid #6633FF' : '1px solid #fff')};
  text-transform: none;
  font-family: Roboto, sans-serif;
  font-size: 14px;
  z-index: 10;
  &:hover {
    background-color: ${({ color }) => ( color === 'white' ? '' : 'rgb(71, 35, 178)')};
    color: ${({ color }) => ( color === 'white' ? '' : 'white')};
    border: ${({ color }) => ( color === 'white' ? '' : '1px solid #fff')};
  }
`

export const StyledTextButtonDelete = styled(TextButton)`
  box-shadow: none;
  background-color: ${({ color }) => ( color === 'white' ? 'white' : Colors.red)};
  color: ${({ color }) => ( color === 'white' ? Colors.red : 'white')};
  border: ${({ color }) => ( color === 'white' ? '2px solid #ee3c5b' : '1px solid #fff')};
  text-transform: none;
  font-family: Roboto, sans-serif;
  font-size: 14px;
  margin-left: 20px;
  z-index: 10;
  height: 40px;
  &:hover {
    background-color: ${({ color }) => ( color === 'white' ? '' : BUTTON_COLORS.purpleHover)};
    color: ${({ color }) => ( color === 'white' ? '' : 'white')};
    border: ${({ color }) => ( color === 'white' ? '' : '1px solid #fff')};
  }
`
import React from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { ScheduleForm } from 'common/UiKit/ScheduleForm'
import { EditPage } from 'common/UiKit/EditPage'
import { createSchedule } from '../../../../../redux/modules/auth'
import { history } from '../../../../../utils/history'
import { PROFILE_FILL_STEPS } from '../../../../../constants/ui'
import { externalFormSubmit } from '../../../../../helpers/externalFormSubmit'

const EditScheduleComponent = ({
  initialValues,
  createSchedule,
  loading,
}) => (
  <EditPage
    label='profile.about'
    onBackIconClick={() => history.push('/panel/profile')}
    buttonText='global.save'
    onButtonClick={() => externalFormSubmit(PROFILE_FILL_STEPS[4])}
    loading={loading}
  >
    <ScheduleForm
      initialValues={initialValues}
      onSubmit={createSchedule}
      id={PROFILE_FILL_STEPS[4]}
      afterSubmit={() => history.push('/panel/profile')}
      disableShadow
    />
  </EditPage>
)

EditScheduleComponent.propTypes = {
  initialValues: PropTypes.arrayOf(PropTypes.object.isRequired).isRequired,
  createSchedule: PropTypes.func.isRequired,
  loading: PropTypes.bool.isRequired,
}

const enhance = connect(
  state => ({
    initialValues: state.auth.relationships.schedule.data,
    loading: state.loading.schedule,
  }),
  { createSchedule },
)

export const EditSchedule = enhance(EditScheduleComponent)

import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import { connect } from 'react-redux'
import { compose, withProps } from 'recompose'
import { EditPage } from 'common/UiKit/EditPage'
import { Notifications as NotificationsForm } from '../../../common/Settings'
import { externalFormSubmit } from '../../../../helpers/externalFormSubmit'
import { history } from '../../../../utils/history'
import { changeNotificationsSettings } from '../../../../redux/modules/settings'

const Wrapper = styled.div`
  margin-top: 24px;
`

const NotificationsComponent = ({
  onButtonClick,
  onBackIconClick,
  notifications,
  onSubmit,
}) => (
  <EditPage
    label='profile.notifications'
    buttonText='global.save'
    onBackIconClick={onBackIconClick}
    onButtonClick={onButtonClick}
    background
  >
    <Wrapper>
      <NotificationsForm
        initialValues={notifications}
        onSubmit={onSubmit}
        afterSubmit={onBackIconClick}
      />
    </Wrapper>
  </EditPage>
)

NotificationsComponent.propTypes = {
  onSubmit: PropTypes.func.isRequired,
  onButtonClick: PropTypes.func.isRequired,
  onBackIconClick: PropTypes.func.isRequired,
  notifications: PropTypes.object.isRequired,
}

const enhance = compose(
  connect(
    state => ({ notifications: state.settings.notifications }),
    { changeNotificationsSettings },
  ),
  withProps(props => ({
    onSubmit: async v => props.changeNotificationsSettings(v),
    onButtonClick: () => externalFormSubmit('notifications'),
    onBackIconClick: () => history.goBack(),
  })),
)

export const Notifications = enhance(NotificationsComponent)

import React, {useEffect, useState} from 'react';
import {Field, Form} from 'react-final-form'
import styled, {css} from 'styled-components'
import {compose, withProps} from 'recompose'
import PropTypes from 'prop-types'
import {MultilineTextField} from 'common/UiKit/MultilineTextField'
import {TextField} from '../UiKit/TextField'
import {Paper} from 'common/UiKit/Paper'
import {connect, useDispatch} from 'react-redux';
import {FormattedMessage, useIntl} from 'react-intl'
import {Colors} from '../../../constants/colors'
import {RadioInputField} from '../RadioInputField';
import {SpecializationsSelect} from '../SpecializationsSelect';
import {fetchSpecializations} from '../../../redux/modules/content';
import Button from "@mui/material/Button";
import {PrescriptionDialog} from "../../Panel/Doctor/Consultations/Session/Prescription";
import idx from "idx";
import Tooltip from '@mui/material/Tooltip';
import {fetchPrescription} from "../../../redux/modules/consultations";
import {TextButton} from "../UiKit/TextButton";
import {CONSULTATION_TYPES, DIAGNOSIS_MAX_LENGTH, SYMPTOMS_MAX_LENGTH} from "../../../constants/consultation";
import Chip from "@mui/material/Chip";
import {Select} from "../Select";

const MultilineField = styled(MultilineTextField)`
  & .root {
    height: 88px;
  }
`

const DiagnosisField = styled(MultilineTextField)`
  & .root {
    height: 168px;
  }
`

const RadioFieldWrapper = styled.div`
  & > div > div {
    box-shadow: none;
    padding-left: 0;
  }
  
  & > div > header, & > div > div > div {
    padding-left: 0;
  }
  
  div[role="button"]:first-of-type {
    margin-left: 0;
  }
`
const Line = styled.div`
  margin-top: 10px;
  margin-bottom: 10px;
  display: flex;
`

const Column = styled.div`
  display: flex;
  flex-direction: column;
`

const Error = styled.div`
  color: red;
  font-size: 10px;
  margin-bottom: 5px;
`

const NextButton = styled(TextButton)`
  && {
    width: auto;
    font-size: 14px;
    color: white;
    cursor: pointer;
    z-index: 0;
    text-transform: none;

    ${props => (props.color && css`
    background-color: ${props.color};
    `)}
    margin-right: 150px;
    margin-left: 600px;
  
  }
  &&:hover {
    background-color: rgb(23, 137, 118);
  }
`
const ButtonStyledTCA = styled(Button)`
  && {
    height: 36px;
    margin: 8px;
    padding: 0 10px;
    font-size: 12px;
    font-weight: 500;
    text-transform: uppercase;
    letter-spacing: 0.4px;
    border: 1px solid;
    border-radius: 16px;
    background-color: ${Colors.paleGrey};
    color: ${Colors.blueGrey};
  }
`

const Label = styled.div`
  width: 100%;
  min-width: 172px;
  margin-bottom: 24px;
  margin-right: 6px;
  opacity: 0.87;
  font-weight: bold;
  font-size: 16px;
  color: ${Colors.dark};
`

const ChipStyled = styled(props => (
  <Chip {...props} classes={{ colorSecondary: 'secondary' }} />
))`
  && {
    height: 36px;
    margin: 8px;
    padding: 0 10px;
    font-size: 12px;
    font-weight: 500;
    text-transform: uppercase;
    letter-spacing: 0.4px;
  }

  &&.secondary {
    background-color: ${Colors.paleGrey};
    color: ${Colors.blueGrey};
  }
`

const PriceField = styled(TextField)`
  && {
    width: auto;
    margin: 0 10px;
    label {
      padding-left: 2px;
      padding-top: 2px;
    }
    & > div {
      padding-left: 2px;
    }
  }
`

const FieldLabel = styled.div`
    align-content: center;
    min-width: 90px;
`

const StyledSelect = styled(Select)`
    margin-left: 15px;
    border: 1px solid ${Colors.silver};
    min-width: 100px;
`

const StyledForm = styled.form`
  margin-top: 20px;
  & > div {
    margin-bottom: 16px;
  }
`

const validate = (values, isMergeEmployment, consiliumStatus, isTCA,isTC) => {

  let errors = {};
  const isConsiliumOrTCA = consiliumStatus && consiliumStatus.consiliumStatus || isTCA ;
  if (!isConsiliumOrTCA && values) {
    if (!values.consultation_price || values.consultation_price <= 0) {
      errors.consultation_price = <FormattedMessage id='errors.required.tarif' />;
    }
    if (!values.consultation_price) errors.consultation_price = <FormattedMessage id='errors.required.tarif.max' />

  } else {
    if(isTCA || isTC){
      if (values.consultation_price.replace(/[0-9]/g, "").includes("..")) {
        errors.consultation_price = <FormattedMessage id='errors.format.tarif.float' />;
      }
      if (!values.consultation_price || values.consultation_price.length < 1) errors.consultation_price = <FormattedMessage id='errors.required.tarif' />
      if (!values.consultation_price || values.consultation_price <= 0) errors.consultation_price = <FormattedMessage id='errors.required.tarif' />
      if (!values.consultation_code_acte) errors.consultation_code_acte = <FormattedMessage id='errors.required.code-acte' />
    }
    if (!values.diagnosis || values.diagnosis.length > DIAGNOSIS_MAX_LENGTH) errors.diagnosis = true
    if (!values.diagnosis || values.diagnosis.length < 1) errors.diagnosis = <FormattedMessage id='errors.required' />
    if (!values.symptoms || values.symptoms.length > SYMPTOMS_MAX_LENGTH) errors.symptoms = true
    if (!values.symptoms || values.symptoms.length < 1) errors.symptoms = <FormattedMessage id='errors.required' />
  }
  if (!values.needConsultationWithSpecialist || values.needConsultationWithSpecialist === '0') errors.needConsultationWithSpecialist = true
  if (values.restrictions && values.restrictions.length > 200) errors.restrictions = true
  if (isMergeEmployment && !values.doctor_type_employment) errors.doctor_type_employment = <FormattedMessage id='errors.required' />
  if (values.redirectToSpecialization && values.redirectToSpecialization !== '0' && !values.redirectSpecializationId) errors.redirectSpecializationId = <FormattedMessage id='errors.required' />
  return errors
}

const enhance = compose(
  connect(state => ({
    chatId: idx(state, _ => _.chats[state.consultations.consultation.id].chat.id),
    consultationId: idx(state, _ => _.consultations.consultation.id),
  })),
  withProps(props => ({
    chatId: props.chatId,
    consultationId: props.consultationId,
    onSubmit: values => props.onSubmit(values)
      .then(() => props.afterSubmit()),
  }))
)

export const MedicalReportForm = enhance(({
  consultationId,
  onSubmit,
  id,
  initialValues,
  consiliumStatus,
  employmentType,
  currentStep,
  setCurrentStep,
  consulationType,
}) => {
  const { formatMessage } = useIntl()
  const f = id => formatMessage({ id })
  const dispatch = useDispatch()
  const [prescriptionOpen, setPrescriptionOpen] = useState(false)
  const [disabled, setDisabled] = useState(true)
  const isConsilium = consiliumStatus && consiliumStatus.consiliumStatus;
  const isTCA = consulationType === CONSULTATION_TYPES.TCA
  const isTC = consulationType === CONSULTATION_TYPES.scheduled || consulationType === CONSULTATION_TYPES.urgent

  const next = () => {
    setCurrentStep(2);
  }


  useEffect(() => {
    dispatch(fetchSpecializations())
    if (consultationId) {
      dispatch(fetchPrescription(consultationId))
        .catch(e => console.error('Failed to fetch prescriptions', e))
    }
  }, [])

  const normalizePrice = value => {
    if (!value) return value;
    return value.replace(/[^\d]/g, ".");
  };

  return (
    <Form
      onSubmit={onSubmit}
      validate={values => validate(values, employmentType === 'merge', consiliumStatus, isTCA,isTC)}
      initialValues={{
        ...(initialValues || {}),
        redirectSpecializationId: null,
        redirectToSpecialization: '0',
        needConsultationWithSpecialist: 'no',
        invoice_paid: '1',
        consultation_price: '',
        consultation_code_acte: 'TC',
      }}
      render={({ handleSubmit, values }) => (
        <StyledForm onSubmit={handleSubmit} id={id}>
            { currentStep === 1 && (
            <>
              {
                !isTCA && !isTC && initialValues.symptoms !== 'null' && (
                  <Paper heading='concilium.describe'>
                    <Field
                      disabled={true}
                      component={MultilineField}
                      name='symptoms'
                      label={`*${f('consultations.symptoms')}`}
                      maxLength={SYMPTOMS_MAX_LENGTH}
                      parse={value => (value ? value.substring(0, SYMPTOMS_MAX_LENGTH) : '')}
                      multiline
                      maxRows={3}
                      isRequired={true}
                    />
                  </Paper>
                )
              }
              <Paper heading='consultation.report.diagnostic'>
                <Field
                  component={MultilineField}
                  name='diagnosis'
                  id='diagnosis'
                  label={`*${f('consultation.report.diagnostic.label')} (champ obligatoire)`}
                  maxLength={DIAGNOSIS_MAX_LENGTH}
                  parse={value => (value ? value.substring(0, DIAGNOSIS_MAX_LENGTH) : '')}
                  isRequired={true}
                  validate = {v => setDisabled(!v)}
                  multiline
                  maxRows={3}
                />
              </Paper>
              <Paper>
                <RadioFieldWrapper>
                  <RadioInputField
                    headingTransparent={f('consultation.redirect.question')}
                    name='needConsultationWithSpecialist'
                    id='needConsultationWithSpecialist'
                    options={[
                      { label: f('global.yes-fast'), value: 'yes-fast' },
                      { label: f('global.yes'), value: 'yes' },
                      { label: f('global.not'), value: 'no' },
                    ]}
                  />
                </RadioFieldWrapper>
              </Paper>
              <Paper>
                <RadioFieldWrapper>
                  <RadioInputField
                    headingTransparent={f('consultation.another.redirect.question')}
                    name='redirectToSpecialization'
                    id='redirectToSpecialization'
                    options={[
                      { label: f('global.not'), value: '0' },
                      { label: f('global.yes'), value: '1' },
                    ]}
                  />
                </RadioFieldWrapper>
              </Paper>
              {
                (values && +values.redirectToSpecialization) ? (
                  <Column>
                    <Label>
                      <FormattedMessage id='profile.specialization' />
                    </Label>
                    <Field
                      component={({ input, meta }) => (
                        <SpecializationsSelect
                          {...input}
                          meta={meta}
                          getOptionValue={option => option.attributes.key}
                        />
                      )}
                      name='redirectSpecializationId'
                    />
                  </Column>
                ) : null
              }
              {
                isConsilium  && employmentType === 'merge' && (
                  <Field
                    name='doctor_type_employment'
                    component={TextField}
                    select
                    label={`*${f('profile.method')}`}
                    options={[
                      { label: <FormattedMessage id='profile.salary' />, value: 'hospital' },
                      { label: <FormattedMessage id='profile.liberal' />, value: 'private' },
                    ]}
                  />
                )
              }
            </>
            )}
          {
            !!prescriptionOpen && (
              <PrescriptionDialog
                opened={!!prescriptionOpen}
                onClose={() => setPrescriptionOpen(false)}
                // onSaved={(p) => setPrescriptionFilled(p != null)}
              />
            )
          }


          {!consiliumStatus.consiliumStatus && currentStep === 2
            ?
            <>
              {
                employmentType === 'merge' && (
                  <Field
                    name='doctor_type_employment'
                    component={TextField}
                    select
                    label={`*${f('profile.method')}`}
                    options={[
                      { label: <FormattedMessage id='profile.salary' />, value: 'hospital' },
                      { label: <FormattedMessage id='profile.liberal' />, value: 'private' },
                    ]}
                  />
                )
              }
              <Paper heading='global.tarification'>
                <RadioFieldWrapper>
                  {
                    isTCA ? (
                      <div>
                        <FormattedMessage id="global.tiers.payant" />
                        <Line>
                          <ChipStyled
                            name='invoice_paid'
                            id='invoice_paid'
                            options={[
                              {label: 'Oui', value: '1'},
                            ]}
                            color={ 'primary'}
                            label={'Oui'}
                          />
                          <Tooltip title='La téléconsultation assistée est disponible pour les patients éligible au tiers payant uniquement.' placement='right-end'>
                            <ButtonStyledTCA>Non</ButtonStyledTCA>
                          </Tooltip>
                        </Line>
                      </div>
                    ) : (
                      <RadioInputField
                        headingTransparent={f('global.tiers.payant')}
                        name='invoice_paid'
                        id='invoice_paid'
                        options={[
                          {label: 'Oui', value: '1'},
                          {label: 'Non', value: '0'},
                        ]}
                      />
                    )
                  }
                </RadioFieldWrapper>

                <Line>
                  <FieldLabel>Code acte</FieldLabel>
                  <Field
                    name='consultation_code_acte'
                    id='consultation_code_acte'
                    label={'Code acte'}
                    options={[
                      {label: 'TC', value: 'TC'},
                      {label: 'TCG', value: 'TCG'},
                      {label: 'TCS', value: 'TCS'},
                    ]}
                  >
                    {props => (
                      <StyledSelect
                        onChange={props.input.onChange}
                        options={props.options}
                        value={props.input.value}
                      />
                    )}
                  </Field>
                  <div>
                    Sélectionnez le code acte correspondant à votre activité. Si vous avez besoin d'aide, suivez le
                    &nbsp;
                    <a
                      href="https://sante.gouv.fr/IMG/pdf/guide_facturation_es_version_cnam_ars_fevrier_2024.pdf"
                      target="_blank"
                    >Guide de facturation</a>
                  </div>
                </Line>

                <Line>
                  <FieldLabel>Tarif acte</FieldLabel>
                  <Field
                    component={PriceField}
                    name='consultation_price'
                    id='consultation_price'
                    parse={normalizePrice}
                    label={f('consultations.cost')}
                  />
                </Line>
              </Paper>
            </>
            :
            ''
          }

          {currentStep <2 && (consulationType === CONSULTATION_TYPES.scheduled || consulationType === CONSULTATION_TYPES.urgent || consulationType === CONSULTATION_TYPES.TCA) &&
            (
               <NextButton
                 variant='extended'
                 color = {disabled ? '#D3D3D3' : '#21C5A9'}
                 size='small'
                 onClick={next}
                 disabled={disabled}
               >
                 <FormattedMessage id='global.next' />
               </NextButton>
            )
          }
        </StyledForm>
      )}
    />
  )
})

MedicalReportForm.propTypes = {
  afterSubmit: PropTypes.func.isRequired,
  onSubmit: PropTypes.func.isRequired,
  id: PropTypes.string.isRequired,
  disableShadow: PropTypes.bool,
}

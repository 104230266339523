import { createAction, handleActions } from 'redux-actions'
import { combineReducers } from 'redux'
import axios from 'axios'
import idx from 'idx'

export const fetchMedicinesRequest = createAction('FETCH_MEDICINES_REQUEST')
export const fetchMedicinesSuccess = createAction('FETCH_MEDICINES_SUCCESS')
export const fetchMedicinesFailure = createAction('FETCH_MEDICINES_FAILURE')

export const fetchMedicines = payload => (dispatch) => {
  dispatch(fetchMedicinesRequest())

  return axios('/drug/search', {
    params: { term: payload },
  })
    .then(res => dispatch(fetchMedicinesSuccess(res.data)))
    .catch((err) => {
      dispatch(fetchMedicinesFailure(err))
    })
}

export const checkMedicineCompabilityWithProfileRequest = createAction('CHECK_COMPABILITY_WITH_PROFILE_REQUEST')
export const checkMedicineCompabilityWithProfileSuccess = createAction('CHECK_COMPABILITY_WITH_PROFILE_SUCCESS')
export const checkMedicineCompabilityWithProfileFailure = createAction('CHECK_COMPABILITY_WITH_PROFILE_FAILURE')

export const checkMedicineCompabilityWithProfile = () => (dispatch, getStore) => {
  dispatch(checkMedicineCompabilityWithProfileRequest())

  const medicines = idx(
    getStore(),
    _ => _.consultations.consultation.relationships.drugs.data.map(d => d.attributes.api_id),
  )

  if (!medicines || !medicines.length) {
    return dispatch(checkMedicineCompabilityWithProfileFailure())
  }

  return Promise.all(
    medicines.map(
      async id => ({
        id,
        res: await axios(`/drug/interaction/profile/${id}`),
      }),
    ),
  )
    .then(arr => dispatch(checkMedicineCompabilityWithProfileSuccess(arr)))
    .catch((err) => {
      dispatch(checkMedicineCompabilityWithProfileFailure(err))
    })
}

export const fetchPatientMedicinesRequest = createAction('FETCH_PATIENT_MEDICINES_REQUEST')
export const fetchPatientMedicinesSuccess = createAction('FETCH_PATIENT_MEDICINES_SUCCESS')
export const fetchPatientMedicinesFailure = createAction('FETCH_PATIENT_MEDICINES_FAILURE')

export const fetchPatientMedicines = payload => (dispatch) => {
  dispatch(fetchPatientMedicinesRequest())

  return axios(`/drug/patient/list/${payload}`)
    .then(res => dispatch(fetchPatientMedicinesSuccess(res.data)))
    .catch((err) => {
      dispatch(fetchPatientMedicinesFailure(err))
    })
}

export const checkMedicineCompabilityWithAnotherRequest = createAction('CHECK_COMPABILITY_WITH_ANOTHER_REQUEST')
export const checkMedicineCompabilityWithAnotherSuccess = createAction('CHECK_COMPABILITY_WITH_ANOTHER_SUCCESS')
export const checkMedicineCompabilityWithAnotherFailure = createAction('CHECK_COMPABILITY_WITH_ANOTHER_FAILURE')

export const checkMedicineCompabilityWithAnother = payload => (dispatch) => {
  dispatch(checkMedicineCompabilityWithAnotherRequest())

  return axios(`/drug/interaction/drugs/${payload.checkedId}/${payload.withId}`)
    .then(res => dispatch(checkMedicineCompabilityWithAnotherSuccess({
      id: payload.checkedId,
      data: res.data.data,
    })))
    .catch((err) => {
      dispatch(checkMedicineCompabilityWithAnotherFailure(err))
    })
}

export const checkMedicinesWithEachOtherRequest = createAction('CHECK_MEDICINES_WITH_EACH_OTHER_REQUEST')
export const checkMedicinesWithEachOtherSuccess = createAction('CHECK_MEDICINES_WITH_EACH_OTHER_SUCCESS')
export const checkMedicinesWithEachOtherFailure = createAction('CHECK_MEDICINES_WITH_EACH_OTHER_FAILURE')

export const checkMedicinesWithEachOther = payload => (dispatch) => {
  dispatch(checkMedicinesWithEachOtherRequest())

  const { medicines } = payload

  const calls = medicines.reduce((prevResult, medicine, index, arr) => {
    const result = [...prevResult]

    arr.slice(index).forEach((checkedMedicine) => {
      const ids = [medicine.value, checkedMedicine.value]
      const names = [medicine.label, checkedMedicine.label]

      if (
        ids[0] === ids[1]
        || result.find(r => r.ids.includes(ids[0]) && r.ids.includes(ids[1]))
      ) return

      result.push({
        ids,
        callback: async () => ({
          names,
          res: await axios(`/drug/interaction/drugs/${ids[0]}/${ids[1]}`),
        }),
      })
    })

    return result
  }, [])

  return Promise.all(calls.map(c => c.callback()))
    .then(arr => dispatch(checkMedicinesWithEachOtherSuccess(arr)))
    .catch((e) => {
      dispatch(checkMedicinesWithEachOtherFailure(e))
    })
}

export const clearCheckedMedicines = createAction('CLEAR_CHECKED_MEDICINES')

const list = handleActions({
  [fetchMedicinesSuccess]: (state, action) => action.payload.data.slice(0, 10),
}, [])

const patientList = handleActions({
  [fetchPatientMedicinesSuccess]: (state, action) => action.payload.data,
}, [])

const checkedWithProfile = handleActions({
  [checkMedicineCompabilityWithProfileSuccess]:
    (state, action) => action.payload.reduce((obj, item) => ({
      ...obj,
      [item.id]: idx(item, _ => _.res.data.data),
    }), {}),
  [clearCheckedMedicines]: () => ({}),
}, {})

const checkedWithMedicines = handleActions({
  [checkMedicineCompabilityWithAnotherSuccess]:
    (state, action) => ({ ...state, [action.payload.id]: action.payload.data }),
  [clearCheckedMedicines]: () => ({}),
}, {})

const checkedWithEachOhter = handleActions({
  [checkMedicinesWithEachOtherSuccess]:
    (state, action) => action.payload.map(item => ({
      names: item.names,
      icon: item.res.data.data.length
        ? item.res.data.data[0].attributes.icon
        : 'ok',
    })),
  [clearCheckedMedicines]: () => ({}),
}, [])

export const medicines = combineReducers({
  list,
  checkedWithProfile,
  patientList,
  checkedWithMedicines,
  checkedWithEachOhter,
})

export const profileChangePaths = {
  personal_data: '/panel/profile/edit/personal_data',
  about_doctor: '/panel/profile/edit/about_doctor',
  diplomas: '/panel/profile/edit/diplomas',
  additional_information: '/panel/profile/edit/additional_information',
  schedule: '/panel/profile/edit/schedule',
  prices: '/panel/profile/edit/prices',
  signature: '/panel/profile/signature',
  emailManagement: '/panel/profile/edit/email-management',
  skills: '/panel/profile/edit/skills',
  external_partners: '/panel/profile/edit/partners',

}

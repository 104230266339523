import React from "react";
import styled from 'styled-components'
import {useSelector} from "react-redux";
import {Field} from "react-final-form";

const Input = styled.input`
  //display: none;
`

export const DraftConsultation = ({ input }) => {
	const draftConsultation = useSelector(state => state.consultations.draft.consultation)
	
	if (typeof draftConsultation !== 'undefined') {
		return (
			<>
				<Field name='draftId' initialValue={draftConsultation.id}>
					{props =>
						<Input
							name={props.input.name}
							type='hidden'
							value={draftConsultation.id}
						/>
					}
				</Field>
			</>
		)
	}
	else {
		return (
			<></>
		)
	}
}
import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import { compose, withProps } from 'recompose'
import { connect } from 'react-redux'
import { Links } from './Links'
import { HealthDescriptionLink } from './HealthDescriptionLink'
import { logOut } from '../../../../../redux/modules/auth'
import { history } from '../../../../../utils/history'
import { NotificationsButton } from '../../../../common/Page/Notifications'
import { PanelBar } from '../../../../common/PanelBar'

const Content = styled.div`
  width: 720px;
  margin: 24px auto 0;
`

const MainComponent = ({
  logOut,
  onIconClick,
}) => (
  <>
    <PanelBar
      label='hr'
      onLogout={logOut}
      onIconClick={onIconClick}
      notification={<NotificationsButton />}
    />
    <Content>
      <HealthDescriptionLink />
      <Links />
    </Content>
  </>
)

MainComponent.propTypes = {
  logOut: PropTypes.func.isRequired,
  onIconClick: PropTypes.oneOfType([PropTypes.bool, PropTypes.func]).isRequired,
}

const enhance = compose(
  connect(
    () => ({}),
    { logOut },
  ),
  withProps(props => ({
    onIconClick: props.match.isExact ? false : () => history.push('/panel/health-records'),
  })),
)

export const Main = enhance(MainComponent)

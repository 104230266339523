import React, {useEffect, useState} from 'react';
import {FormattedMessage} from "react-intl";
import styled from "styled-components";
import { Paper } from 'common/UiKit/Paper'
import {useDispatch, useSelector} from "react-redux";
import {SecuredBckgImage} from "../../../common/SecuredImage";
import moment from "moment-timezone";
import axios from "axios";
import {CreatePatient} from "../../Doctor/Concilium/CreatePatient";
import {updateInfoPatientSuccess} from "../../../../redux/modules/consultations";
import {getAge} from "../../../../utils/dateOperations";
import {Sex} from "../../../common/Gender/sex";
import {DisplayGender} from "../../../common/Gender";

const Wrapper = styled.div`
  align-self: center;
  width: 100%;
  height: 1500px;
`

const Infos = styled.div`
  margin-top: 50px;
  width: 700px;
  display: block;
  margin-right: auto;
  margin-left: auto;
`

const PaperStyled = styled(Paper)`
  margin-bottom: 16px;
`

const WrapperIdentity = styled.div`
  display: flex;
`

const Line = styled.div`
  padding: 8.5px 0;
  display: flex;
  opacity: 0.87;
  text-align: justify;
  text-justify: auto;
`

const Info = styled.div`
  flex-grow: 1;
  margin: 12px;
`

const Identity = styled.div`
  font-weight: 500;
  width: 300px;
  margin-right: 6px;
  font-size: 18px;
  margin-bottom: 20px;
  margin-top: 5px;
`

const Name = styled.span`
  font-weight: 500;
  width: 300px;
  margin-right: 6px;
`

const Birthplace = styled.span`
  text-transform: capitalize;
`

const Avatar = styled(SecuredBckgImage)`
  position: relative;
  min-width: 50px;
  width: 50px;
  height: 50px;
  border: 3px solid white;
  border-radius: 100%;
  background: center no-repeat; 
  background-size: cover;
  display: flex;
  align-items: center;
  justify-content: center;
  box-shadow: 0 4px 8px 0 rgba(6, 173, 255, 0.1);
  margin-right: 10px;
`

export const InfosPatient = () => {
    const attributes = useSelector(state => state.consultations.consultation.relationships.patient.data.attributes)
    const firstName = attributes.first_name
    const lastName = attributes.last_name
    const gender = attributes.gender
    const dateOfBirth = moment(attributes.date_of_birth).format('D MMMM YYYY')
    const medicalInsurance = attributes.medical_insurance
    const cpam = attributes.cpam
    const note = attributes.note
    const photo = attributes.photo
    const age = getAge(new Date(attributes.date_of_birth))
    const isForeign = attributes.is_foreign
    const id = attributes.user_id
    const [birthPlace, setBirthPlace] = useState('');
    const patientId = useSelector(state => state.consultations.consultation.relationships.patient.data.id);
    const virtual = attributes.virtual;
    const hasEdit = attributes.hasEdit;

    useEffect(() => {
        const res = axios.get(`/api/birthplace/${id}`, {responseType: 'json'})
            .then((response) => {
                setBirthPlace(response.data)
            });
    }, []);

    const dispatch = useDispatch();
    const [open, setOpen] = useState(false)
    const modifyButton = !!(virtual && hasEdit);

    return (
        <Wrapper>
            <Infos>
                <PaperStyled heading='patient.identity' modifyButton={modifyButton} setOpen={setOpen}>
                    { virtual && hasEdit && (
                        <CreatePatient editPatientId={patientId} open={open} onClose={() => setOpen(false)} afterSubmit={(dataPatient) => dispatch(updateInfoPatientSuccess(dataPatient))} modify={true}/>
                    )}
                    <WrapperIdentity>
                        {photo ? (
                            <Avatar src={photo}/>
                        ) : (
                            <Avatar src={'/static/media/default-avatar.f79109f6.svg'}/>
                        )}
                        <Info>
                            <Identity>
                                <DisplayGender genderCode={gender} /> {` ${firstName} ${lastName}`}
                            </Identity>
                            <Line>
                                <Name>
                                    <FormattedMessage id='patient.sex'/>
                                </Name>
                                {gender ? <Sex code={gender} /> : '-'}
                            </Line>
                            <Line>
                                <Name>
                                    <FormattedMessage id='patient.age'/>
                                </Name>
                                {age ? age : '-'}
                            </Line>
                            <Line>
                                <Name>
                                    <FormattedMessage id='patient.birthdate'/>
                                </Name>
                                {dateOfBirth ? dateOfBirth : '-'}
                            </Line>
                            <Line>
                                <Name>
                                    <FormattedMessage id='patient.birthplace'/>
                                </Name>
                                <Birthplace>
                                    {birthPlace ? birthPlace.toLowerCase() : '-'}
                                </Birthplace>

                            </Line>
                        </Info>
                    </WrapperIdentity>
                </PaperStyled>
                <PaperStyled heading='patient.insurance'>
                    <Line>
                        <Name>
                            <FormattedMessage id='patient.foreign'/>
                        </Name>
                        {isForeign ? <FormattedMessage id='global.yes'/> : <FormattedMessage id='global.not'/>}
                    </Line>
                    <Line>
                        <Name>
                            <FormattedMessage id='patient.insurance.number'/>
                        </Name>
                        {medicalInsurance ? medicalInsurance : '-'}
                    </Line>
                    <Line>
                        <Name>
                            <FormattedMessage id='patient.cpam'/>
                        </Name>
                        {cpam ? cpam : '-'}
                    </Line>
                </PaperStyled>
                <PaperStyled heading='patient.comments'>
                    <Line>
                        {note ? note : '-'}
                    </Line>
                </PaperStyled>
            </Infos>
        </Wrapper>
    )
}
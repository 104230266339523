import React, { useEffect } from 'react';
import AddIcon from '@mui/icons-material/Add';
import { AsyncSelectField } from 'common/AsyncSelect';
import styled from 'styled-components';

const StyledAsyncInput = styled.div`
    padding-bottom: 15px;
`

const PlusIcon = styled(AddIcon)`
  cursor: pointer;
  margin-bottom: 15px;
  font-size: 30px !important;
`

export const MultipleInfinityAsyncSelect = React.memo(({
  val = [], input = {}, defaultInputValues, createDefaultField, listMembersAtCreation, noOptionsTxt , ...props
}) => {

  const changeValue = (id, i) => {
    const newVal = [...val];
    newVal[i] = id;
    input.onChange(newVal);
  }

  const appendEmpty = () => {
    input.onChange([...val, '']);
  }

  useEffect(() => {
    if (!createDefaultField) {
      input.onChange([...val, '']);
      val=[]
    }
  }, []);

  return (
    <div>
      {[...val].map((el, i) => (
        <StyledAsyncInput key={i}>
          <AsyncSelectField
            listMembersAtCreation={listMembersAtCreation}
            val = {val}
            type='text'
            {...props}
            defaultInputValue={defaultInputValues && defaultInputValues[i+1]}
            input={{
              ...input, 
              value: input.value[i+1],
              onChange: id => changeValue(id, i+1),
            }}
            noOptionsTxt={noOptionsTxt}
          />
        </StyledAsyncInput>
      ))}
      <PlusIcon onClick={appendEmpty} />
    </div>
  )
}, (props, nextProps) => {
  if (props.val && nextProps && nextProps.val && nextProps.val.length && props.val.length === nextProps.val.length) {
    return true;
  }
  return false;
})

import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import get from 'lodash/get';
import styled from 'styled-components';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import uniqBy from 'lodash/uniqBy';
import { FormattedMessage } from 'react-intl';
import { readNotification } from '../../../redux/modules/notification';

const StyledDialog = styled(Dialog)`
    .MuiBackdrop-root {
        opacity: ${({ shadow }) => (shadow ? 1 : '0 !important')};
    }
    .MuiPaper-elevation24 {
    ${({ shadow }) => (!shadow ? 'box-shadow: 0px 0px' : '')}
    }
`

const StyledTitle = styled(DialogTitle)`
    font-weight: bold;
    word-break: break-all;

    h2 {
      font-size: 24px;
    }
`

const StyledDialogContentText = styled(DialogContentText)`
    font-size: 14px !important;
    overflow: scroll;
    max-height: 350px;
    word-break: break-all;
`

export const GlobalNotification = () => {
  const socketNotifications = useSelector(state => state.notification.list);
  const globalNotificationsList = useSelector(state => state.notification.globalNotifications);
  const socketGlobalNotifications = get(socketNotifications, 'data', [])
    .filter((el) => {
      const attrs = el && el.attributes;
      return attrs.type === 'general' && attrs.read === false;
    });
  const [closed, setClosed] = useState([]);
  const dispatch = useDispatch();
  const unifredNotifications = uniqBy([...globalNotificationsList, ...socketGlobalNotifications], 'id');
  const toRenderNotifications = unifredNotifications
    .filter(el => !closed.some(id => id === el.id))
    .sort((el, el2) => {
      if (el.id < el2.id) {
        return -1;
      } 
      return 1;
    })

  const handleClose = (notificationId) => {
    dispatch(readNotification([notificationId]));
    setClosed([...closed, notificationId]);
  }

  return (
    <div>
      {toRenderNotifications.map((el, i) => (
        <StyledDialog
          fullWidth
          shadow={i === toRenderNotifications.length - 1}
          key={el.id}
          open={!closed.some(id => id === el.id) && i === toRenderNotifications.length - 1}
          onClose={() => handleClose(get(el, 'attributes.id', null))}
        >
          <StyledTitle id='alert-dialog-title'>{get(el, 'attributes.title', '')}</StyledTitle>
          <DialogContent>
            <StyledDialogContentText id='alert-dialog-description'>
              {get(el, 'attributes.message', '')}
            </StyledDialogContentText>
          </DialogContent>
          <DialogActions>
            <Button onClick={() => handleClose(get(el, 'attributes.id', null))} color='primary'>
              <FormattedMessage id='global.close' />
            </Button>
          </DialogActions>
        </StyledDialog>
      ))}
    </div>
  ) 
}

import React from 'react'
import styled from 'styled-components'
import { CircularProgress } from '@mui/material'

const Wrapper = styled.div`
  width: ${({$width}) => $width};
  height: ${({$height}) => $height};
  display: flex;
  align-items: center;
  justify-content: center;
`

export const Preloader = ({ width='100%', height='100vh'}) =>
  {
    return (
      <Wrapper $width={width} $height={height}>
        <CircularProgress size={60}/>
      </Wrapper>
    )
  }

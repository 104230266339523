import React, {useState} from 'react'
import styled from "styled-components";
import {DistanceDisplay} from "../../../../../common/DistanceDisplay";
import Paper from "@mui/material/Paper";
import {Colors} from "../../../../../../constants/colors";
import defaultLogo from "../../../../../../images/doctors/structures/logo_structure.png";
import {SecuredBckgImage} from "../../../../../common/SecuredImage";
import {history} from "../../../../../../utils/history";
import idx from "idx";
import {FavoriteButton} from "../../../../../common/DoctorCard/FavoriteButton";
import {useDispatch} from "react-redux";
import {toggleFavoriteStructure} from "../../../../../../redux/modules/structures";

export const StructureItemComponent = ({item}) => {

  const dispatch = useDispatch();
  const city = idx(item, _ => _.attributes.city);
  const zipcode = idx(item, _ => _.attributes.zipcode);
  const type = idx(item, _ => _.attributes.type.type ||  _.attributes.type);
  const distance = idx(item, _ => _.attributes.distance);
  const name = idx(item, _ => _.attributes.name);
  const structureId = idx(item, _ => _.id);
  const photo = idx(item, _ => _.attributes.photo);
  const favorite = idx(item, _ => _.attributes.favorite);

  const [fav, setFav] = useState(favorite);

  const checkFavorite = (e) => {
    dispatch(toggleFavoriteStructure(structureId, !favorite))
      .then(() => {
        setFav(!fav)
      })
    e.stopPropagation();
  }

  return (
    <PaperStyled
      onClick={() => history.push(`/panel/community/structures/${structureId}`)}
    >
      <Wrapper>
        {photo ?
          <StructureIcon
            spinner={{width: '63px', height: '63px'}}
            src={photo.includes('/file/') ? `http://backend-application.owndoctor.eu${photo}` : photo}
          /> :
          <StructureIcon
            spinner={{width: '63px', height: '63px'}}
            src={defaultLogo}
          />}
        <Info>
          <Name>{name}</Name>
          <Type>{type}</Type>
          <City>
            {city}&nbsp;({zipcode})
          </City>
        </Info>
        <FavoriteWrapper>
          <FavoriteButton checked={fav}
                          onClick={checkFavorite}
                          isFetching={false}
                          message={false}
          />
        </FavoriteWrapper>
        <LanguageWrapper>
          {
            !!distance && ( <DistanceDisplay distance={distance} /> )
          }
        </LanguageWrapper>
      </Wrapper>
    </PaperStyled>
  )
}

const PaperStyled = styled(Paper)`
  padding: 10px 24px;
  position: relative;
  display: flex;
  height: 70px;
  border: 1px solid  #DCDCDC;
  :hover {
    color: ${Colors.bluePurple};
    background-color: #E7E2F6;
    cursor: pointer;
  }
`

const Wrapper = styled.div`
  display: flex;
  font-weight: normal;
  font-size: 16px;
  align-items: center;
`

const StructureIcon = styled(SecuredBckgImage)`
  min-width: 63px;
  width: 63px;
  height: 63px;
  display: flex;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  align-items: center;
  justify-content: center;
`

const FavoriteWrapper = styled.div`
  position: absolute;
  top: 8px;
  right: 8px;
  display: flex;
  color: #b7bed2;
`

const LanguageWrapper = styled.div`
  position: absolute;
  bottom: 20px;
  right: 40px;
  text-align: center;
  display: flex;
  align-items: center;
  color: #b7bed2;
`

const Info = styled.div`
  flex-grow: 1;
  margin: 0 32px 0;
  max-width: 260px;
`

const Name = styled.div`
  white-space: nowrap;
  overflow: hidden;
  font-weight: bold;
  word-break: break-word;
  text-overflow: ellipsis;
`

const Type = styled.div`
  margin: 4px 0 0 0;
  font-size: 14px;
  color: ${Colors.dark70};
  font-weight: 400;
  word-break: break-word;
  text-overflow: ellipsis;
`

const City = styled.div`
  margin: 4px 0 0 0;
  color: ${Colors.blueGrey};
  word-break: break-word;
  text-overflow: ellipsis;
`
import React from 'react'
import {FormattedMessage} from "react-intl";
import PropTypes from "prop-types";

export const Sex = ({code, style}) => {
  if (!code) {
    return (<span />)
  } // else

  if (code === 'female') {
    code = 'woman'
  }
  return (
    <span style={style}><FormattedMessage id={'profile.' + code} /></span>
  )
}

Sex.propTypes = {
  code: PropTypes.string,
  style: PropTypes.object
}

Sex.defaultProps = {
  code: null,
  style: {},
}
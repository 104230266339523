import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import idx from 'idx'
import { connect } from 'react-redux'
import { HealthDescription } from 'common/UiKit/HealthDescription'
import { LinkCard } from 'common/UiKit/LinkCard'
import { Colors } from 'constants/colors'
import { FormattedMessage } from 'react-intl'
import { EmptyStub } from '../EmptyStub'
import stubImage from '../../../../../../../images/consultations/profile/healthRecordsEmpty.svg'

/*const DOCUMENT_BUTTONS = [
  'Images',
  'Laboratory analyses',
  'Medical reports',
  'Prescriptions',
  'Other',
]*/

const Field = styled.div`
  margin-top: 24px;
  width: 100%;

  & > * {
    margin-bottom: 10px;
  }

  & > p {
    margin: 0 0 inherit 0;
    font-family: inherit;
    font-weight: 500;
    color: ${Colors.dark};
    font-size: 16px;
  }
`

const HealthRecordsTabComponent = ({
  healthRecordsOpened,
  healthDescription,
  attachmentsLength,
  isCompleted,
  contraindication,
}) => (
  <>
    {
      (healthRecordsOpened && !isCompleted)
        ? (
          <Field>
            <p>
              <FormattedMessage id='hr.description' />
            </p>
            <HealthDescription {...{ ...healthDescription, contraindication }} />
          </Field>
        )
        : (
          <EmptyStub
            image={stubImage}
            heading='hr.closed'
            text='hr.closed.text'
          />
        )
    }

    {
      (healthRecordsOpened && !isCompleted) && (
        <Field>
          <p>
            <FormattedMessage id='hr.documents' />
          </p>
          <LinkCard
            to='/panel/consultations/session/menu/attachments'
            label='profile.attachments'
            length={attachmentsLength}
          />
        </Field>
      )
    }
  </>
)

HealthRecordsTabComponent.propTypes = {
  healthRecordsOpened: PropTypes.bool.isRequired,
  attachmentsLength: PropTypes.number.isRequired,
  isCompleted: PropTypes.bool.isRequired,
}

const enhance = connect(
  state => ({
    healthRecordsOpened: +state.auth.id === idx(state, _ => _.consultations
      .consultation.relationships.additionalMember.data[0].attributes.doctorID)
      ? !!idx(state, _ => _.consultations.consultation
        .relationships.additionalMember.data[0].attributes.accessPatientData)
      : idx(
        state,
        _ => _.consultations.consultation.attributes.access_health_record,
      ),
    isCompleted: idx(state, _ => _.consultations.consultation.attributes.status === 'completed'),
    healthDescription: idx(
      state,
      _ => _.consultations.consultation.relationships.healthRecord.data.attributes,
    ) || idx(
      state,
      _ => _.healthRecords.attributes,
    ),
    contraindication: (idx(
      state,
      _ => _.consultations
        .consultation.relationships.healthRecord.data.relationships.contraindication.data,
    ) || []).map(item => item.attributes.name),
    attachmentsLength: (idx(
      state,
      _ => _.consultations
        .consultation.relationships.healthRecord.data.relationships.attachment.data,
    ) || []).length,
  }),
)

export const HealthRecordsTab = enhance(HealthRecordsTabComponent)

import React, {useState} from 'react'
import styled from "styled-components";
import {DefaultAvatar} from "../../../../common/DefaultAvatar";
import {Colors} from "../../../../../constants/colors";
import {SecuredBckgImage} from "../../../../common/SecuredImage";
import idx from "idx";
import {FormattedMessage} from "react-intl";
import { StyledTextButtonDelete} from "../../../../common/UiKit/TextButton";
import {useDispatch, useSelector} from "react-redux";
import {removeChatMember} from "../../../../../redux/modules/chatmembers";
import {fullName} from "../../../../../helpers/user";
import {ConfirmDialog} from "../../../../common/UiKit/ConfirmDialog";
import {DISCUSSION_TYPE__GROUP, DISCUSSION_TYPE__INDIVIDUAL} from "../../../../../constants/discussion";

export const DoctorMemberCard = ({member}) => {
  const dispatch = useDispatch();
  const discussion = useSelector(state => state.discussions.discussion)
  const discussionType = idx(discussion, _ =>_.attributes.type)
  const members = useSelector(state => idx(state, _ => _.chatMembers.list.data));
  const memberId = idx(member, _ => _.attributes.doctor.doctor_id)
  const isAdmin = (member?.attributes?.role === "ADMIN");
  const myId = useSelector(state => state.auth.id)
  const isMe = (memberId === +myId)

  let adminId = null;

  //We go through all the members to get the chat admin id
  if (members) {
    members.forEach((member) => {
      if (member.attributes?.role === "ADMIN") {
        return adminId = member.attributes.doctor.doctor_id;
      }
    })
  }

  const adminRights = (adminId === +myId)

  const photo = idx(member, _ => _.attributes.doctor.photo);
  const first_name = idx(member, _ => _.attributes.doctor.first_name);
  const last_name = idx(member, _ => _.attributes.doctor.last_name);
  const city = idx(member, _ => _.attributes.doctor.workplace_city);
  const code_postal = idx(member, _ => _.attributes.doctor.workplace_code_postal);

  const gender = idx(member, _ => _.attributes.doctor.gender);
  const specialization_type = idx(member, _ => _.attributes.doctor.specialization_type);
  const specialization = idx(member, _ => _.attributes.doctor.specialization);
  const [showModal, setShowModal] = useState(false);

  const deleteMember = () => {
    dispatch(removeChatMember(discussion.id, member.attributes.doctor.user_id))
    setShowModal(false)
  }
  return (
    <>
      <Wrapper>
        <AvatarWrapper>
          {
            photo ? (
                <Avatar
                  spinner={{width: '63px', height: '63px'}}
                  src={photo.includes('/file/') ? `http://backend-application.owndoctor.eu${photo}` : photo}
                />
              ) :
              (<Default
                  spinner={{width: '63px', height: '63px'}}
                  style={{backgroundImage: `url(${DefaultAvatar(first_name, last_name, specialization_type)})`}}
                />
              )
          }
        </AvatarWrapper>
        <Info>
          <Name>
            {fullName(gender, first_name, last_name)}
          </Name>
          <Specialization>
            {specialization}
          </Specialization>
          <City>
            { city &&
              city + ' (' + code_postal + ')'
            }
          </City>
        </Info>
        { discussionType === DISCUSSION_TYPE__GROUP &&
        <ButtonWrapper>
          {isAdmin &&
            <AdminButton>
              <FormattedMessage id='staff.group.admin'/>
            </AdminButton>
          }
          {isMe && discussionType !== DISCUSSION_TYPE__INDIVIDUAL ?
              <StyledTextButtonDelete
                color='white'
                onClick={() => setShowModal(!showModal)}
              >
                <FormattedMessage id='staff.group.leave' />
              </StyledTextButtonDelete>
            : adminRights ?
              <StyledTextButtonDelete
                color='white'
                onClick={() => setShowModal(!showModal)}
              >
                <FormattedMessage id='global.delete' />
              </StyledTextButtonDelete>
            : ''
          }
          </ButtonWrapper>
        }

        <ConfirmDialog
          onClose={() => setShowModal(false)}
          onConfirm={deleteMember}
          open={showModal}
          confirmationMessage={isMe ? 'staff.groupe.leave.confirm' : 'staff.member.delete.confirm'}
        />
      </Wrapper>

    </>
  )
}

const Wrapper = styled.div`
  padding: 10px 40px;
  border-bottom: 1px solid ${Colors.borderGrey};
  display: flex;
`

const Avatar = styled(SecuredBckgImage)`
  min-width: 63px;
  width: 63px;
  height: 63px;
  border-radius: 100%;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  display: flex;
  align-items: center;
  justify-content: center;
  box-shadow: 0 4px 8px 0 rgba(6, 173, 255, 0.1);
`

const Default = styled.div`
  min-width: 63px;
  width: 63px;
  height: 63px;
  border-radius: 100%;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  box-shadow: 0 4px 8px 0 rgba(6, 173, 255, 0.1);
`

const AvatarWrapper = styled.div`
  & > p {
    margin: 16px 0 0;
    color: ${Colors.clearBlue};
    font-size: 24px;
    font-weight: bold;
    text-align: center;
  }
`

const Info = styled.div`
  flex-grow: 1;
  margin: 0 32px 0;
`

const Name = styled.div`
  font-weight: bold;
  word-break: break-word;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
`

const Specialization = styled.div`
  margin: 4px 0 0 0;
  font-size: 14px;
  color: ${Colors.dark70};
  font-weight: 400;
  word-break: break-word;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
`

const City = styled.div`
  margin: 4px 0 0 0;
  color: ${Colors.blueGrey};
  word-break: break-word;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
`

const ButtonWrapper = styled.div`
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: end;
`

const AdminButton = styled.div`
  height: 40px;
  border: 2px solid ${Colors.blueGrey};
  background-color: ${Colors.tagGreen};
  color: ${Colors.blueGrey};
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 5px;
  padding: 0 16px;
  font-family: Roboto, sans-serif;
  font-size: 14px;
  font-weight: bold;
`
import React, {useState ,useEffect} from 'react'
import PropTypes from 'prop-types'
import {
  branch,
  compose,
  lifecycle,
  renderComponent,
} from 'recompose'
import { connect } from 'react-redux'
import { Route, Redirect, Switch } from 'react-router-dom'
import styled, { css } from 'styled-components'
import queryString from 'query-string'
import idx from 'idx'
import { YesNoAdeliRPPS } from './Login/YesNoAdeliRPPS'
import { MailPasswordAndConfirmPassword } from './Login/MailPasswordAndConfirmPassword'
import { MailPasswordLogin } from './Login/MailPasswordLogin'
import { BottomBar } from './BottomBar'
import { Carrousel } from './Carrousel'
import { PasswordRecovery } from './PasswordRecovery'
import { TermsAndPolicy } from './TermsAndPolicy'
import {authByToken, redirectAfterLogin} from '../../redux/modules/auth'
import { userIsNotAuthenticated } from '../../utils/userIsAuthenticated'
import { Preloader } from '../common/Preloader'
import { PhoneConfirm } from './PhoneConfirm'
import { UserTypeSelect } from './UserTypeSelect'
import { Stepper } from './Stepper'
import { Colors, waterBlue } from "../../constants/colors";
import logo from "../../images/auth/long-logo-conex-sante.png";
import { FormattedMessage } from "react-intl";
import { Link } from "@mui/material";

const AuthComponent = ({ isLoggedIn,preRegistrated }) => {
  const path = window.location.pathname
  const conditionPathAlreadyAccount = path === `/auth/patient/registration-mode/classic-sign-in`  || path === `/auth/doctor/registration-mode/classic-sign-in`
  const role = localStorage?.getItem('userType')


return (
  <Rows>
    {isLoggedIn && <Redirect to='/auth/phone' />}
    {preRegistrated && <Redirect to='/panel/profile/fill' />}
    { !isLoggedIn &&
      <Layout1>
        <Carrousel/>
      </Layout1>
    }
    <Layout2
      isLoggedIn = { isLoggedIn }
    >
        {
          conditionPathAlreadyAccount
          &&
          <LinkStyled href={`/auth/${role}`} alt={'Retour au login de connexion'}>
            <FormattedMessage
              id='auth.question.still.registered.login.you'
            />
          </LinkStyled>
        }
      <Header>
        <Link href={`/auth`} alt={'Retour à l\'accueil'}>
          <Logo src={logo} />
        </Link>
      </Header>
      {
        isLoggedIn
        &&
        <Switch>
          <Route path='/auth/phone' component={Stepper}/>
        </Switch>
      }
      <Switch>
        <Route  path='/auth/:role/registration-mode/classic-sign-in' component={MailPasswordAndConfirmPassword}/>
        <Route  path='/auth/doctor/registration-mode' component={YesNoAdeliRPPS}/>
        <Route path='/auth/phone' component={PhoneConfirm}/>
        <Route path='/auth/password-recovery' component={PasswordRecovery} />
        <Route path='/auth/change-password' component={PasswordRecovery} />
        <Route path='/auth/terms-and-policy' component={TermsAndPolicy} />
        <Route  path='/auth/:role' component={MailPasswordLogin}/>
        <Route  path='/auth' component={UserTypeSelect} />
      </Switch>
      { !isLoggedIn &&
          <BottomBar/>
      }
    </Layout2>

  </Rows>
)
}

AuthComponent.propTypes = {
  isLoggedIn: PropTypes.bool.isRequired,
  preRegistrated: PropTypes.bool.isRequired
}

const enhance = compose(
  userIsNotAuthenticated,
  connect(state => ({
    authenticating: state.loading.authByToken,
    isLoggedIn: !!state.auth.id,
    preRegistrated: idx(state, _ => _.auth.attributes.status) === "PRE_REGISTRATED"
  }), { authByToken, redirectAfterLogin }),
  lifecycle({
    componentDidMount() {
      // TODO: change this
      if (idx(this, _ => _.props.location.pathname) !== '/auth/password-recovery/check') {
        const { token, type, redirect } = queryString.parse(this.props.location.search)

        this.props.authByToken({ token, type })
        this.props.redirectAfterLogin(redirect !== '/panel/2FA' ? redirect : '/')
      }
    },
  }),
  branch(
    props => props.authenticating,
    renderComponent(() => <Preloader />),
  ),
)

export const Auth = enhance(AuthComponent)

// -------------------------------CSS--------------------------------------------------------------

const Header = styled.div`
  margin-top: 10vh;
  text-shadow: 0 1px 2px ${waterBlue};
  font-family: Roboto, sans-serif;
  font-size: 32px;
  font-weight: bold;
  letter-spacing: 0.5px;
  text-align: center;
  color: white;
`

const Logo = styled.img`
  width: 400px;
  @media screen and (max-width: 400px) {
    width: 90vw;
  }
`

const LinkStyled = styled(Link)`
    height : 10px !important;
    width : 100%;
    display: flex;
    justify-content: flex-end;
    align-items center;
    margin : 15px 40px 0 0;
    text-decoration : none;
    color: ${Colors.bluePurple};
    font-family: Roboto;
    font-weight: 500;
    font-size: 1.05em;
`

const Layout2 = styled.div`
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
    @media only screen and (max-width: 1600px) {
      width: 98vw;
    }
  ${({isLoggedIn}) => (isLoggedIn && css`
      width: 98vw;
      justify-content: start;
  `)}
`

const Layout1 = styled.div`
  background-color: #9E86E7;
  display: flex;
  justify-content: center;
  align-items: center;
  @media only screen and (max-width: 1600px) {
      display: none;
    }

`

const Rows = styled.div`
  height: 100vh;
  display: grid;
  grid-template-columns: 50% 50%;
  @media only screen and (max-width: 1600px) {
    grid-template-columns: 100%;
  }
  
`
import React from 'react';
import PropTypes from 'prop-types';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import { FormattedMessage } from 'react-intl';
import Grid from '@mui/material/Grid';
import { connect } from 'react-redux'
import { compose, mapProps } from 'recompose';
import idx from 'idx';
import { fetchRequestCSV } from '../../../../redux/modules/content';
import styled from "styled-components";

export const ModalRequestCsvComponent = (props) => {
  const {
    open,
    handleClose,
    onSubmit,
  } = props;

  return (
    <div>
      <Dialog
        open={open}
        onClose={() => handleClose('requestCsv', false)}
        aria-labelledby='scroll-dialog-title'
        aria-describedby='scroll-dialog-description'
      >
        <DialogTitle id='scroll-dialog-title'>
          <Typography variant='p' align='center' component='span'>
            <FormattedMessage id='gdpr.usage.request.csv.title' />
          </Typography>
        </DialogTitle>
        <DialogContent>
          <DialogContentText
            id='scroll-dialog-description'
            tabIndex={-1}
          >
            <FormattedMessage id='gdpr.modal.request.csv' />
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Grid justifyContent='center' direction='row' container>
            <ButtonStyled onClick={onSubmit} color='primary'>
              <FormattedMessage id='global.send' />
            </ButtonStyled>
            <ButtonStyled onClick={() => handleClose('requestCsv', false)}>
              <FormattedMessage id='global.cancel' />
            </ButtonStyled>
          </Grid>
        </DialogActions>
      </Dialog>
    </div>
  );
};

ModalRequestCsvComponent.propTypes = {
  open: PropTypes.bool.isRequired,
  handleClose: PropTypes.func.isRequired,
  onSubmit: PropTypes.func.isRequired,
};

const enhance = compose(
  connect(
    state => ({
      requestData: idx(state, _ => _.content.requestDataCsv),
    }),
    { fetchRequestCSV },
  ),
  mapProps(props => ({
    ...props,
    onSubmit: () => props.fetchRequestCSV()
      .then(() => {
        props.handleClose('requestCsv', false)
        props.openSuccessModal()
      }),
  })),
);

export const ModalRequestCSV = enhance(ModalRequestCsvComponent);

const ButtonStyled = styled(Button)`
  text-transform: none !important;
  font-size: 14px;
`

import React, {useEffect, useState} from 'react'
import styled from 'styled-components'
import { Paper } from 'common/UiKit/Paper'
import {useDispatch, useSelector} from 'react-redux';
import {Dialog, DialogContent, DialogTitle} from "@material-ui/core";
import CloseIcon from "@mui/icons-material/Close";
import Button from "@mui/material/Button";
import {FormattedMessage} from "react-intl";
import {addDoctorSkill, createDoctorSkill, fetchDoctorSkill} from "../../../../../redux/modules/doctors";
import {Search} from "../../../../common/Search";
import idx from "idx";
import get from "lodash/get";
import InfoIcon from '@mui/icons-material/Info';
import DeleteIcon from '@mui/icons-material/Delete';
import {Colors} from "../../../../../constants/colors";

const PaperStyled = styled(Paper)`
  padding: 10px 24px;
`

const Line = styled.div`
  padding: 8.5px 0;
  display: flex;
  opacity: 0.87;
  text-transform: capitalize;
`

const SkillsBlock = styled.div`
  display: flex;
  max-width: 100%;
  flex-wrap: wrap;
`

const Skills = styled.div`
  background-color: ${Colors.purpleGrey};
  padding: 3px 16px;
  border-radius: 11px;
  margin: 4px 7px 4px 7px;
  color: #5D5577;
  word-break: initial;
  display: inline-flex;
  justify-content: center;
  text-align: center;
`

const CloseDialog = styled(CloseIcon)`
    position: absolute;
    top: 10px;
    right: 10px;
    cursor: pointer;
`
const Title = styled.div`
  font-size: 20px;
  font-weight: 500;
  line-height: 1.55;
  margin-right: 20px;
`

const StyledDivCenter = styled.div`
  text-align:center;
  margin-top:1%;
`

const InfoIconStyled = styled(InfoIcon)`
  padding-top:22px;
`

const MessageRequis = styled.div`
height: auto;
padding: 11px 18px;
word-wrap: break-word;
line-height: 1.5;
color: #000000;
background-color: #d8d8d8;
border-radius: 5px;
display: grid;
grid-template-rows: 100%;
grid-template-columns: 5% 95%;
justify-self: center;
text-align: center;
font-size: 15px;
margin: auto;
`

const DivLeft = styled.div`
  text-align:left;
`

const DivResult = styled.div`
  border: 2px solid #999da8;
`

const DivSearchStyled = styled.div`
  box-shadow: 0px 1px 2px !important;
`

const ButtonResult = styled(Button)`
  text-transform:initial;
  color:black;
  min-width: 0px !important;
  font-size:15px;
`

const SpanStyledSearch = styled.span`
  text-decoration:underline;
  color:#6633FF;
`

const DialogStyled = styled(Dialog)`
  backdrop-filter: blur(3px);
  & > * {
      margin : auto;
      font-size : 20px;
  }
`

const SpanError = styled.span`
  color:red;
  font-size:15px;
`
const LinkStyled = styled.a`
  text-decoration:underline;
  cursor:pointer;
`

export const SkillsDoctor = () => {
  const skillNames = useSelector(state => state.auth.relationships.skill.data);
  const [ skillToShow, setSkillToShow] = useState([])
  const [open, setOpen] = useState(false);
  const [search, setSearch] = useState("");
  const [skillList, setSkillList] = useState([])
  const dispatch = useDispatch();
  const authId = useSelector(state => state.auth.id);
  const [listSkillToAdd, setListSkillToAdd] = useState([]);
  const [showErrorMessage, setShowErrorMessage] = useState(false);

  useEffect(() => {
    const initArray = []
    skillNames.forEach(el =>{
      initArray.unshift(el.id.toString())
    })
    setListSkillToAdd(initArray)
    setSkillToShow(skillNames)
  }, [])

  const createSkill = (skill) => {
    if(skill.attributes.id == ""){
      dispatch(createDoctorSkill(search))
          .then((data) => {
            addSkill(data.payload.data)
          })
          .catch((errors) => {
            console.log(errors)
          })
    }
    else{
      addSkill(skill)
    }
  }

  const addSkill = (skill) => {
    const newSkillToAdd = [skill.id.toString()]
    const concatArray  = listSkillToAdd.concat(newSkillToAdd)
    dispatch(addDoctorSkill(concatArray,authId))
        .then((data) => {
          if(data.type == "ADD_DOCTOR_SKILL_FAILURE"){
            const error = idx(data, _ => _.payload.response.data.data.trace[0].message)
            if(error){
              setShowErrorMessage(true)
            }
          }
          else{
            skillToShow.push(skill)
            setSkillToShow(skillToShow);
            setSearch("")
            setListSkillToAdd(concatArray);
          }
        })
        .catch((errors) => {
          console.log(errors)
        })
  }

  const deleteSkill = (idSkill) => {
    listSkillToAdd.splice(listSkillToAdd.indexOf(idSkill.toString()),1)
    setListSkillToAdd(listSkillToAdd);
    dispatch(addDoctorSkill(listSkillToAdd,authId))
        .then((data) => {
          setSkillToShow(skillToShow.filter(function(obj) {
            return obj.id !== idSkill.toString()
          }));
        })
        .catch((errors) => {
          console.log(errors)
        })

  }

  const onSearchAdd = (value) => {
      setSearch(value)
      dispatch(fetchDoctorSkill(value))
          .then((data) => {
            const skills = idx(data, _ => _.payload.data)
            const newSkill = [{
              attributes:{
                id:"",
                name:'Créer "'+value+'"'
              }
            }]
            const arraySkill = newSkill.concat(skills)
            setSkillList(arraySkill)
          })
          .catch((errors) => {
            console.log(errors)
          })
  }

  const handleClose = () => {
    setOpen(false)
  };
  return (
    <PaperStyled
      heading='profile.skills'
      onModifyButtonClick={open => setOpen(true)}
    >
      {skillToShow && skillToShow.length ? (
        <Line>
          <SkillsBlock>
            {skillToShow.map(el => (
              <Skills key={el.id}>
                {el.attributes.name}
              </Skills>
            ))}
          </SkillsBlock>
        </Line>
      ) :
      <span>
       <FormattedMessage id='skill.none' />
        <LinkStyled onClick={open => setOpen(true)}> <FormattedMessage id='skill.click' /> </LinkStyled>
      </span>}
      <DialogStyled open={open}
              maxWidth='sm'
              fullWidth
              onClose={handleClose}
                    PaperProps={{
                      style: {
                        borderRadius: 30,
                        border: '1px solid #6633FF',
                      }
                    }}
                    BackdropProps={{
                      style: {
                        opacity: 0,
                      }
                    }}>
        <CloseDialog onClick={() => handleClose()} />
        <DialogTitle
            style={{textAlign:"center"}}>
          <Title>
            <FormattedMessage id='skill.title' />
            <br/>
          </Title>
        </DialogTitle>
        <DialogContent>
          <StyledDivCenter>
            <MessageRequis>
              <InfoIconStyled></InfoIconStyled>
              <FormattedMessage id='skill.information'/>
            </MessageRequis>
            <DivSearchStyled>
              <Search
                  onSearch={value => onSearchAdd(value)}
                  placeholder='skill.placeholder'
                  value={search}
              />
            </DivSearchStyled>
            {
              (showErrorMessage || skillToShow.length == 10) && (
                    <SpanError>
                      <FormattedMessage id='skill.error'/>
                    </SpanError>
                )
            }
            {
              skillList && skillList.length > 0 && search.length > 0 ?
                  <DivResult>
                    {skillList.map((skillShow) => {
                    const name = get (skillShow,"attributes.name","");
                    const nameLower = name.toLowerCase();
                    const searchLower = search.toLowerCase();
                    const stringBeforeLower = nameLower.substring(0, nameLower.indexOf(searchLower));
                    const length = nameLower.indexOf(searchLower)+search.length;
                    const stringBefore = name.substring(0,stringBeforeLower.length);
                    const stringAfter = name.substring(length);
                      return (
                        <DivLeft>
                          <ButtonResult onClick={ () =>createSkill(skillShow)} >
                            {
                              stringBefore && stringBefore.length > 0 && (
                                    <span>{stringBefore.replace(/ /g, "\u00A0")}</span>
                              )
                            }
                            <SpanStyledSearch>{search}</SpanStyledSearch>
                            {
                              stringAfter && stringAfter.length > 0 && (
                                    <span>{stringAfter.replace(/ /g, "\u00A0")}</span>
                              )
                            }
                          </ButtonResult>

                        </DivLeft>

                    )
                    })}
                  </DivResult> : ""
            }
            <Line>
              {
                  search.length == 0 ?
                      <SkillsBlock>
                        {skillToShow.map(el => (
                            <Skills key={el.id}>
                              {el.attributes.name}
                              <DeleteIcon onClick={() =>deleteSkill(el.id)}></DeleteIcon>
                            </Skills>
                        ))}
                      </SkillsBlock> :""
              }

            </Line>
          </StyledDivCenter>
        </DialogContent>
      </DialogStyled>
    </PaperStyled>
  ) 
}
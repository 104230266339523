import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import { Paper } from 'common/UiKit/Paper'
import { FormattedHTMLMessage, FormattedMessage } from 'react-intl'
import { Page } from '../Page'

const PaperStyled = styled(Paper)`
  padding: 24px;
`

const Heading = styled.h1`
  margin: 0;
  font-size: 24px;
  font-weight: bold;
  opacity: 0.8;
`

const Text = styled.div`
  line-height: 1.63;
  opacity: 0.6;
  white-space: pre-line;
`

export const TypographyPage = ({
  label,
  text,
  onBackIconClick,
}) => (
  <Page label={label} onBackIconClick={onBackIconClick}>
    <PaperStyled>
      <Heading>
        <FormattedMessage id={label} defaultMessage={label} />
      </Heading>
      <Text>
        <FormattedHTMLMessage id={text} />
      </Text>
    </PaperStyled>
  </Page>
)

TypographyPage.propTypes = {
  label: PropTypes.string.isRequired,
  text: PropTypes.string.isRequired,
  onBackIconClick: PropTypes.func.isRequired,
}

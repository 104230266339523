import React, {useState} from 'react';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import { FormattedMessage } from 'react-intl';
import styled from 'styled-components'
import {Colors} from "../../../../constants/colors";
import ClearIcon from '@mui/icons-material/Clear';
import {InviteDatePicker} from "../../../Panel/Doctor/Concilium/InviteDatePicker";
import { Form, Field } from 'react-final-form'
import {SlotIsFree} from "../../../Panel/Doctor/Concilium/CreateForm";
import {StyledTextButton} from "../../UiKit/TextButton";
import moment from 'moment-timezone'

const Title = styled(DialogTitle)`
    color: #585C65;
    font-size: 2em;
    font-weight: 500;
    margin: 0 !important;
    display: flex;
    justify-content: center;
`

const DescriptionText = styled.div`
  font-size: 16px;
  line-height: 1.5;
  color: ${Colors.black};
  margin-bottom: 20px;
`

const StyledContent = styled(DialogContent)`
  padding-bottom: 0px !important;
`

const StyledClearIcon = styled(ClearIcon)`
    cursor: pointer;
`

const StyledForm = styled.form`
  & > div {
    margin-bottom: 16px;
  }
`

export const NewSlotModal = ({open, handleClose, handleSubmit, proposedDate, doctorId}) => {

  const [displayError, setDisplayError] = useState(false)

  return (
    <Dialog
      open={open}
      onClose={handleClose}
      aria-labelledby='alert-dialog-title'
      aria-describedby='alert-dialog-description'
      PaperProps={{
        style: {maxWidth: '550px', minWidth: '320px'}
      }}
    >
      <Form
        onSubmit={handleSubmit}
        subscription={{submitting: true, pristine: true}}
        initialValues={{proposedDate}}
        render={({handleSubmit, submitting}) => (
          <StyledForm onSubmit={handleSubmit}>
            <Title id='alert-dialog-title' style={{marginTop: '16px', textAlign: 'center'}}>
              <div style={{margin: 'auto'}}><FormattedMessage id='consultation.propose.new.slot'/></div>
              <StyledClearIcon onClick={() => handleClose()}/>
            </Title>
            <StyledContent>
              <DialogContentText id='alert-dialog-description'>
                <DescriptionText>
                  <FormattedMessage id='consultation.propose.new.slot.description'/>
                </DescriptionText>
                <Field
                  name='proposedDate'
                  validate={(value) => value === moment(proposedDate).format('YYYY-MM-DD HH:mm:ss') ? <FormattedMessage id='consultation.propose.new.slot.error' /> : SlotIsFree(value, doctorId)}
                  component={InviteDatePicker}
                  displayError={displayError}
                  setDisplayError={setDisplayError}
                  validateFields={[]}
                />
              </DialogContentText>
            </StyledContent>
            <DialogActions style={{ justifyContent: "space-around", padding: "8px 24px 24px"}}>
              <StyledTextButton
                onClick={() => {
                  setDisplayError(true)
                  handleSubmit()}
                }
                autoFocus
                style={{width: '100%'}}>
                <FormattedMessage id='global.send'/>
              </StyledTextButton>
            </DialogActions>
          </StyledForm>
        )}
      />
    </Dialog>
  )
}

import React from 'react'
import { FormattedMessage } from 'react-intl'
import styled from 'styled-components'
import DoneIcon from '@mui/icons-material/Done'
import LinearProgress from '@mui/material/LinearProgress'

import { yellowMember } from '../../../../constants/colors'

export const StatusMission = ({ data, onClick, modal }) => {
  const isDone = data.missionTotal <= data.missionValue

  const percent = Math.round((data.missionValue / data.missionTotal) * 100)
  const result = `${Math.min(data.missionValue, data.missionTotal)}/${data.missionTotal}`

  return (
    <div>
      <Content>
        <Flex modal={modal}>
          <FormattedMessage id={`status.missions.${data.mission}`} values={{ value: data.missionTotal }} />
          {!modal && <InfoButton onClick={() => onClick(data)}>i</InfoButton>}
          {isDone && <DoneWrapper><DoneIcon /></DoneWrapper>}
        </Flex>
        <Percent done={isDone}>
          {result}
        </Percent>
      </Content>
      <Progress value={percent} color={isDone ? 'secondary' : 'primary'} variant='determinate' />
      <PercentSubtitle>
        100%
      </PercentSubtitle>
    </div>
  )
}

const InfoButton = styled.button`
  outline: none;
  background: #21293e;
  opacity: 0.6;
  width: 16px;
  height: 16px;
  font-weight: bold;
  color: white;
  margin-left: 6px;
  border: none;
  border-radius: 50%;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  font-size: 12px;
  cursor: pointer;
  
  &:hover {
    background-color: ${yellowMember};
    opacity: 1;
  }
`

const Flex = styled.div`
  display: flex;
  align-items: center;
  ${({ modal }) => (modal ? `
    font-size: 22px; font-weight: bold;
  ` : '')}
`

const DoneWrapper = styled.div`
  margin-left: 4px;
  background-color: #21c5a9;
  width: 16px;
  height: 16px;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  color: white;
  border-radius: 50%;
  
  svg {
    width: 12px;
    height: 12px;
  }
  
`

const Content = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`

const Percent = styled.div`
  color: ${({ done }) => (done ? '#21c5a9' : '#209dff')};
  font-size: 22px;
  font-weight: bold;
}
`

const Progress = styled(LinearProgress)`
  && {
    margin-top: 12px;
    height: 4px;
  }
`

const PercentSubtitle = styled.div`
  text-align: right;
  font-weight: 500;
  font-size: 12px;
  color: rgba(33, 41, 62, 0.56);
  margin-top: 4px;
  opacity: 0.3;
`

import { compose, withProps, lifecycle } from 'recompose'
import { connect } from 'react-redux'
import idx from 'idx'
import { MedicalReport as MedicalReportComponent } from '../../../../Doctor/Consultations/Session/Menu/MedicalReportComponent'
import { history } from '../../../../../../utils/history'
import { checkMedicineCompabilityWithProfile, clearCheckedMedicines } from '../../../../../../redux/modules/medicines'
import { fetchConsultation } from '../../../../../../redux/modules/consultations'

const enhance = compose(
  connect(
    (state, props) => ({
      consultation: idx(state, _ => _.consultations.consultation),
      downloadLink: idx(state, _ => _
        .consultations.consultation.relationships.medicalReport.data
        .find(item => item.attributes.doctor === +props.match.params.id).attributes.pdf.report),
      checkedMedicinesWithProfile: idx(state, _ => _.medicines.checkedWithProfile),
      loading: state.loading.medicinesCompability,
    }),
    {
      clearCheckedMedicines,
      onCheckCompabilityButtonClick: checkMedicineCompabilityWithProfile,
      fetchConsultation,
    },
  ),
  lifecycle({
    componentDidMount() {
      if (!this.props.downloadLink) {
        this.props.fetchConsultation(this.props.consultation.id)
      }
    },
    componentWillUnmount() {
      this.props.clearCheckedMedicines()
    },
  }),
  withProps(props => ({
    onCloseIconClick: () => history.push('/panel/consultations/session'),
    onDownloadIconClick: () => window.open(props.downloadLink),
    doctorId: props.match.params.id,
    isPatient: true,
  })),
)

export const MedicalReport = enhance(MedicalReportComponent)

import React, {useEffect, useState} from 'react'
import {EditPage} from "../../../common/UiKit/EditPage";
import {history} from "../../../../utils/history";
import styled from "styled-components";
import { Paper } from 'common/UiKit/Paper';
import {useDispatch, useSelector} from 'react-redux'
import {fetchMedicalOfficeSoftwares} from "../../../../redux/modules/medicalOfficeSoftwares";
import {FormattedMessage} from "react-intl";
import MenuItem from "@mui/material/MenuItem";
import Select from "@mui/material/Select";
import idx from "idx";
import {changeMedicalSoftwareSetting} from "../../../../redux/modules/settings";

export const Lgc = () => {
  const dispatch = useDispatch();
  const userSettingSoftware = useSelector(state => idx(state, _ => _.settings.values.medical_office_software))
  const softwares = useSelector(state => idx(state, _ => _.medicalOfficeSoftwares.list))
  const [selectedSoftware, setSelectedSoftware] = useState(userSettingSoftware);

  const MenuProps = {
    disableScrollLock: true,
    PaperProps: {
      style: {
        maxHeight: 'none',
        width: 400
      },
    },
  }

  useEffect(() => {
    dispatch(fetchMedicalOfficeSoftwares())
  }, [])

  const updateMedicalSoftware = () => {
    dispatch(changeMedicalSoftwareSetting(selectedSoftware.toString()))
      .then(() => {
        history.goBack();
      })
      .catch((e) =>{
        console.log(e)
        history.goBack()
      })
  }

  return (
    <EditPage
      label='profile.settings.lgc'
      buttonText='global.save.language'
      onBackIconClick={() => {history.goBack()}}
      onButtonClick={updateMedicalSoftware}
      background
    >
      <Wrapper>
        <PaperStyled heading="profile.settings.lgc">
          <Line>
            <StyledHead>
              <FormattedMessage id="consultation.select.medical.software"/>
            </StyledHead>
            <DivSelect>
              <SelectStyled
                id='medical_office_software'
                value={selectedSoftware}
                MenuProps={MenuProps}
                onChange={e => setSelectedSoftware(e.target.value)}
              >
                { softwares &&
                  softwares.map(software => {
                    return (
                      <MenuItem
                        key={software.attributes.software_id}
                        value={software.attributes.software_id}
                      >
                        {software.attributes.name}
                      </MenuItem>
                    )
                  })
                }
              </SelectStyled>
            </DivSelect>
          </Line>
        </PaperStyled>
      </Wrapper>
    </EditPage>
  )
}

const Wrapper = styled.div`
  margin-top: 24px;
`

const PaperStyled = styled(Paper)`
  margin-top: 20px;
  padding: 15px 25px 15px 25px; 
`

const Line = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  white-space: pre-line;
  padding: 20px 0;
`

const DivSelect = styled.div`
  padding-left: 20px;
  width: 70%;
`

const SelectStyled = styled(Select)`
  height: 50px;
  width: 100%;
`

const StyledHead = styled.div`
  font-weight: bold;
`
import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components';
import { Subject } from '@mui/icons-material';
import TagsList from './TagsList';
import {Paper} from "./UiKit/Paper";
import {Colors} from "../../constants/colors";
import {TabsDefinition} from "../Public/structure/TabsDefinition";

const TaggedDescription = ({ title, descriptionText, tags, panels }) => {
  return (
    <Wrapper>
      <IconWrapper>
        <Subject />
      </IconWrapper>
      <InnerWrapper>
        {
          !!title && (
            <Title>
              { title }
            </Title>
          )
        }
        <Paper style={{ padding: '10px' }}>
          <div>
            { descriptionText }
          </div>
          {
            (!panels || panels.length <= 0) && (
              <TagsList tags={tags} />
            ) || (
              <TabsDefinition tabs={tags} panels={panels} />
            )
          }
        </Paper>
      </InnerWrapper>
    </Wrapper>
  )
}

TaggedDescription.defaultProps = {
  title: '',
  descriptionText: '',
  tags: [],
  panels: [],
  displayBorder: true,
}

TaggedDescription.propTypes = {
  title: PropTypes.string,
  descriptionText: PropTypes.string.isRequired,
  tags: PropTypes.array.isRequired,
  panels: PropTypes.array,
  displayBorder: PropTypes.bool.isRequired,
}

export default TaggedDescription

// --------------------------------- Styles CSS -------------------------------------

const Wrapper = styled.div`
  color: ${Colors.conexGrey};
  margin-bottom: 25px;
  padding: 10px;
`

const IconWrapper = styled.div`
  float: left;
`

const InnerWrapper = styled.div`
  margin: 0 40px;
`

const Title = styled.h3`
  margin: 0 0 10px 0;
`

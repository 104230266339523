import React, {
  useEffect, useRef, useState, useCallback, 
} from 'react'
import PropTypes from 'prop-types'
import { useDispatch, useSelector } from 'react-redux'
import styled from 'styled-components'
import idx from 'idx'
import { Waypoint } from 'react-waypoint'
import Dropzone from 'react-dropzone'
import { FormattedMessage } from 'react-intl'
import get from 'lodash/get';
import { socket } from '../../../services/socket'
import { InputMessage } from './InputMessage'
import { Message } from './Message'
import {
  uploadFile, clearNotificationsAction, loadLastMessages, connectChat, 
} from '../../../redux/modules/chats'
import { CONSULTATION_TYPES } from '../../../constants/consultation'
import { SomethingWrongMessage } from './SomethingWrongMessage';
import LockIcon from '@mui/icons-material/Lock';
import {
  fetchConsultation,
  transferConsultation,
  transferConsultationFailure
} from "../../../redux/modules/consultations";
import {isConciliumCheck} from "../../../helpers/consultations";
import Grid from '@mui/material/Grid';
import {DISCUSSION_TYPE__INDIVIDUAL} from "../../../constants/discussion";
import {Box} from "@mui/material";
import Skeleton from "@mui/material/Skeleton";
import {CreatePatient} from "../Doctor/Concilium/CreatePatient";
import {Colors} from "../../../constants/colors";
import Button from "../../common/UiKit/Button";
import {Dialog} from "../../common/UiKit/Dialog";
import {Reply} from "@mui/icons-material";
import LoadingButton from "../../common/UiKit/LoadingButton";
import {TextButton} from "../../common/UiKit/TextButton";
import {UPLOAD_FILE_MAX_SIZE} from "../../../constants/uploads";
import {AVAILABLE_EXTENSIONS_STR} from "../../../constants/extensions";
import {hasUnreadMessage} from "../../../redux/modules/discussions";

export const Chat = ({ isDiscussion, isTERRSS }) => {
  const dispatch = useDispatch()
  const [lastReload, setLastReload] = useState(0);
  const [fileLoadingProgress, setLoadingProgress] = useState(0)
  const [firstMessageId, setFirstMessageId] = useState(null);
  const topLoadHistory = useRef(null);
  const doctorId = useSelector(state => idx(state, _ => _
    .consultations.consultation.relationships.doctor.data.attributes.user_id))

  const consultation = useSelector(state => state.consultations.consultation)
  const discussion = useSelector(state => state.discussions.discussion)
  const myId = useSelector(state => state.auth.attributes.user_id);
  const chat = useSelector(state => state.chats[isDiscussion ? discussion.attributes.id : consultation.id])
  const chatIdReal = get(chat, 'id', 0);
  const isDoctor = useSelector(state => state.auth.type === 'Doctor')
  const isRequester = useSelector(state => state.auth.id !== state.consultations?.consultation?.relationships?.doctor?.data?.id)
  const isConcilium = isConciliumCheck(consultation.attributes?.type)
  let patientId;
  let virtual;
  let hasEdit;
  if (discussion === null) {
    patientId = consultation.relationships.patient.data.id;
    virtual = consultation.relationships.patient.data.attributes.virtual;
    hasEdit = consultation.relationships.patient.data.attributes.hasEdit;
  }
  const [open, setOpen] = useState(false);

  const messages = [...((chat && chat.lastMessages) ? chat.lastMessages : [])]
    .sort((a, b) => {
      let aDate
      let bDate
      if (a.attributes.date && b.attributes.date) {
        aDate = Date.parse(a.attributes.date)
        bDate = Date.parse(b.attributes.date)
      } else {
        aDate = Number.parseInt(a.id)
        bDate = Number.parseInt(b.id)
      }
      if (isNaN(aDate) || isNaN(bDate)) {
        return a.id > b.id ? 1 : -1
      }
      return aDate - bDate
    });
  const userId = localStorage.getItem('userId') || 0
  const lastMessageId = idx(messages, _ => _[messages.length - 1].id)
  const status = idx(consultation, _ => _.attributes.status)
  const errors = useSelector(state => idx(state, _ => _.notifications))
  const isError = errors && errors.type === 'error' && errors.title;
  const mustReload = chat && chat.mustReload;
  const [errorFileTooBig, setErrorFileTooBig] = useState(null)
  const [messagePushed, setMessagePushed] = useState('')
  const isTCA = consultation.attributes?.type === CONSULTATION_TYPES.TCA
  const abortControllerReference = useRef();
  const [nbMessages, setNbMessages] = useState(20);

  // Teleexpertise transfert
  const transferLoading = useSelector(state => state.loading.consultationTransfer)
  const transferError = useSelector(state => state.consultations.transfer.error)
  const [transferDialogOpen, setTransferDialogOpen] = useState(false)
  const handleTransferConfirm = () => {
    dispatch(transferConsultation(consultation.id))
      .then(id => {
        if (id) {
          const location = window.location
          window.location.href = `${location.protocol}//${location.host}/panel/consultations/${id}`
        }
      })
  }

  const scrollToTop = useCallback(() => {
    if (topLoadHistory.current) {
      topLoadHistory.current.scrollTo({ top: 300 });
    }
  }, [])
    
  const chatConnectWait = async () => {
    await dispatch(connectChat(
      isDiscussion ? discussion.id : consultation.id,
      isDiscussion ? 'discussion' : 'consultation',
    ))

    if (chatIdReal) {
      await dispatch(loadLastMessages(chatIdReal, null, nbMessages));
    }
  }

  useEffect(() => {
    chatConnectWait().then();
    dispatch(clearNotificationsAction());
    setTimeout(() => scrollToBottom(), 1000)
    setNbMessages(20);
    if (isDiscussion) {
      setTimeout(() => {
        dispatch(hasUnreadMessage())
      }, 1000)
    }
  }, [discussion?.id])

  useEffect(() => {
    if (mustReload && mustReload > lastReload && !!chatIdReal) {
      setLastReload(mustReload);
      if (isDiscussion) {
        dispatch(loadLastMessages(chatIdReal, null, nbMessages));
      } else {
        dispatch(fetchConsultation(consultation.id, null, true))
      }
    }
  }, [mustReload])

  useEffect(() => {
    if (chatIdReal) {
      setNbMessages(20);
      dispatch(loadLastMessages(chatIdReal,null, nbMessages, abortControllerReference));
    }
  }, [!!chatIdReal])

  useEffect(() => {
    if (messages && messages.length && chatIdReal) {
      const notMyIds = messages.filter((item) => {
        const my = idx(item, _ => _.attributes.sender) === +userId;
        const isNew = get(item, 'attributes.read', 0) === 0;
        return !my && isNew;
      })
        .map(el => get(el, 'attributes.id', false))
        .filter(el => el);
      if (notMyIds && notMyIds.length) {
        socket.markAsRead(chatIdReal, notMyIds);
      }
    }
  }, [chatIdReal, messages.length])

  useEffect(() => {
    scrollToBottom()
  }, [lastMessageId, errorFileTooBig])

  useEffect(() => {
    setTimeout(() => scrollToTop(), 1000)
  }, [firstMessageId])

  useEffect(() => {
    if (!transferDialogOpen) {
      dispatch(transferConsultationFailure(null))
    }
  }, [transferDialogOpen]);

  const messageEndRef = useRef(null);

  const scrollToBottom = () => {
    messageEndRef.current?.scrollIntoView({behavior: "smooth"})
  }

  if (!chat) {
    return       <Box   sx={{
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      width: "100%",
      height: "100%"
    }}>
      <Skeleton variant='animation' width='100%' height='90%' marginBottom ='5px'>
        <h1 />
      </Skeleton>
      <br/>
      <Skeleton variant='animation' width='80%' height='5%'>
        <h1 />
      </Skeleton>
    </Box>
  }

  const onDrop = async (files) => {
    if (!files.length) return
    await files.forEach(async (file) => {
      await dispatch(uploadFile({ file }, chatIdReal, setLoadingProgress, setErrorFileTooBig))
    })
  }

  return (
    <Wrapper>
      <Dialog
        open={transferDialogOpen}
        title='Transfert de téléexpertise'
        actions={(
          <>
            <Button
              variant='outlined'
              disabled={transferLoading}
              onClick={() => setTransferDialogOpen(false)}
            >
              <FormattedMessage id='global.cancel' />
            </Button>
            <LoadingButton
              disabled={transferLoading}
              loading={transferLoading}
              onClick={handleTransferConfirm}
            >
              Continuer
            </LoadingButton>
          </>
        )}
      >
        Vous êtes sur le point de transférer cette téléexpertise vers un nouveau médecin requis. Voulez-vous continuer ?
        {
          !!transferError && (
            <div style={{color: 'red'}}>{transferError}</div>
          )
        }
      </Dialog>
      <Grid style={GridStyle} container wrap={"nowrap"}>
        <Grid item xs={12}>
          <DropzoneComponent
            onDrop={onDrop}
            topLoadHistory={topLoadHistory}
            nbMessages={nbMessages}
            messages={messages}
            setNbMessages={setNbMessages}
            chatIdReal={chatIdReal}
            doctorId={doctorId}
            myId={myId}
            isConcilium={isConcilium}
            isTCA={isTCA}
            consultation={consultation}
            discussion={discussion}
            isRequester={isRequester}
            setTransferDialogOpen={setTransferDialogOpen}
            firstMessageId={firstMessageId}
            setFirstMessageId={setFirstMessageId}
            isError={isError}
            messageEndRef={messageEndRef}
            hasEdit={hasEdit}
            virtual={virtual}
            patientId={patientId}
            open={open}
            setOpen={setOpen}
            isDiscussion={isDiscussion}
            scrollToBottom={scrollToBottom}
            status={status}
            isTERESS={isTERRSS}
          />
        </Grid>
      </Grid>
      <Grid container>
        <Grid item xs={12}>
          {(((!isDoctor) && status !== 'completed' && status !== 'cancelled') || isDoctor ) && !isConcilium && (
            <>
              <InputMessage
                disabled={false}
                setMessagePushed={setMessagePushed}
                pushMessage={(message) => {
                  if (!message.trim().length) return
                  socket.sendTextMessage(chatIdReal, message)
                }}
                onFileSubmit={data => dispatch(uploadFile(data, chatIdReal, setLoadingProgress,setErrorFileTooBig))}
                fileLoadingProgress={fileLoadingProgress}
              />
              { errorFileTooBig ? <ErrorStyled> <FormattedMessage id='consultation.filesizetoobig' values={{
                size: UPLOAD_FILE_MAX_SIZE,
              }}/> </ErrorStyled> : '' }
            </>
          )}
          {
            isConcilium && status !== 'cancelled' && status !== 'refused' && (
              <>
                <InputMessage
                  disabled={false}
                  setMessagePushed={setMessagePushed}
                  pushMessage={(message) => {
                    if (!message.trim().length) return
                    socket.sendTextMessage(chatIdReal, message)
                  }}
                  onFileSubmit={data => dispatch(uploadFile(data, chatIdReal, setLoadingProgress, setErrorFileTooBig))}
                  fileLoadingProgress={fileLoadingProgress}
                />
                {errorFileTooBig ? <ErrorStyled> <FormattedMessage id='consultation.filesizetoobig' values={{
                  size: UPLOAD_FILE_MAX_SIZE,
                }}/> </ErrorStyled> : ''}
              </>
            )
          }
        </Grid>
      </Grid>

    </Wrapper>
  )
}

const DropzoneComponent = ({
  onDrop,
  topLoadHistory,
  nbMessages,
  messages,
  setNbMessages,
  chatIdReal,
  doctorId,
  myId,
  isConcilium,
  isTCA,
  consultation,
  discussion,
  isRequester,
  setTransferDialogOpen,
  firstMessageId,
  setFirstMessageId,
  isError,
  messageEndRef,
  hasEdit,
  virtual,
  patientId,
  open,
  setOpen,
  isDiscussion,
  scrollToBottom,
  status,
  isTERESS,
}) => {
  const dispatch = useDispatch()

  return (
    <Dropzone
      accept={AVAILABLE_EXTENSIONS_STR}
      multiple
      onDrop={onDrop}
    >
      {({ getRootProps }) => (
        <Content {...getRootProps()} ref={topLoadHistory}>
          { (nbMessages <= messages.length) &&
            <LoadMessagesButton onClick={ () => {
              setNbMessages(nbMessages + 20);
              dispatch(loadLastMessages(chatIdReal, null, nbMessages + 20));
            }}>
              <FormattedMessage id='discussion.load.messages'/>
            </LoadMessagesButton>
          }
          { doctorId === myId && isConcilium && (
            <MessageRequis>
              <Lock/>
              {isTCA ? <FormattedMessage id='consultation.chat.warningMessage.TCA'/> :
                <FormattedMessage id='consultation.chat.warningMessage'/>}
            </MessageRequis>
          )
          }
          {
              consultation.relationships?.doctor?.data?.attributes?.user_id !== myId && isConcilium && isTERESS && (
                  <MessageRequis>
                    <Lock/>
                    La téléexpertise a été prise en charge par le { consultation.relationships.doctor.data.attributes.full_name }.
                  </MessageRequis>
              )
          }
          {
            consultation?.attributes?.type === CONSULTATION_TYPES.TER
            && consultation.attributes.status !== 'completed'
            && consultation.attributes.status !== 'cancelled'
            && isRequester
            && (
              <MessageTransfert>
                Le médecin que vous avez sollicité ne répond pas assez rapidement ? Transférez votre demande à
                un autre requis :
                <div>
                  <Button onClick={() => setTransferDialogOpen(true)}>
                    <TransferIcon />
                    Transférer la téléexpertise
                  </Button>
                </div>
              </MessageTransfert>
            )
          }
          <Waypoint
            onEnter={(options) => {
              const messageIdTo = get(messages, '[0].id', false);
              if (options.previousPosition && messageIdTo && firstMessageId !== messageIdTo) {
                dispatch(loadLastMessages(chatIdReal, messageIdTo, nbMessages));
                setFirstMessageId(messageIdTo);
              }
            }}
          />
          {isError ? <SomethingWrongMessage />
            : messages.map(item => {
              return (
                <Message
                  messageObject={item}
                  key={item.id}
                  onLoad={scrollToBottom}
                  displaySenderName={(isDiscussion && discussion.attributes.type !== DISCUSSION_TYPE__INDIVIDUAL) || isTERESS}
                />
              )
            })
          }
          <div ref={messageEndRef} />
          { status === 'completed' && hasEdit && (
            <ReminderCPAM>
              <Lock/>
              <div onClick={() => setOpen(true)} style={{cursor: 'pointer', display: 'block'}}>
                <FormattedMessage id='consultation.reminder.cpam' values={{'strong': (...chunks) => (<u><strong>{chunks}</strong></u>)}}/>
              </div>
              { virtual && hasEdit && (
                <CreatePatient editPatientId={patientId} open={open} onClose={() => setOpen(false)} afterSubmit={() => window.location.reload()} modify={true}/>
              )}
            </ReminderCPAM>
          )}
        </Content>
      )}
    </Dropzone>
  )
}

Chat.defaultProps = {
  isDiscussion: undefined,
}

Chat.propTypes = {
  isDiscussion: PropTypes.bool,
}

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
`
const Content = styled.div`
  padding: 36px 27px 0;
  font-family: Roboto, sans-serif;
  flex-grow: 1;
  overflow: auto;

  & > * {
    margin-bottom: 16px;
  }
`
const ErrorStyled = styled.div`
  margin-left: 10px;
  color: red;
`


const MessageRequis = styled.div`
  height: auto;
  padding: 11px 18px;
  word-wrap: break-word;
  line-height: 1.5;
  color: #000000;
  background-color: #FFBD82;
  border-radius: 5px;
  display: grid;
  grid-template-rows: 100%;
  grid-template-columns: 5% 95%;
  justify-self: center;
  text-align: center;
  font-size: 15px;
  margin: auto;
  margin-bottom : 70px;
`

const MessageTransfert = styled.div`
  height: auto;
  padding: 11px 18px;
  word-wrap: break-word;
  line-height: 1.5;
  color: #000000;
  background-color: ${Colors.borderGrey};
  border-radius: 5px;
  display: grid;
  grid-row-gap: 10px;
  justify-self: center;
  text-align: center;
  font-size: 15px;
  margin: auto;
  margin-bottom : 70px;
`

const Lock = styled(LockIcon)`
align-self: center;
`
const GridStyle = {
  overflow: 'auto',
  maxWidth: '100%',
  height: '100%'
}

const ReminderCPAM = styled.div`
  height: auto;
  padding: 11px 18px;
  word-wrap: break-word;
  line-height: 1.5;
  color: #000000;
  background-color: #D8F8E9;
  border-radius: 5px;
  display: grid;
  grid-template-rows: 100%;
  grid-template-columns: 5% 95%;
  justify-self: center;
  text-align: center;
  font-size: 15px;
  margin-bottom : 70px;
`

const TransferIcon = styled(Reply)`
  transform: scale(-1, 1);
`

const LoadMessagesButton = styled(TextButton)`
  align-self: center;
  text-transform : none;
  font-weight: 500;
  font-size: 14px;
  display: block;
  margin: 10px auto 20px auto;
  height: 38px;
  width: 330px;
  && {
    background-color: ${Colors.bluePurple};
    border: 1px solid white;
    color: white;
  }
  &:hover{
    background-color: rgb(71, 35, 178);
    border: 1px solid white;
    color: white;
  }
`
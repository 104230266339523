import React from 'react'
import styled from 'styled-components'
import idx from 'idx'
import { Paper } from '../../../../common/UiKit/Paper'
import { DiplomasForm } from '../../../../common/DiplomasForm';

const PaperStyled = styled(Paper)`
  padding: 0;
`

export const Diplomas = ({
  diplomas,
  changeButtonPath,
  onPaperClick,
}) => (
  <PaperStyled
    changeButtonPath={changeButtonPath}
    heading='profile.identity'
  >
    <DiplomasForm
      diplomas={diplomas.map(item => (item.attributes
        ? {
          id: item.id,
          file: item.attributes.url,
          extension: idx(item, _ => _.attributes.extension),
        }
        : { id: '0', file: '' }
      ))}
      id='profile_diplomas'
      afterSubmit={() => null}
      onSubmit={() => null}
      disableEdit
      onPaperClick={onPaperClick}
    />
  </PaperStyled>
)

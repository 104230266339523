import React from 'react'
import {Route, withRouter} from 'react-router-dom'
import {Profile} from './Profile'
import {HealthRecords} from './HealthRecords'
import {Doctors} from './Doctors'
import {Consultations} from './Consultations'
import {Settings} from "../Doctor/Settings";
import {PersonalData} from "../Doctor/Settings/PersonalData";
import {Version} from "../Doctor/Settings/Version";

export const Patient = withRouter(() => {
    const TwoFA =  parseInt(localStorage.getItem('2fa'));

    return (
      <>
        <Route path='/panel/doctors' component={Doctors}/>
        <Route path='/panel/settings' component={Settings}/>
        <Route path='/panel/personal/data' component={PersonalData}/>
        <Route path='/panel/version' component={Version}/>
        <Route path='/panel/consultations' component={Consultations}/>
        <Route path='/panel/health-records' component={HealthRecords}/>
        <Route path='/panel/profile' component={Profile}/>
      </>
    )
})
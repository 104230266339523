import React, {useEffect, useState} from "react";
import styled from "styled-components";
import {CircularProgress, Skeleton} from "@mui/material";
import PropTypes from "prop-types";

const CustomLoaderCircular = ({isShow, maxSecondAllowed = 20, size = 20}) => {
    const [seconds, setSeconds] = useState(0);

    useEffect(() => {
        const interval = setInterval(() => {
            setSeconds(seconds => seconds + 1);
        }, 1000);
        if(seconds > maxSecondAllowed || !isShow) clearInterval(interval);
        return () => clearInterval(interval);
    }, []);

    return (
        isShow & seconds < maxSecondAllowed
        ? <CircularProgress size={size}/>
        : <></>

    )
}

CustomLoaderCircular.propTypes = {
    isShow : PropTypes.bool,
    maxSecondAllowed : PropTypes.number,
    size : PropTypes.number | PropTypes.string,
}

export default  CustomLoaderCircular
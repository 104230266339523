import { handleActions, createAction } from 'redux-actions'
import { combineReducers } from 'redux'

export const setNotification = createAction('SET_NOTIFICATION')

const title = handleActions({
  [setNotification]: (state, action) => action.payload.title || action.payload,
}, '')

const type = handleActions({
  [setNotification]: (state, action) => action.payload.type || 'error',
}, 'error')

export const notifications = combineReducers({
  title,
  type,
})

import React, { useState } from 'react'
import {FormattedMessage} from "react-intl"
import moment from 'moment-timezone'
import styled, { css } from 'styled-components'
import {history} from "../../../../../utils/history";
import {TextButton} from "../../../../common/UiKit/TextButton";
import EventIcon from '@mui/icons-material/Event';
import {useSelector} from "react-redux";
import {BUTTON_COLORS} from "../../../../../constants/colors";
import {Colors} from "../../../../../constants/colors";

const Wrapper = styled.div`
	display: grid;
	grid-template-columns: repeat(7, 1fr);
	border: 1px solid ${Colors.textMuted};
	border-radius: 3px;
	grid-template-rows: auto
`

const Day = styled.div`
	height: 100%;
	display: flex;
	border-bottom: 1px solid ${Colors.textMuted};
	align-self: center;
	justify-content: center;
	align-content: center;
	margin: 5px 0px;
`

const DayTxt = styled.div`
	color: #000000;
	margin-right: 4px;
	font-family: 'Roboto';
	font-style: normal;
	font-weight: 500;
	align-self: center;
	justify-self: center;
	text-transform: capitalize;
`

const DayNumber = styled.div`
	color: #585C65;
	align-self: center;
	justify-self: center;
`

const Hour = styled.div`
  font-family: Roboto;
  height: 20px;
  font-size: 18px;
  font-weight: 400;
  color: #000000;
  display: flex;
  border-radius: 3px;
  padding: 5px 10px;
  cursor: pointer;
  justify-self: center;
  align-self: center;
  border: 1px solid #6633FF;
  &:hover{
	background-color: #6633FF;
	color: #FFFFFF;
	opacity: 0.4;
  }
  margin: 10px 0px;
  grid-column-start: ${props => (props.column)};
  grid-row-start: ${props => (props.row)};
  
  ${props => (props.isSelected && css`
	  background-color: #6633FF;
	  color: #FFFFFF;
	`)}
	
	  ${props => (props.row==2 && css`
		margin-top: 20px;
	`)}
		 ${props => (props.row==3 && css`
		margin-bottom: 20px;
	`)}
`

const TimeVoid = styled.div`
  width: 8px;
  height: 0px;
  border-top: 1px solid #6633FF;
  display: flex;
  justify-self: center;
  align-self: center;
  margin: 10px 0px;
  grid-column-start: ${props => (props.column)};
  grid-row-start: ${props => (props.row)};
		${props => (props.row==2 && css`
		margin-top: 20px;
	`)}
		 ${props => (props.row==3 && css`
		margin-bottom: 20px;
	`)}
`

const DayVoid = styled.div`
  width: 8px;
  height: 0px;
  border-top: 1px solid #6633FF;
  justify-self: center;
  align-self: center;
`

const TextButtonStyle = styled.div`
  display: flex;
  justify-content: center;

  button {
    text-transform: none;
	margin: 10px 0px 0px 0px;
	font-size: 14px;
	width: 320px;
	height: 40px;
    background-color: ${BUTTON_COLORS.purple};
    color: #fff;
  } 
  button:hover {
	background-color: ${BUTTON_COLORS.purpleHover};
	color: #fff;
  }
`

const CalendarIcon = styled(EventIcon)`
	margin-right: 10px;
`

const TextButtonAvailable = styled(TextButton)`
	z-index:auto;
`

const Error = styled.div`
  color: #EE3C5B;
  font-family: Roboto, Helvetica, Arial, sans-serif;
  font-style: normal;
  font-size: 12px;
  margin: 3px 14px 0px;
`

const normalizeDays = (availabilitiesList) => {
	let result = null;
	if (availabilitiesList) {
		result = Object.keys(availabilitiesList).slice(0, 6);
		let length = result.length;
		for (let i=length; i < 7; i++) {
			result[i]= "-";
		}
	}
	return result;
}

const normalizeTime = (availabilitiesList, day) => {
	let result = null;
	if (availabilitiesList) {
		if (day == "-") {
			result = ["-", "-"]
		} else {
			result = Object.keys(availabilitiesList[moment(day).format('YYYY-MM-DD')]).slice(0, 2)
			let length = result.length;
			for (let i = length; i < 2; i++) {
				result[i] = "-";
			}
		}
	}
	return result;
}

export const DoctorAvailabilities = ({ onDateClick, input, availabilitiesList, meta, isTCA =false}) => {

	const [selected, setSelected] = useState(input);
	const doctorId = useSelector(_ => _.doctors.doctor.id)
	const userType = useSelector(_ => _.auth.type)

	const onButtonClick = (id, proposedDate) => {
		const url = `/panel/concilium/create/${id}${proposedDate ? `?proposedDate=${proposedDate}` : ''}`

		return history.push(url)
	}

	const onClick = (day) => {

		if(selected === day) {
			setSelected(null)
			input.onChange(null)
		} else {
			setSelected(day)
			input.onChange(moment(day).format('YYYY-MM-DD HH:mm'))
			if (onDateClick) {
				onButtonClick(doctorId, moment(day).format('YYYY-MM-DD HH:mm'))
			}
		}
	}

	return (
		<>
			<Wrapper>
				{availabilitiesList && normalizeDays(availabilitiesList).map( (availability) => {
					return (
						availability == "-" ?
							<Day> <DayVoid/> </Day>
						:
							<Day>
								<DayTxt>
									<FormattedMessage id={moment(availability).format("ddd ")}/>
								</DayTxt>
								<DayNumber>
									<FormattedMessage id={moment(availability).format(" DD/MM ")}/>
								</DayNumber>
							</Day>
					)
				})}

				{ availabilitiesList && normalizeDays(availabilitiesList).map( (availability,dayIndex) =>
					normalizeTime(availabilitiesList, availability).map( (Time, TimeIndex) => {
						return(
							Time == "-" ?
								<TimeVoid column={dayIndex+1} row={TimeIndex+2}/>
							:
								<Hour
									column={dayIndex+1}
									row={TimeIndex+2}
									onClick={() => onClick(availabilitiesList[availability][Time].date_time)}
									isSelected={availabilitiesList[availability][Time].date_time == selected}
								>
									<FormattedMessage id={Time}/>
								</Hour> )
					}))
				}
			</Wrapper>
			<Error>
				{meta.touched && meta.error ? <div> {meta.error} </div> : <> </>}
			</Error>
			<TextButtonStyle>
				<TextButtonAvailable
					color='primary'
					onClick={() => {
						userType === 'Patient' ?
							history.push(`/panel/doctors/doctor/${doctorId}`)
							: isTCA ?
							history.push(`/panel/teleconsultation/doctor/${doctorId}`)
							:
							history.push(`/panel/concilium/doctor/${doctorId}`)}
					}
				>
					<CalendarIcon/>
					<FormattedMessage id='concilium.more.availabilities' />
				</TextButtonAvailable>
			</TextButtonStyle>
		</>
	)
}
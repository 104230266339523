import React from 'react';
import styled from 'styled-components';
import Button from '@mui/material/Button';
import { FormattedMessage } from 'react-intl';

const OopsMessage = styled.div`
  color: #959fb8;
  font-size: 20px;
  font-weight: 500;
  text-align: center;
  margin-bottom: 24px;
`
const StyledButtonReload = styled(Button)`
  && {
    width: 140px;
    font-size: 14px;
    margin: 0px auto;
    border-radius: 50px;
    height: 40px;
  }
`

const WrongMessageContainer = styled.div`
  display: flex;
  flex-direction: column;
`
const Wrapper = styled.div`
  margin-bottom: 16px;
  margin: 0px auto;
  margin-top: 27vh;
`

const reload = () => window && window.location.reload(false);

export const SomethingWrongMessage = () => (
  <Wrapper>
    <WrongMessageContainer>
      <OopsMessage>
        <FormattedMessage id='error.oopschat' />
      </OopsMessage>
      <StyledButtonReload onClick={reload} variant='contained' color='primary'>
        <FormattedMessage id='error.reloadpage' />
      </StyledButtonReload>
    </WrongMessageContainer>
  </Wrapper>
)

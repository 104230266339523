import React from 'react'
import {Route, Switch,withRouter} from 'react-router-dom'
import PropTypes from 'prop-types'
import { compose } from 'recompose'
import {connect, useSelector} from 'react-redux'
import idx from 'idx'
import { DiplomasGallery } from '../../common/DiplomasGallery'
import { Consultations } from './Consultations'
import { Profile } from './Profile'
import { FillForm } from './Profile/FillForm'
import { Edit } from './Profile/Edit'
import { Settings } from './Settings'
import { Version } from './Settings/Version'
import { PersonalData } from './Settings/PersonalData'
import { HelpPage } from '../../common/HelpPage'
import { doctorFAQ } from '../../../constants/FAQ'
import { Reviews } from '../Patient/Doctors/Reviews'
import { PhoneEditPage } from '../../Auth/PhoneConfirm/PhoneEditPage'
import { ConciliumContainer } from './Concilium'
import { Notifications } from '../Notifications'
import { AnswerModal } from '../../common/CallModal/AnswerModal'
import { Status } from './Status'
import { Signature } from './Profile/Signature'
import { SignatureUpload } from './Profile/Signature/Upload'
import { ROSOPageList } from './Community/ROSO';
import { GoalsManagement } from './Community/GoalsManagement';
import { ManageTeam } from './Community/ROSO/ManageTeam';
import { StructuresListPage } from './Community/Structures';
import { StructurePage } from './Community/Structures/StructurePage';
import {JoinStructurePage} from "./Community/Structures/JoinStructurePage";
import { PopUpWeeklyActivitiesReport } from "../../common/SecuredImage"
import {CalendarPage} from "./Calendar";
import {MonReseau} from "./Community/Reseau";
import { PhoneConfirm } from './../../Auth/PhoneConfirm/index'
import {AroundMe} from "./AroundMe";
import {Discussions} from "./Discussions";
import { InstructionsComponent} from "./Settings/Instructions";
import {EditInstructions} from "./Settings/EditInstructions";

const enhance = compose(
  withRouter,
  connect(state => ({
    diplomas: idx(state, _ => _.auth.relationships.diploma.data) || [],
  })),
)

export const Doctor = enhance(({ diplomas, match }) =>
{
  const userID = useSelector(state => state.auth.attributes.user_id)
  const invoice = `${window.env.API_URL}/general/doctor/report/${userID}/pdf/weekly/activities`

  return (<>
    <AnswerModal />
    <Route path='/panel/consultations' component={Consultations}/>
    <Route path='/panel/teleconsultations' component={Consultations} />

    <Route path='/panel/concilium' component={ConciliumContainer} />
    <Route path='/panel/teleconsultation' component={ConciliumContainer} />

    <Route
      path='/panel/report/activities-report-weekly/:date'
      render={() => <PopUpWeeklyActivitiesReport action={invoice} />}
    />
    <Route path='/panel/concilium/main/structure/:id' component={StructurePage} exact />
    <Route path='/panel/concilium/main/roso/detail/:id' component={ManageTeam} exact />

    <Route path='/panel/schedule' component={CalendarPage} />
    <Route path='/panel/settings' component={Settings} />
    <Route path='/panel/instructions' component={InstructionsComponent} />
    <Route path='/panel/instructions/edit' component={EditInstructions} />
    <Route path='/panel/version' component={Version} />
    <Route path='/panel/personal/data' component={PersonalData} />
    <Route path='/panel/status' component={Status} />
    <Route path='/panel/help' render={({...props}) => <HelpPage {...props} faq={doctorFAQ} terms='gdpr.modal.terms' />} />
    <Route exact path='/panel/profile' component={Profile} />
    <Route path='/panel/profile/fill' component={FillForm} />
    <Route path='/panel/phone' component={PhoneConfirm} />
    <Route path='/panel/profile/edit' component={Edit} />
    <Route path='/panel/profile/diplomas' render={() => <DiplomasGallery diplomas={diplomas} />} />
    <Route path='/panel/profile/reviews' component={Reviews} />
    <Route path='/panel/profile/phone/edit' component={PhoneEditPage} />
    <Route path='/panel/profile/notifications' component={Notifications} />
    <Route path='/panel/profile/signature' component={Signature} exact />
    <Route path='/panel/profile/signature/upload' component={SignatureUpload} />
    <Route path='/panel/community/roso' component={ROSOPageList} exact />
    <Route path='/panel/community/roso/detail/:id' component={ManageTeam} exact />
    <Route path='/panel/community/goals' component={GoalsManagement} exact />
    <Route path='/panel/community/structures' component={StructuresListPage} exact />
    <Route path='/panel/community/structures/:id' component={StructurePage} exact />
    <Route path='/panel/community/joinstructures' component={JoinStructurePage} exact />
    <Route path='/panel/network' component={MonReseau} exact />
    <Route path='/panel/proximity' component={AroundMe} exact />
    <Switch>
      <Route path='/panel/discussions/:id' component={Discussions} exact />
      <Route path='/panel/discussions' component={Discussions} />
    </Switch>
  </>)}
)

Doctor.propTypes = {
  diplomas: PropTypes.arrayOf(PropTypes.string),
}

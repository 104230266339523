import React, {useState, useMemo, useEffect} from 'react'
import moment from 'moment-timezone'
import styled from 'styled-components'
import {LocalizationProvider} from "@mui/x-date-pickers";
import {AdapterDateFns} from "@mui/x-date-pickers/AdapterDateFns";
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import TextFieldMui from "@mui/material/TextField";
import {generateTimePeriods} from "../../../../helpers/calendar";
import MenuItem from "@mui/material/MenuItem";
import {FormattedMessage} from "react-intl";
import FormHelperText from "@mui/material/FormHelperText";
import {Colors} from "../../../../constants/colors";
import {CALENDAR_START_DAY} from "../../../../constants/calendar";

const Wrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 10px;
`

const FirstLine = styled.div`
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  gap: 10px;
`

const ItalicText = styled.span`
  font-style: italic;
  color: ${Colors.greyText};
  break-after: always;
  min-width: 250px;
  flex: 250px;
`

const NewRow = styled.div`
  display: block;
  width: 100%;
  height: 0px;
`

export const InviteDatePicker = ({ input, meta, props, displayError = true, setDisplayError = () => {}}) => {

  const [date, setDate] = useState(null);
  const [hour, setHour] = useState(moment().hour(CALENDAR_START_DAY).minutes(0).format('H:mm'));
  const periods = useMemo(() => generateTimePeriods(), [])

  const onChangeDate = (newValue) => {
    setDate(newValue)
    setDisplayError(true)
  }

  const onChangeHour = (event) => {
    setHour(event.target.value)
    setDisplayError(true)
  }

  useEffect(() => {
    let match = hour.match('([0-9]{1,2}):([0-9]{2})')
    if(date && hour && match.length === 3) {
      let datetime = moment(date).hour(match[1]).minute(match[2]).format('YYYY-MM-DD HH:mm:ss')
      input.onChange(datetime)
      input.onBlur()
      input.onFocus()
    }
  }, [date, hour])

  useEffect(() => {
    if(input.value) {
      setDate(moment(input.value).toString())
      setHour(moment(input.value).format('H:mm'))
    }
  }, [])

  return (
    <Wrapper>
      <FirstLine>
        <FormattedMessage id='global.the' />
        <LocalizationProvider dateAdapter={AdapterDateFns}>
          <DatePicker
            {...props}
            onChange={onChangeDate}
            inputFormat='dd/MM/yyyy'
            value={date}
            disableToolbar
            disablePast
            renderInput={(params) => <TextFieldMui {...params} style={{width: '10em'}} />}
          />
        </LocalizationProvider>
        <FormattedMessage id='global.at' />
        <TextFieldMui
            name='hour'
            onChange={onChangeHour}
            value={hour}
            select
            style={{minWidth: '5em', maxWidth: '6em'}}
          >
            {periods.map((option) => (
              <MenuItem key={option.from} value={option.from}>
                {option.from}
              </MenuItem>
            ))}
        </TextFieldMui>
        <ItalicText> <FormattedMessage id='consultation.default.duration' /> </ItalicText>
      </FirstLine>
      { displayError && meta.error && meta.touched && meta.error.props &&
        <>
          <NewRow/>
          <FormHelperText error>
            <FormattedMessage id={meta.error.props.id} />
          </FormHelperText>
        </>
      }
    </Wrapper>
  )
}
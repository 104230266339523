import React, {useEffect} from 'react'
import styled from 'styled-components'
import { Paper } from 'common/UiKit/Paper'
import {history} from "../../../utils/history";
import AddIcon from "@mui/icons-material/Add";
import essLogo from "../../Panel/Doctor/Community/ROSO/images/Logo_ESS.png";
import esspLogo from "../../Panel/Doctor/Community/ROSO/images/Logo_ESSP.png";
import {toggleFavoriteTeam} from "../../../redux/modules/teams";
import {useDispatch} from "react-redux";
import RemoveIcon from "@mui/icons-material/Remove";
import {Colors} from "../../../constants/colors";

const PaperStyled = styled(Paper)`
  padding: 10px 14px;
  position: relative;
  display: flex;
  height: 90px;
  align-items: center; 
`

const Wrapper = styled.div`
  display: flex;
  font-weight: normal;
  font-size: 14px;
  margin: auto 0;
  width: 100%;
`

const AvatarWrapper = styled.div`
  display: flex;
  font-weight: normal;
  font-size: 14px;
  align-items: center;
`

const Img = styled.img`
  width: 70px;
`

const Info = styled.div`
  flex-grow: 1;
  margin: auto 32px auto;
`

const NameLinked = styled.h1`
  margin: 0;
  font-size: 18px;
  font-weight: bold;
  color: black;
  cursor: pointer;
  
  &:hover {
    color: #6633FF;
  }
`

const Name = styled.p`
  margin: 0;
  font-size: 18px;
  font-weight: bold;
  color: black;
`

const Specialization = styled.p`
  margin: 0;
  opacity: 0.8;
  padding-top: 5px;
`

const Address = styled.p`
  margin: 0;
  opacity: 0.8;
  padding-top: 15px;
`

const Button = styled.div`
  display: flex;
  justify-content: flex-end;
  align-items: flex-start;
`

const StyledAddIcon = styled(AddIcon)`
  margin-left: 5px;
  font-size: 50px;
  color: ${Colors.bluePurple};
  cursor: pointer;
  border-radius: 100%;
  &:hover {
    background-color: rgba(231, 226, 246, 0.3);
  }
`

const StyledRemoveIcon = styled(RemoveIcon)`
  margin-left: 5px;
  font-size: 50px;
  color: ${Colors.bluePurple};
  cursor: pointer;
  border-radius: 100%;
  &:hover {
    background-color: rgba(231, 226, 246, 0.3);
  }
`

export const FavoriteTeamCardSearch = ({
                                         id,
                                         name,
                                         specializations=[],
                                         teamType,
                                         postcode,
                                         city,
                                         link,
                                         isFavorite,
                                       }) => {

  const dispatch = useDispatch();


  const onConfirm = () => {
    dispatch(toggleFavoriteTeam(id, !isFavorite))
  }

  useEffect(() => {
  },[isFavorite]);

  return (
    <PaperStyled>
      <Wrapper>
        <AvatarWrapper>
          <Img src={teamType === 'ESS' ? essLogo : esspLogo} alt='ess' />
        </AvatarWrapper>
        <Info>
            {link ? (
                <NameLinked onClick={()=>history.push(link)}>
                  {name}
                </NameLinked>)
              : (
                <Name>
                  {name}
                </Name>)
            }
            <Specialization>
              {specializations.map((specialization) => {
                if(specializations[specializations.length-1] === specialization) {
                  return (specialization.attributes.name)
                } else {
                  return (specialization.attributes.name + ", ")
                }
              })}
            </Specialization>
            <Address>
              { postcode && city && postcode + " " + city}
            </Address>
        </Info>

        <Info>
          { !isFavorite && isFavorite!=null && (
            <Button
              variant='outlined'
              onClick={onConfirm}
            >
              <StyledAddIcon fontSize='large' color='#6633FF' />
            </Button>
          )
          }
          { isFavorite && (
            <Button
              variant='outlined'
              onClick={onConfirm}
            >
                  <StyledRemoveIcon fontSize='large' color='#6633FF' />
            </Button>
          )
          }
        </Info>
      </Wrapper>
    </PaperStyled>
  )
}



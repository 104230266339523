export const getQueryString = function () {
  const search = window.location.search;
  return search !== null && search !== undefined && search.length > 1 ? search.substring(1) : '';
}

export const getQueryVariable = function (variable) {
  let vars = getQueryString().split("&");

  for (let i=0, n = vars.length; i < n; i++) {
    let pairs = vars[i].split("=");
    if (pairs[0] === variable)
      return pairs[1];
  } // else

  return false;
}
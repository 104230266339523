import React from 'react'
import styled from 'styled-components'
import { Colors } from '../../../../../constants/colors'

export const SystemMessage = ({ message }) => {
  const messageStr = typeof message.attributes.message === 'string' ? message.attributes.message : message.attributes.translatedMessage
  return !!messageStr ? (
    <Wrapper> {messageStr} </Wrapper>
  ) : (
    <></>
  )
}

const Wrapper = styled.div`
  overflow-wrap: break-word;
  font-size: 16px;
  line-height: 1.5;
  color: ${Colors.footerGrey};
  white-space: pre-wrap;
  font-weight: 500;
`

import React from 'react'
import PropTypes from 'prop-types'
import Dropzone from 'react-dropzone'
import styled from 'styled-components'
import Add from '@mui/icons-material/Add'
import { FormattedMessage } from 'react-intl'
import { Colors } from '../../../constants/colors'
import { TextButton } from '../../common/UiKit/TextButton'

const Wrapper = styled.div`




`

const AddField = styled(TextButton)`
  && {
    background-color: #6633FF;
    min-width: 165px;
    height: 40px;
    font-weight: normal;
    margin: 0 auto 26px;
    color: white;
    text-transform: none;
    cursor: pointer;
    text-align: center;
    &:hover{
    background-color: white;
    color:${Colors.bluePurple};
    border: solid 1px ${Colors.bluePurple};
  }
  }
`

const HelperText = styled.p`
  margin: 0;
  padding: 0 10px;
  font-size: 12px;
  line-height: 1.5;
  font-weight: normal;
  color: ${Colors.blueGrey};
`

export const Upload = ({ onDrop, acceptedExtensions, label }) => (
  <Dropzone onDrop={onDrop} accept={acceptedExtensions}>
    {
      ({ getRootProps, getInputProps }) => (
        <Wrapper
          {...getRootProps()}
        >
          <AddField>
            <FormattedMessage id={label} />
          </AddField>
          <input {...getInputProps()} />
        </Wrapper>
      )
    }
  </Dropzone>
)

Upload.defaultProps = {
  acceptedExtensions: '.jpg, .jpeg, .gif, .png, .heif, .heic, .doc, .docx, .xls, .xlsx, .csv, .pdf, .odt, .ods, .odp, .tex, .ppt, .pptx, .zip, .rar, .tar, .gz, .tar.gz, .7z, .txt, .md, .mov, .mpg, .mpeg, .mkv, .mp4'
}

Upload.propTypes = {
  onDrop: PropTypes.func.isRequired,
  acceptedExtensions: PropTypes.string,
}

import React, { useEffect, useState } from 'react'
import styled from 'styled-components'
import {useDispatch, useSelector} from 'react-redux'
import { FormattedMessage } from 'react-intl'
import { useParams } from 'react-router-dom'
import ArrowBack from '@mui/icons-material/ArrowBack';
import IconButton from '@mui/material/IconButton';
import axios from 'axios';
import { Colors } from '../../../../../constants/colors'
import { Paper } from '../../../../common/UiKit/Paper'
import { SecuredAttachmentInNewTab, SecuredBckgImage, SecuredImage } from '../../../../common/SecuredImage'
import { ConfirmWithReasonModal } from '../../../../common/Modal/ConfirmWithReasonModal'
import {approveConsultation, fetchConsultation, proposeNewSlot, refuseConsultation} from '../../../../../redux/modules/consultations'
import { FileIcon, defaultStyles } from 'react-file-icon';
import { history } from "../../../../../utils/history";
import {StyledTextButton, TextButton} from "../../../../common/UiKit/TextButton";
import {fullName} from "../../../../../helpers/user";
import {CONSULTATION_TYPES} from "../../../../../constants/consultation";
import moment from "moment-timezone";
import RadioButtonsGroup from "../../Concilium/RadioButtonsGroup";
import {NewSlotModal} from "../../../../common/Modal/NewSlotModal";
import {socket} from "../../../../../services/socket";
import Linkify from "react-linkify";
import {MESSAGE_TYPES} from "../../../../../constants/messageTypes";

const StyledForm = styled.div`
  width: 720px;
  margin: 24px auto;

  .MuiButton-label {
    text-transform: initial;
  }

  a {
    font-size: 16px;
    word-break: break-all;
  }
`

const PageWrapper = styled.div`
  background-color: ${Colors.background};
  min-height: 100%;
  position: relative;
`

const Photo = styled(SecuredBckgImage)`
  border-radius: 50%;
  height: 62px;
  width: 62px;
  border: 3px solid ${Colors.white};
  box-shadow: 0 4px 8px 0 ${Colors.azure};
  background-size: cover;
`

const AdditionalLabel = styled.span`
  font-size: 14px;
  color: ${Colors.dark70} !important;
  font-weight: 400;
`

const TypeButtonAccepter = styled(TextButton)`
  && {
    cursor: pointer;
    color: white;
    background-color: #21C5A9;
    padding: 20px;
    min-width: 240px;
    text-transform: none;
    font-weight: 500;
    font-size: 14px;
  }
  &&:hover {
    background-color: rgb(23, 137, 118);
  }
`

const TypeButtonRefuser = styled(TextButton)`
  && {
    margin-right: 120px;
    cursor: pointer;
    color: white;
    background-color: #E83E25;
    padding: 20px;
    min-width: 240px;
    text-transform: none;
    font-weight: 500;
    font-size: 14px;
  }

  &&:hover {
    background-color: #9d3827;
  }
`

const WrapButtons = styled.div`
  font-size: 14px;
  text-transform: none;
`

const Bar = styled.div`
  display: flex;
  padding: 4px 8px;
  border-bottom: solid 1px ${Colors.grey};
  align-items: center;
  height: 56px;
  width: 100%;
  box-sizing: border-box;
  background-color: ${Colors.white};
`

const BackButton = styled(IconButton)`
  && {
    margin: 0;
    color: ${Colors.blueGrey};
  }
`

const ButtonWrapper = styled.div`
  display: flex;
  width: 720px;
  margin: auto;
  justify-items: center;
`

const Buttons = styled.div`
  margin: auto auto 24px;
`

const Info = styled.div`
  flex-grow: 1;
  margin-left: 16px;
  cursor: ${props => (props.onClick ? 'pointer' : 'default')};
`

const Name = styled.h1`
  margin: 0;
  font-size: 18px;
  font-weight: 500;
  color: ${Colors.dark};
`

const ImagePreview = styled(SecuredImage)`
  max-width: 90px;
  max-height: 90px;
  border-radius: 4px;
  display: block;
  margin: auto;
`

const File = styled.div`
  max-width: 90px;
  max-height: 90px;
  border-radius: 4px;
  display: block;
  margin: auto;
  
  &  svg {
  max-height: 90px;
  border-radius: 4px;
  margin: auto;
  display: block;
`

const Attachment = styled.div`
  background-color: #F5F7FB;
  display: flex;
  flex-direction: column;
  padding: 10px;
  width: 130px;
  height: 130px;
`

const FileTitle = styled.div`
  display: block;
  max-width: 110px;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  margin-left: auto;
  margin-right: auto;
`

const AttachmentsWrapper = styled.div`
  margin-top: 20px;
  display: grid;
  align-items: center;
  justify-items: center;
  grid-template-columns: repeat(4, 1fr);
`

const Motive = styled.div`
  padding: 10px;
  white-space: pre-wrap;
`

const Status = styled.div`
  text-align: center;
  padding: 20px;
  margin: 60px 30px 0px;
  font-style: italic;
  background-color: ${({$status}) => $status === 'refused' ? Colors.refused : Colors.waiting};
  border-radius: 4px;
`

const Slot = styled.div`
  align-items: center;
  display: flex;
  justify-content: space-between;
`

export const ConsultationResume = (
  consultation,
) => {
  const onBackIconClick = () => history.goBack();
  const consultation_id = useParams();
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [showNewSlotModal, setShowNewSlotModal] = useState(false);
  const dispatch = useDispatch();
  const [refuseReason, setRefuseReason] = useState('');
  const refuseTrue = true;
  const [attachments, setAttachments] = useState([]);
  const [loading, setLoading] = useState(false)
  const userId = useSelector(state => state.auth.attributes.user_id)
  const interlocutor = !!consultation.doctor && userId !== consultation?.doctor?.data?.attributes?.user_id ? consultation.doctor.data.attributes : consultation.additionalMember.data[0].attributes.doctor;
  const interlocutorId = !!consultation.doctor && userId !== consultation?.doctor?.data?.attributes?.user_id ? consultation.doctor.data.id : consultation.additionalMember.data[0].attributes.doctorID;
  const proposedDate = consultation.consultation.proposed_date
  const localTz = Intl.DateTimeFormat().resolvedOptions().timeZone
  const ConsultationDay = moment.utc(proposedDate).tz(localTz).format('DD/MM/yyyy')
  const ConsultationHour = moment.utc(proposedDate).tz(localTz).format('HH:mm')
  const status = consultation.consultation.status
  const isNurse = useSelector(_ => {
    const specializationKey = _.auth.attributes.specialization_key;
    return ( specializationKey === 'Infirmière' || specializationKey === 'Infirmier(ère) en Pratique Avancée' )
  })
  const isPharma = useSelector(_ => {
    const specializationType = _.auth.attributes.specialization_type;
    return ( specializationType === 'pharmaceutical' )
  })
  const [tcaActContextNurse, setTcaActContextNurse] = useState('TLS')
  const isTCA = consultation.consultation.type === CONSULTATION_TYPES.TCA
  const consultationContext = consultation.consultation.consultationContext

  const onConfirm = () => {
    setLoading(true);
    const context = isNurse && !consultationContext ? tcaActContextNurse : (isPharma ? 'TLM' : null)
    dispatch(approveConsultation(consultation.id, consultation.consultation.type, context))
      .finally(() => {
        setLoading(false)
      })
  }

  useEffect(() => {
    (async function () {
      try {
        const base = `general/consultation/${consultation_id.id}/chat/fileMessages`;
        const resp = await axios
          .get(`${base}`);
        setAttachments(resp.data.data);
      } catch (e) {
        console.warn(e);
      }
    }());
  }, [])

  useEffect(() => {
    if(
      socket.messageReceived.action === 'MessageReceived'
      && socket.messageReceived.attributes.chat_relate_id === consultation_id.id
      && socket.messageReceived.attributes.type === MESSAGE_TYPES.approve
    ) {
      dispatch(fetchConsultation(consultation_id.id, null, true)).then()
    }
  }, [socket.messageReceived])

  return (
    <PageWrapper>
      <Bar>
        <BackButton color='inherit' onClick={onBackIconClick}>
          <ArrowBack />
        </BackButton>
        <Info>
          <Name>
            {fullName(interlocutor.gender,
              interlocutor.first_name,
              interlocutor.last_name)}
          </Name>
        </Info>
      </Bar>
      <StyledForm>
        <Paper heading={consultation.consultation.sender ? 'consultation.doctor' : 'consultation.creator'} >
          <div className='row' style={{ display: 'flex' }}>
            <Photo
              src={interlocutor.photo}
            />
            &nbsp;&nbsp;&nbsp;
            <div style={{margin: '10px 10px'}}>
              <div>
                {fullName(interlocutor.gender,
                  interlocutor.first_name,
                  interlocutor.last_name)}
              </div>
              <AdditionalLabel>
                {interlocutor.specialization}
              </AdditionalLabel>
            </div>
          </div>
        </Paper>
      </StyledForm>
      <StyledForm>
        <Paper heading='consultation.patient'>
          <div className='row' style={{ display: 'flex' }}>
            <Photo
              src={consultation.patient.data.attributes.first_name}
            />
            &nbsp;&nbsp;&nbsp;
            <div style={{ margin: '10px 10px' }}>
              <div>
                { consultation.patient.data.attributes.first_name }
                &nbsp;
                { consultation.patient.data.attributes.last_name }
              </div>
              <AdditionalLabel>
                { consultation.patient.data.attributes.gender === 'male' ? 'Homme' : 'Femme' }
                &nbsp;
                { "- " + consultation.patient.data.attributes.date_of_birth }
              </AdditionalLabel>
            </div>
          </div>
        </Paper>
      </StyledForm>

      {isTCA &&
        <StyledForm>
          <Paper heading='consultation.proposed.date'>
            <Slot>
              <FormattedMessage id='global.the.at' values={{day: ConsultationDay, hour: ConsultationHour}}/>
              {consultation.consultation.sender && consultation.consultation.sender !== userId && status === 'created' &&
                <StyledTextButton
                  color='white'
                  onClick={() => setShowNewSlotModal(true)}
                >
                  <FormattedMessage id='consultation.propose.another.slot'/>
                </StyledTextButton>
              }
            </Slot>
          </Paper>
        </StyledForm>
      }
      {(isTCA && attachments.length || !isTCA) &&
        <StyledForm>
          <Paper heading={isTCA ? 'consultation.attachments' : 'concilium.describe'}>
            { !isTCA && <Motive>
              <Linkify
                componentDecorator={(decoratedHref, decoratedText, key) => (
                  <a target="blank" href={decoratedHref} key={key}>
                    {decoratedText}
                  </a>
                )}
              >
                 {consultation.consultation.symptom_explanation}
              </Linkify>
            </Motive>}
            <AttachmentsWrapper>
              { attachments.map(file => {
                const attachment = file.attributes.file;
                return (
                  <>
                    {attachment.type === 'images' ? (
                        <Attachment>
                          <ImagePreview src={attachment.url} alt={attachment.url} onLoad={()=>{}}/>
                          <FileTitle> {attachment.custom_name || attachment.name} </FileTitle>
                        </Attachment>
                      )
                      : (
                        <SecuredAttachmentInNewTab src={attachment.url}>
                          <Attachment>
                            <File>
                              <FileIcon extension={attachment.extension} {...defaultStyles[attachment.extension]} />
                            </File>
                            <FileTitle> {attachment.custom_name || attachment.name} </FileTitle>
                          </Attachment>
                        </SecuredAttachmentInNewTab>
                      )}
                  </>)})}
            </AttachmentsWrapper>
          </Paper>
      </StyledForm>}

      {isTCA && isNurse && consultation.consultation.sender && consultation.consultation.sender !== userId && status === 'created' && !consultationContext &&
        <StyledForm>
          <Paper heading='tca.act.context'>
            <RadioButtonsGroup
                name='tcaActContextNurse'
                setTcaActContextNurse={setTcaActContextNurse}
                color='primary'
                type='radio'
                validateFields={[]}
              />
          </Paper>
        </StyledForm>
      }

      { (consultation.consultation.sender || consultation.consultation.sender !== userId && status === 'created')
        || (consultation.consultation.type === 'TER_ESS' && consultation.additionalMember?.data[0]?.attributes?.doctor?.id !== userId) ?
        <ButtonWrapper>
          <Buttons>
            {
              consultation.doctor !== undefined && (
                <TypeButtonRefuser
                  variant='extended'
                  onClick={(e) => {
                    e.stopPropagation();
                    return setShowDeleteModal(true)
                  }}
                  isFetching={loading}
                >
                  <FormattedMessage id='consultation.refuser' defaultMessage='consultation.refuser' />
                </TypeButtonRefuser>
              )
            }

            <TypeButtonAccepter onClick={onConfirm} variant='extended' isFetching={loading}>

              {consultation.consultation.type !== 'TER_ESS' ? <FormattedMessage id='consultation.accepter' defaultMessage='consultatio.accepter'/> : <FormattedMessage id='consultation.accepter.ess' defaultMessage='consultatio.accepter'/>}
            </TypeButtonAccepter>
          </Buttons>
        </ButtonWrapper>
        :
        <StyledForm>
          {status === 'created' && <Status $status={status}><FormattedMessage id={'consultation.TCA.created'}/></Status>}
          {status === 'refused' && <Status $status={status}><FormattedMessage id={'consultation.TCA.refused'}/></Status>}
        </StyledForm>
      }


      <ConfirmWithReasonModal
        onClick={e => e.stopPropagation()}
        title={<FormattedMessage id='consultation.refuseconfirm' />}
        titleProps={{
          disableTypography: true,
          style: { maxWidth: '460px', fontSize: '16px', fontWeight: '400 !important' },
        }}
        maxWidth={false}
        open={showDeleteModal}
        confirmButtonText={<WrapButtons><FormattedMessage id='consultation.refuser' /></WrapButtons>}
        cancelButtonText={<WrapButtons><FormattedMessage id='consultation.delteconfirmno' /></WrapButtons>}
        text={<FormattedMessage id='consultation.refusereason' />}
        setRefuseReason={setRefuseReason}
        refuseReason={refuseReason}
        handleSubmit={(e) => {
          setLoading(true);
          e.stopPropagation();
          setShowDeleteModal(false);
          return dispatch(refuseConsultation(consultation_id.id, refuseReason, refuseTrue))
            .then(() => {window.location.reload(); setLoading(false)})
            .catch(e => console.warn(e))
        }}
        handleClose={(e) => {
          e.stopPropagation();
          return setShowDeleteModal(false)
        }}
      />
      <NewSlotModal
        open={showNewSlotModal}
        handleClose={() => setShowNewSlotModal(false)}
        handleSubmit={(values) => {
          const data = values
          data.consultationContext = isNurse ? tcaActContextNurse : (isPharma ? 'TLM' : null)
          dispatch(proposeNewSlot(consultation_id.id, data))
          setShowNewSlotModal(false)
        }}
        proposedDate={proposedDate}
        doctorId={interlocutorId}
      />
    </PageWrapper>
  )
}
import React from 'react'
import Checkbox from '@mui/material/Checkbox'

export const CheckboxField = ({ input, meta, ...rest }) => (
  <Checkbox
    {...input}
    {...rest}
    // value prop have to be a string
    value={input.value ? 'true' : 'false'}
  />
)

import React, {useState} from 'react'
import styled, {css} from 'styled-components'
import { FormattedMessage } from 'react-intl'
import { TextButton } from 'common/UiKit/TextButton'
import { Page } from '../../../../common/Page'
import { history } from '../../../../../utils/history'
import { Colors } from '../../../../../constants/colors'
import InfoIcon from '@mui/icons-material/Info';
import SignatureCanvas from 'react-signature-canvas'
import ClearIcon from '@mui/icons-material/Clear';
import {checkSignatureCode, sendPhone, sendSignatureCode} from "../../../../../redux/modules/auth";
import {useDispatch} from "react-redux";
import {Dialog} from "@material-ui/core";
import ReactCodeInput from "react-verification-code-input";
import {createCertifiedSignature} from "../../../../../redux/modules/signature";
import CloseIcon from "@mui/icons-material/Close";


const EmbeddedContainer = styled.div`
  padding: 24px;
  border-radius: 12px;
  background-color: white;
`

const SpanCanva = styled.div`
border-color:black;
border-style:solid;
height:100px;
width:400px;
margin:auto;
`

const MessageRequis = styled.div`
height: auto;
padding: 11px 18px;
word-wrap: break-word;
line-height: 1.5;
color: #000000;
background-color: #d8d8d8;
border-radius: 5px;
display: grid;
grid-template-rows: 100%;
grid-template-columns: 5% 95%;
justify-self: left;
text-align: left;
font-size: 15px;
margin: auto;
margin-bottom : 70px;
`

const InfoIconStyled = styled(InfoIcon)`
  padding-top:22px;
`

const ClearIconStyled  = styled(ClearIcon)`
  font-size:50px;
`

const ButtonSave = styled(TextButton)`
    width: 144px;
    height: 40px;
    box-shadow: none;
    text-transform: capitalize;
    & > * {
      margin-right: 7px;
      margin-left: 7px;
    }
  && {
    ${props => (props.VertPrintemps && css`
      background-color: ${props.VertPrintemps};
      color: white;
    `)}
    ${props => (props.isButtonMenu && css`
     margin-top: 15px;
    `)}
    ${props => (props.centered && css`
      left: calc(50% - 120px) !important;
      right: auto !important;
    `)}
  }
`

const DivFlex = styled.div`
  display:flex;
  justify-content:center;
  margin-left:380px;
`
const DivButton = styled.div`
  text-align:center;
`

const ButtonClear = styled.button`
background-color: Transparent;
    background-repeat:no-repeat;
    border: none;
    cursor:pointer;
    overflow: hidden;
    outline:none;
    text-align:right;
      font-size:50px;  
`

const Title = styled.div`
  font-size: 22px;
  font-weight: 500;
  margin-top: 32px;
  text-align: center;
`

const Content = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-wrap:wrap;
`

const DialogStyled = styled(Dialog)`
  backdrop-filter: blur(3px);
  & > * {
      margin : auto;
      font-size : 20px;
  }
`

const TitleStyled = styled(Title)`
  margin-bottom:5%;
`
const SpanStyled = styled.div`
  font-size:20px;
  margin-bottom:5%;
text-align:center;
`
const Code = styled(ReactCodeInput)`
  margin: 24px 0;
  width: 100% !important;
  
  & > div {
    display: flex;
    justify-content: space-between;
    
    input {
      border: none !important;
      border-radius: 0 !important;
      background-color: #F5F7FB;
      font-size: 36px;
    }
  }
`

const SpanResend = styled.span`
  font-size:14px;
`

const FormattedMessageLink = styled.a`
    color: ${Colors.bluePurple};
    &:hover {
      text-decoration:underline;
      cursor:pointer;
    }
`
const CloseDialog = styled(CloseIcon)`
    position: absolute;
    top: 10px;
    right: 10px;
    cursor: pointer;
`

const FormattedMessageAlert = styled.div`
  color:red;
  margin-top:5%;
`

const SignatureCanvasStyle = styled(SignatureCanvas)`
border-color:black;
border-style:solid;
`
export const Signature = ({ embedded, onUploadClick }) => {
  const Wrapper = embedded ? EmbeddedContainer : Page
  const [sigPad, setSigPad] = useState({})
  const [codeConfirmation, setCodeConfirmation] = useState("")
  const [dialogOpen, setDialogOpen] = useState(false);
  const [codeInput, setCodeInput] = useState('')
  const dispatch = useDispatch()
  const [errorCodeConfirmation, setErrorCodeConfirmation] = useState(false)


  const clear = () => {
    sigPad.clear()
  }

  const handleClose = () => {
    setDialogOpen(false)
  };

  const save = () => {
    dispatch(sendSignatureCode())
        .then((data) => {
          setDialogOpen(true)
        })
        .catch((errors) => {
          console.log(errors)
        })
    setDialogOpen(true)

  }

  const checkCode = (value) => {
    if (value.length === 6) {
      dispatch(checkSignatureCode(value)).
      then((data) => {
        if(data.payload == "OK"){
          setErrorCodeConfirmation(false)
          dispatch(createCertifiedSignature(sigPad.toDataURL("image/png")))
              .then((data) => {
                history.push('/panel/profile')
              })
              .catch((errors) => {
                console.log(errors)
              })
        }
        else{
          setErrorCodeConfirmation(true)
        }
      })
          .catch((errors) => {
            console.log(errors)
          })
    }
  }

  return (
      <>
        <Wrapper
            label='profile.signature'
            onBackIconClick={() => history.goBack()}
        >
          <TitleStyled>
            <FormattedMessage id='signature.title' />
          </TitleStyled>
            <div>
              <MessageRequis>
                <InfoIconStyled></InfoIconStyled>
                <FormattedMessage id='signature.information'/>
              </MessageRequis>
              <SpanStyled>
                <FormattedMessage id='signature.draw'/>
              </SpanStyled>
              <SpanCanva>
                <SignatureCanvasStyle penColor='black'
                                 canvasProps={{width: 400, height: 100}}
                                 ref={(ref) => {setSigPad(ref) }}
                />
              </SpanCanva>
              <DivFlex>
                <ButtonClear onClick={() => clear()}>
                  <ClearIconStyled/>
                </ButtonClear>
              </DivFlex>
            </div>
            <DivButton>
              <ButtonSave
                  color='secondary'
                  VertPrintemps={'#3CE797'}
                  onClick={() => save()}
              >
                <FormattedMessage id='signature.save'/>
              </ButtonSave>
            </DivButton>

        </Wrapper>
        {
            dialogOpen &&
            <DialogStyled
                open={dialogOpen}
                PaperProps={{
                  style: {
                    borderRadius: 30,
                    border: '1px solid #6633FF',
                    padding: '50px',
                    display: 'flex',
                    flexDirection: 'column',
                  }
                }}
                BackdropProps={{
                  style: {
                    opacity: 0,
                  }
                }}
                onClose={handleClose}
            >
              <p style={{
                fontSize: '25px',
                width: '100%',
                textAlign: 'center',
                margin: '0',
                paddingBottom: '30px'
              }}              >
                <CloseDialog onClick={() => handleClose()} />
                <FormattedMessage id="signature.code.title"></FormattedMessage>
                { errorCodeConfirmation &&
                  <FormattedMessageAlert>
                    <FormattedMessage id="signature.code.incorrect"></FormattedMessage>
                  </FormattedMessageAlert>
                }
              </p>

              <Content>
              <Code
                    fields={6}
                    onChange={checkCode}
                    autoFocus
                />
                <SpanResend>
                  <FormattedMessage id="signature.resend"></FormattedMessage>
                  <FormattedMessageLink onClick={() => save()}>
                    <FormattedMessage id="signature.resend.code"></FormattedMessage>
                  </FormattedMessageLink>
                </SpanResend>
              </Content>

            </DialogStyled>
        }
      </>



  )
}



import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import Select from "react-select";
import {useDispatch, useSelector} from 'react-redux';
import {fetchStructureTypes} from "../../../../../../redux/modules/content";
import {Colors} from "../../../../../../constants/colors";

export const TypeOfStructure = ({ changeFilter }) => {
    const dispatch = useDispatch()
    const [type, setType] = useState(null);
    const Types = useSelector(state => state.content.structureTypes)
    const [options, setOptions] = useState([])

    const handleChange = (select) => {
        if(select == null){
            changeFilter({ type: null });
            setType(null)
        } else {
            changeFilter({ type: select.value });
            setType(select.value);
        }
    };

    useEffect(() => {
        dispatch(fetchStructureTypes())
    }, [])

    useEffect(() => {
        setOptions(Types.map( type => ({value: type.id, label: type.attributes.type})))
    },[Types])

    return (
      <StyledSelect
        placeholder={'Type de structure'}
        options={options}
        onChange={handleChange}
        inputNull={type == null}
        classNamePrefix="Select"
        isClearable={true}
      />
    );
}

const StyledSelect = styled(Select)`
  width: 200px;
  margin-right: 20px;
  float: right;
  .Select__control {
    border: 1px solid ${({inputNull}) => (inputNull ? Colors.textMuted : Colors.bluePurple)}  !important; 
    border-radius: 20px !important;
    cursor: pointer;
    background-color: ${({inputNull}) => (inputNull ? 'white' : Colors.bluePurple)}  !important; 
    box-shadow: none;
          opacity: 1 !important;
    
    .Select__placeholder {
      color: #0000008A !important; 
      opacity: 1 !important;
    }
    
    .Select__single-value {
      color: white !important; 
    }
    
    .Select__indicator {
      color: ${({inputNull}) => (inputNull ? Colors.textMuted : 'white')}  !important;  
    }
  }

  .Select__control:hover, .Select__control--menu-is-open {
    background-color: white !important; 
    border-color: ${Colors.bluePurple} !important; 
    
    .Select__placeholder {
    color: ${Colors.bluePurple} !important; 
    }
    
    .Select__single-value {
      color: ${Colors.bluePurple} !important; 
    }
    
    .Select__indicator {
      color: ${Colors.bluePurple} !important; 
    }
  }
  
  .Select__menu, Select__menu-list, Select__option{
    box-shadow: none;
    background-color: white;
    color: #0000008A !important; 
  }
  
  .Select__option--is-focused, .Select__option--is-selected{
    background-color: ${Colors.bluePurple} !important;
    color: white !important; 
  }
  
  .Select__indicator-separator {
    display: none;
  }
`
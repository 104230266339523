import React, { useEffect } from 'react'
import styled from 'styled-components'
import { Colors } from 'constants/colors'
import { FormattedMessage } from 'react-intl'
import { useDispatch, useSelector } from 'react-redux'
import idx from 'idx'

import { Download } from '../Download'
import { fetchConsultation } from '../../../../../redux/modules/consultations'
import { fetchSpecializations } from '../../../../../redux/modules/content';

export const ReportMessage = ({
  diagnosis,
  medicines,
  symptoms,
  redirectToSpecialization,
  needConsultationWithSpecialist,
  redirectSpecializationId,
  doctor,
  pdf,
  isDoctor,
}) => {
  const loaded = useSelector(state => idx(state, _ => _
    .consultations.consultation.relationships.medicalReport.data.length))
  const dispatch = useDispatch()
  const specializations = useSelector(state => state.content.specializations)

  useEffect(() => {
    if (!loaded) {
      dispatch(fetchConsultation(null, null, true))
    }
  }, [dispatch])

  useEffect(() => {
    if (!specializations.length) {
      dispatch(fetchSpecializations())
    }
  }, [])

  const mappingNCWS = {
    0: 'global.not',
    1: 'global.yes',
    yes: 'global.yes',
    no: 'global.not',
    'yes-fast': 'global.yes-fast',
  }

  return (
    <DownloadStyled
      heading='consultations.report'
      seeButtonPath={`/panel/consultations/session/menu/report/${doctor}`}
      downloadButtonPath={pdf ? pdf.report : ''}
    >
      {
        isDoctor && (
          <>
            <Title>
              <FormattedMessage id='consultations.symptoms' />
              &nbsp;
            </Title>
            {symptoms}
          </>
        )
      }

      <Title>
        <FormattedMessage id='consultations.diagnosis' />
        &nbsp;
      </Title>
      {diagnosis}
      <Title>
        <FormattedMessage id='consultation.redirect.question' />
        &nbsp;
      </Title>
      <FormattedMessage id={mappingNCWS[needConsultationWithSpecialist]} />
      <Title>
        <FormattedMessage id='consultation.another.redirect.question' />
        &nbsp;
      </Title>
      {
        (redirectToSpecialization && redirectSpecializationId)
          ? (specializations
            .find(i => i.attributes.key === redirectSpecializationId) || {
            attributes: { name: redirectSpecializationId },
          })
            .attributes.name
          : <FormattedMessage id='global.not' />
      }
      <br />
      {
        (medicines && !!medicines.length) && (
          <>
            <Title>
              <FormattedMessage id='consultations.prescribed' />
              :&nbsp;
            </Title>
            <Medicines>
              {medicines.map(item => <li key={item}>{item}</li>)}
            </Medicines>
          </>
        )
      }
    </DownloadStyled>
  )
}

const DownloadStyled = styled(Download)`
  min-width: 260px;
  word-break: break-all;
`

const Title = styled.div`
  margin-top: 8px;
  color: ${Colors.dark};
  opacity: 0.87;
`

const Medicines = styled.ol`
  counter-reset: myCounter;
  padding-left: 0;
  margin: 0;

  &>li {
    list-style: none;
    display: flex;
  }

  &>li:before {
    counter-increment: myCounter;
    content: counter(myCounter) ".";
    margin-right: 13px;
  }
`

import React from 'react'
import Dialog from '@mui/material/Dialog'
import DialogTitle from '@mui/material/DialogTitle'
import DialogActions from '@mui/material/DialogActions'
import Button from '@mui/material/Button'
import { FormattedMessage } from 'react-intl'
import idx from 'idx'
import { useDispatch, useSelector } from 'react-redux'
import styled from 'styled-components'

import { toggleAccess } from '../../../../../../redux/modules/consultations'

const Wrapper = styled.div`
  max-width: 360px;
  
  h6 {
    font-size: 16px;
  }
`

export const AccessModal = ({ data, onClose }) => {
  const dispatch = useDispatch()
  const consultationId = useSelector(state => state.consultations.consultation.id)

  const onClick = value => dispatch(toggleAccess(data.attributes.id, value, consultationId))
    .then(() => onClose())

  return (
    <Dialog open>
      <Wrapper>
        <DialogTitle>
          <FormattedMessage
            id='concilium.join.title'
            values={{
              doctor: `${idx(data, _ => _.attributes.doctor.first_name)} ${idx(data, _ => _.attributes.doctor.last_name)}`,
            }}
          />
        </DialogTitle>
        <DialogActions>
          <Button size='small' onClick={() => onClick(false)}>
            <FormattedMessage id='concilium.refuse' />
          </Button>
          <Button color='secondary' variant='contained' onClick={() => onClick(true)} size='small'>
            <FormattedMessage id='concilium.accept' />
          </Button>
        </DialogActions>
      </Wrapper>
    </Dialog>
  );
}

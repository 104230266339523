import React, {useEffect, useMemo} from 'react';
import styled from 'styled-components';
import {useDispatch, useSelector} from 'react-redux';
import {FormattedMessage} from "react-intl";
import {Colors} from 'constants/colors';
import get from "lodash/get";
import {Dialog, DialogContent, DialogTitle} from "@material-ui/core";
import {SearchMonReseau} from "./search";
import {clearSearch} from "../../../../../redux/modules/search";
import {fetchRecentContacts} from "../../../../../redux/modules/recentContact";
import ClearIcon from '@mui/icons-material/Clear';
import {NewFavoriteDoctorCard} from "../../../../common/DoctorCard/NewFavoriteDoctorCard";
import List from "@mui/material/List";
import {Divider} from "@mui/material";
import {TextButton} from "../../../../common/UiKit/TextButton";
import {NewFavoriteTeamCard} from "../../../../common/TeamCard/NewFavoriteTeamCard";
import {NewFavoriteDoctorCardSearch} from "../../../../common/DoctorCard/NewFavoriteDoctorCardSearch";
import {NewFavoriteTeamCardSearch} from "../../../../common/TeamCard/NewFavoriteTeamCardSearch";
import {NewFavoriteStructureCardSearch} from "../../../../common/StructureCard/NewFavoriteStructureCardSearch";
import {createFormsView, fetchNetworkView} from "../../../../../redux/modules/formsView";
import {NETWORK_POP_UP} from "../../../../../constants/forms";
import {Configure, Index, InstantSearch, useHits, useInfiniteHits, useSearchBox} from "react-instantsearch";
import {ElasticSearchService} from "../../../../../services/ElasticSearchService";
import {fetchFavoriteDoctors, fetchFavoriteStructures, fetchFavoriteTeams} from "../../../../../redux/modules/doctors";
import {debounce} from "lodash";


const DialogStyled = styled(Dialog)`
    backdrop-filter: blur(3px);
`

const StyledDialogTitle = styled(DialogTitle)`
    font-family: Roboto, sans-serif;
    display: flex;
    font-size: 18px;
    font-weight: 500;
    opacity: 0.9;
    color: ${Colors.dark80};
    border-bottom: 1px solid ${Colors.cloudyBlue};
`

const StyledClearIcon = styled.div`
    cursor: pointer;
`

const StyledTitle = styled.div`
    margin: auto;
`

const WrapperDialog = styled.div`
    margin-top: 10px;
    justify-content: space-between;
    align-content: space-between;
`

const StyledSubtitle = styled.div`
    margin-top: 40px;
    font-family: 'Roboto', sans-serif;
    font-style: normal;
    font-size: 16px;
    font-weight: bold;
    color: black;
`
const SeeMoreButton = styled.div`
    align-self: center;
    width: 60px;

    && {
        margin-top: 30px;
        margin-bottom: 20px;
        color: #6633FF;
    }

    &:hover {
        color: #6633FF;
        text-decoration: underline;
        cursor: pointer;
    }

    text-transform: none;
    font-weight: 500;
    font-size: 14px;
    display: block;
    margin-left: auto;
    margin-right: auto;
`

const EndButton = styled(TextButton)`
    box-shadow: none;
    align-self: center;
    padding-top: 5px;
    padding-bottom: 5px;

    && {
        width: 20%;
        //height: 35px;
        margin-top: 30px;
        margin-bottom: 20px;
        background-color: #6633FF;
        border: 1px solid #fff;
        color: #fff;
    }

    &:hover {
        background-color: rgb(71, 35, 178);
        border: 1px solid #fff;
        color: #fff;
    }

    text-transform: none;
    font-weight: 500;
    font-size: 14px;
`

/**
 * Instant search
 */
const sk = new ElasticSearchService().createSk(['nomExercice', 'prenomExercice', 'libelleCivilite', 'libelleProfession', 'first_name^8', 'last_name^8','specialization^6', 'codeCivilite', 'name^10']);

function CustomSearchBox(props) {

  const {query, refine} = useSearchBox(props);

  function setQuery(newQuery) {
    refine(newQuery);
  }

  setQuery = debounce(setQuery, 200);

  return (
    <div>
      <SearchMonReseau value={query} onSearch={setQuery}/>
    </div>
  )
}

function CustomHits(props) {
  const {hits} = useHits(props);
  const {query} = useSearchBox();

  return (
    <>
      {query.length > 0 && hits.map((data) => {
        const type = data.indexType;
        const relationships = data.relationships
        data.favorite = false;
        if (type === "DoctorTeam") {
          const specializations = get(relationships, 'skills.data', []);
          const workplace = get(relationships, 'workPlace.data', null);
          return (
            <WrapperDialog>
              <NewFavoriteTeamCardSearch
                id={data.id}
                name={data.name}
                postcode={workplace && workplace.length ? workplace[0].attributes.postcode : null}
                city={workplace && workplace.length ? workplace[0].attributes.city : null}
                specializations={specializations}
                teamType={data.type}
                isFavorite={props.teamsFavorite.some(team => team.id === data.id)}
                link={`concilium/main/roso/detail/` + data.id}
              />
            </WrapperDialog>
          )
        } else if (type === "Doctor") {
          return (
            <WrapperDialog>
              <NewFavoriteDoctorCardSearch
                photo={data.photo}
                doctorId={data.id}
                firstName={data.first_name}
                lastName={data.last_name}
                specialization={data.specialization}
                specialization_type={data.specialization_type}
                status_availability={data.status_availability}
                isFavorite={props.doctorsFavorite.some(doctor => doctor.id === data.id)}
                workplaceName={data?.workplace?.attributes?.name}
                street={data?.workplace?.attributes?.street}
                postcode={data?.workplace?.attributes?.postcode}
                city={data?.workplace?.attributes?.city}
                link={`concilium/doctor/` + data.id}
                gender={data.gender}
              />
            </WrapperDialog>)
        } else if (type === "Structure") {
          return (
            <WrapperDialog>
              <NewFavoriteStructureCardSearch
                id={data.id}
                photo={data.photo}
                name={data.name}
                postcode={data.zipcode}
                city={data.city}
                isFavorite={props.structuresFavorite.some(structure => structure.id === data.id)}
                link={`concilium/main/structure/` + data.id}
              />
            </WrapperDialog>
          )
        } else if (type === 'Annuaire') {
          return (
            <WrapperDialog>
              <NewFavoriteDoctorCardSearch
                id={data.id}
                firstName={data.prenomExercice}
                lastName={data.nomExercice}
                specialization={data.libelleProfession}
                specialization_type={data.specialization_type}
                workplaceName={data?.workplace?.name}
                street={data?.workplace?.street}
                postcode={data?.workplace?.postcode}
                city={data?.workplace?.city}
                link={`concilium/doctor/annuaire/` + data.id}
                gender={data.codeCivilite}
                isAnnuaire={true}
                />
            </WrapperDialog>
          )
        }
      })
      }
    </>
  );
}

function CustomInifiteHits(props) {
  const {hits, isLastPage, showMore} = useInfiniteHits(props);
  return (
    <>
      {hits && hits.map((data) => {

        if (data.indexType === 'Doctor') {
          return (
            <WrapperDialog key={data.id}>
              <List sx={{width: '100%'}}>
                <Divider variant='fullWidth' component='li'/>
                <NewFavoriteDoctorCard
                  photo={data?.photo}
                  doctorId={data?.id}
                  firstName={data?.first_name}
                  lastName={data?.last_name}
                  specialization={data?.specialization}
                  specialization_type={data?.specialization_type}
                  status_availability={data?.status_availability}
                  isFavorite={props.doctorsFavorite.some(doctor => doctor.id === data.id)}
                  postcode={data?.workplace_code_postal}
                  city={data?.workplace_city}
                  link={`concilium/doctor/` + data?.id}
                  distance={data?.distance}
                  />
              </List>
            </WrapperDialog>
          )
        } else if (data.indexType === 'Annuaire') {
          return (
            <WrapperDialog>
              <List sx={{width: '100%'}}>
                <Divider variant='fullWidth' component='li'/>
                <NewFavoriteDoctorCard
                  id={data.id}
                  firstName={data.prenomExercice}
                  lastName={data.nomExercice}
                  isAnnuaire={data.indexType === 'Annuaire'}
                  specialization={data.libelleProfession}
                  specialization_type={data.specialization_type}
                  distance={data?.distance}
                  workplaceName={data?.workplace?.name}
                  street={data?.workplace?.street}
                  postcode={data?.workplace?.postcode}
                  city={data?.workplace?.city}
                  link={`concilium/doctor/annuaire/` + data.id}
                  gender={data.codeCivilite}/>
              </List>
            </WrapperDialog>
          )
        }
      })}
      <SeeMoreButton if={!isLastPage} onClick={showMore}>
        <FormattedMessage id='global.see.more'/>
      </SeeMoreButton>
    </>
  )

}

/**
 * END InstantSearch
 * @param showModal
 * @param setShowModal
 * @param isAlreadySeen
 * @param doctorId
 */

export const InstantSearchDialog = ({showModal, setShowModal, isAlreadySeen, doctorId}) => {

  const dispatch = useDispatch()

  const lastContacts = useSelector(state => state.recentContacts.list.data)
  const favoriteToggle = useSelector(state => state.loading.favoriteToggle);

  const relationships = useSelector(state => state.auth.relationships)
  const workplace = get(relationships, 'workPlace.data', null);
  const latitude = workplace && workplace.length ? workplace[0].attributes.lat : null;
  const longitude = workplace && workplace.length ? workplace[0].attributes.lon : null;
  const structuresFavorite = useSelector(state => state.doctors.favoriteStructures);
  const teamsFavorite = useSelector(state => state.doctors.favoriteTeams);
  const doctorsFavorite = useSelector(state => state.doctors.favorite);
  const profileAuth = useSelector((state) => state.auth);
  const env = window.env.APP_ENV;
  const searchClient = useMemo(() => {
    return new ElasticSearchService(profileAuth).createClient(sk);
  }, []);

  const aroundMeClient = useMemo(() => {
    return new ElasticSearchService(profileAuth).createClient(sk, {'latitude': latitude, 'longitude': longitude})
  }, [latitude, longitude]);

  const fetchRecentContactsList = () => {
    dispatch(fetchRecentContacts({limit: 3, removeFavorite: true}));
  }

  useEffect(() => {
    if (!structuresFavorite?.length) {
      dispatch(fetchFavoriteStructures());
    }
    if (!teamsFavorite?.length) {
      dispatch(fetchFavoriteTeams());
    }
    if (!doctorsFavorite?.length) {
      dispatch(fetchFavoriteDoctors());
    }
  }, []);

  const handleClose = () => {
    setShowModal(false);
    dispatch(clearSearch());
    if (!isAlreadySeen) {
      dispatch(createFormsView(doctorId, NETWORK_POP_UP));
    }
    dispatch(fetchNetworkView(doctorId));
  }

  useEffect(() => {
    fetchRecentContactsList();
  }, [favoriteToggle]);

  return (
    <DialogStyled
      open={showModal}
      onClose={handleClose}
      maxWidth='lg'
      fullWidth
      PaperProps={{
        style: {
          width: '700px',
          height: '65%',
          border: '1px solid #6633FF',
          padding: '10px',
        }
      }}
      BackdropProps={{
        style: {
          opacity: 0,
        }
      }}
    >
      <>
        <StyledDialogTitle disableTypography>
          <StyledTitle><FormattedMessage id='roso.add'/></StyledTitle>
          <StyledClearIcon onClick={() => handleClose()}><ClearIcon/></StyledClearIcon>
        </StyledDialogTitle>
        <DialogContent style={{marginTop: '5px', marginBottom: '5px'}}>

          <InstantSearch searchClient={searchClient}
                         indexName={env+'_professional_v1'}
          >
              <Configure hitsPerPage={5} />
            <Index indexName={'pro_directory'}>
              <Configure hitsPerPage={5} />
            </Index>
            <Index indexName={env+'_team_v1'}>
              <Configure hitsPerPage={5}/>
            </Index>
            <Index indexName={env+'_specialization_v1,'+env+'_skill_v1'}>
              <Configure hitsPerPage={10}/>
            </Index>
            <Index indexName={env+'_structure_v1'}>
              <Configure hitsPerPage={5}/>
            </Index>
            <CustomSearchBox/>
            <CustomHits doctorsFavorite={doctorsFavorite} structuresFavorite={structuresFavorite}
                        teamsFavorite={teamsFavorite}/>
          </InstantSearch>
          {(lastContacts && lastContacts.length > 0) && (
            <StyledSubtitle>
              <FormattedMessage id='global.recent.contacts'/>
            </StyledSubtitle>)}
          {lastContacts && lastContacts.map((data) => {
            const type = data.type
            const relationships = data.relationships
            const attributes = data.attributes

            if (type === "DoctorTeam") {
              const specializations = get(data, 'relationships.skills.data', []);
              const workplace = get(data, 'relationships.workPlace.data', null);
              return (
                <WrapperDialog>
                  <List sx={{width: '100%'}}>
                    <Divider variant='fullWidth' component='li'/>
                    <NewFavoriteTeamCard
                      id={attributes.id}
                      name={attributes.name}
                      postcode={workplace && workplace.length ? workplace[0].attributes.postcode : null}
                      city={workplace && workplace.length ? workplace[0].attributes.city : null}
                      specializations={specializations}
                      teamType={attributes.type}
                      isFavorite={attributes.favorite}
                      link={`concilium/main/roso/detail/` + attributes.id}
                    />
                  </List>
                </WrapperDialog>
              )
            } else {
              const workplace = get(relationships, 'workPlace.data', null);
              return (
                <WrapperDialog>
                  {!attributes.favorite && (
                    <List sx={{width: '100%'}}>
                      <Divider variant='fullWidth' component='li'/>
                      <NewFavoriteDoctorCard
                        photo={attributes.photo}
                        doctorId={attributes.doctor_id}
                        firstName={attributes.first_name}
                        lastName={attributes.last_name}
                        specialization={attributes.specialization}
                        specialization_type={attributes.specialization_type}
                        status_availability={attributes.status_availability}
                        isFavorite={attributes.favorite}
                        workplaceName={workplace && workplace.length ? workplace[0].attributes.name : null}
                        street={workplace && workplace.length ? workplace[0].attributes.street : null}
                        postcode={workplace && workplace.length ? workplace[0].attributes.postcode : null}
                        city={workplace && workplace.length ? workplace[0].attributes.city : null}
                        link={`concilium/doctor/` + attributes.doctor_id}
                        distance={attributes.distance}
                        gender={attributes.gender}
                      />
                    </List>
                  )}

                </WrapperDialog>)
            }
          })
          }
          <StyledSubtitle>
            <FormattedMessage id='global.geo.aroundyou'/>
          </StyledSubtitle>
          <InstantSearch searchClient={aroundMeClient} indexName={env+'_professional_v1'}>
            <Index indexName={'pro_directory'}></Index>
            <CustomInifiteHits latitude={latitude} longitude={longitude} doctorsFavorite={doctorsFavorite}/>
            <Configure hitsPerPage={10}/>
          </InstantSearch>
        </DialogContent>
        <EndButton onClick={handleClose}>
          <FormattedMessage id='global.finish'/>
        </EndButton>
      </>
    </DialogStyled>
  )
}
import React, {useEffect, useRef, useState} from 'react';
import {FormattedMessage} from "react-intl";
import styled from "styled-components";
import {MapContainer, Marker, TileLayer, Tooltip, useMapEvents} from "react-leaflet";
import {useDispatch, useSelector} from "react-redux";
import idx from "idx";
import {Icon} from "leaflet";
import {aroundMe} from "../../../../redux/modules/geoloc";
import MarkerClusterGroup from 'react-leaflet-cluster';
import get from 'lodash/get';
import {DoctorListWithRef} from "../../../common/DoctorList";
import {LocationSearching, MyLocation} from "@mui/icons-material";
import {LoadingButton} from "@mui/lab";
import Pagination from '@mui/material/Pagination';
import {fetchSpecializations, fetchStructureTypes} from "../../../../redux/modules/content";
import Select from "react-select";
import {history} from "../../../../utils/history";
import {Page} from "../../../common/Page"
import {StructureListWithRef} from "../../../common/StructureList";
import {fetchStructures} from "../../../../redux/modules/structures";
import {fetchTeams} from "../../../../redux/modules/teams";
import {TeamListWithRef} from "../../../common/TeamList";
import ToggleButton from '@mui/material/ToggleButton';
import ToggleButtonGroup from '@mui/material/ToggleButtonGroup';
import {Colors} from "../../../../constants/colors";
import {useLocation, useParams} from "react-router-dom";
import {fullName} from "../../../../helpers/user";
import {clearSearchObject} from "../../../../redux/modules/search";
import {fetchSkillPossessors} from "../../../../redux/modules/skills";
import {DoctorAndTeamBySkillListWithRef} from "../../../common/DoctorAndTeamList";
import {MY_API_KEY, URL_GEOAPIFY} from "../../../../constants/map";
import "leaflet/dist/leaflet.css";
import {InstantSearchBar} from "../../../common/Search/instantSearchBar";

const Content = styled.div`
  height: calc(100vh - 56px - 30px);
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  padding: 15px 50px; 
  & > * {
    width: 100%;
  }
`

const Map = styled.div`
  height: calc(100% - 80px);
  display: flex;
  gap: 2%;
  justify-content: center;
  flex-wrap: wrap;
  flex-direction: row-reverse;
  max-width: 100%;
`

const StyledSelect = styled(Select)`
  width: 200px;
    
  .Select__control {
    border: 1px solid ${({inputNull}) => (inputNull ? Colors.textMuted : Colors.bluePurple)}  !important; 
    border-radius: 20px !important;
    cursor: pointer;
    background-color: ${({inputNull}) => (inputNull ? 'white' : Colors.bluePurple)}  !important; 
    box-shadow: none;
          opacity: 1 !important;
    
    .Select__placeholder {
      color: #0000008A !important; 
      opacity: 1 !important;
    }
    
    .Select__single-value {
      color: white !important; 
    }
    
    .Select__indicator {
      color: ${({inputNull}) => (inputNull ? Colors.textMuted : 'white')}  !important;  
    }
  }

  .Select__control:hover, .Select__control--menu-is-open {
    background-color: white !important; 
    border-color: ${Colors.bluePurple} !important; 
    
    .Select__placeholder {
    color: ${Colors.bluePurple} !important; 
    }
    
    .Select__single-value {
      color: ${Colors.bluePurple} !important; 
    }
    
    .Select__indicator {
      color: ${Colors.bluePurple} !important; 
    }
  }
  
  .Select__menu, Select__menu-list, Select__option{
    box-shadow: none;
    background-color: white;
    color: #0000008A !important; 
  }
  
  .Select__option--is-focused, .Select__option--is-selected{
    background-color: ${Colors.bluePurple} !important;
    color: white !important; 
  }
  
  .Select__indicator-separator {
    display: none;
  }
`

const StyledMapContainer = styled(MapContainer)`
  height: 100%;
  width: 100%;
  z-index: 96;
  
 .leaflet-control {
    z-index: 400;
  }
  
  .leaflet-top, .leaflet-bottom {
    z-index: 400;
  }
`

const StyledLoadingButton= styled(LoadingButton)`
  position: absolute;
  z-index: 100;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
`

const BlurFilter = styled.div`
  position: absolute;
  height: 100%;
  width: 100%;
  z-index: 98;
  backdrop-filter: blur(5px);
`

const MapWrapper = styled.div`
  position: relative;
  max-height: 100%;
  min-height: 300px;
  flex-grow: 4;
  z-index: 1200;
  min-width: 300px;
  display: flex;
`

const SidePanel = styled.div`
  min-height: 100%;
  flex-basis: 0;
  display: flex;
  flex-direction: column;
  row-gap: 24px;
`

const Title = styled.div`
  font-weight: bold;  
`

const Container = styled.div`
  flex-basis: 0;
  display: flex;
  flex-direction: column;
  flex-grow: 6;
  min-width: 500px;
  max-height: 100%;
  min-height: 300px;
`

const StyledPagination = styled(Pagination)`
  & > ul {
    align-items: center;
    justify-content: center;
  }
`

const StyledToggleButtonGroup = styled(ToggleButtonGroup)`
  display: flex;
  gap: 10px;
  
  & .MuiToggleButton-root {
    border : 1px solid ${Colors.textMuted} !important;
    border-radius: 20px !important;
    padding: 0 20px;
    background-color: white;
    font-weight: normal;

    &:hover {
        border : 1px solid ${Colors.bluePurple} !important;
        background-color: white;
        color: ${Colors.bluePurple};
    }
  }
  
    & .Mui-selected {
        border : 1px solid ${Colors.bluePurple} !important;
        background-color: ${Colors.bluePurple};
        color: white;
  }
`

const ChangePosition = ({page, setPage, disableMap, spe, type, teamType, structureType, searchName, markers, skillId}) => {
  const dispatch = useDispatch();
  const reverseGeocoding = useSelector(state => state.geoloc.reverseGeocoding)
  const [filters, setFilters] = useState({limit: 10, page: page})
  const abortControllerReferenceDoctors = useRef();

  const fetchStructuresList = () => {
    dispatch(fetchStructures({ ...filters, type: structureType, all: true },  abortControllerReferenceDoctors));
  }

  const fetchTeamsList = () => {
    dispatch(fetchTeams({ ...filters, type: teamType, specialization: spe },  abortControllerReferenceDoctors));
  }

  const fetchSkillsList = () => {
    dispatch(fetchSkillPossessors(skillId, {...filters },  abortControllerReferenceDoctors));
  }

  const map = useMapEvents({})

  useEffect(() => {
    if (markers && markers.length > 0) {
      map.fitBounds(markers)
    }
  }, [markers])

  useEffect(() => {
    if(disableMap == true) {
      map.removeControl(map.zoomControl)
      map.dragging.disable()
      map.scrollWheelZoom.disable()
      map.touchZoom.disable()
      map.boxZoom.disable()
      map.doubleClickZoom.disable()
      map.keyboard.disable()
    } else {
      map.addControl(map.zoomControl)
      map.dragging.enable()
      map.scrollWheelZoom.enable()
      map.touchZoom.enable()
      map.boxZoom.enable()
      map.doubleClickZoom.enable()
      map.keyboard.enable()
    }
  }, [disableMap])

  useEffect(() => {
    map.zoomControl.remove()
    dispatch(fetchSpecializations())
    dispatch(fetchStructureTypes())
  }, [])

  useEffect(() => {
    setPage(1)
  }, [reverseGeocoding, spe, teamType, structureType, skillId])

  useEffect(() => {
      switch (type) {
        case 'doctor':
          break;
        case 'structure':
          fetchStructuresList();
          break;
        case 'team':
          fetchTeamsList();
          break;
        case 'skill':
          fetchSkillsList();
          break;
      }
  }, [filters, reverseGeocoding, spe, teamType, structureType, searchName, type, skillId])

  useEffect(() => {
    setFilters({...filters, page: page})
  }, [page])

  return null
}

function useQuery() {
  const { search } = useLocation();

  return React.useMemo(() => new URLSearchParams(search), [search]);
}

export const NearMe = ({ match }) => {
  const [page, setPage] = useState(1)
  const reverseGeocodingLoading = useSelector(state => state.loading.geolocAroundMe)
  const dispatch = useDispatch();

  const specializations = useSelector(state => state.content.specializations)
  const structureTypes = useSelector(state => state.content.structureTypes)

  const [options, setOptions] = useState({})
  const [optionsStructures, setOptionsStructures] = useState([])

  const [spe, setSpe] = useState(match.params.doctorId)
  const skillId = match.params.skillId
  const [structureType, setStructureType] = useState(null)
  let query = useQuery().get('name')

  const [type, setType] = useState()
  const params = useParams();

  useEffect(() => {
    return () => { dispatch(clearSearchObject()) }
  },[])

  useEffect(() => {
    if(match.path.includes('structures')) {
      setType('structure')
    } else if(match.path.includes('teams')) {
      setType('team')
    } else if(match.path.includes('skills')){
      setType('skill')
    } else {
      setType('doctor')
    }
  },[match])

  useEffect(() => {
    if(type === 'team') {
      setOptions(specializations.map( spe => ({value: spe.id, label: spe.attributes.name})))
    }
  },[specializations, type])

  useEffect(() => {
    if(type === 'structure') {
      setOptionsStructures(structureTypes.map( type => ({value: type.id, label: type.attributes.type})))
    }
  },[structureTypes, type])


  const doctors = useSelector(state => state.doctors.list);
  const structures = useSelector(state => state.structures.list)
  const teams = useSelector(state => state.teams.list)
  const doctorsAndTeams = useSelector(state => state.skills.list)


  const [idSelected, setIdSelected] = useState(null)

  const reverseGeocoding = useSelector(state => state.geoloc.reverseGeocoding)

  const doctorsPaginator = useSelector(state => state.doctors.paginator)
  const teamsPaginator = useSelector(state => state.teams.paginator)
  const structuresPaginator = useSelector(state => state.structures.paginator)
  const doctorsAndTeamsPaginator = useSelector(state => state.skills.paginator)


  const [disableMap, setDisableMap] = useState(true)

  const refs = useRef([])

  const [teamType, setTeamType] = useState(null)

  const handleSelect = (e, type) => {
    setTeamType(type);
  };

  const handleClick = id => refs.current.focusRef(id);

  useEffect(() => {
    if(reverseGeocoding.latitude != undefined && reverseGeocoding.longitude != undefined) {
      setDisableMap(false)
    } else {
      setDisableMap(true)
      setIdSelected(null)
    }
  }, [reverseGeocoding])

  useEffect(() => {
    if (type == 'doctor' ) {
     setSpe(params.doctorId)
    }
  }, [params])

  const DoctorIcon = new Icon({
    iconUrl: `https://api.geoapify.com/v1/icon?icon=user-md&iconType=awesome&type=awesome&color=%236633ff&size=xx-large&apiKey=${MY_API_KEY}&noShadow&noWhiteCircle`,
    iconSize: [31, 46], // size of the icon
    iconAnchor: [15.5, 42], // point of the icon which will correspond to marker's location
    popupAnchor: [0, -45] // point from which the popup should open relative to the iconAnchor//
  })

  const StructureIcon = new Icon({
    iconUrl: `https://api.geoapify.com/v1/icon?icon=hospital-alt&iconType=awesome&type=material&color=%23f1353a&size=xx-large&apiKey=${MY_API_KEY}&noShadow&noWhiteCircle`,
    iconSize: [31, 46], // size of the icon
    iconAnchor: [15.5, 42], // point of the icon which will correspond to marker's location
    popupAnchor: [0, -45] // point from which the popup should open relative to the iconAnchor//
  })

  const [markers, setMarkers] = useState([])

  const getLatLong = (latlong) => (object) => {
    let lat = null
    let lon = null
    if(object.type == 'Doctor') {
      const relationships = object.relationships
      lat = idx(relationships, _ => _.workPlace.data.slice(-1)[0].attributes.lat)
      lon = idx(relationships, _ => _.workPlace.data.slice(-1)[0].attributes.lon)
    } else if (object.type === 'DoctorTeam' || object.type === 'Structure') {
      const attributes = object.attributes
      lat = attributes.lat
      lon = attributes.lon
    }
    lat && lon ? latlong.push([lat, lon]) : null
  }

  useEffect( () => {
   if(!disableMap) {
     let latlong = []
      if (type == 'doctor') {
        doctors.forEach(getLatLong(latlong))
      } else if (type == 'team') {
        teams.forEach(getLatLong(latlong))
      } else if (type == 'structure') {
        structures.forEach(getLatLong(latlong))
      } else if (type == 'skill') {
        doctorsAndTeams.forEach(getLatLong(latlong))
      }
      setMarkers(latlong)
    }
  }, [doctors, structures, teams, doctorsAndTeams])

  const MarkerByObject = (object) => {
    if(object.type === 'Doctor') {
      const {attributes, id, relationships} = object
      const lat = idx(relationships, _ => _.workPlace.data.slice(-1)[0].attributes.lat)
      const lon = idx(relationships, _ => _.workPlace.data.slice(-1)[0].attributes.lon)
      return (
        lat && lon && <Marker position={[lat, lon]}
                              icon={DoctorIcon}
                              key={id + [lat,lon] + object.type}
                              eventHandlers={{
                                click: () => {
                                  setIdSelected(id)
                                  handleClick(id)
                                },
                              }}>
          <Tooltip>
            <Title>{fullName(attributes.gender, attributes.first_name, attributes.last_name)}</Title>
            {attributes.specialization}
          </Tooltip>
        </Marker>)
    } else if (object.type === 'Structure') {
      const { attributes, id } = object
      const lat = attributes.lat
      const lon = attributes.lon

      return(
        lat && lon && <Marker position={[lat, lon]}
                              icon={StructureIcon}
                              key={id + [lat,lon] + object.type}
                              eventHandlers={{
                                click: () => {
                                  setIdSelected(id)
                                  handleClick(id)
                                },
                              }}>
          <Tooltip>
            <Title>{attributes.name}</Title>
          </Tooltip>
        </Marker>)
    } else if (object.type === 'DoctorTeam') {
      const {attributes, id, relationships} = object
      const lat = attributes.lat
      const lon = attributes.lon

      return (
        lat && lon && <Marker position={[lat, lon]}
                              icon={StructureIcon}
                              key={id + [lat,lon] + object.type}
                              eventHandlers={{
                                click: () => {
                                  setIdSelected(id)
                                  handleClick(id)
                                },
                              }}>
          <Tooltip>
            <Title>{attributes.name}</Title>
          </Tooltip>
        </Marker>)
    }
  }

  return(
    <Page
      label={match.path.includes('concilium') ? 'concilium.new.TE' : match.path.includes('doctors') ? 'global.doctors': 'concilium.new.TCA'}
      noWrapperPadding
      fullWidth
      onBackIconClick={() => history.goBack()}
    >
    <Content>
      <InstantSearchBar match={match}/>
      <Map>
        <MapWrapper>
          { disableMap &&
            <StyledLoadingButton
              variant='contained'
              loading={ reverseGeocodingLoading }
              onClick={() => dispatch(aroundMe())}
            >
              {
                (!reverseGeocoding || reverseGeocoding.isUserSearch || Object.keys(reverseGeocoding).length <= 1) && (
                  <LocationSearching sx={{ marginRight: '5px' }} />
                ) || (
                  <MyLocation sx={{ marginRight: '5px' }} />
                )
              }
              <FormattedMessage id='global.geo.aroundme' />
            </StyledLoadingButton>
          }
          { disableMap && <BlurFilter/> }
          <StyledMapContainer center={disableMap ? null : [reverseGeocoding.latitude, reverseGeocoding.longitude]} zoom={12}
                              scrollWheelZoom={false}
                              dragging={false}
                              zoomControl={true}
                              touchZoom={false}
                              boxZoom={false}
                              doubleClickZoom={false}
                              keyboard={false}
          >
            <TileLayer
              attribution='Powered by <a href="https://www.geoapify.com/" target="_blank">Geoapify</a> | © OpenStreetMap <a href="https://www.openstreetmap.org/copyright" target="_blank">contributors</a>'
              url={URL_GEOAPIFY}
            />
            <ChangePosition page={page} setPage={setPage} disableMap={disableMap} spe={spe} type={type} teamType={teamType} structureType={structureType} searchName={query} markers={markers} skillId={skillId}/>
            {!disableMap &&
              <MarkerClusterGroup chunkedLoading showCoverageOnHover={false}>
                { type === 'doctor' && doctors.map(MarkerByObject)}
                { type === 'structure' && structures.map(MarkerByObject)}
                { type === 'team' && teams.map(MarkerByObject)}
                { type === 'skill' && doctorsAndTeams.map(MarkerByObject)}
              </MarkerClusterGroup>
            }
          </StyledMapContainer>
        </MapWrapper>

        <Container>
        <SidePanel>
          {type == 'doctor' &&
            <DoctorListWithRef match={match} idSelected={idSelected} ref={refs}/>
          }
          {type == 'structure' &&
            <>
              <StyledSelect
                placeholder={'Type de structure'}
                options={optionsStructures}
                onChange={event => event ? setStructureType(event.value) : setStructureType(null)}
                inputNull={structureType == null}
                classNamePrefix='Select'
                isClearable={true}
              />
              <StructureListWithRef match={match} idSelected={idSelected} ref={refs}/>
            </>
          }
          {type == 'team' &&
            <>
              <div style={{display: 'flex', gap: '10px'}}>
                <StyledSelect
                  name='spécialités'
                  placeholder={'Spécialité'}
                  options={options}
                  onChange={event => event ? setSpe(event.value) : setSpe(null)}
                  inputNull={spe == null}
                  classNamePrefix='Select'
                  isClearable={true}
                />
                <StyledToggleButtonGroup
                  value={teamType}
                  exclusive
                  onChange={handleSelect}
                >
                  <ToggleButton value={'ESS'}> ESS </ToggleButton>
                  <ToggleButton value={'ESSP'}> ESSP </ToggleButton>
                </StyledToggleButtonGroup>
              </div>
              <TeamListWithRef match={match} idSelected={idSelected} ref={refs}/>
            </>
          }
          {type == 'skill' &&
            <DoctorAndTeamBySkillListWithRef match={match} idSelected={idSelected} ref={refs}/>
          }
          { type == 'doctor' ?
            doctorsPaginator && doctorsPaginator.totalPage > 1 &&
            <StyledPagination
                onChange={(e, page) => {
                  setPage(page)
                }}
                color='primary'
                page={get(doctorsPaginator, 'currentPage', 1)}
                count={get(doctorsPaginator, 'totalPage', 0)}
              /> :
            ( type == 'structure' ?
                structuresPaginator && structuresPaginator.totalPage > 1 &&
                <StyledPagination
                  onChange={(e, page) => {
                    setPage(page)
                  }}
                  color='primary'
                  page={get(structuresPaginator, 'currentPage', 1)}
                  count={get(structuresPaginator, 'totalPage', 0)}
                />
                :
                teamsPaginator && teamsPaginator.totalPage > 1 &&
                <StyledPagination
                  onChange={(e, page) => {
                    setPage(page)
                  }}
                  color='primary'
                  page={get(teamsPaginator, 'currentPage', 1)}
                  count={get(teamsPaginator, 'totalPage', 0)}
                />
            )
            }
          {type === 'skill' &&
            doctorsAndTeamsPaginator && doctorsAndTeamsPaginator.totalPage > 1 &&
            <StyledPagination
              onChange={(e, page) => {
                setPage(page)
              }}
              color='primary'
              page={get(doctorsAndTeamsPaginator, 'currentPage', 1)}
              count={get(doctorsAndTeamsPaginator, 'totalPage', 0)}
            />
            }
        </SidePanel>
      </Container>
      </Map>

    </Content>
    </Page>
  )
}
import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import { withProps } from 'recompose'
import { EditPage } from 'common/UiKit/EditPage'
import { Notifications as NotificationsForm } from '../../../common/Settings'
import { externalFormSubmit } from '../../../../helpers/externalFormSubmit'
import { history } from '../../../../utils/history'

const Wrapper = styled.div`
  margin-top: 24px;
`

const NotificationsComponent = ({
  onSubmit,
  onButtonClick,
  onBackIconClick,
}) => (
  <EditPage
    label='Notifications'
    buttonText='global.save'
    onBackIconClick={onBackIconClick}
    onButtonClick={onButtonClick}
    background
  >
    <Wrapper>
      <NotificationsForm onSubmit={onSubmit} pills afterSubmit={() => history.push('/panel/profile')} />
    </Wrapper>
  </EditPage>
)

NotificationsComponent.propTypes = {
  onSubmit: PropTypes.func.isRequired,
  onButtonClick: PropTypes.func.isRequired,
  onBackIconClick: PropTypes.func.isRequired,
}

const enhance = withProps({

  // TODO: change this
  onSubmit: () => Promise.resolve(),
  onButtonClick: () => externalFormSubmit('notifications'),
  onBackIconClick: () => history.push('/panel/profile'),
})

export const Notifications = enhance(NotificationsComponent)

import React from 'react'
import styled from 'styled-components'
import { Link } from 'react-router-dom'
import PropTypes from 'prop-types'
import Button from '@mui/material/Button'
import { Colors } from 'constants/colors'
import { FormattedMessage } from 'react-intl'

import { OpenSecuredInNewTab } from 'common/SecuredImage'
import { Wrapper, Heading } from '../Info'

const WrapperStyled = styled(Wrapper)`
  padding: 0;
`

const Content = styled.div`
  padding: 10px 12px;
`

const Buttons = styled.div`
  border-top: solid 1px ${Colors.cloudyBlue};
  display: flex;
  
  * {
    flex-grow: 1;
  }

  button {
    font-weight: 500;
  }

  button:first-child {
    width: 100%;
  }
`

const DownloadButton = styled(Button)`
  && {
    border-radius: 0 0 4px 0;
    text-transform: none;
    font-size: 14px;
    font-weight: 500;
  }
`

export const Download = ({
  className,
  heading,
  children,
  seeButtonPath,
  downloadButtonPath,
}) => (
  <WrapperStyled className={className}>
    <Content>
      <Heading>
        <FormattedMessage id={heading} defaultMessage={heading} />
      </Heading>
      {children}
    </Content>
    <Buttons>
      <OpenSecuredInNewTab action={downloadButtonPath}>
        <DownloadButton
          disabled={!downloadButtonPath}
          color='primary'
        >
          <FormattedMessage id='global.download' />
        </DownloadButton>
      </OpenSecuredInNewTab>
    </Buttons>
  </WrapperStyled>
)

Download.defaultProps = {
  seeButtonPath: '',
  downloadButtonPath: '',
}

Download.propTypes = {
  className: PropTypes.string.isRequired,
  children: PropTypes.node.isRequired,
  heading: PropTypes.string.isRequired,
  seeButtonPath: PropTypes.string,
  downloadButtonPath: PropTypes.string,
}

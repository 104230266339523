import React from 'react';
import styled from 'styled-components'
import moment from 'moment-timezone'
import { Colors } from '../../../../../constants/colors'
import { FormattedMessage } from 'react-intl'
import {Description} from "@mui/icons-material";
import Button from "@mui/material/Button";
import {SecuredAttachmentInNewTab} from "../../../../common/SecuredImage";

export const PrescriptionNew = ({ message, time }) => {
  const date = time ? moment(moment.utc(time).toDate()) : null

  return (
    <Wrapper>
      <Heading>
        <FormattedMessage id={'consultations.prescription'} defaultMessage={'Prescription'} />
      </Heading>
      {!!date && (
        <Date>
          <Icon>
            <Description />
          </Icon>
          <div>
            <h1>{date.local().format('ddd MMMM, DD')}</h1>
            <p>{date.format('HH:mm')}</p>
          </div>
        </Date>
      )}
      <Buttons>
        <SecuredAttachmentInNewTab src={message.url}>
          <DownloadButton
            disabled={!message || !message.url}
            color='primary'
          >
            <FormattedMessage id='global.download' />
          </DownloadButton>
        </SecuredAttachmentInNewTab>
      </Buttons>
    </Wrapper>
  )
}

export const Wrapper = styled.div`
  height: auto;
  word-wrap: break-word;
  font-size: 16px;
  line-height: 1.5;
  padding: 13px 12px 0 12px;
  background-color: white;
  border-radius: 4px;
  border: solid 1px ${Colors.cloudyBlue};
  color: ${Colors.blueGrey};
`

export const Heading = styled.h1`
  margin: 0;
  font-size: 14px;
  font-weight: 500;
  opacity: 0.87;
  color: ${Colors.dark};
  margin-bottom: 7px;
`

const Date = styled.div`
  display: flex;
  align-items: center;
  text-transform: uppercase;

  h1 {
    margin: 0;
    font-size: 10px;
    font-weight: 500;
    color: ${Colors.blueGrey};
  }

  p {
    margin: 0;
    font-size: 14px;
    color: ${Colors.dark};
    font-weight: 500;
    opacity: 0.8;
  }
`

const Icon = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 42px;
  height: 42px;
  margin-right: 11px;
  border-radius: 100%;
  background: ${Colors.paleGrey};
`

const Buttons = styled.div`
  border-top: solid 1px ${Colors.cloudyBlue};
  display: flex;
  margin: 10px -12px 0 -12px;
  
  * {
    flex-grow: 1;
  }

  button {
    font-weight: 500;
  }

  button:first-child {
    width: 100%;
  }
`

const DownloadButton = styled(Button)`
  && {
    border-radius: 0 0 4px 0;
  }
`

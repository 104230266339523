import React from 'react'
import { useIntl } from 'react-intl'
import { PhoneConfirmForm } from './PhoneConfirmForm'

export const PhoneConfirm = () => {
  const { formatMessage } = useIntl()
  const f = id => formatMessage({ id })

  return (
        <PhoneConfirmForm />
  )
}

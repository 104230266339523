import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import { Colors } from 'constants/colors'
import Linkify from "react-linkify";

export const TextMessage = ({
  message,
  my,
  system,
  name,
}) => {
  let messageBG = `${Colors.white}`;
  if (my) {
    messageBG = `${Colors.chatMessageSender}`;
  }

  if (system) {
    messageBG = '#ffe1e1';
  }

  return (
    <Wrapper bg={messageBG}>
      { !my && <div style={{fontWeight: '500'}}>{name}</div>}
      <Linkify
        componentDecorator={(decoratedHref, decoratedText, key) => (
          <a target="blank" href={decoratedHref} key={key}>
            {decoratedText}
          </a>
        )}
      >
        {message}
      </Linkify>
    </Wrapper>
  )
}

TextMessage.defaultProps = {
  message: '',
  system: false,
}

TextMessage.propTypes = {
  my: PropTypes.bool.isRequired,
  message: PropTypes.string,
  system: PropTypes.bool,
}

const Wrapper = styled.div`
  height: auto;
  padding: 11px 18px;
  overflow-wrap: break-word;
  font-size: 16px;
  line-height: 1.5;
  color: ${Colors.dark};
  background-color: ${props => props.bg};
  border-radius: 4px;
  border: solid 1px ${Colors.cloudyBlue};
  white-space: pre-wrap;
  
  a {
    font-size: 16px;
    word-break: break-all;
  }
`

import React from 'react'
import { Form, Field } from 'react-final-form'
import { mapProps } from 'recompose'
import PropTypes from 'prop-types'
import { Signature } from './Signature'

const enhance = mapProps(props => ({
  ...props,
}))

export const SignatureForm = enhance(({
  onSubmit,
  createSignature,
  signatures,
  withFilenames,
  acceptedExtensions,
  id,
}) => (
  <Form
    onSubmit={onSubmit}
    subscription={{}}
    render={({ handleSubmit }) => (
      <form onSubmit={handleSubmit} id={id}>
        <Field
          name='signature'
          component={Signature}
          createSignature={createSignature}
          signatures={signatures}
          withFilenames={withFilenames}
          acceptedExtensions={acceptedExtensions}
        />
      </form>
    )}
  />
))

SignatureForm.defaultProps = {
  withFilenames: false,
}

SignatureForm.propTypes = {
  onSubmit: PropTypes.func.isRequired,
  createSignature: PropTypes.func,
  withFilenames: PropTypes.bool,
}

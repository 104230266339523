import axios from "axios";

export const startCibaAuth = (bindingMessage, scope = 'openid scope_all', acrValues = 'eidas1') => {

  return axios('/ciba/auth', {
    method: 'POST',
    data: {
      scope: scope,
      bindingMessage: bindingMessage,
      acrValues: acrValues,
    }
  })
}

export const pollCibaToken = (authReqId, grantType = 'urn:openid:params:grant-type:ciba') => {

  return axios('/ciba/poll', {
    method: 'POST',
    data: {
      grantType: grantType,
      authReqId: authReqId,
    }
  })
}
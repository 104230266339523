import React, {useEffect} from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import Place from '@mui/icons-material/Place'
import {FormattedMessage} from 'react-intl'
import {StyledTextButton} from 'common/UiKit/TextButton'
import {Paper} from 'common/UiKit/Paper'
import {Colors} from '../../../constants/colors'
import {DefaultAvatar} from "../DefaultAvatar";
import {Link} from "react-router-dom";
import get from "lodash/get";
import {DistanceDisplay} from "../DistanceDisplay";
import {fullName} from "../../../helpers/user";
import {useDispatch, useSelector} from "react-redux";
import {setDoctorAnnuaire} from "../../../redux/modules/doctors";
import {history} from "../../../utils/history";

const PaperStyled = styled(Paper)`
  padding: 20px 24px;
  position: relative;
  display: block;
`

const Wrapper = styled.div`
  display: flex;
  font-weight: normal;
  font-size: 16px;
`

const FullLengthWrapper = styled.div`
  margin-top: 20px;
  display: block;
  margin-right: auto;
  margin-left: auto;
  align-items: center;
  justify-content: center;
`

export const Rating = styled.div`
  position: absolute;
  bottom: -12px;
  width: 64px;
  background-color: #fff;
  font-size: 16px;
  border-radius: 30px;
  color: ${Colors.dark80};
  box-shadow: 0 4px 8px 0 rgba(6, 173, 255, 0.1);
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: bold;
  height: 30px;
  padding-left: 2px;
  
  svg {
    fill: #ffcc00;
    width: 24px;
    height: 24px;
    margin-left: 4px;
  }
`

const AvatarWrapper = styled.div`
  & > p {
    margin: 16px 0 0;
    color: ${Colors.clearBlue};
    font-size: 24px;
    font-weight: bold;
    text-align: center;
  }
`

const Info = styled.div`
  flex-grow: 1;
  margin: 12px 32px 0;
`
const NameLinked = styled.a`
  margin: 0;
  font-size: 24px;
  font-weight: bold;
  color: black;
  cursor: pointer;

    &:hover {
    color: #6633FF;
  }
`

const Specialization = styled.p`
  margin: 0;
  opacity: 0.8;
  padding-top: 10px;
`

const Over = styled.p`
  margin: 0;
  color: ${Colors.blueGrey};
  display: flex;
  align-items: center;
  margin-top: 12px;
  text-transform: capitalize;

  && svg {
    font-size: 18px;
    margin-right: 12px;
  }
`


const LanguageWrapper = styled.div`
  position: absolute;
  top: 16px;
  right: 16px;
  text-align: center;
  display: flex;
  align-items: center;
  color: #b7bed2;
`

const Default = styled.div`
  min-width: 126px;
  width: 126px;
  height: 126px;
  border-radius: 100%;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  box-shadow: 0 4px 8px 0 rgba(6, 173, 255, 0.1);
`

const ButtonWrapper = styled.div`
  display: flex;
  justify-content: center;
  gap: 20px;
`
const SpanSkill = styled.span`
    background-color: ${Colors.purpleGrey};
    color: #5D5577;
    padding: 3px 16px;
    border-radius: 11px;
`

const DivFlex = styled.div`
  display:flex;
  flex-wrap:wrap;
  gap: 10px 10px;
`
const TitleSkill = styled.div`
  color:black;
  margin-bottom:5px;
  font-weight:bold;
`

export const DoctorAnnuraireCard = ({
  doctorAnnuaire,
  firstName,
  lastName,
  specialization,
  specialization_type,
  location,
  workplace,
  removePerson,
  skills,
  gender,
  onInvite,
  invited,
  checkInvited,
}) => {
  const dispatch = useDispatch()
  const authType = useSelector(state => state.auth.type)
  const isDoctor = authType === 'Doctor'
  const link = isDoctor ? '/panel/concilium/doctor/annuaire/' : '/panel/doctors/annuaire/'

  const isInvited = useSelector(state => {
    if (checkInvited && !!doctorAnnuaire && !!state.invitations) {
      for (const invitation of state.invitations) {
        if (invitation.relationships?.invited?.data?.identifiantPp === doctorAnnuaire.id) {
          return true
        }
      }
    }
    return false
  })

  return (
    <PaperStyled>
      <LanguageWrapper>
        {
          !!doctorAnnuaire?.distance && !!location.lat && ( <DistanceDisplay float='right' distance={doctorAnnuaire?.distance} /> )
        }
        {removePerson ? removePerson : ''}
      </LanguageWrapper>
      <Wrapper>
        <AvatarWrapper>
          <Default
              spinner={{width: '120px', height: '120px'}}
              style={{backgroundImage: `url(${DefaultAvatar(firstName, lastName, specialization_type)})`}}
            />
        </AvatarWrapper>
        <Info>
          <NameLinked key={doctorAnnuaire.id} onClick={() => {
            dispatch(setDoctorAnnuaire(doctorAnnuaire));
            history.push(link + doctorAnnuaire?.id)
          }} >
            {fullName(gender, firstName || 'Name', lastName || 'Surname')}
          </NameLinked>
          <Specialization>
            {specialization || 'Therapist'}
          </Specialization>
          <Over>
            <Place/>
            {workplace && Object.keys(workplace)?.length && ( !!workplace.name?.trim() || !!workplace.street?.trim() || !!workplace.city?.trim())
              ?
              `${workplace.name?.trim() ?workplace.name:''} ${workplace.name?.trim() && workplace.street?.trim() ? ', ': ''} ${workplace.street?.trim() ?`${workplace.street}`: '' } ${workplace.city?.trim() ? `, ${workplace.city}`: ''}`
              : <FormattedMessage id='global.location'/>}
          </Over>
          <Over>
            {
              skills && skills.length > 0  && (
                <span>
                      <TitleSkill>
                        <FormattedMessage id='profile.skill' />
                      </TitleSkill>
                      <DivFlex>
                        {skills.map((skill) => {
                          const name = get (skill,"attributes.name","");
                          return (
                            <SpanSkill key={skill.id ? skill.id : skill.key} >{name}</SpanSkill>
                          )
                        })}
                      </DivFlex>
                    </span>
              )
            }
          </Over>
            <FormattedMessage id='roso.annuaire.description' />
        </Info>
      </Wrapper>
      {
        isDoctor &&
        <FullLengthWrapper>
          {
            (invited || isInvited) ? (
              <div style={{
                textAlign: 'center',
                color: Colors.dark80,
                fontStyle: 'italic',
              }}>Invitation envoyée</div>
            ) : (
              <ButtonWrapper>
                <StyledTextButton onClick={() => onInvite()} color='white' style={{height: 'auto'}} disabled={invited}>
                  <FormattedMessage id='roso.addAnnuaire' />
                </StyledTextButton>
              </ButtonWrapper>
            )
          }
        </FullLengthWrapper>
      }
    </PaperStyled>
  )
}

DoctorAnnuraireCard.defaultProps = {
  location: {},
  photo: '',
  buttonText: null,
  rating: null,
  invited: false,
  checkInvited: false,
}

DoctorAnnuraireCard.propTypes = {
  firstName: PropTypes.string,
  lastName: PropTypes.string,
  specialization: PropTypes.string,
  photo: PropTypes.string,
  location: PropTypes.object,
  disableFavorite: PropTypes.bool,
  buttonText: PropTypes.string,
  invited: PropTypes.bool,
  checkInvited: PropTypes.bool,
}

import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import CloseIcon from '@mui/icons-material/Close';
import styled, { css } from 'styled-components'
import idx from 'idx'
import { connect, useSelector , useDispatch } from 'react-redux'
import { compose, withProps } from 'recompose'
import { TextButton } from '../../../../../common/UiKit/TextButton'
import { Colors, calendarColors ,BUTTON_COLORS} from 'constants/colors'
import {FormattedMessage, useIntl} from 'react-intl'
import CheckIcon from '@mui/icons-material/Check';
import { OpenSecuredInNewTab } from 'common/SecuredImage';
import { history } from '../../../../../../utils/history'
import {Dialog, DialogContent, DialogTitle} from "@material-ui/core";
import {
  addPrescriptionChat,
  editMedicalReport,
} from "../../../../../../redux/modules/consultations";
import {fetchSpecializations} from "../../../../../../redux/modules/content";
import {PrescriptionDialog} from "../Prescription";
import {CONSULTATION_TYPES} from "../../../../../../constants/consultation";
import { useHistory } from 'react-router-dom';
import Select from 'react-select';
import { sendOlaqinPatient } from "../../../../../../redux/modules/olaqin";
import NoteAddIcon from '@mui/icons-material/NoteAdd';
import Tooltip, { tooltipClasses } from '@mui/material/Tooltip';
import {isConciliumCheck} from "../../../../../../helpers/consultations";
import Button from "@mui/material/Button";
import {DialogAuthMss} from "./Dialog/DialogAuthMss"
import {pollCibaToken, startCibaAuth} from "../../../../../../helpers/CibaProtocol/ciba";
import {DialogSendMss} from "./Dialog/DialogSendMss";
import {sendMailToMss} from "../../../../../../redux/modules/mssante";
import Alert from '@mui/material/Alert';
import Snackbar from "@mui/material/Snackbar";
import {fetchMedicalOfficeSoftwares} from "../../../../../../redux/modules/medicalOfficeSoftwares";
import {fetchSettings} from "../../../../../../redux/modules/settings";
import {DialogInvoice} from "./Dialog/DialogInvoice";
import illustration_popup_completed from '../../../../../../images/doctors/popup_TE_completed.svg';
import {connectionMode} from "../../../../../../redux/modules/auth";
import {CircularProgress} from "@mui/material";
import {getTokenPSC} from "../../../../../../redux/modules/proSanteConnect";
import {MethodSelectDialog} from "../../../../Chat/Message/Payments/Complete/MethodSelectDialog";


const Wrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;
  box-sizing: border-box;
  height: 80px;
  padding: 20px 24px;
  border: 1px solid ${Colors.grey};

  button {
    font-size: 14px;
    font-weight: 500;
  }
`

const ReportButtons = styled.div`
  flex-grow: 1;

  button {
    text-transform: none;
    margin-right: 16px;
    background-color: ${BUTTON_COLORS.purple};
    color: #fff;
  }
  button:hover {
    background-color: ${BUTTON_COLORS.purpleHover};
    color: #fff;
  }
`
const FeuilleDeSoinButtons = styled.div`
  flex-grow: 114;
  button {
    margin-right: 16px;
    text-transform: none;
  }
`

const CheckIconRegular = styled(CheckIcon)`
  && {
    margin-right: 12px;
  }
`

const NoteAddIconMarge = styled(NoteAddIcon)`
  && {
    margin-right: 12px;
    color:white;
  }
`

const LineEnd = styled.div`
  display : flex;
  justify-content : end;
  align-item : center;
`

const Line = styled.div`
  display : flex;
  justify-content : space-between;
  align-item : center;
`

const Content = styled.div`
  padding: 15px;
  text-align: center;

  && {
    border: 2px solid #6633FF;
    border-radius: 10px;
  }
`

const Column = styled.div`
  font-size: 16px;
  display: flex;
  flex-direction : column;
  justify-content: space-between;
  align-items : center;
  margin-bottom: 20px;
`

const ButtonSC = styled(TextButton)`
  && {
    margin-top: 20px;
    background-color: ${BUTTON_COLORS.purple};
    height: 40px;
    min-width : 320px;
    width: 100%;
    font-weight: 500;
    color: #fff;
    font-size: 14px;
    text-transform: none;
  }
  &&:hover{
    background-color: ${BUTTON_COLORS.purpleHover};
    color: #fff;
  }
`

const TextButtonStyled = styled(TextButton)`
  && {
    text-transform: none;
    font-size: 14px;
    font-weight: 500;
    background-color: ${BUTTON_COLORS.green};
    height: 48px;
    width: auto;
  }
  &&:hover {
    background-color: ${BUTTON_COLORS.greenHover};
  }
`

const CloseIconStyled = styled(CloseIcon)`
  &&:hover {
    cursor: pointer;
  }
`

const SendMSSButton = styled.div`
  button {
    text-transform: none;
    margin-right: 16px;
    background-color: #6633FF;
    color: #fff;
  }
  button:hover {
    background-color: rgb(71, 35, 178);
    color: #fff;
`

const Illustration = styled.img`
  width: 200px;
  margin-top : 20px;
  margin-bottom: 20px;
`

const StyleMessage = styled.div`
  font-size: 16px;
  width : 550px;
`

const FinishButton = styled(ButtonSC)`
  && {
    background-color: white;
    border: solid 2px ${BUTTON_COLORS.purple};
    font-weight: 500;
    font-size: 14px;
    width: 100%;
    color: ${BUTTON_COLORS.purple};
  }
    &&:hover{
    background-color: rgba(102, 51, 255, .1);
    color: ${BUTTON_COLORS.purple};
  }
`

const HtmlTooltip = styled(({ className, ...props }) => (
    <Tooltip {...props} classes={{ popper: className }} />
))(({ theme }) => ({
    [`& .${tooltipClasses.tooltip}`]: {
        backgroundColor: '#575B64',
        color: 'rgba(255, 255, 255)',
        maxWidth: 600,
        border: '1px solid #dadde9',
    },
}));

const PrescriptionButton = styled(TextButton)`
  text-transform: none;
  margin-right: 25px;
  &&:hover {
    background-color: rgb(71, 35, 178);
    color: ${Colors.white};
  }
`

const ButtonProfile = styled(Button)`
  && {
    font-size: 14px;
    text-transform: none;
    color: ${Colors.white};
    min-width: 30%;
    height: 40px;
    flex-grow: 0;
    margin-left: 0 !important;
    margin-bottom: 5px;
    background-color: ${Colors.bluePurple};
  }
  &&:hover {
    background-color: rgb(71, 35, 178);
    color: ${Colors.white};
  }
`

const TextStyled = styled.div`
  margin: 50px 15px 35px 15px;
  font-size: 16px;
  color: ${Colors.footerGrey};
  text-align: justify;
`

const CloseDialog = styled(CloseIcon)`
  position: absolute;
  top: 20px;
  right: 20px;
  cursor: pointer;
  color: ${Colors.footerGrey};
`

const getRandomInt = (min, max) => {
  min = Math.ceil(min);
  max = Math.floor(max);
  return Math.floor(Math.random() * (max - min) + min); // The maximum is exclusive and the minimum is inclusive
}

const ButtonsBarComponent = ({
     medicalReportFilled,
     consultationStatus,
     onCompleteButtonClick,
 }) => {
    const consultation = useSelector(state => idx(state, _ => _.consultations.consultation))
    const consultationId = consultation.id;
    const isDoctor = useSelector(state => state.auth.type === 'Doctor')
    const userId = useSelector(state => state.auth.attributes.user_id)
    const medicalReportId = consultationStatus === 'completed' ? consultation.relationships.medicalReport.data[0].id : ''
    const downloadRepporPAth = `${window.env.API_URL}/general/consultation/${consultationId}/pdf/report/${medicalReportId}`
    const editing = useSelector(state => idx(state, _ => _.loading.editMedicalReport));

    const consultationType = idx(consultation, _ => _.attributes.type);
    const isTCA = consultationType === CONSULTATION_TYPES.TCA
    const [showDeleteModal, setShowDeleteModal] = useState(false);
    const signatureCertified = useSelector(state => idx(state, _=> _.auth.relationships.certified.data[0]))
    const dispatch = useDispatch();
    const isConcilium = isConciliumCheck(consultationType);
    const specialization_type = useSelector(state => idx(state, _=> _.auth.attributes.specialization_type))
    const specialization = useSelector(state => idx(state, _=> _.auth.attributes.specialization))
    const [allowedPrescription, setAllowedPrescription] = useState(false);
    const rpps = useSelector(state => idx(state, _=> _.auth.attributes.credentials.rpps))
    const adeli = useSelector(state => idx(state, _=> _.auth.attributes.credentials.adeli))
    const workPlace = useSelector(state => idx(state, _=> _.auth.relationships.workPlace.data[0].attributes))
    const diploma = useSelector(state => idx(state, _=> _.auth.relationships.diploma.data[0].attributes))
    const [restrictedPrescription, setRestrictedPrescription] = useState(true);
    const [prescriptionOpen, setPrescriptionOpen] = useState(false)
    const isForeign = consultation.relationships.patient.data.attributes.is_foreign
    const isAdditionalMember = idx(consultation, _ => _.relationships.additionalMember.data[0].attributes.doctor.id) === userId;
    const olaqin =   useSelector(state => idx(state, _ => _.settings.values.activate_olaqin))
    const [olaqinSSO, setOlaqinSSO] = useState("sso");
    const isPaymentCompleted = idx(consultation, _ => _.attributes.invoice.paid)
    const consultationPrice = idx(consultation, _ => _.attributes.invoice.price);

    const [open, setOpen] = useState(false)
    const [randomCode, setRandomCode] = useState(null);
    const [authReqId, setAuthReqId] = useState(null);
    const [showAuthReportModal, setShowAuthReportModal] = useState(false)
    const [showSendReportModal, setShowSendReportModal] = useState(false)
    const [fetchingAuthToken, setFetchingAuthToken] = useState(false)
    const [authPSCToken, setAuthPSCToken] = useState(null)
    const [errAuthPSCToken, setErrAuthPSCToken] = useState(null);
    const [errSendMSS, setErrSendMSS] = useState(null);
    const [isSending, setIsSending] = useState(false)
    const [intervalId, setIntervalId] = useState(null)

    const [stateSendMsssResp, setStateSendMsssResp] = useState({
      open: false,
      vertical: 'center',
      horizontal: 'center',
    });

    const [severity, setSeverity] = useState("info");
    const [message, setMessage] = useState("");

    const { formatMessage } = useIntl()
    const f = id => formatMessage({ id })

    const handleResponse = (newState) => {
      setStateSendMsssResp({open: true, ...newState});
    };

    const handleCloseMSS = () => {
      setStateSendMsssResp({...stateSendMsssResp, open: false});
    };

    const mssMail = useSelector(state =>
      idx(state, _ => _.auth.relationships.mss.data.find(el => el.attributes.typeAddress === "MSS"))
    )

    useEffect( () => {
        if(specialization_type === "generalist" || specialization_type === "specialist" || specialization === "Dentist surgeon" || specialization === "Midwife" || specialization === 'Infirmière' || specialization === 'Infirmier(ère) en Pratique Avancée' || specialization === "Infirmier(ère) Diplômé d'Etat"){
            setAllowedPrescription(true)
        }
        if((diploma !== undefined) && (workPlace !== undefined) && (signatureCertified !== undefined)){
            if((rpps ||  adeli)  && (workPlace.city !== undefined) && (workPlace.country !== undefined)&& (workPlace.name !== undefined)&& (workPlace.postcode !== undefined) && (workPlace.street !== undefined)&& (diploma.path !== undefined)){
                setRestrictedPrescription(false)
            }
        }
    },[])

    useEffect(() => {
      dispatch(fetchSpecializations())
      if (consultationStatus === "completed") {
        dispatch(fetchMedicalOfficeSoftwares())
      }
    }, [])

    //If the ciba auth was correctly started we start polling with the token to get the state of the validation
    useEffect(() => {
      if (authReqId) {
        let cibaError = null;
        let startTime = new Date().getTime();
        let intervalPoll = setInterval(() => {
          //If 2 min have passed the auth reqId is now invalid and the user cannot connect anymore
          if (new Date().getTime() - startTime > 120000) {
            clearInterval(intervalPoll)
            setErrAuthPSCToken(cibaError)
            return;
          }
          pollCibaToken(authReqId)
            .then((res) => {
              setAuthPSCToken(res.data.access_token)
              clearInterval(intervalPoll)
              setShowAuthReportModal(false)
              closeDialogAuthMss()
              setShowSendReportModal(true)
            })
            .catch((err) => {
              cibaError = err.response?.data?.data;
            })
        }, 5000)
        setIntervalId(intervalPoll)
      }
    }, [authReqId])

    const [showDialogInvoice, setShowDialogInvoice] = useState(false);
    const [isPaymentDialogOpened, togglePaymentDialog] = useState(true)

    const useOlaqin = useSelector(state => (state.settings.values.activate_olaqin))

    const history = useHistory()

  const [ selectedEmployement, setSelectedEmployement] = useState();
  const employementType = useSelector(state => (state.auth.attributes.type_employment));
  const onValidate = (isSimple) => {
    if(isSimple){
      if(employementType === 'merge'){
        dispatch(editMedicalReport({doctor_type_employment : selectedEmployement }))
      } else {
        dispatch(editMedicalReport({doctor_type_employment : employementType } ))
      }
    } else {
      onCompleteButtonClick()
    }
  }
    const myDoctorId = useSelector(state => state.auth.id);
    const isMyEssTer =  consultation.relationships.doctorCreator !== undefined ? consultation.relationships.doctorCreator.data.id === myDoctorId : ''

    const addPrescription = (state) => {
        dispatch(addPrescriptionChat(consultationId))
    }

  const closeDialogAuthMss = () => {
    clearInterval(intervalId)
    setShowAuthReportModal(false);
    setErrAuthPSCToken(null);
  }

  const closeDialogSendMss = () => {
    setShowSendReportModal(false);
    setErrAuthPSCToken(null);
    setErrSendMSS(null);
  }

  const handleClick = () => {
    if (isConcilium && !isTCA) {
      setShowDeleteModal(true);
    } else {
      onCompleteButtonClick();
    }
  };

  //Si l'utilisateur s'est authentifié avec Pro Santé Connect on récupère directement le token dans le back.
  // Si non on commence une authentification ciba
  const startAuthMss = () => {
    setShowSendReportModal(true);
  }

  const sendReportMss = (software = null) => {
    if(mssMail?.attributes.address) {
      setIsSending(true);
      sendMailToMss(mssMail?.attributes.address, consultationId, medicalReportId, software)
        .then(() => {
          //The mail was correctly sent to the MSS so we display a small alert box with success
          setIsSending(false);
          setSeverity("success")
          setMessage(f('consultation.send.mss.success'))
          handleResponse({vertical: 'top', horizontal: 'right'})
          dispatch(fetchSettings())
          closeDialogSendMss();
        })
        .catch(() => {
          setIsSending(false);
          setErrSendMSS(true);
        });
    }
  }

  const sendToOlaqin = () => {
    const patientData = consultation.relationships.patient.data.attributes;
    const ticket_id = consultation.id;
    const prenom = patientData.first_name;
    const nom = patientData.last_name;
    const dateNaissance = new Date(Date.parse(patientData.date_of_birth));
    const dateNaissanceFormatted = dateNaissance.getDate()+'/'+(dateNaissance.getMonth()+1)+'/'+dateNaissance.getFullYear()
    const isPscLogon = localStorage.getItem('connectionMode') === connectionMode.psc
    if(isPscLogon){
      setOlaqinSSO("prosanteconnect")
    }
    const gender = patientData.gender === "male" ? "MASCULIN" : "FEMININ"
    const nir = patientData.medical_insurance
    sendOlaqinPatient(ticket_id,prenom,nom,dateNaissanceFormatted,olaqinSSO,nir,gender)
  }

  const myUserId = useSelector(state => state.auth.attributes.user_id)
  const canUserWritePrescription = consultation.relationships?.doctor?.data.attributes.user_id === myUserId;
  //user can create an invoice only if he is the requis or the requerant
  let canCurrentUserMakeInvoice = idx(consultation, _ => _.relationships.doctorCreator.data.attributes.user_id) === myUserId ||
    idx(consultation, _ => _.relationships.doctor.data.attributes.user_id) === myUserId

  // Si la consutation est une tca, on récupère le requérant dans additionalMember et pas doctorCreator
  if((consultationType === 'TCA' || consultationType === 'TER' || consultationType === 'telexpertise') &&
    idx(consultation, _ => _.relationships.additionalMember.data[0].attributes.doctor.id) === myUserId)
  {
    canCurrentUserMakeInvoice = true
  }

  return (
        <Wrapper>
            {
                (allowedPrescription && !isAdditionalMember && canUserWritePrescription) && (
                    <div>
                        <span>
                            <PrescriptionButton
                                variant='extended'
                                color='primary'
                                sx={{zIndex: 'auto'}}
                                onClick={() => { restrictedPrescription ? setOpen(true) : setPrescriptionOpen(true) }}
                            >
                                <NoteAddIconMarge/>
                                <FormattedMessage id='consultation.prescription.write'/>
                            </PrescriptionButton>
                            <Dialog
                                open={open}
                                onClose={() => setOpen(false)}
                                PaperProps={{
                                    style: {
                                        maxWidth: '600px',
                                        width: '600px',
                                        padding: '1%',
                                        margin: '1%'
                                    }
                                }}
                            >
                                <CloseDialog onClick={() => setOpen(false)}/>
                                <TextStyled>
                                    <FormattedMessage id='consultation.incomplete.profile' values={{'strong': (...chunks) => (<strong>{chunks}</strong>)}}/>
                                </TextStyled>
                                <div style={{textAlign: 'center', marginBottom: '4px'}}>
                                    <ButtonProfile
                                        size='small'
                                        color='primary'
                                        onClick={() => history.push('/panel/profile')}
                                        variant='contained'
                                    >
                                        <FormattedMessage id='global.access.profile'/>
                                    </ButtonProfile>
                                </div>
                            </Dialog>
                        </span>
                    </div>
                )
      }
      <ReportButtons>
        {
            consultationStatus === 'completed' && medicalReportFilled && (
                <OpenSecuredInNewTab action={downloadRepporPAth}>
                  <TextButton
                      variant='extended'
                      color='primary'
                  >
                    <FormattedMessage id='consultations.report' />
                  </TextButton>
                </OpenSecuredInNewTab>
            )
        }
      </ReportButtons>
      {
        consultationStatus === 'completed' && !isDoctor && !isPaymentCompleted &&
        <>
          <TextButton
            variant='extended'
            color='secondary'
            onClick={() => togglePaymentDialog(true)}
          >
            <FormattedMessage id='consultation.complete.payment'/>
          </TextButton>
          <MethodSelectDialog
            onClose={() => togglePaymentDialog(false)}
            open={isPaymentDialogOpened}
            price={consultationPrice}
          />
        </>
      }
      {
        consultationStatus === 'completed' && isDoctor &&
          ( workPlace && !isForeign ?
          <FeuilleDeSoinButtons>
          { canCurrentUserMakeInvoice && (
            <>
              <TextButton
                variant='extended'
                color='secondary'
                onClick={() => setShowDialogInvoice(true)}
              >
                <FormattedMessage id='payments.download'/>
              </TextButton>
              <DialogInvoice
                open={showDialogInvoice}
                handleClose={() => setShowDialogInvoice(false)}
              />
            </>
          )}
            {
                consultationStatus === 'completed' && useOlaqin && olaqin === "1" && (
                    <TextButton
                        variant='extended'
                        color='secondary'
                        onClick={() => sendToOlaqin()}
                    >
                      <FormattedMessage id='olaqin.send.information'/>
                    </TextButton>
                )
            }
          </FeuilleDeSoinButtons>
          :
          <HtmlTooltip
            title={
              <React.Fragment>
                <FormattedMessage id={!workPlace ? 'error.workplace' : 'error.foreign.patient'}/>
              </React.Fragment>
            }
            placement='top-start'
          >
            <FeuilleDeSoinButtons>
              <TextButton
                variant='extended'
                color='secondary'
                disabled
              >
                <FormattedMessage id='payments.download'/>
              </TextButton>
            </FeuilleDeSoinButtons>
          </HtmlTooltip>)
      }
      {consultationStatus === 'completed' && medicalReportFilled && mssMail && (
        <>
          <SendMSSButton>
            <TextButton
              variant='extended'
              color='secondary'
              onClick={startAuthMss}
            >
              {fetchingAuthToken ?
                  <CircularProgress style={{color: "white"}}/> :
                  <FormattedMessage id='send.mss.report'/>
              }
            </TextButton>
          </SendMSSButton>
          <DialogAuthMss
            onClose={closeDialogAuthMss}
            onConfirm={()=> null}
            open={showAuthReportModal}
            error={errAuthPSCToken}
            randomCode={randomCode}
          />
          <DialogSendMss
            onClose={closeDialogSendMss}
            onConfirm={sendReportMss}
            open={showSendReportModal}
            error={errSendMSS}
            isSending={isSending}
          />
        </>
        )
      }
        {(consultationStatus !== 'completed' && isMyEssTer !== true) && (
            <TextButtonStyled
                variant='extended'
                color='secondary'
                sx={{zIndex: 80}}
                onClick={handleClick}
            >
                <CheckIconRegular />
                {consultationType === 'telexpertise' || consultationType === 'TER' || consultationType === 'TER_ESS'
                    ? <FormattedMessage id='concilium.complete' />
                    : consultationType === 'TCA' ? <FormattedMessage id='consultations.finish.TCA' /> : <FormattedMessage id='consultations.complete' />}
            </TextButtonStyled>
        )}
        {
             (
                <Dialog
                    open={showDeleteModal}
                    PaperProps={{
                        style: { borderRadius: 10, minHeight: '150px' }
                    }}
                >
                    <Content>
                        <LineEnd>
                            <CloseIconStyled onClick={() => setShowDeleteModal(false)}>
                            </CloseIconStyled>
                        </LineEnd>
                        <DialogTitle id='alert-dialog-title'>
                            {consultationType === 'TCA' ? <FormattedMessage id='Terminer la téléconsultation assistée'/> : <FormattedMessage id='consultations.finish.TE'/>}
                        </DialogTitle>
                        <DialogContent>
                          <Line>
                            <StyleMessage>
                              {
                                consultationType === 'TCA'
                                  ?
                                    <FormattedMessage id='consultations.finish.TCA.message'/>
                                  :
                                    <>
                                        <FormattedMessage id='consultations.finish.teleexpertise.message' values={{'strong': (...chunks) => (<strong>{chunks}</strong>)}}/>
                                    </>
                              }
                            </StyleMessage>
                          </Line>
                            <Illustration src={ illustration_popup_completed } alt='illustration_popup_completed' />
                                <Column>
                              {
                                consultationType === CONSULTATION_TYPES.TCA && consultationType === CONSULTATION_TYPES.urgent
                                  ?
                                    <>
                                      <ButtonSC onClick={() => onValidate(false)} isFetching={editing}>
                                        <FormattedMessage id='auth.avis.in.detailed' />
                                      </ButtonSC>
                                      <div style={{width: "100%", height: "14px", borderBottom: `1px solid ${Colors.greyText}`, textAlign: "center", margin: "1em 0"}}>
                                          <span style={{fontSize: "20px", backgroundColor: "white", padding: "1em .5em",  color: `${Colors.greyText}`}}>
                                            <FormattedMessage id='consultations.or_text' />
                                          </span>
                                      </div>
                                      <FinishButton onClick={() => onValidate(true)} isFetching={editing}>
                                        <FormattedMessage id='consultations.finish.TE' />
                                      </FinishButton>
                                    </>
                                  :
                                    <ButtonSC onClick={() => onValidate(true)} isFetching={editing}>
                                      <FormattedMessage id='global.finish' />
                                    </ButtonSC>
                              }
                            </Column>
                        </DialogContent>
                    </Content>
                </Dialog>
            )
        }
      {
          !!prescriptionOpen && (
              <PrescriptionDialog
                  opened={!!prescriptionOpen}
                  onClose={() => setPrescriptionOpen(false)}
                  onSaved={(p) => addPrescription(p)}
              />
          )
      }
          <Snackbar
            anchorOrigin={{
              vertical: stateSendMsssResp['vertical'],
              horizontal: stateSendMsssResp['horizontal']
            }}
            key={`${stateSendMsssResp['vertical']},${stateSendMsssResp['horizontal']}`}
            open={stateSendMsssResp['open']}
            autoHideDuration={6000}
            onClose={handleCloseMSS}
          >
            <Alert onClose={handleCloseMSS} severity={severity}>
              {message}
            </Alert>
          </Snackbar>
    </Wrapper>
  )
}

ButtonsBarComponent.propTypes = {
  medicalReportFilled: PropTypes.bool.isRequired,
  consultationStatus: PropTypes.string.isRequired,
  onCompleteButtonClick: PropTypes.func.isRequired,
}

const enhance = compose(
  connect(
    state => ({
      consultationStatus: idx(state, _ => _.consultations.consultation.attributes.status),
    }),
  ),
  withProps(props => ({
    onCompleteButtonClick: () => history.push(`/panel/${history.location.pathname.includes('teleconsultations') ? 'teleconsultations' : 'consultations'}/session/report`),
  })),
)

export const ButtonsBar = enhance(ButtonsBarComponent)

import React, {useEffect, useState} from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import { compose, mapProps, withStateHandlers } from 'recompose'
import Delete from '@mui/icons-material/Delete'
import { Colors } from 'constants/colors'
import Button from '@mui/material/Button'
import { FormattedMessage, useIntl } from 'react-intl'
import { DialogWindow } from '../DialogWindow'
import { PanelBar } from '../../PanelBar'
import { TextButton } from '../TextButton'
import {useSelector} from "react-redux";
import {CONSULTATION_TYPES} from "../../../../constants/consultation";
import {useMediaQuery} from "@mui/material";

const Wrapper = styled.div`
  position: relative;
  box-sizing: border-box;
  min-height: 100%;
  padding-bottom: 60px;
  background-color: ${props => (props.background ? Colors.background : Colors.white)};
`

const Content = styled.div`
  width: 720px;
  margin: 0 auto;
`

const Continue = styled.div`
  position: ${props => props.isNewTC ? 'flex' : 'fixed'};
  bottom: 0;
  background-color: ${props => props.isNewTC ? '' : Colors.white};
  right: 0;
  //269px is the size of the left panel and 63px its size when folded
  width: ${props => props.folded ? 'calc(100% - 63px)' : 'calc(100% - 269px)'};
  padding: 10px 24px;
  text-align: ${props => props.isNewTC ? 'center' : 'right' };
  box-shadow: ${props => props.isNewTC ? '' : ('0 -1px 8px 0 ' + Colors.azure)};
  border: 1px solid ${Colors.grey};
  box-sizing: border-box;

  button {
    font-size: 14px;
    font-weight: 500;
    height: 40px;
    min-width: 148px;
    text-transform: none;
    background-color: ${props => props.isNewTC ? Colors.bluePurple : ''};
    color: ${props => props.isNewTC ? 'white' : ''};
    border: ${props => props.isNewTC ? '2px solid #6633FF' : ''};
    ${props => props.isNewTC ? `
      &:hover{
        background-color: white;
        color: #6633FF;
        cursor: pointer;
        border: 2px solid #6633FF;
        box-shadow: none;
    }` : ``}
  }
`

const CancelButton = styled(Button)`
  && {
    margin-right: 12px;
    border-radius: 30px;
  }
`

const EditPageComponent = ({
  label,
  onBackIconClick,
  onDeleteIconClick,
  buttonText,
  onButtonClick,
  loading,
  isDialogOpen,
  toggleDialog,
  dialogTitle,
  children,
  background,
  cancelButtonText,
  onCancelButtonClick,
  hideButton,
  disabledButton,
  currentStep,
  consiliumStatus,
  pageEdited,
}) => {
  const { formatMessage } = useIntl()
  const isConsilium = consiliumStatus && consiliumStatus.consiliumStatus;
  const location = window.location.pathname
  const consulationType  =  Object.keys(useSelector(state => state.consultations.consultation)).length !== 0 ? useSelector(state => state.consultations.consultation.attributes.type) : ''
  const isTCA = consulationType === CONSULTATION_TYPES.TCA
  const isTC = consulationType === CONSULTATION_TYPES.scheduled || consulationType === CONSULTATION_TYPES.urgent
  const isNewTC = pageEdited === 'newTC';
  const [folded, setFolded] = useState(false);

  const smallScreen = useMediaQuery(`(max-width:1000px)`);

  useEffect(() =>{
    smallScreen ? setFolded(true) : setFolded(false)
  }, [smallScreen])

  return (
    <Wrapper background={background}>
      <>
        <DialogWindow
          open={isDialogOpen}
          title={dialogTitle}
          buttonText={formatMessage({id: 'global.delete'})}
          closeButtonText={formatMessage({id: 'global.cancel'})}
          onButtonClick={onDeleteIconClick}
          onClose={toggleDialog}
        />
        <PanelBar
          label={label}
          onBackIconClick={onBackIconClick}
          onIconClick={onDeleteIconClick && toggleDialog}
          Icon={Delete}
        />
      </>
      <Content>
        {children}
          <Continue isNewTC={isNewTC} folded={folded}>
            {
              !!cancelButtonText && (
                <CancelButton onClick={onCancelButtonClick} color='secondary' variant='text'>
                  <FormattedMessage id={cancelButtonText} defaultMessage={cancelButtonText} />
                </CancelButton>
              )
            }
            {/*Formulaire du rapport medical de la TC*/}
            { (isTC || isTCA) && (
                currentStep === 2 && (
                  <TextButton disabled={disabledButton} color='secondary' variant='extended' onClick={onButtonClick} isFetching={loading}>
                    <FormattedMessage id={buttonText} defaultMessage={buttonText} />
                  </TextButton>
                )
            )}

            {/*Formulaire du rapport medical de la TE/TER*/}
            {  location !==  '/panel/format/rapport/medical' && (
                hideButton ? null : (
                    (isConsilium)
                    && (
                        <TextButton disabled={disabledButton} color='secondary' variant='extended' onClick={onButtonClick} isFetching={loading}>
                          <FormattedMessage id={buttonText} defaultMessage={buttonText} />
                        </TextButton>
                    )
                )
              )
            }

            {/* Autres Formulaires (Edit profile) */}
            { location !==  '/panel/format/rapport/medical' && (
                hideButton ? null : (
                    !isConsilium && location !==  '/panel/consultations/session/report'  && location !==  '/panel/teleconsultations/session/report'
                    && (
                        <TextButton disabled={disabledButton} color='secondary' variant='extended' onClick={onButtonClick} isFetching={loading}>
                          <FormattedMessage id={buttonText} defaultMessage={buttonText} />
                        </TextButton>
                    )
                )
              )
            }
          </Continue>
      </Content>
    </Wrapper>
  )
}

EditPageComponent.defaultProps = {
  onDeleteIconClick: false,
  loading: false,
  dialogTitle: '',
  background: false,
  cancelButtonText: undefined,
}

EditPageComponent.propTypes = {
  label: PropTypes.string.isRequired,
  onBackIconClick: PropTypes.func.isRequired,
  onDeleteIconClick: PropTypes.oneOfType([PropTypes.func, PropTypes.bool]),
  onButtonClick: PropTypes.func.isRequired,
  buttonText: PropTypes.string.isRequired,
  loading: PropTypes.bool,
  isDialogOpen: PropTypes.bool.isRequired,
  toggleDialog: PropTypes.func.isRequired,
  dialogTitle: PropTypes.string,
  children: PropTypes.node.isRequired,
  background: PropTypes.bool,
  cancelButtonText: PropTypes.string,
}

const enhance = compose(
  withStateHandlers({
    isDialogOpen: false,
  }, {
    toggleDialog: ({ isDialogOpen }) => () => ({ isDialogOpen: !isDialogOpen }),
  }),
  mapProps(props => ({
    ...props,
    onDeleteIconClick: !props.onDeleteIconClick ? false : () => {
      props.toggleDialog()
      props.onDeleteIconClick(props.id)
    },
  })),
)

export const EditPage = enhance(EditPageComponent)

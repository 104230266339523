import React from 'react';
import AsyncSelect from 'react-select/async';
import axios from 'axios';
import get from 'lodash/get';
import {FormattedMessage, useIntl} from 'react-intl';
import styled from 'styled-components';
import {Colors} from '../../../../constants/colors';
import ListItem from "@mui/material/ListItem";
import ListItemText from "@mui/material/ListItemText";
import parse from 'autosuggest-highlight/parse'
import match from 'autosuggest-highlight/match'
import {startCase, toLower} from "lodash";

const getLabel = (labelPath, value, inputValue) => {
    if (Array.isArray(labelPath)) {
        return (
            <ListItem disableGutters key={get(value, labelPath[3], '')} style={{alignItems: 'flex-start'}}>
                <ListItemText
                    primary={highlightSearchInput(inputValue, startCase(toLower(get(value, labelPath[0], '') )), " ", startCase(toLower(get(value, labelPath[1], ''))))}
                    secondary={secondaryText(inputValue, get(value, labelPath[2], ''), get(value, labelPath[3], ''), get(value, labelPath[4], ''), get(value, labelPath[5], ''))}
                />
            </ListItem>
        )
    }
    return get(value, labelPath, '');
}

const highlightSearchInput = (input, ...words) => {
  return (
      <div>
          {words.map((word, index) => (
              highlightWord(word, input)
          ))}
      </div>
  )
}

const highlightWord = (value, input) => {
    const matches = match(value, input, { insideWords: true });
    const parts = parse(value, matches);
    return (
        <span>
            {parts.map((part, index) => (
                <span
                    key={index}
                    style={{
                        fontWeight: part.highlight ? 700 : 400,
                        color: part.highlight ? Colors.bluePurple : 'unset',
                    }}
                >
              {part.text}
            </span>
            ))}
        </span>
    )
}

const secondaryText = (input, job, rpps, city, zipcode) => {
  return (
      <>
          <span style={{display: 'block'}}>{job} - {highlightWord(rpps, input)}</span>
          <span style={{display: 'block'}}>{city} {zipcode && '('+zipcode+')'}</span>
      </>
  )
}

const formatToValues = (values, {
    valuePath, labelPath, barcketsPath,
}, inputValue) => values.map(el => ({
    value: get(el, labelPath[3], ''),
    label: barcketsPath ? `${getLabel(labelPath, el, inputValue)} (${get(el, barcketsPath, '')})` : getLabel(labelPath, el, inputValue),
}))

const loadOptions = (inputValue, callback, requestUrl, val, listMembersAtCreation, paths) => {
    if (inputValue && inputValue.length && inputValue.length >= 3) {
        axios(`${requestUrl}${inputValue}`)
            .then((result) => {
                const values = get(result, 'data.data', []);
                    callback(formatToValues(values, paths, inputValue))
            })
            .catch(err => console.warn(err))
    } else {
        callback([]);
    }
};

const ErrorStyled = styled.div`
  color: #ee3c5b;
  font-size: 10px;
  padding: 4px 12px 0;
  font-size: 0.75rem;
  margin-top: ${({ noMarginError }) => (noMarginError ? '0' : '-20px')};
  position: absolute;
  border-top: 1px solid;
  width: calc(100% - 24px);
`

const ErrorContainer = styled.div`
  position: relative;
`

export const AsyncSelectMultiline = ({
                                       requestUrl,
                                       valuePath,
                                       labelPath,
                                       input,
                                       rsStyles = {},
                                       barcketsPath = false,
                                       meta,
                                       noMarginError,
                                       placeholder,
                                       imagePath,
                                       cacheOptions = true,
                                       defaultOptions,
                                       defaultInputValue,
                                       val,
                                       listMembersAtCreation,
                                       noOptionsTxt
                                 }) => {
    const { formatMessage: f } = useIntl()

    return (
        <div>
            <AsyncSelect
                key='input-select'
                styles={{
                  ...rsStyles,
                  container: provided => ({...provided, '&': {zIndex: 1500}}),
                  valueContainer: provided => ({...provided, '&': {height: '60px'}}),
                  control: provided => ({
                    ...provided,
                    '&': {height: '64px', boxShadow: 'none'},
                    '&:hover': {borderColor: Colors.black, boxShadow: 'none'},
                    '&:focus-within': {
                      borderColor: Colors.bluePurple,
                      borderWidth: '2px',
                      boxShadow: 'none',
                      cursor: 'text'
                    }
                  })
                }}
                placeholder={f({ id: placeholder || 'global.search' })}
                cacheOptions={cacheOptions}
                loadOptions={(inputValue, callback) => (
                    loadOptions(inputValue, callback, requestUrl, val, listMembersAtCreation, {
                        valuePath, labelPath, barcketsPath, imagePath,
                    })
                )}
                defaultValue={defaultInputValue}
                defaultOptions={defaultOptions}
                controlShouldRenderValue
                onChange={(value) => {
                    input.onChange(value && value.value);
                }}
                noOptionsMessage = { noOptionsTxt ? (() => f({id: noOptionsTxt})) : (() => '')}
            />
            {meta && meta.error !== undefined && meta.error.props.id && (
                <ErrorContainer>
                    <ErrorStyled noMarginError={noMarginError} key='error-container'>
                        <FormattedMessage id={meta.error.props.id} />
                    </ErrorStyled>
                </ErrorContainer>
            )
            }
            {meta && meta.error && meta.touched ? (
                <ErrorContainer>
                    <ErrorStyled noMarginError={noMarginError} key='error-container'>
                        {meta.error}
                        <FormattedMessage id={meta.error.props.id} />
                    </ErrorStyled>
                </ErrorContainer>
            ) : ''}
        </div>
    )
}

import React, { useRef, useEffect, useState } from 'react'
import { Chart, registerables } from 'chart.js';

Chart.register(...registerables);

const options = {
  maintainAspectRatio: false,
  legend: {
    display: true,
    position: 'bottom',
    maxSize: {
      height: 50,
    },
    labels: {
      boxHeight: 10,
    },
  },
  plugins: {
    filler: {
      propagate: true,
    },
  },
  scales: {
    yAxes: [
      {
        ticks: {
          beginAtZero: true,
          precision: 0,
        },
      },
    ],
  },
}

export const GridChartCard = ({ data, currentPeriod }) => {
  const chartContainer = useRef(null);
  const [chartInstance, setChartInstance] = useState(null);

  useEffect(() => {
    if (chartContainer && chartContainer.current) {
      if (chartInstance && chartInstance.destroy) {
        chartInstance.destroy();
      }
      const newChartInstance = new Chart(chartContainer.current, {
        type: 'line',
        data,
        options,
      })
      setChartInstance(newChartInstance);
    }
  }, [currentPeriod])

  return (
    <div>
      <canvas height={240} ref={chartContainer} />
      <div data={data} options={options} />
    </div>
  ) 
}

import React, { useState, useEffect, useRef } from 'react'
import PropTypes from 'prop-types'
import { compose, withProps } from 'recompose'
import styled from 'styled-components'
import idx from 'idx'
import get from 'lodash/get'
import { connect, useDispatch, useSelector } from 'react-redux';
import StarIcon from '@mui/icons-material/Star'
import Pagination from '@mui/material/Pagination';
import { DoctorCard } from '../../../common/DoctorCard'
import { EmptyScreen } from './EmptyScreen'
import {
  fetchDoctors,
  fetchDoctor,
  toggleFavorite,
} from '../../../../redux/modules/doctors'
import { inviteDoctor } from '../../../../redux/modules/consultations'
import { Page } from '../../../common/Page'
import { history } from '../../../../utils/history'
import { getDoctorPrice } from '../../../../helpers/consultations'
import { UrgentToggle } from '../../Doctor/Concilium/UrgentToggle'
import { FastToggle } from '../../Doctor/Concilium/FastToggle'
import { TeamFilter } from './TeamFilter'
import { Search } from '../../../common/Search';
import { searchDoctors } from '../../../../redux/modules/settings';
import {GeoSearch} from "../../../common/GeoSearch";
import {useIntl} from "react-intl";
import CustomLoaderOverlay from "../../../common/CustomLoaderOverlay";

const Content = styled.div`
  width: 750px;
  margin: 0 auto;

  & > * {
    margin-bottom: 24px;
  }
`

const TopAnkor = styled.div`
  position: absolute;
  top: 0;
`
const GridFilters = styled.div`
  width: 100%;
  display: grid;
  grid-gap: 10px;
  grid-template: repeat(1, 1fr) / 20% 55% 20%; 
  @media screen and (max-width: 1600px) {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
`

const StyledFilters = styled.div`
  grid-area: 1/2/1/2;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`

const StyledPagination = styled(Pagination)`
  & > ul {
    align-items: center;
    justify-content: center;
  }
`

const StyledTeam = styled.div`
  grid-row: 1/1;
  grid-column: 3/3;
  display: flex;
  justify-content: flex-end;
  @media screen and (max-width: 1600px) {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
`

const usePrevious = (value) => {
  const ref = useRef();
  useEffect(() => {
    ref.current = value;
  });
  return ref.current;
}

export const ListComponent = ({
  onButtonClick,
  onButtonClickTER,
  onIconClick,
  onAvatarClick,
  doctors,
  favoriteToggle,
  toggleFavorite,
  title,
  emptySubtitle,
  emptyTitle,
  isFetching,
  match,
  isDoctor,
  fetchDoctors,
  paginator,
  isFavorite,
}) => {
  const { formatMessage } = useIntl()
  const f = id => formatMessage({ id })
  const [urgentToogle, setUrgentToogle] = useState(false)
  const [fastToogle, setFastToogle] = useState(false)
  const [team, onTeamChange] = useState(null)
  const [lazyFetching, setLazyFetching] = useState(false)
  const [page, setPage] = useState(1)
  const search = useSelector(state => state.settings.doctorSearch)
  const reverseGeocoding = useSelector(state => state.geoloc.reverseGeocoding)
  const currentProfile = useSelector(state => state.auth);
  const isTCA = history.location.pathname.includes('teleconsultation')
  const currentDoctorStatus = get(currentProfile, 'attributes.status', '');
  const dispatch = useDispatch()
  const filter = {
    name: search, team, page, status_availability: 0, 
  };
  const prevPage = usePrevious(page)
  const ankor = useRef(null)
  const executeScroll = () => ankor.current.scrollIntoView(true)
  const allowToBookTE = currentProfile.type !== 'Doctor' || currentDoctorStatus === 'VERIFIED';

  useEffect(() => () => dispatch(searchDoctors('')), []);

  useEffect(() => {
    const additionalFilter = {};
    //if (isFirstRun.current) {
    //  isFirstRun.current = false;
    additionalFilter.random = 1;
    //}
    const notPageChanged = prevPage === page;
    const isAdditional = !!match.params.consultationId;

    setLazyFetching(!!notPageChanged,
      fetchDoctors(+match.params.doctorType, isAdditional, {
        ...filter,
        ...(urgentToogle ? { immediate: 1 } : {}),
        ...(fastToogle ? { status_availability: 1 } : {}),
        ...(notPageChanged ? { page: 1 } : {}),
        ...additionalFilter,
      }))
  }, [team, search, page, urgentToogle, fastToogle, reverseGeocoding])

  const ClearlyTeamFilter = (teamId) => {
    dispatch(searchDoctors(''));
    setUrgentToogle(false);
    onTeamChange(teamId);
  }

  return (
    <Page
      label={title || 'global.doctors'}
      Icon={StarIcon}
      onBackIconClick={history.goBack}
      onIconClick={onIconClick}
      isFetching={isFetching && !lazyFetching}
      fullWidth
    >
      <TopAnkor ref={ankor} />
      <GridFilters>
      </GridFilters>
      <div style={{ margin: 'auto', width: '750px'}}>
        <Search value={search} onSearch={v => dispatch(searchDoctors(v))} />
        <GeoSearch placeholder={f('global.geo.where')} />
      </div>
      {
        doctors.length
          ? (
            <Content>
              {doctors.map(({ attributes, id, relationships }) => (
                <DoctorCard
                  key={id}
                  id={id}
                  status_availability={attributes.status_availability}
                  onButtonClick={onButtonClick(id, false)}
                  onButtonClickTER={onButtonClickTER(id, true)}
                  disableButtonClick={!allowToBookTE}
                  onAvatarClick={onAvatarClick(id)}
                  firstName={attributes.first_name}
                  lastName={attributes.last_name}
                  userStatus={attributes.status}
                  experience={attributes.experience}
                  specialization={attributes.specialization}
                  specialization_type={attributes.specialization_type}
                  photo={attributes.photo}
                  attributes={attributes}
                  language={attributes.language}
                  location={idx(relationships, _ => _.workPlace.data.slice(-1)[0].attributes)}
                  distance={attributes.distance}
                  available={idx(attributes, _ => _.calendar_nearest.TC)}
                  isFavorite={attributes.favorite}
                  isFavoriteFetching={favoriteToggle === id}
                  disableFavorite={id == currentProfile.id}
                  toggleFavorite={value => toggleFavorite(id, value)}
                  buttonText={isDoctor ? 'concilium.other.disponibilities' : undefined}
                  teams={
                    team
                      ? attributes.simpleTeams.sort(el => (el && el.id === Number(team) ? -1 : 1))
                      : attributes.simpleTeams
                  }
                  onTeamChange={ClearlyTeamFilter}
                  isDoctor={isDoctor}
                  calendarNearestTE={ isDoctor ? get(attributes, 'calendar_nearest.TE', false) : get(attributes, 'calendar_nearest.TC', false)}
                  /* TODO: add attributes to doctor in API */
                  price={getDoctorPrice(attributes, match.params.consultationType)}
                  link={`${match.path.replace(':doctorType', '')}doctor/` + id}
                />
              ))}
            </Content>
          )
          : <EmptyScreen Icon={StarIcon} subtitle={emptySubtitle} title={emptyTitle} />
      }
      <StyledPagination
        onChange={(e, page) => {
          setPage(page)
          executeScroll()
        }}
        color='primary'
        page={get(paginator, 'currentPage', 1)}
        count={get(paginator, 'totalPage', 0)}
      />
    </Page>
  )
}

ListComponent.defaultProps = {
  onIconClick: null,
  title: '',
  emptySubtitle: '',
  emptyTitle: '',
}

ListComponent.propTypes = {
  doctors: PropTypes.arrayOf(PropTypes.object).isRequired,
  onBackIconClick: PropTypes.func.isRequired,
  onIconClick: PropTypes.func,
  onButtonClick: PropTypes.func.isRequired,
  onButtonClickTER: PropTypes.func.isRequired,
  onAvatarClick: PropTypes.func.isRequired,
  favoriteToggle: PropTypes.oneOfType([PropTypes.bool, PropTypes.number]).isRequired,
  toggleFavorite: PropTypes.func.isRequired,
  title: PropTypes.string,
  emptySubtitle: PropTypes.string,
  emptyTitle: PropTypes.string,
  isFetching: PropTypes.bool.isRequired,
  match: PropTypes.object.isRequired,
  isDoctor: PropTypes.bool.isRequired,
}

const enhance = compose(
  connect(
    (state, props) => ({
      paginator: state.doctors.paginator,
      doctors: props.withUrgentToggle
        ? state.doctors.list.filter(doctor => doctor.id !== state.auth.id)
        : state.doctors.list,
      favoriteToggle: state.loading.favoriteToggle,
      isFetching: state.loading.doctors,
      isDoctor: state.auth.type === 'Doctor',
    }),
    {
      fetchDoctors,
      fetchDoctor,
      toggleFavorite,
      inviteDoctor,
    },
  ),
  withProps(props => ({
    onBackIconClick: () => {
      let url = !props.isDoctor ? '/panel/doctors' : '/panel/concilium/specializations'
      if (props.match.params.consultationId) {
        url = `/panel/concilium/${props.match.params.consultationId}/specializations`
      }
      return history.push(url)
    },
    onIconClick: () => {
      const doctorUrl = props.match.params.consultationId
        ? `/panel/concilium/${props.match.params.consultationId}/favorite`
        : '/panel/concilium/favorite'
      history.push(props.isDoctor ? doctorUrl : '/panel/doctors/favorite')
    },
    onButtonClick: (id, isUrgent) => (e, proposedDate) => {
      const url = !props.isDoctor
        ? `/panel/doctors/${props.match.params.consultationType}/create${proposedDate ? `?proposedDate=${proposedDate}` : ''}`
        : `/panel/concilium/create/${id}${isUrgent ? '?urgent=1' : ''}${proposedDate ? `${isUrgent ? '&' : '?'}proposedDate=${proposedDate}` : ''}`
      e.stopPropagation()

      if (props.isDoctor) {
        if (props.match.params.consultationId) {
          props.inviteDoctor(id, props.match.params.consultationId)
          return history.push('/panel/consultations/session')
        }

        return history.push(url)
      }

      return props.fetchDoctor(id)
        .then(() => history.push(url))
    },
    onButtonClickTER: (id, isUrgent) => (e, proposedDate) => {
      const url = !props.isDoctor
        ? `/panel/doctors/${props.match.params.consultationType}/create${proposedDate ? `?proposedDate=${proposedDate}` : ''}`
        : `/panel/concilium/create/${id}${isUrgent ? '?urgent=1' : ''}${proposedDate ? `${isUrgent ? '&' : '?'}proposedDate=${proposedDate}` : ''}`
      e.stopPropagation()

      if (props.isDoctor) {
        if (props.match.params.consultationId) {
          props.inviteDoctor(id, props.match.params.consultationId)
          return history.push('/panel/consultations/session')
        }

        return history.push(url)
      }

      return props.fetchDoctor(id)
        .then(() => history.push(url))
    },
    onAvatarClick: () => () => {
    },
    onToggleUrgent: (value) => {
      const isAdditional = !!props.match.params.consultationId
      const immediate = !props.isDoctor ? { tciimmediate: 1 } : { immediate: 1 };
      props.fetchDoctors(+props.match.params.doctorType,
        isAdditional, value ? immediate : {})
    },
  })),
)

export const List = enhance(ListComponent)

import React from 'react'
import styled from "styled-components";
import {Colors} from "../../../../constants/colors";
import {photoByType} from "./item";
import CreateIcon from '@mui/icons-material/Create';
import {AddName} from "./NewGroupDialog/AddName";
import IconButton from "@mui/material/IconButton";
import {DISCUSSION_TYPE__GROUP} from "../../../../constants/discussion";

const Name = styled.h1`
  margin: 0;
  font-size: 18px;
  font-weight: 500;
  color: ${Colors.dark};
  line-height: 1.33;
  overflow: hidden;
  text-overflow: ellipsis;
`

const Info = styled.div`
  align-items: center;
  display: flex;
  flex-grow: 1;
  margin-left: 16px;
  overflow: hidden;
`

const WrapperHeader = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  font-family: Roboto, sans-serif;
  padding: 5px 0;
  padding-left: 16px;
  box-sizing: border-box;
`

const ModifyName = styled(IconButton)`
  width: 35px;
  height: 35px;
  margin-left: 15px;
  color: ${Colors.steel};
`

const ModifyIcon = styled(CreateIcon)`
  color: black;
`

export const HeaderLeft = (discussion, showModal, setShowModal) => {

return(
    <WrapperHeader>
        { discussion && photoByType(discussion.attributes.type, discussion.attributes.photo, discussion.relationships.team?.data.attributes.type)}
        <Info>
            <Name>
                {discussion?.attributes.name}
            </Name>
            {discussion?.attributes.type === DISCUSSION_TYPE__GROUP && (
                <ModifyName onClick={() => {setShowModal(true)}}>
                    <ModifyIcon/>
                </ModifyName>
            )}
            {showModal && (
                <AddName
                  showModal={showModal}
                  onHide={() => setShowModal(false)}
                  modifyName={true}
                  discussionId={discussion.id}
                  initialName={discussion?.attributes?.name}
                />
            )}
        </Info>
    </WrapperHeader>
)
}
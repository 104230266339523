import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import { connect } from 'react-redux'
import { compose, withProps } from 'recompose'
import idx from 'idx'
import { DiplomasForm } from 'common/DiplomasForm'

import { Page } from '../../../common/Page'
import { ConsultationsList } from '../Consultations/List/ConsultationsList'
import { history } from '../../../../utils/history'
import { createHealthRecordsAttachment, deleteHealthRecordsAttachment } from '../../../../redux/modules/healthRecords'

const Wrapper = styled.div`
  margin-top: 16px;
`

const PrescriptionsComponent = ({
  consultations,
  afterConsultationClick,
  onBackIconClick,
  diplomas,
  onSubmit,
  onCreateImage,
  deleteHealthRecordsAttachment,
}) => (
  <Page
    label='hr.prescriptions'
    onBackIconClick={onBackIconClick}
  >
    <Wrapper>
      <DiplomasForm
        diplomas={diplomas}
        id='health-records-images'
        onSubmit={onSubmit}
        createDiplomas={onCreateImage}
        deleteDiploma={deleteHealthRecordsAttachment}
        acceptedExtensions='.jpg, .jpeg, .gif, .png, .heif, .heic, .doc, .docx, .xls, .xlsx, .csv, .pdf, .odt, .ods, .odp, .tex, .ppt, .pptx, .zip, .rar, .tar, .gz, .tar.gz, .7z, .txt, .md, .mov, .mpg, .mpeg, .mkv, .mp4'
        withFilenames
      />
      <ConsultationsList
        consultations={consultations}
        afterConsultationClick={afterConsultationClick}
      />
    </Wrapper>
  </Page>
)

PrescriptionsComponent.propTypes = {
  consultations: PropTypes.arrayOf(PropTypes.object).isRequired,
  afterConsultationClick: PropTypes.func.isRequired,
  onBackIconClick: PropTypes.func.isRequired,
  diplomas: PropTypes.arrayOf(PropTypes.object).isRequired,
  onCreateImage: PropTypes.func.isRequired,
  deleteHealthRecordsAttachment: PropTypes.func.isRequired,
  onSubmit: PropTypes.func.isRequired,
}

const enhance = compose(
  connect(
    state => ({
      consultations: idx(state, _ => _.consultations.list).filter(item => item.attributes.status === 'completed'),
      diplomas: idx(state, _ => _.healthRecords.attachment.prescriptions) || [],
      loading: state.loading.healthRecordsAttachments,
    }),
    { createHealthRecordsAttachment, deleteHealthRecordsAttachment },
  ),
  withProps(props => ({
    afterConsultationClick: () => history.push('/panel/health-records/prescriptions/prescription'),
    onBackIconClick: () => history.push('/panel/health-records'),
    onSubmit: () => history.push('/panel/health-records'),
    onCreateImage: values => props.createHealthRecordsAttachment({
      ...values,
      type: 'prescriptions',
    }),
    diplomas: props.diplomas.map(item => (item.attributes
      ? {
        id: item.id,
        file: item.attributes.url,
        extension: idx(item, _ => _.attributes.extension),
        custom_name: item.attributes.custom_name,
      }
      : { id: '0', file: '' }
    )),
  })),
)

export const Prescriptions = enhance(PrescriptionsComponent)

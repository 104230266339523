import React from 'react'
import {useIntl} from "react-intl";
import PropTypes from "prop-types";

export const DisplayGender = ({ genderCode, style }) => {
  let { formatMessage } = useIntl()

  let gender = ''
  if (genderCode) {
    if (genderCode === 'female') {
      genderCode = 'woman'
    }
    gender = formatMessage({ id: 'profile.genders.' + genderCode })
  }
  return (
    <span style={style}>{gender}</span>
  )
}

DisplayGender.propTypes = {
  genderCode: PropTypes.string.isRequired,
  style: PropTypes.object.isRequired,
}

DisplayGender.defaultProps = {
  style: {},
}
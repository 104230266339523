import React from 'react'
import {FavoriteDoctorCard} from "../../../../common/DoctorCard/FavoriteDoctorCard";

const ProCard = ({invitation}) => {
  const pro = invitation?.relationships?.invited?.data

  return (
    !!pro && !!pro.length ? (
      <FavoriteDoctorCard
        firstName={pro.prenomExercice}
        lastName={pro.nomExercice}
        isFavoriteFetching={false}
        toggleFavorite={null}
        specialization={pro.nomSpecialiteV1}
        isFavorite={true}
        isInvited={true}
        disableButtonClick={pro.attributes?.status !== 'VERIFIED'}
      />
    ) : (
      <></>
    )
  )
}

export const ProCards = ({invitations}) => (
  <>{ !!invitations && invitations.map(invitation => (<ProCard invitation={invitation} />)) }</>
)
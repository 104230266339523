import React from 'react';
import { Switch, Route } from 'react-router-dom';
import styled from 'styled-components'
import withStyles from '@mui/styles/withStyles';
import { FormattedMessage } from 'react-intl';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import Typography from '@mui/material/Typography';
import { compose, lifecycle } from 'recompose';
import idx from 'idx';
import moment from 'moment-timezone'
import { connect } from 'react-redux';
import { fetchHistory } from '../../../redux/modules/content';
import { MainPage } from './MainPage';

const styles = theme => ({
  root: {
    maxWidth: 700,
    marginTop: theme.spacing(3),
    overflowX: 'auto',
    margin: 'auto',
    background: 'transparent',
    boxShadow: 'none',
  },
  table: {
    maxWidth: '100%',
    background: '#fff',
    boxShadow: '0px 1px 5px 0px rgba(0, 0, 0, 0.2), 0px 2px 2px 0px rgba(0, 0, 0, 0.14), 0px 3px 1px -2px rgba(0, 0, 0, 0)',
  },
});

const Title = styled.div`
  font-size: 17px;
  padding-bottom: 20px;
  font-weight: 500;
`

const Sum = styled.div`
  font-size: 17px;
  padding-top: 20px;
  font-weight: 500;
`

const POLITICS = {
  privacy_policy: 'global.privacy-policy',
  terms_of_use: 'profile.termsOfUse',
  payment_policy: 'profile.paymentPolicy',
}

export const HistoryPageComponent = ({
  match,
  onBackIconClick,
  history,
  classes,
}) => (
  <>
    <Switch>
      <Route
        exact
        path={`${match.path}`}
        render={({ ...props }) => (
          <MainPage onBackIconClick={onBackIconClick} {...props} />
        )}
      />
    </Switch>
    <Paper className={classes.root}>
      <Title>
        <FormattedMessage id='profile.history' />
      </Title>
      <Table aria-label='simple table' className={classes.table}>
        <TableHead>
          <TableRow>
            <TableCell>
              <Typography variant='body1' component='span'>
                <FormattedMessage id='global.action.object' />
              </Typography>
            </TableCell>
            <TableCell align='right'>
              <Typography variant='body1' component='span'>
                <FormattedMessage id='global.action' />
              </Typography>
            </TableCell>
            <TableCell align='right'>
              <Typography variant='body1' component='span'>
                <FormattedMessage id='global.date' />
              </Typography>
            </TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {history.map(row => (
            <TableRow key={row.id}>
              <TableCell component='th' scope='row'>
                <Typography variant='subtitle2' component='span'>
                  <FormattedMessage
                    id={POLITICS[row.attributes.policy] || row.attributes.policy}
                    defaultMessage={row.attributes.policy}
                  />
                </Typography>
              </TableCell>
              <TableCell align='right'>
                <Typography variant='subtitle2' component='span'>
                  <FormattedMessage id={row.attributes.action} />
                </Typography>
              </TableCell>
              <TableCell align='right' component='span'>
                <Typography variant='subtitle2'>{moment(row.attributes.date).format('YYYY-MM-DD hh:mm:ss')}</Typography>
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
      <Sum><FormattedMessage id='global.total' />: {history.length}</Sum>
    </Paper>
  </>
);

const enhance = compose(
  connect(
    state => ({
      history: idx(state, _ => _.content.history),
    }),
    { fetchHistory },
  ),
  lifecycle({
    componentDidMount() {
      this.props.fetchHistory();
    },
  }),
);

export const HistoryPage = enhance(withStyles(styles)(HistoryPageComponent));

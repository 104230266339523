import React from 'react'
import PropTypes from 'prop-types'
import { compose, withProps, lifecycle } from 'recompose'
import { connect } from 'react-redux'
import idx from 'idx'

import { ProfileInfo } from '../../Doctor/Profile/ProfileInfo'
import { Page } from '../../../common/Page'
import { history } from '../../../../utils/history'
import { doctorIsRecieved } from '../../../../helpers/doctors'
import { toggleFavorite } from '../../../../redux/modules/doctors'
import { fetchDoctorReviews } from '../../../../redux/modules/reviews'

const ProfileComponent = ({
  attributes,
  workPlace,
  schedule,
  diplomas,
  onBackIconClick,
  onAppointmentButtonClick,
  isFavoriteFetching,
  toggleFavorite,
  id,
  reviews,
}) => (
  <Page
    label='consultation.doctor.profile'
    onBackIconClick={onBackIconClick}
  >
    <ProfileInfo
      attributes={{
        ...attributes,
        credentials: null,
        email: attributes.specialization,
      }}
      workPlace={workPlace}
      schedule={schedule}
      diplomas={diplomas}
      onAppointmentButtonClick={onAppointmentButtonClick}
      isFavorite={attributes.favorite}
      isFavoriteFetching={isFavoriteFetching}
      toggleFavorite={value => toggleFavorite(id, value)}
      reviewsCount={reviews.length}
      reviewsLink={`/panel/doctors/${id}/reviews`}
      isPatient
    />
  </Page>
)

ProfileComponent.defaultProps = {
  reviews: [],
}

ProfileComponent.propTypes = {
  attributes: PropTypes.object.isRequired,
  workPlace: PropTypes.object.isRequired,
  diplomas: PropTypes.arrayOf(PropTypes.object).isRequired,
  onBackIconClick: PropTypes.func.isRequired,
  onAppointmentButtonClick: PropTypes.func.isRequired,
  isFavoriteFetching: PropTypes.bool.isRequired,
  toggleFavorite: PropTypes.func.isRequired,
  id: PropTypes.oneOfType([PropTypes.number, PropTypes.string]).isRequired,
  reviews: PropTypes.array,
}

const enhance = compose(
  doctorIsRecieved,
  connect(
    state => ({
      attributes: idx(state, _ => _.doctors.doctor.attributes),
      schedule: idx(state, _ => _.doctors.doctor.relationships.schedule.data),
      diplomas: idx(state, _ => _.doctors.doctor.relationships.diploma.data),
      workPlace:
        !!idx(state, _ => _.doctors.doctor.relationships.workPlace.data).slice(-1)[0]
        && idx(state, _ => _.doctors.doctor.relationships.workPlace.data).slice(-1)[0].attributes,
      id: state.doctors.doctor.id,
      isFavoriteFetching: state.loading.favoriteToggle === state.doctors.doctor.id,
      reviews: state.reviews[state.doctors.doctor.id],
      isDoctor: state.auth.type === 'Doctor',
    }),
    { toggleFavorite, fetchDoctorReviews },
  ),
  withProps(props => ({
    onBackIconClick: () => history.goBack(),
    onAppointmentButtonClick: () => {
      const link = props.isDoctor
        ? `/panel/concilium/create/${props.id}`
        : `/panel/doctors/${props.match.params.consultationType}/create`
      history.push(link)
    },
  })),
  lifecycle({
    componentDidMount() {
      if (!this.props.reviews) {
        this.props.fetchDoctorReviews(this.props.id)
      }
    },
  }),
)

export const Profile = enhance(ProfileComponent)

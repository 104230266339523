import React, {useEffect, useState} from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import { mapProps } from 'recompose'
import {Form, Field} from 'react-final-form'
import { TextButton } from 'common/UiKit/TextButton'
import { FormattedMessage, useIntl } from 'react-intl'
import { TextField } from '../../../../../common/UiKit/TextField'
import { Paper } from '../../../../../common/UiKit/Paper'
import { useDispatch, useSelector } from 'react-redux'
import { fetchSpecializations } from '../../../../../../redux/modules/content'
import { SpecializationsSelect } from '../../../../../common/SpecializationsSelect'
import { COUNTRIES } from './constantes'
import InfoIcon from "@mui/icons-material/Info";
import {createProfile} from '../../../../../../helpers/fillProfile'
import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox from "@mui/material/Checkbox";
import moment from "moment-timezone";
import {DateField} from "../../../../../common/DateField";
import {AttachmentsFieldIdentify} from "./AttachmentsFieldIdentify";

const FirstLine = styled.div`
  display: flex;
  
  & > div:first-child {
    margin-right: 24px;
  }
  
  @media screen and (max-width: 500px) {
    flex-direction: column;
  }
`

const SecondLine = styled.div`
  display: flex;
  max-width : 670px;
  
  & > div:first-child {
      margin-right: 24px;
  }
  
  @media screen and (max-width: 500px) {
    flex-direction: column;
  }
`


const InputWrapper = styled.div`
  display: flex;
  flex-direction: column ;
  margin-bottom : 10px;
  padding: 0 10px !important;
  width: 100% !important;
  box-sizing: border-box ! important;
  & svg + div {
  box-shadow: none;
  background-color: transparent;
 }
`

const Wrapper = styled(Paper)`
  padding: 24px;
  margin : 20px 0;
  display: flex;
`

const Header = styled.h1`
  margin: 36px 0 0;
  font-size: 18px;
  font-weight: 500;
  color: #6633FF;
  text-align: center;
  width: 100%;
`

const Line = styled.div`
  display: flex;
  width: 100%;
  align-items: ${({ start }) => (start ? 'flex-start' : 'center')};

  .location {
    flex-grow: 1;
  }
`

const Information = styled.div`
  display: flex;
  justify-content : center;
  align-items :center;
  color: #585C65;
  max-width: 680px;
  & {
    padding: 6px 10px 6px 2px;
    margin: 5px 0 20px 0;
    font-size: 12.9px;
    font-style: italic;
    line-height: 1.11;
  }
  & > * {
    padding: 5px 10px 5px 10px;
  }
`
const WrapperLocationsFields = styled.div`
  width : 100% !important;
  box-sizing: border-box !important;
  display: flex;
  
  @media screen and (max-width: 500px) {
    flex-direction: column;
  }
`
const Continue = styled.div`
  padding: 10px 24px;
  & > * {
    z-index: 1 !important;
  }
  text-align: center;
  margin-top: 15px;
  button {
    font-size: 12px;
    font-weight: 500;
    height: 40px;
    width: 120px;
  }
`

const Label = styled.div`
  max-width: 300px;
  min-width: 200px;
  margin-bottom: 10px;
  margin-top:  ${({ isLabelIdentity }) => (isLabelIdentity ? '20px' : '5px')};
  margin-left: 14px;
  opacity: 0.87;
  font-weight: bold;
  font-size: 12.9px;
  color: #585C65;
`

const ErrorStyled = styled.div`
  max-width : 200px;
  max-height : 50px;
  color: red;
  font-size: 11px;
  text-align: center;
  margin-top: -10px;
`

const SpecializationsSelectStyled = styled(SpecializationsSelect)`
  border:  ${({ error, meta }) => ((error && meta.touched) ? 'solid 1px red' : 'solid 1px #D3D3D3')};
  border-radius: 5px;
  & > * {
    color: ${({ error, meta }) => ((error && meta.touched) ? 'red' : '')};
  }
`

const ThreeColumn = styled.div`
  display: grid;
  grid-template-columns: repeat(3,1fr);;
  margin-bottom: 10px;
  
  @media screen and (max-width: 500px) {
    display: flex;
    flex-direction: column;
  }
`

const TwoLInes = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: start;
  min-width: 100px !important;
`

const MethodWrapper = styled.div`
  width: 100%;
  margin-left : 15px;
  margin-top : -5px;
  max-height: 60px;
  & > *  > * {
    font-size: 12.9px;
  }
  & > * {
    font-size: 12.9px;
  }
  display: grid; 
  grid-template-columns: 1fr 1fr; 
  grid-template-rows: 30% 70%; 
  gap: 0 0; 
  grid-template-areas: 
    "label label"
    "checkbox1 checkbox2"; 
  .label { 
    grid-area: label;
    text-align : left;
    margin-left : 0;
   }
  .checkbox1 { 
    grid-area: checkbox1;
  }
  .checkbox2 { grid-area: checkbox2; }
`

const StyledTextButton= styled(TextButton)`
  text-transform : none;
`

const City = styled.div`
  display: flex;
  flex-direction: row;
  width: 60%;
  box-sizing: border-box;
  
  @media screen and (max-width: 500px) {
    width: 100%;
  }
`

const Country = styled.div`
  width: 40%;
  box-sizing: border-box;
  
  @media screen and (max-width: 500px) {
    width: 100%;
  }
`

const validate = (values, NotDoctor, genderOptions) => {
  const errors = {};
  if(NotDoctor) {
      if (!values.medical_insurance) {
        errors.medical_insurance = <FormattedMessage id ="error.patient.insurance.as.patient.registration"/>;
      } else if (!/^\d{13}$/.test(values.medical_insurance)) {
        errors.medical_insurance = <FormattedMessage id ="error.patient.insurance.as.patient.registration.valide"/>;
      }
      if (!values.date_of_birth) {
        errors.date_of_birth =  <FormattedMessage id ='error.patient.birthday.as.patient'/>;
      }
      const date = new Date(values.date_of_birth);
      if (isNaN(date.getTime())) {
        errors.date_of_birth =  <FormattedMessage id ='error.patient.birthday.incorrect'/>;
      }
  }
  if (!values.first_name) {
    errors.first_name = <FormattedMessage id ="error.first.name"/>;
  } else if (!/^[A-zÀ-ú\- ]+$/i.test(values.first_name)) {
    errors.first_name = <FormattedMessage id ="error.first.name.valide"/>;
  }
  if (!values.last_name) {
    errors.last_name = <FormattedMessage id ="error.last.name"/>;
  } else if (!/^[A-zÀ-ú\- ]+$/i.test(values.last_name)) {
    errors.last_name = <FormattedMessage id ="error.last.name.valide"/>;
  }
  if (!values.specialization_id) {
    errors.specialization_id = <FormattedMessage id ="error.specialization"/>;
  }
  if (!values.gender || !genderOptions.some(e => e.value === values.gender)) { // we check if the actual values is in the optionsvalues
    errors.gender = <FormattedMessage id ="errors.profile.select.gender"/>;
  }

  if (!values.hospital_name) {
    errors.hospital_name = <FormattedMessage id ="errors.profile.select.hospital.name"/>;
  }
  if (!values.practice_workplace) {
    errors.practice_workplace = <FormattedMessage id ="errors.profile.select.workplace" />;
  }
  if (!values.city) {
    errors.city = <FormattedMessage id ="errors.profile.select.city"/>;
  }
  if (!values.postcode) {
    errors.postcode = true
  } else if (!/^\d{5}$/.test(values.postcode)) {
    errors.postcode = true;
  }
  if (!values.country) {
    errors.country = <FormattedMessage id ="errors.profile.select.country"/>;
  }
  if (!values.rpps && !values.adeli && !values.finess) {
    errors.rpps = <FormattedMessage id ="error.rpps.adeli.finess.at.least.one.number"/>;
    errors.adeli = <FormattedMessage id ="error.rpps.adeli.finess.at.least.one.number"/>;
    errors.finess = <FormattedMessage id ="error.rpps.adeli.finess.at.least.one.number" />;
  }
  if (values.rpps && !/^\d{11}$/.test(values.rpps)) {
    errors.rpps = <FormattedMessage id ="error.rpps_11"/>;
  }
  if (values.adeli && !/^(?:\d{2}[A-F]\d{6}|\d{9})$/.test(values.adeli))
  {
    errors.adeli = <FormattedMessage id ="error.adeli_9"/>;
  }
  if (values.finess && !/^\d{9}$/.test(values.finess))
  {
    errors.finess = <FormattedMessage id = "error.finess.order.number_9"/>;
  }

  return errors;
}


const enhance = mapProps(props => ({
  ...props,
}))

export const InscriptionStepTwo = enhance(({
  initialValues,
  id,
  setDialogOpen,
  setLoading,
}) => {
  const { formatMessage } = useIntl()
  const f = id => formatMessage({ id })
  const specializations = useSelector(state => state.content.specializations)
  const user_id = useSelector(state => state.auth.attributes.user_id)
  const doctor_id = useSelector(state => state.auth.id)
  const isDoctor = useSelector(state => state.auth.type) === 'Doctor'
  const ProfileAccessErrorAdeli = useSelector(state => state.profileaccess.errorAdeli)
  const ProfileAccessErrorRpps = useSelector(state => state.profileaccess.errorRpps)
  const mayEdit = useSelector(state => state.auth.attributes.specialization_edit_available)
  const [selectedFile, setSelectedFile] = useState(null);
  const specialization_key = useSelector(state => state.auth.attributes.specialization_key)
  const specialization_type = useSelector(state => state.auth.attributes.specialization_type)
  const [specialization_key_user_choosing, setSpecialization_key] = useState(specialization_key)
  const [specialization_type_user_choosing, setSpecialization_type] = useState(specialization_type)
  const myUserStatus = useSelector(state => state.auth.attributes.status)
  const isDoctorWithDrPrGenderSpecializationChoosing =
       specialization_type_user_choosing === 'others'
    || specialization_type_user_choosing === 'pharmaceutical'
    || specialization_type_user_choosing === 'generalist'
    || specialization_type_user_choosing === 'specialist'
  const dispatch = useDispatch()
  const type = useSelector(state => state.auth.attributes.type_employment)
  const [[value_private, value_hospital], SetTypeEmployment] = useState(type ? [type === 'private' || type === 'merge', type === 'hospital' || type === 'merge']: [true, false])
  const annuaireRppsAdeli = JSON.parse(localStorage.getItem("rpps_adeli"));
  const location = window.location.pathname

  let genderOptions = !isDoctor || !isDoctorWithDrPrGenderSpecializationChoosing ? [
    { value: 'male', label: 'Mr' },
    { value: 'woman', label: 'Mme' }
  ] :
    [
    { value: 'doctor', label: 'Dr' },
    { value: 'professor', label: 'Pr' }
  ];

  if((specialization_key_user_choosing === 'Midwife'))
  {
    genderOptions = [
      { value: 'male', label: 'Mr' },
      { value: 'woman', label: 'Mme' }
    ]
  }


  useEffect(() => {
  }, [specialization_key_user_choosing,value_private, value_hospital, ProfileAccessErrorRpps, ProfileAccessErrorAdeli])


  useEffect(() => {
    if (!specializations.length) {
      dispatch(fetchSpecializations())
    }
  }, [])

  const onSubmit = (values) => {
    const type_employment = value_private && value_hospital ? 'merge' : value_private ? 'private' : 'hospital'
    return dispatch(createProfile(values, type_employment, isDoctor, doctor_id,  user_id , setDialogOpen, setLoading, myUserStatus ))
  }
  return (
    <Form
      onSubmit={onSubmit}
      initialValues= {annuaireRppsAdeli ? {
        ...initialValues,
        first_name: annuaireRppsAdeli ? annuaireRppsAdeli.prenomExercice : '',
        last_name: annuaireRppsAdeli ? annuaireRppsAdeli.nomExercice : '',
        date_of_birth: initialValues.date_of_birth
          ? moment(initialValues.date_of_birth).format('YYYY-MM-DD')
          : moment('1980', 'YYYY').format('YYYY-MM-DD'),
        rpps: annuaireRppsAdeli.typeIdentifiantPp === 8 ? annuaireRppsAdeli.identifiantPp : '',
        adeli: annuaireRppsAdeli.typeIdentifiantPp === 0 ? annuaireRppsAdeli.identifiantPp : '',
        hospital_name: annuaireRppsAdeli.raisonSocialeSite,
        street:  annuaireRppsAdeli.numeroVoieStructure + ' ' + annuaireRppsAdeli.libelleTypeDeVoieStructure + ' ' + annuaireRppsAdeli.libelleVoieStructure,
        postcode: annuaireRppsAdeli.codePostalStructure,
        city: annuaireRppsAdeli.libelleCommuneStructure,
        specialization_id : undefined,
      } : {
        ...initialValues,
        adeli : initialValues.credentials_adeli,
        finess: initialValues.credentials_order_number,
        rpps: initialValues.credentials_rpps,
        date_of_birth: initialValues.date_of_birth ? moment(initialValues.date_of_birth).format('YYYY-MM-DD')
          : moment('1980', 'YYYY').format('YYYY-MM-DD'),
        hospital_name: initialValues.work_place.name,
        practice_workplace: initialValues.work_place.street,
        postcode: initialValues.work_place.postcode,
        city: initialValues.work_place.city,
        country : initialValues.work_place.country,
        specialization_id : undefined,

      }}
      validate={values => validate(values, !isDoctor, genderOptions)}
      render={({ handleSubmit, values,errors }) => {
        console.log('error', errors);
        return (
          <form onSubmit={onSubmit} id={id}>
            {
              location !== "/panel/profile/edit/personal_data" &&(
                <div>
                      <Header>
                        <FormattedMessage id='profile.title.my.information' />
                      </Header>
                </div>
              )
            }
            <Wrapper>
              <div style={{width: '100%'}}>
                <FirstLine>
                  <Field name='first_name' component={TextField} label={`*${f('profile.first.name')}`} />
                  <Field name='last_name' component={TextField} label={`*${f('profile.last.name')}`} />
                </FirstLine>
                <SecondLine>
                  <Field
                    name='gender'
                    component={TextField}
                    label={`*${f('profile.gender')}`}
                    options={genderOptions}
                  />
                  <Line>
                    <MethodWrapper>
                      <Label  className = {"label"}>
                        <FormattedMessage id='profile.method'/>
                      </Label>
                      <FormControlLabel
                        className = {"checkbox1"}
                        control={(
                          <Checkbox
                            size="small"
                            color='primary'
                            value={value_private}
                            checked={value_private}
                            onChange={() => SetTypeEmployment([!value_private, value_hospital])}
                          />
                        )}
                        label={<FormattedMessage id='profile.liberal'/>}
                      />
                      <FormControlLabel
                        className = {"checkbox2"}
                        control={(
                          <Checkbox
                            size="small"
                            color='primary'
                            value={value_hospital}
                            checked={value_hospital}
                            onChange={() => SetTypeEmployment([value_private, !value_hospital])}
                          />
                        )}
                        label={<FormattedMessage id='profile.salary'/>}
                      />
                    </MethodWrapper>
                  </Line>
                </SecondLine>
                {!isDoctor && (
                  <>
                    <Field
                      name='medical_insurance'
                      component={TextField}
                      label={`*${f('profile.insurance')}`}
                    />
                    <Field
                    name='date_of_birth'
                    label={`*${f('profile.birth.date')}`}
                    render={props => <DateField props={props} minDate={moment('1900', 'YYYY')} maxDate={new Date()} errorMessage={f('error.birth.date')}/> }
                    />
                  </>
                )}
                {
                  isDoctor && (
                    <>
                      <Label>
                        <FormattedMessage id='profile.speciality.label'/>
                      </Label>
                      <Field
                        component={({input, meta}) => (
                          <InputWrapper >
                            <SpecializationsSelectStyled
                              error={errors.specialization_id}
                              setSpecialization_key={setSpecialization_key}
                              setSpecialization_type={setSpecialization_type}
                              isDisabled={!mayEdit}
                              {...input}
                              meta={meta}
                              placeholder={<FormattedMessage id='profile.specialization.choose'/>}
                            />
                          </InputWrapper>
                        )}
                        name='specialization_id'
                      />
                    </>
                  )}
              </div>
            </Wrapper>
            <Paper disableShadow={false} style={{marginBottom:'20px'}}>
              {
                isDoctor &&
               ( <div style={{width: '100%', height: '100%'}}>
                   <ThreeColumn>
                     <TwoLInes>
                       <Label>
                         <FormattedMessage id='profile.rpps.label'/>
                       </Label>
                       <Field
                         component={TextField}
                         type='text'
                         name='rpps'
                         label={`${f('profile.rpps.label')}`}
                       />
                       {ProfileAccessErrorRpps && <ErrorStyled><FormattedMessage id='error.rpps.uniq'/></ErrorStyled> }
                     </TwoLInes>
                     <TwoLInes>
                       <Label>
                         <FormattedMessage id='profile.adeli.label'/>
                       </Label>
                       <Field
                         component={TextField}
                         type='text'
                         name='adeli'
                         label={`${f('profile.adeli.label')}`}
                       />
                       {ProfileAccessErrorAdeli && <ErrorStyled><FormattedMessage id="error.adeli.uniq"/></ErrorStyled>}
                    </TwoLInes>
                    <TwoLInes>
                      <Label>
                        <FormattedMessage id='profile.number.label'/>
                      </Label>
                      <Field
                        type='text'
                        component={TextField}
                        name='finess'
                        label={`${f('profile.number.label')}`}
                      />
                    </TwoLInes>
                  </ThreeColumn>
                  <Information>
                    <InfoIcon/>
                    <FormattedMessage id='auth.register.one.of.tree.number'/>
                  </Information>
                </div>)}
            </Paper>
            <Paper>
              <div style={{width: '100%'}}>
                  <Line>
                    <Label>
                      <FormattedMessage id='profile.hospital.name.main'/>
                    </Label>
                  </Line>
                  <Line>
                    <Field
                      component={TextField}
                      name='hospital_name'
                      label={`*${f('profile.hospital.name')}`}
                      style={{width: '100%'}}
                    />
                  </Line>
                  <Line>
                    <Field
                      component={TextField}
                      name='practice_workplace'
                      label={`*${f('profile.workplace')}`}
                      style={{width: '100%'}}
                    />
                  </Line>
              </div>
              <WrapperLocationsFields>
                <City>
                  <Field
                    component={TextField}
                    name='postcode'
                    label={`*${f('profile.postcode')}`}
                    style={{maxWidth: '100px'}}
                  />
                  <Field
                    component={TextField}
                    name='city'
                    label={`*${f('profile.city')}`}
                  />
                </City>
                <Country>
                  <Field
                    component={TextField}
                    name='country'
                    label={`*${f('profile.country')}`}
                    options={COUNTRIES}
                    style={{boxSizing: 'border-box', width: '100%'}}
                  />
                </Country>
              </WrapperLocationsFields>
              {isDoctor &&
                <>
                  <Label isLabelIdentity = {true}>
                    <FormattedMessage id='profile.identity.optional' />
                  </Label>
                </>
              }
              <Field
                name='files'
                onlyAttachmentId
                accept='.jpg, .jpeg, .gif, .png, .pdf'
                component={AttachmentsFieldIdentify}
              />
              <Information>
                <InfoIcon/>
                <FormattedMessage id='auth.register.info.identity.card'/>
              </Information>
            </Paper>
            <Continue>
              <StyledTextButton disabled={!!Object.keys(errors).length} color='primary' variant='extended' type="submit" onClick={handleSubmit}>
                <FormattedMessage id='global.save.quit' />
              </StyledTextButton>
            </Continue>
          </form>
        )
      }}
    />
  )
})

InscriptionStepTwo.defaultProps = {
  disableShadow: false,
  showInsurance: false,
  initialValues: {},
}

InscriptionStepTwo.propTypes = {
  initialValues: PropTypes.shape({
    first_name: PropTypes.string,
    last_name: PropTypes.string,
    birth_date: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
    gender: PropTypes.string,
  }),
  id: PropTypes.string.isRequired,
  disableShadow: PropTypes.bool,
  showInsurance: PropTypes.bool,
}

import React, {useEffect, useState} from 'react'
import styled from 'styled-components'
import { LoadingButton } from "@mui/lab";
import {Close, LocationSearching, MyLocation, Place} from "@mui/icons-material";
import { Colors } from '../../../constants/colors'
import {FormattedMessage} from "react-intl";
import {useDispatch, useSelector} from "react-redux";
import {aroundMe, aroundMeSuccess, clearGeolocation, locAutoComplete} from "../../../redux/modules/geoloc";
import {Button} from "@mui/material";

const GeoLocation = ({location}) => (
  <>
    {
      !!location.attributes.housenumber && (<span>{location.attributes.housenumber} </span>)
    }
    {
      !!location.attributes.street && (<span>{ location.attributes.street } </span>)
    }
    {
      !!location.attributes.postcode && (<span>{ location.attributes.postcode } </span>)
    }
    {
      !!location.attributes.city && (<span>{location.attributes.city} </span>)
    }
    {
      !!location.attributes.country && (<span>{location.attributes.country} </span>)
    }
  </>
)

const geoLocationLabel = (geolocation) => {
  let label = ''
  if (geolocation.housenumber)
    label += geolocation.housenumber + ', '
  if (geolocation.street)
    label += geolocation.street + ', '
  if (geolocation.postcode)
    label += geolocation.postcode + ', '
  if (geolocation.city)
    label += geolocation.city

  return label
}

export const GeoSearch = ({ placeholder = '' }) => {
  const [inputValue, setInputValue] = useState('')
  const [searchValue, setSearchValue] = useState('')
  const [canDisplay, setCanDisplay] = useState(false)
  const searchValues = useSelector(state => state.geoloc.locationSuggestions)
  const reverseGeocoding = useSelector(state => state.geoloc.reverseGeocoding)
  const reverseGeocodingLoading = useSelector(state => state.loading.geolocAroundMe)
  const dispatch = useDispatch()

  useEffect(() => {
    if (reverseGeocoding.city) {
      setSearchValue(geoLocationLabel(reverseGeocoding))
    } else if (!reverseGeocoding.isUserSearch) {
      setSearchValue('')
    }
  }, [reverseGeocoding])

  useEffect(() => {
    setInputValue(searchValue)
  }, [searchValue])

  const selectLocation = (location) => {
    dispatch(aroundMeSuccess(location))
    setCanDisplay(false)
  }

  const hasNoParentWithId = (element, id) => {
    for (let i = 0, depth = 4; i < depth && element.parentElement; i++) {
      if (element.id === 'geoloc_suggestions')
        return false;
      // else
      element = element.parentElement || element.parentNode
    }
    return true;
  }

  document.addEventListener('click', event => {
    if (!event.target || event.target.id !== 'geosearch' && hasNoParentWithId(event.target, 'geoloc_suggestions')) {
      setCanDisplay(false)
    }
  })

  return (
    <Wrapper>
      <Place />
      <Input
        id='geosearch'
        value={inputValue}
        onFocus={() => setCanDisplay(true)}
        onBlur={() => setInputValue(geoLocationLabel(reverseGeocoding))}
        onChange={e => {
          setInputValue(e.target.value)
          dispatch(locAutoComplete(e.target.value))
        }}
        disabled={ reverseGeocodingLoading }
        placeholder={placeholder}
      />
      <div className='geoloc-load'>
        <Button onClick={() => dispatch(clearGeolocation())}><Close /></Button>
        <LoadingButton
          loading={ reverseGeocodingLoading }
          onClick={() => dispatch(aroundMe())}
        >
          {
            (!reverseGeocoding || reverseGeocoding.isUserSearch || Object.keys(reverseGeocoding).length <= 1) && (
              <LocationSearching sx={{ marginRight: '5px' }} />
            ) || (
              <MyLocation sx={{ marginRight: '5px' }} />
            )
          }
        </LoadingButton>
      </div>
      <Suggestions id='geoloc_suggestions' style={{
        display: !canDisplay || !searchValues || searchValues.length === 0 ? 'none' : 'block',
      }}>
        {
          searchValues.map((address, index) => (
            <li key={index} onClick={() => selectLocation(address)}><GeoLocation location={address} /></li>
          ))
        }
      </Suggestions>
    </Wrapper>
  )
}

const Wrapper = styled.div`
  position: relative;
  margin-top: 16px;
  margin-bottom: 16px;
  width: 100%;
  & > svg {
    position: absolute;
    left: 20px;
    top: 12px;
    fill: ${Colors.bluePurple};
  }
  & > .geoloc-load {
    position: absolute;
    right: 5px;
    top: 5px;
  }
`

const Input = styled.input`
  border: none;
  border-left: 1px solid ${Colors.bluePurple};
  background-color: white;
  outline: none;
  height: 48px;
  padding: 16px 130px 16px 65px;
  box-sizing: border-box;
  width: 100%;
  font-size: 16px;
  font-weight: 500;
  color: ${Colors.dark};
  overflow: hidden;
  text-overflow: ellipsis;
  
  ::placeholder {
    color: ${Colors.blueGrey};
    font-size: 18px;
    font-weight: 500;
  }
  
`

const Suggestions = styled.ul`
  position: absolute;
  background-color: white;
  border: 1px solid #c5cbdb;
  margin: -1px 0 0 0;
  padding: 5px 0;
  width: 100%;
  z-index: 600;
  & > li {
    list-style: none;
    padding-left: 65px;
  }
  & > li:hover {
    background-color: #eee;
    cursor: pointer;
  }
`

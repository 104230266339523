export const MESSAGE_TYPES = {
  text: 'Text',
  symptom: 'Symptom',
  question: 'Question',
  approve: 'ApproveConsultation',
  refused: 'RefusedConsultation',
  cancelled: 'CancelledConsultation',
  created: 'CreateConsultation',
  file: 'File',
  prescriptionNew: 'Prescription',
  report: 'ReportConsultation',
  prescription: 'ReportDrug',
  system: 'System',
}

import React from 'react'
import { Link, Route } from 'react-router-dom'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import Button from '@mui/material/Button'
import { Colors } from '../../../../constants/colors'
import {history} from "../../../../utils/history";
import {useSelector} from "react-redux";
import Tooltip from '@mui/material/Tooltip';

const ButtonStyled = styled(props => (
  <Button {...props} classes={{ label: 'label' }} />
))`
  && {
    height: 46px;
    font-size: 15px;
    font-family: Roboto, sans-serif;
    text-transform: none;
    font-weight: ${props => (props.selected ? 500 : 'normal')};
    background-color: ${props => (props.selected ? '#E7E2F6' : 'none')};
    color: ${props => (props.selected ? Colors.bluePurple : props.disabled ? Colors.disabledText : '#63666F')};
    line-height: 22.5px;
    justify-content: start;
    text-align: left;
    border: 1px solid transparent;
  }
  &&:hover { 
    border-left: 0.8px solid ${Colors.bluePurple}; 
    color: ${Colors.bluePurple};
  }


`

const IconStyled = styled.div`
  display: flex;
  align-items: center;
  ${({selected}) => selected && `color: ${Colors.bluePurple};`}
  position: relative;
  margin-right: 8px;
`

const NewMessage = styled.div`
  color: #ff6161;
  position: absolute;
  right: -7px;
  top: -8px;
  font-size: 20px;
`

function isActive(match, location, path) {
  let isActive = match;
  if (path === '/panel/community' && location.pathname.indexOf('/panel/community/roso') !== -1) {
    isActive = false;
  }

  return isActive;
}

export const MenuButton = ({
  icon,
  label,
  path,
  folded,
  adminChatMessages,
  disabled,
  ...rest
}) => {

  const isAlreadySeen = useSelector(state => state.formsView.isViewed.help_around_me)
  const hasUnreadMessage = useSelector(state => state.discussions.unreadMessage)

  return (
    <Route
      path={path}
      children={({ match, location }) => (
          <ButtonStyled
            folded={ folded }
            selected={isActive(match, location, path)}
            disabled={disabled}
            onClick={() => history.push(path)}
            {...rest}
          >
            {icon && (
              <Tooltip title={!folded ? '' : label} placement='right'>
                <IconStyled selected={isActive(match, location, path)}>
                  {(
                    path === '/panel/proximity' && isAlreadySeen === false ||
                    path === '/panel/discussions' && hasUnreadMessage
                  ) ? (
                    <NewMessage>
                      ●
                    </NewMessage>
                  ) : null}
                  {icon}
                </IconStyled>
              </Tooltip>
            )}
            {!folded ? label : ''}
          </ButtonStyled>
      )}
    />
  )
}


MenuButton.defaultProps = {
  icon: null,
}

MenuButton.propTypes = {
  icon: PropTypes.element,
  label: PropTypes.string.isRequired,
  path: PropTypes.string.isRequired,
}

import { handleActions, createAction } from 'redux-actions'
import { combineReducers } from 'redux'
import axios from 'axios'
import {HELP_AROUND_ME, HELP_WINDOW, NETWORK_POP_UP} from "../../constants/forms";

export const fetchHelpWindowViewRequest = createAction('FETCH_HELP_WINDOW_VIEW_REQUEST')
export const fetchHelpWindowViewSuccess = createAction('FETCH_HELP_WINDOW_VIEW_SUCCESS')
export const fetchHelpWindowViewFailure = createAction('FETCH_HELP_WINDOW_VIEW_FAILURE')

export const fetchHelpWindowView = (doctorId) => (dispatch) => {
    dispatch(fetchHelpWindowViewRequest())
    return axios(`/forms/view/${HELP_WINDOW}/${doctorId}`, {
        method: 'GET',
    })
        .then(response => dispatch(fetchHelpWindowViewSuccess(response.data)))
        .catch(err => dispatch(fetchHelpWindowViewFailure(err)))
}

export const fetchNetworkViewRequest = createAction('FETCH_NETWORK_VIEW_REQUEST')
export const fetchNetworkViewSuccess = createAction('FETCH_NETWORK_VIEW_SUCCESS')
export const fetchNetworkViewFailure = createAction('FETCH_NETWORK_VIEW_FAILURE')

export const fetchNetworkView = (doctorId) => (dispatch) => {
    dispatch(fetchNetworkViewRequest())
    return axios(`/forms/view/${NETWORK_POP_UP}/${doctorId}`, {
        method: 'GET',
    })
        .then(response => dispatch(fetchNetworkViewSuccess(response.data)))
        .catch(err => dispatch(fetchNetworkViewFailure(err)))
}

export const fetchHelpAroundMeViewRequest = createAction('FETCH_HELP_AROUND_ME_VIEW_REQUEST')
export const fetchHelpAroundMeViewSuccess = createAction('FETCH_HELP_AROUND_ME_VIEW_SUCCESS')
export const fetchHelpAroundMeViewFailure = createAction('FETCH_HELP_AROUND_ME_VIEW_FAILURE')

export const fetchHelpAroundMeView = (doctorId) => (dispatch) => {
    dispatch(fetchHelpAroundMeViewRequest())
    return axios(`/forms/view/${HELP_AROUND_ME}/${doctorId}`, {
        method: 'GET',
    })
        .then(response => dispatch(fetchHelpAroundMeViewSuccess(response.data)))
        .catch(err => dispatch(fetchHelpAroundMeViewFailure(err)))
}

export const createFormsViewRequest = createAction('CREATE_FORMS_VIEW_REQUEST')
export const createFormsViewSuccess = createAction('CREATE_FORMS_VIEW_SUCCESS')
export const createFormsViewFailure = createAction('CREATE_FORMS_VIEW_FAILURE')

export const createFormsView = (doctorId, dictionaryId) => (dispatch) => {
    dispatch(createFormsViewRequest())
    return axios(`forms/view`, {
        method: 'POST',
        data: {
            doctor_id: doctorId,
            dictionary_id: dictionaryId,
            is_viewed: true,
        }
    })
        .then(response => dispatch(createFormsViewSuccess(response.data)))
        .catch(err => dispatch(createFormsViewFailure(err)))
}

const isViewed = handleActions({
    [fetchHelpWindowViewSuccess]: (state, action) => ({
        ...state,
        help_window: action.payload,
    }),
    [fetchNetworkViewSuccess]: (state, action) => ({
        ...state,
        network_pop_up: action.payload,
    }),
    [fetchHelpAroundMeViewSuccess]: (state, action) => ({
        ...state,
        help_around_me: action.payload,
    })
}, [])

export const formsView = combineReducers({
    isViewed,
})
import React, { useState, useEffect} from 'react';
import { Field, Form } from 'react-final-form'
import styled from 'styled-components'
import Checkbox from '@mui/material/Checkbox'
import FormControlLabel from '@mui/material/FormControlLabel'
import { FormattedMessage, useIntl } from 'react-intl'
import { TextField } from '../../common/UiKit/TextField'
import { Colors, BUTTON_COLORS } from '../../../constants/colors'
import {auth, getProfessionamNumberFromOauthData} from "../../../redux/modules/auth";
import {useDispatch, useSelector} from "react-redux";
import {TextButton} from "../../common/UiKit/TextButton";
import Isemail from "isemail";
import {useParams} from "react-router-dom";
import {Recaptcha} from "./Recaptcha/Recaptcha";
import {PasswordFieldComponent} from "../PasswordRecovery/PasswordField";
import {validatePassWordConditions} from "../../../utils/validatePassWordConditions";

const CheckboxWrapper = styled.div`
  text-align: left;
`

const FieldStyled = styled(Field)`
  background-color : transparent;
    & > & > *  {
      display: flex;
      justify-content: start;
      align-items: center;
      margin: auto;
      width: 40vw;
    }
`

const CheckboxLabel = styled.span`
  &, & a {
    font-size: 13px;
    line-height: 1.31;
  }
  cursor: default;
  color: ${Colors.textMuted};
  
  a {
    cursor: pointer;
  }
`

const Styledform = styled.form`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`

const TitlePurple = styled.h3`
  color: #6633FF;  
`

const StyledError = styled.div`
  margin-top : 30px;
  margin-bottom : 15px;
  height : 35px;
  width : 355px;
  border: 1px solid #FF3333;
  border-radius : 5px;
  background-color: #FADCE6;
  color: #FF3333;
  display: flex;
  justify-content: center;
  align-items: center;
`

const CaptchaError = styled.div`
  margin-top : 30px;
  margin-bottom : 15px;
  color: ${Colors.red}
`

const ButtonLogin = styled(TextButton)`
  && {
    height: 40px;
    width: 180px;
    margin-top: 10px;
    margin-bottom : 15px;
    text-transform: none;
    font-size: 14px;
    font-weight: 500;
    color: #fff;
  }
  &&:hover {
    background: ${BUTTON_COLORS.purpleHover};
  }`

export const MailPasswordAndConfirmPassword = () => {
  const { formatMessage } = useIntl()
  let { role } = useParams();
  localStorage.setItem('userRole', role === 'doctor' ? 'ROLE_DOCTOR' : 'ROLE_PATIENT')
  localStorage.setItem('userType', role)
  const f = id => formatMessage({ id })
  const [stateCheckBox, setStateCheckBox] = useState(false)
  const [errorMessage, setErrormessage] = useState('')
  const dispatch = useDispatch()
  const [doctorRppsDetails, setDoctorRppsDetails] = useState([])
  const userData = useSelector(_ => _.auth.pscInscription)
  if (localStorage.getItem('pscSubscription') === '1' && userData) {
    localStorage.setItem('pscSubscription', '2')
    const [_, profNb] = getProfessionamNumberFromOauthData(userData)
    if (localStorage.getItem('rpps_adeli')) {
      setDoctorRppsDetails(JSON.parse(localStorage.getItem('rpps_adeli')))
    }
  }

  useEffect(() => {
    if (localStorage.getItem('rpps_adeli')) {
      setDoctorRppsDetails(JSON.parse(localStorage.getItem('rpps_adeli')))
    }
  }, [localStorage.getItem('rpps_adeli')])


  const handleChangeCheckBox = () => {
    setStateCheckBox(true)
  }

  const onSubmit = (values) => {
    //If the registration is for a user we check that the captcha is correct
    if (role === 'patient') {
      if (reCaptchaValue) {
        values['captcha'] = reCaptchaValue
        return dispatch(auth(values, role === 'patient'))
          .then((res) => {
            if(res === 'mail.already.used')
            {
              setErrormessage(res)
              setTimeout(() => {
                setErrormessage('')
              }, "2000")
            }
          })
      } else {
        setRecaptchaError(true);
      }
    } else {
          return dispatch(auth(values, role === 'patient'))
          .then((res) => {
            if(res === 'mail.already.used')
            {
              setErrormessage('mail.already.used')
              setTimeout(() => {
                setErrormessage('')
              }, "2000")
            }
      })
    }
  }

  const [reCaptchaValue, setRecaptchaValue] = useState(null);
  const [reCaptchaError, setRecaptchaError] = useState(false);
  const getRecaptchaValue = (value) => {
    setRecaptchaValue(value);
  }

  useEffect(() => {
    if(reCaptchaValue) {
      setRecaptchaError(false)
    }
  }, [reCaptchaValue])

  const validate = (values) => {
    const errors = {}
    if (!values.email) {
      errors.email = <FormattedMessage id='error.email.required' />
    } else if (!Isemail.validate(values.email, {minDomainAtoms: 2})) {
      errors.email = <FormattedMessage id='error.email' />
    }
    if (!values.password) {
      errors.password = <FormattedMessage id='error.password.required' />
    }
    if(!!values.password && values.password.length < 12) {
      errors.password = <FormattedMessage id='error.password.length'/>
    }
    if (!values.passwordConfirmation ) {
      errors.passwordConfirmation = <FormattedMessage id='error.password.required' />
    }
    if( !stateCheckBox ){
      errors.check1 = <FormattedMessage id='error.not.accepted.conditions' />
    }
    const errorsPassword = validatePassWordConditions(values.password,values.passwordConfirmation,"password","passwordConfirmation")
    return {...errors, ...errorsPassword}
  }

  return (
    <Form
      onSubmit={onSubmit}
      validate={validate}
      render={({ handleSubmit, submitting , stateCheckBox, errors}) => (
        <Styledform onSubmit={handleSubmit}>
          {
            <TitlePurple>
                Bienvenue  {(role === 'doctor' && doctorRppsDetails.length !== 0) && (doctorRppsDetails.codeCivilite + ' ' + doctorRppsDetails.prenomExercice + ' ' + doctorRppsDetails.nomExercice)}
            </TitlePurple>
          }
          {
            errorMessage === 'mail.already.used'
            &&
            <StyledError>
              {<FormattedMessage id='mail.already.used' />}
            </StyledError>
          }
          <FieldStyled
            component={TextField}
            name='email'
            label={<b>{f('auth.email')}</b>}
            type='email'
            required
          />
          <PasswordFieldComponent
              name='password'
              label = 'auth.password'
          />
          <FieldStyled
            component={TextField}
            name='passwordConfirmation'
            label={<b>{f('auth.confirmpassword')}</b>}
            password
            required
          />

          {role === 'patient' &&
            <Recaptcha sendCaptchaValue={getRecaptchaValue}/>
          }
          {reCaptchaError &&
            <CaptchaError>
            <FormattedMessage id='auth.captcha.error'/>
            </CaptchaError>
          }

          <CheckboxWrapper>
            <FormControlLabel
              control={(
                <Checkbox
                  checked={stateCheckBox}
                  onChange={handleChangeCheckBox}
                  value={stateCheckBox}
                  name='check1'
                  color='primary'
                  required
                />
              )}
              label={(
                <CheckboxLabel>
                  <FormattedMessage id='auth.agree' values={{ suffix: <FormattedMessage id='global.les' defaultMessage='' /> }} />
                  {' '}
                  {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
                  <a href="https://conexsante.com/cgu/" target="_blank" rel="noopener noreferrer">
                    <FormattedMessage id='auth.policy' />
                  </a>
                  <FormattedMessage id='global.et.la' />
                  {' '}
                  {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
                  <a href="https://conexsante.com/politique-de-confidentialite/" target="_blank" rel="noopener noreferrer">
                    <FormattedMessage id='auth.privacy.policy' />
                  </a>
                </CheckboxLabel>
              )}
            />
          </CheckboxWrapper>
          <ButtonLogin
            color='primary'
            variant='extended'
            type='submit'
            disabled={!!Object?.keys(errors)?.length}
          >
            <FormattedMessage id='auth.classic.registration' />
          </ButtonLogin>
        </Styledform>
        )}
    >
    </Form>
  )
}

import React, { useEffect } from 'react'
import Dialog from '@mui/material/Dialog'
import IconButton from '@mui/material/IconButton'
import Typography from '@mui/material/Typography'
import DialogTitle from '@mui/material/DialogTitle'
import DialogContent from '@mui/material/DialogContent'
import Avatar from '@mui/material/Avatar'
import { useDispatch, useSelector } from 'react-redux'
import { FormattedMessage } from 'react-intl'
import styled from 'styled-components'
import moment from 'moment-timezone'
import CallIcon from '@mui/icons-material/Call'
import CallEndIcon from '@mui/icons-material/CallEnd'
import { Colors } from 'constants/colors'

import ringtone from '../../../assets/sounds/ringtone.mp3'
import {acceptCall, declineCall, resetCall, terminateCall} from '../../../redux/modules/call'
import { history } from '../../../utils/history'
import { fetchSettings } from '../../../redux/modules/settings';
import {fetchOpentokParams} from "../../../redux/modules/consultations";
//import { toggleCallAnswerModal } from '../../../redux/modules/content';

//#region Styles
const Content = styled.div`
  text-align: center;
  display: flex;
  flex-flow: column nowrap;
  align-items: center;
  min-width: 250px;
`

const Photo = styled(Avatar)`
  && {
    width: 96px;
    height: 96px;
    animation: blinking .6s ease-in-out infinite;
  }
`

const PhotoWrapper = styled.div`
  position: relative;
  margin: 16px 0 32px;
    
  &:after {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    border-radius: 50%;
    border: 2px solid ${Colors.clearBlue};
    box-sizing: border-box;
    content: "";
    animation: ripple .5s ease-out infinite;
  }
`

const Title = styled(DialogTitle)`
  && {
    text-align: center;
  }
`

const AcceptButton = styled(IconButton)`
  && {
    background-color: #35D073;
    color: ${Colors.white};
    
    &:hover {
      background-color: #4BB462;
    }
  }
`

export const DeclineButton = styled(IconButton)`
  && {
    background-color: #F85C50;
    color: ${Colors.white};
    
    &:hover {
      background-color: #EE3D48;
    }
  }
`

const ButtonWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  width: 60%;
  margin-top: 24px;
`
//#endregion

export const CallModal = () => {
  const dispatch = useDispatch()
  const data = useSelector(state => state.call)
  const visio = useSelector(state => state.visio)
  const isCalling = useSelector(state => !!Object.keys(state.consultations.opentok.params).length)
  const isDoctor = useSelector(state => state.auth.type === 'Doctor')
  const myId = useSelector(state => state.auth.attributes.user_id)
  const audio = new Audio(ringtone)
  const settings = useSelector(state => state.settings.values)

  const onEnd = (subject, isAccepted = false) => {
    if (isAccepted) {
      dispatch(fetchOpentokParams())
      dispatch(acceptCall(subject))
    } else {
      dispatch(declineCall())
      dispatch(terminateCall())
    }
  }

  const onAccept = () => {
    onEnd(visio.subject, true)
    history.push(`/panel/consultations/${visio.subject.consultation.id}?call=1`)
  }

  audio.addEventListener('ended', async () => {
    audio.currentTime = 0
    const isPlaying = audio.currentTime > 0 && !audio.paused && !audio.ended
      && audio.readyState > 2

    if (!isPlaying && settings && settings.web_incoming_calls_sound_enabled === '1') {
      await audio.play()
    }
  })

  useEffect(() => {
    dispatch(fetchSettings())
  }, [dispatch])

  useEffect(() => {
    const stopAudio =  () => {
      audio.pause()
      audio.currentTime = 0
    }
    const isPlaying = audio.currentTime > 0 && !audio.paused && !audio.ended
      && audio.readyState > 2
    if (visio?.callIncoming) {
      if (!isPlaying && settings && settings.web_incoming_calls_sound_enabled === '1') {
        audio.play().catch(e => console.warn(e))
      }
    } else {
      stopAudio()
    }

    return stopAudio
  }, [visio])

  if (!visio || !visio.subject?.user || visio.accepted || visio.inprogress) {
    return (<></>)
  }

  return (
    <Dialog open={true}>
      <Title>
        <FormattedMessage id='consultation.incoming.call' />
      </Title>
      <DialogContent>
        <Content>
          <PhotoWrapper>
            <Photo src={visio.subject.user.photo} />
          </PhotoWrapper>
          <Typography variant='h5' component='span'>
            {visio.subject.user.fullName}
          </Typography>
          <Typography variant='caption' color='textSecondary' component='span' paragraph>
            <FormattedMessage id='consultation.at' />
            {' '}
            {moment(visio.subject.consultation.proposedTime).format('HH:mm, DD/MM/YYYY')}
          </Typography>
          <ButtonWrapper>
            <DeclineButton size='large' onClick={() => onEnd(visio.subject)}>
              <CallEndIcon />
            </DeclineButton>
            <AcceptButton size='large' onClick={onAccept}>
              <CallIcon />
            </AcceptButton>
          </ButtonWrapper>
        </Content>
      </DialogContent>
    </Dialog>
  )
}

import { compose, withProps } from 'recompose'
import { connect } from 'react-redux'
import idx from 'idx'
import { MedicalReport as MedicalReportComponent } from 'common/UiKit/MedicalReport'
import { history } from '../../../../../../utils/history'
import { getMainReport } from '../../../../../../helpers/consultations'

const enhance = compose(
  connect(
    (state, props) => ({
      consultation: idx(state, _ => _.consultations.consultation),
      downloadLink: idx(state, (_) => {
        const report = getMainReport(_, +props.match.params.id)
        return report ? report.attributes.pdf.prescription : ''
      }),
    }),
  ),
  withProps(props => ({
    onCloseIconClick: () => history.push('/panel/consultations/session'),
    onDownloadIconClick: () => window.open(props.downloadLink),
    isPrescription: true,
  })),
)

export const Prescription = enhance(MedicalReportComponent)

import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import {compose, withProps} from 'recompose'
import {connect} from 'react-redux'
import {Field, Form} from 'react-final-form'
import {EditPage} from 'common/UiKit/EditPage'
import {TextField} from 'common/UiKit/TextField'
import {Paper} from 'common/UiKit/Paper'
import {FormattedMessage, useIntl} from 'react-intl'
import {history} from '../../../utils/history'
import {externalFormSubmit} from '../../../helpers/externalFormSubmit'
import {changePassword} from '../../../redux/modules/auth'
import {PasswordFieldComponent} from "../../Auth/PasswordRecovery/PasswordField";
import {validatePassWordConditions} from "../../../utils/validatePassWordConditions";

const Wrapper = styled.div`
  height: 100%;

  & > form {
    height: 100%;
  }
`

const Content = styled(Paper)`
  margin-top: 24px;
  padding: 16px 24px 6px 24px;
`

const validate = (values) => {
  const errors = {}

  if (!values.currentPassword) errors.currentPassword = <FormattedMessage id='error.password.current' />
  const errorsPassword = validatePassWordConditions(values.newPassword,null,"newPassword")
  return {...errors, ...errorsPassword}
}
const ChangePasswordComponent = ({
  loading,
  onSubmit,
  onButtonClick,
  onBackIconClick,
}) => {
  const { formatMessage: f } = useIntl()
  return (
    <Wrapper>
      <Form
        onSubmit={onSubmit}
        validate={validate}
        subscription={{}}
        render={({ handleSubmit }) => (
          <form onSubmit={handleSubmit} id='change_password'>
            <EditPage
              background
              label='profile.change.password'
              buttonText='global.save'
              onBackIconClick={onBackIconClick}
              loading={loading}
              onButtonClick={onButtonClick}
            >
              <Content>
                <Field
                  component={TextField}
                  name='currentPassword'
                  label={`${f({ id: 'profile.current.password' })} *`}
                  password
                />
                <PasswordFieldComponent
                    name='newPassword'
                    label = 'profile.new.password'
                />
              </Content>
            </EditPage>
          </form>
        )}
      />
    </Wrapper>
  )
}

ChangePasswordComponent.propTypes = {
  loading: PropTypes.bool.isRequired,
  onButtonClick: PropTypes.func.isRequired,
  onBackIconClick: PropTypes.func.isRequired,
  onSubmit: PropTypes.func.isRequired,
}

const enhance = compose(
  connect(state => ({ loading: state.loading.changePassword }), { changePassword }),
  withProps(props => ({
    onSubmit: values => props.changePassword(values),
    onButtonClick: () => externalFormSubmit('change_password'),
    onBackIconClick: () => history.goBack(),
  })),
)

export const ChangePassword = enhance(ChangePasswordComponent)

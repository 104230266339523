import React from 'react';
import PropTypes from 'prop-types'
import styled from "styled-components";

const Wrapper = styled.div`
      
`
export const CustomIframe = ({
    src,
    title,
}) => {
    return (
        <Wrapper>
            <iframe width="560" height="315" src={`https://www.youtube.com/embed/${src}?origin=https://app.conexsante.com&cc_load_policy=1&controls=2`}
                    title={title}
                    allow="autoplay; encrypted-media;"
                    allowFullScreen></iframe>
        </Wrapper>
    )
}

CustomIframe.propTypes = {
    src: PropTypes.string.isRequired
};
import { connectedRouterRedirect } from 'redux-auth-wrapper/history4/redirect'
import locationHelperBuilder from 'redux-auth-wrapper/history4/locationHelper'
import get from 'lodash/get';

const locationHelper = locationHelperBuilder({})

export const userIsAuthenticated = connectedRouterRedirect({
  redirectPath: '/auth',
  //authenticatedSelector: state => (!!state.auth.id && !!state.auth.attributes.phoneNumber),
  authenticatedSelector:(state) => {
     if(!state.auth.id){
       return false;
     }
     else if (state.auth.attributes.status == "PRE_REGISTRATED"){
       return true;
     }
     else{
       return !!state.auth.id && !!state.auth.attributes.phoneNumber;
     }
   },
  wrapperDisplayName: 'UserIsAuthenticated',
})

export const userIsNotAuthenticated = connectedRouterRedirect({
  redirectPath: (state, ownProps) => {
    const isPatient = get(state, 'auth.type', '') === 'Patient';
    if (get(state, 'auth.attributes.first_name', '') === '') {
      return isPatient ? '/panel/profile/edit' : '/panel/profile/fill';
    }
    // If the user isn't verified we redirect him on his profile page, even if he manually change the current url
    if (get(state, 'auth.attributes.status', '') === 'NOT VERIFIED') {
      return '/panel/profile'
    }
    return locationHelper.getRedirectQueryParam(ownProps) || '/panel/consultations';
  },
  authenticatedSelector: state => (!state.auth.id || !state.auth.attributes.phoneNumber),
  // authenticatedSelector: state => !state.auth.id,
  wrapperDisplayName: 'UserIsNotAuthenticated',
  allowRedirectBack: false,
})

import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import { compose, withProps, withStateHandlers } from 'recompose'
import IconButton from '@mui/material/IconButton'
import AttachFile from '@mui/icons-material/AttachFile'
import Send from '@mui/icons-material/Send'
import { Colors } from 'constants/colors'
import { useIntl, FormattedMessage } from 'react-intl'
import Dropzone from 'react-dropzone'
import { useSelector } from 'react-redux'
import CircularProgress from '@mui/material/CircularProgress'
import TextField from '@mui/material/TextField';
import Grid from "@mui/material/Grid";
import {Box} from "@mui/material";
import Tooltip from "@mui/material/Tooltip";
import {AVAILABLE_EXTENSIONS_STR} from "../../../constants/extensions";

const Wrapper = styled.div`
  display: flex;
`

const InputWrapper = styled.div`
  .MuiOutlinedInput-root {
      padding: 9px 22px;
  }
  box-sizing: border-box;
  margin: 0 24px ${props => (props.focus ? '15px' : '16px')};
  padding-right: 3px;
  border-style: solid;
  border-color: ${props => (props.focus ? Colors.bluePurple : Colors.cloudyBlue)};
  border-width: ${props => (props.focus ? '2px' : '1px')};
  border-radius: 4px;
  background-color: ${props => (props.disabled ? 'rgb(235, 235, 228)' : 'white')};
  display: flex;
  align-items: center;
  flex: 1;
  color: ${Colors.blueGrey};
`

const SendButton = styled(IconButton)`
    height: 41px;
    border-radius: 4px;
    display: flex;
    margin-right: 25px;
    align-items: center;
    justify-content: center;
    border: 1px solid ${Colors.bluePurple};
    cursor: pointer;
    background-color: transparent;
    font-size: 14px;
    padding: 5px;
    font-family: Roboto, sans-serif;
    font-weight: 500;

    svg {
        width: 18px;
        height: 18px;
        margin-left: 15px;
    }

    &:hover, &:active, &:focus {
        background-color: ${Colors.bluePurple};
        color: white;
    }
    &:disabled{
        border-color: ${Colors.cloudyBlue};
    }
`

const SendAttachment = styled(IconButton)`
    && {
        height: 41px;
        padding: 5px;
        color: ${Colors.bluePurple};
        font-size: 14px;
        display: flex;
        justify-content: center;
        align-items: center;
        text-transform: none;
        border-radius: 20px;
        text-align: center;
        font-family: Roboto, sans-serif;
        font-weight: 500;
        margin-right: 25px;
    }

    &&:hover {
        background-color: rgba(113, 77, 236, 0.07);
    }
`

const Input = styled(TextField)`
  flex-grow: 1;
  &::placeholder {
    color: ${Colors.cloudyBlue};
  }
  .MuiOutlinedInput-root {
    font-size: 16px;
    border-left: ${props => (props.focus ? '2px solid' : 'inherit')};
    border-color: ${props => (props.focus ? Colors.bluePurple : Colors.cloudyBlue)};
    border-radius: 0;
    color: ${Colors.dark80};
    &:focus {
      outline: none;
    }
    fieldset {
      border: none ;
    }
  
  }
`
const InputMessageComponent = ({
  value,
  onChange,
  onKeyUp,
  focus,
  onFocus,
  onBlur,
  onClick,
  disabled,
  onDrop,
  fileLoadingProgress,
}) => {
  const { formatMessage } = useIntl()
  const fileUploading = useSelector(state => state.loading.fileUploading)

  return (
    <Wrapper>
          <InputWrapper
            focus={focus ? 'true' : undefined}
            disabled={disabled}
            flex={1}
          >
            <Input
              variant='outlined'
              multiline
              spellCheck='false'
              focus={focus ? 'true' : undefined}
              placeholder={formatMessage({ id: 'consultations.chat.placeholder' })}
              type='text'
              value={value}
              onChange={onChange}
              onKeyUp={onKeyUp}
              onFocus={onFocus}
              onBlur={onBlur}
              disabled={disabled}
            />
          </InputWrapper>
          <SendButton
            color='primary'
            focus={focus ? 'true' : undefined}
            onClick={onClick}
            disabled={!value}
          >
            <FormattedMessage id='global.send' />
            <Send />
          </SendButton>
          <Dropzone
            accept={AVAILABLE_EXTENSIONS_STR}
            multiple
            onDrop={onDrop}
          >
            {
              ({ getRootProps, getInputProps }) => (
                <Tooltip title={<FormattedMessage id='global.sendAttach' />}>
                  <SendAttachment
                    color='inherit'
                    focus={focus ? 'true' : undefined}
                    {...getRootProps()}
                    disabled={fileUploading}
                  >
                    {
                      fileUploading
                        ? <CircularProgress size={24} />
                        : <AttachFile />
                    }
                    <input {...getInputProps()} />
                  </SendAttachment>
                </Tooltip>
              )
            }
          </Dropzone>
    </Wrapper>
  );
}

InputMessageComponent.propTypes = {
  value: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
  onKeyUp: PropTypes.func.isRequired,
  focus: PropTypes.bool.isRequired,
  onFocus: PropTypes.func.isRequired,
  onBlur: PropTypes.func.isRequired,
  onClick: PropTypes.func.isRequired,
  disabled: PropTypes.bool.isRequired,
  onFileSubmit: PropTypes.func.isRequired,
  onDrop: PropTypes.func.isRequired,
  fileLoadingProgress: PropTypes.number.isRequired,
}

const enhance = compose(
  withStateHandlers({
    value: '',
    focus: false,
  }, {
    setValue: () => value => ({ value }),
    onFocus: () => () => ({ focus: true }),
    onBlur: () => () => ({ focus: false }),
  }),
  withProps(props => ({
    onClick: () => {
      props.pushMessage(props.value)
      props.setMessagePushed(props.value)
      props.setValue('')
    },
    onChange: e => props.setValue(e.target.value),
    onDrop: async (files) => {
      if (!files.length) return
      await files.forEach(async (file) => {
        await props.onFileSubmit({ file })
      })
    },
  })),
)

export const InputMessage = enhance(InputMessageComponent)

import React, {useEffect} from 'react'
import styled from 'styled-components'
import { Paper } from 'common/UiKit/Paper'
import { SecuredBckgImage } from 'common/SecuredImage'
import {history} from "../../../utils/history";
import AddIcon from "@mui/icons-material/Add";
import {toggleFavoriteStructure} from "../../../redux/modules/structures";
import {useDispatch, useSelector} from "react-redux";
import defaultLogo from "../../../images/doctors/structures/logo_structure.png";
import {Colors} from "../../../constants/colors";
import CheckIcon from "@mui/icons-material/Check";

const PaperStyled = styled(Paper)`
  padding: 10px 14px;
  position: relative;
  display: flex;
  height: 60px;
  align-items: center; 
`

const Wrapper = styled.div`
  display: flex;
  font-weight: normal;
  font-size: 14px;
  margin: auto 0;
  width: 100%;
`

const AvatarWrapper = styled.div`
  display: flex;
  font-weight: normal;
  font-size: 14px;
`

const Info = styled.div`
  flex-grow: 1;
  margin: auto 32px auto;
`

const NameLinked = styled.h1`
  margin: 0;
  font-size: 16px;
  font-weight: bold;
  color: black;
  cursor: pointer;
  
  &:hover {
    color: #6633FF;
  }
`

const Name = styled.p`
  margin: 0;
  font-size: 16px;
  font-weight: bold;
  color: black;
`

const Address = styled.p`
  margin: 0;
  opacity: 0.8;
  padding-top: 5px;
  font-size: 13px;
`


const CancelButton = styled.div`
  display: flex;
  justify-content: flex-end;
  align-items: flex-start;
`

const StyledAddIcon = styled(AddIcon)`
  margin-left: 30px;
  font-size: 35px;
  color: ${Colors.bluePurple};
  cursor: pointer;
  border-radius: 100%;
  &:hover {
    background-color: rgba(231, 226, 246, 0.3);
  }
`

const StyledCheckIcon = styled(CheckIcon)`
  margin-left: 30px;
  font-size: 35px;
  color: ${Colors.bluePurple};
  cursor: pointer;
  border-radius: 100%;
  &:hover {
    background-color: rgba(231, 226, 246, 0.3);
  }
`


const Avatar = styled(SecuredBckgImage)`
  min-width: 60px;
  width: 60px;
  height: 60px;
  border-radius: 100%;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
`

export const NewFavoriteStructureCardSearch = ({
                                                id,
                                                name,
                                                photo,
                                                postcode,
                                                city,
                                                link,
                                                isFavorite,
                                            }) => {

    const dispatch = useDispatch();


    const onConfirm = () => {
        dispatch(toggleFavoriteStructure(id, !isFavorite))
    }

    return (
        <PaperStyled>
            <Wrapper>
                <AvatarWrapper>
                    {
                        photo ? (
                                <Avatar
                                    spinner={{width: '120px', height: '120px'}}
                                    src={photo.includes('/file/') ? `http://backend-application.owndoctor.eu${photo}` : photo}
                                />
                            ) :
                            (<Avatar
                                    spinner={{width: '120px', height: '120px'}}
                                    src={defaultLogo}
                                />
                            )
                    }
                </AvatarWrapper>
                <Info>
                    {link ? (
                            <NameLinked onClick={()=>history.push(link)}>
                                {name}
                            </NameLinked>)
                        : (
                            <Name>
                                {name}
                            </Name>)
                    }
                    <Address>
                        { postcode && city && city + " (" + postcode + ")"}
                    </Address>
                </Info>

                <Info>
                    { !isFavorite && (
                        <CancelButton
                            variant='outlined'
                            onClick={onConfirm}
                        >
                            <StyledAddIcon fontSize='large' color='#6633FF' />
                        </CancelButton>
                    )


                    }
                    { isFavorite && (
                        <CancelButton
                            variant='outlined'
                            onClick={onConfirm}
                        >
                            <StyledCheckIcon fontSize='large' color='#6633FF' />
                        </CancelButton>
                    )
                    }
                </Info>
            </Wrapper>
        </PaperStyled>
    )
}



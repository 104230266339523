import React from 'react'
import { Autocomplete } from '../Autocomplete'

const checkError = (touched, error, submitError) => {
  if (touched) {
    if (error) return error
    if (submitError) return submitError
  }

  return null
}

export const AutocompleteField = ({
  input,
  meta,
  disabled,
  onChange,
  ...rest
}) => (
  <Autocomplete
    {...rest}
    onChange={onChange}
    input={input}
    errorMessage={checkError(meta.touched, meta.error, meta.submitError)}
  />
)

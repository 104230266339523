import React, {useState} from 'react'
import styled from 'styled-components'
import { Form, Field } from 'react-final-form'
import { TextField } from 'common/UiKit/TextField'
import { FormattedMessage } from 'react-intl'
import CheckIcon from "@mui/icons-material/Check";
import ClearIcon from "@mui/icons-material/Clear";

const Wrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  width:100%;
`
const CheckField = styled.div`
  &.correct {
    text-align : left;
    color : green; 
    font-size:large;
    padding: 0 10px;
    width:100%
  }
  &.incorrect {
    text-align : left;
    color : red; 
    font-size:large;
    padding: 0 10px;
    width:100%
  }
  margin-bottom:10px;
`

const TextFieldStyled = styled(TextField)`
  width:100%;
  && {
    margin-bottom: 24px;
  }
`
const Breaker = styled.div`
  flex-basis: 100%;
  height: 0;
`

const FieldStyled = styled(Field)`
  width:100%;
`

const DivStyled = styled.div`
  width:100%;
`

export const PasswordFieldComponent = ({
name,label
                              }) => {
  const [passwordLength, setPasswordLength] = useState(false)
  const [passwordUpperCase, setPasswordUpperCase] = useState(false)
  const [passwordNumber, setPasswordNumber] = useState(false)
  const [passwordSpecialCharacters, setPasswordSpecialCharacters] = useState(false)

  const regexUpperCase = new RegExp("(?=.*[A-Z])");
  const regexNumber = new RegExp("(?=.*\\d)");
  const regexSpecialCharacters = new RegExp("(?=.*[!@#\\$%\\^&\\*?\\+\\- ])");
  return (
      <Wrapper>
        <DivStyled>
          <FieldStyled
              required
              name={name}
              label={<FormattedMessage id={label}/>}
              password
              component={TextFieldStyled}
              parse={value => {
                value.length >= 12 ? setPasswordLength(true) : setPasswordLength(false)
                regexUpperCase.test(value) ? setPasswordUpperCase(true) : setPasswordUpperCase(false)
                regexNumber.test(value) ? setPasswordNumber(true) : setPasswordNumber(false)
                regexSpecialCharacters.test(value) ? setPasswordSpecialCharacters(true) : setPasswordSpecialCharacters(false)
                return value;
              }}
          />
        </DivStyled>
        <Breaker/>
        <div>
          <CheckField className={passwordLength ? "correct" : "incorrect"}>
            {passwordLength ?
                <CheckIcon style={{verticalAlign: "sub", fontSize: "larger"}}/> :
                <ClearIcon style={{verticalAlign: "sub", fontSize: "larger"}}/>
            }
            <FormattedMessage id='profile.password.security.length'/>
            <b>
              <FormattedMessage id='profile.password.security.length.bold'/>
            </b>
          </CheckField>
          <CheckField className={passwordUpperCase ? "correct" : "incorrect"}>
            {passwordUpperCase ?
                <CheckIcon style={{verticalAlign: "sub", fontSize: "larger"}}/> :
                <ClearIcon style={{verticalAlign: "sub", fontSize: "larger"}}/>
            }
            <FormattedMessage id='profile.password.security.atleast'/>
            <b>
              <FormattedMessage id='profile.password.security.uppercase'/>
            </b>
          </CheckField>
          <CheckField className={passwordNumber ? "correct" : "incorrect"}>
            {passwordNumber ?
                <CheckIcon style={{verticalAlign: "sub", fontSize: "larger"}}/> :
                <ClearIcon style={{verticalAlign: "sub", fontSize: "larger"}}/>
            }
            <FormattedMessage id='profile.password.security.atleast'/>
            <b>
              <FormattedMessage id='profile.password.security.number'/>
            </b>
          </CheckField>
          <CheckField className={passwordSpecialCharacters ? "correct" : "incorrect"}>
            {passwordSpecialCharacters ?
                <CheckIcon style={{verticalAlign: "sub", fontSize: "larger"}}/> :
                <ClearIcon style={{verticalAlign: "sub", fontSize: "larger"}}/>
            }
            <FormattedMessage id='profile.password.security.atleast'/>
            <b>
              <FormattedMessage id='profile.password.security.characters'/>
            </b>
              &nbsp; ! @ # $ % ^ & * ? + -
          </CheckField>
        </div>
      </Wrapper>
  )
}
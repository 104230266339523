import React, {useEffect, useState} from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import { pure, mapProps, compose } from 'recompose'
import { Form, Field } from 'react-final-form'
import moment from 'moment-timezone'
import createDecorator from 'final-form-calculate'
import { FormattedMessage, useIntl } from 'react-intl'
import { TextField } from '../../../../../common/UiKit/TextField'
import { Paper } from '../../../../../common/UiKit/Paper'
import { useDispatch, useSelector } from 'react-redux'
import { AvatarUpload } from '../../../../../common/AvatarUpload'
import { fetchSpecializations } from '../../../../../../redux/modules/content'
import { SpecializationsSelect } from '../../../../../common/SpecializationsSelect'
import {DateField} from "../../../../../common/DateField";
import Isemail from "isemail";

const FirstLine = styled.div`
  display: flex;
  & > div:first-child {
    margin-right: 24px;
  }
`

const InputWrapper = styled.div`
  height: 33px;
  & svg + div {
  box-shadow: none;
  background-color: transparent;
 }
`
const StyledInput = styled.div`
// display: inline-flex;
`

const Wrapper = styled(Paper)`
  padding: 24px;
  display: flex;
  
  & > div:last-child {
    flex: 1
  }
`
const Header = styled.h1`
  margin: 36px 0 0;
  font-size: 18px;
  font-weight: 500;
  color: #6633FF;
`

const WrapperCivilite = styled.div`
  margin-top: 40px;
`

const GENDER_OPTIONS = ['male', 'woman']
const GENDER_OPTIONS_DOCTOR = ['doctor', 'professor']

const validate = (values, showInsurance) => {
  const errors = {}

  if (!compatible(values.gender, values.specialization_id, values.specialization_list)) {
    errors.gender = <FormattedMessage id='errors.gender' />
  }

  if (showInsurance) {
    if (!values.medical_insurance 
      || values.medical_insurance.toString().length !== 15 
      || /[^A-B0-9]/.test(values.medical_insurance.toString())) {
      errors.medical_insurance = <FormattedMessage id='error.patient.insurance_15' />
    }
  }
  if (!values.first_name) errors.first_name = <FormattedMessage id='error.first.name' />
  if (!values.last_name) errors.last_name = <FormattedMessage id='error.last.name' />
  if (!values.date_of_birth) errors.date_of_birth = <FormattedMessage id='error.birth.date' />
  if (!values.email || !Isemail.validate(values.email)) errors.email = <FormattedMessage id='error.email' />
  if (!values.gender || values.gender === 'unknown') errors.gender = <FormattedMessage id='error.gender' />
  if (!values.specialization_id) errors.specialization_id = <FormattedMessage id='errors.required' />

  if (values && values.date_of_birth 
  && (moment(values.date_of_birth).isBefore('1900'))) {
    errors.date_of_birth = <FormattedMessage id='error.birth.date' />
  }
  return errors
}

const enhance = compose(
  pure,
  mapProps(props => ({
    ...props,
    onSubmit: values => props.onSubmit({ ...values }).then(() => props.afterSubmit()),
  })),
)

const calculator = createDecorator({
  field: 'specialization_id',
  updates: {
    gender: (specializationValue, allValues) => (
      !compatible(allValues.gender, specializationValue, allValues.specialization_list) ? undefined : allValues.gender
    ),
  },
})

function compatible(gender, specialization_id, specializations) {
  let result = true;
  if(specialization_id == null || specializations == null) return true;

  let specialization = specializations.filter(value => value.id == specialization_id)[0]

  if (specialization == null || specialization.attributes == null) return true;

  let spe_type = specialization.attributes.type

  if(spe_type == 'generalist' || spe_type == 'specialist' || spe_type == 'pharmaceutical') {
    result = GENDER_OPTIONS_DOCTOR.includes(gender)
  } else if (spe_type == 'others') {
    if (specialization.attributes.specialization_key == 'Midwife') {
      result = GENDER_OPTIONS.includes(gender)
    } else {
      result = GENDER_OPTIONS_DOCTOR.includes(gender)
    }
  } else if (spe_type == 'paramedical') {
    result = GENDER_OPTIONS.includes(gender)
  }

  return result;
}

function mapGenderOptionsLabels(options, isDoctor, f) {
  return options.map(value => ({ value, label: f(`profile${isDoctor ? '.genders' : ''}.${value}`) }))
}

export const PersonalDataForm = enhance(({
  onSubmit,
  initialValues,
  showInsurance,
  id,
  disableShadow,
  isDoctor,
}) => {
  const { formatMessage } = useIntl()
  const f = id => formatMessage({ id })
  const specializations = useSelector(state => state.content.specializations)
  const mayEdit = useSelector(state => state.auth.attributes.specialization_edit_available)
  const specialization = useSelector(state => state.auth.attributes.specialization_id)
  const dispatch = useDispatch()
  const genderOptions = ['doctor', 'professor', 'male', 'woman'];
  const annuaireRpps = JSON.parse(localStorage.getItem("rpps_adeli"));
  const location = window.location.pathname

  useEffect(() => {
    if (!specializations.length) {
      dispatch(fetchSpecializations())
    }
  }, [])


  return (
    <Form
      onSubmit={onSubmit}
      initialValues={annuaireRpps !== null ? {
        ...initialValues,
        first_name: annuaireRpps ? annuaireRpps.prenomExercice : '',
        last_name: annuaireRpps ? annuaireRpps.nomExercice : '',
        date_of_birth: initialValues.date_of_birth
          ? moment(initialValues.date_of_birth).format('YYYY-MM-DD')
          : moment('1980', 'YYYY').format('YYYY-MM-DD'),
        specialization_list: specializations,
      } : {
        ...initialValues,
        date_of_birth: initialValues.date_of_birth
            ? moment(initialValues.date_of_birth).format('YYYY-MM-DD')
            : moment('1980', 'YYYY').format('YYYY-MM-DD'),
        specialization_list: specializations,
      }}
      validate={values => validate(values, showInsurance)}
      decorators={[calculator]}
      render={({ handleSubmit, values }) => {
        const [options, setOptions] = useState(mapGenderOptionsLabels(genderOptions, isDoctor, f))

        useEffect(() => {
          setOptions(genderOptions
            .filter(value => compatible(value, values.specialization_id, values.specialization_list))
            .map(value => ({ value, label: f(`profile${isDoctor ? '.genders' : ''}.${value}`) })))
        }, [values.specialization_id, values.specialization_list])

        return (
          <form onSubmit={handleSubmit} id={id}>
            {
              location !== "/panel/profile/edit/personal_data" &&(
                  <div style={{ backgroundColor: "white", textAlign: "center",  padding: "15px 26px 30px 26px"}}>
                    <FirstLine>
                      <Header>
                        <FormattedMessage id='profile.verified' />
                      </Header>
                    </FirstLine>
                  </div>
              )
            }

            <Wrapper disableShadow={disableShadow}>
              <Field name='photo' component={AvatarUpload} />
              <div>
                <FirstLine>
                  <Field name='first_name' component={TextField} label={`*${f('profile.first.name')}`} />
                  <Field name='last_name' component={TextField} label={`*${f('profile.last.name')}`} />
                </FirstLine>
                <Field
                  name='date_of_birth'
                  label={`*${f('profile.birth.date')}`}
                  render={props => <DateField props={props} minDate={moment('1900', 'YYYY')} maxDate={new Date()} errorMessage={f('error.birth.date')}/> }
                />
                <Field
                  name='email'
                  label={`*${f('profile.email')}`}
                  component={TextField}
                />
                {showInsurance && (
                <Field
                  name='medical_insurance'
                  component={TextField}
                  label={`*${f('profile.insurance')}`}
                />
                )}
                {
                  isDoctor && (
                  <Field
                    component={({ input, meta }) => (
                      <StyledInput>
                        <InputWrapper>
                          <SpecializationsSelect
                            isDisabled={!mayEdit}
                            {...input}
                            meta={meta}
                            placeholder={<FormattedMessage id='profile.specialization' />}
                          />
                        </InputWrapper>
                      </StyledInput>
                    )}
                    name='specialization_id'
                  />
                  )
              }
              <WrapperCivilite>
                <Field
                    name='gender'
                    component={TextField}
                    label={`*${f('profile.gender')}`}
                    options={options}
                />
              </WrapperCivilite>
                <Field
                  name='specialization_list'
                  component={TextField}
                  label={"hidden field"}
                  style={{display: 'none'}}
                />
              </div>
            </Wrapper>
          </form>
        )
      }}
    />
  )
})

PersonalDataForm.defaultProps = {
  disableShadow: false,
  showInsurance: false,
  initialValues: {},
}

PersonalDataForm.propTypes = {
  onSubmit: PropTypes.func.isRequired,
  initialValues: PropTypes.shape({
    first_name: PropTypes.string,
    last_name: PropTypes.string,
    birth_date: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
    gender: PropTypes.string,
  }),
  id: PropTypes.string.isRequired,
  disableShadow: PropTypes.bool,
  showInsurance: PropTypes.bool,
}

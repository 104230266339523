import React, { useState } from 'react'
import { useSelector, useDispatch } from 'react-redux';
import styled from 'styled-components';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import { FormattedMessage, useIntl } from 'react-intl'
import { EditPage } from 'common/UiKit/EditPage'
import { Paper } from 'common/UiKit/Paper'
import { Colors } from 'constants/colors'
import moment from 'moment-timezone';
import FormHelperText from '@mui/material/FormHelperText';
import InputLabel from '@mui/material/InputLabel';
import { List } from 'react-virtualized';
import { history } from '../../../../../utils/history'
import { editProfile } from '../../../../../redux/modules/auth'

const Wrapper = styled.div`
  margin-top: 24px;
`

const Line = styled.div`
  display: flex;
  align-items: ${({ start }) => (start ? 'flex-start' : 'center')};

  .location {
    flex-grow: 1;
  }

  .location-input {
    display: flex;
    justify-content: space-between;
    
    & > *:first-child {
      margin-right: 12px;
    }
  }
`

const Label = styled.div`
  width: 172px;
  min-width: 172px;
  margin-right: 6px;
  opacity: 0.87;
  font-weight: bold;
  font-size: 16px;
  color: ${Colors.dark};
`

const StyledSelect = styled(Select)`
  width: 100%;
  && {
    background-color: #f5f7fb;
  }
`

const FormHelperTextStyled = styled(FormHelperText)`
  width: 100%;
`

const TimeZoneDescription = styled.div`
  opacity: 0.8;
  font-size: 16px;
  line-height: 1.5;
  letter-spacing: normal;
  margin-top: 9px;
  color: #959fb8;
`

const StyledInputLabel = styled(InputLabel)`
  position: absolute;
  font-size: 12px;
  && {
    padding-top: 9px;
    padding-left: 12px;
    z-index: 99;
  }
`
const timeZones = moment.tz.names();

const rowRenderer = (row, cb) => {
  const { key, index, style } = row;
  const option = timeZones[index];
  return (
    <MenuItem
      key={key}
      value={option}
      style={{
        ...style,
        paddingTop: 0,
        paddingBottom: 0,
      }}
      onClick={() => cb(option)}
    >
      {option}
    </MenuItem>
  );
};

const listItems = cb => (
  <List
    width={500}
    height={500}
    rowCount={timeZones.length}
    rowHeight={48}
    rowRenderer={row => rowRenderer(row, cb)}
  />
);

export const Timezones = () => {
  const { formatMessage } = useIntl()
  const f = id => formatMessage({ id })
  const dispatch = useDispatch();

  const profileTz = useSelector(state => state.auth.attributes.time_zone);

  const currentUserTimezone = profileTz || moment.tz.guess();
  const [currentTz, setTz] = useState(currentUserTimezone);
  const [open, setOpen] = useState(false);

  return (
    <EditPage
      label={f('profile.timezone')}
      buttonText='global.save'
      onBackIconClick={() => history.goBack()}
      onButtonClick={() => {
        dispatch(editProfile({ time_zone: currentTz }));
        moment.tz.setDefault(currentTz);
      }}
      background
    >
      <Wrapper>
        <Paper>
          <Line>
            <Label>
              <FormattedMessage id='profile.timezone' />
            </Label>
            <FormHelperTextStyled>
              <StyledInputLabel id='filled-age-native-simple'>
                *<FormattedMessage id='profile.timezone' />
              </StyledInputLabel>
              <StyledSelect 
                onClick={() => setOpen(!open)}
                open={open}
                renderValue={() => currentTz}
                label={f('profile.timezone')}
                defaultValue={currentTz} 
                variant='filled'
                inputProps={{
                  name: 'age',
                  id: 'filled-age-native-simple',
                }}
              >
                {listItems(zone => setTz(zone))}
              </StyledSelect>
            </FormHelperTextStyled>
          </Line>
          <Line>
            <Label />
            <TimeZoneDescription>
              <FormattedMessage id='profile.timezonedesc' />
            </TimeZoneDescription>
          </Line>
        </Paper>
      </Wrapper>
    </EditPage>
  ) 
}

import React, {useState, useEffect} from 'react'
import styled, {css} from 'styled-components'
import {TextButton} from '../TextButton'
import {Colors} from 'constants/colors'
import {FormattedMessage} from 'react-intl'
import {Dialog} from "@material-ui/core";
import PersonAddIcon from '@mui/icons-material/PersonAdd';
import {TextField} from 'common/UiKit/TextField';
import PhoneInput from 'react-phone-number-input';
import {Input} from 'common/UiKit/Input'
import {Field, Form} from 'react-final-form'
import {FieldArray} from 'react-final-form-arrays';
import axios from "axios";
import CircularProgress from "@mui/material/CircularProgress";
import {useSelector} from "react-redux";
import arrayMutators from "final-form-arrays";
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import DeleteForeverRoundedIcon from '@mui/icons-material/DeleteForeverRounded';
import {BUTTON_COLORS} from "../../../../constants/colors";

const DialogStyled = styled(Dialog)`
  backdrop-filter: blur(3px);
  & > * {
      margin : auto;
      font-size : 20px;
  }
`

const AddCircleOutlineIconStyled = styled(AddCircleOutlineIcon)`
  cursor: pointer;
  &:hover {
    color: ${Colors.bluePurple};
  }
`


const ProgressStyled = styled(CircularProgress)`
  && {
    color: white;
  }
`


const FieldStyled = styled(Field)`
  background-color : none;
  margin-left: auto;
  margin-right: auto;
  margin-top: auto;
  margin-bottom: 20px;
    ${props => (props.nblines && css`
        height: calc(${props.nblines}*31px)  ;
        overflow: scroll;
    `)}
`

const AlignInputTrash = styled.div`
      min-width: 555px;
      display: flex;
      justify-content: center;
      align-item: center; 
    ${props => ((props.countEmail > 0 || props.countPhone > 0) && css`
      min-width: 560px;
      display: flex;
      justify-content: space-between;
      align-item: center;   
    `)}    
`

const DeleteStyled = styled(DeleteForeverRoundedIcon)`
    margin: 10px 20px 10px 20px;
    flex-grow: 1;
    max-width: 40px;
`

const AlignButton = styled.div`
    display: flex;
    justify-content: space-around;
    align-item: center;
    margin-top : 50px;
      & > * {
          margin : auto;
          font-size : 20px;
      }
`
const AlignIconLabel = styled.div`
    margin : 30px;
    padding-bottom: 20px;
    width: 100%;
    & > * {
        text-align: left;
    }
`

const AlignInput = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    align-item: flex-start;
    margin-top : 10px;
    & > * {
        margin : auto;
        font-size : 20px;
    }
`

const Content = styled.div`
        & > * {
            margin : 20px;
            font-size :16px;
        }
`

const StyleLabel = styled.span`
        margin : 20px 10px 20px 5px;
        font-size :18px;
`

const ButtonIcon = styled(TextButton)`
 &, &:hover {
    text-transform: lowercase;
    background: none;
    color: inherit;
    border: none;
    padding: 0;
    font: inherit;
    cursor: pointer;
    outline: inherit;
    box-shadow: none;
  }
    ${props => (props.disabled && css`
    display: none;
    `)}
`


const Button = styled(TextButton)`
    margin-bottom: auto;
    margin-left: auto;
    margin-right: auto;    
    width: 144px;
    height: 40px;
    box-shadow: none;
    text-transform: none;
    font-size: 14px;
    & > * {
      margin-right: 7px;
      margin-left: 7px;
    }
    &&:hover {
      background-color: ${BUTTON_COLORS.purpleHover};
      color: #fff;
    }
  && {
    ${props => (props.VertPrintemps && css`
      background-color: ${props.VertPrintemps};
      color: white;
      &:hover {
        color: white;
        border: none;
        background-color: #178976
      }
    `)}
    ${props => (props.isButtonMenu && css`
      width: 180px;
    `)}
    ${props => (props.centered && css`
      left: calc(50% - 120px) !important;
      right: auto !important;
    `)}
  }
`


const PhoneNumberInput = styled(PhoneInput)`
  margin-bottom: 15px;
  width: 520px;
  display: flex;
  justify-content: space-between;
  align-items: center;
`

const InputStyled = styled(Input)`
  && {
    margin-bottom: 0;
    margin-right: 0;
  }
`

const InputComponent = React.forwardRef((props, ref) => <InputStyled {...props}  inputRef={ref}/>)

const PhoneField = ({input, meta}) => (
  <PhoneNumberInput
    {...input}
    international
    variant={'outlined'}
    defaultCountry='FR'
    value={input.value}
    onChange={(v) => {
      input.onChange(v || '+')
    }}
    inputComponent={InputComponent}
    displayInitialValueAsLocalNumber
    numberInputProps={{
      autoFocus: false,
      color: 'primary',
      error: meta.touched && meta.error,
      helperText: meta.touched && meta.error,
    }}
    limitMaxLength
  />
)

export const MenuInvitation = () => {
  const [dialogOpen, setDialogOpen] = useState(false);
  const [messageSent, setMessageSent] = useState(false);
  const [submit, setSubmit] = useState(false);
  const Firstname = useSelector(state => state.auth.attributes.first_name);
  const Lastname = useSelector(state => state.auth.attributes.last_name);
  const [countEmail, setCountEmail] = useState(0);
  const [countPhone, setCountPhone] = useState(0);
  const [arrayEmail, SetArrayEmail] = useState({id: 0, lines: [{id: 0, email: ''}]})
  const [arrayPhone, SetArrayPhone] = useState({id: 0, lines: [{id: 0, phone: ''}]})


  useEffect(() => {
  }, [dialogOpen, submit, messageSent, countEmail, countPhone, arrayEmail, arrayPhone]);

  const onSubmit = data => {
    {
      (
        axios('api/doctor/invitation/colleague', {
          method: 'POST',
          data,
        })
          .then((res) => {
            {
              res &&
              setDialogOpen(false)
              setSubmit(false)
              setCountEmail(0)
              setCountPhone(0)
            }
          })
          .finally(() => {
            setMessageSent(true)
            setCountEmail(0)
            setCountPhone(0)
          })
      )
    }
  }


  const validate = (values) => {

    // const errors = {arrayEmail: [{id: 0, email: ''}], arrayPhone: [{id: 0, phone: ''}], text: ""}
    //
    // if (values.arrayEmail[0]['email'] === '' && values.arrayPhone[0]['phone'] === '') {
    //   if (values.arrayEmail[0]['email'] === '') {
    //     errors.arrayEmail[0].email = <FormattedMessage id='error.email.or.phone.required'/>
    //   }
    // }
    //
    // if (values.arrayEmail[0]['email'] === '' && values.arrayPhone[0]['phone'] === '') {
    //   if (values.arrayPhone[0]['phone'] === '') {
    //     errors.arrayPhone[0].phone = <FormattedMessage id='error.email.or.phone.required'/>
    //   }
    // }
    //
    // if (!values.text) {
    //   errors.text = <FormattedMessage id='error.text.required'/>
    // }
    // return errors
  }

  return (
    <>
      {!messageSent && dialogOpen &&
        <DialogStyled
          open={dialogOpen}
          onClose={() => {
            setDialogOpen(false)
            setCountEmail(0)
            setCountPhone(0)
          }}
          PaperProps={{
            style: {
              width: '1250px',
              borderRadius: 30,
              border: '1px solid #6633FF',
              padding: '50px',
            }
          }}
          BackdropProps={{
            style: {
              opacity: 0,
            }
          }}
        >

          <Content>
            <p style={{color: '#6633FF', fontSize: '25px', width: '100%', textAlign: 'center', margin: 'auto'}}>
              Invitez des confrères à rejoindre Conex Santé</p>
            <Form
              mutators={{
                ...arrayMutators,
              }}
              onSubmit={onSubmit}
              validate={validate}
              initialValues={{
                arrayEmail: arrayEmail?.lines,
                arrayPhone: arrayPhone?.lines,
                text: "Je vous invite à essayer Conex Santé !\nL'application de télémédecine collaborative qui vous permet de réaliser des téléexpertises, des téléconsultations et d'échanger avec vos confrères et consoeurs grâce à une messagerie instantanée et sécurisée."
              }}
              render={({form, handleSubmit, submitting, values}) => (
                <form onSubmit={handleSubmit}>
                  <FieldStyled
                    name={'text'}
                    component={TextField}
                    // label={<FormattedMessage id={'Texte de bienvenue'}/>}
                    type={'text'}
                    multiline
                    variant={'outlined'}
                    nblines={11}
                    autoFocus
                  />
                  <br/>
                  <br/>
                  <StyleLabel>Par email: </StyleLabel>
                  <AlignInput>
                    <FieldArray name='arrayEmail'>
                      {
                        fieldArrayProps => fieldArrayProps.fields.map((name, key) => (
                            <AlignInputTrash key={key} countEmail={countEmail}>
                              <Field
                                key={name}
                                name={`${name}.email`}
                                component={TextField}
                                label={<FormattedMessage id={'auth.email'}/>}
                                type={'email'}
                                countEmail={countEmail}
                                variant={'outlined'}
                                autoFocus
                                multiple
                              />
                              {
                                countEmail > 0
                                &&
                                <DeleteStyled
                                  fontSize={"medium"}
                                  onClick={() => {
                                    fieldArrayProps.fields.remove(key)
                                    setCountEmail(countEmail - 1)
                                  }}
                                  style={{cursor: 'pointer'}}
                                />
                              }
                            </AlignInputTrash>
                          )
                        )
                      }
                    </FieldArray>
                    <AlignIconLabel>
                      <ButtonIcon disabled={values.arrayEmail[countEmail]['email'] === ''}
                                  onClick={() => {
                                    form.mutators.push('arrayEmail', {id: countEmail + 1, email: ''})
                                    setCountEmail(countEmail + 1);
                                  }}
                      >
                      <AddCircleOutlineIconStyled
                        fontSize={'medium'}
                      />
                      <StyleLabel>Ajouter un email</StyleLabel>
                      </ButtonIcon>
                      <br/>
                    </AlignIconLabel>
                  </AlignInput>
                  <StyleLabel>Par téléphone: </StyleLabel>
                  <AlignInput>
                    <FieldArray name='arrayPhone'>
                      {
                        fieldArrayProps => fieldArrayProps.fields.map((name, key) => (
                            <AlignInputTrash key={key} countPhone={countPhone}>
                              <Field
                                name={`${name}.phone`}
                                fontSize={"medium"}
                                component={PhoneField}
                                label={<FormattedMessage id={'global.phone'}/>}
                                type={'text'}
                                autoFocus
                              />
                              {(countPhone > 0 || key !== 0)
                                &&
                                <DeleteStyled
                                  onClick={() => {
                                    fieldArrayProps.fields.remove(key)
                                    setCountPhone(countPhone - 1)
                                  }}
                                  style={{cursor: 'pointer'}}
                                />
                              }
                            </AlignInputTrash>
                          )
                        )
                      }
                    </FieldArray>
                    <AlignIconLabel>
                      <ButtonIcon
                        disabled={values.arrayPhone[0]['phone'] === ''}
                        onClick={() => {
                          form.mutators.push('arrayPhone', {id: countPhone + 1, phone: ''})
                          setCountPhone(countPhone + 1);
                        }}>
                      <AddCircleOutlineIconStyled
                        fontSize={'medium'}
                      />
                      <StyleLabel>Ajouter un téléphone</StyleLabel>
                      </ButtonIcon>
                    </AlignIconLabel>
                  </AlignInput>
                  <AlignButton>
                    <Button
                      color='primary'
                      onClick={() => {
                        setDialogOpen(false)
                        setCountEmail(0)
                        setCountPhone(0)
                      }}>
                      <FormattedMessage id='global.close'/>
                    </Button>
                    <Button
                      color='secondary'
                      onClick={() => {
                        if(values.arrayEmail[0]['email'] !== '')
                        {
                          values.arrayEmail = values.arrayEmail.filter(el => (el['email'] !== ''))
                        }
                        setSubmit(true)
                        setCountEmail(0)
                        setCountPhone(0)
                      }}
                      VertPrintemps={'#21C5A9'}
                      disabled={values.arrayEmail[0]['email'] === ''  && values.arrayPhone[0]['phone'] === ''}
                      type='submit'>
                      {submit ? <ProgressStyled size={20}/> : <PersonAddIcon/>}
                      <FormattedMessage id='auth.send.invite.colleague'/>
                    </Button>
                  </AlignButton>
                </form>
              )}
            />
          </Content>
        </DialogStyled>
      }
      {
        (messageSent && !dialogOpen) &&
        <DialogStyled
          open={messageSent}
          onClose={() => {
            setMessageSent(false)
            setCountEmail(0)
            setCountPhone(0)
          }
          }
          PaperProps={{
            style: {
              borderRadius: 30,
              border: '1px solid #6633FF',
              padding: '50px',
              display: 'flex',
              flexDirection: 'column',
            }
          }}
          BackdropProps={{
            style: {
              opacity: 0,
            }
          }}
        >
          <p style={{
            color: '#6633FF',
            fontSize: '25px',
            width: '100%',
            textAlign: 'center',
            margin: '0',
            paddingBottom: '30px'
          }}>
            Message Envoyé !</p>
          <Button
            color='primary'
            onClick={() => {
              setMessageSent(false)
              setCountEmail(0)
              setCountPhone(0)
            }}>
            <FormattedMessage id='global.close'/>
          </Button>
        </DialogStyled>
      }

      <Button
        isButtonMenu={'isButtonMenu'}
        color='secondary'
        VertPrintemps={'#47C7B6'}
        onClick={() => setDialogOpen(true)}>
        <PersonAddIcon/>
        <FormattedMessage id='auth.invite'/>
      </Button>

    </>
  )
}


import React from 'react'
import styled, { css } from 'styled-components'
import {FormattedMessage} from "react-intl";
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import DoneIcon from '@mui/icons-material/Done';
import {useSelector} from "react-redux";
import idx from "idx";



export const Stepper = () => {
  // si besoin de rajouter des images au carrousel , faire un tableau de n chaine de caractere, initialiser le useState à une des valeur du tableau, et changer les valeurs du hook de manière cyclique
  const currentPath = window.location.pathname;
  const isPhoneStep = currentPath.includes('auth/phone')
  const isAuthInformationStep = currentPath.includes('panel/profile')
  const myUserStatus = useSelector(state => idx(state, _ => _.auth.attributes.status))
  const userType = useSelector(state => idx(state, _ => _.auth.type))

  return(
    <Layout >
      <Step>
        <Pastille><DoneIcon/></Pastille>
        <Text className={'Text'}><FormattedMessage id='profile.creation.account.label' /></Text>
      </Step>
      <ArrowForwardIosIcon
        sx={{
          color: '#6633FF',
        }}
      />
      {(myUserStatus !== 'PRE_REGISTRATED' || userType !== 'Doctor') &&
        <>
          <Step>
            <Pastille>{isAuthInformationStep ? <DoneIcon/> : 2}</Pastille>
            <Text isPhoneStep ={isPhoneStep}><FormattedMessage id='global.phone.label' /></Text>
          </Step>
          <ArrowForwardIosIcon
          sx={{
            color: '#6633FF',
          }}
          />
        </>}
      <Step>
        <Pastille>{myUserStatus !== 'PRE_REGISTRATED' || userType !== 'Doctor' ? 3 : 2}</Pastille>
        <Text isAuthInformationStep ={isAuthInformationStep}><FormattedMessage id='profile.title.my.information' /></Text>
      </Step>
    </Layout>
    )
}

// -------------------------------CSS--------------------------------------------------------------

const Layout = styled.div`
  display: flex;
  align-items: center;
  color: grey;
  font-size : 1.02em;
  font-family: Roboto;
  font-weight: 500;
  margin: 20px 0;
  width: min(700px, 100%);
`

const Text = styled.div`
  text-align: center;
  ${({isPhoneStep}) => (isPhoneStep  && css` 
      color: #6633FF;
  `)}
   ${({isAuthInformationStep}) => (isAuthInformationStep && css` 
      color: #6633FF;
  `)}
  
  @media screen and (max-width: 700px) {
    margin-top: 10px;
  }
`



const Pastille = styled.div`
  min-width: 50px;
  min-height: 50px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size : 1.02em;
  font-weight: 500;
  color: white;
  font-family: 'Roboto', sans-serif;
  border-radius: 25px;
  background-color: #6633FF;
`

const Step = styled.div`
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: space-evenly;
  align-self: start;
  
  @media screen and (max-width: 700px) {
    flex-direction: column;
  }
`
import { combineReducers } from 'redux'
import { calendarFiltersReducer } from './filters'
import { calendarListReducer } from './list'
import { holidaysReducer } from './holidays'

export const calendarReducer = combineReducers({
  filters: calendarFiltersReducer,
  list: calendarListReducer,
  holidays: holidaysReducer,
})

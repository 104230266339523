import axios from 'axios';
import get from 'lodash/get';
import queryString from 'query-string';

const statuses = {
  '-1': 'rejected',
  0: 'moderated',
  1: 'verified',
}

export const teamValuesFormatter = values => ({ 
  ...values,
  members: values.members.filter(el => el),
  geo_lat: get(values, 'geo.lat', ''),
  geo_lon: get(values, 'geo.lng', ''),
  geo: undefined,
})

export const getDoctorTeams = async () => {
  const result = await axios('/api/doctor/team/my');
  return result.data.data;
}

export const getDoctorStructures = async () => {
  const result = await axios('/api/doctor/structure/list?alluser=1');
  return result.data.data;
}

export const getAllTeams = async (filter) => {
  const filterString = queryString.stringify(filter);
  const result = await axios(`/api/doctor/team?${filterString}`);
  return result.data;
}

export const getStatus = (statusCode) => {
  const statusName = statuses[statusCode]
  return statusName
}

export const getTeamById = async (id) => {
  const result = await axios(`/api/doctor/team/view/${id}`);
  return result.data.data;
}

export const getTeamMembersById = async (id) => {
  const result = await axios(`/api/doctor/team/members?team_id=${id}`);
  return result.data.data;
}

export const sendInvite = async ({ team_id, member_id }) => {// eslint-disable-line
  const result = await axios.post('/api/doctor/team/invite', { team_id, member_id });// eslint-disable-line
  return result.data.data;
}

export const deleteMember = async ({ member_id }) => {// eslint-disable-line
  const result = await axios.delete(`/api/doctor/team/${member_id}`);// eslint-disable-line
  return result.data.data;
}

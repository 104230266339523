import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import { Panel } from 'common/UiKit/Panel'
import { Colors } from 'constants/colors'

import { AuthDialog } from '../../AuthDialog'

const Wrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 3vh 0 0;
  margin-bottom: auto;
  border-radius: 4px;
  height: 100%;
`

const Text = styled.p`
  margin: 0;
  padding-bottom: 30px;
  font-family: Roboto;
  font-size: 16px;
  line-height: 1.5;
  color: ${Colors.blueGrey};
  text-align: center;
`

const PanelStyled = styled(Panel)`
  background-color: ${Colors.white15};
`

export const Form = ({
  button,
  children,
  heading,
  loading,
  onButtonClick,
  onIconClick,
  textStyle,
  text
  }) => (
    <AuthDialog backIcon={false} heading={heading} onIconClick={onIconClick}>
      <Wrapper>
        <PanelStyled
          button={button}
          buttonProps={{ isFetching: loading, onClick: onButtonClick }}
        >
          <Text style={textStyle}>
            {text}
          </Text>
          {children}
        </PanelStyled>
      </Wrapper>
    </AuthDialog>
)

Form.defaultProps = {
  loading: false,
  onIconClick: () => null,
}

Form.propTypes = {
  button: PropTypes.any.isRequired,
  children: PropTypes.node.isRequired,
  heading: PropTypes.any.isRequired,
  loading: PropTypes.bool,
  onButtonClick: PropTypes.func.isRequired,
  onIconClick: PropTypes.func,
  text: PropTypes.any.isRequired,
  textStyle: PropTypes.object,
}

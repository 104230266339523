import React, {useState} from 'react'
import PropTypes from 'prop-types'
import idx from 'idx'
import { compose, withProps } from 'recompose'
import {connect, useSelector} from 'react-redux'
import { EditPage } from 'common/UiKit/EditPage'
import { history } from '../../../../../utils/history'
import { externalFormSubmit } from '../../../../../helpers/externalFormSubmit'
import { editMedicalReport } from '../../../../../redux/modules/consultations'
import { socket } from '../../../../../services/socket'
import { CONSULTATION_TYPES } from '../../../../../constants/consultation'
import { MedicalReportForm as Form } from '../../../../common/MedicalReportForm'
import styled from "styled-components";

const MedicalReportFormComponent = ({
  editMedicalReport,
  afterSubmit,
  onButtonClick,
  loading,
  initialValues,
  consiliumStatus,
  employmentType,
}) => {
  const [currentStep, setCurrentStep] = useState(1);
  const consulationType  = useSelector(state => state.consultations.consultation.attributes.type)

  return (
    <Wrapper>
      <EditPage
      label={'concilium.complete'}
      onBackIconClick={() => {
        currentStep === 2 ? setCurrentStep(1) : history.goBack()
      }}
      buttonText='global.finish'
      onButtonClick={onButtonClick}
      loading={loading}
      currentStep={currentStep}
      consiliumStatus={consiliumStatus}
      background
      >
      <Form
        onSubmit={editMedicalReport}
        id='report'
        afterSubmit={afterSubmit}
        initialValues={initialValues}
        consiliumStatus={consiliumStatus}
        employmentType={employmentType}
        currentStep={currentStep}
        setCurrentStep={setCurrentStep}
        disableShadow
        consulationType={consulationType}
      />
      </EditPage>
    </Wrapper>
  )
}

MedicalReportFormComponent.propTypes = {
  editMedicalReport: PropTypes.func.isRequired,
  afterSubmit: PropTypes.func.isRequired,
  loading: PropTypes.bool.isRequired,
  onButtonClick: PropTypes.func.isRequired,
  onBackIconClick: PropTypes.func.isRequired,
  initialValues: PropTypes.object.isRequired,
  consiliumStatus: PropTypes.object.isRequired,
}

// TODO add loading state
const enhance = compose(
  connect(
    state => ({
      loading: state.loading.editMedicalReport,
      chatId: idx(state, _ => _.chats[state.consultations.consultation.id].chat.id),
      initialValues: {
        symptoms: idx(state, _ => _.consultations.consultation.attributes.symptom_explanation),
      },
      consiliumStatus: {
        consiliumStatus: idx(state, (_) => {
          const status = _.consultations.consultation.attributes.consiliumStatus
          if ((_.consultations.consultation.relationships.doctor.data.id === _.auth.id
            && (_.consultations.consultation.attributes.type === CONSULTATION_TYPES.scheduled || _.consultations.consultation.attributes.type === CONSULTATION_TYPES.TCA ))) {
            return false
          }
          return status
        }),
      },
    }),
    { editMedicalReport },
  ),
  withProps(props => ({
    afterSubmit: () => {
      socket.notify(props.chatId)
      history.push(props.consiliumStatus.consiliumStatus ? '/panel/consultations/session' : '/panel/teleconsultations/session')
    },
    onBackIconClick: () => history.goBack(),
    onButtonClick: () =>  externalFormSubmit('report'),
  })),
)

const Wrapper = styled.div`
  height: 100%;
`

export const MedicalReportForm = enhance(MedicalReportFormComponent)

import React from 'react';
import {CircularProgress} from '@mui/material';
import styled from "styled-components";
import PropTypes from "prop-types";
import {Colors} from '../../../constants/colors'

const PageWrapper = ({ panelBar, noWrapperPadding, isFetching, fullWidth, children, ...rest }) => {
  return (
    <PageWrapperDiv {...rest}>
      { !!panelBar && panelBar }
      <Wrapper noWrapperPadding={noWrapperPadding}>
        <Content width={fullWidth ? '100%' : '720px'} withoutOverflow={rest.withoutOverflow}>
          {
            isFetching
              ? (
                <LoaderWrapper $noWrapperPadding={noWrapperPadding}>
                  <CircularProgress size={80} />
                </LoaderWrapper>
              )
              : children
          }
        </Content>
      </Wrapper>
    </PageWrapperDiv>
  )
}

PageWrapper.defaultProps = {
  isFetching: false,
  fullWidth: false,
}

PageWrapper.propTypes = {
  children: PropTypes.node.isRequired,
  isFetching: PropTypes.bool,
  fullWidth: PropTypes.bool,
}

export default PageWrapper


// --------------------------------- Styles CSS -------------------------------------

const Wrapper = styled.div`
  padding: ${({ noWrapperPadding, status }) => status !== 'LIMITED' ? (noWrapperPadding ? '0' : '24px') : '0px'};
`

const Content = styled.div`
  width: ${props => props.width};
  margin: 0 auto;
  ${props => (props.width === '100%' && !props.withoutOverflow) && `
    overflow-x: auto;
  `}
`

const PageWrapperDiv = styled.div`
  background-color: ${({ white }) => (white ? Colors.white : Colors.background)};
  min-height: 100%;
  position: relative;
`

const LoaderWrapper = styled.div`
  height: calc(100vh - 56px - ${({$noWrapperPadding}) => $noWrapperPadding ? '0px' : '48px'});
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
`

import React, {useState, useEffect} from 'react'
import ArrowBackIosNewIcon from '@mui/icons-material/ArrowBackIosNew';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import DrawVideo from '../../images/auth/undraw_group_video.svg'
import DrawSocialNetwork from '../../images/auth/undraw_social_networking_re_i1ex.svg'
import styled, { css } from 'styled-components'
import {FormattedMessage} from "react-intl";




export const Carrousel = () => {
  // si besoin de rajouter des images au carrousel , faire un tableau de n chaine de caractere, initialiser le useState à une des valeur du tableau, et changer les valeurs du hook de manière cyclique
  const currentPath = window.location.pathname;
  const isPatient = currentPath === '/auth/patient'
  const [DrawImage, toggleDrawImage] = useState(false)

  useEffect(() => {
    isPatient ? toggleDrawImage(true) : toggleDrawImage(false);
  }, [isPatient])

  return(
    <Layout >
        <ArrowStyled><ArrowBackIosNewIcon fontSize={'large'} onClick={ () => {toggleDrawImage(!DrawImage)}}/></ArrowStyled>
      <WrapperCarrousel>
      {DrawImage ?
        <Text>
          <FormattedMessage id='auth.teleexpertise.assisted.and.telexpertise.slogan'/>
          <ul>
            <li><FormattedMessage id='auth.handle.scheduling'/></li>
            <li><FormattedMessage id='auth.help.your.patient.slogan'/></li>
          </ul>
        </Text>
        :
        <Text>
          <FormattedMessage id='auth.telemedecine.complete.slogan'/>
          <br/>
          <br/>
          <FormattedMessage id='auth.telemedecine.secure.value.slogan'/>
          <ul>
            <li><FormattedMessage id='auth.teleexpertise.slogan'/></li>
            <li><FormattedMessage id='auth.teleexpertise.assisted.patient.doctor.slogan'/></li>
          </ul>
        </Text> }
        {DrawImage ? <ImageSC src={DrawVideo} alt="DrawVideo"/> : <ImageSC src={DrawSocialNetwork} alt="DrawSocialNetwork"/>}
      </WrapperCarrousel>
        <ArrowStyled><ArrowForwardIosIcon fontSize={'large'} onClick={ () => {toggleDrawImage(!DrawImage)}}/></ArrowStyled>
    </Layout>
    )
}

// -------------------------------CSS--------------------------------------------------------------

const Layout = styled.div`
  width: 50vw;
  height: 80vh;
  display: grid;
  grid-template-columns: 15% 70% 15%;
`

const ImageSC = styled.img`
    height: 40vh;
    margin: auto;
  `

const Text = styled.div`
    font-size : 1.5em;
    font-family: Roboto;
    font-weight: 500;
    color: white;
    display: flex;
    flex-direction : column;
    justify-content: end;
    align-items: center;
    & > ul > li {
       font-size : 0.9em;
       font-weight: 200;
       margin-top: 0.1em;
       margin-bottom: 0.1em;
    }
  margin-bottom: 1.5em;
  `

const WrapperCarrousel = styled.div`
    display: grid;
    grid-template-rows: 50% 50%;
  `

const ArrowStyled = styled.div`
    margin: auto;
    & > * {
       color : white;
       cursor: pointer;
    }
`
import React from 'react'
import { Route, Switch } from 'react-router-dom'
import { Profile } from './Profile'
import { MedicalReport } from './MedicalReport'
import { Prescription } from './Prescription'
import { Payment } from '../../../../Doctor/Consultations/Session/Menu/Payment'

export const Menu = () => (
  <Switch>
    <Route
      path='/panel/consultations/session/menu/report/:id'
      component={MedicalReport}
    />
    <Route
      path='/panel/consultations/session/menu/prescription'
      component={Prescription}
    />
    <Route
      path='/panel/consultations/session/menu/payment'
      component={Payment}
    />
    <Route
      path='/panel/consultations/session/menu'
      component={Profile}
    />
  </Switch>
)

import { compose, withProps } from 'recompose'
import { connect } from 'react-redux'
import idx from 'idx'
import { MedicalReport as MedicalReportComponent } from './MedicalReportComponent'
import { history } from '../../../../../../utils/history'

const enhance = compose(
  connect(
    (state, props) => ({
      consultation: idx(state, _ => _.consultations.consultation),
      downloadLink: idx(state, _ => _
        .consultations.consultation.relationships.medicalReport.data
        .find(item => item.attributes.doctor === +props.match.params.id).attributes.pdf.report),
    }),
  ),
  withProps(props => ({
    onCloseIconClick: () => history.push('/panel/consultations/session'),
    onDownloadIconClick: () => window.open(props.downloadLink),
    doctorId: props.match.params.id,
  })),
)

export const MedicalReport = enhance(MedicalReportComponent)

import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import Tab from '@mui/material/Tab'
import Tabs from '@mui/material/Tabs'
import { Colors } from 'constants/colors'
import { FormattedMessage } from 'react-intl'
import {
  ThemeProvider, StyledEngineProvider, createTheme,
} from '@mui/material';

const ToTabStyled = createTheme(({
  palette: {
    primary: {
      main: '#6633FF',
    },
  },
}));

const TabsStyled = styled(({ ...rest }) => (
  <Tabs {...rest} classes={{ indicator: 'indicator' }} />
))`
  height: 48px;
  background-color: transparent;
  padding: 0 calc((85% - 720px)/2) 0 calc((115% - 720px)/2);

  & .indicator {
    opacity: 0.1;
    height: 48px;
    background-color: '#E7E2F6';
    border-radius: 4px;
  }
`

const TabStyled = styled(({ ...rest }) => (
  <Tab {...rest} classes={{ wrapper: 'wrapper' }} />
))`
 
    width: 240px;
    font-size: 14px;
    font-weight: 500;

`

export const Picker = ({
  tab,
  changeTab,
}) => (
  <StyledEngineProvider injectFirst>
    <ThemeProvider theme={ToTabStyled}>
      <TabsStyled
        value={tab}
        onChange={changeTab}
        indicatorColor='primary'
        textColor='primary'
      >
        <TabStyled disableRipple label={<FormattedMessage id='consultations.upcoming' />} />
        <TabStyled disableRipple label={<FormattedMessage id='consultations.past' />} />
      </TabsStyled>
    </ThemeProvider>
  </StyledEngineProvider>
 
)

Picker.propTypes = {
  tab: PropTypes.number.isRequired,
  changeTab: PropTypes.func.isRequired,
}

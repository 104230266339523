import React from 'react';
import PropTypes from 'prop-types'
import styled from 'styled-components'
import { useSelector } from 'react-redux'
import idx from 'idx'
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline'
import WarningIcon from '@mui/icons-material/Warning'
import IconButton from '@mui/material/IconButton';
import GetAppIcon from '@mui/icons-material/GetApp';
import { FormattedMessage } from 'react-intl'
import { ConsultationReportPanel } from 'common/UiKit/ConsultationReportPanel'
import { TextButton } from 'common/UiKit/TextButton'
import { Colors } from '../../../../../../../constants/colors'

const Field = styled.div`
  margin: 40px 0; font-size: 14px;
  line-height: 1.43;
  color: ${Colors.blueGrey};
  word-break: break-all;

  & > :first-child {
    color: ${Colors.dark80};
    font-size: 16px;
    font-weight: 500;
    margin: 0 0 8px;
  }
`

const List = styled.ol`
  counter-reset: myCounter;
  padding-left: 0;
  margin: 0;
  position:sticky;

  &>li {
    position: relative;
    margin-left:30px;
    list-style: none;
    margin-bottom: 20px;
    color: ${Colors.dark80};
    font-weight: 500;
  }

  span {
    word-break: break-word;
    display: inline-block;
    margin-left: 25px;
    width: auto;
  }
  button{
    width: 20px;
    position: absolute;
    padding: 10px;
  }
  p {
    margin: 0 0 0 25px;
    color: ${Colors.blueGrey};
    font-weight: normal;
  }

  &>li:before {
    counter-increment: myCounter;
    content: counter(myCounter) ".";
    margin-right: 13px;
    position: absolute;
    top: 0;
  }

  svg {
    position: absolute;
    right: 0;
    top: 0;
    font-size: 19px;
  }
`

const ButtonsField = styled.div`
  margin-top: 24px;

  button {
    display: block;
    width: 281px;
    height: 40px;
    margin: 0 auto 16px;
    font-weight: 500;
  }
`

const MediumWarningIcon = styled(WarningIcon)`
  color: ${Colors.yellowOchre};
`

const getIcon = (id, checkedDrugs) => {
  if (!Object.keys(checkedDrugs).length || !checkedDrugs[id]) {
    return null
  }

  const hightIncompability = checkedDrugs[id].find(d => d.attributes.type === 'HIGH')
  const mediumIncompaility = checkedDrugs[id].find(d => d.attributes.type === 'MEDIUM')

  if (hightIncompability) {
    return <WarningIcon color='error' />
  }

  if (mediumIncompaility) {
    return <MediumWarningIcon />
  }

  return <CheckCircleOutlineIcon color='primary' />
}

export const MedicalReport = ({
  onCloseIconClick,
  onDownloadIconClick,
  // onCheckCompabilityButtonClick,
  consultation,
  isPatient,
  checkedMedicinesWithProfile,
  // loading,
  isPrescription,
  doctorId,
}) => {
  const report = idx(consultation, _ => _
    .relationships.medicalReport.data
    .find(item => item.attributes.doctor === +doctorId)) || { attributes: {} }
  const specializations = useSelector(state => state.content.specializations)

  const mappingNCWS = {
    0: 'global.not',
    1: 'global.yes',
    yes: 'global.yes',
    no: 'global.not',
    'yes-fast': 'global.yes-fast',
  }

  return (
    <ConsultationReportPanel
      onCloseIconClick={onCloseIconClick}
      onDownloadIconClick={onDownloadIconClick}
      heading={isPrescription ? 'consultations.prescription' : 'consultations.report'}
      patientFirstName={idx(consultation, _ => _.relationships.patient.data.attributes.first_name)}
      patientLastName={idx(consultation, _ => _.relationships.patient.data.attributes.last_name)}
      doctorFirstName={idx(consultation, _ => _.relationships.doctor.data.attributes.first_name)}
      doctorLastName={idx(consultation, _ => _.relationships.doctor.data.attributes.last_name)}
      specialization={idx(consultation, _ => _.relationships.doctor.data.attributes.specialization)}
      date={idx(consultation, _ => _.attributes.creation_time)}
    >
      {!isPrescription && (
        <>
          <Field>
            <p>
              <FormattedMessage id='consultations.symptoms' />
            </p>
            {idx(consultation, _ => _.attributes.symptom_explanation)}
          </Field>
          <Field>
            <p>
              <FormattedMessage id='consultations.diagnosis' />
            </p>
            {report.attributes.diagnosis}
          </Field>
          <Field>
            <p>
              <FormattedMessage id='consultation.redirect.question' />
            </p>
            <FormattedMessage id={mappingNCWS[report.attributes.needConsultationWithSpecialist]} />
          </Field>
          <Field>
            <p>
              <FormattedMessage id='consultation.another.redirect.question' />
            </p>
            {
              report.attributes.redirectToSpecialization
              && report.attributes.redirectSpecializationId
                ? (specializations
                  .find(i => i.attributes.key === report.attributes.redirectSpecializationId) || {
                  attributes: { name: report.attributes.redirectSpecializationId },
                })
                  .attributes.name
                : <FormattedMessage id='global.not' />
            }
          </Field>
        </>
      )}
      <Field>
        <p>
          <FormattedMessage id='consultations.prescribed' />
        </p>
        <List>
          {(idx(consultation, _ => _.relationships.drugs.data) || []).map(({ attributes }) => (
            <>
              <IconButton onClick={() => { window.open(`${window.env.API_URL}/general/consultation/${consultation.id}/pdf/prescription/${attributes.id}/${attributes.medical_report_id}`) }} color='primary' aria-label='delete' size='small'>
                <GetAppIcon fontSize='inherit' />
              </IconButton>

              <li key={attributes.id}>
                <span>{attributes.name}</span>
                {isPatient && getIcon(attributes.api_id, checkedMedicinesWithProfile)}
              </li>
            </>
          ))}
        </List>
      </Field>
      {isPatient
        ? (
          <ButtonsField>
            {onDownloadIconClick && (
              <TextButton
                variant='extended'
                onClick={onDownloadIconClick}
                color='primary'
              >
                <FormattedMessage id='global.download' />
              </TextButton>
            )}
          </ButtonsField>
        )
        : null}
    </ConsultationReportPanel>
  )
}

MedicalReport.defaultProps = {
  isPatient: false,
  // onCheckCompabilityButtonClick: () => null,
  onDownloadIconClick: false,
  checkedMedicinesWithProfile: {},
  //loading: false,
  isPrescription: false,
}

MedicalReport.propTypes = {
  onCloseIconClick: PropTypes.func.isRequired,
  onDownloadIconClick: PropTypes.oneOfType([PropTypes.bool, PropTypes.func]),
  consultation: PropTypes.object.isRequired,
  isPatient: PropTypes.bool,
  checkedMedicinesWithProfile: PropTypes.objectOf(PropTypes.array),
  isPrescription: PropTypes.bool,
}

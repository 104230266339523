import {FormattedMessage} from "react-intl";
import React from "react";

export const validatePassWordConditions = (password,confirmation =null,errorPassword,confirmationError = null) => {
  const errors = {}

  if (password && password.length < 12) errors[errorPassword] = <FormattedMessage id='error.password.length' />
  const regexPattern = new RegExp("^(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#\\$%\\^&\\*?\\+\\- ])(?=.{12,})");
  if (!regexPattern.test(password)) {
    errors[errorPassword] = <FormattedMessage id='error.passwordconditions' />
  }
  if(confirmation){
    if (password !== confirmation) errors[confirmationError] = <FormattedMessage id='error.passwordsame' />
  }
  return errors

}

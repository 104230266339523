import React from 'react'
import styled from "styled-components";
import {NearMe} from "@mui/icons-material";

const Wrapper = styled.div`
  text-align: right;
  vertical-align: middle;

  & > span {
    display: inline-block;
    height: 24px;
    vertical-align: middle;
  }
  & > svg {
    vertical-align: middle;
  }
`

/**
 * This component display the distance to a geoloc point in km
 * @param distance distance in km to the point
 * @param float
 * @param fontsize props for controlling the MUI icon size
 * @returns {JSX.Element}
 * @constructor
 */
export const DistanceDisplay = ({distance, float = 'none', fontsize = 'medium'}) => {
  distance = distance / 1000.0;
  let distanceDisplay;
  if (distance < 1) {
    distanceDisplay = distance.toFixed(2) + ' km';
  } else if (distance < 10) {
    distanceDisplay = distance.toFixed(1) + ' km';
  } else {
    distanceDisplay = distance.toFixed(0) + ' km';
  }
  return (
    <Wrapper style={{ float }}>
      <NearMe fontSize={fontsize}/>
      <span> {distanceDisplay}</span>
    </Wrapper>
  )
}
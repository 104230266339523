import React, { useEffect, useState } from 'react';
import {
  Dialog,
  DialogContent,
  DialogTitle,
} from '@mui/material'
import { FormattedMessage } from 'react-intl'
import styled from 'styled-components'
import get from 'lodash/get';
import moment from 'moment-timezone';
import HighlightOffIcon from '@mui/icons-material/HighlightOff';
import EditIcon from '@mui/icons-material/Edit';
import CloseIcon from '@mui/icons-material/Close';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import Button from '@mui/material/Button';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import {EventDialog} from "../EventDialog";
import {calendarColors} from "../../../../../constants/colors";
import {deleteRepeat, getRepeats} from "../../../../../redux/modules/calendar/list";

const StyledDeleteIcon = styled(HighlightOffIcon)`
    color: 'red';
    cursor: pointer;
`

const EditIconStyled = styled(EditIcon)`
    color: 'green';
    cursor: pointer;
`

const CloseModal = styled(CloseIcon)`
    position: absolute;
    top: 10px;
    right: 10px;
    cursor: pointer;
`

const Wrapper = styled.div`
    min-width: 350px;
`

const ButtonContainer = styled.div`
    display: flex;
    align-items: center;
    justify-content: end;
`
const CancelButton = styled(Button)`
  && {
    background-color: ${calendarColors.purpleConex};
    margin-left: 15px;
    text-transform: none;
    &&:hover {
      background-color: ${calendarColors.hoverCancel};
    }
  }
`

const DeleteButton = styled(Button)`
  && {
    background-color: red;
    margin-left: 15px;
    text-transform: none;
    &&:hover {
      background-color: ${calendarColors.hoverDelete};
    }
  }
`

export const RepeatModal = ({
  isOpen, close, doctorId
}) => {
  const [repeats, setRepeats] = useState([])
  const [confirm, setConfirm] = useState(false);
  const [purgeCells, setPurgeCells] = useState(true);
  const [eventModal, setEventModal] = useState(null)
  const localTz = Intl.DateTimeFormat().resolvedOptions().timeZone

  const refreshRepeats = () => getRepeats(doctorId)
    .then(result => setRepeats(result))
    .catch(err => console.warn(err))

  const deleteRepeatRequest = (repeatId, purgeCells) => {
    deleteRepeat(repeatId, purgeCells,doctorId)
      .then(() => {
        refreshRepeats()
            .then(() => {
              setConfirm(false);
              setEventModal(null)
              close("close repeat")
            } )
        })


  }

  useEffect(() => {
    if(isOpen){
      refreshRepeats().then()
    }
  }, [isOpen])

  const closeConfirm = () => {
    setPurgeCells(false);
    setConfirm(false);
  }

  const openModal = (row) => {
    setEventModal({
      ...row.attributes,
      repeatId: row.id
    })
  }

  const onCloseEvent = (infoBack) => {
    if(infoBack){
      refreshRepeats().then()
    }
    setEventModal(null)
    close("close repeat")
  }

  return (
    <Dialog maxWidth='md' open={isOpen}>
      <CloseModal onClick={() => close()} />
      <DialogTitle>
        <FormattedMessage id='schedule.editrepeats' />
      </DialogTitle>
      <DialogContent>
        <Wrapper>
          <TableContainer component={Paper}>
            <Table size='small' aria-label='a dense table'>
              <TableHead>
                <TableRow>
                  <TableCell><FormattedMessage id='schedule.day' /></TableCell>
                  <TableCell align='right'><FormattedMessage id='global.from' /></TableCell>
                  <TableCell align='right'><FormattedMessage id='global.to' /></TableCell>
                  <TableCell align='right'><FormattedMessage id='global.dateFrom' /></TableCell>
                  <TableCell align='right'><FormattedMessage id='global.dateTo' /></TableCell>
                  <TableCell align='right'></TableCell>
                  <TableCell align='right'></TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {repeats.map((row) => {
                  const from = new Date().toISOString().split('T')[0]+ ' ' + get(row, 'attributes.from', '');
                  const fromUtc = moment.utc(from).format('HH:mm');
                  const to = new Date().toISOString().split('T')[0]+ ' ' + get(row, 'attributes.to', '');
                  const toUtc = moment.utc(to).format('HH:mm');
                  const day = get(row, 'attributes.dayNumber', 0);
                  const textDay = moment().day(day).format('dddd');
                  const dateFrom = get(row, 'attributes.dateFrom', '-');
                  const dateTo = get(row, 'attributes.dateTo', '-');
                  return (
                    <TableRow key={row}>
                      <TableCell component='th' scope='row'>
                        {textDay}
                      </TableCell>
                      <TableCell align='right'>{fromUtc}</TableCell>
                      <TableCell align='right'>{toUtc}</TableCell>
                      <TableCell align='right'>{dateFrom}</TableCell>
                      <TableCell align='right'>{dateTo}</TableCell>
                      <TableCell align='right'>
                        <EditIconStyled
                          onClick={() => openModal(row)}
                        />
                      </TableCell>
                      <TableCell align='right'>
                        <StyledDeleteIcon
                          style={{ color: 'red' }}
                          onClick={() => setConfirm(row)}
                        />
                      </TableCell>
                    </TableRow>
                  ) 
                })}
              </TableBody>
            </Table>
          </TableContainer>
        </Wrapper>
      </DialogContent>
      <Dialog open={confirm}>
        <CloseModal onClick={() => {
          refreshRepeats().then();
          closeConfirm();
        }}
        />
        <DialogTitle>
          <FormattedMessage id='schedule.deleterepeat' />
        </DialogTitle>
        <DialogContent>
          <Wrapper>
            <FormControlLabel
              control={(
                <Checkbox
                  checked={purgeCells}
                  onChange={() => setPurgeCells(!purgeCells)}
                  name='purgecells'
                  color='primary'
                />
        )}
              label={<FormattedMessage id='schedule.deleterepeatpreviouscells' />}
            />
          </Wrapper>
          <ButtonContainer>
            <CancelButton onClick={() => closeConfirm()} variant='contained'>
              <FormattedMessage id='global.cancel' />
            </CancelButton>
            <DeleteButton onClick={() => deleteRepeatRequest(confirm.id, purgeCells)} variant='contained' color='primary'>
              <FormattedMessage id='global.delete' />
            </DeleteButton>
          </ButtonContainer>
        </DialogContent>
      </Dialog>
      <div>
        { eventModal && <EventDialog open={!!eventModal} onClose={(infoBack) => onCloseEvent(infoBack)} data={eventModal} doctorId={doctorId} /> }
      </div>
    </Dialog>

)
}

import React, { useEffect, useState } from 'react'
import styled from 'styled-components'
import { Paper } from '../UiKit/Paper'
import { Editor } from 'react-draft-wysiwyg';
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';
import ContentState from "draft-js/lib/ContentState";
import EditorState from 'draft-js/lib/EditorState';
import draftToHtml from 'draftjs-to-html';
import htmlToDraft from 'html-to-draftjs';
import { convertToRaw } from 'draft-js';

const PaperStyled = styled(Paper)`
  margin-bottom: 16px;
  padding: 24px 24px 24px;
  font-size: 14px;
`

export const TextEditorComponent = ({ texteState, setTexteState }) => {
  const [editorState, setEditorState] = useState(
    () => {
      const contentBlock = htmlToDraft(texteState)
      if (contentBlock) {
        return EditorState.createWithContent(ContentState.createFromBlockArray(contentBlock.contentBlocks))
      } // else
      return EditorState.createEmpty()
    },
  );

  const texte = setTexteState(draftToHtml(convertToRaw(editorState.getCurrentContent())));

  useEffect(() => {
    setTexteState(texte);
  }, [texte])

  return (
    <PaperStyled heading='profile.instructions.title'>
      <Editor
        editorState={editorState}
        toolbarClassName='toolbarClassName'
        wrapperClassName='wrapperClassName'
        editorClassName='editorClassName'
        onEditorStateChange={setEditorState}
        toolbar={{
          options: ['inline', 'list', 'textAlign', 'remove', 'history'],
        }}
        editorStyle={{
          height: 400, backgroundColor: '#f9fbfd', paddingLeft: 20, paddingRight: 15,
        }}
        toolbarStyle={{ backgroundColor: '#f9fbfd' }}
        placeholder='Entrez les instructions à suivre pour vous adresser une demande de téléexpertise (antécédents du patient, documents à ne pas omettre, etc.)'
      />
    </PaperStyled>
  );
}

import React from "react";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import ListItemIcon from "@mui/material/ListItemIcon";
import {FormattedMessage, useIntl} from "react-intl";
import ListItemText from "@mui/material/ListItemText";
import {history} from "../../../../utils/history";
import {Groups, ThreeP} from "@mui/icons-material";

export const DropdownMenu = ({
   anchorEl,
   handleClose,
   setNewGroup
 }) =>  {
  const open = Boolean(anchorEl);

  return (
    <Menu
      open={open}
      anchorEl={anchorEl}
      onClose={handleClose}
    >
      <MenuItem
        onClick={() => {
          handleClose()
          history.push('/panel/discussions/new')
        }}
      >
        <ListItemIcon>
          <ThreeP/>
        </ListItemIcon>
        <ListItemText>
          <FormattedMessage id='disc.create.1v1'/>
        </ListItemText>
      </MenuItem>
      <MenuItem
          onClick={() => {
            handleClose()
            setNewGroup(true)
          }}
      >
        <ListItemIcon>
          <Groups/>
        </ListItemIcon>
        <ListItemText>
          <FormattedMessage id='disc.create.group'/>
        </ListItemText>
      </MenuItem>
    </Menu>
  )
}
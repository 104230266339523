/* eslint-disable */
import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import Chip from '@mui/material/Chip'

import { Colors } from 'constants/colors'

const Wrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
`

const Input = styled.input`
  position: absolute;
  opacity: 0;
`

const ChipStyled = styled(props => (
  <Chip {...props} classes={{ colorSecondary: 'secondary' }} />
))`
  && {
    height: 36px;
    margin: 8px;
    padding: 0 10px;
    font-size: 12px;
    font-weight: 500;
    text-transform: uppercase;
    letter-spacing: 0.4px;
  }

  &&.secondary {
    background-color: ${Colors.paleGrey};
    color: ${Colors.blueGrey};
  }
`

export const CheckboxInput = ({
  id,
  onChange,
  options,
  value,
  className,
}) => (
  <Wrapper className={className}>
    {
      options.map(option => (
        <span key={option.value}>
          <Input
            checked={value.includes(option.value)}
            id={id + option.value}
            onChange={e => onChange(
              value.includes(e.target.value)
                ? value.filter(item => item !== e.target.value)
                : [...value, e.target.value],
            )}
            type='checkbox'
            value={option.value}
          />
          <label htmlFor={id + option.value}>
            <ChipStyled
              clickable
              color={value.includes(option.value) ? 'primary' : 'secondary'}
              label={option.label}
            />
          </label>
        </span>
      ))
    }
  </Wrapper>
)

CheckboxInput.propTypes = {
  id: PropTypes.string.isRequired,
  className: PropTypes.string,
  onChange: PropTypes.func.isRequired,
  options: PropTypes.arrayOf(PropTypes.objectOf(PropTypes.string)).isRequired,
  value: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.string),
    PropTypes.string,
  ]).isRequired,
}
/* eslint-disable */
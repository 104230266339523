import React, {useState} from "react";
import Box from "@mui/material/Box";
import {FormattedMessage} from "react-intl";
import styled from "styled-components";
import TextField from "@mui/material/TextField";
import axios from "axios";
import {TextButton} from "../../../common/UiKit/TextButton";
import {history} from "../../../../utils/history";


const Wrapper = styled.div`
  justify-content: center;
`

const TitleStyled = styled.div`
  font-size: 18px;
  text-align: center;
  font-weight: 500;
  display: block;
  margin-top: 30px;
  margin-left: auto;
  margin-right: auto;
`

const TextFieldStyled = styled(TextField)`
  margin: 30px 50px 10px 50px;
  width: 500px;
  justify-content: center;
  box-shadow: 0 5px 5px #eae8e8;
`
const ContinueButton = styled(TextButton)`
  box-shadow: none;
  align-self: center;
  z-index: 10;
  && {
    width: 20%;
    height: 35px;
    display: block;
    margin: 24px auto 30px auto;
    background-color: #6633FF;
    border: 1px solid #fff;
    color: #fff;
  }
  &:hover{
    background-color: rgb(71, 35, 178);
    border: 1px solid #fff;
    color: #fff;
  }
  text-transform : none;
  font-weight: 500;
  font-size: 14px;
`

export const StepOther = ({value, setOpen, isViewed}) => {

    const [comments, setComments] = useState('')
    const handleInputChange = (event) => {
        setComments(event.target.value);
    }

    return (
        <Wrapper>
            <Box display="flex" >
                <TitleStyled>
                    <FormattedMessage id='survey.details'/>
                </TitleStyled>
            </Box>
            <form>
                <TextFieldStyled id="outlined-multiline-static" label="Préciser..." onChange={handleInputChange} multiline rows={2} inputProps={{ maxLength: 255 }} variant="outlined"/>
                <ContinueButton
                    onClick={
                        async () => {
                            try {
                                await axios.post('forms/answer', {form: 'Formulaire première connexion', answer: value, doctor_id: null, structure_id: null, comments: comments})
                            } catch (e) {
                                console.warn(e);
                            }
                            setOpen(false);
                            await isViewed();
                            history.push('/panel/proximity');
                        }
                    }
                    variant='contained'
                    color='primary'
                >
                    <FormattedMessage id='survey.continue'/>
                </ContinueButton>
            </form>

        </Wrapper>
    )
}
import React, { useState } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { connect } from 'react-redux';
import { compose, withProps } from 'recompose';
import { Field, Form } from 'react-final-form';
import { RadioField } from 'common/UiKit/RadioField';
import { Paper } from 'common/UiKit/Paper'
import { LANGUAGES } from '../../../constants/languages';
import { setLanguage } from '../../../redux/modules/localization';
import { editProfile } from '../../../redux/modules/auth';

const PaperStyled = styled(Paper)`
  margin-bottom: 16px;
  padding: 12px 24px;
`;

const Line = styled.label`
  font-size: 16px;
  height: 48px;
  display: flex;
  align-items: center;
  display: flex;
  justify-content: space-between;
  cursor: pointer;

  & > span {
    opacity: 0.8;
    font-weight: 500;
  }
`;

const LanguageComponent = ({ setActiveLanguage, initialValues, activeLanguage }) => {
  const [selected, handleChange] = useState(activeLanguage);
  return (
    <PaperStyled heading='profile.language'>
      <Form
        onSubmit={() => setActiveLanguage(selected)}
        initialValues={initialValues}
        subscription={{}}
        render={({ handleSubmit }) => (
          <form onSubmit={handleSubmit} id='language'>
            {LANGUAGES.map(l => (
              <Line key={l.value}>
                {l.label}
                <Field
                  name='language'
                  value={l.value}
                  checked={l.value === selected}
                  color='primary'
                  onChange={e => handleChange(e.target.value)}
                  type='radio'
                  component={RadioField}
                />
              </Line>
            ))}
          </form>
        )}
      />
    </PaperStyled>
  );
};

LanguageComponent.defaultProps = {
  initialValues: {},
};

LanguageComponent.propTypes = {
  setActiveLanguage: PropTypes.func.isRequired,
  initialValues: PropTypes.object,
  activeLanguage: PropTypes.string.isRequired,
};

const enhance = compose(
  connect(state => ({ activeLanguage: state.localization }), {
    setLanguage,
    editProfile,
  }),
  withProps(props => ({
    setActiveLanguage: (value) => {
      props.setLanguage(value)
      localStorage.setItem('language', value)
      props.editProfile({
        interface_language: value,
      })
    },
  })),
);

export const Language = enhance(LanguageComponent);

import React from 'react';
import essLogo from '../../Panel/Doctor/Community/ROSO/images/Logo_ESS.png';
import esspLogo from '../../Panel/Doctor/Community/ROSO/images/Logo_ESSP.png';
import PropTypes from "prop-types";

export const TeamLogo = ({ teamType, ...rest }) => {
  if (!teamType) return (<></>)
  // else

  return (
    <img src={teamType === 'ESS' ? essLogo : esspLogo} alt={teamType} width='65px' {...rest} />
  )
}

TeamLogo.propTypes = {
  teamType: PropTypes.string,
}

TeamLogo.defaultProps = {
  teamType: null,
}

//import axios from 'axios'
import { createAction, handleActions } from 'redux-actions'

import { history } from '../../utils/history'

//#region Actions
export const passwordRecoveryRequest = createAction('PASSWORD_RECOVERY_REQUEST');
export const passwordRecoverySuccess = createAction('PASSWORD_RECOVERY_SUCCESS');
export const passwordRecoveryFailure = createAction('PASSWORD_RECOVERY_FAILURE');
export const passwordRecoveryNotRegistered = createAction('PASSWORD_RECOVERY_NOT_REGISTERED');

export const passwordRecovery = () => (dispatch) => {
  dispatch(passwordRecoveryRequest());

  return new Promise((resolve) => {
    setTimeout(() => {
      dispatch(passwordRecoverySuccess());
      history.push('/auth/password-recovery/temporary-password');
      resolve()
    }, 500)
  })

  /*return axios.put('http://localhost:3004/posts/2', { email: payload })
    .then(
      (res) => {
        if (res.status === 201) {
          dispatch(passwordrecoverysuccess());
          history.push('/auth/password-recovery/temporary-password');
        } else {
          dispatch(passwordrecoveryfailure());
        }
      },
    )
    .catch(
      (err) => {
        if (err.response.status === 404) {
          dispatch(passwordrecoverynotregistered());
        } else {
          dispatch(passwordrecoveryfailure());
        }
      },
    )*/
}
//#endregion

//#region Reducers
const initialState = {
  error: false,
}

export const passwordRecoveryReducer = handleActions({
  [passwordRecoveryNotRegistered]: state => ({ ...state, error: true }),
}, initialState)
//#endregion

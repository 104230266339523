import React from 'react'
import PropTypes from 'prop-types'
import {connect, useSelector} from 'react-redux'
import styled from 'styled-components'
import { FormattedMessage } from 'react-intl';
import { LinkCard } from '../../../common/UiKit/LinkCard'
import { Page } from '../../../common/Page'
import { logOut } from '../../../../redux/modules/auth'
import {history} from "../../../../utils/history";
import LocalPhoneIcon from '@mui/icons-material/LocalPhone';
import LockIcon from '@mui/icons-material/Lock';
import AlternateEmailIcon from '@mui/icons-material/AlternateEmail';

const LinkCardStyled = styled(LinkCard)`
  
  padding: 12px 16px 12px 24px;
  margin-bottom: 16px;
`
const LinkIconBig = styled(LinkCard)`
  height: 400px;
  width: 400px;
`

const Wrapper = styled.div`
  display: flex;
  justify-content: space-around;
  align-items: center;
`

export const ConnexionAuthentificationComponent = ({
                                id,
                                logOut,
                              }) => {

  const role = useSelector(state => state.auth.type)

  return (
    <Page
      label='profile.connexion.authentification'
      onLogout={logOut}
      id={id}
      onBackIconClick={ () => history.goBack()}
    >
      <h2> <FormattedMessage id='profile.connexion.authentification' /> </h2>
      <LinkCardStyled
        label='profile.change.password'
        to='/panel/settings/change-password'
      >
        <LockIcon fontSize={"small"}/>
      </LinkCardStyled>

      <LinkCardStyled
        label='auth.phone.change'
        to='/panel/profile/phone/edit'
      >
        <LocalPhoneIcon fontSize={"small"}/>
      </LinkCardStyled>
      <LinkCardStyled
        label='profile.change.email'
        to='/panel/profile/edit/personal_data'
      >
        <AlternateEmailIcon fontSize={"small"}/>
      </LinkCardStyled>

      {/*<LinkCardStyled*/}
      {/*  label='global.personal.data'*/}
      {/*  to='/panel/personal/data'*/}
      {/*/>*/}

    </Page>
  )
}

ConnexionAuthentificationComponent.propTypes = {
  id: PropTypes.string.isRequired,
  logOut: PropTypes.func.isRequired,
}

const enhance = connect(state => ({ id: state.auth.id }), { logOut })

export const ConnexionAuthentification = enhance(ConnexionAuthentificationComponent)

import {createAction, handleActions} from "redux-actions";
import axios from "axios";
import {combineReducers} from "redux";

export const fetchContactsRequest = createAction('FETCH_CONTACTS_REQUEST')
export const fetchContactsSuccess = createAction('FETCH_CONTACTS_SUCCESS')
export const fetchContactsFailure = createAction('FETCH_CONTACTS_FAILURE')

export const fetchContacts = () => (dispatch) => {
    dispatch(fetchContactsRequest())
    return axios('/discussion/contacts', { method: 'GET' })
        .then(response => dispatch(fetchContactsSuccess(response.data)))
        .catch(err => dispatch(fetchContactsFailure(err)))
}

const list = handleActions({
    [fetchContactsSuccess]: (state, action) => ({
        data: action.payload.data
    })
}, [])

export const contacts = combineReducers({
    list,
})
import React, {useEffect} from 'react';
import styled from "styled-components";
import PropTypes from "prop-types";
import Grid from "@mui/material/Grid";
import {Link} from "react-router-dom";
import {Colors} from '../../../../../constants/colors'
import essLogo from '../../../../Panel/Doctor/Community/ROSO/images/Logo_ESS.png'
import esspLogo from '../../../../Panel/Doctor/Community/ROSO/images/Logo_ESSP.png'
import structureLogo from '../../../../../../src/images/doctors/structures/logo_structure.png'
import {useDispatch, useSelector} from "react-redux";
import {history} from "../../../../../utils/history";
import get from "lodash/get";
import {fullName} from "../../../../../helpers/user";
import {setSearchObject} from "../../../../../redux/modules/search";
import {setDoctorAnnuaire} from "../../../../../redux/modules/doctors";
import {DefaultAvatar} from "../../../../common/DefaultAvatar";


const Wrapper = styled.div`
  position: relative;
  display: ${({display}) => display};
  width: 100%;
  & > * {
    max-width: 100%;
  }
`

const GridStyle = {
    display: 'block',
    borderRadius: '6px',
    boxShadow: 'rgba(6, 173, 255, 0.1) 0px 4px 8px 0px',
    backgroundColor: '#ffffff',
    maxHeight: '350px',
    overflowY: 'auto',
    position: 'absolute',
    zIndex: '600'
}

const ItemStyle = {
    padding: '0px 15px 0px 15px',
    fontFamily: 'Roboto, sans-serif',
    fontSize: '16px',
    color: '${Colors.dark}',
    borderBottom: '1px solid #c5cbdb',
    height: '50px',
    display: 'flex',
    alignItems: 'center',
    maxWidth: '100%',
    width: '100%',
}

const LinkStyle = styled.a`
  display: block;
  color: black;
  
  &:hover {
    cursor: pointer;
    background-color: ${Colors.lightGrey};
  }
`

const Img = styled.img`
 max-height: 25px;
 padding-left: 20px;
 padding-right: 20px;
`

const TextHighlighted = styled.span`
  color: ${Colors.bluePurple};
  text-decoration: underline;
`

const SearchContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: left;
  width: 100%;
  max-width: 100%;
`

const City = styled.span`
  font-size: 14px;
  color: #858992;
  text-transform: capitalize;
  white-space: nowrap;
`

const Name = styled.span`
  white-space: nowrap;
`

const ImgDefault = styled.div`
  min-width: 30px;
  width: 30px;
  height: 30px;
  border-radius: 100%;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
    margin-right: 10px;
`

const highlightText = (searchValue) => {
    const spanToHighlight = document.querySelectorAll("span.searchElement");
    spanToHighlight.forEach(span => {

        let innerHTML = span.innerHTML;
        //we remove old highlight
        innerHTML = innerHTML.replace(/<\/?span[^>]*>/g,"");

        let normalizedInnerHTML = innerHTML.toLowerCase().normalize("NFD").replace(/[\u0300-\u036f]/g, "");

        let index = normalizedInnerHTML.indexOf(searchValue.toLowerCase().normalize("NFD").replace(/[\u0300-\u036f]/g, ""));

        if (index >= 0) {
            innerHTML = innerHTML.substring(0, index) +
              "<span style='color: #6633FF; text-decoration: underline' >" + innerHTML.substring(index, index + searchValue.length) + "</span>" +
              innerHTML.substring(index + searchValue.length);
        }
        span.innerHTML = innerHTML
    })
}

const switchSearchResultType = (value) => {
    switch (value?.type) {
        case 'Doctor':
            const city = get(value, 'relationships.workPlace.data[0].attributes.city', '')
            return (
              <SearchContainer>
                  <div style={{overflow: 'hidden', flexShrink: 0}}>
                      {
                          value.photo ? (
                              <ImgDefault
                                spinner={{width: '30px', height: '30px'}}
                                style={{backgroundImage: `url(${value.photo.includes('/file/') ? `http://backend-application.owndoctor.eu${value.photo}` : value.photo})`}}
                              />
                            ) :
                            (<ImgDefault
                                style={{backgroundImage: `url(${DefaultAvatar(value.first_name, value.last_name, value.specialization_type)})`}}
                              />
                            )
                      }
                  </div>
                  <div style={{overflow: 'hidden', textOverflow: 'ellipsis', flexShrink: 1}}>
                      <Name className='searchElement'>{fullName(value.gender, value.first_name, value.last_name)}</Name>
                      <br/>
                      <City>
                          {value.specialization}
                          {city && " - " + city}
                          {value.city && " - " + value.city}
                      </City>
                  </div>
              </SearchContainer>
            )
        case 'Structure':
            return (
              <SearchContainer>
                  <div style={{overflow: 'hidden', flexShrink: 0}}>
                      <Img src={structureLogo}/>
                  </div>
                  <div style={{overflow: 'hidden', textOverflow: 'ellipsis', flexShrink: 1}}>
                      <Name className='searchElement'>{value.name}</Name>
                      <br/>
                      <City>{value.city}</City>
                  </div>
              </SearchContainer>
            )
        case 'DoctorTeam':
            const type = (value.attributes.type === "ESSP") ? esspLogo : essLogo;
            return (
              <SearchContainer>
                  <div style={{overflow: 'hidden', flexShrink: 0}}>
                      <Img src={type}/>
                  </div>
                  <div style={{overflow: 'hidden', textOverflow: 'ellipsis', flexShrink: 1}}>
                      <Name className='searchElement'>{value.name}</Name>
                      <br/>
                      <City>{get(value, 'relationships.structures.data[0].attributes.city', '')}</City>
                  </div>
              </SearchContainer>
            )
        case 'Specialization':
            return <Name className='searchElement'>{value.name}</Name>
        case 'Skill':
            return <Name className='searchElement'>{value.name}</Name>
        case 'Annuaire':
            return  (
              <SearchContainer>
                  <div style={{overflow: 'hidden', flexShrink: 0}}>
                      <ImgDefault
                        spinner={{width: '120px', height: '120px'}}
                        style={{backgroundImage: `url(${DefaultAvatar(value.nomExercice, value.prenomExercice)})`}}
                      />
                  </div>
                  <div style={{overflow: 'hidden', textOverflow: 'ellipsis', flexShrink: 1}}>
                      <Name className='searchElement'>{fullName(value.codeCivilite, value.nomExercice, value.prenomExercice)}</Name>
                      <br/>
                      <City>
                          {value.libelleProfession}
                      </City>
                  </div>
              </SearchContainer>
            )
    }
}

export const switchSearchResultLink = (value, match, isDoctor) => {
    const isTCA = match.path.includes('teleconsultation')
    switch (value?.type) {
        case 'Annuaire':
            if (!isDoctor) {
                return '/panel/doctors/annuaire/' + value.id;
            }
            return '/panel/concilium/doctor/annuaire/' + value.id
        case 'Doctor':
            if (!isDoctor) {
                return '/panel/doctors/doctor/' + value.id
            }
            if(isTCA){
                return '/panel/teleconsultation/specializations/doctor/' + value.id;
            }
            return '/panel/concilium/specializations/doctor/' + value.id;
        case 'Structure':
            if (!isDoctor) {
                return '/panel/doctors/structures/structure/' + value.id;
            }
            return '/panel/community/structures/' + value.id;
        case 'DoctorTeam':
            if (!isDoctor) {
                return '/panel/doctors/teams/team/' + value.id;
            }
            return '/panel/concilium/main/roso/detail/' + value.id;
        case 'Specialization':
            if (!isDoctor) {
                return '/panel/doctors/specializations/' + value.id
            } else if (match && match.path.includes('teleconsultation')) {
                return '/panel/teleconsultation/specializations/' + value.id;
            }
            return '/panel/concilium/specializations/' + value.id
        case 'Skill':
            return '/panel/concilium/skills/' + value.id;
    }
}

export const SearchResults = ({canDisplay, results, searchValue, match, setLink, setInput}) => {
    const authType = useSelector(state => state.auth.type)
    const isDoctor = authType === 'Doctor'
    const dispatch = useDispatch()

    useEffect(() => {
        highlightText(searchValue);
    }, [results, searchValue]);

    return ( results && results.length > 0 ? (
        <Wrapper display={!canDisplay || !results || results.length === 0 ? 'none' : 'block'}  >
            <Grid container style={GridStyle}>
            {results.map((result) => {
                const link = switchSearchResultLink(result, match, isDoctor);
                return (
                    <LinkStyle key={result.id+result.type} onClick={() => {
                        if (result?.type === 'Annuaire') {
                            dispatch(setDoctorAnnuaire(result));
                            history.push(link)
                        }else {
                            dispatch(setSearchObject(result.attributes))
                            history.push(link)
                        }
                    }}>
                        <Grid item style={ItemStyle}>
                            {switchSearchResultType(result)}
                        </Grid>
                    </LinkStyle>
                )
            })}
            </Grid>
        </Wrapper>)
    : '')
}

SearchResults.propTypes = {
    results: PropTypes.array,
    searchValue: PropTypes.string,
}


import React, {useState} from "react";
import styled from "styled-components";
import {FormattedMessage} from "react-intl";
import {Dialog, DialogContent, DialogTitle} from "@material-ui/core";
import imageHelpAroundMe from "../../../../images/panel/doctors/help-around-me.svg";
import StarIcon from '@mui/icons-material/Star';
import {TextButton} from "../../../common/UiKit/TextButton";
import {createFormsView, fetchHelpAroundMeView} from "../../../../redux/modules/formsView";
import {HELP_AROUND_ME} from "../../../../constants/forms";
import {useDispatch, useSelector} from "react-redux";
import {Colors} from "../../../../constants/colors";

const DialogStyled = styled(Dialog)`
  backdrop-filter: blur(2px);
`

const TitleStyled = styled.div`
  font-size: 20px;
  font-weight: 500;
  display: flex;
  align-items: center;
  justify-content: center;
  color: ${Colors.footerGrey};
`

const ImageStyled = styled.img`
  margin: 5px auto 30px auto;
  width: 180px;
  height: 180px;
  display: block;
`

const SubtitleStyled = styled.div`
  display: block;
  margin-right: auto;
  margin-left: auto;
  text-align: center;
  color: ${Colors.footerGrey};
`

const StarIconStyled = styled(StarIcon)`
  color: ${Colors.disabled};
  width: 22px;
  height: 22px;
`

const CloseButton = styled(TextButton)`
  box-shadow: none;
  text-transform: none;
  font-family: Roboto, sans-serif;
  font-size: 14px;
  height: 40px;
  margin: 10px auto 20px auto;
  display: flex;
  && {
    background-color: ${Colors.bluePurple};
    border: 1px solid ${Colors.white};
    color: ${Colors.white};
  }
  &:hover {
    background-color: rgb(71, 35, 178);
    border: 1px solid ${Colors.white};
    color: ${Colors.white};
  }
`

export const HelpAroundMe = () => {
    const [open, setOpen] = useState(true);
    const dispatch = useDispatch();
    const doctorId = useSelector(state => state.auth.id);

    const fetchIsViewed = () => {
        dispatch(fetchHelpAroundMeView(doctorId))
    }

    const handleClose = () =>  {
        setOpen(false);
        dispatch(createFormsView(doctorId, HELP_AROUND_ME))
            .then((data) => {
                console.log(data)
            })
            .catch((errors) => {
                console.log(errors)
            })
        fetchIsViewed()
    }

    return (
        <DialogStyled
            open={open}
            onClose={handleClose}
            maxWidth='lg'
            fullWidth
            PaperProps={{
                style: {
                    width: '550px',
                    height: '450px',
                    padding: '10px',
                }
            }}
            BackdropProps={{
                style: {
                    opacity: 0,
                }
            }}
        >
            <>
                <DialogTitle disableTypography>
                    <TitleStyled><FormattedMessage id='global.geo.aroundme'/></TitleStyled>
                </DialogTitle>
                <DialogContent style={{marginBottom: '5px'}}>
                    <ImageStyled src={imageHelpAroundMe} />
                    <SubtitleStyled>
                        <FormattedMessage id='global.geo.help'/><br/>
                        <FormattedMessage id='global.geo.help.click'/>
                        <StarIconStyled/>
                        <FormattedMessage id='global.geo.help.add'/>
                    </SubtitleStyled>
                </DialogContent>
                <CloseButton onClick={
                    async () => {
                        handleClose();
                    }
                }>
                    <FormattedMessage id="global.geo.help.button"/>
                </CloseButton>
            </>
        </DialogStyled>
    )
}
import React from 'react'
import Checkbox from '@mui/material/Checkbox'
import FormControl from '@mui/material/FormControl';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormHelperText from '@mui/material/FormHelperText';
import { FormattedMessage } from 'react-intl';

export const CheckboxFieldV2 = ({ input, meta, ...rest }) => ( 
  <FormControl error={meta.touched && meta.error}>
    <FormControlLabel
      {...input}
      value={input.value}
      control={(
        <Checkbox
          {...rest}
        />
      )}
      label={rest.label}
    />
    {meta.touched && meta.error && (
      <FormHelperText>
        <FormattedMessage id={meta.error} defaultMessage={meta.error} />
      </FormHelperText>
    )}
  </FormControl>
)

import React from 'react'
import FormControlLabel from '@mui/material/FormControlLabel'
import Switch from '@mui/material/Switch'
import { FormattedMessage } from 'react-intl'
import styled from "styled-components";

const Wrap = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 750px;
  margin-left: 69px;
    @media screen and (max-width: 1600px) {
      margin-left: 0px;
  }
`


export const UrgentToggle = ({
  checked,
  onChange,
  label,
  disabled,
}) => (
  <Wrap>
    <FormattedMessage id={label}/>
    <Switch
      checked={checked}
      onChange={onChange}
      color='primary'
    />
  </Wrap>
/*  <FormControlLabel
    control={(
      <Switch
        checked={checked}
        onChange={onChange}
        color='primary'
        disabled={disabled}
      />
    )}
    label={<FormattedMessage id={label} />}
  />*/
)

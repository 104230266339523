import React, {useEffect, useState} from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import InsertInvitation from '@mui/icons-material/InsertInvitation'
import Place from '@mui/icons-material/Place'
import AccessIcon from '@mui/icons-material/AccessTime';
import moment from 'moment-timezone'
import {FormattedMessage} from 'react-intl'
import {TextButton} from '../UiKit/TextButton'
import {Paper} from '../UiKit/Paper'
import {SecuredBckgImage} from '../SecuredImage'
import {BUTTON_COLORS, Colors, STATUS_COLORS} from '../../../constants/colors'
import {FavoriteButton} from './FavoriteButton'
import {DefaultAvatar} from "../DefaultAvatar";
import {Link, useRouteMatch} from "react-router-dom";
import {history} from "../../../utils/history";
import axios from "axios";
import get from "lodash/get";
import {DistanceDisplay} from "../DistanceDisplay";
import {fullName} from "../../../helpers/user";
import {DoctorAvailabilitiesTCA} from "./DoctorAvailabilitiesTCA";
import {useSelector} from "react-redux";
import dompurify from "dompurify";
import CustomLoaderRectangleAndCircle from "../CustomLoaderRectangleAndCircle";

const PaperStyled = styled(Paper)`
  padding: 20px 24px;
  position: relative;
  display: block;
`

const Wrapper = styled.div`
  display: flex;
  font-weight: normal;
  font-size: 16px;
`

const FullLengthWrapper = styled.div`
  margin-top: 20px;
  display: block;
  margin-right: auto;
  margin-left: auto;
  align-items: center;
  justify-content: center;
`

export const Rating = styled.div`
  position: absolute;
  bottom: -12px;
  width: 64px;
  background-color: #fff;
  font-size: 16px;
  border-radius: 30px;
  color: ${Colors.dark80};
  box-shadow: 0 4px 8px 0 rgba(6, 173, 255, 0.1);
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: bold;
  height: 30px;
  padding-left: 2px;
  
  svg {
    fill: #ffcc00;
    width: 24px;
    height: 24px;
    margin-left: 4px;
  }
`

const AvatarWrapper = styled.div`
  & > p {
    margin: 16px 0 0;
    color: ${Colors.clearBlue};
    font-size: 24px;
    font-weight: bold;
    text-align: center;
  }
`

const Info = styled.div`
  flex-grow: 1;
  margin: 12px 32px 0;
`
const NameLinked = styled(Link)`
  margin: 0;
  font-size: 24px;
  font-weight: bold;
  color: black;
  
  &:hover {
    color: #6633FF;
  }
`

const Name = styled.h1`
  margin: 0;
  font-size: 24px;
  font-weight: bold;
  color: black;
`

const Specialization = styled.p`
  margin: 0;
  opacity: 0.8;
  padding-top: 10px;
`

const Over = styled.p`
  margin: 0;
  color: ${Colors.blueGrey};
  display: flex;
  align-items: center;
  margin-top: 12px;
  text-transform: capitalize;

  && svg {
    font-size: 18px;
    margin-right: 12px;
  }
`


const LanguageWrapper = styled.div`
  position: absolute;
  top: 16px;
  right: 16px;
  text-align: center;
  display: flex;
  align-items: center;
  color: #b7bed2;
`

const Avatar = styled(SecuredBckgImage)`
  min-width: 126px;
  width: 126px;
  height: 126px;
  border-radius: 100%;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  display: flex;
  align-items: center;
  justify-content: center;
  box-shadow: 0 4px 8px 0 rgba(6, 173, 255, 0.1);
  &:after {
    display: inline-block;
    position : relative;
    content: "oo";
    border: 2px solid white;
    background-color: ${({ status }) => (status) ? STATUS_COLORS["available"] : STATUS_COLORS["notavailable"]};
    color: transparent;
    border-radius: 50%;
    min-width: 30px;
    min-height: 30px;
    top: 40px;
    left: 40px;
  }
`

const Default = styled.div`
  min-width: 126px;
  width: 126px;
  height: 126px;
  border-radius: 100%;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  box-shadow: 0 4px 8px 0 rgba(6, 173, 255, 0.1);
  &:after {
    display: inline-block;
    position : relative;
    content: "oo";
    border: 2px solid white;
    background-color: ${({ status }) => (status) ? STATUS_COLORS["available"] : STATUS_COLORS["notavailable"]};
    color: transparent;
    border-radius: 50%;
    min-width: 30px;
    min-height: 30px;
    top: 90px;
    left: 90px;
  }
`

const DispoDefault = styled.img`
    min-width: 126px;
    width: 126px;
    height: 126px;
    border: 7px solid #00B428;
    border-radius: 100%;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    display: flex;
    align-items: center;
    justify-content: center;
    box-shadow: 0 4px 8px 0 rgba(6, 173, 255, 0.1);
    position: relative;
`

const ClickableTE = styled.div`
  background-color: #fff;
  border: 1px solid gray;
  color: gray;
  border-radius: 5px;
  padding: 5px 10px;
  margin-left: 10px;
  cursor: pointer;
  transition: 0.4s;
  &:hover {
    background-color: transparent;
    color: #01A793;
    border-radius: 0;
    border: 1px solid #01A793;
  }
`

const ButtonWrapper = styled.div`
  display: flex;
  justify-content: center;
  gap: 20px;
`

const Button = styled(TextButton)`
  && {
    text-transform: none;
    font-size: 14px;
    font-weight: 500;
    box-shadow: none;
    height: 35px;
    margin-top: 18px;
    background-color: ${BUTTON_COLORS.purple};
    border: 1px solid #fff;
    color: #fff;
    flex: 0 0 auto;
  }

  &&:hover {
    background-color: ${BUTTON_COLORS.purpleHover};
    border: 1px solid #fff;
    color: #fff;
  }
    &&:disabled{
        background-color: rgba(191, 197, 212);
    }
`

const ButtonTeams = styled(Button)`
  && {
    background-color: #ffffff;
    border: 1px solid gray;
    color: gray;
  }

  &&:hover {
    background-color: #fff;
    border: 1px solid #6633FF;
    color: #6633FF;
  }
`

const Span = styled.span`
    margin-top: 10px;
    font-style: italic;
    display: flex;
    color: #949EB8;
    justify-content: center;
    text-align: justify;
`

const SpanSkill = styled.span`
    background-color: ${Colors.purpleGrey};
    color: #5D5577;
    padding: 3px 16px;
    border-radius: 11px;
`

const DivFlex = styled.div`
  display:flex;
  flex-wrap:wrap;
  gap: 10px 10px;
`
const TitleSkill = styled.div`
  color: black;
  margin-bottom:5px;
  font-weight:bold;
`

const Instructions = styled.div`
  background-color: #E7E2F6;
  margin-top: 20px;
  padding: 15px 15px 15px 15px;
  border-radius: 3px;
  margin-left: -150px;
`

const InstructionsTitle = styled.p`
  margin-bottom: 0;
  margin-top: 0;
  font-weight: bold;
  font-size: 14px;
`

const InstructionsContent = styled.p`
  font-size: 13px;
  margin-bottom: 0;
`

const InstructionsContainer = styled.div`
  position: relative;
  min-height: 100%;
  padding: 5px 0 10px 10px;
  p {
    margin-bottom: 0;
  }
`
const LeftBorderDesign = styled.div`
  position: absolute;
  background-color: ${Colors.bluePurple};
  border-radius: 5px;
  width: 3px;
  height: 100%;
  left: 0;
  top: 0;
`
const AnswerTime = styled.div`
  color: ${Colors.blueGrey};
  font-weight: bold;
  text-align: center;
  display: flex;
  align-items: center;
  margin: 0;
  margin-top: 12px;
`

const AccessIconStyled = styled(AccessIcon)`
  margin-right: 8px;
`

export const DoctorCard = ({
   id,
   userId,
   firstName,
   isUrlPathConcilium,
   lastName,
   userStatus,
   status_availability,
   specialization,
   specialization_type,
   photo,
   price,
   distance,
   location,
   onButtonClick,
   onButtonClickTER,
   onButtonClickTCA,
   onAvatarClick,
   isDoctorDetailsPage,
   isFavorite,
   toggleFavorite,
   isFavoriteFetching,
   disableFavorite,
   rating,
   buttonText,
   isDoctor,
   calendarNearestTE,
   disableButtonClick,
   removePerson,
   link,
   isDoctorDetails,
   attributes,
   skills,
   gender,
   answerTime
}) => {

  const sanitizer = dompurify.sanitize;
  const [isGeoLimitLoading, setIsGeoLimitloading] = useState(false);
  const [isLongLatLoading, setIsLongLatloading] = useState(false);

  const isTCA = history.location.pathname.includes('teleconsultation')
  const match = useRouteMatch();
  const isUrlPathConciliumSpe = match.path.includes('concilium');

  const [list, setList] = useState(null)
  const isAvailableTCA = !!list && (list.length !== 0 && (Object.keys(list).slice(1, 2).length !== 0))

  const connectedDoctor = useSelector(state => state.auth.id);
  const connectedUserType = useSelector(state => state.auth.type)
  const user = useSelector(state => state.auth);
  const connectedUser = user?.attributes?.user_id;
  const [instructions, setInstructions] = useState('')
  const [geographicalLimitation, setGeographicalLimitation] = useState(null)
  const [distanceBetweenWorkplaces, setDistanceBetweenWorkplaces] = useState(null)
  const latitude = location.lat
  const longitude = location.lon
  const mySpeType = useSelector(state => state.auth.attributes.specialization_type)

  let [answerText, setAnswerText] = useState(null);

  const [statusAvailability, setStatusAvailability] = useState(false);
  const [calendarNearest, setCalendarNearest] = useState('')
  const calendarNearestTEUTC = calendarNearestTE ? moment.utc(calendarNearestTE).local().format('YYYY-MM-DD HH:mm:ss') : calendarNearest;

  useEffect(() => {
    if (id && !isDoctorDetails) {
      axios.get(`api/doctor/${id}/isAvailable`)
        .then((response) => {
          setStatusAvailability(response?.data?.status_availability ?? false)
          if (isDoctor){
            const data = response?.data?.calendar_nearest[isTCA ? 'TCA' : 'TE'];

            setCalendarNearest(data ? moment(data).format('YYYY-MM-DD HH:mm:ss'): false)
          }else {
            setCalendarNearest(moment(response?.data?.calendar_nearest?.TC).format('YYYY-MM-DD HH:mm:ss'))
          }
        })
    }
  }, [id]);



  useEffect(() => {
    if (answerTime == null) {
      setAnswerText(null)
    } else if (answerTime < 24) {
      answerText = setAnswerText(<FormattedMessage id='profile.answer.time.one.day'/>)
    } else if (answerTime < 48) {
      answerText = setAnswerText(<FormattedMessage id='profile.answer.time.two.day'/>)
    } else {
      setAnswerText(null)
    }
  }, [answerTime])

  useEffect(() => {
    if (userId) {
      setIsGeoLimitloading(true)
     axios.get(`/user/geographical/limit/${userId}`)
        .then((response) => {
          setGeographicalLimitation(response.data.intValue);
        }).finally( () => setIsGeoLimitloading(false))
      if (latitude && longitude) {
          setIsLongLatloading(true)
          axios.get(`/distance/between/doctors/${connectedDoctor}/${latitude}/${longitude}`)
              .then((response) => {
                if(response.data.length > 0 ){
                  setDistanceBetweenWorkplaces((response.data[0].distance)/1000);
                }
              }).finally(() => setIsGeoLimitloading(false))
      }
      //in case the request is too long
      setTimeout(() => {
        setIsGeoLimitloading(false)
        setIsLongLatloading(false)
      }, 3000)
  }}, [userId]);

  const CheckSpeTCA = (speType) => {
    const typesOfDoctors = ['generalist', 'specialist', 'others' ]
    if (speType && mySpeType &&
      (typesOfDoctors.includes(mySpeType) &&
        !typesOfDoctors.includes(speType)
        ||
        typesOfDoctors.includes(speType) &&
        !typesOfDoctors.includes(mySpeType)
      )
    ) {
      return true
    }
    return false
  }

  useEffect(() => {
    (async function () {
      if (id) {
        try {
          const base = `/api/doctor/calendar/listavailabilities/${id}?offset=${0}&limit=${7}&type=TCA`;
          const resp = await axios
            .get(`${base}`);
          if (resp.data && Object.keys(resp.data).length) {
            setList(resp.data)
          }
        } catch (e) {
          console.warn(e);
        }

        axios.get(`/doctor/instructions/${id}`)
          .then((response) => {
            setInstructions(response.data);
          });
      }
    }());
  }, [isUrlPathConciliumSpe])

  return (
    isLongLatLoading || isGeoLimitLoading
      ? <CustomLoaderRectangleAndCircle />
      : (
        <PaperStyled>
          <LanguageWrapper>
            {
              !!distance && !!latitude && ( <DistanceDisplay float='right' distance={distance} /> )
            }
            {(disableFavorite !== null && !disableFavorite && isDoctor) &&
              <FavoriteButton
                checked={isFavorite}
                onClick={() => toggleFavorite(!isFavorite)}
                isFetching={isFavoriteFetching}
                message={isDoctorDetails}
              />
            }

                  {removePerson ? removePerson : ''}
              </LanguageWrapper>
              <Wrapper>
                  <AvatarWrapper>
                      {
                          photo ? (
                                  <Avatar
                                      size = {126}
                                      spinner={{width: '120px', height: '120px'}}
                                      src={photo.includes('/file/') ? `http://backend-application.owndoctor.eu${photo}` : photo}
                                      status={isDoctorDetails? status_availability: statusAvailability}
                                  />
                              ) :
                              (<Default
                                      spinner={{width: '120px', height: '120px'}}
                                      style={{backgroundImage: `url(${DefaultAvatar(firstName, lastName, specialization_type)})`}}
                                      status={isDoctorDetails? status_availability: statusAvailability}
                                  />
                              )
                      }
                  </AvatarWrapper>
                  <Info>
                      {link ? (
                              <NameLinked to={isFavorite ? link.replace('favorite', '') : link}>
                                  {fullName(gender, firstName || 'Name', lastName || 'Surname')}
                              </NameLinked>)
                          : (
                              <Name>
                                  {fullName(gender, firstName || 'Name', lastName || 'Surname')}
                              </Name>)
                      }
                      <Specialization>
                          {specialization || 'Therapist'}
                      </Specialization>
                      <Over>
                          <Place/>
                          {Object.keys(location)?.length && ( !!location.name?.trim() || !!location.street?.trim() || !!location.city?.trim())
                              ?  `${location.name?.trim() ?location.name:''} ${location.name?.trim() && location.street?.trim() ? ', ': ''} ${location.street?.trim() ?`${location.street}`: '' } ${location.city?.trim() ? `, ${location.city}`: ''}`
                              : <FormattedMessage id='global.location'/>}
                      </Over>
                      {answerText != null ?
                          <AnswerTime>
                              <AccessIconStyled/>
                              {answerText}
                          </AnswerTime>
                          : ''
                      }
                      <Over>
                          {
                              skills && skills.length > 0  && (
                                  <span>
                      <TitleSkill>
                        <FormattedMessage id='profile.skill' />
                      </TitleSkill>
                      <DivFlex>
                        {skills.map((skill) => {
                          const name = get (skill,"attributes.name","");
                          return (
                            <SpanSkill key={skill.id} >{name}</SpanSkill>
                          )
                        })}
                      </DivFlex>

                    </span>
                  )
                }
              </Over>
              {(calendarNearestTEUTC) && isDoctor && !disableButtonClick && !isTCA && (!geographicalLimitation || geographicalLimitation >= distanceBetweenWorkplaces || isFavorite)
                ? (
                  <Over onClick={e => (isUrlPathConcilium || isUrlPathConciliumSpe)
                    ?
                    onButtonClick(e, moment(calendarNearestTEUTC).format('YYYY-MM-DD HH:mm'))
                    :
                    onButtonClickTCA(e, moment(calendarNearestTEUTC).format('YYYY-MM-DD HH:mm'))
                  }>
                    <InsertInvitation/>
                    <FormattedMessage id='consultation.doctor.calendarNearestTE'/>
                    {': '}
                    <ClickableTE>{moment(calendarNearestTEUTC).calendar(null, {
                      sameElse: 'DD.MM.YYYY HH:mm',
                    })}
                    </ClickableTE>
                  </Over>
                ) : <span style={{paddingLeft: '60px'}}>{'        '}</span>}
              {!!instructions && instructions.trim().length ?
                <Instructions>
                  <InstructionsContainer>
                    <LeftBorderDesign></LeftBorderDesign>
                    <InstructionsTitle>
                      <FormattedMessage id='consultation.instructions'/>
                    </InstructionsTitle>
                    <InstructionsContent>
                      <div dangerouslySetInnerHTML={{__html: sanitizer(instructions)}}/>
                    </InstructionsContent>
                  </InstructionsContainer>
                </Instructions>
                : null
              }
              {
                (!isTCA && connectedUserType === 'Doctor' && userId !== connectedUser) && (
                  (!geographicalLimitation || geographicalLimitation >= distanceBetweenWorkplaces || isFavorite) ? (
                      <ButtonWrapper>
                        {((calendarNearestTE || calendarNearest) && !disableButtonClick) ? (
                          <Button
                            variant='extended'
                            onClick={onButtonClick}
                            disabled={userStatus !== 'VERIFIED'}
                          >
                            <FormattedMessage id={buttonText || 'consultation.make.appointment'}/>
                          </Button>
                        ) : null}
                        <span style={{paddingLeft: '20px'}}>{'        '}</span>
                        {(isDoctor && (status_availability || statusAvailability) && !disableButtonClick) ? (
                            (!geographicalLimitation || geographicalLimitation >= distanceBetweenWorkplaces || isFavorite) ? (
                                <Button
                                variant='extended'
                                onClick={onButtonClickTER}
                                disabled={userStatus !== 'VERIFIED' && userStatus !== 1}
                                >
                                    <FormattedMessage id='consultation.rosofollow'/>
                                </Button>
                            ) : (
                                <ButtonWrapper>
                                    <Span><FormattedMessage id='consultation.limitation'/></Span>
                                </ButtonWrapper>
                            )
                        ) : (
                          isDoctor && (
                            <ButtonTeams
                              disabled
                            >
                              <FormattedMessage id='consultation.rosofollow'/>
                            </ButtonTeams>
                          ))}
                        <span style={{paddingLeft: '60px'}}>{'        '}</span>
                      </ButtonWrapper>
                    ) :
                    <ButtonWrapper>
                      <Span><FormattedMessage id='consultation.limitation'/></Span>
                    </ButtonWrapper>
                )}
            </Info>
          </Wrapper>
          <FullLengthWrapper>
            {
              isTCA &&
              CheckSpeTCA(specialization_type, mySpeType) &&
              !!list &&
              (
                !isAvailableTCA ?
                  (
                    <ButtonWrapper>
                      <Button
                        variant='extended'
                        onClick={() => history.push(`/panel/teleconsultation/create/${id}`)}
                        style={{marginTop: '0'}}
                        disabled={userStatus !== 'VERIFIED'}
                      >
                        <FormattedMessage id='tca.new.invite'/>
                      </Button>
                    </ButtonWrapper>
                  )
                  :
                  <DoctorAvailabilitiesTCA
                    id={id}
                    availabilitiesList={list}
                  />
              )
            }
          </FullLengthWrapper>
        </PaperStyled>
      )
  )
}

DoctorCard.defaultProps = {
  location: {},
  onAvatarClick: () => null,
  photo: '',
  buttonText: null,
  rating: null,
}

DoctorCard.propTypes = {
  firstName: PropTypes.string.isRequired,
  lastName: PropTypes.string.isRequired,
  specialization: PropTypes.string.isRequired,
  photo: PropTypes.string,
  location: PropTypes.object,
  onButtonClick: PropTypes.func.isRequired,
  onAvatarClick: PropTypes.func,
  isFavorite: PropTypes.bool.isRequired,
  toggleFavorite: PropTypes.func.isRequired,
  isFavoriteFetching: PropTypes.bool.isRequired,
  disableFavorite: PropTypes.bool,
  rating: PropTypes.number,
  buttonText: PropTypes.string,
}

import React from 'react'
import styled from "styled-components";
import {Colors} from "../../../../../../constants/colors";
import {DistanceDisplay} from "../../../../../common/DistanceDisplay";
import {SecuredBckgImage} from "../../../../../common/SecuredImage";
import idx from "idx";
import get from "lodash/get";
import defaultLogo from "../../../../../../images/doctors/structures/logo_structure.png";

export const StructureToolTipComponent = ({structure}) => {
  const city = idx(structure, _=>_.attributes?.city);
  const zipcode = idx(structure, _=>_.attributes?.zipcode);
  const type = idx(structure, _=>_.attributes?.type.type);
  const distance = idx(structure, _=>_.attributes?.distance);
  const name = idx(structure, _=>_.attributes?.name);
  const structureId = idx(structure, _=>_.id)
  const photo =get(structure.attributes, 'photo', null)

  return (
    <>
      <Wrapper>
        {photo ?
          <StructureIcon
            spinner={{width: '60px', height: '60px'}}
            src={photo.includes('/file/') ? `http://backend-application.owndoctor.eu${photo}` : photo}
          /> :
          <StructureIcon
            spinner={{width: '60px', height: '60px'}}
            src={defaultLogo}
          />}
        <Info>
          <Name>{name}</Name>
          <Type>{type}</Type>
          <City>
            {city}&nbsp;({zipcode})
          </City>
        </Info>
        <LanguageWrapper>
          <DistanceDisplay distance={distance} />
        </LanguageWrapper>
      </Wrapper>
    </>
  )
}


const Wrapper = styled.div`
  display: flex;
  font-weight: normal;
  font-size: 16px;
  align-items: center;
`

const StructureIcon = styled(SecuredBckgImage)`
  min-width: 60px;
  width: 60px;
  height: 60px;
  display: flex;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  align-items: center;
  justify-content: center;
`

const LanguageWrapper = styled.div`
  top: 16px;
  right: 16px;
  text-align: center;
  display: flex;
  align-items: center;
  color: #b7bed2;
`

const Info = styled.div`
  flex-grow: 1;
  margin: 0 32px 0;
`

const Name = styled.div`
  max-width: 230px;
  white-space: nowrap;
  overflow: hidden;
  font-weight: bold;
  word-break: break-word;
  text-overflow: ellipsis;
`

const Type = styled.div`
  font-size: 14px;
  color: ${Colors.dark70};
  font-weight: 400;
  word-break: break-word;
  text-overflow: ellipsis;
`

const City = styled.div`
  color: ${Colors.blueGrey};
  word-break: break-word;
  text-overflow: ellipsis;
`
import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import { Colors } from 'constants/colors'
import DescriptionIcon from '@mui/icons-material/Description'
import {SecuredImage, SecuredAttachmentInNewTab, OpenSecuredInNewTab} from '../../../../common/SecuredImage';

export const FileMessage = ({ message, my, onLoad }) => {
  if (message.type === 'images' && !message.url.startsWith('http')) {
    message.url = window.env.API_URL + message.url
  }
  return (
    <Wrapper my={my} target='_blank'>
      {
        message.type === 'images' && (
          <>
            <Photo src={message.url} alt={message.name} onLoad={onLoad} thumbnail={true} />
            <Name>
              {message.custom_name || message.name}
            </Name>
          </>
        ) || (message.mimeType.startsWith('video/') || message.mimeType === 'application/pdf') && (
          <OpenSecuredInNewTab action={message.url} method='get'>
            <DocumentBlock message={message} />
          </OpenSecuredInNewTab>
        ) || (
          <a href={message.url + '?download=true'} target='_blank'>
            <DocumentBlock message={message} />
          </a>
        )
      }
    </Wrapper>
  )
}

const DocumentBlock = ({message}) => (
  <Document>
    <DocumentIcon>
      <DescriptionIcon />
    </DocumentIcon>
    <div>
      <DocumentName>{message.custom_name || message.name}</DocumentName>
      <DocumentSize>{Math.round(message.size / 1024)} Ko</DocumentSize>
    </div>
  </Document>
)

FileMessage.defaultProps = {
}

FileMessage.propTypes = {
  message: PropTypes.object.isRequired,
  my: PropTypes.bool.isRequired,
}

const Wrapper = styled.a`
  height: auto;
  padding: 11px 18px;
  word-wrap: break-word;
  font-size: 16px;
  line-height: 1.5;
  color: ${Colors.dark};
  background-color: ${({ my }) => (my ? Colors.chatMessageSender : Colors.white)};
  border-radius: 4px;
  border: solid 1px ${Colors.cloudyBlue};

  max-width: 260px;
  text-align: center;
`

const Photo = styled(SecuredImage)`
  cursor: pointer;
  max-width: 260px;
  width: 260px;
  display: block;
  border-radius: 4px;
`

const Name = styled.div`
  margin-top: 8px;
  color: ${Colors.dark60};
  font-size: 14px;
`

const Document = styled.div`
  cursor: pointer;
  background-color: ${Colors.white};
  display: flex;
  align-items: center;
  color: ${Colors.dark60};
  padding: 6px;
  border: 1px solid ${Colors.lightGrey};
  border-radius: 4px;
  font-size: 14px;
  text-align: left;
  
  & > div:last-of-type {
    max-width: calc(100% - 56px);
  }
`

const DocumentName = styled.div`
  max-width: 100%;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  font-size: 15px;
`

const DocumentSize = styled.div`
  font-size: 11px;
`

const DocumentIcon = styled.div`
  padding: 8px 12px 8px 0;
  
  svg {
    width: 32px;
    height: 32px;
  }
`

import React, { useEffect } from 'react';
import PropTypes from 'prop-types'
import styled from 'styled-components'
import moment from 'moment-timezone'
import Schedule from '@mui/icons-material/Schedule'
import CloseIcon from '@mui/icons-material/Close';
import { Colors } from 'constants/colors'
import { FormattedMessage } from 'react-intl'
import { useDispatch, useSelector } from 'react-redux';
import { fetchConsultation } from '../../../../../redux/modules/consultations';
import Linkify from "react-linkify";

export const InfoMessage = ({
  heading,
  date,
  message,
  questions,
  approve,
  canceled,
}) => {
  const dispatch = useDispatch()
  const isApproved = useSelector(state => state.consultations.consultation.attributes.status !== 'created')
  const consultationId = useSelector(state => state.consultations.consultation.attributes.id)
  useEffect(() => {
    if (!isApproved && approve && !!consultationId) {
      dispatch(fetchConsultation(consultationId, null, true))
    }
  }, [approve])
  return (
    <Wrapper>
      <Heading>
        <FormattedMessage id={heading} defaultMessage={heading} />
      </Heading>
      {date && (
        <Date>
          <Icon>
            {canceled ? <CloseIcon style={{ fill: 'red' }} /> : <Schedule />}
          </Icon>
          <div>
            <h1>{moment(date).local().format('ddd MMMM, DD')}</h1>
            <p>{moment(date).format('HH:mm')}</p>
          </div>
        </Date>
      )}
      <Linkify
        componentDecorator={(decoratedHref, decoratedText, key) => (
          <a target="blank" href={decoratedHref} key={key}>
            {decoratedText}
          </a>
        )}
      >
        {message}
      </Linkify>
      {
        questions && Object.keys(questions).map((id, index) => (
          <QuestionWrapper key={id}>
            <Question>{index + 1}. {questions[id].title}</Question>
            <Answer>{questions[id].answer.join(', ')}</Answer>
          </QuestionWrapper>
        ))
      }
    </Wrapper>
  )
}

InfoMessage.defaultProps = {
  date: false,
  message: '',
  questions: undefined,
}

InfoMessage.propTypes = {
  heading: PropTypes.string.isRequired,
  date: PropTypes.oneOfType([PropTypes.bool, PropTypes.string]),
  message: PropTypes.string,
  questions: PropTypes.oneOfType([PropTypes.object, PropTypes.array]),
}

export const Wrapper = styled.div`
  height: auto;
  overflow-wrap: break-word;
  font-size: 16px;
  line-height: 1.5;
  padding: 13px 12px;
  background-color: white;
  border-radius: 4px;
  border: solid 1px ${Colors.cloudyBlue};
  color: ${Colors.blueGrey};
  white-space: pre-wrap;
  a {
    font-size: 16px;
    word-break: break-all;
  }
`

export const Heading = styled.h1`
  margin: 0;
  font-size: 14px;
  font-weight: 500;
  opacity: 0.87;
  color: ${Colors.dark};
  margin-bottom: 7px;
`

const Date = styled.div`
  display: flex;
  align-items: center;
  text-transform: uppercase;

  h1 {
    margin: 0;
    font-size: 10px;
    font-weight: 500;
    color: ${Colors.blueGrey};
  }

  p {
    margin: 0;
    font-size: 14px;
    color: ${Colors.dark};
    font-weight: 500;
    opacity: 0.8;
  }
`

const Icon = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 42px;
  height: 42px;
  margin-right: 11px;
  border-radius: 100%;
  background: ${Colors.paleGrey};
`

const QuestionWrapper = styled.div`
  color: ${Colors.dark};
  opacity: .6;
  font-size: 14px;
`

const Question = styled.div`
  font-weight: 700;
`

const Answer = styled.div`
  text-transform: lowercase;
`

import React from 'react'
import {lifecycle} from 'recompose'
import {ToggleButton, ToggleButtonGroup} from "@mui/material";
import FormHelperText from "@mui/material/FormHelperText";
import {FormattedMessage} from "react-intl";

const checkError = (touched, error, submitError) => {
  if (touched) {
    if (error) return error
    if (submitError) return submitError
  }

  return null
}

const ToggleButtonGroupComponent = ({input, meta, options}) => {
  return (
    <>
      <ToggleButtonGroup
        {...input}
        value={input.value}
        exclusive
        onChange={input.onChange}
        fullWidth={true}
        size="medium"
        errorMessage={checkError(meta.touched, meta.error, meta.submitError)}
      >
        {
          options.map((option) => (
            <ToggleButton
              style={{borderColor: meta.touched && meta.error ? '#ee3c5b' : ''}}
              key={option.value}
              value={option.value}>
              {option.label}
            </ToggleButton>
          ))
        }
      </ToggleButtonGroup>
      {
        meta.touched && !!meta.error && (
          <FormHelperText
            error={true}
            id="component-helper-text"
            style={{position: "absolute"}}
          >
            <FormattedMessage id='error.sex.required' />
          </FormHelperText>
        )
      }
    </>
  )
}

const enhance = lifecycle({
  componentWillUnmount() {
    this.props.input.onChange('')
  },
})

export const ToggleButtonGroupField = enhance(ToggleButtonGroupComponent)

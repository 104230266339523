import React from 'react'
import PropTypes from 'prop-types'
import { Route } from 'react-router-dom'
import { connect } from 'react-redux'
import {
  withProps,
  compose,
  lifecycle,
  withState,
} from 'recompose'
import { Drawer } from 'common/UiKit/Drawer'
import styled from 'styled-components'
import idx from 'idx'
import queryString from 'query-string'
import { Chat } from './Chat'
import { Menu } from './Menu'
import { history } from '../../../../../utils/history'
import { consultationIsRecieved } from '../../../../../helpers/consultations'
import {
  getCallStatus,
  removeDownloadLink,
} from '../../../../../redux/modules/consultations'
import { fetchDoctorReviews, sendReview } from '../../../../../redux/modules/reviews'
import { AccessModal } from './AccessModal'

const Wrapper = styled.div`
  position: relative;
  height: 100%;
`

const SessionComponent = ({
  onPatientAvatarClick,
  isAccessModalOpened,
  toggleAccessModal,
  additionalMember,
  isCalling,
}) => (
  <Wrapper>
    <Route
      path='/panel/consultations/session/menu'
      children={({ match }) => (
        <Drawer
          open={!!match}
          DrawerComponent={Menu}
        >
          <Chat onPatientAvatarClick={onPatientAvatarClick} isCalling={isCalling} />
        </Drawer>
      )}
    />
    {
      isAccessModalOpened && (
        <AccessModal data={additionalMember} onClose={() => toggleAccessModal(false)} />
      )
    }
  </Wrapper>
)

SessionComponent.propTypes = {
  onPatientAvatarClick: PropTypes.func.isRequired,
  toggleRateModal: PropTypes.func.isRequired,
  isRateModalOpened: PropTypes.bool.isRequired,
  onRate: PropTypes.func.isRequired,
}

const enhance = compose(
  consultationIsRecieved,
  connect(
    state => ({
      doctorId: idx(state, _ => _.consultations.consultation.relationships.doctor.data.id),
      consultationId: state.consultations.consultation.id,
      isCompleted: idx(state, _ => _.consultations.consultation.attributes.status === 'completed'),
      additionalMember: idx(state, _ => _.consultations
        .consultation.relationships.additionalMember.data[0]),
      doctorReviews: state
        .reviews[idx(state, _ => _.consultations.consultation.relationships.doctor.data.id) || 0],
      hasMyReviews:
        (state.reviews[
          idx(state, _ => _.consultations.consultation.relationships.doctor.data.id) || 0
        ] || []).some(review => (
          review.attributes.consultation_id === state.consultations.consultation.id
        )),
    }),
    {
      getCallStatus,
      removeDownloadLink,
      fetchDoctorReviews,
      sendReview,
    },
  ),
  withState('isRateModalOpened', 'toggleRateModal', false),
  withState('isAccessModalOpened', 'toggleAccessModal', false),
  withProps(props => ({
    onPatientAvatarClick: () => history.push('/panel/consultations/session/menu/profile'),
    onRate: values => props.sendReview(props.doctorId, props.consultationId, values)
      .then(() => props.toggleRateModal(false)),
    isCalling: !!queryString.parse(props.location.search).call,
  })),
  lifecycle({
    componentDidMount() {
      if (this.props.isCompleted) {
        if (!this.props.doctorReviews) {
          this.props.fetchDoctorReviews(this.props.doctorId)
            .then(() => {
              if (!this.props.hasMyReviews) {
                this.props.toggleRateModal(true)
              }
            })
        } else if (!this.props.hasMyReviews) {
          this.props.toggleRateModal(true)
        }
      }

      if (this.props.isCalling) {
        history.push(this.props.match.url)
      }
    },
    componentWillUnmount() {
      this.props.removeDownloadLink()
    },
    componentDidUpdate(prevProps) {
      if (!prevProps.additionalMember && this.props.additionalMember) {
        this.props.toggleAccessModal(true)
      }

      if (this.props.isCalling) {
        history.push(this.props.match.url)
      }
    },
  }),
)

export const Session = enhance(SessionComponent)

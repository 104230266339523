import React from 'react'
import PropTypes from "prop-types";

export const InlineSpacer = ({ width }) => (
  <span style={{ display: 'inline-block', width: width }} />
)

InlineSpacer.propTypes = {
  width: PropTypes.string,
}

InlineSpacer.defaultProps = {
  width: '10px',
}
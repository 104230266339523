import React, { useState } from 'react';
import styled from 'styled-components';
import { FormattedMessage } from 'react-intl';
import { LinkCard } from '../UiKit/LinkCard'
import { ModalPrivacyPolicy } from './ModalPrivacyPolicy';
import { ModalTerms } from './ModalTerms';
import { ModalRequestDeletion } from './ModalRequestDeletion';
import { ModalSuccess } from './ModalSuccess';
import { ModalPayment } from './ModalPayment';
import { history } from '../../../utils/history'
import { PanelBar } from '../PanelBar'

const Wrapper = styled.div`
  width: 720px;
  margin: 24px auto 0;
`;

const LinkCardStyled = styled(LinkCard)`
  padding: 12px 16px 12px 24px;
  margin-bottom: 16px;
`;

export const PrivacyPolicy = ({ match }) => {
  const [modalStates, setModalStates] = useState({
    // privacy: false,
    terms: false,
    restrict: false,
    requestCsv: false,
    requestDeletion: false,
    modalChange: false,
    payment: false,
  });

  const [modalSucces, setModalSucces] = useState(false);

  const changeModalState = (modal, state) => {
    setModalStates({ ...modalStates, [modal]: state });
  };


  const openCGU = () => {
    window.open("https://conexsante.com/cgu/", '_blank', 'noopener,noreferrer');
  }

  const openPC = () => {
    window.open("https://conexsante.com/politique-de-confidentialite/", '_blank', 'noopener,noreferrer');
  }

  const openCGV = () => {
    window.open("https://conexsante.com/conditions-generales-de-vente/", '_blank', 'noopener,noreferrer');
  }
  return (
    <>
      <PanelBar label='global.settings' onBackIconClick={() => history.goBack()} />
      <Wrapper>
        <h2> <FormattedMessage id='global.conditions.privacy-policy' /> </h2>
        <LinkCardStyled
          label='profile.termsOfUse'
          onClick={() => openCGU()}
        />
        {/*<LinkCardStyled*/}
        {/*  label='global.privacy-policy'*/}
        {/*  onClick={() => openPC()}*/}
        {/*/>*/}
        <LinkCardStyled
          label='profile.paymentPolicy'
          onClick={() => openCGV()}
        />
        <LinkCardStyled label='profile.history' to={`${match.path}/history`} />
      </Wrapper>
      {/*<ModalPrivacyPolicy*/}
      {/*  handleClickOpen={changeModalState}*/}
      {/*  open={modalStates.privacy}*/}
      {/*  handleClose={changeModalState}*/}
      {/*/>*/}
      <ModalTerms
        handleClickOpen={changeModalState}
        handleClose={changeModalState}
        open={modalStates.terms}
      />
      <ModalPayment
        handleClickOpen={changeModalState}
        handleClose={changeModalState}
        open={modalStates.payment}
      />
      <ModalRequestDeletion
        handleClickOpen={changeModalState}
        handleClose={changeModalState}
        open={modalStates.requestDeletion}
      />
      <ModalSuccess
        handleClose={setModalSucces}
        open={modalSucces}
      />
    </>
  );
};

import React from 'react'
import styled from "styled-components";
import {Colors} from "../../../../../../constants/colors";
import {DistanceDisplay} from "../../../../../common/DistanceDisplay";
import essLogo from "../../../Community/ROSO/images/Logo_ESS.png";
import esspLogo from "../../../Community/ROSO/images/Logo_ESSP.png";
import GroupsIcon from "@mui/icons-material/Groups";
import idx from "idx";

export const DoctorTeamToolTipComponent = ({doctorTeam}) =>{

  const teamType = idx(doctorTeam, _ => _.attributes?.type);
  const name = idx(doctorTeam, _ => _.attributes?.name);
  const specializations = idx(doctorTeam, _ => _.attributes?.specializations[0]);
  const memberCount = idx(doctorTeam, _ => _.attributes?.member_count);
  const distance = idx(doctorTeam, _ => _.attributes?.distance);
  const favorite = idx(doctorTeam, _ => _.attributes?.favorite);

  return (
    <>


      <Wrapper>
        <IconWrapper>
          <TeamIcon
            spinner={{width: '63px', height: '63px'}}
            src={teamType === 'ESS' ? essLogo : esspLogo}
            alt='ess'
          />
        </IconWrapper>
        <Info>
          <Name>{name}</Name>
          {teamType === 'ESS' ?
            (<Specialization>{specializations}</Specialization>
            ) : null
          }
          <City></City>
        </Info>
        <LanguageWrapper>
          <Participants>
            <StyledPersonIcon/>
            {memberCount + " "}
          </Participants>
          {
            !!distance && ( <DistanceDisplay distance={distance} /> )
          }
        </LanguageWrapper>
      </Wrapper>
    </>
  )
}


const Wrapper = styled.div`
  display: flex;
  font-weight: normal;
  font-size: 16px;
  align-items: center;
`

const LanguageWrapper = styled.div`
  top: 16px;
  right: 16px;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #b7bed2;
`

const TeamIcon = styled.img`

  height: 40px;
  width: 100px;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
`

const Info = styled.div`
  padding: 0 15px 0 15px;
`

const Specialization = styled.div`
  font-size: 14px;
  color: ${Colors.dark70};
  font-weight: 400;
  word-break: break-word;
  text-overflow: ellipsis;
`

const Name = styled.div`
  font-weight: bold;
  word-break: break-word;
  text-overflow: ellipsis;
`

const City = styled.div`
  color: ${Colors.blueGrey};
`

const Participants = styled.div`
  color: #858992;
  display: flex;
  align-items: center;
  text-transform: lowercase;
  margin-right: 15px;
`

const StyledPersonIcon = styled(GroupsIcon)`
  margin-right: 6px;
  svg {
      color: #b7bed2;
  }
`

const IconWrapper = styled.div`
  display: flex;
  align-items: center;
`
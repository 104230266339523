import React, {useRef} from 'react';
import styled from 'styled-components'
import idx from 'idx'
import { useSelector } from 'react-redux'
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogTitle from '@mui/material/DialogTitle';
import { FormattedMessage } from 'react-intl';
import {Colors} from "../../../../../../../constants/colors";
import ClearIcon from '@mui/icons-material/Clear';

const StyledClearIcon = styled(ClearIcon)`
    cursor: pointer;
`
const ElementList = styled.div`
  {
    text-transform: none;
    margin: 5px;
    width: 100% ;
    max-height: 35px;
    color: ${Colors.greyText};
    font-size: 20px;
    border-bottom: 1px solid ${Colors.greyText} ;
  
    &:hover {
      background-color:${Colors.lightGrey};
      cursor: pointer;
    }
    
    &:before {
      background-image:  url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 512 512'%3E%3Cpath d='M240.9 160c-7.6 0-13.8 4-15.4 9.9-4.9 18.6.2 46.3 9.4 82.3l-2.4 5.9c-6.6 16.4-14.8 33-22.1 47.6l-1 1.9c-7.7 15.3-14.6 28.4-21 39.4l-6.5 3.5c-.5.3-11.6 6.3-14.3 7.9-22.2 13.6-36.9 28.9-39.4 41.2-.8 3.9-.2 8.9 3.7 11.2l6.3 3.2c2.7 1.4 5.6 2.1 8.6 2.1 15.8 0 34.2-20.2 59.5-65.3 29.2-9.7 62.5-17.8 91.6-22.3 22.2 12.8 49.5 21.7 66.8 21.7 3.1 0 5.7-.3 7.8-.9 3.3-.9 6.1-2.8 7.8-5.4 3.4-5.2 4-12.2 3.1-19.5-.3-2.1-2-4.8-3.8-6.6-5.1-5.2-16.5-7.9-33.7-8.1-11.7-.1-25.8.9-40.6 3-6.6-3.9-13.5-8.2-18.8-13.3-14.4-13.8-26.5-32.9-34-53.9.5-2 .9-3.7 1.3-5.4 0 0 8.1-47.1 6-63.1-.3-2.2-.5-2.8-1.1-4.5l-.7-1.9c-2.2-5.2-6.5-10.7-13.3-10.4l-3.8-.2zm2.7 8.4c5.1 0 8 13.1 8.2 25.4.3 12.3-2.6 20.9-6 27.3-2.9-9.4-4.3-24.3-4.3-34-.1-.1-.3-18.7 2.1-18.7zm.4 104.1c8.6 16 19.3 29.5 31.9 40.4 1.6 1.3 3.2 2.7 5 4.1-25.6 5.2-47.7 11.5-67.1 19.1 3.6-6.5 7.2-13.3 11-20.6 9.1-17.7 14.9-31.6 19.2-43zm102.2 51.6c9.3 0 12 0 21.1 2.3 9.1 2.4 9.2 7.2 7.6 8.2-1.6 1-6 1.6-8.9 1.6-9.2 0-20.7-4.3-36.8-11.4 6.3-.4 11.9-.7 17-.7zm-165.4 35.8c-17.7 28.8-29.5 40.3-37.3 43.8 2.9-8.1 14.3-24 31.2-38.1 1.1-.9 3.7-3.4 6.1-5.7zM352 32H96c-17.7 0-32 14.3-32 32v384c0 17.7 14.3 32 32 32h320c17.7 0 32-14.3 32-32V128l-96-96zm64 416H96V64h224v96h96v288z'%3E%3C/path%3E%3C/svg%3E");
      background-size: cover;
      position: relative;
      width: 20px;
      height: 20px;
      display: inline-block;
      content: "";
      margin-right: 10px;
      margin-bottom: -2px;
    }
  }
`

export const DialogFDS = ({ open, handleSubmit, handleClose }) => {

  const consultation = useSelector(state => idx(state, _ => _.consultations.consultation))
  const consultationId = consultation.id;
  const userId = useSelector(state => state.auth.attributes.user_id)
  const invoice = consultation.attributes.invoice.invoicePDF
  const refFormBackground = useRef();
  const token = localStorage.getItem('authToken');
  const refFormPDF = useRef();
  const pdfUrl = `${window.env.API_URL}/general/consultation/${consultationId}/${userId}/pdf/activity_report`

  return (
    <Dialog
      open={open}
      onClose={handleClose}
      PaperProps={{
        style: { minHeight: '150px', borderRadius: 10}
      }}
    >
      <DialogTitle id='alert-dialog-title' style={{textAlign: 'center'}}>
        <FormattedMessage id='payments.dialog.fds'/>
        <StyledClearIcon onClick={() => handleClose()} style={{float: "right"}}/>
      </DialogTitle>

      <DialogActions style={{ justifyContent: "space-evenly", flexDirection: "column"}}>

        <ElementList
            onClick={() => pdfUrl && refFormPDF && refFormPDF.current && refFormPDF.current.submit && refFormPDF.current.submit()}
            style={{marginLeft: '8px'}}
        >
          <FormattedMessage id='payments.dialog.fds.activity_report'/>
          <form target='_blank' action={pdfUrl} ref={refFormPDF} method={'post'} style={{width: '100%'}}>
            <input type='hidden' name='token' value={token} />
          </form>
        </ElementList>


        <ElementList onClick={() => {
          refFormBackground && refFormBackground.current && refFormBackground.current.submit && refFormBackground.current.submit();
          handleClose();
        }}>
          <FormattedMessage id='payments.dialog.fds.fds'/>
          <form target='_blank' action={invoice} ref={refFormBackground} method={'post'}>
            <input type='hidden' name='token' value={token} />
            <input type='hidden' name='background' value={1} />
            <input type='hidden' name='download' value={0} />
          </form>
        </ElementList>
      </DialogActions>
    </Dialog>)
}

import React from 'react'
import styled from 'styled-components'
import { compose, lifecycle } from 'recompose'
import { connect } from 'react-redux'
import { Colors } from 'constants/colors'
import { Route, Switch } from 'react-router-dom'
import { Main } from './Main'
import { Edit } from './Edit'
import { Attachments } from './Attachments'
import { MedicalReports } from './MedicalReports'
import { Report } from './MedicalReports/Report'
import { Prescriptions } from './Prescriptions'
import { DrugIncompability } from './DrugIncompability'
import { Allergies } from './Allergies'
import { fetchHealthRecords, fetchAllergies } from '../../../../redux/modules/healthRecords'
import { fetchConsultations } from '../../../../redux/modules/consultations'
import { fetchContraindications } from '../../../../redux/modules/content'

const Wrapper = styled.div`
  background-color: ${Colors.background};
  min-height: 100%;
`

const HealthRecordsComponent = () => (
  <Wrapper>
    <Switch>
      <Route path='/panel/health-records/edit' component={Edit} />
      <Route path='/panel/health-records/attachment' component={Attachments} />
      <Route path='/panel/health-records/medical-reports' component={MedicalReports} />
      <Route exact path='/panel/health-records/prescriptions' component={Prescriptions} />
      <Route exact path='/panel/health-records/drug-incompability' component={DrugIncompability} />
      <Route exact path='/panel/health-records/allergies' component={Allergies} />
      <Route
        path='/panel/health-records/prescriptions/prescription'
        render={() => <Report isPrescription />}
      />
      <Route path='/panel/health-records' component={Main} />
    </Switch>
  </Wrapper>
)

const enhance = compose(
  connect(
    () => ({}),
    {
      fetchHealthRecords,
      fetchConsultations,
      fetchContraindications,
      fetchAllergies,
    },
  ),
  lifecycle({
    componentDidMount() {
      this.props.fetchHealthRecords()
      this.props.fetchConsultations()
      this.props.fetchContraindications()
      this.props.fetchAllergies()
    },
  }),
)

export const HealthRecords = enhance(HealthRecordsComponent)

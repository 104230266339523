export const AvatarESS = () => {

   const color = "#6633FF"
   const name = "ESS"

    let size = 500

    const canvas=document.createElement('canvas')
    const context=canvas.getContext('2d')
    canvas.width=canvas.height=size

    context.fillStyle="#e9f5ff"
    context.fillRect(0,0,size,size)

    context.fillStyle=`${color}99`
    context.fillRect(0,0,size,size)

    context.fillStyle="#ffffff";
    context.textBaseline='middle'
    context.textAlign='center'
    context.font =`250px Roboto`
    context.fillText(name,(size/2),(size/2 + 25))
    return canvas.toDataURL()
};
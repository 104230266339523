import React, {useEffect, useRef, useState} from 'react'
import { Paper } from 'common/UiKit/Paper'
import { Colors } from 'constants/colors'
import Button from '@mui/material/Button'
import { FormattedMessage } from 'react-intl'
import styled from 'styled-components'
import DescriptionIcon from '@mui/icons-material/Description'
import CloseIcon from '@mui/icons-material/Close'
import get from 'lodash/get';
import { uploadSingleFile } from '../../../../helpers/attachments';
import {UPLOAD_FILE_MAX_SIZE} from "../../../../constants/uploads";
import CircularProgress from '@mui/material/CircularProgress';
import {useSelector} from "react-redux";
import axios from "axios";
import Skeleton from '@mui/material/Skeleton';

const Input = styled.input`
  display: none;
`

const List = styled.div`
  display: flex;
  flex-flow: row wrap;
`

const FileWrapper = styled.div`
  display: flex;
  align-items: center;
  padding: 12px 16px;
  border-radius: 4px;
  background-color: ${Colors.lightGrey};
  font-weight: 500;
  color: ${Colors.dark60};
  margin-right: 12px;
  margin-bottom: 8px;
  font-size: 14px;
  
  svg {
    margin-right: 12px;
  }
`

const CloseWrapper = styled.div`
  width: 40px;
  text-align: right;
  color: ${Colors.red};
  
  svg {
    cursor: pointer;
    height: 18px;
    margin-top: -18px;
    margin-right: -6px;
  }
`

const ErrorStyled = styled.div`
  color: red;
  font-size: 14px;
  margin-bottom : 14px;
`

const loadFilesSequential = async (files, setProgress) => Promise.all(files.map(file => uploadSingleFile({ file }, setProgress)
  .then(downloaded => get(downloaded, 'data.data.attributes', {}))))

const convertToMB = (bytes = 0) => bytes / 1024 / 1024;

export const AttachmentsField = ({ input, onlyAttachmentId, accept, progress, setProgress }) => {
  const [bigFileInitial, setBigFileInitial] = useState(false);
  const inputRef = useRef(null)

  const draftConsultation = useSelector(state => state.consultations.draft.consultation)
  const isDraft = typeof draftConsultation !== 'undefined'
  const [draftAttachmentLoading, setDraftAttachmentLoading] = useState(isDraft)

  useEffect(() => {
    if (isDraft) {

      axios.get('/api/attachment/consultation/'+draftConsultation.id)
        .then(res => {
          const attachmentsList = []
          res.data.data.map(item => {
              attachmentsList.push(item.attributes)
            }
          )
          input.onChange([...input.value, ...attachmentsList])
          setDraftAttachmentLoading(false)
        })
    }
  }, [draftConsultation])

  const onButtonClick = () => {
    setProgress(0)
    if (inputRef.current) {
      inputRef.current.click()
    }
  }

  const proxiedConditionalChange = async (e, existing = []) => {
    setBigFileInitial(false);
    if (onlyAttachmentId) {
      const files = get(e, 'target.files', {});
      const arrayFiles = Object.keys(files).map(file => files[file]);
      const isBig = arrayFiles.some(el => Math.ceil(convertToMB(el.size)) + 0.1 > UPLOAD_FILE_MAX_SIZE);
      const sizedFiles = arrayFiles
        .filter(el => el.size && el.size && el.size > 0 && Math.ceil(convertToMB(el.size)) + 0.1 <= UPLOAD_FILE_MAX_SIZE);
      if (isBig) {
        setBigFileInitial(true);
      }
      try {
        const filesIds = await loadFilesSequential(sizedFiles, setProgress);
        return input.onChange([...existing, ...filesIds]);
      } catch (e) {
        console.warn(e);
      }
    } 
    return input.onChange([...input.value, ...e.target.files])
  } 

  const onDelete = (id) => {
    input.onChange(input.value.filter(item => item.id !== id));
    if (inputRef.current) {
      inputRef.current.value = '';
    }
  }
  const currentValue = get(input, 'value', []);
  const someFileBig = currentValue && Array.isArray(currentValue) 
    ? currentValue.some(el => Math.ceil(convertToMB(el.size)) + 0.1 > UPLOAD_FILE_MAX_SIZE) : false;
  // TOTAL FILE CHECK SIZE
  // const totalSize = currentValue && Array.isArray(currentValue) ? currentValue.reduce((accum, el) => el.size + accum, 0) : 0;
  // const totalSizeMB = convertToMB(totalSize);
  const showAddButton = !someFileBig && get(input, 'value.length', 0) < 5;

  return (
    <Paper heading='concilium.attachments' optional>
      {bigFileInitial ? (
          <ErrorStyled>
            <FormattedMessage id='error.only.n.size.allowed2' />
          </ErrorStyled>
      ) : ''}
      {someFileBig ? (
          <ErrorStyled>
            <FormattedMessage id='error.only.n.size.allowed' />
          </ErrorStyled>
      ) : ''}
      {input.value && input.value.length > 5 ? (
              <ErrorStyled>
                <FormattedMessage id='error.only.n.files.allowed' />
              </ErrorStyled>
          )
          : ''}
      {input.value && input.value.length ? (
        <List>
          {
            input.value.map(item => (
              <FileWrapper key={`${item.name}-${item.size}-${item.id}`}>
                <DescriptionIcon />
                <span>
                  {item.name}
                  {' '}
                  ({convertToMB(item.size).toFixed(2)}MB)
                </span>
                <CloseWrapper>
                  <CloseIcon onClick={() => onDelete(item.id)} />
                </CloseWrapper>
              </FileWrapper>
            ))
          }
        </List>
      ) : null}
      {(draftAttachmentLoading) ? (
          <>
            <Skeleton variant="text" sx={{ fontSize: '30px' }} />
            <Skeleton variant="text" sx={{ fontSize: '30px' }} />
            <Skeleton variant="text" sx={{ fontSize: '30px' }} />
          </>
      ) : ''}
      {progress && progress != 100 ? <CircularProgress variant="determinate" value={progress} style={{display: 'flex'}}/> : null}
      {showAddButton ? (
        <Button size='small' color='primary' onClick={onButtonClick}>
          <FormattedMessage id='concilium.add' />
        </Button>
      ) : ''}
      <Input
        type='file'
        accept={accept || '*'}
        multiple 
        ref={inputRef} 
        onChange={e => proxiedConditionalChange(e, input.value)}
      />
    </Paper>
  )
}

/* eslint-disable jsx-a11y/label-has-associated-control, jsx-a11y/label-has-for */
import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import Chip from '@mui/material/Chip'

import { Colors } from '../../../../constants/colors'

const Wrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
`

const Input = styled.input`
  position: absolute;
  opacity: 0;
`

const ChipStyled = styled(props => (
  <Chip {...props} classes={{ colorSecondary: 'secondary' }} />
))`
  && {
    height: 36px;
    margin: 8px;
    padding: 0 10px;
    font-size: 12px;
    font-weight: 500;
    text-transform: uppercase;
    letter-spacing: 0.4px;
  }

  &&.secondary {
    background-color: ${Colors.paleGrey};
    color: ${Colors.blueGrey};
  }
`

export const RadioInput = ({
  id,
  onChange,
  options,
  value,
}) => (
  <Wrapper>
    {
      options.map(option => (
        <span key={option.value}>
          <label htmlFor={id + option.value}>
            <Input
              checked={value === option.value}
              id={id + option.value}
              onChange={e => onChange(e.target.value)}
              type='radio'
              value={option.value}
            />
            <ChipStyled
              clickable
              color={value === option.value ? 'primary' : 'secondary'}
              label={option.label}
            />
          </label>
        </span>
      ))
    }
  </Wrapper>
)

RadioInput.propTypes = {
  id: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
  options: PropTypes.arrayOf(PropTypes.shape({
    label: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
    value: PropTypes.string.isRequired,
  })).isRequired,
  value: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.string),
    PropTypes.string,
  ]).isRequired,
}
/* eslint-disable jsx-a11y/label-has-associated-control, jsx-a11y/label-has-for */

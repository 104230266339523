import React from 'react'
import { Page } from '../../../common/Page'
import { history } from '../../../../utils/history';


export const versionComponent = () => (
  <Page
    label='global.version'
    onBackIconClick={() => history.goBack()}
  >
    <h2> RC19.02.22 </h2>
  </Page>
)
export const Version = versionComponent

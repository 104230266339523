import React from 'react'
import styled from 'styled-components'
import PropTypes from 'prop-types'
import Checkbox from '@mui/material/Checkbox'
import { Field } from 'react-final-form'
import { compose, mapProps, withStateHandlers } from 'recompose'
import { Colors } from 'constants/colors'
import { FormattedMessage, useIntl } from 'react-intl'
import { scheduleTime } from 'constants/scheduleTime'
import { TextField } from '../TextField'

const Wrapper = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 24px;
`

const CheckboxWrapper = styled.div`
  width: 204px;
  margin-left: -12px;
  display: flex;
  align-items: center;
  font-size: 16px;
  font-weight: bold;
  color: ${Colors.dark};
  text-transform: capitalize;

  .day {
    opacity: 0.87;
    margin-left: 12px;
  }
`

const FieldWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  flex-grow: 1;
`

const TextFieldStyled = styled(TextField)`
  && {
    width: 234px;
    margin: 0;
  }
`

const DayFieldComponent = ({
  checked,
  onChange,
  day,
  validateFrom,
  validateTo,
}) => {
  const { formatMessage } = useIntl()

  return (
    <Wrapper>
      <CheckboxWrapper>
        <Checkbox
          color='primary'
          checked={checked}
          onChange={onChange}
        />
        <span className='day'>
          <FormattedMessage id={`global.${day}`} />
        </span>
      </CheckboxWrapper>
      <FieldWrapper>
        <Field
          name={`${day}_from`}
          component={TextFieldStyled}
          label={`*${formatMessage({ id: 'global.from' })}`}
          disabled={!checked}
          options={scheduleTime}
          validate={validateFrom}
        />
        <Field
          name={`${day}_to`}
          component={TextFieldStyled}
          label={`*${formatMessage({ id: 'global.to' })}`}
          disabled={!checked}
          options={scheduleTime}
          validate={validateTo}
        />
      </FieldWrapper>
    </Wrapper>
  )
}

const enhance = compose(
  withStateHandlers(props => ({
    checked: !!props.filled || false,
  }), {
    onChange: ({ checked }) => () => ({ checked: !checked }),
  }),
  mapProps(props => ({
    ...props,
    validateFrom: value => !value && props.checked && 'Please enter Start Time',
    validateTo: value => !value && props.checked && 'Please enter Finish Time',
  })),
)

DayFieldComponent.propTypes = {
  checked: PropTypes.bool.isRequired,
  onChange: PropTypes.func.isRequired,
  day: PropTypes.string.isRequired,
  validateFrom: PropTypes.func.isRequired,
  validateTo: PropTypes.func.isRequired,
}

export const DayField = enhance(DayFieldComponent)

import React from 'react'
//import PropTypes from 'prop-types'
import styled from 'styled-components'

import { Colors, BUTTON_COLORS } from 'constants/colors'
import { TextButton } from '../TextButton'

const Wrapper = styled.div`
  background-color: ${Colors.white};
  height: 100%;
  display: flex;
  flex-direction: column;
`

const Button = styled(TextButton)`
  && {
    height: 44px;
    width: 260px;
    position: relative;
    text-transform: none;
    background-color: ${BUTTON_COLORS.purple};
    font-size: 14px;
    font-weight: 500;
    margin: auto;
  }
  &&:hover {
    background-color: ${BUTTON_COLORS.purpleHover};
  }
`

export const Panel = ({
  button,
  buttonProps = {},
  children,
  className,
}) => (
  <Wrapper className={className}>
    {children}
    {
      !!button
      && (
      <Button color='primary' variant='extended' {...buttonProps}>
        {button}
      </Button>
      )
    }
  </Wrapper>
)

// Panel.propTypes = {
//   button: PropTypes.string,
//   buttonProps: PropTypes.object,
//   children: PropTypes.node,
//   className: PropTypes.string,
// }

import React from 'react'
import styled from 'styled-components'
import { Paper } from 'common/UiKit/Paper'
import {Colors} from '../../../constants/colors'
import {history} from "../../../utils/history";
import GroupsIcon from "@mui/icons-material/Groups";
import essLogo from "../../Panel/Doctor/Community/ROSO/images/Logo_ESS.png";
import esspLogo from "../../Panel/Doctor/Community/ROSO/images/Logo_ESSP.png";


const PaperStyled = styled(Paper)`
  padding: 10px 14px;
  position: relative;
`

const Wrapper = styled.div`
  display: grid;
  grid-template-rows: 1fr 1fr 1fr;  
  font-weight: normal;
  font-size: 16px;
  align-content: center;
  height: 140px;
  overflow: hidden;
  text-overflow: ellipsis;
  & > * {
    min-width: 0;
    min-height: 0;
  }
  justify-self: center;
`

const Div = styled.div`
  display: grid;
  grid-template-columns: 70px auto;  
  font-weight: normal;
  font-size: 16px;
  align-items: center;
`

const NameLinked = styled.h1`
  margin: 0 20px;
  font-size: 18px;
  font-weight: bold;
  color: black;
  max-height: 70px;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;  
  overflow: hidden;
  
  &:hover {
    cursor: pointer;
    color: #6633FF;
  }
`

const Name = styled.p`
  margin: 0 20px;
  font-size: 18px;
  font-weight: bold;
  color: black;
  max-height: 70px;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;  
  overflow: hidden;
`

const Specialization = styled.p`
  margin: 0 20px;
  opacity: 0.8;
  
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;  
  overflow: hidden;
`

const Members = styled.div`
  align-items: center;
  color: #959fb8;
  font-size: 14px;
  font-weight: 200;
  display: flex;
  text-transform: lowercase;
  justify-content: space-evenly;
`

const AvatarWrapper = styled.div`
  & > p {
    margin: 16px 0 0;
    color: ${Colors.clearBlue};
    font-size: 15px;
    font-weight: bold;
    text-align: center;
  }
`

const Img = styled.img`
  width: 65px;
`

export const FavoriteTeamCard = ({
                                        id,
                                        name,
                                        members,
                                        type,
                                        specializations,
                                        status_availability,
                                        link,
                                      }) => {
  return (
    <PaperStyled>
      <Wrapper>
        <Div>
          <AvatarWrapper>
            <Img src={type === 'ESS' ? essLogo : esspLogo} alt='ess' />
          </AvatarWrapper>

          {link ? (
              <NameLinked onClick={()=>history.push(link)}>
                {name}
              </NameLinked>)
            : (
              <Name>
                {name}
              </Name>)
          }

        </Div>

        <Div>
          <Members>
            <GroupsIcon />
            <div>
              {members}
            </div>
          </Members>

          <Specialization>
            {specializations.map((specialization) => {
              if(specializations[specializations.length-1] === specialization) {
                return (specialization.attributes.name)
              } else {
                return (specialization.attributes.name + ", ")
              }
            })}
          </Specialization>
        </Div>
      </Wrapper>
    </PaperStyled>
  )
}
import React, {useState} from 'react'
import PropTypes from 'prop-types'
import {connect, useSelector} from 'react-redux'
import styled from 'styled-components'
import { FormattedMessage } from 'react-intl';
import { LinkCard } from '../../../common/UiKit/LinkCard'
import { Page } from '../../../common/Page'
import { logOut } from '../../../../redux/modules/auth'
import {ModalPrivacyPolicy} from "../../../common/PrivacyPolicy/ModalPrivacyPolicy";
import {ModalSuccess} from "../../../common/PrivacyPolicy/ModalSuccess";
import {history} from "../../../../utils/history";

const LinkCardStyled = styled(LinkCard)`
  
  padding: 12px 16px 12px 24px;
  margin-bottom: 16px;
`
const LinkIconBig = styled(LinkCard)`
  height: 400px;
  width: 400px;
`

const Wrapper = styled.div`
  display: flex;
  justify-content: space-around;
  align-items: center;
`

export const AboutSettingsComponent = ({
                                id,
                                logOut,
                              }) => {

  const role = useSelector(state => state.auth.type)
  const [modalSucces, setModalSucces] = useState(false);
  const [modalStates, setModalStates] = useState({
    privacy: false,
  });
  const changeModalState = (modal, state) => {
    setModalStates({ ...modalStates, [modal]: state });
  };
  return (
    <Page
      label='profile.about.CDG.version.policy'
      onLogout={logOut}
      id={id}
      onBackIconClick={ () => history.goBack()}
    >
      <h2> <FormattedMessage id='profile.about.CDG.version.policy' /> </h2>
      <LinkCardStyled
        label='global.version'
        to='/panel/version'
      />
      <LinkCardStyled
        label='global.general.privacy-policy-conditions'
        to='/panel/settings/privacy-policy'
      />
      <LinkCardStyled
        label='global.privacy-policy'
        onClick={() => changeModalState('privacy', true)}
      />
      <ModalPrivacyPolicy
        handleClickOpen={changeModalState}
        open={modalStates.privacy}
        handleClose={changeModalState}
      />
      <ModalSuccess
        handleClose={setModalSucces}
        open={modalSucces}
      />
    </Page>
  )
}

AboutSettingsComponent.propTypes = {
  id: PropTypes.string.isRequired,
  logOut: PropTypes.func.isRequired,
}

const enhance = connect(state => ({ id: state.auth.id }), { logOut })

export const AboutSettings = enhance(AboutSettingsComponent)

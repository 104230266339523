import React, { useState, useEffect, useRef } from 'react';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import { Button } from '@mui/material';
import AddIcon from '@mui/icons-material/Add';
import axios from 'axios';
import styled from 'styled-components';
import Pagination from '@mui/material/Pagination';
import queryString from 'query-string'
import { FormattedMessage, useIntl } from 'react-intl'

import { Paper } from '../../../../common/UiKit/Paper'
import { Search } from '../../../../common/Search'
import { ListOfStructures } from './components/ListOfStructures'
import { history } from '../../../../../utils/history'
import { Page } from '../../../../common/Page'

const PaginationContainer = styled.div`
    text-align: center;
    display: flex;
    align-items: center;
    justify-content: center;
    padding-top: 20px;
    padding-bottom: 20px;
`

const StyledPapper = styled(Paper)`
    padding: 0 !important;
`

const SearchWrapper = styled.div`
    input {
        border: 1px solid #c5cbdb;
    }
    & > div {
        margin-right: 0;
    }
    width: 721px;
    margin: 0px auto;
    margin-top: 24px;
`

const Wrapper = styled.div`
  max-width: 100%;
  min-width: 720px;
  margin: 24px auto;

  .MuiButton-label {
  text-transform: initial;
}
`

const EmptyList = styled.div`
    color: #959fb8;
    font-size: 20px;
    font-weight: 500;
    max-width: 253px;
    margin: 0px auto;
    margin-top: 197px;
    text-align: center;
    opacity: 0.9;
    line-height: 1.2;
`
const Content = styled.div`
  display: flex;
  margin-top: 16px;
  justify-content: right;
`
const CreateBlockTitle = styled.div`
  font-family: Roboto;
  font-size: 24px;
  font-weight: 500;
  color: #21293e;
  margin-bottom: 30px;
  margin-inline-start: 250px;
  justify-content: left;
  flex: 1;
`
const ButtonBlock = styled.div`
 align-items: center;
 margin-right: 80px;
 margin-top: 30px;
`
const CreateBtnStyle = {
  padding: '5px 16px',
  background: '#6633FF',
  color: '#fff',
  boxShadow: 'none',
  borderRadius: '25px',
  cursor: 'pointer',
  textTransform: 'none',
}

export const StructuresListPage = () => {
  const { formatMessage } = useIntl();
  const f = id => formatMessage({ id });
  //const [currentTab, setCurrentTab] = useState(1);
  const [list, setList] = useState([]);
  const [filter, setFilter] = useState({ limit: 10 });
  const [paginator, setPaginator] = useState({ currentPage: 1, total: 0, totalPage: 0 });
  const topAnkor = useRef();
  /*eslint func-names: ["error", "never"]*/
  useEffect(() => {
    (async function () { 
      try {
        const base = '/api/doctor/structure/list';
        const resp = await axios
          .get(`${base}?${queryString.stringify({ ...filter, ...{} })}`);
        setList(resp.data.data);
        setPaginator(resp.data.paginator);
      } catch (e) {
        console.warn(e);
      } 
    }());
  }, [filter.page, filter.search])

  useEffect(() => {
    topAnkor.current.scrollIntoView(true);
  }, [filter.page])

  return (
    <Page
      label='structures.mesStructures'
      fullWidth
    >
      <Wrapper ref={topAnkor}>
        <Content>
          <CreateBlockTitle>
            <FormattedMessage id='structures.mesStructures' />
          </CreateBlockTitle>
          <ButtonBlock>
            <Button onClick={() => history.push('/panel/community/joinstructures')} variant='contained' color='primary' style={CreateBtnStyle} startIcon={<AddIcon />}>
              <FormattedMessage id='structures.joinTitle' />
            </Button>
          </ButtonBlock>
        </Content>

        {/*<StyledPapper>
          <Tabs
            value={currentTab}
            onChange={(e, value) => {
              setFilter({ limit: 10 });
              setCurrentTab(value);
            }}
            indicatorColor='primary'
            textColor='primary'
            centered
          >
            <Tab label={f('structures.allStructures')} />
            <Tab label={f('structures.mesStructures')} />
          </Tabs>
        </StyledPapper>
        <SearchWrapper>
          <Search label='Search' onSearch={search => setFilter({ ...filter, search })} />
        </SearchWrapper>*/}
        {!list || !list.length ? <EmptyList><FormattedMessage id='structures.none' /></EmptyList>
          : <ListOfStructures list={list} />}
        {paginator.totalPage ? (
          <PaginationContainer>
            <Pagination
              onChange={(e, page) => setFilter({ ...filter, page })}
              count={paginator.totalPage}
              color='primary'
            />
          </PaginationContainer>
        ) : null}
      </Wrapper>
    </Page>
  ) 
}

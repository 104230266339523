import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import queryString from 'query-string'
import { compose, mapProps } from 'recompose'
import { connect } from 'react-redux'
import { Form, Field } from 'react-final-form'
import { TextField } from 'common/UiKit/TextField'
import { FormattedMessage } from 'react-intl'
import { Form as FormWindow } from '../Form'
import { setNewPassword } from '../../../../redux/modules/auth'
import { externalFormSubmit } from '../../../../helpers/externalFormSubmit'
import { history } from '../../../../utils/history'
import {PasswordFieldComponent} from "../PasswordField";
import {validatePassWordConditions} from "../../../../utils/validatePassWordConditions";

const Wrapper = styled.div`
  display: flex;
  justify-content: center;
  width: 680px;
`

const TextFieldStyled = styled(TextField)`
  && {
    margin-bottom: 24px;
  }
`

const Filler = styled.div`
  height: 49px;
`


const validate = (values) => {
  return validatePassWordConditions(values.password,values.confirmation,"password","confirmation")
}

const NewPasswordComponent = ({
  loading,
  onButtonClick,
  onSubmit,
  onIconClick,
}) => {

  return (
      <Wrapper>
        <Form
            onSubmit={onSubmit}
            validate={validate}
            subscription={{}}
            render={({handleSubmit}) => (
                <form onSubmit={handleSubmit} id='new_password'>
                  <FormWindow
                      button={<FormattedMessage id='auth.valider'/>}
                      heading={<FormattedMessage id='auth.new.password'/>}
                      loading={loading}
                      onButtonClick={onButtonClick}
                      onIconClick={onIconClick}
                      backIcon={false}
                      textStyle={{
                        display: 'flex',
                        justifyContent: 'center',
                        fontWeight: 'bold',
                      }}
                      text={<FormattedMessage id='auth.new.password.description'/>}
                  >
                  <PasswordFieldComponent
                      name='password'
                      label = 'auth.create.password'
                  />
                    <Field
                        name='confirmation'
                        label={<FormattedMessage id='auth.repeat.password'/>}
                        password
                        component={TextFieldStyled}
                    />
                    <Filler/>
                  </FormWindow>
                </form>
            )}
        />
      </Wrapper>
  )
}

NewPasswordComponent.propTypes = {
  loading: PropTypes.bool.isRequired,
  onButtonClick: PropTypes.func.isRequired,
  onSubmit: PropTypes.func.isRequired,
  onIconClick: PropTypes.func.isRequired,
}

const enhance = compose(
  connect(state => ({ loading: state.loading.setNewPassword }), { setNewPassword }),
  mapProps(props => ({
    ...props,
    onButtonClick: () => externalFormSubmit('new_password'),
    onIconClick: () => history.push('/auth'),
    onSubmit: values => props.setNewPassword({
      values: {
        ...values,
        token: queryString.parse(props.location.search).token,
      },
      type: queryString.parse(props.location.search).type,
    }),
  })),
)

export const NewPassword = enhance(NewPasswordComponent)

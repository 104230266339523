import React, { useRef, useState } from 'react'
import { Paper } from 'common/UiKit/Paper'
import { Colors } from 'constants/colors'
import Button from '@mui/material/Button'
import { FormattedMessage } from 'react-intl'
import styled from 'styled-components'
import PermIdentityIcon from '@mui/icons-material/PermIdentity';
import DeleteOutlineIcon from '@mui/icons-material//DeleteOutline';
import CloseIcon from '@mui/icons-material/Close'
import get from 'lodash/get';
import { uploadSingleFileIdentify } from '../../../../../../helpers/attachments';
import {UPLOAD_FILE_MAX_SIZE} from "../../../../../../constants/uploads";
import CircularProgress from '@mui/material/CircularProgress';

const Input = styled.input`
  display: none;
`

const List = styled.div`
  display: flex;
  flex-flow: row wrap;
`

const StyledSpan = styled.span`
  margin-top : auto;
  margin-bottom : auto;
  margin-left : 12px;
  margin-right : 5px;
`

const StyledButton = styled(Button)`
    && {
    background-color:white ;
    min-width: 165px;
    height: 40px;
    font-weight: normal;
    margin: 5px 0 0 10px;
    color: ${Colors.bluePurple};
    text-transform: none;
    cursor: pointer;
    text-align: center;
    border: solid 1px ${Colors.bluePurple};
    &:hover{
    background-color: ${Colors.bluePurple};
    color: white;
  }
`


const FileWrapper = styled.div`
  display: grid;
  grid-template-columns: 10% 83% 7%;
  padding: 12px 10px;
  border-radius: 4px;
  background-color: ${Colors.lightGrey};
  font-weight: 500;
  color: ${Colors.dark60};

  font-size: 14px;
`

const CloseWrapper = styled.div`
  width: 40px;
  color: ${Colors.red};
  svg {
    cursor: pointer;
    height: 22px;
  }
`

const ErrorStyled = styled.div`
  color: red;
  font-size: 14px;
  margin-bottom : 14px;
`

const loadFilesSequential = async (files, setProgress) => Promise.all(files.map(file => uploadSingleFileIdentify({ file }, setProgress)
  .then(downloaded => get(downloaded, 'data.data.attributes', {}))))

const convertToMB = (bytes = 0) => bytes / 1024 / 1024;

export const AttachmentsFieldIdentify = ({ input, onlyAttachmentId, accept }) => {
  const [bigFileInitial, setBigFileInitial] = useState(false);
  const inputRef = useRef(null)
  const [progress, setProgress] = useState()

  const onButtonClick = () => {
    if (inputRef.current) {
      inputRef.current.click()
    }
  }

  const proxiedConditionalChange = async (e, existing = []) => {
    setBigFileInitial(false);
    if (onlyAttachmentId) {
      const files = get(e, 'target.files', {});
      const arrayFiles = Object.keys(files).map(file => files[file]);
      const isBig = arrayFiles.some(el => Math.ceil(convertToMB(el.size)) + 0.1 > UPLOAD_FILE_MAX_SIZE);
      const sizedFiles = arrayFiles
        .filter(el => el.size && el.size && el.size > 0 && Math.ceil(convertToMB(el.size)) + 0.1 <= UPLOAD_FILE_MAX_SIZE);
      if (isBig) {
        setBigFileInitial(true);
      }
      try {
        const filesIds = await loadFilesSequential(sizedFiles, setProgress);
        return input.onChange([...existing, ...filesIds]);
      } catch (e) {
        console.warn(e);
      }
    } 
    return input.onChange([...input.value, ...e.target.files])
  } 

  const onDelete = (id) => {
    input.onChange(input.value.filter(item => item.id !== id));
    if (inputRef.current) {
      inputRef.current.value = '';
    }
  }
  const currentValue = get(input, 'value', []);
  const someFileBig = currentValue && Array.isArray(currentValue) 
    ? currentValue.some(el => Math.ceil(convertToMB(el.size)) + 0.1 > UPLOAD_FILE_MAX_SIZE) : false;
  // TOTAL FILE CHECK SIZE
  // const totalSize = currentValue && Array.isArray(currentValue) ? currentValue.reduce((accum, el) => el.size + accum, 0) : 0;
  // const totalSizeMB = convertToMB(totalSize);
  const showAddButton = !someFileBig && get(input, 'value.length', 0) < 5;

  return (
    <>
      {bigFileInitial ? (
          <ErrorStyled>
            <FormattedMessage id='error.only.n.size.allowed2' />
          </ErrorStyled>
      ) : ''}
      {someFileBig ? (
          <ErrorStyled>
            <FormattedMessage id='error.only.n.size.allowed' />
          </ErrorStyled>
      ) : ''}
      {input.value && input.value.length > 5 ? (
              <ErrorStyled>
                <FormattedMessage id='error.only.n.files.allowed' />
              </ErrorStyled>
          )
          : ''}
      {input.value && input.value.length ? (
        <List>
          {
            input.value.map(item => (
              <FileWrapper key={`${item.name}-${item.size}-${item.id}`}>
                <PermIdentityIcon />
                <StyledSpan>
                  {item.name}
                  {' '}
                  ({convertToMB(item.size).toFixed(2)}MB)
                </StyledSpan>
                <CloseWrapper>
                  <DeleteOutlineIcon  onClick={() => onDelete(item.id)} />
                </CloseWrapper>
              </FileWrapper>
            ))
          }
        </List>
      ) : null}
      {progress && progress != 100 ? <CircularProgress variant="determinate" value={progress} style={{display: 'flex'}}/> : null}
      {showAddButton ? (
        <StyledButton size='small' color='primary' onClick={onButtonClick}>
          <FormattedMessage id='profile.add.identity' />
        </StyledButton>
      ) : ''}
      <Input 
        type='file'
        accept={".jpg, .jpeg, .gif, .png, .pdf"}
        multiple
        ref={inputRef}
        onChange={e => proxiedConditionalChange(e, input.value)}
      />
    </>
  )
}

import React, { useEffect } from 'react'
import styled from 'styled-components'
import { Colors } from 'constants/colors'
import Paper from '@mui/material/Paper'
import CardContent from '@mui/material/CardContent'
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline'
import Button from '@mui/material/Button'
import { withRouter } from 'react-router-dom'
import queryString from 'query-string'
import axios from 'axios'
import { history } from '../../utils/history'

const TEXTS = {
  en: {
    button: 'Continue',
    title: 'Thank you, your review is accepted!',
  },
  ru: {
    button: 'Продолжить',
    title: 'Спасибо, Ваш отзыв принят!',
  },
  fr: {
    button: 'Continuez',
    title: 'Merci, votre avis est accepté!',
  },
  de: {
    button: 'Weiter',
    title: 'TVielen Dank, Ihre Bewertung wird akzeptiert!',
  },
}

export const HEALTH_RECORDS_TEXTS = {
  en: {
    button: 'Continue',
    title: 'You have allowed access to your health records',
  },
  ru: {
    button: 'Продолжить',
    title: 'Вы разрешили доступ к вашей медицинской карте',
  },
  fr: {
    button: 'Continuez',
    title: 'Vous avez autorisé l`accès à votre dossier médical',
  },
  de: {
    button: 'Weiter',
    title: 'Sie haben Zugriff auf Ihre Gesundheitsakten gewährt',
  },
}

export const SuccessScreen = withRouter(({ location: { search }, texts = TEXTS }) => {
  const params = queryString.parse(search)

  useEffect(() => {
    if (params.token) {
      axios.post(`/general/consultation/allowAccess/patient?token=${params.token}`)
    }
  }, [params.token])

  return (
    <Wrapper>
      <Paper>
        <CardContent>
          <Success>
            <CheckCircleOutlineIcon />
          </Success>
          <Text>
            {(texts[params.lang] || texts.en).title}
          </Text>
          <Button
            color='primary'
            variant='contained'
            size='small'
            fullWidth
            onClick={() => history.push('/')}
          >
            {(texts[params.lang] || texts.en).button}
          </Button>
        </CardContent>
      </Paper>
    </Wrapper>
  )
})

const Wrapper = styled.div`
  height: 100vh;
  width: 100vw;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: ${Colors.background};
  text-align: center;
  
  & > div {
    width: 360px;
  }
`

const Success = styled.div`
  display: flex;
  justify-content: center;
  
  svg {
    width: 60px;
    height: 60px;
    fill: ${Colors.clearBlue};
    margin-bottom: 24px;
  }
`

const Text = styled.div`
  text-align: center;
  font-size: 24px;
  font-weight: 700;
  margin-bottom: 32px;
`

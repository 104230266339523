import React from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import styled from 'styled-components'
import { AuthDialog } from '../AuthDialog'
import { history } from '../../../utils/history';
import Button from '@mui/material/Button';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import people_couple from "../../../images/auth/people_couple.svg";
import doctors_couple from "../../../images/auth/doctors_couple.svg";
import PersonAddIcon from "@mui/icons-material/PersonAdd";
import axios from "axios";
import {getQueryString} from "../../../helpers/querystring";


export const UserTypeSelect = () => {
  const { formatMessage } = useIntl()
  const f = id => formatMessage({ id })

  return (
    <WrapperUserSelect>
      <StyledHeadField>
        <FormattedMessage id='auth.welcome.question' />
      </StyledHeadField>
      <WrapperButton>
          <Card onClick={() => history.push("/auth/doctor")}>
            <IconBig src={doctors_couple} />
            <TextStyle><FormattedMessage id='auth.doctor' /></TextStyle>
          </Card>
          <Card onClick={() => history.push("/auth/patient")}>
            <IconBig src={people_couple} />
            <TextStyle><FormattedMessage id='auth.patient' /></TextStyle>
          </Card>
      </WrapperButton>
    </WrapperUserSelect>
  )
}

const WrapperUserSelect = styled.div`
  display: flex;
  flex-direction : column;
  max-height : 500px !important;
`

const Card = styled.div`
    display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  font-family: Roboto, sans-serif;
  font-size: 16px;
  margin-top: 30px;
  margin-bottom: auto;
  margin-right: 60px;
  padding:  24px;
  border-radius: 8px;
  box-shadow: rgba(0, 0, 0, 0.16) 0px 1px 4px;
  background-color: white;
  cursor: pointer;
  height: 180px;
  width: 200px;
  border: 1px solid transparent;
    &:hover {
      & > * {
        color: #6633FF;
        font-weight: 600;
        }
      box-shadow: rgba(102, 51, 255, 0.7) 0px 2px 4px;
      border: 1px solid #6633FF;
  }
`

const IconBig = styled.img`
  height: 200px;
`

const TextStyle = styled.h4`
    font-family: Roboto, sans-serif;
    font-size: 19px;
    font-weight: 450;
    color: black;
    text-align: center;
`


const StyledHeadField = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    font-family: Roboto;
    font-size : 1.5em;
    color: #7D7E7F;
    font-weight: 400;
    margin-top: 30px;
    margin-bottom: 30px;
`


const WrapperButton = styled.div`
    display: flex;
    justify-content: space-around;
    align-items: center;
`


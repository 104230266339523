import { compose, withProps, lifecycle } from 'recompose'
import { connect } from 'react-redux'
import idx from 'idx'
import { MedicalReport as MedicalReportComponent } from 'common/UiKit/MedicalReport'
import { history } from '../../../../../../utils/history'
import { checkMedicineCompabilityWithProfile, clearCheckedMedicines } from '../../../../../../redux/modules/medicines'
import { getMainReport } from '../../../../../../helpers/consultations';
import { fetchConsultation } from '../../../../../../redux/modules/consultations'

const enhance = compose(
  connect(
    state => ({
      consultation: idx(state, _ => _.consultations.consultation),
      loaded: !!idx(state, _ => _.consultations.consultation.relationships.drugs.data.length),
      downloadLink: idx(state, (_) => {
        const report = getMainReport(_)
        return report ? report.attributes.pdf.prescription : ''
      }),
      checkedMedicinesWithProfile: idx(state, _ => _.medicines.checkedWithProfile),
      loading: state.loading.medicinesCompability,
    }),
    {
      clearCheckedMedicines,
      onCheckCompabilityButtonClick: checkMedicineCompabilityWithProfile,
      fetchConsultation,
    },
  ),
  lifecycle({
    componentDidMount() {
      if (!this.props.loaded) {
        this.props.fetchConsultation(null, null, true)
      }
    },
    componentWillUnmount() {
      this.props.clearCheckedMedicines()
    },
  }),
  withProps(props => ({
    onCloseIconClick: () => history.push('/panel/consultations/session'),
    onDownloadIconClick: () => window.open(props.downloadLink),
    isPatient: true,
    isPrescription: true,
  })),
)

export const Prescription = enhance(MedicalReportComponent)

export const LANGUAGES = [
  { label: 'English', value: 'en' },
  { label: 'Deutsch', value: 'de' },
  // { label: 'Русский', value: 'ru' },
  { label: 'Français', value: 'fr' },
]

export const USER_LANGUAGES = [
  { label: 'English', value: 'en' },
  { label: 'Deutsch', value: 'de' },
  // { label: 'Русский', value: 'ru' },
  { label: 'Français', value: 'fr' },
  { label: 'Español', value: 'es' },
  { label: 'Arabe', value: 'ar' },
]

import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux'
import styled from 'styled-components'
import Avatar from '@mui/material/Avatar'
import { FormattedMessage } from 'react-intl'
import { Colors, LEVEL_COLORS, yellowMember } from 'constants/colors'

import { fetchStatus } from '../../../../redux/modules/status'
import { Page } from '../../../common/Page'
import { StatusMission } from './Mission'
import { MissionModal } from './MissionModal'
import { StatusCodes } from './Codes'
import defaultAvatar from '../../../../images/panel/default-avatar.svg'

export const Status = () => {
  const dispatch = useDispatch()
  const me = useSelector(state => state.auth.attributes)
  const missions = useSelector(state => state.status.missions.required)
  const optional = useSelector(state => state.status.missions.optional)
  const referral = useSelector(state => state.status.missions.referral)
  const userLevel = useSelector(state => (state.auth.type === 'Doctor' ? state.auth.attributes.level : undefined))
  const isApproved = me.status === 'VERIFIED'

  const [missionModal, toggleModal] = useState(null)

  useEffect(() => {
    dispatch(fetchStatus())
  }, [])

  return (
    <Page label='status' white fullWidth withoutOverflow>
      <Content>
        <Left>
          <Plate>
            <Flex>
              <Photo src={me.photo || defaultAvatar} level={userLevel} />
              <div>
                <Name>
                  {me.first_name}
                  {' '}
                  {me.last_name}
                </Name>
                <Member level={userLevel}>
                  <FormattedMessage id={`status.${userLevel}`} />
                </Member>
                {
                  referral && (
                    <Sponsor>
                      <FormattedMessage id='status.sponsored' />
                      {' '}
                      {referral}
                    </Sponsor>
                  )
                }
              </div>
            </Flex>
          </Plate>
          <TitlePlate>
            <div>
              <FormattedMessage id='status.advantages' />
              :
            </div>
            <Text>
              <FormattedMessage id={`advantages.${userLevel}`} />
            </Text>
          </TitlePlate>
          <TitlePlate>
            <div>
              <FormattedMessage id='status.missions.membre' />
              <FormattedMessage id={`status.${userLevel}`} />
              :
            </div>
            <div>
              {(missions || []).map(item => (
                <StatusMission key={item.mission} data={item} onClick={toggleModal} />
              ))}
            </div>
          </TitlePlate>
          {
            (optional && optional.length) && (
              <TitlePlate>
                <div>
                  <FormattedMessage id='status.missions.optional' />
                  :
                </div>
                <div>
                  {optional.map(item => (
                    <StatusMission key={item.mission} data={item} onClick={toggleModal} />
                  ))}
                </div>
              </TitlePlate>
            )
          }
        </Left>
        {
          isApproved ? (
            <StatusCodes />
          ) : <div />
        }
      </Content>
      <MissionModal data={missionModal} onClose={() => toggleModal(null)} />
    </Page>
  )
}

const Content = styled.div`
  display: flex;
  min-height: calc(100vh - 24px - 24px - 56px);
  
  & > div:first-of-type {
    width: 60%;
  }
  
  & > div:last-of-type {
    width: 40%;
  }
`

const Plate = styled.div`
  border-radius: 8px;
  box-shadow: 0 4px 8px 0 rgba(6, 173, 255, 0.2);
  background-color: #ffffff;
  margin: 12px 0;
  padding: 18px 24px;
  width: 100%;
  box-sizing: border-box;
  position: relative;
`

const Photo = styled(Avatar)`
  && {
    width: 72px;
    height: 72px;
    border: 4px solid ${({ level }) => (level ? LEVEL_COLORS[level] : yellowMember)};
    margin-right: 24px;
  }
`

const Name = styled.div`
  font-size: 24px;
  line-height: 28px;
  font-weight: 500;
`

const Flex = styled.div`
  display: flex;
  align-items: center;
`

const Member = styled.div`
  padding: 8px 32px;
  background-color: ${({ level }) => (level ? LEVEL_COLORS[level] : yellowMember)};
  border-radius: 20px;
  font-weight: 500;
  text-align: center;
  display: inline-block;
  margin-top: 10px;
  font-size: 16px;
`

const Left = styled.div`
  padding: 12px 64px;
`

export const TitlePlate = styled(Plate)`
  padding: 16px 24px;
  
  & > div:first-child {
    border-bottom: 1px solid rgba(197,203,219,0.2);
    margin: 0 -24px;
    padding: 0 24px 14px;
    font-weight: bold;
  }
  
  & > div:nth-child(2) {
    padding-top: 12px;
    font-size: 16px;
    
    & > div:not(:first-of-type) {
      margin-top: 16px;
    }
  }
`

const Text = styled.div`
  opacity: 0.6;
`

const Sponsor = styled.small`
  position: absolute;
  bottom: 16px;
  right: 16px;
  color: ${Colors.blueGrey};
`

import { createAction, handleActions } from 'redux-actions'
import get from 'lodash/get';
import axios from 'axios'

const arrayToObjectSet = settings => settings.reduce((accum, el) => {
  const toNewAccum = { ...accum };
  const key = get(el, 'attributes.key', false);
  const value = get(el, 'attributes.value', false);
  if (!key || !value) {
    return toNewAccum;
  }
  toNewAccum[key] = value;
  return toNewAccum;
}, {})

const defaultState = {
  notifications: { time: 'none', consultations: false, sound: false },
  consultationSearch: '',
  doctorSearch: '',
  language: 'English',
  values: {
    enabled: false,
    reminderTime: 0,
    web_incoming_calls_sound_enabled: 1,
  },
}

//#region Actions
export const changeNotificationsSettings = createAction('CHANGE_NOTIFICATIONS_SETTINGS')
export const changeLanguageSettings = createAction('CHANGE_LANGUAGE_SETTINGS')

export const fetchSettingsRequest = createAction('FETCH_SETTINGS_REQUEST')
export const fetchSettingsSuccess = createAction('FETCH_SETTINGS_SUCCESS')
export const fetchSettingsFailure = createAction('FETCH_SETTINGS_FAILURE')

export const updateSettingsRequest = createAction('UPDATE_SETTINGS_REQUEST')
export const updateSettingsSuccess = createAction('UPDATE_SETTINGS_SUCCESS')
export const updateSettingsFailure = createAction('UPDATE_SETTINGS_FAILURE')

export const searchConsultations = createAction('SEARCH_CONSULTATIONS')
export const searchDoctors = createAction('SEARCH_DOCTORS')
export const createTeamRequest = createAction('CREATE_TEAM_REQUEST');

export const changeMedicalSoftwareRequest = createAction('CHANGE_MEDICAL_SOFTWARE_REQUEST')
export const changeMedicalSoftwareSuccess = createAction('CHANGE_MEDICAL_SOFTWARE_SUCCESS')
export const changeMedicalSoftwareFailure = createAction('CHANGE_MEDICAL_SOFTWARE_FAILURE')


export const activateOlaqinRequest = createAction('ACTIVATE_OLAQIN_REQUEST')
export const activateOlaqinSuccess = createAction('ACTIVATE_OLAQIN_SUCCESS')
export const activateOlaqinFailure = createAction('ACTIVATE_OLAQIN_FAILURE')

//#endregion

//#region Reducers
export const settings = handleActions({
  [changeNotificationsSettings]: (state, action) => ({ ...state, notifications: action.payload }),
  [changeLanguageSettings]: (state, action) => ({ ...state, language: action.payload }),
  [fetchSettingsSuccess]: (state, action) => ({ ...state, values: action.payload }),
  [updateSettingsSuccess]: (state, action) => ({ ...state, values: action.payload }),
  [searchConsultations]: (state, action) => ({ ...state, consultationSearch: action.payload }),
  [searchDoctors]: (state, action) => ({ ...state, doctorSearch: action.payload }),
}, defaultState)
//#endregion

//#region Thunks
export const fetchSettings = () => (dispatch) => {
  dispatch(fetchSettingsRequest())

  return axios('/user/settings')
    .then(response => dispatch(fetchSettingsSuccess(arrayToObjectSet(response.data.data))))
    .catch(e => dispatch(fetchSettingsFailure(e)))
}

export const updateSettings = values => (dispatch) => {
  dispatch(updateSettingsRequest())
  const data = new FormData()
  data.append('notifications_reminder_time', Number(values.notifications_reminder_time))
  data.append('notifications_reminder_time_tc', Number(values.notifications_reminder_time_tc))
  data.append('mail_opinion_te', Number(values.mail_opinion_te))
  data.append('sms_opinion_te', Number(values.sms_opinion_te))
  data.append('mail_message_received_te', Number(values.mail_message_received_te))
  data.append('sms_message_received_te', Number(values.sms_message_received_te))
  data.append('sms_message_received_te', Number(values.sms_message_received_te))
  data.append('mail_cancelled_te', Number(values.mail_cancelled_te))
  data.append('sms_cancelled_te', Number(values.sms_cancelled_te))
  data.append('mail_accepted_ter', Number(values.mail_accepted_ter))
  data.append('sms_accepted_ter', Number(values.sms_accepted_ter))
  data.append('mail_refused_ter', Number(values.mail_refused_ter))
  data.append('sms_refused_ter', Number(values.sms_refused_ter))
  data.append('sms_completed_te', Number(values.sms_completed_te))
  data.append('mail_completed_te', Number(values.mail_completed_te))
  data.append('mail_not_finished_te', Number(values.mail_not_finished_te))
  data.append('sms_not_finished_te', Number(values.sms_not_finished_te))
  data.append('mail_request_tc', Number(values.mail_request_tc))
  data.append('sms_request_tc', Number(values.sms_request_tc))
  data.append('mail_cancelled_tc', Number(values.mail_cancelled_tc))
  data.append('sms_cancelled_tc', Number(values.sms_cancelled_tc))
  data.append('mail_ess_essp', Number(values.mail_ess_essp))
  data.append('sms_ess_essp', Number(values.sms_ess_essp))
  data.append('activate_olaqin', Number(values.activate_olaqin))

  return axios.post('/user/settings', data)
    .then(response => dispatch(updateSettingsSuccess(response.data.data.attributes)))
    .catch(e => dispatch(updateSettingsFailure(e)))
}

export const updateFormatMedicalReport = values => (dispatch) => {
  dispatch(updateSettingsRequest())
  const data = new FormData()

  data.append('notifications_enabled', Number(values.notifications_enabled))
  data.append('notifications_reminder_time', Number(values.notifications_reminder_time))
  data.append('web_incoming_calls_sound_enabled', Number(values.web_incoming_calls_sound_enabled))

  return axios.post('/user/settings', data)
      .then(response => dispatch(updateSettingsSuccess(response.data.data.attributes)))
      .catch(e => dispatch(updateSettingsFailure(e)))
}

export const updateExternalPartners = values => (dispatch) => {
  dispatch(updateSettingsRequest())
  const data = new FormData()

  data.append('activate_olaqin', Number(values.activate_olaqin))

  return axios.post('/user/settings', data)
      .then(response => dispatch(updateSettingsSuccess(response.data.data.attributes)))
      .catch(e => dispatch(updateSettingsFailure(e)))
}

export const changeMedicalSoftwareSetting = (value) => dispatch => {
  dispatch(changeMedicalSoftwareRequest);
  return axios('/api/user/setting/software', {
    method: 'POST',
    data: {
      medicalSoftwareId: value
    }
  })
    .then(() =>{
      dispatch(changeMedicalSoftwareSuccess)
      dispatch(fetchSettings())
  })
    .catch((err) => {
      dispatch(changeMedicalSoftwareFailure(err))
    })
}
//#endregion

import React from 'react'
import styled from "styled-components";
import {Colors, STATUS_COLORS} from "../../../../../../constants/colors";
import {DistanceDisplay} from "../../../../../common/DistanceDisplay";
import {DefaultAvatar} from "../../../../../common/DefaultAvatar";
import {SecuredBckgImage} from "../../../../../common/SecuredImage";
import idx from "idx";
import {useIntl} from "react-intl";

export const DoctorTooltipComponent = ({doctor}) => {
  const { formatMessage } = useIntl()
  const f = id => formatMessage({ id })

  const distance = idx(doctor, _ => _.attributes.distance);
  const photo = idx(doctor, _ => _.attributes?.photo);
  const status_availability = idx(doctor, _ => _.attributes?.status_availability);
  const first_name = idx(doctor, _ => _.attributes?.first_name);
  const last_name = idx(doctor, _ => _.attributes?.last_name);
  const specialization_type = idx(doctor, _ => _.attributes?.specialization_type);
  const specialization = idx(doctor, _ => _.attributes?.specialization);
  const city = idx(doctor, _ => _.attributes?.workplace_city);
  const code_postal = idx(doctor, _ => _.attributes?.workplace_code_postal);
  const genderCode = idx(doctor, _ => _.attributes?.gender);
  const gender = genderCode ? f('profile.genders.' + genderCode) : '';

  return (
      <>
        <Wrapper>
          <AvatarWrapper>
            {
              photo ? (
                  <Avatar
                    spinner={{width: '30px', height: '30px'}}
                    src={photo.includes('/file/') ? `http://backend-application.owndoctor.eu${photo}` : photo}
                    status={status_availability}
                  />
                ) :
                (<Default
                    spinner={{width: '30px', height: '30px'}}
                    style={{backgroundImage: `url(${DefaultAvatar(first_name, last_name, specialization_type)})`}}
                    status={status_availability}
                  />
                )
            }
          </AvatarWrapper>
          <Info>
            <Name>
              {gender}&nbsp;{first_name}&nbsp;{last_name}
            </Name>
            <Specialization>
              {specialization}
            </Specialization>
            <City>
              {city}&nbsp;({code_postal})
            </City>
          </Info>
          <LanguageWrapper>
            <DistanceDisplay distance={distance}/>
          </LanguageWrapper>
        </Wrapper>
      </>
  )
}

const Wrapper = styled.div`
  display: flex;
  font-weight: normal;
  font-size: 16px;
  align-items: center;
`

const LanguageWrapper = styled.div`
  top: 16px;
  right: 16px;
  text-align: center;
  display: flex;
  align-items: center;
  color: #b7bed2;
`


const Avatar = styled(SecuredBckgImage)`
  min-width: 50px;
  width: 50px;
  height: 50px;
  border-radius: 100%;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  display: flex;
  align-items: center;
  justify-content: center;
  box-shadow: 0 4px 8px 0 rgba(6, 173, 255, 0.1);
  &:after {
    display: inline-block;
    position : relative;
    content: "oo";
    border: 2px solid white;
    background-color: ${({ status }) => (status) ? STATUS_COLORS["available"] : STATUS_COLORS["notavailable"]};
    color: transparent;
    border-radius: 50%;
    width: 15px;
    height: 15px;
    top: 15px;
    left: 15px;
  }
`

const Default = styled.div`
  min-width: 50px;
  width: 50px;
  height: 50px;
  border-radius: 100%;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  box-shadow: 0 4px 8px 0 rgba(6, 173, 255, 0.1);
  &:after {
    display: inline-block;
    position : relative;
    content: "oo";
    border: 2px solid white;
    background-color: ${({ status }) => (status) ? STATUS_COLORS["available"] : STATUS_COLORS["notavailable"]};
    color: transparent;
    border-radius: 50%;
    width: 15px;
    height: 15px;
    top: 30px;
    left: 30px;
  }
`

const AvatarWrapper = styled.div`
  & > p {
    margin: 16px 0 0;
    color: ${Colors.clearBlue};
    font-size: 24px;
    font-weight: bold;
    text-align: center;
  }
`

const Info = styled.div`
  flex-grow: 1;
  margin: 0 16px 0;
`

const Name = styled.div`
  font-weight: bold;
  word-break: break-word;
  text-overflow: ellipsis;
  max-width: 230px;
  white-space: nowrap;
  overflow: hidden;
`

const Specialization = styled.div`
  font-size: 14px;
  color: ${Colors.dark70};
  font-weight: 400;
  word-break: break-word;
  text-overflow: ellipsis;
  max-width: 230px;
  white-space: nowrap;
  overflow: hidden;
`

const City = styled.div`
  color: ${Colors.blueGrey};
  word-break: break-word;
  text-overflow: ellipsis;
`
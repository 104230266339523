import React, {useEffect, useState} from 'react';
import PropTypes from 'prop-types'
import idx from 'idx'
import { compose, withProps, lifecycle } from 'recompose'
import {connect, useDispatch} from 'react-redux'
import styled from 'styled-components'
import { EditPage } from 'common/UiKit/EditPage'
import { FormattedMessage } from 'react-intl'
import get from 'lodash/get';
import { AppointmentConsultationForm } from '../../../../common/AppointmentConsultationForm';
import { externalFormSubmit } from '../../../../../helpers/externalFormSubmit'
import { history } from '../../../../../utils/history'
import { DoctorInfo } from './DoctorInfo'
import { fetchConsultationsQuestions } from '../../../../../redux/modules/content'
import { setNotification } from '../../../../../redux/modules/notifications'
import { doctorIsRecieved } from '../../../../../helpers/doctors'
import { PatientCalendar } from './Calendar'
import { getDoctorPrice } from '../../../../../helpers/consultations'
import { AccessToggle } from '../AccessToggle';
import {fetchDoctor} from "../../../../../redux/modules/doctors";
import {CircularProgress} from "@mui/material";
import {createAppointmentConsultation} from "../../../../../redux/modules/consultations";

const Content = styled.div`
  div div div div div > div:nth-child(2) {
    display: block;
  }
`

const AppointmentConsultationComponent = ({
  onButtonClick,
  onBackIconClick,
  onSubmit,
  afterSubmit,
  loading,
  doctor,
  workPlace,
  questions,
  onAccessError,
}) => {
  const [modal, toggleModal] = useState(false)
  const [access, toggleAccess] = useState(false)
  const [typeOfPrice, setTypeOfPrice] = useState('daily_price');

  return (
    <EditPage
      label='consultation.by.appointment'
      buttonText='consultation.verify'
      onBackIconClick={onBackIconClick}
      onButtonClick={onButtonClick}
      loading={loading}
      background
      pageEdited='newTC'
    >
      <Content>
        <DoctorInfo
          firstName={doctor.first_name}
          lastName={doctor.last_name}
          experience={doctor.experience}
          specialization={doctor.specialization}
          photo={doctor.photo}
          language={doctor.language}
          location={workPlace}
          teamName={doctor.team_name}
          doctorPrices={doctor.price}
          currentPriceType={typeOfPrice}
          specialization_type={doctor.specialization_type}
          /* TODO: add attributes to doctor in API */
          price={getDoctorPrice(doctor, typeOfPrice)}
          available={get(doctor, 'calendar_nearest.TC', null)}
        />
      </Content>
      <AppointmentConsultationForm
        onSubmit={values => onSubmit({ ...values, accessHealthRecord: access })}
        afterSubmit={afterSubmit}
        id='appointment-consultation'
        questions={questions}
        onAccessError={onAccessError}
        TimeField={PatientCalendar}
        setTypeOfPrice={setTypeOfPrice}
        available={get(doctor, 'calendar_nearest.TC', null)}
      />
      <AccessToggle
        isOpened={modal}
        toggle={toggleModal}
        access={access}
        toggleAccess={toggleAccess}
        onClick={() => {
          toggleModal(false)
          onButtonClick()
        }}
      />
    </EditPage>
  )
}

AppointmentConsultationComponent.defaultProps = {
  workPlace: {},
  questions: [],
}

AppointmentConsultationComponent.propTypes = {
  onButtonClick: PropTypes.func.isRequired,
  onBackIconClick: PropTypes.func.isRequired,
  onSubmit: PropTypes.func.isRequired,
  loading: PropTypes.bool.isRequired,
  doctor: PropTypes.object.isRequired,
  workPlace: PropTypes.object,
  questions: PropTypes.arrayOf(PropTypes.object),
  onAccessError: PropTypes.func.isRequired,
}

const enhance = compose(
  doctorIsRecieved,
  connect(
    state => ({
      loading: state.loading.createConsultation,
      doctor: idx(state, _ => _.doctors.doctor.attributes),
      questions: idx(state, _ => _.content.consultationQuestions),
      workPlace: idx(
        state,
        _ => _.doctors.doctor.relationships.workPlace.data.slice(-1)[0].attributes,
      ),
    }),
    dispatch => ({
      createAppointmentConsultation: v => dispatch(createAppointmentConsultation(v)),
      fetchConsultationsQuestions: () => dispatch(fetchConsultationsQuestions()),
      onAccessError: () => dispatch(setNotification(<FormattedMessage id='error.policy' />)),
    }),
  ),
  withProps(props => ({
    onButtonClick: () => externalFormSubmit('appointment-consultation'),
    onBackIconClick: () => history.goBack(),
    onSubmit: values => props.createAppointmentConsultation(values),
    afterSubmit: () => history.push('/panel/consultations'),
    // onSubmit: (values) => history.push({
    //   pathname: "/panel/doctors/appointment/payment",
    //   state: {
    //     consultationValues: values
    //   }
    // })
  })),
  lifecycle({
    componentDidMount() {
      this.props.fetchConsultationsQuestions()
    },
  }),
)

export const AppointmentConsultation = enhance(AppointmentConsultationComponent)

// Charge le docteur avec l'ID présent dans la route
// et redirige vers le composant de création d'une téléconsultation
export const NewAppointmentConsultation = ({ match }) => {
  const dispatch = useDispatch()

  useEffect(() => {
    if (match?.params?.doctorId) {
      dispatch(fetchDoctor(match.params.doctorId)).then(() => history.push('/panel/doctors/appointment/create'))
    }
  }, [dispatch])

  return (
    <CircularProgress />
  )
}
import React, { useState, useEffect } from 'react';
import { CreatableAsyncSelectField } from 'common/AsyncSelect/CreatableAsyncSelect';
import CancelIcon from '@mui/icons-material/Cancel';
import styled from 'styled-components';
import get from 'lodash/get';
import uniq from 'lodash/uniq';
import uniqBy from 'lodash/uniqBy';

const Wrapper = styled.div`
  maring: 0;
`

const TagItem = styled.div`
  background-color: #63f;
  color: #fff;
  padding: 7px;
  border-radius: 5px;
  margin: 10px;
  margin-top: 5px;
  margin-bottom: 5px;
  margin-left: 0;
  word-break: inherit;
  display: flex;
  align-items: center;
`

const TagsList = styled.div`
  margin-top: 5px;
  display: flex;
  flex-wrap: wrap;
  min-height: 53px;
`

const DeleteIcon = styled(CancelIcon)`
  margin-left: 5px;
  cursor: pointer;
`

export const TagMultipleInfinityAsyncSelect = ({
  val = [], notCreateNewImmidiate, toSave = 'value', toDelete = 'value',
  input = {}, defaultInputValues, createNew, maxValues, defaultFullValues = [], wrapp, ...props
}) => {
  const [currentValue, setCurrentValue] = useState('');
  const [fullValues, setFullValues] = useState([]);
  useEffect(() => {
    setFullValues(defaultFullValues);
  }, [defaultFullValues])

  const changeValue = ({ value }) => {
    if (value && value.value) {
      setCurrentValue('');
      if (value.label__isNew__ && !notCreateNewImmidiate) { // eslint-disable-line no-underscore-dangle
        createNew(value.value)
          .then((result) => {
            const newId = get(result, 'data.data.attributes.id');

            if (newId) {
              input.onChange(uniq([...val, newId]));
            }
          })
          .catch(err => console.warn(err))
      } else {
        const save = value[toSave] ? value[toSave] : value.value;
        input.onChange(uniq([...val, save]));
      }
      setFullValues(uniqBy([...fullValues, value], 'value'));
    }
  }

  const deleteValue = (value) => {
    input.onChange(val.filter(el => el !== value[toDelete]));
    setFullValues(state => state.filter(el => el.value !== value.value));
  }

  return (
    <Wrapper>
      <CreatableAsyncSelectField 
        type='text'
        {...props}
        isValidNewOption={value => value && value.length && value.length <= 50}
        isDisabled={val && val.length && maxValues && val.length >= maxValues + 1}
        input={{
          ...input,
          value: currentValue,
          onChange: (id, text) => changeValue(id, text),
        }}
      />
      <TagsList>
        {fullValues.map(el => (
          <TagItem key={el.label}>
            {el.label} <DeleteIcon onClick={() => deleteValue(el)} />
          </TagItem>
        ))}
      </TagsList>
    </Wrapper>
  )
}

import React, { useState, useEffect, useRef } from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import { Colors } from '../../../../constants/colors'
import moment from 'moment-timezone'
import { FormattedMessage } from 'react-intl'
import get from 'lodash/get'
import Paper from '@mui/material/Paper';
import MenuItem from '@mui/material/MenuItem';
import MenuList from '@mui/material/MenuList';
import FiberManualRecordIcon from '@mui/icons-material/FiberManualRecord';
import DoneAllIcon from '@mui/icons-material/DoneAll';
import defaultAvatar from '../../../../images/panel/default-avatar.svg'
import { MESSAGE_TYPES } from '../../../../constants/messageTypes'
import { TextMessage } from './Text'
import { InfoMessage } from './Info'
import { FileMessage } from './File'
import { ReportMessage } from './Report'
import { PrescriptionMessage } from './Prescription'
import { CompletePaymentMessage } from './Payments/Complete'
import { SuccessMessage } from './Payments/Success'
import {deleteChatMessage, isConciliumCheck} from '../../../../helpers/consultations';
import {DefaultAvatar} from "../../../common/DefaultAvatar";
import {useSelector} from "react-redux";
import {CONSULTATION_TYPES} from "../../../../constants/consultation";
import {PrescriptionNew} from "./PrescriptionNew";
import idx from 'idx'
import {fullName} from "../../../../helpers/user";
import {SystemMessage} from "./System";
import {Done, DoneAll} from "@mui/icons-material";
import {InlineSpacer} from "../../../common/UiKit/InlineSpacer";

const StatusMessage = styled.div`
  position: absolute;
  right: ${({ read }) => (read ? '-28px' : '-20px')};
  top: 12px;
`

const NotReadDot = styled(FiberManualRecordIcon)`
  color: #21c5a9;
  width: 8px !important;
  height: 8px !important;
`

const ReadCheck = styled(DoneAllIcon)`
  color: rgba(149, 159, 184, 0.4);
  width: 16px !important;
`

const Status = React.memo(({ read }) => (
  <StatusMessage read={read}>
    {read ? <ReadCheck /> : <NotReadDot />}
  </StatusMessage>
))

export const Message = React.memo(({
  messageObject,
  scrollToBottom,
  displaySenderName,
}) => {
  // New hooks to replace component props
  const consultation = useSelector(state => state.consultations.consultation.attributes);

  const chatId = useSelector( state => state.consultations.consultation.id);
  const isDoctor = useSelector(state => state.auth.type === 'Doctor');
  const additionalDoctor = useSelector( state => state.consultations.consultation.relationships?.additionalMember.data[0]);

  //old props now directly declared in the component
  const message = messageObject.attributes.message;
  const messageFile = messageObject.attributes.file || messageObject.attributes.message;
  const time = messageObject.attributes.date;
  const messageType = messageObject.attributes.type;
  const read = messageObject.attributes.read;
  const messageId = messageObject.attributes.id;
  const price = idx(consultation, _ => _.invoice.price)
  const invoice = idx(consultation, _ => _.invoice.invoicePDFv2)
  const isPaymentCompleted = idx(consultation, _ => _.invoice.paid)
  const isConcilium = isConciliumCheck(consultation?.type);
  const isAdditionalDoctor = additionalDoctor
      && messageObject.attributes.sender === idx(additionalDoctor, _ => _.attributes.doctor.id)


  // Check who is the message owner is the additional member, patient and doctor
  const currentUserId = useSelector(state => state.auth.attributes.user_id);
  const my = (currentUserId === messageObject.attributes.sender);

  // Need this information for the Avatar
  const photo = messageObject.attributes.avatar
  const firstname = messageObject.attributes.sender_firstname;
  const lastname = messageObject.attributes.sender_lastname;
  const gender = messageObject.attributes.sender_gender;
  //Undefined if the message owner is a patient
  const spec = messageObject.attributes.sender_specialization_type

  const [showDeleteContextMenu, setShowDeleteContextMenu] = useState(false);
  const proposedDate = moment.utc(consultation?.proposed_date).local().format('YYYY-MM-DD HH:mm:ss');
  const isTC = (consultation?.type === CONSULTATION_TYPES.scheduled || consultation?.type === CONSULTATION_TYPES.urgent);

  /**
   * Affiche le message en fonction de son type
   * @param messageType
   * @returns {JSX.Element|null}
   */
  const renderMessage = (messageType) => {
    switch (messageType) {
      case MESSAGE_TYPES.text:
        return (
            <TextMessage my={my} name={displaySenderName ? fullName(gender, firstname, lastname) : null} message={message} />
        )
      case MESSAGE_TYPES.file:
        return (
            <FileMessage my={my} name={displaySenderName ? fullName(gender, firstname, lastname) : null} message={messageFile} onLoad={scrollToBottom} />
        )
      case MESSAGE_TYPES.prescriptionNew:
        return (
            <PrescriptionNew message={message} time={time} />
        )
      case MESSAGE_TYPES.symptom:
        return (
            <InfoMessage message={message} heading='consultations.symptoms' />
        )
      case MESSAGE_TYPES.question:
        return (
            <InfoMessage questions={message} heading='consultation.questions' />
        )
      case MESSAGE_TYPES.report:
        if (message.medicalReport) {
          return (
              isPaymentCompleted
                  ? (
                      <>
                        {(!isAdditionalDoctor && isDoctor && isTC) && (
                            <SuccessMessage
                                pdf={invoice}
                                price={price}
                            />
                        )}
                        <ReportMessage
                            diagnosis={message.medicalReport.diagnosis}
                            restrictions={message.medicalReport.restrictions}
                            symptoms={message.medicalReport.symptoms}
                            redirectToSpecialization={!!message.medicalReport.redirectToSpecialization}
                            needConsultationWithSpecialist={message
                                .medicalReport.needConsultationWithSpecialist}
                            redirectSpecializationId={message.medicalReport.redirectSpecializationId}
                            medicines={message.drugs ? message.drugs.map(item => item.name) : []}
                            pdf={message.medicalReport.pdf}
                            doctor={message.medicalReport.doctor}
                        />
                      </>
                  )
                  : (
                      <>
                        {
                          (!isDoctor
                              && <CompletePaymentMessage price={price} />
                          )
                        }
                      </>
                  )
          )
        } else {
          return null;
        }
      case MESSAGE_TYPES.prescription:
        if (message.drugs) {
          return (
              <>
                {(isPaymentCompleted || isDoctor || isAdditionalDoctor || isConcilium) ? (
                    <PrescriptionMessage
                        medicines={message.drugs.map(item => item.name)}
                        chatId={chatId}
                    />
                ) : (
                    <TextMessage my={my} system message={(<FormattedMessage id='consultation.medical_report.need_payment_message' />)} />
                )}
              </>
          )
        } else {
          return null;
        }
      case MESSAGE_TYPES.approve:
        return (
            <InfoMessage date={proposedDate} heading='consultation.msg.approved' approve />
        )
      case MESSAGE_TYPES.created:
        return (
            <InfoMessage date={proposedDate} heading='consultation.msg.created' approve />
        )
      case MESSAGE_TYPES.refused:
        return (
          <InfoMessage date={proposedDate} heading='consultation.msg.refused' canceled approve />
        )
      case MESSAGE_TYPES.cancelled:
        return (
            <InfoMessage date={proposedDate} message={get(message, 'message', '')} heading='consultation.canceledv2' canceled approve />
        )
      case MESSAGE_TYPES.system:
        return (
            <SystemMessage message={messageObject} />
        )
      default:
        if (message && message.cancelled) {
          return (
              <InfoMessage date={proposedDate} message={get(message, 'message', '')} heading='consultation.canceledv2' canceled approve />
          )
        } else {
          return null;
        }
    }
  }

  const useOutsideAlerter = (ref) => {
    useEffect(() => {
      const handleClickOutside = (event) => {
        if (ref.current && !ref.current.contains(event.target)) {
          setShowDeleteContextMenu(false);
        }
      }
      document.addEventListener('mousedown', handleClickOutside);
      return () => {
        document.removeEventListener('mousedown', handleClickOutside);
      };
    }, [ref]);
  }

  const wrapperRef = useRef(null);
  useOutsideAlerter(wrapperRef);

  const deleteMessageAction = () => {
    deleteChatMessage(messageId);
    setShowDeleteContextMenu(false);
  }

  return (
    <Wrapper ref={wrapperRef} my={my} messageType={messageType}>
      {
        message !== 'null' && messageType !== MESSAGE_TYPES.report && !my && messageType !== MESSAGE_TYPES.system && (
            photo ? (<Avatar photo={photo}/>)
                  : (<Avatar photo={DefaultAvatar(firstname, lastname, spec)} />)
        )
      }
      <Content>
        {/*
            {my ? <Status read={Boolean(read)} /> : null}
        */}
        {
          showDeleteContextMenu ? (
            <ChatContextMenu>
              <MenuList>
                <MenuItem onClick={deleteMessageAction}>
                  <FormattedMessage id='global.delete' />
                </MenuItem>
              </MenuList>
            </ChatContextMenu>
          ) : ''
        }
        {renderMessage(messageType)}
        {
          message !== 'null' && messageType !== MESSAGE_TYPES.report && messageType != MESSAGE_TYPES.system && (
            <Time>
              {time ? moment(moment.utc(time).toDate()).format('LLL') : ''}
              <InlineSpacer width='5px' />
              <MessageReadStatus read={read} />
            </Time>
          )
        }
      </Content>
    </Wrapper>
  ) 
})

const MessageReadStatus = ({read}) => {
  return read ?
    <DoneAll sx={{ fontSize: 'inherit', color: Colors.bluePurple }} /> :
    <Done sx={{ fontSize: 'inherit' }} />
}

/**
 * Renvoie l'objet du store redux contenant le propriétaire du message
 * @param senderId
 */
const findMessageOwner = (senderId) => {
  switch (senderId) {
    case useSelector(state => idx(state, _=> _.auth.attributes.user_id)):
      return useSelector(state => state.auth.attributes);
    case useSelector(state => idx(state , _=> _.consultations.consultation.relationships.doctor.data.attributes.user_id)):
      return useSelector(state => state.consultations.consultation.relationships.doctor.data.attributes);
    case useSelector(state => idx(state, _=>_.consultations.consultation.relationships.additionalMember.data[0].attributes.doctor.id)):
      return useSelector(state => state.consultations.consultation.relationships.additionalMember.data[0].attributes.doctor);
    case useSelector( state => idx(state, _=>_.consultations.consultation.relationships.patient.data.attributes.user_id)):
      return useSelector( state => state.consultations.consultation.relationships.patient.data.attributes);
    default:
      return null;
  }
}

Message.defaultProps = {
  scrollToBottom: undefined,
  invoice: '',
}

Message.propTypes = {
  messageObject: PropTypes.object.isRequired,
  scrollToBottom: PropTypes.func,
}

const Wrapper = styled.div`
  width: auto;
  height: auto;
  display: flex;
  justify-content: ${props => props.messageType == MESSAGE_TYPES.system ? 'center' : (props.my ? 'end' : '')};
`

const Avatar = styled.div`
  min-width: 43px;
  height: 43px;
  box-sizing: border-box;
  border-radius: 100%;
  background: url(${props => props.photo || defaultAvatar}) ${Colors.lightGrey} center no-repeat; 
  cursor: ${props => (!props.onClick ? 'default' : 'pointer')};
  background-size: cover;
  border: 3px solid white;
  box-shadow: 0 4px 8px 0 rgba(6, 173, 255, 0.1);
  margin-right: 12px;
`

const Content = styled.div`
  position: relative;
  max-width: 800px;
  min-width: 100px;

  @media only screen and (max-width: 1050px) {
    max-width: 400px;
  }
`

const ChatContextMenu = styled(Paper)`
  position: absolute;
  left: calc(100% + 13px);
  min-width: 128px;
  border-radius: 4px;
  box-shadow: 0 4px 8px 0 rgba(0, 30, 45, 0.12), 0 -1px 4px 0 rgba(0, 30, 45, 0.1);
  background-color: #f5f7fb;
`

const Time = styled.div`
  width: 100%;
  font-size: 11px;
  padding-top: 4px;
  color: ${Colors.blueGrey};
  text-align: right;
`

import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import GroupsIcon from '@mui/icons-material/Groups';
import Button from '@mui/material/Button';
import get from 'lodash/get';
import { Link } from 'react-router-dom';
import { FormattedMessage } from 'react-intl'
import axios from 'axios'
import { SecuredBckgImage } from 'common/SecuredImage'
import { JoinStructure, leaveStructure } from '../../../helpers/structures';
import { Paper } from '../UiKit/Paper'
import { Colors } from '../../../constants/colors';
import { withRouter } from 'react-router-dom'
import defaultLogo from "../../../images/doctors/structures/logo_structure.png"
import {FavoriteButton} from "../DoctorCard/FavoriteButton";
import LocationOnIcon from '@mui/icons-material/LocationOn';
import {DistanceDisplay} from "../DistanceDisplay";
import CustomLoaderRectangleAndCircle from "../CustomLoaderRectangleAndCircle";

const ItemWrapper = styled(Paper)`
    padding: 20px 24px;
    display: grid;
    grid-template-columns: 2fr 8fr 2fr;
    grid-template-rows: repeat(2,auto);
    column-gap: 20px;
`

const StructureNameLink = styled.div`
  font-size: 24px;
  font-weight: 500;
  color: #585C65;
  &:hover {
      color: ${Colors.bluePurple};
  }
  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;  
  overflow: hidden;
`

const StructureName = styled.div`
  font-size: 24px;
  font-weight: 500;
  line-height: 0.83;
  color: #585C65;
  margin-top: 10px;
`


const StructureInfo = styled.div`
    display: flex;
    flex-direction: column;
    align-self: center;
    text-transform: lowercase;
`

const StructureParam = styled.div`
    display: flex;
    color: #858992;
    margin: 7px 0 7px;
    align-items: center;
`

const Participants = styled.div`
    color: #858992;
    display: flex;
    margin: 7px 0 7px;
    justify-self: end;
`
const TopRight = styled.div`
    color: #b7bed2;
    display: flex;
    justify-self: end;
    align-items: start;
`

const StyledPersonIcon = styled(GroupsIcon)`
    margin-right: 12px;
    svg {
        color: #858992;
    }
`

const StyledLocationIcon = styled(LocationOnIcon)`
    margin-right: 12px;
    svg {
        color: #858992;
    }
`

const LeaveOrJoinButton = styled(Button)`
  && {
    height: 40px;
    margin-top: 15px;
    font-size: 14px;
    font-weight: 500;
    max-width: 243px;
    border-radius: 18px;
    margin-bottom: 5px;
    align-self: end;
  }
`

const Avatar = styled(SecuredBckgImage)`
    grid-row: 1 / 3;
    grid-column: 1;
    min-width: 126px;
    width: 126px;
    height: 126px;
    border: 7px solid white;
    border-radius: 100%;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    display: flex;
    align-items: center;
    justify-content: center;
    box-shadow: 0 4px 8px 0 rgba(6, 173, 255, 0.1);
    position: relative;
`

const StructureCardComponent = ({
                                  id,
                                  name,
                                  members,
                                  type,
                                  address,
                                  zipcode,
                                  city,
                                  distance,
                                  disableButtons,
                                  isNotClickable,
                                  location,
                                  photo,
                                  isFavorite,
                                  isFavoriteFetching,
                                  toggleFavorite,
                                  showMessageFavorite,
                                  isDoctor,
                                }) => {
  const [myStructuresIds, setMyStructuresId] = useState([]);

  const getIds = async () => {
    try {
      const myStruct = await axios.get('/api/doctor/structure/list')
      const ids = get(myStruct, 'data.data', []).map(el => el.id)
      setMyStructuresId(ids);
    } catch (e) {
      console.warn(e);
    }
  }

  useEffect(() => {
    getIds();
  }, [myStructuresIds.length])

  return (
    <ItemWrapper key={id} >
      {photo ?
        <Avatar
          spinner={{width: '120px', height: '120px'}}
          src={photo.includes('/file/') ? `http://backend-application.owndoctor.eu${photo}` : photo}
        /> :
        <Avatar
          spinner={{width: '120px', height: '120px'}}
          src={defaultLogo}
        />}
      { (!isNotClickable) ?
        <Link to={isDoctor ? `/panel/concilium/main/structure/${id}` : `/panel/doctors/structures/structure/${id}`}>
          <StructureNameLink>
            {name}
          </StructureNameLink>
        </Link>
        :   <StructureName>
          {name}
        </StructureName>
      }
      <TopRight>
        {
          !!distance && ( <DistanceDisplay float='right' distance={distance} /> )
        }
        {isDoctor &&
          <FavoriteButton
            checked={isFavorite}
            onClick={() => toggleFavorite(!isFavorite)}
            isFetching={isFavoriteFetching}
            message={showMessageFavorite}
          />
        }
      </TopRight>
      <StructureInfo>
        <Participants>
          <StyledPersonIcon/>
          <StructureInfo>
            {members + " "}
            <FormattedMessage id='structures.participants' />
          </StructureInfo>
        </Participants>
        <StructureParam>
          {(address || zipcode || city) &&
            <>
              <StyledLocationIcon/>
              {`${address ? address : ""}, ${zipcode ? zipcode : ""} ${city ? city : ""}`}
            </>
          }
        </StructureParam>
      </StructureInfo>
      {!disableButtons && (myStructuresIds.includes(id)
        ? (
          <LeaveOrJoinButton
            variant='outlined'
            color='primary'
            onClick={async () => {
              await leaveStructure(id);
              setMyStructuresId(myStructuresIds.filter(f => f !== id));
            }}
          >
            <FormattedMessage id='structures.leaveTheStructure' />
          </LeaveOrJoinButton>
        )
        : (
          <LeaveOrJoinButton
            variant='contained'
            color='primary'
            onClick={async () => {
              await JoinStructure(id);
              setMyStructuresId([...myStructuresIds, id]);
            }}
          >
            <FormattedMessage id='structures.join' />
          </LeaveOrJoinButton>
        ))}
    </ItemWrapper>
  )
}

export const StructureCardNew = withRouter(StructureCardComponent)

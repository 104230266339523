import React, { useEffect, useState } from 'react'
import styled from 'styled-components'
import { FormattedMessage } from 'react-intl'
import { Button } from '@mui/material'
import GroupAddOutlinedIcon from '@mui/icons-material/GroupAddOutlined';
// eslint-disable-next-line import/no-extraneous-dependencies
import { Colors } from 'constants/colors'
import { Page } from '../../../../common/Page'
import { getDoctorTeams } from '../../../../../helpers/Doctor/TeamHelper'
import { history } from '../../../../../utils/history'
import { ItemComponent } from './Item'

const CreateBtnStyle = {
  padding: '5px 16px',
  background: '#6633FF',
  color: '#fff',
  boxShadow: 'none',
  borderRadius: '25px',
  cursor: 'pointer',
  textTransform: 'none',
}

export const ROSOPageList = ({ location }) => {
  const [teams, setTeams] = useState([]);
  const refreshMyTeams = () => getDoctorTeams().then(result => setTeams(result));
  useEffect(() => {
    refreshMyTeams();
  }, [location]);

  return (
    <Page
      label='roso.community.page.title'
      fullWidth
    >
      <Wrapper>
        <Content>
          <CreateBlockTitle>
            <FormattedMessage id='roso.community.page.title' />
          </CreateBlockTitle>
        </Content>
        {teams && teams.length ? (
          <div>
            {teams.map(team => (
              <ItemComponent key={team.id} refreshMyTeams={refreshMyTeams} team={team} />
            ))}
          </div>
        ) : (
          <>
            <WrapperNotTeam>
              <Logo><GroupAddOutlinedIcon 
                style={
                {
                  width: '100%',
                  height: '100%', 
                  color: 'lightblue', 
                }
                }
              />
              </Logo>   
              <Heading>
                <FormattedMessage id='roso.not.team' />
              </Heading>
              <Text>
                <FormattedMessage id='roso.not.team.text' />
              </Text>
            </WrapperNotTeam>
          </>
        ) }
      </Wrapper>
    </Page>
  )
}

const Wrapper = styled.div`
  max-width: 100%;
  min-width: 720px;
  margin: 24px auto;

  .MuiButton-label {
  text-transform: initial;
}
`
const Content = styled.div`
  display: flex;
  margin-top: 16px;
  justify-content: right;
`

const CreateBlockTitle = styled.div`
  font-family: Roboto;
  font-size: 24px;
  font-weight: 500;
  color: #21293e;
  margin-bottom: 30px;
  margin-inline-start: 250px;
  justify-content: left;
  flex: 1;
`
const ButtonBlock = styled.div`
 align-items: center;
 margin-right: 10px;
`
const WrapperNotTeam = styled.div`
  width: 380px;
  height: calc(100vh - 48px - 56px - 24px * 2 - 80px);
  margin: auto;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  font-family: Roboto, sans-serif;
`

const Logo = styled.div`
  margin-bottom: 36px;
  width: 168px;
  height: 168px;
`

const Heading = styled.h1`
  margin: 0 0 16px;
  font-size: 24px;
  font-weight: 500;
  line-height: 1.33;
  color: ${Colors.dark};
  text-align: center;
  }
`

const Text = styled.p`
  margin: 0;
  opacity: 0.8;
  font-size: 16px;
  font-weight: normal;
  line-height: 1.5;
  color: ${Colors.blueGrey};
  text-align: center;
`
//#endregion

import React from 'react'
import {Redirect, Route, Switch} from 'react-router-dom'
import {UrgentConsultation} from './UrgentConsultation'
import {AppointmentConsultation, NewAppointmentConsultation} from './AppointmentConsultation'
import {Profile} from './Profile'
import {Favorite} from './Favorite'
import {Reviews} from './Reviews'
import {DoctorDetails} from "../../Doctor/Concilium/DoctorDetails";
import {MainTeleexpertise} from "../../Doctor/Concilium/MainTeleexpertise";
import {NearMe} from "../../Doctor/Concilium/NearMeTab";
import {Specializations} from "../../Doctor/Concilium/Specializations";
import {StructurePage} from "../../Doctor/Community/Structures/StructurePage";
import {NearMeInstantSearch} from "../../Doctor/Concilium/NearMeTabInstantSearch";
import {DoctorAnnuaireDetails} from "../../Doctor/Concilium/DoctorAnnuaireDetails";

export const Doctors = ({match}) => (
  <Switch>
    <Redirect from={match.path} exact to={`${match.path}/main`} />
    <Route path='/panel/doctors/urgent/create' component={UrgentConsultation} />
    <Route path='/panel/doctors/appointment/create' component={AppointmentConsultation} />
    <Route path='/panel/doctors/appointment/new/:doctorId' component={NewAppointmentConsultation} />
    <Route path='/panel/doctors/:consultationType/profile' component={Profile} />
    <Route path='/panel/doctors/:doctorId/reviews' component={Reviews} />
    <Route path='/panel/doctors/annuaire/:annuaireId' component={DoctorAnnuaireDetails} />
    <Route path='/panel/doctors/doctor/:doctorId' component={DoctorDetails} />
    <Route exact path='/panel/doctors/specializations/:doctorId' component={NearMeInstantSearch} />
    <Route exact path='/panel/doctors/structures' component={NearMe} />
    <Route exact path='/panel/doctors/search' component={NearMeInstantSearch} />
    <Route exact path='/panel/doctors/teams' component={NearMe} />
    <Route path='/panel/doctors/structures/structure/:id' component={StructurePage} exact />
    <Route path='/panel/doctors/favorite' component={Favorite} />
    <Route path='/panel/doctors/main' component={MainTeleexpertise} />
    <Route path={`${match.path}/specializations`} exact component={Specializations} />
  </Switch>
)

import React from 'react'
import styled from 'styled-components';
import { Apartment, LocationOn } from '@mui/icons-material';
import { MapContainer, Marker, TileLayer } from 'react-leaflet';
import { Icon } from 'leaflet'
import {MY_API_KEY, URL_GEOAPIFY} from "../../../constants/map";

export const StructureInfosLogo = ({ logo, name }) => (
  <>
    {
      !!logo && (
        <LogoWrapper>
          <img alt={ name ? name : 'logo'} src={logo} />
        </LogoWrapper>
      )
    }
  </>
)

export default ({ logo, name, type, address, position, ...rest }) => {

  const StructureMapIcon = new Icon({
    iconUrl: `https://api.geoapify.com/v1/icon?icon=hospital-alt&iconType=awesome&type=material&color=%23f1353a&size=xx-large&apiKey=${MY_API_KEY}&noShadow&noWhiteCircle`,
    iconSize: [31, 46],
    iconAnchor: [15.5, 42],
    popupAnchor: [0, -45],
  })

  return (
    <InfosWrapper {...rest}>
      <StructureInfosLogo logo={logo} name={name} />
      {
        !!type && (
          <TextWrapper>
            <Apartment style={{ verticalAlign: 'middle', marginRight: '5px', float: 'left' }} />
            <span style={{ verticalAlign: 'middle' }}>{type}</span>
          </TextWrapper>
        )
      }
      {
        !!address && (
          <TextWrapper>
            <LocationOn style={{ verticalAlign: 'middle', marginRight: '5px', float: 'left' }} />
            <span style={{ verticalAlign: 'middle' }}>{address}</span>
          </TextWrapper>
        )
      }
      {
        position && (
          <StyledMapContainer center={[position.latitude, position.longitude]} zoom={13} scrollWheelZoom={false}>
            <TileLayer
              attribution='Powered by <a href="https://www.geoapify.com/" target="_blank">Geoapify</a> | © OpenStreetMap <a href="https://www.openstreetmap.org/copyright" target="_blank">contributors</a>'
              url={URL_GEOAPIFY}
            />
            <Marker
              position={[position.latitude, position.longitude]}
              icon={StructureMapIcon}
            />
          </StyledMapContainer>
        )
      }
    </InfosWrapper>
  )
}

// --------------------------------- Styles CSS -------------------------------------

const InfosWrapper = styled.div`
  background-color: transparent;
  color: #626364;
`

const LogoWrapper = styled.div`
  display: inline-block;
  text-align: center;
  margin: 0;
  width: 250px;
  overflow: hidden;
  
  img {
    display: inline-block;
    max-width: 230px;
  }
`

const NameWrapper = styled.div`
  font-size: 24px;
  font-weight: bold;
  margin: 25px 0;
`

const TextWrapper = styled.div`
  margin-bottom: 25px;
  & > span {
    display: block;
    padding-top: 2px;
  }
`

const StyledMapContainer = styled(MapContainer)`
  height: 240px;
  width: 240px;
`

import React, {useEffect, useState} from 'react';
import styled from 'styled-components';
import { EditPage } from 'common/UiKit/EditPage';
import { history } from '../../../../utils/history';
import {FormattedMessage} from "react-intl";
import { Paper } from 'common/UiKit/Paper';
import Switch from '@mui/material/Switch';
import {Slider} from "@mui/material";
import axios from "axios";
import {useSelector} from "react-redux";
import {Circle, MapContainer, Marker, TileLayer} from "react-leaflet";
import {Icon} from "leaflet";
import {Colors, calendarColors} from "../../../../constants/colors"
import {MY_API_KEY, URL_GEOAPIFY} from "../../../../constants/map";

const Wrapper = styled.div`
  margin-top: 24px;
`

const PaperStyled = styled(Paper)`
  margin-top: 30px;
  padding: 15px 25px 15px 25px; 
`

const Title = styled.div`
  color: ${Colors.footerGrey};
  font-size: 16px;
  font-weight: 500;
  align-content: center;
  justify-content: center;
  margin-top: 8px;
`

const Text = styled.div`
  margin-top: 20px;
  color: ${calendarColors.buttonBorder};
  font-size: 14px;
  width: 550px;
`

const Line = styled.div`
  display: inline-flex;
`

const SwitchStyled = styled(Switch)`
  margin-left: 155px;
`

const SliderStyled = styled(Slider)`
  color: ${Colors.bluePurple};
  width: 350px;
  height: 3px;
  display: block;
  margin: 40px auto 0 auto;
`

const MapContainerStyled = styled(MapContainer)`
  height: 350px;
  width: 100%;
  margin-top: 30px;
  margin-bottom: 10px;
  z-index: 0;
`

export const LimitationTE = () => {
    const [checked, setChecked] = useState(false);
    const [distance, setDistance] = useState();
    const userId = useSelector(state => state.auth.attributes.user_id);
    const workplaceLat = useSelector(state => state.auth.relationships.workPlace.data.slice(-1)[0].attributes.lat)
    const workplaceLon = useSelector(state => state.auth.relationships.workPlace.data.slice(-1)[0].attributes.lon)

    const DoctorIcon = new Icon({
        iconUrl: `https://api.geoapify.com/v1/icon?icon=user-md&iconType=awesome&type=awesome&color=%236633ff&size=xx-large&apiKey=${MY_API_KEY}&noShadow&noWhiteCircle`,
        iconSize: [31, 46], // size of the icon
        iconAnchor: [15.5, 42], // point of the icon which will correspond to marker's location
    })

    useEffect(() => {
        const res = axios.get(`/user/geographical/limit/${userId}`)
            .then((response) => {
                setDistance(response.data.intValue);
            })
    }, [])

    useEffect(() => {
        distance ? setChecked(true) : setChecked(false)
    }, [distance])

    const postGeographicalLimit = async (distance) => {
        try {
            await axios.post('/user/geographical/limit', {int_value: distance})
        } catch (e) {
            console.warn(e);
        }
    }

    return (
        <EditPage
            label='profile.limitation.TE'
            buttonText='global.save.language'
            onBackIconClick={() => {history.goBack()}}
            onButtonClick={
                async () => {
                    checked ? (!distance ? await postGeographicalLimit(50) : await postGeographicalLimit(distance)) : await postGeographicalLimit(null);
                    history.goBack();
                }
            }
            background
        >
            <Wrapper>
                <h2>
                    <FormattedMessage id='profile.limitation.TE' />
                </h2>
                <PaperStyled>
                    <Line>
                        <Title>
                            <FormattedMessage id='profile.limitation.TE.title' />
                        </Title>
                        <SwitchStyled
                            color='primary'
                            inputProps={{'aria-label': 'controlled'}}
                            checked={checked}
                            onChange={(event) => {setChecked(event.target.checked)}}
                        />
                    </Line>
                    <Text>
                        <FormattedMessage id='profile.limitation.TE.text'/>
                    </Text>
                    {checked && (
                        <>
                            <SliderStyled
                                valueLabelDisplay={"on"}
                                step={1}
                                marks
                                min={1}
                                max={150}
                                size={"small"}
                                value={distance ? distance : 50}
                                onChange={(event) => {setDistance(event.target.value)}}
                            />
                            <MapContainerStyled
                                center={[workplaceLat, workplaceLon]}
                                zoom={8}
                                scrollWheelZoom={true}
                                dragging={true}
                                zoomControl={true}
                                touchZoom={false}
                                boxZoom={false}
                                doubleClickZoom={false}
                                keyboard={false}
                            >
                                <TileLayer
                                    attribution='Powered by <a href="https://www.geoapify.com/" target="_blank">Geoapify</a> | © OpenStreetMap <a href="https://www.openstreetmap.org/copyright" target="_blank">contributors</a>'
                                    url={URL_GEOAPIFY}
                                />
                                <Circle
                                    center={[workplaceLat, workplaceLon]}
                                    radius={distance ? distance*1000 : 50000}
                                    pathOptions={{fillColor: Colors.bluePurple}}
                                    stroke={false}
                                />
                                <Marker position={[workplaceLat, workplaceLon]} icon={DoctorIcon}/>
                            </MapContainerStyled>
                        </>
                    )}
                </PaperStyled>
            </Wrapper>
        </EditPage>
    )
}
import React from 'react'
import styled from 'styled-components'
import { FormattedMessage } from 'react-intl'
import { Paper } from 'common/UiKit/Paper'
import { SecuredBckgImage } from 'common/SecuredImage'
import { history } from "../../../utils/history";
import defaultLogo from "../../../images/doctors/structures/logo_structure.png";
import GroupsIcon from "@mui/icons-material/Groups";
import PlaceIcon from '@mui/icons-material/Place';


const PaperStyled = styled(Paper)`
  padding: 10px 14px;
  position: relative;
`

const Wrapper = styled.div`
  display: grid;
  grid-template-rows: 2.5fr 1fr 1fr;  
  font-weight: normal;
  font-size: 16px;
  align-content: center;
  height: 140px;
  overflow: hidden;
  text-overflow: ellipsis;
  & > * {
    min-width: 0;
    min-height: 0;
  }
  justify-self: center;
`

const Div = styled.div`
  display: flex;
  font-weight: normal;
  font-size: 16px;
  align-items: center;
`

const NameLinked = styled.h1`
  margin: 0 20px;
  font-size: 18px;
  font-weight: bold;
  color: black;
  max-height: 70px;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;  
  overflow: hidden;
  
  &:hover {
    cursor: pointer;
    color: #6633FF;
  }
`

const Name = styled.p`
  margin: 0 20px;
  font-size: 18px;
  font-weight: bold;
  color: black;
  max-height: 70px;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;  
  overflow: hidden;
`

const Avatar = styled(SecuredBckgImage)`
  min-width: 70px;
  width: 70px;
  height: 70px;
  border-radius: 100%;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
`

const Members = styled.div`
  align-items: center;
  color: #959fb8;
  font-size: 14px;
  font-weight: 200;
  display: flex;
  text-transform: lowercase;
  
  & > * {
    padding: 0 8px;
  }
`

const Address = styled.div`
  align-items: center;
  color: #959fb8;
  font-size: 14px;
  font-weight: 200;
  display: flex;

  & > * {
    padding: 0 8px;
  }
`

const AddressContent = styled.div`
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap
`

export const FavoriteStructureCard = ({
                                     id,
                                     name,
                                     members,
                                     address,
                                     zipcode,
                                     city,
                                     photo,
                                     link,
                                   }) => {


  return (
    <PaperStyled>
      <Wrapper>
        <Div>
          {
            photo ? (
                <Avatar
                  spinner={{width: '120px', height: '120px'}}
                  src={photo.includes('/file/') ? `http://backend-application.owndoctor.eu${photo}` : photo}
                />
              ) :
              (<Avatar
                  spinner={{width: '120px', height: '120px'}}
                  src={defaultLogo}
                />
              )
          }
            {link ? (
                <NameLinked onClick={()=>history.push(link)}>
                  {name}
                </NameLinked>)
              : (
                <Name>
                  {name}
                </Name>)
            }

        </Div>

        <Members>
          <GroupsIcon />
          <div>
          {members} <FormattedMessage id='structures.participants' />
          </div>
        </Members>

        <Address>
          <PlaceIcon />
          {(address || zipcode || city) &&
            <AddressContent>
            {`${address ? address + ", " : ""} ${zipcode ? zipcode : ""} ${city ? city : ""}`}
            </AddressContent>
          }
        </Address>
      </Wrapper>
    </PaperStyled>
  )
}
import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import { Colors } from 'constants/colors'

export const Method = ({ onClick, icon, selected }) => (
  <Button onClick={onClick} type='button' selected={selected}>
    <img src={icon} alt='Method' />
  </Button>
)

Method.propTypes = {
  onClick: PropTypes.func.isRequired,
  icon: PropTypes.string.isRequired,
  selected: PropTypes.bool.isRequired,
}

//#region SC
const Button = styled.button`
  outline: none;
  border: ${props => (props.selected ? `2px solid ${Colors.clearBlue}` : `1px solid ${Colors.divider}`)};
  border-radius: 4px;
  padding: 6px 16px 4px;
  background-color: white;
  cursor: pointer;
  margin-right: 4px;
  transition: background-color .1s ease-out;
  box-sizing: border-box;
  
  &:hover {
    background-color: ${Colors.lightGrey};
  }
  
  img {
    height: 32px;
  }
`
//#endregion

import React, {useEffect, useState} from 'react'
import styled from 'styled-components'
import PropTypes from 'prop-types'
import {connect, useDispatch, useSelector} from 'react-redux'
import {
  compose,
  lifecycle,
  branch,
  renderComponent,
} from 'recompose'
import { Menu } from '../common/UiKit/Menu'
import { Colors } from '../../constants/colors'
import idx from 'idx'
import { doctorMenuButtons, patientMenuButtons } from '../../constants/menuButtons'
import { MenuProfileInfo } from './MenuProfileInfo'
import { Doctor } from './Doctor'
import { Patient } from './Patient'
import { userIsAuthenticated } from '../../utils/userIsAuthenticated'
import { updateUserTimestamp } from '../../redux/modules/auth'
import { Preloader } from '../common/Preloader'
import { socket } from '../../services/socket'
import { handleMessageAction } from '../../redux/modules/chats'
import { fetchSignature } from '../../redux/modules/signature'
import { fetchNotificationCount, fetchAdminChatCount } from '../../redux/modules/notification'
import { CallModal } from '../common/CallModal'

import {useMediaQuery} from "@mui/material";
import {Survey} from "./Doctor/Survey/Survey";
import Visio from "../common/Visio";
import axios from "axios";
import {hasUnreadMessage} from "../../redux/modules/discussions";

const Wrapper = styled.div`
  height: 100vh;
  background-color: ${Colors.white};
  display: flex;
`

const Content = styled.div`
  height: 100%;
  flex-grow: 1;
  overflow: auto;
  position: relative;
`

const enhance = compose(
  userIsAuthenticated,
  connect(state => ({
    user: idx(state, _ => _.auth.type),
    myId: idx(state, _ => _.auth.attributes.user_id),
    myUserStatus: idx(state, _ => _.auth.attributes.status),
    authenticating: state.loading.authByToken,
    isSessionActive: Object.keys(state.consultations.opentok.params).length
      ? state.consultations.consultation.id
      : null,
  }), {
    updateUserTimestamp,
    handleMessageAction,
    fetchSignature,
    fetchNotificationCount,
    fetchAdminChatCount,
  }),
  lifecycle({
    componentDidMount() {
      const token = localStorage.getItem('authToken')

      this.props.updateUserTimestamp()
      this.timerID = setInterval(() => this.props.updateUserTimestamp(), 60000)
      socket.initialize(token, message => this.props.handleMessageAction(message), this.props.myId, this.props.consultation)

      setInterval(() => socket.keepAlive(), 10000)

      if (this.props.user === 'Doctor') {
        this.props.fetchSignature()
      }

      this.props.fetchNotificationCount()
      //this.props.fetchAdminChatCount()
    },
    componentWillUnmount() {
      clearInterval(this.timerID)
    },
  }),
  branch(
    props => props.authenticating,
    renderComponent(() => <Preloader />),
  ),
)

let unreadCheckTask = null;

export const Panel = enhance(({
  user, location, isSessionActive, myId, myUserStatus,
}) => {
  const dispatch = useDispatch()
  const lastMessageTime = useSelector(state => state.discussions.lastMessage)
  const verified = useSelector(state => state.auth.attributes.status === 'VERIFIED')
  const chats = useSelector(state => state.chatsNotifications)
  const adminChatOpened = location.pathname === '/panel/chat-administrator';
  const [folded, setFolded] = useState(false);
  const adminChat = chats.filter(el => el
    && el.read === 0
    && el.chat_relate_type
    && el.chat_relate_type === 'support'
    && el.sender !== myId
  )
  const path = window.location.pathname

  const smallScreen = useMediaQuery(`(max-width:1100px)`);

  useEffect(() =>{
    smallScreen ? setFolded(true) : setFolded(false)
  }, [smallScreen])

  useEffect(() => {
    if (unreadCheckTask != null) {
      clearTimeout(unreadCheckTask)
    }
    unreadCheckTask = setTimeout(() => {
      dispatch(hasUnreadMessage())
    }, 1000)
  }, [lastMessageTime]);

  return (
    <Wrapper>
      {
        myUserStatus !== 'LIMITED' && path !== '/panel/2FA' && myUserStatus !== 'PRE_REGISTRATED' && (
          <Menu
            adminChatMessages={
              !adminChatOpened
              && adminChat
              && adminChat.length
            }
            folded = {folded}
            menuButtons={user === 'Doctor' ? doctorMenuButtons : patientMenuButtons}
            disabledButtons={!verified ? [
              'roso.discussions',
              'concilium.title',
              'teleconsultations.title',
              'schedule.title',
              'doctor.healthcare.teams',
              'doctor.reseau',
              'doctor.proximity',
              'structures.mesStructures',
            ] : []}
          >
            <MenuProfileInfo folded={folded} setFolded={setFolded}/>
          </Menu>
        )
      }
      <Content>
        <Visio />
        {user === 'Doctor' ? <Doctor /> : <Patient />}
      </Content>
      <CallModal />
    </Wrapper>
  )
})

Panel.defaultProps = {
  user: 'Patient',
}

Panel.propTypes = {
  user: PropTypes.string,
  location: PropTypes.object.isRequired,
}
import PropTypes from 'prop-types'
import React from 'react'
import styled, { css } from 'styled-components'
import StarIcon from '@mui/icons-material/Star';
import CircularProgress from '@mui/material/CircularProgress'

import { Colors } from '../../../constants/colors'
import CheckmarkIcon from '../../../images/panel/doctors/checkmark.svg'
import {FormattedMessage} from "react-intl";

const Button = styled.div`
  cursor: pointer;
  width: 24px;
  height: 24px;
  position: relative;
  margin-left: 6px;
  display: inline;
  
  svg {
    fill: ${Colors.disabled};
  }
  
  ${props => (props.checked
    ? css`
      svg {
        fill: #FFCC00;
      }

      &:after {
        content: '';
        position: absolute;
        width: 12px;
        height: 12px;
        top: 4px;
        left: 6px;
        /*background-image: url(${CheckmarkIcon});*/
        background-size: contain;
      }
    `
    : css`
      &:after {
        /*content: "+";*/
        position: absolute;
        width: 8px;
        height: 8px;
        left: 8px;
        color: ${Colors.white};
        border-color: #6633FF
        font-size: 12px;
        top: 4px;
        font-weight: bold;
      }
    `
  )}
  
  ${props => (props.loading && css`
    &:after {
      content: none !important;
    }
  `)}
`

const ProgressWrapper = styled.div`
  position: absolute;
  top: 0;
  left: 7px;
  color: white;
  
  svg {
    color: white;
  }
  
  & > div {
    height: auto !important;
  }
`

const FavoriteMessage = styled.div`
  cursor: pointer;
  color: #6633FF;
  text-decoration: none;
  font-family: Roboto, sans-serif;
  font-weight: 400;
  display: inline;
`

const Wrapper = styled.div`
  align-items: center;
  display: flex;
`
export const FavoriteButton = ({ checked, isFetching, onClick, message }) => (
  <Wrapper>
    {message &&
      <FavoriteMessage onClick={onClick}>
        <FormattedMessage id={checked ? 'roso.remove' : 'roso.add'}/>
      </FavoriteMessage>
    }
    <Button checked={checked} loading={`${isFetching}`} onClick={onClick}>
      <StarIcon />
      {isFetching && <ProgressWrapper><CircularProgress size={10} thickness={6} /></ProgressWrapper>}
    </Button>
  </Wrapper>
)

FavoriteButton.propTypes = {
  checked: PropTypes.bool.isRequired,
  isFetching: PropTypes.bool.isRequired,
  onClick: PropTypes.func.isRequired,
}

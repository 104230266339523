import React, {useEffect, useState} from 'react'
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import Grid from '@mui/material/Grid';
import Button from '@mui/material/Button';
import styled from 'styled-components';
import CloseIcon from '@mui/icons-material/Close';
import { Form, Field } from 'react-final-form';
import { FieldArray } from 'react-final-form-arrays';
import arrayMutators from 'final-form-arrays';
import { TextField } from '../../../../../common/FinalFormFields'
import {compose, withProps} from "recompose";
import {connect, useDispatch} from "react-redux";
import idx from "idx";
import PropTypes from 'prop-types';
import {
  fetchPrescription,
  savePrescription,
  savePrescriptionFailure
} from '../../../../../../redux/modules/consultations'
import {FormattedMessage, useIntl} from "react-intl";

const CloseModal = styled(CloseIcon)`
    position: absolute;
    top: 10px;
    right: 10px;
    cursor: pointer;
`

const Title = styled.div`
  text-align: center;
  padding-right: 30px;
  border-bottom: 2px solid #ccc;
`

const ColTitle = styled.div`
  font-size: 1.1em;
  font-weight: bold;
  text-align: center;
  margin-bottom: 15px;
`

const SubmitWrapper = styled.div`
  margin-top: 15px;
  padding-top: 5px;
  text-align: right;
  border-top: 2px solid #ccc;
`

const Error = styled.div`
      margin-top: 10px;
      display: flex;
      color: #800;
      flex-flow: row nowrap;
      justify-content: center;
`

const PrescriptionDialogComponent = ({ consultation, opened, onClose, onSaved }) => {
  const { formatMessage } = useIntl()
  const f = id => formatMessage({ id })
  const dispatch = useDispatch()
  const [prescription, setPrescription] = useState({lines: [{name: '', dosage: '' }] })
  const [error, setError] = useState(null)
  const [errorEmpty, setErrorEmpty] = useState(true)


  useEffect(() => {
    dispatch(fetchPrescription(consultation.id))
      .then(response => {
        if (response && response.payload.prescription) {
          //setPrescription(response.payload.prescription)
        }
      })
  }, [])

  return (
    <Dialog open={!!opened} disableEscapeKeyDown={false}>
      <CloseModal onClick={() => onClose()} />
      <DialogTitle>
        <Title><FormattedMessage id={'consultation.prescription.title'}/></Title>
      </DialogTitle>
      <DialogContent>
        <Form
          mutators={{
            ...arrayMutators,
          }}
          onSubmit={async (values) => {
            for (let requestFailed = true, retries = 0; retries < 3 && requestFailed; retries++) {
              await dispatch(savePrescription(consultation.id, values.traitements))
                .then(response => {
                  if (response.type === savePrescriptionFailure) {
                    setError(f('consultations.prescription.network-failed'))
                    requestFailed = true
                  } else {
                    setError(null)
                    requestFailed = false
                    setPrescription(response.payload.prescription)
                    if (onSaved)
                      onSaved(response.payload.prescription)
                    onClose()
                  }
                })
                .catch(console.error)
            }
          }}
          validate={(values) => {
            setErrorEmpty(true)
            values.traitements.forEach(line => {
              if(line.name != undefined && line.name != "" ){
                setErrorEmpty(false)
              }
            })
          }}
          initialValues={{
            traitements: prescription?.lines,
          }}
          render={({ form, handleSubmit }) => (
            <form onSubmit={handleSubmit}>
              <Grid container spacing={1}>
                <Grid item xs={6}>
                  <ColTitle><FormattedMessage id={'consultation.prescription.treatments'}/></ColTitle>
                </Grid>
                <Grid item xs={6}>
                  <ColTitle><FormattedMessage id={'consultation.prescription.dosage'}/></ColTitle>
                </Grid>
                <FieldArray name='traitements'>
                  {fieldArrayProps => fieldArrayProps.fields.map((name, key) => (
                    <React.Fragment key={ key }>
                      <Grid item xs={6}>
                        <Field key={name} name={`${name}.name`} component={TextField} />
                      </Grid>
                      <Grid item xs={6}>
                        <Field key={name} name={`${name}.dosage`} component={TextField} />
                      </Grid>
                    </React.Fragment>
                  ))}
                </FieldArray>
              </Grid>
              <Button
                variant='outlined'
                onClick={() => {
                  form.mutators.push('traitements', {name: '', dosage: '' })
                }}
              ><FormattedMessage id={'consultation.prescription.add'}/></Button>
              <SubmitWrapper>
                <Button type='submit' variant='contained' disabled={errorEmpty}><FormattedMessage id={'consultation.prescription.save'}/></Button>
              </SubmitWrapper>
              {error && <Error className="error">{error}</Error>}
            </form>
          )}
        />
      </DialogContent>
    </Dialog>
  )
}

PrescriptionDialogComponent.propTypes = {
  consultation: PropTypes.object.isRequired,
  opened: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
}

const enchance = compose(
  connect(
    state => ({
      consultation: idx(state, _ => _.consultations.consultation),
    }),
  ),
  withProps(props => ({
    opened: props.opened,
    onClose: props.onClose,
    onSaved: props.onSaved,
  }))
)
export const PrescriptionDialog = enchance(PrescriptionDialogComponent)
import React, {useCallback, useEffect, useMemo, useRef, useState} from 'react';
import throttle from 'lodash/throttle';
import {Tab, Tabs} from '@mui/material';
import PropTypes from "prop-types";

const makeUnique = (hash, unique, i = 1) => {
  const uniqueHash = i === 1 ? hash : `${hash}-${i}`;

  if (!unique[uniqueHash]) {
    unique[uniqueHash] = true;
    return uniqueHash;
  }

  return makeUnique(hash, unique, i + 1);
};

export const textToHash = (text, unique = {}) => {
  return makeUnique(
    encodeURI(
      text
        .toLowerCase()
        .replace(/=&gt;|&lt;| \/&gt;|<code>|<\/code>|&#39;/g, "")
        // eslint-disable-next-line no-useless-escape
        .replace(/[!@#\$%\^&\*\(\)=_\+\[\]{}`~;:'"\|,\.<>\/\?\s]+/g, "-")
        .replace(/-+/g, "-")
        .replace(/^-|-$/g, "")
    ),
    unique
  );
};
const noop = () => {
};

function useThrottledOnScroll(callback, delay) {
  const throttledCallback = useMemo(
    () => (callback ? throttle(callback, delay) : noop),
    [callback, delay]
  );

  useEffect(() => {
    if (throttledCallback === noop) return undefined;

    window.addEventListener("scroll", throttledCallback);
    return () => {
      window.removeEventListener("scroll", throttledCallback);
      throttledCallback.cancel();
    };
  }, [throttledCallback]);
}

function ScrollSpyTabs({tabsInScroll, tabHeight, tabsAreFixed, tabStyle}) {
  const [activeState, setActiveState] = useState(null);

  let itemsServer = tabsInScroll.map(tab => {
    const hash = textToHash(tab.anchor);
    return {
      icon: tab.icon || "",
      text: tab.text,
      component: tab.component,
      anchor: tab.anchor,
      hash: hash,
      node: document.getElementById(tab.anchor)
    };
  });

  const itemsClientRef = useRef([]);
  useEffect(() => {
    itemsClientRef.current = itemsServer;
  }, [itemsServer]);

  const clickedRef = useRef(false);
  const unsetClickedRef = useRef(null);
  const findActiveIndex = useCallback(() => {
    // set default if activeState is null
    if (activeState === null) setActiveState(itemsServer[0].hash);

    // Don't set the active index based on scroll if a link was just clicked
    if (clickedRef.current) return;

    let active;
    for (let i = itemsClientRef.current.length - 1; i >= 0; i -= 1) {
      // No hash if we're near the top of the page
      if (document.documentElement.scrollTop < 0) {
        active = {hash: null};
        break;
      }

      const item = itemsClientRef.current[i];

      if (
        item.node &&
        item.node.offsetTop < document.documentElement.scrollTop + document.documentElement.clientHeight / 8 + tabHeight
      ) {
        active = item;
        break;
      }
    }

    if (active && activeState !== active.hash) {
      setActiveState(active.hash);
    }
  }, [activeState, itemsServer]);

  // Corresponds to 10 frames at 60 Hz
  useThrottledOnScroll(itemsServer.length > 0 ? findActiveIndex : null, 166);

  const handleClick = item => () => {
    // Used to disable findActiveIndex if the page scrolls due to a click
    clickedRef.current = true;
    unsetClickedRef.current = setTimeout(() => {
      clickedRef.current = false;
    }, 1000);

    if (activeState !== item.hash) {
      setActiveState(item.hash);

      if (window) {
        let position = document.getElementById(item.anchor).getBoundingClientRect().top + window.pageYOffset
        if (tabsAreFixed) {
          position -= tabHeight;
        }
        window.scrollTo({
          top: position,
          behavior: "smooth"
        });
      }
    }
  };

  useEffect(
    () => () => {
      clearTimeout(unsetClickedRef.current);
    },
    []
  );

  return (
    <Tabs value={activeState ? activeState : itemsServer[0].hash} TabIndicatorProps={{children: <div/>}}>
      {itemsServer.map(item2 => (
        <Tab
          key={item2.hash}
          label={item2.text}
          onClick={handleClick(item2)}
          value={item2.hash}
          sx={tabStyle}
        />
      ))}
    </Tabs>
  );
}

ScrollSpyTabs.propTypes = {
  tabsInScroll: PropTypes.array.isRequired,
  tabHeight: PropTypes.number.isRequired,
  tabsAreFixed: PropTypes.bool.isRequired,
  tabStyle: PropTypes.object,
}

ScrollSpyTabs.defaultProps = {
  tabStyle: {},
  tabHeight: 0,
  tabsAreFixed: false,
}

export default ScrollSpyTabs;

import React from 'react'
import { Dialog, DialogContent } from '@mui/material'
import CloseIcon from '@mui/icons-material/Close'
import styled from 'styled-components'
import { TextButton } from 'common/UiKit/TextButton'
import { Colors } from 'constants/colors'
import { FormattedMessage } from 'react-intl'
import { StatusMission } from './Mission'
import { history } from '../../../../utils/history';

const MISSIONS_PATHS = {
  'consultation.status.completed': '/panel/schedule',
  'doctor.favorite.added': '/panel/concilium/specializations',
  'doctor.avatar.uploaded': '/panel/profile/edit/personal_data',
}

export const MissionModal = ({ data, onClose }) => {
  if (!data) return null
  return (
    <Dialog open onClose={onClose} fullWidth maxWidth='xs'>
      <DialogContent>
        <CloseWrapper>
          <CloseIcon onClick={onClose} />
        </CloseWrapper>
        <StatusMission data={data} modal />
        <FormattedMessage id={`status.missions.${data.mission}.text`} values={{ value: data.missionTotal }} />
        {
          MISSIONS_PATHS[data.mission] && (
            <Button color='primary' onClick={() => history.push(MISSIONS_PATHS[data.mission])}>
              <FormattedMessage id={`status.missions.${data.mission}.button`} />
            </Button>
          )
        }
      </DialogContent>
    </Dialog>
  )
}

const CloseWrapper = styled.div`
  text-align: right;
  color: #a2a7bd;
  
  svg {
    fill: #a2a7bd;
    width: 20px;
    height: 20px;
    cursor: pointer;
    
    &:hover {
      fill: ${Colors.dark80};
    }
  }
`

const Button = styled(TextButton)`
  && {
    font-size: 14px;
    font-weight: 500;
    margin: 16px auto 0;
    display: block;
  }
`

import React from 'react'
import styled from 'styled-components'
import { Colors } from 'constants/colors'
import { FormattedMessage } from 'react-intl'

const Wrapper = styled.div`
  width: 380px;
  height: calc(100vh - 56px - 42px - 22px * 2 - 40px * 2);
  margin: auto;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  font-family: Roboto, sans-serif;
`

const Logo = styled.img`
  margin-bottom: 5px;
  width: 350px;
  height: 350px;
`

const Heading = styled.h1`
  font-size: 18px;
  font-weight: ${({ bold }) => (bold) ? '700' : 'normal'};
  line-height: 1.33;
  color: ${Colors.dark};
  text-align: center;
`

const Subtitle = styled.p`
  color: ${Colors.dark};
  font-size: 14px;
  text-align: center;
`

const Text = styled.div`
  margin: 0;
  opacity: 0.8;
  font-size: 16px;
  font-weight: normal;
  line-height: 1.5;
  color: ${Colors.blueGrey};
  text-align: center;
`

export const EmptyScreen = ({ title, logo, subtitle }) => (
  <Wrapper>
    <Logo src={logo} />
      { subtitle ?
        <>
          <Heading bold>
            <FormattedMessage id={title} />
          </Heading>
          <Subtitle>
            <FormattedMessage id={subtitle} />
          </Subtitle>
        </>
        :
        <Heading>
          <FormattedMessage id={title} />
        </Heading>
      }
  </Wrapper>
)

import 'moment/locale/fr'
import 'moment/locale/de'
import moment from "moment";
import momentTZ from "moment-timezone";

export const changeLocale = (locale) => {
  moment.locale(locale)
  momentTZ.defineLocale(locale, moment.localeData()._config)
  momentTZ.locale(locale)
}

import React from 'react'
import { Switch, Route } from 'react-router-dom'
import styled from 'styled-components'
import { Colors } from 'constants/colors'
import { ChangePassword } from '../../../common/Settings'
import { Main } from './Main'
import { Language } from './Language'
import { Timezones } from './Timezones'
import { Notifications } from './Notifications'
import { ParametersApplication } from './ParametersApplication'
import { AboutSettings } from './AboutSettings'
import { TutorialVideo } from './TutorialVideo'
import { ConnexionAuthentification } from './ConnexionAuthentification'
import { RequestDeletion } from './RequestDeletion';
import { PrivacyPolicy } from '../../../common/PrivacyPolicy'
import { HistoryPage } from '../../../common/History'
import { history } from '../../../../utils/history'
import {EmailManagement} from "../Profile/Edit/EmailManagement";
import {WebView} from "./Webview";
import {LimitationTE, LimitationTe} from "./LimitationTE";
import {Lgc} from "./Lgc";

export const Settings = () => (
  <Switch>
    <Route
      path='/panel/settings/parameters'
      component={ParametersApplication}
    />
    <Route
      path='/panel/settings/connexion-authentification'
      component={ConnexionAuthentification}
    />
    <Route
      path='/panel/settings/about-settings'
      component={AboutSettings}
    />
    <Route
        path='/panel/settings/tutorials'
        component={TutorialVideo}
    />
    <Route
      path='/panel/settings/change-password'
      render={props => <ChangePassword {...props} to='/panel/settings' />}
    />
    <Route
    path='/panel/profile/edit/email-management'
    exact
    render={props => <EmailManagement {...props} />}
    />
    <Route
      path='/panel/settings/language'
      component={Language}
    />
    <Route
      path='/panel/settings/limitation/te'
      component={LimitationTE}
    />
    <Route
      path='/panel/settings/lgc'
      component={Lgc}
    />
    <Route
      path='/panel/settings/timezone'
      component={Timezones}
    />
    <Route
      path='/panel/settings/notifications'
      component={Notifications}
    />
    <Route
      path='/panel/settings/request-deletion'
      component={RequestDeletion}
    />
    <Route
      path='/panel/settings/privacy-policy'
      exact
      render={props => <Wrapper><PrivacyPolicy {...props} /></Wrapper>}
    />
    <Route
      path='/panel/settings/privacy-policy/history'
      render={({ ...props }) => (
        <Wrapper>
          <HistoryPage
            onBackIconClick={() => history.push('/panel/settings/privacy-policy')}
            {...props}
          />
        </Wrapper>
      )}
    />
    <Route
      path='/panel/settings'
      component={Main}
    />
  </Switch>
)

const Wrapper = styled.div`
  background-color: ${Colors.background};
  min-height: 100%;
`

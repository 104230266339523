import { createAction, handleActions, combineActions } from 'redux-actions'
import axios from 'axios'
import { mapHealthRecords } from '../../helpers/healthRecords'

//#region Actions
export const fetchHealthRecordsRequest = createAction('FETCH_HEALTH_RECORDS_REQUEST')
export const fetchHealthRecordsSuccess = createAction('FETCH_HEALTH_RECORDS_SUCCESS')
export const fetchHealthRecordsFailure = createAction('FETCH_HEALTH_RECORDS_FAILURE')

export const fetchHealthRecords = consultationId => (dispatch, getState) => {
  dispatch(fetchHealthRecordsRequest())

  // TODO: add function attribute
  const { id } = getState().auth

  const url = consultationId
    ? `/general/consultation/${consultationId}/healthRecord`
    : `/general/patient/${id}/healthRecord`

  return axios(url, { params: { include: 'attachment,contraindication' } })
    .then(res => dispatch(fetchHealthRecordsSuccess(res.data.data)))
    .catch((err) => {
      dispatch(fetchHealthRecordsFailure(err))
    })
}

export const editHealthRecordsRequest = createAction('EDIT_HEALTH_RECORDS_REQUEST')
export const editHealthRecordsSuccess = createAction('EDIT_HEALTH_RECORDS_SUCCESS')
export const editHealthRecordsFailure = createAction('EDIT_HEALTH_RECORDS_FAILURE')

export const editHealthRecords = values => (dispatch, getState) => {
  dispatch(editHealthRecordsRequest())

  // TODO: add function attribute
  const { id } = getState().auth
  const data = values
  const formData = new FormData()

  Object.keys(data).forEach((item) => {
    if (item === 'diseases') {
      data[item].map(v => formData.append('diseases[]', v))
    } else if (item === 'contraindication') {
      data[item].map(v => formData.append('contraindication[]', v))
    } else {
      formData.append(item, data[item])
    }
  })

  return axios(`/general/patient/${id}/healthRecord?include=attachment`, {
    method: 'POST',
    data: formData,
  })
    .then(res => dispatch(editHealthRecordsSuccess(res.data.data)))
    .catch((err) => {
      dispatch(editHealthRecordsFailure(err))
    })
}

export const createHealthRecordsAttachmentRequest = createAction('CREATE_HEALTH_RECORDS_ATTACHMENT_REQUEST')
export const createHealthRecordsAttachmentSuccess = createAction('CREATE_HEALTH_RECORDS_ATTACHMENT_SUCCESS')
export const createHealthRecordsAttachmentFailure = createAction('CREATE_HEALTH_RECORDS_ATTACHMENT_FAILURE')

export const createHealthRecordsAttachment = values => (dispatch, getStore) => {
  dispatch(createHealthRecordsAttachmentRequest(values))
  const { id } = getStore().auth
  const formData = new FormData()

  // TODO: change payload to object in diplomas form
  formData.append('file', values.file[0])
  formData.append('type', values.type)

  if (values.custom_name) {
    formData.append('custom_name', values.custom_name)
  }

  return axios(`/general/patient/${id}/healthRecord/attachment`, {
    method: 'POST',
    data: formData,
  })
    .then(() => dispatch(fetchHealthRecords()))
    .then(res => dispatch(createHealthRecordsAttachmentSuccess(res.data)))
    .catch((err) => {
      dispatch(createHealthRecordsAttachmentFailure(err))
    })
}

export const deleteHealthRecordsAttachmentRequest = createAction('DELETE_HEALTH_RECORDS_ATTACHMENT_REQUEST')
export const deleteHealthRecordsAttachmentSuccess = createAction('DELETE_HEALTH_RECORDS_ATTACHMENT_SUCCESS')
export const deleteHealthRecordsAttachmentFailure = createAction('DELETE_HEALTH_RECORDS_ATTACHMENT_FAILURE')

export const deleteHealthRecordsAttachment = value => (dispatch, getStore) => {
  dispatch(deleteHealthRecordsAttachmentRequest(value))
  const { id } = getStore().auth

  return axios(`/general/patient/${id}/healthRecord/attachment/${value}`, {
    method: 'DELETE',
  })
    .then(() => dispatch(fetchHealthRecords()))
    .then(res => dispatch(deleteHealthRecordsAttachmentSuccess(res.data)))
    .catch((err) => {
      dispatch(deleteHealthRecordsAttachmentFailure(err))
    })
}

export const editHealthRecordsAttachmentRequest = createAction('EDIT_HEALTH_RECORDS_ATTACHMENT_REQUEST')
export const editHealthRecordsAttachmentSuccess = createAction('EDIT_HEALTH_RECORDS_ATTACHMENT_SUCCESS')
export const editHealthRecordsAttachmentFailure = createAction('EDIT_HEALTH_RECORDS_ATTACHMENT_FAILURE')

export const editHealthRecordsAttachment = value => (dispatch, getStore) => {
  dispatch(editHealthRecordsAttachmentRequest(value))
  const { id } = getStore().auth

  return axios(`/general/patient/${id}/healthRecord/attachment/${value}`, {
    method: 'PATCH',
  })
    .then(() => dispatch(fetchHealthRecords()))
    .then(res => dispatch(editHealthRecordsAttachmentSuccess(res.data)))
    .catch((err) => {
      dispatch(editHealthRecordsAttachmentFailure(err))
    })
}

export const fetchAllergiesRequest = createAction('FETCH_ALLERGIES_REQUEST')
export const fetchAllergiesSuccess = createAction('FETCH_ALLERGIES_SUCCESS')
export const fetchAllergiesFailure = createAction('FETCH_ALLERGIES_FAILURE')

export const fetchAllergies = () => (dispatch) => {
  dispatch(fetchAllergiesRequest())

  return axios('/general/patient/healthRecordAllergy')
    .then(res => dispatch(fetchAllergiesSuccess(res.data.data)))
    .catch((err) => {
      dispatch(fetchAllergiesFailure(err))
    })
}

export const addAllergyRequest = createAction('ADD_ALLERGY_REQUEST')
export const addAllergySuccess = createAction('ADD_ALLERGY_SUCCESS')
export const addAllergyFailure = createAction('ADD_ALLERGY_FAILURE')

export const addAllergy = payload => (dispatch) => {
  dispatch(addAllergyRequest())

  return axios.post('/general/patient/healthRecordAllergy', payload)
    .then(() => dispatch(addAllergySuccess()))
    .then(() => dispatch(fetchAllergies()))
    .catch((err) => {
      dispatch(addAllergyFailure(err))
    })
}

export const deleteAllergyRequest = createAction('DELETE_ALLERGY_REQUEST')
export const deleteAllergySuccess = createAction('DELETE_ALLERGY_SUCCESS')
export const deleteAllergyFailure = createAction('DELETE_ALLERGY_FAILURE')

export const deleteAllergy = payload => (dispatch) => {
  dispatch(deleteAllergyRequest())
  const { id } = payload

  return axios.delete(`/general/patient/healthRecordAllergy/${id}`)
    .then(() => dispatch(deleteAllergySuccess()))
    .then(() => dispatch(fetchAllergies()))
    .catch((err) => {
      dispatch(deleteAllergyFailure(err))
    })
}
//#endregion

//#region Reducers
export const healthRecords = handleActions({
  [combineActions(
    fetchHealthRecordsSuccess,
    editHealthRecordsSuccess,

    // I don't know is it right
  )]: (state, action) => mapHealthRecords(action.payload, state),
  [fetchHealthRecordsFailure]: () => ({}),
  [fetchAllergiesSuccess]: (state, action) => ({ ...state, allergies: action.payload }),
}, {})
//#endregion

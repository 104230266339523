import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import queryString from 'query-string'
import { compose, mapProps } from 'recompose'
import { connect } from 'react-redux'
import { Form, Field } from 'react-final-form'
import { TextField } from 'common/UiKit/TextField'
import { FormattedMessage } from 'react-intl'
import { Form as FormWindow } from '../Form'
import { setNewPasswordPreRegistrated } from '../../../../redux/modules/auth'
import { externalFormSubmit } from '../../../../helpers/externalFormSubmit'
import { history } from '../../../../utils/history'
import {PasswordFieldComponent} from "../PasswordField";
import {validatePassWordConditions} from "../../../../utils/validatePassWordConditions";

const Wrapper = styled.div`
  display: flex;
  justify-content: center;
  width: 100%;
  height: 100%;
`

const TextFieldStyled = styled(TextField)`
  && {
    margin-bottom: 24px;
    label {
      max-width: calc(100% - 40px) !important;
    }
  }
`
const FieldStyled = styled(Field)`
  display: flex;
  align-self: center;
`

const validate = (values) => {
  return validatePassWordConditions(values.password,values.confirmation,"password","confirmation")
}

const NewPasswordPreRegistratedComponent = ({
                                loading,
                                onButtonClick,
                                onSubmit,
                                onIconClick,
                              }) => {

  return (
      <Wrapper>
        <Form
            onSubmit={onSubmit}
            validate={validate}
            subscription={{}}
            render={({ handleSubmit }) => (
                <form onSubmit={handleSubmit} id='new_password_pre_registrated' style={{width: '100%'}}>
                  <FormWindow
                      button={<FormattedMessage id='global.save' />}
                      heading={<FormattedMessage id='auth.new.password' />}
                      loading={loading}
                      onButtonClick={onButtonClick}
                      onIconClick={onIconClick}
                      textStyle={{
                        display: 'flex',
                        justifyContent: 'center',
                        fontWeight: 'bold',
                      }}
                      text={<FormattedMessage id='auth.new.password.description' />}
                  >
                    <PasswordFieldComponent
                        name='password'
                        label = 'auth.create.password'
                    />
                    <FieldStyled
                        name='confirmation'
                        label={<FormattedMessage id='auth.repeat.password' />}
                        password
                        component={TextFieldStyled}
                    />
                  </FormWindow>
                </form>
            )}
        />
      </Wrapper>
  )
}

NewPasswordPreRegistratedComponent.propTypes = {
  loading: PropTypes.bool.isRequired,
  onButtonClick: PropTypes.func.isRequired,
  onSubmit: PropTypes.func.isRequired,
  onIconClick: PropTypes.func.isRequired,
}

const enhance = compose(
    connect(state => ({ loading: state.loading.setNewPasswordPreRegistrated }), { setNewPasswordPreRegistrated }),
    mapProps(props => ({
      ...props,
      onButtonClick: () => externalFormSubmit('new_password_pre_registrated'),
      onIconClick: () => history.push('/auth'),
      onSubmit: values => props.setNewPasswordPreRegistrated({
        values: {
          ...values,
          token: queryString.parse(props.location.search).token,
        },
      }),
    })),
)

export const NewPasswordPreRegistrated = enhance(NewPasswordPreRegistratedComponent)

import idx from 'idx'
import { CONSULTATION_TYPES } from '../../constants/consultation'

export const patientSelector = consultation => (state) => {
  const data = consultation || state.consultations.consultation

  if (idx(data, _ => _.attributes.type === CONSULTATION_TYPES.telexpertise || _.attributes.type === CONSULTATION_TYPES.TER || _.attributes.type === CONSULTATION_TYPES.TER_ESS || _.attributes.type === CONSULTATION_TYPES.TCA)) {
    if(
        idx(data, _ => _.attributes.type === CONSULTATION_TYPES.TER_ESS)
        && idx(data, _ => _.relationships.doctorCreator.data.attributes.user_id === state.auth.attributes.user_id)
    ){
        return data.relationships.team.data.attributes.name;
    }

    if (idx(data, _ => _.relationships.doctor.data.id === state.auth.id)) {
      return idx(data, _ => _.relationships.additionalMember.data[0].attributes.doctor)
    }

    if (idx(data, _ => _.attributes.type === CONSULTATION_TYPES.TER_ESS) && (idx(data, _ => _.relationships.doctorCreator.data.attributes.user_id !== state.auth.attributes.user_id) || idx(data, _ => _.relationships.doctor.data.id !== state.auth.id))) {
      return idx(data, _ => _.relationships.additionalMember.data[0].attributes.doctor)
    }

    return idx(data, _ => _.relationships.doctor.data.attributes)
  }

  return idx(data, _ => _.relationships.patient.data.attributes)
}

import React from 'react'
import {LoadingButton as MuiLoadingButton} from "@mui/lab";
import PropTypes from "prop-types";
import './LoadingButton.css'

const LoadingButton = ({ style, variant, loading, children , ...rest}) => {
  const buttonStyles = {
    textTransform: 'none',
    ...style,
  }
  return (
    <MuiLoadingButton variant={variant} loading={loading} style={buttonStyles} {...rest}>{children}</MuiLoadingButton>
  )
}

LoadingButton.defaultProps = {
  style: {},
  variant: 'contained',
  children: [],
}

LoadingButton.propTypes = {
  style: PropTypes.object,
}

export default LoadingButton
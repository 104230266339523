import React, {useState} from 'react'
import conexLogo from '../../../../src/images/auth/long-logo-conex-sante.png'
import {Field, Form} from "react-final-form";
import { CheckboxField } from '../../common/CheckboxField'
import {FormattedMessage} from "react-intl";
import {TextButton} from "../../common/UiKit/TextButton";
import {sendUnsubscribeCampaign} from "../../../redux/modules/unsubscribe";
import queryString from "query-string";
import Alert from "@mui/material/Alert";
import Snackbar from "@mui/material/Snackbar";
import styled from "styled-components";

export const Campaign = () => {

  const [showSuccessSnackBar, setShowSuccessSnackBar] = useState(false)
  const [showErrorSnackBar, setShowErrorSnackBar] = useState(false)


  const onSubmit = (values) => {
    const decodeToken = queryString.parse(location.search).token
    if(values.unsubscribe){
      sendUnsubscribeCampaign(decodeToken).
        then( res =>
          setShowSuccessSnackBar(true)
        )
        .catch( err =>
          setShowErrorSnackBar(true)
        )
     }
  }

  return (
      <>
        <Snackbar open={showSuccessSnackBar} autoHideDuration={5000} anchorOrigin={{ vertical: 'top', horizontal: 'center' }} onClose={() =>  setShowSuccessSnackBar(false)}>
          <Alert  severity="success" sx={{ width: '100%' }} onClose={() => setShowSuccessSnackBar(false)}>
            <FormattedMessage id="gdpr.unsubscribe.campaign.success"/>
          </Alert>
        </Snackbar>
        <Snackbar open={showErrorSnackBar} autoHideDuration={5000} anchorOrigin={{ vertical: 'top', horizontal: 'center' }} onClose={() =>  setShowErrorSnackBar(false)}>
          <Alert  severity="success" sx={{ width: '100%' }} onClose={() => setShowErrorSnackBar(false)}>
            <FormattedMessage id="gdpr.unsubscribe.campaign.error"/>
          </Alert>
        </Snackbar>
        <div>
          <Image src={conexLogo} />
          <Form
              onSubmit={onSubmit}
              render={({ handleSubmit }) => (
                  <form onSubmit={handleSubmit}>
                    <div>
                      <FieldDiv>
                        <Field
                            name='unsubscribe'
                            component={CheckboxField}
                            color='primary'
                            type='checkbox'
                            label={<FormattedMessage id="gdpr.unsubscribe.campaign"/>}
                        />
                      </FieldDiv>
                      <CheckboxDiv >
                        <TextButton
                            color='primary'
                            type='submit'
                            style={{textTransform: 'none'}}
                        >
                          <FormattedMessage id={'gdpr.unsubscribe.campaign.submit'}/>
                        </TextButton>
                      </CheckboxDiv>
                    </div>
                  </form>
              )}/>
        </div>
      </>
  )
}

// --------------------------------- Styles CSS -------------------------------------

const Image = styled.img`
    display: block;
    margin: auto;
`


const FieldDiv = styled.div`
    display: block;
    text-align: center;
    margin-top: 5%;
`

const CheckboxDiv = styled.div`
    display: block;
    text-align: center;
    margin-top: 2%;
`

import React from 'react'
import SearchIcon from '@mui/icons-material/Search'
import styled from 'styled-components'
import { Colors } from 'constants/colors'
import { useIntl } from 'react-intl'

export const SearchMonReseau = (
    { onSearch
    },
) => {
    const { formatMessage } = useIntl()

    return (
        <Wrapper>
            <SearchIcon />
            <Input
                onChange={e => onSearch(e.target.value)}
                placeholder={formatMessage({ id: 'global.search.reseau' })}
            />
        </Wrapper>
    )
}

const Wrapper = styled.div`
  position: relative;
  margin-top: 16px;
  margin-bottom: 16px;
  
  & > svg {
    position: absolute;
    left: 20px;
    top: 12px;
    fill: ${Colors.bluePurple};
  }
`

const Input = styled.input`
  border: 1px solid #6633FF;
  background-color: white;
  outline: none;
  height: 48px;
  padding: 16px 16px 16px 65px;
  box-sizing: border-box;
  width: 100%;
  font-size: 16px;
  font-weight: 500;
  color: ${Colors.dark};
  
  ::placeholder {
    color: ${Colors.blueGrey};
    font-size: 16px;
    font-weight: 500;
  }
  
`

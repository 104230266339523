import React from 'react';
import {Dialog} from "@mui/material";
import {FormattedMessage} from "react-intl";
import styled from "styled-components";
import {TextButton} from "../../common/UiKit/TextButton";
import {BUTTON_COLORS} from "../../../constants/colors";

const Content = styled.div`
  padding: 32px 48px;

  text-align: center;
  
 && {
  border: 3px solid #6633FF;
  border-radius: 30px;
}
`

const DialogStyled = styled(Dialog)`
  backdrop-filter: blur(3px);
  & > * {
      margin : auto;
      font-size : 20px;
  }
`

const Button = styled(TextButton)`
  && {
    background-color: ${BUTTON_COLORS.purple};
    min-width: 165px;
    height: 40px;
    font-weight: normal;
    margin: 0 auto 26px;
    color: white;
    text-transform: none;
  }
  &&:hover{
    background-color: ${BUTTON_COLORS.purpleHover};
  }
`

export const NotActivatedDialog = ({ dialogOpen, onCloseAction}) => (
  <DialogStyled
    open={dialogOpen}
    onClose={onCloseAction}
    fullWidth
    PaperProps={{
      style: { borderRadius: 30, minWidth: "500px" }
    }}
  >
    <Content>
      <p>
        Félicitations !
      </p>
      <p>
        Votre compte a bien été créé.
        <br/>
        Il va maintenant être validé par nos équipes.
        <br/>
        Vous recevrez un email dès l’opération terminée.
      </p>
      <br/>
      <Button onClick={(e) => onCloseAction(e)}>
        <FormattedMessage id='auth.back.welcome.page' />
      </Button>
    </Content>
  </DialogStyled>
)
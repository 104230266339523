import React from "react"
import {Skeleton} from "@mui/material";

const CustomLoaderRectangleAndCircle = () => {
    return (
        <div>
            <Skeleton variant="rectangular" height={100} style={{
              marginTop: '30px',
              borderRadius: '8px',
            }} />
        </div>
    );
}

export default CustomLoaderRectangleAndCircle
import React, {useEffect, useRef, useState} from 'react'
import styled from "styled-components";
import Button from "../UiKit/Button";
import {useDispatch, useSelector} from "react-redux";
import '@vonage/video-publisher/video-publisher';
import '@vonage/video-subscribers/video-subscribers';
import {
  callInProgress,
  otConnectionCreated,
  sessionCreated,
  terminateCall
} from "../../../redux/modules/call";
import '@opentok/client'
import Avatar from "@mui/material/Avatar";
import {Colors} from "../../../constants/colors";
import IconButton from "@mui/material/IconButton";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import {FormattedMessage} from "react-intl";
import moment from "moment-timezone";
import Grid from "@mui/material/Grid";
import defaultAvatar from "../../../images/panel/default-avatar.svg";
import {CircularProgress} from "@mui/material";
import {getAllParticipants} from "../../../helpers/consultations";
import CallEndIcon from "@mui/icons-material/CallEnd";
import {DeclineButton} from "../CallModal";
import UnfoldLess from "@mui/icons-material/UnfoldLess";
import UnfoldMore from "@mui/icons-material/UnfoldMore";

function opentokParamsValid(params, checkToken = false) {
  return params?.attributes && params.attributes?.session_id && (!checkToken || params.attributes?.session_token);
}

const Visio = () => {
  const dispatch = useDispatch()
  const opentok = useSelector(state => state.consultations.opentok)
  const existingSession = useSelector(state => state.visio.session)
  const callAccepted = useSelector(state => state.visio.accepted)
  const isCallInProgress = useSelector(state => state.visio.inprogress)
  const connections = useSelector(state => state.visio.connections)
  const [reduced, setReduced] = useState(false)
  const waitingMode = useSelector(state => state.visio.connections <= 1)

  const publisher = useRef(null)
  const subscribers = useRef(null)

  const onReduce = () => {
    setReduced(!reduced)
  }

  const onConnectionsUpdate = (e) => {
    dispatch(otConnectionCreated(e.target?.connections?.length()))
  }

  const onSessionDisconnected = (e) => {
    if (publisher?.current?.session) {
      publisher.current.session = {}
      publisher.current.token = null;
    }
    if (subscribers?.current?.session) {
      subscribers.current.session = {}
      subscribers.current.token = null;
    }
    // Cleaning up event listeners on the session
    // => The OT library keeps sessions cached and reusing a session will increase event listeners
    [
      'connectionCreated',
      'connectionDestroyed',
      'sessionConnected',
      'sessionDisconnected',
      'streamCreated',
      'streamDestroyed',
      'signal',
      'archiveStarted',
      'archiveStopped',
      'muteForced',
      'sessionReconnected',
      'sessionReconnecting',
      'streamPropertyChanged',
    ].forEach(eventType => e.target.off(eventType))
  }

  useEffect(() => {
    if (!!opentok?.params && opentokParamsValid(opentok.params) && !isCallInProgress && callAccepted && !existingSession) {
      const session = OT.initSession(opentok.params?.attributes?.api_key, opentok.params?.attributes?.session_id)
      session.on('connectionCreated', onConnectionsUpdate)
      session.on('connectionDestroyed', onConnectionsUpdate)
      session.once('sessionDisconnected', onSessionDisconnected)
      dispatch(sessionCreated(session))
    }
  }, [opentok, callAccepted])

  useEffect(() => {
    if (existingSession != null && opentokParamsValid(opentok.params, true) && !isCallInProgress && callAccepted) {
      publisher.current.session = existingSession
      publisher.current.token = opentok.params.attributes.session_token
      subscribers.current.session = existingSession
      subscribers.current.token = opentok.params.attributes.session_token
      publisher.current.style.display = 'block'
      dispatch(callInProgress(true))
    }
  }, [existingSession, opentok, isCallInProgress, callAccepted])

  useEffect(() => {
    if (publisher?.current?.style) {
      if (waitingMode) {
        publisher.current.style.position = 'relative'
        publisher.current.style.width = '100%'
        publisher.current.style.height = '100%'
      } else {
        publisher.current.style.position = 'absolute'
        publisher.current.style.right = '10px'
        publisher.current.style.bottom = '10px'
        publisher.current.style.width = '180px'
        publisher.current.style.height = '130px'
      }
    }
  }, [connections, publisher])

  useEffect(() => {
    if (subscribers?.current?.style) {
      if (waitingMode) {
        subscribers.current.style.width = '0px'
        subscribers.current.style.height = '0px'
      } else {
        subscribers.current.style.width = '100%'
        subscribers.current.style.height = '100%'
      }
    }
  }, [connections, subscribers])

  const component = useRef(null)

  useEffect(() => {
    if (component.current) {
      if (reduced) {
        component.current.style.top = ''
        component.current.style.left = '0'
        component.current.style.bottom = '16px'
      } else {
        component.current.style.left = '0'
        component.current.style.top = '0'
      }
    }
  }, [reduced])

  const origin = {x: 0, y: 0, movX: 0, movY: 0}

  const handleDragStart = (e) => {
    let rect = component.current.getBoundingClientRect()
    origin.x = rect.left
    origin.y = rect.top
    origin.movX = e.screenX
    origin.movY = e.screenY
    origin.lastX = 0
    origin.lastY = 0
  }

  const handleDrag = (e) => {
    const diffX = e.screenX - origin.movX
    const diffY =  e.screenY - origin.movY
    if (
      Math.abs(origin.lastX) - Math.abs(origin.x + diffX) < 100 &&
      Math.abs(origin.lastY) - Math.abs(origin.y + diffY) < 100)
    {
      origin.lastX = origin.x + diffX
      origin.lastY = origin.y + diffY
      component.current.style.left = `${origin.lastX}px`
      component.current.style.top = `${origin.lastY}px`
    }
  }

  return (
    <Wrapper reduced={reduced} displayed={callAccepted || isCallInProgress} ref={component}>
      <MoverHandle
        reduced={reduced}
        onDrag={handleDrag}
        onDragStart={handleDragStart}
        draggable={true}
      />
      <Content reduced={reduced}>
        {
          waitingMode && !reduced && (
            <Header>
              <Back>
                <IconButton size='large' onClick={() => dispatch(terminateCall())}>
                  <ArrowBackIcon />
                </IconButton>
                <FormattedMessage id='waiting.back' tagName='span' />
              </Back>
              <Date>
                {moment().format('dddd, MMMM DD')}
                {' | '}
                {moment().format('HH:mm')}
              </Date>
            </Header>
          )
        }
        <Grid container style={{ overflow: reduced ? 'hidden' : 'scroll' }}>
          { waitingMode && callAccepted && !reduced && ( <WaitingInfo /> ) }
          {
            !!existingSession && (
              <Publisher reduced={reduced} fullscreen={!waitingMode} onDrag={handleDrag} onDragStart={handleDragStart}>
                <ButtonsContainer>
                  <Buttons>
                    <Button
                      style={{ borderRadius: '50px', minWidth: '40px', width: '40px', height: '40px' }}
                      onClick={onReduce}
                    >
                      { reduced ? <UnfoldMore /> : <UnfoldLess/>}
                    </Button>
                    <DeclineButton onClick={() => dispatch(terminateCall())}><CallEndIcon /></DeclineButton>
                  </Buttons>
                </ButtonsContainer>
                <video-publisher
                  style={{
                    display: !waitingMode && reduced ? 'none' : 'block',
                    border: !reduced && !waitingMode ? `2px solid ${Colors.bluePurple}` : 'none',
                    borderRadius: !reduced ? '5px' : '0',
                    zIndex: '2000'
                  }}
                  ref={publisher}>
                </video-publisher>
                <SubscriberWrapper>
                  <video-subscribers
                    style={{
                      display: waitingMode ? 'none' : 'flex',
                      width: waitingMode ? '0px' : '100%',
                      height: waitingMode ? '0px' : '100%',
                    }}
                    ref={subscribers}>
                  </video-subscribers>
                </SubscriberWrapper>
              </Publisher>
            )
          }
        </Grid>
      </Content>
    </Wrapper>
  )
}

export default Visio

const WaitingInfo = () => {
  const myId = useSelector(state => state.auth.attributes.user_id)
  const users = useSelector(state => {
    let consultation = state.consultations?.consultation
    return getAllParticipants(consultation)
      .filter(item => ((item && item.attributes) ? item.attributes.user_id !== myId : false))
  })
  return (
    <Grid item xs={12} sm={12}>
      <MeetingInfo>
        <FormattedMessage id='waiting.title' tagName='h4'/>
        <div>
          {
            users.length
              ? users.map(item => (
                <Photo
                  src={item.attributes.photo || defaultAvatar}
                  key={item.id}
                />
              ))
              : null
          }
        </div>
        {
          users.length === 1 && (
            <Name>
              {users[0].attributes.first_name || ''}
              {' '}
              {users[0].attributes.last_name || ''}
            </Name>
          )
        }
        <Wait>
          <CircularProgress color='secondary' size={26}/>
          <FormattedMessage id='waiting.wait' tagName='div'/>
        </Wait>
      </MeetingInfo>
    </Grid>
  )
}

const SubscriberWrapper = styled.div`
  width: 100% !important;
  height: 100% !important;
  
  & > div {
    display: flex !important;
    
    & > div {
      flex-grow: 1;
    }
  }

  &, div {
    height: 100% !important;
    width: 100% !important;
  }
`

const ButtonsContainer = styled.div`
  position: absolute;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 4000;
`

const Buttons = styled.div`
  text-align: center;
  & > button {
    margin: 10px;
  }
`

const Publisher = styled.div`
  position: relative;
  height: ${ props => props.reduced ? '191px' : props.fullscreen ? '100vh' : '50vh' };
  width: ${ props => props.fullscreen ? '100%' : props.reduced ? '100%' : '45%' };
  margin: auto;
`

const Wrapper = styled.div`
  position: fixed;
  bottom: 0;
  left: 0;
  background-color: rgba(0, 0, 0, 0.1);
  z-index: 1060;
  display: ${props => props.displayed ? 'block' : 'none'};
  ${ props => {
    if (props.reduced) {
      return `
        height: 185px;
        width: 268px;
      `
    } else {
      return `
        width: 100%;
        top: 0;
        left: 0;
      `
    }
  }}
`

const MoverHandle = styled.div`
  height: 10px;
  background-color: ${Colors.dark};
  display: ${props => props.reduced ? 'block' : 'none'};
`

const Content = styled.div`
  position: relative;
  background-color: white;
  width: 100%;
  height: ${props => props.reduced ? '191px' : '100vh'};
`

const Header = styled.div`
  padding: 16px;
  border-bottom: 2px solid ${Colors.grey};
  align-items: center;
  display: flex;
  justify-content: space-between;
`

const Back = styled.div`
  & > span {
    text-transform: uppercase;
    font-size: 18px;
    font-weight: 500;
    color: ${Colors.dark};
    margin-left: 12px;
  }
  
  display: flex;
  align-items: center;
  cursor: pointer;
`

const Date = styled.div`
  font-size: 14px;
  line-height: 16px;
  color: #283044;
  text-transform: capitalize;
`

const MeetingInfo = styled.div`
  text-align: center;
  
  h4 {
    font-size: 32px;
    line-height: 38px;
    font-weight: 500;
    color: ${Colors.dark};
  }
  
  div {
    text-align: center;
  }
`

const Photo = styled(Avatar)`
  && {
    height: 44px;
    width: 44px;
    display: inline-block;
    margin: 0 -16px;
    border: 3px solid white;
  }
`

const Name = styled.div`
  font-size: 14px;
  line-height: 16px;
  color: ${Colors.dark};
`

const Wait = styled.div`
  padding: 16px 24px;
  color: ${Colors.tealish};
  text-transform: uppercase;
  will-change: transform;
  font-weight: 500;
  font-size: 16px;
  margin-top: 32px;
  
  & > div {
    margin-top: 18px;
  }
`

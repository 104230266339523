import { createAction, handleActions } from 'redux-actions'
import { combineReducers } from 'redux'
import axios from 'axios'
import idx from 'idx'

import { getFormData } from '../../../../helpers/getFormData'

//#region Actions
export const HOLIDAYS_ACTIONS = {
  fetchHolidaysRequest: 'FETCH_HOLIDAYS_REQUEST',
  fetchHolidaysSuccess: 'FETCH_HOLIDAYS_SUCCESS',
  fetchHolidaysFailure: 'FETCH_HOLIDAYS_FAILURE',

  setHolidaysRequest: 'SET_HOLIDAYS_REQUEST',
  setHolidaysSuccess: 'SET_HOLIDAYS_SUCCESS',
  setHolidaysFailure: 'SET_HOLIDAYS_FAILURE',

  deleteHolidaysRequest: 'DELETE_HOLIDAYS_REQUEST',
  deleteHolidaysSuccess: 'DELETE_HOLIDAYS_SUCCESS',
  deleteHolidaysFailure: 'DELETE_HOLIDAYS_FAILURE',
}

export const fetchHolidaysRequest = createAction(HOLIDAYS_ACTIONS.fetchHolidaysRequest)
export const fetchHolidaysSuccess = createAction(HOLIDAYS_ACTIONS.fetchHolidaysSuccess)
export const fetchHolidaysFailure = createAction(HOLIDAYS_ACTIONS.fetchHolidaysFailure)

export const setHolidaysRequest = createAction(HOLIDAYS_ACTIONS.setHolidaysRequest)
export const setHolidaysSuccess = createAction(HOLIDAYS_ACTIONS.setHolidaysSuccess)
export const setHolidaysFailure = createAction(HOLIDAYS_ACTIONS.setHolidaysFailure)

export const deleteHolidaysRequest = createAction(HOLIDAYS_ACTIONS.deleteHolidaysRequest)
export const deleteHolidaysSuccess = createAction(HOLIDAYS_ACTIONS.deleteHolidaysSuccess)
export const deleteHolidaysFailure = createAction(HOLIDAYS_ACTIONS.deleteHolidaysFailure)
//#endregion

//#region Reducer
const weekDays = handleActions({
  [fetchHolidaysSuccess]: (state, action) => action.payload.weekDays,
  [setHolidaysSuccess]: (state, action) => {
    const newWeekDays = action.payload.reduce((acc, holiday) => {
      if (holiday.attributes.weekDay) {
        return [...acc, { weekDay: holiday.attributes.weekDay, id: holiday.id }]
      }
      return acc
    }, [])

    return [...state, ...newWeekDays]
  },
  [deleteHolidaysSuccess]: (state, action) => state.filter(item => item.id !== action.payload.id),
}, [])

const dateTime = handleActions({
  [fetchHolidaysSuccess]: (state, action) => action.payload.dateTime,
  [setHolidaysSuccess]: (state, action) => {
    const newDateTime = action.payload.reduce((acc, holiday) => {
      if (holiday.attributes.dateTime) {
        return [...acc, { dateTime: holiday.attributes.dateTime, id: holiday.id }]
      }
      return acc
    }, [])

    return [...state, ...newDateTime]
  },
  [deleteHolidaysSuccess]: (state, action) => state.filter(item => item.id !== action.payload.id),
}, [])

const fetching = handleActions({
  [setHolidaysRequest]: (state, { payload }) => (payload.dateTime
    ? [...state, payload.dateTime]
    : state),
  [setHolidaysSuccess]: (state, { payload }) => {
    const { dateTime } = payload[0].attributes
    if (dateTime) {
      return state.filter(item => item !== dateTime)
    }
    return state
  },
  [deleteHolidaysRequest]: (state, { payload }) => (payload.dateTime
    ? [...state, payload.dateTime]
    : state),
  [deleteHolidaysSuccess]: (state, { payload }) => {
    const { dateTime } = payload
    if (dateTime) {
      return state.filter(item => item !== dateTime)
    }
    return state
  },
}, [])

export const holidaysReducer = combineReducers({
  weekDays,
  dateTime,
  fetching,
})
//#endregion

//#region Thunks
export const fetchHolidays = () => (dispatch) => {
  dispatch(fetchHolidaysRequest())

  return axios('/api/doctor/holiday/list')
    .then((response) => {
      const holidays = idx(response, _ => _.data.data) || []
      const weekDays = holidays.reduce((acc, holiday) => {
        if (holiday.attributes.weekDay) {
          return [...acc, { weekDay: holiday.attributes.weekDay, id: holiday.id }]
        }
        return acc
      }, [])
      const dateTime = holidays.reduce((acc, holiday) => {
        if (holiday.attributes.dateTime) {
          return [...acc, { dateTime: holiday.attributes.dateTime, id: holiday.id }]
        }
        return acc
      }, [])

      dispatch(fetchHolidaysSuccess({ weekDays, dateTime }))
    })
    .catch(err => dispatch(fetchHolidaysFailure(err)))
}

export const setHolidays = data => (dispatch) => {
  dispatch(setHolidaysRequest(data))

  return axios('/api/doctor/holiday', {
    method: 'POST',
    data: getFormData(data),
  })
    .then(response => dispatch(setHolidaysSuccess(response.data.data)))
    .catch(err => dispatch(setHolidaysFailure(err)))
}

export const deleteHoliday = item => (dispatch) => {
  dispatch(deleteHolidaysRequest(item))

  return axios(`/api/doctor/holiday/${item.id}`, {
    method: 'DELETE',
  })
    .then(() => dispatch(deleteHolidaysSuccess(item)))
    .catch(err => dispatch(deleteHolidaysFailure(err)))
}
//#endregion

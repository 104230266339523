import React from 'react'
import styled from 'styled-components'
import {connect, useSelector} from 'react-redux'
import { compose } from 'recompose'
import PropTypes from 'prop-types'
import { Colors } from 'constants/colors'
import { logOut } from '../../../../../redux/modules/auth'
import { setTab } from '../../../../../redux/modules/consultations'
import { Picker } from './Picker'
import { Past } from './Past'
import { Upcoming } from './Upcoming'
import { NotificationsButton } from '../../../../common/Page/Notifications'
import { PanelBar } from '../../../../common/PanelBar'
import { FillProfileStub } from '../../FillProfileStub'
import consultationLogo from '../../../../../images/panel/consultation/consultation-logo.svg'
import { Page } from '../../../../common/Page'
import Skeleton from "@mui/material/Skeleton";


const Wrapper = styled.div`

`

const ListComponent = ({
  id,
  logOut,
  tab,
  setTab,
  filled,
  match
}) => {
  const myUserStatus = useSelector(state => state.auth.attributes.status)
  const isConciliumInUrl = match.url === '/panel/consultations'
  return (
    <Wrapper>
      <Page
        label={isConciliumInUrl ? 'concilium.title' : 'teleconsultations.title'}
        id={id}
        fullWidth
        onLogout={logOut}
      >
        {
        filled && (myUserStatus !== 'LIMITED' && myUserStatus !== 'PRE_REGISTRATED')
          ? (
            <>
              <Picker
                tab={tab}
                changeTab={(e, v) => setTab(v)}
              />
                {tab === 0 && <Upcoming isConciliumInUrl={isConciliumInUrl}/>}
              {tab === 1 && <Past isConciliumInUrl={isConciliumInUrl}/>}
            </>
          )
          : <FillProfileStub image={consultationLogo} heading='teleconsultation.title' userStatus={myUserStatus} />
      }
      </Page>
    </Wrapper>
  )
}

ListComponent.propTypes = {
  id: PropTypes.string.isRequired,
  logOut: PropTypes.func.isRequired,
  tab: PropTypes.number.isRequired,
}

const enhance = compose(
  connect(state => ({
    id: state.auth.id,
    tab: state.consultations.tab,
    filled: !!state.auth.attributes.credentials.rpps || !!state.auth.attributes.credentials.adeli || !!state.auth.attributes.credentials.orderNumber,
  }), { logOut, setTab }),
)
export const List = enhance(ListComponent)

import React, { useEffect, useState } from 'react';
import {FormattedMessage} from 'react-intl'
import styled, { keyframes } from 'styled-components'
import { Page } from '../../../common/Page'
import {history} from "../../../../utils/history";
import get from "lodash/get";
import {FavoriteTeamCardSearch} from "../../../common/TeamCard/FavoriteTeamCardSearch";
import {FavoriteDoctorCardSearch} from "../../../common/DoctorCard/FavoriteDoctorCardSearch";
import {useDispatch, useSelector} from "react-redux";
import {fetchRecentContacts} from "../../../../redux/modules/recentContact";
import {Paper} from "../../../common/UiKit/Paper";
import {StyledTextButton} from "../../../common/UiKit/TextButton";
import stethoscope from "../../../../images/icons/stethoscope.svg"
import logo_structure from "../../../../images/doctors/structures/logo_structure.png"
import logo_ess from "../Community/ROSO/images/Logo_ESS.png"
import logo_essp from "../Community/ROSO/images/Logo_ESSP.png";
import {Colors} from "../../../../constants/colors";
import {InstantSearchBar} from "../../../common/Search/instantSearchBar";

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  height: calc(100vh - 56px - 100px);
  padding: 40px 5%;
  min-width: 500px;
`

const Contacts = styled.div`
  width: 100%;
  margin-top: 40px;
  margin-bottom: 50px;
  display: flex;
  flex-direction: column;
  color: #585C65;
  font-family: Roboto, sans-serif;
  font-weight: 500;
  font-size: 22px;
`

const WrapperDialog = styled.div`
  margin-top: 10px;
  justify-content: space-between;
  align-content: space-between;
  width: auto;
  max-width: 457px;
`

const ContactsContent = styled.div`
  grid-template-rows: 1fr;
  grid-template-columns: repeat( auto-fit, minmax(315px, 1fr) );
  grid-gap: 40px;
  display: grid;
  width: 100%;
  margin: 10px auto auto;
`

const PaperStyled = styled(Paper)`
  padding: 10px 30px;
  position: relative;
  display: flex;
  align-items: center; 
  justify-content: space-evenly;
  flex-direction: column;
  font-size: 18px;
  color: #585C65;
  font-family: Roboto, sans-serif;
  font-weight: 500;
  height: 160px;
`

const ListStyled = styled.div`
  max-width: 100%;
  display: grid;
  grid-gap: 25px;
  grid-template-columns: repeat( auto-fit, minmax(0, 315px) );
  & > * {
    min-height: 0;
  }
  justify-content: center;
`

const WrapperTitle =styled.div`
  display: flex;
  font-size: 35px;
  width: 100%;
  color: #585C65;
  font-family: Roboto, sans-serif;
  font-weight: 500;  
  flex-wrap: wrap;
  margin-bottom: 50px;
`

const DynamicTitle = styled.div`
  height: 40px;
  overflow: hidden;
`

const StaticTitle = styled.span`
  color: #585C65;
  font-family: Roboto, sans-serif;
  white-space: pre-wrap;
`

const titleAnimation = keyframes`
  0% { transform: translateY(0%); }
  23.3333% { transform: translateY(-100%); }
  33.3333% { transform: translateY(-100%); }
  56.6666% { transform: translateY(-200%); }
  66.6666% { transform: translateY(-200%); }
  90% { transform: translateY(-300%); }
  100% { transform: translateY(-300%); }
`

const AnimatedText = styled.div`
  color: ${Colors.bluePurple};
  animation-name: ${titleAnimation};
  animation-duration: 6s;
  animation-iteration-count: infinite;
  animation-timing-function: ease;
  animation-delay: 2s;
  animation-direction: normal;
  animation-fill-mode: none;
  animation-play-state: running;
  min-width: 350px;
`

export const MainTeleexpertise = ({ match, location }) => {
  const dispatch = useDispatch()
  const isDoctor = useSelector(state => state.auth.type === 'Doctor')
  const lastContacts = useSelector(state => state.recentContacts.list.data)
  const relatedConsultation = useSelector(state => state.consultations?.consultation?.relationships?.relatedConsultation)
  const isDraft = useSelector(state => !!state.consultations?.draft?.consultation)

  const fetchRecentContactsList = () => {
    match.path.includes('teleconsultation') ? dispatch(fetchRecentContacts({limit: 3, all: 1})) : dispatch(fetchRecentContacts({limit: 3}));
  }

  useEffect(() => {
    fetchRecentContactsList();
  },[]);
  let label = 'concilium.new.TE'
  if (!!relatedConsultation && isDraft) {
    label = 'concilium.transfer.TE'
  } else if (match.path.includes('teleconsultation')) {
    label = 'concilium.new.TCA'
  } else if(match.path.includes('doctors')) {
    label = 'global.doctors'
  }

  const pushRoute = route => {
    if (window.location.pathname.startsWith('/panel/consultations')) {
      history.push('/panel/concilium/' + route)
    } else {
      history.push(route)
    }
  }

  return (
    <Page
      label={label}
      noWrapperPadding
      fullWidth
      onBackIconClick={() => history.goBack()}
    >
      <Wrapper>
        <WrapperTitle>
        <StaticTitle>
          <FormattedMessage id='concilium.start.search'/>
        </StaticTitle>
        <DynamicTitle>
          <AnimatedText>
            <FormattedMessage id='concilium.one.doctor'/>
          </AnimatedText>
          <AnimatedText>
            <FormattedMessage id='concilium.one.team'/>
          </AnimatedText>
          <AnimatedText>
            <FormattedMessage id='concilium.one.structure'/>
          </AnimatedText>
          <AnimatedText>
            <FormattedMessage id='concilium.one.doctor'/>
          </AnimatedText>
        </DynamicTitle>
        </WrapperTitle>
        <InstantSearchBar match={match} zconexUsers={true}/>
        <div style={{width: 'auto', marginBottom: 'auto', marginTop: '50px'}}>
        <ListStyled>
        <PaperStyled>
          <img src={stethoscope} style={{height: "60px"}}/>
          <FormattedMessage id='roso.team.specialties'/>
          <StyledTextButton
            color='white'
            onClick={() => pushRoute("specializations")}
            style={{height: '40px', width: '100%'}}
          >
            <FormattedMessage id='concilium.view.specialists'/>
          </StyledTextButton>
        </PaperStyled>

          <PaperStyled>
            <div style={{display: "flex", height: "60px", width: "100%", alignItems: "center"}}>
            <div style={{display: "flex", justifyContent: "space-evenly", width: "100%", alignItems: "center"}}>
              <img src={logo_ess} style={{height: "30px"}}/>
              &
              <img src={logo_essp} style={{height: "30px"}}/>
            </div>
            </div>
            <FormattedMessage id='concilium.teams'/>
            <StyledTextButton
              color='white'
              onClick={() => pushRoute("teams")}
              style={{height: '40px', width: '100%'}}
            >
              <FormattedMessage id='concilium.view.teams'/>
            </StyledTextButton>
          </PaperStyled>

          <PaperStyled>
            <img src={logo_structure} style={{height: "60px"}}/>
            <FormattedMessage id='concilium.structures'/>
            <StyledTextButton
              color='white'
              onClick={() => pushRoute("structures")}
              style={{height: '40px', width: '100%'}}
            >
              <FormattedMessage id='concilium.view.structures'/>
            </StyledTextButton>
          </PaperStyled>

        </ListStyled>
        </div>
        {lastContacts &&
          <Contacts>
            <FormattedMessage id='global.last.contacts'/>
            <ContactsContent>
              {lastContacts && lastContacts.map((data, index) => {
                const type = data.type
                const attributes = data.attributes

                if (type === "DoctorTeam") {
                  const specializations = get(data, 'relationships.skills.data', []);
                  return (
                    <WrapperDialog key={index}>
                      <FavoriteTeamCardSearch
                        id={attributes.id}
                        name={attributes.name}
                        specializations={specializations}
                        teamType={attributes.type}
                        link={`/panel/concilium/main/roso/detail/` + attributes.id}
                      />
                    </WrapperDialog>
                  )
                } else {
                  return (
                    <WrapperDialog key={index}>
                      <FavoriteDoctorCardSearch
                        photo={attributes.photo}
                        doctorId={attributes.doctor_id}
                        firstName={attributes.first_name}
                        lastName={attributes.last_name}
                        specialization={attributes.specialization}
                        specialization_type={attributes.specialization_type}
                        status_availability={attributes.status_availability}
                        link={(isDoctor ? `specializations/doctor/` : 'doctor/')  + attributes.doctor_id}
                        gender={attributes.gender}
                      />
                    </WrapperDialog>)
                }
              })
              }
            </ContactsContent>
          </Contacts>
        }
      </Wrapper>
    </Page>
  ); 
}

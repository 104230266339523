import React from 'react'
import { lifecycle } from 'recompose'
import { Input } from '../Input'

const checkError = (touched, error, submitError) => {
  if (touched) {
    if (error) return error
    if (submitError) return submitError
  }

  return null
}

const TextFieldComponent = ({
  input,
  nblines,
  value,
  variant,
  meta,
  disabled,
  ...rest
}) => (
  <Input
    {...rest}
    {...input}
    InputLabelProps={
      rest && rest.type === 'date' 
        ? { ...rest.InputLabelProps, shrink: true }
        : { ...rest.InputLabelProps }
}
    value={value ? value : rest && rest.type === 'date' && meta.touched ? undefined : input.value}
    variant={variant}
    nblines={nblines}
    disabled={disabled}
    onChange={disabled ? input.onChange('') : input.onChange}
    errorMessage={checkError(meta.touched, meta.error, meta.submitError)}
  />
)

const enhance = lifecycle({
  componentWillUnmount() {
    this.props.input.onChange('')
  },
})

export const TextField = enhance(TextFieldComponent)

import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import { LinkCard } from 'common/UiKit/LinkCard'
import { connect } from 'react-redux'
import idx from 'idx'

const Wrapper = styled.div`
  margin: 26px 0 0;

  & > * {
    margin-bottom: 16px;
  }
`

const LinkCardStyled = styled(LinkCard)`
  padding: 12px 16px 12px 24px;
`

const LinksComponent = ({ profileFilled }) => (
  <Wrapper>
    <LinkCardStyled
      label='profile.notifications'
      to='/panel/profile/settings/notifications'
    />
    {
      profileFilled && (
        <LinkCardStyled
          label='profile.language'
          to='/panel/profile/language'
        />
      )
    }
    <LinkCardStyled
      label='auth.phone.change'
      to='/panel/profile/phone/edit'
    />
    <LinkCardStyled
      label='global.help'
      to='/panel/profile/help'
    />
    <LinkCardStyled
      label='global.privacy-policy'
      to='/panel/profile/privacy-policy'
    />
    {/*
    <LinkCardStyled
      label='Others'
      to='/panel/profile/others'
    />
    */}
  </Wrapper>
)

LinksComponent.propTypes = {
  profileFilled: PropTypes.bool.isRequired,
}

const enhance = connect(
  state => ({ profileFilled: !!idx(state, _ => _.auth.attributes.first_name) }),
)

export const Links = enhance(LinksComponent)

import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types'
import { FormattedMessage } from 'react-intl'
import styled from 'styled-components'
import { Colors } from 'constants/colors'
import { useDispatch, useSelector } from 'react-redux';
import Button from '@mui/material/Button'

import { Wrapper, Heading } from '../../Info'
import { MethodSelectDialog } from './MethodSelectDialog'
import { getDoctorPrice } from '../../../../../../helpers/consultations';
import { fetchConsultation } from '../../../../../../redux/modules/consultations'

export const CompletePaymentMessage = ({ price }) => {
  const [isDialogOpened, toggleDialog] = useState(false)
  const dispatch = useDispatch()
  const needRefetch = useSelector(state => getDoctorPrice(state
    .consultations.consultation.relationships.doctor.data.attributes,
  state.consultations.consultation.attributes.type)) === price
  const consultationId = useSelector(state => state.consultations.consultation.id)
  useEffect(() => {
    if (needRefetch || price < 1) {
      dispatch(fetchConsultation(consultationId, null, true))
    }
  }, [dispatch, needRefetch])

  return (
    <WrapperStyled>
      <Content>
        <Heading>
          <FormattedMessage id='consultation.complete' />
        </Heading>
        <Sum>
          <FormattedMessage tagName='div' id='consultation.total.sum' />
          <span>
            {price.toFixed(0)}€
          </span>
        </Sum>
      </Content>
      <ButtonWrapper>
        <DownloadButton color='primary' onClick={() => toggleDialog(true)}>
          <FormattedMessage id='consultation.complete.payment' />
        </DownloadButton>
      </ButtonWrapper>
      {
        isDialogOpened && (
          <MethodSelectDialog
            onClose={() => toggleDialog(false)}
            open
            price={price}
          />
        )
      }
    </WrapperStyled>
  )
}

CompletePaymentMessage.propTypes = {
  price: PropTypes.number.isRequired,
}

//#region SC
const Sum = styled.div`
  display: flex;
  flex-flow: column nowrap;
  
  div {
    text-transform: uppercase;
    color: ${Colors.blueGrey};
    font-size: 14px;
  }
  
  span {
    font-weight: 500;
    color: ${Colors.bluePurple};
  }
`

const WrapperStyled = styled(Wrapper)`
  padding: 0;
  min-width: 320px;
`

const Content = styled.div`
  padding: 12px;
`

const ButtonWrapper = styled.div`
  border-top: solid 1px ${Colors.cloudyBlue};
  display: flex;

  button {
    font-weight: 500;
  }
`

const DownloadButton = styled(Button)`
  && {
    border-radius: 0 0 4px 0;
    font-size: 14px;
    width: 100%;
  }
`
//#endregion

import React, {useEffect, useState} from 'react'
import styled from 'styled-components'
import { Paper } from 'common/UiKit/Paper'
import { FormattedMessage } from 'react-intl'
import {useSelector} from "react-redux";
import idx from "idx";

const PaperStyled = styled(Paper)`
  padding: 0;
`

const EmailLine = styled.div`
  width: 100%;
  display: flex;
  padding: 10px 40px;
`

const TypeLabel = styled.div`
  min-width: 110px;
  font-weight: bold;
  padding: 10px 24px;
`

const Wrapper = styled.div`
  display: flex;
  width: 100%;
`


export const EmailsManagement = ({ changeButtonPath}) => {

  const mssante = useSelector( state => idx(state, _ => _.auth.relationships.mss.data));
  const [mss, setMss] = useState();
  const [apicrypt, setApicrypt] = useState()

  useEffect(() => {
    // If apicrypt and mss adresses already exists we set them with useState hooks
    if (mssante && Array.isArray(mssante)) {

      const elMSS = mssante.find(el => el.attributes.typeAddress === 'MSS')
      const elApicrypt = mssante.find(el => el.attributes.typeAddress === 'APICRYPT')

      if (elMSS) {
        setMss(elMSS.attributes)
      }
      if (elApicrypt) {
        setApicrypt(elApicrypt.attributes)
      }
    }
  }, [mssante])

  return (
    <PaperStyled
      heading='profile.email.mss'
      changeButtonPath={changeButtonPath}
    >
      <Wrapper>
        {mss ?
            <Wrapper>
              <TypeLabel>
                <FormattedMessage id='profile.email.type.mss' />
              </TypeLabel>
              <EmailLine>{mss.address}</EmailLine>
            </Wrapper>
        : ''}
      </Wrapper>
    </PaperStyled>
  ) 
}

import {calendarColors} from "../../constants/colors";

export const colorsByElement = (events) => {
  events.map(element => {
    if(element.type == "Available"){
      if (element.data.actionAvailable.TE && element.data.actionAvailable.TC && element.data.actionAvailable.TCA) {
        element.backgroundColor =  calendarColors.multipleDispo;
        element.borderColor =  calendarColors.multipleDispo;
      }
      else if (element.data.actionAvailable.TE && element.data.actionAvailable.TC) {
        element.backgroundColor =  calendarColors.multipleDispo;
        element.borderColor =  calendarColors.multipleDispo;
      }
      else if (element.data.actionAvailable.TE && element.data.actionAvailable.TCA) {
        element.backgroundColor = calendarColors.multipleDispo;
        element.borderColor =  calendarColors.multipleDispo;
      }
      else if (element.data.actionAvailable.TC && element.data.actionAvailable.TCA) {
        element.backgroundColor =  calendarColors.multipleDispo;
        element.borderColor =  calendarColors.multipleDispo;
      }
      else if (element.data.actionAvailable.TE) {
        element.backgroundColor =  calendarColors.availableTE
        element.borderColor =  calendarColors.availableTE
      }
      else if (element.data.actionAvailable.TC) {
        element.backgroundColor =  calendarColors.availableTC
        element.borderColor =  calendarColors.availableTC
      }
      else if (element.data.actionAvailable.TCI) {
        element.backgroundColor =  calendarColors.availableTC
        element.borderColor = calendarColors.availableTC
      }
      else if (element.data.actionAvailable.TCA) {
        element.backgroundColor = calendarColors.availableTCA
        element.borderColor = calendarColors.availableTCA
      }
    }
    else {
      element.backgroundColor = calendarColors.occupied
      element.borderColor = calendarColors.occupied
    }
  })
}
import React, { useEffect, useState } from 'react'
import axios from 'axios'
import MenuItem from '@mui/material/MenuItem'
import InputLabel from '@mui/material/InputLabel'
import FormControl from '@mui/material/FormControl'
import Select from '@mui/material/Select'
import OutlinedInput from '@mui/material/OutlinedInput'

import { FormattedMessage } from 'react-intl'
import styled from 'styled-components'

const Input = styled(Select)`
  && {
    background-color: white;
    justify-self: flex-end;
    min-width: 240px;
    margin-bottom: 24px;
  }
`

const Label = styled(InputLabel)`
  && {
    background-color: white;
    padding-right: 4px;
  }
`

export const TeamFilter = ({
  value, onChange, specialization, isFavorite, 
}) => {
  const [teams, setTeams] = useState([])
  useEffect(() => {
    let isMounted = true;
    axios(`/api/doctor/team?specialization=${specialization}${isFavorite ? '&byFavorites=1' : ''}`)
      .then(response => isMounted && setTeams(response.data.data))
    return () => { isMounted = false };
  }, [])

  return (
    <FormControl
      variant='outlined'
      size='small'
    >
      <Label htmlFor='team-select'>
        <FormattedMessage id='profile.team' />
      </Label>
      <Input
        value={value}
        variant='outlined'
        onChange={onChange}
        input={<OutlinedInput labelwidth={60} id='team-select' />}
      >
        <MenuItem value=''>
          <em>
            <FormattedMessage id='global.none' />
          </em>
        </MenuItem>
        {teams.map(team => (
          <MenuItem
            key={team.attributes.name}
            value={team.attributes.id}
          >
            {team.attributes.name}
          </MenuItem>
        ))}
      </Input>
    </FormControl>
  )
}

import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import { mapProps } from 'recompose'
import { Form } from 'react-final-form'
import moment from 'moment-timezone'
import { useIntl } from 'react-intl'
import { Paper } from '../Paper'
import { DayField } from './DayField'

const Wrapper = styled(Paper)`
  padding: 28px 24px;
`

const days = [
  'monday',
  'tuesday',
  'wednesday',
  'thursday',
  'friday',
  'saturday',
  'sunday',
]

const validate = (values) => {
  const errors = {}

  // Check work day duration
  const workDuration = days.reduce((sum, day) => {
    const time = ['from', 'to']
      .map(str => values[`${day}_${str}`])
      .filter(str => str)
      .map(str => moment(str, 'h:mm a'))

    const dayLength = time.length === 2 ? time[1] - time[0] : null

    // Check if finish time less or equal than start time
    if (dayLength === 0 || dayLength < 0) errors[`${day}_to`] = 'Finish time must be larger'

    return dayLength ? sum + dayLength : sum
  }, 0)

  if (workDuration <= 2.16e7) errors.hours = true

  return errors
}

const normalizeValues = values => (days.map(day => ({
  day,
  available: !!values[`${day}_from`] && !!values[`${day}_to`],
  startTime: values[`${day}_from`],
  finishTime: values[`${day}_to`],
}))
  .filter(v => v.available)
)

const normalizeInitialValues = (values) => {
  const result = {}
  values.forEach(({ attributes }) => {
    result[`${attributes.day}_from`] = attributes.startTime
    result[`${attributes.day}_to`] = attributes.finishTime
  })
  return result
}

const isFilled = (initialValues, day) => (Object.keys(normalizeInitialValues(initialValues))
  .some(key => key.includes(day))
)

const enhance = mapProps(props => ({
  ...props,
  onSubmit: values => props
    .onSubmit({ schedule: normalizeValues(values) })
    .then(() => props.afterSubmit()),
}))

export const ScheduleForm = enhance(({
  id,
  onSubmit,
  initialValues,
  disableShadow,
}) => {
  const { formatMessage } = useIntl()
  return (
    <Form
      onSubmit={onSubmit}
      validate={validate}
      subscription={{ errors: true }}
      initialValues={normalizeInitialValues(initialValues)}
      render={({ handleSubmit, errors }) => (
        <form onSubmit={handleSubmit} id={id}>
          <Wrapper disableShadow={disableShadow} warning={errors.hours ? formatMessage({ id: 'profile.schedule.warning' }) : ''}>
            {days.map(day => (
              <DayField
                day={day}
                key={day}
                filled={isFilled(initialValues, day)}
              />
            ))}
          </Wrapper>
        </form>
      )}
    />
  )
})

ScheduleForm.defaultProps = {
  initialValues: [],
  disableShadow: false,
}

ScheduleForm.propTypes = {
  id: PropTypes.string.isRequired,
  onSubmit: PropTypes.func.isRequired,
  afterSubmit: PropTypes.func.isRequired,
  initialValues: PropTypes.arrayOf(PropTypes.object),
  disableShadow: PropTypes.bool,
}

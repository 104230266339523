import { Colors } from 'constants/colors'
import { isMacOS } from './useragent'

export const makeScrollbar = () => {
  if (isMacOS()) return ''

  return `
    scrollbar-color: ${Colors.cloudyBlue} white;
    scrollbar-width: thin;

    &::-webkit-scrollbar {
      width: 4px;
    }

    &::-webkit-scrollbar-thumb {
      width: 4px;
      height: 85px;
      border-radius: 5px;
      background-color: ${Colors.cloudyBlue};
    }
  `
}

import React from 'react'
import styled from 'styled-components'
import { SecuredBckgImage } from '../SecuredImage'
import { STATUS_COLORS } from '../../../constants/colors'
import PropTypes from 'prop-types'

export const Avatar = ({ image, status, isDoctor, small, onClick, ...rest }) => {
  return (<StyledSecuredImage
    src={image}
    isDoctor={isDoctor}
    status={status}
    small={small}
    onClick={onClick}
    {...rest}
  />)
}

Avatar.defaultProps = {
  image: null,
  status: false,
  isDoctor: false,
  small: false,
  onClick: null,
}

Avatar.propTypes = {
  status: PropTypes.bool.isRequired,
  isDoctor: PropTypes.bool.isRequired,
  small: PropTypes.bool.isRequired,
  onClick: PropTypes.func,
}

const StyledSecuredImage = styled(SecuredBckgImage)`
  float: left;
  width: ${({ small }) => (small) ? '32px' : '65px'};
  height: ${({ small }) => (small) ? '32px' : '65px'};
  margin-bottom:  ${({ small }) => (small) ? '3px' : '14px'} ;
  margin-right:  ${({ small }) => (small) ? '3px' : '0'} ;
  cursor: pointer;
  border-radius: 100%;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  &:after {
    display: ${({ small, isDoctor }) => !isDoctor ? 'none' : small ? 'none' : ''};
    position : relative;
    content: "oo";
    background-color: ${({ status }) => (status) ? STATUS_COLORS["available"] : STATUS_COLORS["notavailable"]};
    color: transparent;
    outline: 2px solid white;
    border-radius: 50%;
    top: 50px;
    left: 45px;
  }
`


import React, {useState} from "react";

import {AddContacts} from "../../../../Doctor/Discussions/NewGroupDialog/AddContacts";

export const SearchMemberDialog = ({showModal, setShowModal, isSearch}) => {
  const [dialogPage, setDialogPage] = useState('addContacts');
  const [membersAdded, setMembersAdded] = useState([])

  return (
    <>
      {dialogPage === 'addContacts' && (
        <AddContacts showModal={showModal}
                     onHide={() => setShowModal(false)}
                     setDialogPage={setDialogPage}
                     membersAdded={membersAdded}
                     setMembersAdded={setMembersAdded}
                     isSearch={isSearch}
        />
      )}
    </>
  )
}
import React, {useEffect, useState} from 'react';
import AsyncSelect from 'react-select/async';
import axios from 'axios';
import get from 'lodash/get';
import {FormattedMessage, useIntl} from 'react-intl';
import styled from 'styled-components';
import capitalize from 'lodash/capitalize';
import { SecuredBckgImage } from 'common/SecuredImage';
import { Colors } from '../../../constants/colors';
import difference from "lodash/difference";
import {DefaultAvatar} from "../DefaultAvatar";

const Image = styled(SecuredBckgImage)`
  width: 25px;
  height: 25px;
  overflow: hidden;
  border-radius: 50px;
  background-size: cover;
  background-position: center;
  margin-right: 5px;
`

const DefaultImage = styled.img`
  width: 25px;
  height: 25px;
  overflow: hidden;
  border-radius: 50px;
  background-size: cover;
  background-position: center;
  margin-right: 5px;
`

const Lined = styled.div`
  align-items: center;
  display: flex;
`

const getLabel = (labelPath, value, imagePath) => {
  if (Array.isArray(labelPath)) {
    return (
      <Lined>
        {get(value, imagePath) ?
        (<Image src={get(value, imagePath, '')} />) :
        (<DefaultImage src={DefaultAvatar(get(value, labelPath[0], ''), get(value, labelPath[1], ''), get(value, labelPath[3], '') )} />) }
        {capitalize(get(value, labelPath[0], '')) + " " + capitalize(get(value, labelPath[1], '')) + ", " + capitalize(get(value, labelPath[2], ''))}
        {/*labelPath.map(el => capitalize(get(value, el, ''))).join(' ')*/}
      </Lined>
    );
  }
  return get(value, labelPath, '');
}

const formatToValues = (values, {
  valuePath, labelPath, barcketsPath, imagePath, 
}) => values.map(el => ({
  value: get(el, valuePath, ''),
  label: barcketsPath ? `${getLabel(labelPath, el, imagePath)} (${get(el, barcketsPath, '')})` : getLabel(labelPath, el, imagePath),
}))

//  filtre les options de membres à choisir en prenant en compte ceux déjà choisi

const ChooseOnlyOnceAMember = (values, val ) => {
// récupération des id dans values (cela permet de n'avoir qu'une liste d'Id)
  // values : tableau complexe; listOptionMembers et val : tableaux simples liste d'id;
  const listOptionMembers = []
  values.map((el,i) => listOptionMembers[i] = el.id)

  const newIDOptionMember = difference(listOptionMembers, val).filter(el => el !== '');
  const NewTableOptionValues= []
  // parcourt du tableau  newIDOptionMember (liste simple d'id) pour ne récupérer que les valeurs des id du tableau complexe values (grappe de donnéesayant une propriété "id")
  newIDOptionMember.map(
    (el,i) => [NewTableOptionValues[i]] = values.filter(element => element.id === el)
  )
  return NewTableOptionValues
}

const ErrorStyled = styled.div`
  color: #ee3c5b;
  padding: 4px 12px 0;
  font-size: 0.75rem;
  margin-top: ${({ noMarginError }) => (noMarginError ? '0' : '-20px')};
  position: absolute;
  border-top: 1px solid;
  width: calc(100% - 24px);
`

const ErrorContainer = styled.div`
  position: relative;
`

const caseDefaultVlaueCompleteObject = value => (value && value.id ? `${value.name} (${value.insee_code})` : value)

// debut du composant

export const AsyncSelectField = ({
  requestUrl, valuePath, labelPath, input, rsStyles = {},
  barcketsPath = false, meta, noMarginError, placeholder,
  imagePath, cacheOptions = true, defaultOptions, defaultInputValue, val, listMembersAtCreation, noOptionsTxt
}) => {
  const { formatMessage: f } = useIntl()
  const [cancelToken, setCancelToken] = useState(null)

  useEffect(() => {
    return () => {
      if (cancelToken) {
        cancelToken.cancel('Component unmounted')
      }
    }
  }, [cancelToken])

  const loadOptions =  (inputValue, callback, requestUrl, val, listMembersAtCreation, paths) => {

    if(cancelToken) {
      cancelToken.cancel('Request canceled');
    }

    const source = axios.CancelToken.source();
    setCancelToken(source);

    try {
      if (inputValue && inputValue.length && inputValue.length >= 3) {
        const delayDebounce = setTimeout(() => {
           axios(`${requestUrl}${inputValue}`, {cancelToken: source.token})
            .then((result) => {
              const values = get(result, 'data.data', []);
              !!listMembersAtCreation
                ?
                callback(formatToValues(ChooseOnlyOnceAMember(values, [...val,...listMembersAtCreation]), paths))
                :
                callback(formatToValues(ChooseOnlyOnceAMember(values, val), paths))
            })
            .catch(err => console.warn(err))
        }, 400)
        return () => clearTimeout(delayDebounce);

      } else {
        callback([]);
      }
    } catch (error) {
      if (axios.isCancel(error)) {
        console.log('Request canceled: ', error.message)
      }
    }
  };

  return (
    <div>
      <AsyncSelect
        key='input-select'
        styles={{ ...rsStyles, control: provided => ({ ...provided, '&:hover': { borderColor: Colors.bluePurple } }) }}
        placeholder={f({ id: placeholder || 'global.search' })}
        cacheOptions={cacheOptions}
        loadOptions={(inputValue, callback) => (
          loadOptions(inputValue, callback, requestUrl, val, listMembersAtCreation, {
            valuePath, labelPath, barcketsPath, imagePath, 
          })
        )}
        defaultInputValue={defaultInputValue ? false : caseDefaultVlaueCompleteObject(input.value)}
        defaultValue={defaultInputValue}
        defaultOptions={defaultOptions}
        controlShouldRenderValue
        onChange={(value) => {
          input.onChange(value && value.value);
        }}
        noOptionsMessage = { noOptionsTxt ? (() => f({id: noOptionsTxt})) : (() => 'No options')}
      />
      {meta && meta.error !== undefined && meta.error.props.id && (
            <ErrorContainer>
              <ErrorStyled noMarginError={noMarginError} key='error-container'>
                <FormattedMessage id={meta.error.props.id} />
              </ErrorStyled>
            </ErrorContainer>
          )
      }
      {meta && meta.error && meta.touched ? (
        <ErrorContainer>
          <ErrorStyled noMarginError={noMarginError} key='error-container'>
            {meta.error}
            <FormattedMessage id={meta.error.props.id} />
          </ErrorStyled>
        </ErrorContainer>
      ) : ''}
    </div>
  ) 
}

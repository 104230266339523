import React from 'react';
import PropTypes from 'prop-types'
import { Route, Switch } from 'react-router-dom'
import { MedicalReportForm } from './MedicalReportForm'
import { Main } from './Main'
import { consultationIsRecieved } from '../../../../../helpers/consultations'

export const SessionComponent = ({ match }) => {
  return (
    <>
      <Switch>
        <Route
          path={`${match.path}/report`}
          component={MedicalReportForm}
        />
        <Route
          path={`${match.path}`}
          component={Main}
        />
      </Switch>
    </>
  )
}

SessionComponent.propTypes = {
  match: PropTypes.object.isRequired,
}

export const Session = consultationIsRecieved(SessionComponent)

import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import Place from '@mui/icons-material/Place'
import { Paper } from 'common/UiKit/Paper'
import { Colors } from 'constants/colors'
import { FormattedMessage, useIntl } from 'react-intl'
import moment from 'moment-timezone'
import axios from 'axios';
import defaultAvatar from '../../../../../images/panel/default-avatar.svg'
import dompurify from "dompurify";

const PaperStyled = styled(Paper)`
  padding: 20px 0 20px 24px;
  margin: 24px 0 16px;
`

const Wrapper = styled.div`
  display: flex;
  font-weight: normal;
  font-size: 16px
`

const Avatar = styled.div`
  min-width: 126px;
  width: 126px;
  height: 126px;
  border: 7px solid white;
  border-radius: 100%;
  background: url(${props => props.photo || defaultAvatar}) ${Colors.lightGrey} center no-repeat; 
  background-size: cover;
  display: flex;
  align-items: center;
  justify-content: center;
  box-shadow: 0 4px 8px 0 rgba(6, 173, 255, 0.1);
`

const Info = styled.div`
  flex-grow: 1;
  margin: 12px 0 0 32px;
`

const GeneralInfo = styled.div`
  display: flex;
  justify-content: space-between;
`

const Price = styled.div`
  height: 68px;
  box-sizing: border-box;
  padding: 4px 20px 0 20px;
  color: ${Colors.clearBlue};
  font-size: 32px;
  font-weight: bold;
  border: solid 1px ${Colors.cloudyBlue};
  border-radius: 20px 0 0 20px;

  & > :first-child {
    margin: 0;
    font-size: 16px;
    color: ${Colors.blueGrey};
    font-weight: normal;
  }
`

const Name = styled.h1`
  margin: 0;
  font-size: 24px;
  font-weight: bold;
`

const Specialization = styled.p`
  margin: 0;
  opacity: 0.8;
  padding-top: 10px;
`

const Over = styled.p`
  margin: 0;
  color: ${Colors.blueGrey};
  display: flex;
  align-items: center;
  margin-top: 12px;
  text-transform: capitalize;

  && svg {
    font-size: 18px;
    margin-right: 12px;
  }
`
const Instructions = styled.div`
  background-color: #E7E2F6;
  margin-top: 20px;
  margin-right: 30px;
  padding: 15px 15px 15px 15px;
  border-radius: 3px;
`

const InstructionsTitle = styled.p`
  margin-bottom: 0;
  margin-top: 0;
  font-weight: bold;
  font-size: 14px;
`

const InstructionsContent = styled.p`
  font-size: 13px;
  margin-bottom: 0;
`

export const DoctorInfo = ({
  doctorId,
  firstName,
  lastName,
  specialization,
  photo,
  available,
  price,
  location,
  specialization_type,
  specialization_key,
  match,
}) => {
  const { formatMessage } = useIntl()
  const f = id => formatMessage({ id })
  // const availableUTC = new Date(`${available}Z`);
  const availableUTC = moment.utc(available).local().format('YYYY-MM-DD HH:mm:ss');

  const [instructions, setInstructions] = useState('')

  if (doctorId) {
    axios.get(`/doctor/instructions/${doctorId}`)
      .then((response) => {
        setInstructions(response.data)
      });
  }

  const isTCA = match.path.includes('teleconsultation')
  const sanitizer = dompurify.sanitize;

  return (
    <PaperStyled>
      <Wrapper>
        <Avatar
          photo={photo && photo.includes('/file/') ? `http://backend-application.owndoctor.eu${photo}` : photo}
        />
        <Info>
          <GeneralInfo>
            <div>
              <Name>
                {`${firstName || 'Name'} ${lastName || 'Surname'}`}
              </Name>
              <Specialization>
                {`${specialization || 'Specialization'}`}
              </Specialization>
            </div>
            <Price>
              <p>
                <FormattedMessage id='global.cost' />
              </p>
              {price || '0'}
              &nbsp;&euro;
            </Price>
          </GeneralInfo>
          <Over>
            <Place />
            {Object.keys(location).length ? `${location.name}, ${location.street}, ${location.city}` : f('global.location')}
          </Over>
          { !isTCA && instructions !== '' && instructions.length !== 8 && (specialization_type === 'generalist' || specialization_type === 'specialist' || specialization_key === 'Midwife') && (
            <Instructions>
              <InstructionsTitle>
                <FormattedMessage id='consultation.instructions'/>
              </InstructionsTitle>
              <InstructionsContent>
                <div dangerouslySetInnerHTML={{ __html: sanitizer(instructions) }} />
              </InstructionsContent>
            </Instructions>
          )}
          
          {/*<Over>*/}
          {/*  <InsertInvitation />*/}
          {/*  {available ? moment(availableUTC).calendar(null, { sameElse: 'DD.MMMM.YYYY, HH:mm' }) : ''}*/}
          {/*</Over>*/}
        </Info>
      </Wrapper>
    </PaperStyled>
  )
}

DoctorInfo.defaultProps = {
  doctorId: '',
  firstName: '',
  lastName: '',
  specialization: '',
  photo: defaultAvatar,
  available: '',
  price: '0',
  location: {},
  specialization_type: '',
  specialization_key: '',
}

DoctorInfo.propTypes = {
  doctorId: PropTypes.string,
  firstName: PropTypes.string,
  lastName: PropTypes.string,
  specialization: PropTypes.string,
  photo: PropTypes.string,
  available: PropTypes.string,
  price: PropTypes.number,
  location: PropTypes.object,
  specialization_type: PropTypes.string,
  specialization_key: PropTypes.string,
}

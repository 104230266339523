import {createAction, handleActions} from "redux-actions";
import axios from "axios";
import {combineReducers} from "redux";
import queryString from "query-string";

export const SearchRequest = createAction('SEARCH_REQUEST')
export const SearchSuccess = createAction('SEARCH_SUCCESS')
export const SearchFailure = createAction('SEARCH_FAILURE')
export const ClearSearch = createAction('CLEAR_SEARCH')

let ajaxRequest = null;

export const fetchSearch = (filters, abortController = {}) => (dispatch) => {
  dispatch(SearchRequest(filters))

  // abort previous request if not finished
  if(ajaxRequest) {
    ajaxRequest.cancel();
  }

  ajaxRequest = axios.CancelToken.source();

  const params =
    {
      ...filters,
      include: 'workPlace,structures',
      cancelToken: ajaxRequest.token,
    }

  return axios(`/api/general/search`, {
      params,
      signal: abortController.signal,
      method: 'GET',
  })
    .then((res) => {
      dispatch(SearchSuccess(res.data))
    })
    .catch((e) => {
      dispatch(SearchFailure(e))
    })
}

export const clearSearch = (abortController = {}) => (dispatch) => {
  if(abortController.current){
    abortController.current.abort()
  }
  dispatch(ClearSearch())
}

const list = handleActions({
  [SearchSuccess]: (state, action) => ({
    ...state,
    data: action.payload.data,
    paginator: action.payload.paginator,
  }),
  [ClearSearch]: () => ({
    data: []
  })
}, [])

export const fetchSearchRequest = createAction("FETCH_SEARCH_REQUEST");
export const fetchSearchSuccess = createAction("FETCH_SEARCH_SUCCESS");
export const fetchSearchFailure = createAction("FETCH_SEARCH_FAILURE");

export const getSearchResult = (filter) => (dispatch, getStore) => {

  dispatch(fetchSearchRequest());

  const base = '/api/general/search'

  const location = getStore().geoloc.reverseGeocoding;
  const params = {
    ...filter,
  }

  if (location) {
    params.latitude = location.latitude;
    params.longitude = location.longitude;
  }

  return axios(`${base}?${queryString.stringify({...filter})}`, {
    params,
    method: 'GET',
  })
    .then((res) => {
      dispatch(fetchSearchSuccess(res.data));
    })
    .catch(e => {
      dispatch(fetchSearchFailure(e))
    })
}

const searchResults = handleActions({
  [fetchSearchSuccess]: (state, action) => action.payload.data
}, {})

export const setSearchedResult = createAction('SET_SEARCHED')
export const clearSearchedResult = createAction('CLEAR_SEARCHED')

export const setSearchObject = (object) => (dispatch) => {
  dispatch(setSearchedResult(object))
}

export const clearSearchObject = () => (dispatch) => {
  dispatch(clearSearchedResult())
}

const searched = handleActions({
  [setSearchedResult] : (state, action) => {
    return action.payload
  },
  [clearSearchedResult] : (state, action) => {
    return {}
  },
}, {})

export const search = combineReducers({
  list,
  searchResults,
  searched,
})
import React, {useEffect, useState} from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import { Paper } from 'common/UiKit/Paper'
import { SecuredBckgImage } from 'common/SecuredImage'
import {Colors, STATUS_COLORS} from '../../../constants/colors'
import {DefaultAvatar} from "../DefaultAvatar";
import {Link} from "react-router-dom";
import AddIcon from "@mui/icons-material/Add";
import {useDispatch} from "react-redux";
import {toggleFavorite} from "../../../redux/modules/doctors";
import CheckIcon from "@mui/icons-material/Check";
import {fullName} from "../../../helpers/user";
import axios from "axios";

const PaperStyled = styled(Paper)`
  padding: 10px 14px;
  position: relative;
  display: flex;
  height: fit-content;
  align-items: center; 
`

const Wrapper = styled.div`
  display: flex;
  font-weight: normal;
  font-size: 14px;
  margin: auto 0;
  width: 100%;
`

const AvatarWrapper = styled.div`
  display: flex;
  font-weight: normal;
  font-size: 14px;
`

const Info = styled.div`
  flex-grow: 1;
  margin: auto 32px auto;
`

const NameLinked = styled(Link)`
  margin: 0;
  font-size: 16px;
  font-weight: bold;
  color: black;
  
  &:hover {
    color: #6633FF;
  }
`

const Name = styled.h1`
  margin: 0;
  font-size: 16px;
  font-weight: bold;
  color: black;
`

const Specialization = styled.p`
  margin: 0;
  opacity: 0.8;
  padding-top: 2px;
  font-size: 14px;
`

const Address = styled.p`
  margin: 0;
  opacity: 0.8;
  padding-top: 5px;
  font-size: 13px;
`

const Avatar = styled(SecuredBckgImage)`
  min-width: 60px;
  width: 60px;
  height: 60px;
  border-radius: 100%;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  &:after {
    position : relative;
    content: "oo";
    background-color: ${({ status }) => (status) ? STATUS_COLORS["available"] : STATUS_COLORS["notavailable"]};
    color: transparent;
    border-radius: 50%;
    top: 44px;
    left: 42px;
  }
`

const Default = styled.div`
  min-width: 60px;
  width: 60px;
  height: 60px;
  border-radius: 100%;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  &:after {
    position : relative;
    content: "oo";
    background-color: ${({ status }) => (status) ? STATUS_COLORS["available"] : STATUS_COLORS["notavailable"]};
    color: transparent;
    border-radius: 50%;
    top: 44px;
    left: 42px;
  }
`

const DefaultWithoutAvailability = styled.div`
  min-width: 60px;
  width: 60px;
  height: 60px;
  border-radius: 100%;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
`

const Button = styled.div`
  display: flex;
  justify-content: flex-end;
  align-items: flex-start;
`

const StyledAddIcon = styled(AddIcon)`
  margin-left: 30px;
  font-size: 35px;
  color: ${Colors.bluePurple};
  cursor: pointer;
  border-radius: 100%;
  &:hover {
    background-color: rgba(231, 226, 246, 0.3);
  }
  
`

const StyledCheckIcon = styled(CheckIcon)`
  margin-left: 30px;
  font-size: 35px;
  color: ${Colors.bluePurple};
  cursor: pointer;
  border-radius: 100%;
  &:hover {
    background-color: rgba(231, 226, 246, 0.3);
  }
`

export const NewFavoriteDoctorCardSearch = ({
                                             id,
                                             doctorId,
                                             firstName,
                                             lastName,
                                             specialization,
                                             specialization_type,
                                             photo,
                                             isFavorite,
                                             link,
                                             workplaceName,
                                             postcode,
                                             city,
                                             street,
                                             gender,
                                             isAnnuaire
                                         }) => {

    const dispatch = useDispatch();
    const onConfirm = () => {
        dispatch(toggleFavorite(doctorId, !isFavorite))
    }

  const [statusAvailability, setStatusAvailability] = useState(false);

  useEffect(() => {
    if (doctorId) {
      axios.get(`api/doctor/${doctorId}/isAvailable`)
        .then((response) => {
          setStatusAvailability(response?.data?.status_availability ?? false);
        })
    }
  }, [doctorId]);

    return (
        <PaperStyled>
            <Wrapper>
                <AvatarWrapper>
                    {
                      isAnnuaire ? (
                          <DefaultWithoutAvailability
                            spinner={{width: '120px', height: '120px'}}
                            style={{backgroundImage: `url(${DefaultAvatar(firstName, lastName, specialization_type)})`}}
                          />
                        ) :
                        photo ? (
                                <Avatar
                                    spinner={{width: '120px', height: '120px'}}
                                    src={photo.includes('/file/') ? `http://backend-application.owndoctor.eu${photo}` : photo}
                                    status={statusAvailability}
                                />
                            ) :
                            (<Default
                                    spinner={{width: '120px', height: '120px'}}
                                    style={{backgroundImage: `url(${DefaultAvatar(firstName, lastName, specialization_type)})`}}
                                    status={statusAvailability}
                                />
                            )
                    }
                </AvatarWrapper>
              <Info>

                {link ? (
                    <NameLinked to={isFavorite ? link.replace('favorite', '') : link}>
                              {fullName(gender, firstName, lastName)}
                            </NameLinked>)
                        : (
                            <Name to={link}>
                              {fullName(gender, firstName, lastName)}
                            </Name>)
                    }
                    <Specialization>
                        {specialization || 'Therapist'}
                    </Specialization>


                    <Address>
                        {`${workplaceName ? workplaceName + ", " : ""} ${street ? street + ", " : ""} ${postcode ? postcode : ""} ${city ? city : ""}`}
                    </Address>
                </Info>
                <Info>
                    { !isFavorite && isFavorite!=null && (
                        <Button
                            variant='outlined'
                            onClick={onConfirm}
                        >
                            <StyledAddIcon fontSize='large' color='#6633FF' />
                        </Button>
                    )


                    }
                    { isFavorite && (
                        <Button
                            variant='outlined'
                            onClick={onConfirm}
                        >
                            <StyledCheckIcon fontSize='large' color='#6633FF' />
                        </Button>
                    )
                    }
                </Info>
            </Wrapper>
        </PaperStyled>
    )
}

NewFavoriteDoctorCardSearch.defaultProps = {
    location: {},
    onAvatarClick: () => null,
    photo: '',
    buttonText: null,
    rating: null,
}

NewFavoriteDoctorCardSearch.propTypes = {
    firstName: PropTypes.string.isRequired,
    lastName: PropTypes.string.isRequired,
    specialization: PropTypes.string.isRequired,
    photo: PropTypes.string,
    price: PropTypes.number,
    location: PropTypes.object,
    onButtonClick: PropTypes.func,
    onAvatarClick: PropTypes.func,
    isFavorite: PropTypes.bool,
    disableFavorite: PropTypes.bool,
    rating: PropTypes.number,
    buttonText: PropTypes.string,
}

import React, { useState } from 'react'
import { Link } from 'react-router-dom'
import { connect, useDispatch, useSelector } from 'react-redux';
import styled, { css }  from 'styled-components'
import FormControlLabel from '@material-ui/core/FormControlLabel'
import { FormattedMessage } from 'react-intl'
import Switch from '@mui/material/Switch';
import { STATUS_COLORS, Colors } from '../../../constants/colors'
import avatarImg from '../../../images/panel/default-avatar.svg'
import { history } from '../../../utils/history';
import logo_conexsante_little from '../../../images/panel/logo_conexsante_little.png';
import logo_conexsante from '../../../images/panel/logo_conexsante.png';
import { EditStatusDoctorAvailability, fetchDoctorAvailableSuccess } from '../../../redux/modules/consultations';
import {DefaultAvatar} from "../../common/DefaultAvatar";
import DoubleArrowIcon from '@mui/icons-material/DoubleArrow';
import {Avatar} from "../../common/Avatar";

// const Img = styled.img`
//   width: 100%;
//   padding-bottom: 20px;
// `

const Logo = styled.img`
  width:  ${({ folded }) => (folded) ? '28px' : '140px'};
  margin-top: ${({ folded }) => (folded) ? '3px' : '0px'};
  margin-bottom: ${({ folded }) => (folded) ? '10px' : '15px'};
`



const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  font-family: Roboto, sans-serif;
  width: 100%;
`
//small change for merge, delete later
const Default = styled.div`
  width: ${({ folded }) => (folded) ? '32px' : '70px'};
  height: ${({ folded }) => (folded) ? '32px' : '70px'};
  margin-bottom:  ${({ folded }) => (folded) ? '3px' : '14px'} ;
  margin-right:  ${({ folded }) => (folded) ? '3px' : '0'} ;
  cursor: pointer;
  border-radius: 100%;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  &:after {
    display: ${({ folded, isDoctor }) => !isDoctor ? 'none' : folded ? 'none' : ''};
    position : relative;
    content: "oo";
    border: 2px solid white;
    background-color: ${({ status }) => (status) ? STATUS_COLORS["available"] : STATUS_COLORS["notavailable"]};
    color: transparent;
    border-radius: 50%;
    top: 50px;
    left:50px;
  }
`

const LinkStyled = styled(Link)`
  width: 100%;
  margin-bottom: 2px;
  font-size: 18px;
  font-weight: 500;
  line-height: 1.11;
  letter-spacing: 0.2px;
  color: black;
  word-wrap: break-word;

  &:hover {
    text-decoration: underline;
  }
`
const CheckboxStyled = styled.div`
  box-sizing: border-box;
  width: 80%;
  font-size: 15px;
  font-weight: 400;
  line-height: 1.11;
  color: ${Colors.borderGrey};
`

const TextStyled = styled.div`
  font-size: 0.9em;
  ${({checked}) => (checked && css`
      color: #00B428;
    `)}
   ${({NotCheckedRed}) => (NotCheckedRed && css`
      color: #FE4967;
    `)}
`

const SpecializationAuth = styled.p`
  color: black;
  font-size: 12px;
  font-weight: normal;
`

const IconDepliant = styled(DoubleArrowIcon)`
  color: ${Colors.steel};
  transform: ${({ folded }) => (folded) ? 'none' : 'rotate(180deg)'};
  margin-right: ${({ folded }) => (folded) ? 'auto' : 'none'};
  margin-left: ${({ folded }) => (folded) ? 'auto' : 'none'};
  cursor: pointer;
  &:hover {
    color:  ${Colors["link"]};
  }
`

const Line = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
   ${ ({alItems, jusContent}) => (alItems && jusContent && css`
    align-items: ${alItems};
    justify-content: ${jusContent};
  `)}
  margin-bottom: ${({ folded }) => (folded) ? '5px' : '8px'};
  margin-top: ${({ folded }) => (folded) ? '5px' : '8px'};
`

const Column = styled(Line)`
  margin-left: 10px;
  flex-direction: column;
  align-items: start;
  justify-content: space-around;
  min-width : 150px;
  max-width : 180px;
`

const SwitchStyled = styled(Switch)`
  margin-left: 3px;
`

export const MenuProfileInfoComponent = ({
  photo,
  specialization_auth,
  firstName,
  lastName,
  folded,
  setFolded,
  ...rest
}) => {
  const isDoctor = useSelector(state => (state.auth.type === 'Doctor'))
  const status_availability = useSelector(state => (state.auth.attributes.status_availability))
  const [checked, setChecked] = useState(status_availability);
  const dispatch = useDispatch();
  const photo_uploaded = useSelector(state => (state.auth.attributes.photo))
  const otherArgs = {...rest}
  delete otherArgs.dispatch

  const onConfirm = () => {
    dispatch(EditStatusDoctorAvailability(!checked))
      .then(response => dispatch(fetchDoctorAvailableSuccess(response.data)))
  }
  return (
    <Wrapper>
        <Line style={{width: '90%'}} alItems={'end'} jusContent={'flex-end'} >
          <IconDepliant folded={folded} onClick={() => setFolded(!folded)} />
        </Line>
          <Logo src={ folded ? logo_conexsante_little : logo_conexsante } folded={folded}/>


        <Line  folded={folded} alItems={'center'} jusContent={'start'}>
          {photo_uploaded ?
            (<Avatar
              isDoctor={isDoctor}
              small={folded}
              image={photo}
              status={checked}
              onClick={() => history.push('/panel/profile')}
            />)
            :
            (<Avatar
              isDoctor={isDoctor}
              small={folded}
              style={{backgroundImage: `url(${photo})`}}
              status={checked}
              onClick={() => history.push('/panel/profile')}
            />)
          }
          {
          !folded &&
          <Column>
            <LinkStyled to='/panel/profile'>
              {firstName}
              {(firstName.length + lastName.length) > 16  ? <br/> : ' '}
              {lastName}
            </LinkStyled>
            <SpecializationAuth>{specialization_auth}</SpecializationAuth>
          </Column>}
        </Line>

      <form style={{textAlign : 'center'}}>
        { isDoctor
        ? (
          <Line folded={folded}>
            <CheckboxStyled >
              <FormControlLabel
                control={(
                  <>
                    {
                      !folded &&
                      <TextStyled NotCheckedRed={!checked}>
                        <FormattedMessage id='global.doctor.non.disponible'/>
                      </TextStyled>
                    }
                    <SwitchStyled
                      name='status_availability '
                      color='success'
                      checked={checked}
                      {...otherArgs}
                      onClick={onConfirm}
                      onChange={event => setChecked(event.target.checked)}
                      inputProps={{'aria-label': 'controlled'}}
                    />
                    {
                      !folded &&
                      <TextStyled checked={checked}>
                        <FormattedMessage id='global.doctor.disponible'/>
                      </TextStyled>
                    }
                  </>
                )}
              />
            </CheckboxStyled>
          </Line>
          )
          : ''
        }
      </form>
    </Wrapper>
  )
}

const enhance = connect(
  state => ({
    photo: state.auth.attributes.photo || DefaultAvatar(state.auth.attributes.first_name, state.auth.attributes.last_name, state.auth.attributes.specialization_type) || avatarImg,
    specialization_auth: state.auth.attributes.specialization,
    firstName: state.auth.attributes.first_name || <FormattedMessage id='global.not.filled.name' />,
    lastName: state.auth.attributes.last_name || <FormattedMessage id='global.not.filled.lastName' />,
  }),
)

export const MenuProfileInfo = enhance(MenuProfileInfoComponent)

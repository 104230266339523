import React from 'react'
import styled from 'styled-components'
import PropTypes from "prop-types";
import {nonBreakingSpaces} from "../../utils/tools";
import {Colors} from "../../constants/colors";

const tagDisplay = (tag, key, onSelect, selectedColor, selectedBackColor, textColor, backColor, hoverColor) => {
  const tagName = nonBreakingSpaces(typeof tag === 'object' ? tag.name : tag)
  if (onSelect) {
    return (
      <TagSpan
        key={key}
        selected={typeof tag === 'object' ? tag.selected || false : false}
        selectedColor={selectedColor}
        selectedBackColor={selectedBackColor}
        textColor={textColor}
        backColor={backColor}
        hoverColor={hoverColor}>
          <a href='#' onClick={(e) => {
            e.preventDefault()
            onSelect(tag)
          }}>{tagName}</a>
      </TagSpan>
    )
  } // else

  // Affichage d'un tag simple
  return (<TagSpan key={key} textColor={textColor} backColor={backColor}>{tagName}</TagSpan>)
}

const TagsList = ({ tags, onSelect, selectedColor, selectedBackColor, emptyOption, textColor, backColor, hoverColor, wrapperStyle={} }) => (
  <>
    {
      !!tags && tags.length > 0 && (
        <TagsWrapper style={wrapperStyle}>
          { tags.map((tag, key) => tagDisplay(tag, key, onSelect, selectedColor, selectedBackColor, textColor, backColor, hoverColor)) }
        </TagsWrapper>
      ) || !!emptyOption && emptyOption
    }
  </>
)

TagsList.defaultProps = {
  tags: [],
  onSelect: null,
  selectedColor: '#5D5577',
  textColor: '#5D5577',
  selectedBackColor: Colors.purpleGrey,
  backColor: Colors.purpleGrey,
  hoverColor: null,
  emptyOption: null,
}

TagsList.propTypes = {
  tags: PropTypes.array.isRequired,
  onSelect: PropTypes.func,
  selectedColor: PropTypes.string,
  selectedBackColor: PropTypes.string,
  textColor: PropTypes.string.isRequired,
  backColor: PropTypes.string.isRequired,
  hoverColor: PropTypes.string,
  emptyOption: PropTypes.node,
}

export default TagsList

// --------------------------------- Styles CSS -------------------------------------

const TagsWrapper = styled.div`
  font-size: 0.8em;
  margin: 20px 0 10px;
`

const TagSpan = styled.span`
  ${props => `
    color: ${props.selected ? props.selectedColor : props.textColor};
    background-color: ${props.selected ? props.selectedBackColor : props.backColor};
    border: 1px solid ${props.selected ? props.backColor : props.textColor};

    ${props.hoverColor && `
      :hover {
        background-color: ${props.selected ? props.selectedBackColor : props.hoverColor};
      }
    `}

    & > a, & > a:hover, & > a:visited {
      color: ${props.selected ? props.selectedColor : props.textColor};
    }
  `}
  display: inline-block;
  border-radius: 20px; 
  padding: 3px 8px;
  margin-right: 5px;
  margin-bottom: 5px;
`

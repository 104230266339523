import React from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { Page } from '../Page'
import { logOut } from '../../../redux/modules/auth'

export const MainPageComponent = ({
  id,
  logOut,
  onBackIconClick,
}) => (
  <Page
    label='global.privacy-policy'
    onLogout={logOut}
    id={id}
    onBackIconClick={onBackIconClick}
  />
)

MainPageComponent.propTypes = {
  id: PropTypes.string.isRequired,
  logOut: PropTypes.func.isRequired,
  onBackIconClick: PropTypes.oneOfType([PropTypes.bool, PropTypes.func]).isRequired,
}

const enhance = connect(state => ({ id: state.auth.id }), { logOut })

export const MainPage = enhance(MainPageComponent)

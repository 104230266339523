import React from 'react'
import {Backdrop, Dialog as MuiDialog, DialogActions, DialogContent, DialogTitle, Paper} from "@mui/material";
import styled from "styled-components";
import PropTypes from "prop-types";
import {Colors} from "../../../../constants/colors";
import CloseIcon from "@mui/icons-material/Close";

const StyledDialog = styled(MuiDialog)`
  backdrop-filter: blur(3px);
  z-index: 2000;
  & > * {
    margin : auto;
    font-size : 20px;
  }
`

const StyledBackdrop = styled(Backdrop)`
  background-color: transparent;
`

const StyledPaper = styled(Paper)`
  border: 1px solid ${Colors.bluePurple};
  padding: 10px;
`

const StyledDialogTitle = styled(DialogTitle)`
  border-bottom: 1px solid ${Colors.steel};
`

const StyledCloseIcon = styled(CloseIcon)`
  &:hover {
    cursor: pointer;
  }
`

export const Dialog = ({ title, titleAlignment, children, actions, ...props}) => {
  return (
    <StyledDialog
      BackdropComponent={StyledBackdrop}
      PaperComponent={StyledPaper}
      {...props}
    >
      <StyledDialogTitle>
        <StyledCloseIcon style={{ float: 'right', color: Colors.steel }} onClick={() => props.onClose()} />
        <div style={{ textAlign: titleAlignment }}>{title}</div>
      </StyledDialogTitle>

      <DialogContent style={{ paddingTop: '10px' }}>
        {children}
      </DialogContent>

      {!!actions && (<DialogActions>{actions}</DialogActions>)}
    </StyledDialog>
  )
}

Dialog.propTypes = {
  title: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
  titleAlignment: PropTypes.string.isRequired,
  actions: PropTypes.node,
}

Dialog.defaults = {
  titleAlignment: 'left'
}
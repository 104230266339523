import React, {useEffect, useState} from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import moment from 'moment-timezone'
import {connect, useDispatch, useSelector} from 'react-redux'
import { compose, withProps, withStateHandlers } from 'recompose'
import idx from 'idx'
import get from 'lodash/get';
import { DialogWindow } from '../../../../../common/UiKit/DialogWindow'
import { history } from '../../../../../../utils/history'
import {
  pushMessage,
  fetchOpentokParams,
  fetchConsultation,
  toggleVideoAccess, toggleD2UPanel,
} from '../../../../../../redux/modules/consultations'
import { ButtonsBar } from './ButtonsBar'
import { Chat as WebChat } from '../../../../Chat'
import { CONSULTATION_TYPES } from '../../../../../../constants/consultation'
import { patientSelector } from '../../../../../../redux/selectors/patientSelector'
import { socket } from '../../../../../../services/socket'
import Doc2U from "../Menu/Doc2U";
import {ChatLayout} from "../../../../ChatLayout";
import {RecipientInfo} from "../../../../Header/recipientInfo";
import {Actions} from "../../../../Header/Actions";
import {DocumentsTab} from "../../../../Chat/DocumentsTab";
import {InfosPatient} from "../../../../Chat/InfosPatientTab/InfosPatient";
import MessageIcon from "@mui/icons-material/Message";
import {ContactPage} from "@mui/icons-material";
import DescriptionIcon from "@mui/icons-material/Description";
import {acceptCall} from "../../../../../../redux/modules/call";
import {getAge} from "../../../../../../utils/dateOperations";
import {DisplayGender} from "../../../../../common/Gender";
import {Sex} from "../../../../../common/Gender/sex";

const Wrapper = styled.div`
  height: 100%;
  display: flex;
  flex-direction: column;
  background-color: rgb(245, 247, 251);
`

const ChatWrapper = styled.div`
    height: 100%;
`

const ChatComponent = ({
  patient, // This variable is not always the patient (use consultationPatient to get the patient)
  consultationPatient,
  onVideoIconClick,
  medicalReportFilled,
  dialogWindowText,
  onDialogWindowClose,
  isNotDoctor,
  isCalling,
  completed,
  loading,
  isPaymentCompleted,
  consultationStatus,
  location,
  consultationId,
  whomRequested,
  userId,
  isTCA,
  doc2URunning
}) => {
  const [btnDoc2UText, setBtnDoc2UText] = useState('waiting.doc2u.start');
  const [open, setOpen] = useState(false);
  const isDisplayTeamName =  typeof patient === "string";
  const consultation = useSelector(state => state.consultations.consultation)
  const isTERESS = consultation.attributes.type === CONSULTATION_TYPES.TER_ESS

  const dispatch = useDispatch();
  useEffect(() => {
    if (doc2URunning && isTCA) {
      onDoc2UButtonClick()
    }
  }, [isCalling, loading, doc2URunning])

  const onDoc2UButtonClick = () => {
    setOpen(!open);
    // Delete D2U Rooms
    /*if (doc2UOpen) {
      axios.delete('/doctor/consultation/'+consultationId+'/doc2u/end')
        .then(() => {
          console.log('D2U fini')
        })
    }*/
  }

  useEffect(() => {
    setBtnDoc2UText(open ? 'waiting.doc2u.end' : 'waiting.doc2u.start')
    dispatch(toggleD2UPanel(open))

  }, [open])

  //Component à mettre dans le footer du Chat
  const footer =((isNotDoctor && !completed) || consultationStatus === 'cancelled' || consultationStatus === 'refused')
    ? null
    : (
        <ButtonsBar
            isPaymentCompleted={isPaymentCompleted}
            medicalReportFilled={medicalReportFilled || (isNotDoctor && completed)}
            isPatient={(isNotDoctor && completed) || false}
        />
    )

  // Array of tabs used in the chat layout component
  const doctorTabs= [{
      tabName: 'Discussion',
      tabIcon: <MessageIcon />,
      tabContent: (
        <ChatWrapper hideButtons={((isNotDoctor && !completed) || consultationStatus === 'cancelled')}>
          <WebChat isTERRSS={ isTERESS }/>
        </ChatWrapper>
      )
    },
    {
      tabName: 'Infos patient',
      tabIcon: <ContactPage />,
      tabContent: <InfosPatient/>
    },
    {
      tabName: 'Documents',
      tabIcon: <DescriptionIcon />,
      tabContent: <DocumentsTab/>
    }
  ]

  const headerLeft = (
    <RecipientInfo
        firstName={idx(patient, _ => isDisplayTeamName ? _ : _.first_name)}
        lastName={idx(patient, _ => isDisplayTeamName ? ' ' : _.last_name)}
        specialization={idx(patient, _ => _.specialization)}
    />
  )

  const [actionsWidth, setActionsWidth] = useState(0)

  const headerRight = (
      <Actions
          onCallIconClick={() => onVideoIconClick(true)}
          onVideoIconClick={() => onVideoIconClick(false)}
          doc2uOpen={open}
          onDoc2UButtonClick={onDoc2UButtonClick}
          btnDoc2UText={btnDoc2UText}
          actionsWidth={actionsWidth}
          setActionsWidth={setActionsWidth}
      />
  )

  const patientInfo = (
    <div style={{ paddingLeft: '40px', paddingTop: '12px' }}>
      Patient : {!!consultationPatient && (
        <em>
          <DisplayGender genderCode={consultationPatient.gender} /> &nbsp;
          {consultationPatient.first_name} &nbsp;
          {consultationPatient.last_name} &nbsp;-&nbsp;
          <Sex code={consultationPatient.gender} />&nbsp;-&nbsp;
          {moment(consultationPatient.date_of_birth).format('D MMMM YYYY')}&nbsp;
          ({getAge(new Date(consultationPatient.date_of_birth))})
        </em>
    )}
    </div>
  )

  return (
    <Wrapper>
      <DialogWindow
        open={!!dialogWindowText}
        onClose={onDialogWindowClose}
        closeButtonText='ok'
      >
        {dialogWindowText}
      </DialogWindow>
      <ChatLayout
        doc2uOpen={open}
        tabs={doctorTabs}
        footer={footer}
        headerLeft={headerLeft}
        headerRight={headerRight}
        headerRightSize={actionsWidth}
        subHeader={patientInfo}
        subHeaderTabs={[0]}
      />
      {isTCA &&
        <Doc2U
          open={open}
          consultationId={consultationId}
          isDoctor={whomRequested === userId}
        />
      }
    </Wrapper>
  )
}

ChatComponent.defaultProps = {
  patient: {},
  subHeaderTabs: [],
}

ChatComponent.propTypes = {
  healthRecordsOpened: PropTypes.bool.isRequired,
  onPatientAvatarClick: PropTypes.func.isRequired,
  patient: PropTypes.object,
  onBackIconClick: PropTypes.func.isRequired,
  onVideoIconClick: PropTypes.func.isRequired,
  onDialogWindowClose: PropTypes.func.isRequired,
  medicalReportFilled: PropTypes.bool.isRequired,
  online: PropTypes.bool.isRequired,
  dialogWindowText: PropTypes.string.isRequired,
  subHeader: PropTypes.element,
  subHeaderTabs: PropTypes.array.isRequired,
}

const enhance = compose(
  connect(
    state => ({
      isPaymentCompleted: idx(state, _ => get(_, 'consultations.consultation.attributes.invoice.paid', false)),
      patient: patientSelector()(state), // ATTENTION : This doesn't always return the patient
      consultationPatient: idx(state, _ => get(_, 'consultations.consultation.relationships.patient.data.attributes', false)),
      userAvatar: idx(state, _ => _.auth.attributes.photo),
      messages: idx(state, _ => _.consultations.opentok.messages),
      user: idx(state, _ => _.auth.type).toLowerCase(),
      userId: idx(state, _ => _.auth.id),
      userData: idx(state, _ => _.auth.attributes),
      consultationId: idx(state, _ => _.consultations.consultation.id),
      consultation: idx(state, _ => _.consultations.consultation),
      whomRequested: idx(state, _ => _.consultations.consultation.relationships.doctor.data.id),
      consultationStatus: idx(state, _ => _.consultations.consultation.attributes.status),
      loading: state.loading.consultation,
      disableInput: !idx(state, _ => _.consultations.opentok.session.id),
      isNotDoctor: idx(state, _ => (_
        .consultations.consultation.attributes.type === CONSULTATION_TYPES.telexpertise || _.consultations.consultation.attributes.type === CONSULTATION_TYPES.TER_ESS
        || _.consultations.consultation.attributes.type === CONSULTATION_TYPES.TER || _.consultations.consultation.attributes.type === CONSULTATION_TYPES.TCA
        ? _.consultations.consultation.relationships.doctor.data.id !== _.auth.id
        : false)),
      completed: idx(state, _ => _.consultations.consultation.attributes.status === 'completed'),
      isTCA: idx(state, _ => _.consultations.consultation.attributes.type === CONSULTATION_TYPES.TCA),
      doc2URunning: idx(state, _ => _.consultations.consultation.attributes.doc2u !== null),
      healthRecordsOpened: idx(
        state,
        _ => _.consultations.consultation.attributes.access_health_record,
      ),
      medicalReportFilled: !!idx(
        state,
        _ => _.consultations.consultation.relationships.medicalReport.data
          .find(item => item.attributes.doctor === +_.auth.id),
      ),
      additionalMemberAvailable: idx(state, _ => _.consultations
        .consultation.relationships.additionalMember.data[0].attributes.availableTill.date),
    }),
    {
      pushMessage,
      fetchOpentokParams,
      fetchConsultation,
      toggleVideoAccess,
      acceptCall,
    },
  ),
  withStateHandlers({
    dialogWindowText: '',
  }, {
    setDialogWindowText: () => dialogWindowText => ({ dialogWindowText }),
  }),
  withProps(props => ({
    onBackIconClick: () => history.goBack(),
    onPatientAvatarClick: () => history.push('/panel/consultations/session/menu'),
    online: moment.utc((idx(props, _ => _
      .patient.available_till) || props.additionalMemberAvailable)).unix() > moment().unix(),
    onDialogWindowClose: () => props.setDialogWindowText(''),
    onVideoIconClick: (withoutVideo) => {
      if (props.consultationStatus === 'completed') props.setDialogWindowText('The consultation is completed')
      if (props.consultationStatus === 'confirmed' || props.consultationStatus === 'created' || props.consultationStatus === 'accepted') {
        props.toggleVideoAccess(!withoutVideo)
        props.acceptCall({consultation: props.consultation, user: props.userData })
        history.push('/panel/consultations/session')
        props.fetchOpentokParams(true)
        if (!props.isCalling) {
          socket.call(props.consultationId)
        }
      }
    },
  })),
)

export const Chat = enhance(ChatComponent)

import React, {useEffect, useState} from 'react'
import styled from 'styled-components'
import {Page} from "../../../../common/Page";
import {FormattedMessage, useIntl} from "react-intl";
import AddIcon from '@mui/icons-material/Add';
import RemoveIcon from '@mui/icons-material/Remove';
import {Colors} from 'constants/colors'
import {FavoritePS} from "../../../Patient/Doctors/Favorite/FavoritePS";
import {FavoriteTeams} from "../../../Patient/Doctors/Favorite/FavoriteTeams";
import {FavoriteStructures} from "../../../Patient/Doctors/Favorite/FavoriteStructures";
import {StyledTextButton} from "../../../../common/UiKit/TextButton";
import {useDispatch, useSelector} from "react-redux";
import {fetchHelpWindowView, fetchNetworkView} from "../../../../../redux/modules/formsView";
import {MenuInvitation} from "../../../../common/UiKit/MenuInvitation";
import {InstantSearchDialog} from "./InstantSearchDIalog";
import {fetchInvitations} from "../../../../../redux/modules/proInvite";
import {ProCards} from "./ProCard";


const Container = styled.div`
  background-color: ${Colors.background};
  border-radius: 5px;
`

const Wrapper = styled.div`
  padding: 24px;
  justify-content: space-between;
  align-content: space-between;
`

const HeadingTransparent = styled.header`
  margin: 0;
  font-family: Roboto, sans-serif;
  font-size: 16px;
  font-weight: 500;
  opacity: 0.9;
  justify-content: space-between;
  padding: 0 24px 15px;
  font-weight: 500;
  color: ${Colors.dark80};
  border-bottom: 1px solid ${Colors.cloudyBlue};  
  
  .MuiSvgIcon-root {
    color: ${Colors.dark80};
    transition: transform 200ms ease;
    cursor: pointer;
    position: relative;
    top: 5px;
  }

  button {
    font-weight: 500;
    font-size: 16px;
    margin-right: -16px;
  }
`

const ButtonBlock = styled.div`
  justify-content: end;
  margin: 20px 20px 20px 20px;
  display: flex;
`

const Title = styled.div`
  padding-left: 15px;
  justify-self: end;
  display: inline;
`

const InvitationWrapper = styled.div`
  background-color: #E5E5E5;
  width: 900px;
  color: #585C65;
  padding: 15px 25px 15px 25px;
  border-radius: 2px;
  font-size: 14px;
  display: flex;
  margin-right: auto;
  margin-left: auto;
  justify-content: space-around;
  align-items: center;
  @media screen and (max-width: 1600px) {
    width : 80%;
    flex-direction : row;
    justify-content: space-around;
    align-items: center;
  }
`

const Header = styled.div`
  display: flex;
  justify-content: space-around;
  align-items: center;
  margin-bottom: 10px;
`

const Content = styled.div`
  width: auto;
  margin: 10px auto;
  display: grid;
  grid-gap: 25px;
  grid-template-columns: repeat( auto-fill, minmax(315px, 1fr) );
  & > * {
    min-height: 0;
  }
`

export const MonReseau = ({match}) => {

    const { formatMessage } = useIntl()
    const f = id => formatMessage({ id })

    const [showPS, setShowPS] = useState(true)
    const [showES, setShowES] = useState(true)
    const [showStructure, setShowStructure] = useState(true)

    const dispatch = useDispatch();
    const doctorId = useSelector(state => state.auth.id);
    const isAlreadySeen = useSelector(state => state.formsView.isViewed.network_pop_up);
    const [showModal, setShowModal] = useState(!isAlreadySeen);
    const [isLoading, setIsLoading] = useState(true);

    const invitations = useSelector(state => state.invitations)

    const fetchIsViewed = () => {
        setIsLoading(true)
        Promise.all([
            dispatch(fetchHelpWindowView(doctorId)),
            dispatch(fetchNetworkView(doctorId)),
            dispatch(fetchInvitations()),
        ]).then(_ => {
            setIsLoading(false)
        })
    }

    useEffect(() => {
        fetchIsViewed();
    }, [])

    return (
        <Page
            label={f('doctor.reseau')}
            noHeaderCapitalize
            fullWidth
            style={{ backgroundColor: '#f5f7fb' }}
        >
            <Header>
                <InvitationWrapper>
                    <FormattedMessage id='roso.invite.message'/>
                    <MenuInvitation/>
                </InvitationWrapper>
                <ButtonBlock>
                    <StyledTextButton onClick={event => setShowModal(true)} color='white'>
                        <AddIcon fontSize={'large'}/>
                        <FormattedMessage id='roso.add' />
                    </StyledTextButton>
                </ButtonBlock>
            </Header>
            <InstantSearchDialog
              showModal={showModal}
              setShowModal={setShowModal}
              isAlreadySeen={isAlreadySeen}
              doctorId={doctorId}
            />
            {  (
                    <>
                        <Container>
                            <HeadingTransparent onClick={event => setShowPS(!showPS)}>
                                {showPS ? <RemoveIcon /> : <AddIcon />}
                                <Title> <FormattedMessage id={'auth.doctors'}  /> </Title>
                            </HeadingTransparent>

                            <div hidden={!showPS}>
                                <Wrapper >
                                    <Content>
                                        <ProCards invitations={invitations} />
                                        <FavoritePS match={match}/>
                                    </Content>
                                </Wrapper>
                            </div>
                        </Container>

                        <Container>
                            <HeadingTransparent onClick={event => setShowES(!showES)}>
                                {showES ? <RemoveIcon /> : <AddIcon />}
                                <Title> <FormattedMessage id={'roso.community.btn'}  /> </Title>
                            </HeadingTransparent>

                            <div hidden={!showES}>
                                <Wrapper >
                                    <FavoriteTeams/>
                                </Wrapper>
                            </div>
                        </Container>

                        <Container>
                            <HeadingTransparent onClick={event => setShowStructure(!showStructure)}>
                                {showStructure ? <RemoveIcon /> : <AddIcon />}
                                <Title> <FormattedMessage id={'roso.structures'}  /> </Title>
                            </HeadingTransparent>
                            <div hidden={!showStructure}>
                                <Wrapper >
                                    <FavoriteStructures/>
                                </Wrapper>
                            </div>
                        </Container>
                    </>
                )
            }

        </Page>
    )
}


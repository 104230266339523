import React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import styled from 'styled-components'
import { Page } from '../../../common/Page'
import { logOut } from '../../../../redux/modules/auth'
import {CalendarComponent} from "../../../common/Calendar";

export const CalendarPage = () => {
  const dispatch = useDispatch()
  const doctorId = useSelector(state => state.auth.id)


  return (
    <PageStyled
      label='schedule.title'
      fullWidth
      onLogout={() => dispatch(logOut())}
    >
      {
              <CalendarComponent
                  doctorId={doctorId}
              >
              </CalendarComponent>
      }


</PageStyled>

)
}

const PageStyled = styled(Page)`
overflow: initial;

& > div {
&, & > div {
overflow: initial;
}
}
`

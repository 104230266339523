import React from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { compose, withStateHandlers } from 'recompose'
//import { UserInfo } from 'common/UiKit/UserInfo'
//import { TabsFilled } from 'common/UiKit/TabsFilled'
import { ConsultationProfilePanel } from 'common/UiKit/ConsultationProfilePanel'
//import { HealthRecordsTab } from './HealthRecordsTab'
//import { ConsultationsTab } from './ConsultationsTab'

//const TABS = ['Health Records', 'Consultations']

const ProfileComponent = ({
  onCloseIconClick,
  //user,
  //tab,
  //changeTab,
}) => (
  <ConsultationProfilePanel onCloseIconClick={onCloseIconClick}>
    {/*
    <UserInfo small {...user}>
      <TabsFilled
        tabs={TABS}
        checkedTab={tab}
        onClick={changeTab}
      />
    </UserInfo>
    {tab === 0 && <HealthRecordsTab />}
    {tab === 1 && <ConsultationsTab />}

    */}
  </ConsultationProfilePanel>
)

ProfileComponent.propTypes = {
  onCloseIconClick: PropTypes.func.isRequired,
  //user: PropTypes.object.isRequired,
  //tab: PropTypes.number.isRequired,
  //changeTab: PropTypes.func.isRequired,
}

const enhance = compose(
  connect(
    state => ({ user: state.consultations.consultation.user }),
  ),
  withStateHandlers({
    tab: 0,
  }, {
    changeTab: () => e => ({ tab: +e.target.value }),
  }),
)

export const Profile = enhance(ProfileComponent)

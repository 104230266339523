import React from 'react'
import GooglePlay from '../../images/auth/google-play.png'
import AppStore from '../../images/auth/app-store.png'
import styled from 'styled-components'
import { FormattedMessage } from "react-intl";
import { Colors } from "../../constants/colors";

export const BottomBar = ({ background = 'white' }) => {
  return(
    <Layout background={background}>
      <WrappToggleRowColumn>
        <NormalColumnItem>
          <img alt='CONEX SANTE' src={process.env.PUBLIC_URL + '/logo_conexsante_large_240_31.png'} width='150px' />
        </NormalColumnItem>
        <NormalColumnItem>
          <FormattedMessage id='footer.conex-sante' />
        </NormalColumnItem>
        <TousDroitsRes>
          {'© '}{new Date().getFullYear()}
          <FormattedMessage id='auth.all.rights.reserved' />
        </TousDroitsRes>
      </WrappToggleRowColumn>
      <WrappToggleRowColumnWithMargin>
        <FooterTitle>Confidentialité</FooterTitle>
        <a target='_blank' href='https://conexsante.com/cgu/'>
          <FormattedMessage id='global.general.privacy-policy-conditions' />
        </a>
        <a target='_blank' href='https://conexsante.com/conditions-generales-de-vente/'>
          <FormattedMessage id='global.general.terms-and-conditions-of-sell' />
        </a>
        <a target='_blank' href='https://conexsante.com/politique-de-confidentialite/'>
          <FormattedMessage id='global.privacy-policy' />
        </a>
        <a target='_blank' href='https://conexsante.com/mentions-legales/'>
          <FormattedMessage id='global.legal-notices' />
        </a>
      </WrappToggleRowColumnWithMargin>
      <WrappToggleRowColumnWithMargin>
        <FooterTitle><FormattedMessage id='footer.more-on-conex' /></FooterTitle>
        <a target='_blank' href='/'>
          <FormattedMessage id='footer.link.home' />
        </a>
        <a target='_blank' href='https://www.facebook.com/conexsante/'>Facebook</a>
        <a target='_blank' href='https://www.linkedin.com/company/conex-sante/'>LinkedIn</a>
        <a target='_blank' href='https://twitter.com/ConexSante'>Twitter</a>
        <a target='_blank' href='mailto:support@conexsante.com'>
          <FormattedMessage id='footer.link.contact-us' />
        </a>
      </WrappToggleRowColumnWithMargin>
      <WrapperImages>
          <a href=" https://apps.apple.com/fr/app/conex-sant%C3%A9/id1588047693">
            <ImageSC style={{width : '100px'}} src={AppStore} alt="AppStore" />
          </a>
          <a href="https://play.google.com/store/apps/details?id=com.conexsante.android&gl=FR">
            <ImageSC style={{width : '100px'}} src={GooglePlay} alt="GooglePlay"/>
          </a>
      </WrapperImages>
    </Layout>
    )
}

// -------------------------------CSS--------------------------------------------------------------

const RESPONSIVE_WIDTH = '800px';

const Layout = styled.div`
  font-family: Roboto, sans-serif;
  font-weight: 300;
  font-size: 13px;
  background-color: ${props => props.background};
  align-items: start;
  margin-bottom: 2px;
  border-top : solid 0.5px ${Colors.steel};
  padding-top: 10px;
  padding-bottom: 10px;
  width: 80%;
  display: grid;
  grid-template-columns: 25% 25% 25% 25%; 
  margin: 40px auto 0;

  @media screen and (max-width: ${RESPONSIVE_WIDTH}) {
    grid-template-columns: 100%; 
  }
`

const ImageSC = styled.img`
  display: inline-block;
  vertical-align: middle;
`

const WrappToggleRowColumn = styled.div`
  display: flex;
  flex-direction: column;
  justify-content : flex-start;
  align-items: start;
  @media screen and (max-width: ${RESPONSIVE_WIDTH}) {
    display: none;
  }
`

const WrappToggleRowColumnWithMargin = styled(WrappToggleRowColumn)`
  margin-top: 30px;
  padding-left: 30px;

  a {
    font-size: 1em;
    text-decoration: none;
    color: ${Colors.footerGrey};
  }

  a:hover {
    text-decoration: underline;
  }
`

const FooterTitle = styled.h3`
  font-size: 1.05em;
  color: ${Colors.footerGrey};
  margin: 0 0 5px;
`

const WrapperImages = styled.div`
    display: flex;
    flex-direction : column;
    margin-right: auto;
    margin-left: auto;
    & > * {
      margin-top: 10px;
      margin-bottom: 10px;
    }
    justify-content: center;
    align-items: center;
    @media screen and (max-width: ${RESPONSIVE_WIDTH}) {
      width : 90%;
      flex-direction : row;
      justify-content: space-between;
      align-items: center;
  }
`

const NormalColumnItem = styled.div`
  padding-bottom: 15px;
  padding-right: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
  color: ${Colors.footerGrey};
  //font-size : 0.9em;
`

const TousDroitsRes = styled(NormalColumnItem)`
  color: ${Colors.steel};
`

import axios from "axios";
import {createAction, handleActions} from "redux-actions";

export const inviteProfessionalRequest = createAction('INVITE_PROFESSIONAL_REQUEST')
export const inviteProfessionalSuccess = createAction('INVITE_PROFESSIONAL_SUCCESS')
export const inviteProfessionalFailure = createAction('INVITE_PROFESSIONAL_FAILURE')

export const inviteProfessional = (professionalId, formValues) => (dispatch) => {
  dispatch(inviteProfessionalRequest(formValues))
  return axios
    .post(`/api/doctor/invite/${professionalId}`, formValues)
    .then(() => dispatch(inviteProfessionalSuccess()))
    .catch(err => {
      let errMessage = 'Erreur';
      if (err.response?.status >= 500) {
        errMessage = 'Unexpected error'
      } else if (err.response?.status >= 400) {
        if (err.response.errors) {
          errMessage = 'Invalid data : '
          for (const error of err.response.errors) {
            errMessage += error + ' | '
          }
        } else if (typeof err.response?.data === 'string') {
          errMessage = err.response.data
        }
      }
      dispatch(inviteProfessionalFailure(errMessage))
      throw err
    })
}

export const fetchInvitationsRequest = createAction('FETCH_INVITATIONS_REQUEST')
export const fetchInvitationsSuccess = createAction('FETCH_INVITATIONS_SUCCESS')
export const fetchInvitationsFailure = createAction('FETCH_INVITATIONS_FAILURE')

export const fetchInvitations = () => (dispatch) => {
  dispatch(fetchInvitationsRequest())
  axios.get('/api/doctor/invite')
    .then((response) => {
      dispatch(fetchInvitationsSuccess(response.data.data))
    })
    .catch((err) => {
      dispatch(fetchInvitationsFailure(err))
    })
}

const defaultState = {
  error: null
}

export const proInviteReducer = handleActions({
  [inviteProfessionalRequest]: () => defaultState,
  [inviteProfessionalFailure]: (state, action) => ({error: action.payload})
}, defaultState)

export const invitationsReducer = handleActions({
  [fetchInvitationsSuccess]: (state, action) => action.payload
}, [])
export const IGNORED_ERRORS = [
  'AbortError: The play() request was interrupted by a new load request. https://goo.gl/LdLk22',
  'AbortError: The play() request was interrupted by a call to pause(). https://goo.gl/LdLk22',
  'NotAllowedError: The play method is not allowed by the user agent or the platform in the current context, possibly because the user denied permission.',
  'NotAllowedError: play() failed because the user didn\'t interact with the document first. https://goo.gl/xX8pDD',
  'Failed to fetch',
  'The error you provided does not contain a stack trace.',
  'doctor.signature.notfound',
  'field.validation.rpps.exist',
]

export const IGNORED_ERRORS_MATCH = [
  /Confirmation code wrong '.*', the confirmation cancelled\./,
]

export const checkIgnoreMatchError = message => IGNORED_ERRORS_MATCH
  .some(el => el.test(message))

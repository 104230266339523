import React from 'react'
import styled from 'styled-components'
import PropTypes from 'prop-types'
import Create from '@mui/icons-material/Create'
import Delete from '@mui/icons-material/Delete'
import IconButton from '@mui/material/IconButton'
import Button from '@mui/material/Button'
import { Link } from 'react-router-dom'
import { FormattedMessage } from 'react-intl'
import { Colors } from '../../../../constants/colors'
import {StyledTextButton} from "../TextButton";

const Wrapper = styled.div`
  background-color: ${Colors.white};
  border-radius: 4px;
  ${props => (props.disableShadow ? '' : 'box-shadow: rgba(0, 0, 0, 0.02) 0px 1px 3px 0px, rgba(27, 31, 35, 0.15) 0px 0px 0px 1px;')}
  font-family: Roboto, sans-serif;
  font-size: 16px;
  color: ${Colors.dark};
  ${props => props.hoverColor && `
    :hover {
      color: ${props.hoverColor};
      box-shadow:inset 0px 0px 0px 2px ${props.hoverColor};
    }
  `}
`

const Content = styled.div`
  padding: 32px 24px 24px;
  ${props => props.hoverColor && `
    :hover {
      color: ${props.hoverColor};
    }
  `}
`

const Heading = styled.header`
  height: 50px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin: 0;
  padding: 0 24px;
  font-weight: 500;
  color: ${Colors.steel};
  border-bottom: 1px solid ${Colors.cloudyBlue};

  button {
    font-weight: 500;
    font-size: 16px;
    margin-right: -16px;
  }
`

const HeadingTransparent = styled.header`
  margin: 0;
  font-family: Roboto, sans-serif;
  font-size: 16px;
  padding: 0 24px 10px;
  font-weight: 500;
  color: ${Colors.dark};
  opacity: 0.9;
`

const Warning = styled.h1`
  margin: 0;
  padding: 16px 24px;
  font-size: 14px;
  font-weight: 500;
  color: ${Colors.yellowOchre};
  background-color: ${Colors.offWhite};
  border-radius: 4px;
`

const AddButton = styled(Button)`
  && {
    font-size: 14px;
    text-transform: uppercase;
    margin-right: -20px;
  }
`

const ChangeButton = styled(IconButton)`
  && {
    margin-right: -18px;
  }

  && svg {
    font-size: 18px;
  }
`

const Optional = styled.span`
  opacity: 0.6;
`

export const Paper = ({
  children,
  heading,
  headingTransparent,
  optional,
  warning,
  changeButtonPath,
  addButtonPath,
  disableShadow,
  onDeleteButtonClick,
  onCheckCompabilityButtonClick,
  onModifyButtonClick,
  additionalButton,
  papperRootProps = {},
  contentWrapperStyle = {},
  hoverColor,
  modifyButton,
  setOpen,
  ...rest
}) => {
  return (
    <div {...papperRootProps}>
      {headingTransparent && (
        <HeadingTransparent>
          <FormattedMessage id={headingTransparent} defaultMessage={headingTransparent} />
        </HeadingTransparent>
      )}
      <Wrapper disableShadow={disableShadow} style={contentWrapperStyle} hoverColor={hoverColor}>
        {warning
          && <Warning>{warning}</Warning>}
        {heading
          && (
            <>
              <Heading>
                <div>
                  <FormattedMessage id={heading} defaultMessage={heading} />
                  &nbsp;
                  {optional && <Optional><FormattedMessage id='global.optional' /></Optional>}
                </div>
                {
                  changeButtonPath
                  && (
                    <Link to={changeButtonPath}>
                      <ChangeButton color='primary'>
                        <Create fontSize='inherit' />
                      </ChangeButton>
                    </Link>
                  )
                }
                {
                  addButtonPath
                  && (
                    <Link to={addButtonPath}>
                      <AddButton color='primary'>
                        <FormattedMessage id='global.add' />
                      </AddButton>
                    </Link>
                  )
                }
                {
                  onDeleteButtonClick
                  && (
                    <ChangeButton color='primary' onClick={onDeleteButtonClick}>
                      <Delete fontSize='inherit' />
                    </ChangeButton>
                  )
                }
                {
                  onModifyButtonClick
                  && (
                    <ChangeButton color='primary' onClick={onModifyButtonClick}>
                      <Create fontSize='inherit' />
                    </ChangeButton>
                  )
                }
                {
                  onCheckCompabilityButtonClick
                  && (
                    <Button color='primary' onClick={onCheckCompabilityButtonClick}>
                      <FormattedMessage id='global.check.compatibility' />
                    </Button>
                  )
                }
                {
                  modifyButton
                  && (
                      <StyledTextButton
                          color='white'
                          onClick={() => setOpen(true)}
                          style={{height: '35px', width: '20%', marginRight: '5px'}}
                      >
                        <FormattedMessage id='global.edit'/>
                      </StyledTextButton>
                  )
                }
                {additionalButton || null}
              </Heading>
            </>
          )}
        <Content hoverColor={hoverColor} {...rest}>
          {children}
        </Content>
      </Wrapper>
    </div>
  )
}


Paper.defaultProps = {
  heading: '',
  headingTransparent: '',
  optional: false,
  warning: '',
  changeButtonPath: '',
  addButtonPath: '',
  disableShadow: false,
  onDeleteButtonClick: false,
  onCheckCompabilityButtonClick: false,
  hoverColor: '',
}

Paper.propTypes = {
  heading: PropTypes.string,
  headingTransparent: PropTypes.string,
  optional: PropTypes.bool,
  disableShadow: PropTypes.bool,
  warning: PropTypes.string,
  changeButtonPath: PropTypes.string,
  addButtonPath: PropTypes.string,
  onDeleteButtonClick: PropTypes.oneOfType([PropTypes.func, PropTypes.bool]),
  onCheckCompabilityButtonClick: PropTypes.oneOfType([PropTypes.func, PropTypes.bool]),
  hoverColor: PropTypes.string.isRequired,
}

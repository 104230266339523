import React from 'react'
import { withState } from 'recompose'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import IconButton from '@mui/material/IconButton'
import InputAdornment from '@mui/material/InputAdornment'
import MenuItem from '@mui/material/MenuItem'
import TextField from '@mui/material/TextField'
import Visibility from '@mui/icons-material/Visibility'
import VisibilityOff from '@mui/icons-material/VisibilityOff'

import { Colors } from 'constants/colors'

export const TextFieldStyled = styled(({ InputProps, InputLabelProps, ...rest }) => (
  <TextField
    {...rest}
    InputProps={{
      ...InputProps,
      classes: {
        root: 'root',
        multiline: 'multiline',
        underline: 'underline',
      },
    }}
    InputLabelProps={{
      ...InputLabelProps,
      classes: {
        error: 'labelError',
        root: 'labelRoot',
        focused: 'labelFocused',
      },
    }}
    FormHelperTextProps={{ classes: { root: 'helperRoot' } }}
    SelectProps={{
      classes: { select: 'select' },
    }}
  />
))`
  && {
    border-radius: 4px;
    background-color: transparent;
    width: 95%;
    padding-left : 10px;
    padding-right : 10px;
    margin-bottom: 24px;
    margin-top: 10px;
    font-size: 12.9px;
  }
  
  & .root {
    height: 50px;
    box-sizing: border-box;
    
    input {
      caret-color: ${Colors.bluePurple};
      height: 12px;
    }
     
    input[aria-invalid="true"] {
      caret-color: ${Colors.red};
      width : 100%;
    }
  }

  & .multiline {
    align-items: flex-start;
    line-height: 1.5;
        ${props => props.nblines
  && `
          height: ${props.nblines*30}px;
        `}
  }

  & .labelRoot {
    color: ${Colors.blueGrey};
    padding-left : 10px;
    padding-right : 10px;
    z-index: 0;
    margin: 0;
    font-size: 12.9px;
  }

  & .labelFocused:first-child:not(.labelError) {
    color: ${Colors.bluePurple};
    padding-left : 10px;
    padding-right : 10px;
    z-index: 1;

  }

   .MuiInput-underline:after {
    border-bottom: 2px solid ${Colors.bluePurple};
  }
   .MuiInput-underline:before{
    border-bottom: 1px solid ${Colors.grey};
  }

  & .helperRoot {
    width: 100%;
    position: absolute;
    top: 100%;
    box-sizing: border-box;
    margin: 0;
    padding: 4px 12px 0;
    background-color: white;
    ${props => props.maxLength
        && `
          text-align: right;
          color: ${Colors.steel};
        `}
  }

  .select:focus {
    background-color: transparent;
  }
`

const IconButtonStyled = styled(IconButton)`
  && {
    margin-bottom: 10px;
  }
`

const InputComponent = ({
  variant,
  error,
  errorMessage,
  handleClickShowPassword,
  maxLength,
  options,
  password,
  showPassword,
  value,
  type,
  onClick,
  helperText,
  InputProps,
  ...rest
}) => (
  <TextFieldStyled
    {...rest}
    variant={variant ? variant : 'outlined'}
    error={error || !!errorMessage}
    helperText={maxLength && !errorMessage ? `${value.length}/${maxLength}` : errorMessage || helperText}
    select={!!options}
    type={type || (showPassword || !password ? 'text' : 'password')}
    value={value}
    onClick={onClick}
    // Prop for styled-components
    maxLength={maxLength && !errorMessage}
    // Prop for password visibility icon
    InputProps={{
      ...InputProps,
      ...(!password || !value
        ? {}
        : {
          endAdornment: (
            <InputAdornment position='end'>
              <IconButtonStyled
                aria-label='Toggle password visibility'
                onClick={() => handleClickShowPassword(n => !n)}
              >
                {showPassword ? <Visibility /> : <VisibilityOff />}
              </IconButtonStyled>
            </InputAdornment>
          ),
        }),
    }}
  >
    {
      !options
        ? null
        : options.map(option => (
          <MenuItem
            style={ {fontSize: '12.9px'}}
            key={option.value + option.label}
            value={option.value}
          >
            {option.label}
          </MenuItem>
        ))
    }
  </TextFieldStyled>
);

const enhance = withState('showPassword', 'handleClickShowPassword', false);

export const Input = enhance(InputComponent);

Input.defaultProps = {
  helperText: '',
}

Input.propTypes = {
  error: PropTypes.bool,
  errorMessage: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]),
  handleClickShowPassword: PropTypes.func,
  maxLength: PropTypes.number,
  options: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string.isRequired,
      value: PropTypes.any.isRequired,
    }),
  ),
  password: PropTypes.bool,
  showPassword: PropTypes.bool,
  value: PropTypes.any.isRequired,
  type: PropTypes.string,
  onClick: PropTypes.func,
  helperText: PropTypes.any.isRequired,
}

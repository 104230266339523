import Searchkit from "searchkit";
import Client from "@searchkit/instantsearch-client";
import {Dispatch} from "redux";
import {CustomTransporterES} from "../transporters/EsTransporter";

export class ElasticSearchService {

  constructor(profile = null) {
    this.profile = profile;
    // Fields returned on results
    this.resultsAttributes = ['nomExercice', 'prenomExercice', 'codeCivilite', 'codeCiviliteExercice', 'libelleProfession', 'location', 'first_name', 'last_name', 'rpps', 'adeli', 'type', 'gender', 'specialization_key', 'specialization_id', 'specialization', 'specialization_type', 'name', 'description', 'city', 'indexType', 'user_id', 'workplace', 'photo', 'nomSpecialiteV1', 'userStatus'];
  }

  createSk(searchAttributes = [], queryRewrite = null, is_id = false): Searchkit {
    if (queryRewrite) {
      return createQueryRewriteSk(queryRewrite, searchAttributes,this.resultsAttributes ,is_id);
    } else {
      return createSk(searchAttributes, this.resultsAttributes);
    }
  }

  // Client needed for some treatments after/before the request
  createClient(sk, reverseGeocoding, searchbar = false, dispatcher: {
    dispatch: Dispatch,
    fetchAction: ReduxActions,
    firstObject: boolean
  } | null = null) {
    return Client(sk, {
      hooks: {
        beforeSearch: async(searchRequests) => {
          return searchRequests.map((sr) => {
            if (reverseGeocoding?.latitude && reverseGeocoding?.longitude) {
              const locationString = formatLatLong(reverseGeocoding);

              return {
                ...sr,
                body: {
                  ...sr.body,
                  "sort": [
                    {
                      "_geo_distance": {
                        "location": [locationString],
                        "unit": "m",
                        "mode": "min",
                        "order": "asc",
                        "ignore_unmapped": true
                      }
                    }
                  ]
                }
              }
            }
            return sr;
          })
        },
        afterSearch: async (searchRequest, searchResponse) => {
          let arrayResponseFormat = [];

          // If multiple response, we need all in the first
          searchResponse.forEach((response) => {
            const arrayFormat = response?.hits?.hits?.map((object) => {
              return formatObjectJson(object, !!searchbar);
            });
            if (arrayFormat){
              arrayResponseFormat = [...arrayResponseFormat, ...arrayFormat];
            }
          });
          arrayResponseFormat.sort((a, b) => b._score > a._score);

          //filter current doctor
          if (this.profile?.type === 'Doctor') {
            arrayResponseFormat = arrayResponseFormat.filter((objectResponse) =>
              objectResponse._source.indexType !== 'Doctor' || objectResponse._source.indexType === 'Doctor' && objectResponse._id !== this.profile.id);
          }

          if (searchResponse) {
            searchResponse[0].hits.hits = arrayResponseFormat;
          }

          if (dispatcher && Object.keys(dispatcher)?.length) {
            dispatcher.dispatch(dispatcher.fetchAction(dispatcher.firstObject ? arrayResponseFormat[0].attributes : arrayResponseFormat));
          }

          searchResponse = searchResponse.filter((response) => !response?.error);

          searchResponse[0].hits.hits = filterProAlreadySubscribed(searchResponse[0].hits.hits);

          return searchResponse;
        }
      }
    });
  }
}

function formatObjectJson(object, isInSource = false) {
  if (isInSource) {
    object._source.id = object._id;
    object._source.type = object._source.indexType;
    if (object._source.type === 'Annuaire') {
      object._source.gender = object._source.codeCivilite;
      object._source.first_name = object._source.prenomExercice;
      object._source.last_name = object._source.nomExercice;
      object._source.specialization = object._source.nomSpecialiteV1 ? object._source.nomSpecialiteV1 : object._source.libelleProfession;
    }
    object._source.attributes = {...object._source};
    delete object._source.attributes['attributes'];
    return object;
  } else {
    object._source.id = object._id;
    object.id = object._id;
    object.type = object._source.indexType;
    object._source.distance = object.sort && object._source.location?.lat ? object.sort[0] : null;
    object.attributes = {...object._source};
    if (object._source.indexType === 'Annuaire') {
      object.attributes = {
        ...object.attributes,
        first_name: object.attributes.prenomExercice,
        last_name: object.attributes.nomExercice,
        gender: object.attributes.libelleCivilite,
        specialization: object._source.nomSpecialiteV1 ? object._source.nomSpecialiteV1 : object._source.libelleProfession,
      }
    }
    if (object._source.indexType === 'Annuaire' || object._source.indexType === 'Doctor') {
      object.attributes.type = object.attributes.indexType;
    }

    return object;
  }
}

function formatLatLong(reverseGeocoding) {
  let latitude: string = reverseGeocoding.latitude;
  let longitude: string = reverseGeocoding.longitude;

  latitude = parseFloat(latitude);
  longitude = parseFloat(longitude);
  return `${latitude.toString()},${longitude.toString()}`;
}

// searchKit for request with initial value or exact id
function createQueryRewriteSk(queryRewrite: string, searchAttributes: [],resultAttributes: [] ,is_id: boolean = false) {
  if (is_id) {
    return new Searchkit({
      connection: new CustomTransporterES('_pro_search_v1'),
      search_settings: {
        search_attributes: searchAttributes,
        result_attributes: resultAttributes,
        geo_attribute: "location",
        "query_rules": [
          {
            "id": "1",
            "conditions": [
              []
            ],
            "actions": [
              {
                "action": "PinnedResult",
                "documentIds": [queryRewrite]
              }
            ],
          }],
      },
    })
  } else {
    return new Searchkit({
      connection: new CustomTransporterES('_pro_search_v1'),
      search_settings: {
        search_attributes: searchAttributes,
        result_attributes: resultAttributes,
        geo_attribute: "location",
        "query_rules": [
          {
            "id": "1",
            "conditions": [
              []
            ],
            "actions": [
              {
                "action": "QueryRewrite",
                "query": queryRewrite
              }
            ],
          }],
      },
    })
  }
}

// Remove the professionals of the index pro_directory from the search result if they already have a Conex Sante account
function filterProAlreadySubscribed(searchResults) {
  let identifiersArray = []
  // Retrieving the adeli and rpps of all the pro already registered in the search's result
  searchResults.forEach(result => {
    if (result._source.type === "Doctor") {
      identifiersArray.push({"rpps": result._source.rpps, "adeli": result._source.adeli})
    }
  })

  // Removing the pros of the index pro_directory from the search's result, if their rpps or adeli
  // is already linked to a pro registered in the app
  return searchResults.filter(function(result) {
    if (result._source.indexType !== 'Annuaire' ) {
      return true
    }
    let alreadyRegistered = identifiersArray.some(identifier => {
        return result._source.rpps && identifier.rpps === result._source.rpps
      }) ||
      identifiersArray.some(identifier => result._source.adeli && identifier.adeli === result._source.adeli)

    return !alreadyRegistered
  })
}

function createSk(searchAttributes: [], resultAttributes: []) {
  return new Searchkit({
    connection: new CustomTransporterES('_general_search_v1'),
    search_settings: {
      search_attributes: searchAttributes,
      result_attributes: resultAttributes,
      geo_attribute: "location",
    },
  });
}
import React, { useState } from 'react'
import styled from 'styled-components'
import { Paper } from '@mui/material'
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import Grid from '@mui/material/Grid'
import { FormattedMessage } from 'react-intl'
import get from 'lodash/get';
import { useSelector } from 'react-redux';
import essLogo from '../../Community/ROSO/images/Logo_ESS.png'
import esspLogo from '../../Community/ROSO/images/Logo_ESSP.png'
import { history } from '../../../../../utils/history'
import GroupsIcon from '@mui/icons-material/Groups';
import {withRouter} from "react-router-dom";

const PaperStyle = {
  wordBreak: 'breakWord',
  marginTop: '15px',
  padding: '15px',
  borderRadius: '5px',
  boxShadow: 'rgba(6, 173, 255, 0.1) 0px 4px 8px 0px',
}

const GridStyle = {
  display: 'flex',
  alignItems: 'center',
}

const ItemComponent = (
  { team, location },
) => {
  const localization = useSelector(state => state.localization);
  const [show, setShow] = useState(false);
  const teamData = team.attributes;
  const specializations = get(team, 'relationships.specializations.data', []);
  const workPlaces = get(team, 'relationships.workPlace.data', []);
  const structures = get(team, 'relationships.structures.data', []);
  const mySkills = get(team, 'relationships.skills.data', []);

  return (
    <Container>
      <Paper style={PaperStyle}>
        <Grid container style={GridStyle}>
          <Grid item xs={2}><Img src={teamData.type === 'ESS' ? essLogo : esspLogo} alt='ess' /></Grid>
          <Grid
            onClick={() => history.push( location.pathname.search("panel/concilium/main/structure") ? `/panel/concilium/main/roso/detail/${teamData.id}` : `/panel/community/roso/detail/${teamData.id}`)}
            item
            xs={6}
          >
            <Name>{teamData.name}</Name>
          </Grid>
          <Grid item xs={2}>
            <Members>
              {teamData.member_count}
            </Members>
          </Grid>
          <Grid item xs={1}> <GroupsIcon fontSize='medium' sx={{ ml: 3 }} /> </Grid>
          <Grid item xs={1} style={{ textAlign: 'end' }}>
            <KeyboardArrowDownIcon 
              style={{ transform: !show ? 'rotate(180deg)' : 'none' }}
              onClick={() => { setShow(!show) }}
            />
          </Grid>
        </Grid>
      </Paper>
      <ShowContainer style={{ display: show ? 'block' : 'none' }}>
        <H5><FormattedMessage id='roso.team.specialties' />:&nbsp;
          {specializations
            .filter(el => el && el.attributes && el.attributes.name && el.attributes.names[localization])
            .map((specialization, i) => (
              <Specialization key={specialization.id}>
                {specialization.attributes.names[localization]}
                {i + 1 !== specializations.length ? ', ' : ''}
              </Specialization>
            ))}
        </H5>
        <H5>
          <FormattedMessage id='roso.team.city' />:&nbsp;
          {workPlaces.map((workplace, i) => (
            <span key={workplace.id}>{workplace.attributes.city}
              {i + 1 !== workPlaces.length ? ', ' : ''}
            </span>
          ))}
        </H5>
      
        <H5>
          Structure:&nbsp;
          {structures.map(structure => (
            <span key={structure.id}>
              {structure.attributes.name},&nbsp;
              {structure.attributes.address},&nbsp;
              {structure.attributes.city},&nbsp;
              {structure.attributes.zipcode}.
            </span>
          ))} 
        </H5>
        {mySkills && mySkills.length ? (
          <H5>
            <FormattedMessage id='roso.team.skills.domains' />:
            <SkillsBlock>
              {mySkills.map(el => (
                <Skills
                  key={el.attributes.id}
                >
                  {el.attributes.name}
                </Skills>
              ))}
            </SkillsBlock>
          </H5>
        ) : ''}
         
        <H5>
          <FormattedMessage id='roso.team.description' />:
          <p>{teamData.description}</p>
        </H5>
      </ShowContainer>
    </Container>
 
  )
}
const Specialization = styled.span`
  padding-right: 5px;
`
const ShowContainer = styled.div`
    max-width: 100%;
    background-color: hsl(0deg 0% 90%);
    padding: 15px;
    margin-bottom: 15px;
    border-radius: 5px;
    word-break: break-word;

`
const Name = styled.div`
  opacity: 0.8;
  font-family: Roboto;
  font-size: 18px;
  font-weight: 500;
  color: #21293e;
  cursor: pointer;
  
  &:hover {
    color: #6633FF;
  }
`

const Img = styled.img`
 width: 65px;
`
const H5 = styled.div`
  font-family: Roboto;
  font-size: 16px;
  font-weight: 500;
  margin: 10px 0;

  span {
  font-family: Roboto;
  font-size: 16px;
  font-weight: 400;
  }
  p {
  margin: 5px 0;
  font-family: Roboto;
  font-size: 15px;
  font-weight: 400;
  }
`
const Container = styled.div`
  max-width: 100%;
  min-width: 600px;
  margin: 15px auto;
 .MuiSvgIcon-root {
    color: #959fb8;
    transition: transform 200ms ease;
    cursor: pointer;  
 }
`
const Members = styled.div`
  text-align: end;
  color: #959fb8;
  font-size: 14px;
  font-weight: 200;
`
const SkillsBlock = styled.div`
  display: flex;
  max-width: 100%;
  flex-wrap: wrap;
`
const Skills = styled.div`
background-color: #6633ff;
padding: 7px;
border-radius: 5px;
margin: 7px;
color: #ffff;
margin-left: 0;
margin-top: 4px;
margin-bottom: 4px
word-break: initial;
display: inline-flex;
 font-size: 13px;    
 font-weight: bold;
 justify-content: center;
 text-align: center;
`
export const Item = withRouter(ItemComponent)
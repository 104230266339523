import React, {useEffect, useState} from 'react'
import styled from 'styled-components'
import axios from 'axios';
import { history } from '../../../../utils/history';
import { Paper } from 'common/UiKit/Paper'
import { Page } from '../../../common/Page'
import {FormattedMessage} from "react-intl"
import dompurify from "dompurify";
import {useDispatch, useSelector} from "react-redux";
import {loadRequisInstructions} from "../../../../redux/modules/doctors";

const Wrapper = styled.div`
  margin-top: 24px;
`
const PaperStyled = styled(Paper)`
  margin-bottom: 16px;
  padding: 12px 24px 12px 24px;
  background-color: white;
  font-size: 14px;
`

const EmptyInstructions = styled.div`
  color: #7C808A;
  line-height: 20px;
  text-align: justify;
`

export const InstructionsComponent = () => {
  const dispatch = useDispatch()
  const instructions = useSelector(state => state.doctors.instructions)
  const sanitizer = dompurify.sanitize;

  useEffect(() => {
    dispatch(loadRequisInstructions()).then()
  }, []);

  return (
      <Page
          label='profile.instructions'
          onBackIconClick={ () => history.goBack()}
      >
          <Wrapper>
              <PaperStyled
                  heading='profile.instructions.title'
                  changeButtonPath='/panel/instructions/edit'
              >
                  { instructions ?
                      <div dangerouslySetInnerHTML={{ __html: sanitizer(instructions) }} />
                    :
                      <EmptyInstructions>
                          <FormattedMessage id='profile.instructions.empty'/>
                      </EmptyInstructions>
                  }
              </PaperStyled>
          </Wrapper>
      </Page>
  ) 
}

const COUNTRIES = [
  { label: 'France', value: 'France' },
]

const GENDER_OPTIONS = ['male', 'woman']
const GENDER_OPTIONS_DOCTOR = ['doctor', 'professor']

const normalizeInitialValues = values => ({
  hospital_name: values.work_place.name || '',
  country: values.work_place.country || 'France',
  city: values.work_place.city || '',
  street: values.work_place.street || '',
  postcode: values.work_place.postcode || '',
  credentials_rpps: values.credentials_rpps || '',
  credentials_adeli: values.credentials_adeli || '',
  credentials_order_number: values.credentials_order_number || '',
  daily_price: parseInt(values.daily_price, 10) || '',
  weekend_price: parseInt(values.weekend_price, 10) || '',
  urgent_price: parseInt(values.urgent_price, 10) || '',
  work_place: {
    name: values.hospital_name || '',
    country: values.country || '',
    city: values.city || '',
    street: values.street || '',
    postcode: values.postcode || '',
  },
})

const normalizeValues = values => ({
  work_place: {
    name: values.hospital_name || '',
    country: values.country || '',
    city: values.city || '',
    street: values.street || '',
    postcode: values.postcode || '',
  },
  credentials_rpps: values.credentials_rpps || '',
  credentials_adeli: values.credentials_adeli || '',
  credentials_order_number: values.credentials_order_number || '',
  daily_price: values.daily_price || '',
  weekend_price: values.weekend_price || '',
  urgent_price: values.urgent_price || '',
})

export { GENDER_OPTIONS, GENDER_OPTIONS_DOCTOR, COUNTRIES, normalizeInitialValues, normalizeValues };
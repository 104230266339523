import axios from 'axios';

export const uploadSingleFile = (data, setProgress) => {
  const formData = new FormData()

  formData.append('file', data.file)

  return axios.post('/api/attachment/upload', formData, {
    headers: {
      "Content-Type": "multipart/form-data",
    },
    onUploadProgress: data => {
      setProgress(Math.round((100 * data.loaded) / data.total))
    }
  })
    .then(response => response)
    .catch((err) => {
      console.warn(err);
    })
}


export const uploadSingleFileIdentify = (data, setProgress) => {
  const formData = new FormData()

  formData.append('file', data.file)

  return axios.post('/api/attachment/upload/identify', formData, {
    headers: {
      "Content-Type": "multipart/form-data",
    },
    onUploadProgress: data => {
      setProgress(Math.round((100 * data.loaded) / data.total))
    }
  })
    .then(response => response)
    .catch((err) => {
      console.warn(err);
    })
}

import {combineReducers} from "redux";
import {createAction, handleActions} from "redux-actions";
import axios from "axios";
export const fetchChatMembersListRequest = createAction('FETCH_CHAT_MEMBERS_LIST_REQUEST')
export const fetchChatMembersListSuccess = createAction('FETCH_CHAT_MEMBERS_LIST_SUCCESS')
export const fetchChatMembersListFailure = createAction('FETCH_CHAT_MEMBERS_LIST_FAILURE')

/**
 * Retrieves all the chatMembers for a discussion
 * @param params
 * @returns {function(*): Promise<AxiosResponse<any>>}
 */
export const fetchChatMembers = params => (dispatch) => {
  let id = params.discussionId;
  dispatch(fetchChatMembersListRequest(id))

  return axios(`/discussion/${id}/members`)
    .then((res) => {
      dispatch(fetchChatMembersListSuccess(res.data.data))
    })
    .catch((e) => {
      dispatch(fetchChatMembersListFailure(e))
    })
}

/**
 *  Remove a chatMember from a discussion
 * @param discussionId
 * @param userId
 * @returns {function(*): Promise<AxiosResponse<any>>}
 */
export const removeChatMember = (discussionId, userId) => (dispatch) => {
  dispatch(fetchChatMembersListRequest(discussionId, userId))
  return axios.delete(`/discussion/${discussionId}/member/${userId}`)
    .then((res) => {
      dispatch(fetchChatMembersListSuccess(res.data.data))
    })
    .catch((e) => {
      dispatch(fetchChatMembersListFailure(e))
    })
}

/**
 * Add chatMembers to a discussion
 * @param discussionId
 * @param members
 * @returns {function(*): Promise<AxiosResponse<any>>}
 */
export const addChatMembers = (discussionId, members) => (dispatch) => {
  const formData = new FormData()
  members.forEach(member => {
    formData.append('members[]', member.data.attributes.user_id);
  })
  dispatch(fetchChatMembersListRequest(discussionId, members))
  return axios(`/discussion/${discussionId}/members`, {
    method: 'POST',
    data: formData,
  })
  .then((res) => {
    dispatch(fetchChatMembersListSuccess(res.data.data))
  })
  .catch((e) => {
    dispatch(fetchChatMembersListFailure(e))
  })
}

const list = handleActions({
  [fetchChatMembersListSuccess]: (state, action) => ({
    ...state,
    data: action.payload,
  }),
}, [])

export const chatmembers = combineReducers({
  list,
})
import React, { useState } from 'react'
import PropTypes from 'prop-types'
import Dialog from '@mui/material/Dialog'
import DialogTitle from '@mui/material/DialogTitle'
import DialogContent from '@mui/material/DialogContent'
import DialogActions from '@mui/material/DialogActions'
import Button from '@mui/material/Button'
import { FormattedMessage } from 'react-intl'
import { useDispatch, useSelector } from 'react-redux'
import CircularProgress from '@mui/material/CircularProgress'
import styled from 'styled-components'

import { PAYMENT_METHODS } from '../../../../../../../constants/paymentMethods'
import { Method } from './Method'
import { pay } from '../../../../../../../redux/modules/consultations'
import Table from "@mui/material/Table";
import TableCell from "@mui/material/TableCell";
import {Colors} from "../../../../../../../constants/colors";
import {Divider} from "@mui/material";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";

export const MethodSelectDialog = ({
  open,
  onClose,
  price,
}) => {
  const [selected, select] = useState('card')
  const loading = useSelector(state => state.loading.payment)
  const id = useSelector(state => state.consultations.consultation.id)
  const dispatch = useDispatch()

  const onConfirmClick = () => dispatch(pay(id)).then(() => onClose())

  return (
    <Dialog open={open} onClose={onClose}>
      {loading && (
        <SpinnerWrapper>
          <CircularProgress />
        </SpinnerWrapper>
      )}
      <DialogTitle>
        <FormattedMessage id='payments.select.method' />
      </DialogTitle>
      <DialogContent style={{textAlign: "center"}}>
        {
          PAYMENT_METHODS.map(item => (
            <Method
              onClick={() => select(item.value)}
              icon={item.icon}
              key={item.value}
              selected={selected === item.value}
            />
          ))
        }
        
        {price &&
          <Table>
            <TableHead>
              <TableRow>
                <TableCell/>
                <TableCell/>
              </TableRow>
              <TableRow>
                <TableCell align={"left"}><FormattedMessage tagName='div' id='consultation.total.sum'/></TableCell>
                <TableCell align={"right"} style={{color: Colors.bluePurple}}>{price.toFixed(0)}€</TableCell>
              </TableRow>
            </TableHead>
          </Table>
        }
      </DialogContent>
      <DialogActions>
        <Button size='small' color='primary' onClick={onClose}>
          <FormattedMessage id='global.cancel' />
        </Button>
        <Button size='small' color='primary' onClick={onConfirmClick}>
          <FormattedMessage id='payments.confirm' />
        </Button>
      </DialogActions>
    </Dialog>
  )
}

MethodSelectDialog.propTypes = {
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
}

const SpinnerWrapper = styled.div`
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: white;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 1;
`

import React from 'react'
import PropTypes from 'prop-types'
import { PricesConsultations } from 'common/UiKit/PricesConsultations'
import { EditPage } from 'common/UiKit/EditPage'
import { connect } from 'react-redux'
import { mapProps, compose } from 'recompose'
import { history } from '../../../../../utils/history'
import { createPrices } from '../../../../../redux/modules/auth'
import { externalFormSubmit } from '../../../../../helpers/externalFormSubmit'
import { EmploymentTypeField } from '../EmploymentTypeField';

export const EditPricesConsultationsComponent = ({
  initialValues,
  onSubmit,
  employmentType,
}) => (
  <EditPage
    label='profile.edit.prices'
    onBackIconClick={() => history.push('/panel/profile')}
    buttonText='global.save'
    onButtonClick={() => externalFormSubmit('prices')}
  >
    <PricesConsultations
      id='prices'
      initialValues={initialValues}
      disableShadow
      onSubmit={onSubmit}
      afterSubmit={() => history.push('/panel/profile')}
      methodField={<EmploymentTypeField />}
      employmentType={employmentType}
    />
  </EditPage>
);

EditPricesConsultationsComponent.propTypes = {
  initialValues: PropTypes.object.isRequired,
  onSubmit: PropTypes.func.isRequired,
}

const enhance = compose(
  connect(
    ({ auth }) => ({
      initialValues: {
        daily_price: auth.attributes.price.daily_price,
        weekend_price: auth.attributes.price.weekend_price,
        urgent_price: auth.attributes.price.urgent_price,
        requisites_private_bic: auth.attributes.requisites.private.bic || '',
        requisites_private_iban: auth.attributes.requisites.private.iban || '',
        requisites_hospital_bic: auth.attributes.requisites.hospital.bic || '',
        requisites_hospital_iban: auth.attributes.requisites.hospital.iban || '',
      },
      employmentType: auth.attributes.type_employment,
    }),
    { createPrices },
  ),
  mapProps(props => ({
    ...props,
    onSubmit: values => props.createPrices({
      daily_price: values.daily_price,
      weekend_price: values.weekend_price,
      urgent_price: values.urgent_price,
      requisites_private_bic: values.requisites_private_bic || '',
      requisites_private_iban: values.requisites_private_iban || '',
      requisites_hospital_bic: values.requisites_hospital_bic || '',
      requisites_hospital_iban: values.requisites_hospital_iban || '',
    }),
  })),
)

export const EditPricesConsultations = enhance(EditPricesConsultationsComponent)

import React from 'react'
import { Route, Switch } from 'react-router-dom'
import { List } from './List'
import { Session } from './Session'
import { ConsultationById } from './ById'

export const Consultations = () => (
  <Switch>
    <Route path='/panel/consultations/session' component={Session} />
    <Route path='/panel/consultations/:id' component={ConsultationById} />
    <Route path='/panel/consultations' component={List} />
  </Switch>
)

import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import { Colors, calendarColors } from 'constants/colors'
import { CONSULTATION_TYPES } from '../../../../../../../constants/consultation'

export const MonthDayConsultation = ({
  consultation: {
    patient,
    status,
    doctor,
    creatorDoctor,
    type,
  },
  time,
  myId,
  onClick,
}) => (
  <Wrapper
    status={type === CONSULTATION_TYPES.telexpertise ? CONSULTATION_TYPES.telexpertise : status}
    onClick={onClick}
  >
    {time}: {(patient || (myId === (doctor || {}).id ? creatorDoctor : doctor) || {}).name}
  </Wrapper>
)

MonthDayConsultation.propTypes = {
  consultation: PropTypes.object.isRequired,
  time: PropTypes.string.isRequired,
}

const Wrapper = styled.div`
  box-sizing: border-box;
  padding: 8px;
  font-size: 14px;
  font-weight: 700;
  color: ${props => (props.status !== 'cancelled' ? Colors.dark80 : Colors.disabledText)};
  margin-right: 4px;
  margin-bottom: 8px;
  border-top-right-radius: 4px;
  border-bottom-right-radius: 4px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  cursor: ${({ onClick }) => (onClick ? 'pointer' : 'default')};
  
  border-left: 3px solid ${(props) => {
    switch (props.status) {
      case 'cancelled':
        return calendarColors.cancelledBorder
      case 'completed':
        return calendarColors.completedBorder
      case CONSULTATION_TYPES.telexpertise:
        return calendarColors.conciliumBorder
      default:
        return Colors.clearBlue
    }
  }};
  
  background-color: ${(props) => {
    switch (props.status) {
      case 'cancelled':
        return calendarColors.cancelledBg
      case 'completed':
        return calendarColors.completedBg
      case CONSULTATION_TYPES.telexpertise:
        return calendarColors.conciliumBg
      default:
        return calendarColors.confirmedBg
    }
  }};
`

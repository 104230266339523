import React, {useEffect, useState} from 'react'
import styled from 'styled-components'
import {Dialog, DialogContent, DialogTitle} from "@material-ui/core";
import {FormattedMessage, useIntl} from "react-intl";
import GroupsIcon from "@mui/icons-material/Groups";
import {Colors} from "../../../../../constants/colors";
import CloseIcon from "@mui/icons-material/Close";
import IconButton from "@mui/material/IconButton";
import {TextButton} from "../../../../common/UiKit/TextButton";
import EastIcon from "@mui/icons-material/East";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import {useDispatch, useSelector} from "react-redux";
import {fetchRecentContacts} from "../../../../../redux/modules/recentContact";
import {Divider} from "@mui/material";
import defaultAvatar from "../../../../../images/panel/default-avatar.svg";
import {fetchContacts} from "../../../../../redux/modules/contacts";
import idx from "idx";
import {addChatMembers} from "../../../../../redux/modules/chatmembers";

const DialogStyled = styled(Dialog)`
  backdrop-filter: blur(2px);
`

const IconButtonStyled = styled(IconButton)`
  width: 5px;
  height: 5px;
  margin-top: 5px;
  margin-right: 5px;
  margin-left: 10px;
  padding: 20px 20px 20px 20px;
  color: ${Colors.steel};
  position: absolute;
  top: 0;
  right: 0;
`

const Header = styled.div`
  font-family: Roboto, sans-serif;
  display: flex;
  font-size: 20px;
  font-weight: 500;
  color: black;
  margin-bottom: 20px;
`

const TitleStyled = styled.div`
  margin-top: 10px;
  margin-left: 20px;
`

const GroupIconStyled = styled(GroupsIcon)`
  justify-self: center;
  min-width: 40px;
  width: 40px;
  height: 40px;
  padding: 3px;
  box-sizing: border-box;
  border-radius: 100%;
  border: 2px solid ${Colors.bluePurple};
  background-color: rgba(147, 132, 189, 0.5);
  color: ${Colors.bluePurple};
  grid-row: 1/3;
  grid-column: 1;
`

const Subtitle = styled.div`
  font-size: 18px;
  color: black;
  margin-left: 10px;
`

const Letter = styled.div`
  font-size: 18px;
  color: black;
  margin-left: 10px;
  margin-top: 10px;
`

const WrapperSearch = styled.div`
  width: 60%;
  position: relative;
  margin: 10px 0 20px 0;
  & > svg {
    position: absolute;
    left: 15px;
    top: 13px;
    fill: ${Colors.bluePurple};
  }
`

const Input = styled.input`
  background-color: white;
  border: 1px solid ${Colors.textMuted};
  border-radius: 3px;
  outline: none;
  height: 40px;
  padding: 16px 16px 16px 16px;
  box-sizing: border-box;
  width: 600px;
  font-size: 14px;
  font-weight: 500;
  color: black;
  ::placeholder {
    color: black;
    font-size: 14px;
    font-weight: 500;
  }
`

const NextButton = styled(TextButton)`
  box-shadow: none;
  align-self: center;
  padding-top: 5px;
  padding-bottom: 5px;
  && {
    width: 20%;
    height: 35px;
    margin-top: 15px;
    margin-bottom: 15px;
    background-color: ${Colors.bluePurple};
    border: 1px solid white;
    color: white;
  }
  &:hover{
    background-color: rgb(71, 35, 178);
    border: 1px solid white;
    color: white;
  }
  text-transform : none;
  font-weight: 500;
  font-size: 14px;
`

const AddButton = styled(TextButton)`
  box-shadow: none;
  align-self: center;
  padding-top: 5px;
  padding-bottom: 5px;
  && {
    width: 20%;
    height: 35px;
    margin-top: 15px;
    margin-bottom: 15px;
    background-color: ${Colors.bluePurple};
    border: 1px solid white;
    color: white;
  }
  &:hover{
    background-color: rgb(71, 35, 178);
    border: 1px solid white;
    color: white;
  }
  text-transform : none;
  font-weight: 500;
  font-size: 14px;
`

const Arrow = styled(EastIcon)`
  margin-left: 8px;
  margin-right: 0;
  height: 20px;
  width: 20px;
`

const ListItemStyled = styled(ListItem)`
  cursor: pointer;
  &&:hover {
    background-color: #E7E2F6;
  }
`

const Avatar = styled.div`
  justify-self: center;
  min-width: 30px;
  width: 30px;
  height: 30px;
  box-sizing: border-box;
  border-radius: 100%;
  background: url(${props => props.photo || defaultAvatar}) ${Colors.lightGrey} center no-repeat; 
  cursor: ${props => (!props.onClick ? 'default' : 'pointer')};
  background-size: cover;
  grid-row: 1/3;
  grid-column: 1;
`

const FullName = styled.div`
  font-size: 16px;
  margin-left: 10px;
`

const Specialization = styled.div`
  margin-left: 5px;
  font-size: 14px;
  color: ${Colors.footerGrey};
`

const SelectedItem = styled.div`
  display: inline-flex;
  background-color: ${Colors.purpleGrey};
  border-radius: 15px;
  width: fit-content;
  height: 35px;
  align-items: center;
  margin-bottom: 10px;
  margin-right: 5px;
`

const Wrapper = styled.div`
  margin-left: 5px;
  margin-right: 5px;
  display: flex;
`

const SelectedAvatar = styled.div`
  justify-self: center;
  min-width: 28px;
  width: 28px;
  height: 28px;
  box-sizing: border-box;
  border-radius: 100%;
  background: url(${props => props.photo || defaultAvatar}) ${Colors.lightGrey} center no-repeat; 
  cursor: ${props => (!props.onClick ? 'default' : 'pointer')};
  background-size: cover;
  grid-row: 1/3;
  grid-column: 1;
`

const SelectedFullName = styled.div`
  font-size: 14px;
  margin-left: 7px;
  margin-top: 7px;
`

const Close = styled(IconButton)`
  width: 3px;
  height: 3px;
  margin-top: 2px;
  margin-right: 7px;
  padding: 10px 10px 10px 10px;
  color: ${Colors.steel};
`

const WrapperSelectedItem = styled.div`
  flex-flow: row wrap;
  display: flex;
`

/**
 *
 * @param showModal
 * @param onHide
 * @param setDialogPage
 * @param membersAdded
 * @param setMembersAdded
 * @param isSearch determines if the component is called from the searchMember modal or for the group creation
 * @returns {JSX.Element}
 * @constructor
 */
export const AddContacts = ({showModal, onHide, setDialogPage, membersAdded, setMembersAdded, isSearch}) => {
    const { formatMessage } = useIntl()
    const f = id => formatMessage({ id: id })
    const lastContacts = useSelector(state => state.recentContacts.list.data)
    const [lastContactsFiltered, setLastContactsFiltered] = useState(null);
    const discussionId = useSelector( state => idx(state, _=> _.discussions.discussion.id))
    const dispatch = useDispatch()
    let lastLetter = null;
    const [searchInput, setSearchInput] = useState("");
    const contacts = useSelector(state => state.contacts.list.data)
    const [contactsFiltered, setContactsFiltered] = useState(null);
    const chatMembers = useSelector(state => idx(state, _ => _.chatMembers.list.data))
    const fetchRecentContactsList = () => {
        dispatch(fetchRecentContacts({limit: 3, all: 1}));
    }
    const fetchContactsList = () => {
        dispatch(fetchContacts());
    }

    useEffect(() => {
        fetchRecentContactsList();
        fetchContactsList();
    }, [])

    useEffect(() => {
        //If the componenent is called from a search dialog it means the group already exists
        //and we need to remove the contact which are already in the group from the list
        if (isSearch && lastContacts) {
            let newList = lastContacts.filter((lastContact) => {
                const notAMember = chatMembers.every(member => member.attributes.doctor.doctor_id !== lastContact.attributes.doctor_id)
                if (notAMember) {
                    return lastContact;
                }
            })
            setLastContactsFiltered(newList)
        } else {
            setLastContactsFiltered(lastContacts)
        }
    }, [lastContacts])

    useEffect(() => {
        //If the componenent is called from a search dialog it means the group already exists
        //and we need to remove the contact which are already in the group from the list
        if (isSearch && contacts) {
            let newList = contacts.filter((contact) => {
                const notAMember = chatMembers.every(member => member.attributes.doctor.doctor_id !== contact.attributes.doctor_id)
                if (notAMember) {
                    return contact;
                }
            })
            setContactsFiltered(newList);
        } else {
            setContactsFiltered(contacts);
        }
    },[contacts])

    const handleChange = (e) => {
        e.preventDefault();
        setSearchInput(e.target.value);
    }

    const searchList = () => {
        let searchResult = [];

        contactsFiltered.filter((data) => {
            if (data.attributes.full_name.toLowerCase().match(searchInput.toLowerCase())) {
                searchResult.push(data);
            }
        })
        return searchResult;
    }

    const handleClose = () => {
        setMembersAdded([]);
        onHide()
    }

    const changePage = () => {
        setDialogPage('addName');
    }

    const addMembers = () => {
        dispatch(addChatMembers(discussionId, membersAdded));
        handleClose();
    }

    return (
        <DialogStyled
            open={showModal}
            onClose={handleClose}
            maxWidth='lg'
            fullWidth
            PaperProps={{
                style: {
                    width: '650px',
                    height: '65%',
                    border: `1px solid ${Colors.bluePurple}`,
                    padding: '10px',
                }
            }}
            BackdropProps={{
                style: {
                    opacity: 0,
                }
            }}
        >
            <>
                <DialogTitle disableTypography>
                    <Header>
                        <IconButtonStyled onClick={handleClose}>
                            <CloseIcon/>
                        </IconButtonStyled>
                        <GroupIconStyled/>
                        <TitleStyled><FormattedMessage id='new.group.title'/></TitleStyled>
                    </Header>
                    <Subtitle>
                        <FormattedMessage id='new.group.add.members'/>
                    </Subtitle>
                    <WrapperSearch>
                        <Input
                            placeholder={f('staff.contacts.add')}
                            type='text'
                            onChange={handleChange}
                            value={searchInput}
                        />
                    </WrapperSearch>
                    <WrapperSelectedItem>
                        { membersAdded.map((member) => {
                            const attributes = member.data.attributes
                            const photo = attributes.photo
                            const fullname = attributes.full_name
                            const userId = attributes.user_id
                            return (
                                <SelectedItem key={attributes.id}>
                                    <Wrapper>
                                        <SelectedAvatar photo={photo}/>
                                        <SelectedFullName>{fullname}</SelectedFullName>
                                    </Wrapper>
                                    <Close onClick={() => { setMembersAdded(membersAdded.filter(m => m.data.attributes.user_id !== userId)) }}>
                                        <CloseIcon fontSize="small"/>
                                    </Close>
                                </SelectedItem>
                            )
                        })
                        }
                    </WrapperSelectedItem>
                </DialogTitle>
                <DialogContent style={{marginBottom: '5px'}}>
                    {searchInput.length <= 0 && (
                        <>
                        <Subtitle>
                            <FormattedMessage id='new.group.recent.contacts'/>
                        </Subtitle>
                        <List sx={{width: '100%'}}>
                            {lastContactsFiltered && lastContactsFiltered.map((data) => {
                                const attributes = data.attributes
                                const photo = attributes.photo
                                const fullname = attributes.full_name
                                const specialization = attributes.specialization
                                const userId = attributes.user_id
                                return (
                                    <>
                                        <Divider variant="fullWidth" component="li" key={`div_${attributes.id}`} />
                                        <ListItemStyled key={attributes.id} onClick={() => {
                                            if (!membersAdded.some(m => {return m.data.attributes.user_id === userId})){
                                                setMembersAdded([
                                                    ...membersAdded,
                                                    {data}
                                                ])
                                            }
                                        }}>
                                            <Avatar photo={photo}/>
                                            <FullName>
                                                {fullname && fullname + " - "}
                                            </FullName>
                                            <Specialization>
                                                {specialization && specialization}
                                            </Specialization>
                                        </ListItemStyled>
                                    </>
                                )
                            })
                            }
                            <Divider variant="fullWidth" component="li" />
                        </List>
                        </>
                    )}
                    <List sx={{width: '100%'}}>
                        {contactsFiltered && searchList().map((data) => {
                            const attributes = data.attributes
                            const photo = attributes.photo
                            const fullname = attributes.full_name
                            const specialization = attributes.specialization
                            const userId = attributes.user_id
                            if ((data.attributes.last_name).substring(0, 1).toUpperCase() === lastLetter) {
                                return (
                                    <>
                                        <Divider variant="fullWidth" component="li" key={`div_${attributes.id}`} />
                                        <ListItemStyled key={attributes.id} onClick={() => {
                                            if (!membersAdded.some(m => {return m.data.attributes.user_id === userId})){
                                                setMembersAdded([
                                                    ...membersAdded,
                                                    {data}
                                                ])
                                            }
                                        }}>
                                            <Avatar photo={photo}/>
                                            <FullName>
                                                {fullname && fullname + " - "}
                                            </FullName>
                                            <Specialization>
                                                {specialization && specialization}
                                            </Specialization>
                                        </ListItemStyled>
                                    </>
                                )
                            } else {
                                lastLetter = (data.attributes.last_name).substring(0, 1).toUpperCase()
                                return (
                                    <>
                                        <Letter key={`let_${attributes.id}`}>{lastLetter}</Letter>
                                        <Divider key={`div_${attributes.id}`} variant="fullWidth" component="li" />
                                        <ListItemStyled key={attributes.id} onClick={() => {
                                            if (!membersAdded.some(m => {return m.data.attributes.user_id === userId})){
                                                setMembersAdded([
                                                    ...membersAdded,
                                                    {data}
                                                ])
                                            }
                                        }}>
                                            <Avatar photo={photo}/>
                                            <FullName>
                                                {fullname && fullname + " - "}
                                            </FullName>
                                            <Specialization>
                                                {specialization && specialization}
                                            </Specialization>
                                        </ListItemStyled>
                                    </>
                                )
                            }
                        })
                        }
                        <Divider variant="fullWidth" component="li" />
                    </List>
                </DialogContent>
                { membersAdded.length > 0 && !isSearch ? (
                    <NextButton onClick={async () => {
                        await changePage();
                    }}>
                        <FormattedMessage id='new.group.next.button'/>
                        <Arrow/>
                    </NextButton>
                ) : membersAdded.length > 0 && isSearch ? (
                    <AddButton onClick={() => addMembers()}>
                        <FormattedMessage id='concilium.add'/>
                    </AddButton>
                ) : ''
                }
            </>
        </DialogStyled>
    )
}
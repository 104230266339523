import React from 'react'
import idx from 'idx'
import { compose, withProps, lifecycle } from 'recompose'
import { connect } from 'react-redux'
import { EditPage } from 'common/UiKit/EditPage'
import { editProfile, createWorkPlace, updateWorkPlace } from '../../../../../redux/modules/auth'
import { fetchSpecializations } from '../../../../../redux/modules/content'
import { history } from '../../../../../utils/history'
import { PROFILE_FILL_STEPS } from '../../../../../constants/ui'
import { externalFormSubmit } from '../../../../../helpers/externalFormSubmit'
import { AboutDoctorForm } from '../FillForm/AboutDoctorForm'
import { EmploymentTypeField } from '../EmploymentTypeField';

const EditAboutDoctorComponent = ({
  initialValues,
  onSubmit,
  loading,
  specializations,
  employmentType,
}) => (
  <EditPage
    label='profile.about'
    onBackIconClick={() => history.push('/panel/profile')}
    buttonText='global.save'
    onButtonClick={() => externalFormSubmit(PROFILE_FILL_STEPS[1])}
    loading={loading}
  >
    <AboutDoctorForm
      initialValues={initialValues}
      onSubmit={onSubmit}
      id={PROFILE_FILL_STEPS[1]}
      afterSubmit={() => history.push('/panel/profile')}
      specializations={specializations}
      disableShadow
      methodField={<EmploymentTypeField />}
      employmentType={employmentType}
    />
  </EditPage>
)

const enhance = compose(
  connect(
    ({ auth, loading, content }) => ({
      workplace: auth.relationships.workPlace.data.length
        && [...auth.relationships.workPlace.data].pop(),
      initialValues: {
        addition_email: auth.attributes.addition_email || '',
        work_place: auth.relationships.workPlace.data.length
          && [...auth.relationships.workPlace.data].pop().attributes,
        credentials_rpps: idx(auth, _ => _.attributes.credentials.rpps) || '',
        credentials_adeli: idx(auth, _ => _.attributes.credentials.adeli) || '',
        credentials_order_number: idx(auth, _ => _.attributes.credentials.orderNumber) || '',
        territorialStructureType: auth.attributes.territorialStructureType,
        territorialStructureValue: auth.attributes.territorialStructureValue,
        typeOfEstablishmentType: auth.attributes.typeOfEstablishmentType,
        typeOfEstablishmentValue: auth.attributes.typeOfEstablishmentValue,
      },
      specializations: idx(content, _ => _.specializations)
        .map(({ attributes: a, id }) => ({ value: id, label: a.name })),
      loading: loading.editProfile || loading.workPlace,
      employmentType: auth.attributes.type_employment,
    }),
    { editProfile, createWorkPlace, updateWorkPlace, fetchSpecializations },
  ),
  withProps(props => ({
    onSubmit: values => {
      let action;
      if (props.workplace && props.workplace.id) {
        action = props.updateWorkPlace(props.workplace.id, values.work_place)
      } else {
        action = props.createWorkPlace({ workPlace: [values.work_place] })
      }
      action.then(() => props.editProfile(values, true))
    },
  })),
  lifecycle({
    componentDidMount() {
      this.props.fetchSpecializations()
    },
  }),
)

export const EditAboutDoctor = enhance(EditAboutDoctorComponent)

import React from 'react'
import styled from 'styled-components'
import { Colors } from 'constants/colors'
import { FormattedMessage } from 'react-intl'
import logoTC from '../../../../../../images/panel/doctors/tc-empty-screen.svg'

const Wrapper = styled.div`
  width: 380px;
  height: calc(100vh - 48px - 56px - 24px * 2 - 80px);
  margin: auto;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  font-family: Roboto, sans-serif;
`

const Logo = styled.img`
  margin-bottom: 5px;
  width: 350px;
`

const Heading = styled.h1`
  font-size: 18px;
  font-weight: normal;
  line-height: 1.33;
  color: ${Colors.dark};
  text-align: center;
`

export const EmptyScreen = () => (
  <Wrapper>
    <Logo src={logoTC} />
    <Heading>
      <FormattedMessage id='tc.empty' />
    </Heading>
  </Wrapper>
)

import React, {useEffect, useState} from "react";
import emptyViewChat from "/src/images/panel/chat/empty_view_chat.svg"
import {EmptyScreen} from "../../Consultations/List/EmptyScreen";
import styled from "styled-components";
import {FormattedMessage, useIntl} from "react-intl";
import {SearchMultipleSelect} from "../../../../common/AsyncSelect/SearchMultiple";
import Grid from "@mui/material/Grid";
import {useDispatch, useSelector} from "react-redux";
import {fetchRecentContacts} from "../../../../../redux/modules/recentContact";
import {history} from "../../../../../utils/history";
import {createIndividualDiscussion, fetchDiscussions} from "../../../../../redux/modules/discussions";
import {Alert} from "@mui/lab";
import Snackbar from "@mui/material/Snackbar";
import {Colors} from "../../../../../constants/colors";

export const NewDiscussion = () => {
  const dispatch = useDispatch()
  const { formatMessage } = useIntl()
  const f = id => formatMessage({ id })

  const lastContacts = useSelector(state => state.recentContacts.list.data)

  const [stateAlertBox, setStateAlertBox] = useState({
    open: false,
    vertical: 'center',
    horizontal: 'center'
  });
  const [severity, setSeverity] = useState("info");
  const [message, setMessage] = useState("");

  const handleResponse = (newState) => {
    setStateAlertBox({open: true, ...newState})
  }

  const handleClose = () => {
    setStateAlertBox({ open: false, vertical: 'center',
      horizontal: 'center'})
  }

  const fetchRecentContactsList = () => {
    dispatch(fetchRecentContacts({limit: 3, all: 1}))
  }
  
  useEffect(() => {
    fetchRecentContactsList();
  },[]);
  
  const selectContact = (id) => {
    dispatch(createIndividualDiscussion(id.value))
      .then(res => {
        dispatch(fetchDiscussions({include: 'chat,team'}))
          .then(() => {
            history.push('/panel/discussions/'+res.payload.id)
          })
      })
      .catch((err) => {
        setSeverity("error")
        setMessage(f('discussion.create.error.individual'));
        handleResponse({vertical: 'top', horizontal: 'right'})
        if(err.response.data.data.values.id_discussion) {
          history.push('/panel/discussions/' + err.response.data.data.values.id_discussion)
        }
      })
  }
  
  return (
    <>
      <SearchBar container spacing={2}>
          <SearchPrefix item xs={1}>
            <FormattedMessage id='disc.new.to' />
          </SearchPrefix>
        <Grid item xs={11}>
          <SearchMultipleSelect
            onChange={selectContact}
            createDefaultField
            cacheOptions={false}
            autoFocus
            placeholder='disc.new.to.input.name'
            noOptionsTxt={lastContacts}
            name='searchDoctor'
            valuePath='id'
            noMarginError
            labelPath={[
              'firstname',
              'name',
              'job',
              'identifier',
              'town',
              'zip',
            ]}
            requestUrl='/discussion/contacts?search='
          />
        </Grid>
      </SearchBar>
      <EmptyScreen logo={emptyViewChat} title={'disc.new.chat.emptyview.title'} subtitle={'disc.new.chat.emptyview.subtitle'}/>
      <Snackbar
        anchorOrigin={{
          vertical: stateAlertBox['vertical'],
          horizontal: stateAlertBox['horizontal']
        }}
        key={`${stateAlertBox['vertical']},${stateAlertBox['horizontal']}`}
        open={stateAlertBox['open']}
        autoHideDuration={6000}
        onClose={handleClose}
      >
        <Alert onClose={handleClose} severity={severity}>
          {message}
        </Alert>
      </Snackbar>
    </>
  )
}

const SearchBar = styled(Grid)`
  z-index: 1;
  background-color: white;
  border-bottom: 1px solid ${Colors.borderGrey};
`

const SearchPrefix = styled(Grid)`
  font-size: 20px;
  text-align: right;
  padding-top: 37px !important;
  max-width: 60px;
`
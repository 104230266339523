import React, { useEffect, useMemo, useState } from 'react'
import { useSelector } from 'react-redux'
import styled from 'styled-components'
import moment from 'moment-timezone'

import { generateTimePeriods, getDaysArray } from '../../../../../helpers/calendar'
import { TimeColumn } from './TimeColumn'
import { DayColumn } from './DayColumn'

export const WeekCalendar = React.memo(({
                                          confirm,
                                          editing,
                                          onFreeClick,
                                          selected,
                                          locale = moment.locale(),
                                          setEditMenuData,
                                          concilium,
                                          cellType,
                                          available,
                                        }) => {
  const periods = useMemo(() => generateTimePeriods(), [])
  const {
    from: fromDate,
    to: toDate,
  } = useSelector(state => state.calendar.filters.values.date)
  const range = useMemo(() => getDaysArray(fromDate, toDate), [fromDate, toDate, locale])
  const data = useSelector(state => state.calendar.list.week)
  const [scrolledOnce, toggleScrolledState] = useState(false)

  useEffect(() => {
    //const el = document.getElementById('current_cell')
    const el = document.getElementById('nearest_available');

    if (el && !scrolledOnce) {
      el.scrollIntoView({ behavior: 'smooth', block: 'start' })
      toggleScrolledState(true)
    }
  }, [data])

  return (
      <Wrapper>
        <TimeColumn periods={periods} />
        {
          range.map((item) => {
            const list = data[item.format('YYYY-MM-DD')]
            const isDayOff = list ? Object.keys(list).every(key => list[key].type === 'Holiday') : false

            return (
                <DayColumn
                    day={item}
                    periods={periods}
                    key={item.toString()}
                    isDayOff={isDayOff}
                    hoursTotal={0}
                    confirm={confirm}
                    data={list}
                    editing={editing}
                    onFreeClick={onFreeClick}
                    selected={selected}
                    setEditMenuData={setEditMenuData}
                    concilium={concilium}
                    cellType={cellType}
                    available={available}
                />
            )
          })
        }
      </Wrapper>
  )
})

const Wrapper = styled.div`
  display: flex;
`

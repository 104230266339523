export function nonBreakingSpaces(str) {
  if (!str) return str;
  // else
  return str.replaceAll(/ +/g, '\u00A0')
}

export function nlToBr(str, safeHtml = true) {
  if (!str) return str;
  // else
  if (safeHtml) {
    str = str.replaceAll(/[\u00A0-\u9999<>\\&]/gim, function(i) {
      return '&#' + i.charCodeAt(0) + ';';
    });
  }
  return str.replaceAll('\n', '<br/>')
}
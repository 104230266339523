import React, {useEffect, useState} from 'react'
import PropTypes from 'prop-types'
import axios from 'axios'
import { Switch, Route, Redirect } from 'react-router-dom'
import { ThemeProvider, StyledEngineProvider } from '@mui/material/styles';
import { DateProvider } from 'common/UiKit/DatePicker'
import { IntlProvider } from 'react-intl'
import {
  connect,
  useDispatch,
  useSelector,
} from 'react-redux'
import moment from 'moment-timezone'
import idx from 'idx';
import { changeLocale } from './utils/changeLocale'
import { getQueryString } from './helpers/querystring'
import { Auth } from './components/Auth'
import { Panel } from './components/Panel'
import { theme } from './utils/muiTheme'
import { GlobalStyle } from './constants/globalStyles'
import { GlobalNotification } from './components/common/Notification/GlobalNotifications';
import { Notification } from './components/common/Notification'
import { ChatNotifications } from './components/common/Notification/chat'
import { SocketErrorNotification } from './components/common/Notification/socketError'
import { HEALTH_RECORDS_TEXTS, SuccessScreen } from './components/SuccessScreen'

import { en } from './locales/en'
import { ru } from './locales/ru'
import { de } from './locales/de'
import { fr } from './locales/fr'
import { ErrorBoundary } from './components/common/ErrorBoundary';
import { ErrorPage } from './components/ErrorPage';
import { IGNORED_ERRORS, checkIgnoreMatchError } from './constants/errors';
import { setNotification } from './redux/modules/notifications';
import 'date-input-polyfill-react';
import {directOAuth, forceLogout, oauth2Psc, pscInscription} from './redux/modules/auth';
import { Logout } from './components/Auth/Logout';
import Public from './components/Public'
import get from 'lodash/get';
import { NotActivatedDialog } from './components/Auth/Login/NotActivatedDialog';
import {decodeToken} from "react-jwt";
import {Campaign} from "./components/Public/unsubscribe/Campaign";

const messages = {
  en,
  ru,
  de,
  fr,
}

if (window?.navigator?.userAgent) {
  const userAgent = window.navigator.userAgent.toUpperCase()
  const noRedirectPaths = [
    '/auth/password-recovery',
    '/auth/password-recovery/check',
    '/auth/change-password/pre-registrated',
    '/auth/doctor/registration-mode',
    '/auth/doctor/registration-mode/classic-sign-in',
    '/auth/patient/registration-mode/classic-sign-in',
    '/auth/phone',
    '/panel/profile',
    '/panel/profile/fill',
    '/panel/profile/edit',
  ];
  if (
    (userAgent.includes('ANDROID') || userAgent.includes('IPHONE'))
    && !noRedirectPaths.includes(window.location.pathname)
  ) {
    window.location.href = window.env.API_URL + '/redirectstore'
  }
}

// Vérification du token toutes les 15 secondes
setInterval(() => {
  let token = localStorage.getItem('authToken')
  if (token && axios.defaults.headers.common.Authorization) {
    const tokenInfo = decodeToken(token)
    const remainingSeconds = tokenInfo.exp - new Date().getTime() / 1000;
    const refreshLimit = (tokenInfo.exp - tokenInfo.iat) / 2
    if (remainingSeconds < refreshLimit) {
      axios.get(`/api/refresh`)
        .then((res) => {
          if (res.data && res.data.token) {
            localStorage.setItem('authToken', res.data.token);
            axios.defaults.headers.common.Authorization = res.data.token
          }
        })
        .catch(e => {
          if (e.response && (e.response.status < 200 || e.response.status >= 400)) {
            forceLogout()
          } else {
            console.error('Failed to refresh token: ' + e)
          }
        })
    }
  }
}, 10000)

const AppComponent = ({ locale }) => {
  const dispatch = useDispatch()
  const [dialogOpen, setDialogOpen] = useState(false)
  const profile = useSelector(state => state.auth.attributes);
  if (window.location.pathname === '/oauth/psc/check') {
    dispatch(oauth2Psc(window.env.API_URL + '/oauthpsc/check' + '?' + getQueryString()))
      .then(res => {
        if (res && res.type === 'OAUTH2_PSC_FAILURE') {
          if (get(res, 'payload.response.data.status', 0) === 403 && res.payload.response.data.family_name) {
            dispatch(pscInscription(res.payload.response.data))
          } else if (get(res, 'payload.response.status', 0) === 401) {
            setDialogOpen(true)
          }
        }
      })
      .catch(err => {
        console.log("NOT ACTIVATED ???????")
        console.log(err)
      })
  } else if (window.location.pathname === '/oauth') {
    dispatch(directOAuth('psc', getQueryVariable('token')))
  }

  useEffect(() => {
    if (profile && profile.time_zone) {
      moment.tz.setDefault(profile.time_zone);
    }
  }, [profile && profile.time_zone])

  useEffect(() => {
    if (typeof document !== 'undefined' && document.documentElement && document.documentElement.lang !== locale) {
      document.documentElement.lang = locale;
    }

    moment.locale(locale)
    changeLocale(locale)
    axios.interceptors.response.use(response => response, (error) => {
      const message = idx(error, _ => _.response.data.data.trace[0].message) || 'Something went wrong, please try again later'
      const code = idx(error, _ => _.response.data.data.trace[0].code)
      if (message 
        && !error.isAxiosError
        && !checkIgnoreMatchError(message)
        && !IGNORED_ERRORS.includes(message) 
        && code !== 403) {
        dispatch(setNotification(message)
        && error != "Cancel: canceled")
      }
      return Promise.reject(error)
    })
  }, [])
  return (
    <StyledEngineProvider injectFirst>
      <ThemeProvider theme={theme}>
        <IntlProvider locale={locale} messages={messages[locale]}>
          <ErrorBoundary>
            <DateProvider>
              <GlobalStyle />
              <Notification />
              <ChatNotifications />
              <SocketErrorNotification />
              <GlobalNotification />
              <Switch>
                <Route path='/drugs/feedback' component={SuccessScreen} />
                <Route path='/error' component={ErrorPage} />
                <Route path='/access/allow' component={props => <SuccessScreen texts={HEALTH_RECORDS_TEXTS} {...props} />} />
                <Route path='/auth' component={Auth} />
                <Route path='/panel' component={Panel} />
                <Route path='/logout' component={Logout} />
                <Route path='/public/structure' component={Public} />
                <Route path='/public/unsubscribe/campaign' component={Campaign} />
                <Redirect from='/' to='/auth' />
              </Switch>
            </DateProvider>
            <NotActivatedDialog dialogOpen={dialogOpen} onCloseAction={() => setDialogOpen(false)} />
          </ErrorBoundary>
        </IntlProvider>
      </ThemeProvider>
    </StyledEngineProvider>
  );
}

AppComponent.propTypes = {
  locale: PropTypes.string.isRequired,
}

export const App = connect(
  state => ({ locale: state.localization }),
  null,
  null,
  { pure: false },
)(AppComponent)

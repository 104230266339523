import React, {useEffect, useState} from 'react'
import PropTypes from 'prop-types'
import {connect, useDispatch, useSelector} from 'react-redux';
import { EditPage } from 'common/UiKit/EditPage'
import { editProfile } from '../../../../../redux/modules/auth'
import { history } from '../../../../../utils/history'
import { PROFILE_FILL_STEPS } from '../../../../../constants/ui'
import { externalFormSubmit } from '../../../../../helpers/externalFormSubmit'
import { PersonalDataForm } from '../FillForm/PersonalDataForm'
import {FormattedMessage} from "react-intl";
import Switch from "@mui/material/Switch";
import styled from "styled-components";
import Paper from "@mui/material/Paper";
import { fetchDoctorRapportDetailleSuccess} from "../../../../../redux/modules/consultations";
import {
  activateOlaqinSetting,
  activateOlaqinSuccess, fetchSettings, updateExternalPartners, updateSettings,
  updateSettingsSuccess
} from "../../../../../redux/modules/settings";
import {CircularProgress} from "@mui/material";
import moment from "moment-timezone";
import idx from "idx";


const Wrapper = styled(Paper)`
  margin-top: 24px;
  margin-bottom: 16px;
  border-radius: 4px;
  box-shadow: 0;
  background-color: #ffffff;
  border-bottom: 0;
  padding: 0px;
`

const MiniTitle = styled.div`
  font-size: 14px;
  font-weight: 500;
  color: rgba(33, 41, 62, 0.5);
  padding-bottom: 8px;
`

const PaperStyled = styled(Paper)`
  margin-bottom: 16px;
`;

const Line = styled.label`
  font-size: 16px;
  height: 48px;
  & > span {
    opacity: 0.8;
    font-weight: 600;
  }
`;

const Title = styled.div` 
  font-size: 16px;
  font-weight: 500;
  line-height: 1.13;
  color: #757f99;
  border-bottom: 1px solid rgba(6, 173, 255, 0.1);
  padding-bottom: 18px;
  padding-top: 21px;
  padding-left: 24px;
`
const LoaderWrapper = styled.div`
  height: calc(100vh - 56px);
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
`

const DivStyled = styled.div`
  display:flex;
  justify-content:space-between;
  padding-left:45px;
  padding-top:16px;
`

const EditExternalPartnersComponent = ({
                                         loading
                                   }) => {
  const user_id = useSelector(state => (state.auth.attributes.user_id))
  const stateActivateOlaqin =   useSelector(state => idx(state, _ => _.settings.values.activate_olaqin))
  const [ activateOlaqin, setActivateOlaqin] = useState();

  useEffect(() => {
    setActivateOlaqin(parseInt(stateActivateOlaqin))
  }, stateActivateOlaqin)

  const dispatch = useDispatch();
  const onConfirm = () => {
    const value = {
      'activate_olaqin':Number(activateOlaqin)
    }
    dispatch(updateExternalPartners(value))
        .then(() => dispatch(fetchSettings()))
        .then(() => history.goBack())
        .catch((err) => {
          console.log(err)
        });
  }

  return (
      <EditPage
          label='profile.externalpartner'
          onBackIconClick={() => history.push('/panel/profile')}
          buttonText='global.save'
          onButtonClick={() => onConfirm()}
          loading={loading}
          background='grey'
      >
        {
          stateActivateOlaqin != undefined ? (
                <Wrapper>
                  <Title>
                    <FormattedMessage id='profile.externalpartner.olaqin' />
                  </Title>
                  <form>
                    <DivStyled>
                      <FormattedMessage id='profile.externalpartner.olaqin.checkbox' />
                      <Line>
                        <Switch
                            name='activate_olaqin'
                            color='success'
                            inputProps={{'aria-label': 'controlled'}}
                            checked={activateOlaqin}
                            onChange={event =>  setActivateOlaqin(event.target.checked)}
                        />
                      </Line>
                    </DivStyled>

                  </form>
                </Wrapper>
            ) :
              <LoaderWrapper>
                <CircularProgress size={80} />
              </LoaderWrapper>
        }

      </EditPage>
  )
}

const enhance = connect(
    state => ({
      loading: state.loading.editProfile,
    }),
    { editProfile },
)

export const EditExternalPartners = enhance(EditExternalPartnersComponent)

import React, {useEffect, useRef, useState} from 'react'
import {history} from "../../../../utils/history";
import {Page} from '../../../common/Page'
import get from 'lodash/get';
import {fetchDoctorFromAnnuaire} from "../../../../redux/modules/doctors";
import styled from "styled-components";
import {useDispatch, useSelector} from "react-redux";
import {fullName} from "../../../../helpers/user";
import {DoctorAnnuraireCard} from "../../../common/DoctorCard/DoctorAnnuaireCard";
import {useParams} from "react-router-dom";
import {useIntl} from "react-intl";
import {fetchInvitations, inviteProfessional} from "../../../../redux/modules/proInvite";
import {InviteProfessionalDialog} from "../Professional/InviteProfessionalDialog";

const Content = styled.div`
    margin-top: 20px;
    position: relative;
    overflow: initial;
`

export const DoctorAnnuaireDetails = () => {

  let {annuaireId} = useParams();
  const dispatch = useDispatch();
  const topAnkor = useRef();
  const doctorAnnuaire = useSelector(state => state.doctors.annuaireDoctor);
  const {formatMessage} = useIntl()
  const f = (id, params = {}) => formatMessage({id: id}, params)
  const user = useSelector(state => state.auth.attributes)
  const invited = useSelector(state => {
    if (!!state.doctors.annuaireDoctor && !!state.invitations) {
      for (const invitation of state.invitations) {
        if (invitation.relationships?.invited?.data?.identifiantPp === state.doctors.annuaireDoctor.id) {
          return true
        }
      }
    }
    return false
  })

  const onSendInvite = (formValues) => {
    dispatch(inviteProfessional(annuaireId, formValues))
      .then(() => {
        setInviteDialogOpen(false)
        dispatch(fetchInvitations())
      })
      .catch(err => {
        console.error(err)
      })
  }

  const [inviteDialogOpen, setInviteDialogOpen] = useState(false)

  const invitationMessage =
    f('invitation.message.1', {firstName: doctorAnnuaire?.first_name })
    + '\n\n'
    + f('invitation.message.2')
    + '\n\n'
    + f('invitation.message.3', {
      senderFirstName: user?.first_name || '',
      senderLastName: user?.last_name || ''
    })

  useEffect(() => {
    if (!!annuaireId && annuaireId !== doctorAnnuaire?.id) {
      dispatch(fetchDoctorFromAnnuaire(annuaireId))
      dispatch(fetchInvitations())
    }
  }, [annuaireId, doctorAnnuaire]);

  return (
    <Page
      label={fullName(get(doctorAnnuaire, 'gender'), get(doctorAnnuaire, 'first_name'), get(doctorAnnuaire, 'last_name'))}
      onBackIconClick={() => history.goBack()} noHeaderCapitalize={true}>
      <Content ref={topAnkor}>
        <InviteProfessionalDialog
          open={inviteDialogOpen}
          onClose={() => setInviteDialogOpen(false)}
          onSendInvite={onSendInvite}
          firstName={doctorAnnuaire?.first_name || ''}
          lastName={doctorAnnuaire?.last_name || ''}
          initialMessage={invitationMessage}
        />
        <DoctorAnnuraireCard
          doctorAnnuaire={doctorAnnuaire}
          firstName={doctorAnnuaire?.first_name}
          lastName={doctorAnnuaire?.last_name}
          specialization={doctorAnnuaire?.specialization}
          workplace={doctorAnnuaire?.workplace}
          onInvite={() => setInviteDialogOpen(true)}
          invited={invited}
        />
      </Content>
    </Page>
  )
}
import {handleActions, createActions, createAction} from 'redux-actions'
import axios from 'axios'
import idx from 'idx'
import moment from 'moment-timezone'
import {colorsByElement} from "../../../../helpers/Calendar/correctColor";
import React from "react";
import {getCorrectText} from "../../../../helpers/Calendar/correctText";


//#region Actions
export const CALENDAR_LIST_ACTIONS = {
  fetchCalendarDataRequest: 'FETCH_CALENDAR_DATA_REQUEST',
  fetchCalendarDataSuccess: 'FETCH_CALENDAR_DATA_SUCCESS',
  fetchCalendarDataFailure: 'FETCH_CALENDAR_DATA_FAILURE',

  editEventRequest: 'EDIT_EVENT_REQUEST',
  editEventSuccess: 'EDIT_EVENT_SUCCESS',
  editEventFailure: 'EDIT_EVENT_FAILURE',

  editEventRepeatRequest: 'EDIT_EVENT_REPEAT_REQUEST',
  editEventRepeatSuccess: 'EDIT_EVENT_REPEAT_SUCCESS',
  editEventRepeatFailure: 'EDIT_EVENT_REPEAT_FAILURE',

  addEventRepeatRequest: 'ADD_EVENT_REPEAT_REQUEST',
  addEventRepeatSuccess: 'ADD_EVENT_REPEAT_SUCCESS',
  addEventRepeatFailure: 'ADD_EVENT_REPEAT_FAILURE',
}

export const {
  fetchCalendarDataRequest,
  fetchCalendarDataSuccess,
  fetchCalendarDataFailure,

  editEventRequest,
  editEventSuccess,
  editEventFailure,

  editEventRepeatRequest,
  editEventRepeatSuccess,
  editEventRepeatFailure,

  addEventRepeatRequest,
  addEventRepeatSuccess,
  addEventRepeatFailure
} = createActions(...(Object.keys(CALENDAR_LIST_ACTIONS)
    .map(item => CALENDAR_LIST_ACTIONS[item])))
//#endregion

//#region Reducer
const initialValues = {
  data: {}
}

export const calendarListReducer = handleActions({
  [fetchCalendarDataSuccess]: (state, action) => ({...state, data: action.payload}),
  [editEventRequest]: (state, {payload}) => (payload.time ? ({
    ...state,
    [payload.calendarType]: {
      ...state[payload.calendarType],
      [payload.day]: {
        ...state[payload.calendarType][payload.day],
        [payload.time]: {
          ...state[payload.calendarType][payload.day][payload.time],
          type: payload.value,
          doctor_id: payload.value
        },
      },
    },
  }) : state),
}, initialValues)
//#endregion

//#region Thunks


export const fetchCalendarData = (dateFrom, dateTo, doctorId) => (dispatch, getStore) => {
  dispatch(fetchCalendarDataRequest())

  return axios.get(`/api/doctor/calendar/event?dateFrom=${dateFrom}&dateTo=${dateTo}&doctor_id=${doctorId}`)
      .then((data) => {
        colorsByElement(data.data.data.attributes.data)
        getCorrectText(data.data.data.attributes.data)
        return dispatch(fetchCalendarDataSuccess(data.data.data.attributes))
      })
      .catch(e => dispatch(fetchCalendarDataFailure(e)))
}


export const editEvent = (day, time, value, oldValue, av = {}, doctorId) => (dispatch, getStore) => {
  const {calendarType} = getStore().calendar.filters.values

  dispatch(editEventRequest({
    day,
    time,
    value,
    calendarType,
    te_available: av.te_available || 0,
    tc_available: av.tc_available || 0,
    tci_available: av.tci_available || 0,
    tca_available: av.tca_available || 0,
  }))
  const data = new FormData()
  data.append('te_available', av.te_available || 0);
  data.append('tc_available', av.tc_available || 0);
  data.append('tci_available', av.tci_available || 0);
  data.append('tca_available', av.tca_available || 0);
  data.append('doctor_id', doctorId);
  if (Array.isArray(day)) {
    // day.forEach(i => data.append('date[]', i)
    day.forEach(function (i) {
      const convertDateToUTC = moment(i, null).tz(Intl.DateTimeFormat().resolvedOptions().timeZone).utc().format('YYYY-MM-DD HH:mm:ss')
      data.append('date[]', convertDateToUTC)
    })
  } else {
    data.append('date[0]', `${day} ${time}`)
  }

  if (Array.isArray(value)) {
    value.forEach(i => data.append('type[]', i))
  } else if (value !== 'Empty') {
    data.append('type', value)
  } else if (oldValue) {
    data.append('type', oldValue)
  }

  return axios(`/api/doctor/calendar/event${value === 'Empty' ? '/delete' : ''}`, {
    method: 'POST',
    data,
  })
      .then((response) => {
        dispatch(editEventSuccess(response.data))
        if (value === 'Free' && oldValue === 'Holiday') {
          data.append('type', value)
          axios('/api/doctor/calendar/event', {
            method: 'POST',
            data,
          })
        }
      })
      .catch(err => dispatch(editEventFailure(err)))
}

export const deleteEvent = (day, doctorId) => (dispatch, getStore) => {

  dispatch(editEventRequest({}))
  const data = new FormData()
  data.append('doctor_id', doctorId);
  if (Array.isArray(day)) {
    day.forEach(function (i) {
      const convertDateToUTC = moment(i, null).tz(Intl.DateTimeFormat().resolvedOptions().timeZone).utc().format('YYYY-MM-DD HH:mm:ss')
      data.append('date[]', convertDateToUTC)
    })
  }
  return axios(`/api/doctor/calendar/event/delete`, {
    method: 'POST',
    data,
  })
      .then((response) => {
        dispatch(editEventSuccess(response.data))
      })
      .catch(err => dispatch(editEventFailure(err)))
}


export const deleteRepeat = (repeatId, purgeCalendar, doctor_id) => axios.delete(`/api/doctor/calendar/repeat`,
    {data: {doctor_id:doctor_id,
      repeat_id:repeatId,
      purge_calendar : purgeCalendar}})
    .then(() => {
    })
    .catch(err => console.warn(err))


export const getRepeats = (doctorId) => axios(`/api/doctor/calendar/repeat?doctor_id=${doctorId}`)
    .then(result => result.data.data)

export const addRepeat = ({type,from, to, dateRepeatTo, dateRepeatFrom, days, te_available, tc_available, tci_available, tca_available,doctorId}) => (dispatch) => {
  dispatch(addEventRepeatRequest())

  return axios('/api/doctor/calendar/repeat', {
        method: 'POST',
        data: {
          type: type,
          from: from,
          to: to,
          date_repeat_to: dateRepeatTo,
          date_repeat_from: dateRepeatFrom,
          days: days,
          te_available: te_available,
          tc_available: tc_available,
          tci_available: tci_available,
          tca_available: tca_available,
          doctor_id: doctorId
        },
      })
        .then((response) => {
          dispatch(addEventRepeatSuccess(response.data))
        })
      .catch(err => dispatch(addEventRepeatFailure(err)))
}

export const editRepeat = ({type,from = moment(from, null).tz(Intl.DateTimeFormat().resolvedOptions().timeZone).utc().format('YYYY-MM-DD HH:mm:ss'),to = moment(to, null).tz(Intl.DateTimeFormat().resolvedOptions().timeZone).utc().format('YYYY-MM-DD HH:mm:ss'),
                             dateRepeatTo,dateRepeatFrom,days, te_available, tc_available, tci_available, tca_available,repeatId, doctor_id
                           }) => (dispatch) => {
  dispatch(editEventRepeatRequest())
  return axios(`/api/doctor/calendar/repeat/update`, {
    method: 'POST',
    data: {
      type: type,
      from: from,
      to: to,
      date_repeat_to: dateRepeatTo,
      date_repeat_from: dateRepeatFrom,
      days: days,
      te_available: te_available,
      tc_available: tc_available,
      tci_available: tci_available,
      tca_available: tca_available,
      doctor_id: doctor_id,
      repeat_id: repeatId
    },
  })
      .then((response) => {
        dispatch(editEventRepeatSuccess(response.data))
      })
      .catch(err => dispatch(editEventRepeatFailure(err)))
}
//#endregion

import React, {useEffect, useState} from 'react';
import {CircularProgress, Drawer} from "@mui/material";
import styled from "styled-components";
import axios from "axios";
import idx from "idx";

const drawerWidth = 600;

const LoaderWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  top: 50%;
  right: 50%;
`

const Doc2UIframe = styled.iframe`
  height: 100%;
`

export default function ({
  open,
  consultationId,
  isDoctor
}) {
  const [isLoading, setIsLoading] = useState(true);
  const [roomToken, setRoomToken] = useState();
  const [iframeMode, setIframeMode] = useState();

  useEffect(() => {
    if (open) {
      axios.put('/doctor/consultation/'+consultationId+'/doc2u/start')
        .then(res => {
          setRoomToken(idx(res, _ => _.data.data.attributes.roomTokenDoctor));
          setIframeMode(isDoctor ? 'remote' : 'local');
          setIsLoading(false)
        })
    }
  }, [open]);

  return (
      <Drawer
        sx={{
          width: drawerWidth,
          flexShrink: 0,
          '& .MuiDrawer-paper': {
            width: drawerWidth,
          },
        }}
        variant="persistent"
        anchor="right"
        open={open}
      >
        <LoaderWrapper
          style={{transition: 'opacity 0.5s ease 0.5s', opacity: isLoading ? 1 : 0}}
        >
          <CircularProgress size={80} />
        </LoaderWrapper>
        {!isLoading &&
          <Doc2UIframe
            src={"https://app.doc2u.fr/room?t="+roomToken+'&practitionerMode='+iframeMode}
            frameBorder="0">
          </Doc2UIframe>
        }
      </Drawer>
  );
}
import React from 'react'
import PropTypes from 'prop-types'
import { InlineDatePicker } from 'common/UiKit/DatePicker'
import styled from 'styled-components'

import { DateFilterValue } from './Value'
import { getBackDates, getForwardDates, getToDate } from '../../../../../../helpers/calendar'

export const DateFilter = ({ value, onChange, calendarType }) => {
  const onSelect = (value) => {
    const fromDate = value.format('YYYY-MM-DD')
    const to = getToDate(calendarType, value)

    onChange({ from: fromDate, to })
  }
  return (
      <Wrapper>
        <InlineDatePicker
            onlyCalendar
            variant='outlined'
            TextFieldComponent={props => (
                <DateFilterValue
                    {...props}
                    to={value.to}
                    onBackClick={() => onChange(getBackDates(calendarType, value.from))}
                    onForwardClick={() => onChange(getForwardDates(calendarType, value.to))}
                />
            )}
            format='DD.MM'
            value={value.from}
            onChange={onSelect}
        />
      </Wrapper>
  )
}

DateFilter.propTypes = {
  value: PropTypes.object.isRequired,
  onChange: PropTypes.func.isRequired,
  calendarType: PropTypes.oneOf(['day', 'week', 'month']).isRequired,
}

const Wrapper = styled.div`
  margin-right: 12px;
  width: 150px;
`

import {useDispatch} from "react-redux";
import React, {useMemo, useState} from "react";
import {FormattedMessage, useIntl} from "react-intl";
import {deleteEvent, editEvent, fetchCalendarData} from "../../../redux/modules/calendar/list";
import dateFormat from "date-input-polyfill-react/dateformat";
import moment from "moment-timezone";
import FullCalendar from "@fullcalendar/react";
import dayGridPlugin from "@fullcalendar/daygrid";
import listPlugin from "@fullcalendar/list";
import timeGridPlugin from "@fullcalendar/timegrid";
import interactionPlugin from "@fullcalendar/interaction";
import {EventDialog} from "../../Panel/Doctor/Calendar/EventDialog";
import {DeleteDialog} from "../../Panel/Doctor/Calendar/DeleteDialog";
import styled from "styled-components";
import {RepeatModal} from "../../Panel/Doctor/Calendar/RepeatModal";
import Tooltip from "./TooltipCalendar"
import Snackbar from '@mui/material/Snackbar'
import Alert from "@mui/material/Alert";
import {generateTimePeriods} from "../../../helpers/calendar";
import {calendarColors, white} from "../../../constants/colors";
import {Preloader} from "../Preloader";

const Wrapper = styled.div`
max-width: 1400px;
min-width: 1100px;
overflow: initial;
margin: 16px auto 0;
padding-right: 16px;
animation: .2s fadeIn ease-out;
height: calc(100vh - 56px - 48px - 16px);

.calendar__first-cell {
top: 72px;
}

.fc-timegrid-slot {
    min-height: 1px ! important;
    height: 1px !important; // 1.5em by default
    border-bottom: 0 !important;
}

.fc-button{
background-color: ${white};
border-color: ${calendarColors.buttonBorder};
color: ${calendarColors.buttonLabel};

&:hover {
color: ${white} !important;
background-color: ${calendarColors.purpleConex} !important;
border-color: ${calendarColors.purpleConex};
}
&:focus {
box-shadow: none !important;
}
}
.fc-timegrid-slot-label-cushion {
color: ${calendarColors.buttonLabel};
}

.fc-col-header-cell-cushion, .fc-toolbar-title{
color: ${calendarColors.columnTitle};
}

.fc-timegrid-slot {
  font-size : smaller
}

.fc-button-active{
color: ${white} !important;
background-color: ${calendarColors.purpleConex} !important;
border-color: ${calendarColors.purpleConex} !important;
}

.fc tr {
    min-height: 1px ! important;
    height: 1px !important; // 1.5em by default
    border-bottom: 0 !important;
}

.popper,
.tooltip {
  position: absolute;
  z-index: 9999;
  background: ${calendarColors.backgroundTooltip};
  color: black;
  width: 150px;
  border-radius: 3px;
  box-shadow: 0 0 2px rgba(0,0,0,0.5);
  padding: 10px;
  text-align: center;
}
.style5 .tooltip {
  background: ${calendarColors.backgroundTooltip};
  color: ${white};
  max-width: 200px;
  width: auto;
  font-size: .8rem;
  padding: .5em 1em;
}
.popper .popper__arrow,
.tooltip .tooltip-arrow {
  width: 0;
  height: 0;
  border-style: solid;
  position: absolute;
  margin: 5px;
}

.tooltip .tooltip-arrow,
.popper .popper__arrow {
  border-color: ${calendarColors.backgroundTooltip};
}
.style5 .tooltip .tooltip-arrow {
  border-color: ${calendarColors.backgroundTooltip};
}
.popper[x-placement^="top"],
.tooltip[x-placement^="top"] {
  margin-bottom: 5px;
}
.popper[x-placement^="top"] .popper__arrow,
.tooltip[x-placement^="top"] .tooltip-arrow {
  border-width: 5px 5px 0 5px;
  border-left-color: transparent;
  border-right-color: transparent;
  border-bottom-color: transparent;
  bottom: -5px;
  left: calc(50% - 5px);
  margin-top: 0;
  margin-bottom: 0;
}
.popper[x-placement^="bottom"],
.tooltip[x-placement^="bottom"] {
  margin-top: 5px;
}
.tooltip[x-placement^="bottom"] .tooltip-arrow,
.popper[x-placement^="bottom"] .popper__arrow {
  border-width: 0 5px 5px 5px;
  border-left-color: transparent;
  border-right-color: transparent;
  border-top-color: transparent;
  top: -5px;
  left: calc(50% - 5px);
  margin-top: 0;
  margin-bottom: 0;
}
.tooltip[x-placement^="right"],
.popper[x-placement^="right"] {
  margin-left: 5px;
}
.popper[x-placement^="right"] .popper__arrow,
.tooltip[x-placement^="right"] .tooltip-arrow {
  border-width: 5px 5px 5px 0;
  border-left-color: transparent;
  border-top-color: transparent;
  border-bottom-color: transparent;
  left: -5px;
  top: calc(50% - 5px);
  margin-left: 0;
  margin-right: 0;
}
.popper[x-placement^="left"],
.tooltip[x-placement^="left"] {
  margin-right: 5px;
}
.popper[x-placement^="left"] .popper__arrow,
.tooltip[x-placement^="left"] .tooltip-arrow {
  border-width: 5px 0 5px 5px;
  border-top-color: transparent;
  border-right-color: transparent;
  border-bottom-color: transparent;
  right: -5px;
  top: calc(50% - 5px);
  margin-left: 0;
  margin-right: 0;
}
`
const CalendarWrapper = styled.div`
  height: 100%;
  ${props => props.blurLoading ? 'filter:blur(2px)' : ''  };
`

export const CalendarComponent = ({
    doctorId,
}) => {
  const calendarRef = React.createRef()
  const dispatch = useDispatch()
  const [eventModal, setEventModal] = useState(null)
  const [deleteModal, setDeleteModal] = useState(null)
  const [showRepeatModal, setShowRepeatModal] = useState(false)
  const [dateFrom, setDateFrom] = useState(null)
  const [dateTo, setDateTo] = useState(null)
  const { formatMessage } = useIntl()
  const f = id => formatMessage({ id })
  const [eventsCalendar,setEventsCalendar] = useState([])
  const [openSnackBarDragConsultError, openSnackBarDragConsultErrorSet] = useState(false);
  const [openSnackBarDragGeneralError, openSnackBarDragGeneralErrorSet] = useState(false);
  const [openSnackBarCreatePastError, openSnackBarCreatePastErrorSet] = useState(false);
  const periods = useMemo(() => generateTimePeriods(), [])
  const [loading, isLoading] = useState(false)
  const locale = moment.locale()


  const onCloseEvent = (infoBack) => {
    if(infoBack){
      fetchData(dateFrom,dateTo,true)
    }
    setEventModal(null)
    setDeleteModal(null)
  }

  const onCloseRepeat = (infoBack) => {
    if(infoBack){
      fetchData(dateFrom,dateTo,true)
    }
    setShowRepeatModal(false)
  }

  function onClickCreate(event=null){

    if(event !== null && event?.start < new Date()){
      openSnackBarCreatePastErrorSet(true)
    }else{
      setEventModal({
        day:event ? dateFormat(event.start,"yyyy-mm-dd") : null,
        periodFrom : event ? dateFormat(event.start,"HH:MM") : "08:00",
        to : event ? dateFormat(event.end,"HH:MM") : "23:00"
      })
    }
  }
  function onClickDelete(info){
    setDeleteModal({
      day:dateFormat(info.event._instance.range.start,"yyyy-mm-dd"),
      periodFrom :dateFormat(info.event._instance.range.start,"UTC:HH:MM"),
      periodTo :dateFormat(info.event._instance.range.end,"UTC:HH:MM") > dateFormat(info.event._instance.range.start,"UTC:HH:MM") ? dateFormat(info.event._instance.range.end,"UTC:HH:MM") : "23:50",
    })
  }

  function showTooltip(info){
    if(info.event.extendedProps.type !== undefined && info.event.extendedProps.patient !== undefined){
      const tooltip = new Tooltip(info.el, {
        title: f(info.event.extendedProps.type) +` ${f("schedule.with")} ` +info.event.extendedProps.patient,
      });
    }
  }

  function getDateForEvent(event){
    const startDateOld = moment(event._instance.range.start, null).tz(Intl.DateTimeFormat().resolvedOptions().timeZone).utc().format('H:mm')
    const endDateOld = moment(event._instance.range.end, null).tz(Intl.DateTimeFormat().resolvedOptions().timeZone).utc().format('H:mm')
    const dayOld = moment(event._instance.range.end, null).tz(Intl.DateTimeFormat().resolvedOptions().timeZone).utc().format('YYYY-MM-DD')
    const fromIndexOld = periods.findIndex(i => i.from === startDateOld)
    let toIndexOld = endDateOld === periods[periods.length-1].to ? periods.length-1  :   periods.findIndex(i => i.from === endDateOld)
    if (toIndexOld === -1 || fromIndexOld === -1) {
      return -1
    }
    else if(fromIndexOld === toIndexOld){
      return periods.slice(fromIndexOld).map(i => `${dayOld} ${i.from.length < 5 ? `0${i.from}` : i.from}`)
    }
    else {
      return periods.slice(fromIndexOld, toIndexOld).map(i => `${dayOld} ${i.from.length < 5 ? `0${i.from}` : i.from}`)
    }
  }

  function onChangeEvent(info){
    const type  = info.oldEvent._def.extendedProps.type
    const daysOld = getDateForEvent(info.oldEvent)
    const daysNew = getDateForEvent(info.event)
    if(daysOld !== -1 && daysNew  !== -1){
      isLoading(true)
      dispatch(deleteEvent(daysOld,doctorId))
          .then(() =>
          {
            const actionAvailableNew = info.event._def.extendedProps.data
            dispatch(editEvent(daysNew,null,type,null,
                { te_available: actionAvailableNew.actionAvailable.TE ? 1 : 0,
                  tc_available: actionAvailableNew.actionAvailable.TC ? 1 : 0,
                  tca_available: actionAvailableNew.actionAvailable.TCA ? 1 : 0,
                },doctorId))
                .then(() =>
                {
                  isLoading(false)
                })
          })
    }
    else{
      info.revert()
      openSnackBarDragGeneralErrorSet(true)
    }
  }
  function onEventAllow(info,event){
    if(info.start < new Date()){
      openSnackBarCreatePastErrorSet(true)
      return false;
    }
    if(event._def.extendedProps.type !== "Available"){
      openSnackBarDragConsultErrorSet(true)
      return false;
    }
    return true;
  }

  function fetchData(dateStart,dateEnd,reset = false){
    dispatch(fetchCalendarData(moment(dateStart).format('YYYY-MM-DD'),moment(dateEnd).format('YYYY-MM-DD'),doctorId))
        .then(resp => {
              resp.payload.data.forEach(element => {
                  element.title ? element.title = `${f(element.title)}` : element.title = ''
              })
              reset ? setEventsCalendar(resp.payload.data) : setEventsCalendar(eventsCalendar.concat(resp.payload.data))
            }
        )
  }

  function changeDate(arg){
    arg.end.setDate(arg.end.getDate()-1)

    if(!dateFrom){
      const newDateFrom = new Date();
      newDateFrom.setHours(0,0,0,0);
      newDateFrom.setMonth(newDateFrom.getMonth()-1)
      const newDateTo = new Date(newDateFrom);
      newDateTo.setMonth((newDateTo.getMonth()+2));
      setDateFrom(newDateFrom)
      setDateTo(newDateTo)
      fetchData(newDateFrom,newDateTo)
      return;
    }
    // We need informations before and after the current -> reset array
    if(arg.start < dateFrom && arg.end > dateTo ){
      arg.start.setHours(0,0,0,0);
      arg.end.setHours(0,0,0,0);
      fetchData(arg.start,arg.end,true);
      setDateFrom(arg.start)
      setDateTo(arg.end)
    }
    else if(arg.start < dateFrom){
      arg.start.setHours(0,0,0,0);
      fetchData(arg.start,dateFrom.setDate(dateTo.getDate()-1))
      setDateFrom(arg.start)
    }
    else if (arg.end > dateTo){
      arg.end.setHours(0,0,0,0);
      fetchData(dateTo.setDate(dateTo.getDate()+1),arg.end)
      setDateTo(arg.end)
    }
  }


  return (
      <Wrapper>
        <Snackbar open={openSnackBarDragConsultError} autoHideDuration={5000} anchorOrigin={{ vertical: 'top', horizontal: 'center' }} onClose={() =>  openSnackBarDragConsultErrorSet(false)}>
          <Alert  severity="error" sx={{ width: '100%' }} onClose={() => openSnackBarDragConsultErrorSet(false)}>
            <FormattedMessage id="schedule.drag.consult"/>
          </Alert>
        </Snackbar>
        <Snackbar open={openSnackBarCreatePastError} autoHideDuration={5000} anchorOrigin={{ vertical: 'top', horizontal: 'center' }} onClose={() =>  openSnackBarCreatePastErrorSet(false)}>
          <Alert  severity="error" sx={{ width: '100%' }} onClose={() => openSnackBarCreatePastErrorSet(false)}>
            <FormattedMessage id="schedule.create.past"/>
          </Alert>
        </Snackbar>
        <Snackbar open={openSnackBarDragGeneralError} autoHideDuration={5000} anchorOrigin={{ vertical: 'top', horizontal: 'center' }} onClose={() =>  openSnackBarDragGeneralErrorSet(false)}>
          <Alert  severity="error" sx={{ width: '100%' }} onClose={() => openSnackBarDragGeneralErrorSet(false)}>
            <FormattedMessage id="schedule.drag.problem"/>
          </Alert>
        </Snackbar>
        { loading &&  <Preloader height={'auto'}/> }
        <CalendarWrapper blurLoading={loading}>
          <FullCalendar
              ref={calendarRef}
              locale={locale}
              height={'100%'}
              allDaySlot={false}
              plugins={[ dayGridPlugin, listPlugin, timeGridPlugin,interactionPlugin ]}
              initialView={"timeGridWeek"}
              views={{
                listUpcomingYear: {
                  type: 'list',
                  visibleRange: function(currentDate) {
                    let startDate
                    if(currentDate.getFullYear() <= new Date().getFullYear()) {
                      startDate = new Date()
                    } else {
                      startDate = new Date(currentDate.getFullYear(), 0, 1)
                    }
                    let endDate = new Date(startDate.getFullYear(), 11, 31)
                    return { start: startDate, end: endDate }
                  },
                  titleFormat: { year: 'numeric' }
                }
              }}
              editable={true}
              selectable={true}
              select={onClickCreate}
              eventClick={onClickDelete}
              eventDidMount={showTooltip}
              datesSet={changeDate}
              eventDrop={onChangeEvent}
              eventResize={onChangeEvent}
              eventAllow={onEventAllow}
              eventOverlap={false}
              events={eventsCalendar}
              viewDidMount={function (arg) {
                let calendarAPI = arg.view.calendar
                if (arg.view.type == "listUpcomingYear") {
                  calendarAPI.setOption("headerToolbar", {
                    start: 'timeGridWeek,listUpcomingYear,createEvent,repeatEvent',
                    center: 'title',
                    end: 'prevYear,nextYear'
                  })
                }
                else {
                  calendarAPI.setOption("headerToolbar", {
                    start: 'timeGridWeek,listUpcomingYear,createEvent,repeatEvent',
                    center: 'title',
                    end: 'prev,next'
                  })
                  calendarAPI.today()
                }
              }}
              customButtons={{
                createEvent : {
                  text: f('schedule.create'),
                  click: function(){
                    onClickCreate(null)
                  }
                },
                repeatEvent : {
                  text: f('schedule.editrepeats'),
                  click: function(){
                    setShowRepeatModal(true)
                  }
                },
                prevYear: {
                  text: '<<',
                  click: function (e) {
                    let calendarAPI = calendarRef.current.getApi()
                    calendarAPI.prevYear()
                  }
                },
                nextYear: {
                  text: '>>',
                  click: function (e) {
                    let calendarAPI = calendarRef.current.getApi()
                    calendarAPI.nextYear()
                  }
                }
              }}
              firstDay={1}
              slotDuration={'00:10'}
              slotMinTime={"08:00:00"}
              slotMaxTime={"20:00:00"}
              buttonText={
                {
                  week:     f('schedule.week'),
                  list:     f('schedule.list')
                }
              }
              slotLabelFormat={
                {
                  hour: 'numeric',
                  minute: '2-digit',
                  meridiem: false
                }
              }
          />
        </CalendarWrapper>
        { showRepeatModal && <RepeatModal close={(infoBack) => onCloseRepeat(infoBack)} isOpen={showRepeatModal} doctorId={doctorId}/> }
        { eventModal && <EventDialog open={!!eventModal} onClose={(infoBack) => onCloseEvent(infoBack)} data={eventModal} doctorId={doctorId} /> }
        { deleteModal &&  <DeleteDialog open={!!deleteModal} onClose={(infoBack) => onCloseEvent(infoBack)} data={deleteModal} doctorId={doctorId} /> }
      </Wrapper>
  )
}
import idx from 'idx'

export const mapHealthRecords = (data, state) => {
  const result = {
    ...state,
    ...data,
    attachment: {
      images: [],
      medical_reports: [],
      prescriptions: [],
      laboratory_analyses: [],
      other: [],
    },
  }

  idx(data, _ => _.relationships.attachment.data.forEach((item) => {
    result.attachment[item.attributes.type].push(item)
  }))

  return result
}

export const checkAttachmentType = (type) => {
  const types = ['images', 'laboratory_analyses', 'medical_reports', 'prescriptions', 'other']

  return types.some(item => item === type) ? type : 'images'
}

import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import { Colors } from 'constants/colors'
import { FormattedMessage } from 'react-intl'
import { Paper } from '../Paper'

const Wrapper = styled(Paper)`
  padding: 5px 16px;
  color: ${Colors.blueGrey};
`

const Line = styled.div`
  display: flex;
  padding: 12px 0;

  & > div, h1 {
    word-wrap: break-word;
  }
`

const Value = styled.div`
  max-width: calc(100% - 160px - 13px);
  font-size: 14px;
  line-height: 1.57;
  text-transform: capitalize;
`

const Label = styled.h1`
  width: 160px;
  min-width: 160px;
  max-width: 160px;
  margin: 0 13px 0 0;
  font-size: 16px;
  font-weight: normal;
  color: ${Colors.dark};
  opacity: 0.9;
`

export const PropertiesList = ({ list }) => (
  <Wrapper>
    {list.map(item => (
      <Line key={item.label}>
        <Label>
          <FormattedMessage id={item.label} defaultMessage={item.label} />
        </Label>
        <Value>
          {
            item.value
            && <FormattedMessage id={item.value} defaultMessage={item.value} />
          }
        </Value>
      </Line>
    ))}
  </Wrapper>
)

PropertiesList.defaultProps = {
  list: [],
}

PropertiesList.propTypes = {
  list: PropTypes.arrayOf(PropTypes.shape({
    label: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  })),
}

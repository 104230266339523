import React, { useState } from 'react';
import styled from 'styled-components'
import { FormattedMessage } from 'react-intl'
import FormHelperText from '@mui/material/FormHelperText';
import { TagMultipleInfinityAsyncSelect } from 'common/AsyncSelect/TagMultipleInfinity';
import Paper from '@mui/material/Paper';
import { Form, Field } from 'react-final-form'
import { EditPage } from 'common/UiKit/EditPage'
import { useDispatch, useSelector } from 'react-redux';
import { history } from '../../../../../utils/history'
import { appendSkill } from '../../../../../helpers/skill';
import { externalFormSubmit } from '../../../../../helpers/externalFormSubmit'
import { editSkills } from '../../../../../redux/modules/auth';
import {Page} from "../../../../common/Page";

const Title = styled.div`
  font-size: 22px;
  font-weight: 500;
  margin-top: 32px;
  text-align: center;
`


export const EditSkills = (
) => {
  return (
        <Title>
          <FormattedMessage id='signature.title' />
        </Title>
  )
}

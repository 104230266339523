import { createAction, handleActions } from 'redux-actions'
import axios from 'axios'
import { combineReducers } from 'redux'
import { history } from '../../../utils/history';

//#region Actions
export const fetchStatusRequest = createAction('FETCH_STATUS_REQUEST')
export const fetchStatusSuccess = createAction('FETCH_STATUS_SUCCESS')
export const fetchStatusFailure = createAction('FETCH_STATUS_FAILURE')

export const updateCodeRequest = createAction('UPDATE_CODE_REQUEST')
export const updateCodeSuccess = createAction('UPDATE_CODE_SUCCESS')
export const updateCodeFailure = createAction('UPDATE_CODE_FAILURE')
//#endregion

//#region Reducer
const missions = handleActions({
  [fetchStatusSuccess]: (state, action) => action.payload,
}, {
  required: [],
  optional: [],
})

export const statusReducer = combineReducers({
  missions,
})

//#endregion

//#region Thunks
export const fetchStatus = () => (dispatch) => {
  dispatch(fetchStatusRequest())

  return axios('/api/doctor/implication/status')
    .then(res => dispatch(fetchStatusSuccess(res.data.data.attributes)))
    .catch((err) => {
      dispatch(fetchStatusFailure(err))
    })
}

export const updateInviteCode = data => (dispatch) => {
  dispatch(updateCodeRequest(data))

  return axios('/doctor/referral/join', {
    method: 'POST',
    data,
  })
    .then(res => dispatch(updateCodeSuccess(res.data.data.attributes)))
    .catch((err) => {
      dispatch(updateCodeFailure(err))
      history.push('/panel/profile')
    })
}
//#endregion

import React, { useState } from 'react';
import styled from 'styled-components';
import { FormattedMessage } from 'react-intl';
import { LinkCard } from '../../../common/UiKit/LinkCard'
import { ModalRestrictUsage } from '../../../common/PrivacyPolicy/ModalRestrictUsage';
import { ModalRequestCSV } from '../../../common/PrivacyPolicy/ModalRequestCSV';
import { ModalChangeDecision } from '../../../common/PrivacyPolicy/ModalChangeDecision';
import { history } from '../../../../utils/history'
import { PanelBar } from '../../../common/PanelBar'

const Wrapper = styled.div`
  width: 720px;
  margin: 24px auto 0;
`;

const LinkCardStyled = styled(LinkCard)`
  padding: 12px 16px 12px 24px;
  margin-bottom: 16px;
`;

export const PersonalData = () => {
  const [modalStates, setModalStates] = useState({
    privacy: false,
    terms: false,
    restrict: false,
    requestCsv: false,
    requestDeletion: false,
    modalChange: false,
    payment: false,
  });

  const [setModalSucces] = useState(false);

  const changeModalState = (modal, state) => {
    setModalStates({ ...modalStates, [modal]: state });
  };

  const openSuccessModal = () => {
    setModalSucces(true)
  }

  return (
    <>
      <PanelBar label='global.personal.data' onBackIconClick={() => history.goBack()} />
      <Wrapper>
        <h2> <FormattedMessage id='global.personal.data' /> </h2>
        <LinkCardStyled
          label='profile.changeData'
          onClick={() => changeModalState('modalChange', true)}
        />
        <LinkCardStyled
          label='profile.requestData'
          onClick={() => changeModalState('requestCsv', true)}
        />
        <LinkCardStyled
          label='profile.restrictData'
          onClick={() => changeModalState('restrict', true)}
        />
        <LinkCardStyled
          label='profile.requestDeletion'
          to='/panel/settings/request-deletion'
        />
      </Wrapper>
      <ModalChangeDecision
        handleClickOpen={changeModalState}
        open={modalStates.modalChange}
        handleClose={changeModalState}
      />
      <ModalRestrictUsage
        handleClickOpen={changeModalState}
        handleClose={changeModalState}
        open={modalStates.restrict}
        openSuccessModal={openSuccessModal}
      />
      <ModalRequestCSV
        handleClickOpen={changeModalState}
        handleClose={changeModalState}
        open={modalStates.requestCsv}
        openSuccessModal={openSuccessModal}
      />
    </>
  );
};

import {combineReducers} from "redux";
import {createAction, handleActions} from "redux-actions";
import axios from "axios";

export const aroundMeRequest = createAction('AROUND_ME_REQUEST')
export const aroundMeSuccess = createAction('AROUND_ME_SUCCESS')
export const aroundMeFailure = createAction('AROUND_ME_FAILURE')

/**
 *
 * @param workPlace
 * @returns {function(*): void}
 */
export const aroundMe = (workPlace) => (dispatch) => {
  if (locAutocompleteTask != null) {
    clearTimeout(locAutocompleteTask)
  }
  dispatch(aroundMeRequest())
  let lat
  let lon
  if (workPlace) {
    lat = workPlace.lat
    lon = workPlace.lon
    return axios.get(`/general/geocoding/reverse?lat=${lat}&lon=${lon}`)
      .then(res => dispatch(aroundMeSuccess(res.data.data)))
      .catch(err => dispatch(aroundMeFailure(err)))
  } else {
    return navigator.geolocation.getCurrentPosition(position => {
      let lat = position.coords.latitude
      let lon = position.coords.longitude
      return axios.get(`/general/geocoding/reverse?lat=${lat}&lon=${lon}`)
        .then(res => dispatch(aroundMeSuccess(res.data.data)))
        .catch(err => dispatch(aroundMeFailure(err)))
    }, err => {
      const errorCode = err.code || 0
      let errorMessage
      // TODO Replace messages with localized code
      switch (errorCode) {
        case GeolocationPositionError.PERMISSION_DENIED:
          errorMessage = "Localisation refusée"
          break
        case GeolocationPositionError.POSITION_UNAVAILABLE:
          errorMessage = "Localisation non disponible"
          break
        case GeolocationPositionError.TIMEOUT:
          errorMessage = "Temps de localisation trop long"
          break
        default:
          errorMessage = "Localisation impossible"
      }
      return dispatch(aroundMeFailure(errorMessage))
    })
  }
}

export const locAutoCompleteRequest = createAction('LOC_AUTOCOMPLETE_REQUEST')
export const locAutoCompleteSuccess = createAction('LOC_AUTOCOMPLETE_SUCCESS')
export const locAutoCompleteFailure = createAction('LOC_AUTOCOMPLETE_FAILURE')

const removeDuplicates = (coordinates = []) => {
  if (!coordinates || coordinates.length <= 1)
    return coordinates
  // else
  const coordinatesList = [coordinates[0]]
  let {city, country, housenumber, postcode, street} = coordinates[0].attributes
  for (let i = 1, n = coordinates.length; i < n; i++) {
    if (city !== coordinates[i].attributes.city || country !== coordinates[i].attributes.country ||
        housenumber !== coordinates[i].attributes.housenumber || postcode !== coordinates[i].attributes.postcode ||
        street !== coordinates[i].attributes.street) {
      coordinatesList.push(coordinates[i])
    }
    city = coordinates[i].attributes.city
    country = coordinates[i].attributes.country
    housenumber = coordinates[i].attributes.housenumber
    postcode = coordinates[i].attributes.postcode
    street = coordinates[i].attributes.street
  }
  return coordinatesList
}

let locAutocompleteTask = null;
export const locAutoComplete = (value) => (dispatch) => {
  if (!value || value.length < 3)
    return
  // else
  if (locAutocompleteTask != null) {
    clearTimeout(locAutocompleteTask)
  }
  locAutocompleteTask = setTimeout(() => {
    locAutocompleteTask = null;
    dispatch(locAutoCompleteRequest(value))
    axios.get(`/general/geocoding/address-autocomplete?q=${value}`)
      .then(res => {
        if (res.data && res.data.data)
          dispatch(locAutoCompleteSuccess(res.data.data))
        else
          dispatch(locAutoCompleteSuccess([]))
      })
      .catch(err => dispatch(locAutoCompleteFailure(err)))
  }, 900)
}

const clearGeolocationData = createAction('CLEAR_GEOLOCATION')

export const clearGeolocation = () => (dispatch) => {
  return dispatch(clearGeolocationData())
}

const reverseGeocoding = handleActions({
  [aroundMeSuccess]: (state, action) => action.payload.attributes,
  [aroundMeFailure]: (state, action) => action.payload,
  [locAutoCompleteRequest]: () => ({isUserSearch: true}),
  [clearGeolocationData]: () => ({}),
}, {})

const locationSuggestions = handleActions({
  [locAutoCompleteSuccess]: (state, action) => removeDuplicates(action.payload),
  [locAutoCompleteFailure]: () => ([]),
  [clearGeolocationData]: () => ([]),
}, [])

export const geolocReducer = combineReducers({
  reverseGeocoding,
  locationSuggestions,
})
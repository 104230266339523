import React, {useEffect, useState} from 'react'
import {FormattedMessage} from "react-intl"
import moment from 'moment-timezone'
import styled, {css} from 'styled-components'
import {TextButton} from "../UiKit/TextButton";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import {history} from "../../../utils/history"
import {BUTTON_COLORS, Colors} from "../../../constants/colors";


const CalendarWrapper = styled.div`
  display: flex;
  width: auto;
  justify-content: center;
`

const Calendar = styled.div`
	display: grid;
	grid-template-columns: repeat(6, 1fr);
	grid-template-rows: auto;
	border-bottom: 1px solid ${Colors.borderGrey};
	width: 650px;
	@media screen and (max-width: 1550px) {
		width: auto;
		display: grid;
		flex-direction: column;
		justify-content: center;
		align-items: center;
	}
`

const Day = styled.div`
	height: 100%;
	display: flex;
	align-self: center;
	justify-content: center;
	align-content: center;
	margin: 5px 0;
`

const DayTxt = styled.div`
	color: ${Colors.black};
	margin-right: 4px;
	font-family: 'Roboto';
	font-style: normal;
	font-weight: 500;
	align-self: center;
	justify-self: center;
	text-transform: capitalize;
`

const DayNumber = styled.div`
	color: ${Colors.greyText};
	align-self: center;
	justify-self: center;
`

const Hour = styled.div`
  font-family: Roboto;
  height: 20px;
  font-size: 16px;
  font-weight: 400;
  color: ${Colors.black};
  display: flex;
  border-radius: 3px;
  padding: 2px 8px;
  cursor: pointer;
  justify-self: center;
  align-self: center;
  border: 1px solid ${BUTTON_COLORS.purple};
  &:hover{
	background-color: ${BUTTON_COLORS.lightPurple};
  }
  margin: 10px 0px;
  grid-column-start: ${props => (props.column)};
  grid-row-start: ${props => (props.row)};
  
  ${props => (props.isSelected && css`
	  background-color: ${BUTTON_COLORS.lightPurple};
	  color: ${Colors.white};
	`)}
	
	  ${props => (props.row==2 && css`
		margin-top: 20px;
	`)}
`

const TimeVoid = styled.div`
  width: 8px;
  height: 0px;
  border-top: 1px solid ${Colors.bluePurple};
  display: flex;
  justify-self: center;
  align-self: center;
  margin: 10px 0px;
  grid-column-start: ${props => (props.column)};
  grid-row-start: ${props => (props.row)};
		${props => (props.row==2 && css`
		margin-top: 20px;
	`)}
`

const LeftIcon = styled(ChevronLeftIcon)`
  && {
    color: ${Colors.greyText};
    margin-top: 4px;
    width: 22px;
    height: 22px;
  }
  &&:hover {
    background-color: ${BUTTON_COLORS.lightPurple};
    border-radius: 15px;
  }
`

const RightIcon = styled(ChevronRightIcon)`
  && {
    color: ${Colors.greyText};
    margin-top: 4px;
    width: 22px;
    height: 22px;
  }
  &&:hover {
    background-color: ${BUTTON_COLORS.lightPurple};
    border-radius: 15px;
  }
`

const SeeMoreButton = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
`

const Button = styled(TextButton)`
  && {
    text-transform: none;
    font-size: 14px;
    font-weight: 500;
    box-shadow: none;
    height: 35px;
    margin-top: 18px;
    background-color: ${Colors.white};
    border: 1px solid ${BUTTON_COLORS.purple};
    color: ${BUTTON_COLORS.purple};
    flex: 0 0 auto;
  }

  &&:hover {
    background-color: ${BUTTON_COLORS.lightPurple};
  }
`

const normalizeDays = (availabilitiesList, day) => {
	let result = []
	if (Object.keys(availabilitiesList).includes(moment(day).format('YYYY-MM-DD'))){
		result.push(day)
	} else {
		result.push("-")
	}
	return result;
}

const normalizeTime = (availabilitiesList, day, limit) => {
	let result = [];
	if (availabilitiesList) {
		if (day === "-") {
			for (let i = 0; i < limit; i++){
				result.push("-")
			}
		} else {
			result = Object.values(availabilitiesList[moment(day).format('YYYY-MM-DD')]).slice(0, limit)
			let length = result.length;
			for (let i = length; i < limit; i++) {
				result[i] = "-"
			}
		}
	}
	return result;
}

const formatAvailabilitiesList = (oldList) => {
	const newList = {}
	oldList.forEach(function (datetime) {
		const date = moment(datetime).format('YYYY-MM-DD');
		if(newList[date] === undefined) {
			newList[date] = []
		}
		newList[date].push(moment(datetime).format('HH:mm'))
	})
	return newList
}

export const DoctorAvailabilitiesTCA = ({id, availabilitiesList}) => {
	const [limit, setLimit] = useState(2)
	const [max, setMax] = useState(7)
	const [min, setMin] = useState(0)
	availabilitiesList = availabilitiesList['date_times'] ?? []
	availabilitiesList = formatAvailabilitiesList(availabilitiesList)

	const getDays = (min, max) => {
		let days = []
		let today = new Date();
		for (let i = min; i < max; i++) {
			let value = new Date(today);
			value.setDate(today.getDate() + i);
			if (moment(value).format("ddd") !== "dim."){
				days.push(value);
			}
		}
		return days;
	}

	 const getLimitMax = () => {
		 let limitMax = 0;
		 getDays(min, max).map((day) => {
			 if (Object.keys(availabilitiesList).includes(moment(day).format('YYYY-MM-DD'))) {
				 if (Object.keys(availabilitiesList[moment(day).format('YYYY-MM-DD')]).length > limitMax) {
					 limitMax = Object.keys(availabilitiesList[moment(day).format('YYYY-MM-DD')]).length;
				 }
			 }
		 })
		 return limitMax;
	 }

	const nextDays = () => {
		setMin(min+7)
		setMax(max+7)
		setLimit(2)
	}

	const previousDays = () => {
		if (min > 0) {
			setMin(min-7)
		}
		if (max > 7) {
			setMax(max-7)
		}
		setLimit(2)
	}

	const getSelected = (Time, day) => {
		return moment(day).format('YYYY-MM-DD') + " " + Time;
	}

	const seeMoreAvailabilities = () => {
		if (getLimitMax() > limit) {
			setLimit(limit+2)
		}
	}

	return (
		<>
			<CalendarWrapper>
				<LeftIcon onClick={previousDays}/>
				<Calendar>
					{getDays(min, max).map( (day) => {
						return (
							<Day>
								<DayTxt>
									<FormattedMessage id={moment(day).format("ddd ")}/>
								</DayTxt>
								<DayNumber>
									<FormattedMessage id={moment(day).format(" DD/MM ")}/>
								</DayNumber>
							</Day>
						)
					})}
					{getDays(min, max).map((day, dayIndex) =>
						normalizeDays(availabilitiesList, day).map((availability) =>
							normalizeTime(availabilitiesList, availability, limit).map( (Time, TimeIndex) => {
								return (
									Time === "-" ?
										<TimeVoid column={dayIndex+1} row={TimeIndex+2}/>
										:
										<Hour
											column={dayIndex+1}
											row={TimeIndex+2}
											onClick={() => {
												history.push(`/panel/teleconsultation/create/${id}?proposedDate=${getSelected(Time, day)}`)
											}}
										>
											<FormattedMessage id={Time}/>
										</Hour>
								)
							})
						)
					)}
				</Calendar>
				<RightIcon onClick={nextDays}/>
			</CalendarWrapper>
			<SeeMoreButton>
				<Button
					variant='extended'
					onClick={seeMoreAvailabilities}
				>
					<FormattedMessage id="global.see.other.availabilities"/>
				</Button>
			</SeeMoreButton>
		</>
	)
}
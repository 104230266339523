import React, { useState , useEffect } from 'react';
import { Form, Field } from 'react-final-form'
import { mapProps } from 'recompose'
import PropTypes from 'prop-types'
import moment from 'moment-timezone'
import styled from 'styled-components'
import { FormattedMessage, useIntl } from 'react-intl';
import { TextField } from '../UiKit/TextField'
import get from 'lodash/get';
import { AsyncSelectField } from '../AsyncSelect';
import { FieldWrapper } from '../CheckboxInputField'
import {DateField} from "../DateField";
import Grid from "@mui/material/Grid";
import TextFieldMui from "@mui/material/TextField";
import { isValid } from 'date-fns';
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Switch from '@mui/material/Switch';
import {Colors} from "../../../constants/colors";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import Tooltip from '@mui/material/Tooltip';
import {ToggleButtonGroupField} from "../UiKit/ToggleButtonGroupField";


const normalizeInitialValues = values => ({
  ...values,
  height: values.height || null,
  weight: values.weight || null,
  lastHospitalized: values.lastHospitalized && moment(values.lastHospitalized).format('YYYY-MM-DD'),
  birthday: values.birthday && moment(values.birthday).format('YYYY-MM-DD')
})

const normalizeValues = values => ({
  ...values,
  birthplace: get(values, 'birthplace.id', values.birthplace),
  ...(values.cpam ? {
    cpam: String(values.cpam).replace(/\s/gi, '')
  } : {
    cpam: null
  }),
  height: values.height || 0,
  weight: values.weight || 0,
  lastName: values.lastName || '',
  lastHospitalized: values.lastHospitalized || '',
  birthday: values.birthday && moment(values.birthday).format('YYYY-MM-DD'),
})

const enhance = mapProps(props => ({
  ...props,
  onSubmit: values => props.onSubmit(normalizeValues(values))
    .then(() => props.afterSubmit()),
}))

export const HealthDescriptionForm = enhance(({
  onSubmit,
  id,
  initialValues,
  additionalFields,
  }) => {
  const isValidDate = (dateString) => {
    return isValid(new Date(dateString));
  };
  const [errorBirthday, setErrorBirthday] = useState('');

  useEffect(() => {
  }, [errorBirthday]);


  const validate = ({
                      height, weight, firstName, lastName, birthday, insurance, lastHospitalized, gender, cpam
                    }, withAdditionalFields) => {
    const errors = {}
    if (height && (height > 300 || height < 10)) errors.height = 'Wrong height'
    if (weight && (weight > 500 || weight < 3)) errors.weight = 'Wrong weight'
    if (lastHospitalized
        && (moment(lastHospitalized).isBefore('1990')
            || moment(lastHospitalized).isAfter(Date.now()))) {
      errors.lastHospitalized = 'Wrong date'
    }
    if (withAdditionalFields) {
      if (!firstName) errors.firstName = <FormattedMessage id='error.patient.name' />
      if (!lastName) errors.lastName = <FormattedMessage id='error.patient.name' />
      if (firstName && firstName.length > 45) errors.firstName = <FormattedMessage id='error.toolong' values={{ expectedLenght: 45 }} />
      if (lastName && lastName.length > 45) errors.lastName = <FormattedMessage id='error.toolong' values={{ expectedLenght: 45 }} />
      if (!birthday) setErrorBirthday( 'error.patient.birthday')
      if (!insurance && !foreignCheck) errors.insurance = <FormattedMessage id='error.patient.insurance' />
      if (!gender) {
        errors.gender = <FormattedMessage id='error.sex.required' />
      }
      if (birthday
          && ((moment(birthday).isBefore('1900')
              || moment(birthday).isAfter(Date.now())) || !isValidDate(birthday)) ) {
        errors.birthday = <FormattedMessage id='error.patient.birthday.incorrect' />
        setErrorBirthday( 'error.patient.birthday.incorrect')
      }
      if (birthday && isValidDate(birthday)) setErrorBirthday( '')
    }
    if (cpam && cpam?.length !== 9){
      errors.cpam = <FormattedMessage id='error.cpam.nine.optional' />
    }
    return errors
  }

  const { formatMessage: f } = useIntl()
  const format = id => f({ id })
  const [foreignCheck, setForeignCheck] = useState(initialValues.is_foreign);

  return (
    <Form
      onSubmit={ values => {
        onSubmit({...values, is_foreign: foreignCheck})
      }}
      subscription={{}}
      mutators={{
        setBirthLastNameLikeLastName: (args, state, utils) => {
          if (!state.formState.initialValues.birth_lastname) {
            utils.changeValue(state, 'birth_lastname', () => {
              let value = '';
              if (state.fields.firstName.lastFieldState.value !== undefined) {
                value += `${state.fields.firstName.lastFieldState.value}`;
              }
              return value;
            });
          }
        },
        setCommentInput: (args, state, utils) => {
          utils.changeValue(state, 'note', () => {
            return args[0].target.value;
          });
        },
      }}
      validate={values => validate(values, !!additionalFields)}
      initialValues={initialValues && normalizeInitialValues(initialValues)}
      render={({ handleSubmit, form }) => (
        <form onSubmit={handleSubmit} id={id}>
            <FieldStyledPatientCheckBox
              name='is_foreign'
              label={'Patient étranger'}
              render={() =>
                  <CheckboxStyled
                    setForeignCheck={setForeignCheck}
                    foreignCheck={foreignCheck}
                  />
              }
            />
          <Grid container spacing={2}>

          {
            additionalFields && additionalFields.map((item) => {
              if (item.name === "insurance" && foreignCheck) {
                return null
              }
              if (item.name === "cpam" && foreignCheck) {
                return null
              }
              if (item.type === 'date') {
                return (
                    <Grid item key={item.name} {...item.grid}>
                      <Field
                          name={item.name}
                          label={`${format(item.label)}`}
                          render={props =>
                            <ColumnForAwesomeform>
                            <DateField props={props}
                                       minDate={moment('1900', 'YYYY')}
                                       maxDate={new Date()}
                          />
                              {errorBirthday !== '' ? <SpanError><FormattedMessage id={errorBirthday}/></SpanError> : null}
                            </ColumnForAwesomeform>
                            }
                      />
                    </Grid>
                )
              }
              if (item.type === 'choose' && item.options && Array.isArray(item.options)) {
                return (
                    <Grid item key={item.name} {...item.grid}>
                      <Field
                        label={f({ id: item.label2 })}
                        name={item.name}
                        component={TextField}
                        options={item.options}
                      />
                    </Grid>
                );
              }
              if (item.type === 'button-group' && item.options && Array.isArray(item.options)) {
                return (
                    <Grid item key={item.name} {...item.grid}>
                      <Field
                        name={item.name}
                        component={ToggleButtonGroupField}
                        options={item.options}
                      />
                    </Grid>
                );
              }
              if (item.type === 'autocomplete') {
                return (
                      <Grid item key={item.name} {...item.grid}>
                        <SpanTitle>
                          <FormattedMessage id={item.label} />
                        </SpanTitle>
                        <DivAutoComplete>
                          <Field
                              component={AsyncSelectField}
                              barcketsPath='attributes.insee_code'
                              name={item.name}
                              valuePath='attributes.id'
                              noMarginError
                              labelPath='attributes.name'
                              requestUrl='/api/birthplace/list?name='
                          />
                        </DivAutoComplete>
                      </Grid>

                );
              }
              if (item.rows !== undefined) {
                return (
                    <Grid item key={item.name} {...item.grid}>
                      <TextFieldMui
                          label={`${format(item.label)}`}
                          name="noteText"
                          onChange={form.mutators.setCommentInput}
                          multiline
                          style={{width: '98%', resize: `both`, marginLeft: '10px'}}
                          rows={item.rows}
                          inputProps={{ maxLength: 1000, resize: "both" }}
                          defaultValue={initialValues.note}
                      />
                      <Field
                          label={f({ id: item.label })}
                          name={item.name}
                          component={TextField}
                          style={{display: 'none'}}
                      />
                    </Grid>
                );
              }
              return (
                  <Grid item key={item.name} {...item.grid} style={(item.name === 'cpam') ? {display: 'grid', gridTemplateRows: '100%', gridTemplateColumns: '95% 5%'} : {}}>
                    <Field
                      {...(item.sameDefault ? { onKeyUp: form.mutators.setBirthLastNameLikeLastName } : {})}
                      name={item.name}
                      component={TextField}
                      style={{marginBottom: '15px', marginTop: 'auto'}}
                      label={f({
                        id: item.placeholder || item.label,
                        defaultMessage: item.placeholder || item.label,
                      })}
                      {...(item.parse ? { parse: item.parse } : {})}
                      {...(item.validate ? { validate: item.validate } : {})}
                    />
                    { item.name === 'cpam' && (
                        <Tooltip title={<FormattedMessage id='consultation.tooltip.cpam'/>}>
                          <InfoOutlinedIcon style={{color: 'gray', marginTop: '12px'}}/>
                        </Tooltip>
                    )}
                  </Grid>
              )
            })
          }
          </Grid>
        </form>
      )}
    />
  )
})

HealthDescriptionForm.defaultProps = {
  initialValues: {},
}

HealthDescriptionForm.propTypes = {
  afterSubmit: PropTypes.func.isRequired,
  onSubmit: PropTypes.func.isRequired,
  id: PropTypes.string.isRequired,
  initialValues: PropTypes.shape({
    height: PropTypes.number,
    weight: PropTypes.number,
    lastHospitalized: PropTypes.string,
    contraindication: PropTypes.arrayOf(PropTypes.string),
    smoke: PropTypes.bool,
  }).isRequired,
}

const Checkbox = ({ setForeignCheck, foreignCheck }) =>
{
  return (<FormControlLabel
    control={(
      <>
        <TextStyled>
          <FormattedMessage id='global.patient.foreigner'/>
        </TextStyled>
        <SwitchStyled
          checked={foreignCheck}
          onClick={event => setForeignCheck(!foreignCheck)}
          inputProps={{'aria-label': 'controlled'}}
        />
      </>
    )}
  />)
}




const CheckboxStyled = styled(Checkbox)`
  box-sizing: border-box;
  width: 80%;
  font-size: 15px;
  font-weight: 400;
  line-height: 1.11;
  color: ${Colors.borderGrey};
  & > * > * {
    margin-left : 40px;
  }
`

const TextStyled = styled.div`
  font-size: 16px;
  margin : 0 0 20px 30px;
`

const DateWrapper = styled(FieldWrapper)`
  & > div {
    width: 100% !important;
    
    & > div {
      margin-bottom: 0 !important;
    }
  }
`

const SwitchStyled = styled(Switch)`
  font-size: 0.9em;
  margin-bottom: 20px;
`

const SpanError = styled.span`
        color: #EE3C5B;
        font-size: 0.8rem;
        margin-top : -20px;
        margin-left : 10px;
`

const ColumnForAwesomeform = styled.span`
        display : flex;
        flex-direction: column;
        justify-content : start;
        align-items : start;
`

const SpanTitle = styled.span`
  color: ${Colors.blueGrey};
  font-size: 13px;
  margin-left: 20px;
`

const FieldStyledPatientCheckBox = styled(Field)`
    background-color: red;
`

const DivAutoComplete = styled.div`
  margin-bottom: 10px;
  margin-left: 10px;
  font-size: 16px;
  max-width: 95%;
  height: 50px;
`
import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import { Paper } from 'common/UiKit/Paper'
import { SecuredBckgImage } from 'common/SecuredImage'
import {Colors, STATUS_COLORS} from '../../../constants/colors'
import {DefaultAvatar} from "../DefaultAvatar";
import AddIcon from "@mui/icons-material/Add";
import RemoveIcon from "@mui/icons-material/Remove";
import {useDispatch, useSelector} from "react-redux";
import {toggleFavorite} from "../../../redux/modules/doctors";
import {fullName} from "../../../helpers/user";
import {history} from "../../../utils/history";
import { useLocation } from 'react-router-dom';

const PaperStyled = styled(Paper)`
  padding: 10px 14px;
  position: relative;
  display: flex;
  height: 90px;
  align-items: center; 
`

const Wrapper = styled.div`
  display: flex;
  font-weight: normal;
  font-size: 14px;
  margin: auto 0;
  width: 100%;
`

const AvatarWrapper = styled.div`
  display: flex;
  font-weight: normal;
  font-size: 14px;
`

const Info = styled.div`
  flex-grow: 1;
  margin: auto 32px auto;
`

const NameLinked = styled.span`
  margin: 0;
  font-size: 18px;
  font-weight: bold;
  color: black;
  
  &:hover {
    cursor: pointer;
    color: #6633FF;
  }
`

const Name = styled.h1`
  margin: 0;
  font-size: 18px;
  font-weight: bold;
  color: black;

  &:hover {
    cursor: pointer;
    color: #6633FF;
  }
`

const Specialization = styled.p`
  margin: 0;
  opacity: 0.8;
  padding-top: 5px;
`

const Address = styled.p`
  margin: 0;
  opacity: 0.8;
  padding-top: 15px;
`

const Avatar = styled(SecuredBckgImage)`
  min-width: 70px;
  width: 70px;
  height: 70px;
  border-radius: 100%;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  &:after {
    position : relative;
    content: "oo";
    background-color: ${({ status }) => (status) ? STATUS_COLORS["available"] : STATUS_COLORS["notavailable"]};
    color: transparent;
    border-radius: 50%;
    top: 50px;
    left:50px;
  }
`

const Default = styled.div`
  min-width: 70px;
  width: 70px;
  height: 70px;
  border-radius: 100%;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  &:after {
    position : relative;
    content: "oo";
    background-color: ${({ status }) => (status) ? STATUS_COLORS["available"] : STATUS_COLORS["notavailable"]};
    color: transparent;
    border-radius: 50%;
    top: 50px;
    left:50px;
  }
`

const Button = styled.div`
  display: flex;
  justify-content: flex-end;
  align-items: flex-start;
`

const StyledAddIcon = styled(AddIcon)`
  margin-left: 5px;
  font-size: 50px;
  color: ${Colors.bluePurple};
  cursor: pointer;
  border-radius: 100%;
  &:hover {
    background-color: rgba(231, 226, 246, 0.3);
  }
  
`

const StyledRemoveIcon = styled(RemoveIcon)`
  margin-left: 5px;
  font-size: 50px;
  color: ${Colors.bluePurple};
  cursor: pointer;
  border-radius: 100%;
  &:hover {
    background-color: rgba(231, 226, 246, 0.3);
  }
`

export const FavoriteDoctorCardSearch = ({
                                     id,
                                     doctorId,
                                     firstName,
                                     lastName,
                                     status_availability,
                                     specialization,
                                     specialization_type,
                                     photo,
                                     isFavorite,
                                     link,
                                     workplaceName,
                                     postcode,
                                     city,
                                     street,
                                     gender,
                                   }) => {

  const dispatch = useDispatch();
  const consultationDraft = useSelector(state => state.consultations.draft.consultation)
  const location = useLocation();

  const onConfirm = () => {
    dispatch(toggleFavorite(doctorId, !isFavorite))
  }

  const formatLink = () => {
    if (consultationDraft) {
      location.pathname = location.pathname.replace('consultations', 'concilium')
      history.push(link)
    } else {
      history.push(link)
    }
  }
  return (
    <PaperStyled>
      <Wrapper>
        <AvatarWrapper>
          {
            photo ? (
                <Avatar
                  spinner={{width: '120px', height: '120px'}}
                  src={photo.includes('/file/') ? `http://backend-application.owndoctor.eu${photo}` : photo}
                  status={status_availability}
                />
              ) :
              (
                <Default
                  spinner={{width: '120px', height: '120px'}}
                  style={{backgroundImage: `url(${DefaultAvatar(firstName, lastName, specialization_type)})`}}
                  status={status_availability}
                />
              )
          }
        </AvatarWrapper>
        <Info>

            {link ? (
                <NameLinked onClick={() => formatLink()} to={isFavorite ? link.replace('favorite', '') : link}>
                  {fullName(gender, firstName || 'Name', lastName || 'Surname')}
                </NameLinked>)
              : (
                <Name to={link}>
                  {fullName(gender, firstName || 'Name', lastName || 'Surname')}
                </Name>)
            }
            <Specialization>
              {specialization || 'Therapist'}
            </Specialization>


          <Address>
          {`${workplaceName ? workplaceName + ", " : ""} ${street ? street + ", " : ""} ${postcode ? postcode : ""} ${city ? city : ""}`}
          </Address>
        </Info>
        <Info>
          { !isFavorite && isFavorite!=null && (
            <Button
              variant='outlined'
              onClick={onConfirm}
            >
                  <StyledAddIcon fontSize='large' color='#6633FF' />
            </Button>
          )


          }
          { isFavorite && (
            <Button
              variant='outlined'
              onClick={onConfirm}
            >
                  <StyledRemoveIcon fontSize='large' color='#6633FF' />
            </Button>
          )
          }
        </Info>
      </Wrapper>
    </PaperStyled>
  )
}

FavoriteDoctorCardSearch.defaultProps = {
  location: {},
  onAvatarClick: () => null,
  photo: '',
  buttonText: null,
  rating: null,
}

FavoriteDoctorCardSearch.propTypes = {
  firstName: PropTypes.string.isRequired,
  lastName: PropTypes.string.isRequired,
  specialization: PropTypes.string.isRequired,
  photo: PropTypes.string,
  price: PropTypes.number,
  location: PropTypes.object,
  onButtonClick: PropTypes.func.isRequired,
  onAvatarClick: PropTypes.func,
  isFavorite: PropTypes.bool.isRequired,
  toggleFavorite: PropTypes.func.isRequired,
  isFavoriteFetching: PropTypes.bool.isRequired,
  disableFavorite: PropTypes.bool,
  rating: PropTypes.number,
  buttonText: PropTypes.string,
}

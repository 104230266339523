import {createAction, handleActions} from "redux-actions";
import axios from "axios";
import moment from "moment-timezone";
import idx from "idx";
import {fetchConsultationsListSuccess} from "./consultations";
import {combineReducers} from "redux";

export const fetchRecentContactsRequest = createAction('FETCH_RECENT_CONTACTS_REQUEST')
export const fetchRecentContactsSuccess = createAction('FETCH_RECENT_CONTACTS_SUCCESS')
export const fetchRecentContactsFailure = createAction('FETCH_RECENT_CONTACTS_FAILURE')

export const fetchRecentContacts = params => (dispatch) => {
  dispatch(fetchRecentContactsRequest(params))

  return axios(`/api/doctor/contact/recents?include=specializations,skills,workPlace`, { params })
    .then((res) => {
      dispatch(fetchRecentContactsSuccess(res.data))
    })
    .catch((e) => {
      dispatch(fetchRecentContactsFailure(e))
    })
}

const list = handleActions({
  [fetchRecentContactsSuccess]: (state, action) => ({
    ...state,
    data: action.payload.data,
    paginator: action.payload.paginator,
  }),
}, [])

export const recentContacts = combineReducers({
  list,
})
export const doctorFAQ = [
  {
    heading: 'faq.1.question',
    text: 'faq.1.answer',
  },
  // {
  //   heading: 'faq.2.question',
  //   text: 'faq.2.answer',
  // },
  // {
  //   heading: 'faq.3.question',
  //   text: 'faq.3.answer',
  // },
  {
    heading: 'faq.4.question',
    text: 'faq.4.answer',
  },
  {
    heading: 'faq.5.question',
    text: 'faq.5.answer',
  },
]

export const patientFAQ = [
  {
    heading: 'faq.6.question',
    text: 'faq.6.answer',
  },
  {
    heading: 'faq.7.question',
    text: 'faq.7.answer',
  },
  {
    heading: 'faq.8.question',
    text: 'faq.8.answer',
  },
  {
    heading: 'faq.9.question',
    text: 'faq.9.answer',
  },
  {
    heading: 'faq.10.question',
    text: 'faq.10.answer',
  },
  {
    heading: 'faq.11.question',
    text: 'faq.11.answer',
  },
  {
    heading: 'faq.12.question',
    text: 'faq.12.answer',
  },
  {
    heading: 'faq.13.question',
    text: 'faq.13.answer',
  },
  {
    heading: 'faq.14.question',
    text: 'faq.14.answer',
  },
  {
    heading: 'faq.15.question',
    text: 'faq.15.answer',
  },
]

import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import styled from 'styled-components'
import { SessionComponent } from '../Session'
import { ConsultationResume } from '../ConsultationResume'
import {
  fetchConsultation,
  setDraftConsultation
} from '../../../../../redux/modules/consultations'
import {Colors} from "../../../../../constants/colors";
import Skeleton from '@mui/material/Skeleton';
import {Box} from "@mui/material";
import {MainTeleexpertise} from "../../Concilium/MainTeleexpertise";
import idx from "idx";


const StyledForm = styled.div`
  width: 720px;
  margin: 24px auto;
`


const Bar = styled.div`
  display: flex;
  padding: 4px 8px;
  border-bottom: solid 1px ${Colors.grey};
  align-items: center;
  height: 56px;
  width: 100%;
  box-sizing: border-box;
  background-color: ${Colors.white};
`



export const CurrentConsultation = ({ match, location, ...props }) => {
  const { id } = match.params
  const dispatch = useDispatch()
  const isFetching = useSelector(state => state.loading.consultation)
  const consultation = useSelector(state => idx(state, _ => _.consultations.consultation))
  const doctorId = useSelector(state => state.auth.id)
  const userId = useSelector(state => state.auth.attributes.user_id)
  const isRequis = consultation.attributes?.type === 'TER' && consultation.attributes?.status !== 'draft' ? consultation.relationships?.doctor.data.id === doctorId : false

  useEffect(() => {
    dispatch(fetchConsultation(id, null))
  }, [id, dispatch])
  
  useEffect(() => {
    if (typeof consultation.attributes  !== 'undefined' && consultation.attributes.status === 'draft') {
      dispatch(setDraftConsultation(consultation))
    }
  }, [id, consultation, dispatch])

  if (isFetching || !consultation.id) {
    return (
      <Box   sx={{
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        width: "100%",
        height: "100%",
      }}>
        <Skeleton variant="rectangular" animation="wave" width="100%" marginBottom ='5px'
                  // sx={{
                  //   backgroundColor: '#d9cdff', // Couleur de fond
                  // }}
        >
          <Bar />
        </Skeleton>
        <br/>
        <Skeleton variant="rectangular" animation="wave" width="100%" height="82%" marginBottom ='5px'>
          <StyledForm />
        </Skeleton>
        <br/>
        <Skeleton variant="rectangular" animation="wave" width="80%" height="5%">
          <h1 />
        </Skeleton>
      </Box>
    );
  }
  if (
    consultation.attributes.type === 'TER'
    && (
      consultation.attributes.status === 'accepted'
      || consultation.attributes.status === 'completed'
      || consultation.attributes.status === 'refused'
      || consultation.attributes.status === 'cancelled'
    )
  ) {
    return <SessionComponent match={match} {...props} location={location} />
  }

  if (consultation.relationships.doctor) {
    if (
        (consultation.attributes.type === 'TER' &&
          consultation.attributes.status === 'created' &&  isRequis
        )
          ||
        (consultation.attributes.type === 'TCA'
          && (consultation.attributes.sender !== userId)
          &&
            consultation.attributes.status === 'created'
          )
    ) {
      return (
        <ConsultationResume
          consultation={consultation.attributes}
          patient={consultation.relationships.patient}
          doctor={consultation.relationships.doctor}
          healthRecord={consultation.relationships.healthRecord}
          additionalMember={consultation.relationships.additionalMember}
        />
      )
    }
  } else if (
    consultation.attributes.type === 'TER_ESS'
    && !!consultation.relationships?.additionalMember
    && consultation.relationships.additionalMember.data[0].attributes.doctorID.toString() !== doctorId
    && (
      consultation.attributes.status !== 'accepted'
      || consultation.attributes.status !== 'refused'
      || consultation.attributes.status !== 'cancelled'
    )
  ) {
    return (
      <ConsultationResume
        consultation={consultation.attributes}
        patient={consultation.relationships.patient}
        doctor={consultation.relationships.doctor}
        healthRecord={consultation.relationships.healthRecord}
        additionalMember={consultation.relationships.additionalMember}
      />
    )
  } else if (consultation.attributes.status === 'draft') {
    return (
      <MainTeleexpertise
        match={match}
        />
    )
  }
  if (
      consultation.attributes.type === 'TER_ESS'
      && (consultation.attributes.status !== 'accepted' && consultation.attributes.status !== 'completed')
  ) {
    return (
      <ConsultationResume
        consultation={consultation.attributes}
        patient={consultation.relationships.patient}
        doctor={consultation.relationships.doctor}
        healthRecord={consultation.relationships.healthRecord}
        additionalMember={consultation.relationships.additionalMember}
      />
    )
  }
  return <SessionComponent match={match} {...props} location={location} />
}

import React, { useEffect, useState } from 'react'
import Dialog from '@mui/material/Dialog'
import Button from '@mui/material/Button'
import DialogTitle from '@mui/material/DialogTitle'
import DialogContent from '@mui/material/DialogContent'
import DialogActions from '@mui/material/DialogActions'
import { FormattedMessage } from 'react-intl'
import { Colors } from 'constants/colors'
import { useDispatch, useSelector } from 'react-redux'
import idx from 'idx'
import axios from 'axios'
import styled from 'styled-components'
import formatStringByPattern from 'format-string-by-pattern';
import { fetchContraindications } from '../../../../redux/modules/content'
import { externalFormSubmit } from '../../../../helpers/externalFormSubmit'
import { HealthDescriptionForm } from '../../../common/HealthDescriptionForm'
import { transformRawPatientToForm } from '../../../../helpers/editPatient';
import CloseIcon from "@mui/icons-material/Close";
import {BUTTON_COLORS} from "../../../../constants/colors";


const formatOnlyNumbers = (anyString) => {
  const onlyNumbers = anyString.replace(/[^\d]/g, '');
  return formatStringByPattern('999999999', onlyNumbers);
};

const DialogStyled = styled(Dialog)`
  & > * {
      margin : auto;
      font-size : 20px;
  }
`

const CloseDialog = styled(CloseIcon)`
    position: absolute;
    top: 20px;
    right: 20px;
    cursor: pointer;
    color: ${Colors.footerGrey};
`

const Wrapper = styled(DialogContent)`
  && {
    overflow-y: visible;
  }
`

const Title = styled(DialogTitle)`
  && {
  }
`

const Actions = styled(DialogActions)`
  && {
    margin: 0;
    padding: 16px 8px;
  }
`

export const CheckboxWrapper = styled.div`
  text-align: right;
  
  span:last-of-type {
    color: ${Colors.blueGrey};
  }
`
export const DivButton = styled.div`
  text-align: center;
  margin-bottom:4px;
`

export const ButtonSave = styled(Button)`
  && {
    font-family: Roboto;
    font-size: 14px;
    text-transform: none;
    color: ${Colors.white};
    min-width: 20%;
    height: 40px;
    flex-grow: 0;
    margin-left: 0 !important;
    background-color: ${Colors.bluePurple};
  }
  &&:hover {
    background-color: rgb(71, 35, 178);
    color: ${Colors.white};
  }
`

export const TitleStyled = styled(DialogTitle)`  
  color: ${Colors.footerGrey};
  font-size: 2em;
  font-weight: 500;
  margin: 0 !important;
  display: flex;
  justify-content: center;
  padding: 5px 3% 30px;
`

export const CreatePatient = ({
  open, onClose, afterSubmit, editPatientId, modify
}) => {
  const dispatch = useDispatch()
  const [defaultData, setDefaultData] = useState(false);
  const contraindications = useSelector(state => idx(state, _ => _.content.contraindications || [])
    .map(({ attributes: a }) => ({ label: a.name, value: a.uid })))

  const fetchSelectedPatient = () => {
    if (editPatientId) {
      axios.get(`/api/doctor/virtualPatient/${editPatientId}`)
        .then(result => setDefaultData(transformRawPatientToForm(result.data.data)))
        .catch(err => console.warn(err))
    }
  }
  const onSubmit = values => {
    return(
      axios.post(editPatientId ? `/api/doctor/virtualPatient/${editPatientId}` : '/api/doctor/virtualPatient', {...values})
      .then((response) => {
        afterSubmit(response.data.data);
        fetchSelectedPatient();
        onClose()
      })
    )
  }



  useEffect(() => {
    fetchSelectedPatient();
  }, [editPatientId])

  useEffect(() => {
    dispatch(fetchContraindications())
  }, [dispatch])

  if (editPatientId && !defaultData) {
    return '';
  }

  const handleClose = () => {
    setOpen(false)
  };

  return (
    <DialogStyled open={open} onClose={onClose}
      PaperProps={{
        style: {
          maxWidth: '900px',
          width: '1250px',
          padding: '1%',
          margin: '1%'
        }
      }}
    >
      <CloseDialog onClick={() => onClose()} />
      <TitleStyled>
        <FormattedMessage id={modify ? 'concilium.modify.patient' : 'concilium.create.patient'}/>
      </TitleStyled>
      <Wrapper>
        <HealthDescriptionForm
          {...(editPatientId ? { initialValues: defaultData } : {})}
          onSubmit={onSubmit}
          id='patient-form'
          afterSubmit={() => {}}
          locales={{
            smoke: 'hr.patient.smoke',
            pregnant: 'hr.patient.pregnant',
          }}
          additionalFields={[
            {
              label: 'concilium.insurance.required',
              name: 'insurance',
              placeholder: 'concilium.insurance.required',
              need: true,
              grid: {xs: 12, sm: 6},
              parse: value => value.substring(0, 16),
              validate: (value) => {
                if (!value || !value.length || value.length !== 15 || /[^A-B0-9]/.test(value)) {
                  return <FormattedMessage id='error.patient.insurance_15' />
                }
                return false;
              },
            },
            {
              label: 'concilium.cpam.optional',
              name: 'cpam',
              placeholder: 'concilium.cpam.optional',
              grid: {xs: 12, sm: 6},
              //parse: formatOnlyNumbers,
            },
            {
              label: 'concilium.name.required',
              name: 'lastName',
              need: true,
              sameDefault: ['lastName'],
              placeholder: 'concilium.name.required',
              grid: {xs: 12, sm: 6},
              validate: (value) => {
                const regex = new RegExp("^[A-zÀ-ú -]+$");
                if (!value || !value.length || !regex.test(value)) {
                  return <FormattedMessage id='error.familyname.letters' />
                }
                return false;
              },
            },
            {
              label: 'concilium.birthname.required',
              name: 'birth_lastname',
              need: true,
              placeholder: 'concilium.birthname.required',
              grid: {xs: 12, sm: 6},
              validate: (value) => {
                const regex = new RegExp("^[A-zÀ-ú -]+$");
                if ((value || value?.length) && !regex.test(value)) {
                  return <FormattedMessage id='error.birthname.letters' />
                }
                return false;
              },
            },
            {
              label: 'concilium.firstname.required',
              name: 'firstName',
              need: true,
              placeholder: 'concilium.firstname.required',
              grid: {xs: 12, sm: 6},
              validate: (value) => {
                const regex = new RegExp("^[A-zÀ-ú -]+$");
                if (!value || !value.length || !regex.test(value)) {
                  return <FormattedMessage id='error.firstname.letters' />
                }
                return false;
              },
            },
            {
              label: 'concilium.sexe.required',
              label2: 'concilium.sexe.required',
              name: 'gender',
              type: 'button-group',
              need: true,
              grid: {xs: 12, sm: 6},
              options: [
                { label: 'Femme', value: 'woman' },
                { label: 'Homme', value: 'male' },
              ],
              validate: (value) => {
                const regex = new RegExp("^(male|woman)$");
                if (!value || !value.length || !regex.test(value)) {
                  return <FormattedMessage id='error.sex.required' />
                }
                return false;
              },
            },
            {
              label: 'concilium.birthdate.required',
              name: 'birthday',
              type: 'date',
              need: true,
              grid: {xs: 12, sm: 6},
              placeholder: 'concilium.birthdate.required',
              validate: (value) => {
                const regex = new RegExp("(0[1-9]|[12][0-9]|3[01])[-\/](0[1-9]|1[012])[-\/](19|20)\\d\\d");
                if (!value || !value.length || !regex.test(value)) {
                  return <FormattedMessage id='error.birthdate.required' />
                }
                return false;
              },
            },
            {
              label: 'concilium.birthplace.optional',
              name: 'birthplace',
              need: true,
              placeholder: 'concilium.birthplace.optional',
              grid: {xs: 12, sm: 6},
              type: 'autocomplete',
            },
            {
              label: 'concilium.comment.optional',
              name: 'note',
              rows: 4,
              need: true,
              grid: {xs: 12, sm: 12},
              placeholder: 'concilium.comment.optional',
            },
          ]}
          contraindications={contraindications}
        />
      </Wrapper>
      <DivButton>
        <ButtonSave
            size='small'
            color='primary'
            onClick={() => externalFormSubmit('patient-form')}
            variant='contained'
        >
          <FormattedMessage id='global.save' />
        </ButtonSave>
      </DivButton>
    </DialogStyled>
  );
}

import React from 'react';
import ReCAPTCHA from "react-google-recaptcha";

export const Recaptcha = ({sendCaptchaValue}) => {

  const recaptchaRef = React.createRef();

  const handleRecaptchaChange = (value) => {
    sendCaptchaValue(value)
  }

  return (
    <ReCAPTCHA
      ref={recaptchaRef}
      sitekey={window.env.RECAPTCHA_SITE_KEY}
      onChange={handleRecaptchaChange}
    />
  )
}
import React from 'react'
import PropTypes from 'prop-types'
import {compose, lifecycle, withProps} from 'recompose'
import styled from 'styled-components'
import get from 'lodash/get'
import { connect} from 'react-redux';
import StarIcon from '@mui/icons-material/Star'
import { EmptyScreen } from '../EmptyScreen'
import { history } from '../../../../../utils/history'
import {FavoriteTeamCard} from "../../../../common/TeamCard/FavoriteTeamCard";
import {fetchFavoriteTeams} from "../../../../../redux/modules/doctors";
import CustomLoaderOverlay from "../../../../common/CustomLoaderOverlay";

const Content = styled.div`
  width: auto;
  margin: 10 auto;
  display: grid;
  grid-gap: 25px;
  grid-template-columns: repeat( auto-fill, minmax(315px, 1fr) );
  & > * {
    min-height: 0;
  }
`

export const FavoriteTeamsComponent = ({
  teams,
  emptyTitle,
  isFetching,
}) => {

  const doctorAvailability = (data) => {
    let availabilityValues = []
    data.some(function(teamMember) {
      availabilityValues.push(teamMember.attributes.status_availability)
    })
    return availabilityValues.includes(true)
  }

  return (
    <>
      <CustomLoaderOverlay isShow={isFetching}/>
      {
        teams.length && !isFetching
          ? (
            <Content>
              {teams.map((team) => {
                const teamData = team.attributes;
                const specializations = get(team, 'relationships.skills.data', []);
                return (
                  <FavoriteTeamCard
                    id={team.id}
                    name={teamData.name}
                    type={teamData.type}
                    specializations={specializations}
                    members={teamData.member_count}
                    link={`concilium/main/roso/detail/` + teamData.id}
                    status_availability={doctorAvailability(get(team, 'relationships.members.data', []))}
                  />
                )
              })}
            </Content>
          )
          : <EmptyScreen Icon={StarIcon} title={emptyTitle} />
      }
    </>
  )
}

FavoriteTeamsComponent.defaultProps = {
  teams: null,
  favoriteToggle: null,
  onButtonClickTER: null,
  emptyTitle: '',
}

FavoriteTeamsComponent.propTypes = {
  teams: PropTypes.arrayOf(PropTypes.object).isRequired,
  favoriteToggle: PropTypes.oneOfType([PropTypes.bool, PropTypes.number]).isRequired,
  onButtonClickTER: PropTypes.func.isRequired,
  emptyTitle: PropTypes.string,
}

const enhance = compose(
  connect(
    (state, props) => ({
      teams: state.doctors.favoriteTeams,
      favoriteToggle: state.loading.favoriteToggle,
      isFetching: state.loading.favoriteTeams,
    }),
    {
      fetchFavoriteTeams,
    },
  ),
  withProps(props => ({
    onButtonClickTER: (id) => (e, proposedDate) => {
      const url = `/panel/concilium/team/create/${id}/ESS'?urgent=1'`
      e.stopPropagation()

      return history.push(url)
    },
    emptyTitle: 'consultations.no.favorite.team',
  })),
  lifecycle({
    componentDidMount() {
      this.props.fetchFavoriteTeams()
    },
  }),
)

export const FavoriteTeams= enhance(FavoriteTeamsComponent)

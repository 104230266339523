import React, {useEffect} from 'react';
import { useState } from 'react';
import moment from 'moment-timezone'
import styled from "styled-components";
import {Colors} from "../constants/colors";

export const Countdown = () => {
  const [days, setDays] = useState(0);
  const [hours, setHours] = useState(0);
  const [minutes, setMinutes] = useState(2);
  const [seconds, setSeconds] = useState(0);
  let endtime = moment(new Date()).add(2, 'm').toDate()

  const getTime = () => {
    const time = endtime - Date.now();
    let valueMinutes = Math.floor((time / 1000 / 60) % 60)
    let valueSeconds = Math.floor((time / 1000) % 60)
    if (valueMinutes < 0) {
      valueMinutes = 0
    }
    if (valueSeconds < 0) {
      valueSeconds = 0
    }
    setMinutes(valueMinutes);
    setSeconds(valueSeconds);
  }

  useEffect(() => {
    const interval = setInterval(() => {
      getTime(endtime)
      if(endtime <= Date.now()) {
        setMinutes(0)
        setSeconds(0)
        clearInterval(interval)
      }
    }, 1000);

    return () => clearInterval(interval);
  }, [])

  return (
    <TimerBlock>
      <UnitBlock>
        <div>{minutes < 10 ? "0" + minutes : minutes}</div>
      </UnitBlock>
      <div> : </div>
      <UnitBlock>
        <div>{seconds < 10 ? "0" + seconds : seconds}</div>
      </UnitBlock>
    </TimerBlock>
  );
}

const TimerBlock = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  color: red;
  font-weight: bold;
`

const UnitBlock = styled.div`
  padding: 0 5px 0 5px;
  display: flex;  
`
import React, { useEffect } from 'react';
import { AsyncSelectMultiline } from 'common/AsyncSelect/AsyncSelectMultiline';
import styled from 'styled-components';
import {AsyncSelectContact} from "../Contact";

const StyledAsyncInput = styled.div`
    display: inline-block;
    width: 100%;
`

export const SearchMultipleSelect = React.memo(({
                                                  val = [],
                                                  input = {},
                                                  defaultInputValues,
                                                  createDefaultField,
                                                  listMembersAtCreation,
                                                  noOptionsTxt,
                                                  multiline,
                                                  onChange,
                                                  ...props
                                                }) => {
      
      const changeValue = (id, i) => {
          const newVal = [...val];
          newVal[i] = id;
          input.onChange(id);
      }
      
      useEffect(() => {
          if (!createDefaultField) {
              input.onChange([...val, '']);
              val = []
          }
      }, []);
      
      return (
        <>
            <StyledAsyncInput key={1}>
                {multiline &&
                  <AsyncSelectMultiline
                    listMembersAtCreation={listMembersAtCreation}
                    val={val}
                    type='text'
                    {...props}
                    defaultInputValue={defaultInputValues && defaultInputValues[1]}
                    input={{
                        ...input,
                        value: input.value[1],
                        onChange: id => changeValue(id, 1),
                    }}
                    noOptionsTxt={noOptionsTxt}
                  />}
                {!multiline &&
                  <AsyncSelectContact
                    listMembersAtCreation={listMembersAtCreation}
                    val={val}
                    type='text'
                    {...props}
                    defaultInputValue={defaultInputValues && defaultInputValues[1]}
                    onChange={onChange}
                    defaultOptions={noOptionsTxt}
                  />}
            </StyledAsyncInput>
        </>
      )
  },
  (props, nextProps) => {
      if (props.val && nextProps && nextProps.val && nextProps.val.length && props.val.length === nextProps.val.length) {
          return true;
      }
      return false;
  })

import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import { compose, withProps } from 'recompose'
import { connect } from 'react-redux'
import { DiplomasForm } from 'common/DiplomasForm'
import { EditPage } from 'common/UiKit/EditPage'
import idx from 'idx'
import queryString from 'query-string'
import { createHealthRecordsAttachment, deleteHealthRecordsAttachment } from '../../../../redux/modules/healthRecords'
import { history } from '../../../../utils/history'
import { externalFormSubmit } from '../../../../helpers/externalFormSubmit'
import { checkAttachmentType } from '../../../../helpers/healthRecords'

const Wrapper = styled.div`
  margin-top: 24px;
`

const LABELS = {
  other: 'profile.attachments.other',
  images: 'profile.attachments.images',
  laboratory_analyses: 'profile.attachments.laboratory_analyses',
  prescriptions: 'profile.attachments.prescriptions',
}

const AttachmentsComponent = ({
  diplomas,
  loading,
  onCreateImage,
  deleteHealthRecordsAttachment,
  onBackIconClick,
  onButtonClick,
  onSubmit,
  type,
}) => (
  <EditPage
    label={LABELS[type] || type.split('_').join(' ')}
    onBackIconClick={onBackIconClick}
    buttonText='global.save.changes'
    onButtonClick={onButtonClick}
    loading={loading}
    background
  >
    <Wrapper>
      <DiplomasForm
        diplomas={diplomas}
        id='health-records-images'
        onSubmit={onSubmit}
        createDiplomas={onCreateImage}
        deleteDiploma={deleteHealthRecordsAttachment}
        acceptedExtensions='.jpg, .jpeg, .gif, .png, .heif, .heic, .doc, .docx, .xls, .xlsx, .csv, .pdf, .odt, .ods, .odp, .tex, .ppt, .pptx, .zip, .rar, .tar, .gz, .tar.gz, .7z, .txt, .md, .mov, .mpg, .mpeg, .mkv, .mp4'
        withFilenames
      />
    </Wrapper>
  </EditPage>
)

AttachmentsComponent.propTypes = {
  diplomas: PropTypes.arrayOf(PropTypes.object).isRequired,
  onCreateImage: PropTypes.func.isRequired,
  deleteHealthRecordsAttachment: PropTypes.func.isRequired,
  loading: PropTypes.bool.isRequired,
  onButtonClick: PropTypes.func.isRequired,
  onBackIconClick: PropTypes.func.isRequired,
  onSubmit: PropTypes.func.isRequired,
  type: PropTypes.string.isRequired,
}

const enhance = compose(
  withProps(props => ({
    type: checkAttachmentType(queryString.parse(props.location.search).type),
  })),
  connect(
    (state, props) => ({
      diplomas: idx(state, _ => _.healthRecords.attachment[props.type]) || [],
      loading: state.loading.healthRecordsAttachments,
    }),
    { createHealthRecordsAttachment, deleteHealthRecordsAttachment },
  ),
  withProps(props => ({
    onButtonClick: () => externalFormSubmit('health-records-images'),
    onBackIconClick: () => history.push('/panel/health-records'),
    onSubmit: () => history.push('/panel/health-records'),
    onCreateImage: values => props.createHealthRecordsAttachment({
      ...values,
      type: props.type,
    }),
    diplomas: props.diplomas.map(item => (item.attributes
      ? {
        id: item.id,
        file: item.attributes.url,
        extension: idx(item, _ => _.attributes.extension),
        custom_name: item.attributes.custom_name,
      }
      : { id: '0', file: '' }
    )),
  })),
)

export const Attachments = enhance(AttachmentsComponent)

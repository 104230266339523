import React, {useEffect, useMemo, useRef, useState} from 'react';
import {FormattedMessage} from "react-intl";
import styled from "styled-components";
import {MapContainer, Marker, TileLayer, Tooltip, useMap, useMapEvents} from "react-leaflet";
import {useDispatch, useSelector} from "react-redux";
import {fetchDoctorsListSuccess} from "../../../../redux/modules/doctors";
import {Icon} from "leaflet";
import {aroundMe} from "../../../../redux/modules/geoloc";
import MarkerClusterGroup from 'react-leaflet-cluster';
import {DoctorListWithRef} from "../../../common/DoctorList";
import {LocationSearching, MyLocation} from "@mui/icons-material";
import {LoadingButton} from "@mui/lab";
import Pagination from '@mui/material/Pagination';
import {fetchSpecializations, fetchStructureTypes} from "../../../../redux/modules/content";
import Select from "react-select";
import {history} from "../../../../utils/history";
import {Page} from "../../../common/Page"
import {fetchStructures} from "../../../../redux/modules/structures";
import {fetchTeams} from "../../../../redux/modules/teams";
import {Colors} from "../../../../constants/colors";
import {useLocation, useParams} from "react-router-dom";
import {fullName} from "../../../../helpers/user";
import {fetchSkillPossessors} from "../../../../redux/modules/skills";
import {DoctorAndTeamBySkillListWithRef} from "../../../common/DoctorAndTeamList";
import {MY_API_KEY, URL_GEOAPIFY} from "../../../../constants/map";
import "leaflet/dist/leaflet.css";
import {Configure, Index, InstantSearch, useInstantSearch, usePagination} from "react-instantsearch";
import {InstantSearchBar} from "../../../common/Search/instantSearchBar";
import {ElasticSearchService} from "../../../../services/ElasticSearchService";

const Content = styled.div`
    height: calc(100vh - 56px - 30px);
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    padding: 15px 50px;

    & > * {
        width: 100%;
    }
`

const Map = styled.div`
    height: calc(100% - 80px);
    display: flex;
    gap: 2%;
    justify-content: center;
    flex-wrap: wrap;
    flex-direction: row-reverse;
    max-width: 100%;
`

const StyledSelect = styled(Select)`
    width: 200px;

    .Select__control {
        border: 1px solid ${({inputNull}) => (inputNull ? Colors.textMuted : Colors.bluePurple)} !important;
        border-radius: 20px !important;
        cursor: pointer;
        background-color: ${({inputNull}) => (inputNull ? 'white' : Colors.bluePurple)} !important;
        box-shadow: none;
        opacity: 1 !important;

        .Select__placeholder {
            color: #0000008A !important;
            opacity: 1 !important;
        }

        .Select__single-value {
            color: white !important;
        }

        .Select__indicator {
            color: ${({inputNull}) => (inputNull ? Colors.textMuted : 'white')} !important;
        }
    }

    .Select__control:hover, .Select__control--menu-is-open {
        background-color: white !important;
        border-color: ${Colors.bluePurple} !important;

        .Select__placeholder {
            color: ${Colors.bluePurple} !important;
        }

        .Select__single-value {
            color: ${Colors.bluePurple} !important;
        }

        .Select__indicator {
            color: ${Colors.bluePurple} !important;
        }
    }

    .Select__menu, Select__menu-list, Select__option {
        box-shadow: none;
        background-color: white;
        color: #0000008A !important;
    }

    .Select__option--is-focused, .Select__option--is-selected {
        background-color: ${Colors.bluePurple} !important;
        color: white !important;
    }

    .Select__indicator-separator {
        display: none;
    }
`

const StyledMapContainer = styled(MapContainer)`
    height: 100%;
    width: 100%;
    z-index: 96;

    .leaflet-control {
        z-index: 400;
    }

    .leaflet-top, .leaflet-bottom {
        z-index: 400;
    }
`

const StyledLoadingButton = styled(LoadingButton)`
    position: absolute;
    z-index: 100;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
`

const BlurFilter = styled.div`
    position: absolute;
    height: 100%;
    width: 100%;
    z-index: 98;
    backdrop-filter: blur(5px);
`

const MapWrapper = styled.div`
    position: relative;
    max-height: 100%;
    min-height: 300px;
    flex-grow: 4;
    z-index: 1200;
    min-width: 300px;
    display: flex;
`

const SidePanel = styled.div`
    min-height: 100%;
    flex-basis: 0;
    display: flex;
    flex-direction: column;
    row-gap: 24px;
`

const Title = styled.div`
    font-weight: bold;
`

const Container = styled.div`
    flex-basis: 0;
    display: flex;
    flex-direction: column;
    flex-grow: 6;
    min-width: 500px;
    max-height: 100%;
    min-height: 300px;
`

const StyledPagination = styled(Pagination)`
    & > ul {
        align-items: center;
        justify-content: center;
    }
`

const ChangePosition = ({
  page,
  setPage,
  disableMap,
  spe,
  type,
  teamType,
  structureType,
  searchName,
  markers,
  skillId
}) => {
  const dispatch = useDispatch();
  const reverseGeocoding = useSelector(state => state.geoloc.reverseGeocoding)
  const [filters, setFilters] = useState({limit: 10, page: page})
  const abortControllerReferenceDoctors = useRef();

  const fetchStructuresList = () => {
    dispatch(fetchStructures({...filters, type: structureType, all: true}, abortControllerReferenceDoctors));
  }

  const fetchTeamsList = () => {
    dispatch(fetchTeams({...filters, type: teamType, specialization: spe}, abortControllerReferenceDoctors));
  }

  const fetchSkillsList = () => {
    dispatch(fetchSkillPossessors(skillId, {...filters}, abortControllerReferenceDoctors));
  }

  const mapEvent = useMapEvents({});
  const map = useMap();

  useEffect(() => {
    if (markers && markers.length > 0) {
      mapEvent.fitBounds(markers)
    }
  }, [markers])

  useEffect(() => {
    if (disableMap == true) {
      mapEvent.removeControl(mapEvent.zoomControl)
      mapEvent.dragging.disable()
      mapEvent.scrollWheelZoom.disable()
      mapEvent.touchZoom.disable()
      mapEvent.boxZoom.disable()
      mapEvent.doubleClickZoom.disable()
      mapEvent.keyboard.disable()
    } else {
      mapEvent.addControl(mapEvent.zoomControl)
      mapEvent.dragging.enable()
      mapEvent.scrollWheelZoom.enable()
      mapEvent.touchZoom.enable()
      mapEvent.boxZoom.enable()
      mapEvent.doubleClickZoom.enable()
      mapEvent.keyboard.enable()
    }
  }, [disableMap])

  useEffect(() => {
    mapEvent.zoomControl.remove()
    dispatch(fetchSpecializations())
    dispatch(fetchStructureTypes())
  }, [])

  useEffect(() => {
    setPage(1)
  }, [reverseGeocoding, spe, teamType, structureType, skillId]);

  useEffect(() => {
    if (reverseGeocoding.latitude) {
      map.setView({'lat': reverseGeocoding.latitude, 'lng': reverseGeocoding.longitude}, 12)
    }
  }, [reverseGeocoding]);

  useEffect(() => {
    switch (type) {
      case 'structure':
        fetchStructuresList();
        break;
      case 'team':
        fetchTeamsList();
        break;
      case 'skill':
        fetchSkillsList();
        break;
    }
  }, [filters, reverseGeocoding, spe, teamType, structureType, searchName, type, skillId])

  useEffect(() => {
    setFilters({...filters, page: page})
  }, [page])

  return null
}

function useQuery() {
  const {search} = useLocation();

  return React.useMemo(() => new URLSearchParams(search), [search]);
}

/**
 * Instant search components
 */


// Custom result for list component
function CustomHits(props) {

  return (
    <>
      {props.type == 'doctor' &&
        <DoctorListWithRef match={props.match} idSelected={props.idSelected} ref={props.refs}/>
      }
      {props.type == 'skill' &&
        <DoctorAndTeamBySkillListWithRef match={props.match} idSelected={props.idSelected} ref={props.refs}/>
      }
    </>
  )
}

function CustomPagination(props) {
  let {
    currentRefinement,
    nbPages,
    refine,
  } = usePagination(props);

  return <>
    {
      nbPages > 1 &&
      <StyledPagination
        onChange={(e, page) => {
          refine(page -1);
          props.searchClient.clearCache()
        }}
        color='primary'
        page={currentRefinement +1}
        count={nbPages - 1}
        defaultPage={1}
      />
    }
  </>
}

function RefreshWidget(props) {
  const {refresh} = useInstantSearch();

  useEffect(() => {
    refresh();
  }, [props.query, props.reverseGeocoding]);

  return <></>
}

export const NearMeInstantSearch = ({match}) => {
  const [page, setPage] = useState(1);
  const reverseGeocodingLoading = useSelector(state => state.loading.geolocAroundMe);
  const dispatch = useDispatch();

  const specializations = useSelector(state => state.content.specializations);
  const structureTypes = useSelector(state => state.content.structureTypes);
  const [options, setOptions] = useState({});
  const [optionsStructures, setOptionsStructures] = useState([]);
  const profileAuth = useSelector((state) => state.auth);
  const [structureType, setStructureType] = useState(null);
  const favoritesDoctors = useSelector(state => state.doctors.favorite);
  const [spe, setSpe] = useState(match.params.doctorId);
  const skillId = match.params.skillId;
  let query = useQuery().get('name');

  const sk = useMemo(() => {
    const searchAttributes = spe ? ['specialization_id^5','idSpecialiteV1'] :
      ['first_name^8', 'last_name^8','name','nomExercice', 'prenomExercice', 'libelleCivilite', 'libelleProfession', 'codeCivilite'];
    return new ElasticSearchService().createSk(searchAttributes, spe ? spe : query);
  }, [query, spe]);

  const [type, setType] = useState();
  const params = useParams();

  useEffect(() => {
    if (match.path.includes('structures')) {
      setType('structure')
    } else if (match.path.includes('teams')) {
      setType('team')
    } else if (match.path.includes('skills')) {
      setType('skill')
    } else {
      setType('doctor')
    }
  }, [match])

  useEffect(() => {
    if (type === 'team') {
      setOptions(specializations.map(spe => ({value: spe.id, label: spe.attributes.name})))
    }
  }, [specializations, type])

  useEffect(() => {
    if (type === 'structure') {
      setOptionsStructures(structureTypes.map(type => ({value: type.id, label: type.attributes.type})))
    }
  }, [structureTypes, type])


  const doctors = useSelector(state => state.doctors.list);
  const structures = useSelector(state => state.structures.list);
  const teams = useSelector(state => state.teams.list);
  const doctorsAndTeams = useSelector(state => state.skills.list);
  const env = window.env.APP_ENV;

  const [idSelected, setIdSelected] = useState(null);

  const reverseGeocoding = useSelector(state => state.geoloc.reverseGeocoding);

  const [disableMap, setDisableMap] = useState(true);

  const refs = useRef([]);

  const [teamType, setTeamType] = useState(null);

  const handleClick = id => refs.current.focusRef(id);

  useEffect(() => {
    if (reverseGeocoding.latitude != undefined && reverseGeocoding.longitude != undefined) {
      setDisableMap(false);
    } else {
      setDisableMap(true);
      setIdSelected(null);
    }
  }, [reverseGeocoding]);

  useEffect(() => {
    if (type == 'doctor') {
      setSpe(params.doctorId)
    }
  }, [params])

  const DoctorIcon = new Icon({
    iconUrl: `https://api.geoapify.com/v1/icon?icon=user-md&iconType=awesome&type=awesome&color=%236633ff&size=xx-large&apiKey=${MY_API_KEY}&noShadow&noWhiteCircle`,
    iconSize: [31, 46], // size of the icon
    iconAnchor: [15.5, 42], // point of the icon which will correspond to marker's location
    popupAnchor: [0, -45] // point from which the popup should open relative to the iconAnchor//
  })

  const StructureIcon = new Icon({
    iconUrl: `https://api.geoapify.com/v1/icon?icon=hospital-alt&iconType=awesome&type=material&color=%23f1353a&size=xx-large&apiKey=${MY_API_KEY}&noShadow&noWhiteCircle`,
    iconSize: [31, 46], // size of the icon
    iconAnchor: [15.5, 42], // point of the icon which will correspond to marker's location
    popupAnchor: [0, -45] // point from which the popup should open relative to the iconAnchor//
  })

  const [markers, setMarkers] = useState([])

  const getLatLong = (latlong) => (object) => {
    let lat = null;
    let lon = null;
    const attributes = object.attributes
    lat = attributes.location.lat
    lon = attributes.location.lon

    lat && lon ? latlong.push([lat, lon]) : null;
  }

  useEffect(() => {
    if (!disableMap) {
      let latlong = []
      if (type === 'doctor') {
        doctors.forEach(getLatLong(latlong))
      } else if (type === 'team') {
        teams.forEach(getLatLong(latlong))
      } else if (type === 'structure') {
        structures.forEach(getLatLong(latlong))
      } else if (type === 'skill') {
        doctorsAndTeams.forEach(getLatLong(latlong))
      }
      setMarkers(latlong)
    }
  }, [doctors, structures, teams, doctorsAndTeams])

  const MarkerByObject = (object) => {
    if (object.type === 'Doctor' || object.type === 'Annuaire') {
      const {attributes, id} = object
      const lat = attributes.location.lat;
      const lon = attributes.location.lon;
      return (
        lat && lon && <Marker position={[lat, lon]}
                              icon={DoctorIcon}
                              key={id + [lat, lon] + object.type}
                              eventHandlers={{
                                click: () => {
                                  setIdSelected(id)
                                  handleClick(id)
                                },
                              }}>
          <Tooltip>
            <Title>{fullName(attributes.gender, attributes.first_name, attributes.last_name)}</Title>
            {attributes.specialization}
          </Tooltip>
        </Marker>)
    } else if (object.type === 'Structure') {
      const {attributes, id} = object
      const lat = attributes.lat
      const lon = attributes.lon

      return (
        lat && lon && <Marker position={[lat, lon]}
                              icon={StructureIcon}
                              key={id + [lat, lon] + object.type}
                              eventHandlers={{
                                click: () => {
                                  setIdSelected(id)
                                  handleClick(id)
                                },
                              }}>
          <Tooltip>
            <Title>{attributes.name}</Title>
          </Tooltip>
        </Marker>)
    } else if (object.type === 'DoctorTeam') {
      const {attributes, id} = object
      const lat = attributes.lat
      const lon = attributes.lon

      return (
        lat && lon && <Marker position={[lat, lon]}
                              icon={StructureIcon}
                              key={id + [lat, lon] + object.type}
                              eventHandlers={{
                                click: () => {
                                  setIdSelected(id)
                                  handleClick(id)
                                },
                              }}>
          <Tooltip>
            <Title>{attributes.name}</Title>
          </Tooltip>
        </Marker>)
    }
  }

  const searchClient = useMemo(() => {
    const dispatcher = {dispatch: dispatch, fetchAction: fetchDoctorsListSuccess, firstObject: false};
    return new ElasticSearchService(profileAuth).createClient(sk, reverseGeocoding, false, dispatcher);
  }, [reverseGeocoding, sk]);

  return (
    <Page
      label={match.path.includes('concilium') ? 'concilium.new.TE' : match.path.includes('teleconsultation') ? 'concilium.new.TCA' : 'global.doctors' }
      noWrapperPadding
      fullWidth
      onBackIconClick={() => history.goBack()}
    >
      <Content>
        <InstantSearchBar match={match}/>
        <Map>
          <MapWrapper>
            {disableMap &&
              <StyledLoadingButton
                variant='contained'
                loading={reverseGeocodingLoading}
                onClick={() => dispatch(aroundMe())}
              >
                {
                  (!reverseGeocoding || reverseGeocoding.isUserSearch || Object.keys(reverseGeocoding).length <= 1) && (
                    <LocationSearching sx={{marginRight: '5px'}}/>
                  ) || (
                    <MyLocation sx={{marginRight: '5px'}}/>
                  )
                }
                <FormattedMessage id='global.geo.aroundme'/>
              </StyledLoadingButton>
            }
            {disableMap && <BlurFilter/>}
            <StyledMapContainer center={reverseGeocoding.latitude && [43.60, 1.44]}
                                zoom={12}
                                scrollWheelZoom={false}
                                dragging={false}
                                zoomControl={true}
                                touchZoom={false}
                                boxZoom={false}
                                doubleClickZoom={false}
                                keyboard={false}
            >
              <TileLayer
                attribution='Powered by <a href="https://www.geoapify.com/" target="_blank">Geoapify</a> | © OpenStreetMap <a href="https://www.openstreetmap.org/copyright" target="_blank">contributors</a>'
                url={URL_GEOAPIFY}
              />
              <ChangePosition page={page} setPage={setPage} disableMap={disableMap} spe={spe} type={type}
                              teamType={teamType} structureType={structureType} searchName={query} markers={markers}
                              skillId={skillId}/>
              {!disableMap &&
                <MarkerClusterGroup chunkedLoading showCoverageOnHover={false}>
                  {type === 'doctor' && doctors.map(MarkerByObject)}
                  {type === 'structure' && structures.map(MarkerByObject)}
                  {type === 'team' && teams.map(MarkerByObject)}
                  {type === 'skill' && doctorsAndTeams.map(MarkerByObject)}
                </MarkerClusterGroup>
              }
            </StyledMapContainer>
          </MapWrapper>
          <Container>
            <SidePanel>
              <InstantSearch stalledSearchDelay={200} indexName={env+'_professional_v1'}
                             searchClient={searchClient}>
                <Index indexName={'pro_directory'}></Index>
                <CustomHits type={type} match={match} idSelected={idSelected} refs={refs} query={query}
                            setTeamType={setTeamType}/>
                <CustomPagination searchClient={searchClient}/>
                <RefreshWidget query={query} searchClient={searchClient} reverseGeocoding={reverseGeocoding}/>
              </InstantSearch>
            </SidePanel>
          </Container>
        </Map>
      </Content>
    </Page>
  )
}
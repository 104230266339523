import React from 'react'
import PropTypes from 'prop-types'
import {Link, Redirect} from 'react-router-dom'
import styled from 'styled-components'
import { TextButton } from 'common/UiKit/TextButton'
import { Colors } from 'constants/colors'
import { FormattedMessage } from 'react-intl'

const Wrapper = styled.div`
  height: auto;
  color: ${Colors.dark};
  display: flex;
  flex-direction: column;
  align-items: center;
  font-family: Roboto, sans-serif;
  font-size: 16px;
  font-weight: normal;
  line-height: 1.5;
`

const Header = styled.h1`
  margin: 36px 0 0;
  font-size: 24px;
  font-weight: 500;
`

const Logo = styled.img`
  height: 168px;
  width: 168px;
  margin-top: 80px;
`

const Text = styled.div`
  width: 480px;
  margin-top: 24px;
  text-align: center;
  opacity: 0.8;
`

const List = styled.ol`
  counter-reset: myCounter;
  padding-left: 10px;
  margin-top: 24px;

  &>li {
    list-style: none;
    display: flex;
  }

  &>li:before {
    counter-increment: myCounter;
    content: counter(myCounter) ".";
    margin-right: 13px;
  }
`

const Button = styled(TextButton)`
  && {
    height: 40px;
    width: 240px;
    margin-top: 40px;
    font-size: 14px;
    font-weight: 500;
  }
`

export const FillProfileStub = ({
  children,
  image,
  userStatus
}) => (
    <Wrapper>
      userStatus === 'PRE_REGISTRATED' ?
        <Redirect to='/panel/profile/fill' />
        :
          {children}
          <Logo alt='logo' src={image} />
          <Header>
            <FormattedMessage id='profile.fill.title'/>
          </Header>
          <Text>
            <FormattedMessage
                id='profile.stub.title'
                values={{
                  service: <q><FormattedMessage id='global.own.doctor' /></q>,
                }}
            />
          </Text>
          <Link to='/panel/profile/fill'>
            <Button color='primary' variant='extended'>
              <FormattedMessage id='profile.fill' />
            </Button>
          </Link>
    }
    </Wrapper>

)

FillProfileStub.defaultProps = {
  children: null,
}

FillProfileStub.propTypes = {
  children: PropTypes.node,
  image: PropTypes.string.isRequired,
  heading: PropTypes.string.isRequired,
}

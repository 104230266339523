import React, { useEffect, useState } from 'react';
import {
  compose,
  mapProps,
  lifecycle,
} from 'recompose'
import styled from 'styled-components'
import { connect, useDispatch, useSelector } from 'react-redux';
import idx from 'idx'
import { NotActivatedDialog } from "../../../../Auth/Login/NotActivatedDialog";
import { Page } from '../../../../common/Page'
import { PROFILE_FILL_STEPS } from '../../../../../constants/ui'
import { fetchSpecializations } from '../../../../../redux/modules/content'
import {
  editProfile,
  logOut
} from '../../../../../redux/modules/auth'
import {InscriptionStepTwo} from "./InscriptionStepTwo";
import { Stepper } from '../../../../Auth/Stepper'
import {Skeleton} from "@mui/material";

const Screen = styled(Page)`
  min-height: calc(100% - 60px);
  background-color: white;
  overflow-x: hidden;
`

const Wrapper = styled.div`
  margin-top : 20px;
  display: flex;
  flex-direction : column;
  justify-content : space-around;
  margin: auto;
  width: 720px; 
 
  @media screen and (max-width: 800px) {
    width: 99%;
  }
`
const WrapperSkeletonInfo = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  justify-content: center;
`

const FillFormComponent = ({
  initialValues,
  onFormSubmit,
  id,
  logOut,
  employmentType,
  signatures,
}) => {
  const [dialogOpen, setDialogOpen] = useState(false)
  const [loading, setLoading] = useState(false)
  const myUserStatus = useSelector(state => state.auth.attributes.status)
  const dispatch = useDispatch()

  useEffect(() => {
    dispatch(fetchSpecializations())
  }, [dispatch])

  return (
    <Screen
      label='profile.fill'
      onBackIconClick={false}
      id={id}
      onLogout={logOut}
      myUserStatus={myUserStatus}
      fullWidth
      withoutOverflow
    >
      <Wrapper>
        <Stepper/>
        {
          loading
            ?
            <WrapperSkeletonInfo>
              <Skeleton style={{ marginLeft : "auto", marginRight : "auto" }} variant="text" width={200} height={20}/>
              <br/>
              <Skeleton animation="wave" variant="rectangular"  height={270} />
              <br/>
              <Skeleton variant="rectangular"  height={220} />
              <br/>
              <Skeleton animation="wave" variant="rectangular"  height={500} />
              <br/>
            </WrapperSkeletonInfo>
            :
            <InscriptionStepTwo
              initialValues={initialValues}
              id={PROFILE_FILL_STEPS[1]}
              isDoctor
              setDialogOpen={setDialogOpen}
              setLoading={setLoading}
            />
        }
        <NotActivatedDialog
          dialogOpen={dialogOpen}
          onCloseAction={() => setDialogOpen(false)}
        />
      </Wrapper>
     </Screen>
  )
}

const enhance = compose(
  connect((state) => ({
    auth: state.auth,
    id: state.auth.id,
    diplomas: idx(state, _ => _.auth.relationships.diploma.data) || [],
    signatures: [idx(state, _ => _.signature)],
    personalData: {
      date_of_birth: state.auth.attributes.date_of_birth,
      first_name: state.auth.attributes.first_name,
      last_name: state.auth.attributes.last_name,
      gender: state.auth.attributes.gender,
      photo: state.auth.attributes.photo,
    },
    aboutDoctor: {
      specialization_id: state.auth.attributes.specialization_id,
      medical_degree: state.auth.attributes.medical_degree,
      experience: state.auth.attributes.experience || '',
      language: state.auth.attributes.language,
      work_place: state.auth.relationships.workPlace.data.length
        && state.auth.relationships.workPlace.data[0].attributes,
    },
    initialValues: {
      date_of_birth: state.auth.attributes.date_of_birth,
      first_name: state.auth.attributes.first_name,
      last_name: state.auth.attributes.last_name,
      gender: state.auth.attributes.gender,
      photo: state.auth.attributes.photo,
      language: state.auth.attributes.language,
      specialization_id: state.auth.attributes.specialization_id,
      addition_email: state.auth.attributes.addition_email || '',
      work_place: state.auth.relationships.workPlace.data.length
        && [...state.auth.relationships.workPlace.data].pop().attributes,
      credentials_rpps: idx(state.auth, _ => _.attributes.credentials.rpps) || '',
      credentials_adeli: idx(state.auth, _ => _.attributes.credentials.adeli) || '',
      credentials_order_number: idx(state.auth, _ => _.attributes.credentials.orderNumber) || '',
      territorialStructureType: state.auth.attributes.territorialStructureType,
      territorialStructureValue: state.auth.attributes.territorialStructureValue,
      typeOfEstablishmentType: state.auth.attributes.typeOfEstablishmentType,
      typeOfEstablishmentValue: state.auth.attributes.typeOfEstablishmentValue,
    },
    employmentType: state.auth.attributes.type_employment,
    specializations: idx(state, _ => _.content.specializations)
      .filter(el => el && el.attributes && el.id)
      .map(({ attributes: a, id }) => ({ value: id, label: a.name })),
    loading: [
      state.loading.editProfile,
      state.loading.workPlace,
      state.loading.diplomas,
      state.loading.schedule,
    ].some(v => v),
  }), {
    editProfile,
    logOut,
    fetchSpecializations,
  }),
  lifecycle({
    componentDidMount() {
      this.props.fetchSpecializations()
    },
  }),
  mapProps(props => ({
    ...props,
    diplomas: props.diplomas.map(item => (item.attributes
      ? {
        id: item.id,
        file: item.attributes.url,
        extension: idx(item, _ => _.attributes.extension),
      }
      : { id: '0', file: '' }
    )),
    onAboutInfoSubmit: values => props.createWorkPlace({ workPlace: [values.work_place] })
      .then(() => props.editProfile(values, true)),
    onPricesSubmit: values => props.createPrices({
      daily_price: values.daily_price,
      weekend_price: values.weekend_price,
      urgent_price: values.urgent_price,
      requisites_private_bic: values.requisites_private_bic || '',
      requisites_private_iban: values.requisites_private_iban || '',
      requisites_hospital_bic: values.requisites_hospital_bic || '',
      requisites_hospital_iban: values.requisites_hospital_iban || '',
    }),
  })),
)

export const FillForm = enhance(FillFormComponent)

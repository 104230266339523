import React, {useEffect} from "react";
import {useDispatch} from "react-redux";
import {logOut} from "../../../redux/modules/auth";
import {compose} from "recompose";
import {userIsAuthenticated} from "../../../utils/userIsAuthenticated";

const enhance = compose(
  userIsAuthenticated
)

const LogoutComponent = () => {
  const dispatch = useDispatch()

  useEffect(() => {
    dispatch(logOut())
  }, [])

  return (
    <div>
      Déconnexion en cours
    </div>
  )
}

export const Logout = enhance(LogoutComponent)
import React, {useEffect, useState} from 'react'
import styled from 'styled-components';
import {Colors} from '../../constants/colors'
import StructureInfo, {StructureInfosLogo} from './structure/StructureInfo';
import PageWrapper from '../common/Page/PageWrapper';
import TaggedDescription from '../common/TaggedDescription';
import {BottomBar} from '../Auth/BottomBar';
import TagsList from '../common/TagsList';
import {MEDIUMSCREEN_MAX_WIDTH, SMALLSCREEN_MAX_WIDTH, XSMALLSCREEN_MAX_WIDTH} from '../../constants/globalStyles';
import {useDispatch, useSelector} from 'react-redux';
import {loadStructure, loadTeamDetails} from '../../redux/modules/structureInfos';
import {Paper} from '../common/UiKit/Paper';
import { Groups, OpenInNew, Person } from '@mui/icons-material';
import { Box, useMediaQuery } from '@mui/material';
import Button from '../common/UiKit/Button';
import {history} from '../../utils/history';
import {authByToken} from '../../redux/modules/auth';
import {FormattedMessage, useIntl} from 'react-intl';
import {TeamDetailsDialog} from './structure/TeamDetailsDialog';
import {TitledBlock} from './structure/TitledBlock';
import ScrollSpyTabs from '../common/UiKit/ScrollSpyTabs';
import {Card, DoctorCardSimple, TeamCard} from './structure/cards';

function structureAddress(structure) {
  let address = structure?.address// + ', ' + zipcode + ' ' + city
  if (structure?.zipcode) {
    address += ', ' + structure.zipcode
  }
  if (structure?.city) {
    if (!structure?.zipcode) {
      address += ', '
    }
    address += structure.city
  }
  return address;
}

export default ({ location }) => {
  const formatter = useIntl()
  const dispatch = useDispatch()
  const structure = useSelector(state => state.structureInfo?.structure)
  const structureAttr = structure?.attributes
  const structureName = structureAttr?.name || ''
  const addressStructure = structureAddress(structureAttr)
  const tagsPS = useSelector(state => state.structureInfo?.specs?.specializations || [])
  const structureMembers = structureAttr?.members || []

  const pageTabs = [
    {
      text: formatter.formatMessage({ id: 'structure.info.introduction'}),
      anchor: 'structure-introduction'
    },
    {
      text: formatter.formatMessage({ id: 'structure.info.health-professionals'}),
      anchor: 'health-professional'
    },
    {
      text: formatter.formatMessage({ id: 'structure.info.care-teams'}),
      anchor: 'care-teams'
    },
  ]

  const xsmallWidth = useMediaQuery(`(max-width:${XSMALLSCREEN_MAX_WIDTH}px)`)
  const smallWidth = useMediaQuery(`(max-width:${SMALLSCREEN_MAX_WIDTH}px)`)

  const [teamDetails, setTeamDetails] = useState(false)
  const [selectedTeam, setSelectedTeam] = useState(null)
  const onCloseTeamDetails = () => setTeamDetails(false)

  const tagsStructure = [
    formatter.formatMessage({ id: 'consultation.teleexpertise' }),
    formatter.formatMessage({ id: 'consultation.teleconsultation' }),
    formatter.formatMessage({ id: 'schedule.tca' }),
    formatter.formatMessage({ id: 'consultation.telesoins' }),
  ]

  const panels = [
    (<>
      <FormattedMessage id='structure.info.teleexpertise-1' /><br/>
      <FormattedMessage id='structure.info.teleexpertise-2' />
      <CustomLink href='https://www.ameli.fr/medecin/exercice-liberal/telemedecine/teleexpertise'>
        <FormattedMessage id='global.learn-more' />
      </CustomLink>
    </>),
    (<>
      <FormattedMessage id='structure.info.teleconsultation-1' /><br/>
      <FormattedMessage id='structure.info.teleconsultation-2' />
      <CustomLink href='https://www.ameli.fr/assure/remboursements/rembourse/consultations-telemedecine/telemedecine/teleconsultation'>
        <FormattedMessage id='global.learn-more' />
      </CustomLink>
    </>),
    (<>
      <FormattedMessage id='structure.info.teleconsultation-assistee-1' /><br/>
      <FormattedMessage id='structure.info.teleconsultation-assistee-2' />
      <CustomLink href='https://www.ameli.fr/pharmacien/exercice-professionnel/acces-soins/telemedecine-assistance-teleconsultation-officine'>
        <FormattedMessage id='global.learn-more' />
      </CustomLink>
    </>),
    (<>
      <FormattedMessage id='structure.info.telesoins-1' /><br/>
      <FormattedMessage id='structure.info.telesoins-2' /><br/>
      <FormattedMessage id='structure.info.telesoins-3' />
      <CustomLink href='https://www.ameli.fr/infirmier/exercice-liberal/realisation-actes-telesante'>
        <FormattedMessage id='global.learn-more' />
      </CustomLink>
    </>),
  ]

  useEffect(() => {
    if (!!selectedTeam && !!selectedTeam.id) {
      dispatch(loadTeamDetails(selectedTeam.id)).then()
    }
  }, [selectedTeam])

  const currentStructureId = () => {
    const locationRegex = /^\/public\/structure\/([A-Za-z0-9_-]+)/
    if (!location || !locationRegex.test(location.pathname)) {
      console.error("NO STRUCTURE ID PROVIDED", location.pathname)
      history.push('/')
      return null
    } else {
      return locationRegex.exec(location.pathname)[1]
    }
  }

  const onSelectTag = (selectedTag) => {
    const structureId = currentStructureId()
    if (structureId) {
      dispatch(loadStructure(structureId, selectedTag.selected ? null : selectedTag.key))
    }
  }

  const onConnect = () => history.push('/')

  useEffect(() => {
    dispatch(authByToken({}))
    const structureId = currentStructureId()
    if (structureId) {
      dispatch(loadStructure(structureId))
    }
  }, [dispatch])

  return (
    <Wrapper>
      <TopBar>
        {
          (!smallWidth || xsmallWidth) && (
            <LogoWrapper>
              <img alt='CONEX SANTE' src={process.env.PUBLIC_URL + '/logo_conexsante_large_240_31.png'} width='140px' />
            </LogoWrapper>
          )
        }
        {
          !xsmallWidth && (
            <TabsBox>
              <ScrollSpyTabs
                tabsInScroll={pageTabs}
                tabHeight={65}
                tabsAreFixed={true}
                tabStyle={{ textTransform: 'none' }}
              />
            </TabsBox>
          )
        }
        <Button
          style={{ display: 'inline-block', position: 'absolute', top: '15px', right: '15px' }}
          onClick={onConnect}
        >
          <FormattedMessage id={'global.access.platform'} />
        </Button>
      </TopBar>
      {
        !xsmallWidth && (
          <StructureInfoBlock>
            <Paper style={{ padding: '10px' }}>
              <StructureInfo
                logo={structureAttr?.photo}
                name={structureName}
                type={structureAttr?.type?.type || ''}
                address={addressStructure}
                position={structureAttr?.location}
              />
            </Paper>
          </StructureInfoBlock>
        )
      }
      <Content smallscreen={xsmallWidth}>
        <PageStyled style={{ minHeight: '0' }} fullWidth noWrapperPadding={true} white={false}>
          {
            xsmallWidth && (
              <>
                <div style={{ textAlign: 'center' }}>
                  <StructureInfosLogo logo={structureAttr?.photo} name={structureName} />
                </div>
              </>
            )
          }
          <TitleStructure id={pageTabs[0].anchor}>{structureName}</TitleStructure>
          <TaggedDescription
            title={pageTabs[0].text}
            descriptionText={structureAttr?.description || ''}
            tags={tagsStructure}
            panels={panels}
          />
          <TitledBlock
            title={
              <>
                <Person /><span>{pageTabs[1].text} ({structureAttr?.membersNumber || 0})</span>
              </>
            }
            anchor={pageTabs[1].anchor}
          >
            <div style={{ marginLeft: '10px' }}>
              <TagsList
                tags={tagsPS}
                onSelect={onSelectTag}
                selectedColor='white'
                selectedBackColor={Colors.selectedTagGreen}
                textColor={Colors.conexGrey}
                backColor={Colors.tagGreen}
                hoverColor={Colors.tagHover}
              />
            </div>
            <CardsWrapper className='flex-container'>
              {
                structureMembers.map((member, key) => (
                  <DoctorCardSimple
                    key={key}
                    doctor={member}
                  />
                ))
              }

              {/*
              Permet d'éviter que les dernières DoctorCard affichées soient plus grandes que
              celles des premières lignes dans le cas où le nombre de DoctorCard n'arrive pas en bout de ligne
            */}
              <Card />
              <Card />
            </CardsWrapper>
          </TitledBlock>
          {
            !!structureAttr?.teams && structureAttr.teams.length > 0 && (
              <TitledBlock
                title={
                  <>
                    <Groups />{pageTabs[2].text} ({structureAttr.teams.length})
                  </>
                }
                anchor={pageTabs[2].anchor}
              >
                <CardsWrapper>
                  {
                    structureAttr.teams.map((team, key) => (
                      <TeamCard key={key} team={team} onCardClick={(selectedTeam) => {
                        setSelectedTeam(selectedTeam)
                        setTeamDetails(true)
                      }} />
                    ))
                  }
                  <Card />
                  <Card />
                </CardsWrapper>
              </TitledBlock>
            )
          }
        </PageStyled>
        <BottomBar background={'transparent'} />
      </Content>
      <TeamDetailsDialog open={teamDetails} onClose={onCloseTeamDetails} />
    </Wrapper>
  )
}

const CustomLink = ({ href, children }) => (
  <span style={{ verticalAlign: 'middle', display: 'inline-block', margin: '0 5px' }}>
    <a style={{ verticalAlign: 'middle', display: 'inline-block', margin: '0' }} href={href} target='_blank'>
      {children}
    </a>
    <OpenInNew  style={{ verticalAlign: 'middle', height: '16px' }}/>
  </span>
)

// --------------------------------- Styles CSS -------------------------------------

const PageStyled = styled(PageWrapper)`
  margin: 0 auto auto;
  width: 82%;
  min-height: 0;
  overflow: scroll;
  @media (max-width: ${MEDIUMSCREEN_MAX_WIDTH}px) {
    width: 97%;
  }
`

const Wrapper = styled.div`
  padding: 0;
  min-height: 100vh;
  //position:fixed;
  //top:0;
  //bottom:0;
  background-color: #F5F7FB;
`

const TopBar = styled.div`
  && {
    background-color: white;
    box-shadow: rgba(6, 173, 255, 0.1) 0 4px 8px 0;
    width: 100%;
    height: 65px;
    position: fixed;
    top: 0;
    z-index: 1000;
  }
  &&> div {
    vertical-align: bottom;
  }
`

const TabsBox = styled(Box)`
  display: inline-block;
  border-bottom: 1px;
  margin-left: 10%;

  @media (max-width: ${MEDIUMSCREEN_MAX_WIDTH}px) {
    margin-left: 3%;
  }

  @media (max-width: ${SMALLSCREEN_MAX_WIDTH}px) {
    position: absolute;
    bottom: 0;
    left: 1%;
  }
`

const LogoWrapper = styled.div`
  width: 260px;
  text-align: center;
  display: inline-block;
  margin-top: 25px;
  margin-bottom: 20px;
`

const StructureInfoBlock = styled.div`
  position: fixed;
  top: 80px;
  left: 15px;
  width: 260px;
`

const Content = styled.div`
  padding: 70px 0 0 270px;

  @media (max-width: ${XSMALLSCREEN_MAX_WIDTH}px) {
    padding: 65px 0 0 0;
  }
`

const TitleStructure = styled.h2`
  margin: 20px 50px 20px;
  color: ${Colors.dark80};
`

const CardsWrapper = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;

  @media (max-width: ${SMALLSCREEN_MAX_WIDTH}px) {
    flex-direction: column;
  }
`
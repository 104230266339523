import React, {useRef, useEffect, useState} from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import { withStateHandlers } from 'recompose'
import ArrowBack from '@mui/icons-material/ArrowBack'
import IconButton from '@mui/material/IconButton'
import Menu from '@mui/material/Menu'
import MenuItem from '@mui/material/MenuItem'
import MoreVert from '@mui/icons-material/MoreVert'
import { capitalize } from 'lodash'
import { FormattedMessage, useIntl } from 'react-intl'
import { doctorMenuButtons, patientMenuButtons } from '../../../constants/menuButtons'
import { useSelector, useDispatch } from 'react-redux';
import { withRouter, Link } from 'react-router-dom'
import WarningIcon from '@mui/icons-material/Warning';
import InfoIcon from '@mui/icons-material/Info';
import Settings from '@mui/icons-material/Settings';
import Help from '@mui/icons-material/Help';
import { Colors, LEVEL_COLORS } from '../../../constants/colors'
import {connectionMode, logOut, pscLogout} from '../../../redux/modules/auth'
import { NotificationsButton } from '../Page/Notifications'
import {Divider, ListItemIcon} from "@mui/material";
import {Logout} from "@mui/icons-material";
import axios from "axios";
import {createFormsView, fetchHelpWindowView} from "../../../redux/modules/formsView";
import {HELP_WINDOW} from "../../../constants/forms";

const Wrapper = styled.div`
  height: 56px;
  width: 100%;
  box-sizing: border-box;
  padding: 0 24px;
  background-color: ${Colors.white};
  color:  ${Colors.steel};
  display: flex;
  align-items: center;
  font-family: Roboto, sans-serif;
  border-bottom: 0.8px solid ${Colors.borderGrey};
`

const Header = styled.span`
  margin: 0 0 0 15px;
  font-size: 18px;
  font-weight: 500;
  flex-grow: 1;
`


const IconButtonStyled = styled(IconButton)`
  && { margin-left: -20px; }
`

const ButtonStyled = styled(IconButton)`
  && {
    margin-left: 8px;
    color: ${({ isUrlMatched }) =>  isUrlMatched ? Colors.PanelBarBluePurple : Colors.steel};
  }

  &&:hover {
    color: ${Colors.PanelBarBluePurple};
    background-color: white;
  }
`

const TestAccountLabel = styled.div`
  background-color: #00c9a8;
  color: ${Colors.steel};
  padding: 0 24px;
  display: flex;
  align-items: center;
  padding-right: 40px;
  height: 32px;
`

const TestAccountMessage = styled.div`
  padding-left: 8px;
  flex-grow: 2;
  font-size: 12px;
  font-weight: 500;
  color: ${Colors.steel};
`

const HeaderDangerIcon = styled(WarningIcon)`
  font-size: 17px !important;
`

const HeaderInfoIcon = styled(InfoIcon)`
  font-size: 17px !important;
`

const PanelBarComponent = ({
  label,
  onBackIconClick,
  location,
  match,
  history,
  handleMenu,
  handleClose,
  anchorEl,
  noHeaderCapitalize,
}) => {
  const { formatMessage: f } = useIntl()
  const dispatch = useDispatch()
  const userLevel = useSelector(state => (state.auth.type === 'Doctor' ? state.auth.attributes.level : undefined))
  const isDoctor = useSelector(state => state.auth.type === 'Doctor')
  const isTestUser = status === 'TEST';
  const topAnkor = useRef();
  const path = match.path;
  //const tabPath = path.split("/");
  //const currentUrl= !!tabPath && tabPath[tabPath.length - 1];
  useEffect(() => {
    topAnkor.current.scrollIntoView(true);
  }, [location])
  const myUserStatus = useSelector(state => state.auth.attributes.status)
  const ElementCorrespondant =  doctorMenuButtons.find(element => element.path === path)
  const isPscLogon = localStorage.getItem('connectionMode') === connectionMode.psc
  const onLogout = () => {
      localStorage.setItem('idLogout',localStorage.getItem('userId'));
      dispatch(logOut())
  }
  const onPSCLogout = () => dispatch(pscLogout())
  const doctorId = useSelector(state => state.auth.id);
  const isAlreadySeen = useSelector(state => state.formsView.isViewed);

  return (
    <>
      <Wrapper ref={topAnkor} hideBorder={isTestUser} level={userLevel}>
        {onBackIconClick ?
          <IconButtonStyled color='inherit' onClick={onBackIconClick}><ArrowBack /></IconButtonStyled>
          :
          ( ElementCorrespondant &&
            <ElementCorrespondant.icon/>
          )
        }
        {!noHeaderCapitalize ? (
          <Header>
            {capitalize(f({ id: label, defaultMessage: label }))}
          </Header>
        ) : (<Header>{ label }</Header>)}

        {
          myUserStatus !== 'LIMITED' && myUserStatus !== 'PRE_REGISTRATED'&& (
            <>
              <NotificationsButton />
              { isDoctor && <ButtonStyled
                isUrlMatched = {  !!path.match('help') }
                component={Link}
                to='/panel/help'
                onClick={async () => {
                  if (isAlreadySeen === false) {
                    await dispatch(createFormsView(doctorId, HELP_WINDOW))
                        .catch((errors) => {
                          console.log(errors)
                        })
                  }
                }}
              >
                 <Help/>
              </ButtonStyled>}
              <ButtonStyled
                component={Link}
                to='/panel/settings'
                isUrlMatched = {  !!path.match('settings') }
              >
                <Settings />
              </ButtonStyled>
            </>
          )
        }

        {
        onLogout && (
          <>
            <ButtonStyled
              aria-owns={anchorEl ? 'menu-appbar' : undefined}
              aria-haspopup='true'
              onClick={handleMenu}
            >
              <MoreVert />
            </ButtonStyled>
            <Menu
              id='menu-appbar'
              anchorEl={anchorEl}
              anchorOrigin={{
                vertical: 'top',
                horizontal: 'right',
              }}
              transformOrigin={{
                vertical: 'top',
                horizontal: 'right',
              }}
              open={!!anchorEl}
              onClose={handleClose}
            >
              <MenuItem onClick={onLogout}>
                <ListItemIcon><Logout /></ListItemIcon>
                <FormattedMessage id='global.logout' />
              </MenuItem>
              { isPscLogon && ( <Divider /> ) }
              {
                isPscLogon && (
                  <MenuItem onClick={onPSCLogout}>
                    <FormattedMessage id='global.psc.logout' />
                  </MenuItem>
                )
              }
            </Menu>
          </>
        )
      }
      </Wrapper>
      {isTestUser ? (
        <TestAccountLabel>
          <HeaderDangerIcon />
          <TestAccountMessage>You have a TEST profile. </TestAccountMessage>
          <HeaderInfoIcon />
        </TestAccountLabel>
      ) : null}
    </>
  )
}

PanelBarComponent.defaultProps = {
  onBackIconClick: false,
  onIconClick: false,
  anchorEl: null,
}

PanelBarComponent.propTypes = {
  label: PropTypes.string.isRequired,
  onBackIconClick: PropTypes.oneOfType([PropTypes.func, PropTypes.bool]),
  handleClose: PropTypes.func.isRequired,
  handleMenu: PropTypes.func.isRequired,
  anchorEl: PropTypes.object,
}

const enhance = withStateHandlers({
  anchorEl: null,
}, {
  handleMenu: () => e => ({ anchorEl: e.target }),
  handleClose: () => () => ({ anchorEl: null }),
})

export const PanelBar = withRouter(enhance(PanelBarComponent))

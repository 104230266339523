import React from 'react';
import PropTypes from 'prop-types';
import { FormattedHTMLMessage, FormattedMessage } from 'react-intl'
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import Grid from '@mui/material/Grid';
import styled from 'styled-components'
import {BUTTON_COLORS} from "../../../../constants/colors";

const Text = styled(DialogContentText)`
  && {
    h1, strong {
      color: rgb(75,172,198);
      margin-bottom: 16px;
    }
    
    strong {
      display: inline-block;
    }
    
    ul {
      margin: 0;
    }
  }
`

const ButtonAgree = styled(Button)`
  && {
    font-size: 14px;
    font-weight: 500;
    text-transform: none;
    color: #ffffff;
    background-color: ${BUTTON_COLORS.purple};
  }
  &&:hover {
    background-color: ${BUTTON_COLORS.purpleHover};
  }
`

export const ModalPrivacyPolicy = (props) => {
  const {
    open,
    handleClose,
    scroll,
    descriptionElementRef,
  } = props;

  return (
    <div>
      <Dialog
        open={open}
        onClose={() => handleClose('privacy', false)}
        scroll={scroll}
        aria-labelledby='scroll-dialog-title'
        aria-describedby='scroll-dialog-description'
      >
        <DialogTitle id='scroll-dialog-title'>
          <Typography variant='p' align='center' component='span'>
            <FormattedMessage id='global.privacy-policy' />
          </Typography>
        </DialogTitle>
        <DialogContent dividers={scroll === 'paper'}>
          <Text
            id='scroll-dialog-description'
            ref={descriptionElementRef}
            tabIndex={-1}
          >
            <FormattedHTMLMessage id='gdpr.modal.privacy' />
          </Text>
        </DialogContent>
        <DialogActions>
          <Grid justifyContent='center' direction='row' container>
            <ButtonAgree onClick={() => handleClose('privacy', false)} variant='contained' color='primary'>
              <FormattedMessage id='profile.button.iAgree' />
            </ButtonAgree>
          </Grid>
        </DialogActions>
      </Dialog>
    </div>
  );
};

ModalPrivacyPolicy.propTypes = {
  open: PropTypes.bool.isRequired,
  handleClose: PropTypes.func.isRequired,
  scroll: PropTypes.bool.isRequired,
  descriptionElementRef: PropTypes.func.isRequired,
};

import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import { Colors } from 'constants/colors'
import moment from 'moment-timezone'
import {
  useDispatch,
  useSelector,
} from 'react-redux'

import get from 'lodash/get';
import { WeekDayCell } from './Cell'
import { history } from '../../../../../../utils/history'
import {
  getNowPeriod, getPeriodDate, isInFuture,
} from '../../../../../../helpers/calendar'
import { editEvent } from '../../../../../../redux/modules/calendar/list'

export const DayColumn = React.memo(({
  periods,
  day,
  isDayOff,
  withoutHeader,
  confirm,
  data,
  editing,
  onFreeClick,
  selected,
  setEditMenuData,
  concilium,
  cellType,
  available,
}) => {
  const dispatch = useDispatch()
  const formattedDay = day.format('YYYY-MM-DD')
  const isRealPatient = useSelector(state => state.auth.type === 'Patient');
  const isPatient = isRealPatient || concilium;
  const myId = useSelector(state => +state.auth.id)
  const nowPeriod = getNowPeriod()

  return (
    <Wrapper fullWidth={withoutHeader}>
      {!withoutHeader && (
        <FirstCell blue={isDayOff} className='calendar__first-cell'>
          <DayName>{day.format('dddd')}</DayName>
          <DayNumber>{day.format('DD')}</DayNumber>
        </FirstCell>
      )}
      {
        periods.map((period) => {
          const periodFrom = moment(period.from, 'HH:mm').format('HH:mm')
          if (!data || !data[periodFrom]) {
            return (
              <WeekDayCell
                key={period.from}
                isAvailable={false}
                editing={editing}
              />
            )
          }
          const periodData = data[periodFrom]
          const consultation = periodData.type === 'Consultation' ? periodData.data : undefined
          const collection = periodData.type === 'Available' ? periodData.data : undefined
          const AvailableForTC = periodData.type === 'Available' && get(periodData, 'data.actionAvailable.TC', false);
          const AvailableForTE = periodData.type === 'Available' && get(periodData, 'data.actionAvailable.TE', false);
          const upcoming = isInFuture(period, day)
          const disableEdit = !upcoming || (!!consultation && consultation.status === 'completed')
          const isSelected = getPeriodDate(day, period).isSame(moment(selected))

          const onClick = (isReallyUrgent) => {
            if (((isPatient || concilium) && periodData.type === (cellType || 'Available'))
              || (concilium && periodData.type === 'Available' && periodData.data.length === 1)) {
              onFreeClick(day, period, isReallyUrgent)
            } else if (consultation && consultation.status === 'created' && myId !== consultation.creatorDoctor.id) {
              confirm({ ...consultation, day, period })
            } else if (consultation && consultation.id) {
              history.push(`/panel/consultations/${consultation.id}`)
            }
          }

          const onEdit = (e) => {
            e.stopPropagation()
            if (disableEdit) return
            if (consultation) {
              confirm({
                refuse: true,
                ...consultation,
                period,
                day,
              })
            } else if (periodData.type !== 'Empty') {
              dispatch(editEvent(formattedDay, periodFrom, 'Empty', periodData.type))
            } else {
              setEditMenuData({
                el: e.currentTarget,
                day: formattedDay,
                periodFrom,
              })
            }
          }

          return (
            <WeekDayCell
              available={available}
              key={period.from}
              isAvailable={(isRealPatient && AvailableForTC) || (!isRealPatient && AvailableForTE)}
              isUrgent={periodData ? periodData.type === 'FreeUrgent' : false}
              isInFuture={isInFuture(period, day)}
              consultation={consultation}
              onClick={onClick}
              editing={editing}
              disableEdit={disableEdit}
              onEdit={onEdit}
              isPatient={isPatient}
              isSelected={isSelected}
              type={periodData ? periodData.type : null}
              collection={collection}
              dateTime={periodData.date_time}
              myId={myId}
              isNow={nowPeriod === period.from}
            />
          )
        })
      }
    </Wrapper>
  )
})

DayColumn.defaultProps = {
  isDayOff: false,
  withoutHeader: false,
  data: undefined,
  onFreeClick: () => {},
  //isSelected: false,
  concilium: false,
}

DayColumn.propTypes = {
  periods: PropTypes.array.isRequired,
  day: PropTypes.object.isRequired,
  isDayOff: PropTypes.bool,
  withoutHeader: PropTypes.bool,
  confirm: PropTypes.bool.isRequired,
  data: PropTypes.object,
  editing: PropTypes.bool.isRequired,
  onFreeClick: PropTypes.func,
  //isSelected: PropTypes.bool,
  setEditMenuData: PropTypes.func,
  concilium: PropTypes.bool,
}

//#region Styled components
const Wrapper = styled.div`
  width: ${props => (props.fullWidth ? 100 : 12.5)}%;
  position: relative;
`

const FirstCell = styled.div`
  box-sizing: border-box;
  position: sticky;
  top: 0;
  z-index: 10;
  width: 100%;
  border-bottom: 1px solid ${Colors.divider};
  border-right: 1px solid ${Colors.divider};
  background-color: ${Colors.white};
  border-top: 1px solid ${Colors.divider};
  height: 100px;
  padding: 8px 20px;
  display: flex;
  align-items: flex-start;
  justify-content: center;
  flex-flow: column nowrap;
  color: ${Colors.dark80};
  //position: relative;

  &:before {
    content: ${props => (props.blue ? '""' : 'none')};
    position: absolute;
    left: 0;
    right: 0;
    height: 5px;
    border-radius: 2px;
    top: 0;
    background-color: ${Colors.clearBlue};
  }

  & > div:first-of-type {
    color: ${props => (props.blue ? Colors.clearBlue : 'inherit')}
  }
`

const DayName = styled.div`
  font-size: 13px;
  font-weight: 500;
  letter-spacing: -0.1px;
  text-transform: capitalize;
`

const DayNumber = styled.div`
  font-weight: 700;
  font-size: 32px;
  letter-spacing: 0.5px;
`
//#endregion

import {createAction, handleActions} from "redux-actions";
import axios from "axios";

export const mssanteRequest = createAction('MSSANTE_REQUEST')
export const mssanteSuccess = createAction('MSSANTE_SUCCESS')
export const mssanteFailure = createAction('MSSANTE_FAILURE')

export const fetchMSS = (id) => dispatch => {
    dispatch(mssanteRequest())
    return axios.get(`/user/mssante/${id}`)
      .then(res => {
          if(res.data && res.data.data) {
              dispatch(mssanteSuccess(res.data.data))
          } else {
              dispatch(mssanteSuccess([]))
          }
      })
      .catch( err => dispatch(mssanteFailure(err)))

}

export const editMSS = (mssAddresses) => dispatch => {
    dispatch(mssanteRequest())
    return axios.post(`/user/mssante`, {
        mssAddresses
    })
    .then(res => {
        dispatch(mssanteSuccess(res.data.data))
    })
    .catch(err => dispatch(mssanteFailure(err)))
}

// If the software is set it will tell the back to create a new line in the table user_settings
// so we only need it on the first call of this function
export const sendMailToMss = (mss, consultationId, medicalReportId, software = null) => {

  return axios('/mss/send/mail', {
    method: "POST",
    data: {
      mss: mss,
      consultation_id: consultationId,
      medical_report_id: medicalReportId,
      medical_office_software: software,
    }
  })
}

export const mssanteReducer = handleActions({
    [mssanteSuccess]: (state, action) => action.payload,
    [mssanteFailure]: (state, action) => action.payload
}, {})
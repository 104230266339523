import React, {useEffect, useState} from 'react'
import styled from 'styled-components'
import { DiplomasForm } from 'common/DiplomasForm'
import { Paper } from 'common/UiKit/Paper'
import {useDispatch, useSelector} from "react-redux";
import {getCertifiedSignature} from "../../../../../redux/modules/signature";
import idx from "idx";
import { TextButton } from 'common/UiKit/TextButton'
import {Button} from "@mui/material";
import {history} from "../../../../../utils/history";
import AddIcon from "@mui/icons-material/Add";
import {FormattedMessage} from "react-intl";

const PaperStyled = styled(Paper)`
  padding: 0;
`
const CreateBtnStyle = {
    padding: '5px 16px',
    background: '#fff',
    color: '#6633FF',
    boxShadow: 'none',
    border: '#6633FF 2px solid',
    borderRadius: '5px',
    cursor: 'pointer',
    textTransform: 'capitalize',
}

const ButtonBlock = styled.div`
  margin:10px;
  height:55px;
`
export const Signature = ({ signatures, changeButtonPath }) => {
  const dispatch = useDispatch()
  const authId = useSelector(state => state.auth.id);
  const [sigPad, setSigPad] = useState([])
  const [signatureExist, setSignatureExist] = useState(false)

  useEffect(() => {
    dispatch(getCertifiedSignature(authId))
        .then((data) => {
          const signature = [idx(data, _ => _.payload.data.attributes)]
          if(signature[0] != undefined){
            setSigPad(signature)
            setSignatureExist(true)
          }
        })
        .catch((errors) => {
          console.log(errors)
        })
  }, [])

    return (
  <PaperStyled
    heading='profile.signature'
    changeButtonPath={changeButtonPath}
  >
    <div>
      {
          signatureExist ?
              <DiplomasForm
                  diplomas={sigPad.map(item => ({
                    id: item.id,
                    file: item.url,
                    extension: item.extension,
                    image: item.url,
                  }))}
                  id='profile_signature'
                  afterSubmit={() => null}
                  onSubmit={() => null}
                  disableEdit
                  onPaperClick={() => {}}
              />
          :
              <ButtonBlock>
                <Button onClick={() => history.push(changeButtonPath)} variant='contained' color='primary' style={CreateBtnStyle}>
                  <FormattedMessage id='signature.create' />
                </Button>
                <br/>
              </ButtonBlock>

      }
    </div>


  </PaperStyled>
)
}
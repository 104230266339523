import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import idx from 'idx'
import { connect } from 'react-redux'
import { LinkCard } from 'common/UiKit/LinkCard'
import { Colors } from 'constants/colors'
import { FormattedMessage } from 'react-intl'

const Wrapper = styled.div`
  margin: 16px 0 0;

  & > * {
    margin-bottom: 16px;
  }
`

const Heading = styled.header`
  margin: 0;
  font-family: Roboto, sans-serif;
  font-size: 16px;
  padding: 0 24px 10px;
  font-weight: 500;
  color: ${Colors.dark};
  opacity: 0.9;
`

const LinkCardStyled = styled(LinkCard)`
  padding: 12px 16px 12px 24px;
`

const LinksComponent = ({
  images,
  analyses,
  reports,
  others,
  allergies,
  prescriptions,
}) => (
  <Wrapper>
    <Heading>
      <FormattedMessage id='hr.documents' />
    </Heading>
    <LinkCardStyled
      label='profile.attachments.images'
      to='/panel/health-records/attachment?type=images'
      length={images.length}
    />
    <LinkCardStyled
      label='profile.attachments.laboratory_analyses'
      to='/panel/health-records/attachment?type=laboratory_analyses'
      length={analyses.length}
    />
    <LinkCardStyled
      label='hr.reports'
      to='/panel/health-records/medical-reports'
      length={reports.length}
    />
    <LinkCardStyled
      label='profile.attachments.prescriptions'
      to='/panel/health-records/prescriptions'
      length={reports.length + prescriptions.length}
    />
    {/*<LinkCardStyled*/}
    {/*  label='hr.incompatibility'*/}
    {/*  to='/panel/health-records/drug-incompability'*/}
    {/*/>*/}
    <LinkCardStyled
      label='hr.allergies'
      to='/panel/health-records/allergies'
      length={allergies.length}
    />
    <LinkCardStyled
      label='profile.attachments.other'
      to='/panel/health-records/attachment?type=other'
      length={others.length}
    />
  </Wrapper>
)

LinksComponent.defaultProps = {
  images: [],
  analyses: [],
  reports: [],
  others: [],
  allergies: [],
}

LinksComponent.propTypes = {
  images: PropTypes.arrayOf(PropTypes.object),
  analyses: PropTypes.arrayOf(PropTypes.object),
  reports: PropTypes.arrayOf(PropTypes.object),
  others: PropTypes.arrayOf(PropTypes.object),
  allergies: PropTypes.arrayOf(PropTypes.object),
}

const enhance = connect(state => (
  {
    images: idx(state, _ => _.healthRecords.attachment.images),
    analyses: idx(state, _ => _.healthRecords.attachment.laboratory_analyses),
    others: idx(state, _ => _.healthRecords.attachment.other),
    reports: idx(state, _ => _.consultations.list).filter(item => item.attributes.status === 'completed'),
    allergies: idx(state, _ => _.healthRecords.allergies),
    prescriptions: idx(state, _ => _.healthRecords.attachment.prescriptions) || [],
  }
))

export const Links = enhance(LinksComponent)

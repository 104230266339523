import React from 'react';
import PropTypes from 'prop-types';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import { FormattedMessage } from 'react-intl';
import Grid from '@mui/material/Grid';
import { connect } from 'react-redux'
import { logOut } from '../../../../redux/modules/auth'

export const ModalRequestDeletionComponent = (props) => {
  const {
    open,
    handleClose,
    logOut,
  } = props;

  return (
    <div>
      <Dialog
        open={open}
        onClose={() => handleClose('requestDeletion', false)}
        aria-labelledby='scroll-dialog-title'
        aria-describedby='scroll-dialog-description'
      >
        <DialogTitle id='scroll-dialog-title'>
          <Typography variant='p' align='center' component='span'>
            <FormattedMessage id='gdpr.usage.request.csv.deletion' />
          </Typography>
        </DialogTitle>
        <DialogContent>
          <DialogContentText
            id='scroll-dialog-description'
            tabIndex={-1}
          >
            <FormattedMessage id='gdpr.modal.request.deletion' />
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Grid justifyContent='center' direction='row' container>
            <Button onClick={logOut} color='primary'>
              <FormattedMessage id='global.delete' />
            </Button>
            <Button onClick={() => handleClose('requestDeletion', false)}>
              <FormattedMessage id='global.cancel' />
            </Button>
          </Grid>
        </DialogActions>
      </Dialog>
    </div>
  );
};

ModalRequestDeletionComponent.propTypes = {
  open: PropTypes.bool.isRequired,
  handleClose: PropTypes.func.isRequired,
  logOut: PropTypes.func.isRequired,
};

const enhance = connect(state => ({ id: state.auth.id }), { logOut })

export const ModalRequestDeletion = enhance(ModalRequestDeletionComponent);

import React from 'react'
import styled from 'styled-components'
import { FormattedMessage } from 'react-intl'
import { useDispatch, useSelector } from 'react-redux'
// eslint-disable-next-line import/no-extraneous-dependencies
import { TextButton } from 'common/UiKit/TextButton'
import AddIcon from '@mui/icons-material/Add';
import { Search } from '../../../../common/Search'
import { searchConsultations } from '../../../../../redux/modules/settings'
import { history } from '../../../../../utils/history';
import {BUTTON_COLORS} from "../../../../../constants/colors";
import {clearDraftConsultation} from "../../../../../redux/modules/consultations";

const Button = styled(TextButton)`
  box-shadow: none;
  min-width: ${props => (props.isInConciliumMenu ? '250px' : '340px')};
  && {
    width: 80%;
    margin-top: 24px;
    background-color: ${BUTTON_COLORS.purple};
    border: 1px solid #fff;
    color: #fff;
  }
  &:hover{
    background-color: ${BUTTON_COLORS.purpleHover};
    border: 1px solid #fff;
    color: #fff;
  }
`

const Wrapper = styled.div`
  display: flex;
  justify-content: left;
  min-width: 500px;
  margin-left: 10px;
`

const HeaderItem = styled.div`
  z-index: 1;
  margin-right: 6vw;
  & > * > &::last-child {
    margin-right: 5px;
  }
  & > * {
  font-size: 14px;
  text-transform: none;
  }
`

const SearchWrapper = styled.div`
  margin-top: 8px;
  width: 700px;
  & > * {
    border: 1px solid #c5cbdb;
  }
`

export const Header = ({ isInConciliumMenu, filteredList }) => {

  const dispatch = useDispatch()
  const locale = useSelector(state => state.localization)

  return (
    <Wrapper key={locale}>
      <HeaderItem>
        {
          <Button
            onClick={
              () => {
                dispatch(clearDraftConsultation())
                isInConciliumMenu ? history.push('/panel/concilium/main') : history.push('/panel/teleconsultation/main')
              }}
            variant='contained'
            color='primary'
            isInConciliumMenu={isInConciliumMenu}
          >
            <AddIcon fontSize='large'/>
            <FormattedMessage id={isInConciliumMenu ? 'concilium.new.TE' : 'concilium.new.TCA'}/>
          </Button>
        }
      </HeaderItem>
      <SearchWrapper>
          {/*{(filteredList.length !== 0) && (*/}
          {/*  <Search onSearch={value => dispatch(searchConsultations(value))} />)}*/}
      </SearchWrapper>
    </Wrapper>
  );
}

import React, {useEffect, useLayoutEffect, useRef, useState} from 'react'
import {history} from "../../../../utils/history";
import { Page } from '../../../common/Page'
import axios from 'axios';
import get from 'lodash/get';
import idx from "idx";
import {fetchDoctor, toggleFavorite} from "../../../../redux/modules/doctors";
import { DoctorCard } from '../../../common/DoctorCard';
import styled from "styled-components";
import {useDispatch, useSelector} from "react-redux";
import { Item } from './DoctorTeams/ListItemsTeams';
import moment from 'moment-timezone'
import {ListAvailabilities} from "./ListAvailabilities";
import {TextButton} from "../../../common/UiKit/TextButton";
import {FormattedMessage} from "react-intl";
import {BUTTON_COLORS, Colors} from "../../../../constants/colors";
import {fullName} from "../../../../helpers/user";

const Content = styled.div`
    margin-top : 20px;
    position: relative;
    overflow: initial;
`

const Title =styled.h1`
  margin: 0;
  font-size: 24px;
  font-weight: bold;
  color: black;
  margin-top: 50px;
  margin-bottom: 20px;
`

const Button = styled(TextButton)`
  && {
    display: block;
    margin: 0 auto 0;
    // height: 42px;
    font-size: 14px;
    color: #FFFFFF;
    width: 250px;
    background-color: ${({ disabled }) => (disabled ? Colors.grey : BUTTON_COLORS.purple)};
    border-radius: 3px;
    text-transform: none;
    font-weight: 500;
  }
  &&:hover {
    background-color: ${BUTTON_COLORS.purpleHover};
  }
`

const MarginBottom = styled.div`
    margin-top: 10px;
    height: 10px;
`

const DefaultText = styled.div`
    margin-top: 50px;
    margin-bottom: 70px;
    text-align: center;
    opacity: 0.8;
    font-size: 16px;
    font-weight: normal;
    color: ${Colors.blueGrey};
`

const normalizeAvailabilities = function(listAvailabilities) {
  return listAvailabilities.reduce((acc, datetime) => {
    let datetimelocale = moment(datetime).local()
    if(datetimelocale.isValid()) {
      if(!Object.keys(acc).find((e) => e === datetimelocale.format('Y-MM-DD'))){
        acc[datetimelocale.format('Y-MM-DD')] = []
      }
      acc[datetimelocale.format('Y-MM-DD')][datetimelocale.format('HH:mm')] = {date_time: datetimelocale.format('Y-MM-DD HH:mm')}
    }
    return acc
  }, [])
}

export const DoctorDetails  = ({ match }) => {
    const { doctorId } = match.params
    const [teams, setTeams] = useState([]);
    const favoriteToggle = useSelector(state => state.loading.favoriteToggle);
    const dispatch = useDispatch();
    const currentProfile = useSelector(state => state.auth);
    const currentDoctorStatus = get(currentProfile, 'attributes.status', '');
    const isDoctor = get(currentProfile, 'type', '') === 'Doctor';
    const isDoctorDetailsPage = match.path.includes('panel/teleconsultation/specializations/doctor');
    const allowToBookTE = isDoctor || currentDoctorStatus === 'VERIFIED';
    const [listTCA, setListTCA] = useState([]);
    const [listTE, setListTE] = useState([]);
    const [listTC, setListTC] = useState([]);
    const limit = 7
    const [offset, setOffset] = useState(0);
    const [hideButton, setHideButton] = useState(1);
    const isTCA = history.location.pathname.includes('teleconsultation')
    const topAnkor = useRef();
    const doctor = useSelector(state => state.doctors.doctor);
    const answerTime = useSelector(state => idx(state, _ => _.doctors.doctor.attributes.answerMedianTime))

    const loadAvailabilities = async function(doctorId, type, offset, limit, setHideButton) {
      // Agenda
      setHideButton(1)
      const url = `/api/doctor/calendar/listavailabilities/${doctorId}?offset=${offset}&limit=${limit}&type=${type}`;
      const resp = await axios.get(url).then(resp => {
        if (resp.data && Object.keys(resp.data).length>1) {
          let newList;
          //depending on the type of availabilities we set the correct list
          switch (type) {
            case 'TCA':
              newList = _.merge(listTCA, normalizeAvailabilities(Object.values(resp.data.date_times)))
              setListTCA({...newList})
              break;
            case 'TE':
              newList = _.merge(listTE, normalizeAvailabilities(Object.values(resp.data.date_times)))
              setListTE({...newList})
              break;
            case 'TC':
              newList = _.merge(listTC, normalizeAvailabilities(Object.values(resp.data.date_times)))
              setListTC({...newList})
              break;
            default:
              break;
          }
        }
        if (get(resp.data, 'isNext', false)) {
          setHideButton(0)
        }
      }).catch(err => {
        console.warn(err)
      });
    }

  useLayoutEffect(() => {
        if((!match.path.includes("/panel/concilium/specializations/doctor") &&  !match.path.includes("/panel/teleconsultation/specializations/doctor") )|| offset !== 0){
          topAnkor.current.scrollIntoView(false)
        }
    }, [hideButton, teams])

  useEffect(() => {
    dispatch(fetchDoctor(doctorId))
  }, [favoriteToggle])


    useEffect(() => {
        (async function () {
            try {
                // Equipes de soins
                const base = `/api/doctor/team/list/${doctorId}`;
                const resp = await axios
                    .get(`${base}`);
                setTeams(resp.data.data);
            } catch (e) {
                console.warn(e);
            }
        }());
    }, [])

    useEffect(() => {
      if (isDoctor) {
        // Chargement des disponibilités pour la TCA
        loadAvailabilities(doctorId, 'TCA', offset, limit, setHideButton)
        // Chargement des disponibilités pour la TE
        loadAvailabilities(doctorId, 'TE', offset, limit, setHideButton)
      } else {
        // Chargement des disponibilités pour la TC
        loadAvailabilities(doctorId, 'TC', offset, limit, setHideButton)
      }
    }, [offset])

    const onButtonClick = (id, isUrgent, isTCA = false) => (e, proposedDate) => {
        const url = !isDoctor
          ? `/panel/doctors/appointment/create${proposedDate ? `?proposedDate=${proposedDate}` : ''}`
          : `/panel/${isTCA ? 'teleconsultation' : 'concilium'}/create/${id}${isUrgent ? '?urgent=1' : ''}${proposedDate ? `${isUrgent ? '&' : '?'}proposedDate=${proposedDate}` : ''}`
        e.stopPropagation()

        return history.push(url)
    }

  return (
    <Page label={fullName(get(doctor, 'attributes.gender'), get(doctor, 'attributes.first_name'), get(doctor, 'attributes.last_name'))}
          onBackIconClick={history.goBack} noHeaderCapitalize={true}>
      <Content ref={topAnkor}>
        <DoctorCard
          id={doctorId}
          userId={get(doctor, 'attributes.user_id', null)}
          isDoctorDetailsPage={isDoctorDetailsPage}
          firstName={get(doctor, 'attributes.first_name', '')}
          lastName={get(doctor, 'attributes.last_name', '')}
          userStatus={get(doctor, 'attributes.status', '')}
          specialization={get(doctor, 'attributes.specialization',  '')}
          specialization_type={get(doctor, 'attributes.specialization_type', null)}
          price={Number(get(doctor, 'attributes.price.daily_price', 0))}
          isFavorite={get(doctor, 'attributes.favorite', false)}
          onButtonClick={onButtonClick(doctorId, false)}
          onButtonClickTER={onButtonClick(doctorId, true)}
          onButtonClickTCA={onButtonClick(doctorId, false, true)}
          disableButtonClick={!allowToBookTE}
          photo={get(doctor, 'attributes.photo', null)}
          isDoctor={isDoctor}
          status_availability={get(doctor, 'attributes.status_availability', false)}
          location={idx(get(doctor, 'relationships'), _ => _.workPlace.data.slice(-1)[0].attributes)}
          calendarNearestTE={get(doctor, `attributes.calendar_nearest.${isTCA ? 'TCA' : 'TE'}`)}
          buttonText={isDoctor ? 'concilium.other.disponibilities' : 'consultation.make.appointment'}
          toggleFavorite={value => dispatch(toggleFavorite(doctorId, value))}
          isFavoriteFetching={favoriteToggle === doctorId}
          isDoctorDetails={true}
          skills={get(doctor,'relationships.skill.data',null)}
          gender={get(doctor, 'attributes.gender', null)}
          answerTime={get(doctor, 'attributes.answerMedianTime', null)}
        />
        {isDoctor && (
          <>
            <Title>
                <FormattedMessage id={'roso.community.btn'} defaultMessage={'roso.community.btn'}/>
            </Title>
            {teams && teams.length ? (
              <div>
                {teams.map(team => (
                  <Item key={team.id} team={team}/>
                ))}
              </div>
            ) : (<DefaultText> <FormattedMessage id={'doctor.community.none'} defaultMessage={'doctor.community.none'}/>
            </DefaultText>)}
          </>
        )}

        {isDoctor && isTCA && (
          <AvailabilitiesComponent
            title='schedule.availabilities.TCA'
            doctorId={doctorId}
            availabilitiesList={listTCA}
            onButtonClick={onButtonClick}
            hideButton={hideButton}
            setOffset={setOffset}
            offset={offset}
            limit={limit}
            forTCA={true}
          />
        )}
        {isDoctor && !isTCA && (
          <AvailabilitiesComponent
            title='schedule.availabilities.TE'
            doctorId={doctorId}
            availabilitiesList={listTE}
            onButtonClick={onButtonClick}
            hideButton={hideButton}
            setOffset={setOffset}
            offset={offset}
            limit={limit}
          />
        )}

        {!isDoctor && (
          <AvailabilitiesComponent
            title='schedule.availabilities.TC'
            doctorId={doctorId}
            availabilitiesList={listTC}
            onButtonClick={onButtonClick}
            hideButton={hideButton}
            setOffset={setOffset}
            offset={offset}
            limit={limit}
          />
        )}

      </Content>
    </Page>
  )
}

const AvailabilitiesComponent = ({
  title,
  doctorId,
  availabilitiesList,
  onButtonClick,
  hideButton,
  setOffset,
  offset,
  limit,
  forTCA = false,
}) => (
  <div >
    <Title>
      <FormattedMessage id={title} defaultMessage={title}/>
    </Title>
    {availabilitiesList && Object.keys(availabilitiesList).length ? (
      <>
        <div>
          {Object.keys(availabilitiesList).map(
            day => (
              <ListAvailabilities day={moment(day).format('dddd DD MMMM')}
                                  availabilities={availabilitiesList[day]}
                                  onButtonClick={onButtonClick(doctorId, false, forTCA)}></ListAvailabilities>
            )
          )}
        </div>
        {!hideButton ?
          <Button onClick={() => {
              setOffset(offset + limit)
          }} disabled={hideButton}>
              <FormattedMessage id={'schedule.moreAvailabilities'}
                                defaultMessage={'schedule.moreAvailabilities'}/>
          </Button> : <div></div>}
      </>
    ) : (<DefaultText><FormattedMessage id={'schedule.noAvailabilities'}
                                        defaultMessage={'schedule.noAvailabilities'}/> </DefaultText>)}
    <MarginBottom></MarginBottom>
  </div>
)
import React from 'react'
import axios from 'axios'
import { Dialog, DialogContent, DialogTitle } from '@mui/material'
import { FormattedMessage } from 'react-intl'
import { Field, Form } from 'react-final-form'
import { Input } from 'common/UiKit/Input'
import { TextButton } from 'common/UiKit/TextButton'
import { TextField } from 'common/UiKit/TextField'
import styled from 'styled-components'
import { useDispatch } from 'react-redux'
import PhoneInput from 'react-phone-number-input';
import { setNotification } from '../../../../redux/modules/notifications'

const InputComponent = React.forwardRef((props, ref) => <InputStyled {...props} inputRef={ref} />)

const PhoneField = ({ input, meta }) => (
  <PhoneNumberInput
    {...input}
    international
    defaultCountry='FR'
    value={input.value}
    onChange={(v) => {
      input.onChange(v || '+')
    }}
    inputComponent={InputComponent}
    displayInitialValueAsLocalNumber
    numberInputProps={{
      autoFocus: true,
      color: 'primary',
      error: meta.touched && meta.error,
      helperText: meta.touched && meta.error,
    }}
    limitMaxLength
  />
)
 
export const ShareModal = ({ open, type, onClose }) => {
  const dispatch = useDispatch()
  const onSubmit = data => axios(`/doctor/referral/share/${type}`, {
    method: 'POST',
    data,
  })
    .then(() => {
      dispatch(setNotification({ title: 'status.share.success', type: 'success' }))
    })
    .finally(() => {
      onClose()
    })

  return (
    <Dialog open={open} onClose={onClose}>
      <DialogTitle>
        <FormattedMessage id='status.share' />
      </DialogTitle>
      <DialogContent>
        <Form
          onSubmit={onSubmit}
          render={({ handleSubmit, submitting, values }) => (
            <form onSubmit={handleSubmit}>
              <Field
                name={type === 'email' ? 'email' : 'phoneNumber'}
                component={type === 'email' ? TextField : PhoneField}
                label={<FormattedMessage id={type === 'email' ? 'auth.email' : 'global.phone'} />}
                type={type === 'email' ? type : 'text'}
                autoFocus
              />
              <Button
                color='primary'
                isFetching={submitting}
                disabled={submitting || !(values.email || values.phoneNumber)}
                type='submit'
              >
                <FormattedMessage id='global.send' />
              </Button>
            </form>
          )}
        />
      </DialogContent>
    </Dialog>
  )
}

const Button = styled(TextButton)`
  && {
    font-size: 14px;
    font-weight: 500;
    height: 42px;
    min-width: 60%;
    margin: 0 auto;
    display: block;
  }
`

const PhoneNumberInput = styled(PhoneInput)`
  margin-bottom: 24px;
`

const InputStyled = styled(Input)`
  && {
    margin-bottom: 0;
  }
`

import styled from "styled-components";
import {history} from "../../../utils/history";
import ArrowBack from "@mui/icons-material/ArrowBack";
import {useSelector} from "react-redux";
import {isConciliumCheck} from "../../../helpers/consultations";
import {CONSULTATION_TYPES} from "../../../constants/consultation";
import {Colors} from "../../../constants/colors";
import React from "react";
import IconButton from "@mui/material/IconButton";
import idx from "idx";
export const RecipientInfo = ({
  firstName,
  lastName,
  specialization,
}) => {
    const consulationType = useSelector(state => state.consultations.consultation.attributes.type)
    const isConcilium = isConciliumCheck(consulationType);
    const isTC = (consulationType === CONSULTATION_TYPES.scheduled || consulationType === CONSULTATION_TYPES.urgent);
    const isTERESS = consulationType === CONSULTATION_TYPES.TER_ESS;
    const isDoctor = useSelector(state => state.auth.type === 'Doctor')
    const authId = useSelector(state => state.auth.id)
    const doctorId = useSelector(state => idx(state, _ => _.consultations.consultation.relationships.doctor.data.id))

    let civilite

    if (isTC) {
      if (isDoctor) {
        civilite = useSelector(state => idx(state, _ => _.consultations.consultation.relationships.patient.data.attributes.gender))
      } else {
        civilite = useSelector(state => idx(state, _ => _.consultations.consultation.relationships.doctor.data.attributes.gender))
      }
    } else {
      if(isTERESS){
        civilite = '';
      } else if (authId === doctorId) {
        civilite = useSelector(state => idx(state, _ => _.consultations.consultation.relationships.additionalMember.data[0].attributes.doctor.gender))
      } else {
        civilite = useSelector(state => idx(state, _ => _.consultations.consultation.relationships.doctor.data.attributes.gender))
      }
    }

    return (
        <Wrapper>
            <BackButton color='inherit' onClick={() => history.goBack()}>
                <ArrowBack />
            </BackButton>
            <Info>
                {
                    <WrapperInfo>
                      <WrapperName>
                        <Civilite>
                          {selectGenderOrCivility(civilite)} &nbsp;
                        </Civilite>
                        <Name>
                          {firstName || 'Name'}

                          &nbsp;
                          {lastName || ' Surname'}
                        </Name>
                      </WrapperName>
                      {specialization && (
                          <Specialization>{specialization}</Specialization>
                      )}
                    </WrapperInfo>
                }
            </Info>
        </Wrapper>
    )

}

const selectGenderOrCivility = (additionalMemberGender) => {
  switch (additionalMemberGender) {
    case('doctor'):
      return 'Dr. '
    case('professor'):
      return 'Pr. '
    case('woman'):
      return 'Mme. '
    case('male'):
      return 'M. '
    default:
      return additionalMemberGender
  }

}

const Wrapper = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  font-family: Roboto, sans-serif;
`

const Info = styled.div`
  align-items: center;
  flex-grow: 1;
  padding-top: 5px;
  margin-left: 16px;
  cursor: ${props => (props.onClick ? 'pointer' : 'default')};
`

const WrapperInfo = styled.div`
  display: block;
  flex-flow: wrap;
`

const WrapperName = styled.div`
  display: flex;
`

const Name = styled.h1`
  margin: 0;
  font-size: 18px;
  font-weight: 500;
  color: ${Colors.dark};
  line-height: 1.33;
`

const Civilite = styled.h1`
  margin: 0;
  font-size: 18px;
  font-weight: 500;
  color: ${Colors.dark};
  line-height: 1.33;
`

const Specialization= styled.h1`
  margin: 0;
  font-size: 18px;
  font-weight: 500;
  color: ${Colors.blackBg};
  line-height: 1.33;
`

const BackButton = styled(IconButton)`
  && {
    margin: 0;
    color: ${Colors.blueGrey};
  }
`
import React, {useEffect, useState} from "react";
import styled from "styled-components";
import {CircularProgress, Skeleton} from "@mui/material";
import PropTypes from "prop-types";

const OverlayDiv = styled.div`
    position: absolute;
    z-index: 1;
    width: 100%;
    height: 100%;
    text-align: center;
`


const CustomLoaderOverlay = ({isShow, isCircularLoader = true, maxSecondAllowed = 20}) => {
    const [seconds, setSeconds] = useState(0);

    useEffect(() => {
        const interval = setInterval(() => {
            setSeconds(seconds => seconds + 1);
        }, 1000);
        if(seconds > maxSecondAllowed || !isShow) clearInterval(interval);
        return () => clearInterval(interval);
    }, []);

    return (
        isShow & seconds < maxSecondAllowed
        ? (
            <OverlayDiv>
                {isCircularLoader
                    ? (<CircularProgress />)
                    : (<Skeleton/>)
                }

            </OverlayDiv>
        )
        : (<></>)
    )
}

CustomLoaderOverlay.propTypes = {
    isShow : PropTypes.bool,
    isCircularLoader : PropTypes.bool,
    maxSecondAllowed : PropTypes.number,
}

export default  CustomLoaderOverlay
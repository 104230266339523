import React from 'react';
import AsyncSelect from 'react-select/async';
import axios from 'axios';
import get from 'lodash/get';
import {FormattedMessage, useIntl} from 'react-intl';
import styled from 'styled-components';
import {Colors} from '../../../../constants/colors';
import ListItem from "@mui/material/ListItem";
import ListItemText from "@mui/material/ListItemText";
import parse from 'autosuggest-highlight/parse'
import match from 'autosuggest-highlight/match'
import {startCase, toLower} from "lodash";
import ListItemAvatar from "@mui/material/ListItemAvatar";
import Avatar from "@mui/material/Avatar";

const getLabel = (name, specialization, photo, id) => {
    return (
        <ListItem disableGutters key={id} style={{alignItems: 'flex-start'}}>
          <ListItemAvatar>
            <Avatar src={photo} />
          </ListItemAvatar>
            <ListItemText
                primary={
                  <div>
                    {startCase(toLower(name))} {secondaryText(specialization)}
                  </div>
                }
            />
        </ListItem>
    )
}

const highlightSearchInput = (input, ...words) => {
  return (
      <div>
          {words.map((word, index) => (
              highlightWord(word, input)
          ))}
      </div>
  )
}

const HighlightedWord = styled.span`
  font-weight: ${props => props.highlighted ? '700' : '400'};
  color: ${props => props.highlighted ? Colors.bluePurple : 'unset'};
`

const highlightWord = (value, input) => {
    const matches = match(value, input, { insideWords: true });
    const parts = parse(value, matches);
    return (
        <span>
            {parts.map((part, index) => (
              <HighlightedWord highlighted={part.highlight} key={index}>
                {part.text}
              </HighlightedWord>
            ))}
        </span>
    )
}

const secondaryText = (text) => {
  return (
      <>
          <span style={{color: 'grey'}}> - {text}</span>
      </>
  )
}

const formatToValues = (values) => {
  if (Array.isArray(values)) {
    return values.map(opt => ({
      value: opt.attributes.user_id,
      label: getLabel(opt.attributes.full_name, opt.attributes.specialization, opt.attributes.photo, opt.attributes.user_id),
    }))
  }
}

const loadOptions = (inputValue, callback, requestUrl, val, listMembersAtCreation, paths) => {
    if (inputValue && inputValue.length && inputValue.length >= 3) {
        axios(`${requestUrl}${inputValue}`)
            .then((result) => {
                const values = get(result, 'data.data', []);
                    callback(formatToValues(values, paths, inputValue))
            })
            .catch(err => console.warn(err))
    } else {
        callback([]);
    }
};

export const AsyncSelectContact = ({
                                     requestUrl,
                                     valuePath,
                                     labelPath,
                                     input,
                                     rsStyles = {},
                                     barcketsPath = false,
                                     meta,
                                     noMarginError,
                                     placeholder,
                                     imagePath,
                                     cacheOptions = true,
                                     defaultOptions,
                                     defaultInputValue,
                                     val,
                                     listMembersAtCreation,
                                     noOptionsTxt,
                                     onChange
                                   }) => {
  const { formatMessage: f } = useIntl()
  
  defaultOptions = formatToValues(defaultOptions)

  return (
      <>
          <AsyncSelect
              key='input-select'
              styles={{
                ...rsStyles,
                container: provided => ({...provided, '&': {zIndex: 1500}}),
                dropdownIndicator: provided => ({...provided, '&': {display: 'none'}}),
                indicatorSeparator: provided => ({...provided, '&': {display: 'none'}}),
                valueContainer: provided => ({...provided}),
                control: provided => ({
                  ...provided,
                  '&': {
                    height: '64px',
                    borderWidth: '0px',
                    boxShadow: 'none'
                  },
                  '&:hover': {
                    boxShadow: 'none',
                    cursor: 'text'
                  },
                  '&:focus-within': {
                    borderWidth: '0px',
                    boxShadow: 'none',
                    cursor: 'text'
                  }
                })
              }}
              placeholder={f({ id: placeholder || 'global.search' })}
              cacheOptions={cacheOptions}
              loadOptions={(inputValue, callback) => (
                  loadOptions(inputValue, callback, requestUrl, val, listMembersAtCreation, {
                      valuePath, labelPath, barcketsPath, imagePath,
                  })
              )}
              /*defaultValue={defaultInputValue}*/
              defaultOptions={defaultOptions}
              onChange={onChange}
              controlShouldRenderValue
              noOptionsMessage = { noOptionsTxt ? (() => f({id: noOptionsTxt})) : (() => null)}
          />
      </>
    )
}

import React from 'react'
import styled from 'styled-components'
import { useIntl } from 'react-intl'
import { LinkCard } from 'common/UiKit/LinkCard'
import { DialogWindow } from 'common/UiKit/DialogWindow'
import { useSelector } from 'react-redux'
import { AboutDoctor } from './AboutDoctor'
import { Diplomas } from './Diplomas'
import { Signature } from './Signature'
import { UserInfo } from '../../../../common/UserInfo'
import { EmailsManagement } from './EmailsManagement'
import {SkillsDoctor} from "./SkillsDoctor";
import {ExternalPartners} from "./ExternalPartners";
import {Roso} from "./Roso";

const Wrapper = styled.div`
  padding-bottom: 30px;

  & > div {
    margin-bottom: 16px;
  }
`

const LinkCardStyled = styled(LinkCard)`
  padding: 12px 16px 12px 24px;
  margin-bottom: 16px;
`

export const ProfileInfo = ({
  attributes: {
    user_id,
    date_of_birth,
    email,
    country,
    experience,
    first_name,
    gender,
    language,
    last_name,
    photo,
    specialization,
    requisites,
    credentials,
    phoneNumber,
    price,
  },
  workPlace,
  diplomas,
  verified,
  dialogWindow,
  changeButtonPaths,
  onDiplomasClick,
  onAppointmentButtonClick,
  reviewsCount,
  reviewsLink,
  signatures,
  isPatient,
}) => {
  const { formatMessage } = useIntl()
  // eslint-disable-next-line
  const isMe = useSelector(state => state.auth.attributes.user_id === user_id)
  return (
    <Wrapper>
      {
        dialogWindow && (
          <DialogWindow
            title='Your Data sent'
            content='Reply will be sent within 72 hours.'
            cacelButtonText='ok'
            open={!verified}
          />
        )
      }
      <UserInfo
        email={email}
        dateOfBirth={date_of_birth}
        firstName={first_name}
        lastName={last_name}
        photo={photo}
        gender={gender}
        changeButtonPath={changeButtonPaths.personal_data}
        heading='profile.personal'
        onAppointmentButtonClick={onAppointmentButtonClick}
        phoneNumber={phoneNumber}
        specialization={specialization}
      />
      {!isPatient && (
        <LinkCardStyled
          label={formatMessage({ id: 'profile.reviews' })}
          to={reviewsLink}
          length={reviewsCount}
          hideArrow
        />
      )}
      <AboutDoctor
        specialization={specialization}
        experience={experience}
        country={country}
        // eslint-disable-next-line
        language={language}
        // eslint-disable-next-line
        work_place={workPlace}
        changeButtonPath={changeButtonPaths.about_doctor}
        credentials={credentials}
        requisites={requisites}
        price={price}
      />
      <SkillsDoctor/>
      {
        isMe && (
          <Diplomas
            onPaperClick={onDiplomasClick}
            diplomas={diplomas}
            changeButtonPath={changeButtonPaths.diplomas}
          />
        )
      }
      { isMe && (<Roso locked={!verified} />) }
      {!!signatures && !!signatures.length && (
        <Signature
          signatures={signatures}
          changeButtonPath={changeButtonPaths.signature}
        />
      )}
      <EmailsManagement
        changeButtonPath={changeButtonPaths.emailManagement}
      />
      <ExternalPartners
        verified={verified}
      />
    </Wrapper>
  )
}

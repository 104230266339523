import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import styled from 'styled-components'
import { Link } from 'react-router-dom'
import { Paper } from 'common/UiKit/Paper'
import { Colors } from 'constants/colors'
import { FormattedMessage } from 'react-intl'
//import PlayCircleFilledIcon from '@mui/icons-material/PlayCircleFilled';
import Grid from '@mui/material/Grid'
import get from 'lodash/get'
import { Page } from '../Page'
import { FAQItem } from './FAQItem'
import { AppInfo } from './AppInfo'
import { logOut } from '../../../redux/modules/auth'
import { VideoModal } from './VideoModal'
import { getTutorialList } from '../../../helpers/doctors'
import {history} from "../../../utils/history";
import tutoVideo from "../../../images/panel/doctors/settings/undraw_tutorial_video_re_wepc.svg";
import useCase from "../../../images/panel/doctors/settings/undraw_bookshelves_re_lxoy.svg";
import helpCenterIcon from "../../../images/panel/doctors/settings/HelpCenter.svg";


const PaperStyled2 = styled(Paper)`
  padding: 0px;
  margin-bottom: 16px;
`


const Links = styled.div`
  a {
    margin-bottom: 10px;
  }
`


const NameOfVideo = styled.div` 
  font-size: 18px;
  font-weight: bold;
  line-height: 1.11;
  color: #21293e;
  padding-left: 22px;
  padding-right: 22px;
  padding-bottom: 8px;
`

const Video = styled.video`
  width: 100%    !important;
  height: auto   !important;
  cursor: pointer;
`


const TextStyle = styled.h4`

    font-family: Roboto, sans-serif;
    font-size: 16px;
    font-weight: 400;
    color: black;
    text-align: center;
`


const IconBig = styled.img`
  height: 200px;
`

const IconBigUseCase = styled.img`
  height: 130px;
`

const Card = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  font-family: Roboto, sans-serif;
  font-size: 16px;
  margin: 0 5px;
  padding:  26px;
  border-radius: 8px;
  box-shadow: rgba(0, 0, 0, 0.16) 0px 1px 4px;
  background-color: white;
  height: 179px;
  width: 179px;
  border: 1px solid transparent;
    &:hover {
      & > * {color: #6633FF;}
      box-shadow: rgba(102, 51, 255, 0.7) 0px 2px 4px;
      border: 1px solid #6633FF;
  }
`

const Wrapper = styled.div`
  display: flex;
  justify-content: space-around;
  align-items: center;
`

const LinkStyled = styled.div`
  cursor: pointer;
`



export const MainPageComponent = ({
  id,
  logOut,
  faq,
  match,
  onBackIconClick,
}) => {
  const [videoModalOpen, setVideoModalOpen] = useState('');
  const [tutorials, setTutorials] = useState([]);

  useEffect(() => {
    getTutorialList()
      .then(result => setTutorials(result))
      .catch(err => console.log(err))
  }, [])

  const openCGU = () => {
    window.open("https://conexsante.com/cgu/", '_blank', 'noopener,noreferrer');
  }

  const urlUseCases = 'https://conexsante.notion.site/Cas-d-usage-034494818033434d92db68d546628830'
  const urlTutos = 'https://conexsante.notion.site/Tutos-bb79301683e444eb9541c218253c944a'
  const urlCenter = 'https://conexsante.notion.site/0db1710bb8834435aaafbe5a32eb4f5c?v=ef9d097fc2a04d049796882c1352eb50'

  return (
    <Page
      label='global.help'
      onLogout={logOut}
      id={id}
      onBackIconClick={onBackIconClick}
    >
      <AppInfo />
      <Wrapper>
        <LinkStyled onClick={() => { window.open(urlCenter, "_blank") }}>
          <Card>
            <IconBigUseCase src={helpCenterIcon} />
            <TextStyle><FormattedMessage id='panel.help.center' /></TextStyle>
          </Card>
        </LinkStyled>
        <LinkStyled onClick={() => window.open(urlTutos, "_blank")}>
          <Card>
            <IconBig src={tutoVideo} />
            <TextStyle><FormattedMessage id='panel.help.tuto' /></TextStyle>
          </Card>
        </LinkStyled>
        <LinkStyled onClick={() => { window.open(urlUseCases, "_blank") }}>
          <Card>
            <IconBigUseCase src={useCase} />
            <TextStyle><FormattedMessage id='panel.help.use.case' /></TextStyle>
          </Card>
        </LinkStyled>
      </Wrapper>

      <Grid container spacing={2}>
        {tutorials.map((el) => {
          /* eslint-disable */
          const custom_name = get(el, 'attributes.custom_name', '');
          const url = get(el, 'attributes.url', '');
          return (
            <Grid item key={custom_name} sm={4}>
              <PaperStyled2>
                <Video onClick={() => setVideoModalOpen(url)} width='229'>
                  <source src={url} type='video/mp4' />
                  <track src='captions_en.vtt' kind='captions' srcLang='en' label='english_captions' />
                  Your browser doesnt support HTML5 video tag.
                </Video>
                <NameOfVideo>{custom_name}</NameOfVideo>
              </PaperStyled2>
            </Grid>
            /* eslint-enable */
          )
        })}
      </Grid>
    </Page>
  ) 
}

MainPageComponent.propTypes = {
  id: PropTypes.string.isRequired,
  logOut: PropTypes.func.isRequired,
  faq: PropTypes.arrayOf(PropTypes.object).isRequired,
  match: PropTypes.object.isRequired,
  onBackIconClick: PropTypes.oneOfType([PropTypes.bool, PropTypes.func]).isRequired,
}

const enhance = connect(state => ({ id: state.auth.id }), { logOut })

export const MainPage = enhance(MainPageComponent)

import {createAction, handleActions} from "redux-actions";
import axios from "axios";
import {combineReducers} from "redux";
import {toggleFavoriteSuccess} from "./doctors";
import {toggleFavoriteTeamSuccess} from "./teams";

export const fetchSkillPossessorsRequest = createAction("FETCH_SKILL_POSSESSORS_REQUEST");
export const fetchSkillPossessorsSuccess = createAction("FETCH_SKILL_POSSESSORS_SUCCESS");
export const fetchSkillPossessorsFailure = createAction("FETCH_SKILL_POSSESSORS_FAILURE");
export const fetchPaginatorSuccess = createAction('FETCH_SKILL_POSSESSORS_PAGINATOR_SUCCESS')

export const fetchSkillPossessors = (skillId, filters, abortController = {}) => (dispatch, getStore) => {
  dispatch(fetchSkillPossessorsRequest(filters))

  // abort previous request if not finished
  if(abortController.current){
    abortController.current.abort()
  }
  abortController.current = new AbortController();

  const params = {
    include: 'workPlace,structures',
    ...filters,
  }

  const location = getStore().geoloc.reverseGeocoding;
  if (location) {
    params.latitude = location.latitude;
    params.longitude = location.longitude;
  }

  return axios(`/api/general/skill/${skillId}/possessors`, {
    params,
    signal: abortController.current.signal,
    method: 'GET',
  })
    .then((res) => {
      dispatch(fetchSkillPossessorsSuccess(res.data.data))
      dispatch(fetchPaginatorSuccess(res.data.paginator))
    })
    .catch((e) => {
      if(!abortController.current.signal.aborted) {
        dispatch(fetchSkillPossessorsFailure(e))
      }
    })

}

export const clearSkillsList = createAction('CLEAR_SKILLS_LIST')

export const clearSkills = () => (dispatch) => {
  dispatch(clearSkillsList())
}

const list = handleActions({
  [fetchSkillPossessorsSuccess]: (state, action) => action.payload,
  [toggleFavoriteSuccess]: (state, action) => state.map((item) => {
    if (item.id === action.payload.id && item.type === action.payload.type) {
      return {
        ...item,
        attributes: { ...item.attributes, favorite: action.payload.attributes.favorite },
      }
    }
    return item
  }),
  [toggleFavoriteTeamSuccess]: (state, action) => state.map((item) => {
    if (item.id === action.payload.id && item.type === action.payload.type) {
      return {
        ...item,
        attributes: { ...item.attributes, favorite: action.payload.attributes.favorite },
      }
    }
    return item
  }),
  [clearSkillsList]: () => [],
}, [])

const paginator = handleActions({
  [fetchPaginatorSuccess]: (state, action) => action.payload,
  [clearSkillsList]: () => null,
}, [])

export const skills = combineReducers({
  paginator,
  list,
})
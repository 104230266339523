import React, { useEffect} from 'react';
import { Field, Form } from 'react-final-form'
import styled, {css} from 'styled-components'
import { FormattedMessage, useIntl } from 'react-intl'
import { TextField } from '../../common/UiKit/TextField'
import { TextButton } from 'common/UiKit/TextButton'
import {history} from "../../../utils/history";
import {useDispatch, useSelector} from "react-redux";
import { auth } from '../../../redux/modules/auth'
import { SearchMultipleSelect } from '../../common/AsyncSelect/SearchMultiple'
import {BUTTON_COLORS} from "../../../constants/colors";

const GreyStyle = styled.h4`
 && {
    margin-right: auto;
    margin-left: auto;
    font-weight: 500;
    font-family: Roboto, sans-serif;
    color: #A6A39E;
    font-size: 16px;
    margin-bottom: 0;
  }
`

const Button = styled(TextButton)`
  box-shadow: none;
  min-width: ${props => (props.isInConciliumMenu ? '250px' : '340px')};
  background-color: white;
  color: #6633FF;
  border: 2px solid #6633FF;
  font-size: 14px;
  text-transform: none;
  font-weight: 500;
  
  && {
    margin-top: 24px;
  }
`


const TitlePurple = styled.h5`
  margin-right: auto;
  margin-left: auto;
  color: #6633FF;  
  font-weight: 500;
  font-size: 14px;
  &&:hover {
    cursor : pointer;
  }
`

const WrapperRppsDisclaimer = styled.div`
  display : flex;
  flex-direction : column;
  justify-content: center;
  align-items: center;
`
const Wrappform = styled.form`
  display: grid;
  grid-template-rows: ${({localRppsValueProps}) => localRppsValueProps ? css`60px 70px 140px 60px 40px 60px` : '60px 70px 60px 40px 60px'};
`




const ButtonLogin = styled(TextButton)`

  && {
    height: 40px;
    width: 180px;
    margin-right: auto;
    margin-left: auto;
    margin-top: 10px;
    text-transform: none;
    font-size: 14px;
    font-weight: 500;
    background-color: ${BUTTON_COLORS.purple};
    color: #fff;
  }
  &&:hover {
    background-color: ${BUTTON_COLORS.purpleHover};
    color: #fff;
  }
  &&:hover {
    background: ${BUTTON_COLORS.purpleHover};
  }`


export const YesNoAdeliRPPS = ({
   buttonProps = {},
   }) => {
  const { formatMessage } = useIntl()
  const dispatch = useDispatch()
  const f = id => formatMessage({ id })
  const rppsSearchLoading = useSelector(_ => _.loading.rppsAdeliSearch)
  const authLoading = useSelector(_ => _.loading.auth)
  const localRppsValue = useSelector(_ => _.authRppsAction?.errorRppsAdeli )

  const onSubmit = (values) => {
    dispatch(auth(values, false))
  }

  const validate = (values) => {
    const errors = {}
    if (!values.rpps_adeli) {
      errors.rpps_adeli = <FormattedMessage id='error.rpps.required' />
    } else if (!/^[A-zÀ-ú0-9 -]+$/.test(values.rpps_adeli)) {
      errors.rpps_adeli = <FormattedMessage  id='error.field_rpps' />
    }
    return errors
  }

  useEffect(() => {
  }, [localRppsValue])

  return (
    <Form
      onSubmit={onSubmit}
      validate={validate}
      mutators={{
        setRppsInput: (args, state, utils) => {
          utils.changeValue(state, 'rpps_adeli', () => {
            return args[0];
          });
        },
      }}
      render={({ handleSubmit, submitting, form }) => (
        <Wrappform onSubmit={handleSubmit} localRppsValueProps={localRppsValue}>
          <h3>
            <FormattedMessage id='global.enter.name.rpps.adeli' />
          </h3>
          <SearchMultipleSelect
            input={{
              value: "",
              onChange:form.mutators.setRppsInput,
            }}
            createDefaultField
            multiline={true}
            cacheOptions={false}
            autoFocus
            placeholder='auth.rpps'
            noOptionsTxt='auth.register.search.none'
            name='members'
            valuePath='id'
            noMarginError
            labelPath={[
              'firstname',
              'name',
              'job',
              'identifier',
              'town',
              'zip',
            ]}
            requestUrl='/doctor/register/search?rpps_adeli='
            styles={{height: '56px'}}
          />
          <Field
            name='rpps_adeli'
            component={TextField}
            onChange={() => form.mutators.submitRpps(form)}
            style={{display: 'none'}}
          />
          { localRppsValue &&
          <WrapperRppsDisclaimer>
            <p
              style ={{
                color: "red",
              }}>
              <FormattedMessage id='auth.number.given.already.exist' />
            </p>
              <FormattedMessage id='auth.please.verify.your.number.or' />
              <a
                style={{
                  backgroundColor: "transparent",
                  border: "none",
                  cursor: "pointer",
                  overflow: "hidden",
                  outline: "none",
                }}
                onClick={() =>{
                  localStorage.removeItem('rpps_adeli')
                  history.push('registration-mode/classic-sign-in')
                }}>
                <TitlePurple><FormattedMessage id='auth.follow.registration' /></TitlePurple>
              </a>
          </WrapperRppsDisclaimer>
          }
            <ButtonLogin
            color='primary'
            variant='extended'
            isFetching={rppsSearchLoading || authLoading}
            {...buttonProps}
            type='submit'
            >
              <FormattedMessage id='auth.valider' />
            </ButtonLogin>
          <GreyStyle>OU</GreyStyle>
          <Button
          onClick={() =>{
            localStorage.removeItem('rpps_adeli')
            history.push('registration-mode/classic-sign-in')
          }
          }>
          <TitlePurple><FormattedMessage id='auth.continue.without.rpps' /></TitlePurple>
          </Button>
        </Wrappform>
      )}
    >
    </Form>
  )
}

import styled from "styled-components";
import React, {useState} from "react";
import PropTypes from "prop-types";
import {Dialog, DialogContent, DialogTitle} from "@material-ui/core";
import {Colors} from "../../../../../../../constants/colors";
import DialogActions from "@mui/material/DialogActions";
import {FormattedMessage} from "react-intl";
import ClearIcon from "@mui/icons-material/Clear";
import {TextButton} from "../../../../../../common/UiKit/TextButton";
import {useSelector} from "react-redux";
import idx from "idx";
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";

export const DialogSendMss = ({
  open,
  onClose,
  onConfirm,
  error,
  isSending
}) => {

  const userSettingSoftware = useSelector(state => idx(state, _ => _.settings.values.medical_office_software))
  const softwares = useSelector(state => idx(state, _ => _.medicalOfficeSoftwares.list))
  const [selectedSoftware, setSelectedSoftware] = useState(null);

  const MenuProps = {
    disableScrollLock: true,
    PaperProps: {
      style: {
        maxHeight: 'none',
        width: 400
      },
    },
  }

  return (
    <DialogStyled
      open={open}
      onClose={onClose}
      maxWidth='lg'
      PaperProps={{
        style: {
          minWidth: '25%',
          maxWidth: '40%',
          border: `1px solid ${Colors.bluePurple}`,
          padding: '10px',
        }
      }}
      BackdropProps={{
        style: {
          opacity: 0,
        }
      }}
    >
      <>
        <StyledDialogTitle disableTypography>
          <StyledTitle>
            <h3>
              <FormattedMessage id="consultation.send.mss.dialog.title"/>
            </h3>
          </StyledTitle>
          <StyledClearIcon onClick={onClose}><ClearIcon/></StyledClearIcon>
        </StyledDialogTitle>
        <DialogContentStyled>
          <Paragraph>
            <FormattedMessage id="consultation.send.mss.explanation"/>
          </Paragraph>
          {!userSettingSoftware && (
            <ParagraphOfficeSoftware>
              <StyledHead>
                <FormattedMessage id="consultation.select.medical.software"/>
              </StyledHead>
              <DivSelect>
                <SelectStyled
                  id='medical_office_software'
                  value={selectedSoftware}
                  MenuProps={MenuProps}
                  onChange={e => setSelectedSoftware(e.target.value)}
                >
                  { softwares &&
                    softwares.map(software => {
                      return (
                        <MenuItem
                          key={software.attributes.software_id}
                          value={software.attributes.software_id}
                        >
                          {software.attributes.name}
                        </MenuItem>
                      )
                    })
                  }
                </SelectStyled>
              </DivSelect>
            </ParagraphOfficeSoftware>
          )}
          {error && (
            <ErrorMessage>
              <FormattedMessage id ="consultation.send.mss.error"/>
            </ErrorMessage>
          )}
        </DialogContentStyled>
        <DialogActionsStyled>
          <TextButton disabled={!selectedSoftware && !userSettingSoftware} isFetching={isSending} variant='contained' color='secondary' onClick={() => {
            if (!selectedSoftware) {
              onConfirm();
            } else {
              onConfirm(selectedSoftware);
            }
          }}>
            <FormattedMessage id="global.send" values={{br: <br />}}/>
          </TextButton>
        </DialogActionsStyled>
      </>
    </DialogStyled>
  )
}

DialogSendMss.propTypes = {
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  onConfirm: PropTypes.func.isRequired,
  authPSCToken: PropTypes.string,
  error: PropTypes.bool
}

export const DialogStyled = styled(Dialog)`
  backdrop-filter: blur(3px);
`

const StyledDialogTitle = styled(DialogTitle)`
  font-family: Roboto, sans-serif;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 20px;
  font-weight: 500;
  opacity: 0.9;
  color: ${Colors.dark80};
  border-bottom: 1px solid ${Colors.cloudyBlue};  
`

const StyledClearIcon = styled.div`
  cursor: pointer;
`

const StyledTitle = styled.div`
  margin: auto;
`

const DialogContentStyled = styled(DialogContent)`
  display: flex;
  flex-direction: column;
  justify-content: center;
`

const DialogActionsStyled = styled(DialogActions)`
  display: flex;
  justify-content: center;
  align-items: center;
`
const Paragraph = styled.div`
  white-space: pre-line;
  padding: 20px 0;
`

const ParagraphOfficeSoftware = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  white-space: pre-line;
  padding: 20px 0;
`

const DivSelect = styled.div`
  padding-left: 20px;
  width: 70%;
`

const SelectStyled = styled(Select)`
  width: 100%;
`

const StyledHead = styled.div`
  font-weight: bold;
`

const ErrorMessage = styled.div`
  padding: 20px 0;
  color: ${Colors.red};
  display: flex;
  justify-content: center;
  text-align: center;
`


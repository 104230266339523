import React from 'react'
import SearchIcon from '@mui/icons-material/Search'
import styled from 'styled-components'
import { Colors } from 'constants/colors'
import { useIntl } from 'react-intl'

export const Search = ({
  onSearch,
  value = false,
  placeholder = 'global.search',
  defaultValue,
  disabled=false
}) => {
  const { formatMessage } = useIntl()

  return (
    <Wrapper>
      <SearchIcon />
      <Input
        defaultValue={defaultValue}
        disabled={disabled}
        {...(value === '' ? { value } : {})}
        onChange={e => onSearch(e.target.value)}
        placeholder={formatMessage({ id: placeholder })}
      />
    </Wrapper>
  )
}

const Wrapper = styled.div`
  position: relative;
  margin-top: 16px;
  margin-bottom: 16px;
  width: 100%;
  & > svg {
    position: absolute;
    left: 20px;
    top: 12px;
    fill: ${Colors.bluePurple};
  }
`

const Input = styled.input`
  border: none;
  background-color: white;
  outline: none;
  height: 48px;
  padding: 16px 16px 16px 65px;
  box-sizing: border-box;
  width: 100%;
  font-size: 16px;
  font-weight: 500;
  color: ${Colors.dark};
  
  ::placeholder {
    color: ${Colors.blueGrey};
    font-size: 18px;
    font-weight: 500;
  }
  
`

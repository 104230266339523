import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import { Link } from 'react-router-dom'
import { Paper } from 'common/UiKit/Paper'
import IconButton from '@mui/material/IconButton'
import KeyboardArrowRight from '@mui/icons-material/KeyboardArrowRight'
import { FormattedMessage, useIntl } from 'react-intl'

const PaperStyled = styled(Paper)`
  padding: 24px 12px 24px 24px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  cursor: pointer;
`

const Heading = styled.h1`
  margin: 0;
  font-size: 18px;
  opacity: 0.8;
  line-height: 1.11;
  font-weight: bold;
`

const Text = styled.p`
  margin: 9px 0 0;
  opacity: 0.6;
  line-height: 1.63;
`

const LinkStyled = styled(Link)`
  width: 100%;
`

export const FAQItem = ({
  heading,
  text,
  id,
  path,
}) => {
  const { formatMessage } = useIntl()
  return (
    <LinkStyled to={`${path}/faq${id}`}>
      <PaperStyled>
        <div>
          <Heading>
            <FormattedMessage id={heading} />
          </Heading>
          <Text>{`${formatMessage({ id: text }).slice(0, 80)}...`}</Text>
        </div>
        <IconButton color='primary' size='large'>
          <KeyboardArrowRight />
        </IconButton>
      </PaperStyled>
    </LinkStyled>
  );
}

FAQItem.propTypes = {
  heading: PropTypes.string.isRequired,
  text: PropTypes.string.isRequired,
  id: PropTypes.number.isRequired,
  path: PropTypes.string.isRequired,
}

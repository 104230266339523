import React, {useEffect, useImperativeHandle, useRef, useState} from 'react'
import {compose, withProps} from 'recompose'
import styled from 'styled-components'
import idx from 'idx'
import get from 'lodash/get'
import {connect, useDispatch, useSelector} from 'react-redux';
import {DoctorCard} from '../DoctorCard'
import {fetchDoctor, fetchFavoriteDoctors, toggleFavorite,} from '../../../redux/modules/doctors'
import {inviteDoctor} from '../../../redux/modules/consultations'
import {history} from '../../../utils/history'
import {getDoctorPrice} from '../../../helpers/consultations'
import {Colors} from "../../../constants/colors";
import {EmptyScreen} from "../../Panel/Patient/Doctors/EmptyScreen";
import {DoctorAnnuraireCard} from "../DoctorCard/DoctorAnnuaireCard";
import {fetchInvitations, inviteProfessional} from "../../../redux/modules/proInvite";
import {InviteProfessionalDialog} from "../../Panel/Doctor/Professional/InviteProfessionalDialog";
import {useIntl} from "react-intl";

const Content = styled.div`
    margin: 0 auto;
    min-width: 100%;
    overflow-y: scroll;
`

const DoctorWrapper = styled.div`
    & > * {
        border: ${props => (props.outlined ? `2px solid ${Colors.bluePurple}` : '')};
        margin-bottom: 24px;
    }
`

const usePrevious = (value) => {
  const ref = useRef();
  useEffect(() => {
    ref.current = value;
  });
  return ref.current;
}

export const DoctorListComponent = ({
  onButtonClick,
  onButtonClickTER,
  onButtonClickTCA,
  doctors,
  favoriteToggle,
  toggleFavorite,
  match,
  isDoctor,
  idSelected,
  forwardedRef,
}) => {
  const {formatMessage} = useIntl()
  const f = (id, params = {}) => formatMessage({id: id}, params)
  const currentProfile = useSelector(state => state.auth);
  const isTCA = history?.location?.pathname?.includes('teleconsultation');
  const currentDoctorStatus = get(currentProfile, 'attributes.status', '');
  const isUrlPathConcilium = match.path.includes('concilium');
  const allowToBookTE = currentProfile.type !== 'Doctor' || currentDoctorStatus === 'VERIFIED';
  const favoritesDoctors = useSelector(state => state.doctors.favorite);
  const user = useSelector(state => state.auth.attributes)
  const [inviteDialogOpen, setInviteDialogOpen] = useState(false)
  const [selectedDoctor, setSelectedDoctor] = useState(null)
  const dispatch = useDispatch();

  const refs = useRef({})

  useEffect(() => {
    if (!favoritesDoctors?.length){
      dispatch(fetchFavoriteDoctors());
    }
    dispatch(fetchInvitations())
  }, []);

  /**
   * Returns a link depending on the type of consultation chosen and the user profil (doctor/patient)
   * @param id doctor id
   * @returns string
   */
  const selectLink = (id) => {
    if (isDoctor) {
      if (isTCA) {
        return `/panel/teleconsultation/doctor/${id}`
      } else {
        return `/panel/concilium/doctor/${id}`
      }
    } else {
      return `/panel/doctors/doctor/${id}`
    }
  }

  useImperativeHandle(forwardedRef, () => ({
    focusRef: (id) => {
      refs.current[id].scrollIntoView({
        behavior: 'smooth',
        block: 'start',
      });
    }
  }));

  const onSendInvite = (formValues) => {
    dispatch(inviteProfessional(selectedDoctor.id, formValues))
      .then(() => {
        setInviteDialogOpen(false)
        dispatch(fetchInvitations())
      })
      .catch(err => {
        console.error(err)
      })
  }

  const invitationMessage =
    f('invitation.message.1', {firstName: selectedDoctor?.first_name })
    + '\n\n'
    + f('invitation.message.2')
    + '\n\n'
    + f('invitation.message.3', {
      senderFirstName: user?.first_name || '',
      senderLastName: user?.last_name || ''
    })

  return (
    <>
      {
        doctors.length ? (
            <Content>
              <InviteProfessionalDialog
                open={inviteDialogOpen}
                onClose={() => setInviteDialogOpen(false)}
                onSendInvite={onSendInvite}
                firstName={selectedDoctor?.first_name || ''}
                lastName={selectedDoctor?.last_name || ''}
                initialMessage={invitationMessage}
              />
              {doctors?.map(({attributes, id,}) => (
                <DoctorWrapper key={id} ref={ref => (refs.current[id] = ref)} outlined={idSelected === id}>
                  {attributes.type === 'Doctor' ? (
                      <DoctorCard
                        key={id}
                        id={id}
                        userId={attributes.user_id}
                        isUrlPathConcilium={isUrlPathConcilium}
                        status_availability={attributes.status_availability}
                        userStatus={attributes.userStatus}
                        onButtonClick={onButtonClick(id, false)}
                        onButtonClickTER={onButtonClickTER(id, true)}
                        onButtonClickTCA={onButtonClickTCA(id, false)}
                        disableButtonClick={!allowToBookTE}
                        firstName={attributes.first_name}
                        lastName={attributes.last_name}
                        experience={attributes.experience}
                        specialization={attributes.specialization}
                        specialization_type={attributes.specialization_type}
                        photo={attributes.photo}
                        language={attributes.language}
                        location={{...attributes.workplace, lat: attributes.location.lat, lon: attributes.location.lon}}
                        distance={attributes.distance}
                        available={idx(attributes, _ => _.calendar_nearest.TC)}
                        isFavorite={favoritesDoctors.some((doctor) => doctor.id === id)}
                        isFavoriteFetching={favoriteToggle === id}
                        toggleFavorite={value => toggleFavorite(id, value)}
                        buttonText={isDoctor ? 'concilium.other.disponibilities' : 'consultation.make.appointment'}
                        isDoctor={isDoctor}
                        calendarNearestTE={isDoctor ? get(attributes, `calendar_nearest.${isTCA ? 'TCA' : 'TE'}`, false) : get(attributes, 'calendar_nearest.TC', false)}
                        price={getDoctorPrice(attributes, 'daily_price')}
                        link={selectLink(id)}
                        gender={attributes.gender}
                        answerTime={attributes.answerMedianTime}
                      />
                    ) :
                    attributes.type === 'Annuaire' ? (
                        <DoctorAnnuraireCard
                          key={id}
                          doctorAnnuaire={attributes}
                          userId={attributes.user_id}
                          isUrlPathConcilium={isUrlPathConcilium}
                          status_availability={attributes.status_availability}
                          disableButtonClick={!allowToBookTE}
                          firstName={attributes.prenomExercice}
                          lastName={attributes.nomExercice}
                          experience={attributes.experience}
                          specialization={attributes.specialization}
                          specialization_type={attributes.specialization_type}
                          photo={attributes.photo}
                          language={attributes.language}
                          location={attributes.location}
                          workplace={attributes.workplace}
                          distance={attributes.distance}
                          isFavorite={!!attributes.favorite}
                          isFavoriteFetching={favoriteToggle === id}
                          toggleFavorite={value => toggleFavorite(id, value)}
                          isDoctor={isDoctor}
                          gender={attributes.gender}
                          onInvite={() => {
                            setSelectedDoctor(attributes)
                            setInviteDialogOpen(true)
                          }}
                          checkInvited={true}
                        />
                      ) :
                      (<EmptyScreen/>)}
                </DoctorWrapper>
              ))}
            </Content>
          ) :
          <EmptyScreen/>
      }
    </>
  )
}

const enhance = compose(
  connect(
    (state, props) => ({
      doctors: props.withUrgentToggle
        ? state.doctors.list.filter(doctor => doctor.id !== state.auth.id)
        : state.doctors.list,
      favoriteToggle: state.loading.favoriteToggle,
      isDoctor: state.auth.type === 'Doctor',
    }),
    {
      fetchDoctor,
      toggleFavorite,
      inviteDoctor,
    },
    null,
    {forwardRef: true}
  ),
  withProps(props => ({
    onButtonClick: (id, isUrgent) => (e, proposedDate) => {
      const url = !props.isDoctor
        ? `/panel/doctors/${props.match.params.consultationType}/create${proposedDate ? `?proposedDate=${proposedDate}` : ''}`
        : `/panel/concilium/create/${id}${isUrgent ? '?urgent=1' : ''}${proposedDate ? `${isUrgent ? '&' : '?'}proposedDate=${proposedDate}` : ''}`
      e.stopPropagation()

      if (props.isDoctor) {
        if (props.match.params.consultationId) {
          props.inviteDoctor(id, props.match.params.consultationId)
          return history.push('/panel/consultations/session')
        }

        return history.push(url)
      }

      return props.fetchDoctor(id)
        .then(() => history.push(url))
    },
    onButtonClickTCA: (id, isUrgent) => (e, proposedDate) => {
      const url = !props.isDoctor
        ? `/panel/doctors/appointment/create${proposedDate ? `?proposedDate=${proposedDate}` : ''}`
        : `/panel/teleconsultation/create/${id}${proposedDate ? `?proposedDate=${proposedDate}` : ''}`
      e.stopPropagation()
      if (props.isDoctor) {
        if (props.match.params.consultationId) {
          props.inviteDoctor(id, props.match.params.consultationId)
          return history.push('/panel/consultations/session')
        }
        return history.push(url)
      }

      return props.fetchDoctor(id)
        .then(() => history.push(url))
    },
    onButtonClickTER: (id, isUrgent) => (e, proposedDate) => {
      const url = !props.isDoctor
        ? `/panel/doctors/${props.match.params.consultationType}/create${proposedDate ? `?proposedDate=${proposedDate}` : ''}`
        : `/panel/concilium/create/${id}${isUrgent ? '?urgent=1' : ''}${proposedDate ? `${isUrgent ? '&' : '?'}proposedDate=${proposedDate}` : ''}`
      e.stopPropagation()

      if (props.isDoctor) {
        if (props.match.params.consultationId) {
          props.inviteDoctor(id, props.match.params.consultationId)
          return history.push('/panel/consultations/session')
        }

        return history.push(url)
      }

      return props.fetchDoctor(id)
        .then(() => history.push(url))
    },
    onToggleUrgent: (value) => {
      const isAdditional = !!props.match.params.consultationId
      const immediate = !props.isDoctor ? {tciimmediate: 1} : {immediate: 1};
      props.fetchDoctors(+props.match.params.doctorId,
        isAdditional, value ? immediate : {})
    },
  }))
)

export const DoctorList = enhance(DoctorListComponent)

export const DoctorListWithRef = React.forwardRef(({...props}, ref) => <DoctorList {...props} forwardedRef={ref}/>)

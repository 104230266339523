import {createAction, handleActions} from 'redux-actions'
import {combineReducers} from 'redux'
import axios from 'axios'
import idx from 'idx'
import moment from 'moment-timezone'
import { MessageCreator } from '../../helpers/consultations'
import { socket } from '../../services/socket'
import {chatConnectAction, handleMessageAction} from './chats'
import { history } from '../../utils/history'
import {terminateCall} from "./call";

const opentokDefaultState = {
  params: {},
  session: {},
  messages: [],
}

//#region Actions
export const pushMessageRequest = createAction('PUSH_MESSAGE_REQUEST')
export const pushMessageSuccess = createAction('PUSH_MESSAGE_SUCCESS')
export const pushMessageFailure = createAction('PUSH_MESSAGE_FAILURE')
export const deleteMessages = createAction('DELETE_MESSAGES')

export const pushMessage = data => (dispatch, getStore) => {
  const { session } = getStore().consultations.opentok

  dispatch(pushMessageRequest(data))

  return (
    session.signal({
      type: 'msg',
      data: JSON.stringify(data),
    },
    e => e && pushMessageFailure(e))
  )
}

export const sendQuestionsRequest = createAction('SEND_QUESTION_REQUEST')
export const sendQuestionsSuccess = createAction('SEND_QUESTION_SUCCESS')
export const sendQuestionsFailure = createAction('SEND_QUESTION_FAILURE')

export const sendQuestions = (consultationId, data) => (dispatch, getStore) => {
  dispatch(sendQuestionsRequest())
  const questions = getStore().content.consultationQuestions
  const answers = data.map((answer, idx) => ({ id: (questions[idx] || {}).id, answer }))
    .filter(item => !!item)

  return axios(`/general/consultation/${consultationId}/question`, {
    method: 'POST',
    data: {
      question: answers,
    },
  })
    .then(() => dispatch(sendQuestionsSuccess()))
    .catch((e) => {
      dispatch(sendQuestionsFailure(e))
    })
}

export const fetchConsultationsListRequest = createAction('FETCH_CONSULTATIONS_LIST_REQUEST')
export const fetchConsultationsListSuccess = createAction('FETCH_CONSULTATIONS_LIST_SUCCESS')
export const fetchConsultationsListFailure = createAction('FETCH_CONSULTATIONS_LIST_FAILURE')

export const fetchConsultations = params => (dispatch) => {
  dispatch(fetchConsultationsListRequest(params))

  const { userType } = localStorage

  if (userType === 'doctor') {
    return axios(`/${userType}/consultation/v3?include=specializations,skills,workPlace,patient,doctor,team,doctorCreator,teamMembers,additionalMember`, { params })
      .then((res) => {
        dispatch(fetchConsultationsListSuccess(res.data))
      })
      .catch((e) => {
        dispatch(fetchConsultationsListFailure(e))
      })
  }

  return axios(`/${userType}/consultation?include=patient,doctor,additionalMember`, { params })
    .then((res) => {
      dispatch(fetchConsultationsListSuccess(res.data))
    })
    .catch((e) => {
      dispatch(fetchConsultationsListFailure(e))
    })
}

export const fetchConsultationRequest = createAction('FETCH_CONSULTATION_REQUEST')
export const fetchConsultationSuccess = createAction('FETCH_CONSULTATION_SUCCESS')
export const fetchConsultationFailure = createAction('FETCH_CONSULTATION_FAILURE')

export const CONSULT_PARAM__DOCTOR = 'doctor'
export const CONSULT_PARAM__TEAM = 'team'
export const CONSULT_PARAM__CREATOR = 'doctorCreator'
export const CONSULT_PARAM__MEMBERS = 'teamMembers'
export const CONSULT_PARAM__PATIENT = 'patient'
export const CONSULT_PARAM__HEALTH_RECORD = 'healthRecord.attachment'
export const CONSULT_PARAM__MEDICAL_REPORT = 'medicalReport'
export const CONSULT_PARAM__DRUGS = 'drugs'
export const CONSULT_PARAM__ADDITIONAL_MEMBER = 'additionalMember'
export const CONSULT_PARAM__RELATED_CONSULTATION = 'relatedConsultation'
export const FETCH_CONSULT_PARAMS_DEFAULT = [
  CONSULT_PARAM__DOCTOR,
  CONSULT_PARAM__TEAM,
  CONSULT_PARAM__CREATOR,
  CONSULT_PARAM__MEMBERS,
  CONSULT_PARAM__PATIENT,
  CONSULT_PARAM__HEALTH_RECORD,
  CONSULT_PARAM__MEDICAL_REPORT,
  CONSULT_PARAM__DRUGS,
  CONSULT_PARAM__ADDITIONAL_MEMBER,
  CONSULT_PARAM__RELATED_CONSULTATION,
]

/**
 * Returns the list with the default parameters and the specified additional parameters
 * @param additionalParameters Parameters to add to the default list
 */
export const consultationFetchParametersAnd = (additionalParameters = []) => {
  return [
    ...FETCH_CONSULT_PARAMS_DEFAULT,
    ...additionalParameters
  ]
}

export const fetchConsultation = (consultationId, type, silent, parameters = null) => dispatch => {
  const id = consultationId
  if (!silent) {
    dispatch(fetchConsultationRequest({
      consultationId, type, silent, id,
    }))
  }

  dispatch(deleteMessages())

  parameters = parameters === null ? FETCH_CONSULT_PARAMS_DEFAULT : parameters
  const params = {
    include: parameters.join(',')
  }

  return axios(`/general/consultation/${id}`, { params })
    .then((res) => {
      dispatch(fetchConsultationSuccess(res.data))
      new MessageCreator(res.data.data, msg => dispatch(pushMessageSuccess(msg)))
        .pushInitialMessages()
    })
    .catch((e) => {
      dispatch(fetchConsultationFailure(e))
      if (e.response && e.response.status === 403) {
        // On redirige vers la liste des consultations si l'accès à la consultation est interdite
        history.push('/panel/consultations')
      }
    })
}

export const doctorConnectRequest = createAction('DOCTOR_CONNECT_REQUEST')
export const doctorConnectSuccess = createAction('DOCTOR_CONNECT_SUCCESS')
export const doctorConnectFailure = createAction('DOCTOR_CONNECT_FAILURE')

export const doctorConnect = () => (dispatch, getStore) => {
  dispatch(doctorConnectRequest())

  const id = idx(getStore(), _ => _.consultations.consultation.id)

  if (!id) return doctorConnectFailure()

  return axios(`/doctor/consultation/connect/${id}`)
    .then(() => dispatch(doctorConnectSuccess()))
    .catch((e) => {
      dispatch(doctorConnectFailure(e))
    })
}

export const doctorDisconnectRequest = createAction('DOCTOR_DISCONNECT_REQUEST')
export const doctorDisconnectSuccess = createAction('DOCTOR_DISCONNECT_SUCCESS')
export const doctorDisconnectFailure = createAction('DOCTOR_DISCONNECT_FAILURE')

export const doctorDisconnect = () => (dispatch, getStore) => {
  dispatch(doctorDisconnectRequest())

  const id = idx(getStore(), _ => _.consultations.consultation.id)

  if (!id) return doctorDisconnectFailure()

  return axios(`/doctor/consultation/disconnect/${id}`)
    .then(() => dispatch(doctorDisconnectSuccess()))
    .catch((e) => {
      dispatch(doctorDisconnectFailure(e))
    })
}

export const fetchOpentokParamsRequest = createAction('FETCH_OPENTOK_PARAMS_REQUEST')
export const fetchOpentokParamsSuccess = createAction('FETCH_OPENTOK_PARAMS_SUCCESS')
export const fetchOpentokParamsFailure = createAction('FETCH_OPENTOK_PARAMS_FAILURE')

export const fetchOpentokParams = (initiating = false) => (dispatch, getStore) => {
  dispatch(fetchOpentokParamsRequest())

  const { id } = initiating ? getStore().consultations.consultation : getStore().visio.subject.consultation

  if (!id) return dispatch(fetchOpentokParamsFailure)

  return axios(`/general/consultation/${id}/session?initiating=${initiating}`)
    .then(res => dispatch(fetchOpentokParamsSuccess(res.data)))
    .then(() => dispatch(doctorConnect()))
    .catch((e) => {
      dispatch(fetchOpentokParamsFailure(e))
    })
}

export const opentokSessionCreated = createAction('OPENTOK_SESSION_CREATED')
export const finishOpentokSession = createAction('FINISH_OPENTOK_SESSION')
export const deleteSessionData = createAction('DELETE_SESSION_DATA')

export const createAppointmentConsultationRequest = createAction('CREATE_APPOINTMENT_CONSULTATION_REQUEST')
export const createAppointmentConsultationSuccess = createAction('CREATE_APPOINTMENT_CONSULTATION_SUCCESS')
export const createAppointmentConsultationFailure = createAction('CREATE_APPOINTMENT_CONSULTATION_FAILURE')

/**
 *
 * @param values
 * @returns {function(*, *): Promise<AxiosResponse<any> | *>}
 */
export const createAppointmentConsultation = values => (dispatch, getStore) => {
  dispatch(createAppointmentConsultationRequest(values))
  const doctorId = idx(getStore(), _ => _.doctors.doctor.id)
  const formData = new FormData()
  const data = {
    ...values,
    doctorId,
    specializationId: '0',
  }
  const proposedDate = moment(data.proposedDate, null).tz(Intl.DateTimeFormat().resolvedOptions().timeZone).utc().format('YYYY-MM-DD HH:mm:ss')

  Object.keys(data).forEach((item) => {
    if(item !== "proposedDate"){
      formData.append(item, data[item])
    }
  })
  formData.append('symptomExplanation', "Téléconsultation")
  formData.append('proposedDate', proposedDate)

  return axios('/patient/consultation', {
    method: 'POST',
    data: formData,
  })
    .then((res) => {
      dispatch(createAppointmentConsultationSuccess(res.data))
      return res;
    })
    .catch((e) =>{
      dispatch(createAppointmentConsultationFailure(e))
      return e
    })
}

export const createUrgentConsultationRequest = createAction('CREATE_URGENT_CONSULTATION_REQUEST')
export const createUrgentConsultationSuccess = createAction('CREATE_URGENT_CONSULTATION_SUCCESS')
export const createUrgentConsultationFailure = createAction('CREATE_URGENT_CONSULTATION_FAILURE')

export const createUrgentConsultation = values => (dispatch) => {
  dispatch(createUrgentConsultationRequest(values))
  const formData = new FormData()
  const data = {
    ...values,
  }

  // TODO: kostyl'
  if (!data.proposedDate) data.proposedDate = moment().add('day', 1).format('YYYY-MM-DD hh:mm:ss')

  Object.keys(data).forEach((item) => {
    formData.append(item, data[item])
  })

  return axios('/patient/consultation', {
    method: 'POST',
    data: formData,
  })
    .then(res => dispatch(createUrgentConsultationSuccess(res.data)))
    .catch(e => dispatch(createUrgentConsultationFailure(e)))
}

export const editMedicalReportRequest = createAction('EDIT_MEDICAL_REPORT_REQUEST')
export const editMedicalReportSuccess = createAction('EDIT_MEDICAL_REPORT_SUCCESS')
export const editMedicalReportFailure = createAction('EDIT_MEDICAL_REPORT_FAILURE')

export const editMedicalReport = values => (dispatch, getStore) => {
  dispatch(editMedicalReportRequest(values))
  const { id } = getStore().consultations.consultation
  const formData = new FormData()
  const data = values

  if (!id) return dispatch(editMedicalReportFailure)
  Object.keys(data).forEach((key) => {
    formData.append(key, data[key] || '');
  })

  return axios(`/general/consultation/${id}/medicalReport/v2`, {
    method: 'POST',
    data: formData,
  })
    // TODO: kostyl'
    .then((res) => {
      dispatch(editMedicalReportSuccess(res.data))
      dispatch(fetchConsultation(id))
      new MessageCreator(res.data.data, msg => dispatch(pushMessageSuccess(msg)))
        .pushReportMessage()
    })
    .catch((e) => {
      dispatch(editMedicalReportFailure(e))
    })
}

export const addPrescriptionRequest = createAction('ADD_PRESCRIPTION_REQUEST')
export const addPrescriptionSuccess = createAction('ADD_PRESCRIPTION_SUCCESS')
export const addPrescriptionFailure = createAction('ADD_PRESCRIPTION_FAILURE')

export const addPrescription = values => (dispatch, getStore) => {
  dispatch(addPrescriptionRequest(values))
  const { id } = getStore().consultations.consultation
  const data = {
    drugs: values.medicationPrescribed ? values.medicationPrescribed.map(m => ({
      // api_id: m.medicine.value,
      // dosage: m.dosage,
      // iteration: m.iteration,
      // duration: m.duration,
      // type: m.type,
      // quantity: m.quantity || 0,
      name: m.medicine,
      api_id: '123',
      // dosage: '123',
      iteration: '123',
      duration: '123',
      // type: '123',
      quantity: '123',
    })) : [],
  }

  if (!data.drugs.length) return dispatch(addPrescriptionFailure)

  if (!id) return dispatch(addPrescriptionFailure)

  return axios(`/general/consultation/${id}/medicalReportDrug`, {
    method: 'POST',
    data,
  })
    .then((res) => {
      dispatch(addPrescriptionSuccess(res.data))
      dispatch(fetchConsultation(id))
      new MessageCreator(res.data.data, msg => dispatch(pushMessageSuccess(msg)))
        .pushPresciptionMessage()
    })
    .catch((e) => {
      dispatch(addPrescriptionFailure(e))
    })
}

export const fetchPrescriptionRequest = createAction('FETCH_PRESCRIPTION_REQUEST')
export const fetchPrescriptionSuccess = createAction('FETCH_PRESCRIPTION_SUCCESS')
export const fetchPrescriptionFailure = createAction('FETCH_PRESCRIPTION_FAILURE')

function responseToPrescriptionData(response) {
  let { data } = response
  if (response.data.data.type === 'Prescription') {
    data = {
      prescription: {
        id: response.data.data.id,
        lines: [],
      },
    }
    for (const lineData of response.data.data.relationships.lines.data) {
      data.prescription.lines.push({
        id: lineData.attributes.id,
        name: lineData.attributes.name,
        dosage: lineData.attributes.dosage,
      })
    }
  }
  return data;
}

export const fetchPrescription = consultationId => (dispatch, getStore) => {
  dispatch(fetchPrescriptionRequest())
  const store = getStore()

  const id = consultationId || idx(store, _ => _.consultations.consultation.id)
  if (!id) return dispatch(fetchPrescriptionFailure('No Consult'))
  // else

  return axios.get(`/general/consultation/prescription/${id}`)
    .then((res) => {
      const data = responseToPrescriptionData(res)
      return dispatch(fetchPrescriptionSuccess(data))
    })
    .catch(e => dispatch(fetchPrescriptionFailure(e)))
}

export const savePrescriptionRequest = createAction('SAVE_PRESCRIPTION_REQUEST')
export const savePrescriptionSuccess = createAction('SAVE_PRESCRIPTION_SUCCESS')
export const savePrescriptionFailure = createAction('SAVE_PRESCRIPTION_FAILURE')

export const savePrescription = (consultationId, traitements) => (dispatch, getStore) => {
  dispatch(savePrescriptionRequest())
  const store = getStore()

  const id = consultationId || idx(store, _ => _.consultations.consultation.id)
  const data = {
    lines: traitements,
  };

  return axios.post(`/general/consultation/prescription/${id}`, data)
    .then((res) => {
      const data = responseToPrescriptionData(res)
      return dispatch(savePrescriptionSuccess(data))
    })
    .catch(e => dispatch(savePrescriptionFailure(e)))
}

export const approveConsultationRequest = createAction('APPROVE_CONSULTATION_REQUEST')
export const approveConsultationSuccess = createAction('APPROVE_CONSULTATION_SUCCESS')
export const approveConsultationFailure = createAction('APPROVE_CONSULTATION_FAILURE')

export const approveConsultation = (consultationId, type, consultationContext = null) => (dispatch, getStore) => {
  dispatch(approveConsultationRequest())
  const store = getStore()

  const id = consultationId || idx(store, _ => _.consultations.consultation.id)
  const chatId = idx(store, _ => _.chats[id].chat.id)

  if (!id) return approveConsultationFailure()

  return axios.post(`/doctor/consultation/${id}/approve?include=patient`, {consultationContext})
    .then(() => dispatch(approveConsultationSuccess()))
    .then(() => {
      dispatch(fetchConsultation(id, type))
      socket.notify(chatId)
    })
    .catch((e) => {
      dispatch(approveConsultationFailure(e))
    })
}

export const fetchDoctorAvailableSuccess = createAction('FETCH_DOCTOR_AVAILABLE_SUCCESS')
export const fetchDoctorRapportDetailleSuccess = createAction('FETCH_DOCTOR_RAPPORT_DETAILLE_SUCCESS')

export const EditStatusDoctorAvailability = checked => (dispatch) => {
  dispatch(fetchDoctorAvailableSuccess())
  const data = new FormData()
  const check = checked ? 1 : 0;
  data.append('status_availability', check)

  return axios.post('/doctor/available', data)
    .then(response => dispatch(fetchDoctorAvailableSuccess(response.data)))
    .catch(e => dispatch(fetchDoctorAvailableSuccess(e)))
}


export const removeDownloadLink = createAction('REMOVE_DOWNLOAD_LINK')

export const getCallStatusRequest = createAction('GET_CALL_STATUS_REQUEST')
export const getCallStatusSuccess = createAction('GET_CALL_STATUS_SUCCESS')
export const getCallStatusFailure = createAction('GET_CALL_STATUS_FAILURE')

export const getCallStatus = () => (dispatch, getStore) => {
  dispatch(getCallStatusRequest())

  const id = idx(getStore(), _ => _.consultations.consultation.id)

  if (!id) return getCallStatusFailure()

  return axios(`/patient/consultation/callStatus/${id}`)
    .then(res => dispatch(getCallStatusSuccess(res.data)))
    .catch((e) => {
      dispatch(getCallStatusFailure(e))
    })
}

export const fetchPreviousConsultationsRequest = createAction('FETCH_PREVIOUS_CONSULTATIONS_REQUEST')
export const fetchPreviousConsultationsSuccess = createAction('FETCH_PREVIOUS_CONSULTATIONS_SUCCESS')
export const fetchPreviousConsultationsFailure = createAction('FETCH_PREVIOUS_CONSULTATIONS_FAILURE')

export const fetchPreviousConsultations = (patient, params = {}) => (dispatch) => {
  dispatch(fetchPreviousConsultationsRequest(params))

  return axios('/doctor/consultation', {
    params: {
      ...params,
      patient,
    },
  })
    .then(res => dispatch(fetchPreviousConsultationsSuccess(res.data)))
    .catch((e) => {
      dispatch(fetchPreviousConsultationsFailure(e))
    })
}

export const refuseConsultationRequest = createAction('REFUSE_CONSULTATION_REQUEST')
export const refuseConsultationSuccess = createAction('REFUSE_CONSULTATION_SUCCESS')
export const refuseConsultationFailure = createAction('REFUSE_CONSULTATION_FAILURE')

export const refuseConsultation = (id, message, refuse) => (dispatch) => {
  dispatch(refuseConsultationRequest(id))
  const data = new FormData()
  data.append('message', message)

  if (refuse === false) {
    return axios(`/doctor/consultation/${id}/cancelled`, {
      method: 'POST',
      data,
    })
      .then(() => dispatch(refuseConsultationSuccess()))
      .catch(() => dispatch(refuseConsultationFailure()))
  }
  if (refuse === true) {
    return axios(`/doctor/consultation/${id}/refused`, {
      method: 'POST',
      data,
    })
      .then(() => dispatch(refuseConsultationSuccess()))
      .catch(() => dispatch(refuseConsultationFailure()))
  }
}

export const paymentRequest = createAction('PAYMENT_REQUEST')
export const paymentSuccess = createAction('PAYMENT_SUCCESS')
export const paymentFailure = createAction('PAYMENT_FAILURE')

export const pay = id => (dispatch) => {
  dispatch(paymentRequest(id))
  const data = new FormData()

  const host = window.env.HOST

  data.append('payment_type', 'PAYMENT_CONSULTATION')
  data.append('object_id', id)
  data.append('success_url', `${host}/panel/consultations/${id}?payment_status=1`)
  data.append('fail_url', `${host}/panel/consultations/${id}?payment_status=0`)

  return axios('/payment/transaction/pay', {
    method: 'POST',
    data,
  })
    .then((response) => {
      dispatch(paymentSuccess(response.data))
      const url = idx(response, _ => _.data.data.attributes.transactionURL)

      if (url) {
        window.open(url, '_blank');
        //window.location = url
      }
    })
    .catch(() => dispatch(paymentFailure()))
}

export const inviteDoctorRequest = createAction('INVITE_DOCTOR_REQUEST')
export const inviteDoctorSuccess = createAction('INVITE_DOCTOR_SUCCESS')
export const inviteDoctorFailure = createAction('INVITE_DOCTOR_FAILURE')

export const inviteDoctor = (doctorId, consultationId) => (dispatch, getStore) => {
  dispatch(inviteDoctorRequest(doctorId, consultationId))
  const chatId = idx(getStore(), _ => _.chats[consultationId].chat.id)

  return axios(`/doctor/consultation/addDoctor/${consultationId}/${doctorId}`, { method: 'POST' })
    .then(() => {
      if (chatId) {
        socket.notify(chatId)
      }
      dispatch(inviteDoctorSuccess())
    })
    .catch(err => dispatch(inviteDoctorFailure(err)))
}

export const toggleConciliumRequest = createAction('TOGGLE_CONCILIUM_REQUEST')
export const toggleConciliumSuccess = createAction('TOGGLE_CONCILIUM_SUCCESS')
export const toggleConciliumFailure = createAction('TOGGLE_CONCILIUM_FAILURE')

export const toggleConcilium = url => (dispatch) => {
  dispatch(toggleConciliumRequest())
  return axios(url, { method: 'POST' })
    .then(() => dispatch(toggleConciliumSuccess()))
    .catch(err => dispatch(toggleConciliumFailure(err)))
}

export const toggleAccessRequest = createAction('TOGGLE_ACCESS_REQUEST')
export const toggleAccessSuccess = createAction('TOGGLE_ACCESS_SUCCESS')
export const toggleAccessFailure = createAction('TOGGLE_ACCESS_FAILURE')

export const toggleAccess = (id, value, consultationId) => (dispatch, getStore) => {
  dispatch(toggleAccessRequest())
  const chatId = idx(getStore(), _ => _.chats[consultationId].chat.id)

  return axios(`/doctor/consultation/addDoctor/patient/data/${id}`, {
    method: 'POST',
    data: {
      access: +value,
    },
  })
    .then(() => {
      if (chatId) {
        socket.notify(chatId)
      }
      dispatch(toggleAccessSuccess())
    })
    .catch(err => dispatch(toggleAccessFailure(err)))
}

export const createConciliumRequest = createAction('CREATE_CONCILIUM_REQUEST')
export const createConciliumSuccess = createAction('CREATE_CONCILIUM_SUCCESS')
export const createConciliumFailure = createAction('CREATE_CONCILIUM_FAILURE')

export const createConcilium = (values, isUrgent, isTCA, isTCAAndNurse, isTCAPharma, teamType) => (dispatch, getStore) => {

  dispatch(createConciliumRequest(values))
  const data = new FormData()
  if (values.proposedDate) {
    //TODO : prendre le fuseau horaire des paramètres de l'utilisateur et non pas l'heure du navigateur

    const convertDateToUTC = moment(values.proposedDate, null).tz(Intl.DateTimeFormat().resolvedOptions().timeZone).utc().format('YYYY-MM-DD HH:mm:ss')

    data.append('proposedDate', convertDateToUTC)
  }
    data.append('consultationContext', isTCAAndNurse ? values.tcaActContextNurse : isTCAPharma ? 'TLM' : '')

  if (!isTCA) {
    data.append('symptomExplanation', values.symptomExplanation)
  }
  else {
    data.append('symptomExplanation', 'Téléconsultation assistée')
    data.append('type_name_consultation', 'TCA')
    data.append('isInvitation', values.isInvitation)
  }

  //TODO :
  // Comportement anomral entre values et data (type Formdata ) lors du append pour les données gérer par des hooks en amont
  data.append(values.hospitalized_patient ? 'hospitalized_patient' : '', values.hospitalized_patient)

  data.append('doctorId', values.doctorId)
  data.append('type_employment', values.doctor_type_employment || getStore().auth.attributes.type_employment)

  if(teamType) {
    data.append('teamType', teamType)
  }

  if (values.patient) {
    data.append(values.patient.type === 'HealthRecord' ? 'healthRecordId' : 'patientId', values.patient.id)
  }
  
  if (values.draftId) {
    data.append('draftId', values.draftId)
  }

  if (values.files) {
    values.files.forEach(file => data.append('files[]', file.id))
  }


  return axios('/doctor/telexpertise', {
    method: 'POST',
    params: isUrgent ? { priority: 'urgent' } : undefined,
    data,
  })
    .then(() => {
      dispatch(createConciliumSuccess())
      if(isTCA){
        history.push('/panel/teleconsultations')
      }
      else{
        history.push('/panel/consultations')
      }
    })
    .catch(err => dispatch(createConciliumFailure(err)))
}

export const editConciliumRequest = createAction('EDIT_CONCILIUM_REQUEST')
export const editConciliumSuccess = createAction('EDIT_CONCILIUM_SUCCESS')
export const editConciliumFailure = createAction('EDIT_CONCILIUM_FAILURE')

export const editConcilium = (values) => (dispatch) => {
  dispatch(editConciliumRequest(values))
  const data = new FormData()
  if (values.consultationContext) {
    data.append('consultationContext', values.consultationContext)
  }

  if (values.consultationID) {
    data.append('consultationID', values.consultationID)
  }

  return axios('/doctor/telexpertise', {
    method: 'PATCH',
    data: JSON.stringify(Object.fromEntries(data)), // conversion du formdata en json car comportement différent avec une method PATCH
  })
    .then((res) => {
      dispatch(editConciliumSuccess(res.data))
    })
    .catch(err => dispatch(editConciliumFailure(err)))
}



export const setTab = createAction('SET_CONSULTATIONS_TAB')
export const toggleVideoAccess = createAction('TOGGLE_VIDEO_ACCESS')
export const toggleAudioAccess = createAction('TOGGLE_AUDIO_ACCESS')

export const sendUsefulRequest = createAction('SEND_USEFUL_REQUEST')
export const sendUsefulSuccess = createAction('SEND_USEFUL_SUCCESS')
export const sendUsefulFailure = createAction('SEND_USEFUL_FAILURE')

export const toggleD2UPanel = createAction('TOGGLE_DOC2U_PANEL')

export const setDraftConsultation = createAction('SET_DRAFT_CONSULTATION')

export const clearDraftConsultation = createAction('CLEAR_DRAFT_CONSULTATION')

export const sendUseful = (data, consultationId) => (dispatch) => {
  dispatch(sendUsefulRequest())
  return axios(`/general/consultation/${consultationId}/useful`, {
    method: 'POST',
    data,
  })
    .then(() => {
      dispatch(sendUsefulSuccess())
    })
    .catch(err => dispatch(sendUsefulFailure(err)))
}

export const addPrescriptionChatRequest = createAction('ADD_PRESCRIPTION_CHAT_REQUEST')
export const addPrescriptionChatSuccess = createAction('ADD_PRESCRIPTION_CHAT_SUCCESS')
export const addPrescriptionChatFailure = createAction('ADD_PRESCRIPTION_CHAT_FAILURE')

export const addPrescriptionChat = (consultationId) => (dispatch, getStore) => {
  dispatch(addPrescriptionChatRequest())
  const store = getStore()

  const id = consultationId || idx(store, _ => _.consultations.consultation.id)
  const chatId = idx(store, _ => _.chats[id].chat.id)

  if (!id) return addPrescriptionChatFailure()

  return axios.post(`/general/consultation/${id}/add/chat`)
      .then((response) => {
        socket.sendPrescriptionMessage(chatId,response.data.data.id)
      })
      .then(() => dispatch(addPrescriptionChatSuccess()))
      .catch((e) => {
        dispatch(addPrescriptionChatFailure(e))
      })
}

export const updateInfoPatientSuccess = createAction('UPDATE_INFO_PATIENT_SUCCESS')
export const proposeNewSlotRequest = createAction('PROPOSE_NEW_SLOT_REQUEST')
export const proposeNewSlotSuccess = createAction('PROPOSE_NEW_SLOT_SUCCESS')
export const proposeNewSlotFailure = createAction('PROPOSE_NEW_SLOT_FAILURE')

export const proposeNewSlot = (consultationId, values) => (dispatch, getStore) => {
  dispatch(proposeNewSlotRequest())
  const data = {}
  const convertDateToUTC = moment(values.proposedDate, null).tz(Intl.DateTimeFormat().resolvedOptions().timeZone).utc().format('YYYY-MM-DD HH:mm:ss')
  data.newSlot = convertDateToUTC
  data.consultationContext = values.consultationContext || null

  const params = {
    include: 'doctor,team,doctorCreator,teamMembers,patient,healthRecord.attachment,medicalReport,drugs,additionalMember'
  }

  return axios.patch(`/doctor/invite/${consultationId}/edit`, data, {params})
    .then((response) => dispatch(proposeNewSlotSuccess(response.data)))
    .catch((e) => {
      dispatch(proposeNewSlotFailure(e))
    })
}

export const transferConsultationRequest = createAction('TRANSFER_CONSULTATION_REQUEST')
export const transferConsultationSuccess = createAction('TRANSFER_CONSULTATION_SUCCESS')
export const transferConsultationFailure = createAction('TRANSFER_CONSULTATION_FAILURE')
export const transferConsultation = (consultationId) => (dispatch) => {
  dispatch(transferConsultationRequest(consultationId))
  return axios.post(`/general/consultation/${consultationId}/redirect`)
    .then(response => {
      dispatch(transferConsultationSuccess(response.data?.data))
      return Promise.resolve(response.data?.data?.id)
    })
    .catch(err => {
      let errorMessage = 'Erreur inattendue';
      if (err.payload?.status >= 400 && err.payload?.status < 500) {
        errorMessage = err.payload?.message || errorMessage
      }
      dispatch(transferConsultationFailure(errorMessage))
    })
}
//#endregion

//#region Reducers
const list = handleActions({
  [fetchConsultationsListSuccess]: (state, action) => ({
    ...state,
    data: action.payload.data
      .sort((a, b) => {
        const time1 = moment(idx(a, _ => _.attributes.lastmessagetime)).unix()
        const time2 = moment(idx(b, _ => _.attributes.lastmessagetime)).unix()
        return time2 - time1
      }),
    paginator: action.payload.paginator,
  }),
}, [])

const consultation = handleActions({
  [fetchConsultationSuccess]: (state, action) => action.payload.data,
  [chatConnectAction]: (state, {payload}) => {
    const id = idx(payload, _ => _.id) || {}
    const { relateType : type } = idx(payload, _ => _.attributes) || {}
    if (type == 'consultation') {
      return {
        ...state,
        chatId: id,
      }
    }
  },
  [editConciliumSuccess]: (state, action) => ({ ...state,
      attributes : {
        ...state.attributes,
        consultationContext: action.payload[0],
        TCA_RQD_price: action.payload[1]
      }
    }
  ),
  [updateInfoPatientSuccess]: (state, action) => ({
    ...state,
    relationships: {
      ...state.relationships,
      patient: {
        data: action.payload,
      }
    }
  }),
  [handleMessageAction]: (state, action) => {
    if (action.payload.action === 'AdditionalDoctorJoin') {
      if (idx(action, _ => _.payload.attributes.consultationId) === state.id) {
        return {
          ...state,
          relationships: {
            ...state.relationships,
            additionalMember: {
              data: [
                action.payload,
              ],
            },
          },
        }
      }
    }

    return state
  },
  [proposeNewSlotSuccess]: (state, action) => action.payload.data,
}, {})

const previous = handleActions({
  [fetchPreviousConsultationsRequest]: () => [],
  [fetchPreviousConsultationsSuccess]: (state, action) => action.payload.data
    .sort((a, b) => {
      const time1 = moment(idx(a, _ => _.attributes.proposed_date)).unix()
      const time2 = moment(idx(b, _ => _.attributes.proposed_date)).unix()

      return time2 - time1
    }),
}, [])

const opentok = handleActions({
  [fetchOpentokParamsSuccess]: (state, action) => ({ ...state, params: action.payload.data }),
  [opentokSessionCreated]: (state, action) => ({ ...state, session: action.payload }),
  [pushMessageSuccess]: (state, action) => ({
    ...state,
    messages: [...state.messages, JSON.parse(action.payload)],
  }),
  [finishOpentokSession]: state => ({ ...opentokDefaultState, messages: state.messages }),
  [deleteSessionData]: () => opentokDefaultState,
  [deleteMessages]: state => ({ ...state, messages: [] }),
  [terminateCall]: () => opentokDefaultState,
  [handleMessageAction]: (state, action) => {
    if (action.payload.action === 'cancel') {
      return opentokDefaultState
    }
    return state
  }
}, opentokDefaultState)

const callStatus = handleActions({
  [getCallStatusSuccess]: (state, action) => action.payload.data,
}, {})

const tab = handleActions({
  [setTab]: (state, action) => action.payload,
}, 0)

const mediaAccess = handleActions({
  [toggleVideoAccess]: (state, action) => ({ ...state, video: action.payload }),
  [toggleAudioAccess]: (state, action) => ({ ...state, audio: action.payload }),
}, {
  video: true,
  audio: true,
})

const doc2u = handleActions({
  [toggleD2UPanel]: (state, action) => ({ ...state, open: action.payload }),
}, {
  open: false,
})

const draft = handleActions({
  [setDraftConsultation]: (state, action) => ({ ...state, consultation: action.payload }),
  [clearDraftConsultation]: (state, action) => ({}),
}, {
  consultation: undefined,
})

const defaultTransferState = {
  consultation: null,
  error: null,
}

const transfer = handleActions({
  [transferConsultationRequest]: () => defaultTransferState,
  [transferConsultationFailure]: (state, action) => ({ ...state, error: action.payload }),
  [transferConsultationSuccess]: (state, action) => ({ ...state, consultation: action.payload }),
}, defaultTransferState)

export const consultations = combineReducers({
  list,
  consultation,
  opentok,
  callStatus,
  previous,
  tab,
  mediaAccess,
  doc2u,
  draft,
  transfer,
})
//#endregion

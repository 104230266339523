import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import { pure, mapProps, compose } from 'recompose'
import { Form, Field } from 'react-final-form'
import moment from 'moment-timezone'
import { useSelector } from 'react-redux';
import { FormattedMessage, useIntl } from 'react-intl'
import { TextField } from 'common/UiKit/TextField'
import { Paper } from 'common/UiKit/Paper'
import { AsyncSelectField } from 'common/AsyncSelect'
import get from 'lodash/get'
import { AvatarUpload } from '../AvatarUpload'
import { Colors } from '../../../constants/colors'
import {DateField} from "../DateField";
// import { COUNTRIES as NATIVE_COUNTRIES } from '../../constants/countries'

const FirstLine = styled.div`
  display: flex;
  
  & > div:first-child {
    margin-right: 24px;
  }
`

const CheckboxLine = styled.div`
  margin: 0 0 24px;
`

const CheckboxLabel = styled.div`
  margin: 0 12px 8px;
  display: flex;
  align-items: center;
  font-size: 12px;
  color: ${Colors.blueGrey};
`

const Wrapper = styled(Paper)`
  padding: 24px;
  display: flex;
  
  & > div:last-child {
    flex: 1
  }
`
const GENDER_OPTIONS = ['male', 'woman']
const GENDER_OPTIONS_DOCTOR = ['woman', 'male', 'doctor', 'professor']

const validate = (values, showInsurance, isDoctor) => {
  const errors = {}
  
  if (showInsurance) {
    if (!values.medical_insurance 
      || values.medical_insurance.toString().length !== 15 
      || /[^A-B0-9]/.test(values.medical_insurance.toString())) {
      errors.medical_insurance = <FormattedMessage id='error.patient.insurance_15' />
    }
  }
  if (!values.first_name) errors.first_name = <FormattedMessage id='error.first.name' />
  if (!values.last_name) errors.last_name = <FormattedMessage id='error.last.name' />
  if (!values.date_of_birth) errors.date_of_birth = <FormattedMessage id='error.birth.date' />
  // if (!values.photo) errors.photo = <FormattedMessage id='error.photo' />
  if (!values.gender || values.gender === 'unknown') errors.gender = <FormattedMessage id='error.gender' />
  // if (!values.country_id) errors.country_id = <FormattedMessage id='error.country' />
  if (values && values.date_of_birth
    && (moment(values.date_of_birth).isBefore('1900'))) {
    errors.date_of_birth = <FormattedMessage id='error.birth.date' />
  }

  // if (!values.birthplace) {
  //   errors.birthplace = <FormattedMessage id='error.required' />;
  // }

  return errors
}

const enhance = compose(
  pure,
  mapProps(props => ({
    ...props,
    onSubmit: values => props.onSubmit({ ...values }).then(() => props.afterSubmit()),
  })),
)

export const PersonalDataForm = enhance(({
  onSubmit,
  initialValues,
  showInsurance,
  id,
  disableShadow,
}) => {
  const { formatMessage } = useIntl()
  const f = id => formatMessage({ id })
  const [initialValuesWithoutUpdate, setInitialValuesWithoutUpdate] = useState({});

  useEffect(() => {
    setInitialValuesWithoutUpdate(initialValues)
  }, [])
  const authType = useSelector(state => state.auth.type);
  const isDoctor = authType === 'Doctor';
  const genderOptions = isDoctor ? GENDER_OPTIONS_DOCTOR : GENDER_OPTIONS;
  const defaultBirthPlaceValue = get(initialValues, 'birthplace.name', false) && get(initialValues, 'birthplace.id', false) ? { 
    label: initialValues.birthplace.name, 
    value: initialValues.birthplace.id,
  } : null;

  return (
    <Form
      onSubmit={onSubmit}
      initialValues={{
        ...initialValuesWithoutUpdate,
        ...(initialValues && initialValues.birthplace ? { birthplace: initialValues.birthplace } : {}),
        date_of_birth: initialValuesWithoutUpdate.date_of_birth
          ? moment(initialValuesWithoutUpdate.date_of_birth).format('YYYY-MM-DD')
          : moment('1980', 'YYYY').format('YYYY-MM-DD'),
      }}
      validate={values => validate(values, showInsurance)}
      subscription={{}}
      render={({ handleSubmit }) => (
        <form onSubmit={handleSubmit} id={id}>
          <Wrapper disableShadow={disableShadow}>
            <Field name='photo' component={AvatarUpload} />
            <div>
              <FirstLine>
                <Field name='first_name' component={TextField} label={`*${f('profile.first.name')}`} />
                <Field name='last_name' component={TextField} label={`*${f('profile.last.name')}`} />
              </FirstLine>
              <Field
                name='date_of_birth'
                label={`*${f('profile.birth.date')}`}
                render={props => <DateField props={props} minDate={moment('1900', 'YYYY')} maxDate={new Date()} errorMessage={f('error.birth.date')}/> }
              />
              {showInsurance && (
              <Field
                name='medical_insurance'
                component={TextField}
                label={`*${f('profile.insurance')}`}
                type='text'
              />
              )}
              <Field
                name='gender'
                component={TextField}
                label={`*${f('profile.gender')}`}
                options={genderOptions.map(value => ({ value, label: f(`profile${isDoctor ? '.genders' : ''}.${value}`) }))}
              />
              <CheckboxLine>
                <CheckboxLabel>
                  <FormattedMessage id='concilium.brithplace' />
                </CheckboxLabel>
              </CheckboxLine>
              <Field
                rsStyles={{
                  control: provided => ({ 
                    ...provided,
                    border: '0',
                    borderBottom: '1px solid #e7eaf0',
                    backgroundColor: 'rgba(245,247,251,0.6)',
                    marginBottom: '20px',
                  }),
                }}
                defaultValueFormatted={defaultBirthPlaceValue}
                component={AsyncSelectField}
                barcketsPath='attributes.insee_code'
                name='birthplace'
                valuePath='attributes.id'
                labelPath='attributes.name'
                requestUrl='/api/birthplace/list?name='
              />
              {/*<Field*/}
              {/*  name='country_id'*/}
              {/*  component={TextField}*/}
              {/*  label={`*${f('profile.country')}`}*/}
              {/*  options={NATIVE_COUNTRIES}*/}
              {/*/>*/}
            </div>
          </Wrapper>
        </form>
      )}
    />
  )
})

PersonalDataForm.defaultProps = {
  disableShadow: false,
  showInsurance: false,
  initialValues: {},
}

PersonalDataForm.propTypes = {
  onSubmit: PropTypes.func.isRequired,
  initialValues: PropTypes.shape({
    first_name: PropTypes.string,
    last_name: PropTypes.string,
    birth_date: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
    // country_id: PropTypes.string,
    gender: PropTypes.string,
  }),
  id: PropTypes.string.isRequired,
  disableShadow: PropTypes.bool,
  showInsurance: PropTypes.bool,
}

import React from 'react'
import styled from 'styled-components'
import PropTypes from 'prop-types'
import { TextButton } from 'common/UiKit/TextButton'
import { Colors } from 'constants/colors'
import { FormattedMessage } from 'react-intl'

const Wrapper = styled.div`
  position: fixed;
  bottom: 0;
  background-color: ${Colors.white};
  right: 0;
  left: 280px;
  padding: 10px 8px;
  text-align: right;
  box-shadow: 0 -1px 8px 0 ${Colors.azure};

  button {
    min-width: 271px;
    font-size: 14px;
    font-weight: 500;
    margin-right: 16px;
    float: right;
    height: 40px;
  }
`

export const Buttons = ({
  setCheckWith,
  loadingWithProfile,
  // loadingWithMedicine,
}) => (
  <Wrapper>
    <TextButton
      variant='extended'
      color='secondary'
      onClick={() => setCheckWith('profile')}
      isFetching={loadingWithProfile}
    >
      <FormattedMessage id='hr.check.with.profile' />
    </TextButton>
    {/*<TextButton*/}
    {/*  variant='extended'*/}
    {/*  color='secondary'*/}
    {/*  onClick={() => setCheckWith('medicine')}*/}
    {/*  isFetching={loadingWithMedicine}*/}
    {/*>*/}
    {/*  <FormattedMessage id='hr.check.with.medication' />*/}
    {/*</TextButton>*/}
  </Wrapper>
)

Buttons.defaultProps = {
  // loadingWithMedicine: false,
}

Buttons.propTypes = {
  setCheckWith: PropTypes.func.isRequired,
  loadingWithProfile: PropTypes.bool.isRequired,
  // loadingWithMedicine: PropTypes.bool,
}

import { createAction, handleActions, combineActions } from 'redux-actions'
import axios from 'axios'
import idx from 'idx'
import moment from 'moment-timezone'
import get from 'lodash/get';
import { history } from '../../utils/history'
import { setNotification } from './notifications'
import { clearSignature } from './signature'

const defaultState = {}
const errorMessage = 'Email ou mot de passe incorrect.'

export const connectionMode = {
  conex: 'CONEX',
  psc: 'PSC',
}

//#region Actions
export const authByTokenRequest = createAction('AUTH_BY_TOKEN_REQUEST')
export const authByTokenSuccess = createAction('AUTH_BY_TOKEN_SUCCESS')
export const authByTokenFailure = createAction('AUTH_BY_TOKEN_FAILURE')

export const authByToken = payload => (dispatch) => {
  dispatch(clearSignature())
  dispatch(authByTokenRequest(payload))

  if (!payload?.token && !localStorage.authToken) {
    return dispatch(authByTokenFailure())
  }

  if (!payload?.type && !localStorage.userType) {
    return dispatch(authByTokenFailure())
  }

  if (payload?.token && payload.type) {
    localStorage.setItem('authToken', payload.token)
    localStorage.setItem('userType', payload.type)
  }

  const token = `Bearer ${localStorage.authToken}`
  const { userType } = localStorage

  axios.defaults.headers.common.Authorization = token

  return axios(`/${userType}/profile?include=workPlace,skill,schedule,diploma,certified`)
    .then((response) => {
      dispatch(authByTokenSuccess(response.data))
      dispatch(editProfile({time_zone: Intl.DateTimeFormat().resolvedOptions().timeZone}))
    })
    .catch((err) => {
      dispatch(authByTokenFailure(err))
      delete axios.defaults.headers.common.Authorization;
      localStorage.removeItem('authToken')
      localStorage.removeItem('userType')
      localStorage.removeItem('connectionMode')
      history.push('/');
    })
}

export const authRequest = createAction('AUTH_REQUEST')
export const authSuccess = createAction('AUTH_SUCCESS')
export const authFailure = createAction('AUTH_FAILURE')
export const rppsSearchRequest = createAction('RPPS_ADELI_SEARCH')
export const rppsSearchSuccess = createAction('RPPS_ADELI_SUCCESS')
export const rppsSearchFailure = createAction('RPPS_ADELI_FAILURE')

export const authenticateWithData = (response, isPatient, cmode) => {
  const token = idx(response, _ => _.data.data.attributes.token)
  const userId = idx(response, _ => _.data.data.attributes.id)
  const status = idx(response, _ => _.data.data.attributes.status)
  const user = isPatient ? 'patient' : 'doctor'

  localStorage.setItem('authToken', token)
  localStorage.setItem('userType', user)
  localStorage.setItem('userId', userId)
  localStorage.setItem('connectionMode', cmode)

  axios.defaults.headers.common.Authorization = token
  return {
    token: token,
    defaultRedirection: '/panel/consultations',
    userStatus: status,
  }
}

export const auth = (values, isPatient) => (dispatch, getStore) => {

  const formData = new FormData()
  if (_.has(values, 'rpps_adeli')) {
    const rppsAdeli = values.rpps_adeli || '';
    dispatch(rppsSearchRequest(rppsAdeli))
    return axios(`/doctor/rpps/v2`, {
      method: 'GET',
      params: {rpps_adeli: rppsAdeli},
    }).then((response) => {
      const rpps_adeli = JSON.stringify(response.data)
      localStorage.setItem('rpps_adeli', rpps_adeli)
      dispatch(rppsSearchSuccess(rpps_adeli))
      return response
    }).then((response) => {
      if(response.data.exist && !response.data.attributed)
        history.push('registration-mode/classic-sign-in')
      else
        dispatch(rppsSearchFailure(response.data))
    })
      .catch(e => dispatch(rppsSearchFailure(e)))
  } else {
    let redirect = getStore().auth.redirect
    dispatch(authRequest(values))

    if (values.email) {
      formData.append('email', values.email)
    }
    if (values.password) {
      formData.append('password', values.password)
    }
    if (values.passwordConfirmation) {
      formData.append('confirmation', values.passwordConfirmation)
      formData.append('policy', 'true')
    }
    if (values.passwordConfirmation && localStorage.getItem('pscSubscription') === '2') {
      formData.append('oauthToken', localStorage.getItem('oauthToken'))
    }
    if(values.captcha) {
      formData.append('captcha', values.captcha)
    }

    return axios(`/${isPatient ? 'patient' : 'doctor'}/${values.passwordConfirmation ? 'registration' : 'authorization'}`, {
      method: 'POST',
      data: formData,
    })
      .then((response) => {
        const {
          errors,
          token,
          defaultRedirection,
          userStatus,
        } = authenticateWithData(response, isPatient, connectionMode.conex);
        if (errors) {
          dispatch(authFailure())
          return errors
        }
        if (userStatus === 0) {
          redirect = '/panel/profile';
        }
        dispatch(authSuccess())
        dispatch(authByToken(token))
        localStorage.removeItem('pscSubscription')
        localStorage.removeItem('oauthToken')
        history.push(redirect ? redirect : defaultRedirection)
      })
      .catch((err) => {
        dispatch(authFailure(err))
        delete axios.defaults.headers.common.Authorization;
        localStorage.removeItem('authToken')
        localStorage.removeItem('userType')
        localStorage.removeItem('connectionMode')
        //si réponse 401 du back : le compte n'est pas encore activé car user.status = 0
        if (err.response?.status === 401) {
          return {status: err.response.status}
        }
        if(err.response?.data?.data?.error_key === 'mail.already.used') {
          return ( err.response?.data?.data?.error_key )
        }
        // Final form submission error
        return { errorMessage }
      })
  }
}

const defaultStateRppsAdeli = null

export const authRppsAction = handleActions({
  [rppsSearchFailure]: (state, action) => ({
    errorRppsAdeli: action.payload,
  }),
  [rppsSearchRequest]: () => (defaultStateRppsAdeli),
}, defaultStateRppsAdeli)

export const oauth2PscRequest = createAction('OAUTH2_PSC_REQUEST')
export const oauth2PscSuccess = createAction('OAUTH2_PSC_SUCCESS')
export const oauth2PscFailure = createAction('OAUTH2_PSC_FAILURE')
export const oauth2Psc = (request_uri) => (dispatch) => {
  dispatch(oauth2PscRequest())

  return axios(request_uri)
    .then(res => {
      const {errors, token, defaultRedirection, userStatus} = authenticateWithData(res, false, connectionMode.psc);
      if (errors) {
        dispatch(oauth2PscFailure(errors))
        return errors
      } // else
      let redirect = localStorage.getItem('redirect')
      if (userStatus === 0) {
        redirect = '/panel/profile'
      }
      dispatch(oauth2PscSuccess())
      const result = dispatch(authByToken(token))
      localStorage.removeItem('redirect')
      history.push(redirect ? redirect : defaultRedirection)
      return result
    })
    .catch(err => {
      console.error(err)
      return dispatch(oauth2PscFailure(err))
    })
}

export function getProfessionamNumberFromOauthData(userData) {
  let numberType
  let professionalNumber
  if (userData.otherIds.RPPS) {
    numberType = 8
    professionalNumber = userData.otherIds.RPPS.substring(1)
  } else if (userData.otherIds.ADELI) {
    numberType = 0
    professionalNumber = userData.otherIds.ADELI.substring(1)
  } else if (userData.otherIds.FINESS) {
    numberType = 3
    professionalNumber = userData.otherIds.FINESS
  }
  return [numberType, professionalNumber]
}

export const pscInscriptionRequest = createAction('PSC_INSCRIPTION');
export const pscInscription = (userData) => (dispatch) => {
  if (userData.status === 403 && localStorage.getItem('pscSubscription') === '1') {
    localStorage.setItem('oauthToken', userData.oauthToken)
    dispatch(pscInscriptionRequest({
      pscInscription: userData,
    }))
    const [numberType, professionalNumber] = getProfessionamNumberFromOauthData(userData)
    dispatch(rppsSearchRequest(professionalNumber))
    return axios(`/doctor/rpps/v2`, {
      method: 'GET',
      params: {rpps_adeli: professionalNumber},
    }).then((response) => {
      if(!response.data.exist) {
        response.data = {
          typeIdentifiantPp: numberType,
          identifiantPp: professionalNumber,
          codeCivilite: get(userData, ['SubjectRefPro', 'codeCivilite'], ''),
          libelleCivilite: '',
          nomExercice: userData.family_name,
          prenomExercice: userData.given_name,
          libelleProfession: '',
          libelleModeExercice: '',
          raisonSocialeSite: get(userData, 'SubjectRefPro.exercices[0].activities[0].raisonSocialeSite', ''),
          numeroVoieStructure: get(userData, 'SubjectRefPro.exercices[0].activities[0].numeroVoie', ''),
          libelleTypeDeVoieStructure: get(userData, 'SubjectRefPro.exercices[0].activities[0].codeTypeDeVoie', ''),
          libelleVoieStructure: get(userData, 'SubjectRefPro.exercices[0].activities[0].livelleVoie', ''),
          codePostalStructure: get(userData, 'SubjectRefPro.exercices[0].activities[0].codePostal', ''),
          codeCommuneStructure: get(userData, 'SubjectRefPro.exercices[0].activities[0].codeCommune', ''),
          libelleCommuneStructure: '',
        }
      }
      const rpps_adeli = JSON.stringify(response.data)
      localStorage.setItem('rpps_adeli', rpps_adeli)
      dispatch(rppsSearchSuccess(rpps_adeli))
      history.push('/auth/doctor/registration-mode/classic-sign-in')
      return {isFound: response }
    }).catch(e => {
      if (!window.env.HOST.endsWith('app.conexsante.com')) {
        localStorage.removeItem('pscSubscription')
        localStorage.removeItem('oauthToken')
        return dispatch(rppsSearchFailure(e))
      } else {
        history.push('/auth/doctor/registration-mode/classic-sign-in')
      }
    })
  }
}

export const pscSubscribe = () => (dispatch, getStore) => {
  localStorage.setItem('redirect', getStore().auth.redirect)
  localStorage.setItem('pscSubscription', '1')
  window.location.href = `${window.env.API_URL}/oauthpsc/login`
}

export const pscPostLogin = (idConsultation) => (dispatch, getStore) => {
  localStorage.setItem('redirect', '/panel/consultations/' + idConsultation)
  localStorage.setItem('pscSubscription', '1')
  window.location.href = `${window.env.API_URL}/oauthpsc/login`
}

export const logOutRequest = createAction('LOG_OUT_REQUEST')
export const logOutSuccess = createAction('LOG_OUT_SUCCESS')
export const logOutFailure = createAction('LOG_OUT_FAILURE')

export const forceLogout = () => {
  const lang = localStorage.getItem('language')
  localStorage.clear()

  if (lang) {
    localStorage.setItem('language', lang)
  }
  window.location.pathname = '/auth'
}

export const logOut = () => async (dispatch, getStore) => {
  const response = await axios(`/${localStorage.getItem('userType')}/profile`)
  const result = await axios(`${window.env.API_URL}/logLogout/${response.data.data.attributes.user_id}/v1`);
  dispatch(logOutRequest())

  return new Promise((resolve) => {
    getStore().loading.authByToken = true
    const lang = localStorage.getItem('language')
    localStorage.clear()

    if (lang) {
      localStorage.setItem('language', lang)
    }
    dispatch(logOutSuccess())
    resolve()
    // NOTE: Il faudrait normalement utiliser `history.push`, cependant dans certains cas
    // l'appel est ignoré et rien ne se passe ce qui cause un écran blanc bloquant l'utilisateur.
    // On contourne le problème pour le moment en utilisant une redirection ce qui provoque
    // le rechargement de la page.
    window.location.href='/auth'
    // history.push('/auth')
  }).finally(() => {
    getStore().loading.authByToken = false
  })
}

export const pscLogoutRequest = createAction('PSC_LOGOUT_REQUEST')
export const pscLogoutSuccess = createAction('PSC_LOGOUT_SUCCESS')
export const pscLogoutFailure = createAction('PSC_LOGOUT_FAILURE')
export const pscLogout = () => (dispatch, getStore) => {
  getStore().loading.authByToken = true;
  dispatch(pscLogoutRequest())
  return axios.get(`/oauthpsc/logout`)
    .then((res) => {
      if (res.data && res.data.redirectUrl) {
        dispatch(pscLogoutSuccess(res.data.redirectUrl))
        window.location.href=res.data.redirectUrl
      } else {
        dispatch(pscLogoutFailure('No redirect URL'))
        history.push('/auth')
      }
    })
    .catch((err) => {
      console.error({error: err})
      dispatch(pscLogoutFailure(err))
    }).finally(() => {
      getStore().loading.authByToken = false;
    })
}

export const directOAuth = (service, token) => (dispatch) => {
  return axios.get(`/oauth`, {
    params: {
      service: service,
      token: token,
    },
  }).then(response => {
    dispatch(authByToken({
      token: response.data.token,
      type: 'doctor',
    }))
  }).catch(err => {
    console.log(err)
    history.push('/auth/doctor/registration-mode')
  })
}

export const passwordRecoveryRequest = createAction('PASSWORD_RECOVERY_REQUEST')
export const passwordRecoverySuccess = createAction('PASSWORD_RECOVERY_SUCCESS')
export const passwordRecoveryFailure = createAction('PASSWORD_RECOVERY_FAILURE')

export const passwordRecovery = (payload, successText) => (dispatch) => {
  dispatch(passwordRecoveryRequest(payload))

  const data = payload.values
  const type = payload.type === 'doctor' ? 'doctor' : 'patient'
  const formData = new FormData()

  Object.keys(data).forEach((item) => {
    formData.append(item, data[item])
  })

  return axios(`/${type}/forgotPassword/send`, {
    method: 'POST',
    data: formData,
  })
    .then(() => {
      dispatch(passwordRecoverySuccess())
      dispatch(setNotification({ title: successText, type: 'success' }))
    })
    .catch((err) => {
      dispatch(passwordRecoveryFailure(err))
      dispatch(setNotification({ title: successText, type:'success'}))
    })
}

export const redirectAfterLogin = createAction('REDIRECT_AFTER_LOGIN')

export const setNewPasswordRequest = createAction('SET_NEW_PASSWORD_REQUEST')
export const setNewPasswordSuccess = createAction('SET_NEW_PASSWORD_SUCCESS')
export const setNewPasswordFailure = createAction('SET_NEW_PASSWORD_FAILURE')

export const setNewPassword = payload => (dispatch) => {
  dispatch(setNewPasswordRequest(payload))

  const data = payload.values
  const type = payload.type === 'doctor' ? 'doctor' : 'patient'
  const formData = new FormData()

  Object.keys(data).forEach((item) => {
    formData.append(item, data[item])
  })

  return axios(`/${type}/forgotPassword/check`, {
    method: 'POST',
    data: formData,
  })
    .then(() => {
      localStorage.removeItem('authToken');
      localStorage.removeItem('userType');
      history.push(`/auth/${type}`);
    })
    .catch((err) => {
      if (idx(err, _ => _.response.data.errors.some(item => item.title === 'authorization_exception.role_is_not_defined'))) {
        dispatch(setNewPassword({ values: data, type: payload.type === 'doctor' ? 'patient' : 'doctor' }))
        return
      }
      dispatch(setNewPasswordFailure(err))
      dispatch(setNotification('An error occured'))
    })
}

export const setNewPasswordPreRegistratedRequest = createAction('SET_NEW_PASSWORD_PRE_REGISTRATED_REQUEST')
export const setNewPasswordPreRegistratedSuccess = createAction('SET_NEW_PASSWORD_PRE_REGISTRATED_SUCCESS')
export const setNewPasswordPreRegistratedFailure = createAction('SET_NEW_PASSWORD_PRE_REGISTRATED_FAILURE')

export const setNewPasswordPreRegistrated = payload => (dispatch) => {
  dispatch(setNewPasswordPreRegistratedRequest(payload))
  const decodeToken = window.atob(payload.values.token)
  const jsonToken = JSON.parse(decodeToken)
  return axios(`/api/doctor/password/preregistrated`, {
    method: 'POST',
    data: {
     confirmation : payload.values.confirmation,
     password : payload.values.password,
     token : payload.values.token,

    },
  })
      .then((data) => {
        dispatch(setNewPasswordPreRegistratedSuccess(data))
        const valueAuth = {
          password : payload.values.password,
          email: jsonToken[1],
          }
          dispatch(auth(valueAuth,false))
          history.push('/panel/profile/fill');
      })
      .catch((err) => {
        dispatch(setNewPasswordPreRegistratedFailure(err))
        dispatch(setNotification('An error occured'))
      })
}

export const changePasswordRequest = createAction('CHANGE_PASSWORD_REQUEST');
export const changePasswordSuccess = createAction('CHANGE_PASSWORD_SUCCESS');
export const changePasswordFailure = createAction('CHANGE_PASSWORD_FAILURE');

export const changePassword = values => (dispatch) => {
  dispatch(changePasswordRequest(values));

  const { userType } = localStorage
  const data = values
  const formData = new FormData()

  Object.keys(data).forEach((item) => {
    if (item === 'currentPassword') {
      formData.append('password', data[item])

      return
    }
    formData.append(item, data[item])
  })

  return axios(`/${userType}/changePassword`, {
    method: 'POST',
    data: formData,
  })
    .then((res) => {
      dispatch(changePasswordSuccess(res.data))
      history.push('/panel/profile')
    })
    .catch((err) => {
      dispatch(changePasswordFailure(err))

      // Final form submission error
      return { currentPassword: 'Incorrect password, please try again' }
    })
}

export const editProfileRequest = createAction('EDIT_PROFILE_REQUEST')
export const editProfileSuccess = createAction('EDIT_PROFILE_SUCCESS')
export const editProfileFailure = createAction('EDIT_PROFILE_FAILURE')

export const deleteEmail = id => (dispatch) => {
  const { userType } = localStorage
  return axios(`/${userType}/email/${id}`, {
    method: 'DELETE',
  })
    .then(() => axios(`/${userType}/profile?include=workPlace,skill,schedule,diploma`))
    .then(res => dispatch(editProfileSuccess(res.data)))
    .catch((err) => {
      console.warn(err);
    })
}

export const editSkills = (values, cb) => (dispatch) => {
  axios.post('/api/doctor/skill', values)
    .then(() => axios('/doctor/profile?include=workPlace,skill,schedule,diploma'))
    .then(res => dispatch(editProfileSuccess(res.data)))
    .then(() => cb && cb())
    .catch((err) => {
      console.warn(err);
    })
}

export const editEmails = values => (dispatch) => {
  const formData = new FormData()

  Object.keys(values).forEach((item) => {
    if (item === 'addition_email') {
      if (!values[item].length) return
      values[item]
        .forEach(email => (email 
          ? formData.append(`addition_email[${email.id ? email.id : ''}]`, email.email || '') 
          : null))
    } else {
      formData.append(item, values[item])
    }
  });

  const { userType } = localStorage
  return axios(`/${userType}/email`, {
    method: 'POST',
    data: formData,
  })
    .then((resp) => {
      const token = get(resp, 'data.data.attributes.token', false);
      if (token) {
        localStorage.setItem('authToken', token);
        axios.defaults.headers.common.Authorization = token;
      }
    })
    .then(() => axios(`/${userType}/profile?include=workPlace,skill,schedule,diploma`))
    .then(res => dispatch(editProfileSuccess(res.data)))
    .catch((err) => {
      console.warn(err);
      throw err;
    })
}

export const editProfile = (values, followError) => (dispatch, getStore) => {
  dispatch(editProfileRequest(values))
  const profile = getStore().auth.attributes
  const { userType } = localStorage
  const data = values
  const formData = new FormData()

  data.email = values && values.email ? values.email : profile.email;
  // TODO: kostyl'
  if (!data.first_name && profile.first_name) data.first_name = profile.first_name
  if (!data.last_name && profile.last_name) data.last_name = profile.last_name
  if (!data.gender && profile.gender) data.gender = profile.gender

  if (!data.birthplace) {
    delete data.birthplace;
  }

  if (data.birthplace && typeof data.birthplace === 'object' && data.birthplace.id) {
    data.birthplace = data.birthplace.id;
  }

  if (!data.addition_email && profile.addition_email) {
    data.addition_email = profile.addition_email
  }

  Object.keys(data).forEach((item) => {
    if (item === 'work_place') return
    if (item === 'language' || item === 'specialization_list') {
    } else if (item === 'addition_email') {
      // if (!data[item].length) return
      // data[item]
      //   .forEach(addition_email => (
      //     addition_email ? formData.append('addition_email[]', addition_email || '') : null))
    } else if (item === 'date_of_birth') {
      formData.append(item, moment(data[item]).format('YYYY-MM-DD'))
    } else {
      formData.append(item, data[item])
    }
  })

  delete formData.insurance

  if (userType === 'doctor') {
    formData.append('experience', profile.experience || 0)

    if (!data.specialization_id) {
      formData.append('specialization_id', profile.specialization_id)
    }
  }

  return axios(`/${userType}/profile`, {
    method: 'POST',
    data: formData,
  })
    .then(() => axios(`/${userType}/profile`))
    .then(res => dispatch(editProfileSuccess(res.data)))
    .catch((err) => {
      dispatch(editProfileFailure(err))
      if (followError) {
        throw err;
      }
    })
}

export const createWorkPlaceRequest = createAction('CREATE_WORKPLACE_REQUEST')
export const createWorkPlaceSuccess = createAction('CREATE_WORKPLACE_SUCCESS')
export const createWorkPlaceFailure = createAction('CREATE_WORKPLACE_FAILURE')

export const createWorkPlace = values => (dispatch, getStore) => {
  dispatch(createWorkPlaceRequest(values))
  const { id } = getStore().auth

  const formData = new FormData()

  // TODO: kostyl'
  values.workPlace.map((v, i) => (
    Object.keys(v).forEach(key => formData.append(`workPlace[${i}][${key}]`, v[key]))
  ))

  return axios(`/general/doctor/${id}/workPlace`, {
    method: 'POST',
    data: formData,
  })
    .then(() => axios('/doctor/profile?include=workPlace,skill,schedule,diploma'))
    .then(res => dispatch(createWorkPlaceSuccess(res.data)))
    .catch((err) => {
      dispatch(createWorkPlaceFailure(err))
    })
}

export const updateWorkPlaceRequest = createAction('UPDATE_WORKPLACE_REQUEST')
export const updateWorkPlaceSuccess = createAction('UPDATE_WORKPLACE_SUCCESS')
export const updateWorkPlaceFailure = createAction('UPDATE_WORKPLACE_FAILURE')

export const updateWorkPlace = (workplaceId, values) => (dispatch, getStore) => {
  dispatch(updateWorkPlaceRequest(values))
  const { id } = getStore().auth

  const formData = new FormData()
  Object.keys(values).forEach(key => formData.append(key, values[key]))

  return axios(`/general/doctor/${id}/workPlace/${workplaceId}`, {
    method: 'POST',
    data: formData,
  }).then(() => axios('/doctor/profile?include=workPlace,skill,schedule,diploma'))
    .then(res => dispatch(updateWorkPlaceSuccess(res.data)))
    .catch((err) => {
      dispatch(updateWorkPlaceFailure(err))
    })
}

// prices
export const createPricesRequest = createAction('CREATE_PRICES_REQUEST')
export const createPricesSuccess = createAction('CREATE_PRICES_SUCCESS')
export const createPricesFailure = createAction('CREATE_PRICES_FAILURE')

export const createPrices = values => (dispatch, getStore) => {
  dispatch(createPricesRequest(values))
  const { id } = getStore().auth

  const data = values
  const formData = new FormData()

  Object.keys(data).forEach((item) => {
    formData.append(item, data[item])
  })

  return axios(`/general/doctor/price/${id}`, {
    method: 'POST',
    data: formData,
  })
    .then(() => axios('/doctor/profile?include=workPlace,skill,schedule,diploma'))
    .then(res => dispatch(createPricesSuccess(res.data)))
    .catch((err) => {
      dispatch(createPricesFailure(err))
    })
}

export const createDiplomasRequest = createAction('CREATE_DIPLOMAS_REQUEST')
export const createDiplomasSuccess = createAction('CREATE_DIPLOMAS_SUCCESS')
export const createDiplomasFailure = createAction('CREATE_DIPLOMAS_FAILURE')

export const createDiplomas = values => (dispatch, getStore) => {
  dispatch(createDiplomasRequest(values))
  const { id } = getStore().auth
  const formData = new FormData()

  values.file.forEach((item) => {
    formData.append('file', item)
  })

  return axios(`/general/doctor/${id}/diploma`, {
    method: 'POST',
    data: formData,
  })
    .then(() => axios('/doctor/profile?include=workPlace,skill,schedule,diploma'))
    .then(res => dispatch(createDiplomasSuccess(res.data)))
    .catch((err) => {
      dispatch(createDiplomasFailure(err))
    })
}

export const deleteDiplomaRequest = createAction('DELETE_DIPLOMA_REQUEST')
export const deleteDiplomaSuccess = createAction('DELETE_DIPLOMA_SUCCESS')
export const deleteDiplomaFailure = createAction('DELETE_DIPLOMA_FAILURE')

export const deleteDiploma = value => (dispatch, getStore) => {
  dispatch(deleteDiplomaRequest(value))
  const { id } = getStore().auth

  return axios(`/general/doctor/${id}/diploma/${value}`, {
    method: 'DELETE',
  })
    .then(() => axios('/doctor/profile?include=workPlace,skill,schedule,diploma'))
    .then(res => dispatch(deleteDiplomaSuccess(res.data)))
    .catch((err) => {
      dispatch(deleteDiplomaFailure(err))
    })
}

export const createScheduleRequest = createAction('CREATE_SCHEDULE_REQUEST')
export const createScheduleSuccess = createAction('CREATE_SCHEDULE_SUCCESS')
export const createScheduleFailure = createAction('CREATE_SCHEDULE_FAILURE')

export const createSchedule = values => (dispatch, getStore) => {
  dispatch(createScheduleRequest(values))
  const { id } = getStore().auth

  const formData = new FormData()

  // TODO: kostyl'
  values.schedule.map((v, i) => (
    Object.keys(v).forEach(key => formData.append(`schedule[${i}][${key}]`, v[key]))
  ))

  return axios(`/general/doctor/${id}/schedule`, {
    method: 'POST',
    data: formData,
  })
    .then(() => axios('/doctor/profile?include=workPlace,skill,schedule,diploma'))
    .then(res => dispatch(createScheduleSuccess(res.data)))
    .catch((err) => {
      dispatch(createDiplomasFailure(err))
    })
}

// UNCOMMENT IF PROBLEM (INDEPENDED COMPONENTS ARE UPDATING WHEN ACTIONS NOT SPECCIFIED IN REDUCER)

// export const updateUserTimestampRequest = createAction('UPDATE_USER_TIMESTAMP_REQUEST')
// export const updateUserTimestampSuccess = createAction('UPDATE_USER_TIMESTAMP_SUCCESS')
// export const updateUserTimestampFailure = createAction('UPDATE_USER_TIMESTAMP_FAILURE')

export const updateUserTimestamp = () => () => {
  const { userType } = localStorage

  //dispatch(updateUserTimestampRequest())
  return axios('/api/user/account/online', {
    method: 'POST',
    data: {
      available_till: moment().add(10, 'm').format(),
    },
  })
    .then(() => axios(`/${userType}/profile?include=workPlace,skill,schedule,diploma`))
  //.then(res => dispatch(editProfileSuccess(res.data)))
    .catch((err) => {
      console.warn(err);
    //dispatch(updateUserTimestampFailure(err))
    })
}

export const sendPhoneRequest = createAction('SEND_PHONE_REQUEST')
export const sendPhoneSuccess = createAction('SEND_PHONE_SUCCESS')
export const sendPhoneFailure = createAction('SEND_PHONE_FAILURE')

export const sendPhone = phoneNumber => (dispatch) => {
  dispatch(sendPhoneRequest(phoneNumber))
  const { userType } = localStorage

  return axios(`/${userType}/phone/enter`, {
    method: 'POST',
    data: { phoneNumber },
  })
    .then(response => dispatch(sendPhoneSuccess(response.data)))
    .catch((err) => {
      dispatch(sendPhoneFailure(err))
      throw err
    })
}

export const sendSignatureCodeRequest = createAction('SEND_SIGNATURE_CODE')
export const sendSignatureCodeSuccess = createAction('SEND_SIGNATURE_CODE')
export const sendSignatureCodeFailure = createAction('SEND_SIGNATURE_CODE')

export const sendSignatureCode = () => (dispatch) => {
  dispatch(sendSignatureCodeRequest())

  return axios(`/doctor/signature/code`, {
    method: 'POST'
  })
      .then(response => dispatch(sendSignatureCodeSuccess(response.data)))
      .catch((err) => {
        dispatch(sendSignatureCodeFailure(err))
        throw err
      })
}

export const checkSignatureCodeRequest = createAction('CHECK_SIGNATURE_CODE')
export const checkSignatureCodeSuccess = createAction('CHECK_SIGNATURE_CODE')
export const checkSignatureCodeFailure = createAction('CHECK_SIGNATURE_CODE')

export const checkSignatureCode = code => (dispatch) => {
  dispatch(checkSignatureCodeRequest())

  return axios(`/doctor/signature/check`, {
    method: 'POST',
    data: { code:code}
  })
      .then(response => dispatch(checkSignatureCodeSuccess(response.data)))
      .catch((err) => {
        dispatch(checkSignatureCodeFailure(err))
        throw err
      })
}


export const sendCodeRequest = createAction('SEND_CODE_REQUEST')
export const sendCodeSuccess = createAction('SEND_CODE_SUCCESS')
export const sendCodeFailure = createAction('SEND_CODE_FAILURE')

export const sendCode = confirmationCode => (dispatch) => {
  dispatch(sendCodeRequest(confirmationCode))
  const { userType } = localStorage

  return axios(`/${userType}/phone/confirm`, {
    method: 'POST',
    data: { confirmationCode },
  })
    .then((response) => {
      return dispatch(sendCodeSuccess(response.data))
    })
    .then(() => axios(`/${userType}/profile?include=workPlace,skill,schedule,diploma`))
    .then(() => history.push(userType === 'doctor' ? '/panel/profile/fill' : '/panel/profile/edit'))
    .catch((err) => {
      dispatch(sendCodeFailure(err))
      throw err
    })
}

export const changeLanguageRequest = createAction('CHANGE_LANGUAGE_REQUEST')
export const changeLanguageFailure = createAction('CHANGE_LANGUAGE_FAILURE')

export const changeLanguage = value => (dispatch) => {
  dispatch(changeLanguageRequest())
  return axios('/api/user/setting/interface_language', {
    method: 'POST',
    data: value,
  })
    .catch(e => dispatch(changeLanguageFailure(e)))
}

export const updateMssRequest = createAction('UPDATE_MSS_REQUEST')
export const updateMssSuccess = createAction('UPDATE_MSS_SUCCESS')
export const updateMssError = createAction('UPDATE_MSS_FAILURE')

export const editMSS = (mssAddresses) => dispatch => {
  dispatch(updateMssRequest())
  return axios.post(`/user/mssante`, {
    mssAddresses
  })
  .then(res => {
    dispatch(updateMssSuccess(res.data.data))
  })
  .catch(err => dispatch(updateMssError(err)))
}

//#endregion

//#region Reducers
export const authReducer = handleActions({
  [authSuccess]: (state, action) => ({
    ...action.payload,
    redirect: state.redirect,
  }),
  [pscInscriptionRequest] : (state, action) => action.payload,
  [logOutSuccess]: () => ({}),
  [redirectAfterLogin]: (state, action) => ({ ...state, redirect: action.payload }),
  [updateMssSuccess]: (state, action) => action.payload,
  [updateMssError]: (state, action) => action.payload,
  [combineActions(
    authByTokenSuccess,
    editProfileSuccess,
    createWorkPlaceSuccess,
    updateWorkPlaceSuccess,
    createPricesSuccess,
    createDiplomasSuccess,
    deleteDiplomaSuccess,
    createScheduleSuccess,
  )]: (state, action) => ({
    ...action.payload.data,
    redirect: state.redirect,
  }),
}, defaultState)
//#endregion

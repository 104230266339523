import React from 'react'
import styled from 'styled-components'
import { Colors } from 'constants/colors'
import { Route, Switch } from 'react-router-dom'
import { Main } from './Main'
import { HelpPage } from '../../../common/HelpPage'
import { ChangePassword } from '../../../common/Settings'
import { Language } from './Language'
import { Notifications } from './Notifications'
import { Notifications as NotificationsList } from '../../Notifications'
import { PrivacyPolicy } from '../../../common/PrivacyPolicy'
import { HistoryPage } from '../../../common/History'
import { history } from '../../../../utils/history'
import { patientFAQ } from '../../../../constants/FAQ'
import { PhoneEditPage } from '../../../Auth/PhoneConfirm/PhoneEditPage'

const Wrapper = styled.div`
  background-color: ${Colors.background};
  min-height: 100%;
`

export const Profile = () => (
  <Wrapper>
    <Switch>
      <Route
        path='/panel/profile/help'
        render={({ ...props }) => (
          <HelpPage
            faq={patientFAQ}
            terms='gdpr.modal.terms'
            onBackIconClick={() => history.push('/panel/profile')}
            {...props}
          />
        )}
      />
      <Route
        path='/panel/profile/privacy-policy/history'
        render={({ ...props }) => (
          <HistoryPage
            onBackIconClick={() => history.push('/panel/profile/privacy-policy')}
            {...props}
          />
        )}
      />
      <Route path='/panel/profile/privacy-policy' component={PrivacyPolicy} />
      <Route path='/panel/profile/change-password' render={props => <ChangePassword {...props} to='/panel/profile' />} />
      <Route path='/panel/profile/language' component={Language} />
      <Route path='/panel/profile/settings/notifications' component={Notifications} />
      <Route path='/panel/profile/notifications' component={NotificationsList} />
      <Route path='/panel/profile/phone/edit' component={PhoneEditPage} />
      <Route path='/panel/profile' component={Main} />
    </Switch>
  </Wrapper>
)

import { createAction, handleActions } from 'redux-actions'
import { combineReducers } from 'redux'
import axios from 'axios'
import idx from 'idx'
import set from 'lodash/set';
import get from 'lodash/get';
import { handleMessageAction } from '../chats'

//#region Actions
export const fetchNotificationRequest = createAction('FETCH_NOTIFICATION_REQUEST')
export const fetchNotificationSuccess = createAction('FETCH_NOTIFICATION_SUCCESS')
export const fetchNotificationFailure = createAction('FETCH_NOTIFICATION_FAILURE')

export const fetchNotificationCountRequest = createAction('FETCH_NOTIFICATION_COUNT_REQUEST')
export const fetchNotificationCountSuccess = createAction('FETCH_NOTIFICATION_COUNT_SUCCESS')
export const fetchNotificationCountFailure = createAction('FETCH_NOTIFICATION_COUNT_FAILURE')

export const readNotificationRequest = createAction('READ_NOTIFICATION_REQUEST')
export const readNotificationSuccess = createAction('READ_NOTIFICATION_SUCCESS')
export const readNotificationFailure = createAction('READ_NOTIFICATION_FAILURE')

export const readAllNotificationsRequest = createAction('READ_ALL_NOTIFICATIONS_REQUEST')
export const readAllNotificationsSuccess = createAction('READ_ALL_NOTIFICATIONS_SUCCESS')
export const readAllNotificationsFailure = createAction('READ_ALL_NOTIFICATIONS_FAILURE')

export const adminChatCountRequest = createAction('ADMIN_CHAT_COUNT_REQUEST')
export const adminChatCountSuccess = createAction('ADMIN_CHAT_COUNT_SUCCESS')
export const adminChatCountFailure = createAction('ADMIN_CHAT_COUNT_FAILURE')

export const globalNotificationRequest = createAction('GLOBAL_NOTIFICATION_REQUEST');
export const globalNotificationSuccess = createAction('GLOBAL_NOTIFICATION_SUCCESS');
export const globalNotificationsFailure = createAction('GLOBAL_NOTIFICATION_FAILURE');
//#endregion

//#region Reducer
const globalNotifications = handleActions({
  [globalNotificationSuccess]: (state, action) => action.payload,
}, [])

const list = handleActions({
  [fetchNotificationSuccess]: (state, action) => action.payload,
  [handleMessageAction]: (state, action) => {
    if (action.payload.route === 'User\\Notification' && action.payload.action === 'send') {
      const newState = { ...state };
      const oldNotification = get(state, 'data', []);
      set(newState, 'data', [{ id: action.payload.id, attributes: action.payload.attributes }, ...oldNotification]);
      return newState;
    }

    return state
  },
}, {})

const count = handleActions({
  [fetchNotificationCountSuccess]: (state, action) => action.payload,
  [readNotificationSuccess]: (state, action) => {
    const newCount = state - get(action, 'payload.length', 0);
    if (newCount < 0) {
      return 0;
    }
    return newCount;
  },
  [readAllNotificationsSuccess]: () => 0,
  [handleMessageAction]: (state, action) => {
    if (action.payload.route === 'User\\Notification' && action.payload.action === 'send') {
      return state + 1
    }

    return state
  },
}, 0)

export const notificationReducer = combineReducers({
  list,
  count,
  globalNotifications,
})

//#endregion

//#region Thunks
export const fetchGlobalNotification = () => (dispatch) => {
  dispatch(globalNotificationRequest())
  return axios('/api/global-notification/check')
    .then(res => dispatch(globalNotificationSuccess(res.data.data)))
    .catch((err) => {
      dispatch(globalNotificationsFailure(err))
    })
}
export const fetchNotification = (page = 1) => (dispatch) => {
  dispatch(fetchNotificationRequest())
  return axios(`/general/user/notification?page=${page}`)
    .then(res => dispatch(fetchNotificationSuccess(res.data)))
    .catch((err) => {
      dispatch(fetchNotificationFailure(err))
    })
}

export const fetchNotificationCount = () => (dispatch) => {
  dispatch(fetchNotificationCountRequest())

  return axios('/general/user/notification/count')
    .then(res => dispatch(fetchNotificationCountSuccess(idx(res, _ => _
      .data.data.attributes.count))))
    .catch((err) => {
      dispatch(fetchNotificationCountFailure(err))
    })
}

export const readNotification = ids => (dispatch) => {
  dispatch(readNotificationRequest())

  return axios('/general/user/notification/read', { method: 'POST', data: { ids } })
    .then(() => dispatch(readNotificationSuccess(ids)))
    .catch((err) => {
      dispatch(readNotificationFailure(err))
    })
}

export const readAllNotifications = () => (dispatch) => {
  dispatch(readAllNotificationsRequest())

  return axios.post('/general/user/notification/read/all')
    .then(() => {
      dispatch(readAllNotificationsSuccess([]))
      dispatch(fetchNotification())
    })
    .catch((err) => {
      dispatch(readAllNotificationsFailure(err))
    })
}

export const fetchAdminChatCount = () => (dispatch) => {
  dispatch(adminChatCountRequest())

  return axios('/chat/message/count/new', { method: 'POST' })
    .then(() => dispatch(adminChatCountSuccess()))
    .catch((err) => {
      dispatch(adminChatCountFailure(err))
    })
}
//#endregion

import { createAction, handleActions } from 'redux-actions'
import { handleMessageAction } from '../chats'
import {socket} from "../../../services/socket";

export const acceptCall = createAction('ACCEPT_CALL')
export const declineCall = createAction('DECLINE_CALL')
export const sessionCreated = createAction('VISIO_SESSION_CREATED')
export const callInProgress = createAction('CALL_IN_PROGRESS')
export const terminateCall = createAction('TERMINATE_CALL')
export const otConnectionCreated = createAction('OT_CONNECTION_CREATED')

export const resetCall = createAction('RESET_CALL')

export const callReducer = handleActions({
  [declineCall]: (state) => ({ ...state, declined: true }),
  [terminateCall]: () => {
    return null
  },
  [handleMessageAction]: (state, action) => {
    if (action.payload.action === 'call') {
      return action.payload.attributes
    }

    if (action.payload.action === 'cancel') {
      return null
    }
    return state
  },
  [resetCall]: state => ({ ...state, declined: true }),
}, null)

const defaultVisio = {
  session: null,
  accepted: false,
  inprogress: false,
  callIncoming: false,
  connections: 0,
}

const doTerminateCall = (state) => {
  if (!!state.subject?.consultation?.id) {
    socket.cancelCall(state.subject?.consultation.id)
  }
  if (state.session) {
    try {
      state.session.streams?.forEach((stream) => {
        let subscribers = state.session.getSubscribersForStream(stream)
        for (let i = 0, n = subscribers.length; i < n; i++) {
          state.session.unsubscribe(subscribers[i])
        }
        let publisher = state.session.getPublisherForStream(stream)
        if (publisher) {
          try {
            publisher.destroy();
          } catch (e) {
            console.error(e)
          }
          state.session.unpublish(publisher)
        }
      })
      state.session.disconnect()
    } catch (e) {
      console.error('Failed to disconnect OpenTok session', e)
    }
  }
  return defaultVisio
}

export const visioReducer = handleActions({
  [acceptCall]: (state, action) => {
    if (!!action.payload) {
      return ({ ...state, accepted: true, callIncoming: false, subject: action.payload })
    } // else
    return ({ ...state, accepted: true, callIncoming: false })
  },
  [declineCall]: (state) => ({ ...state, accepted: false, callIncoming: false }),
  [callInProgress]: (state, action) => ({ ...state, inprogress: action.payload }),
  [sessionCreated]: (state, action) => ({ ...state, session: action.payload}),
  [terminateCall]: doTerminateCall,
  [handleMessageAction]: (state, action) => {
    if (action.payload?.action === 'call') {
      if (state.inprogress) {
        // An other call is already in progress
        socket.cancelCall(action.payload?.attributes?.consultation?.id, 'BUSY')
        return state
      }
      return { ...state, callIncoming: true, subject: action.payload.attributes }
    } // else
    if (action.payload?.action === 'cancel') {
      return doTerminateCall(state)
    } // else
    return state
  },
  [otConnectionCreated]: (state, action) => {
    if (action.payload !== null && action.payload !== undefined && typeof action.payload === 'number') {
      return { ...state, connections: action.payload  }
    } // else
    return state
  },
}, defaultVisio)
import React from 'react'
import TextField from "@mui/material/TextField";
import {Field} from "react-final-form";
import PropTypes from "prop-types";
import {Colors} from "../../../../constants/colors";

export const MuiTextField = ({
  name,
  placeholder,
  multiline,
  minRows,
  fullWidth,
  disabled,
  error
}) => (
  <Field name={name}>
    {props => (
      <>
        <TextField
          id='emailOrPhone'
          name={props.input.name}
          value={props.input.value}
          onChange={props.input.onChange}
          onBlur={props.input.onBlur}
          onFocus={props.input.onFocus}
          placeholder={placeholder}
          fullWidth={fullWidth}
          multiline={multiline}
          minRows={minRows}
          disabled={disabled}
          error={props.meta.touched && !!error}
        />
        {
          props.meta.touched && !!error && (
            <div style={{ color: Colors.red }}>{error}</div>
          )
        }
      </>
    )}
  </Field>
)

MuiTextField.propTypes = {
  name: PropTypes.string.isRequired,
  placeholder: PropTypes.string,
  multiline: PropTypes.bool,
  minRows: PropTypes.number,
  fullWidth: PropTypes.bool,
  disabled: PropTypes.bool,
  error: PropTypes.string,
}

MuiTextField.defaults = {
  placeholder: '',
  multiline: false,
  minRows: 1,
  fullWidth: false,
  disabled: false,
  error: undefined,
}
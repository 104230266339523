import React from 'react';
import AsyncSelect from 'react-select/async-creatable';
import axios from 'axios';
import get from 'lodash/get';
import { useIntl } from 'react-intl';
import styled from 'styled-components';
import capitalize from 'lodash/capitalize';
import { SecuredBckgImage } from 'common/SecuredImage';
import { Colors } from '../../../constants/colors';

const Image = styled(SecuredBckgImage)`
  width: 25px;
  height: 25px;
  overflow: hidden;
  border-radius: 50px;
  background-size: cover;
  background-position: center;
  margin-right: 5px;
`

const Lined = styled.div`
  align-items: center;
  display: flex;
`
const getLabel = (labelPath, value, imagePath) => {
  if (Array.isArray(labelPath)) {
    const image = get(value, imagePath, '');
    return (
      <Lined>
        {image ? <Image src={image} /> : ''}
        {labelPath.map(el => capitalize(get(value, el, ''))).join(' ')}
      </Lined>
    );
  }
  return get(value, labelPath, '');
}

const getLabelText = (labelPath = [], value = {}) => labelPath.map(el => get(value, el, '')).join(' ')

const formatToValues = (values, {
  valuePath, labelPath, barcketsPath, imagePath, 
}) => values.map(el => ({
  value: get(el, valuePath, ''),
  label: barcketsPath ? `${getLabel(labelPath, el, imagePath)} (${get(el, barcketsPath, '')})` : getLabel(labelPath, el, imagePath),
  labelText: getLabelText(labelPath, el),
})) 

const loadOptions = (inputValue, callback, requestUrl, paths) => {
  if (inputValue && inputValue.length && inputValue.length >= 3) {
    axios(`${requestUrl}${inputValue}`)
      .then((result) => {
        const values = get(result, 'data.data', []);
        callback(formatToValues(values, paths));
      })
      .catch(err => console.warn(err))
  } else {
    callback([]);
  }
};

const ErrorStyled = styled.div`
  color: #ee3c5b;
  font-size: 10px;
  padding: 4px 12px 0;
  font-size: 0.75rem;dfdsg
  margin-top: ${({ noMarginError }) => (noMarginError ? '0' : '-20px')};
  position: absolute;
  border-top: 1px solid;
  width: calc(100% - 24px);
`

const ErrorContainer = styled.div`
  position: relative;
`

const caseDefaultVlaueCompleteObject = value => (value && value.id ? `${value.name} (${value.insee_code})` : value)

export const CreatableAsyncSelectField = ({
  requestUrl, valuePath, labelPath, input, rsStyles = {},
  barcketsPath = false, meta, noMarginError, placeholder,
  imagePath, cacheOptions = true, defaultOptions, defaultInputValue,
  isDisabled, isValidNewOption, noOptionsTxt,
}) => {
  const { formatMessage: f } = useIntl()

  return (
    <div>
      <AsyncSelect
        isValidNewOption={isValidNewOption}
        isDisabled={isDisabled}
        isClearable
        key='input-select'
        styles={{ ...rsStyles, control: provided => ({ ...provided, '&:hover': { borderColor: Colors.bluePurple } }) }}
        placeholder={f({ id: placeholder || 'global.search' })}
        cacheOptions={cacheOptions}
        loadOptions={(inputValue, callback) => (
          loadOptions(inputValue, callback, requestUrl, {
            valuePath, labelPath, barcketsPath, imagePath, 
          })
        )}
        defaultInputValue={defaultInputValue ? false : caseDefaultVlaueCompleteObject(input.value)}
        defaultValue={defaultInputValue}
        defaultOptions={defaultOptions}
        value={input.value}
        onChange={(value, text) => input.onChange({ value, text })}
        noOptionsMessage = { noOptionsTxt ? (() => f({id: noOptionsTxt})) : (() => 'No options')}

      />
      {meta && meta.error && meta.touched ? (
        <ErrorContainer>
          <ErrorStyled noMarginError={noMarginError} key='error-container'>
            {meta.error}
          </ErrorStyled>
        </ErrorContainer>
      ) : ''}
    </div>
  ) 
}

import React, {useEffect, useState} from 'react'
import {useDispatch, useSelector} from "react-redux";
import styled from "styled-components";
import {Colors} from "../../../../constants/colors";

import idx from "idx";
import {DoctorMemberCard} from "./Doctor";
import {StyledTextButton} from "../../../common/UiKit/TextButton";
import {FormattedMessage} from "react-intl";
import PersonAddAlt1Icon from '@mui/icons-material/PersonAddAlt1';
import LogoutIcon from '@mui/icons-material/Logout';
import {SearchMemberDialog} from "./Modal/SearchMember";
import {ConfirmDialog} from "../../../common/UiKit/ConfirmDialog";
import {InlineSpacer} from "../../../common/UiKit/InlineSpacer";
import {
  deleteDiscussion,
  fetchDiscussionSuccess
} from "../../../../redux/modules/discussions";
import {DISCUSSION_TYPE__GROUP} from "../../../../constants/discussion";

export const MembersTab = () => {
  const dispatch = useDispatch()
  const members = useSelector(state => idx(state, _ => _.chatMembers.list.data));
  const discussion = useSelector(state => idx(state, _ => _.discussions.discussion));
  const discussionType = idx(discussion, _ =>_.attributes.type)
  const myId = useSelector(state => idx(state, _ => _.auth.id));
  const [showModal, setShowModal] = useState(false);
  const [showDeleteModal, setShowDeleteModal] = useState(false);

  let adminId = null;

  //We go through all the members to get the chat admin id
  if (members) {
    members.forEach((member) => {
      if (member?.attributes?.role === "ADMIN") {
        adminId = member.attributes.doctor.doctor_id;
      }
    })
  }

  const adminRights = (adminId === +myId)

  const deleteGroup = () => {
    dispatch(deleteDiscussion(discussion.id))
      .then(() => {
        dispatch(fetchDiscussionSuccess(null))
      })
      .catch((res) => {
        console.error(res)
      })
  }

  // If the current user is not in the group anymore, it means he has been removed and we reload the page
  useEffect(() => {
    let isMember = false
    if (members) {
      members.map((member) => {
        if (member.attributes.doctor.doctor_id === +myId) {
          isMember = true;
        }
      })
    }
    if (isMember === false) {
      window.location.reload();
    }
  }, [members])

  return (
    <>
      <Wrapper>
        <StyledHeader>
          <MembersCount>
            <FormattedMessage id='staff.members'/>
            &nbsp;({members?.length || 0})
          </MembersCount>
          { (discussionType === DISCUSSION_TYPE__GROUP) &&
            <>
              <StyledButton onClick={() => setShowModal(!showModal)}>
                <PersonAddAlt1Icon />
                <InlineSpacer />
                <FormattedMessage id='concilium.add'/>
              </StyledButton>
              <SearchMemberDialog showModal={showModal} setShowModal={setShowModal} isSearch={true}/>
            </>
          }
        </StyledHeader>
        <StyledListMembers>
          { members &&
            members.map(member => {
              return (
                <DoctorMemberCard key={member.attributes.id} member={member}/>
              )
            })
          }
        </StyledListMembers>
      </Wrapper>
      {(adminRights && discussionType === DISCUSSION_TYPE__GROUP) &&
        <>
          <DeleteGroup onClick={() => setShowDeleteModal(!showDeleteModal)}>
            <DeleteTitle>
              <LogoutIcon/>
              <InlineSpacer />
              <FormattedMessage id='staff.group.delete'/>
            </DeleteTitle>
          </DeleteGroup>
          <ConfirmDialog
            onClose={() => setShowDeleteModal(false)}
            onConfirm={deleteGroup}
            open={showDeleteModal}
            confirmationMessage='staff.group.delete.confirm'
          />
        </>
      }
    </>
  )
}

const Wrapper = styled.div`
  height: 70%;
  width: 70%;
  margin: auto;
  background: ${Colors.white};
  box-shadow: 0 4px 4px rgba(0, 0, 0, 0.05);
  flex-direction: column;
  display: flex;
`

const DeleteGroup = styled.div`
  width: 70%;
  margin-left: auto;
  margin-right: auto;
  margin-bottom: 40px;
  background-color: ${Colors.white};
  box-shadow: 0 4px 4px rgba(0, 0, 0, 0.05);
  &&:hover{
    cursor: pointer;
  }
`

const StyledListMembers = styled.div`
  display: flex;
  flex-direction: column;
  overflow: auto;
`

const StyledHeader = styled.div`
  position: relative;
  display: flex;
  justify-content: left;
  align-items: center;
  padding: 10px 40px;
  border-bottom: 1px solid ${Colors.borderGrey};
`

const MembersCount = styled.h1`
  font-size: 24px;
  font-weight: bold;
  color: ${Colors.conexGrey};
`

const DeleteTitle = styled.h3`
  display: flex;
  align-items: center;
  justify-content: left;
  padding: 0 20px;
  color: ${Colors.red}
`

const StyledButton = styled(StyledTextButton)`
  height: 40px;
  position: absolute;
  right: 40px;
  top: 20px;
`
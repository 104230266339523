import React from 'react';
import axios from 'axios';
import { EditPage } from 'common/UiKit/EditPage';
import styled from 'styled-components';
import { Form, Field } from 'react-final-form';
import { Colors } from 'constants/colors'
import { FormattedMessage, useIntl } from 'react-intl';
import { history } from '../../../../utils/history';
import { MultilineTextField } from '../../../common/MultilineTextField';
import { TextField } from '../../../common/UiKit/TextField';
import { externalFormSubmit } from '../../../../helpers/externalFormSubmit'

const Wrapper = styled.div`
  margin-top: 24px;
`
const MultilineField = styled(MultilineTextField)`
  & .root {
    height: 88px;
  }
`

const Line = styled.div`
  display: flex;
  align-items: ${({ start }) => (start ? 'flex-start' : 'center')};

  .location {
    flex-grow: 1;
  }

  .location-input {
    display: flex;
    justify-content: space-between;
    
    & > *:first-child {
      margin-right: 12px;
    }
  }
`
const Label = styled.div`
  width: 172px;
  min-width: 172px;
  margin-bottom: 24px;
  margin-right: 6px;
  opacity: 0.87;
  font-weight: bold;
  font-size: 16px;
  color: ${Colors.dark};
`

const sendRequestDeletion = values => axios({
  method: 'POST',
  url: '/api/gdpr/personal-data/remove',
  data: values,
})
  .then(() => {
    history.goBack();
  })
  .catch(err => console.warn(err))

const validate = (values) => {
  const errors = {};
  if (!values.comment) {
    errors.comment = <FormattedMessage id='errors.required' />;
  }
  if (values.comment && values.comment.length && values.comment.length > 300) {
    errors.comment = <FormattedMessage id='error.toolong' values={{ expectedLenght: 300 }} />;
  }
  return errors;
}

export const RequestDeletion = () => {
  const { formatMessage } = useIntl()
  const f = id => formatMessage({ id })
  const optionsReason = [
    { label: f('gdpr.request.deletion.reason.dontWant'), value: 'dontWant' },
    { label: f('gdpr.request.deletion.reason.notEnaught'), value: 'notEnaught' },
    { label: f('gdpr.request.deletion.reason.other'), value: 'other' },
  ]


  return (
    <EditPage
      label='profile.requestDeletion'
      buttonText='gdpr.request.deletion.send'
      onBackIconClick={() => history.goBack()}
      onButtonClick={() => externalFormSubmit('REQUEST_DELETION')}
      background
    >
      <Wrapper>
        <p>
          <FormattedMessage id='gdpr.request.deletion.toptext' />
        </p>
        <Form
          validate={validate}
          initialValues={{
            reason: optionsReason[0].value,
          }}
          onSubmit={sendRequestDeletion}
          render={({ handleSubmit }) => (
            <form onSubmit={handleSubmit} id='REQUEST_DELETION'>
              <Line>
                <Label>
                  <FormattedMessage id='gdpr.request.deletion.reason' />
                </Label>
                <Field
                  options={optionsReason}
                  component={TextField}
                  type='select'
                  name='reason'
                  label={`*${f('gdpr.request.deletion.reason')}`}
                />
              </Line>
              <Line>
                <Label>
                  <FormattedMessage id='gdpr.request.deletion.comment' />
                </Label>
                <Field
                  component={MultilineField}
                  type='text'
                  maxRows={3}
                  name='comment'
                  multiline
                  label={`*${f('gdpr.request.deletion.comment')}`}
                />
              </Line>
            </form>
          )}
        />
      </Wrapper>
    </EditPage>
  ) 
}

import React, {useEffect, useState} from 'react';
import { Form, Field } from 'react-final-form'
import { useLocation } from 'react-router-dom'
import { TextButton } from 'common/UiKit/TextButton'
import { Colors } from 'constants/colors'
import { Paper } from 'common/UiKit/Paper'
import styled from 'styled-components'
import { FormattedMessage, useIntl } from 'react-intl'
import {useDispatch, useSelector} from 'react-redux'
import { PatientCalendar } from '../../Patient/Doctors/AppointmentConsultation/Calendar'
import { AttachmentsField } from './AttachmentsField'
import { PatientField } from './PatientField'
import {clearDraftConsultation, createConcilium} from '../../../../redux/modules/consultations'
import { MultilineTextField } from '../../../common/MultilineTextField'
import { CheckboxField } from '../../../common/CheckboxField'
import { DoctorAvailabilities } from "../../Patient/Doctors/AppointmentConsultation/DoctorAvailabilities";
import axios from "axios";
import { history } from "../../../../utils/history"
import RadioButtonsGroup from './RadioButtonsGroup'
import { TextField } from 'common/UiKit/TextField'
import {UPLOAD_FILE_MAX_SIZE} from "../../../../constants/uploads";
import SendIcon from '@mui/icons-material/Send';
import {SYMPTOMS_MAX_LENGTH} from "../../../../constants/consultation";
import {BUTTON_COLORS} from "../../../../constants/colors";
import {InviteDatePicker} from "./InviteDatePicker";
import moment from "moment-timezone";
import {DraftConsultation} from "./DraftConsultation";
import {AVAILABLE_EXTENSIONS_STR} from "../../../../constants/extensions";


//#region Styled Components
const MultilineField = styled(MultilineTextField)`
  & .root {
    height: 100%;
  }
`

const StyledForm = styled.form`
  & > div {
    margin-bottom: 16px;
  }
`

const Terms = styled.label`
  display: flex;
  flex-direction : column;
  align-items: flex-start;
  margin-top: 24px;
  font-family: Roboto, sans-serif;
  font-size: 14px;
  color: ${Colors.blueGrey};
  line-height: 1.29;
  padding-bottom: 15px;
  


  & > div {
    flex-grow: 1;
  }
`

const Checkbox = styled(CheckboxField)`
  && {
    height: 24px;
  }
`

const TextButtonStyle = styled.div`
  display: flex;
  justify-content: flex-end;
  button {
    text-transform: none;
    margin: 0px 5px 20px 0px;
    font-size: 14px;
    width: 288px;
    height: 40px;
    background-color: ${BUTTON_COLORS.purple};
    color: #fff;
  }
  button:hover {
    background-color: ${BUTTON_COLORS.purpleHover};
    color: #fff;
  }
`

const SendIconStyled = styled(SendIcon)`
  margin-right: 12px;
`

const normalizeAvailabilities = function(listAvailabilities) {
  return listAvailabilities.reduce((acc, datetime) => {
    let datetimelocale = moment(datetime).local()
    if(datetimelocale.isValid()) {
      if(!Object.keys(acc).find((e) => e === datetimelocale.format('Y-MM-DD'))){
        acc[datetimelocale.format('Y-MM-DD')] = []
      }
      acc[datetimelocale.format('Y-MM-DD')][datetimelocale.format('HH:mm')] = {date_time: datetimelocale.format('Y-MM-DD HH:mm')}
    }
    return acc
  }, [])
}
//#endregion

const convertToMB = (bytes = 0) => bytes / 1024 / 1024;

export const SlotIsFree = async (slot, doctorId) => {
  const datetime = moment(slot, null).tz(Intl.DateTimeFormat().resolvedOptions().timeZone).utc().format('YYYY-MM-DD HH:mm:ss')
  return new Promise ((resolve, reject) =>
  {
    if(!slot) {
      resolve(<FormattedMessage id='error.te.time' />)
    } else if (moment().isAfter(moment(slot))) {
      resolve(<FormattedMessage id='error.te.past.time' />)
    } else if(datetime !== 'Invalid date') {
      axios.get(`/api/doctor/calendar/slot/free/${doctorId}`, {params: {datetime}})
        .then((response) => {
          resolve(!!response.data.isFree ? undefined : <FormattedMessage id='error.invite.slot.unavailable' />)
        })
        .catch((e) => {
          reject(e)
        })
    } else {
      resolve( <FormattedMessage id='error.te.time.invalid' />)
    }
  })
}

export const CreateConciliumForm = ({ doctorId, isUrgent, available, type_employment,  isTCA, teamType }) => {
  function useQuery() {
    return new URLSearchParams(useLocation().search);
  }

  const { formatMessage } = useIntl()
  const [hospitalized_patient, toggleHospitalizedPatient] = useState(false)

  const dispatch = useDispatch()
  const query = useQuery();
  const f = id => formatMessage({ id })
  const proposedDate = query.get('proposedDate');
  const onSubmit = (values) => {
    return dispatch(createConcilium({
      ...values,
      doctorId,
      tcaActContextNurse,
      hospitalized_patient,
      isInvitation: isTCA && (!list || Object.keys(list).length < 1) ? 1 : 0,
    }, isUrgent, isTCA, isTCAAndNurse, isTCAPharma, teamType))
  }
  const [list, setList] = useState(null)
  const isTCAAndNurse = useSelector(_ => {
    const specializationKey = _.auth.attributes.specialization_key;
    return ( specializationKey === 'Infirmière' || specializationKey === 'Infirmier(ère) en Pratique Avancée' )
  })

  const isTCAPharma = useSelector(_ => {
    const specializationType = _.auth.attributes.specialization_type;
    return ( specializationType === 'pharmaceutical' )
  })

  const [tcaActContextNurse, setTcaActContextNurse] = useState('TLS')
  const url = isTCA ? "TCA" : "TE";

  const symptoms = useSelector(state => state.consultations.draft.consultation?.attributes?.symptom_explanation)
  const draftConsultation = useSelector(state => state.consultations.draft.consultation)
  const isDraft = typeof draftConsultation !== 'undefined'
  useEffect(() => {
    if (isDraft) {
      return () => dispatch(clearDraftConsultation())
    }
  }, [isDraft]);

  const [progressAttachment, setProgressAttachment] = useState(0)

  const validate = (values) => {
    const errors = {}
    if (!values.proposedDate && !isUrgent) {
      errors.proposedDate = <FormattedMessage id='error.te.time' />
    }

    if (!values.symptomExplanation && !isTCA) {
      errors.symptomExplanation = <FormattedMessage id='error.te.description' />
    }

    if (values.symptomExplanation && values.symptomExplanation.length > SYMPTOMS_MAX_LENGTH) {
      errors.symptomExplanation = <FormattedMessage id='error.te.description.long' />
    }

    if (!values.accessPatientInformed) {
      errors.accessPatientInformed = <FormattedMessage id='error.access.patient.informed' />
    }

    if (type_employment === 'merge' && !values.doctor_type_employment) {
      errors.doctor_type_employment = <FormattedMessage id='error.te.merged.field.required' />
    }

    if (!values.patient) {
      errors.patient = <FormattedMessage id='error.patient.required' />
    }

    if (values.files && values.files.length && values.files.length > 5) {
      errors.files = <FormattedMessage id='error.patient.required' />
    }

    if (values.files && values.files.length && values.files.some(el => convertToMB(el.size) > UPLOAD_FILE_MAX_SIZE)) {
      errors.files = <FormattedMessage id='error.only.n.size.allowed' />
    }

    return errors
  }

  useEffect(() => {
    (async function () {
      try {
        // Dispos TE
        const base = `/api/doctor/calendar/listavailabilities/${doctorId}?offset=${0}&limit=${7}&type=${url}`;
        const resp = await axios
            .get(`${base}`);
        if(resp.data && Object.keys(resp.data).length) {
          const newList = _.merge(list, normalizeAvailabilities(Object.values(resp.data.date_times)))
          setList({...newList})
        }
      } catch (e) {
        console.warn(e);
      }
    }());
  }, [])

  return (
    <Form
      onSubmit={onSubmit}
      validate={validate}
      subscription={{ submitting: true, pristine: true }}
      initialValues={{ proposedDate, symptomExplanation: symptoms }}
      render={({ handleSubmit, submitting }) => (
        <StyledForm onSubmit={handleSubmit}>
          {!isUrgent  && (
            <Paper heading='concilium.select.time'>
              {history.location.search ?
                <Field
                    available={available}
                    name='proposedDate'
                    component={PatientCalendar}
                    cellType='Available'
                    concilium
                    setTypeOfPrice={() => {}}
                    validateFields={[]}
                />
                  :
                (
                  <>
                    {
                      (!list || Object.keys(list).length < 1) && (
                        <Field
                          name='proposedDate'
                          validate={(value) => SlotIsFree(value, doctorId)}
                          component={InviteDatePicker}
                          validateFields={[]}
                        />
                      )
                    }
                    {
                      !!list && Object.keys(list).length >= 1 && (
                        <Field
                        name='proposedDate'
                        component={DoctorAvailabilities}
                        availabilitiesList={list}
                        isTCA={!!isTCA}
                        validateFields={[]}
                        />
                      )
                    }
                  </>
                )
              }
            </Paper>
          )}
          <Field
            name='patient'
            component={PatientField}
            hospitalized_patient={hospitalized_patient}
            toggleHospitalizedPatient={toggleHospitalizedPatient}
            validateFields={[]}
          />
          {
            !isTCA
            &&
            <Paper heading='concilium.describe'>
              <Field
                component={MultilineField}
                name='symptomExplanation'
                label={`*${formatMessage({id: 'concilium.describe'})}`}
                multiline
                minRows={2}
                maxLength={SYMPTOMS_MAX_LENGTH}
                validateFields={[]}
                defaultValue={isDraft ? draftConsultation.attributes.symptom_explanation : ''}
              />
            </Paper>
          }
          {
            type_employment === 'merge' && (
              <Paper heading='concilium.merged.statut.title'>
                <Field
                  name='doctor_type_employment'
                  component={TextField}
                  select
                  label={`*${f('profile.method')}`}
                  options={[
                    { label: <FormattedMessage id='profile.salary' />, value: 'hospital' },
                    { label: <FormattedMessage id='profile.liberal' />, value: 'private' },
                  ]}
                  validateFields={[]}
                />
              </Paper>
            )
          }
          <Field 
            name='files' 
            onlyAttachmentId
            accept={AVAILABLE_EXTENSIONS_STR}
            component={AttachmentsField}
            validateFields={[]}
            progress={progressAttachment}
            setProgress={setProgressAttachment}
          />
          {
            (isTCAAndNurse && isTCA) &&
            <Paper heading='tca.act.context'>
              <Field
                name='tcaActContextNurse'
                setTcaActContextNurse={setTcaActContextNurse}
                component={RadioButtonsGroup}
                color='primary'
                type='radio'
                validateFields={[]}
              />
            </Paper>
          }
          {
            isDraft && <DraftConsultation />
          }
          <Terms>
            <Field
              name='accessPatientInformed'
              component={Checkbox}
              color='primary'
              type='checkbox'
              label={(
                <FormattedMessage
                  id={ isTCA ? 'concilium.patient.access.TCA' : 'concilium.patient.access'}
                />
              )}
              validateFields={[]}
            />
          </Terms>
          { isTCA ?
            <TextButtonStyle>
              <TextButton
                color='primary'
                type='submit'
                style = {{textTransform : 'none'}}
                disabled={submitting || (progressAttachment!==0 && progressAttachment!==100)}
                isFetching={submitting}
              >
                <FormattedMessage id={available ? 'concilium.create.TCA' : 'tca.send.invite'}/>
              </TextButton>
            </TextButtonStyle>
            :
            <TextButtonStyle>
              <TextButton
                color='primary'
                type='submit'
                style = {{textTransform : 'none'}}
                disabled={submitting || (progressAttachment!==0 && progressAttachment!==100)}
                isFetching={submitting}
              >
                {isUrgent ?
                  <>
                    <SendIconStyled/>
                    <FormattedMessage id='concilium.start.urgent'/>
                  </>
                  :
                  <FormattedMessage id='concilium.start'/>
                }
              </TextButton>
            </TextButtonStyle>
          }
        </StyledForm>
      )}
    />
  )
}



import { handleActions, createAction } from 'redux-actions'
import { combineReducers } from 'redux'
import axios from 'axios'
import pickBy from 'lodash/pickBy';
import queryString from 'query-string';
import {fetchFavoriteStructures} from "./doctors";
import {fetchTeamsListSuccess} from "./teams";

//#region Actions
export const fetchStructuresListRequest = createAction('FETCH_STRUCTURES_LIST_REQUEST')
export const fetchStructuresListSuccess = createAction('FETCH_STRUCTURES_LIST_SUCCESS')
export const fetchStructuresListFailure = createAction('FETCH_STRUCTURES_LIST_FAILURE')

export const fetchPaginatorStructuresListSuccess = createAction('FETCH_PAGINATOR_STRUCTURES_LIST_SUCCESS')

let ajaxRequest = null;

export const fetchStructures = (filter = {}, abortController = {}) => (dispatch, getStore) => {
  dispatch(fetchStructuresListRequest())

  if(abortController.current){
    abortController.current.abort()
  }
  abortController.current = new AbortController();

  // abort previous request if not finished
  if(ajaxRequest) {
    ajaxRequest.cancel();
  }

  ajaxRequest = axios.CancelToken.source();

  const url = '/api/doctor/structure/list'

  const params =
    {
      ...filter,
      cancelToken: ajaxRequest.token,
    }

  const location = getStore().geoloc.reverseGeocoding;
  if (location) {
    params.latitude = location.latitude;
    params.longitude = location.longitude;
  }

  return axios(url, {
    params,
    signal: abortController.current.signal
  })
    .then((res) => {
      dispatch(fetchStructuresListSuccess(res.data.data))
      dispatch(fetchPaginatorStructuresListSuccess(res.data.paginator))
    })
    .catch(e => {
        if (!abortController.current.signal.aborted) {
          dispatch(fetchStructuresListFailure(e))
        }
    })
}

export const clearStructuresList = createAction('CLEAR_STRUCTURES_LIST')

export const clearStructures = () => (dispatch) => {
  dispatch(clearStructuresList())
}


export const toggleFavoriteStructureRequest = createAction('TOGGLE_FAVORITE_STRUCTURE_REQUEST')
export const toggleFavoriteStructureSuccess = createAction('TOGGLE_FAVORITE_STRUCTURE_SUCCESS')
export const toggleFavoriteStructureFailure = createAction('TOGGLE_FAVORITE_STRUCTURE_FAILURE')

export const toggleFavoriteStructure = (id, value) => (dispatch) => {
  dispatch(toggleFavoriteStructureRequest({ id, value }))
  return axios(`/general/patient/favorite_structure/${id}`, { method: value ? 'POST' : 'DELETE' })
    .then(response => {
      dispatch(toggleFavoriteStructureSuccess(response.data))
    })
    .catch((err) => {
      dispatch(toggleFavoriteStructureFailure(err))
    })
}

//#endregion

//#region Reducers
const paginator = handleActions({
  [fetchPaginatorStructuresListSuccess]: (state, action) => action.payload,
  [clearStructuresList]: () => null,
}, [])

const list = handleActions({
  [fetchStructuresListSuccess]: (state, action) => action.payload,
  [toggleFavoriteStructureSuccess]: (state, action) => state.map((item) => {
    if (item.id === action.payload.data.id) {
      return {
        ...item,
        attributes: { ...item.attributes, favorite: action.payload.data.attributes.favorite },
      }
    }
    return item
  }),
  [clearStructuresList]: () => [],
}, [])

export const structures = combineReducers({
  paginator,
  list,
})
//#endregion

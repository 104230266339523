import React from 'react'
import { Route } from 'react-router-dom'
import { EditPricesConsultations } from './EditPricesConsultations'
import { EditPersonalData } from './EditPersonalData'
import { EditAboutDoctor } from './EditAboutDoctor'
import { EditDiplomas } from './EditDiplomas'
import { EditAdditionalInformation } from './EditAdditionalInformation'
import { EditSchedule } from './EditSchedule'
import { EditSignature } from './EditSignature'
import { EmailManagement } from './EmailManagement'
import { EditSkills } from './EditSkills'
import {ExternalPartners} from "../ProfileInfo/ExternalPartners";
import {EditExternalPartners} from "./EditExternalPartners";

export const Edit = () => (
  <>
    <Route path='/panel/profile/edit/personal_data' component={EditPersonalData} />
    <Route path='/panel/profile/edit/about_doctor' component={EditAboutDoctor} />
    <Route path='/panel/profile/edit/diplomas' component={EditDiplomas} />
    <Route path='/panel/profile/edit/additional_information' component={EditAdditionalInformation} />
    <Route path='/panel/profile/edit/schedule' component={EditSchedule} />
    <Route path='/panel/profile/edit/prices' component={EditPricesConsultations} />
    <Route path='/panel/profile/edit/signature' component={EditSignature} />
    <Route path='/panel/profile/edit/skills' component={EditSkills} />
    <Route
      path='/panel/profile/edit/email-management'
      exact
      render={props => <EmailManagement {...props} />}
    />
    <Route path='/panel/profile/edit/partners' component={EditExternalPartners} />
  </>
)

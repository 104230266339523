import get from 'lodash/get';

const chatRoutes = {
  auth: 'User\\Auth',
  chat: 'Chat\\Message',
  call: 'Consultation\\Call',
  keepAlive: 'Core\\Connection',
}

class ChatSocket {
  constructor() {
    this.token = 0
    this.chatid = 0
    this.userid = 0
    this.socket = {}
    this.reconnectAttempts = 0
    this.messageReceived = ''
    this.handleMessage = undefined

    this.connecting = false
    this.authorizedValue = false
    this.timeToSocketReconnect = false
    this.connect = this.connect.bind(this)
    this.onMessage = this.onMessage.bind(this)
    this.reconnect = this.reconnect.bind(this)
    this.queue = []
    this.authQueue = []
  }

  get authorized() {
    return this.authorizedValue
  }

  set authorized(value) {
    this.authorizedValue = value
    if (value) {
      this.handleQueue()
    }
  }

  initialize(token, handleMessage, userid) {
    this.token = token
    this.userid = userid
    this.handleMessage = handleMessage

    if (!this.connecting && get(this, 'socket.readyState', false) !== 1) {
      this.connect()
    }
  }

  handleQueue() {
    this.queue.forEach(callback => callback())
    this.queue = []
  }

  handleAuthQueue() {
    this.authQueue.forEach(callback => callback())
    this.authQueue = []
  }

  reconnect() {
    console.log('socket reconnecting...');
    if (this.reconnectAttempts < 3) {
      this.reconnectAttempts += 1
      this.timeToSocketReconnect = 3000
      const event = new CustomEvent('socketClosed', { detail: this.timeToSocketReconnect / 1000 });
      document.dispatchEvent(event);
      clearTimeout(this.timerReconnect);
      this.timerReconnect = setTimeout(() => {
        this.connecting = false
        this.connect()
      }, 3000)
    }
  }

  connect(callback, waitForAuthorization = false) {
    const socketState = get(this, 'socket.readyState', false)
    if (!this.connecting && (socketState === false || socketState === 3)) {
      try {
        this.connecting = true
        this.closeConnection()
        this.socket = new WebSocket(window.env.WEBSOCKET_URL)

        this.socket.onopen = () => {
          const eventConnected = new CustomEvent('socketConnected');
          document.dispatchEvent(eventConnected);
          this.timeToSocketReconnect = false;
          console.log('Socket connected!');
          this.socket.onmessage = this.onMessage
          this.reconnectAttempts = 0
          this.authorize()
          this.connecting = false
          this.handleQueue()
        }
        this.socket.onerror = (err) => {
          console.error('Socket error', err);
          return this.reconnect
        }
        this.socket.onclose = (e) => {
          console.warn('Socket has been closed!', e);
          this.connecting = false
          const event = new CustomEvent('socketClosed', { detail: this.timeToSocketReconnect / 1000 });
          document.dispatchEvent(event);
          return this.reconnect
        }

        if (typeof callback !== 'undefined' && callback instanceof Function) {
          if (waitForAuthorization) {
            this.authQueue = [...this.authQueue, callback]
          } else {
            this.queue = [...this.queue, callback]
          }
        }
      } catch (err) {
        console.error('Socket error -', err);
        this.reconnect()
      }
    } else if (typeof callback !== 'undefined' && callback instanceof Function) {
      if (waitForAuthorization) {
        this.authQueue = [...this.authQueue, callback]
      } else {
        this.queue = [...this.queue, callback]
      }
    }
  }

  closeConnection() {
    if (this.socket) {
      try {
        this.socket.close()
        this.socket = null
      } catch (ignored) {
      }
    }
  }

  markAsRead(chatId, messages = [], onMessageSent = null) {
    this.sendMessage({
      route: chatRoutes.chat,
      action: 'read',
      data: {
        chat_id: chatId,
        messages: messages
      }
    }, onMessageSent)
  }

  onMessage(message) {
    try {
      this.messageReceived = JSON.parse(message.data)
    } catch (err) {
      console.error('Failed to parse remote message', err)
      return;
    }

    if (this.handleMessage) {
      this.handleMessage(this.messageReceived)
    }

    if (this.messageReceived && this.messageReceived.action == "authorization" && this.messageReceived.code == "200") {
      this.authorized = true
      this.handleAuthQueue();
    } else if (this.authQueue.length > 0) {
      this.authQueue = []
    }
  }

  sendMessage(msg, onMessageSent = null) {
    const jsonMessage = JSON.stringify(msg)
    if (this.connecting || get(this, 'socket.readyState', false) !== 1) {
      this.connect(() => this.sendMessage(msg), true)
    } else {
      try {
        this.socket.send(jsonMessage)
        if (onMessageSent) {
          onMessageSent()
        }
      } catch (e) {
        console.error(e, jsonMessage)
      }
    }
  }

  authorize() {
    let msg = {
      route: chatRoutes.auth,
      action: "authorization",
      data: {
        token: this.token,
      },
    };

    this.socket.send(JSON.stringify(msg));
  }

  keepAlive() {
    this.sendMessage({
      route: chatRoutes.keepAlive,
      action: 'healthlife',
    })
  }

  connectChat(id, isDiscussion) {
    this.sendMessage(chatRoutes.chat, 'chatConnect', {
      type: isDiscussion ? 'discussion' : 'consultation',
      id,
    })
  }

  sendTextMessage(chatId, message) {
    this.sendMessage({
      route: chatRoutes.chat,
      action: 'send',
      data: {
        chat_id: chatId,
        type: 'Text',
        message: message
      }
    })
  }

  sendFileMessage(chatId, fileId) {
    this.sendMessage({
      route: chatRoutes.chat,
      action: 'send',
      data: {
        chat_id: chatId,
        type: 'File',
        message: {
          attachment_id: fileId,
        }
      }
    })
  }

  sendPrescriptionMessage(chatId, fileId) {
    this.sendMessage({
      route: chatRoutes.chat,
      action: 'send',
      data: {
        chat_id: chatId,
        type: 'Prescription',
        message: {
          attachment_id: fileId,
        }
      }
    })
  }

  loadHistory(chatId, last) {
    this.sendMessage({
      route: chatRoutes.chat,
      action: 'loadMessage',
      data: {
        chat_id: chatId,
        last,
      }
    })
  }

  notify(chatId) {
    this.sendMessage({
      route: chatRoutes.chat,
      action: 'newMessage',
      data: {
        chat_id: chatId,
      }
    })
  }

  call(consultation) {
    this.sendMessage({
      route: chatRoutes.call,
      action: 'call',
      data: {
        consultation: consultation
      }
    })
  }

  cancelCall(consultation, message) {
    this.sendMessage({
      route: chatRoutes.call,
      action: 'cancel',
      data: {
        consultation: consultation,
        message: message || ' ',
      }
    })
  }
}

export const socket = new ChatSocket()
export const DefaultAvatar = (firstname, lastname, type) => {

    let color
    if (type=="specialist" || type=="generalist") {
        color = "#DD0A0A"
    } else if (type=="paramedical") {
        color = "#094BF3"
    } else if (type=="pharmaceutical") {
        color = "#009621"
    } else if (type=="others") {
        color = "#C62EFB"
    } else {
        color = "#ada4a4"
    }

    let name= (firstname ? firstname.charAt(0).toUpperCase() : '')  + (lastname ? lastname.charAt(0).toUpperCase() : '');

    let size = 500

    const canvas=document.createElement('canvas')
    const context=canvas.getContext('2d')
    canvas.width=canvas.height=size

    context.fillStyle="#ffffff"
    context.fillRect(0,0,size,size)

    context.fillStyle=`${color}99`
    context.fillRect(0,0,size,size)

    context.fillStyle="#ffffff";
    context.textBaseline='middle'
    context.textAlign='center'
    context.font =`250px Roboto`
    context.fillText(name,(size/2),(size/2 + 25))
    return canvas.toDataURL()
};
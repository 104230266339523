import {Dialog, DialogContent, DialogTitle} from "@material-ui/core";
import styled from "styled-components";
import React, {useEffect, useState} from "react";
import PropTypes from "prop-types";
import {Colors} from "../../../../../../../constants/colors";
import DialogActions from "@mui/material/DialogActions";
import ClearIcon from "@mui/icons-material/Clear";
import {startCibaAuth} from "../../../../../../../helpers/CibaProtocol/ciba";
import {FormattedMessage} from "react-intl";
import {TextButton} from "../../../../../../common/UiKit/TextButton";
import {CircularProgress} from "@mui/material";
import {Countdown} from "../../../../../../../helpers/Countdown";
import {pscPostLogin, pscSubscribe} from "../../../../../../../redux/modules/auth";
import {useDispatch, useSelector} from "react-redux";
import {authPSC} from "../../../../../../../redux/modules/proSanteConnect";
import idx from "idx";

export const DialogAuthMss = ({
  open,
  onClose,
  onConfirm,
  error,
  randomCode
}) => {
  const dispatch = useDispatch()

  const consultationId = useSelector(state => idx(state, _ => _.consultations.consultation.id))

  return (
    <DialogStyled
      open={open}
      onClose={onClose}
      maxWidth='lg'
      PaperProps={{
        style: {
          minWidth: '25%',
          maxWidth: '40%',
          border: `1px solid ${Colors.bluePurple}`,
          padding: '10px',
        }
      }}
      BackdropProps={{
        style: {
          opacity: 0,
        }
      }}
    >
      <>
        <StyledDialogTitle disableTypography>
          <StyledTitle>
            <h3>
              <FormattedMessage id="consultation.auth.mss.dialog.title"/>
            </h3>
          </StyledTitle>
          <StyledClearIcon onClick={onClose}><ClearIcon/></StyledClearIcon>
        </StyledDialogTitle>
        <DialogContentStyled>
          <Paragraph>
            <GreyStyle>
              <FormattedMessage id='mss.slogan.identification.eCPS' />
            </GreyStyle>
            <a href='#' onClick={(e) => {
              e.preventDefault()
              dispatch(pscPostLogin(consultationId))
            }}>
              <img src={`${process.env.PUBLIC_URL}/ProSanteConnect_sidentifier_COULEURS.svg`} width='240'
                   alt="Se connecter ou s'inscrire avec Pro Santé Connect" />
            </a>

          </Paragraph>

          {error && (
            <ErrorMessage>
              <FormattedMessage id ="consultation.auth.mss.error"/>
            </ErrorMessage>
          )}
        </DialogContentStyled>
      </>
    </DialogStyled>
  )
}

DialogAuthMss.propTypes = {
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  onConfirm: PropTypes.func.isRequired,
}

export const DialogStyled = styled(Dialog)`
  backdrop-filter: blur(3px);
`

const StyledDialogTitle = styled(DialogTitle)`
  font-family: Roboto, sans-serif;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 20px;
  font-weight: 500;
  opacity: 0.9;
  color: ${Colors.dark80};
  border-bottom: 1px solid ${Colors.cloudyBlue};  
`

const StyledClearIcon = styled.div`
  cursor: pointer;
`

const StyledTitle = styled.div`
  margin: auto;
`

const DialogContentStyled = styled(DialogContent)`
  display: flex;
  flex-direction: column;
  justify-content: center;
`

const DialogActionsStyled = styled(DialogActions)`
  display: flex;
  justify-content: center;
  align-items: center;
`

const AuthCodeBlock = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  border: 2px solid ${Colors.bluePurple};
  font-size: 35px;
  font-weight: 500;
  border-radius: 5px;
  height: 50px;
  width: 100px;
  font-family: Roboto, sans-serif;
  margin: auto;
`

const Paragraph = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  font-family: Roboto, sans-serif;
`

const ErrorMessage = styled.div`
  padding: 20px 0;
  color: ${Colors.red};
  display: flex;
  justify-content: center;
`

const GreyStyle = styled.h4`
 && {
    font-weight: 500;
    font-family: Roboto, sans-serif;
    color: #A6A39E;
    font-size: 16px;
    margin-top: 15px;
    margin-bottom: 15px;
   padding: 0 40px;
  }
`

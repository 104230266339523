import React, { useRef, useImperativeHandle } from 'react'
import {compose, lifecycle, withProps} from 'recompose'
import styled from 'styled-components'
import { connect } from 'react-redux';
import {clearStructures, toggleFavoriteStructure,fetchStructures} from '../../../redux/modules/structures'
import {Colors} from "../../../constants/colors";
import {EmptyScreen} from "../../Panel/Patient/Doctors/EmptyScreen";
import {StructureCardNew} from "../StructureCard/StructureCardNew";

const Content = styled.div`
  margin: 0 auto;
  min-width: 100%;
  overflow-y: scroll;
`

const Wrapper = styled.div`
   & > * {
    border: ${props => (props.outlined ? `2px solid ${Colors.bluePurple}` : '')};
    margin-bottom: 24px;
  }
`

export const StructureListComponent = ({
                                         structures,
                                         favoriteToggle,
                                         toggleFavoriteStructure,
                                         match,
                                         idSelected,
                                         forwardedRef,
                                         isDoctor,
                                    }) => {

  const refs = useRef({})

  useImperativeHandle(forwardedRef, () => ({
    focusRef: (id) => {
      refs.current[id].scrollIntoView({
        behavior: 'smooth',
        block: 'start',
      });
    }
  }));

  return (
    <>
      {
        structures.length ? (
            <Content>
              {structures.map(({ attributes, id, relationships }) => (
                <Wrapper ref={ref => (refs.current[id] = ref)} outlined={idSelected === id} >
                  <StructureCardNew
                    id={id}
                    name={attributes.name}
                    members={attributes.members}
                    type={attributes.type}
                    address={attributes.address}
                    zipcode={attributes.zipcode}
                    city={attributes.city}
                    disableButtons={true}
                    isNotClickable={false}
                    photo={attributes.photo}
                    isFavorite={attributes.favorite}
                    isFavoriteFetching={favoriteToggle === id}
                    toggleFavorite={value => toggleFavoriteStructure(id, value)}
                    showMessageFavorite={false}
                    distance={attributes.distance}
                    isDoctor={isDoctor}
                  />
                </Wrapper>
              ))}
            </Content>
          ) :
          <EmptyScreen/>
      }
    </>
  )
}

const enhance = compose(
  connect(
    (state, props) => ({
      structures: state.structures.list,
      favoriteToggle: state.loading.favoriteToggle,
      isDoctor: state.auth.type === 'Doctor',
    }),
    {
      fetchStructures,
      clearStructures,
      toggleFavoriteStructure,
    },
    null,
    { forwardRef: true }
  ),
  withProps(props => ({
  })),
  lifecycle({
    componentDidMount() {
      this.props.clearStructures()
    },
  }),
)

export const StructureList = enhance(StructureListComponent)

export const StructureListWithRef = React.forwardRef(({...props}, ref) => <StructureList {...props} forwardedRef={ref} />)

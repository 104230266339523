import React from 'react'

export class ErrorBoundary extends React.Component {
  constructor(props) {
    super(props);
    this.state = { error: false };
  }

  static getDerivedStateFromError(error) {
    return { error };
  }

  componentDidCatch(error, errorInfo) {
    console.error('Cached component error', error)
  }

  render() {
    if (this.state.error && this.state.error.toString) {
      return (
        <pre style={{ color: 'red' }}>
          {this.state.error.toString()}
          {this.state.error.stack}
        </pre>
      )
    }
    const { children } = this.props
    return children
  }
}

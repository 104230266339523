import PropTypes from 'prop-types'
import React from 'react'
import styled from 'styled-components'
import ArrowBack from '@mui/icons-material/ArrowBack'
import IconButton from '@mui/material/IconButton'
import Clear from '@mui/icons-material/Clear'

import { paleGrey, waterBlue } from '../../../constants/colors'
import logo from "../../../images/auth/long-logo-conex-sante.png";



export const AuthDialog = ({
  backIcon,
  children,
  onIconClick,
}) => (
  <Wrapper>
    {/*<Header>*/}
    {/*  <Logo src={logo} />*/}
    {/*</Header>*/}
    {!!onIconClick && (
      <IconButtonStyled style={!backIcon && {display: 'none'}} color='inherit' onClick={onIconClick}>
        <ArrowBack fontSize='inherit' />
      </IconButtonStyled>
    )}
    {children}
  </Wrapper>
);

AuthDialog.defaultProps = {
  backIcon: true,
  onIconClick: undefined,
}

AuthDialog.propTypes = {
  backIcon: PropTypes.bool,
  children: PropTypes.node.isRequired,
  heading: PropTypes.any.isRequired,
  onIconClick: PropTypes.func,
}


//----------------------------------------------------------------------CSS-----------------------------------------------------------

const Wrapper = styled.div`
  height: 50vh;
  max-height: 700px;
  min-height: 400px;
  display: flex;
  flex-direction: column;
  justify-content:  center;
  align-items: center;
`

const Header = styled.h1`
  @media screen and (max-width: 1720px) {
    margin-top: calc( 8vh - 120px);
  }
  margin-top: 80px;
  text-shadow: 0 1px 2px ${waterBlue};
  font-family: Roboto, sans-serif;
  font-size: 32px;
  font-weight: bold;
  letter-spacing: 0.5px;
  text-align: center;
  color: white;
`

const IconButtonStyled = styled(IconButton)`
  && {
    font-size: 41px;
    color: blue;
    position: relative;
    right:330px;
    z-index: 10001;
  }
`

const Logo = styled.img`
  width: 400px;
`
import React, {useState} from 'react'
import styled from 'styled-components'
import {Dialog, DialogContent, DialogTitle} from "@material-ui/core";
import {FormattedMessage, useIntl} from "react-intl";
import GroupsIcon from "@mui/icons-material/Groups";
import {Colors} from "../../../../../constants/colors";
import CloseIcon from "@mui/icons-material/Close";
import IconButton from "@mui/material/IconButton";
import {TextButton} from "../../../../common/UiKit/TextButton";
import {useDispatch} from "react-redux";
import {createGroupDiscussion, modifyDiscussionName} from "../../../../../redux/modules/discussions";
import {history} from "../../../../../utils/history";

const DialogStyled = styled(Dialog)`
  backdrop-filter: blur(2px);
`

const IconButtonStyled = styled(IconButton)`
  width: 5px;
  height: 5px;
  margin-top: 5px;
  margin-right: 5px;
  margin-left: 10px;
  padding: 20px 20px 20px 20px;
  color: ${Colors.steel};
  position: absolute;
  top: 0;
  right: 0;
`

const DialogTitleStyled = styled(DialogTitle)`
  font-family: Roboto, sans-serif;
  display: flex;
  font-size: 18px;
  font-weight: 500;
  color: black;
`

const TitleStyled = styled.div`
  margin-top: 10px;
  margin-left: 20px;
`

const GroupIconStyled = styled(GroupsIcon)`
  justify-self: center;
  min-width: 40px;
  width: 40px;
  height: 40px;
  padding: 3px;
  box-sizing: border-box;
  border-radius: 100%;
  border: 2px solid ${Colors.bluePurple};
  background-color: rgba(147, 132, 189, 0.5);
  color: ${Colors.bluePurple};
  grid-row: 1/3;
  grid-column: 1;
`

const Subtitle = styled.div`
  font-size: 16px;
  color: black;
  margin-left: 10px;
`

const WrapperSearch = styled.div`
  width: 60%;
  position: relative;
  margin: 10px 0 0 0;
  & > svg {
    position: absolute;
    left: 15px;
    top: 13px;
    fill: ${Colors.bluePurple};
  }
`

const Input = styled.input`
  background-color: white;
  border: 1px solid ${Colors.textMuted};
  border-radius: 3px;
  outline: none;
  height: 40px;
  padding: 16px 16px 16px 16px;
  box-sizing: border-box;
  width: 550px;
  font-size: 14px;
  font-weight: 500;
  color: black;
  ::placeholder {
    color: black;
    font-size: 14px;
    font-weight: 500;
  }
`

const CreateButton = styled(TextButton)`
  box-shadow: none;
  align-self: center;
  padding-top: 5px;
  padding-bottom: 5px;
  && {
    width: 20%;
    height: 35px;
    margin-top: 30px;
    margin-bottom: 20px;
    background-color: ${Colors.bluePurple};
    border: 1px solid white;
    color: white;
  }
  &:hover{
    background-color: rgb(71, 35, 178);
    border: 1px solid white;
    color: white;
  }
  text-transform : none;
  font-weight: 500;
  font-size: 14px;
`

export const AddName = ({showModal, onHide, membersAdded, modifyName, discussionId, initialName}) => {
    const { formatMessage } = useIntl()
    const dispatch = useDispatch()
    const f = id => formatMessage({ id: id })
    const [groupName, setGroupName] = useState(initialName ?? '');
    let members = [];

    const handleClose = () => {
        onHide();
    }

    const handleChange = (e) => {
        e.preventDefault();
        setGroupName(e.target.value);
    }

    if (!modifyName) {
        members = membersAdded.map((member) => member.data.attributes.user_id)
    }

    const createDiscussionCall = () => {
      dispatch(createGroupDiscussion(members, groupName))
        .then((res) => {
          handleClose();
          history.push('/panel/discussions/' + res.payload.id)
        })
    }

    const modifyDiscussionNameCall = () => {
      dispatch(modifyDiscussionName(discussionId, groupName))
        .then((res) => {
          handleClose();
            history.push('/panel/discussions/' + res.payload.id)
        })
    }

    return (
        <DialogStyled
            open={showModal}
            onClose={handleClose}
            maxWidth='lg'
            fullWidth
            PaperProps={{
                style: {
                    width: '600px',
                    height: '250px',
                    border: `1px solid ${Colors.bluePurple}`,
                    padding: '10px',
                }
            }}
            BackdropProps={{
                style: {
                    opacity: 0,
                }
            }}
        >
            <>
                <DialogTitleStyled disableTypography>
                    <IconButtonStyled onClick={handleClose}>
                        <CloseIcon/>
                    </IconButtonStyled>
                    <GroupIconStyled/>
                    <TitleStyled><FormattedMessage id={modifyName ? 'modify.group.name' : 'new.group.title'}/></TitleStyled>
                </DialogTitleStyled>
                <DialogContent>
                    <Subtitle>
                        <FormattedMessage id='new.group.name'/>
                    </Subtitle>
                    <WrapperSearch>
                        <Input
                            placeholder={f('staff.group.name.edit')}
                            type="text"
                            maxLength="50"
                            onChange={handleChange}
                            value={groupName}
                        />
                    </WrapperSearch>
                </DialogContent>
                <CreateButton onClick={
                    () => {
                        modifyName ? modifyDiscussionNameCall() : createDiscussionCall()
                    }
                }>
                    <FormattedMessage id={modifyName ? 'modify.group.name.button' : 'new.group.create.button'}/>
                </CreateButton>
            </>
        </DialogStyled>
    )
}
import React, {useState, useEffect, useRef} from 'react'
import styled from "styled-components";
import Select from "react-select";
import {Colors} from "../../../../constants/colors";
import ToggleButton from '@mui/material/ToggleButton';
import ToggleButtonGroup from '@mui/material/ToggleButtonGroup';
import {fetchSpecializationsAvailable} from "../../../../redux/modules/content";
import {useDispatch, useSelector} from "react-redux";
import {useIntl} from "react-intl";
import FormControl from "@mui/material/FormControl";
import {Input} from "@mui/material";
import TextField from "@mui/material/TextField";
import MenuItem from "@mui/material/MenuItem";

export const Filters = ({filters, setFilters, page, setPage}) => {
  const [filterType, setFilterType] = useState('doctor')
  const [spe, setSpe] = useState('')
  const [options, setOptions] = useState(null)
  const dispatch = useDispatch()
  const specializations = useSelector(state => state.content.specializations)
  const { formatMessage } = useIntl()
  const f = id => formatMessage({ id })

  const usePrevious = (value) => {
    const ref = useRef();
    useEffect(() => {
      ref.current = value;
    });
    return ref.current;
  }

  const prevFilterType = usePrevious(filterType)
  const prevSpe = usePrevious(spe)

  const handleSelect = (e, type) => {
    if(e.target && e.target.name == 'specialization'){
      setSpe(e.target.value)
      setFilterType(e.target.name)
    } else {
      setSpe('')
      setFilterType(type ? type : 'all')
    }
  };

  useEffect(() => {
    dispatch(fetchSpecializationsAvailable(1))
  }, [])

  useEffect(() => {
    setOptions(specializations.map( spe => ({value: spe.id, label: spe.attributes.name})))
  },[specializations])

  useEffect(() => {
    if(prevFilterType != filterType || prevSpe != spe) {
      setPage(1)
      if(filterType === 'team') {
        setFilters({...filters, objectType: filterType, specialization: spe, page: 1, limitTeam: 50})
      } else {
        setFilters({...filters, objectType: filterType, specialization: spe, page: 1, limitTeam: 10})
      }
    }
  },[filterType, spe])

  return(
    <StyledToggleButtonGroup
      value={filterType}
      exclusive
      onChange={handleSelect}
    >
      <ToggleButton value={'all'}> {f('roso.aroundMe.all')} </ToggleButton>
      <ToggleButton value={'doctor'}> {f('roso.aroundMe.doctors')} </ToggleButton>
      <ToggleButton value={'team'}> {f('roso.aroundMe.teams')} </ToggleButton>
      <ToggleButton value={'structure'}> {f('roso.aroundMe.structures')} </ToggleButton>
      { options && options.length > 0 &&
        <StyledTextField
          onChange={e => setSpe(e.target.value)}
          defaultValue={''}
          inputnull={spe == '' ? 1 : 0}
          color="grey"
          className={"Select"}
          name='specialization'
          label={f('roso.aroundMe.specializations')}
          value={spe}
          select
        >
          {options.map((option) => (
            <MenuItem key={option.value} value={option.value}>
              {option.label}
            </MenuItem>
          ))}
        </StyledTextField>}
    </StyledToggleButtonGroup>
)
}

const StyledToggleButtonGroup = styled(ToggleButtonGroup)`
  display: flex;
  gap: 10px;
  height: 28px;
  
  & .MuiToggleButton-root {
    border : 1px solid ${Colors.textMuted} !important;
    border-radius: 20px !important;
    padding: 0 20px;
    background-color: white;
    font-weight: normal;
    font-size: 14px;
    text-transform: none;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    display: inline-block;

    &:hover {
        border : 1px solid #E4DFF4 !important;
        background-color: #E4DFF4;
        color: #0000008A;
    }
  }
  
    & .Mui-selected {
        border : 1px solid #3A247E !important;
        background-color: #3A247E;
        color: white;
  }
`

const StyledTextField = styled(TextField)`
  width: 200px;

 .MuiFormLabel-root {
    font-size: 14px;
    align-items: center;
    height: 28px;
    min-height: 28px;
    padding: 0px 20px;
    color: #0000008A !important; 
    transform: none !important;
    text-overflow: ellipsis;
    overflow: hidden;
    display: ${({inputnull}) => (inputnull ? 'flex' : 'none')} !important; 
  }
  
  .MuiSelect-select{
    padding: 0 !important;
  }
    
  .MuiInputBase-root {
    font-size: 14px;
    height: 28px;
    min-height: 28px;
    padding: 0px 20px;
    border: 1px solid ${({inputnull}) => (inputnull ? Colors.textMuted : '#3A247E')}  !important; 
    border-radius: 20px !important;
    cursor: pointer;
    background-color: ${({inputnull}) => (inputnull ? 'white' : '#3A247E')}  !important; 
    box-shadow: none;
    opacity: 1 !important;
    color: ${({inputnull}) => (inputnull ? '#3A247E' : 'white')} !important; 
   
    .MuiOutlinedInput-notchedOutline{
      border: 0;
    }

    .MuiSelect-icon {
      color: ${({inputnull}) => (inputnull ? Colors.textMuted : 'white')}  !important;  
    }
  }
`
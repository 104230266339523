import React from "react";
import { FormattedMessage } from 'react-intl';
import { CustomIframe } from '../../../common/CustomIframe/CustomIframe'
import {PanelBar} from "../../../common/PanelBar";
import {history} from "../../../../utils/history";
import styled from "styled-components";

const Wrapper = styled.div`
  width: 720px;
  margin: 24px auto 0;
`;
export const TutorialVideo = () => {


    return (
        <>
            <PanelBar label='panel.help.tuto' onBackIconClick={() => history.goBack()} />
            <Wrapper>
                <h2> <FormattedMessage id ='panel.help.tuto'/> </h2>
                <CustomIframe src="0Vaeek1yqGI" title="Tuto créer une demande de téléexpertise"></CustomIframe>
            </Wrapper>
        </>
    );
};
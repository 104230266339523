import React from 'react'
import PropTypes from 'prop-types'
import {Redirect, Route, Switch} from 'react-router-dom'
import { List } from './List'
import { Session } from './Session'
import { CurrentConsultation } from './Current'
import {useSelector} from "react-redux";


export const Consultations = ({ match }) => {
  const myUserStatus = useSelector(state => state.auth.attributes.status)
  return (
    <Switch>
      { myUserStatus === 'PRE_REGISTRATED' && (<Redirect to='/panel/profile/fill'/>) }
        <Route
            path={match.path}
            exact
            component={List}
        />
        <Route
        path={`${match.path}/session`}
        component={Session}
        />
        <Route
        path={`${match.path}/:id`}
        component={CurrentConsultation}
        />
    </Switch>
  )
}

Consultations.propTypes = {
  match: PropTypes.object.isRequired,
}

import React from 'react'
import Checkbox from '@mui/material/Checkbox'
import FormControl from '@mui/material/FormControl';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormHelperText from '@mui/material/FormHelperText';
import { FormattedMessage } from 'react-intl';

export const CheckboxField = ({
  input, meta, label = '', ...rest 
}) => (
  <FormControl error={meta.touched && meta.error}>
    <FormControlLabel
      control={(
        <Checkbox
          {...input}
          {...rest}
          // value prop have to be a string
          value={input.value ? 'true' : 'false'}
        />
      )}
      label={label}
    />
    {meta.touched && meta.error && (
      <FormHelperText>
        <FormattedMessage id={meta.error} defaultMessage={meta.error} />
      </FormHelperText>
    )}
  </FormControl>
)

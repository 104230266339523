import PropTypes from "prop-types";
import {TabContext} from "@mui/lab";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import React, {useEffect, useImperativeHandle, useState} from "react";
import styled from "styled-components";
import Grid from "@mui/material/Grid";
import {Colors} from "../../../constants/colors";
import {styled as styling} from "@material-ui/core/styles";
import Tooltip from "@mui/material/Tooltip";

/**
 *
 * @param footer
 * @param headerLeft
 * @param headerRight
 * @param tabs
 * @param doc2uOpen
 * @param resetTabs
 * @returns {JSX.Element}
 * @constructor
 */
export const ChatLayout = ({
                             footer,
                             headerLeft,
                             headerRight,
                             headerRightSize = 0,
                             subHeader = null,
                             subHeaderTabs = [],
                             tabs,
                             doc2uOpen = false,
                             resetTabs,
                           }) => {
  const [tabValue, setTabValue] = useState(0);
  const [largeWindow, setLargeWindow] = useState(true);
  const propsHeaderLeft = doc2uOpen ? {xs: 1.25, xl: 4} : (headerRightSize < 200 ? {xs: 5} : {sm: 5.5, md: 6, lg: 5, xl: 4})
  const propsTab = doc2uOpen ? {xs: 5.5, xl: 4} : (headerRightSize < 200 ? {xs: 4} : {sm: 3.5, md: 3, lg: 2, xl: 4})
  const propsHeaderRight = doc2uOpen ? {xs: 5.25, xl: 4} : (headerRightSize < 200 ? {xs: 3} : {sm: 3, lg: 5, xl: 4})

  //using the imperativeHandle hook to reset the selected tab, this way it can be called from a parent component
  useImperativeHandle(resetTabs, () => ({
    reset() {
      setTabValue(0)
    }
  }));

  useEffect(() => {
    const handleWindowResize = () => {
      setLargeWindow(window.innerWidth > 1536 && !doc2uOpen || window.innerWidth > 1650);
    };
    handleWindowResize()

    window.addEventListener('resize', handleWindowResize);

    return () => {
      window.removeEventListener('resize', handleWindowResize);
    };
  });

  const displaySubHeader = (subHeader, tabs, currentTab) => {
    return !!subHeader && tabs.indexOf(currentTab) >= 0
  }

  const handleChange = (event, newValue)  => {
    setTabValue(newValue);
  }

  let baseHeight = 54
  if (footer) {
    baseHeight += 80
  }
  if (displaySubHeader(subHeader, subHeaderTabs, tabValue)) {
    baseHeight += 46
  }

  return (
    <>
      <Wrapper
      style={{height: `calc(100% - ${baseHeight}px)`}}>

      <ConsultationFrame open={doc2uOpen} >
          <TabContext value={tabValue.toString()}>
            <GridHeader container>
              <ItemHeaderLeft item {...propsHeaderLeft} >
                {headerLeft}
              </ItemHeaderLeft>
              <ItemTabs item {...propsTab}>
                <TabsStyled value={tabValue}
                            onChange={handleChange}
                            aria-label="tabs"
                            variant="scrollable"
                            scrollButtons="auto"
                            $doc2uOpen={doc2uOpen}
                            $resizeTreshold={headerRightSize < 200 ? '1200px' : '1536px'}>
                  {tabs.map((tab, index) => {
                    if (largeWindow && !doc2uOpen)
                      return (
                        <Tab
                          label={tab.tabName}
                          key={index}
                        />
                      )
                    else
                      return (
                        <Tooltip title={tab.tabName}>
                          <Tab
                            icon={tab.tabIcon}
                            key={index}
                          />
                        </Tooltip>
                      )
                  })}
                </TabsStyled>
              </ItemTabs>
              <ItemHeaderRight item {...propsHeaderRight}>
                {headerRight}
              </ItemHeaderRight>
            </GridHeader>
            {
              displaySubHeader(subHeader, subHeaderTabs, tabValue) && (
                <SubHeader container>
                  {subHeader}
                </SubHeader>
              )
            }
            <GridContent container>
              <ItemContent item xs={12}>
                {tabs.map((tab, index) => {
                  return (
                    <TabPanelComponent value={tabValue} index={index} key={index}>
                      {tab.tabContent}
                    </TabPanelComponent>
                  )
                })}
              </ItemContent>
            </GridContent>
          </TabContext>
          <FooterStyled
            style={{display: tabValue !== 0 || footer === null ?
                'none' :
                'block'}}
          >
            {footer}
          </FooterStyled>
      </ConsultationFrame>
      </Wrapper>

    </>
  )
}

const Wrapper = styled.div`
  display: flex;
`
const TabPanelComponent = ({ children, value, index }) => {
  return (
    <TabStyled
      role="tabpanel"
      hidden={value !== index}
      style={{display:  value !== index ? 'none' : 'flex'}}
      id={index}
    >
      {value === index && (
        children
      )}
    </TabStyled>
  );
};

const drawerWidth = 600;

const ConsultationFrame = styling('div', {
  shouldForwardProp: (prop) => prop !== 'open'
}) (({ theme, open }) => ({
    flexGrow: 1,
    transition: theme.transitions.create('margin', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    marginRight: 0,
    ...(open && {
      transition: theme.transitions.create('margin', {
        easing: theme.transitions.easing.easeOut,
        duration: theme.transitions.duration.enteringScreen,
      }),
      marginRight: drawerWidth,
    }),
  }),
);

const GridHeader = styled(Grid)`
  border-bottom: solid 1px ${Colors.grey};
  display: flex;
  white-space: nowrap;
  height: 53px;
  background-color: white;
`

const SubHeader = styled(Grid)`
  border-bottom: solid 1px ${Colors.grey};
  display: flex;
  white-space: nowrap;
  height: 45px;
  background-color: ${Colors.backGrey};
`

const ItemHeaderLeft = styled(Grid)`
  display: flex;
  text-overflow: ellipsis;
  overflow: hidden;
`

const ItemHeaderRight = styled(Grid)`
  display: flex;
  justify-content: end;
  align-items: center;
`

const ItemTabs = styled(Grid)`
  display: flex;
  justify-content: center;
`

const GridContent = styled(Grid)`
  display: flex;
  height: 100%;
  overflow: auto;
`

const ItemContent = styled(Grid)`
  display: flex;
  height: 100%;
  flex-direction: column;
  overflow: auto;
`

const TabStyled = styled.div`
  display: flex;
  height: 100%;
  flex-direction: column;
  overflow: auto;
`

const TabsStyled = styled(Tabs)`
  .MuiTabs-scroller {
    width : ${({$doc2uOpen}) => $doc2uOpen ? '90px' : 'auto'};
    @media screen and (max-width: ${({$resizeTreshold}) => $resizeTreshold}) {
      width: 90px;
    }
  }
`

const FooterStyled = styled.div`
  height: 80px;
  background-color: white;
`

ChatLayout.propTypes = {
  footer: PropTypes.element,
  headerLeft: PropTypes.element,
  headerRight: PropTypes.element,
  tabs: PropTypes.arrayOf(PropTypes.shape({
    tabName: PropTypes.string,
    tabContent: PropTypes.element,
  }))
}
import React from 'react'
import { Route, Switch } from 'react-router-dom'
import { Profile } from './Profile'
import { MedicalReport } from './MedicalReport'
import { Attachments } from './Attachments'
import { Prescription } from './Prescription'
import { Payment } from './Payment'

export const Menu = () => (
  <Switch>
    <Route
      path='/panel/consultations/session/menu/report/:id'
      component={MedicalReport}
    />
    <Route
      path='/panel/consultations/session/menu/prescription'
      component={Prescription}
    />
    <Route
      path='/panel/consultations/session/menu/attachments'
      component={Attachments}
    />
    <Route
      path='/panel/consultations/session/menu/payment'
      component={Payment}
    />
    <Route
      path='/panel/consultations/session/menu'
      component={Profile}
    />
  </Switch>
)

import React, {useState} from "react";
import {AddContacts} from "./AddContacts";
import {AddName} from "./AddName";

const DIALOG_STATE_CONTACTS = 'addContacts'
const DIALOG_STATE_GROUP_NAME = 'addName'

export const NewGroupDialog = ({open, setOpen}) => {
    const [dialogPage, setDialogPage] = useState(DIALOG_STATE_CONTACTS);
    const [membersAdded, setMembersAdded] = useState([])

    const handleHide = () => {
        setOpen(false)
        setDialogPage(DIALOG_STATE_CONTACTS)
        setMembersAdded([])
    }

    return (
        <>
            {dialogPage === DIALOG_STATE_CONTACTS && (
                <AddContacts showModal={open} onHide={handleHide} setDialogPage={setDialogPage} membersAdded={membersAdded} setMembersAdded={setMembersAdded}/>
            )}
            {dialogPage === DIALOG_STATE_GROUP_NAME && (
                <AddName showModal={open} onHide={handleHide} membersAdded={membersAdded}/>
            )}
        </>
    )
}
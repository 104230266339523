import React from 'react'
import styled from 'styled-components'

import { Colors } from 'constants/colors'

const ImageWrapper = styled.a`
  box-sizing: border-box;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 6px;

  > svg {
    color: ${Colors.blueGrey};
    width: 70%;
    height: 70%;
  }
`

const Image = styled.img`
  max-width: 100%;
  max-height: 100%;
  border: 2px solid ${Colors.clearBlue};
  border-radius: 4px;
`

const Wrapper = styled.div`
  height: 100%;
  margin: 0 16px;
`

export const Preview = ({
  file,
  setWrapperRef,
}) => (
  <Wrapper ref={setWrapperRef}>
    <ImageWrapper
      href={file}
      target='_blank'
    >
      <Image src={file} />
    </ImageWrapper>
  </Wrapper>
)

import React from 'react'
import{Button as MuiButton} from '@mui/material'
import PropTypes from "prop-types";
import styled from "styled-components";
import {BUTTON_COLORS, Colors} from "../../../../constants/colors";
import Tooltip from "@mui/material/Tooltip";

const ButtonComponent = ({ style, variant, large, children, color, tooltipTitle, ...rest}) => {
  const buttonStyles = {
    textTransform: 'none',
    ...style,
  }
  if (large) {
    buttonStyles.minWidth = '165px'
  }
  if (color === 'grey') {
    color = undefined;
  }
  let button = (
    <MuiButton color={color} variant={variant} style={buttonStyles} {...rest}>{children}</MuiButton>
  )
  if (tooltipTitle) {
    button = (
      <Tooltip title={tooltipTitle}>
        {button}
      </Tooltip>
    )
  }
  return button
}

ButtonComponent.defaultProps = {
  style: {},
  variant: 'contained',
  large: false,
  tooltipTitle: null,
  children: [],
}

ButtonComponent.propTypes = {
  style: PropTypes.object,
  large: PropTypes.bool,
  tooltipTitle: PropTypes.string,
}

const Button = styled(ButtonComponent)`
  ${props => {
    if (props.color === 'grey') {
      return `
        {
          color: grey;
        }
        :hover {
          color: ${Colors.bluePurple};
        }
      `
    }
  }}
  ${props => {
    if (!props.variant || props.variant === 'contained') {
      return `
        :hover {
          background-color: ${BUTTON_COLORS.purpleHover};
        }
      `
    } else {
      return `
        :hover {
          background-color: rgb(245, 245, 245);
        }
      `
    }
  }}
`

export default Button
import React, {useState, useEffect, useRef} from 'react'
import styled from 'styled-components'
import {AdapterDateFns} from "@mui/x-date-pickers/AdapterDateFns";
import {DesktopDatePicker} from "@mui/x-date-pickers/DesktopDatePicker";
import {TextFieldStyled} from "../UiKit/Input";
import {LocalizationProvider} from "@mui/x-date-pickers";

export const DateField = ({
  props, minDate, maxDate, errorMessage
}) => {
  const [errorBirthDate, setErrorBirthDate] = useState();
  return (
  <LocalizationProvider dateAdapter={AdapterDateFns}>
    <DesktopDatePicker
      {...props}
      name={props.input.name}
      onChange={(date) => props.input.onChange(date)}
      inputFormat='dd/MM/yyyy'
      minDate={minDate}
      maxDate={maxDate}
      views ={['year','month','day']} // ici defini l'enchainement des choix, l'année en 1er choix, puis le mois et le jour
      openTo='year' // défini par quel donnée commence l'enchainement des choix, ici l'année
      value={props.input.value}
      onError={(e) => {
        if (e) {
          setErrorBirthDate(errorMessage)
        } else {
          setErrorBirthDate(null)
        }
      }}
      renderInput={(params) => <TextFieldStyled {...params} helperText={errorBirthDate ?? null}
                                                variant={'outlined'}/>} //  helperText={errorBirthDate ?? errorBirthDate}
    />
  </LocalizationProvider>
  )
}

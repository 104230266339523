import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import { compose, withProps, withStateHandlers } from 'recompose'
import { connect } from 'react-redux'
import { Colors } from 'constants/colors'
import { Picker } from './Picker'
import { Upcoming } from './Upcoming'
import { Past } from './Past'
import { history } from '../../../../../utils/history'
import { logOut } from '../../../../../redux/modules/auth'
import { Page } from '../../../../common/Page'
import {PanelBar} from "../../../../common/PanelBar";
import {NotificationsButton} from "../../../../common/Page/Notifications";

const Wrapper = styled.div`
  background-color: ${Colors.background};
  min-height: 100%;
  position: relative;
`

const Content = styled.div`
  margin: auto;
  padding: 24px 0;
`

const ListComponent = ({
  tab,
  changeTab,
  onBackIconClick,
  logOut,
}) => (
  <Wrapper>
    <Page
      label='teleconsultations.title'
      fullWidth
      onLogout={logOut}
      onBackIconClick={onBackIconClick}
    >
      <Picker
        tab={tab}
        changeTab={changeTab}
      />
      <Content>
        {tab === 0 && <Upcoming />}
        {tab === 1 && <Past />}
      </Content>
    </Page>
  </Wrapper>
)

ListComponent.propTypes = {
  tab: PropTypes.number.isRequired,
  changeTab: PropTypes.func.isRequired,
  onBackIconClick: PropTypes.func.isRequired,
  logOut: PropTypes.func.isRequired,
}

const enhance = compose(
  connect(() => ({}), { logOut }),
  withStateHandlers({
    tab: 0,
  }, {
    changeTab: () => (e, v) => ({ tab: v }),
  }),
  withProps({
    onBackIconClick: () => history.push('/panel/doctors/main'),
  }),
)

export const List = enhance(ListComponent)

import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import styled from 'styled-components'
import { Page } from '../../../common/Page'
import { fetchSpecializations } from '../../../../redux/modules/content'
import {history} from "../../../../utils/history";
import {FormattedMessage} from "react-intl";
import {Colors} from "../../../../constants/colors";
import {setSearchObject} from "../../../../redux/modules/search";
import {InstantSearchBar} from "../../../common/Search/instantSearchBar";

export const Specializations = ({ match }) => {
  const dispatch = useDispatch()
  const specializations = useSelector(state => state.content.specializations)

  useEffect(() => {
    dispatch(fetchSpecializations())
  }, [dispatch])

  return (
    <Page
      label={match.path.includes('concilium') ? 'concilium.new.TE' : match.path.includes('doctors') ? 'global.doctors': 'concilium.new.TCA'}
      noWrapperPadding
      fullWidth
      onBackIconClick={() => history.goBack()}
    >
      <Content>
        <Wrapper>
          <Heading>
            <FormattedMessage id='concilium.specializations' />
          </Heading>
          <InstantSearchBar match={match}/>
          <ListStyled>
            {specializations
              .map(item => (
                <SpeCard onClick={()=>{
                  dispatch(setSearchObject(item))
                  history.push("specializations/" + item.id)
                }}>
                  <FormattedMessage id={item.attributes.name} defaultMessage={item.attributes.name} />
                </SpeCard>
              ))}
          </ListStyled>
        </Wrapper>
      </Content>
    </Page>
  )
}

const Content = styled.div`
  padding: 40px 100px; 
`
const Wrapper = styled.div`
  margin-top: 5px;
  & > * {
    max-width: 100%;
  }
`

const Heading = styled.div`
  margin: 0;
  font-size: 24px;
  font-weight: 500;
  color: #585C65;
  font-family: Roboto, sans-serif;
  display: flex;
  align-items: center;
  margin-bottom: 20px;
`

const ListStyled = styled.div`
  margin: 20px auto;
  display: grid;
  grid-gap: 25px;
  grid-template-columns: repeat( auto-fill, minmax(315px, 1fr) );
  & > * {
    min-height: 0;
  }
`

const SpeCard = styled.div`
  display: flex;
  align-items: center;
  padding: 20px;
  display: flex;
  font-family: Roboto, sans-serif;
  font-size: 14px;
  font-weight: 500;
  color: #585C65;
  cursor: pointer;
  border-radius: 4px;
  background-color: ${Colors.white};
  box-shadow: 0 4px 8px 0 ${Colors.azure};
  justify-content: center;
  text-align: center;
  &:hover {
    color: ${Colors.bluePurple};
  }
  & svg {
    color: ${Colors.cloudyBlue};
  }
`

import React, {useState} from 'react'
import {Field, Form} from 'react-final-form'
import { withProps } from 'recompose'
import PropTypes from 'prop-types'
import idx from 'idx'
import { Switchers } from './Switchers'
import { Time } from './Time'
import {RadioInputField} from "../RadioInputField";
import {PatientField} from "../../Panel/Doctor/Concilium/PatientField";
import {useLocation} from "react-router-dom";


const validate = /*questions => */ (values) => {
  const errors = {}

  if (!values.accessPolicy) errors.accessPolicy = true
  if (!values.proposedDate) errors.proposedDate = true
  return errors
}

const normalizeOptions = (question) => {
  const answers = idx(question, _ => _.attributes.answers)
  return Object.keys(answers).map(k => ({
    label: answers[k],
    value: answers[k],
  }))
}

const enhance = withProps(props => ({
  onSubmit: values => props.onSubmit(values).then(() => props.afterSubmit())
}))

export const AppointmentConsultationForm = enhance(({
  onSubmit,
  questions,
  id,
  onAccessError,
  TimeField,
  setTypeOfPrice,
  available,
}) => {
  function useQuery() {
    return new URLSearchParams(useLocation().search);
  }
  const query = useQuery();
  const proposedDate = query.get('proposedDate');
  let [showPatientField, setShowPatientField ]= useState(false)
  const [access, toggleAccess] = useState(false)
  const [hospitalized_patient, toggleHospitalizedPatient] = useState(false)
  const changeHandler = e => {
    if(e.target.value !== 'POUR MOI'){
      setShowPatientField(true)
    }else{
      setShowPatientField(false)
    }
  };
  return (
    <Form
      onSubmit={onSubmit}
      subscription={{ errors: true }}
      validate={validate}
      initialValues={{
        accessHealthRecord: false,
        questions: 'POUR MOI',
        proposedDate
      }}
      render={({ handleSubmit, errors }) => (
          <form
            onSubmit={(e) => {
              if (Object.keys(errors).length === 1 && errors.accessPolicy) {
                onAccessError()
              }

              handleSubmit(e)
            }}
            id={id}
          >
            <Time available={available} setTypeOfPrice={setTypeOfPrice} Component={TimeField}/>
            {questions.map((q) => {

              if (idx(q, _ => _.attributes.type) === 'choice') {
                return (
                  <RadioInputField
                    key={q.id}
                    headingTransparent={q.attributes.title}
                    options={normalizeOptions(q)}
                    onChange={changeHandler}
                    name='questions'
                    id={q.id}
                  />
                )
              }

              return null
            })}
            {
              showPatientField === true && (

                <div aria-hidden={showPatientField}>
                  <Field name='patient' component={PatientField}
                         access={access}
                         toggleAccess={toggleAccess}
                         hospitalized_patient={hospitalized_patient}
                         toggleHospitalizedPatient={toggleHospitalizedPatient}
                  />
                </div>

              )
            }

            <Switchers />
          </form>
      )}
    />
  )
})

AppointmentConsultationForm.defaultProps = {
  questions: [],
}

AppointmentConsultationForm.propTypes = {
  questions: PropTypes.arrayOf(PropTypes.object),
  onSubmit: PropTypes.func.isRequired,
  id: PropTypes.string.isRequired,
  onAccessError: PropTypes.func.isRequired,
}

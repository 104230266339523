import React from 'react'
import styled from 'styled-components'
import { withProps } from 'recompose'
import moment from 'moment-timezone'
import Cake from '@mui/icons-material/Cake'
import LanguageIcon from '@mui/icons-material/Language'
import ContactsIcon from '@mui/icons-material/Contacts'
import Wc from '@mui/icons-material/Wc'
import PhoneIcon from '@mui/icons-material/Phone'
import { FormattedMessage } from 'react-intl'
import { TextButton } from 'common/UiKit/TextButton'
import { Paper } from 'common/UiKit/Paper'
import { useSelector } from 'react-redux'
import { Colors } from '../../../constants/colors'
import avatar from '../../../images/panel/default-avatar.svg'
import { LANGUAGES } from '../../../constants/languages'
import {DefaultAvatar} from "../DefaultAvatar";
import {SecuredBckgImage} from "../SecuredImage";

const PaperStyled = styled(Paper)`
  ${props => (props.small ? 'padding: 18px 16px' : 'padding-top: 24px')}
`

const Wrapper = styled.div`
  display: flex;
  font-weight: normal;
  ${props => props.small && 'font-size: 12px'}
`

const Avatar = styled(SecuredBckgImage)`
  position: relative;
  min-width: 126px;
  width: 126px;
  height: 126px;
  border: 7px solid white;
  border-radius: 100%;
  background: center no-repeat; 
  background-size: cover;
  display: flex;
  align-items: center;
  justify-content: center;
  box-shadow: 0 4px 8px 0 rgba(6, 173, 255, 0.1);
  
  ${props => props.small && `
    min-width: 88px;
    width: 88px;
    height: 88px;
    border: 5px solid white;
  `}
`
const Default = styled.img`
  position: relative;
  min-width: 126px;
  width: 126px;
  height: 126px;
  border: 7px solid white;
  border-radius: 100%;
  background: center no-repeat; 
  background-size: cover;
  display: flex;
  align-items: center;
  justify-content: center;
  box-shadow: 0 4px 8px 0 rgba(6, 173, 255, 0.1);
  
  ${props => props.small && `
    min-width: 88px;
    width: 88px;
    height: 88px;
    border: 5px solid white;
  `}
`

const Info = styled.div`
  flex-grow: 1;
  margin: 12px ${props => (props.small ? '16px' : '32px')};
`
const Name = styled.h1`
  margin: 0;
  font-size: ${props => (props.small ? '20px' : '24px')};
  font-weight: bold;
`

const Email = styled.p`
  margin: 0;
  opacity: 0.8;
  padding-top: 10px;
`

const Over = styled.p`
  margin: 0;
  color: ${Colors.blueGrey};
  display: flex;
  align-items: center;
  margin-top: 12px;
  text-transform: capitalize;

  && svg {
    font-size: 18px;
    margin-right: ${props => (props.small ? '8px' : '12px')};
  }
`

const Button = styled(TextButton)`
  && {
    height: auto;
    margin-top: 18px;
    padding: 9px 58px;
    font-size: 14px;
    font-weight: 500;
  }
`

const Language = styled.div`
  font-size: 16px;
  color: ${Colors.blueGrey};
  text-transform: capitalize;
`

const UserInfoComponent = ({
  small,
  heading,
  changeButtonPath,
  email,
  dateOfBirth,
  firstName,
  lastName,
  photo,
  gender,
  children,
  onAppointmentButtonClick,
  language,
  phoneNumber,
  medicalInsurance,
  specialization,
}) => {
  const authType = useSelector(state => state.auth.type);
  const SpecType = useSelector(state => state.auth.attributes.specialization_type);
  const isDoctor = authType === 'Doctor';

  return (
    <PaperStyled
      small={small}
      heading={heading}
      changeButtonPath={changeButtonPath}
    >
      <Wrapper small={small}>
        {photo? (
            <Avatar
                small={small}
                src={photo}
            />
        ) : (
            <Default
                small={small}
                src={DefaultAvatar(firstName, lastName, SpecType)}
            />
        )}

        <Info small={small}>
          <Name small={small}>
            {`${firstName} ${lastName}`}
          </Name>
          {email && !small && <Email>{email}</Email>}
          {
          !!specialization && (
            <Over>
              {specialization}
            </Over>
          )
        }
          {
          !!phoneNumber && (
            <Over small={small}>
              <PhoneIcon />
              {phoneNumber}
            </Over>
          )
        }
          <Over small={small}>
            <Cake />
            {dateOfBirth}
          </Over>
          {medicalInsurance && (
          <Over small={small}>
            <ContactsIcon />
            <FormattedMessage id='profile.insurance' />
            {': '}
            {medicalInsurance}
          </Over>
          )}
          <Over small={small}>
            <Wc />
            <FormattedMessage id={gender === 'unknown' ? 'hr.unknown' : `profile${isDoctor ? '.genders' : ''}.${gender}`} />
          </Over>

          {
          !!language && (
            <Over small={small}>
              <LanguageIcon />
              <Language>
                {
                  Array.isArray(language)
                    ? language.sort().map(lang => (LANGUAGES.find(item => item.value === lang) || {}).label).join(', ')
                    : null
                }
              </Language>
            </Over>
          )
        }
          {
          onAppointmentButtonClick
            ? (
              <Button
                onClick={onAppointmentButtonClick}
                color='primary'
                variant='extended'
              >

                <FormattedMessage id='consultation.make.appointment' />
              </Button>
            )
            : null
        }
        </Info>
      </Wrapper>
      {children}
    </PaperStyled>
  ) 
}

const enhance = withProps(props => ({
  firstName: props.firstName,
  lastName: props.lastName,
  gender: props.gender === 'woman' ? 'female' : props.gender,
  dateOfBirth: props.dateOfBirth
    ? (
      <>
        {moment(props.dateOfBirth).format('D MMMM YYYY')} {moment().diff(moment(props.dateOfBirth), 'years')}
        {' '}
        <FormattedMessage id='profile.yearsold' />
      </>
    )
    : <FormattedMessage id='profile.birth.date' />,
}))

export const UserInfo = enhance(UserInfoComponent)

import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import { withStateHandlers } from 'recompose'
import IconButton from '@mui/material/IconButton'
import KeyboardArrowLeft from '@mui/icons-material/KeyboardArrowLeft'
import KeyboardArrowRight from '@mui/icons-material/KeyboardArrowRight'
import { PanelBar } from '../PanelBar'
import { Photo } from './Photo'

const Wrapper = styled.div`
  height: 100vh;
  width: 100%;
`

const Content = styled.div`
  height: calc(100% - 56px);
  display: flex;
  align-items: center;
  padding: 0 12px;
`

const NavButton = styled(IconButton)`
  && {
    padding: 0;
  }

  && svg {
    font-size: 96px;
  }
`

const DiplomasGalleryComponent = ({
  diplomas,
  position,
  incPosition,
  decPosition,
}) => (
  <Wrapper>
    <PanelBar label={`${position + 1}/${diplomas.length}`} onIconClick={position === 0 ? false : decPosition} />
    <Content>
      <NavButton onClick={decPosition} color='primary'>
        <KeyboardArrowLeft fontSize='inherit' />
      </NavButton>
      <Photo
        file={diplomas[position].attributes.url}
      />
      <NavButton onClick={incPosition} color='primary'>
        <KeyboardArrowRight fontSize='inherit' />
      </NavButton>
    </Content>
  </Wrapper>
)

DiplomasGalleryComponent.propTypes = {
  diplomas: PropTypes.arrayOf(PropTypes.object).isRequired,
  position: PropTypes.number.isRequired,
  incPosition: PropTypes.func.isRequired,
  decPosition: PropTypes.func.isRequired,
}

const enhance = withStateHandlers({
  position: 0,
}, {
  incPosition: ({ position }, { diplomas }) => () => ({
    position: position === diplomas.length - 1 ? position : position + 1,
  }),
  decPosition: ({ position }) => () => ({
    position: position === 0 ? 0 : position - 1,
  }),
})

export const DiplomasGallery = enhance(DiplomasGalleryComponent)

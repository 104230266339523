import React from 'react'
import { Paper } from 'common/UiKit/Paper'
import styled from 'styled-components'

import { Page } from '../../../common/Page'
import { PhoneConfirmForm } from '../PhoneConfirmForm'
import { history } from '../../../../utils/history'

export const PhoneEditPage = () => (
  <Page
    label='auth.phone.change'
    onBackIconClick={() => history.goBack()}
  >
    <Content>
      <StyledPaper>
        <PhoneConfirmForm afterSubmit={() => history.push('/panel/profile')} />
      </StyledPaper>
    </Content>
  </Page>
)

const Content = styled.div`
  max-width: 360px;
  margin: 0 auto;
`

const StyledPaper = styled(Paper)`
  padding-left: 44px;
  padding-top: 16px;
  padding-bottom: 16px;
`

import React, {useState} from 'react'
import PropTypes from 'prop-types'
import styled, { css } from 'styled-components'
import { withProps } from 'recompose'
import idx from 'idx'
import { Colors } from '../../../../../constants/colors'
import {FormattedMessage, useIntl} from 'react-intl'
import get from 'lodash/get';
import CloseIcon from '@mui/icons-material/Close';
import {useDispatch, useSelector} from 'react-redux';
import { SecuredBckgImage } from 'common/SecuredImage';
import Tooltip from '@material-ui/core/Tooltip';
import moment from 'moment-timezone';
import { CONSULTATION_TYPES } from '../../../../../constants/consultation';
import defaultPhoto from '../../../../../images/panel/default-avatar.svg';
import { ConfirmWithReasonModal } from '../../../../common/Modal/ConfirmWithReasonModal';
import {refuseConsultation} from '../../../../../redux/modules/consultations';
import { DefaultAvatar } from '../../../../common/DefaultAvatar';
import {dateLabelOrDay} from "../../../../../helpers/consultations";
import {AvatarESS} from "../../../../common/DefaultAvatar/avatarESS";
import {fullName} from "../../../../../helpers/user";
import {BUTTON_COLORS} from "../../../../../constants/colors";
import {useMediaQuery} from "@mui/material";

const POSSIBLE_CONSULTATION_STATUS = ['completed', 'cancelled', 'refused']

const Wrapper = styled.div`
  padding: 10px;
  background-color: ${Colors.white};
  border-radius: 8px;
  margin-bottom: 20px;
  display: flex;
  min-width: 500px;
  align-items: center;
  height: 75px;
  justify-content: space-between;
  cursor: pointer;
  ${({
    type
  }) => {
    let colors = {background: 'white' }
    if (type === CONSULTATION_TYPES.telexpertise) colors = { ...colors, color: '#6633FF'  }

    if (type === CONSULTATION_TYPES.TER || type === CONSULTATION_TYPES.TER_ESS ) colors = { ...colors, color: '#E83E25'}
    
    if (type === 'TCA' ) colors = { ...colors, color: '#FFA500'}

    if ( !(type === CONSULTATION_TYPES.telexpertise || type === CONSULTATION_TYPES.TER || type === 'TCA' || type === CONSULTATION_TYPES.TER_ESS) ) colors = { ...colors, color: '#01A793'}
    
    return `
      background-color: ${colors.background};
      border: 3px solid transparent;
      border-left: 6px solid ${colors.color};
      border-right: 6px solid ${colors.color};
      box-shadow: rgba(17, 17, 26, 0.05) 0px 2px 0px, rgba(17, 17, 26, 0.05) 0px 0px 10px;
      &:hover{
        border: 3px solid ${colors.color};
        padding: 10px 13px;
      }
      &:last-child:hover{
        color: ${colors.color};
      }
    `
  }}
`

const Photo = styled(SecuredBckgImage)`
  border-radius: 50%;
  height: 62px;
  width: 62px;
  border: 3px solid ${Colors.white};
  box-shadow: 0 4px 8px 0 ${Colors.azure};
  background-image: url(${props => get(props, 'photo', defaultPhoto)}), url(${defaultPhoto});
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center center;
`

const DefaultPhoto = styled.img`
  border-radius: 50%;
  height: 62px;
  width: 62px;
  border: 3px solid ${Colors.white};
  box-shadow: 0 4px 8px 0 ${Colors.azure};
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center center;
`


const Patient = styled.div`
  flex: 1 1 auto;
  margin-left: 5px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`

const Separator = styled.div`
  margin-right: 10px;
  margin-left: 10px;
  height: 95px;
  width: 2px;
  background-color: #DCDCDC;
`

const ItemGlobal = styled.div`
  border-radius: 8px;
  width: 100%;
  display: grid;
  //grid-template-columns: ${({$isInvite}) => $isInvite ? '6% 39% 16% 37% 4%' : '6% 39% 16% 19% 18% 4%'}; // photo / (doc correspondant + Patient) / reçu envoyé le / TER TEP Cons. dernier msg / icon delete 
  grid-template-columns: 7% 46% 22% 22% 3%;
  @media screen and (max-width: 900px) {
      grid-template-columns: 10% 54% 33% 3%;
  }
  cursor: pointer;
  align-items: stretch;
`

const CancelButton = styled.div`
display: flex;
justify-content: flex-end;
align-items: flex-start;
`


const PhotoStyled = styled.div`
  margin-top: auto;
  margin-bottom: auto;
`

const AdditionalMember = styled.div`
  display: flex;
  flex-direction: column;
  padding: 15px;
`

const StyledCloseIcon = styled(CloseIcon)`
  margin-left: 5px;
  ${props => (props.color && css`
  && {
    color: ${props.color};
    font-size: 20px;
  }
    &:hover {
    font-size: 23px;
    transition: 0.3s;
  }
  `)
}
   ${props => (props.color === '#01A793' && css`
    margin-top: 4px;
  `)}
`

const WrapButtons = styled.div`
  font-size: 14px;
`

const WrapIconMsg = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  & > * {
      margin-right: auto;
      margin-left: auto;
  }
`

const WrapMessages = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  font-size: 15px;
`
const WrapConcilium = styled.div`
  display: flex;
  justify-content: space-between;
  align-items : center;
`

const Title = styled.h4`
  margin: 0;
  font-size: 14px;
  margin-top: 4px;
  margin-left: 2px;
  color: ${Colors.dark70};
  font-weight: 400;
  display: inline-block;
`


const Specialization = styled.p`
  margin: 4px 0 0 0;
  font-size: 14px;
  color: ${Colors.dark70};
  font-weight: 400;
`

const ConsultationStatus = ({
  isReception,
  day,
  time,
  status,
  statusColor = 'inherit'
}) => {
  return (
    <WrapMessages>
      <Separator />
      <div style={{ display: 'flex', flexDirection: 'column' }}>
        <p>
          { isReception ? 'Reçue le' : 'Envoyée le' }
          <br/>
          {`${day} à ${time}`}
        </p>
        <span style={{ color: statusColor }}>{status}</span>
      </div>
    </WrapMessages>
  )
}


const ItemComponent = (props) => {
  const {
    additionalMemberIfTEOrTER,
    status,
    onClick,
    isConcilium,
    isMy,
    additionalMember,
    type,
    id,
    refreshConsultations,
    filters,
    specialization,
    specialization_type,
    patient_TCTE,
    timeCreation,
    lastmessagetime,
    item,
    isMyTerTeam,
    team,
    isMyEssTer,
    sender,
  } = props;
  const { formatMessage } = useIntl()
  const f = (id, values) => formatMessage({ id }, values)
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const dispatch = useDispatch();
  const isUpcoming = filters && filters.filter && filters.filter === 'upcoming';
  const [refuseReason, setRefuseReason] = useState('');
  const refuseFalse = false;
  const localTz = Intl.DateTimeFormat().resolvedOptions().timeZone
  const ConsultationTimeCreationDAY = moment.utc(timeCreation).tz(localTz).format('DD/MM')
  const ConsultationTimeCreationHour = moment.utc(timeCreation).tz(localTz).format('HH:mm')
  const ConsultationTimeLastMessageDAY = dateLabelOrDay(lastmessagetime)
  const ConsultationTimeLastMessageHour = moment.utc(lastmessagetime).tz(localTz).format('HH:mm')
  const additionalMemberFirstName = idx((isMy && additionalMember ? additionalMember : additionalMemberIfTEOrTER), _ => _.first_name)
  const patientFirstName = idx(patient_TCTE, _ => _.first_name)
  const additionalMemberLastName = idx((isMy && additionalMember ? additionalMember : additionalMemberIfTEOrTER), _ => _.last_name)
  const patientLastName = idx(patient_TCTE, _ => _.last_name)
  const additionalMemberPhoto = idx((isMy && additionalMember ? additionalMember : additionalMemberIfTEOrTER), _ => _.photo)
  const additionalMemberGender = idx((isMy && additionalMember ? additionalMember : additionalMemberIfTEOrTER), _ => _.gender)
  const patientGender = idx(patient_TCTE, _ => _.gender)
  const myUserId = useSelector(state => state.auth.attributes.user_id)
  const isReception = type !== CONSULTATION_TYPES.TER_ESS ?
    (isMy && !sender) || (sender && myUserId !== sender) || !isConcilium
    : !isMyTerTeam
  let colorByType = Colors.bluePurple
  if (type === 'TCA') {
    colorByType = '#FFA500'
  } else if (type === CONSULTATION_TYPES.telexpertise) {
    colorByType = Colors.bluePurple
  } else if (!isConcilium) {
    colorByType = '#01A793'
  } else if (type === CONSULTATION_TYPES.TER) {
    colorByType = '#E83E25'
  } else if (type === CONSULTATION_TYPES.TER_ESS) {
    colorByType = '#E83E25'
  }

  const smallScreen = useMediaQuery(`(max-width:900px)`);

  const statusToDisplay = !isMy ? item.attributes.billing_requerant : item.attributes.billing_requis
  return (
    <Wrapper type={type} onClick={onClick}>
      <ItemGlobal $isInvite={!!sender && status === 'created'}>
        <PhotoStyled>
          {(type === CONSULTATION_TYPES.telexpertise || type === CONSULTATION_TYPES.TER)
            && (additionalMemberPhoto)
            && (
              <Photo src={ additionalMemberPhoto } />
            )
          }
          {isMyEssTer && type === CONSULTATION_TYPES.TER_ESS && (status === 'accepted' || status === 'completed') &&
            (
              <Photo src={ item.relationships.doctor.data.attributes.photo } />
            )
          }
          {
            (isMyEssTer && item.relationships.doctor === undefined && (type === CONSULTATION_TYPES.TER_ESS)) && (
              <DefaultPhoto src={AvatarESS()} />
            )
          }
          {
            (!isMyEssTer && (type === CONSULTATION_TYPES.TER_ESS)) && (
              <Photo src={ additionalMember.photo } />
            )
          }
          {(type === CONSULTATION_TYPES.telexpertise || type === CONSULTATION_TYPES.TER)
            && !additionalMemberPhoto
            && (
            <DefaultPhoto
              src={DefaultAvatar(additionalMemberFirstName,
                additionalMemberLastName, specialization_type)}
            />
            )
          }
          {!(type === CONSULTATION_TYPES.telexpertise || type === CONSULTATION_TYPES.TER || type === CONSULTATION_TYPES.TER_ESS)
            && (
              <Photo src={additionalMemberPhoto} />
            )
          }
        </PhotoStyled>
        {
          isConcilium || type === CONSULTATION_TYPES.TCA || type === CONSULTATION_TYPES.TER_ESS
            ? (
              <WrapConcilium>
                <AdditionalMember>
                  {/* eslint-disable-next-line no-nested-ternary */}
                  {
                    isMyEssTer && status !== 'completed' && (
                      <>
                        &nbsp;
                        {team}
                      </>
                    )
                  }
                  {
                      isMyEssTer && status === 'completed' && (
                          <>
                            {fullName(additionalMemberIfTEOrTER.gender, additionalMemberIfTEOrTER.first_name, additionalMemberIfTEOrTER.last_name)}

                        <Specialization>{status !== 'accepted' && status !== 'completed' ? item.relationships.doctorCreator.data.attributes.specialization : ''}</Specialization>
                      </>
                    )
                  }
                  {
                    (!isMyEssTer && (type === CONSULTATION_TYPES.TER_ESS)) && (
                        <>
                          {fullName(additionalMember.gender, additionalMember.first_name, additionalMember.last_name)}

                        <Specialization>{status !== 'accepted' && status !== 'completed' ? item.relationships.doctorCreator.data.attributes.specialization : ''}</Specialization>
                      </>
                    )
                  }
                  {
                      type !== CONSULTATION_TYPES.TER_ESS &&
                      (
                          <>
                            {fullName(additionalMemberGender, additionalMemberFirstName, additionalMemberLastName)}
                          </>
                      )
                  }

                  <Specialization>{isConcilium ? specialization : ''}</Specialization>
                </AdditionalMember>
                <Separator />
                <Patient>
                  <Title>Patient</Title>
                  {fullName(patientGender, patientFirstName || 'Name', patientLastName || 'Surname')}
                </Patient>
              </WrapConcilium>
            )
            : (
              <WrapConcilium>
                <Patient>
                  {fullName(patientGender, patientFirstName || 'Name', patientLastName || 'Surname')}
                </Patient>
              </WrapConcilium>
            )

        }
        {
          !smallScreen && (
            <ConsultationStatus
              isReception={isReception}
              day={ConsultationTimeCreationDAY}
              time={ConsultationTimeCreationHour}
              status={POSSIBLE_CONSULTATION_STATUS.indexOf(status) >= 0 ? f('consultation.' + status) : ''}
              statusColor={colorByType}
            />
          )
        }

        { !(type === CONSULTATION_TYPES.TCA && sender && status === 'created') &&
            <WrapIconMsg>
              <Separator/>
              <div style={{ display: 'flex', flexDirection: 'column' }}>
                <div>
                  <Title>Dernier message&nbsp;</Title>
                  <span>{ConsultationTimeLastMessageDAY}&nbsp;{ConsultationTimeLastMessageHour}</span>
                </div>
                {
                  !isUpcoming && (
                    <div style={{ color: `${statusToDisplay ? `${BUTTON_COLORS.green}` : "black"}`, marginTop: '5px' }}>
                      {statusToDisplay ? "Facturé" : "En attente de facturation"}
                      <span style={{ width: '16px', height: '16px', display: 'inline-block', marginLeft: '5px' }}>
                      {statusToDisplay ?
                        (
                          <svg fill={`${BUTTON_COLORS.green}`} viewBox="0 0 512 512">
                            <path d="M256 32C132.3 32 32 132.3 32 256s100.3 224 224 224 224-100.3 224-224S379.7 32 256 32zm0 416c-106 0-192-86-192-192S150 64 256 64s192 86 192 192-86 192-192 192zm-32-154-83-83-45 45 128 128 176-176-45-45-131 131z"></path>
                          </svg>
                        ) : (
                          <svg viewBox="0 0 512 512"></svg>
                        )
                      }
                      </span>
                    </div>
                  )
                }
              </div>
            </WrapIconMsg>
        }

        {isUpcoming && type !== CONSULTATION_TYPES.TER_ESS && (
        <CancelButton
          variant='outlined'
          type={type}
          onClick={(e) => {
            e.stopPropagation();
            return setShowDeleteModal(true)
          }}
        >
          <Tooltip title='Annuler'>
            <>
              {type === CONSULTATION_TYPES.telexpertise && (
              <StyledCloseIcon color='#6633FF' />
              )}
              {!isConcilium && (
              <StyledCloseIcon color='#01A793' />
              )}
              {type === CONSULTATION_TYPES.TER && (
              <StyledCloseIcon color='#E83E25' />
              )}
              {type === CONSULTATION_TYPES.TCA && (
                <StyledCloseIcon color='#FFA500' />
              )}
            </>
          </Tooltip>
        </CancelButton>
        )}

        {
          type === CONSULTATION_TYPES.TER_ESS
          && (
              item.relationships.doctorCreator.data.attributes.user_id === myUserId
              || item.relationships?.doctor?.data.attributes.user_id === myUserId
          )
          && (
            <CancelButton
                variant='outlined'
                type={type}
                onClick={(e) => {
                  e.stopPropagation();
                  return setShowDeleteModal(true)
                }}
            >
              <Tooltip title='Annuler'>
                <>
                      <StyledCloseIcon color='#E83E25' />

                </>
              </Tooltip>
            </CancelButton>
        )}
      </ItemGlobal>

      {isUpcoming && (
        <ConfirmWithReasonModal
          onClick={e => e.stopPropagation()}
          title={getCancelTitle(type, sender && status === 'created')}
          titleProps={{
            disableTypography: true,
            style: { maxWidth: '460px', fontSize: '16px', fontWeight: '400 !important' },
          }}
          maxWidth={false}
          open={showDeleteModal}
          confirmButtonText={<WrapButtons><FormattedMessage id='consultation.delteconfirmyes' /></WrapButtons>}
          cancelButtonText={<WrapButtons><FormattedMessage id='consultation.delteconfirmno' /></WrapButtons>}
          text={(
            <FormattedMessage
              id={type === 'telexpertise' ? 'consultation.cancelreason' : 'consultation.cancelreason.consultation'}
            />
          )}
          setRefuseReason={setRefuseReason}
          refuseReason={refuseReason}
          handleSubmit={(e) => {
            e.stopPropagation();
            setShowDeleteModal(false);
            return dispatch(refuseConsultation(id, refuseReason, refuseFalse))
              .then(() => refreshConsultations())
              .catch(e => console.warn(e))
          }}
          handleClose={(e) => {
            e.stopPropagation();
            return setShowDeleteModal(false)
          }}
        />
      )}
    </Wrapper>
  )
}

ItemComponent.propTypes = {
  additionalMemberIfTEOrTER: PropTypes.object.isRequired,
  status: PropTypes.string.isRequired,
  date: PropTypes.string.isRequired,
  onClick: PropTypes.func.isRequired,
}

const calcStatus = (status, accessHealthRecords) => {
  if (status === 'confirmed') {
    return accessHealthRecords ? 'access_opened' : 'access_closed'
  }

  return status
}

const getCancelTitle = (consultationType, isInvite) => {
  if (consultationType === CONSULTATION_TYPES.TCA) {
    if (isInvite) {
      return  <FormattedMessage id='consultation.delteconfirm.invite.TCA' />
    } else {
      return  <FormattedMessage id='consultation.delteconfirm.TCA' />
    }
  } else if (consultationType === CONSULTATION_TYPES.scheduled || consultationType === CONSULTATION_TYPES.urgent) {
    return  <FormattedMessage id='consultation.delteconfirm.consultation' />
  } else {
      return  <FormattedMessage id='consultation.delteconfirm' />
  }
}

const enhance = withProps(props => ({
  status: calcStatus(props.status, props.accessHealthRecords),

}))

export const Item = enhance(ItemComponent)
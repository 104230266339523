import React from 'react';
import {Skeleton} from '@mui/material';
import PropTypes from "prop-types";

export const SkeletonTextRepeat = ({ repeat, width, inline }) => (
  <>
    {
      Array(repeat).fill(0).map((_, key) => (
        <Skeleton
          sx={{
            display: inline ? 'inline-block' : 'block',
            marginRight: '5px',
          }}
          width={width}
          key={key} />
      ))
    }
  </>
)

SkeletonTextRepeat.propTypes = {
  repeat: PropTypes.number.isRequired,
  width: PropTypes.number.isRequired,
  inline: PropTypes.bool.isRequired,
}

SkeletonTextRepeat.defaultProps = {
  inline: true,
}
import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import idx from 'idx'
import { compose, withProps } from 'recompose'
import { connect } from 'react-redux'
import { HealthDescriptionForm } from 'common/UiKit/HealthDescriptionForm'
import { EditPage } from 'common/UiKit/EditPage'
import { externalFormSubmit } from '../../../../helpers/externalFormSubmit'
import { history } from '../../../../utils/history'
import { editHealthRecords } from '../../../../redux/modules/healthRecords'

const Wrapper = styled.div`
  margin-top: 24px;
`

const EditComponent = ({
  editHealthRecords,
  onButtonClick,
  onBackIconClick,
  initialValues,
  loading,
  contraindications,
}) => (
  <EditPage
    label='hr'
    buttonText='global.edit'
    onBackIconClick={onBackIconClick}
    onButtonClick={onButtonClick}
    loading={loading}
    background
  >
    <Wrapper>
      <HealthDescriptionForm
        afterSubmit={onBackIconClick}
        onSubmit={editHealthRecords}
        initialValues={initialValues}
        id='health-records-edit'
        contraindications={contraindications}
      />
    </Wrapper>
  </EditPage>
)

EditComponent.propTypes = {
  editHealthRecords: PropTypes.func.isRequired,
  onButtonClick: PropTypes.func.isRequired,
  onBackIconClick: PropTypes.func.isRequired,
  initialValues: PropTypes.object.isRequired,
  loading: PropTypes.bool.isRequired,
  contraindications: PropTypes.arrayOf(PropTypes.shape({
    label: PropTypes.string.isRequired,
    value: PropTypes.string.isRequired,
  })).isRequired,
}

const enhance = compose(
  connect(state => (
    {
      initialValues: idx(state, _ => _.healthRecords.attributes),
      loading: state.loading.healthRecords,
      contraindications: idx(state, _ => _.content.contraindications)
        .map(({ attributes: a }) => ({ label: a.name, value: a.uid })),
    }
  ), { editHealthRecords }),
  withProps({
    onButtonClick: () => externalFormSubmit('health-records-edit'),
    onBackIconClick: () => history.push('/panel/health-records'),
  }),
)

export const Edit = enhance(EditComponent)

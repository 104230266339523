import { useState, useEffect } from 'react'

export const TemporaryWrapper = ({ children }) => {
  const [expired, setExpired] = useState(false);

  useEffect(() => {
    setTimeout(() => setExpired(true), 15000)
  }, [])

  return expired ? null : children
}

import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import GroupsIcon from '@mui/icons-material/Groups';
import Button from '@mui/material/Button';
import get from 'lodash/get';
import { Link } from 'react-router-dom';
import { FormattedMessage } from 'react-intl'
import axios from 'axios'
import { SecuredBckgImage } from 'common/SecuredImage'

import { JoinStructure, leaveStructure } from '../../../helpers/structures';
import { Paper } from '../UiKit/Paper'
import {BUTTON_COLORS, Colors} from '../../../constants/colors';
import { withRouter } from 'react-router-dom'
import {DistanceDisplay} from "../DistanceDisplay";
import defaultLogo from "../../../images/doctors/structures/logo_structure.png"
import {FavoriteButton} from "../DoctorCard/FavoriteButton";
import CustomLoaderRectangleAndCircle from "../CustomLoaderRectangleAndCircle";

const ItemWrapper = styled(Paper)`
    padding: 20px 24px;
    display: grid;
    grid-template-columns: ${({isStructurePage}) => isStructurePage? '3fr 8fr 5fr' : '3fr 7fr 3fr'};
    grid-template-rows: ${({isStructurePage}) => isStructurePage? 'repeat(2,auto)' : '1fr 2fr'};
    column-gap: ${({isStructurePage}) => isStructurePage? '20px' : ''};
`

const StructureNameLink = styled.div`
    font-size: 24px;
    font-weight: 500;
    line-height: 0.83;
    color: #21293e;
    margin-top: 10px;
    &:hover {
        color: ${Colors.bluePurple};
      }
`

const StructureName = styled.div`
    font-size: 24px;
    font-weight: 500;
    line-height: 0.83;
    color: #21293e;
    margin-top: 10px;
`


const StructureInfo = styled.div`
    display: flex;
    flex-direction: column;
    align-self: center;
`

const StructureParam = styled.div`
    flex: 1;
    opacity: 0.8;
    font-size: 16px;
    line-height: 1.5;
    color: #21293e;
`

const Participants = styled.div`
    color: #b7bed2;
    display: flex;
    margin: ${({isStructurePage}) => isStructurePage? '7px 0 7px' : '0 0 17px'};
    justify-self: end;
`
const TopRight = styled.div`
    color: #b7bed2;
    display: flex;
    justify-self: end;
    align-items: start;
`

const StyledPersonIcon = styled(GroupsIcon)`
    margin-right: 12px;
    margin-left: ${({isStructurePage}) => isStructurePage? '' : '10px'};
    svg {
        color: #b7bed2;
    }
`

const LeaveOrJoinButton = styled(Button)`
  && {
    height: 40px;
    margin-top: 15px;
    font-size: 14px;
    font-weight: 500;
    max-width: 243px;
    border-radius: 18px;
    margin-bottom: 5px;
    align-self: end;
    text-transform: none;
    background-color: ${BUTTON_COLORS.purple};
    color: #fff;
  }
  &&:hover {
    background-color: ${BUTTON_COLORS.purpleHover};
    color: #fff;
  }
`

const Avatar = styled(SecuredBckgImage)`
    grid-row: 1 / 3;
    grid-column: 1;
    min-width: 126px;
    width: 126px;
    height: 126px;
    border: 7px solid white;
    border-radius: 100%;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    display: flex;
    align-items: center;
    justify-content: center;
    box-shadow: 0 4px 8px 0 rgba(6, 173, 255, 0.1);
    position: relative;
`

const StructureCardComponent = ({
  id,
  name,
  members,
  type,
  address,
  zipcode,
  city,
  distance,
  disableButtons,
  isNotClickable,
  location,
  photo,
  isFavorite,
  isFavoriteFetching,
  toggleFavorite,
  showMessageFavorite,
  isStructurePage,
  isDoctor,
}) => {
  const [myStructuresIds, setMyStructuresId] = useState([]);
  const getIds = async () => {
    try {
      const myStruct = axios.get('/api/doctor/structure/list')
      const ids = get(myStruct, 'data.data', []).map(el => el.id)
      setMyStructuresId(ids);
    } catch (e) {
      console.warn(e);
    }
  }

  useEffect(() => {
    getIds();
  }, [myStructuresIds.length])

  return (
    <ItemWrapper key={id} isStructurePage={isStructurePage}>
      {photo ?
        <Avatar
          spinner={{width: '120px', height: '120px'}}
          src={photo.includes('/file/') ? `http://backend-application.owndoctor.eu${photo}` : photo}
        /> :
        <Avatar
          spinner={{width: '120px', height: '120px'}}
          src={defaultLogo}
        />}
      { (!isNotClickable) ?
          <Link to={location.pathname==="/panel/community/structures" ? `${location.pathname}/${id}` : `/panel/concilium/main/structure/${id}`}>
            <StructureNameLink>
              {name}
            </StructureNameLink>
          </Link>
      :   <StructureName>
            {name}
          </StructureName>
      }
      {isStructurePage ?
        <TopRight>
          {isDoctor &&
            <FavoriteButton
              checked={isFavorite}
              onClick={() => toggleFavorite(!isFavorite)}
              isFetching={isFavoriteFetching}
              message={showMessageFavorite}
            />}
        </TopRight>
        :
        <Participants>
          {
            !!distance && ( <DistanceDisplay distance={distance} /> )
          }
          <StyledPersonIcon />
          <StructureInfo>{members} </StructureInfo>
        </Participants>
      }
      <StructureInfo>
        {isStructurePage &&
          <Participants isStructurePage={isStructurePage}>
            <StyledPersonIcon isStructurePage={isStructurePage} />
            <StructureInfo>{members} </StructureInfo>
          </Participants>
        }
        <StructureParam>
          <FormattedMessage id='structures.type' />: {type}
        </StructureParam>
        <StructureParam>
          {(address || zipcode || city) &&
            <>
            <FormattedMessage id='structures.address'/>: {`${address ? address : ""
            }, ${zipcode ? zipcode : ""} ${city ? city : ""}`}
            </>
          }
        </StructureParam>
      </StructureInfo>
      {!disableButtons && (myStructuresIds.includes(id)
        ? (
          <LeaveOrJoinButton
            variant='outlined'
            color='primary'
            onClick={async () => {
              await leaveStructure(id);
              setMyStructuresId(myStructuresIds.filter(f => f !== id));
            }}
          >
            <FormattedMessage id='structures.leaveTheStructure' />
          </LeaveOrJoinButton>
        )
        : (
          <LeaveOrJoinButton
            variant='contained'
            color='primary'
            onClick={async () => {
              await JoinStructure(id);
              setMyStructuresId([...myStructuresIds, id]);
            }}
          >
            <FormattedMessage id='structures.join' />
          </LeaveOrJoinButton>
        ))}
    </ItemWrapper>
  )
}

export const StructureCard = withRouter(StructureCardComponent)

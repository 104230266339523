import React from 'react';
import styled from 'styled-components';
import GroupsIcon from '@mui/icons-material/Groups';
import { Link } from 'react-router-dom';
import { FormattedMessage } from 'react-intl'
import { Paper } from '../UiKit/Paper'
import { Colors } from '../../../constants/colors';
import { withRouter } from 'react-router-dom'
import {FavoriteButton} from "../DoctorCard/FavoriteButton";
import essLogo from "../../Panel/Doctor/Community/ROSO/images/Logo_ESS.png";
import esspLogo from "../../Panel/Doctor/Community/ROSO/images/Logo_ESSP.png";
import {history} from "../../../utils/history";
import {StyledTextButton} from "../UiKit/TextButton";
import {DistanceDisplay} from "../DistanceDisplay";

const ItemWrapper = styled(Paper)`
  padding: 20px 24px;
  display: grid;
  grid-template-columns: 2fr 8fr 2fr;
  grid-template-rows: 1fr 2fr;
`

const TeamNameLink = styled.div`
  font-size: 24px;
  font-weight: 500;
  color: #585C65;
  &:hover {
      color: ${Colors.bluePurple};
  }
  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;  
  overflow: hidden;
`

const Info = styled.div`
  grid-row: 1 / 3;
  display: flex;
  flex-direction: column;
  grid-column: 2;
  justify-content: space-between; 
`

const ButtonWrapper = styled.div`
  display: flex;
  justify-self: end;
`

const Participants = styled.div`
  color: #858992;
  display: flex;
  align-items: center;
  text-transform: lowercase;
`

const TopRight = styled.div`
  color: #b7bed2;
  display: flex;
  justify-self: end;
  align-items: start;
  gap: 10px;
`

const StyledPersonIcon = styled(GroupsIcon)`
  margin-right: 12px;
  svg {
      color: #b7bed2;
  }
`

const Avatar = styled.img`
  grid-row: 1 / 3;
  grid-column: 1;
  min-width: 126px;
  width: 126px;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
`

const Specialization = styled.p`
  font-size: 16px;
  color: #858992;
  margin-top: 5px;
  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;  
  overflow: hidden;
`

const TeamCardComponent = ({
                                  id,
                                  name,
                                  members,
                                  type,
                                  isFavorite,
                                  isFavoriteFetching,
                                  toggleFavorite,
                                  status_availability,
                                  specializations,
                                  isDoctor,
                                  distance,
                                }) => {

  const onButtonClickTER = (team_id, isUrgent, type) => (e) => {
    const url = `/panel/concilium/team/create/${team_id}/${type}${isUrgent ? '?urgent=1' : ''}`
    e.stopPropagation()

    return history.push(url)
  }
  const uniqueSpecializations = [...new Set(specializations)]; // use Set to filter out duplicates, and then convert it back to an array

  return (
    <ItemWrapper key={id} >
      <Avatar
        spinner={{width: '120px', height: '120px'}}
        src={type === 'ESS' ? essLogo : esspLogo}
        alt='ess'
      />
      <Info>
        <Link to={isDoctor ? `/panel/concilium/main/roso/detail/${id}` : `/panel/doctors/teams/team/${id}`}>
          <TeamNameLink>
            {name}
          </TeamNameLink>
        </Link>
        <Participants>
          <StyledPersonIcon/>
            {members + " "}
            <FormattedMessage id='structures.participants' />
        </Participants>
        <Specialization>
          {uniqueSpecializations.map((specialization, index) => {
            if(index === uniqueSpecializations.length-1) {
              return (specialization)
            } else {
              return (specialization + ", ")
            }
          })}
        </Specialization>
        {/*{type == 'ESS' && isDoctor &&*/}
        {/*  <ButtonWrapper>*/}
        {/*    <StyledTextButton*/}
        {/*      onClick={onButtonClickTER(id, true, type)}*/}
        {/*      style={{height: '40px', width: '100%'}}*/}
        {/*      disabled={!status_availability}*/}
        {/*    >*/}
        {/*      <FormattedMessage id='roso.team.te'/>*/}
        {/*    </StyledTextButton>*/}
        {/*  </ButtonWrapper>*/}
        {/*}*/}
        {/*{(type == 'ESSP' || !isDoctor) &&*/}
        {/*  <div style={{height: "40px"}}></div>*/}
        {/*}*/}
      </Info>
      <TopRight>
        {
          !!distance && ( <DistanceDisplay float='right' distance={distance} /> )
        }
        {isDoctor &&
          <FavoriteButton
            checked={isFavorite}
            onClick={() => toggleFavorite(!isFavorite)}
            isFetching={isFavoriteFetching}
            message={false}
          />
        }
      </TopRight>
    </ItemWrapper>
  )
}

export const TeamCard = withRouter(TeamCardComponent)

import React from 'react';
import styled from 'styled-components';
import { FormattedMessage } from 'react-intl'
import get from 'lodash/get';

const TotalCardContainer = styled.div`
    display: flex;
    margin-bottom: 8px;
`

const SymbolContainer = styled.div`
    color: #fff;
    background: ${({ bgColor }) => bgColor};
    font-size: 24px;
    font-weight: bold;
    line-height: 0.67;
    letter-spacing: 1.99px;
    color: #ffffff;
    width: 64px;
    text-align: center;
    padding-top: 24px;
    padding-bottom: 24px;
    border-radius: 50%;
`

const TotalCardTitle = styled.div`
    font-size: 12px;
    font-weight: bold;
    line-height: 1.33;
    letter-spacing: 1px;
    color: #949eb8;
    padding-bottom: 12px;
`

const TotalCardNumber = styled.div`
    font-size: 32px;
    font-weight: 500;
    line-height: 0.88;
    letter-spacing: -0.08px;
    color: #333c40;
    padding-bottom: 26px;
`
const FirstTotalCardCol = styled.div`
    flex: 1;
`

const TotalCardNumberPercentContainer = styled.div` 
    display: flex;
    align-items: center;
`

const TotalCardNumberText = styled.div`
    font-size: 12px;
    letter-spacing: -0.04px;
    color: #949eb8;
`

const TotalCardPercentTendention = styled.div`
    color: #47b881;
    margin-right: 8px;
`

const TotalCardPercentNumberContainer = styled.div`
    font-size: 12px;
    font-weight: 500;
    letter-spacing: -0.04px;
    color: #47b881;
    margin-right: 8px;
`

export const TotalCard = ({
  name, symbol, data = {}, symbolBgColor = '#000', 
}) => (
  <TotalCardContainer>
    <FirstTotalCardCol>
      <TotalCardTitle>
        {name}
      </TotalCardTitle>
      <TotalCardNumber>
        {data.count}
      </TotalCardNumber>
      <TotalCardNumberPercentContainer>
        <TotalCardPercentTendention>
          {data.tendention === 'up' ? '▲' : '▼'}
        </TotalCardPercentTendention>
        <TotalCardPercentNumberContainer>
          {get(data, 'percent', 0)}%
        </TotalCardPercentNumberContainer>
        <TotalCardNumberText>
          <FormattedMessage id='comunity.statistic.sincelastmonth' />
        </TotalCardNumberText>
      </TotalCardNumberPercentContainer>
    </FirstTotalCardCol>
    <div>
      <SymbolContainer bgColor={symbolBgColor}>
        {symbol}
      </SymbolContainer>
    </div>
  </TotalCardContainer>
)

import React, {useState, useEffect} from 'react';
import styled from 'styled-components'
import idx from 'idx'
import { BUTTON_COLORS } from 'constants/colors'
import {useDispatch, useSelector} from 'react-redux'
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import DialogTitle from '@mui/material/DialogTitle';
import Box from '@mui/material/Box';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import {FormattedMessage, useIntl} from 'react-intl';
import { TextButton } from 'common/UiKit/TextButton'
import {Field, Form} from "react-final-form";
import {TextField} from "../../../../../../common/UiKit/TextField";
import ClearIcon from '@mui/icons-material/Clear';
import {fullName} from "../../../../../../../helpers/user";
import {DialogFDS} from "./DialogFDS";
import moment from "moment-timezone";
import {editConcilium} from "../../../../../../../redux/modules/consultations";
import {Colors} from "../../../../../../../constants/colors";
import {OpenInNew} from "@mui/icons-material";
import {ContentCopy} from "@mui/icons-material";
import axios from "axios";
import Tooltip from '@mui/material/Tooltip';

const Button = styled(TextButton)`
  width: 100%;
  && {
    text-transform: none;
    margin: 3%;
    min-height: 48px;
    height: auto;
    border: ${({$orange}) => $orange ? BUTTON_COLORS.orange : BUTTON_COLORS.purple} 1px solid;
    background-color: ${({$orange}) => $orange ? BUTTON_COLORS.orange : BUTTON_COLORS.purple};
    color: #fff;
    font-size: 14px;
    font-weight: 500;
    &:hover {
      color: #fff;
      background-color: ${({$orange}) => $orange ? BUTTON_COLORS.orangeHover : BUTTON_COLORS.purpleHover}
    }
  }
`

const CheckBoxButton = styled(TextButton)`
  width: 100%;
  && {
    display: flex;
    justify-content: space-between;
    text-transform: none;
    margin: 3%;
    min-height: 48px;
    height: auto;
    border: ${BUTTON_COLORS.grey} 1px solid;
    background-color:white;
    color: ${BUTTON_COLORS.purple};
    font-size: 14px;
    font-weight: 500;
    &:hover {
      background-color: rgba(102, 51, 255, .1)
    }
    
  }
`

const Title = styled(DialogTitle)`
    color: #585C65;
    font-size: 2em;
    font-weight: 500;
    margin: 0 !important;
    display: flex;
    justify-content: center;
    padding: 20px 3% 50px;
`

const Content = styled(DialogContent)`
    color: #585C65;
    font-size: 1em;
    padding: 0 0 24px;
`

const Label = styled.div`
    opacity: 0.87;
    font-weight: 400;
    font-size: 1em;
    color: #000000;
    white-space: nowrap;
    align-self: center;
`

const StyledClearIcon = styled(ClearIcon)`
    cursor: pointer;
`

const Grid = styled.div`
    display: grid;
    grid-template-rows: 1fr;
    grid-template-columns: repeat(auto-fit, minmax(300px, calc(50% - 0.5px)));
    grid-gap: 1px;
    
    @media screen and (max-width: 1300px) {
        grid-template-columns: repeat(auto-fit, minmax(300px, 100%));
        grid-gap: 0px;
    }
`

const InfoTitle = styled.h1`
    text-align: center;
    padding: 0.5em 0;
    color: #585C65;
    font-weight: 500;
    font-size: 1em;
`

const InfoContent = styled.div`
    border-top: solid 1px #A1A5A6;
    color: #000000;
    line-height: 200%;
`

const Value = styled.span`
    font-weight: 500;
    align-self: end;
`

const Line = styled.div`
    justify-content: space-between;
    display: flex;
    padding-right: 10px;
    padding-left: 10px;
    background-color: #D8F8E9;
    &&:hover {
      background-color: #BED9CF;
    }
`

const CopyIcon = styled(ContentCopy)`
    color: #7C808A;
    margin-left: 10px;
    width: 20px;
    height: 20px;
    &&:hover {
      color: #4D4D4D;
    }
`

const Test = styled(Tooltip)`
  //background-color: black;
  //width: 20px;
//  height: 20px;
`

const LinkTuto = styled.a`
  color: ${Colors.link};
  font-size: 16px;
  font-weight: 700;

  &:hover {
    text-decoration: underline;
  }
  svg {
    transform: translateY(5px);
  }
`

const StyledTextField = styled(TextField)`
    height: 2em;
    min-width: 4em;
    input {
        padding: 2px 7px;
        text-align: right;
    }
`

export const DialogInvoice = ({ open, handleClose }) => {
  const consultation = useSelector(state => idx(state, _ => _.consultations.consultation))
  const type = useSelector(state => idx(state, _ => _.consultations.consultation.attributes.type))
  const doctor = useSelector(state => idx(state, _ => _.consultations.consultation.relationships.doctor.data.attributes))
  const additionalMember = useSelector(state => idx(state, _ => _.consultations.consultation.relationships.additionalMember.data[0].attributes.doctor))
  const patient = useSelector(state => idx(state, _ => _.consultations.consultation.relationships.patient.data.attributes))
  const consultationCodeActe = consultation.relationships?.medicalReport?.data[0]?.attributes?.consultation_code_acte

  const userId = useSelector(state => state.auth.attributes.user_id)

  const { formatMessage } = useIntl()
  const f = id => formatMessage({ id })
  const isRequested = doctor.user_id === userId
  const arrayCodeActNurse = ['TLS', 'TLL', 'TLD'];
  const consultationContext = type === 'TCA' && isRequested ? (consultation.attributes.isRequestedGeneralist ? 'TCG' : 'TC') : useSelector(state => idx(state, _ => _.consultations.consultation.attributes.consultationContext));
  const isNurse = arrayCodeActNurse.includes(consultationContext);

  const correspondent = isRequested ? additionalMember : doctor
  const [showDialogFDS, setShowDialogFDS] = useState(false);
  const [new_act_nurse_code, setNewActNurseCode] = React.useState(useSelector(state => idx(state, _ => _.consultations.consultation.attributes.consultationContext)));
  const dispatch = useDispatch()
  const [copied, setCopied] = useState({
    lastname: false,
    firstname: false,
    dateOfBirth: false,
    medicalInsurance: false,
    cpam: false,
    specialization: false,
    rpps: false,
    adeli: false,
    orderNumber: false,
  });
  const [showCopyIconPatient, setShowCopyIconPatient] = useState({
    lastname: false,
    firstname: false,
    dateOfBirth: false,
    medicalInsurance: false,
    cpam: false,
  });
  const [showCopyIconPro, setShowCopyIconPro] = useState({
    specialization: false,
    rpps: false,
    adeli: false,
    orderNumber: false,
  })
  const token = localStorage.getItem('authToken');
  const pdfActionUrl = `${window.env.API_URL}/api/consultation/${consultation.id}/billing`;
  const [isChecked, setIsChecked] = useState(isRequested ? consultation.attributes.billing_requis : consultation.attributes.billing_requerant);

  const handleChange = (event) => {
    setNewActNurseCode(event.target.value);
    dispatch(
      editConcilium({
      'consultationContext': event.target.value,
      'consultationID': consultation.id
    })
    )
  };

  useEffect(() => {
  }, [consultationContext,consultation.attributes.TCA_RQD_price]);

  const handleSubmitOnBilling = async () => {
    try{
      await axios.post(
          pdfActionUrl,
          {
            'isRequis' : isRequested
          },
          { headers: {Authorization: `Bearer ${token}`}}
      );
      setIsChecked(!isChecked);
    }catch (e) {
      console.error(e)
    }

  }

  const isTC = () => {
    return (type === 'scheduled' || type === "urgent")
  }

  const showCorrectTitle =  () => {
    switch(type) {
      case 'TCA' :
        return 'payments.dialog.title.TCA'
      case 'scheduled' :
      case 'urgent' :
        return 'payments.dialog.title.TC'
      default :
        return 'payments.dialog.title.TE'
    }
  }

  const correctAct = () => {
    switch(type) {
      case 'TCA' :
        return f('schedule.tca')
      case 'scheduled' :
      case 'urgent' :
        return f('consultation.teleconsultation')
      default :
        return f('consultation.teleexpertise') + ''  + (isRequested ?' ('+f('payments.dialog.requested')+')' : ' ('+f('payments.dialog.requester')+')')
    }
  }

  const correctCode = () => {
    if (isRequested) {
      if (consultationCodeActe != null && (isTC() || type === 'TCA')) {
        return consultationCodeActe;
      } // else
      return consultation.attributes.code_requis
    } else {
      return consultation.attributes.code_requerant
    }
  }

  const correctPrice = () => {
    switch(type) {
      case 'TCA' :
        return (isRequested ? consultation.attributes.TCA_Requested_price : consultation.attributes.TCA_RQD_price)  + "€"
      case 'scheduled' :
      case 'urgent' :
        return consultation.attributes.invoice.price
      default :
        return (isRequested ? consultation.attributes.TE2_price : consultation.attributes.RQD_price)  + "€"
    }
  }


  return (
    <Dialog
      open={open}
      onClose={handleClose}
      PaperProps={{
        style: { maxWidth: '60%', minWidth: '370px'}
      }}
    >
      <Title id='alert-dialog-title'>
          <div style={{margin: 'auto'}}>
            <FormattedMessage id={showCorrectTitle()} />
          </div>
      <StyledClearIcon onClick={() => handleClose()}/>
    </Title>

  <Content>
    <div style={{padding: '0 3%'}}>
          {!isTC() ? (
              <div>
                {
                  type === 'TCA'
                      ?
                      <FormattedMessage id='payments.dialog.explication.TCA'
                                        values={{'strong': (...chunks) => (<strong>{chunks}</strong>)}}/>
                      :
                      <div>
                        <FormattedMessage id='payments.dialog.explication.TE'
                                          values={{'strong': (...chunks) => (<strong>{chunks}</strong>)}}/>
                        <div>{f('payments.dialog.explication.TE.need_help')}<LinkTuto target='_blank' href="https://conexsante.notion.site/Tutoriels-de-facturations-b19ff0997809480293da97345d97501a?pvs=4" >{f('payments.dialog.explication.TE.need_help.link')}<OpenInNew></OpenInNew></LinkTuto></div>
                      </div>
                }
              </div>
              )
          : null
          }
        </div>
        <Form onSubmit={()=>{}}
              initialValues={{
                acte: correctAct(),
                code: correctCode(),
                tarif: correctPrice()
              }}
              render={({ handleSubmit, values }) => {
                return (
                  <form onSubmit={handleSubmit} style={{display: "flex", flexDirection: 'row', margin: '2em 3%', justifyContent: 'space-between', flexWrap: 'wrap', lineHeight: '3em'}}>
                    <div style={{display: 'flex', width: '40%', minWidth: '230px'}}>
                      <Label>
                        <FormattedMessage id={"payments.dialog.act"}/> :
                      </Label>
                      {
                        isNurse && type === 'TCA'
                          ?
                        <Box sx={{ minWidth: 180, margin: 'auto' }}>
                          <FormControl fullWidth>
                            <InputLabel id="act-code-select-label">Téléconsultation assistée réalisée</InputLabel>
                            <Select
                              labelId="act-code-select-label"
                              id="act-code-select"
                              value={new_act_nurse_code}
                              label="Téléconsultation assistée réalisée"
                              onChange={handleChange}
                              style={{height: '40px', marginLeft: '5px'}}
                            >
                              <MenuItem value={'TLS'}>{f('payments.dialog.first.proposition')}</MenuItem>
                              <MenuItem value={'TLL'}>{f('payments.dialog.second.proposition')}</MenuItem>
                              <MenuItem value={'TLD'}>{f('payments.dialog.third.proposition')}</MenuItem>
                            </Select>
                          </FormControl>
                        </Box>
                          :
                        <Field
                        component={StyledTextField}
                        type='text'
                        name='acte'
                        disabled
                        style={{display: 'flex', margin: '0 auto', alignSelf: 'center'}}
                        />
                      }
                    </div>
                    <div style={{display: 'flex', width: '20%', minWidth: '150px'}}>
                      <Label>
                        <FormattedMessage id={"payments.dialog.code"}/> :
                      </Label>
                      <Field
                        component={StyledTextField}
                        type='text'
                        name='code'
                        value={isRequested ? consultation.attributes.code_requis : consultation.attributes.code_requerant}
                        disabled
                        style={{display: 'flex', margin: '0 auto', alignSelf: 'center'}}
                      />
                    </div>
                    <div style={{display: 'flex', width: '20%', minWidth: '150px'}}>
                      <Label>
                        <FormattedMessage id={"payments.dialog.tarif"}/> :
                      </Label>
                      {
                        type === 'TCA' && !isRequested
                          ?
                        <div style={{margin: 'auto'}}> { consultation.attributes.TCA_RQD_price + "€"} </div>
                          :
                        <Field
                        component={StyledTextField}
                        type='text'
                        name='tarif'
                        value={type === 'TCA' ? consultation.attributes.TCA_Requested_price : ( isRequested ? consultation.attributes.TE2_price : consultation.attributes.RQD_price ) + "€"}
                        disabled
                        style={{display: 'flex', margin: '0 auto', alignSelf: 'center'}}
                        />
                      }
                    </div>
                  </form>
                )}}/>
        <Grid>
          <div style={{width: '100%', paddingLeft: '6%', paddingRight: '6%', boxSizing: 'border-box', backgroundColor: 'white'}}>
            <InfoTitle>Patient</InfoTitle>
            <InfoContent left>
              { patient.last_name &&
                  <Line
                    onMouseEnter={() => setShowCopyIconPatient({...showCopyIconPatient, lastname: true, firstname: false})}
                    onMouseLeave={() => setShowCopyIconPatient({...showCopyIconPatient, lastname: false})}
                  >
                    <span>{f('payments.dialog.last_name')}</span>
                    <Value>
                      <Tooltip
                        title={"La donnée a été copiée"}
                        disableFocusListener
                        disableHoverListener
                        disableTouchListener
                        open={copied['lastname']}
                        enterTouchDelay={3000}
                        placement={"right"}
                      >
                      {patient.last_name}
                      </Tooltip>
                      { showCopyIconPatient['lastname'] && (
                        <CopyIcon onClick={() => {
                          navigator.clipboard.writeText(patient.last_name);
                          setCopied({...copied, lastname: true});
                          setTimeout(() => {
                            setCopied({...copied, lastname: false});
                          }, 3000)
                        }}/>
                      )}
                    </Value>
                  </Line>
              }
              { patient.first_name &&
                  <Line
                      onMouseEnter={() => setShowCopyIconPatient({...showCopyIconPatient, firstname: true, lastname: false, dateOfBirth: false})}
                      onMouseLeave={() => setShowCopyIconPatient({...showCopyIconPatient, firstname: false})}
                  >
                    <span>{f('payments.dialog.first_name')}</span>
                    <Value>
                      <Tooltip
                          title={"La donnée a été copiée"}
                          disableFocusListener
                          disableHoverListener
                          disableTouchListener
                          open={copied['firstname']}
                          enterTouchDelay={3000}
                          placement={"right"}
                      >
                        {patient.first_name}
                      </Tooltip>
                      { showCopyIconPatient['firstname'] && (
                          <CopyIcon onClick={() => {
                            navigator.clipboard.writeText(patient.first_name);
                            setCopied({...copied, firstname: true});
                            setTimeout(() => {
                              setCopied({...copied, firstname: false});
                            }, 3000)
                          }}/>
                      )}
                    </Value>
                  </Line>
              }
              { patient.date_of_birth &&
                  <Line
                      onMouseEnter={() => setShowCopyIconPatient({...showCopyIconPatient, dateOfBirth: true, firstname: false, medicalInsurance: false})}
                      onMouseLeave={() => setShowCopyIconPatient({...showCopyIconPatient, dateOfBirth: false})}
                  >
                    <span>{f('payments.dialog.date_of_birth')}</span>
                    <Value>
                      <Tooltip
                          title={"La donnée a été copiée"}
                          disableFocusListener
                          disableHoverListener
                          disableTouchListener
                          open={copied['dateOfBirth']}
                          enterTouchDelay={3000}
                          placement={"right"}
                      >
                        {moment(patient.date_of_birth).format('DD/MM/YYYY')}
                      </Tooltip>
                      { showCopyIconPatient['dateOfBirth'] && (
                          <CopyIcon onClick={() => {
                            navigator.clipboard.writeText(moment(patient.date_of_birth).format('DD/MM/YYYY'));
                            setCopied({...copied, dateOfBirth: true});
                            setTimeout(() => {
                              setCopied({...copied, dateOfBirth: false});
                            }, 3000)
                          }}/>
                      )}
                    </Value>
                  </Line>
              }
              { patient.medical_insurance &&
                  <Line
                      onMouseEnter={() => setShowCopyIconPatient({...showCopyIconPatient, medicalInsurance: true, dateOfBirth: false, cpam: false})}
                      onMouseLeave={() => setShowCopyIconPatient({...showCopyIconPatient, medicalInsurance: false})}
                  >
                    <span>{f('payments.dialog.medical_insurance')}</span>
                    <Value>
                      <Tooltip
                          title={"La donnée a été copiée"}
                          disableFocusListener
                          disableHoverListener
                          disableTouchListener
                          open={copied['medicalInsurance']}
                          enterTouchDelay={3000}
                          placement={"right"}
                      >
                        {patient.medical_insurance}
                      </Tooltip>
                      { showCopyIconPatient['medicalInsurance'] && (
                          <CopyIcon onClick={() => {
                            navigator.clipboard.writeText(patient.medical_insurance);
                            setCopied({...copied, medicalInsurance: true});
                            setTimeout(() => {
                              setCopied({...copied, medicalInsurance: false});
                            }, 3000)
                          }}/>
                      )}
                    </Value>
                  </Line>
              }
              { patient.cpam &&
                  <Line
                      onMouseEnter={() => setShowCopyIconPatient({...showCopyIconPatient, cpam: true, medicalInsurance: false})}
                      onMouseLeave={() => setShowCopyIconPatient({...showCopyIconPatient, cpam: false})}
                  >
                    <span>{f('payments.dialog.cpam')}</span>
                    <Value>
                      <Tooltip
                          title={"La donnée a été copiée"}
                          disableFocusListener
                          disableHoverListener
                          disableTouchListener
                          open={copied['cpam']}
                          enterTouchDelay={3000}
                          placement={"right"}
                      >
                        {patient.cpam}
                      </Tooltip>
                      { showCopyIconPatient['cpam'] && (
                          <CopyIcon onClick={() => {
                            navigator.clipboard.writeText(patient.cpam);
                            setCopied({...copied, cpam: true});
                            setTimeout(() => {
                              setCopied({...copied, cpam: false});
                            }, 3000)
                          }}/>
                      )}
                    </Value>
                  </Line>
              }
            </InfoContent>
          </div>

          {
            !!correspondent && (
              <div style={{width: '100%', paddingLeft: '6%', paddingRight: '6%', boxSizing: 'border-box', backgroundColor: 'white'}}>
                <InfoTitle>{f(isRequested ? 'payments.dialog.requester' : 'payments.dialog.requested')}</InfoTitle>
                <InfoContent right>
                  <Line>
                    <div>Nom :</div>
                    <Value>
                      {fullName(correspondent.gender, correspondent.first_name, correspondent.last_name)}
                    </Value>
                  </Line>
                  { correspondent.specialization &&
                    <Line
                      onMouseEnter={() => setShowCopyIconPro({...showCopyIconPro, specialization: true, rpps: false})}
                      onMouseLeave={() => setShowCopyIconPro({...showCopyIconPro, specialization: false})}
                    >
                      <span>{f('payments.dialog.specialization')}</span>
                      <Value>
                        <Tooltip
                          title={"La donnée a été copiée"}
                          disableFocusListener
                          disableHoverListener
                          disableTouchListener
                          open={copied['specialization']}
                          enterTouchDelay={3000}
                          placement={"right"}
                        >
                          {correspondent.specialization}
                        </Tooltip>
                        { showCopyIconPro['specialization'] && (
                          <CopyIcon onClick={() => {
                            navigator.clipboard.writeText(correspondent.specialization);
                            setCopied({...copied, specialization: true});
                            setTimeout(() => {
                              setCopied({...copied, specialization: false});
                            }, 3000)
                          }}/>
                        )}
                      </Value>
                    </Line>
                  }
                  { correspondent.credentials.rpps &&
                    <Line
                      onMouseEnter={() => setShowCopyIconPro({...showCopyIconPro, rpps: true, specialization: false, adeli: false})}
                      onMouseLeave={() => setShowCopyIconPro({...showCopyIconPro, rpps: false})}
                    >
                      <span>{f('payments.dialog.RPPS')}</span>
                      <Value>
                        <Tooltip
                          title={"La donnée a été copiée"}
                          disableFocusListener
                          disableHoverListener
                          disableTouchListener
                          open={copied['rpps']}
                          enterTouchDelay={3000}
                          placement={"right"}
                        >
                          {correspondent.credentials.rpps}
                        </Tooltip>
                        { showCopyIconPro['rpps'] && (
                          <CopyIcon onClick={() => {
                            navigator.clipboard.writeText(correspondent.credentials.rpps);
                            setCopied({...copied, rpps: true});
                            setTimeout(() => {
                              setCopied({...copied, rpps: false});
                            }, 3000)
                          }}/>
                        )}
                      </Value>
                    </Line>
                  }
                  { correspondent.credentials.adeli &&
                    <Line
                      onMouseEnter={() => setShowCopyIconPro({...showCopyIconPro, adeli: true, rpps: false, orderNumber: false})}
                      onMouseLeave={() => setShowCopyIconPro({...showCopyIconPro, adeli: false})}
                    >
                      <span>{f('payments.dialog.ADELI')}</span>
                      <Value>
                        <Tooltip
                          title={"La donnée a été copiée"}
                          disableFocusListener
                          disableHoverListener
                          disableTouchListener
                          open={copied['adeli']}
                          enterTouchDelay={3000}
                          placement={"right"}
                        >
                          {correspondent.credentials.adeli}
                        </Tooltip>
                        { showCopyIconPro['adeli'] && (
                          <CopyIcon onClick={() => {
                            navigator.clipboard.writeText(correspondent.credentials.adeli);
                            setCopied({...copied, adeli: true});
                            setTimeout(() => {
                              setCopied({...copied, adeli: false});
                            }, 3000)
                          }}/>
                        )}
                      </Value>
                    </Line>
                  }
                  { correspondent.credentials.orderNumber &&
                    <Line
                      onMouseEnter={() => setShowCopyIconPro({...showCopyIconPro, orderNumber: true, adeli: false})}
                      onMouseLeave={() => setShowCopyIconPro({...showCopyIconPro, orderNumber: false})}
                    >
                      <span>{f('payments.dialog.FINESS')}</span>
                      <Value>
                        <Tooltip
                          title={"La donnée a été copiée"}
                          disableFocusListener
                          disableHoverListener
                          disableTouchListener
                          open={copied['orderNumber']}
                          enterTouchDelay={3000}
                          placement={"right"}
                        >
                          {correspondent.credentials.orderNumber}
                        </Tooltip>
                        { showCopyIconPro['orderNumber'] && (
                          <CopyIcon onClick={() => {
                            navigator.clipboard.writeText(correspondent.credentials.orderNumber);
                            setCopied({...copied, orderNumber: true});
                            setTimeout(() => {
                              setCopied({...copied, orderNumber: false});
                            }, 3000)
                          }}/>
                        )}
                      </Value>
                    </Line>
                  }
                </InfoContent>
              </div>
            )
          }
        </Grid>
      </Content>

      <DialogActions style={{ justifyContent: "space-around", padding: "0"}}>

        <Button onClick={() => setShowDialogFDS(true)}>
          <FormattedMessage id='payments.generate.fds'/>
        </Button>
        <DialogFDS
            open={showDialogFDS}
            handleClose={() => setShowDialogFDS(false)}
            handleSubmit={() => setShowDialogFDS(false)}
        />

        <CheckBoxButton onClick={() => handleSubmitOnBilling()}>
          {(type === 'TCA' || type === 'scheduled') ?
            <FormattedMessage id='payments.tca.handle_billing.btn'/> :
            <FormattedMessage id='payments.te.handle_billing.btn'/>
          }
          <input
              type="checkbox"
              name="billing"
              id="billing"
              checked={isChecked}
          />
        </CheckBoxButton>

      </DialogActions>
    </Dialog>)
}

import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import { compose, withProps } from 'recompose'
import { connect } from 'react-redux'
import { Form, Field } from 'react-final-form'
import { TextField } from 'common/UiKit/TextField'
import Isemail from 'isemail'
import queryString from 'query-string'
import { useIntl } from 'react-intl'
import { passwordRecovery } from '../../../../redux/modules/auth'
import { Form as FormWindow } from '../Form'
import { externalFormSubmit } from '../../../../helpers/externalFormSubmit'
import { history } from '../../../../utils/history'
import { Colors } from 'constants/colors'

const Wrapper = styled.div`
  @media only screen and (max-width: 1600px) {
    & > div {
      width: 80vw;
      height: 50vh;
    }
  }
`

const TextFieldStyled = styled(TextField)`
  &  .MuiFormHelperText-root.Mui-error {
    background-color: ${Colors.white15};
  },
`

const EmailComponent = ({
  loading,
  onSubmit,
  onButtonClick,
  onIconClick,
}) => {
  const { formatMessage } = useIntl()

  const validate = (values) => {
    const errors = {}

    if (!values.email) {
      errors.email = formatMessage({ id: 'global.password.email.missing'})
    } else if (!Isemail.validate(values.email)) {
      errors.email = formatMessage({ id: "global.password.email.wrong.format"})
    }

    return errors
  }

  return (
    <Wrapper>
      <Form
        onSubmit={values => onSubmit(values, formatMessage({ id: 'global.password.recovery.email.sent' }))}
        validate={validate}
        subscription={{}}
        render={({ handleSubmit }) => (
          <form onSubmit={handleSubmit} id='password_recovery'>
            <FormWindow
              button={formatMessage({ id: 'global.recovery.button' })}
              heading={formatMessage({ id: 'global.password.recovery' })}
              loading={loading}
              onButtonClick={onButtonClick}
              onIconClick={onIconClick}
              text={formatMessage({ id: 'global.recovery.text' })}
            >
              <Field
                name='email'
                label='*Email'
                component={TextFieldStyled}
              />
            </FormWindow>
          </form>
        )}
      />
    </Wrapper>
  )
}

EmailComponent.propTypes = {
  loading: PropTypes.bool.isRequired,
  onSubmit: PropTypes.func.isRequired,
  onButtonClick: PropTypes.func.isRequired,
  onIconClick: PropTypes.func.isRequired,
}

const enhance = compose(
  connect(state => ({ loading: state.loading.passwordRecovery }), { passwordRecovery }),
  withProps(props => ({
    onButtonClick: () => externalFormSubmit('password_recovery'),
    onSubmit: (values, successText) => props.passwordRecovery({
      values,
      type: queryString.parse(props.location.search).user,
    }, successText),
    onIconClick: () => history.push('/auth'),
  })),
)

export const Email = enhance(EmailComponent)

import React from 'react'
import styled from 'styled-components'
import { Field } from 'react-final-form'
import { Link } from 'react-router-dom'
import { FormattedMessage } from 'react-intl'
import { CheckboxField } from '../CheckboxField'
import { Colors } from '../../../constants/colors'

const Wrapper = styled.div`
  display: flex;
  padding-right: 40px;
  margin-bottom: 16px;
`

const Terms = styled.label`
  display: flex;
  font-family: Roboto, sans-serif;
  font-size: 14px;
  color: ${Colors.blueGrey};
  line-height: 1.29;

  & > div {
    margin-top: 14px;
    display: flex;
    white-space: nowrap;
    align-items: center;
  }

  a {
    margin-left: 5px;
  }
`

const Checkbox = styled(CheckboxField)`
  && {
    height: 24px;
  }
`

const LinkStyled = styled(Link)`
  text-decoration: none;
  color: ${Colors.bluePurple};
`

export const Switchers = () => {
const openCGU = () => {
  window.open("https://conexsante.com/cgu/", '_blank', 'noopener,noreferrer');
}
return (
  <Wrapper>
    <Terms>
      <Field
        name='accessPolicy'
        component={Checkbox}
        color='primary'
        type='checkbox'
      />
      <div>
        <FormattedMessage
          id='consultations.agree'
          values={{
            policy: (
              <LinkStyled onClick={() => openCGU()}>
                <FormattedMessage id='consultations.policy' />
              </LinkStyled>),
          }}
        />
      </div>
    </Terms>
  </Wrapper>
)}

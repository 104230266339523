import React, { useState } from 'react';
import styled from 'styled-components';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import { FormattedMessage } from 'react-intl';
import { useSelector } from 'react-redux';

export const TeamScopeOfActivity = ({ changeFilter }) => {
  const [activity, setActivity] = useState('udefined');  
  const handleChange = (event) => {
    changeFilter({ scope: event.target.value });
    setActivity(event.target.value);
  };
  const workPlaceMapConnect = useSelector(state => state.auth.attributes.workplace_map_connect);

  return (
    <StyledSelect>
      <FormControl variant='outlined'>
        <StyledSelectInput
          displayEmpty
          id='concilium.perimetr'
          value={activity}
          onChange={handleChange}
        > 
          <MenuItem value='udefined' disabled>
            <FormattedMessage id='concilium.perimetr' />
          </MenuItem>
          <MenuItem value=''><FormattedMessage id='concilium.localizationAll' /></MenuItem>
          <MenuItem value='international'><FormattedMessage id='concilium.localization.international' /></MenuItem>
          <MenuItem value='national'><FormattedMessage id='concilium.localization.national' /></MenuItem>
          <MenuItem disabled={!workPlaceMapConnect} value='local'><FormattedMessage id='concilium.localization.local' /></MenuItem>
          <MenuItem value='favorite'><FormattedMessage id='concilium.localization.MyNetwork' /></MenuItem>
        </StyledSelectInput>
      </FormControl>
    </StyledSelect>
  );
}

const StyledSelectInput = styled(Select)`
  &>div {
    padding: 5px 14px !important;
    padding-right: 24px !important;
  }
`

const StyledSelect = styled.div`
  width: 250px;
  float: right;
  &>div {
    width: 100% !important;
  }
  .MuiSelect-select:focus {
      background-color: #ffff;
  }
`

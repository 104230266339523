import styled from "styled-components";
import moment from "moment-timezone";
import {FormattedMessage} from "react-intl";
import {isConciliumCheck} from "../../../helpers/consultations";
import {CONSULTATION_TYPES} from "../../../constants/consultation";
import {useSelector} from "react-redux";
import {TextButton} from "../../common/UiKit/TextButton";
import React, {useEffect, useLayoutEffect, useRef, useState} from "react";
import idx from "idx";
import VideocamIcon from '@mui/icons-material/Videocam';
import CloseIcon from '@mui/icons-material/Close';
import {Box} from "@mui/material";
import {BUTTON_COLORS} from "../../../constants/colors";
import Button from "../../common/UiKit/Button";
import Videocam from "@mui/icons-material/Videocam";

export const Actions = ({
    onCallIconClick,
    onVideoIconClick,
    doc2uOpen,
    onDoc2UButtonClick,
    btnDoc2UText,
    actionsWidth,
    setActionsWidth }) => {
    const hasVideoAccess = useSelector(state => (state.consultations.mediaAccess.video ? 'video' : 'audio'))
    const [selected] = useState(hasVideoAccess)
    const isDoctor = useSelector(state => state.auth.type === 'Doctor')
    const consulationType = useSelector(state => state.consultations.consultation.attributes.type)
    const statut = useSelector(state => (state.consultations.consultation.attributes.status === 'completed'))
    const whomRequested = useSelector(state => idx(state, _ => _.consultations.consultation.relationships.doctor.data.id));
    const userId = useSelector(state => idx(state, _ => _.auth.id))
    const isMeRequested = (whomRequested === userId);


    const isConcilium = isConciliumCheck(consulationType);
    const isTERESS = consulationType === CONSULTATION_TYPES.TER_ESS
    const isTCA = consulationType === CONSULTATION_TYPES.TCA
    const isTC = (consulationType === CONSULTATION_TYPES.scheduled || consulationType === CONSULTATION_TYPES.urgent);

    const ref = useRef(null)

    useLayoutEffect(() => {
        const { width } = ref.current.getBoundingClientRect();
        setActionsWidth(width);
    }, []);

    return (
        <Wrapper ref={ref}>
            {
              (isTCA) ? (
                <JoinButton variant='contained' color='primary' onClick={() => onDoc2UButtonClick()}>
                    { !doc2uOpen ?
                      <Box sx={{display: {xs: 'none', lg: 'flex'}}}>
                          <FormattedMessage id={btnDoc2UText} />
                          &nbsp;
                      </Box>
                      :
                      <>
                          <Box sx={{display: {xs: 'flex', xl:'none'}}} >
                              <CloseIcon/>
                          </Box>
                          <Box sx={{display: {xs: 'none', xl:'flex'}}}>
                              <FormattedMessage id={btnDoc2UText} />
                              &nbsp;
                          </Box>
                      </>
                    }
                    <FormattedMessage id={'waiting.doc2u'}/>
                </JoinButton>
              ) : ''
            }
            {
              (isConcilium || isTC || isTCA) && (!isTERESS) &&
              (isDoctor && isMeRequested && !statut) ? (
                <>
                    <Button
                      variant='text'
                      color='grey'
                      tooltipTitle='Appel Visio'
                      style={{zIndex: 'auto'}}
                      onClick={() => (selected === 'video' ? onVideoIconClick() : onCallIconClick())}
                    >
                        <VideocamIcon/>
                    </Button>
                </>
              ) : ''
            }
        </Wrapper>
    )
}

const StyledBox = styled(Box)`
  padding-left: 10px;
`

const Wrapper = styled.div`
  display: flex;
  gap: 5%;
  margin-right: 5%;
`
const JoinButton = styled(TextButton)`
  && {
    text-transform: none;
    font-weight: 500;
    height: 40px;
    box-shadow: none;
    font-size: 14px;
    border-radius: 4px;
    background-color: ${BUTTON_COLORS.purple};
    color: #fff;
  }
  &&:hover {
    background-color: ${BUTTON_COLORS.purpleHover};
    color: #fff;
  }
`

import React, {useState} from 'react'
import styled from 'styled-components'
import {DatePicker} from 'common/UiKit/DatePicker'
import {Colors} from 'constants/colors'
import PropTypes from 'prop-types'
import {
  ThemeProvider, StyledEngineProvider, createTheme,
} from '@mui/material';
import {FormattedMessage} from 'react-intl'
import {useDispatch, useSelector} from 'react-redux'
import RotateLeftIcon from '@mui/icons-material/RotateLeft';
import {Search} from '../../../../common/Search'
import {searchConsultations} from '../../../../../redux/modules/settings'
import {TextButton} from 'common/UiKit/TextButton'
import {history} from "../../../../../utils/history";
import AddIcon from '@mui/icons-material/Add';


const Button = styled(TextButton)`
  && {
    text-transform: none;
    width: 80%;
    min-width : 230px;
    margin-top: 25px;
    margin-bottom: 10px;
  }
  &:hover{
    background-color: white;
    color: #6633FF;
    border: 2px solid #6633FF;
    box-shadow: none;  
  }
`


const Wrapper = styled.div`
  display: flex;
  justify-content: left;
  align-item: center;
  min-width: 830px;
  @media screen and (max-width: 4000px) {
      margin-right: -100px;
  }
  @media screen and (max-width: 1800px) {
    margin-right: -50px;
  }
  @media screen and (max-width: 1400px) {
    margin-right: -200px;
  }

`

const HeaderItem = styled.div`
  z-index: 1;
  margin-right: 6vw;
  & > * > &:last-child {
  margin-right: 5px;
  }
  & > * {
  font-size: 14px;
  text-transform: capitalize;
  }
`


const SearchWrapper = styled.div`
  margin-top: 8px;
  width: 700px;
  & > * {
    border: 1px solid #c5cbdb;
  }
`

export const Header = ({}) => {
  const dispatch = useDispatch()

  const locale = useSelector(state => state.localization)


  return (
    <Wrapper key={locale}>
        <HeaderItem style={{width: '230px'}}>
        <Button
            onClick={
                () => history.push(`/panel/doctors/main`)}
            variant='contained'
            color='primary'
        >
            <AddIcon fontSize={'large'}/>
            <FormattedMessage id='concilium.new.TC.patient'/>
        </Button>
        </HeaderItem>
      {/*<SearchWrapper>
        <Search onSearch={value => dispatch(searchConsultations(value))}/>
      </SearchWrapper>*/}
    </Wrapper>
  );
}

Header.propTypes = {
  from: PropTypes.object.isRequired,
  to: PropTypes.object.isRequired,
  handleFromChange: PropTypes.func.isRequired,
  handleToChange: PropTypes.func.isRequired,
}

import React from 'react'
import { Form } from 'react-final-form'
import { withProps } from 'recompose'
import PropTypes from 'prop-types'
import { Symptoms } from './Symptoms'
import { Switchers } from './Switchers'

const validate = ({ symptomExplanation, accessPolicy }) => {
  const errors = {}

  if (!symptomExplanation || symptomExplanation.length > 300) errors.symptomExplanation = true
  if (!accessPolicy) errors.accessPolicy = true

  return errors
}

const enhance = withProps(props => ({
  onSubmit: values => props.onSubmit(values)
    .then(() => props.afterSubmit()),
}))

export const UrgentConsultationForm = enhance(({
  onSubmit,
  id,
  onAccessError,
}) => (
  <Form
    onSubmit={onSubmit}
    subscription={{ errors: true }}
    validate={validate}
    initialValues={{
      accessHealthRecord: false,
    }}
    render={({ handleSubmit, errors }) => (
      <form
        onSubmit={(e) => {
          if (Object.keys(errors).length === 1 && errors.accessPolicy) {
            onAccessError()
          }

          handleSubmit(e)
        }}
        id={id}
      >
        <Symptoms />
        <Switchers />
      </form>
    )}
  />
))

UrgentConsultationForm.defaultProps = {
  initialValues: false,
}

UrgentConsultationForm.propTypes = {
  afterSubmit: PropTypes.func.isRequired,
  onSubmit: PropTypes.func.isRequired,
  id: PropTypes.string.isRequired,
  onAccessError: PropTypes.func.isRequired,
}

import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import { Paper } from 'common/UiKit/Paper'
import { Colors } from 'constants/colors'
import moment from 'moment-timezone'

import { FullStar } from '../../Consultations/List/ConsultationsList/RateModal'
import defaultAvatar from '../../../../../images/panel/default-avatar.svg'

export const Review = ({
  photo,
  firstName,
  lastName,
  text,
  date,
  rating,
}) => (
  <Wrapper>
    <Content>
      <Photo photo={photo} />
      <TextWrapper>
        <Name>
          {firstName}
          {' '}
          {lastName}
        </Name>
        <Text>
          {text}
        </Text>
        <Date>
          {moment(date).format('MMMM DDD, YYYY')}
        </Date>
      </TextWrapper>
      <Rating>
        {rating}
        <FullStar />
      </Rating>
    </Content>
  </Wrapper>
)

Review.defaultProps = {
  photo: null,
}

Review.propTypes = {
  firstName: PropTypes.string.isRequired,
  lastName: PropTypes.string.isRequired,
  photo: PropTypes.string,
  text: PropTypes.string.isRequired,
  date: PropTypes.string.isRequired,
  rating: PropTypes.number.isRequired,
}

//#region Styled components
const Wrapper = styled(Paper)`
  margin-bottom: 10px;
  padding: 18px 22px;
`

const Content = styled.div`
  display: flex;
  align-items: flex-start;
  position: relative;
`

const Photo = styled.div`
  border-radius: 50%;
  margin-right: 20px;
  height: 38px;
  min-width: 38px;
  width: 38px;
  border: 3px solid ${Colors.white};
  box-shadow: 0 4px 8px 0 ${Colors.azure};
  background-image: url(${props => props.photo || defaultAvatar});
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center center;
`

const Name = styled.div`
  font-weight: 500;
  font-size: 18px;
  line-height: 20px;
  margin-bottom: 8px;
`

const TextWrapper = styled.div`

`

const Text = styled.div`
  font-size: 16px;
  line-height: 24px;
  color: ${Colors.dark60};
  margin-bottom: 12px;
`

const Date = styled.div`
  font-size: 12px;
  line-height: 17px;
  color: ${Colors.blueGrey};
`

const Rating = styled.div`
  position: absolute;
  top: 0;
  right: 0;
  font-size: 16px;
  line-height: 20px;
  display: flex;
  align-items: center;
  font-weight: 500;
  
  svg {
    margin-left: 3px;
    width: 20px !important;
    height: 20px !important;
  }
`
//#endregion

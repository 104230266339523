import { connect } from 'react-redux'
import { compose } from 'recompose'
import idx from 'idx'
import { ConsultationsList } from './ConsultationsList'
import { fetchConsultations } from '../../../../../redux/modules/consultations'
import { history } from '../../../../../utils/history'

export const Upcoming  = compose(
  connect(
    state => ({
      consultations: idx(state, _ => _.consultations.list.data),
      afterConsultationClick: () => history.push('/panel/consultations/session'),
      filters: { filter: 'upcoming', isconcilium : state.isConciliumInUrl},
    }),
    ({ fetchConsultations }),
  ),
)(ConsultationsList)

import React from 'react'
import { Form, Field } from 'react-final-form'
import { mapProps } from 'recompose'
import PropTypes from 'prop-types'
import { Diplomas } from './Diplomas'

const enhance = mapProps(props => ({
  ...props,
  diplomas: props.diplomas || [],
  signatures: props.signatures || [],
}))

export const DiplomasForm = enhance(({
  onSubmit,
  id,
  diplomas,
  disableShadow,
  disableEdit,
  onPaperClick,
  createDiplomas,
  deleteDiploma,
  acceptedExtensions,
  signatures,
  withFilenames,
}) => (
  <Form
    onSubmit={onSubmit}
    subscription={{}}
    render={({ handleSubmit }) => (
      <form onSubmit={handleSubmit} id={id}>
        <Field
          name='diplomas'
          component={Diplomas}
          diplomas={diplomas}
          signatures={signatures}
          disableShadow={disableShadow}
          disableEdit={disableEdit}
          onPaperClick={onPaperClick}
          createDiplomas={createDiplomas}
          deleteDiploma={deleteDiploma}
          acceptedExtensions={acceptedExtensions}
          withFilenames={withFilenames}
        />
      </form>
    )}
  />
))

DiplomasForm.defaultProps = {
  disableEdit: false,
  disableShadow: false,
  withFilenames: false,
}

DiplomasForm.propTypes = {
  onSubmit: PropTypes.func.isRequired,
  onPaperClick: PropTypes.func,
  createDiplomas: PropTypes.func,
  deleteDiploma: PropTypes.func,
  id: PropTypes.string.isRequired,
  diplomas: PropTypes.arrayOf(PropTypes.object),
  disableShadow: PropTypes.bool,
  disableEdit: PropTypes.bool,
  acceptedExtensions: PropTypes.string,
  withFilenames: PropTypes.bool,
}

import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import styled from 'styled-components';
import Paper from '@mui/material/Paper';
import CloseIcon from '@mui/icons-material/Close';
import get from 'lodash/get';
import { withRouter } from 'react-router-dom';
import orderBy from 'lodash/orderBy';
import { useIntl } from 'react-intl';
import uniq from 'lodash/uniq';
import { blueGrey } from '../../../constants/colors';
import { history } from '../../../utils/history';
import { TemporaryWrapper } from './temporaryComponent';

const ChatNotificationsContainer = styled.div`
    position: absolute;
    right: 16px;
    width: 260px;
    z-index: 500;
    bottom: 0;
`

const Avatar = styled.div`
    width: 44px;
    height: 45px;
    margin-left: 12px;
    margin-top: 12px;
    margin-bottom: 12px;
    border-radius: 50px;
    background-image: ${({ photo }) => `url(${photo})`};
    margin-right: 22px;
    background-size: cover;
    background-position: center;
    box-shadow: 0px 4px 6px -3px;
    border: 2px solid #fff;
`

const RightSideNotification = styled.div`
    background-color: #fff;
`

const MessageContainer = styled(Paper)`
  display: flex;
  align-items: center;
  position: relative;
  margin-bottom: 16px;
  & > :hover {
    cursor: pointer;
  }
`

const TitleItem = styled.div`
    font-size: 14px;
    font-weight: 500;
    color: #21293e;
    max-width: 145px;
    overflow: hidden;
    text-overflow: ellipsis;
`

const Message = styled.div`
    font-size: 14px;
    color: ${blueGrey};
    max-width: 145px;
    overflow: hidden;
    text-overflow: ellipsis;
`

const StyledClose = styled(CloseIcon)`
    && {
        position: absolute;
        top: 12px;
        right: 12px;
        font-size: 16px;
        color: ${blueGrey};
        cursor: pointer;
    }
`

export const ChatNotifications = withRouter(({ location }) => {
  const [closed, setClosed] = useState([]);
  const currentPage = location.pathname;
  const isConsultationOpen = /\/panel\/consultations\/.*/.test(currentPage);
  const currentOpenedChat = useSelector(state => {
    const chatId = get(state, 'consultations.consultation.chatId', false)
    if (chatId) {
      return parseInt(chatId)
    } // else
    return false
  });
  const currentUser = useSelector(state => state.auth)
  const currentUserId = get(currentUser, 'attributes.user_id', false);
  const chatsNotifications = useSelector(state => state.chatsNotifications)
  const { formatMessage } = useIntl()
  const f = id => formatMessage({ id })

  const chatWithUsers = orderBy(chatsNotifications
    .filter(
      el => el.chat_id && el.id
      && !closed.includes(el.id)
      && el.sender !== currentUserId
      && !(el.chat_id === currentOpenedChat && isConsultationOpen)
      && (el.type === 'Text' || el.type === 'File')
    )
    .map(el => ({ ...el, senderAttributes: el.sender })), 'id')
    .slice(0, 5);

  useEffect(() => {
    if (currentOpenedChat && isConsultationOpen && chatsNotifications && chatsNotifications.length) {
      const allIdsFromChat = chatsNotifications
        .filter(el => el && el.chat_id && el.chat_id === currentOpenedChat)
        .map(el => el.id);
      setClosed(uniq([...closed, ...allIdsFromChat]));
    }
  }, [chatsNotifications])

  return (
    <ChatNotificationsContainer>

      {chatWithUsers.map((el, index) => {
        const senderName = get(el, 'sender_name', 'Unknown');
        const senderAvatar = get(el, 'avatar', '/static/media/default-avatar.b887eb6a.svg');
        const relatedChatId = get(el, 'chat_relate_id', false);
        const chatType = get(el, 'chat_relate_type', false);
        let chatLink
        switch (chatType) {
          case 'consultation':
            chatLink = `/panel/consultations/${relatedChatId}`
            break
          case 'discussion':
            chatLink = `/panel/discussions/${relatedChatId}`
            break
          case 'support':
            chatLink = '/panel/chat-administrator'
            break
          default:
            chatLink = '#'
            break
        }

        return (
          <TemporaryWrapper key={el.id}>
            <MessageContainer onClick={() => history.push(chatLink)}>
              <Avatar photo={senderAvatar} />
              <RightSideNotification>
                <TitleItem>{senderName}</TitleItem>
                <Message>{typeof el.message === 'string' ? el.message : f('consultation.sentattachment')}</Message>
              </RightSideNotification>
              <StyledClose onClick={(e) => {
                e.stopPropagation();
                setClosed([...closed, el.id])
              }}
              />
            </MessageContainer>
          </TemporaryWrapper>
        )
      })}
    </ChatNotificationsContainer>
  )
})

import React from 'react'
import PropTypes from 'prop-types'
import { NotificationsButton } from './Notifications'
import { PanelBar } from '../PanelBar'
import PageWrapper from "./PageWrapper";

export const Page = ({
  myUserStatus,
  label,
  children,
  onBackIconClick,
  id,
  onLogout,
  Icon,
  onIconClick,
  isFetching,
  fullWidth,
  noWrapperPadding,
  noHeaderCapitalize,
  ...rest
}) => {

  return(
    <PageWrapper
      noWrapperPadding={noWrapperPadding}
      isFetching={isFetching}
      fullWidth={fullWidth}
      panelBar={<PanelBar
        label={label}
        onBackIconClick={onBackIconClick}
        id={id}
        onLogout={onLogout}
        Icon={Icon}
        onIconClick={onIconClick}
        noHeaderCapitalize={noHeaderCapitalize}
        notification={<NotificationsButton />}
        myUserStatus={myUserStatus}
      />}
      {...rest}
    >
      {children}
    </PageWrapper>
  )
}

Page.defaultProps = {
  id: false,
  onLogout: false,
  onBackIconClick: false,
  onIconClick: false,
  isFetching: false,
  fullWidth: false,
}

Page.propTypes = {
  children: PropTypes.node.isRequired,
  label: PropTypes.string.isRequired,
  id: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]),
  onLogout: PropTypes.oneOfType([PropTypes.func, PropTypes.bool]),
  onBackIconClick: PropTypes.oneOfType([PropTypes.func, PropTypes.bool]),
  onIconClick: PropTypes.oneOfType([PropTypes.func, PropTypes.bool]),
  isFetching: PropTypes.bool,
  fullWidth: PropTypes.bool,
}
import { handleActions, createAction } from 'redux-actions'
import { combineReducers } from 'redux'
import axios from 'axios'
import {handleMessageAction} from "./chats";
import {DISCUSSION_TYPE__GROUP, DISCUSSION_TYPE__INDIVIDUAL} from "../../constants/discussion";

//#region Actions
export const fetchDiscussionsListRequest = createAction('FETCH_DISCUSSIONS_LIST_REQUEST')
export const fetchDiscussionsListSuccess = createAction('FETCH_DISCUSSIONS_LIST_SUCCESS')
export const fetchDiscussionsListFailure = createAction('FETCH_DISCUSSIONS_LIST_FAILURE')

export const fetchDiscussions = params => (dispatch) => {
  dispatch(fetchDiscussionsListRequest(params))

  return axios(`discussion`, { params })
    .then((res) => {
      dispatch(fetchDiscussionsListSuccess(res.data.data))
    })
    .catch((e) => {
      dispatch(fetchDiscussionsListFailure(e))
    })
}

export const deleteDiscussionRequest = createAction('DELETE_DISCUSSION_REQUEST')
export const deleteDiscussionSuccess = createAction('DELETE_DISCUSSION_SUCCESS')
export const deleteDiscussionFailure = createAction('DELETE_DISCUSSION_FAILURE')

/**
 * Delete a discussion based on the id given
 * @param discussionId
 */
export const deleteDiscussion = (discussionId) => (dispatch) => {
  dispatch(deleteDiscussionRequest(discussionId))
  return axios.delete(`/discussion/${discussionId}`)
    .then((res) => {
      return dispatch(deleteDiscussionSuccess(res))
    })
    .catch((err) => {
      return dispatch(deleteDiscussionFailure(err))
    })
}

export const fetchDiscussionRequest = createAction('FETCH_DISCUSSION_REQUEST')
export const fetchDiscussionSuccess = createAction('FETCH_DISCUSSION_SUCCESS')
export const fetchDiscussionError = createAction('FETCH_DISCUSSION_ERROR')
export const fetchDiscussionSocketRequest = createAction('FETCH_DISCUSSION_SOCKET_REQUEST')
export const fetchDiscussionSocketSuccess = createAction('FETCH_DISCUSSION_SOCKET_SUCCESS')
export const fetchDiscussionSocketError = createAction('FETCH_DISCUSSION_SOCKET_ERROR')

/**
 *
 * @param id
 * @param socketUpdate indicate if the function is called after a socket update
 * @returns {(function(*): (*))|*}
 */
export const fetchDiscussion = (id, socketUpdate = false) => (dispatch) => {

  //if the fetch is requested because of a socket update (i.e a new message was received in a discussion)
  // we use different redux action
  if (socketUpdate) {
    dispatch(fetchDiscussionSocketRequest(id))
    if (!id) {
      return dispatch(fetchDiscussionSocketSuccess(null))
    }
    return axios.get(`/discussion/${id}`, {
      params: {
        include: 'chat,team',
      }
    })
      .then((res) => {
        dispatch(fetchDiscussionSocketSuccess(res.data.data))
        return res;
      })
      .catch((e) => {
        dispatch(fetchDiscussionSocketError(e))
      })
  } else {
    dispatch(fetchDiscussionRequest(id))
    if (!id) {
      return dispatch(fetchDiscussionSuccess(null))
    }
    return axios.get(`/discussion/${id}`, {
      params: {
        include: 'chat,team',
      }
    })
      .then((res) => {
        dispatch(fetchDiscussionSuccess(res.data.data))
        return res;
      })
      .catch((e) => {
        dispatch(fetchDiscussionError(e))
      })
  }
}

export const CreateDiscussionRequest = createAction('CREATE_DISCUSSION_REQUEST')
export const CreateDiscussionSuccess = createAction('CREATE_DISCUSSION_SUCCESS')
export const CreateDiscussionFailure = createAction('CREATE_DISCUSSION_FAILURE')

/**
 * @param member
 */
export const createIndividualDiscussion = (member) => (dispatch) => {
  return createDiscussion(DISCUSSION_TYPE__INDIVIDUAL, [member], null, dispatch)
}

/**
 * @param {Array} members
 * @param {string} groupName
 */
export const createGroupDiscussion = (members, groupName) => (dispatch) => {
  return createDiscussion(DISCUSSION_TYPE__GROUP, members, groupName, dispatch)
}

const createDiscussion = (type, members, groupName, dispatch) => {
  dispatch(CreateDiscussionRequest)
  const options = {type: type, members: members}
  if (type !== DISCUSSION_TYPE__INDIVIDUAL && groupName) {
    options.name = groupName
  }
  return axios.post('/discussion', options)
    .then((res) =>  {
      return dispatch(CreateDiscussionSuccess(res.data.data))
    })
    .catch((err) => {
      dispatch(CreateDiscussionFailure(err))
      return Promise.reject(err)
    })
}

export const ModifyDiscussionRequest = createAction('MODIFY_DISCUSSION_REQUEST')
export const ModifyDiscussionSuccess = createAction('MODIFY_DISCUSSION_SUCCESS')
export const ModifyDiscussionFailure = createAction('MODIFY_DISCUSSION_FAILURE')
/**
 * @param {number} discussionId
 * @param {string} groupName
 */
export const modifyDiscussionName = (discussionId, groupName) => (dispatch) => {
  dispatch(ModifyDiscussionRequest)
  return axios.post(`/discussion/${discussionId}/edit`, {name: groupName})
    .then((res) => {
      return dispatch(ModifyDiscussionSuccess(res.data.data))
    })
    .catch((err) => {
      return dispatch(ModifyDiscussionFailure(err))
    })
}

export const hasUnreadMessageRequest = createAction('HAS_UNREAD_MESSAGE_REQUEST')
export const hasUnreadMessageSuccess = createAction('HAS_UNREAD_MESSAGE_SUCCESS')
export const hasUnreadMessageFailure = createAction('HAS_UNREAD_MESSAGE_FAILURE')

export const hasUnreadMessage = () => (dispatch) => {
  dispatch(hasUnreadMessageRequest())
  axios.get(`/discussion/has-unread-messages`)
    .then((response) => {
      dispatch(hasUnreadMessageSuccess(response.data['has-unread-messages']))
    })
    .catch(err => {
      dispatch(hasUnreadMessageFailure(err))
    })
}

//#endregion

const discussionUpdated = (state, action) => {
  if (action.payload?.id) {
    state = {...state}
    if (state.index[action.payload.id] !== undefined) {
      state.data[state.index[action.payload.id]] = action.payload
    } else {
      let index = state.data.push(action.payload) - 1
      state.index[action.payload.id] = index
    }
  }
  return state
}

//#region Reducers
const list = handleActions({
  [fetchDiscussionsListSuccess]: (state, action) => ({
    ...state,
    index: action.payload?.reduce((a, v, index) => ({...a, [`${v.id}`]: index}), {}),
    data: action.payload,
  }),
  [fetchDiscussionSuccess]: discussionUpdated,
  [fetchDiscussionSocketSuccess]: discussionUpdated,
  [CreateDiscussionSuccess]: discussionUpdated,
  [ModifyDiscussionSuccess]: discussionUpdated,
  [handleMessageAction]: (state, action) => {
    if (
      action.payload.action === 'MessageReceived' &&
      action.payload.attributes?.type === 'System' &&
      action.payload.attributes?.message?.type === 'removed-discussion'
    ) {
      state = {...state}
      const discussionId = parseInt(action.payload.attributes.chat_relate_id)
      if (state.index[discussionId] !== undefined) {
        state.data.splice(state.index[discussionId], 1)
        delete state.index[discussionId]
      }
    }
    return state
  }
}, {
  index: {},
  data: []
})

const discussion = handleActions({
  [fetchDiscussionSuccess]: (state, action) => action.payload,
  [CreateDiscussionSuccess]: (state, action) => action.payload,
  [ModifyDiscussionSuccess]: (state, action) => action.payload,
}, null)

const unreadMessage = handleActions({
  [hasUnreadMessageSuccess]: (state, action) => action.payload,
  [hasUnreadMessageFailure]: () => false,
}, false)

const lastMessage = handleActions({
  [handleMessageAction]: (state, { payload }) => {
    if (
      payload.attributes?.chat_relate_type === 'discussion'
      && payload.action !== 'MessageReaded'
    ) {
      return new Date().getTime()
    }
    return state
  },
}, 0)

export const discussions = combineReducers({
  list,
  discussion,
  unreadMessage,
  lastMessage,
})
//#endregion

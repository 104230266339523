import React, {useEffect, useState} from "react";
import {FormattedMessage} from "react-intl";
import styled from "styled-components";
import Button from "@mui/material/Button";
import {Dialog} from "@material-ui/core";
import {TextButton} from "../../../common/UiKit/TextButton";
import {StepDoctor} from "./StepDoctor";
import {StepStructure} from "./StepStructure";
import axios from "axios";
import {StepOther} from "./StepOther";
import CloseIcon from "@mui/icons-material/Close";
import IconButton from "@mui/material/IconButton";
import Box from "@mui/material/Box";
import {FormControl} from "@mui/material";
import FormControlLabel from "@mui/material/FormControlLabel";
import RadioGroup from "@mui/material/RadioGroup";
import Radio from "@mui/material/Radio";
import {history} from "../../../../utils/history";
import {useSelector} from "react-redux";
import {SURVEY} from "../../../../constants/forms";
import {Colors} from "../../../../constants/colors";

const ButtonStyled = styled(Button)`
  width: 50px;
`
const DialogStyled = styled(Dialog)`
  backdrop-filter: blur(2px);
`
const Wrapper = styled.div`
    justify-content: center;
`

const ContinueButton = styled(TextButton)`
  box-shadow: none;
  align-self: center;
  z-index: 10;
  && {
    width: 20%;
    height: 35px;
    display: block;
    margin: 24px auto 30px auto;
    background-color: #6633FF;
    border: 1px solid #fff;
    color: #fff;
  }
  &:hover{
    background-color: rgb(71, 35, 178);
    border: 1px solid #fff;
    color: #fff;
  }
  text-transform : none;
  font-weight: 500;
  font-size: 14px;
`
const IconButtonStyled = styled(IconButton)`
  width: 10px;
  height: 10px;
  margin-top: 5px;
  margin-right: 10px;
  margin-left: 10px;
  padding: 20px 20px 20px 20px;
  color: ${Colors.steel};
  position: absolute;
  top: 0px;
  right: 0px
`
const TitleStyled = styled.div`
  font-size: 18px;
  text-align: center;
  font-weight: 500;
  display: block;
  margin-top: 30px;
  margin-left: auto;
  margin-right: auto;
`
const RadioGroupStyled = styled(RadioGroup)`
  align-content: center;
  font-size: 14px;
  margin-top: 25px;
  & > * {
    font-size: 12px;
    color: #00000099;
  }
`
const RadioStyled = styled(Radio)`
  color: #6633FF;
  font-size: 10px;
`

export const Survey = () => {
    const [open, setOpen] = useState(true);
    const doctorId = useSelector(state => state.auth.id);
    const type = useSelector(state => state.auth.type);
    const [step, setStep] = useState('first');
    const [isAlreadySeen, setIsAlreadySeen] = useState('');

    useEffect(() => {
        axios.get(`/forms/view/${SURVEY}/${doctorId}`)
            .then((response) => {
                setIsAlreadySeen(response.data)
            });
    }, []);

    const isViewed = async () => {
        try {
            await axios.post('forms/view', {doctor_id: doctorId, dictionary_id: SURVEY, is_viewed: true})
        } catch (e) {
            console.warn(e);
        }
    }
    const handleClose = async () =>  {
        setOpen(false);
        await isViewed();
    }

    const [constantes, setConstantes] = useState('');
    const libelle = 'forms_answer';

    useEffect(() => {
        axios.get(`/forms/constantes/${libelle}`)
            .then((response) => {
                setConstantes(response.data)
            });
    }, []);

    const [value, setValue] = useState('');

    const answers = [];
    for (let i = 0; i<constantes.length; i++){
        answers.push(
            <FormControlLabel
                value={constantes[i].libFr}
                control={<RadioStyled size="small" />}
                label={constantes[i].libFr} />)
    }
    const handleRadioChange = (event) => {
        setValue(event.target.value);
    }

    return (
        <Wrapper>
            { type === "Doctor" && isAlreadySeen === false && (

                <DialogStyled open={open} onClose={handleClose}>
                    <IconButtonStyled onClick={handleClose}>
                        <CloseIcon />
                    </IconButtonStyled>

                    { step === 'first' && (
                        <Wrapper>
                            <Box display="flex">
                                <TitleStyled>
                                    <FormattedMessage id='survey.welcome'/>
                                    <br/>
                                    <FormattedMessage id='survey.how'/>
                                </TitleStyled>
                            </Box>
                            <form>
                                <FormControl sx={{ml: 13, mr: 5}}>
                                    <RadioGroupStyled name="answers-question-group" value={value} onChange={handleRadioChange}>
                                        { answers }
                                    </RadioGroupStyled>
                                </FormControl>
                            </form>
                            <ContinueButton
                                onClick={
                                    async () => {
                                        if (value === "J'ai été invité(e) par un(e) confrère(soeur)") {
                                            setStep('doctor');
                                        }
                                        if (value === "Via ma structure") {
                                            setStep('structure');
                                        }
                                        if (value === "Autre"){
                                            setStep('autre');
                                        }
                                        if (value === "Via une communication (newsletter, réseaux sociaux, annuaire e-CPS, ...)"){
                                            try {
                                                await axios.post('forms/answer', {form: 'Formulaire première connexion', answer: value, doctor_id: null, structure_id: null, comments: null})
                                            } catch (e) {
                                                console.warn(e);
                                            }
                                            await handleClose();
                                            history.push('/panel/proximity');

                                        }
                                    }
                                }
                                variant='contained'
                                color='primary'
                            >
                                <FormattedMessage id='survey.continue'/>
                            </ContinueButton>
                        </Wrapper>
                    )}
                    { step === 'doctor' && (
                        <StepDoctor value={value} setOpen={setOpen} isViewed={isViewed}/>
                    )}
                    { step === 'structure' && (
                        <StepStructure value={value} setOpen={setOpen} isViewed={isViewed}/>
                    )}
                    { step === 'autre' && (
                        <StepOther value={value} setOpen={setOpen} isViewed={isViewed}/>
                    )}
                </DialogStyled>
            )}
        </Wrapper>
    )
}
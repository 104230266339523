import {compose, lifecycle, withProps} from 'recompose'
import {connect} from 'react-redux'
import {fetchDoctor, fetchFavoriteDoctors, toggleFavorite,} from '../../../../../redux/modules/doctors'
import {inviteDoctor} from '../../../../../redux/modules/consultations'
import {history} from '../../../../../utils/history'
import {FavoritePSList} from "../FavoritePS";

const enhance = compose(
    connect(
        state => ({
            paginator: state.doctors.paginator,
            doctors: state.doctors.favorite,
            favoriteToggle: state.loading.favoriteToggle,
            isDoctor: state.auth.type === 'Doctor',
            isFavorite: true,
        }),
        {
            fetchFavoriteDoctors,
            fetchDoctor,
            toggleFavorite,
            inviteDoctor,
        },
    ),

    withProps(props => ({
        fetchDoctors: (specId, isAdditional, filter = {}) => {
            props.fetchFavoriteDoctors({ ...filter, search: filter.name || '', name: undefined })
        },
        emptyTitle: 'consultations.no.favorite.doctor',
        onBackIconClick: () => history.push(props.isDoctor ? '/panel/concilium/main' : '/panel/doctors/main'),
        onButtonClick: id => (e, proposedDate) => {
            e.stopPropagation()
            return props.fetchDoctor(id)
                .then(() => {
                    if (props.match.params.consultationId && props.isDoctor) {
                        props.inviteDoctor(id, props.match.params.consultationId)
                        history.push('/panel/consultations/session')
                    } else {
                        history.push(props.isDoctor ? `/panel/concilium/create/${id}${proposedDate ? `?proposedDate=${proposedDate}` : ''}` : '/panel/doctors/appointment/create')
                    }
                })
        },
        onButtonClickTER: (id, isUrgent) => (e, proposedDate) => {
            const url = !props.isDoctor
                ? `/panel/doctors/${props.match.params.consultationType}/create${proposedDate ? `?proposedDate=${proposedDate}` : ''}`
                : `/panel/concilium/create/${id}${isUrgent ? '?urgent=1' : ''}${proposedDate ? `${isUrgent ? '&' : '?'}proposedDate=${proposedDate}` : ''}`
            e.stopPropagation()

            if (props.isDoctor) {
                if (props.match.params.consultationId) {
                    props.inviteDoctor(id, props.match.params.consultationId)
                    return history.push('/panel/consultations/session')
                }

                return history.push(url)
            }

            return props.fetchDoctor(id)
                .then(() => history.push(url))
        },
        onAvatarClick: () => null,
    })),
    lifecycle({
        componentDidMount() {
            this.props.fetchFavoriteDoctors()
        },
    }),
)

export const FavoritePS = enhance(FavoritePSList)

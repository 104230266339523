import React, {useState} from 'react'
import styled from "styled-components";
import Paper from "@mui/material/Paper";
import {DistanceDisplay} from "../../../../../common/DistanceDisplay";
import essLogo from "../../../Community/ROSO/images/Logo_ESS.png";
import esspLogo from "../../../Community/ROSO/images/Logo_ESSP.png";
import {Colors} from "../../../../../../constants/colors";
import GroupsIcon from "@mui/icons-material/Groups";
import {history} from "../../../../../../utils/history";
import idx from "idx";
import {FavoriteButton} from "../../../../../common/DoctorCard/FavoriteButton";
import {toggleFavoriteTeam} from "../../../../../../redux/modules/teams";
import {useDispatch} from "react-redux";

export const DoctorTeamItemComponent = ({item}) => {

  const dispatch = useDispatch();

  const teamType = idx(item, _ => _.attributes.type);
  const name = idx(item, _ => _.attributes.name);
  const specializations = idx(item, _ => _.attributes.specializations[0]);
  const memberCount = idx(item, _ => _.attributes.member_count);
  const distance = idx(item, _ => _.attributes.distance);
  const favorite = idx(item, _ => _.attributes.favorite);
  const teamId = idx(item, _ => _.id);
  const city = idx(item, _ => _.attributes.city || _.relationships.structures.data[0].attributes.city);
  const post_code = idx(item, _ => _.attributes.postcode || _.relationships.structures.data[0].attributes.zipcode)

  const [fav, setFav] = useState(favorite);

  const checkFavorite = (e) => {
    dispatch(toggleFavoriteTeam(teamId, !favorite))
      .then(() => {
        setFav(!fav)
      })
    e.stopPropagation();
  }

  return (
    <PaperStyled
      onClick={() => history.push(`/panel/community/roso/detail/${teamId}`)}
    >
      <Wrapper>
        <IconWrapper>
          <TeamIcon
            spinner={{width: '63px', height: '63px'}}
            src={teamType === 'ESS' ? essLogo : esspLogo}
            alt='ess'
          />
        </IconWrapper>
        <Info>
          <Name>{name}</Name>
          {teamType === 'ESS' ?
            (<Specialization>{specializations}</Specialization>
            ) : null
          }
          <City>
            {city}&nbsp;({post_code})
          </City>
        </Info>
        <FavoriteWrapper>
          <FavoriteButton checked={fav}
                          onClick={checkFavorite}
                          isFetching={false}
                          message={false}
          />
        </FavoriteWrapper>
        <LanguageWrapper>
          <Participants>
            <StyledPersonIcon/>
            {memberCount + " "}
          </Participants>
          {
            !!distance && ( <DistanceDisplay distance={distance} /> )
          }
        </LanguageWrapper>
      </Wrapper>
    </PaperStyled>

  )
}

const PaperStyled = styled(Paper)`
  padding: 10px 24px 10px 10px;
  position: relative;
  display: flex;
  height: 70px;
  border: 1px solid  #DCDCDC;
  :hover {
    color: ${Colors.bluePurple};
    background-color: #E7E2F6;
    cursor: pointer;
  }
`

const Wrapper = styled.div`
  display: flex;
  font-weight: normal;
  font-size: 16px;
  align-items: center;
`

const FavoriteWrapper = styled.div`
  position: absolute;
  top: 8px;
  right: 8px;
  display: flex;
  color: #b7bed2;
`

const LanguageWrapper = styled.div`
  position: absolute;
  bottom: 20px;
  right: 40px;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #b7bed2;
`

const TeamIcon = styled.img`
  max-height: 40px;
  max-width: 100px;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
`

const Info = styled.div`
  margin-left: 10px;
  max-width: 260px;
`

const Specialization = styled.div`
  margin: 4px 0 0 0;
  font-size: 14px;
  color: ${Colors.dark70};
  font-weight: 400;
  word-break: break-word;
  text-overflow: ellipsis;
`

const Name = styled.div`
  font-weight: bold;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
`

const City = styled.div`
  margin: 4px 0 0 0;
  color: ${Colors.blueGrey};
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  max-width: 200px;
`

const Participants = styled.div`
  color: #858992;
  display: flex;
  align-items: center;
  text-transform: lowercase;
  margin-right: 15px;
`

const StyledPersonIcon = styled(GroupsIcon)`
  margin-right: 6px;
  svg {
      color: #b7bed2;
  }
`

const IconWrapper = styled.div`
  display: flex;
  align-items: center;
`
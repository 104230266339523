import React, {useEffect} from "react";
import styled from "styled-components";
import Grid from "@mui/material/Grid";
import {Colors} from "../../../../constants/colors";
import get from "lodash/get";
import doctorLogoDefault from "../../../../images/panel/default-avatar.svg";
import structureLogo from "../../../../images/doctors/structures/logo_structure.png";

const Wrapper = styled.div`
  position: relative;
  display: ${({display}) => display};
  width: 500px;
  & > * {
    max-width: 100%;
  }
`
const GridStyle = {
    display: 'block',
    borderRadius: '6px',
    boxShadow: 'rgba(6, 173, 255, 0.1) 0px 4px 8px 0px',
    backgroundColor: '#ffffff',
    maxHeight: '350px',
    overflowY: 'auto',
    position: 'absolute',
    zIndex: '1000'
}

const LinkStyle = styled.div`
  display: block;
  color: #00000099;
  
  &:hover {
    background-color: ${Colors.lightGrey};
  }
`

const ItemStyle = {
    padding: '0px 15px 0px 15px',
    fontFamily: 'Roboto, sans-serif',
    fontSize: '16px',
    color: '${Colors.dark}',
    borderBottom: '1px solid #c5cbdb',
    height: '50px',
    display: 'flex',
    alignItems: 'center',

}

const SearchContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: left;
`

const City = styled.span`
  font-size: 16px;
  color: #00000099;
  text-transform: capitalize;
`

const Img = styled.img`
 max-height: 25px;
 padding-left: 20px;
 padding-right: 20px;
`

export const SearchResults = ({canDisplay, results, searchValue, type, handleClick}) => {

    useEffect(() => {
        highlightText(searchValue);
    }, [results])

    return ( results.length > 0 ? (
        <Wrapper display={!canDisplay || !results || results.length === 0 ? 'none' : 'block'}  >
            <Grid container style={GridStyle}>
                {results.map((result) => {
                    if (result.type === 'Doctor' && type === 'doctor') {
                        return (
                            <LinkStyle key={result.id} onClick={() => handleClick(result)}>
                                <Grid item style={ItemStyle}>
                                    {doctorSearchResult(result)}
                                </Grid>
                            </LinkStyle>
                        )

                    }
                    else if (result.type === 'Structure' && type === 'structure'){
                        return (
                            <LinkStyle key={result.id} onClick={() => handleClick(result)}>
                                <Grid item style={ItemStyle}>
                                    {structureSearchResult(result)}
                                </Grid>
                            </LinkStyle>
                        )
                    }
                    else {
                        return null
                    }

                })}
            </Grid>
        </Wrapper>)
    : '')
}

const highlightText = (searchValue) => {
    const spanToHighlight = document.querySelectorAll("span.searchElement");
    spanToHighlight.forEach(span => {

        let innerHTML = span.innerHTML;
        //we remove old highlight
        innerHTML = innerHTML.replace(/<\/?span[^>]*>/g,"");

        let normalizedInnerHTML = innerHTML.toLowerCase().normalize("NFD").replace(/[\u0300-\u036f]/g, "");

        let index = normalizedInnerHTML.indexOf(searchValue.toLowerCase().normalize("NFD").replace(/[\u0300-\u036f]/g, ""));

        if (index >= 0) {
            innerHTML = innerHTML.substring(0, index) +
                "<span style='color: #6633FF; text-decoration: underline' >" + innerHTML.substring(index, index + searchValue.length) + "</span>" +
                innerHTML.substring(index + searchValue.length);
        }
        span.innerHTML = innerHTML
    })
}

const doctorSearchResult = (value) => {
            const city = get(value, 'relationships.workPlace.data[0].attributes.city', '')
            return (
                <SearchContainer>
                    <div>
                        <Img src={doctorLogoDefault}/>
                    </div>
                    <div>
                        <span className="searchElement">{value.attributes.full_name} </span>
                        <City>
                            {" - " + value.attributes.specialization}
                            {city && " (" + city + ")"}
                        </City>
                    </div>
                </SearchContainer>
            )

}

const structureSearchResult = (value) => {
    return (
        <SearchContainer>
            <div>
                <Img src={structureLogo}/>
            </div>
            <div>
                <span className="searchElement">{value.attributes.name}</span>
                <City>{" - " + value.attributes.city}</City>
            </div>
        </SearchContainer>
    )

}
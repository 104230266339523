import React from 'react';
import PropTypes from 'prop-types'
import { connect } from 'react-redux';
import { compose, withStateHandlers, withProps } from 'recompose'
import idx from 'idx'
import { TabsFilled } from 'common/UiKit/TabsFilled'
import { UserInfo } from 'common/UiKit/UserInfo'
import { ConsultationProfilePanel } from 'common/UiKit/ConsultationProfilePanel'
import { HealthRecordsTab } from './HealthRecordsTab'
import { ConsultationsTab } from './ConsultationsTab'
import { history } from '../../../../../../../utils/history'

const TABS = ['hr', 'teleconsultation.title']

const ProfileComponent = ({
  onCloseIconClick,
  patient,
  tab,
  changeTab,
}) => (
  <ConsultationProfilePanel onCloseIconClick={onCloseIconClick}>
    <UserInfo
      small
      firstName={idx(patient, _ => _.first_name) || idx(patient, _ => _.fullName) || ''}
      lastName={idx(patient, _ => _.last_name) || ''}
      dateOfBirth={idx(patient, _ => _.date_of_birth || _.birthday)}
      photo={idx(patient, _ => _.photo)}
      gender={idx(patient, _ => _.gender) || 'unknown'}
      medicalInsurance={idx(patient, _ => _.insurance)}
    >
      <TabsFilled
        tabs={TABS}
        checkedTab={tab}
        onClick={changeTab}
      />
    </UserInfo>
    {tab === 0 && <HealthRecordsTab />}
    {tab === 1 && <ConsultationsTab />}
  </ConsultationProfilePanel>
)

ProfileComponent.propTypes = {
  onCloseIconClick: PropTypes.func.isRequired,
  patient: PropTypes.object.isRequired,
  tab: PropTypes.number.isRequired,
  changeTab: PropTypes.func.isRequired,
}

const enhance = compose(
  connect(
    state => ({
      patient: idx(state, _ => _.consultations.consultation.relationships.patient.data.attributes)
        || idx(state, _ => _.healthRecords.attributes),
    }),
  ),
  withStateHandlers({
    tab: 0,
  }, {
    changeTab: () => e => ({ tab: +e.target.value }),
  }),
  withProps({
    onCloseIconClick: () => history.push('/panel/consultations/session'),
  }),
)

export const Profile = enhance(ProfileComponent)

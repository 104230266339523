import React from 'react'
import styled from 'styled-components'
import PropTypes from 'prop-types'
import { Field } from 'react-final-form'
import { Paper } from '../Paper'
import { RadioInput } from '../RadioInput'

const Wrapper = styled(Paper)`
  display: flex;
  justify-content: space-between;
  padding: 8px 16px;
  margin-bottom: 16px;
`

export const RadioInputField = ({
  name,
  id,
  options,
  ...rest
}) => (
  <Wrapper {...rest}>
    <Field
      component={RadioInput}
      name={name}
      id={id}
      options={options}
    />
  </Wrapper>
)

RadioInputField.propTypes = {
  name: PropTypes.string.isRequired,
  id: PropTypes.string.isRequired,
  options: PropTypes.arrayOf(PropTypes.shape({
    label: PropTypes.string.isRequired,
    value: PropTypes.string.isRequired,
  })).isRequired,
}

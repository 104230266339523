import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import { Colors } from 'constants/colors'
import { FormattedMessage } from 'react-intl'

const Wrapper = styled.div`
  margin: 40px 0 27px;
  padding: 0 10px;
  text-align: center
  color: ${Colors.blueGrey};
  font-size: 14px;
  line-height: 1.47;
`
const Image = styled.img`
  width: 128px;
  margin: 0 auto 23px;
`

const Heading = styled.h1`
  margin: 0 0 12px 0;
  font-size: 20px;
  font-weight: 500;
  color: ${Colors.dark}
`

export const EmptyStub = ({
  image,
  heading,
  text,
}) => (
  <Wrapper>
    <Image src={image} alt='logo' />
    <Heading>
      <FormattedMessage id={heading} defaultMessage={heading} />
    </Heading>
    {!!text && <FormattedMessage id={text} defaultMessage={text} />}
  </Wrapper>
)

EmptyStub.defaultProps = {
  text: null,
}

EmptyStub.propTypes = {
  image: PropTypes.string.isRequired,
  heading: PropTypes.string.isRequired,
  text: PropTypes.string,
}

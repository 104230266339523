import React, {useEffect, useState} from 'react'
import styled from 'styled-components'
import { Paper } from '@mui/material'
import essLogo from "../Community/ROSO/images/Logo_ESS.png";
import esspLogo from "../Community/ROSO/images/Logo_ESSP.png";
import Grid from "@mui/material/Grid";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import {history} from "../../../../utils/history";
import moment from "moment-timezone";
import {Colors} from "../../../../constants/colors";

const Container = styled.div`
  max-width: 100%;
  min-width: 600px;
  margin: 15px auto;
  white-space: nowrap;
 .MuiSvgIcon-root {
    color: #959fb8;
    transition: transform 200ms ease;
    cursor: pointer;  
 }
`

const ShowContainer = styled.div`
    max-width: 100%;
    background-color: #FFFFFF;
    padding: 15px;
    margin : auto;
    margin-bottom: 15px;
    border-radius: 5px;
    word-break: break-word;
    white-space: normal;
    border: 2px solid ${Colors.textMuted};
    border-style: none solid solid solid;
`

const PaperStyle = {
    marginTop: '15px',
    padding: '15px',
    borderRadius: '5px',
    border: `2px solid ${Colors.textMuted}`,
    boxShadow: '0px',
}

const Name = styled.div`
  font-family: Roboto;
  font-size: 18px;
  font-weight: 500;
  color: #6633FF;

`

const Hour = styled.div`
  max-width: 100%;
  font-family: Roboto;
  font-size: 18px;
  font-weight: 400;
  color: #000000;
  display: inline-block;
  background-color: rgba(102, 51, 255, 0.34);
  border-radius: 3px;
  padding: 5px 10px;
  margin: 15px;
  cursor: pointer;
  justify-items: center;
`

const Test = styled.div`
display: grid;
grid-template-columns: repeat(7, 1fr);
    margin: auto;
`


export const ListAvailabilities = (
    {
        day,
        availabilities,
        onButtonClick,
    }
) => {
    const [show, setShow] = useState(false);

    return(
        <Container>
            {  availabilities && Object.keys(availabilities).length ?
                <>
                    <Paper style={PaperStyle}>
                        <Grid container>
                            <Grid item xs={11}> <Name> {day} </Name> </Grid>

                            <Grid item xs={1} style={{ textAlign: 'end' }}>
                                <KeyboardArrowDownIcon
                                    style={{ transform: !show ? 'rotate(180deg)' : 'none' }}
                                    onClick={() => { setShow(!show) }}
                                />
                            </Grid>
                        </Grid>
                    </Paper>
                    <ShowContainer style={{ display: show ? 'block' : 'none' }}>
                        <Test>
                    {

                        Object.keys(availabilities).map (
                                     function (key, index) {
                                             return (
                                                 <Hour onClick={e => onButtonClick(e, availabilities[key].date_time)}> {key} </Hour>
                                             )
                                     })

                    } </Test>
                    </ShowContainer>
                </> : <></>
            }
        </Container>
    )
}
import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import { Switch, Route, Redirect } from 'react-router-dom'
import { MainTeleexpertise } from './MainTeleexpertise';
import { Specializations } from './Specializations'
import { List } from '../../Patient/Doctors/List'
import { CreateConciliumPage } from './CreatePage'
import { Favorite } from '../../Patient/Doctors/Favorite'
import { Profile } from '../../Patient/Doctors/Profile';
import {DoctorDetails} from "./DoctorDetails";
import {DoctorAnnuaireDetails} from "./DoctorAnnuaireDetails";
import {NearMe} from "./NearMeTab";
import {NearMeInstantSearch} from "./NearMeTabInstantSearch";

const Wrapper = styled.div`
  z-index: 10;
  position: absolute;
  width: 100%;
  height: 100%;
`

export const ConciliumContainer = ({ match }) => (
    <Switch>
      <Redirect from={match.path} exact to={`${match.path}/specializations`} />
      <Route path={`${match.path}/specializations/doctor/:doctorId`} component={DoctorDetails} />
      <Route path={`${match.path}/doctor/annuaire/:annuaireId`} component={DoctorAnnuaireDetails} />
      <Route path={`${match.path}/doctor/:doctorId`} component={DoctorDetails} />
      <Route path={`${match.path}/main`} exact component={MainTeleexpertise} />
      <Route path={`${match.path}/specializations`} exact component={Specializations} />
      <Route path={`${match.path}/favorite`} component={Favorite} />
      <Route path={`${match.path}/doctors`} render={props => <NearMeInstantSearch {...props} />} />
      <Route path={`${match.path}/specializations/:doctorId`} render={props => <NearMeInstantSearch {...props} />} />
      <Route path={`${match.path}/skills/:skillId`} render={props => <NearMe {...props} />} />
      <Route path={`${match.path}/structures`} render={props => <NearMe {...props} />} />
      <Route path={`${match.path}/teams`} render={props => <NearMe {...props} />} />
      <Route path={`${match.path}/create/:doctorId`} component={CreateConciliumPage} />
      <Route path={`${match.path}/team/create/:teamId`} component={CreateConciliumPage} />
      <Route path={`${match.path}/doctor/profile`} component={Profile} />
      <Route path={`${match.path}/:consultationId/specializations`} exact component={Specializations} />
      <Route path={`${match.path}/:consultationId/specializations/:doctorType`} render={props => <List {...props} onlyUrgent />} />
      <Route path={`${match.path}/:consultationId/favorite`} component={Favorite} />
      <Route path={`${match.path}/:consultationId/add/:doctorId`} component={CreateConciliumPage} />
    </Switch>
)

ConciliumContainer.propTypes = {
  match: PropTypes.object.isRequired,
}

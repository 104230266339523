import React, {useEffect, useRef, useState} from 'react'
import {Page} from "../../../common/Page";
import {FormattedMessage, useIntl} from "react-intl";
import styled from "styled-components";
import {Colors} from "../../../../constants/colors";

import {useDispatch, useSelector} from "react-redux";
import {aroundMe} from "../../../../redux/modules/geoloc";
import {MapContainer, TileLayer, Tooltip} from "react-leaflet";
import {Marker} from "react-leaflet";
import MarkerClusterGroup from 'react-leaflet-cluster';
import idx from "idx";
import {Icon} from "leaflet";
import {getAroundMeResult} from "../../../../helpers/structures";
import {DoctorItemComponent} from "./Items/Doctor";
import {DoctorTeamItemComponent} from "./Items/DoctorTeam";
import {StructureItemComponent} from "./Items/Structure";
import {TextButton} from "../../../common/UiKit/TextButton";
import {DoctorTooltipComponent} from "./Tooltips/Doctor";
import {StructureToolTipComponent} from "./Tooltips/Structure";
import {DoctorTeamToolTipComponent} from "./Tooltips/DoctorTeam";
import {history} from "../../../../utils/history";
import {CircularProgress, Skeleton} from "@mui/material";
import {Filters} from "./filters";
import {HelpAroundMe} from "./HelpAroundMe";
import {fetchHelpAroundMeView} from "../../../../redux/modules/formsView";
import {MY_API_KEY, URL_GEOAPIFY} from "../../../../constants/map";
import "leaflet/dist/leaflet.css";

export const AroundMe = () => {
  const { formatMessage } = useIntl()
  const dispatch = useDispatch();

  const f = id => formatMessage({ id })


  const workplace = useSelector(state => idx(state, _ => _.auth.relationships.workPlace.data[0].attributes))
  const [workPlaceLat, setWorkPlaceLat] = useState('')
  const [workPlaceLon, setWorkPlaceLon] = useState('')

  const [disableMap, setDisableMap] = useState(true)
  const [workPlaceCoord, setWorkPlaceCoord] = useState({})

  const [listElements, setListElements] = useState([]);
  const elementsRef = useRef([]);
  const [map, setMap] = useState(null);
  const [loading, setLoading] = useState(false);

  const isAlreadySeen = useSelector(state => state.formsView.isViewed.help_around_me)
  const doctorId = useSelector(state => state.auth.id);

  const fetchIsViewed = () => {
    dispatch(fetchHelpAroundMeView(doctorId))
  }

  useEffect(() => {
    fetchIsViewed();
  }, [])

  const handleHoverRef = (id) => {
    let marker = elementsRef.current[id]

    //If the marker exist and is visible on the map (also not in a cluster)
    if (marker && map.target.hasLayer(elementsRef.current[id])) {
      return marker.toggleTooltip()
    }
  }

  const [page, setPage] = useState(1);
  const [filters, setFilters] = useState({limitDoctor: 50, limitStructure: 10, limitTeam: 10, page: 1, objectType: 'doctor'})

  const DoctorIcon = new Icon({
    iconUrl: `https://api.geoapify.com/v1/icon?icon=user-md&iconType=awesome&type=awesome&color=%236633ff&size=xx-large&apiKey=${MY_API_KEY}&noShadow&noWhiteCircle`,
    iconSize: [31, 46], // size of the icon
    iconAnchor: [15.5, 42], // point of the icon which will correspond to marker's location
    popupAnchor: [0, -45] // point from which the popup should open relative to the iconAnchor//
  })

  const StructureIcon = new Icon({
    iconUrl: `https://api.geoapify.com/v1/icon?icon=hospital-alt&iconType=awesome&type=material&color=%23f1353a&size=xx-large&apiKey=${MY_API_KEY}&noShadow&noWhiteCircle`,
    iconSize: [31, 46], // size of the icon
    iconAnchor: [15.5, 42], // point of the icon which will correspond to marker's location
    popupAnchor: [0, -45] // point from which the popup should open relative to the iconAnchor//
  })

  const TeamIcon = new Icon({
    iconUrl: `https://api.geoapify.com/v1/icon?icon=users&iconType=awesome&type=material&color=%23f1353a&size=xx-large&apiKey=${MY_API_KEY}&noShadow&noWhiteCircle`,
    iconSize: [31, 46], // size of the icon
    iconAnchor: [15.5, 42], // point of the icon which will correspond to marker's location
    popupAnchor: [0, -45] // point from which the popup should open relative to the iconAnchor//
  })

  const loadMore = () =>{
    setPage(page + 1);
  }

  useEffect(() => {
    setFilters({...filters, page: page})
  }, [page])

  /**
   * Fetch the doctor, structure and
   * @param filter
   * @returns {Promise<any | undefined>}
   */
  const fetchAllElements = filter => getAroundMeResult(filter)
    .then((res) => {
      let resultSorted
      if (res && res[0].data) {
        const result = res[0].data.concat(res[1].data).concat(res[2].data);
        resultSorted = result.sort(compareDistances);
        if(filter.page === 1) {
          setListElements([...resultSorted])
        } else {
          setListElements([...listElements, ...resultSorted])
        }
      }
    })

  function compareDistances(x, y) {
    return x.attributes.distance - y.attributes.distance;
  }
  useEffect(() => {
    if (workPlaceLat && workPlaceLon) {
      setWorkPlaceCoord(workPlaceCoord => ({...workPlaceCoord, lat: workPlaceLat, lon: workPlaceLon}));
      setFilters({...filters, latitude: workPlaceLat, longitude: workPlaceLon})
    }
  }, [workPlaceLat, workPlaceLon]);

  useEffect(() => {
    if(Object.keys(workPlaceCoord).length !== 0) {
      dispatch(aroundMe(workPlaceCoord))
    }
  }, [workPlaceCoord])

  useEffect(() => {
    if (filters.latitude && filters.longitude) {
      setLoading(true);
      fetchAllElements(filters).then(() => {
        setDisableMap(false);
        setLoading(false);
      })
    }
  }, [filters])

  useEffect(() => {
    setWorkPlaceLat(workplace.lat)
    setWorkPlaceLon(workplace.lon)
  }, [])

  useEffect(() => {
    if(map && disableMap === false)  {
      map.target.addControl(map.target.zoomControl)
      map.target.dragging.enable()
      map.target.scrollWheelZoom.enable()
      map.target.touchZoom.enable()
      map.target.boxZoom.enable()
      map.target.doubleClickZoom.enable()
      map.target.keyboard.enable()
      let lat = '';
      let lon = '';
      let latLon = [];
      if (listElements.length > 0 && map) {
        listElements.map(({attributes, type, relationships}) => {
          switch (type) {
            case 'Doctor':
              lat = idx(relationships, _ => _.workPlace.data.slice(-1)[0].attributes.lat)
              lon = idx(relationships, _ => _.workPlace.data.slice(-1)[0].attributes.lon)
              if (lat && lon) {
                latLon.push([lat, lon])
              }
              break;
            case 'Structure':
              lat = attributes.lat
              lon = attributes.lon
              if (lat && lon) {
                latLon.push([lat, lon])
              }
              break;
            case 'DoctorTeam':
              lat = attributes.lat
              lon = attributes.lon
              if (lat && lon) {
                latLon.push([lat, lon])
              }
              break;
            default:
              break;
          }
        })
        if (latLon.length > 0) {
          map.target.fitBounds(latLon);
        }
      }
    }
  }, [map, listElements])

  return (
    <Page
      label={f('doctor.proximity')}
      noHeaderCapitalize
      fullWidth
      style={{ backgroundColor: '#f5f7fb' }}
    >
      <Content>
        <ContainerFilter>
          <Filters filters={filters} setFilters={setFilters} page={page} setPage={setPage}/>
        </ContainerFilter>
        <ContainerMap>
            <PanelList>
              <ListWrapper>
                {(!loading || listElements.length > 0) ?
                  listElements.map((element, index) => {
                    switch (element.type) {
                      case 'Doctor':
                        return (
                          <div onMouseEnter={() => handleHoverRef(index)} onMouseLeave={() => handleHoverRef(index)} key={'doctor' + element.id}>
                            <DoctorItemComponent item={element}/>
                          </div>
                        )
                      case 'Structure':
                        return (
                          <div onMouseEnter={() => handleHoverRef(index)} onMouseLeave={() => handleHoverRef(index)} key={'structure_' + element.id}>
                            <StructureItemComponent item={element}/>
                          </div>
                        )
                      case 'DoctorTeam':
                        const lat = element.attributes.lat
                        const lon = element.attributes.lon
                        return (
                          <div onMouseEnter={() => handleHoverRef(index)} onMouseLeave={() => handleHoverRef(index)} key={'doctorteam' + element.id + lat + lon}>
                            <DoctorTeamItemComponent item={element}/>
                          </div>
                        )
                      default:
                        return null;
                    }
                  }) :
                  <div style={{paddingTop: '30px'}}>
                    {
                      Array.from(new Array(7)).map((value, index) => (
                      <WrapperSkeleton key={index}>
                        <Skeleton variant="circular" height={70} width={70}/>
                        <WrapperSkeletonInfo>
                          <Skeleton width={'100%'}/>
                          <Skeleton width={'40%'}/>
                          <Skeleton width={'80%'}/>
                        </WrapperSkeletonInfo>
                      </WrapperSkeleton>
                      ))
                    }
                  </div>
                }
              </ListWrapper>
              <ButtonWrapper>
                <MoreButton onClick={loadMore}>
                  {loading ?
                    <CircularProgress style={{color: "white"}}/> :
                    <FormattedMessage id="roso.aroundMe.more"/>
                  }
                </MoreButton>
              </ButtonWrapper>
            </PanelList>

          <PanelMap>
              <MapWrapper>
                {!disableMap &&
                  <StyledMapContainer center={[workPlaceLat, workPlaceLon]}
                                      zoom={6}
                                      scrollWheelZoom={true}
                                      dragging={true}
                                      zoomControl={true}
                                      touchZoom={false}
                                      boxZoom={false}
                                      doubleClickZoom={false}
                                      keyboard={false}
                                      whenReady={setMap}
                  >
                    <TileLayer
                      attribution='Powered by <a href="https://www.geoapify.com/" target="_blank">Geoapify</a> | © OpenStreetMap <a href="https://www.openstreetmap.org/copyright" target="_blank">contributors</a>'
                      url={URL_GEOAPIFY}
                    />

                        <MarkerClusterGroup chunkedLoading showCoverageOnHover={false}>
                          {
                            listElements.map((element, index) => {
                              let lat = '';
                              let lon = '';
                              switch (element.type) {
                                case 'Doctor':
                                  lat = idx(element.relationships, _ => _.workPlace.data.slice(-1)[0].attributes.lat)
                                  lon = idx(element.relationships, _ => _.workPlace.data.slice(-1)[0].attributes.lon)
                                  return (lat && lon) ?  (
                                    <Marker position={[lat, lon]}
                                            icon={DoctorIcon}
                                            ref={ref => elementsRef.current[index] = ref}
                                            key={'doctor_' + element.id}
                                            eventHandlers={{
                                              click: () => {
                                                history.push(`/panel/concilium/doctor/${element.id}`)
                                              }                                            }}
                                            >
                                      <Tooltip>
                                        <DoctorTooltipComponent item={element} doctor={element}/>
                                      </Tooltip>
                                    </Marker>
                                  ) : ''
                                case 'DoctorTeam':
                                  lat = element.attributes.lat
                                  lon = element.attributes.lon
                                  return (lat && lon) ? (
                                    <Marker position={[lat, lon]}
                                            icon={TeamIcon}
                                            ref={ref => elementsRef.current[index] = ref}
                                            key={'doctorteam_' + element.id + lat + lon}
                                            eventHandlers={{
                                              click: () => {
                                                history.push(`/panel/community/roso/detail/${element.id}`)
                                              }
                                            }}
                                    >
                                      <Tooltip>
                                        <DoctorTeamToolTipComponent doctorTeam={element}/>
                                      </Tooltip>
                                    </Marker>
                                  ) : ''
                                case 'Structure':
                                  lat = element.attributes.lat
                                  lon = element.attributes.lon
                                  return (lat && lon) ? (
                                    <Marker position={[lat, lon]}
                                            icon={StructureIcon}
                                            ref={ref => elementsRef.current[index] = ref}

                                            id={'structure_' + element.id}
                                            key={'structure_' + element.id}
                                            eventHandlers={{
                                              click: () => {
                                                history.push(`/panel/community/structures/${element.id}`)
                                              }
                                            }}
                                    >
                                      <Tooltip>
                                        <StructureToolTipComponent structure={element}/>
                                      </Tooltip>
                                    </Marker>
                                  ) : ''
                                default:
                                  return null
                              }
                            })
                          }
                        </MarkerClusterGroup>

                  </StyledMapContainer>
                }
              </MapWrapper>
          </PanelMap>
        </ContainerMap>
      </Content>
      { isAlreadySeen === false && (
          <HelpAroundMe/>
      )}
    </Page>
  )
}

const Content = styled.div`
  height: calc(100vh - 56px - 50px);
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  & > * {
    width: 100%;
  }
`

const ContainerFilter = styled.div`
  padding: 10px;
`

const ContainerMap = styled.div`
  height: calc(100% - 80px);
  display: flex;
  justify-content: center;
  max-width: 100%;
`

const PanelList = styled.div`
  display: flex;
  flex-direction: column;
  border: solid 1px ${Colors.cloudyBlue};
  background: ${Colors.white};
`

const PanelMap = styled.div`
  display: flex;
  min-height: 100%;
  width: 70%;
  border: solid 1px ${Colors.cloudyBlue};
`

const MapWrapper = styled.div`
  position: relative;
  max-height: 100%;
  min-height: 300px;
  z-index: 96;
  min-width: 100%;
  display: flex;
`

const StyledMapContainer = styled(MapContainer)`
  height: 100%;
  width: 100%;
  z-index: 96;
  
 .leaflet-control {
    z-index: 400;
  }
  
  .leaflet-top, .leaflet-bottom {
    z-index: 400;
  }
`

const ListWrapper = styled.div`
  flex-basis: 0;
  display: flex;
  flex-direction: column;
  flex-grow: 6;
  min-width: 500px;
  max-height: 100%;
  min-height: 300px;
  width: 35%;
  overflow-y: scroll;
`

const ButtonWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-items: center;
  justify-content: center;

`
const MoreButton = styled(TextButton)`
  display: flex;
  font-size: 14px;
  text-transform: none;

  && {
    width: 30%;
    margin: 10px 0 10px 0;
    background-color: #6633FF;
    border: 1px solid #fff;
    color: #fff;
  }
  &:hover{
    background-color: rgb(71, 35, 178);
    border: 1px solid #fff;
    color: #fff;
  }
`

const WrapperSkeleton = styled.div`
  display: flex;
  flex-direction: row;
  padding: 10px 24px;
`

const WrapperSkeletonInfo = styled.div`
  padding-left: 10%;
  display: flex;
  flex-direction: column;
  width: 80%;
  justify-content: center;
`
import {useIntl} from "react-intl";
import React, {useEffect, useState} from "react";
import {getSearchResult} from "../../../../helpers/structures";
import SearchIcon from "@mui/icons-material/Search";
import {SearchResults} from "./SearchResults";
import styled from "styled-components";
import {Colors} from "../../../../constants/colors";
import get from "lodash/get";

const WrapperSearch = styled.div`
  width: 60%;
  position: relative;
  margin: 45px 50px 30px 50px;
  & > svg {
    position: absolute;
    left: 15px;
    top: 13px;
    fill: ${Colors.bluePurple};
  }
`

const Input = styled.input`
  background-color: white;
  border: 2px solid #ccc;
  border-radius: 3px;
  box-shadow: 0 5px 5px #eae8e8;
  outline: none;
  height: 50px;
  padding: 16px 16px 16px 45px;
  box-sizing: border-box;
  width: 500px;
  font-size: 16px;
  font-weight: 500;
  color: ${Colors.dark};

  ::placeholder {
    color: #00000099;
    font-size: 16px;
    font-weight: 500;
  }
`
export const SearchBar = ({type, id, setId}) => {
    const {formatMessage} = useIntl()
    const [search, onSearch] = useState('')
    const [searchResults, setSearchResults] = useState([]);
    const [canDisplay, setCanDisplay] = useState(false)

    const [filtersTest, setFiltersTest] = useState({limit: 5, page: 1});

    let message = type === 'doctor' ? 'survey.who.search' : 'survey.structure.search';

    const fetchSearchResult = filter => getSearchResult(filter)
        .then((res) => {
            setSearchResults(res.data);
        });
    const searchItem = (value) => {
        onSearch(value);
        return setFiltersTest({ search: value, limit: 5});
    }

    useEffect(() => {
        if (filtersTest.search && filtersTest.search.length >= 3) {
            fetchSearchResult(filtersTest);
        } else {
            setSearchResults([])
        }
    }, [filtersTest])


    const hasNoParentWithId = (element, id) => {
        for (let i = 0, depth = 4; i < depth && element.parentElement; i++) {
            if (element.id === 'search-suggestions')
                return false;
            // else
            element = element.parentElement || element.parentNode
        }
        return true;
    }

    document.addEventListener('click', event => {
        if (!event.target || event.target.id !== 'global-search' && hasNoParentWithId(event.target, 'search-suggestions')) {
            setCanDisplay(false)
        }
    })


    const [name, setName] = useState();
    const [specialization, setSpecialization] = useState();
    const [city, setCity] = useState();

    const handleClick = (result) => {
        setId(result.id)
        onSearch('')
        setCanDisplay(false)
        if (type === 'doctor') {
            setName(result.attributes.full_name)
            setSpecialization(result.attributes.specialization)
            setCity(get(result, 'relationships.workPlace.data[0].attributes.city', ''))
        } else {
            setName(result.attributes.name)
            setCity(result.attributes.city)
        }
    }

    let selection = type === 'doctor' ? (name + " - " + specialization + " (" + city + ")") : (name + " - " + city);

    return (
        <WrapperSearch>
            <SearchIcon />
            <Input
                id='global-search'
                value={search}
                onChange={e => searchItem(e.target.value)}
                placeholder={ (id !== 0 && (formatMessage({ id: selection }))) || (id === 0 && (formatMessage({ id: message }))) }
                onFocus={() => setCanDisplay(true)}
            />
            <SearchResults
                id='search-suggestions'
                canDisplay={canDisplay}
                results={searchResults}
                searchValue={search}
                type={type}
                handleClick={handleClick}
            />
        </WrapperSearch>
    )
}
import React from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { AdditionalInformationForm } from 'common/UiKit/AdditionalInformationForm'
import { EditPage } from 'common/UiKit/EditPage'
import { Field, Form } from 'react-final-form'
import { editProfile } from '../../../../../redux/modules/auth'
import { history } from '../../../../../utils/history'
import { externalFormSubmit } from '../../../../../helpers/externalFormSubmit'

const validate = (values) => {
  const errors = {}

  if (values.additional_information && values.additional_information.length > 300) {
    errors.additional_information = true
  }
  return errors
}

const EditAdditionalInformationComponent = ({
  initialValues,
  editProfile,
  loading,
}) => (
  <EditPage
    label='profile.edit.additional'
    onBackIconClick={() => history.push('/panel/profile')}
    buttonText='global.save'
    onButtonClick={() => externalFormSubmit('additional_information')}
    loading={loading}
  >
    <Form
      onSubmit={values => editProfile({ additional_info: values.additional_information }).then(() => history.push('/panel/profile'))}
      initialValues={initialValues}
      validate={validate}
      subscription={{}}
      render={({ handleSubmit }) => (
        <form onSubmit={handleSubmit} id='additional_information'>
          <AdditionalInformationForm
            Field={Field}
            disableShadow
          />
        </form>
      )}
    />
  </EditPage>
)

EditAdditionalInformationComponent.propTypes = {
  initialValues: PropTypes.object.isRequired,
  editProfile: PropTypes.func.isRequired,
  loading: PropTypes.bool.isRequired,
}

const enhance = connect(
  state => ({
    initialValues: { additional_information: state.auth.attributes.additional_info },
    loading: state.loading.editProfile,
  }),
  { editProfile },
)

export const EditAdditionalInformation = enhance(EditAdditionalInformationComponent)

import React, {useState} from 'react';
import Paper from "@mui/material/Paper";
import styled from "styled-components";
import imageHelp from '../../../../../images/panel/doctors/help-window.svg'
import {FormattedMessage} from "react-intl";
import HelpIcon from "@mui/icons-material/Help";
import CloseIcon from "@mui/icons-material/Close";
import IconButton from "@mui/material/IconButton";
import {TextButton} from "../../../../common/UiKit/TextButton";
import {history} from "../../../../../utils/history";
import {Colors} from "../../../../../constants/colors";

const Wrapper = styled.div`
  right: 30px;
  position: fixed;
  top: 100px;
`

const PaperStyled = styled(Paper)`
  border: 1px solid #6633FF;
  width: 250px;
  box-shadow: 5px 5px 5px #c9c7c7;
`

const ImageStyled = styled.img`
  margin: 50px auto 15px auto;
  width: 230px;
  height: 230px;
  display: block;
`

const TitleStyled = styled.div`
  font-size: 18px;
  text-align: center;
  font-weight: 500;
  display: block;
  margin-top: 40px;
  margin-left: 20px;
  margin-right: 20px;
  color: #6633FF;
`

const TextStyled = styled.div`
  text-align: center;
  font-size: 16px;
  margin-right: 25px;
  margin-left: 25px;
  margin-top: 15px;
`

const HelpIconStyled = styled(HelpIcon)`
  color: #6633FF;
  margin-left: 5px;
  margin-right: 5px;
`

const CloseIconStyled = styled(IconButton)`
  width: 50px;
  height: 50px;
  margin-right: 10px;
  margin-left: 200px;
  padding: 20px 20px 20px 20px;
  color: ${Colors.steel};
  position: absolute;
`

const HelpButton = styled(TextButton)`
  box-shadow: none;
  background-color: white;
  color: ${Colors.bluePurple};
  border: 1px solid ${Colors.bluePurple};
  text-transform: none;
  font-family: Roboto, sans-serif;
  font-size: 14px;
  height: 40px;
  margin: 30px auto 20px auto;
  display: flex;
`

export const HelpWindow = ({handleClose}) => {
    return (
        <Wrapper>
            <PaperStyled>
                <CloseIconStyled onClick={async () => {handleClose()}}>
                    <CloseIcon/>
                </CloseIconStyled>
                <ImageStyled src={imageHelp} />
                <TitleStyled>
                    <FormattedMessage id="help.title"/>
                </TitleStyled>
                <TextStyled>
                    <FormattedMessage id="help.click"/>
                    <HelpIconStyled/>
                    <FormattedMessage id="help.consult"/>
                    <strong>
                    <FormattedMessage id="help.use.cases"/>
                    </strong>
                    <FormattedMessage id="help.and"/>
                    <strong>
                    <FormattedMessage id="help.tutos"/>
                    </strong>
                    <FormattedMessage id=" !"/>
                </TextStyled>
                <HelpButton onClick={
                    async () => {
                        handleClose();
                        history.push('/panel/help');
                    }
                }>
                    <HelpIcon color='#6633FF' sx={{ marginRight: '5px' }}/>
                    <FormattedMessage id="help.button"/>
                </HelpButton>
            </PaperStyled>
        </Wrapper>
    )
}
import React from 'react'
import PropTypes from 'prop-types'
import {compose, lifecycle, withProps} from 'recompose'
import styled from 'styled-components'
import { connect} from 'react-redux';
import StarIcon from '@mui/icons-material/Star'
import { EmptyScreen } from '../EmptyScreen'
import {fetchFavoriteStructures} from "../../../../../redux/modules/doctors";
import {FavoriteStructureCard} from "../../../../common/StructureCard/FavoriteStructureCard";
import CustomLoaderOverlay from "../../../../common/CustomLoaderOverlay";

const Content = styled.div`
  width: auto;
  margin: 10 auto;
  display: grid;
  grid-gap: 25px;
  grid-template-columns: repeat( auto-fill, minmax(315px, 1fr) );
  & > * {
    min-height: 0;
  }
`

export const FavoriteStructuresComponent = ({
  structures,
  emptyTitle,
  isFetching,
}) => {
  return (
    <>
      <CustomLoaderOverlay isShow={isFetching}/>
      {
        structures.length && !isFetching
          ? (
            <Content>
              {structures.map((el) => {
                const structureData = el.attributes;
                return (
                  <FavoriteStructureCard
                    name={structureData.name}
                    photo={structureData.photo}
                    members={structureData.members}
                    address={structureData.address}
                    city={structureData.city}
                    zipcode={structureData.zipcode}
                    link={`concilium/main/structure/` + el.id}
                  />
                )
              })}
            </Content>
          )
          : <EmptyScreen Icon={StarIcon} title={emptyTitle} />
      }
    </>
  )
}

FavoriteStructuresComponent.defaultProps = {
  teams: null,
  favoriteToggle: null,
  onButtonClickTER: null,
  emptyTitle: '',
}

FavoriteStructuresComponent.propTypes = {
  structures: PropTypes.arrayOf(PropTypes.object).isRequired,
  favoriteToggle: PropTypes.oneOfType([PropTypes.bool, PropTypes.number]).isRequired,
  onButtonClickTER: PropTypes.func.isRequired,
  emptyTitle: PropTypes.string,
}

const enhance = compose(
  connect(
    (state, props) => ({
      structures: state.doctors.favoriteStructures,
      favoriteToggle: state.loading.favoriteToggle,
      isFetching: state.loading.favoriteStructures,
    }),
    {
      fetchFavoriteStructures,
    },
  ),
  withProps(props => ({
    emptyTitle: 'consultations.no.favorite.structure',
  })),
  lifecycle({
    componentDidMount() {
      this.props.fetchFavoriteStructures()
    },
  }),
)

export const FavoriteStructures= enhance(FavoriteStructuresComponent)

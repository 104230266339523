import React, {useEffect, useState} from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import {SecuredBckgImage} from 'common/SecuredImage'
import {Colors, STATUS_COLORS} from '../../../constants/colors'
import {DefaultAvatar} from "../DefaultAvatar";
import {Link} from "react-router-dom";
import AddIcon from "@mui/icons-material/Add";
import {useDispatch} from "react-redux";
import {toggleFavorite} from "../../../redux/modules/doctors";
import {DistanceDisplay} from "../DistanceDisplay";
import ListItem from "@mui/material/ListItem";
import CheckIcon from "@mui/icons-material/Check";
import axios from "axios";

const Wrapper = styled.div`
    display: flex;
    font-weight: normal;
    font-size: 14px;
    margin: 5px 0 0;
    width: 100%;
`

const AvatarWrapper = styled.div`
    display: flex;
    font-weight: normal;
    font-size: 14px;
`

const Info = styled.div`
    flex-grow: 1;
    margin: auto 32px auto;
`

const NameLinked = styled(Link)`
    margin: 0;
    font-size: 16px;
    font-weight: bold;
    color: black;

    &:hover {
        color: #6633FF;
    }
`

const Name = styled.h1`
    margin: 0;
    font-size: 16px;
    font-weight: bold;
    color: black;
`

const Specialization = styled.p`
    margin: 0;
    opacity: 0.8;
    padding-top: 2px;
    font-size: 14px;
`

const Address = styled.p`
    margin: 0;
    opacity: 0.8;
    padding-top: 5px;
    font-size: 13px;
`

const Avatar = styled(SecuredBckgImage)`
    min-width: 60px;
    width: 60px;
    height: 60px;
    border-radius: 100%;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;

    &:after {
        position: relative;
        content: "oo";
        background-color: ${({status}) => (status) ? STATUS_COLORS["available"] : STATUS_COLORS["notavailable"]};
        color: transparent;
        border-radius: 50%;
        top: 44px;
        left: 42px;
    }
`

const DefaultWithoutAvaibility = styled.div`
    min-width: 60px;
    width: 60px;
    height: 60px;
    border-radius: 100%;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
`

const Default = styled.div`
    min-width: 60px;
    width: 60px;
    height: 60px;
    border-radius: 100%;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;

    &:after {
        position: relative;
        content: "oo";
        background-color: ${({status}) => (status) ? STATUS_COLORS["available"] : STATUS_COLORS["notavailable"]};
        color: transparent;
        border-radius: 50%;
        top: 44px;
        left: 42px;
    }
`

const Button = styled.div`
    display: flex;
    justify-content: flex-end;
    align-items: flex-start;
`

const InfoRight = styled.div`
    margin: auto 32px auto;
    color: ${Colors.steel};
`

const StyledAddIcon = styled(AddIcon)`
    margin-left: 30px;
    font-size: 35px;
    color: ${Colors.bluePurple};
    cursor: pointer;
    border-radius: 100%;

    &:hover {
        background-color: rgba(231, 226, 246, 0.3);
    }

`

const StyledCheckIcon = styled(CheckIcon)`
    margin-left: 30px;
    font-size: 35px;
    color: ${Colors.bluePurple};
    cursor: pointer;
    border-radius: 100%;

    &:hover {
        background-color: rgba(231, 226, 246, 0.3);
    }
`

export const NewFavoriteDoctorCard = ({
  doctorId,
  firstName,
  lastName,
  isAnnuaire,
  specialization,
  specialization_type,
  photo,
  isFavorite,
  link,
  postcode,
  city,
  distance
}) => {
  const dispatch = useDispatch();
  const [checked, setChecked] = useState(false)

  const [statusAvailability, setStatusAvailability] = useState(false);

  useEffect(() => {
    if (doctorId) {
      axios.get(`api/doctor/${doctorId}/isAvailable`)
        .then((response) => {
          setStatusAvailability(response?.data?.status_availability ?? false);
        })
    }
  }, [doctorId]);

  const addPS = () => {
    isFavorite = true
    dispatch(toggleFavorite(doctorId, isFavorite))
    setChecked(true)
  }

  const removePS = () => {
    isFavorite = false
    dispatch(toggleFavorite(doctorId, isFavorite))
    setChecked(false)
  }

  return (
    <ListItem alignItems='flex-start' sx={{height: 60}}>
      <Wrapper>
        <AvatarWrapper>
          {
            isAnnuaire ? (
                  <DefaultWithoutAvaibility
                    spinner={{width: '120px', height: '120px'}}
                    style={{backgroundImage: `url(${DefaultAvatar(firstName, lastName, specialization_type)})`}}
                  />
              ):
            photo ? (
                  <Avatar
                    spinner={{width: '120px', height: '120px'}}
                    src={photo.includes('/file/') ? `http://backend-application.owndoctor.eu${photo}` : photo}
                    status={statusAvailability}
                  />
              ) :
              (
                  <Default
                    spinner={{width: '120px', height: '120px'}}
                    style={{backgroundImage: `url(${DefaultAvatar(firstName, lastName, specialization_type)})`}}
                    status={statusAvailability}
                  />
              )
          }
        </AvatarWrapper>
        <Info>
          {link ? (
              <NameLinked to={isFavorite ? link.replace('favorite', '') : link}>
                {`${firstName || 'Name'} ${lastName || 'Surname'}`}
              </NameLinked>)
            : (
              <Name to={link}>
                {`${firstName || 'Name'} ${lastName || 'Surname'}`}
              </Name>)
          }
          <Specialization>
            {specialization || 'Therapist'}
          </Specialization>
          <Address>
            {`${city ? city : ""} ${postcode ? "(" + postcode + ")" : ""}`}
          </Address>
        </Info>
        <InfoRight>
          {distance && (
            <DistanceDisplay float='left' distance={distance}/>
          )}
          {!checked && !isFavorite && isFavorite != null && (
            <Button
              variant='outlined'
              onClick={addPS}
            >
              <StyledAddIcon fontSize='large' color='#6633FF'/>
            </Button>
          )
          }
          {(isFavorite || checked) && (
            <Button
              variant='outlined'
              onClick={removePS}
            >
              <StyledCheckIcon fontSize='large' color='#6633FF'/>
            </Button>
          )
          }
        </InfoRight>
      </Wrapper>
    </ListItem>
  )
}

NewFavoriteDoctorCard.defaultProps = {
  location: {},
  onAvatarClick: () => null,
  photo: '',
  buttonText: null,
  rating: null,
}

NewFavoriteDoctorCard.propTypes = {
  firstName: PropTypes.string.isRequired,
  lastName: PropTypes.string.isRequired,
  specialization: PropTypes.string.isRequired,
  photo: PropTypes.string,
  price: PropTypes.number,
  location: PropTypes.object,
  onButtonClick: PropTypes.func,
  onAvatarClick: PropTypes.func,
  isFavorite: PropTypes.bool,
  toggleFavorite: PropTypes.func,
  isFavoriteFetching: PropTypes.bool,
  disableFavorite: PropTypes.bool,
  rating: PropTypes.number,
  buttonText: PropTypes.string,
}

import React, { useEffect, useState } from 'react'
import {loadFiles} from "../../../../redux/modules/chats";
import {useDispatch, useSelector} from "react-redux";
import MuiTable from '@mui/material/Table'
import TableBody from '@mui/material/TableBody'
import TableCell from '@mui/material/TableCell'
import TableContainer from '@mui/material/TableContainer'
import TableHead from '@mui/material/TableHead'
import TableRow from '@mui/material/TableRow'
import { FormattedMessage } from 'react-intl'
import get from 'lodash/get';
import { TextButton } from '../../../common/UiKit/TextButton'
import MoreHorizIcon from '@mui/icons-material/MoreHoriz';
import styled from "styled-components";
import {Colors} from "../../../../constants/colors";
import PdfIcon from '@mui/icons-material/PictureAsPdf';
import DocIcon from '@mui/icons-material/Description';
import ImageIcon from '@mui/icons-material/Image';
import DownloadIcon from '@mui/icons-material/Download';
import Menu from '@mui/material/Menu'
import MenuItem from "@mui/material/MenuItem";
import {ListItemIcon} from "@mui/material";
import emptyView from "../../../../images/panel/chat/empty_view_Documents.svg"
import {EmptyScreen} from "../../Doctor/Consultations/List/EmptyScreen";
import {OpenSecuredInNewTab} from "../../../common/SecuredImage";
import moment from "moment-timezone";

export const StyledTextButton = styled(TextButton)`
  box-shadow: none !important;
  background-color: white;
  color: ${Colors.footerGrey};
  border: none;
  text-transform: none;
  font-family: Roboto, sans-serif;
  font-size: 14px;
  z-index: 10;
  height: auto;
`

const StyledTableContainer = styled(TableContainer)`
  height: 70%;
  width: 70%;
  margin: auto;
  background: white;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.05);
  border-radius: 5px;
  
  .MuiTableCell-root {
    border-bottom: 1px solid ${Colors.borderGrey};
    padding: 10px;
  }
`

const Wrapper = styled.div`
  padding-top: 50px;
  width: 100%;
  height: 100%;
  overflow: hidden;
`

const Download = styled.a`
  all: unset;
  color: ${Colors.footerGrey} !important;
  display: flex;
`

const IconStyle = {color: Colors.footerGrey}

const DownloadButton = ({filePath}) => {
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  const token = localStorage.getItem('authToken');

  return(
      <>
        <StyledTextButton
            aria-owns={anchorEl ? 'menu-appbar' : undefined}
            aria-haspopup='true'
            onClick={handleClick}
        >
          <MoreHorizIcon/>
        </StyledTextButton>
        <Menu
            id='menu-appbar'
            anchorEl={anchorEl}
            anchorOrigin={{
              vertical: 'top',
              horizontal: 'left',
            }}
            transformOrigin={{
              vertical: 'top',
              horizontal: 'left',
            }}
            open={open}
            onClose={handleClose}
        >
          <MenuItem>
            <Download href={filePath + '?download=true&token=' + token}>
              <ListItemIcon><DownloadIcon /></ListItemIcon>
              <FormattedMessage id='chat.documents.download' />
            </Download>
          </MenuItem>
        </Menu>
      </>
  )}

export const DocumentsTab = ({isDiscussion}) => {

  const dispatch = useDispatch();
  const chatId = useSelector(state => isDiscussion ? state.discussions.discussion.attributes.chatId : state.consultations.consultation.chatId)
  const files = useSelector(state => state.chatsFiles[chatId]);

  useEffect(() => {
    if (chatId) {
      dispatch(loadFiles(chatId))
    }
  }, [chatId])

  const TABLE_HEADERS = [
    'type',
    'name',
    'date',
    'sender',
    'download'
  ]

  const getIconByMimeType = (mimetype) => {
    if(mimetype.includes('image')) {
      return(<ImageIcon style={IconStyle}/>)
    } else if (mimetype.includes('pdf')) {
      return(<PdfIcon style={IconStyle}/>)
    } else {
      return(<DocIcon style={IconStyle}/>)
    }
  }

  return(
      <Wrapper>
        {files && (files.length ?
            <StyledTableContainer>
              <MuiTable stickyHeader>
                <TableHead>
                  <TableRow>
                    <TableCell key={'type'}>
                      <FormattedMessage id={'chat.documents.type'} />
                    </TableCell>
                    <TableCell key={'name'}>
                      <FormattedMessage id={'chat.documents.name'} />
                    </TableCell>
                    <TableCell key={'date'}>
                      <FormattedMessage id={'chat.documents.date'} />
                    </TableCell>
                    <TableCell key={'sender'}>
                      <FormattedMessage id={'chat.documents.sender'} />
                    </TableCell>
                    <TableCell key={'download'}>
                    </TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {files && files.map(({ attributes }, id) => {
                    const row = {
                      type: getIconByMimeType(attributes.file.mimeType),
                      name: attributes.file.name,
                      date: moment(moment.utc(attributes.date).toDate()).format('DD/MM/YYYY HH:mm:ss'),
                      sender: attributes.sender_name,
                      id: id,
                      download: <DownloadButton filePath={attributes.file.url}/>,
                      postRequest: attributes.file.postRequest
                    }

                    return(
                        <TableRow hover style={{cursor: 'pointer'}}>
                          {
                            TABLE_HEADERS.map((header) => {
                              let value = get(row, header, '-')
                              return (
                                  <TableCell key={header + row.id} style={header == 'download' ? {textAlign: 'right'} : {}}>
                                    {header == 'download' ?
                                        value
                                        :
                                        <OpenSecuredInNewTab action={attributes.file.url} method={row.postRequest ? 'post' : 'get'}>
                                          {value}
                                        </OpenSecuredInNewTab>
                                    }

                                  </TableCell>
                              )
                            })
                          }
                        </TableRow>
                    )
                  })}
                </TableBody>
              </MuiTable>
            </StyledTableContainer>
            :
            <EmptyScreen logo={emptyView} title={'chat.documents.emptyView.title'} subtitle={'chat.documents.emptyView.subtitle'}/>)
        }
      </Wrapper>
  )
}

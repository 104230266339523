/* eslint-disable */
import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import Chip from '@mui/material/Chip'

import { Colors } from 'constants/colors'
import {useSelector} from "react-redux";
import {CONSULTATION_TYPES} from "../../../../constants/consultation";

const Wrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
`

const Input = styled.input`
  position: absolute;
  opacity: 0;
`

const ChipStyled = styled(props => (
  <Chip {...props} classes={{ colorSecondary: 'secondary' }} />
))`
  && {
    height: 36px;
    margin: 8px;
    padding: 0 10px;
    font-size: 12px;
    font-weight: 500;
    text-transform: uppercase;
    letter-spacing: 0.4px;
    border: 1px solid;
  }

  &&.secondary {
    background-color: ${Colors.paleGrey};
    color: ${Colors.blueGrey};
  }
`

export const RadioInput = ({
  id,
  onChange,
  options,
  value,
}) => {
  return (
    <Wrapper>
      {
        options.map(option => (
          <span key={option.value}>
          <Input
            checked={value === option.value}
            id={id + option.value}
            onChange={e => onChange(e.target.value)}
            type='radio'
            value={option.value}
          />
          <label htmlFor={id + option.value}>
            <ChipStyled
              clickable
              color={value === option.value ? 'primary' : 'secondary'}
              label={option.label}
            />
          </label>
        </span>
        ))
      }
    </Wrapper>
  )
}

RadioInput.propTypes = {
  id: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
  options: PropTypes.arrayOf(PropTypes.shape({
    label: PropTypes.string.isRequired,
    value: PropTypes.string.isRequired,
  })).isRequired,
  value: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.string),
    PropTypes.string,
  ]).isRequired,
}
/* eslint-disable */
import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import { Colors } from '../../../../constants/colors'
import { FormattedMessage, useIntl } from 'react-intl'
import { useSelector } from 'react-redux';
import { MenuButton } from '../MenuButton'
import {SMALLSCREEN_MAX_WIDTH} from "../../../../constants/globalStyles";

const Img = styled.img`
  width: 50%;
  margin-left: auto;
  margin-right: auto;
`

const Wrapper = styled.div`
  width: ${({ folded }) => (folded) ? '55px' : '260px' };
  min-width: ${({ folded }) => (folded) ? '55px' : '260px'};
  height: 100%;
  padding: 0 0 0 8px;
  background-color: white;
  display: flex;
  flex-direction: column;
  border-right: ${({ folded }) => (folded) ? 'none' : `0.8px solid ${Colors.borderGrey}`};

  @media (max-width: ${SMALLSCREEN_MAX_WIDTH}) {
    display: none;
  }
`

const Footer = styled.div`
  margin-right : auto;
  margin-left : auto;
  color: black;
  font-size: 12px;
  font-weight: normal;
  align-self: center;
  margin-top: 5px;
`

const Separator = styled.div`
  width: 100%;
  margin : 10px 0;
  height: 1px;
  background-color: ${Colors.borderGrey};
`

const SectionTitle = styled.div`
  font-family: Roboto, sans-serif;
  text-transform: uppercase;
  color: ${Colors.blueGrey};
  letter-spacing: 4px;
  padding-bottom: 4px;
`

export const Menu = ({
  children,
  menuButtons,
  disabledButtons,
  adminChatMessages,
  folded,
  displayCopyright,
  hideInvitation,
}) => {
  const { formatMessage } = useIntl()
  const currentProfile = useSelector(state => state.auth)
  const isDoctor = useSelector(state => state.auth.type === 'Doctor')

  const activitiesSection = menuButtons.filter(button => button.section === 'activities');
  const networkSection = menuButtons.filter(button => button.section === 'network');
  return (
    <Wrapper folded={ folded }>
      {children}
      <Separator/>
      {!folded && (
        <SectionTitle>
          <FormattedMessage id='panel.section.activities'/>
        </SectionTitle>
      )}
      {activitiesSection.map(button => (
        <MenuButton
          adminChatMessages={adminChatMessages}
          fullWidth
          folded={ folded }
          icon={<button.icon />}
          disabled={disabledButtons.indexOf(button.label) >= 0}
          key={button.label}
          label={formatMessage({ id: button.label })}
          path={button.path}
        />
      ))}
      {isDoctor &&
        <>
          <Separator/>
          {!folded && (
            <SectionTitle>
              <FormattedMessage id='panel.section.network'/>
            </SectionTitle>
          )}
        </>
      }

      {networkSection.map(button => (
        <MenuButton
          adminChatMessages={adminChatMessages}
          fullWidth
          folded={ folded }
          icon={<button.icon />}
          disabled={disabledButtons.indexOf(button.label) >= 0}
          key={button.label}
          label={formatMessage({ id: button.label })}
          path={button.path}
        />
      ))}
      {
        !folded && displayCopyright &&
        <Footer>
          &copy; {new Date().getFullYear()} CONEX SANTE
        </Footer>
      }
    </Wrapper>
  )
}

Menu.defaultProps = {
  children: null,
  displayCopyright: true,
  hideInvitation: false,
}

Menu.propTypes = {
  children: PropTypes.node,
  menuButtons: PropTypes.arrayOf(PropTypes.shape({
    label: PropTypes.string.isRequired,
    path: PropTypes.string.isRequired,
  })).isRequired,
  hideInvitation: PropTypes.bool,
}

import React, { useEffect, useState } from 'react';
import get from 'lodash/get';
import set from 'lodash/set';
import last from 'lodash/last';
import axios from 'axios';
import Grid from '@mui/material/Grid';
import { Paper } from 'common/UiKit/Paper'
import styled from 'styled-components';
import moment from 'moment-timezone';
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import { FormattedMessage } from 'react-intl'

import { Page } from '../../../../common/Page';
import { GridChartCard } from './GridChartCard';
import { TotalCard } from './TotalCard';
import { GoalsManagementTable } from './GoalsManagementTable';

const StyledChartContainer = styled(Paper)`
    height: 295px;
    padding: 24px;
    padding-top: 20px;
    margin-bottom: 17px;
    padding-bottom: 0px;
`

const StyledGridContainer = styled(Grid)`
    width: 100% !important;
    margin: 0 !important;
`

const RightBlockPapper = styled(Paper)` 
    padding-top: 20px;
    margin-bottom: 8px;
`

const ChartPeriodSwitcher = styled.div`
  display: flex;
  align-items: center;
  opacity: 0.8;
  font-size: 18px;
  font-weight: 500;
  color: #209dff;
  white-space: nowrap;
`

const PeriodArrowSelectForward = styled(ArrowForwardIosIcon)`
  color: #209dff;
  cursor: pointer;
`

const PeriodArrowSelectBack = styled(ArrowBackIosIcon)`
  color: #209dff;
  cursor: pointer;
`

const ChartsHeader = styled.div`
  display: flex;
  justify-content: space-between;
`

const ChartTitle = styled.h1`
  font-size: 18px;
  line-height: 1;
  color: #333c40;
  font-weight: normal;
`

const data = {
  labels: [0, 1, 2, 3, 4, 5, 6],
  datasets: [
    {
      label: 'NB TC',
      data: [],
      fill: false,
      lineTension: 0,
      backgroundColor: '#21c5a9',
      borderColor: '#21c5a9',
      //pointRadius: 0,
    },
    {
      label: 'NB TE',
      data: [],
      fill: false,
      lineTension: 0,
      backgroundColor: '#f5a623',
      borderColor: '#f5a623',
      //pointRadius: 0,
    },
    {
      label: 'NB urgent',
      data: [],
      fill: false,
      lineTension: 0,
      backgroundColor: '#209dff',
      borderColor: '#209dff',
      //pointRadius: 0,
    },
  ],
} 

const getSheduledArray = (periods, week, type) => {
  if (!periods[week] || !type) {
    return false;
  }
  const existDates = Object.keys(periods[week]);
  const existDays = existDates.map(el => moment(el).day());
  const daysToReturn = [0, 0, 0, 0, 0, 0, 0];

  return daysToReturn.map((el, i) => {
    if (existDays.includes(i)) {
      const currentKey = existDates.find(el => moment(el).day() === i);
      return get(periods, `${week}.${currentKey}.${type}`, 0);
    } 
    return el;
  });
}

const computePersent = (val1, val2) => {
  const tendentionUp = val1 > val2;
  let basePersent = tendentionUp ? (val1 / val2) * 100 : (val2 / val1) * 100;
  if (val1 === 0 && val2 === 0) {
    return 0;
  }
  if (basePersent === Infinity) {
    basePersent = 100;
  } 
  return tendentionUp ? Math.ceil(basePersent) : -Math.abs(Math.ceil(basePersent));
} 

const countPeriod = period => period.reduce((accum, el) => ({
  scheduled: el.scheduled + get(accum, 'scheduled', 0),
  telexpertise: el.telexpertise + get(accum, 'telexpertise', 0),
}), { scheduled: 0, telexpertise: 0 }) 

const countCurrentMonth = (periods) => {
  const allMonthsArray = Object.keys(periods).map(el => el);

  const firstDate = allMonthsArray && allMonthsArray[0] ? allMonthsArray[0] : new Date();
  const lastDate = allMonthsArray && last(allMonthsArray) ? last(allMonthsArray) : new Date();
  const weeks = moment(lastDate).diff(moment(firstDate), 'weeks');

  const currentMonthArray = Object.keys(periods).map((el) => {
    if (moment(el).isSame(new Date(), 'month')) {
      return periods[el];
    } 
    return false;
  }).filter(el => el);
  const previousMonthArray = Object.keys(periods).map((el) => {
    if (moment(el).isSame(moment(new Date()).subtract(1, 'months'), 'month')) {
      return periods[el];
    } 
    return false;
  }).filter(el => el);

  const currentMonthCount = countPeriod(currentMonthArray);
  const previousMonthCount = countPeriod(previousMonthArray);

  const comparsionPercent = {
    scheduled: computePersent(currentMonthCount.scheduled, previousMonthCount.scheduled),
    telexpertise: computePersent(currentMonthCount.telexpertise, previousMonthCount.telexpertise),
  }

  return {
    weeks,
    currentMonthCount,
    currentMonthArray,
    previousMonthArray,
    previousMonthCount,
    comparsionPercent,
  }
}

const groupByWeek = periods => Object.keys(periods)
  .reduce((accum, el) => {
    const newEl = accum;
    set(newEl, `${moment(el).format('YYYY-[W]WW')}.${el}`, periods[el]);
    return { ...accum, ...newEl };
  }, {});

export const GoalsManagement = () => {
  const [statistic, setStatistic] = useState({});
  const [dashboard, setDashboard] = useState({});
  const [currentPeriod1, setCurrentPeriod1] = useState(false);
  const [totalStatisticMonth, setTotalStatisticMonth] = useState({});
  const totalScheduled = get(statistic, 'general_statistics.scheduled', 0);
  const totalTelexpertise = get(statistic, 'general_statistics.telexpertise', 0);
  const averageScheduledWeek = totalScheduled / totalStatisticMonth.weeks;
  const averageTelexpertiseWeek = totalTelexpertise / totalStatisticMonth.weeks;

  useEffect(() => {
    axios('/api/doctor/statistic')
      .then((response) => {
        const statisticResp = get(response, 'data.data.attributes', {});
        const dashboardResp = get(statisticResp, 'dashboard', {});
        const weeklyGroupedDashboard = groupByWeek(dashboardResp);
        const totalS = countCurrentMonth(dashboardResp);
        setTotalStatisticMonth(totalS);
        setStatistic(statisticResp);
        setDashboard(weeklyGroupedDashboard);
        setCurrentPeriod1(
          moment().startOf('isoWeek').format('YYYY-[W]WW'),
          //Object.keys(weeklyGroupedDashboard) && Object.keys(weeklyGroupedDashboard)[0],
        );
      })
      .catch(err => console.warn(err))
  }, [])

  const setPeriod = (operation) => {
    // IN CASE OF RETURN ONLY EXIST DATA
    //const keys = Object.keys(dashboard);
    //const currentIndexPeriod = keys.findIndex(el => el === currentPeriod1);
    //const possibleNext = Object.keys(dashboard) && Object.keys(dashboard)[currentIndexPeriod + 1];
    //const possiblePrev = Object.keys(dashboard) && Object.keys(dashboard)[currentIndexPeriod - 1];
    const possibleNext = moment(currentPeriod1).add(1, 'weeks').startOf('isoWeek').format('YYYY-[W]WW');
    const possiblePrev = moment(currentPeriod1).subtract(1, 'weeks').startOf('isoWeek').format('YYYY-[W]WW');

    if (operation === 'next' && possibleNext) {
      setCurrentPeriod1(possibleNext);
    }
    if (operation === 'prev' && possiblePrev) {
      setCurrentPeriod1(possiblePrev);
    }
  }

  data.datasets[0].data = getSheduledArray(dashboard, currentPeriod1, 'scheduled');
  data.datasets[1].data = getSheduledArray(dashboard, currentPeriod1, 'telexpertise');
  data.datasets[2].data = getSheduledArray(dashboard, currentPeriod1, 'urgent');
  data.labels = data.labels.map((el) => {
    if (!currentPeriod1) {
      return el;
    }
    return moment(currentPeriod1).startOf('week').add(el, 'days').format('DD MMM')
  })

  return (
    <Page
      label='goals.comunity.label'
      fullWidth
    >
      <StyledGridContainer container spacing={3}>
        <Grid item xs={8}>
          <StyledChartContainer>
            <ChartsHeader>
              <ChartTitle>
                <FormattedMessage id='comunity.statistic.charttitle' />
              </ChartTitle>
              <ChartPeriodSwitcher>
                <PeriodArrowSelectBack onClick={() => setPeriod('prev')} />
                {moment(currentPeriod1).startOf('week').format('DD.MM.YY').toString()}
                {' - '}
                {moment(currentPeriod1).endOf('week').format('DD.MM.YY').toString()}
                <PeriodArrowSelectForward onClick={() => setPeriod('next')} />
              </ChartPeriodSwitcher>
            </ChartsHeader>
            <GridChartCard currentPeriod={currentPeriod1} data={data} />
          </StyledChartContainer>
        </Grid>
        <Grid item xs={4}>
          <RightBlockPapper>
            <TotalCard 
              name='TOTAL TEC' 
              symbol='TEC'
              symbolBgColor='#47b881' 
              data={{
                count: get(totalStatisticMonth, 'currentMonthCount.scheduled', 0),
                percent: get(totalStatisticMonth, 'comparsionPercent.scheduled', 0),
                tendention: get(totalStatisticMonth, 'comparsionPercent.scheduled', 0) > 0 ? 'up' : 'down',
              }}
            />
          </RightBlockPapper>
          <RightBlockPapper>
            <TotalCard 
              name='TOTAL TEP' 
              symbol='TEP'
              symbolBgColor='#f5a623' 
              data={{
                count: get(totalStatisticMonth, 'currentMonthCount.telexpertise', 0),
                percent: get(totalStatisticMonth, 'comparsionPercent.telexpertise', 0),
                tendention: get(totalStatisticMonth, 'comparsionPercent.telexpertise', 0) > 0 ? 'up' : 'down',
              }}
            />
          </RightBlockPapper>
          <GoalsManagementTable 
            ripsCount={statistic.ripsCount} 
            ripsSpecializationsCount={statistic.ripsSpecializationsCount}
            ripsRosoCount={statistic.ripsTeamsCount}
            averageScheduledWeek={averageScheduledWeek}
            averageTelexpertiseWeek={averageTelexpertiseWeek}
            countOfLastMonthChat={statistic.countOfLastMonthChat}
          />
        </Grid>
      </StyledGridContainer>
    </Page>
  ) 
}

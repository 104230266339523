import {createAction, handleActions} from 'redux-actions';
import axios from 'axios';
import {history} from "../../utils/history";

const specializationSelected = createAction('SPECIALIZATION_SELECTED')
export const loadStructureRequest = createAction('LOAD_STRUCTURE_REQUEST')
export const loadStructureSuccess = createAction('LOAD_STRUCTURE_SUCCESS')
export const loadStructureFailure = createAction('LOAD_STRUCTURE_FAILURE')

export const loadStructure = (structureId, specializationKey = null) => (dispatch) => {
  dispatch(loadStructureRequest(structureId))
  dispatch(specializationSelected(specializationKey))
  if (!structureId)
    return dispatch(loadStructureFailure('No structure ID provided'))
  // else
  return axios.get(`/api/public/structure/${structureId}?specialization=${specializationKey ? specializationKey : ''}`)
    .then(response => {
      dispatch(loadStructureSuccess(response.data))
    })
    .catch(err => {
      console.error(err)
      dispatch(loadStructureFailure(err))
      if (err.toJSON().status === 404) {
        history.push(`/`)
      }
    })
}

const selectSpecialization = (state, specializationKey) => {
  if (state.specs.specializations) {
    return state.specs.specializations.map(s => {
      return {
        name: s.name,
        key: s.key,
        selected: s.key === specializationKey,
      }
    })
  } else {
    return state.specs.specializations
  }
}

const listOfSpecializationsFromDoctors = (state, id, doctors) => {
  if (doctors) {
    if (!state.specs.loaded || state.specs.id !== id) {
      let specializations = new Map()
      for (const doctor of doctors) {
        specializations.set(doctor.specialization_key, {
          name: doctor.specialization,
          key: doctor.specialization_key,
          selected: false,
        })
      }
      return Array.from(specializations.values()).sort((a, b) => a.name.localeCompare(b.name))
    } // else
    return state.specs.specializations
  } // else
  return []
}

export const loadTeamDetailsRequest = createAction('LOAD_TEAM_DETAILS_REQUEST')
export const loadTeamDetailsSuccess = createAction('LOAD_TEAM_DETAILS_SUCCESS')
export const loadTeamDetailsFailure = createAction('LOAD_TEAM_DETAILS_FAILURE')

export const loadTeamDetails = (teamId) => (dispatch) => {
  dispatch(loadTeamDetailsRequest(teamId))

  return axios.get(`/api/doctor/team/view/${teamId}?load_members=1&load_skills=1`)
    .then(response => {
      return dispatch(loadTeamDetailsSuccess(response.data))
    })
    .catch(err => {
      console.error('Failed to load team ' + teamId, err)
      dispatch(loadTeamDetailsFailure(err))
      return Promise.reject(err)
    })
}

export const structureInfo = handleActions({
  [specializationSelected]: (state, action) => ({
    ...state,
    specs: {
      ...state.specs,
      specializations: selectSpecialization(state, action.payload)
    }
  }),
  [loadStructureSuccess]: (state, action) => ({
    structure: action.payload.data,
    specs: {
      id: action.payload.data.id,
      loaded: true,
      specializations: listOfSpecializationsFromDoctors(state, action.payload.data.id, action.payload.data?.attributes?.members)
    }
  }),
  [loadStructureFailure]: (state, action) => ({ error: action.payload }),
  [loadTeamDetailsSuccess]: (state, action) => ({
    ...state,
    team: action?.payload?.data,
  }),
}, {
  specs: { loaded: false },
  team: null,
})
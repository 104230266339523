import React, {useEffect, useMemo, useState} from 'react';
import {Configure, Index, InstantSearch, useHits, useSearchBox} from "react-instantsearch";
import styled from "styled-components";
import {Colors} from "../../../constants/colors";
import {SearchResults} from "../../Panel/Patient/Doctors/Main/SearchResults";
import {useSelector} from "react-redux";
import Tooltip from "@mui/material/Tooltip";
import {FormattedMessage, useIntl} from "react-intl";
import {GeoSearch} from "../GeoSearch";
import {history} from "../../../utils/history"
import {StyledTextButton} from "../UiKit/TextButton";
import SearchIcon from "@mui/icons-material/Search";
import {debounce} from 'lodash';
import {ElasticSearchService} from "../../../services/ElasticSearchService";

const Wrapper = styled.div`
    margin: 0;
    display: flex;
    z-index: 1500;
    width: 100%;
`

const WrapperSearch = styled.div`
    width: 60%;
    min-width: 135px;
    position: relative;
    margin-top: 16px;
    margin-bottom: 16px;

    & > svg {
        position: absolute;
        left: 20px;
        top: 12px;
        fill: ${Colors.bluePurple};
    }
`

const WrapperGeoSearch = styled.div`
    width: 40%;
    min-width: 375px;
`

const WrapperButton = styled.div`
    display: flex;
    justify-content: start;
    align-items: center;
    height: 48px;
    margin-top: 16px;
    margin-bottom: 16px;
`

const Input = styled.input`
    border: none;
    background-color: white;
    outline: none;
    height: 48px;
    padding: 16px 16px 16px 65px;
    box-sizing: border-box;
    width: 100%;
    font-size: 16px;
    font-weight: 500;
    color: ${Colors.dark};

    ::placeholder {
        color: ${Colors.blueGrey};
        font-size: 18px;
        font-weight: 500;
    }
`;

const StyledTooltip = styled((props) => (
  <Tooltip classes={{popper: props.className}} {...props} />
))`
    & .MuiTooltip-tooltip {
        font-size: 14px;
        display: flex;
        border-radius: 4px;
    }
`;

const sk = new ElasticSearchService().createSk(['nomExercice', 'prenomExercice', 'libelleCivilite', 'libelleProfession','first_name^8', 'last_name^8','specialization^2', 'codeCivilite', 'name^105']);

//function to search with elasticsearch
function CustomSearchBox(props) {
  const { refine} = useSearchBox(props);

  function setQuery(newQuery) {
    props.onSearch(newQuery);
    refine(newQuery);
  }

  setQuery = debounce(setQuery, 300);

  return (
    <div>
      <Input id='global-search'
             onChange={(event) => setQuery(event.currentTarget.value)}
             onKeyUp={ event => event.key === 'Enter' ? props.setEnterKeyIsPress(true): null}
             onFocus={ () => props.setCanDisplay(true) }
      />
    </div>
  )
}

// result elasticsearch
function CustomHits(props) {
  const {hits} = useHits(props);
  const {query} = useSearchBox(props);
  
  return (
    <SearchResults
      results={hits}
      canDisplay={props.canDisplay}
      searchValue={query}
      match={props.match}
    />
  );
}

//searchbar elasticSearch
export const InstantSearchBar = ({match}) => {

  const {formatMessage} = useIntl()
  const f = (id) => formatMessage({id: id});
  const [canDisplay, setCanDisplay] = useState(false);
  const isDoctor = useSelector(state => state.auth.type === 'Doctor');
  const reverseGeocoding = useSelector(state => state.geoloc.reverseGeocoding);
  const [searchWord, setSearchWord] = useState('');
  const [enterKeyIsPress, setEnterKeyIsPress] = useState(false);
  const profileAuth = useSelector((state) => state.auth);
  const env = window.env.APP_ENV;

  useEffect(() => {
    if (enterKeyIsPress){
      redirectPageResult();
      setEnterKeyIsPress(false);
    }
  }, [enterKeyIsPress, redirectPageResult]);

  function hasNoParentWithId (element, id) {
    for (let i = 0, depth = 6; i < depth && element.parentElement; i++) {
      if (element.id === id)
        return false;
      // else
      element = element.parentElement || element.parentNode
    }
    return true;
  }

  document.addEventListener('click', event => {
    if (!event.target || hasNoParentWithId(event.target, 'searchbar-annuaire')) {
      setCanDisplay(false);
    }
  });

  const searchClient= useMemo(()=> {
    return new ElasticSearchService(profileAuth).createClient(sk,reverseGeocoding,true)
  }, [reverseGeocoding]);

  function redirectPageResult() {
    const isTCA = match.path.includes('teleconsultation')
    if (isTCA) {
      history.push('/panel/teleconsultation/doctors' + '?name=' + searchWord);
    } else {
      history.push((isDoctor ? '/panel/concilium/doctors' : '/panel/doctors/search') + (searchWord ? '?name=' + searchWord : ''));
    }
    searchClient.clearCache();
  }

  return (
    <Wrapper id='searchbar-annuaire'>
      <WrapperSearch>
        <SearchIcon/>
        <InstantSearch stalledSearchDelay={200}
                       indexName={env+'_specialization_v1'}
                       searchClient={searchClient} >
          <Configure hitsPerPage={5} />
          <Index indexName={env+'_professional_v1,'}>
            <Configure hitsPerPage={5} />
          </Index>
          <Index indexName={'pro_directory,'}>
            <Configure hitsPerPage={5} />
          </Index>
          <Index indexName={env+'_team_v1'}>
            <Configure hitsPerPage={5} />
          </Index>
          <Index indexName={env+'_skill_v1'}>
            <Configure hitsPerPage={5} />
          </Index>
          <Index indexName={env+'_structure_v1'}>
            <Configure hitsPerPage={5} />
          </Index>
          <CustomSearchBox setCanDisplay={setCanDisplay} onSearch={setSearchWord} setEnterKeyIsPress={setEnterKeyIsPress} />
          <CustomHits canDisplay={canDisplay} match={match}/>
        </InstantSearch>
      </WrapperSearch>
      <WrapperGeoSearch>
        <GeoSearch placeholder={f('global.geo.where')}/>
      </WrapperGeoSearch>
      <StyledTooltip
        title={(!searchWord && !Object.keys(reverseGeocoding).length) || (!match.path.includes('main')) ? 'Vous devez d’abord rechercher une spécialité, un professionnel de santé, une équipe ou une structure.' : null}>
        <WrapperButton>
          <StyledTextButton
            disabled={ false}
            onClick={() => redirectPageResult()}
          >
            <FormattedMessage id='global.search'/>
          </StyledTextButton>
        </WrapperButton>
      </StyledTooltip>
    </Wrapper>
  );
}
InstantSearchBar.defaultProps = {};
InstantSearchBar.propTypes = {};
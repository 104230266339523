import React from 'react';
import PropTypes from 'prop-types';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import { FormattedMessage } from 'react-intl';
import Grid from '@mui/material/Grid';
import { connect } from 'react-redux'
import { compose, mapProps } from 'recompose';
import idx from 'idx';
import { fetchRestrict } from '../../../../redux/modules/content';
import styled from "styled-components";

export const ModalRestrictUsageComponent = (props) => {
  const {
    open,
    handleClose,
    onSubmit,
  } = props;

  return (
    <div>
      <Dialog
        open={open}
        onClose={() => handleClose('restrict', false)}
        aria-labelledby='scroll-dialog-title'
        aria-describedby='scroll-dialog-description'
      >
        <DialogTitle id='scroll-dialog-title'>
          <Typography variant='p' align='center' component='span'>
            <FormattedMessage id='gdpr.usage.restrictions.title' />
          </Typography>
        </DialogTitle>
        <DialogContent>
          <DialogContentText
            id='scroll-dialog-description'
            tabIndex={-1}
          >
            <FormattedMessage id='gdpr.modal.restrict' />
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Grid justifyContent='center' direction='row' container>
            <ButtonStyled
              onClick={onSubmit}
              color='primary'
            >
              <FormattedMessage id='global.send' />
            </ButtonStyled>
            <ButtonStyled onClick={() => handleClose('restrict', false)}>
              <FormattedMessage id='global.cancel' />
            </ButtonStyled>
          </Grid>
        </DialogActions>
      </Dialog>
    </div>
  );
};

ModalRestrictUsageComponent.propTypes = {
  open: PropTypes.bool.isRequired,
  handleClose: PropTypes.func.isRequired,
  onSubmit: PropTypes.func.isRequired,
};

const enhance = compose(
  connect(
    state => ({
      restrict: idx(state, _ => _.content.restrictData),
    }),
    { fetchRestrict },
  ),
  mapProps(props => ({
    ...props,
    onSubmit: () => props.fetchRestrict()
      .then(() => {
        props.handleClose('restrict', false)
        props.openSuccessModal()
      }),
  })),
);

export const ModalRestrictUsage = enhance(ModalRestrictUsageComponent);

const ButtonStyled = styled(Button)`
  text-transform: none !important;
  font-size: 14px;
`
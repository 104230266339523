import { createAction, handleActions } from 'redux-actions'
import idx from 'idx'
import axios from 'axios'
import uniqBy from 'lodash/uniqBy'
import get from 'lodash/get'
import { socket } from '../../../services/socket'
import {UPLOAD_FILE_MAX_BYTES} from "../../../constants/uploads";
import {useSelector} from "react-redux";

//#region Actions
export const CHAT_ACTIONS = {
  handleMessage: 'CHATS > HANDLE_MESSAGE',
  clearNotifications: 'CHATS > CLEAR NOTIFICATIONS',
  uploadFileRequest: 'CHATS > UPLOAD_FILE_REQUEST',
  uploadFileSuccess: 'CHATS > UPLOAD_FILE_SUCCESS',
  uploadFileFailure: 'CHATS > UPLOAD_FILE_FAILURE',
  loadLastMessages: 'CHATS > LOAD_LAST_MESSAGES',
  chatConnect: 'CHATS > CONNECT',
  loadFilesRequest: 'CHATS > LOAD_FILES_REQUEST',
  loadFilesSuccess: 'CHATS > LOAD_FILES_SUCCESS',
  loadFilesFailure: 'CHATS > LOAD_FILES_FAILURE',
}

export const handleMessageAction = createAction(CHAT_ACTIONS.handleMessage)
export const clearNotificationsAction = createAction(CHAT_ACTIONS.clearNotifications)
export const loadLastMessagesAction = createAction(CHAT_ACTIONS.loadLastMessages)
export const chatConnectAction = createAction(CHAT_ACTIONS.chatConnect)
export const uploadFileRequest = createAction(CHAT_ACTIONS.uploadFileRequest)
export const uploadFileSuccess = createAction(CHAT_ACTIONS.uploadFileSuccess)
export const uploadFileFailure = createAction(CHAT_ACTIONS.uploadFileFailure)
export const loadFilesRequest = createAction(CHAT_ACTIONS.loadFilesRequest)
export const loadFilesSuccess = createAction(CHAT_ACTIONS.loadFilesSuccess)
export const loadFilesFailure = createAction(CHAT_ACTIONS.loadFilesFailure)
//#endregion

//#region Reducers
export const chatNotificationsReducer = handleActions({
  [clearNotificationsAction]: () => [],
  [handleMessageAction]: (state, { payload }) => {
    if (payload.action === 'MessageReceived' && payload.attributes &&
      (payload.attributes?.chat_relate_type !== 'discussion' || !window.location?.pathname?.startsWith('/panel/discussions'))) {
      return [...state, payload.attributes];
    }

    return state;
  },
}, [])

export const chatFilesReducer = handleActions({
  [loadFilesSuccess]: (state, action) => {
    return {...state, [action.payload.chatId]: action.payload.files}
  },
}, [])

export const chatsReducer = handleActions({
  [chatConnectAction]: (state, { payload }) => {
    const id = idx(payload, _ => _.attributes.relateId) || {}
    if (!id) return state

    return {
      ...state,
      [id] : {
        ...payload, 
        chat: {
          ...payload.attributes, 
          ...payload, 
          id: Number(payload.id),
        },
      },
    }
  },
  [loadLastMessagesAction]: (state, { payload }) => {
    const chatId = idx(payload, _ => _[0].attributes.chat_id)
    socket.chatid = chatId
    const chatRelateId = idx(payload, _ => _[0].attributes.chat_relate_id)
    if (!chatId || !chatRelateId) return state
    const chat = state[chatRelateId]
    const newMessages = uniqBy([...(get(chat, 'lastMessages', [])), ...payload], 'id')

    return {
      ...state,
      [chatRelateId]: {
        ...chat,
        lastMessages: newMessages,
      },
    }
  },

  [handleMessageAction]: (state, { payload }) => {
    if (payload.code === 400) {
      socket.authorize()
    }

    if (payload.action === 'MessageDelete') {
      const id = idx(payload, _ => _.id) || false;
      const keys = Object.keys(state);
      if (keys && keys[0] && state[keys[0]] && state[keys[0]].lastMessages && id) {
        const filteredLastMessages = state[keys[0]].lastMessages
          .filter(el => +el.id !== +id);
        return {
          ...state,
          [keys[0]]: {
            ...state[keys[0]],
            lastMessages: filteredLastMessages,
          },
        }
      }
    }
    /* eslint-disable */
    if (payload.action === 'MessageReaded') {
      const messagesIds = get(payload, 'attributes', []).map(el => el.id);
      const { id, chat_id } = get(payload, 'attributes[0].attributes', {});
      if (!id || !(state instanceof Object) || !chat_id) return state

      const currentChat = Object.keys(state).reduce((accum, el) => {
        if (get(state, `[${el}].chat.id`, false) === chat_id) {
          return { ...state[el], relateId: el };
        }
        return accum;
      }, {})

      return {
        ...state,
        [currentChat.relateId]: {
          ...currentChat,
          lastMessages: get(currentChat, 'lastMessages', []).map((el) => {
            let newAttr = { ...el.attributes };
            if (messagesIds.includes(String(el.id))) {
              newAttr = { ...el.attributes, read: 1 };
            }
            return { ...el, attributes: newAttr };
          }),
        },
      }
    }

    if (payload.action === 'MessageReceived') {
      const relateId = payload.attributes.chat_relate_id;
      if (!relateId) return state

      const chat = state[relateId]
      if (!chat) return state

      return {
        ...state,
        [relateId]: {
          ...chat,
          lastMessages: [
            { id: payload.id, attributes: payload.attributes },
            ...uniqBy((chat.lastMessages || []), 'id'),
          ],
        },
      }
    }

    if (payload.action === 'loadMessage') {
      const chatId = idx(payload, _ => _.attributes[0].attributes.chat_id)
      if (!chatId) return state
      const consultationId = Object.keys(state).find(item => state[item].chat.id === chatId)
      const chat = state[consultationId]
      const newMessages = uniqBy([...chat.lastMessages, ...payload.attributes], 'id')

      return {
        ...state,
        [consultationId]: {
          ...chat,
          lastMessages: newMessages,
          mustReload: false,
        },
      }
    }

    if (payload.route === 'User\\Notification' && payload.action === 'send') {
      const consultationId = idx(payload, _ => _.attributes.consultationId);
      if (consultationId && state[consultationId]) {
        const chat = state[consultationId];
        return {
          ...state,
          [consultationId]: {
            ...chat,
            mustReload: new Date().getTime(),
          },
        }
      }
    }

    if (payload.action === 'ChatReload') {
      const consultationId = idx(payload, _ => _.attributes.chat.relateId);
      const chat = state[consultationId];
      return {
        ...state,
        [consultationId]: {
          ...chat,
          mustReload: new Date().getTime(),
        },
      }
    }

    if (payload.action === 'AdditionalDoctorJoin') {
      const chat = state[payload.attributes.consultationId]
      if (chat) {
        socket.notify(chat.chat.id)
      }
      return state
    }
   /* eslint-enable */
    return state
  },
}, {})
//#endregion

//#region Thunks
export const connectChat = (relateId, relateType = 'consultation') => dispatch => (
  axios.get(`/api/chat?relateId=${relateId}&relateType=${relateType}`)
    .then((response) => {
      const chat = get(response, 'data.data', {});
      dispatch(chatConnectAction(chat));
    })
    .catch(err => console.warn(err)))

export const loadLastMessages = (chatId, messageId, nbMessages, abortController = {}) => (dispatch) => {

  if(abortController.current){
    abortController.current.abort()
  }

  abortController.current = new AbortController();

  axios.get(`/api/chat/messages?chatId=${chatId}${messageId ? `&lastId=${messageId}` : ''}${nbMessages ? `&nbMessages=${nbMessages}` : ''}`, {
    signal: abortController.current.signal
  })
    .then((response) => {
      const messages = get(response, 'data.data', []);
      dispatch(loadLastMessagesAction(messages))
    })
    .catch((err) => {
      console.log(err);
    })
}

export const uploadFile = (data, chatId, setLoadingProgress, setErrorFileTooBig, attachmentType = 0) => (dispatch) => {
  dispatch(uploadFileRequest())
  if (data.file.size > UPLOAD_FILE_MAX_BYTES) {
    setErrorFileTooBig(true)
    return dispatch(uploadFileFailure('File too large : ' + data.file.size))
  } // else

  const formData = new FormData()
  formData.append('file', data.file)
  formData.append('attachment_type', attachmentType.toString(10))
  return axios('/api/attachment/upload?private=true', {
    method: 'POST',
    data: formData,
    onUploadProgress: (progressEvent) => {
      const percentCompleted = Math.round((progressEvent.loaded * 100) / progressEvent.total)
      setLoadingProgress(percentCompleted)
    },
  })
    .then((response) => {
      dispatch(uploadFileSuccess(response.data))
      socket.sendFileMessage(chatId, response.data.data.id)
      setLoadingProgress(0)
      setErrorFileTooBig(null)
    })
    .catch((err) => {
      setErrorFileTooBig(err.message)
      setLoadingProgress(0)
      dispatch(uploadFileFailure(err))
    })
}

export const loadFiles = (chatId) => (dispatch) => {
  dispatch(loadFilesRequest())
  axios.get(`/api/chat/${chatId}/files/all`)
    .then((response) => {
      const files = get(response, 'data.data', []);
      dispatch(loadFilesSuccess({chatId, files}))
    })
    .catch((err) => {
      dispatch(loadFilesFailure(err))
    })
}
//#endregion

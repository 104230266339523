import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import { useDispatch, useSelector } from 'react-redux'
import idx from 'idx'
import styled from 'styled-components'
import queryString from 'query-string'

import get from 'lodash/get';
import { Page } from '../../../common/Page'
import { history } from '../../../../utils/history'
import {fetchDoctor} from '../../../../redux/modules/doctors'
import { DoctorInfo } from '../../Patient/Doctors/UrgentConsultation/DoctorInfo'
import { getDoctorPrice } from '../../../../helpers/consultations'
import { CreateConciliumForm } from './CreateForm'
import { Paper } from 'common/UiKit/Paper'
import EssLogo from "../Community/ROSO/images/Logo_ESS.png";
import {FormattedMessage} from "react-intl";
import {clearDraftConsultation} from "../../../../redux/modules/consultations";
import {getTeamById} from "../../../../helpers/Doctor/TeamHelper";
import {Colors} from "../../../../constants/colors";


const PaperStyled = styled(Paper)`
  padding: 20px 0 20px 24px;
  margin: 24px 0 16px;
`

const Wrapper = styled.div`
  display: flex;
  font-weight: normal;
  font-size: 16px
`

const Info = styled.div`
  flex-grow: 1;
  margin: 12px 0 0 32px;
`

const GeneralInfo = styled.div`
  display: flex;
  justify-content: space-between;
`


const Name = styled.h1`
  margin: 0;
  font-size: 24px;
  font-weight: bold;
`

const Specialization = styled.p`
  margin: 0;
  opacity: 0.8;
  padding-top: 10px;
`

const Img = styled.img`
  //keep ratio
    height: 60px;
    width: 150px;
    margin : auto;
`

const TeamName = styled.h1`
  margin: 0;
  font-size: 24px;
  font-weight: bold;
`
const SkillsBlock = styled.div`
  display: flex;
  max-width: 100%;
  flex-wrap: wrap;
`
const Skills = styled.div`
  background-color: ${Colors.purpleGrey};
  padding: 3px 16px;
  border-radius: 11px;
  margin: 4px 7px 4px 7px;
  color: #5D5577;
  word-break: initial;
  display: inline-flex;
  justify-content: center;
  text-align: center;
`
const H5 = styled.div`
  font-family: Roboto;
  font-size: 16px;
  font-weight: 500;
  margin: 10px 0;
  word-break: break-word;

  span {
  font-family: Roboto;
  font-size: 16px;
  font-weight: 400;
  }
  p {
  margin: 5px 0;
  font-family: Roboto;
  font-size: 15px;
  font-weight: 400;
  }
`


export const CreateConciliumPage = ({ match, location }) => {
  const dispatch = useDispatch()
  const doctor = useSelector(state => state.doctors.doctor)
  const type_employment = useSelector(state => state.auth.attributes.type_employment)
  const workPlace = useSelector(state => idx(
    state,
    _ => _.doctors.doctor.relationships.workPlace.data.slice(-1)[0].attributes,
  ))
  const isDoctor = useSelector(state => state.auth.type === 'Doctor')
  const isTCA = match.path.includes('teleconsultation')
  const keyNearest = isDoctor ? isTCA ? 'TCA' : 'TE' : 'TC';
  const isUrgent = !!queryString.parse(location.search).urgent
  const isProposedDate = !!queryString.parse(location.search).proposedDate
  const teamType = location.pathname.split("/").pop()

  if(teamType !== 'ESS') {
    useEffect(() => {
      dispatch(fetchDoctor(match.params.doctorId))
    }, [match.params.doctorId])
  }

  const [team, setTeam] = useState([]);
  const mySkills = get(team, 'relationships.skills.data', []);

  useEffect(() => {
    if (!!match?.params?.teamId) {
      getTeamById(match.params.teamId).then(result => setTeam(result));
    }
  }, [match]);

  const refreshTeam = () => {
    if (!!match?.params?.teamId) {
      getTeamById(match.params.teamId).then(result => setTeam(result));
    }
  }

  useEffect(() => {
    refreshTeam();
  }, []);

  const specializations = get(team, 'relationships.specializations.data', []);
  const workPlaces = get(team, 'relationships.structures.data', []);
  const teamInfo = get(team, 'attributes', {});

  return (
    <Page
      label={isTCA ? (isProposedDate? 'concilium.create.tca' : 'tca.new.invite') : 'concilium.create'}
      onBackIconClick={history.goBack}
      isFetching={teamType !== 'ESS' && !doctor.id || teamType === 'ESS' && !team}
    >
      {
        (teamType === 'ESS') ? (
            <>
              <Content>
                <PaperStyled>
                  <Wrapper>
                    <Img src={ EssLogo } alt='EsspLogo' />
                    <Info>
                      <TeamName>
                        {teamInfo.name}
                      </TeamName>
                      {specializations.length > 0  &&
                          (
                              <Specialization>
                                <FormattedMessage id='roso.team.specialty' />
                                :&nbsp;
                                {specializations.map((el, i) => (
                                    <span key={`${el.attributes.id}-${el.attributes.name}`}>
                          {el.attributes.name}
                        </span>
                                ))}
                              </Specialization>
                          )}

                      { workPlaces.length > 0  && (
                        <Specialization>
                          <FormattedMessage id='roso.team.city' />
                          :&nbsp;
                          {workPlaces.map((workplace, i) => (
                              <span>
                                {
                                    workplace.attributes.city && (
                                        <span key={workplace.id}>{workplace.attributes.city}
                                          {i + 1 !== workPlaces.length ? ', ' : ''}
                                    </span>
                                    )
                                }
                              </span>
                          ))}
                        </Specialization>
                      )}

                      {mySkills && mySkills.length ? (
                          <H5>
                            <FormattedMessage id='roso.team.skills.domains' />:
                            <SkillsBlock>
                              {mySkills.map(el => (
                                  <Skills
                                      key={el.attributes.id}
                                  >
                                    {el.attributes.name}
                                  </Skills>
                              ))}
                            </SkillsBlock>
                          </H5>
                      ) : ''}

                      { teamInfo.description && (
                          <Specialization>
                            <FormattedMessage id='roso.team.description' />
                            :&nbsp;
                            {teamInfo.description}
                          </Specialization>
                      )}

                    </Info>
                  </Wrapper>
                </PaperStyled>
              </Content>
              <CreateConciliumForm
                type_employment={type_employment}
                isTCA={isTCA}
                available={get(doctor, `attributes.calendar_nearest.${keyNearest}`, null)}
                doctorId={ match.params.teamId }
                isUrgent={isUrgent}
                teamType={teamType}
              />
            </>
        ) : (
            doctor.id
                && (
                    <>
                      <Content>
                        <DoctorInfo
                          doctorId={match.params.doctorId}
                          firstName={doctor.attributes.first_name}
                          lastName={doctor.attributes.last_name}
                          experience={doctor.attributes.experience}
                          specialization={doctor.attributes.specialization}
                          photo={doctor.attributes.photo}
                          language={doctor.attributes.language}
                          location={workPlace}
                          available={get(doctor, `attributes.calendar_nearest.${keyNearest}`, null)}
                          price={getDoctorPrice(doctor, 'urgent')}
                          specialization_type={doctor.attributes.specialization_type}
                          specialization_key={doctor.attributes.specialization_key}
                          match={match}
                        />
                      </Content>
                      <CreateConciliumForm
                          type_employment={type_employment}
                          isTCA={isTCA}
                          available={get(doctor, `attributes.calendar_nearest.${keyNearest}`, null)}
                          doctorId={match.params.doctorId}
                          isUrgent={isUrgent}
                      />
                    </>
                )
        )
      }
    </Page>
  )
}

CreateConciliumPage.propTypes = {
  match: PropTypes.object.isRequired,
}

const Content = styled.div`
  & div > div:nth-child(2) > div > div:nth-child(2) {
    display: none;
  }
`

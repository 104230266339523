import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux'
import { Router } from 'react-router-dom'
import axios from 'axios'

import { App } from './App'
import * as serviceWorker from './serviceWorker'
import { store } from './redux'
import { history } from './utils/history'

axios.defaults.baseURL = window.env.API_URL

ReactDOM.render((
  <Router history={history}>
    <Provider store={store}>
      <App />
    </Provider>
  </Router>
), document.getElementById('root'));

serviceWorker.unregister();

import React, { useState, useEffect } from 'react'
import styled from 'styled-components'
import ErrorIcon from '@mui/icons-material/Error'
import { socket } from '../../../services/socket'

const SocketErrorWrapper = styled.div`
    position: absolute;
    background-color: #fff9e4;
    z-index: 9;
    right: 21px;
    height: 56px;
    color: #ffbb00;
    display: flex;
    align-items: center;
    border-radius: 4px;
    top: 65px;
`

const IconHolder = styled.div`
    display: flex;
    padding: 16px;
`

const MessageContainer = styled.div`
    display: block;
`

export const SocketErrorNotification = () => {
  const [timer, setTimer] = useState(socket.connecting)
  const [hidden, setHidden] = useState(false)
  const [cancelTimeout, setCancelTimeout] = useState(null)

  useEffect(() => {
    const closedSocket = () => {
      setCancelTimeout(setTimeout(() => {
        setTimer(true);
      }, 5000))
    };

    const openedSocket = () => {
      if (cancelTimeout) {
        cancelTimeout()
        setCancelTimeout(null)
      }
      setTimer(false);
      setHidden(false)
    }

    document.addEventListener('socketClosed', closedSocket, false);
    document.addEventListener('socketConnected', openedSocket, false);

    return () => {
      document.removeEventListener('socketClosed', closedSocket, false);
      document.removeEventListener('socketConnected', openedSocket, false);
    }
  }, [])

  return !timer || hidden ? null : (
    <SocketErrorWrapper onClick={() => setHidden(true)}>
      <IconHolder>
        <ErrorIcon />
      </IconHolder>
      <MessageContainer>
        <strong>Oups</strong> ! Votre connexion avec le serveur est fermée, vous ne pouvez pas recevoir ni envoyer de messages. <br />
        <em>Veuillez attendre quelques secondes que la connexion soit rétablie</em>
      </MessageContainer>
    </SocketErrorWrapper>
  ) 
}

import React from 'react'
import { Form, Field } from 'react-final-form'
import styled from 'styled-components'
import { mapProps } from 'recompose'
import { FormattedMessage } from 'react-intl'
import { Colors } from 'constants/colors'
import { Paper } from '../Paper'
import { TextField } from '../TextField'

const Line = styled.div`
  display: flex;

  .location {
    flex-grow: 1;
  }

  .location-input {
    display: flex;
    justify-content: space-between;
  }
`

const Label = styled.div`
  width: 172px;
  min-width: 172px;
  margin-top: 26px;
  margin-right: 12px;
  opacity: 0.87;
  font-weight: bold;
  font-size: 16px;
  color: ${Colors.dark};
`
const PriceField = styled(Field)`
  && {
    width: 152px;
    margin-top:16px;
    label {
      padding-left: 15px;
      padding-top: 2px;
    }
    & > div {
      padding-left: 20px;
    }
  }
  &&:before {
    content: '€';
    position: absolute;
    left: 5px;
    top: 25px;
    color: #949eb8;
  }
`
const RequisitesField = styled(Field)`
  && {
    margin-top:16px;
    
    &:first-child {
      width: 30%;
    }
    
    &:last-child {
      width: 60%;
    }
    
    label {
      padding-left: 15px;
      padding-top: 2px;
    }
    & > div {
      padding-left: 20px;
      height: auto;
      line-height: auto;
    }
  }
`

const validate = (values) => {
  const errors = {}
  if (typeof (values.daily_price) !== 'number') errors.daily_price = <FormattedMessage id='profile.error.daily.price' />
  if (typeof (values.weekend_price) !== 'number') errors.weekend_price = <FormattedMessage id='profile.error.weekend.price' />
  if (typeof (values.urgent_price) !== 'number') errors.urgent_price = <FormattedMessage id='profile.error.urgent.price' />
  return errors
}

const normalizeValues = values => ({
  daily_price: values.daily_price,
  weekend_price: values.weekend_price,
  urgent_price: values.urgent_price,
  requisites_private_bic: values.requisites_private_bic,
  requisites_private_iban: values.requisites_private_iban,
  requisites_hospital_bic: values.requisites_hospital_bic,
  requisites_hospital_iban: values.requisites_hospital_iban,
})

const normalizeInitialValues = values => ({
  daily_price: values.daily_price,
  weekend_price: values.weekend_price,
  urgent_price: values.urgent_price,
  requisites_private_bic: values.requisites_private_bic,
  requisites_private_iban: values.requisites_private_iban,
  requisites_hospital_bic: values.requisites_hospital_bic,
  requisites_hospital_iban: values.requisites_hospital_iban,
})

const enhance = mapProps(props => ({
  ...props,
  onSubmit: values => props.onSubmit({ ...normalizeValues(values) })
    .then(() => props.afterSubmit()),
}))

export const PricesConsultations = enhance(({
  onSubmit,
  id,
  initialValues,
  disableShadow,
  methodField,
  employmentType,
}) => (
  <Form
    onSubmit={onSubmit}
    validate={validate}
    subscription={{}}
    initialValues={initialValues && normalizeInitialValues(initialValues)}
    render={({ handleSubmit }) => (
      <form onSubmit={handleSubmit} id={id}>
        <Paper disableShadow={disableShadow}>
          <Line>
            <Label>
              <FormattedMessage id='profile.cost.consultation' />
            </Label>
            <div className='location'>
              <div className='location-input'>
                <PriceField name='daily_price' parse={value => value && (+value || '')} label={<FormattedMessage id='profile.urgent.price' />} component={TextField} />
                <PriceField name='weekend_price' parse={value => value && (+value || '')} label={<FormattedMessage id='profile.weekend' />} component={TextField} />
                <PriceField name='urgent_price' parse={value => value && (+value || '')} label={<FormattedMessage id='profile.urgent' />} component={TextField} />
              </div>
            </div>
          </Line>
          {
              methodField && (
                <Line>
                  <Label>
                    <FormattedMessage id='profile.method' />
                  </Label>
                  <div className='location'>
                    {methodField}
                  </div>
                </Line>
              )
            }
          {
              (employmentType === 'private' || employmentType === 'merge') && (
                <Line>
                  <Label>
                    <FormattedMessage id='profile.requisites.liberal' />
                  </Label>
                  <div className='location'>
                    <div className='location-input'>
                      <RequisitesField
                        name='requisites_private_bic'
                        label='BIC'
                        component={TextField}
                        multiline
                        maxRows={4}
                      />
                      <RequisitesField
                        name='requisites_private_iban'
                        label='IBAN'
                        component={TextField}
                        multiline
                        maxRows={4}
                      />
                    </div>
                  </div>
                </Line>
              )
            }
          {
              (employmentType === 'hospital' || employmentType === 'merge') && (
                <Line>
                  <Label>
                    <FormattedMessage id='profile.requisites.salary' />
                  </Label>
                  <div className='location'>
                    <div className='location-input'>
                      <RequisitesField
                        name='requisites_hospital_bic'
                        label='BIC'
                        component={TextField}
                        multiline
                        maxRows={4}
                      />
                      <RequisitesField
                        name='requisites_hospital_iban'
                        label='IBAN'
                        component={TextField}
                        multiline
                        maxRows={4}
                      />
                    </div>
                  </div>
                </Line>
              )
            }
        </Paper>
      </form>
    )}
  />
))
